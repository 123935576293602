import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import RegisterIcon from '../../assets/svg/Crew.svg';
import PresentIcon from '../../assets/svg/User.svg';
import JobIcon from '../../assets/svg/jobs.svg';
import MatchedIcon from '../../assets/svg/Match.svg';
import NoMatchIcon from '../../assets/svg/NoMatch.svg';
import { IconBadge } from '../shared-components/IconBadge';
import { COLORS } from '../../styles/constants/colors';
import { getUserIconColorWithRoleId } from '../../styles/utils';
import { getRoleId } from '../../utils/userInfo';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
// import AppSocketContext from '../../context/AppSocket';
// import { getUrlVars } from '../../utils/common';
// will implement this after having proper solution for caching issue.

const EventAnalyticsCandidate = ({ analytics }) => {
  const initialValue = '-';
  const roleId = getRoleId();
  /*
  const client = useContext(AppSocketContext);
  const history = useHistory();
  const { event } = getUrlVars(get(history, 'location.search', {}));
*/
  const [noOfCandidates, setNoOfCandidates] = useState(0);
  const [noOfCandidatesPresent, setNoOfCandidatesPresent] = useState(
    initialValue,
  );
  const [noOfJobs, setNoOfJobs] = useState(initialValue);
  const [noOfMatches, setNoOfMatches] = useState(initialValue);
  const [
    noOfZeroSkillMatchCandidates,
    setNoOfZeroSkillMatchCandidates,
  ] = useState(initialValue);

  useEffect(() => {
    setNoOfCandidates(get(analytics, 'noOfCandidates', initialValue));
    setNoOfCandidatesPresent(
      get(analytics, 'noOfCandidatesPresent', initialValue),
    );
    setNoOfJobs(get(analytics, 'noOfJobs', initialValue));
    setNoOfMatches(get(analytics, 'noOfMatches', initialValue));
    setNoOfZeroSkillMatchCandidates(
      get(analytics, 'noOfZeroSkillMatchCandidates', initialValue),
    );
  }, [analytics]);

  /*
  useEffect(() => {
    if (client && event) {
      (async () => {
        await client.subscribe(
          `/attendees-management/event/${event}/`,
          (resp) => {
            console.log(toNumber(noOfCandidates) + 1);
            if (!resp.isCompany && noOfCandidates !== '-') {
              console.log(toNumber(noOfCandidates) + 1);
              setNoOfCandidates(toNumber(noOfCandidates) + 1);
            }
          },
        );
      })();
    }
    // eslint-disable-next-line
  }, [event]);
  */

  const analyticsData = [
    {
      icon: RegisterIcon,
      key: 'Registered',
      value: noOfCandidates,
    },
    {
      icon: PresentIcon,
      key: 'Present',
      value: noOfCandidatesPresent,
    },
    {
      icon: JobIcon,
      key: 'Jobs',
      value: noOfJobs,
    },
    {
      icon: MatchedIcon,
      key: 'Matched',
      value: noOfMatches,
    },
    {
      icon: NoMatchIcon,
      key: 'No Match',
      value: noOfZeroSkillMatchCandidates,
    },
  ];

  return analyticsData.map((item, i) => (
    <Wrapper key={i.toString()}>
      <IconBadge
        icon={item.icon}
        color={getUserIconColorWithRoleId(roleId)}
        text={item.key}
        textColor={COLORS.WHITE}
        status={item.value}
        isCursorPointer={false}
      />
    </Wrapper>
  ));
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: flex-start;
  }
`;

export default EventAnalyticsCandidate;
