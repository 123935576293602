import get from 'lodash/get';
import api from './api';
import { APIs, Method } from '../constants';

const getCrewMembers = () => {
  return api({
    url: APIs.companyMember,
    method: Method.get,
  });
};

const inviteRegistererdMember = (body) => {
  return api({
    url: APIs.inviteRegisteredMember,
    method: Method.post,
    body,
  });
};

const inviteNonRegistererdMember = (body) => {
  return api({
    url: APIs.inviteNonRegistererdMember,
    method: Method.post,
    body,
  });
};

const acceptInvitation = (companyId, companyMemberId) => {
  const url = `/v1/company/${companyId}/invite/${companyMemberId}/accept/`;

  return api({
    url,
    method: Method.put,
  });
};

const getCompanys = () => {
  return api({
    url: APIs.companys,
    method: Method.get,
  });
};

const switchCompany = (data) => {
  return api({
    url: APIs.switchCompanys,
    method: Method.post,
    body: data,
  }).then((response) => {
    const companyData = get(response, 'data.payload', {});
    return companyData;
  });
};

const removeMemberInvite = (data) => {
  return api({
    url: APIs.removeInvite,
    method: Method.post,
    body: data,
  });
};

export const removeMember = (data) => {
  return api({
    url: APIs.removeMember,
    method: Method.put,
    body: data,
  });
};

export const rejectInvitation = (companyId, companyMemberId) => {
  const url = APIs.rejectCompanyInvite(companyId, companyMemberId);

  return api({
    url,
    method: Method.put,
  });
};

export const acceptCrewMember = (crewMemberId) => {
  const url = APIs.acceptCrewMember;

  return api({
    url,
    method: Method.put,
    body: { crewMemberId },
  });
};

export {
  getCrewMembers,
  inviteRegistererdMember,
  inviteNonRegistererdMember,
  acceptInvitation,
  getCompanys,
  switchCompany,
  removeMemberInvite,
};
