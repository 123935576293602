import React, { useContext, useEffect, useState } from 'react';
// Commented out for lite version.Will be used later
// import { NotificationSettingsIcon } from '../svgicons';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { getNotificationsUpdate } from '../../store/features/notifications';
import { NotificationUpdatesStore } from '../../utils/notifications';
import { getRoleId, getUserClassWithRoleId } from '../../utils/userInfo';
import { NotificationsCard } from '../shared-components/NotificationsCard';
import AlarmIcon from '../../assets/icons-and-sets/082-alarm-1.svg';
import BriefCaseIcon from '../../assets/svg/jobs.svg';
import ChatIcon from '../../assets/icons-and-sets/chat.svg';
import SalesIcon from '../../assets/icons-and-sets/170-ticket.svg';
import RecruitIcon from '../../assets/icons-and-sets/031-businessman.svg';
import { COLORS } from '../../styles/constants/colors';
import AppSocketContext from '../../context/AppSocket';
import { userProfileStore } from '../../utils/profile';
import { NotificationsType } from '../../constants/notificationsType';

const Notifications = () => {
  const roleId = getRoleId();

  const { updates } = NotificationUpdatesStore();

  const client = useContext(AppSocketContext);
  const { profileData } = userProfileStore();
  const [sales, setSales] = useState(get(updates, 'sales', 0));
  const [team, setTeam] = useState(get(updates, 'team', 0));
  const [update, setUpdate] = useState(get(updates, 'update', 0));
  const [jobs, setJobs] = useState(get(updates, 'jobs', 0));
  const [chat, setChat] = useState(get(updates, 'chat', 0));

  const dispatch = useDispatch();

  useEffect(() => {
    if (updates) {
      setSales(get(updates, 'sales', 0));
      setTeam(get(updates, 'team', 0));
      setUpdate(get(updates, 'update', 0));
      setJobs(get(updates, 'jobs', 0));
      setChat(get(updates, 'chat', 0));
    }
  }, [updates]);

  useEffect(() => {
    if (client && profileData.userId) {
      (async () => {
        await client.subscribe(
          `/user/${profileData.userId}/notification/`,
          (resp) => {
            switch (resp.category) {
              case NotificationsType.Team:
                setTeam((prevTeam) => prevTeam + 1);
                break;
              case NotificationsType.Sales:
                setSales((prevSales) => prevSales + 1);
                break;
              case NotificationsType.Chat:
                setChat((prevChat) => prevChat + 1);
                break;
              case NotificationsType.Jobs:
                setJobs((prevJobs) => prevJobs + 1);
                break;
              default:
                setUpdate((prevUpdate) => prevUpdate + 1);
            }
          },
        );
      })();
    }

    // eslint-disable-next-line
  }, [profileData.userId]);

  useEffect(() => {
    dispatch(getNotificationsUpdate());
  }, [dispatch]);

  const icons = {
    candidate: [
      {
        icon: AlarmIcon,
        color: COLORS.BLUSH,
        text: 'Updates',
        updates: update,
        link: 3,
      },
      {
        icon: BriefCaseIcon,
        color: COLORS.FEDORA,
        text: 'Jobs',
        updates: jobs,
        link: 5,
      },
      {
        icon: ChatIcon,
        color: COLORS.SLATE_BLUE,
        text: 'Chats',
        updates: chat,
        link: 4,
      },
    ],
    organizer: [
      {
        icon: SalesIcon,
        color: COLORS.DARK_SALMON,
        text: 'Sales',
        updates: sales,
        link: 1,
      },
      {
        icon: AlarmIcon,
        color: COLORS.BLUSH,
        text: 'Updates',
        updates: update,
        link: 3,
      },
      {
        icon: BriefCaseIcon,
        color: COLORS.FEDORA,
        text: 'Team',
        updates: team,
        link: 2,
      },
      {
        icon: ChatIcon,
        color: COLORS.SLATE_BLUE,
        text: 'Chat',
        updates: chat,
        link: 4,
      },
    ],

    recruiter: [
      {
        icon: RecruitIcon,
        color: COLORS.DARK_SALMON,
        text: 'Recruits',
        updates: jobs,
        link: 5,
      },
      {
        icon: AlarmIcon,
        color: COLORS.BLUSH,
        text: 'Updates',
        updates: update,
        link: 3,
      },
      {
        icon: BriefCaseIcon,
        color: COLORS.FEDORA,
        text: 'Team',
        updates: team,
        link: 2,
      },
      {
        icon: ChatIcon,
        color: COLORS.SLATE_BLUE,
        text: 'Chat',
        updates: chat,
        link: 4,
      },
    ],
  };

  return (
    <>
      <NotificationsCard theme={getUserClassWithRoleId(roleId)} icons={icons} />
      {
        // Commented out for lite version.Will be used later
        /* <NotificationSettingsIcon /> */
      }
    </>
  );
};

export default Notifications;
