import React from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import {
  ProfileInformationHeader,
  ProfileInformationValue,
} from '../CrewManagement/common';

const BasicInformation = ({ profileData }) => {
  const name = get(
    profileData,
    'representativeName',
    get(profileData, 'name', '-'),
  );
  const phone = get(
    profileData,
    'representativePhone',
    get(profileData, 'phone', '-'),
  );
  const email = get(
    profileData,
    'representativeEmail',
    get(profileData, 'email', '-'),
  );

  return (
    <Row gutter={[16, 24]}>
      <Col xs={24} lg={8}>
        <ProfileInformationHeader>Full Name</ProfileInformationHeader>
        <ProfileInformationValue>{name}</ProfileInformationValue>
      </Col>
      <Col xs={24} lg={8}>
        <ProfileInformationHeader>Phone Number</ProfileInformationHeader>
        <ProfileInformationValue>{phone}</ProfileInformationValue>
      </Col>
      <Col xs={24} lg={8}>
        <ProfileInformationHeader>Email Address</ProfileInformationHeader>
        <ProfileInformationValue>{email}</ProfileInformationValue>
      </Col>
    </Row>
  );
};

export default BasicInformation;
