/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import {
  ADDRESS_LINE_ONE,
  ADDRESS_LINE_TWO,
  ADDRESS_NAME,
  CITY_TEXT,
  COUNTRY_TEXT,
  ZIPCODE_TEXT,
  ZERO_RESULTS,
  STATE_TEXT,
} from '../../constants';
import { getNationalityData, getStatesData } from '../../utils/common';
import MandatoryStar from './mandatoryStar';
import {
  AddressAutoCompleteBox,
  Dropdown,
  TextBox,
} from '../shared-components/Form/Form';
import { SmallHeading } from '../shared-components/Texts';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';

const AddressAutoComplete = ({
  form,
  field,
  list,
  className,
  disabled,
  isForBilling,
  isForBillingEditMode = false,
  zipcode = '',
  showExtraFields = false,
  showExtraFieldsWithState = false,
  flex,
  candidateAddressProfileEdit,
  isCreateEvent = false,
  isCandidateEdit = false,
}) => {
  const { countryData } = useSelector((st) => st.commons);
  const [country, setCountry] = useState(form.getFieldValue('country'));
  const [state, setState] = useState(form.getFieldValue('state'));
  const [city, setCity] = useState(form.getFieldValue('city'));
  const [zip, setZip] = useState(zipcode || form.getFieldValue('zip'));
  const [statesList, setStates] = useState([]);
  const [lastErrorZip, setErrorZip] = useState(null);
  const [nationalityList, setNationalityList] = useState([]);

  const zipValidator = (rule, value, callback) => {
    if (!/\d/.test(value)) {
      callback('');
    }
    if (value.trim().length < 3 || value.trim().length > 12) {
      callback('');
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (country && country !== undefined) {
      getStatesData(country).then((res) => setStates(res));
    }
  }, [country]);

  useEffect(() => {
    if (zipcode) setZip(zipcode);
  }, [zipcode]);

  const idBase = `address_auto_complete`;
  const handleChangeZip = (zip) => {
    setZip(zip);
  };

  const handleAutoFill = (location) => {
    if (location && zip !== lastErrorZip) {
      const suggestedAddress = location.formattedSuggestion.secondaryText.split(
        ',',
      );
      const address = suggestedAddress.reverse();
      const country = (address[0] && address[0].trim()) || '';
      const state = (address[1] && address[1].trim()) || '';
      const city = (address[2] && address[2].trim()) || state || '';
      setCountry(country);
      setState(state);
      setCity(city);
    }
  };

  const onError = (status, clearSuggestions) => {
    if (status === ZERO_RESULTS) {
      setErrorZip(zip);
      setCountry(null);
      setState(null);
      setCity(null);
      clearSuggestions();
      // Set error to fields
      form.setFields([
        {
          name: COUNTRY_TEXT,
          errors: [''],
        },
        {
          name: STATE_TEXT,
          errors: [''],
        },
        {
          name: CITY_TEXT,
          errors: [''],
        },
      ]);
    }
  };

  useEffect(() => {
    if (isForBilling && isForBillingEditMode)
      form.setFieldsValue({ country, state, city, zipcode: zip });
    else form.setFieldsValue({ country, state, city });

    // eslint-disable-next-line
  }, [country, state, city, zip]);

  const handleCountryChange = (country) => {
    setCountry(country);
  };

  const getSingleListItemName = (name) => {
    return name;
  };

  const getMultipleListItemName = (name) => {
    return [field.name, name];
  };

  const isDynamicEntryList = () => {
    return list === true;
  };

  useEffect(() => {
    getNationalityData().then((res) => setNationalityList(res));
  }, []);

  return (
    <Row gutter={[25, 5]} className={className}>
      {isForBilling ? (
        <>
          <Col xs={24} lg={6}>
            <StyledProfileInfoHeader>
              <SmallHeading>
                Address Name <MandatoryStar />
              </SmallHeading>
            </StyledProfileInfoHeader>
            <TextBox
              name={
                isDynamicEntryList()
                  ? getMultipleListItemName(ADDRESS_NAME)
                  : getSingleListItemName(ADDRESS_NAME)
              }
              placeholder="Name"
              rule="text"
              field={field}
              disabled={disabled}
            />
          </Col>
          <Col xs={24} lg={6}>
            <StyledProfileInfoHeader>
              <SmallHeading>
                Address line <MandatoryStar />
              </SmallHeading>
            </StyledProfileInfoHeader>
            <TextBox
              name={
                isDynamicEntryList()
                  ? getMultipleListItemName(ADDRESS_LINE_ONE)
                  : getSingleListItemName(ADDRESS_LINE_ONE)
              }
              placeholder="Address"
              rule="text"
              field={field}
              disabled={disabled}
            />
          </Col>
          <Col xs={24} lg={6}>
            <StyledProfileInfoHeader>
              <SmallHeading>
                Address line 1 <MandatoryStar />
              </SmallHeading>
            </StyledProfileInfoHeader>
            <TextBox
              name={
                isDynamicEntryList()
                  ? getMultipleListItemName(ADDRESS_LINE_TWO)
                  : getSingleListItemName(ADDRESS_LINE_TWO)
              }
              placeholder="Address"
              rule="text"
              field={field}
              disabled={disabled}
            />
          </Col>
        </>
      ) : null}
      {/* showExtraFields ? (
        <Col xs={24} lg={7}>
          <StyledProfileInfoHeader>
            <SmallHeading>
              Street Address <MandatoryStar />
            </SmallHeading>
          </StyledProfileInfoHeader>
          <TextBox
            name={
              isDynamicEntryList()
                ? getMultipleListItemName(ADDRESS_TEXT)
                : getSingleListItemName(ADDRESS_TEXT)
            }
            placeholder="Address"
            rule="text"
            field={field}
            disabled={disabled}
            maxLength={100}
          />
        </Col>
          ) : null */}
      <Col xs={24} md={!isCreateEvent ? (isCandidateEdit ? 8 : 6) : 7}>
        <StyledProfileInfoHeader id={`${idBase}_zip`}>
          <StyledLabel>
            Zipcode <MandatoryStar />
          </StyledLabel>
        </StyledProfileInfoHeader>
        <AddressAutoCompleteBox
          name={
            isDynamicEntryList()
              ? getMultipleListItemName(ZIPCODE_TEXT)
              : getSingleListItemName(ZIPCODE_TEXT)
          }
          placeholder="Zipcode"
          value={zip}
          onError={onError}
          handleChange={handleChangeZip}
          handleAutoFill={handleAutoFill}
          field={field}
          disabled={disabled}
          zipValidator={zipValidator}
          id={`${idBase}_zip_input`}
        />
      </Col>
      <StyledCountryCol
        xs={24}
        md={!isCreateEvent ? (isCandidateEdit ? 8 : 6) : 7}
      >
        <StyledProfileInfoHeader id={`${idBase}_country`}>
          <StyledLabel>
            Country <MandatoryStar />
          </StyledLabel>
        </StyledProfileInfoHeader>
        <Dropdown
          required
          name={
            isDynamicEntryList()
              ? getMultipleListItemName(COUNTRY_TEXT)
              : getSingleListItemName(COUNTRY_TEXT)
          }
          placeholder="Country"
          options={countryData}
          dropdownMatchSelectWidth={false}
          showSearch
          handleChange={handleCountryChange}
          field={field}
          showArrow
          disabled={disabled}
          id={`${idBase}_country_input`}
        />
      </StyledCountryCol>
      {candidateAddressProfileEdit && (
        <Col xs={24} lg={flex === 'no-wrap' ? 24 : isCandidateEdit ? 8 : 6}>
          <StyledProfileInfoHeader id={`${idBase}_nationality`}>
            <SmallHeading>
              Nationality <MandatoryStar />
            </SmallHeading>
          </StyledProfileInfoHeader>
          <Dropdown
            name="nationality"
            placeholder="Nationality"
            className="fl"
            style={{ width: '30%' }}
            options={nationalityList}
            required
            showArrow
            showSearch
            id={`${idBase}_nationality_input`}
          />
        </Col>
      )}
      {showExtraFields ? (
        <>
          {showExtraFieldsWithState ? (
            <Col xs={24} lg={4}>
              <StyledProfileInfoHeader>
                <SmallHeading>
                  State <MandatoryStar />
                </SmallHeading>
              </StyledProfileInfoHeader>
              <Dropdown
                required
                name={
                  isDynamicEntryList()
                    ? getMultipleListItemName(STATE_TEXT)
                    : getSingleListItemName(STATE_TEXT)
                }
                placeholder="State"
                options={statesList}
                dropdownMatchSelectWidth={false}
                showSearch
                field={field}
                showArrow
                disabled={disabled}
              />
            </Col>
          ) : null}
          <Col xs={24} lg={4}>
            <StyledProfileInfoHeader id={`${idBase}_city`}>
              <SmallHeading>
                City <MandatoryStar />
              </SmallHeading>
            </StyledProfileInfoHeader>
            <TextBox
              name={
                isDynamicEntryList()
                  ? getMultipleListItemName(CITY_TEXT)
                  : getSingleListItemName(CITY_TEXT)
              }
              placeholder="City"
              field={field}
              disabled={disabled}
              maxLength={50}
              rule="text"
              id={`${idBase}_city_input`}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};

const StyledProfileInfoHeader = styled.p`
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${MARGIN(0, 0, 10, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[13]};
    ${MARGIN(0, 0, 5, 0)}
  }
`;

const StyledCountryCol = styled(Col)`
  ${MARGIN(0, 0, 16, 0)}
`;

const StyledLabel = styled.div`
  font-size: 14px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }
`;

export default AddressAutoComplete;
