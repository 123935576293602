import React from 'react';
import styled from 'styled-components';
import { Dropdown } from '../shared-components/Form/Form';
import Label from '../shared-components/HiddenLable';
import { COLORS } from '../../styles/constants/colors';
import { mediumHeadingMixin } from '../../styles/constants/mixins';

const EditCurrencySetting = ({ currenciesData, color }) => {
  return (
    <div>
      <HeadingLevelThree color={color}>Currency Setting</HeadingLevelThree>
      <div>
        <Label isHidden id="paymentCurrencyId" />
        <Dropdown
          id="paymentCurrencyId"
          name="paymentCurrencyId"
          placeholder="Currency Setting"
          options={currenciesData}
          dropdownMatchSelectWidth={false}
          required
          showArrow
          showSearch
        />
      </div>
    </div>
  );
};

export default EditCurrencySetting;

const HeadingLevelThree = styled.h3`
  color: ${({ color }) => color || COLORS.CHINESE_BLACK};
  padding-bottom: 24px;
  font-weight: 600;
  ${mediumHeadingMixin}
`;
