import get from 'lodash/get';
import { clearStorage } from '../utils/storage';
import { Routes } from '../constants/routes';
import Notifcation from '../components/Notification/Notification';
import {
  getErrorMessage,
  ERROR_UNAUTHORISED,
  BAD_REQUEST,
  RATE_LIMIT_EXCEED,
  RateLimitErrorResetTime,
  SERVER_DOWN,
} from '../constants';

export const setRateLimitExceededStateInStorage = () => {
  const now = new Date();
  const validValue = {
    expiry: now.getTime() + RateLimitErrorResetTime,
    value: true,
  };
  localStorage.setItem(
    'hasEncouteredTooManyRequestsError',
    JSON.stringify(validValue),
  );
  Notifcation(
    'info',
    'Access',
    `Access blocked for ${RateLimitErrorResetTime / (60 * 1000)} min.`,
  );
};

export const handleRateLimitError = () => {
  Notifcation('info', 'Access', `Rate limit exceeded. Try again after 5 min.`);
};

export const handleErrors = (response) => {
  const { statusCode, message } = get(response, 'data', {
    statusCode: SERVER_DOWN,
    message: '',
  });

  const errorMessage = getErrorMessage(message);

  switch (statusCode) {
    case ERROR_UNAUTHORISED:
      {
        // Log out the user
        clearStorage();
        const location = get(window, 'location.pathname', '');

        if (!(location.search(/\/login/g) >= 0)) {
          window.location.replace(`${Routes.login}`);
        }
      }
      break;
    case BAD_REQUEST:
      Notifcation('error', 'Request failed', message);
      break;
    case RATE_LIMIT_EXCEED:
      Notifcation('error', 'Request failed', errorMessage);
      setRateLimitExceededStateInStorage();
      break;
    case SERVER_DOWN:
      break;
    default:
      Notifcation('error', 'Request failed', errorMessage);
  }
};
