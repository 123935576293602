import React from 'react';
import { Col } from 'antd';
import Notifications from './Notifications';
import { colPropsEarningSection } from '../../constants/layouts/column';
import styled from 'styled-components';
import { checkIfUserIsCandidate, getRoleId } from '../../utils/userInfo';

const DashboardEarningsNotifications = () => {
  const roleId = getRoleId();
  return (
    <StyledCol
      {...colPropsEarningSection}
      className="par_earn_notify pl30 grid_xxl_6 dashboard-earnings-notifications__mobile"
      xl={6}
      candidate={checkIfUserIsCandidate(roleId)}
    >
      {/*checkIfUserIsOrganizer(getRoleId()) ? <Earnings /> : null
       */}

      <Notifications />
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  margin-bottom: ${({ candidate }) => (candidate ? '0' : '20px')};
`;

export default DashboardEarningsNotifications;
