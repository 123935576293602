import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { selectEventFirst } from '../../../utils/notifications';
import EventAnalyticCompany from '../../../components/ManagementHeader/EventAnalyticsCompany';
import ManagementTable from './ManagementTable';
import EventAnalytics from '../../../components/ManagementHeader/EventAnalytics';
import {
  eventCompanyColumns,
  transformEventRecruiters,
} from '../../../utils/event';
import { updateEventRecruiterAttendanceData } from '../../../store/features/events';
import { getEventRecruiter } from '../../../service/organizer';
import {
  ATTENDANCE_PRESENT,
  ATTENDEES_MANAGEMENT_ROLES,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from '../../../constants';
import { isTestModeOn } from '../../../utils/test-helper';
import { getUrlVars } from '../../../utils/common';

const { company } = ATTENDEES_MANAGEMENT_ROLES;

const CompanyManagement = ({
  currentEventId,
  tabEventInfo,
  updateTableData,
  events = [],
  populateEvents,
  adminTheme = null,
  setTabEventInfo,
  setAttendeesFilter,
  currentFilter,
  getPagination,
  selectedPage,
  selectedLimit,
  handleCurrentSorter,
  currentSorter,
}) => {
  const history = useHistory();
  const { filter } = getUrlVars(get(history, 'location.search', ''));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [
    eventRecruiterPagination,
    setEventRecruiterPagination,
  ] = React.useState(null);
  const eventId = get(tabEventInfo, `${company}.event.id`, null);
  const [sorter, setSorter] = React.useState(null);
  const [attendance, setAttendance] = useState({});

  const fetchEventCompanyData = async (page, limit, filter, sorter = null) => {
    if (currentEventId) {
      const result = await getEventRecruiter(
        currentEventId,
        selectedPage,
        selectedLimit,
        currentFilter,
        currentSorter,
      );
      const recruiters = get(result, 'recruiters', []);
      const currentPage = get(result, 'currentPage', DEFAULT_PAGE);
      const currentLimit = get(result, 'currentLimit', DEFAULT_LIMIT);
      const totalItems = get(result, 'totalItems', DEFAULT_LIMIT);

      const attendenceObject = recruiters.reduce((curr, recruiter) => {
        curr[recruiter.id] =
          get(recruiter, 'attendance', ATTENDANCE_PRESENT) ===
          ATTENDANCE_PRESENT;
        return curr;
      }, {});
      setAttendance(attendenceObject);

      if (recruiters) {
        updateTableData(company, recruiters);
      }
      if (currentPage || currentLimit) {
        setEventRecruiterPagination({ currentPage, currentLimit, totalItems });
      }
    }
  };

  React.useEffect(() => {
    // Set setEventRecruiterPagination to 10 if it's testing mode
    if (isTestModeOn()) {
      setEventRecruiterPagination(10);
    }
  }, []);

  const handleChange = (id, body) => {
    dispatch(
      updateEventRecruiterAttendanceData([id], body, currentEventId),
    ).then(() => {
      setAttendance((prev) => {
        const tempAttendence = { ...prev };
        tempAttendence[id] = !tempAttendence[id];
        return tempAttendence;
      });
    });
    // sending in default page = 1 and limit = 10 if event is changed
  };

  const event = events.find((event) => event.id === currentEventId);
  const handleReload = () => {
    selectEventFirst(currentEventId);
    setLoading(true);
    updateTableData(company, []);
    fetchEventCompanyData(DEFAULT_PAGE, DEFAULT_LIMIT).then(() => {
      setLoading(false);
    });
  };

  const getCompanyTableData = (tabInfo) => {
    return get(tabInfo, `${company}.tableData`, []);
  };

  return (
    <div>
      <div>
        <EventAnalytics
          EventAnalyticsItemComponent={EventAnalyticCompany}
          analytics={tabEventInfo[company].analytics}
        />
      </div>
      <ManagementTable
        type={company}
        columns={eventCompanyColumns}
        data={transformEventRecruiters(
          getCompanyTableData(tabEventInfo),
          event,
          handleChange,
          attendance,
          selectedPage,
          selectedLimit,
        )}
        tableDataDependency={getCompanyTableData(tabEventInfo)}
        event={event}
        placeholder="Companies participating in your events can be managed here."
        fetchData={fetchEventCompanyData}
        pagination={eventRecruiterPagination}
        handleReload={handleReload}
        loading={loading}
        currentEventId={currentEventId}
        eventId={eventId}
        populateEvents={populateEvents}
        sorter={sorter}
        setSorter={setSorter}
        adminTheme={adminTheme}
        setTabEventInfo={setTabEventInfo}
        setAttendance={setAttendance}
        setAttendeesFilter={setAttendeesFilter}
        currentFilter={parseInt(filter)}
        getPagination={getPagination}
        selectedLimit={selectedLimit}
        selectedPage={selectedPage}
        handleCurrentSorter={handleCurrentSorter}
      />
    </div>
  );
};

export default CompanyManagement;
