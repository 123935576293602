import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

const { confirm } = Modal;

export const ConfirmWorkAuthorization = (country, handleYes, handleNo) => {
  confirm({
    title: `Are you authorized to work in ${country}?`,
    icon: <ExclamationCircleOutlined />,
    onOk: handleYes,
    onCancel: handleNo,
    okText: 'Yes',
    cancelText: 'No',
  });
};
