/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Switch } from 'antd';
import moment from 'moment';
import get from 'lodash/get';
import { UploadOutlined } from '@ant-design/icons';
import lowerCase from 'lodash/lowerCase';
import MandatoryStar from '../../Common/mandatoryStar';
import {
  TextBox,
  Dropdown,
  DateTimePicker,
} from '../../shared-components/Form/Form';
import ProfilesLinksList from '../../ProfileLinks/ProfileLinksList';
import { eventTypes } from '../../../mocks/organizer/events/create-event';
import {
  ACCEPTED_IMAGE_EXTENSIONS,
  ACCEPTED_EVENT_COVER_IMAGE,
  EVENT_IMAGE_COVER_PAYLOAD,
  EVENT_COVER_IMAGE_HINT,
  ACCEPTED_POAP_EXTENSIONS,
  ACCEPTED_EVENT_POAP_IMAGE,
  EVENT_IMAGE_POAP_PAYLOAD,
} from '../../../constants/file';
import { currentDate } from '../../../utils/formatDateTime';
import AddressAutoComplete from '../../Common/AddressAutoComplete';
import {
  getDomainsData,
  getQuerySkillsData,
  imageValidator,
} from '../../../utils/common';
import Notification from '../../Notification/Notification';
import {
  CROP_POFILE_PIC_OPTIONS,
  EVENT_CROP_COVER_OPTIONS,
} from '../../../constants/index';
import QuillEditor from '../../Common/QuillEditor';
import { getRoleId } from '../../../utils/userInfo';
import {
  PictureUploadWithList,
  TextUpload,
  ToolTippedInfo,
} from '../../shared-components/Upload';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN } from '../../../styles/constants/display';
import CategoryDropdown from '../../Common/CategoryDropdown';
import {
  getUserBackgroundColorWithRoleId,
  getUserColorWithRoleId,
} from '../../../styles/utils';
import { SHADOWS } from '../../../styles/constants/shadows';
import Schedule from '../../../components/Events/EventForm/Schedule';
import TermsAndPolicy from '../../../components/Events/EventForm/TermsAndPolicy';
import { privateEventText } from '../../../constants/texts/organizer';

const { Title } = Typography;

const BasicInformation = ({
  form,
  uploadEventFile,
  handleFileChange,
  handleSelectType,
  description,
  handleDescription,
  descriptionCount,
  coverFile,
  event = null,
  isForTest,
  setBookingMethod,
  categories,
  handleSelectCategories,
  handleSelectDomains,
  isTabActive,
  errorFields,
  setIsWeb3Event,
  isWeb3Event,
  poapImage,
  setIsPrivate,
  isPrivate,
  coverError,
  documentError,
  descriptionError,
  documentFileList,
  handleRemoveDocument,
  customInput,
  companyInfo,
  daysSchedule,
  setDaysSchedule,
  days,
  setDays,
  isEdit,
}) => {
  const [industryTypes, setIndustryTypes] = React.useState([]);
  const [querySkills, setQuerySkills] = React.useState([]);
  const [eventType, setEventType] = useState(
    event && event.eventType === 2 ? 'online' : 'hybrid',
  );

  const roleId = getRoleId();
  const color = getUserColorWithRoleId(roleId);
  const screenWidth = get(window, 'screen.width', null);
  const mobileScreen = +BREAKPOINTS.MOBILE_SPECIAL_CASE_9.slice(0, -2);
  const mobileScreenSmall = +BREAKPOINTS.MOBILE_SPECIAL_CASE_10.slice(0, -2);
  const tabletScreenSmall = +BREAKPOINTS.TABLET_SPECIAL_CASE_5.slice(0, -2);
  const tabletScreenMid = +BREAKPOINTS.TABLET_SPECIAL_CASE_6.slice(0, -2);
  const tabletScreenLarge = +BREAKPOINTS.TABLET_SPECIAL_CASE_7.slice(0, -2);
  const tabletScreenExtraLarge = +BREAKPOINTS.TABLET_SPECIAL_CASE_3.slice(
    0,
    -2,
  );
  const laptopScreenSmall = +BREAKPOINTS.LAPTOP_MIN.slice(0, -2);
  const laptopScreenMedium = +BREAKPOINTS.LAPTOP_SPECIAL_CASE_3.slice(0, -2);
  const laptopScreenLarge = +BREAKPOINTS.LAPTOP_SPECIAL_CASE_2.slice(0, -2);
  const laptopScreen = +BREAKPOINTS.LAPTOP_SPECIAL_CASE_6.slice(0, -2);
  const desktopScreen = +BREAKPOINTS.DESKTOP_SPECIAL_CASE_4.slice(0, -2);
  const desktopScreenLarge = +BREAKPOINTS.DESKTOP_SPECIAL_CASE_5.slice(0, -2);

  React.useEffect(() => {
    getDomainsData().then((domains) => setIndustryTypes(domains));
  }, []);

  const handleEventTypeChange = (value) => {
    setEventType(value);
  };

  const handleCoverBeforeUpload = (file) => {
    if (file.size > ACCEPTED_EVENT_COVER_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...EVENT_IMAGE_COVER_PAYLOAD, file });
  };

  const handlePoapBeforeUpload = (file) => {
    if (file.size > ACCEPTED_EVENT_POAP_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...EVENT_IMAGE_POAP_PAYLOAD, file });
  };

  const handleQueryTagSkills = async (words) => {
    if (words.length > 1) {
      const skills = await getQuerySkillsData(words);
      // Set state only if there's result
      if (skills.length) {
        setQuerySkills(skills);
      }
    }
  };

  return (
    <StyledPosition>
      {/* comapny-info-sec */}
      <StyledDiv>
        <Row gutter={[25, 10]}>
          <Col xs={24} md={7}>
            <label htmlFor="event_name">
              <StyledInfoHeader id="event_name_label">
                Event Name <MandatoryStar />
              </StyledInfoHeader>
            </label>
            <TextBox
              rule="text"
              name="nameValue"
              placeholder="Enter Event Name"
              required
              id="event_name"
            />
          </Col>
          <Col xs={24} md={5} lg={4}>
            <label htmlFor="event_type">
              <StyledInfoHeader id="event_type_label">
                Type of Event <MandatoryStar />
              </StyledInfoHeader>
            </label>
            <Dropdown
              name="eventType"
              placeholder="Select event type"
              options={eventTypes}
              showArrow
              handleChange={handleEventTypeChange}
              required
              id="event_type"
            />
          </Col>
          <Col xs={24} md={8}>
            <label htmlFor="date_time">
              <StyledInfoHeader id="date_time_label">
                Select Date & Time <MandatoryStar />
              </StyledInfoHeader>
            </label>
            <DateTimePicker
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment(), moment().endOf('month')],
              }}
              disabledDate={(d) => d.isSameOrBefore(currentDate(true, 'lll'))}
              name="dateTime"
              showTime
              format="DD/MM/YYYY HH:mm"
              required
              id="date_time"
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Col>
          <Col xs={24} sm={3} md={3} lg={3} xl={3}>
            <label htmlFor="event_web3">
              <StyledInfoHeader id="event_web3_label">
                Private Event?
              </StyledInfoHeader>
            </label>
            <ToolTippedInfo
              backgroundColor={color}
              hintText={privateEventText}
              defaultHintText={privateEventText}
              position="absolute"
              top="2px"
              left={
                (screenWidth <= mobileScreenSmall && '120px') ||
                (screenWidth <= mobileScreen && '70px')
              }
              right={
                (screenWidth >= desktopScreenLarge && '35px') ||
                (screenWidth >= desktopScreen && '5px') ||
                (screenWidth >= laptopScreen && '0px') ||
                (screenWidth >= laptopScreenLarge && '-10px') ||
                (screenWidth >= laptopScreenMedium && '-15px') ||
                (screenWidth >= laptopScreenSmall && '20px') ||
                (screenWidth >= tabletScreenExtraLarge && '-5px') ||
                (screenWidth >= tabletScreenLarge && '-15px') ||
                (screenWidth >= tabletScreenMid && '20px') ||
                (screenWidth >= tabletScreenSmall && '10px') ||
                (screenWidth > mobileScreen && '0px')
              }
            />
            <StyledSwitch
              onChange={() => setIsPrivate(!isPrivate)}
              checked={isPrivate}
              name="isPrivate"
            />
          </Col>
        </Row>
      </StyledDiv>

      <Row gutter={[25, 10]}>
        <Col xs={24} md={8} lg={7}>
          <label htmlFor="industry">
            <StyledInfoHeader id="industry_label">
              Industry <MandatoryStar />
            </StyledInfoHeader>
          </label>
          <Dropdown
            name="domains"
            placeholder="Select Domains"
            options={industryTypes}
            mode="multiple"
            showArrow
            showSearch
            handleChange={(options) => {
              // Optional function prop
              if (handleSelectType) {
                handleSelectType(options, 'domains');
              }
              handleSelectDomains(options);
            }}
            required
            id="industry"
          />
        </Col>
        <Col xs={24} md={8} lg={7}>
          <label htmlFor="category">
            <StyledInfoHeader id="category_label">
              Category <MandatoryStar />
            </StyledInfoHeader>
          </label>
          <CategoryDropdown
            name="categories"
            value={categories}
            handleChange={handleSelectCategories}
          />
        </Col>
        <Col xs={24} md={8} lg={7}>
          <label htmlFor="tag">
            <StyledInfoHeader id="tag_label">
              Tags <MandatoryStar />
            </StyledInfoHeader>
          </label>
          <StyledDropdownWrapper
            color={color}
            backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
          >
            <Dropdown
              name="tags"
              mode="tags"
              showSearch
              placeholder="Tags (eg: cyber security, web development)"
              style={{ width: '100%' }}
              handleChange={(options) => {
                // Optional function prop
                if (handleSelectType) {
                  handleSelectType(options, 'tags');
                }
              }}
              onSearch={(value) => {
                handleQueryTagSkills(value);
              }}
              options={querySkills}
              required
              id="tag"
            />
          </StyledDropdownWrapper>
        </Col>
        <Col xs={24} md={7}>
          <TermsAndPolicy
            handleFileChange={handleFileChange}
            uploadEventFile={uploadEventFile}
            handleRemove={handleRemoveDocument}
            fileList={documentFileList}
            isTabActive={isTabActive}
            errorFields={errorFields}
            documentError={documentError}
          />
        </Col>
        <Col xs={24} md={8}>
          {/* upload official */}
          <StyledInfoHeader id="event_upload_cover">
            Upload Event Cover <MandatoryStar />
          </StyledInfoHeader>
          <StyledUploadWrapper>
            <PictureUploadWithList
              name="cover"
              text="Upload Cover"
              icon={<UploadOutlined />}
              customRequest={(data) => uploadEventFile(data, 'cover')}
              onChange={(data) => handleFileChange(data, 'cover')}
              accept={ACCEPTED_IMAGE_EXTENSIONS}
              maxCount={1}
              beforeUpload={handleCoverBeforeUpload}
              fileList={coverFile}
              cropOptions={EVENT_CROP_COVER_OPTIONS}
              cropActive
              placement="bottomLeft"
              roleId={roleId}
              hintText={EVENT_COVER_IMAGE_HINT}
              id="event_upload_cover_input"
              error={coverError}
            />
          </StyledUploadWrapper>
        </Col>
        {/* Web 3 Switch */}
        {/* <Col xs={24} md={4}>
            <label htmlFor="event_web3">
              <StyledInfoHeader id="event_web3_label">
                Web 3.0 Event?
              </StyledInfoHeader>
            </label>
            <Switch
              onChange={() => setIsWeb3Event(!isWeb3Event)}
              checked={isWeb3Event}
              name="isWeb3Event"
            />
          </Col> */}
      </Row>

      {/* comapny address */}
      <div>
        {/* <button className="map_btn large-button">Maps</button> */}

        {/* Note: this component is being skipped for testing as it's recommeded to test this separately. It's also getting break when testing. */}
        {isForTest || lowerCase(eventType) === 'online' ? null : (
          <StyledDiv>
            <AddressAutoComplete form={form} isCreateEvent />
          </StyledDiv>
        )}

        {/* about event */}
        <StyledRow>
          <Col xs={14}>
            <label htmlFor="about_event">
              <StyledDescriptionText id="about_event_label">
                Description <MandatoryStar />
              </StyledDescriptionText>
            </label>
          </Col>
          <Col xs={10}>
            <StyledCharacterCountText>
              Characters {descriptionCount}/1000
            </StyledCharacterCountText>
          </Col>
        </StyledRow>
        <Row>
          <Col xs={24} md={24}>
            {/* Using React quill instead of InputTextArea
            For now, we are skipping this component in testing aspect. */}
            {!isForTest ? (
              <StyledQuillEditor
                id="about_event"
                name="description"
                placeholder="Enter Description of the Event"
                value={description}
                maxLength={1000}
                handleChange={handleDescription}
                error={descriptionError}
              />
            ) : null}
          </Col>
        </Row>

        {/* upload official */}
        <StyledTitle level={5} id="event_upload_cover">
          Proof of Attendance Protocol{' '}
          <span>
            <a
              href="https://poap.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              (POAP)
            </a>
          </span>{' '}
        </StyledTitle>
        <StyledUploadLogoWrapper>
          <StyledInnerLogoWrapper>
            <img
              src={get(event, 'meta.poap.imageUrl')}
              id="poap-image"
              alt=""
            />
            <TextUpload
              style={{ backgroundColor: 'transparent' }}
              name="changePoapLogo"
              text={
                poapImage || get(event, 'meta.poap.id')
                  ? 'Change Poap Artwork'
                  : 'Add Poap Artwork'
              }
              customRequestHandler={(data) => uploadEventFile(data, 'poap')}
              onChange={(data) => handleFileChange(data, 'poap')}
              accept={ACCEPTED_POAP_EXTENSIONS}
              hintText="The POAP image for the event. PNG images under 4 MB are supported. This is one time upload and cannot be updated later"
              roleId={roleId}
              beforeUpload={handlePoapBeforeUpload}
              maxCount={1}
              cropActive
              cropOptions={CROP_POFILE_PIC_OPTIONS}
              placement="bottomLeft"
              id="add_poap_logo"
              uploadButtonVisible={!get(event, 'meta.poap.id')}
              top="4px"
            />
          </StyledInnerLogoWrapper>
        </StyledUploadLogoWrapper>
      </div>

      {/* add link */}
      <StyledProfileLinkWrapper>
        <ProfilesLinksList profileLinkText="Event Profile Link" form={form} />
      </StyledProfileLinkWrapper>

      <Schedule
        customInput={customInput}
        companyInfo={companyInfo}
        isTabActive={isTabActive}
        errorFields={errorFields}
        form={form}
        daysSchedule={daysSchedule}
        setDaysSchedule={setDaysSchedule}
        days={days}
        setDays={setDays}
        isEdit={isEdit}
      />
    </StyledPosition>
  );
};

export default BasicInformation;

export const StyledTitle = styled(Title)`
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  span {
    a {
      color: ${COLORS.primaryColor};
      cursor: pointer;
    }
  }
`;

export const StyledInfoHeader = styled.span`
  display: block;
  ${MARGIN(0, 0, 10, 0)};
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledDescriptionText = styled.span`
  display: block;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledCharacterCountText = styled.span`
  display: block;
  text-align: right;
  color: ${COLORS.PHILLIPPINE_GRAY};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledQuillEditor = styled(QuillEditor)`
  ${MARGIN(20, 0, 60, 0)};
  width: 100%;
  height: 200px;
  > .ql-container {
    border: ${(props) => (props.error ? '1px solid #ff4d4f' : null)};
  }
  > .ql-toolbar.ql-snow {
    border: ${(props) => (props.error ? '1px solid #ff4d4f' : null)};
  }
`;

const StyledUploadWrapper = styled.div`
  button {
    height: 42px;
  }
`;

const StyledProfileLinkWrapper = styled.div`
  h3 {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 50px;
  }
`;

const StyledDropdownWrapper = styled.span`
  span.ant-select-selection-item {
    color: ${(props) => props.color};
    background: ${(props) => props.backgroundColor};

    span.ant-select-selection-item-remove {
      color: ${(props) => props.color};
    }
  }
`;

const StyledUploadLogoWrapper = styled.div`
  margin-top: 20px;

  button {
    height: 40px;
  }
`;

const StyledInnerLogoWrapper = styled.div`
  text-align: center;
  max-width: 160px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 0 auto;
  }

  span.ant-avatar,
  img {
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
    border-radius: 100%;
    box-shadow: ${SHADOWS.SHADOW2};
    background: #ccc;
  }
`;

const StyledSwitch = styled(Switch)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 20px;
  }
`;

const StyledRow = styled(Row)`
  ${MARGIN(16, 0, 0, 0)}
`;
const StyledDiv = styled.div`
  ${MARGIN(16, 0, 0, 0)}
`;
const StyledPosition = styled.div`
  position: relative;
`;
