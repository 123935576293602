/* eslint-disable react/no-danger */
import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import MainContainer from '../Common/MainContainer';
import { COLORS } from '../../styles/constants/colors';
import { largeFontMixin } from '../../styles/constants/mixins';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import Learn from '../Common/icons/Learn';
import Star from '../Common/icons/Star';
import Events from './Events';
import About from './About';
import JobsSection from './Jobs';
import { BriefCaseIcon } from '../svgicons';
import { RightOutlined } from '@ant-design/icons';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetCommunityPageQuery } from '../../store/features/rtkApi/common';

const Overview = ({ changeTab }: { changeTab: (key: number) => void }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const { data: communityPageData, isLoading } = useGetCommunityPageQuery(
    companyId,
  );
  if (isLoading) {
    return <Skeleton />;
  }

  const brief = get(communityPageData, 'company.company.brief', '');
  return (
    <StyledSection>
      <MainContainer>
        <div>
          {/* About Us section */}
          <StyleContentContainer>
            <StyledHeaderContainer>
              <StyledSubHeaderIconContainer color={COLORS.primaryColor}>
                <Learn color={COLORS.primaryColor} />
              </StyledSubHeaderIconContainer>
              <StyledLevelTwoHeading
                color={COLORS.CHINESE_BLACK}
                borderColor={COLORS.primaryColor}
              >
                <span>About</span> Us
              </StyledLevelTwoHeading>
            </StyledHeaderContainer>
            <About brief={brief} letterCount={100} />
          </StyleContentContainer>

          {/* Jobs section */}
          <StyleInnerContainer>
            <div>
              <StyledHeaderContainer>
                <StyledSubHeaderIconContainer color={COLORS.primaryColor}>
                  <BriefCaseIcon />
                </StyledSubHeaderIconContainer>
                <StyledLevelTwoHeading
                  color={COLORS.CHINESE_BLACK}
                  borderColor={COLORS.primaryColor}
                >
                  <span>App</span>ly2Earn
                </StyledLevelTwoHeading>
                <StyledViewAll onClick={() => changeTab(2)}>
                  <p>View All</p> <StyledRightOutlined />
                </StyledViewAll>
              </StyledHeaderContainer>
            </div>
            <div>
              <div>
                <JobsSection companyId={companyId} changeTab={changeTab} />
              </div>
            </div>
          </StyleInnerContainer>

          {/* Events cards */}
          <StyleInnerContainer>
            <div>
              <StyledHeaderContainer>
                <StyledSubHeaderIconContainer color={COLORS.primaryColor}>
                  <Star />
                </StyledSubHeaderIconContainer>
                <StyledLevelTwoHeading
                  color={COLORS.CHINESE_BLACK}
                  borderColor={COLORS.primaryColor}
                >
                  <span>Our</span> Events
                </StyledLevelTwoHeading>
                <StyledViewAll onClick={() => changeTab(3)}>
                  <p>View All</p> <StyledRightOutlined />
                </StyledViewAll>
              </StyledHeaderContainer>
            </div>
            <div>
              <div>
                <Events section />
              </div>
            </div>
          </StyleInnerContainer>
        </div>
      </MainContainer>
    </StyledSection>
  );
};

export default Overview;

const StyledSection = styled.section`
  ${PADDING(0, 0, 25)}
  ${largeFontMixin};
  overflow: hidden !important;

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    ${MARGIN(0)}
  }
`;

const StyleInnerContainer = styled.div`
  ${MARGIN(50, 0, 0, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(40, 0)}
  }
`;

export const StyledSubHeaderIconContainer = styled.span`
  background-color: ${({ color }) => color || COLORS.SUNSHINE_SUPRISE};
  width: 38px;
  height: 38px;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  border-radius: 4px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 30px;
    height: 30px;
  }

  svg {
    width: 24px;
    height: 24px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledHeaderContainer = styled.div`
  ${DISPLAY_FLEX()}
  column-gap: 20px;
  ${MARGIN(null, null, 25, null)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    column-gap: 10px;
  }
`;

const StyledViewAll = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  margin-left: auto;
  cursor: pointer;

  p {
    font-weight: ${FONT_WEIGHTS['MEDIUM']};
  }
`;

const StyledRightOutlined = styled(RightOutlined)`
  svg {
    font-size: 12px;
    ${MARGIN(0, 0, 0, 5)}
  }
`;

const StyledLevelTwoHeading = styled.h2<{ color: string; borderColor: string }>`
  font-size: 22px;
  font-weight: 600;
  line-height: 0.88;
  color: ${({ color }) => color};
  align-self: flex-end;
  ${PADDING(null, null, 5, null)}

  span {
    font-size: 22px;
    border-bottom: 2px solid ${({ borderColor }) => borderColor};
    ${PADDING(null, null, 5, null)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;

    span {
      font-size: 14px;
    }
  }

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) AND (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    font-size: 20px;

    span {
      font-size: 20px;
    }
  }
`;

const StyleContentContainer = styled.div`
  ${MARGIN(20, 0, 50, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(40, 0)}
  }
`;
