import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import { getRoleId, getUserClassWithRoleId } from '../../../../utils/userInfo';
import '../../../../styles/components/events/event-analytics-and-report.scss';
import { ToolTipsText } from '../../../../constants/tooltips';
import { PrimaryButton } from '../../../shared-components/Button';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import { FONT } from '../../../../styles/constants/typography';
import { claimPoapByEvent } from '../../../../service/poap';
import { getEventByIdDataWithoutReload } from '../../../../store/features/events';
import Notification from '../../../Notification/Notification';
import { getPastEvent } from '../../../../utils/event';

export const ClaimPoapButton = ({ event }) => {
  const dispatch = useDispatch();
  const roleId = getRoleId();

  const canClaimNFT =
    event &&
    getPastEvent(event) &&
    get(event, 'meta.poap.deliveryId', null) &&
    !get(event, 'ticket.meta.isPoapNFTClaimed', false);

  const handleClaimNFT = async () => {
    if (canClaimNFT) {
      await claimPoapByEvent(event.id);
      dispatch(getEventByIdDataWithoutReload(event.id));
      Notification('success', 'NFT Claimed');
    }
  };

  return canClaimNFT ? (
    <PrimaryButton
      onClick={handleClaimNFT}
      theme={getUserClassWithRoleId(roleId)}
      id="claim-nft"
    >
      <StyledTooltip
        className="view-analytics"
        title={ToolTipsText.claimNft}
        svgColor={getUserColorWithRoleId(roleId)}
      >
        <StyledText>Claim NFT</StyledText>
      </StyledTooltip>
    </PrimaryButton>
  ) : null;
};

const StyledText = styled.span`
  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: ${FONT[14]};
  }

  font-size: ${FONT[15]};
`;

const StyledTooltip = styled(Tooltip)`
  font-size: 15px;
  svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    fill: ${COLORS.WHITE};
  }
  &:hover {
    svg {
      fill: ${(props) => props.svgColor};
    }
  }
  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 11px;
    padding: 0;

    svg {
      width: 15px;
      height: 15px;
    }
  } ;
`;
