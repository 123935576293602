import Notification from '../../components/Notification/Notification';
import config from '../../config';
// eslint-disable-next-line import/no-cycle
import { downloadInviterAttendeesCsv } from '../../service/masterAdmin';

export const handleExportCsv = async (
  event,
  role,
  profileFilter = 0,
  registerFilter = 0,
  joinFilter = 0,
  search = '',
  setIsExporting,
) => {
  if (event && event.id) {
    setIsExporting(true);
    await downloadInviterAttendeesCsv(
      event.id,
      role,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    )
      .then(() => {
        setIsExporting(false);
      })
      .catch(() => {
        Notification('error', 'Could not export the data');
        setIsExporting(false);
      });
  }
};

export const getExportFileS3 = (fileName) => {
  return fileName ? `${config.aws_cdn_url}/exports/${fileName}` : null;
};
