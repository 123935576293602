import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import TextArea from 'antd/lib/input/TextArea';
import { COLORS } from '../../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { SHADOWS } from '../../../../styles/constants/shadows';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import {
  ACCEPTED_LOGO_IMAGE,
  ACCEPTED_PNG_IMAGE_EXTENSIONS,
  IMAGE_LOGO_PAYLOAD,
} from '../../../../constants/file';
import { imageValidator } from '../../../../utils/common';
import Notification from '../../../Notification/Notification';
import { getFileS3 } from '../../../../utils/s3.storage';
import { TextUpload } from '../../../shared-components/Upload';
import { DropdownBox, InputBox } from '../../../shared-components/Form/Form';
import { TrashIcon } from '../../../svgicons';
import { getRoleId } from '../../../../utils/userInfo';
import ProfileLinkContainer from './ProfileLinkContainer';

const SponsorsInfo = ({
  type,
  handleEditCompany,
  sponsors,
  uploadEventFile,
  handleFileChange,
  handleDeleteEntity,
}) => {
  const roleId = getRoleId();
  const handleLogoBeforeUpload = (file) => {
    if (file.size > ACCEPTED_LOGO_IMAGE.maxFileSize) {
      // handleRemoveCompanyLogo();
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...IMAGE_LOGO_PAYLOAD, file });
  };

  const entityType = type === 'Sponsors' ? 'sponsors' : 'partners';

  const handleLinkChangeCallBack = (form, linkFrom, index) => {
    const links = (form.getFieldValue(linkFrom) || []).map((item) =>
      get(item, `link`, undefined),
    );
    handleEditCompany(entityType, index, 'links', links);
  };

  return (
    <StyledWrapper>
      {sponsors &&
        sponsors.map((company, index) => {
          return (
            <StyledCompanyWrapper key={company.id}>
              <StyledContainer>
                <StyedRow>
                  <Col xs={24} lg={4}>
                    <StyledUploadLogoWrapper>
                      <StyledInnerLogoWrapper
                        isValidImage={!!getFileS3(company.pic)}
                      >
                        <Avatar
                          src={getFileS3(company.pic)}
                          alt="recruiter-logo"
                        />
                        <TextUpload
                          name="companyLogo"
                          text={getFileS3(company.pic) ? "Upload logo" : "Add Logo"}
                          icon={<UploadOutlined />}
                          customRequestHandler={(data) =>
                            uploadEventFile(
                              data,
                              type === 'Partners' ? 'partner' : 'sponsor',
                              index,
                            )
                          }
                          onChange={(data) =>
                            handleFileChange(
                              data,
                              type === 'Partners' ? 'partner' : 'sponsor',
                              index,
                            )
                          }
                          accept={ACCEPTED_PNG_IMAGE_EXTENSIONS}
                          maxCount={1}
                          roleId={roleId}
                          beforeUpload={handleLogoBeforeUpload}
                          placement="bottomLeft"
                          id="add_company_logo"
                          hintText="Recommended: PNG with 400px X 400px in lesser than 3MB"
                        />
                      </StyledInnerLogoWrapper>
                    </StyledUploadLogoWrapper>
                  </Col>
                  <Col xs={24} lg={6}>
                    <label htmlFor="company_name">
                      <StyledInfoHeader id="company_name_label">
                        Company
                      </StyledInfoHeader>
                    </label>
                    <InputBox
                      name="company"
                      placeholder="Enter Name"
                      value={company.name}
                      handleChange={(e) =>
                        handleEditCompany(
                          entityType,
                          index,
                          'name',
                          e.target.value,
                        )
                      }
                      rule="text"
                      id="company_name"
                    />
                  </Col>
                  <Col xs={24} lg={5}>
                    <label htmlFor="company_name">
                      <StyledInfoHeader id="company_name_label">
                        Type
                      </StyledInfoHeader>
                    </label>
                    <DropdownBox
                      name="type"
                      placeholder="Select Type"
                      options={[
                        { id: 'Platinum', name: 'Platinum' },
                        { id: 'Gold', name: 'Gold' },
                        { id: 'Silver', name: 'Silver' },
                      ]}
                      value={company.type}
                      handleChange={(val) =>
                        handleEditCompany(entityType, index, 'type', val)
                      }
                      rule="text"
                      id="company_name"
                    />
                  </Col>
                  <StyledButtonWrapper>
                    <StyledEditButton
                      tabIndex={0}
                      onClick={() =>
                        handleDeleteEntity(
                          index,
                          type === 'Partners' ? 'partners' : 'sponsors',
                        )
                      }
                    >
                      <TrashIcon />
                    </StyledEditButton>
                  </StyledButtonWrapper>
                </StyedRow>
                <StyedRow>
                  <Col xs={24} lg={4} />
                  <Col xs={24} lg={19}>
                    <label htmlFor="speaker_description">
                      <StyledInfoHeader id="speaker_designation_label">
                        Description
                      </StyledInfoHeader>
                    </label>
                    <TextArea
                      name="description"
                      required={false}
                      placeholder="Description"
                      value={get(company, 'brief', '')}
                      onChange={(e) =>
                        handleEditCompany(
                          entityType,
                          index,
                          'brief',
                          e.target.value,
                        )
                      }
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      maxLength={250}
                      showCount={true}
                      id="speaker_description"
                    />
                  </Col>
                </StyedRow>

                <ProfileLinkContainer
                  type={type}
                  company={company}
                  index={index}
                  handleLinkChangeCallBack={handleLinkChangeCallBack}
                />
                {/* upload official */}
              </StyledContainer>
            </StyledCompanyWrapper>
          );
        })}
    </StyledWrapper>
  );
};

export default SponsorsInfo;

const StyledWrapper = styled.div`
  margin-top: 30px;
`;

const StyledCompanyWrapper = styled.div`
  background-color: ${COLORS.LOTION};
  margin: 2rem 0;
  padding: 20px;
  position: relative;
`;

const StyledInfoHeader = styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    margin-bottom: 8px;
  }
`;

const StyledInnerLogoWrapper = styled.div`
  text-align: center;
  max-width: 140px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 0 auto;
  }

  span.ant-avatar {
    width: 110px;
    height: 110px;
    display: block;
    box-shadow: ${SHADOWS.SHADOW2};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 20px;
`;

const StyledEditButton = styled(Button)`
  ${DISPLAY_FLEX(null, 'center', 'center')};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: ${SHADOWS.SHADOW2};

  svg {
    width: 25px;
    font-size: 25px;
    fill: ${COLORS.ORANGE_RED};
  }
`;

const StyledContainer = styled.div`
  clear: both;
  overflow: hidden;

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-explain {
    display: none;
  }
`;

const StyedRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 20px;
  row-gap: 10px !important;
`;

const StyledUploadLogoWrapper = styled.div`
  margin-top: 20px;

  button {
    height: 40px;
  }
`;
