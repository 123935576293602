import { useState, useCallback } from 'react';

const useTransactionModal = () => {
  const [visible, setVisible] = useState(false);
  const [transactionProcessing, setTransactionProcessing] = useState(false);

  const closeModal = useCallback(() => {
    setVisible(false);
  }, []);

  const startProcessing = useCallback(() => {
    setTransactionProcessing(true);
  }, []);

  const stopProcessing = useCallback(() => {
    setTransactionProcessing(false);
  }, []);

  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  const toggleModal = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  return {
    isVisible: visible,
    closeModal,
    toggleModal,
    openModal,
    startProcessing,
    stopProcessing,
    ongoingTransaction: transactionProcessing,
  };
};

export default useTransactionModal;
