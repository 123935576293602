import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getFileS3 } from '../../../utils/s3.storage';

export const EventSchedule = ({ schedule, setSpeakerId }: any) => {
  const [selected, setSelected] = useState(0);

  function handleDayChange(key: number) {
    setSelected(key);
  }

  const days = groupBy(schedule, function (date) {
    return moment.unix(date.startTime).format('DD/MM/YY');
  });

  return (
    <>
      {schedule && schedule.length ? (
        <div>
          <StyledTitle>Event Schedule</StyledTitle>
          <StyledButtons>
            {Object.keys(days).map((day: any, idx) => (
              <StyledButton
                key={day}
                onClick={() => handleDayChange(idx)}
                selected={selected === idx}
              >
                Day {idx + 1}
              </StyledButton>
            ))}
          </StyledButtons>
          <StyledContent>
            <StyledDate>Date: {Object.keys(days)[selected]}</StyledDate>
            {Object.values(days)[selected].map((day: any) => (
              <StyledSchedule key={day}>
                <p>{moment.unix(day.startTime).format('hh:mm A')}</p>
                <div>
                  <div>
                    <span>{day.topic}</span>
                    {day.speakers.map((speaker: any) => (
                      <img
                        key={speaker}
                        src={getFileS3(speaker.pic)}
                        alt="speaker"
                        onClick={() => setSpeakerId(speaker.id)}
                        role="none"
                      />
                    ))}
                  </div>
                  <span>
                    {moment
                      .unix(day.endTime)
                      .diff(moment.unix(day.startTime), 'minutes')}{' '}
                    Mins
                  </span>
                </div>
              </StyledSchedule>
            ))}
          </StyledContent>
        </div>
      ) : null}
    </>
  );
};

interface ButtonProp {
  selected: boolean;
}

const StyledTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 24px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 16px;
  }
`;

const StyledDate = styled.p`
  margin-top: 12px;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledButton = styled.button<ButtonProp>`
  width: 120px;
  padding: 3px 0;
  border: none;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  font-weight: 500;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: none;
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-left: 1px solid black;
      background: white;
      border-radius: 6px 6px 0px 0px;
      margin-bottom: -2px;
    `}

  &:hover {
    background: white;
  }
`;

const StyledContent = styled.div`
  border: 1px solid black;
  background: white;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  font-weight: 500;

  & > div,
  h3 {
    margin-top: 20px;
  }

  h3 {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 17px;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 21px;
    }
  }
`;

const StyledSchedule = styled.div`
  & > p {
    margin-bottom: 10px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 14px;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 18px;
    }
  }

  & > div {
    padding: 20px;
    border-radius: 10px;
    color: white;
    background: #2d2d2d;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: 14px;
      }

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: 18px;
      }
    }

    img {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      border: 1px solid #695edd;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;
