/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as ShareLogo } from '../../assets/svg/009-share.svg';
import {
  LinkedinIcon,
  WhatsAppIcon,
  TeligramIcon,
  TwitterIcon,
  FacebookIcon,
} from '../shareIcons';
import { PublicRoutes } from '../../constants/routes';
import { CopyIcon } from '../svgicons';
import Notification from '../Notification/Notification';
import {
  ShareEventProps,
} from '../../interfaces/components/ShareEvent';
import { getRoleId } from '../../utils/userInfo';
import { getUserColorWithRoleId } from '../../styles/utils';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { ReactComponent as JobShareLogo } from '../../assets/svg/job-share-logo.svg';

export const SocialShareIcons: React.FC<ShareEventProps> = ({
  eventId,
  name,
  jobId,
  isEventShared,
  url,
}): React.ReactElement => {
  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const shareableUrl =
    url || PublicRoutes.publicEvent(eventId, name || '', jobId, isEventShared);

  // react-share is not type supported and does not allow the round props like it works on js, so using ignore here for this library, will do more research on this.
  return (
    <div onClick={handleClick}>
      <Menu>
        <SocialIcon id="facebook_share">
          {/* @ts-ignore */}
          <FacebookShareButton url={shareableUrl} round>
            <FacebookIcon />
          </FacebookShareButton>
        </SocialIcon>
        {/* <SocialIcon id="facebook_mng_share">
           @ts-ignore
          <FacebookMessengerShareButton url={shareableUrl} round>
            <MessangerIcon />
          </FacebookMessengerShareButton>
        </SocialIcon> */}
        <SocialIcon id="telegram_share">
          {/* @ts-ignore */}
          <TelegramShareButton url={shareableUrl} round>
            <TeligramIcon />
          </TelegramShareButton>
        </SocialIcon>
        <SocialIcon id="twitter_share">
          {/* @ts-ignore */}
          <TwitterShareButton url={shareableUrl} round>
            <TwitterIcon />
          </TwitterShareButton>
        </SocialIcon>
        <SocialIcon id="whatsapp_share">
          {/* @ts-ignore */}
          <WhatsappShareButton url={shareableUrl} round>
            <WhatsAppIcon />
          </WhatsappShareButton>
        </SocialIcon>
        <SocialIcon id="linkedin_share">
          {/* @ts-ignore */}
          <LinkedinShareButton url={shareableUrl} round>
            <LinkedinIcon />
          </LinkedinShareButton>
        </SocialIcon>
        <SocialIcon id="copy_share">
          <CopyToClipboard
            text={shareableUrl}
            onCopy={() => {
              Notification('success', 'Event Link Copied');
            }}
            // @ts-ignore
            title="Copy link"
          >
            <div className="copy-link-button">
              <CopyIcon />
            </div>
          </CopyToClipboard>
        </SocialIcon>
      </Menu>
    </div>
  );
};

export const ShareOverlay: React.FC<ShareEventProps> = ({
  children,
  eventId,
  name,
  jobId,
  isEventShared,
}) => {
  return (
    <Dropdown
      overlay={
        <SocialShareIcons
          eventId={eventId}
          name={name}
          jobId={jobId}
          isEventShared={isEventShared}
        />
      }
      trigger={['click']}
    >
      {children}
    </Dropdown>
  );
};

export const ShareEventButton: React.FC<ShareEventProps> = ({
  eventId,
  name,
  jobId,
  jobShareLogo,
  isEventShared,
}) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <ShareOverlay
      eventId={eventId}
      name={name}
      jobId={jobId}
      isEventShared={isEventShared}
    >
      <ShareIconContainer id="share_event_icon_btn" onClick={handleClick}>
        {!jobShareLogo ? <ShareLogo /> : <JobShareLogo />}
      </ShareIconContainer>
    </ShareOverlay>
  );
};

export const EventPageShare: React.FC<ShareEventProps> = ({
  eventId,
  name,
}) => {
  return (
    <ShareOverlay eventId={eventId} name={name}>
      <EventSharButton
        color={getUserColorWithRoleId(getRoleId())}
        id="share_event_btn"
      >
        <ShareLogo />
      </EventSharButton>
    </ShareOverlay>
  );
};

export const GenericShareOverlay: React.FC<any> = ({ url, children }) => {
  return (
    <Dropdown overlay={<SocialShareIcons url={url} />} trigger={['click']}>
      {children}
    </Dropdown>
  );
};

export const ShareButton: React.FC<any> = ({ url }) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <GenericShareOverlay url={url}>
      <ShareIconContainer id="share_event_icon_btn" onClick={handleClick}>
        <ShareLogo />
      </ShareIconContainer>
    </GenericShareOverlay>
  );
};

const ShareIconContainer = styled.div`
  width: max-content;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  cursor: pointer;

  svg {
    width: 25px;
    position: relative;
    height: 15px;
  }
`;

const SocialIcon = styled(Menu.Item)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  cursor: pointer;

  button,
  div {
    width: 32px;
    height: 32px;
    ${DISPLAY_FLEX('row', 'center', 'center')};
  }
`;

const EventSharButton = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}

  background-color: ${(props) => props.color};
  color: ${COLORS.WHITE};
  width: max-content;
  padding: 8px;
  border: none;
  border-radius: 10px;
  ${MARGIN(0, 5)}

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: ${COLORS.WHITE};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 10px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
