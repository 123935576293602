import React from 'react';
import styled from 'styled-components';
import { TextProps } from '../../interfaces/components/Text';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';

export const SmallText: React.FC<TextProps> = ({
  props,
  children,
  color,
  font,
  fontWeight,
  margin,
  id,
}) => {
  return (
    <StyledSmallText
      {...props}
      color={color}
      font={font}
      fontWeight={fontWeight}
      margin={margin}
      id={id}
    >
      {children}
    </StyledSmallText>
  );
};

export const MediumText: React.FC<TextProps> = ({
  props,
  children,
  color,
  font,
  fontWeight,
  margin,
  onClick,
  cursor,
  opacity,
}) => {
  return (
    <StyledMediumText
      {...props}
      color={color}
      font={font}
      fontWeight={fontWeight}
      margin={margin}
      onClick={onClick}
      cursor={cursor}
      opacity={opacity}
    >
      {children}
    </StyledMediumText>
  );
};

export const BigText: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
  margin,
}) => {
  return (
    <StyledBigText
      {...props}
      color={color}
      fontWeight={fontWeight}
      margin={margin}
    >
      {' '}
      {children}
    </StyledBigText>
  );
};

export const SmallFont: React.FC<TextProps> = ({
  props,
  children,
  color,
  margin,
  fontWeight,
}) => {
  return (
    <StyledSmallFont
      {...props}
      color={color}
      margin={margin}
      fontWeight={fontWeight}
    >
      {children}
    </StyledSmallFont>
  );
};

export const MediumFont: React.FC<TextProps> = ({
  props,
  children,
  color,
  margin,
  fontWeight,
  opacity,
}) => {
  return (
    <StyledMediumFont
      {...props}
      color={color}
      margin={margin}
      fontWeight={fontWeight}
      opacity={opacity}
    >
      {children}
    </StyledMediumFont>
  );
};

export const LargeFont: React.FC<TextProps> = ({
  props,
  children,
  color,
  margin,
  fontWeight,
  cursor,
}) => {
  return (
    <StyledLargeFont
      {...props}
      color={color}
      margin={margin}
      fontWeight={fontWeight}
      cursor={cursor}
    >
      {children}
    </StyledLargeFont>
  );
};

export const LargeSpecialCaseFont: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
}) => {
  return (
    <StyledLargeSpecialCaseFont
      {...props}
      color={color}
      fontWeight={fontWeight}
    >
      {children}
    </StyledLargeSpecialCaseFont>
  );
};

export const ExtraLargeFont: React.FC<TextProps> = ({
  props,
  children,
  color,
  margin,
  fontWeight,
}) => {
  return (
    <StyledExtraLargeFont
      {...props}
      color={color}
      margin={margin}
      fontWeight={fontWeight}
    >
      {children}
    </StyledExtraLargeFont>
  );
};

export const Heading: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
  margin,
}) => {
  return (
    <StyledHeading
      {...props}
      color={color}
      fontWeight={fontWeight || FONT_WEIGHTS.BOLD}
      margin={margin || '18px 0'}
    >
      {children}
    </StyledHeading>
  );
};

export const SmallHeading: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
}) => {
  return (
    <StyledSmallHeading {...props} color={color} fontWeight={fontWeight}>
      {children}
    </StyledSmallHeading>
  );
};

export const MediumHeading: React.FC<TextProps> = ({
  props,
  children,
  color,
  margin,
  fontWeight,
}) => {
  return (
    <StyledMediumHeading
      {...props}
      color={color}
      fontWeight={fontWeight}
      margin={margin}
    >
      {children}
    </StyledMediumHeading>
  );
};

export const SemiLargeHeading: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
  margin,
}) => {
  return (
    <StyledSemiLargeHeading
      {...props}
      color={color}
      fontWeight={fontWeight || FONT_WEIGHTS.BOLD}
      margin={margin || '18px 0'}
    >
      {children}
    </StyledSemiLargeHeading>
  );
};

export const LargeHeading: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
  margin,
}) => {
  return (
    <StyledLargeHeading
      {...props}
      color={color}
      fontWeight={fontWeight || FONT_WEIGHTS.BOLD}
      margin={margin || '18px 0'}
    >
      {children}
    </StyledLargeHeading>
  );
};

export const ExtraLargeHeading: React.FC<TextProps> = ({
  props,
  children,
  color,
  fontWeight,
  margin,
}) => {
  return (
    <StyledExtraLargeHeading
      color={color}
      {...props}
      fontWeight={fontWeight || FONT_WEIGHTS.SEMI_BOLD}
      margin={margin || '18px 0'}
    >
      {children}
    </StyledExtraLargeHeading>
  );
};

const StyledSmallText = styled.div<TextProps>`
  font-size: ${FONT[13]};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[15]};
  }
`;

const StyledMediumText = styled.div<TextProps>`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};
  font-size: ${FONT[15]};
  cursor: ${(props) => props.cursor};
  display: inline-block;
  opacity: ${(props) => props.opacity};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[17]};
  }
`;

const StyledBigText = styled.div<TextProps>`
  font-size: ${FONT[18]};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  margin: ${(props) => props.margin};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[20]};
  }
`;

export const StyledSmallFont = styled.div<TextProps>`
  font-size: ${FONT[12]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin: ${(props) => props.margin || '0'};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[12]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[10]};
  }
`;

export const StyledMediumFont = styled.div<TextProps>`
  font-size: ${FONT[12]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin: ${(props) => props.margin || '0'};
  cursor: ${(props) => props.cursor};
  opacity: ${(props) => props.opacity};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[14]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }
`;

export const StyledLargeFont = styled.div<TextProps>`
  font-size: ${FONT[14]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin: ${(props) => props.margin || '0'};
  cursor: ${(props) => props.cursor || ''};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const StyledExtraLargeFont = styled.div<TextProps>`
  font-size: ${FONT[14]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin: ${(props) => props.margin || '0'};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }
`;

export const StyledHeading = styled.div<TextProps>`
  font-size: ${FONT[14]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const StyledSmallHeading = styled.div<TextProps>`
  font-size: ${FONT[14]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }
`;

export const StyledMediumHeading = styled.div<TextProps>`
  font-size: ${FONT[14]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const StyledSemiLargeHeading = styled.div<TextProps>`
  font-size: ${FONT[16]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[20]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const StyledLargeHeading = styled.div<TextProps>`
  font-size: ${FONT[18]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[24]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const StyledExtraLargeHeading = styled.div<TextProps>`
  font-size: ${FONT[24]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[30]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }
`;

export const StyledLargeSpecialCaseFont = styled.span<TextProps>`
  font-size: ${FONT[14]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[10]};
  }
`;

export const StyledSpecialLargeHeading = styled.div<TextProps>`
  font-size: ${FONT[19]};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[25]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[18]};
  }

  @media (min-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3}) and (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[22]};
  }
`;
