import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import { getRecruiterBookedEventsData } from '../../../store/features/events';
import { RecruiterRoutes } from '../../../constants/routes';
import JobLists from './JobLists';
import {
  JOBAPPLICATION,
  JOBOPPORTUNITIES,
  RETRY_ATTEMPTS,
  RETRY_INTERVALS,
} from '../../../constants';
import { isTestModeOn } from '../../../utils/test-helper';
import { TabSwitch } from '../../../components/shared-components/Tabs';
import Circle from '../../../components/shared-components/PageCircle';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { SHADOWS } from '../../../styles/constants/shadows';
import { getJobRestrict } from '../../../service/masterAdmin';
import Notification from '../../../components/Notification/Notification';
import {
  getRoleId,
  checkIfUserIsRecruiter,
} from '../../../utils/userInfo';
import { safeLoad } from '../../../utils/loader';
import Loading from '../../../components/Common/Loading';

const JobApplications = React.lazy(() => {
  return safeLoad(
    () => import('./JobApplications'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

export default function Recruitment({ match, history, testMockData }) {
  const defaultTab = match.params.tab ? match.params.tab : JOBAPPLICATION;
  const [previousJobApplicationUrl, setPreviousJobApplication] = useState(
    `${RecruiterRoutes.recruit}${JOBAPPLICATION}`,
  );
  const [tab, setTab] = useState(defaultTab);
  const [isJobCreateValid, setIsJobCreateValid] = useState(false);

  const roleId = getRoleId();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!parseInt(match.params.tab)) {
      history.push(`${RecruiterRoutes.recruit}${JOBAPPLICATION}`);
      setTab(JOBAPPLICATION);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getRecruiterBookedEventsData());
  }, [dispatch]);

  const tabChange = (currentTab) => {
    setTab(currentTab);
    if (currentTab === JOBOPPORTUNITIES) {
      setPreviousJobApplication(
        history.location.pathname + history.location.search,
      );
      history.push(`${RecruiterRoutes.recruit}${currentTab}`);
    } else {
      history.push(previousJobApplicationUrl);
    }
  };

  const getTab = (currentTab) => {
    return isTestModeOn() ? testMockData.tab : currentTab;
  };

  const recruitTabsItems = [
    {
      key: JOBOPPORTUNITIES,
      name: 'Jobs',
      component: <JobLists history={history} tab={getTab(tab)} />,
    },
    {
      key: JOBAPPLICATION,
      name: 'Applications',
      component: (
        <React.Suspense fallback={<Loading />}>
          <JobApplications history={history} tab={getTab(tab)} />,
        </React.Suspense>
      )
    },
  ];

  const JobRestrictWarming = () => {
    Notification("warning", "Jobs Restricted", "Max Allowed limit reached")
  }

  const fetchData = async () => {
    const res = await getJobRestrict();
    if (res.isValid) {
      setIsJobCreateValid(true);
    } else {
      setIsJobCreateValid(false);
    }
  }

  useEffect(() => {
    if (checkIfUserIsRecruiter(roleId)) {
      fetchData();
    }
  }, [roleId])

  return (
    <div>
      <StyledCircleWrapper>
        <Circle color={COLORS.SUPER_NOVA} />
      </StyledCircleWrapper>
      <Col>
        <StyledHeadingContainer>
          <Col xs={24} md={16}>
            <StyledBreadcrumbWrapper>
              Recruit
              <StyledResponsiveHeader>
                {'>'}
                {defaultTab === JOBAPPLICATION
                  ? ' Job Applications '
                  : ' Job Opportunities '}
              </StyledResponsiveHeader>
            </StyledBreadcrumbWrapper>
          </Col>
          <StyledButtonContainer xs={24} md={8}>
            <div>
              {
                isJobCreateValid ? (
                  <Link to={RecruiterRoutes.createJob}>
                    <StyledButton>+ Create Job</StyledButton>
                  </Link>
                ) : (<StyledButton onClick={JobRestrictWarming}>+ Create Job</StyledButton>)
              }
            </div>
          </StyledButtonContainer>
        </StyledHeadingContainer>

        <StyledTabWrapper>
          <StyledContentContainer>
            <TabSwitch
              items={recruitTabsItems}
              onChange={tabChange}
              defaultActiveKey={defaultTab}
              activeKey={getTab(tab)}
              theme="recruiter"
            />
          </StyledContentContainer>
        </StyledTabWrapper>
      </Col>
    </div>
  );
}

const StyledCircleWrapper = styled.div`
  position: relative;
`;

const StyledHeadingContainer = styled(Row)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('column')};
  }
`;

const StyledBreadcrumbWrapper = styled(Row)`
  ${MARGIN(20, 0)};
  ${PADDING(10, 0)};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.SUPER_NOVA};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};

    div {
      font-size: ${FONT[18]};
    }
  }
`;

const StyledResponsiveHeader = styled.div`
  margin-left: 5px;
`;

const StyledButtonContainer = styled(Col)`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: center;
    max-width: 92%;
    margin-bottom: 20px;
  }
`;

const StyledButton = styled(Button)`
  ${PADDING(5, 25)};
  background-color: ${COLORS.SUPER_NOVA};
  color: ${COLORS.WHITE};
  width: min-content;
  height: min-content;
  border-radius: 5px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 78vw;
  }

  &:hover {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.SUPER_NOVA};
    border: 1px solid ${COLORS.SUPER_NOVA};
  }

  span {
    font-size: ${FONT[14]};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }
`;

const StyledTabWrapper = styled(Row)`
  box-shadow: ${SHADOWS.SHADOW1};
`;

const StyledContentContainer = styled.div`
  background: ${COLORS.WHITE};
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 20px;

  .ant-tabs-tab {
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    outline: none;
  }
`;
