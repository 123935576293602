import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Switch } from 'antd';
import BasicPricing from './BasicPricing';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { getRoleId } from '../../../utils/userInfo';
import { MediumHeading } from '../../shared-components/Texts';
import { MARGIN } from '../../../styles/constants/display';

const PricingAndPromoContainer = ({
  event,
  form,
  setBookingMethod
}) => {
  const roleId = getRoleId();

  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (event && (event.recruiterTicketPrice || event.userTicketPrice)) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [event]);

  const handleStatusChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <StyledRow>
        <StyledSwitch
          color={getUserColorWithRoleId(roleId)}
          checked={isChecked}
          onChange={handleStatusChange}
        />
        <MediumHeading
          fontWeight={FONT_WEIGHTS.SEMI_BOLD}
          margin="0 10px"
          color={getUserColorWithRoleId(roleId)}
        >
          Free Event
        </MediumHeading>
      </StyledRow>
      <BasicPricing form={form} event={event} isDisabled={isChecked} setBookingMethod={setBookingMethod} />
    </>
  );
};

export default PricingAndPromoContainer;

const StyledSwitch = styled(Switch)`
  ${MARGIN(0, 0, 10, 0)}
  &.ant-switch-checked {
    background-color: ${(props) => props.color};
  }
`;

const StyledRow = styled(Row)`
 ${MARGIN(5, 0, 12, 0)}
`;
