import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import {
  deleteGroupChatThread,
  deleteGroupChatThreads,
  pinnedGroupChatThread,
  getSelectedGroupChatDetails,
  getSelectedGroupChatUsersDetails,
  setGroupChats,
} from '../../../store/features/chat';
import { EMPTY_GROUP_CHATROOM_TEXT } from '../../../constants/texts/chat';
import { DELETE_ALL } from '../../../constants/chat';
import { getUsersDetailsInChatroom } from '../../../utils/chat';
import {
  deleteThread,
  getChatRoomWithId,
  getRoomByEventId,
  setPinnedMessage,
} from '../../../service/chat';
import Notification from '../../Notification/Notification';
import ThreadInput from './ThreadInput';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getUrlVars } from '../../../utils/common';
import { COLORS } from '../../../styles/constants/colors';
import { STATUS_ACTIVE, RETRY_ATTEMPTS, RETRY_INTERVALS, } from '../../../constants';
import { checkIfStatusIsActive } from '../../../utils/admin/common';
import { safeLoad } from '../../../utils/loader';
import Loading from '../../Common/Loading';

const Heading = React.lazy(() => {
  return safeLoad(() => import('./Heading'), RETRY_ATTEMPTS, RETRY_INTERVALS);
});

const PinnedMessage = React.lazy(() => {
  return safeLoad(() => import('./PinnedMessage'), RETRY_ATTEMPTS, RETRY_INTERVALS);
});

const ThreadContainer = React.lazy(() => {
  return safeLoad(
    () => import('./ThreadContainer'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const GroupBody = ({ discussionRoom, eventDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { event } = getUrlVars(get(history, 'location.search', {}));
  const id = eventDetails && eventDetails.id;
  const {
    isGroupChatsLoaded: isLoaded,
    groupChats,
    selectedGroupChatId,
    selectedGroupChatDetails,
    groupChatsUsersProfileData: usersProfileData,
    userAnonymity,
  } = useSelector((state) => state.chat);

  // Real time Chat - Socket Messages Array
  const [newMessages, setNewMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [chatId, setChatId] = React.useState(null);
  const [chatStatus, setChatStatus] = useState(STATUS_ACTIVE);

  // Gets chat ID
  useEffect(() => {
    if (id) {
      (async () => {
        await getRoomByEventId(id).then((data) => {
          setChatId(get(data, 'payload._id', ''));
          setChatStatus(get(data, 'payload.status', ''));
        });
      })();
    }
    /*
    function fetchAndSetChatId() {
      if (groupChats.length && discussionRoom) {
        groupChats.map((item) => {
          if (item.eventId === id) {
            setChatId(item.id);
          }
          return '';
        });
      }
    }

    fetchAndSetChatId();
    */
  }, [id, discussionRoom]);

  // Sets Chat ID
  useEffect(() => {
    if (discussionRoom) {
      if (chatId) {
        dispatch(setGroupChats(chatId));
      }
      return;
    }
    dispatch(setGroupChats(event));
  }, [dispatch, event, chatId, discussionRoom]);

  // Get Chat Room
  const getChatRoom = async (chatId) => {
    await getChatRoomWithId(chatId).then((chatRoomDetails) => {
      dispatch(getSelectedGroupChatDetails(chatRoomDetails));
      const usersArr = chatRoomDetails.users;
      const userDetails = getUsersDetailsInChatroom(usersArr, usersProfileData);
      dispatch(getSelectedGroupChatUsersDetails(userDetails));
    });
  };

  // Get Chat Room on initial render
  useEffect(() => {
    if (discussionRoom && id && usersProfileData) {
      setLoading(true);
      getRoomByEventId(id).then(async (data) => {
        const chatId = get(data, 'payload._id', '');
        await getChatRoomWithId(chatId).then((chatRoomDetails) => {
          dispatch(getSelectedGroupChatDetails(chatRoomDetails));
          const usersArr = chatRoomDetails.users;
          const userDetails = getUsersDetailsInChatroom(
            usersArr,
            usersProfileData,
          );
          dispatch(getSelectedGroupChatUsersDetails(userDetails));
        });
      });
      setLoading(false);
    }

    if (selectedGroupChatId && !discussionRoom) {
      getChatRoom(selectedGroupChatId);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    groupChats,
    selectedGroupChatId,
    usersProfileData,
    discussionRoom,
    id,
  ]);

  // Delete Message Handler
  const deleteHandler = async (
    chatRoomId,
    threadId,
    isDifferentMessage,
    type,
  ) => {
    if (type === DELETE_ALL && isArray(threadId)) {
      Promise.all(
        threadId.map((id) => {
          return deleteThread(chatRoomId, id, isDifferentMessage);
        }),
      )
        .then(() => {
          if (isDifferentMessage) {
            // Clean all messages
            setNewMessages([]);
          } else {
            // Clean only current users
            setNewMessages((prevMessages) => {
              return prevMessages.filter(
                (msg) => !threadId.includes(msg.messageId),
              );
            });
          }
          dispatch(deleteGroupChatThreads(threadId));
          Notification('success', 'Message Deleted', 'Successfully Deleted');
        })
        .catch(() => {
          Notification('error', "Can't delete", 'Error in deleting message');
        });
    } else {
      const isDeleted = await deleteThread(
        chatRoomId,
        threadId,
        isDifferentMessage,
      );
      if (isDeleted) {
        let deletedMessageIsRealTime = false;
        setNewMessages((prevMessages) =>
          prevMessages.filter((msg) => {
            if (msg.messageId === threadId) deletedMessageIsRealTime = true;
            return msg.messageId !== threadId;
          }),
        );
        if (!deletedMessageIsRealTime)
          dispatch(deleteGroupChatThread(threadId));
        Notification(
          'success',
          'Message Deleted',
          'Thread Successfully Deleted',
        );
      } else {
        Notification('error', "Can't delete", 'Error in deleting message');
      }
    }
  };

  // Pinned message Handler
  const handlePinNotification = (isPinned) => {
    Notification(
      'success',
      `Message ${isPinned ? 'Pinned' : 'Unpinned'} `,
      `Message ${isPinned ? 'Pinned' : 'Unpinned'} successfully`,
    );
  }

  const pinnedHandler = (isPin, chatRoomId, threadId) => {
    const messageId = threadId;
    setPinnedMessage({ chatRoomId, messageId, isPin: !isPin }).then((data) => {
      dispatch(pinnedGroupChatThread(threadId, chatRoomId, isPin));
      handlePinNotification(!isPin);
    }).catch(() => {
      Notification('error', 'Maximum Pinned messages exceeded');
    });
  }


  return (
    <StyledGroupChatBody userAnonymity={userAnonymity}>
      {isLoaded ? (
        <>
          {groupChats.length || discussionRoom ? (
            <>
              {selectedGroupChatDetails ? (
                <>
                  {discussionRoom &&
                    chatId &&
                    !loading &&
                    checkIfStatusIsActive(chatStatus) ? (
                    <>
                      <StyledComment>Comments</StyledComment>
                      <ThreadInput discussionRoom />
                      <React.Suspense fallback={<Loading />}>
                        <PinnedMessage
                          deleteHandler={deleteHandler}
                          pinnedHandler={pinnedHandler}
                          discussionRoom
                          userAnonymity={userAnonymity}
                        />
                      </React.Suspense>
                      <React.Suspense fallback={<Loading />}>
                        <ThreadContainer
                          deleteHandler={deleteHandler}
                          pinnedHandler={pinnedHandler}
                          newMessages={newMessages}
                          setNewMessages={setNewMessages}
                          discussionRoom
                          chatId={chatId}
                        />
                      </React.Suspense>
                    </>
                  ) : !discussionRoom ? (
                    <>
                      <React.Suspense fallback={<Loading />}>
                        <Heading
                          deleteMessage={deleteHandler}
                          newMessages={newMessages}
                          getChatRoom={getChatRoom}
                        />
                      </React.Suspense>
                      <React.Suspense fallback={<Loading />}>
                        <PinnedMessage
                          deleteHandler={deleteHandler}
                          pinnedHandler={pinnedHandler}
                          userAnonymity={userAnonymity}
                        />
                      </React.Suspense>
                      <React.Suspense fallback={<Loading />}>
                        <ThreadContainer
                          deleteHandler={deleteHandler}
                          pinnedHandler={pinnedHandler}
                          newMessages={newMessages}
                          setNewMessages={setNewMessages}
                        />
                      </React.Suspense>
                      <ThreadInput />
                    </>
                  ) : (
                    <h1>Discussion Room Not Available!</h1>
                  )}
                </>
              ) : (
                <Skeleton active />
              )}
            </>
          ) : (
            <h1>{EMPTY_GROUP_CHATROOM_TEXT}</h1>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
    </StyledGroupChatBody>
  );
};

const StyledComment = styled.h3`
  color: ${COLORS.RICH_BLUE};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 18px;
  }
`;

const StyledGroupChatBody = styled.div`
  ${DISPLAY_FLEX('column')}

  height: ${({ userAnonymity }) =>
    userAnonymity || !userAnonymity ? '' : '80vh'};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: ${({ userAnonymity }) =>
    userAnonymity || !userAnonymity ? '' : '82vh'};
    margin-top: 0;
  }

  ${StyledComment} {
    color: ${({ userAnonymity }) => userAnonymity && 'white'};
  }
`;

export default GroupBody;
