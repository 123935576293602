import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import QuillEditor from '../../../../components/Common/QuillEditor';
import { MARGIN } from '../../../../styles/constants/display';
import { StyledInfoHeading } from './Common';
import { FONT } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';

const Textarea = ({
  xs,
  sm,
  lg,
  xl,
  md,
  title,
  isMandatory = false,
  value,
  name,
  placeholder,
  handleChange,
  textCount,
  setText,
  setTextCount,
}) => {
  return (
    <Col xs={xs} lg={lg} sm={sm} md={md} xl={xl}>
      <StyledInfoHeading>
        {title} {isMandatory ? <MandatoryStar /> : null}
      </StyledInfoHeading>
      <div>
        {/* Using React quill instead of InputTextArea
        For now, we are skipping this component in testing aspect. */}
        <StyledQuillEditor
          name={name}
          placeholder={placeholder}
          value={value}
          maxLength={1000}
          handleChange={(value, delta, source, editor) =>
            handleChange(value, setTextCount, setText, editor.getLength() - 1)
          }
        />
        <StyledCountText>{textCount}/1000</StyledCountText>
      </div>
    </Col>
  );
};

const StyledQuillEditor = styled(QuillEditor)`
  ${MARGIN(20, 0, 60, 0)};
  width: 100%;
  height: 200px;
`;
const StyledCountText = styled.p`
  font-size: ${FONT[14]};
  text-align: right;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

export default Textarea;
