import axios from 'axios';
import get from 'lodash/get';
import Message from '../components/messages/message';
import Config from '../config';
import { getAccessToken } from '../utils/storage';
import { APIRequestLimit, ApiType, Method } from '../constants';
import { handleErrors, handleRateLimitError } from './errorHandler';
import { requestUtils, getUniqueUrl } from '../utils/requests';
import { isTestModeOn } from '../utils/test-helper';

const isRateLimitExceeded = () => {
  const value = localStorage.getItem('hasEncouteredTooManyRequestsError');
  if (!value) return false;

  const item = JSON.parse(value);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem('hasEncouteredTooManyRequestsError');
    return false;
  }
  return item.value;
};

/**
 * API wrapper
 * @param {string} url endpoint
 * @param {string} request method
 * @param {object|boolean} body object
 * @param {boolean} auth for authentication
 * @param {string} contentType
 * @param {string} baseUrl for chatAPI or not.
 * @param {object|null} optional response object for mock requests
 * @returns {object} endpoint response
 */
const api = async ({
  url,
  method,
  body = false,
  auth = true,
  contentType = 'application/json',
  type = '',
  errorHandlingMessage = true,
  cancelToken,
}) => {
  if (isRateLimitExceeded()) {
    return handleRateLimitError();
  }
  const headers = {};

  if (auth) {
    const { accessToken } = getAccessToken();
    headers.Authorization = `Bearer ${accessToken}`;
    headers['content-type'] = contentType;
  }

  let apiUrl = Config.baseURL + url;
  if (type === ApiType.chat) {
    apiUrl = Config.chatBaseURL + url;
  }

  if (type === ApiType.analytics) {
    apiUrl = Config.analyticsBaseUrl + url;
  }

  if (auth) {
    const { accessToken } = getAccessToken();
    headers.Authorization = `Bearer ${accessToken}`;
    headers['content-type'] = contentType;
  }

  switch (method) {
    case Method.get:
      return axios[method](apiUrl, { cancelToken, headers })
        .then((res) => res)
        .catch((err) => {
          if (isTestModeOn()) {
            const errorMessage = get(err, 'response.data.message', undefined);
            return Promise.reject(errorMessage);
          }
          if (errorHandlingMessage) {
            // Handle errors with status code
            handleErrors(err.response);
          }
          return get(err, 'response.data');
        });
    case Method.delete:
      return axios[method](apiUrl, { cancelToken, headers }, body)
        .then((res) => res)
        .catch((err) => {
          if (isTestModeOn()) {
            const errorMessage = get(err, 'response.data.message', undefined);
            return Promise.reject(errorMessage);
          }
          if (errorHandlingMessage) {
            // Handle errors with status code
            handleErrors(err.response);
          }
          return get(err, 'response.data');
        });
    default:
      return axios[method](apiUrl, body, { cancelToken, headers })
        .then((res) => res)
        .catch((err) => {
          if (isTestModeOn()) {
            const errorMessage = get(err, 'response.data.message', undefined);
            return Promise.reject(errorMessage);
          }
          if (errorHandlingMessage) {
            // Handle errors with status code
            handleErrors(err.response);
          }
          return get(err, 'response.data');
        });
  }
};

axios.interceptors.request.use((config) => {
  const key = getUniqueUrl(config);
  if (requestUtils.isLimitExceeded(key) && Config.under_dev) {
    Message(
      'error',
      `More then ${APIRequestLimit.request} requests send in less then ${
        APIRequestLimit.duration / 1000
      } sec to the url ${config.url}`,
    );
  }
  return config;
});

export const CancelToken = axios.CancelToken;
export default api;
