import get from 'lodash/get';
import { STATUS_ACTIVE, STATUS_DELETE, STATUS_DISABLED } from '../../constants';
import { showConfirm } from '../../containers/MasterAdmin/commons/confirm';
import Notification from '../../components/Notification/Notification';

export const checkIfStatusIsDisabled = (status) => {
  return status === STATUS_DISABLED;
};

export const checkIfStatusIsActive = (status) => {
  return status === STATUS_ACTIVE;
};

export const getKabebMenuOptions = (status, callback, onEdit) => {
  return !checkIfStatusIsDisabled(status)
    ? [
        {
          text: 'Edit',
          onclick: onEdit,
        },
        {
          text: 'Suspend Account',
          onclick: () => showConfirm(() => callback(STATUS_DISABLED)),
        },
        {
          text: 'Delete Account',
          onclick: () => showConfirm(() => callback(STATUS_DELETE), 'Delete'),
        },
      ]
    : [
        {
          text: 'Reactivate Account',
          onclick: () => showConfirm(() => callback(STATUS_ACTIVE), 'Reactivate'),
        },
        {
          text: 'Delete Account',
          onclick: () => showConfirm(() => callback(STATUS_DELETE), 'Delete'),
        },
      ];
};

export const performAfterAccountDeleteAction = (response) => {
  const originalEmail = get(response, 'originalEmail', '');
  const afterDeleteEmail = get(response, 'afterDeleteEmail', '');
  Notification(
    'success',
    'Account Deleted',
    `Original Email:- ${originalEmail}  , After Delete Email:- ${afterDeleteEmail}`,
    null,
  );
};

/**
 * Filter country names by country ids
 * @param {Array} countries
 * @param {Array} countryIds
 */

export const filterCountryNamesByIds = (countryIds = [], countries = []) => {
  return countryIds.map((item) => {
    const country = countries.find((c) => c.id === item);
    return country.name;
  });
};
