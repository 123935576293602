import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';

export const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  width: calc(${BREAKPOINTS.TABLET_MAX} - 20px);
  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
    max-width: 100%;
    width: 100%;
    padding: 0 20px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 98%;
    overflow-x: hidden;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_5};
  }
`;

export const RightAlignedContainer = ({
  children,
  margin,
  padding,
  gap,
  mobileJustify = 'flex-end',
}) => {
  return (
    <StyledCustomContainer
      margin={margin}
      padding={padding}
      gap={gap}
      mobileJustify={mobileJustify}
    >
      {children}
    </StyledCustomContainer>
  );
};

const StyledCustomContainer = styled.div`
  gap: ${({ gap }) => (gap ? `${gap}px;` : '10px')};
  ${DISPLAY_FLEX('row', 'flex-end', 'center')}
  flex-wrap: wrap;

  width: 100%;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ mobileJustify }) =>
      DISPLAY_FLEX('row', mobileJustify || 'flex-end', 'center')}
  }
`;

export const HiddenMobile = styled.div`
  display: block;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

export const ShowMobile = styled.div`
  display: none;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block;
  }
`;

export const StyledMainWrapper = styled.div`
  padding: 30px 0;
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: 20px 0;
  }
`;

export const StyledBoldText = styled.span`
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-size: inherit;
`;

export const StyledCenterAligned = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;
