import React from 'react';

const LinkedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_8_1"
      data-name="Component 8 – 1"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_63"
        data-name="Rectangle 63"
        width="40"
        height="40"
        rx="18"
        fill="#f0f0f0"
      />
      <path
        id="Icon_awesome-linkedin-in"
        data-name="Icon awesome-linkedin-in"
        d="M4.379,19.564H.323V6.5H4.379ZM2.349,4.721A2.36,2.36,0,1,1,4.7,2.35,2.369,2.369,0,0,1,2.349,4.721Zm17.21,14.843H15.512V13.206c0-1.515-.031-3.459-2.109-3.459-2.109,0-2.432,1.646-2.432,3.349v6.467H6.92V6.5h3.89V8.284h.057A4.262,4.262,0,0,1,14.7,6.175c4.1,0,4.859,2.7,4.859,6.214v7.175Z"
        transform="translate(10 9.999)"
        fill="#8c8c8c"
      />
    </svg>
  );
};

export default LinkedInIcon;
