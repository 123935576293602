import { gql } from '@apollo/client';
import { authClient, unAuthClient } from '../client';
import ERRORS from '../../constants/errors';

const GET_ALL_NATIONALITIES = gql`
  query getAllNationalities {
    nationalities {
      id
      name
      status
    }
  }
`;

export const getAllNationalities = (addAuthHeader = true) => {
  const client = addAuthHeader ? authClient : unAuthClient;
  return client
    .query({
      query: GET_ALL_NATIONALITIES
    })
    .then(response => response.data.nationalities)
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
