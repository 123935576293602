import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row, Switch, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import isArray from 'lodash/isArray';
// import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { ToolTipsText } from '../../../constants/tooltips';
import { DropdownBox } from '../../../components/shared-components/Form/Form';
import {
  updateEventRecruiterAttendanceData,
  updateEventCandidateAttendanceData,
} from '../../../store/features/events';
import { isDesktop } from '../../../helpers/display';
import {
  ATTENDANCE_PRESENT,
  ATTENDEES_MANAGEMENT_ROLES,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from '../../../constants';
import {
  isEventLiveNow,
  isAutoPresentModeActive,
  isMarkPresentDisabled,
} from '../../../utils/event';
import { switchAutoPresent } from '../../../service/event';
import CustomPagination from '../../../components/Common/CustomPagination';
import { ATTENDEES_FILTER_OPTIONS } from '../../../constants/attendees';
import { checkIfUserIsOrganizer, getRoleId } from '../../../utils/userInfo';
import { switchAutoPresentAdmin } from '../../../service/masterAdmin';
import Notification from '../../../components/Notification/Notification';
import StyledManagementTable from './StyledManagementTable';
import { ATTENDEES_SORT, SORT_ORDER } from '../../../constants/admin';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const { company, candidate } = ATTENDEES_MANAGEMENT_ROLES;

const ManagementTable = ({
  columns,
  data,
  type,
  event,
  placeholder,
  fetchData,
  pagination,
  loading,
  handleReload,
  currentEventId,
  eventId,
  populateEvents = () => {},
  sorter,
  setSorter,
  adminTheme,
  setAttendance,
  currentFilter,
  selectedLimit,
  setAttendeesFilter,
  getPagination,
  handleCurrentSorter,
}) => {
  const tabId = type;
  // const client = useContext(AppSocketContext);
  // const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentLimit, setCurrentLimit] = React.useState(10);
  const [filter, setFilter] = React.useState(currentFilter || 1);
  const dispatch = useDispatch();
  const roleId = getRoleId();

  const onTableChange = (pagination, filters, sorter) => {
    const field = ATTENDEES_SORT[get(sorter, 'field', '')];
    const order = SORT_ORDER[get(sorter, 'order', '')];

    setSorter(order ? `${field}_${order}` : null);
    handleCurrentSorter(order ? `${field}_${order}` : null);
  };

  /*
  useEffect(() => {
    const { event } = getUrlVars(get(history, 'location.search', {}));
    if (client && event) {
      (async () => {
        await client.subscribe(
          `/attendees-management/event/${event}/`,
          (resp) => {
            if (currentPage === DEFAULT_PAGE) {
              const currentRole = resp.isCompany
                ? ATTENDEES_MANAGEMENT_ROLES.company
                : ATTENDEES_MANAGEMENT_ROLES.candidate;

              setTabEventInfo((prevState) => {
                let tableData = get(prevState, `${currentRole}.tableData`, []);
                // Set new state with previous state
                tableData = tableData.filter((data) => {
                  if (checkIfUserIsCandidate(roleId)) {
                    return data.userId !== resp.userId;
                  } else {
                    return data.companyId !== resp.companyId;
                  }
                });
                if (tableData.length === currentLimit) {
                  tableData.splice(-1, 1);
                }
                return {
                  ...prevState,
                  [currentRole]: {
                    ...prevState[currentRole],
                    tableData: [{ ...resp, convertedData: true }, ...tableData],
                  },
                };
              });
            }
          },
        );
      })();
    }
    // eslint-disable-next-line
  }, []);
*/
  useEffect(() => {
    if (data) {
      setTableData(data);
    } else {
      setTableData([]);
    }
    // tableDataDependency is required here for comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // Only fetch table data if current tabEventInfo has current event data
    if (eventId === currentEventId) {
      fetchData(DEFAULT_PAGE, DEFAULT_LIMIT, filter, sorter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, currentEventId]);

  const onSelectChange = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleMarkPresent = (rows) => {
    if (!rows || rows.length < 1) {
      return;
    }

    let actionCreator;
    let eventId;
    let eventData;

    if (tabId === company) {
      eventData = tableData;
      eventId = event.id;
      actionCreator = updateEventRecruiterAttendanceData;
    } else if (tabId === candidate) {
      eventData = tableData;
      eventId = event.id;
      actionCreator = updateEventCandidateAttendanceData;
    }
    const entityIds = [];
    rows.forEach((id) => {
      const currentEl = eventData.find((user) => user.id === id);
      // Check currentEl and attendance
      const isPresent = get(
        currentEl,
        'attendance.props.children.props.checked',
        true,
      );
      if (currentEl && !isPresent) {
        entityIds.push(id);
      }
    });
    const body = {
      attendance: ATTENDANCE_PRESENT,
    };

    const usersType = tabId === candidate ? 'candidates' : 'companies';

    Notification('info', 'Request in process', `Mark present ${usersType}`);
    if (entityIds.length) {
      dispatch(actionCreator(entityIds, body, eventId))
        .then(() => {
          setAttendance((prev) => {
            const tempAttendence = { ...prev };
            entityIds.forEach((id) => {
              tempAttendence[id] = true;
            });
            return tempAttendence;
          });

          Notification(
            'success',
            'Request successful',
            `Marked ${usersType} present`,
          );
        })
        .catch(() =>
          Notification('error', 'Request failed', `Mark present ${usersType}`),
        );
    }
  };

  const switchAutoPresentMode = async (event) => {
    if (checkIfUserIsOrganizer(roleId)) {
      await switchAutoPresent(event.id, {
        isAutoPresentEnabled: !event.isAutoPresentEnabled,
      }).then(() => {
        populateEvents();
      });
    } else {
      await switchAutoPresentAdmin(event.id, event.companyId, {
        isAutoPresentEnabled: !event.isAutoPresentEnabled,
      }).then(() => {
        populateEvents();
      });
    }
  };

  const handleChange = async (index) => {
    setFilter(index);
    setAttendeesFilter(index);
  };

  useEffect(() => {
    if (currentPage === DEFAULT_PAGE) {
      fetchData(currentPage, currentLimit, filter, sorter);
    } else {
      setCurrentPage(DEFAULT_PAGE);
    }

    // eslint-disable-next-line
  }, [filter, sorter]);

  useEffect(() => {
    fetchData(currentPage, currentLimit, filter, sorter);

    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  const onPageChangeHandler = (pageNumber, limit) => {
    setCurrentPage(pageNumber);
    getPagination(pageNumber, limit);
  };

  const onLimitChangeHandler = (pageNumber, limit) => {
    setCurrentPage(pageNumber);
    setCurrentLimit(limit);
  };

  return (
    <>
      <StyledManagementTableContainer>
        <StyledManagementTableContainerLeftColumn md={12} xs={24} sm={24}>
          <Row>
            <StyledManagementTableUser>
              {tabId === company ? 'Company information' : 'Client information'}
            </StyledManagementTableUser>
            <StyledAutoButton>
              <StyledAutoMark adminTheme={adminTheme}>
                Auto Present Mode
              </StyledAutoMark>
              <Tooltip title={ToolTipsText.autoPresent}>
                <StyledSwitch
                  checked={isAutoPresentModeActive(event)}
                  disabled={!isEventLiveNow(event)}
                  onChange={() => switchAutoPresentMode(event)}
                  data-testid="auto-mark-switch"
                />
              </Tooltip>
            </StyledAutoButton>
          </Row>
        </StyledManagementTableContainerLeftColumn>
        <StyledManagementTableColumnReverse md={12} xs={24} sm={24}>
          <div>
            <Row>
              <div>
                <StyledFilterContainer>
                  <Tooltip title={ToolTipsText.refresh}>
                    <ReloadOutlined
                      spin={loading}
                      onClick={() => {
                        setFilter(1);
                        handleReload();
                      }}
                      data-testid="reload-icon-organizer"
                      role="button"
                    />
                  </Tooltip>
                  <StyledMarkPresentButton
                    onClick={() => handleMarkPresent(selectedRowKeys)}
                    disabled={isMarkPresentDisabled(event, selectedRowKeys)}
                    adminTheme={adminTheme}
                  >
                    Mark Present
                  </StyledMarkPresentButton>
                  <div>
                    <StyledFilter>
                      <DropdownBox
                        placeholder="Filter"
                        options={ATTENDEES_FILTER_OPTIONS}
                        handleChange={handleChange}
                        value={filter}
                        id="filter-select"
                      />
                    </StyledFilter>
                  </div>
                </StyledFilterContainer>
              </div>
            </Row>
          </div>
        </StyledManagementTableColumnReverse>
        <StyledManagementTableScrollable>
          {!isArray(tableData) || tableData.length === 0 ? (
            <p>{placeholder}</p>
          ) : (
            <>
              <StyledManagementTable
                rowSelection={rowSelection}
                columns={columns}
                tableData={tableData}
                size={isDesktop() ? 'default' : 'small'}
                pagination={false}
                onChange={onTableChange}
              />
            </>
          )}
        </StyledManagementTableScrollable>
        <StyledPagination>
          <CustomPagination
            currentPage={get(pagination, 'currentPage', currentPage)}
            totalItems={get(pagination, 'totalItems', tableData.length)}
            onPageChange={onPageChangeHandler}
            onLimitChange={onLimitChangeHandler}
            currentLimit={selectedLimit}
          />
        </StyledPagination>
      </StyledManagementTableContainer>
    </>
  );
};

const StyledManagementTableScrollable = styled.div`
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 0px;
  width: 100%;

  span,
  th,
  td {
    font-size: ${FONT[12]};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]};
    }
  }
`;

const StyledPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: center;
    margin: 0;
  }
`;

const StyledFilter = styled.div`
  .ant-select-selector {
    background-color: ${COLORS.BRIGHT_GRAY} !important;
    border: none !important;
    border-radius: 5px !important;
    width: 130px !important;
    height: 33px !important;
    margin-left: -15px;

    span {
      font-size: ${FONT[14]};
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      opacity: 0.7;

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: ${FONT[16]};
      }
    }
  }
`;

const StyledManagementTableContainer = styled(Row)`
  margin-top: 30px;
  margin-bottom: 0;
`;

const StyledManagementTableContainerLeftColumn = styled(Col)`
  display: flex;
  align-items: center;
  align-content: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 25px;
  }
`;

const StyledManagementTableUser = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledAutoButton = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 10px 0;
  }
`;

const StyledAutoMark = styled.span`
  color: ${(props) =>
    !props.adminTheme ? COLORS.PUERTO_RICO : COLORS.DODGER_BLUE};
  border: none;
  margin-left: 4px;
  margin-right: 4px;
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledSwitch = styled(Switch)`
  margin-left: 20px;
`;

const StyledManagementTableColumnReverse = styled(Col)`
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    flex-direction: row;
  }
`;

const StyledFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  margin: 0 0 0 auto;
  color: 'gray';
  float: right;
`;

const StyledMarkPresentButton = styled.button`
  width: 121px;
  height: 33px;
  background-color: ${(props) =>
    !props.adminTheme ? COLORS.PUERTO_RICO : COLORS.DODGER_BLUE};
  color: ${COLORS.WHITE};
  border: 0;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 5px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: ${FONT[14]};
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 140px;
    font-size: ${FONT[16]};
  }

  &:disabled {
    background-color: ${COLORS.GRAY};
    cursor: not-allowed;
  }

  span {
    @include large-font-mixin;
  }
`;

export default ManagementTable;
