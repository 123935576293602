import get from 'lodash/get';
import { APIs, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import api from './api';

/** Get virtual wallet information */
export const fetchWalletInformation = async (token) => {
  return api({
    url: APIs.walletInformation,
    method: Method.get,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.wallet', null);
    })
    .catch(() => {
      Promise.reject(ERRORS.REQUEST_FAILED);
    });
};
/** Fetch the incoming balance for a current wallet */
export const getIncomingBalance = async (token) => {
  return api({
    url: APIs.walletIncomingBalance,
    method: Method.get,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

/** Withdraw the whole amount from the wallet */
export const withdrawFromWallet = async (body, token) => {
  return api({
    url: APIs.walletWithdraw,
    method: Method.post,
    body,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.transactionHash', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const revertFailedTransaction = async (body, token) => {
  return api({
    url: APIs.revertTransaction,
    method: Method.put,
    body,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
