import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { socialMediaIconList } from '../../constants';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, SHARP_IMAGE } from '../../styles/constants/display';

const SocialIconGenerator: React.FC<any> = ({
  link,
  width = 34,
  height = 34,
}) => {
  const result = socialMediaIconList.find((el) => link.includes(el.name)) || {
    name: 'default',
    value: `/static/images/browser.png`,
    backgroundColor: `#E8E8E8`,
    filter: null,
  };

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <StyledContainer result={result} width={width} height={height}>
        <img src={result.value} alt="icons" />
      </StyledContainer>
    </a>
  );
};

export default SocialIconGenerator;

const StyledContainer = styled.div<any>`
  background-color: ${({ result }) =>
    get(result, 'backgroundColor', COLORS.DODGER_BLUE)};
  width: ${({ width }) => `${width}px`} !important;
  height: ${({ height }) => `${height}px`};
  padding: 7px;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  border-radius: 100%;
  cursor: pointer;

  img {
    ${SHARP_IMAGE}
    ${({ result }) =>
      get(result, 'filter') ? `filter: ${get(result, 'filter')}` : ''};
    height: ${({ height, result }) =>
      `${height - (get(result, 'extraSmall', false) ? 18 : 15)}px`} !important;
  }
`;
