import { STATUS_ACTIVE, STATUS_DELETE } from '../constants';
import get from 'lodash/get';
import { COLORS } from '../styles/constants/colors';
import { getLatestBookingStatus, jobsReorder } from './event';
import store from '../store/store';
import { allowAccessWithoutCryptoIncentivePlan } from '../constants/crypto';

// Recrui - job applicationst
export const jobOptions = (jobDetailsData) => {
  return jobsReorder(jobDetailsData).map((job) => ({
    id: job.id,
    value: job.id,
    label: job.designation,
  }));
};

// Get available events
export const getAvailableEvents = (events) => {
  return events
    .filter(
      (event) =>
        getLatestBookingStatus(event, 'eventCompanys') === STATUS_ACTIVE,
    )
    .map((event) => {
      return {
        id: event.id,
        name: event.name,
        companies: event.eventCompanys,
        value: event.id,
        label: event.name,
      };
    });
};

/**
 * Takes following params and returns object containing colors
 * @param {object} skillLevel
 * @param {object} matches
 * @param {number} index
 * @returns
 */
export const getColorBySkillPreference = (skillLevel, matches, index) => {
  switch (true) {
    case skillLevel[matches[index].toLowerCase()] === STATUS_ACTIVE:
      return {
        bgColor: COLORS.TANGERINE_YELLOW,
        color: COLORS.WHITE,
      };
    case skillLevel[matches[index].toLowerCase()] === STATUS_DELETE:
      return {
        bgColor: COLORS.METALLIC_SILVER,
        color: COLORS.WHITE,
      };
    default:
      return {
        bgColor: COLORS.DODGER_BLUE,
        color: COLORS.WHITE,
      };
  }
};

export const canNavigateAccrossRoutes = () => {
  const currentState = store.getState();
  const allowAccess = get(
    currentState.profile,
    'profileData.allowAccessWithoutCryptoIncentivePlan',
    allowAccessWithoutCryptoIncentivePlan.SKIPPED,
  );

  if (
    allowAccess === allowAccessWithoutCryptoIncentivePlan.ENABLE ||
    allowAccess === allowAccessWithoutCryptoIncentivePlan.SKIPPED
  ) {
    return true;
  }

  return false;
};
