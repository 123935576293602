import React from 'react';

const ArrowDropDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.255"
      height="17.255"
      viewBox="0 0 17.255 17.255"
    >
      <g opacity="0.5">
        <path data-name="Path 7" d="M0 0h17.255v17.255H0z" fill="none" />
        <path
          data-name="Path 8"
          d="m7 10 3.595 3.595L14.189 10z"
          transform="translate(-1.967 -2.811)"
          fill="#141414"
        />
      </g>
    </svg>
  );
};

export default ArrowDropDown;

export const FilterArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.303"
      height="3.929"
      viewBox="0 0 7.303 3.929"
    >
      <g data-name="Group 8235">
        <path
          data-name="Path 2479"
          d="M27.462 992.233a.7.7 0 0 1-.389-.166l-2.935-2.516a.7.7 0 1 1 .909-1.061l2.481 2.127 2.481-2.127a.7.7 0 1 1 .909 1.061l-2.935 2.516a.7.7 0 0 1-.521.166z"
          transform="translate(-23.876 -988.307)"
          fill="#5b5b5b"
        />
      </g>
    </svg>
  );
};
