/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';
import Picker from 'emoji-picker-react';
import get from 'lodash/get';
import Emoji from '../../../assets/img/smile.png';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN } from '../../../styles/constants/display';

export const EmojiPicker: React.FC<any> = ({ setMessage, onSendMessage }) => {
  const onEmojiClick = (event: any, emojiObject: any) => {
    if (get(event, 'target.nodeName') !== 'BUTTON') {
      setMessage((prev: any) => prev + get(emojiObject, 'emoji', ''));
    }
  };

  return (
    <Popover
      content={
        <StyledPicker>
          <Picker onEmojiClick={onEmojiClick} preload disableAutoFocus={true} />
        </StyledPicker>
      }
      title={null}
      trigger="click"
      overlayStyle={{
        zIndex: 4000,
      }}
      overlayClassName={
        "emoji-overlay-wrapper"
      }
    >
      <StyledEmojiIcon src={Emoji} />
    </Popover>
  );
};

const StyledEmojiIcon = styled.img`
  width: 18px;
  height: 18px;
  ${MARGIN(0, 8)};
  cursor: pointer;
`;

const StyledPicker = styled.div`
  margin: -15px;
  @media screen and (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}){
    > aside.emoji-picker-react{
      width: 100% !important;
    }
  }
`;
