import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PricingCardList from './PricingCardList';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { PrimaryButton } from '../../components/shared-components/Button';
import { useAccount } from 'wagmi';
import {
  CryptoPlanProvider,
  usePlanSelector,
} from '../../context/CryptoPlanProvider';
import { useGetContract } from '../../hooks/USDC';
import { PAYMENT_STATUS } from '../../enums/payment-status';
import Notification from '../../components/Notification/Notification';
import { useDispatch } from 'react-redux';
import { updateCryptoIncentivePlan } from '../../store/features/cryptoPlan';
import { useHistory, useLocation } from 'react-router-dom';
import { RecruiterRoutes } from '../../constants/routes';
import { usePaymentInformation } from '../../hooks/paymentInformation';
import TransactionModal from '../../components/Web3Payment/TransactionModal';
import useTransactionModal from '../../hooks/useTransactionModal';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { skipOrEnablePaymentAccess } from '../../store/features/profile';
import { COLORS } from '../../styles/constants/colors';
import { useSkipPayment } from '../../hooks/useSkipPayment';
import { useGetRecruiterRedirectQuery } from '../../store/features/rtkApi/recruiterApi';
import get from 'lodash/get';

const PricingPlan = () => {
  const { isConnected: isWalletConnected } = useAccount();
  const { selectedPlan } = usePlanSelector();
  const { transferUSDC } = useGetContract();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { isRecruiterExisting, paymentCompleted } = usePaymentInformation();
  const { isBlocked } = useSkipPayment();
  const { data: recruiterRedirectToDashboard } = useGetRecruiterRedirectQuery();
  const {
    isVisible,
    closeModal,
    openModal,
    startProcessing,
    stopProcessing,
    ongoingTransaction,
  } = useTransactionModal();
  const { openConnectModal } = useConnectModal();
  const firstTimeRef = React.useRef(true);

  /** This useEffect is responsible if the payment is completed but still it is going in the pricing plan then redirect to recruiter dashboard */
  useEffect(() => {
    if (paymentCompleted && firstTimeRef.current) {
      history.push(RecruiterRoutes.recruiter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentCompleted, history]);

  useEffect(() => {
    let isPageOpen = true;
    if (!isWalletConnected && isPageOpen) {
      openConnectModal();
    }

    return () => {
      isPageOpen = false;
    };
    // restricting this page to open the modal only once when first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToProperRouteAfterPaymentOrSkip = useCallback(() => {
    // check for event-invite params
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('eventId') || queryParams.has('inviteToken')) {
      history.push(
        `/recruiter/events/view/${queryParams.get(
          'eventId',
        )}?inviteToken=${queryParams.get('inviteToken')}`,
      );
    } else {
      if (recruiterRedirectToDashboard) {
        history.push(RecruiterRoutes.recruiter);
      } else {
        history.push(RecruiterRoutes.createJob);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiterRedirectToDashboard]);

  const handlePayment = useCallback(
    async (id, amount, couponCode = null) => {
      try {
        startProcessing();
        const txnHash = await transferUSDC(amount);
        let requestBody = {
          cryptoIncentivePlanId: id,
          paymentStatus: PAYMENT_STATUS.Successful,
          transactionHash: txnHash,
        };
        if (!!couponCode) requestBody = { ...requestBody, couponCode };
        dispatch(updateCryptoIncentivePlan(requestBody)).then((response) => {
          firstTimeRef.current = false;
          closeModal();
          if (get(response, 'payload.data.payload.success', false)) {
            redirectToProperRouteAfterPaymentOrSkip();
          }
        });
      } catch (error) {
        stopProcessing();
        Notification('error', 'Could not make the payment');
      }
    },
    [
      dispatch,
      stopProcessing,
      startProcessing,
      transferUSDC,
      closeModal,
      redirectToProperRouteAfterPaymentOrSkip,
    ],
  );

  const handleProceed = () => {
    // if wallet is not connected
    if (!isWalletConnected) {
      return Notification('error', 'Connect your wallet to proceed.');
    }
    // if no plan is selected
    if (selectedPlan === null) {
      return Notification('error', 'Please select a plan first!');
    }
    openModal();
  };

  /** For skipping the payment */
  const handleSkip = useCallback(async () => {
    dispatch(skipOrEnablePaymentAccess());
    redirectToProperRouteAfterPaymentOrSkip();
  }, [redirectToProperRouteAfterPaymentOrSkip, dispatch]);

  return (
    <StyledContainer>
      <StyledHeading>
        <h1>
          {isRecruiterExisting
            ? `Select a plan to continue using our services`
            : `For using our services, please proceed with the payment`}
        </h1>
      </StyledHeading>
      <PricingCardList />
      <StyledButtonContainer>
        <PrimaryButton
          padding="20px 40px"
          theme="recruiter"
          onClick={handleProceed}
        >
          Proceed
        </PrimaryButton>
        {isBlocked() ? null : (
          <div>
            <span onClick={handleSkip}>{`Skip>`}</span>
          </div>
        )}
      </StyledButtonContainer>

      {isWalletConnected ? (
        <TransactionModal
          isVisible={isVisible}
          closeModal={closeModal}
          handlePayment={handlePayment}
          ongoingTransaction={ongoingTransaction}
          skipPayment={handleSkip}
        />
      ) : null}
    </StyledContainer>
  );
};

const PricingPlanMain = () => {
  return (
    <CryptoPlanProvider>
      <PricingPlan />
    </CryptoPlanProvider>
  );
};
export default PricingPlanMain;

const StyledContainer = styled.div`
  min-height: 100vh;
  ${PADDING(30, 0)}
  ${DISPLAY_FLEX('column', null, 'center')}

  @media (min-width : ${BREAKPOINTS.TABLET_MAX}) and (max-width : ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3}) {
    align-items: flex-start;
  }
`;

const StyledHeading = styled.div`
  width: 100%;
  ${PADDING(0, 34)}
  h1 {
    font-size: 30px;
    text-align: left;

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      font-size: 24px;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 18px;
      text-align: center;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_SPECIAL_CASE_2}) {
    h1 {
      text-align: center;
    }
  }
`;

const StyledButtonContainer = styled.div`
  ${MARGIN(25, 0, 0, 0)}
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 10px;
  button {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_MAX}) and (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3}) {
    width: 100%;
    ${DISPLAY_FLEX('row', 'center')}
  }
  span {
    color: ${COLORS.GRAY2};
    cursor: pointer;
    font-size: 14px;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 12px;
    }
  }
`;
