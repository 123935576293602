import get from 'lodash/get';
import { isValid } from '../utils/api';
import ERRORS from '../constants/errors';
import api from './api';
import { APIs, Method } from '../constants';
import Config from '../config';

export const getCountryCode = async () => {
  return api({
    url: `${APIs.index}countries/codes/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getDomains = async () => {
  return api({
    url: `${APIs.index}domains/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.domains', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCurrencies = async () => {
  return api({
    url: `${APIs.index}currencies/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.currencies', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getNationalityList = async () => {
  return api({
    url: `${APIs.index}nationalities/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.currencies', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getSkillsList = async () => {
  return api({
    url: APIs.skill,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.skills', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCountries = async () => {
  return api({
    url: `${APIs.index}countries/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getStates = async (country) => {
  return api({
    url: `${APIs.index}states/${country}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getQuerySkills = async (words) => {
  return api({
    url: `${APIs.skill}?find=${words}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.skills', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecommendedSkills = async (skillIds) => {
  return api({
    url: `${APIs.skill}related/skill-prompt?skillIds=${skillIds}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.skills', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getTimeZones = async () => {
  return api({
    url: `${APIs.index}timezones/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.timezones', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getFile = (fileName) => {
  return fileName ? `${Config.baseURL}${APIs.index_file}${fileName}/` : null;
};

export const uploadFile = async (file, fileType = 'file') => {
  const url = `${APIs.index}upload/file?fileType=${fileType}`;
  const method = Method.post;
  const headers = { Accept: 'application/json' };

  const contentType = 'multipart/form-data';

  const formData = new FormData();
  formData.append('file', file);

  return api({ url, method, headers, body: formData, contentType })
    .then(async (response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return await get(response, 'data.filename', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteCertificate = async (fileName) => {
  return api({
    url: `${APIs.index}delete/certificate/`,
    method: Method.put,
    body: {
      file: fileName,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.domains', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getUserFeatureTours = async () => {
  return api({
    url: `${APIs.index}user/feature-tour/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.featureTours', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getUserParticularFeatureTour = async (tour = '') => {
  return api({
    url: `${APIs.index}user/feature-tour/particular/?tour=${tour}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.completed', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const completeUserParticularFeatureTour = async (body) => {
  return api({
    url: `${APIs.index}user/feature-tour/particular/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.success', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCategories = async () => {
  return api({
    url: `${APIs.index}category/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.categories', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCategoriesForDomain = async (industry) => {
  return api({
    url: `${APIs.index}industry/${encodeURIComponent(industry)}/category/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.categories', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getActiveCategories = async () => {
  return api({
    url: `${APIs.index}categories/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.records', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
