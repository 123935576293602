import React, { useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import ConfigContext from '../store/configContext';
import Dashboard from './SuperAdmin/Dashboard';
import Profile from './SuperAdmin/Profile';
import Admins from './SuperAdmin/AdminManagement/Admins';
import CandidateIcon from '../assets/svg/User.svg';
import DashboardIcon from '../assets/icons-and-sets/dashboard.svg';
import Settings from '../assets/svg/settings.svg';
import { SuperAdminRoutes } from '../constants/routes';
import { checkIfUserIsSuperAdmin, getRoleId } from '../utils/userInfo';
import { redirectToProperUserRole } from '../utils/common';

const SuperAdmin = () => {
  const { navigationList, setNavigationList } = useContext(ConfigContext);

  const roleId = getRoleId();

  if (roleId && !checkIfUserIsSuperAdmin(roleId)) {
    redirectToProperUserRole(roleId);
  }

  useEffect(() => {
    setNavigationList([
      {
        Icon: DashboardIcon,
        title: 'Dashboard',
        route: SuperAdminRoutes.dashboard,
        exact: true,
        Component: Dashboard,
      },
      {
        Icon: CandidateIcon,
        title: 'User Management',
        route: SuperAdminRoutes.userManagement,
        Component: Admins,
      },
      {
        Icon: Settings,
        title: 'Profile',
        route: SuperAdminRoutes.profile,
        Component: Profile,
      },
    ]);
  }, [setNavigationList]);

  return (
    <div>
      <Switch>
        {navigationList.map((navItem, idx) => {
          const { route, Component, exact } = navItem;
          return (
            <Route
              key={idx}
              path={route}
              component={Component}
              exact={!!exact}
            />
          );
        })}
      </Switch>
    </div>
  );
};

export default SuperAdmin;
