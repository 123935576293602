import styled from 'styled-components';
import React from 'react';
import { AvatarPicture } from '../shared-components/AvatarPicture';

const AvatarOnline: React.FC<any> = ({ picture, isOnline }) => {
  return (
    <StyledRelativeContainer className="online_avatar_wrapper">
      <AvatarPicture picture={picture} size={44} />
      {isOnline ? <StyledOnlineDot /> : null}
    </StyledRelativeContainer>
  );
};

export default AvatarOnline;

const StyledOnlineDot = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: green;
  right: 1px;
  bottom: 1px;
  border-radius: 100%;
`;

const StyledRelativeContainer = styled.div`
  position: relative;
`;
