import React, { useState, useEffect } from 'react';
import { Table, PageHeader, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import styled from 'styled-components';
import AddAdmin from './AddAdmin';
import {
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
} from '../../../service/superAdmin';
import { DEFAULT_LIMIT, DEFAULT_PAGE, SUPER_ADMIN } from '../../../constants';
import { makeAdminTableData } from '../../../utils/superAdmin/admin';
import { AdminTableColumns } from '../../../constants/superAdmin';
import CustomPagination from '../../../components/Common/CustomPagination';
import { Heading } from '../../../components/shared-components/Texts';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { PageContainer } from '../../../components/shared-components/PageContainer';
import { RightAlignContainer } from '../../../components/shared-components/RightAlignContainer';
import Circle from '../../../components/shared-components/PageCircle';

const { confirm } = Modal;

const DeleteUserConfirm = (record, handleDelete) => {
  confirm({
    title: `Do you Want to delete ${record.name}?`,
    icon: <ExclamationCircleOutlined />,
    onOk: () => {
      handleDelete(record.id);
    },
  });
};

const Admins = () => {
  const [showAddUser, setShowAddUser] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT);
  const [pagination, setPagination] = useState({
    currentPage: null,
    totalItems: null,
  });

  const handleDeleteUser = async (userId) => {
    await deleteAdmin({ userId }).then(() => {
      populateData(currentPage, currentLimit);
    });
  };

  const handleEditConfirmation = (record) => {
    setEditRecord(record);
    setShowAddUser(true);
  };

  const populateData = (page = DEFAULT_PAGE, limit = DEFAULT_LIMIT) => {
    getAdmins(page, limit).then((response) => {
      const users = get(response, 'users', []);
      setAdmins(
        makeAdminTableData(
          users,
          handleEditConfirmation,
          DeleteUserConfirm,
          handleDeleteUser,
        ),
      );
      setPagination({
        currentPage: get(response, 'currentPage', null),
        totalItems: get(response, 'totalItems', null),
      });
    });
  };

  useEffect(() => {
    populateData();
    // eslint-disable-next-line
  }, []);

  const handleCloseModal = () => {
    setShowAddUser(false);
    setEditRecord({});
  };

  const handleAddUser = async (data) => {
    await createAdmin(data).then(() => {
      populateData(currentPage, currentLimit);
    });
    handleCloseModal();
  };

  const handleUpdateUser = async (data) => {
    await updateAdmin(data, editRecord.id).then(() => {
      populateData(currentPage, currentLimit);
    });
    handleCloseModal();
  };

  useEffect(() => {
    populateData(currentPage, currentLimit);

    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  const onPageChangeHandler = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onLimitChangeHandler = async (pageNumber, limit) => {
    setCurrentPage(pageNumber);
    setCurrentLimit(limit);
  };

  const handleAddAdmin = () => {
    setEditRecord(null);
    setShowAddUser(true);
  };

  return (
    <>
      <Circle color={getUserColorWithRoleId(SUPER_ADMIN)} />
      <Heading color={getUserColorWithRoleId(SUPER_ADMIN)}>Admin</Heading>
      <PageContainer>
        <PageHeader
          title="User Management"
          extra={[
            <Button key="1" type="primary" onClick={handleAddAdmin}>
              Add User
            </Button>,
          ]}
        >
          <StyledTableContainer>
            <Table
              columns={AdminTableColumns}
              dataSource={admins}
              pagination={false}
            />
            <RightAlignContainer>
              <CustomPagination
                currentPage={pagination.currentPage || currentPage}
                totalItems={pagination.totalItems || admins.length}
                onPageChange={onPageChangeHandler}
                onLimitChange={onLimitChangeHandler}
                currentLimit={currentLimit}
              />
            </RightAlignContainer>
          </StyledTableContainer>
          {showAddUser && (
            <AddAdmin
              handleCancel={handleCloseModal}
              handleSubmit={!editRecord ? handleAddUser : handleUpdateUser}
              record={editRecord}
            />
          )}
        </PageHeader>
      </PageContainer>
    </>
  );
};

export default Admins;

const StyledTableContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  height: max-content;
`;
