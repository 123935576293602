import React from 'react';
import { Row, Col } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import styled from 'styled-components';
import { InputBox, DropdownBox } from '../shared-components/Form/Form';
import { highestEducationList } from '../../mocks/profile/highestEducationList';
import { ADD, DELETE } from '../../constants';
import { ProfileInformationHeader } from '../CrewManagement/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { HiddenMobile } from '../shared-components/DocumentViewerModal';
import { MARGIN } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';

const CreateEducationInfoRow = ({
  educations,
  setEducations,
  text,
  headingColor,
}) => {
  const handleRemoveEducationInfoRow = (i) => {
    const temp = [...educations];
    if (get(educations[i], 'operation', ADD) === ADD) {
      temp.splice(i, 1);
    } else {
      temp[i] = {
        ...educations[i],
        id: educations[i].id || i,
        operation: DELETE,
      };
    }
    setEducations(temp);
  };

  const handleSchoolNameChange = (i, event) => {
    const temp = [...educations];
    temp[i] = {
      ...educations[i],
      school: event.target.value,
    };
    setEducations(temp);
  };

  const handleDegreeChange = (i, val) => {
    const temp = [...educations];
    temp[i] = {
      ...educations[i],
      degree: val.children,
    };
    setEducations(temp);
  };

  const handleMajorChange = (i, event) => {
    const temp = [...educations];
    temp[i] = {
      ...educations[i],
      major: event.target.value,
    };
    setEducations(temp);
  };

  if (!isArray(educations) || educations.length === 0) {
    return null;
  }

  return (
    <StyledMainContainer>
      {educations.map((education, idx) => (
        <div key={idx.toString()}>
          {education.operation !== DELETE ? (
            <>
              <HiddenMobile>
                <StyledRow gutter={[25, 24]}>
                  <Col xs={24} md={7}>
                    <ProfileInformationHeader color={headingColor}>
                      School Name
                    </ProfileInformationHeader>
                  </Col>
                  <Col xs={24} md={7}>
                    <ProfileInformationHeader color={headingColor}>
                      Highest Education
                    </ProfileInformationHeader>
                  </Col>
                  <Col xs={24} md={7}>
                    <ProfileInformationHeader color={headingColor}>
                      Specialization
                    </ProfileInformationHeader>
                  </Col>
                  <Col xs={24} md={3}>
                    <ProfileInformationHeader />
                  </Col>
                </StyledRow>
              </HiddenMobile>
              <Row gutter={[25, 24]} align="middle">
                <Col xs={24} md={7}>
                  <InputBox
                    value={education.school}
                    placeholder="School Name"
                    handleChange={(e) => handleSchoolNameChange(idx, e)}
                    required
                  />
                </Col>
                <Col xs={24} md={7}>
                  <DropdownBox
                    value={education.degree}
                    placeholder="Highest Education"
                    options={highestEducationList}
                    handleChange={(e, val) => handleDegreeChange(idx, val)}
                    required
                  />
                </Col>
                <Col xs={24} md={7}>
                  {/* NOTE: Can be used some time in future */}
                  {/* <DropdownBox
                  value={education.major}
                  placeholder="Specializaiton"
                  options={fieldOfStudyList}
                  handleChange={(e, val) => handleMajorChange(idx, val)}
                /> */}
                  <InputBox
                    value={education.major}
                    placeholder="specialization"
                    handleChange={(e) => handleMajorChange(idx, e)}
                    required
                  />
                  <StyledRemoveIconContainer>
                    {text ? (
                      <StyledRemoveText
                        onClick={() => handleRemoveEducationInfoRow(idx)}
                      >
                        {text || '- Remove Education Detail'}
                      </StyledRemoveText>
                    ) : (
                      <StyledRemoveIcon
                        onClick={() => handleRemoveEducationInfoRow(idx)}
                      />
                    )}
                  </StyledRemoveIconContainer>
                </Col>
                <HideMobile>
                  <Col xs={24} md={2}>
                    {text ? (
                      <StyledRemoveText
                        onClick={() => handleRemoveEducationInfoRow(idx)}
                      >
                        {text || '- Remove Education Detail'}
                      </StyledRemoveText>
                    ) : (
                      <StyledRemoveIcon
                        onClick={() => handleRemoveEducationInfoRow(idx)}
                      />
                    )}
                  </Col>
                </HideMobile>
              </Row>
            </>
          ) : null}
        </div>
      ))}
    </StyledMainContainer>
  );
};

export default CreateEducationInfoRow;

const StyledMainContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(25, 0, 0, 0)};
  }
`;

const StyledRemoveText = styled.div`
  color: ${COLORS.CUSTOM_RED};
  font-weight: 500;
`;

const StyledRow = styled(Row)`
  ${MARGIN(15, 0, 0, 0)}
`;

const StyledRemoveIcon = styled(MinusCircleOutlined)`
  color: ${COLORS.CUSTOM_RED};
  margin-left: 20px;
  svg {
    font-size: 24px;
    margin-top: 5px;
  }
`;

const StyledRemoveIconContainer = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block;
    margin: 10px 0;
    text-align: right;
  }
`;

const HideMobile = styled.div`
  display: block;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;
