import { notification } from 'antd';
import { DEFAULT_NOTIFICATION_DURATION } from '../../constants';

// Temp store descriptionQueue for ref
const descriptionQueue = [];

const Notification = (
  type,
  message,
  description,
  duration = DEFAULT_NOTIFICATION_DURATION,
) => {
  if (!descriptionQueue.includes(description)) {
    notification[type]({
      message,
      description,
      className: 'small-font',
      onClose: () => {
        // Find & remove the message from messageQueue
        const index = descriptionQueue.indexOf(description);
        descriptionQueue.splice(index, 1);
      },
      duration,
    });
    // Push to messageQueue
    descriptionQueue.push(description);
  }
};

export default Notification;
