import React, { useRef, useEffect } from 'react';
import { Dropdown } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useWaitForTransaction } from 'wagmi';
import { IDENTITY_VERIFICATION } from '../../../enums/identity-verification';
import DropdownWalletModal from './DropdownWalletModal';
import ActiveWallet from '../../../assets/svg/ActiveWallet.svg';
import InActiveWallet from '../../../assets/svg/InactiveWallet.svg';
import { useVirtualWallet } from '../../../hooks/useVirtualWallet';
import { TransactionStatus } from '../../../constants/web3';
import {
  getWalletInformation,
  revertWalletBalance,
} from '../../../store/features/virtualWallet';
import Notification from '../../Notification/Notification';

const WalletDropdown = ({ isSidebar }) => {
  const walletIconRef = useRef();
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const identityVerification = useSelector(
    (state) => state.profile.profileData.identityVerification,
  );
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const { txnHash } = useVirtualWallet();
  // Waiting for transaction to happen
  const { status: txnStatus } = useWaitForTransaction({
    hash: txnHash,
  });

  /** This useEffect is responsible for checking clicks outside of wallet icon and dropdown overlay if that happens then only the dropdown will close */
  useEffect(() => {
    let handler = (event) => {
      if (
        !dropdownRef.current?.contains(event.target) &&
        !walletIconRef.current?.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  /** Checking for whether the transaction is sucessfull or not */
  useEffect(() => {
    // transaction successfull
    if (txnStatus === TransactionStatus.SUCCESS) {
      dispatch(getWalletInformation());
      return Notification('success', 'Successfully withdrawn the balance');
    }
    // loading state i.e transaction processing
    else if (txnStatus === TransactionStatus.LOADING) {
      return Notification(
        'info',
        'Transaction processing do not refresh or go back!',
      );
    }
    // error - Transaction failed
    else if (txnStatus === TransactionStatus.ERROR) {
      Notification('error', 'Unexpected error occured, please try again.');
      return dispatch(
        revertWalletBalance({
          transactionHash: txnHash,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnStatus, dispatch]);

  return (
    <Dropdown
      overlayStyle={{ position: 'fixed' }}
      overlay={
        <DropdownWalletModal
          dropdownRef={dropdownRef}
          isSidebar={isSidebar}
          txnStatus={txnStatus}
        />
      }
      visible={dropdownVisible}
    >
      <div
        ref={walletIconRef}
        onClick={() => setDropdownVisible((prev) => !prev)}
      >
        <img
          // src will be based on the condition
          src={
            identityVerification === IDENTITY_VERIFICATION.Verified
              ? ActiveWallet
              : InActiveWallet
          }
          alt="active-wallet"
          height={30}
          width={30}
        />
        {isSidebar ? (
          <span
            data-verified={
              identityVerification === IDENTITY_VERIFICATION.Verified
                ? 'active'
                : 'inactive'
            }
          >
            {'Credits'}
          </span>
        ) : null}
      </div>
    </Dropdown>
  );
};

export default React.memo(WalletDropdown);
