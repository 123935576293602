import React from 'react';
import { Dropdown } from 'antd';

export const ShareOverlay = ({ children, ShareIcons }) => {
  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Dropdown
      // overlay={<SocialShareIcons event={event} jobDetails={jobDetails} />}
      overlay={ShareIcons}
      trigger={['click', 'hover']}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={onClick}>{children}</div>
    </Dropdown>
  );
};
