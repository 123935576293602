import React, { createContext, useEffect } from 'react';
import { Client } from '@hapi/nes/lib/client';
import { useSelector } from 'react-redux';
import Config from '../config';
import { getAccessToken } from '../utils/storage';

let socketUrl = '';
if (Config.chatBaseURL) {
  socketUrl = `${Config.appEnv !== 'local' ? 'wss' : 'ws'}://${
    Config.chatBaseURL.split('//')[1]
  }`;
}

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const client = new Client(socketUrl);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.accessToken && client) {
      (async () => {
        const { accessToken } = await getAccessToken();
        await client.connect({
          auth: {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        });
      })();
    }
    // eslint-disable-next-line
  }, [client, user?.accessToken]);

  return (
    <SocketContext.Provider value={client}>{children}</SocketContext.Provider>
  );
};

export default SocketContext;
