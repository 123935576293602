/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Notification from '../Notification/Notification';
import { styledStateText } from './Styled-components/EventCardCommon';
import {
  isEventOnline,
  getEventFormaterDate,
  getUpComingEvent,
  handleJoinEvent,
  checkEventBookingInfo,
  getPastEvent,
} from '../../utils/event';
import { EventCalendarIcon, PinIcon } from '../svgicons';
import {
  LIVE_TEXT,
  ONGOING_TEXT,
  ONLINE_EVENT,
  PAST_TEXT,
  UPCOMING_TEXT,
} from '../../constants';
import { getFileS3 } from '../../utils/s3.storage';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import {
  DISPLAY_FLEX,
  MARGIN,
  SHARP_IMAGE,
} from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { viewEventUrl } from '../Events/Common';
import { smallFontMixin } from '../../styles/constants/mixins';
import { checkIfUserIsCandidate } from '../../utils/userInfo';
import { useTagManager } from '../../utils/customHooks';
import getNow from '../../utils/getNow';
import { userInfoRegardingChatroom } from '../../utils/chat';
import {
  bookEvent,
  checkIfCandidateIsPresentInEvent,
} from '../../service/event';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { checkAndReGenerate } from '../../store/features/chat';
import { EventStatusLabel } from '../../utils/event';
import EventEndTimer from './Event/EventEndTimer';

const EventCard = ({
  event,
  roleId,
  width,
  height,
  analytics,
  dashboard,
  category,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = userInfoRegardingChatroom();

  const { id } = event;
  const viewEventPageUrl = viewEventUrl(roleId, id);

  const thirdPartyData = get(event, 'thirdPartyData', []);
  const updateUserActionInGTM = useTagManager();
  const eventStatus = EventStatusLabel(event);

  const handleBookEvent = async () => {
    if (!event || getPastEvent(event)) {
      return true;
    }
    const res = await checkEventBookingInfo(event.id);
    if (res.status === 200) {
      const payload = await get(res, 'data.payload', null);

      const isEventBooked = await get(payload, 'isEventBooked', false);

      if (!isEventBooked) {
        if (
          event.userTicketPrice &&
          checkIfUserIsCandidate(roleId) &&
          thirdPartyData.length === 0
        ) {
          history.push(viewEventPageUrl);
          return false;
        }
        updateUserActionInGTM('event_booked');
        const freeEventBody = {
          eventId: event.id,
          paymentDate: getNow(),
        };

        await bookEvent(freeEventBody);
        return true;
      }
      history.push(viewEventPageUrl);
      return false;
    }
  };

  async function handleBookAndJoinEvent() {
    const response = await handleBookEvent();
    if (response) {
      Notification(
        'success',
        'Event Registered',
        'Hold on tight as we find you amazing jobs.',
      );
      await handleJoinEvent(event, roleId, userData, history, 1000, () =>
        checkIfCandidateIsPresentInEvent(event.id),
      ).then(() => {
        if (checkIfUserIsCandidate(roleId)) {
          dispatch(checkAndReGenerate());
        }
        updateUserActionInGTM('event_joined');
        history.push(viewEventPageUrl);
      });
    }
  }

  function handleEventCard() {
    if (checkIfUserIsCandidate(roleId)) {
      return !getUpComingEvent(event)
        ? handleBookAndJoinEvent()
        : history.push(viewEventPageUrl);
    }
    return history.push(viewEventPageUrl);
  }

  return (
    <StyledFlex>
      <StyledMainContainer dashboard={dashboard}>
        <StyledCardContainer category={category}>
          <div onClick={handleEventCard} role="none">
            <StyledCardFrontContainer>
              <StyledImageContainer>
                <StyledImage
                  src={getFileS3(get(event, 'cover', ''))}
                  alt="event card"
                />
                {checkIfUserIsCandidate(roleId) ? (
                  category === 'recommended' ? (
                    <StyledStatus eventStatus={eventStatus}>
                      <StyledBadge>
                        <p>{eventStatus}</p>
                      </StyledBadge>
                    </StyledStatus>
                  ) : null
                ) : (
                  <StyledStatus eventStatus={eventStatus}>
                    <StyledBadge>
                      <p>{eventStatus}</p>
                    </StyledBadge>
                  </StyledStatus>
                )}
              </StyledImageContainer>
              <StyledEventInfoContainer category={category}>
                <StyledEventHeaderContainer>
                  <StyledHeadingContainer>
                    <h4>{get(event, 'name', '')}</h4>
                  </StyledHeadingContainer>
                </StyledEventHeaderContainer>

                <StyledWrapper>
                  <StyledIconAndTextContainer>
                    <div>
                      <StyledInfoIconContainer>
                        <EventCalendarIcon />
                      </StyledInfoIconContainer>
                      {eventStatus === LIVE_TEXT ||
                      eventStatus === ONGOING_TEXT ? (
                        <EventEndTimer dateTime={get(event, 'endDateTime')} />
                      ) : (
                        <StyledTime
                          dateTime={moment
                            .unix(get(event, 'dateTime', null))
                            ?.format()}
                        >
                          {getEventFormaterDate(get(event, 'dateTime', null))}
                        </StyledTime>
                      )}
                    </div>
                  </StyledIconAndTextContainer>
                  <StyledIconAndTextContainer>
                    <StyledInfoIconContainer>
                      <PinIcon />
                    </StyledInfoIconContainer>
                    <StyledAddress>
                      <span>
                        {isEventOnline(get(event, 'eventType', ONLINE_EVENT))
                          ? 'Online'
                          : `${get(event, 'country', '')}, ${get(
                              event,
                              'zip',
                              '',
                            )}`}
                      </span>
                    </StyledAddress>
                  </StyledIconAndTextContainer>
                </StyledWrapper>
              </StyledEventInfoContainer>
            </StyledCardFrontContainer>
          </div>
        </StyledCardContainer>
      </StyledMainContainer>
      {checkIfUserIsCandidate(roleId) && dashboard && (
        <StyledLink to={viewEventPageUrl}>
          View Details <StyledRightOutlined />
        </StyledLink>
      )}
    </StyledFlex>
  );
};

export default EventCard;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${MARGIN(0, 0, 25, 5)}
  font-size: 13px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
    color: ${COLORS.RICH_BLUE};
  }
`;

const StyledRightOutlined = styled(RightOutlined)`
  svg {
    font-size: 12px;
  }
`;

const StyledMainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
`;

const StyledCardContainer = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) =>
    props.category === ONGOING_TEXT.toLowerCase() ||
    props.category === UPCOMING_TEXT.toLowerCase()
      ? '320px'
      : '325px'};
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer !important;
  &:hover img {
    transform: scale(1.1);
    border-radius: 5px;
  }
`;

const StyledCardFrontContainer = styled.div`
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
`;

const StyledImageContainer = styled.div`
  width: 100% !important;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const StyledBadge = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')};

  p {
    font-size: 10px;
    color: white;
    margin-bottom: -1px;
    font-weight: 500;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      margin-bottom: 0px;
    }
  }

  svg {
    width: 15px !important;
    height: 15px !important;
    fill: white;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      height: 16px !important;
      width: 16px !important;
    }
  }
`;

const keyframe = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const StyledStatus = styled.div`
  background: ${({ eventStatus }) =>
    eventStatus === ONGOING_TEXT
      ? `linear-gradient(to right, ${COLORS.ONGOING_EVENT_GRADIENT_1} 0%, ${COLORS.ONGOING_EVENT_GRADIENT_2} 100%)`
      : eventStatus === LIVE_TEXT
      ? `linear-gradient(to right, ${COLORS.LIVE_EVENT_GRADIENT_1} 0%, ${COLORS.LIVE_EVENT_GRADIENT_2} 50%, ${COLORS.LIVE_EVENT_GRADIENT_3} 100%)`
      : eventStatus === PAST_TEXT
      ? `linear-gradient(to right, ${COLORS.PAST_EVENT_GRADIENT_1} 0%, ${COLORS.PAST_EVENT_GRADIENT_2} 50%,  ${COLORS.PAST_EVENT_GRADIENT_3} 100%)`
      : `linear-gradient(to right, ${COLORS.UPCOMING_EVENT_GRADIENT_1} 0%, ${COLORS.UPCOMING_EVENT_GRADIENT_1} 50%,  ${COLORS.UPCOMING_EVENT_GRADIENT_3} 100%)`};
  animation: ${keyframe} 2s ease infinite;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
  width: 90px;
  height: 26px;
  border-radius: 50px;
  background-size: 250% 400%;
  text-align: center;
  ${DISPLAY_FLEX('row', 'center', 'center')};
  position: absolute;
  right: 10px;
  top: 11px;

  svg {
    margin: 0 4px;
  }
`;

const StyledEventInfoContainer = styled.div`
  position: relative;
  padding: 13px 10px;
  ${DISPLAY_FLEX('column', 'space-between')};
  height: ${(props) =>
    props.category === ONGOING_TEXT.toLowerCase() ||
    props.category === UPCOMING_TEXT.toLowerCase()
      ? '130px'
      : '140px'};
`;

const StyledWrapper = styled.div`
  ${MARGIN(12, 0, 0, 0)}

  p {
    font-size: 11px;
    margin-top: -3px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 10px;
    }
  }
`;

export const StyledEventCostTypeText = styled.span`
  ${smallFontMixin};
  font-weight: 800;
  color: ${COLORS.BLUE_BERRY};
`;
export const StyledEventText = styled.span`
  ${smallFontMixin};
  margin-left: 2px;
  margin-right: 4px;
`;
const StyledEventHeaderContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-gap: 5px;
`;

const StyledHeadingContainer = styled.div`
  h4 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const StyledAddress = styled.address`
  ${styledStateText};
  margin-bottom: 0;

  span {
    font-size: 12px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 12px;
      margin-left: 2px;
    }
  }
`;

const StyledTime = styled.time`
  ${styledStateText};
`;

export const StyledIconAndTextContainer = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 7px;
  margin-bottom: 10px;

  & > div {
    ${DISPLAY_FLEX('row', null, 'center')};
    gap: 7px;
  }
`;

export const StyledInfoIconContainer = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;

  line-height: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 14px;
    height: 14px;
  }

  svg {
    width: inherit;
    height: inherit;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  ${SHARP_IMAGE}
  border-radius: 5px 5px 0 0;
  height: 180px;
  transition: all 0.4s;

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    max-height: 240px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-height: 230px;
  }
`;

export const textWrap = (maxLines) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines}; /* number of lines to show */
  -webkit-box-orient: vertical;`;
