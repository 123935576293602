import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { SHADOWS } from '../../../styles/constants/shadows';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

interface StyledBroadcastTagContainerProps {
  blink?: boolean | null;
};

export const StyledBroadcastTagContainer = styled.div<StyledBroadcastTagContainerProps>`
  max-width: 135px;
  width: 100%;
  height: 37px;
  background: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.SHADOW5};
  border-radius: 20px;
  position: absolute;
  right: 14px;
  top: 12px;
  text-align: center;
  padding: 5px;
  color: red;
  animation: ${(props) => props.blink ? 'blink 2s infinite' : 'unset'};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    right: 28px;
    top: 19px;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    fill: ${COLORS.ORANGE_RED};
    width: 23px;
    margin-left: 7px;
    line-height: 16px;
    vertical-align: sub;
  }
`;
