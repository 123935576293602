import styled from 'styled-components';
import { Steps } from 'antd';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  mediumLargeHeadingMixin,
  smallFontMixin,
} from '../../../styles/constants/mixins';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../styles/constants/display';

export const StyledBoard = styled.div`
  position: relative;

  .steps-content {
    overflow: auto;
  }

  .circle_box {
    background-color: ${({ color }) => color};
  }
`;

export const StyledSteps = styled(Steps)`
  max-width: 80%;
  margin: 45px auto 36px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  .ant-steps-item-icon {
    border-radius: 100%;
    margin: 0 16px 0 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
  }

  .ant-steps-item-title {
    ${mediumLargeHeadingMixin};
    color: #262626;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    margin-bottom: 10px;
  }

  // Overide default ant styles

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    max-width: unset;
    ${smallFontMixin};
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    white-space: unset;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    max-width: unset;
    ${smallFontMixin};
    font-size: 12px;
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    white-space: unset;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    max-width: unset;
    ${smallFontMixin}
    font-size: 12px;
    white-space: unset;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ color }) => color};
  }

  .ant-steps-item-title::after {
    background-color: #707070 !important;
    opacity: 0.4;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${({ color }) => color};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background: #fff;
      border: 1px solid ${({ color }) => color};
      border-radius: 100%;
      width: 38px !important;
      height: 38px;

      .ant-steps-icon {
        ${DISPLAY_FLEX('row', 'center', 'center')}
        height: 32px;
        width: auto;
        svg {
          fill: ${({ color }) => color};
        }
      }
    }
  }

  .ant-steps-item-finish {
    transition: 0.3s;

    .ant-steps-item-icon {
      background: ${({ color }) => color} !important;
      border: 1px solid ${({ color }) => color};
      border-radius: 100%;
      width: 38px !important;
      height: 38px;

      .ant-steps-icon {
        ${DISPLAY_FLEX('row', 'center', 'center')}
        height: 32px;
        width: auto;
        svg {
          fill: ${COLORS.WHITE};
        }
      }
    }
    &:hover .ant-steps-item-icon {
      background: ${COLORS.LIGHT_ICON_BG_ADMIN} !important;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background: ${COLORS.WHITE};
      border-color: ${({ color }) => color};
      border: 1px solid #a2acab;
      border-radius: 100%;
      width: 38px !important;
      height: 38px;

      .ant-steps-icon {
        ${DISPLAY_FLEX('row', 'center', 'center')}
        color: ${({ color }) => color};
        height: 32px;
        width: auto;
        svg {
          fill: ${COLORS.DAVYS_GREY};
          transition: 0.3s;
        }
      }
    }
    &:hover .ant-steps-icon {
      svg {
        fill: ${COLORS.LIGHT_ICON_BG_ADMIN};
      }
    }
  }
`;
