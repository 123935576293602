export const onRightClick = (event) => {
  event.preventDefault();
};

export const addClickBlocker = (containerId) => {
  const container = document.getElementById(containerId);

  if (container) {
    container.addEventListener('contextmenu', onRightClick);
  }

  return () => {
    if (container) {
      container.removeEventListener('contextmenu', onRightClick);
    }
  };
};
