import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { changeChatWindow } from '../../store/features/chat';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { ChatIcon } from '../svgicons';

const ChatBubbleButton: React.FC<any> = ({ chatWindow }) => {
  const dispatch = useDispatch();
  return (
    <>
      <StyledButton
        onClick={() => {
          dispatch(changeChatWindow(!chatWindow));
        }}
      >
        <ChatIcon /> Chat
      </StyledButton>
    </>
  );
};

export default ChatBubbleButton;

const StyledButton = styled.button`
  width: 108px;
  height: 56px;
  position: relative;
  ${DISPLAY_FLEX('row', 'center', 'center')};
  gap: 10px;
  cursor: pointer;
  color: white;
  background: ${COLORS.DODGER_BLUE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 11px;
  opacity: 1;
  border: none;
`;
