import React from 'react';
import get from 'lodash/get';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PAYMENT_STATUS } from '../../../../constants';
import { getRoleId, getUserClassWithRoleId } from '../../../../utils/userInfo';
import { PrimaryButton } from '../../../shared-components/Button';
import AuthenticationButton from './AuthenticationButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const RegisterButton = ({
  event,
  handleBookEvent,
  idBase = 'book',
  profileData,
  companyData,
}) => {
  const roleId = getRoleId();
  const ticket = get(event, 'ticket', '');
  const theme = getUserClassWithRoleId(roleId);

  switch (get(ticket, 'paymentStatus', null)) {
    case PAYMENT_STATUS.PENDING:
    case PAYMENT_STATUS.ERROR:
      return (
        <PrimaryButton
          onClick={handleBookEvent}
          theme={theme}
          id={`${idBase}_inapp`}
        >
          Complete the Payment
        </PrimaryButton>
      );
    case PAYMENT_STATUS.PROCESSING:
      return <></>;
    case PAYMENT_STATUS.REQUIRES_ACTION:
      return (
        <Elements stripe={stripePromise}>
          <AuthenticationButton
            event={event}
            companyData={companyData}
            idBase={idBase}
            profileData={profileData}
          />
        </Elements>
      );
    default:
      return (
        <PrimaryButton
          onClick={handleBookEvent}
          theme={theme}
          id={`${idBase}_inapp`}
        >
          Register
        </PrimaryButton>
      );
  }
};

export default RegisterButton;
