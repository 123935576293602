export const highestEducationList = [
  {
    id: '1',
    name: 'Doctorate',
  },
  {
    id: '2',
    name: 'Masters',
  },
  {
    id: '3',
    name: 'Bachelors',
  },
  {
    id: '4',
    name: 'Associates',
  },
  {
    id: '5',
    name: 'Diploma',
  },
  {
    id: '6',
    name: 'Certification',
  },
  {
    id: '7',
    name: 'High School',
  },
  {
    id: '8',
    name: 'Others',
  },
];
