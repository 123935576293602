import React from 'react';
import styled from 'styled-components';
import { IDENTITY_VERIFICATION } from '../../../enums/identity-verification';
import { COLORS } from '../../../styles/constants/colors';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const WalletConditions = ({ isWalletConnected, profileIdentity }) => {
  return (
    <StyledWalletConditionsContainer>
      <StyledFlexDiv>
        <StyledIconContainer>
          {isWalletConnected ? (
            <StyledCheckOutlined />
          ) : (
            <StyledCloseOutlined />
          )}
        </StyledIconContainer>
        <div>
          <StyledText1 data-wallet={isWalletConnected ? 'active' : 'inactive'}>
            {isWalletConnected ? 'Wallet connected' : 'Connect your wallet'}
          </StyledText1>
        </div>
      </StyledFlexDiv>
      <StyledFlexDiv>
        <StyledIconContainer>
          {profileIdentity !== IDENTITY_VERIFICATION.Verified ? (
            <StyledCloseOutlined />
          ) : (
            <StyledCheckOutlined />
          )}
        </StyledIconContainer>
        <StyledFlexDiv>
          <StyledText1
            data-identity={
              profileIdentity !== IDENTITY_VERIFICATION.Verified
                ? 'inactive'
                : 'active'
            }
          >
            {profileIdentity !== IDENTITY_VERIFICATION.Verified
              ? 'Profile not verified'
              : 'Profile is verified'}
          </StyledText1>
        </StyledFlexDiv>
      </StyledFlexDiv>
    </StyledWalletConditionsContainer>
  );
};

export default WalletConditions;

const StyledWalletConditionsContainer = styled.div`
  ${DISPLAY_FLEX('column')};
  row-gap: 10px;
  background: ${COLORS.WHITE};
  ${PADDING(20)};
  border-radius: 5px;
  box-shadow: 2px 2px 10px #00000059;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 0, 40)};
  }
`;

const StyledText1 = styled.span`
  &[data-wallet='active'] {
    color: ${COLORS.GREEN_TONE_3};
  }
  &[data-wallet='inactive'] {
    color: ${COLORS.RED};
  }

  &[data-identity='active'] {
    color: ${COLORS.GREEN_TONE_3};
  }
  &[data-identity='inactive'] {
    color: ${COLORS.RED};
  }
`;

const StyledFlexDiv = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 10px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  color: ${COLORS.RED};
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  color: ${COLORS.GREEN_TONE_3};
`;
const StyledIconContainer = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
`;
