/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import { RenderButtonTags } from '../Common';
import ProfileLinksComponent from '../../ProfileLinks/ProfileLinks';
import {
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
} from '../../../utils/userInfo';
import { MediumText } from '../../shared-components/Texts';
import {
  getUserBackgroundColorWithRoleId,
  getUserColorWithRoleId,
} from '../../../styles/utils';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { MARGIN, PADDING } from '../../../styles/constants/display';
import OrganizerProfileInfoDetails from '../../profile/OrganizerProfileInfoDetails';

export const OrganizerInfoSection = ({ event, roleId }) => {
  const [companyId, setCompantId] = useState();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCompantId(event.companyId);
  }, [event]);

  const { company, meta } = event;
  if (!isObject(company) || !isObject(meta)) {
    return null;
  }

  const profileLinks = get(event, 'meta.profileLinks', []);
  const domains = get(event, 'domains', []);

  const companyName = get(event, 'company.name', null);
  const tags = get(event, 'tags', []);

  const handleModal = () => {
    return setVisible(true);
  };

  const handleModalClose = () => {
    return setVisible(false);
  };

  return (
    <StyledMainContainer>
      <StyledMainHeading color={getUserColorWithRoleId(roleId)}>
        Organizer
      </StyledMainHeading>

      {companyName ? (
        <StyledCompanyName>
          <StyledCompanyHeading>{companyName}</StyledCompanyHeading>
        </StyledCompanyName>
      ) : null}

      {!checkIfUserIsOrganizer(roleId) && !checkIfUserIsMasterAdmin(roleId) ? (
        <StyledInfoSection>
          <StyledText onClick={handleModal}>View Info</StyledText>
          <OrganizerProfileInfoDetails
            visible={visible}
            event={event}
            handleModalClose={handleModalClose}
          />
        </StyledInfoSection>
      ) : checkIfUserIsMasterAdmin(roleId) ? (
        <Link
          to={{
            pathname: `/master-admin/organizer/organizer-info/${companyId}`,
          }}
        >
          <MediumText>View Info</MediumText>
        </Link>
      ) : null}
      {profileLinks.length ? (
        <ProfileLinksComponent profileLinks={profileLinks} string />
      ) : null}

      <StyledAdditionalSection>
        {tags.length ? (
          <StyledSectionRow>
            <StyledSectionHeader>Tags</StyledSectionHeader>
            <StyledTagsContainer>
              <ul>
                {tags.map((tag, idx) => {
                  return (
                    <li key={idx.toString()}>
                      <RenderButtonTags
                        text={tag.name}
                        color={getUserColorWithRoleId(roleId)}
                        backgroundColor={getUserBackgroundColorWithRoleId(
                          roleId,
                        )}
                      />
                    </li>
                  );
                })}
              </ul>
            </StyledTagsContainer>
          </StyledSectionRow>
        ) : null}
        {domains.length ? (
          <StyledSectionRow isDomain={true}>
            <StyledSectionHeader>Industry</StyledSectionHeader>
            <StyledDomainContainer>
              <ul>
                {domains.map((domain, idx) => {
                  return (
                    <li key={idx.toString()}>
                      <RenderButtonTags
                        text={domain.name}
                        color={getUserColorWithRoleId(roleId)}
                        backgroundColor={getUserBackgroundColorWithRoleId(
                          roleId,
                        )}
                      />
                    </li>
                  );
                })}
              </ul>
            </StyledDomainContainer>
          </StyledSectionRow>
        ) : null}
      </StyledAdditionalSection>
    </StyledMainContainer>
  );
};

const StyledCompanyHeading = styled.p`
  font-size: 16px;
  color: inherit;
  font-weight: inherit;
`;

const StyledSectionHeader = styled.p`
  font-size: 18px;
  color: rgb(67 67 67);
  font-weight: 600;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: 14px;
  }
`;

const StyledText = styled.div`
  cursor: pointer;
  color: ${COLORS.DARK_GRAY};
  text-decoration: underline;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  display: inline-block;
  font-size: 14px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    margin-top: -10px;
  }
`;

const StyledMainContainer = styled.div`
  margin-bottom: 25px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 5px;
  }
`;

const StyledMainHeading = styled.h4`
  position: relative;
  color: ${COLORS.RAISIN_BLACK};
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-size: ${FONT[18]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[24]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -13px;
    width: 60px;
    height: 8px;
    background-color: ${(props) => props.color};
  }
`;

const StyledCompanyName = styled.h5`
  ${MARGIN(20, 0, -12, 0)};
  ${PADDING(15, 0, 25, 0)};
  color: ${COLORS.RAISIN_BLACK};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

const StyledInfoSection = styled.div`
  margin-bottom: 15px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-bottom: 20px;
  }

  & a:hover {
    cursor: pointer;
    color: ${COLORS.ARGENT};
  }
`;

const StyledAdditionalSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
  @media (min-width: ${BREAKPOINTS.MOBILE}) {
    flex-direction: row;
  }
`;

const StyledSectionRow = styled.div`
  margin-top: 20px;
  ${({ isDomain }) => (isDomain ? 'flex : 1;' : null)}
  p {
    position: relative;
    top: 5px;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    margin-bottom: 20px;
  }
`;

const StyledTagsContainer = styled.div`
  li {
    ${MARGIN(4, 16, 4, 0)};
    list-style: none;
    display: inline-block;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ul {
      margin-top: 20px;
    }
    li {
      margin-bottom: 10px;
    }
  }
`;
const StyledDomainContainer = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    ${MARGIN(4, 16, 4, 0)};
    list-style: none;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ul {
      margin-top: 20px;
    }
    li {
      margin-bottom: 10px;
    }
  }
`;
