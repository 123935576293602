import React from 'react';
import { Form, Row, Col } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import { getRoleId } from '../../utils/userInfo';
import Notification from '../Notification/Notification';
import { DateAndTypeSection } from '../shared-components/Event/DateAndTypeSection';
import { LargeFont, MediumFont } from '../shared-components/Texts';
import { getThemeByRoleId } from '../../utils/common';
import { PrimaryButton } from '../shared-components/Button';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { EventTitle } from '../shared-components/Event/Title';
import { Modal } from '../shared-components/Modal';

const LinkSwitchModal = ({ visible, handleClose }) => {
  const id = document.getElementById('live-event-id');

  const roleId = getRoleId();
  let event = null;
  if (id) {
    event = JSON.parse(id.innerHTML);
  }
  const [form] = Form.useForm();
  const links = get(event, 'streamUrlsData', []);
  const handleModalCancel = () => {
    form.resetFields();
    handleClose();
  };

  const handleWatchNow = (link) => {
    const iframe =
      document.getElementById('live-event-video-iframe') ||
      document.getElementById('live-event-iframe');

    if (iframe) {
      iframe.src = link ? `${link.url}?autoplay=1&enablejsapi=1` : '';
    }
    const name = document.getElementById('live-event-name');
    if (name) {
      name.innerHTML = link.title;
    }
    Notification('success', `Now you are watching ${link.title}`);
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width="60%"
      onCancel={handleModalCancel}
      title="Select the Broadcast"
      theme={getThemeByRoleId(roleId)}
    >
      <Row>
        <Col xs={24}>
          <EventTitle>Event Info : {event.name}</EventTitle>
          <DateAndTypeSection event={event} isLeft={false} />
          <LargeFont fontWeight={FONT_WEIGHTS.BOLD}>Broadcast List</LargeFont>
          <StyledLinkContainer xs={24}>
            {isArray(links) ? (
              links.map((link, i) => {
                return (
                  <Row key={i.toString()}>
                    <StyledColumn xs={6}>
                      <MediumFont>{link.title}</MediumFont>
                    </StyledColumn>
                    <StyledColumn xs={10} md={12}>
                      <MediumFont>{link.url}</MediumFont>
                    </StyledColumn>
                    <StyledColumn xs={8} md={6}>
                      <PrimaryButton
                        theme={getThemeByRoleId(roleId)}
                        onClick={() => handleWatchNow(link)}
                        id="link_switch_modal_watch"
                      >
                        Watch Now
                      </PrimaryButton>
                    </StyledColumn>
                  </Row>
                );
              })
            ) : (
              <MediumFont>
                Please add stream urls here to make the event broadcast
                avaliable to the attendees
              </MediumFont>
            )}
          </StyledLinkContainer>
        </Col>
      </Row>
    </Modal>
  );
};

export default LinkSwitchModal;

const StyledLinkContainer = styled(Col)`
  max-height: 350px;
  overflow: auto;
`;

const StyledColumn = styled(Col)`
  ${DISPLAY_FLEX('row', null, 'center')}
`;
