import get from 'lodash/get';
import { useSelector } from 'react-redux';
import {
  ADMIN,
  CANDIDATE,
  ORGANIZER,
  RECRUITER,
  SUPER_ADMIN,
  CANDIDATE_TEXT,
  RECRUITER_TEXT,
  ORGANIZER_TEXT,
  CANDIDATE_COLOR,
  RECRUITER_COLOR,
  ORGANIZER_COLOR,
} from '../constants';

export const UserInformation = () => {
  const { isAuthenticated, isLoading, error, user } = useSelector(
    (state) => state.auth,
  );
  return { isAuthenticated, isLoading, error, user };
};

export const getRoleId = () => {
  const data = UserInformation();
  return get(data, 'user.roleId', '');
};

export const getUserId = () => {
  const data = UserInformation();
  return get(data, 'user.userId', get(data, 'user.id', ''));
};

export const getCompanyId = () => {
  const data = UserInformation();
  return get(data, 'user.companyId', '');
};

export const checkIfUserIsOrganizer = (roleId) => {
  return roleId ? roleId === ORGANIZER : getRoleId() === ORGANIZER;
};

export const checkIfUserIsRecruiter = (roleId) => {
  return roleId ? roleId === RECRUITER : getRoleId() === RECRUITER;
};

export const checkIfUserIsCandidate = (roleId) => {
  return roleId ? roleId === CANDIDATE : getRoleId() === CANDIDATE;
};

export const checkIfUserIsMasterAdmin = (roleId) => {
  return roleId ? roleId === ADMIN : getRoleId() === ADMIN;
};

export const checkIfUserIsSuperAdmin = (roleId) => {
  return roleId ? roleId === SUPER_ADMIN : getRoleId() === SUPER_ADMIN;
};

export const getUserTypeWithTheme = (theme) => {
  switch (theme) {
    case CANDIDATE_COLOR:
      return CANDIDATE_TEXT;

    case RECRUITER_COLOR:
      return RECRUITER_TEXT;

    case ORGANIZER_COLOR:
      return ORGANIZER_TEXT;

    default:
      return '';
  }
};

export const getUserTypeWithRole = (roleId = CANDIDATE) => {
  switch (true) {
    case checkIfUserIsCandidate(roleId):
      return CANDIDATE_TEXT;

    case checkIfUserIsRecruiter(roleId):
      return RECRUITER_TEXT;

    case checkIfUserIsOrganizer(roleId):
      return ORGANIZER_TEXT;

    default:
      return '';
  }
};

export const getUserClassWithRoleId = (roleId) => {
  if (checkIfUserIsOrganizer(roleId)) {
    return ORGANIZER_TEXT.toLowerCase();
  }
  if (checkIfUserIsRecruiter(roleId)) {
    return RECRUITER_TEXT.toLowerCase();
  }
  if (checkIfUserIsCandidate(roleId)) {
    return CANDIDATE_TEXT.toLowerCase();
  }
  return '';
};
