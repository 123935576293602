import { Table } from 'antd';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from '../../../components/shared-components/Modal';
import { BuildingIcon } from '../../../components/svgicons';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../../constants';
import { getColdOrWarmLeadsData } from '../../../store/features/analytics';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { getSerialNumber, serialNoColumn } from '../../../utils/common';

export const CompaniesOnboarded = () => {
  const { coldOrWarmLeads } = useSelector(
    (state: any) => state.analytics.masterAdminDashboardAnalytics,
  );
  const { data } = coldOrWarmLeads;
  const [dataSource, setDataSource] = useState([]);
  const [modalDataSource, setModalDataSource] = useState([]);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);

  useEffect(() => {
    dispatch(getColdOrWarmLeadsData('cold', current, pageSize));
  }, [current, pageSize, dispatch]);

  useEffect(() => {
    const renderData = () => {
      if (current === 1) {
        setDataSource(
          data?.users?.map((item: any, rowId: any) => ({
            serialNo: getSerialNumber(current, pageSize, rowId),
            company: get(item, 'companyRecruiter.company.name', '-'),
            user: get(item, 'name', '-'),
            jobs: get(item, 'companyRecruiter.company.jobs', '-'),
          })),
        );
      }
      setModalDataSource(
        data?.users?.map((item: any, rowId: any) => ({
          serialNo: getSerialNumber(current, pageSize, rowId),
          company: get(item, 'companyRecruiter.company.name', '-'),
          user: get(item, 'name', '-'),
          jobs: get(item, 'companyRecruiter.company.jobs', '-'),
        })),
      );
    };

    renderData();
  }, [data, current, pageSize]);

  const columns = [
    { ...serialNoColumn },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Jobs',
      dataIndex: 'jobs',
      key: 'jobs',
    },
  ];

  const handleModalClose = () => {
    setVisible(false);
  };

  return (
    <>
      <StyledSection onClick={() => setVisible(true)}>
        <div>
          <BuildingIcon /> <h1>New Companies Onboarded - {data.totalItems}</h1>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource?.slice(0, 3)}
          pagination={false}
          scroll={{ x: true }}
        />
      </StyledSection>
      <Modal
        title={`New Companies Onboarded - ${data.totalItems}`}
        width="80%"
        visible={visible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={modalDataSource}
          pagination={{ pageSize, total: data.totalItems, current }}
          onChange={(e: any) => {
            setCurrent(e.current);
            setPageSize(e.pageSize);
          }}
          scroll={{ x: true }}
        />
      </Modal>
    </>
  );
};

const StyledSection = styled.div`
  cursor: pointer;
  h1 {
    margin-bottom: 20px;
    color: ${COLORS.TRUE_GREEN_2};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  & > div:first-child {
    display: flex;
    gap: 7px;
  }

  svg {
    fill: ${COLORS.TRUE_GREEN_2};
    width: 20px;
    height: 20px;
  }
`;
