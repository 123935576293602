import { Modal } from 'antd';

const { confirm } = Modal;

export const showConfirm = (onOk, action = 'Suspend') => {
  confirm({
    title: `Do you want to ${action} this account?`,
    content: `Click on the confirm button to ${action} this account`,
    okText: 'Confirm',
    onOk,
  });
};

export const showDeleteJobConfirm = (onOk) => {
  confirm({
    title: `Do you want to delete this job?`,
    content: `Click on the confirm button to delete.`,
    okText: 'Confirm',
    onOk,
  });
};
