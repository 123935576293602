import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { FONT } from '../../styles/constants/typography';

const CalendarBlockItem: React.FC<any> = ({ color, item }) => {
  return (
    <StyledLI color={color} key={item.id}>
      <StyledBubble color={color} />
      <StyledDiv>
        <StyledFont>{`${item.isInterview ? 'Interview With' : ''} ${
          item.name
        }`}</StyledFont>
      </StyledDiv>
    </StyledLI>
  );
};

export default CalendarBlockItem;

const StyledLI = styled.li`
  ${DISPLAY_FLEX('row', null, null)}

  color: ${(props) => props.color};
`;

const StyledBubble = styled.div`
  background-color: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 100%;
  ${MARGIN(7, 5, 0, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 8px;
    height: 8px;
    ${MARGIN(3, 5, 0, 5)}
  }
`;

const StyledDiv = styled.div`
  color: ${(props) => props.color};
  width: 90%;
`;

const StyledFont = styled.p`
  font-size: ${FONT[12]};
  margin: 3px 0;
  
  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
    font-size: ${FONT[10]};
  }
`
