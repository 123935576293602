import { message } from 'antd';

const contentsQueue = [];

const Message = (
  type = 'info',
  content = '',
  duration = 10,
  className = '',
) => {
  if (!contentsQueue.includes(content)) {
    contentsQueue.push(content);
    return message.open({
      type: type,
      className: className,
      content: content,
      duration: duration,
      onClose: () => {
        const index = contentsQueue.indexOf(content);
        contentsQueue.splice(index, 1);
      },
      onClick: () => {
        message.destroy();
      },
    });
  }
};

export default Message;
