import React from 'react';
import WhiteLogo from '../../assets/svg/recorem_white_logo.svg';
import Arrow from '../../assets/svg/arrow.svg';
import WhiteLogoSymbol from '../../assets/svg/WhiteLogoSymbol.svg';

export default ({ handleModalCancel, title, type = 'normal' }) => {
  return (
    <div className={`responsive-modal-header__container ${type}`}>
      <div
        onClick={() => handleModalCancel()}
        className="responsive-modal-header__container__arrow__container"
      >
        <img
          className="responsive-modal-header__container__arrow"
          src={Arrow}
          alt=""
        />
      </div>
      <div className="responsive-modal-header__container__logo_Container">
        <div className="responsive-modal-header__container__logo__title__container">
          <img
            className="responsive-modal-header__container__logo__title--logo"
            alt=""
            src={WhiteLogo}
          />
          <p className="responsive-modal-header__container__logo__title--title">
            {title}
          </p>
        </div>
        <div className="responsive-modal-header__container__sideLogo">
          <img
            className="responsive-modal-header__container__sideLogo--right"
            src={WhiteLogoSymbol}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
