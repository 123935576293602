import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Document } from 'react-pdf';
import { Pagination } from 'antd';
import { onRightClick } from '../../utils/document';
import { MediumText } from './Texts';
import { ResponsiveModalHeader } from './ResponsiveModalHeader';
import { DEFAULT_PAGE, HelpDocLimit } from '../../constants';
import { FileContainer } from './FileContainer';
import { getRoleId, getUserClassWithRoleId } from '../../utils/userInfo';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import {
  DocumentViewerProps,
  DocumentViewerModalProps,
} from '../../interfaces/documentViewer';
import DocPages from '../Common/DocPages';
import { StyledModal } from './Modal';

export const DocumentViewerModal: React.FC<DocumentViewerModalProps> = ({
  filePath,
  isModalOpen,
  handleModalCancel,
}) => {
  const [file, setFile] = useState<string>('');
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE);
  const roleId = getRoleId();
  const [limit, setLimit] = useState(DEFAULT_PAGE);
  const documentViewer = useRef<HTMLDivElement | null>();

  useEffect(() => {
    setFile(filePath);
  }, [filePath]);

  useLayoutEffect(() => {
    if (documentViewer.current) {
      documentViewer.current.scrollTop = 0;
    }
  }, [pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setTotalPages(numPages);
  };

  const onChangePage = (page: number, pageSize: number | undefined) => {
    if (pageNumber !== page) setPageNumber(page);
    // according to this version pageSize may be undefined
    if (pageSize && limit !== pageSize) setLimit(pageSize);
  };

  const getTotal = (pages: number) => {
    return pages;
  };

  const Title = 'Document Viewer';

  return (
    <StyledModal
      theme={getUserClassWithRoleId(roleId)}
      visible={isModalOpen}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      title={
        <>
          <HiddenMobile>{Title}</HiddenMobile>
          <ResponsiveModalHeader
            handleModalCancel={handleModalCancel}
            title={Title}
          />
        </>
      }
    >
      <div
        ref={(node) => {
          documentViewer.current = node;
        }}
        onContextMenu={onRightClick}
      >
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <DocPages
            pageNumber={pageNumber}
            limit={limit}
            numPages={totalPages}
          />
        </Document>
        <PaginationContainer>
          <Pagination
            onChange={onChangePage}
            total={getTotal(totalPages)}
            defaultCurrent={DEFAULT_PAGE}
            current={pageNumber}
            pageSizeOptions={HelpDocLimit}
            defaultPageSize={DEFAULT_PAGE}
            showSizeChanger
          />
        </PaginationContainer>
      </div>
    </StyledModal>
  );
};

export const DocumentViewer: React.FC<DocumentViewerProps> = ({
  filePath,
  isTextButton,
  fileName,
  key = 0,
  onDelete = null,
  isLoading = false,
}) => {
  const [openModal, setModal] = useState(false);

  const handleModalCancel = () => {
    setModal(false);
  };

  const handleOpenModal = () => {
    setModal(true);
  };

  return (
    <>
      {!isTextButton ? (
        <FileContainer
          fileName={fileName}
          isButton
          onClick={handleOpenModal}
          onDelete={onDelete}
          key={key}
          isLoading={isLoading}
        />
      ) : (
        <MediumText onClick={handleOpenModal} cursor="pointer">{`${fileName} ${
          key || ''
        }`}</MediumText>
      )}
      <DocumentViewerModal
        isModalOpen={openModal}
        filePath={filePath}
        handleModalCancel={handleModalCancel}
      />
    </>
  );
};

export const HiddenMobile = styled.span`
  font: inherit;
  font-weight: inherit;
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const PaginationContainer = styled.div`
  ${MARGIN(10, 0)}
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;
