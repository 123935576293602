import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Badge, Select, Collapse } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import toLower from 'lodash/toLower';
import { candidateStatusFilter } from '../../../../constants/candidateStatusFilter';
import {
  jobApplicationStatusChange,
  getJobById,
  downloadResume,
  getCandidateProfile,
} from '../../../../service/recruiter';
import RejectApplicationModal from './RejectApplicationModal';
import Notification from '../../../../components/Notification/Notification';
import { RecruiterRoutes } from '../../../../constants/routes';
import {
  YES,
  YES_TEXT,
  NO_TEXT,
  STATUS_ACTIVE,
  STATUS_DELETE,
  getApplicationStatusText,
  getApplicationStatusTextForApi,
  APPLICATION_STATUS_SUBMITTED,
  APPLICATION_STATUS_REJECTED,
  CHATROOM_TYPE_PRIVATE,
  USERTYPE_INDIVIDUAL,
  RECRUITER,
} from '../../../../constants';
import { isJobApplicationSelected } from '../../../../utils/job-application';
import { JOB_APPLICATION_STATUS } from '../../../../constants/jobs';
import {
  candidateInfoRegardingChatroom,
  eventDataRegardingChatroom,
  userInfoRegardingChatroom,
} from '../../../../utils/chat';
import { createChatroom, isChatroomExist } from '../../../../service/chat';
import config from '../../../../config';
import { getEvaluationDeadlineDateTime } from '../../../../service/event';
import { hasJobApplicationEvaluationEnded } from '../../../../utils/jobApplications';
import {
  currencyConverting,
  currencyNameToCode,
  formatCurrency,
} from '../../../../utils/currencyConvertor';
import {
  PrimaryButton,
  OutlinedButton,
} from '../../../../components/shared-components/Button';
import { addThreeDaysInUnixTime, getThemeByRoleId } from '../../../../utils/common';
import { COLORS } from '../../../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN } from '../../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { SHADOWS } from '../../../../styles/constants/shadows';
import { ViewProfileModal } from './ViewProfileModal';
import { useTagManager } from '../../../../utils/customHooks';
import UserSocialLinks from '../../../../components/ProfileLinks/UserSocialLinks';

const { Panel } = Collapse;
const { Option } = Select;

function ResumeDetails({
  application,
  jobId,
  selectedSkills = [],
  setSelectedSkills,
  eventId,
  isInModal = false,
  refreshApplicants,
}) {
  const dispatch = useDispatch();
  const [
    selectedJobApplicationStatus,
    setSelectedJobApplicationStatus,
  ] = useState(APPLICATION_STATUS_SUBMITTED);
  const [rejectionModalVisible, setVisible] = useState(false);
  const history = useHistory();
  const updateUserActionInGTM = useTagManager();
  const initialValue = '-';
  const {
    expectedCtc,
    expectedCtcCurrency,
    expectedSalaryCurrency,
    city,
    country,
    willingToRelocate,
    startAvailability,
    profileLinks,
    userSkills,
    name,
  } = get(application, 'candidate', {
    name: initialValue,
    expectedCtc: initialValue,
    expectedCtcCurrency: initialValue,
    city: initialValue,
    country: initialValue,
    willingToRelocate: initialValue,
    startAvailability: initialValue,
  });

  const eventName = get(application, 'event.name', initialValue);
  const [applicationStatus, setApplicationStatus] = useState(
    get(application, 'applicationStatus', JOB_APPLICATION_STATUS.REVIEWED),
  );

  React.useEffect(() => {
    const val = get(
      application,
      'applicationStatus',
      JOB_APPLICATION_STATUS.REVIEWED,
    );
    setApplicationStatus(val);

    setSelectedJobApplicationStatus(val);
  }, [application]);

  const { jobSkills } = get(application, 'job', {});

  const skillsLevelMap = {};

  const [disclaimerVisible, setDisclaimerVisible] = useState(false);
  const [mandatorySkillsMatch, setMandatorySkillsMatch] = useState([]);
  const [preferedSkillsMatch, setPreferedSkillsMatch] = useState([]);
  const [anyOtherSkillMatch, setAnyOtherSkillMatch] = useState([]);
  const [jobsMandatorySkillsMatch, setJobsMandatorySkillsMatch] = useState([]);
  const [jobsPreferedSkillsMatch, setJobsPreferedSkillsMatch] = useState([]);
  const [downloadInprocess, setDownloadInProcess] = useState(false);
  const [isModal, setIsModal] = useState(false);
  // const getUserProfilePicture = get(application, 'candidate.profilePic', null);

  const getCandidateMatchPercentage = get(application, 'matchPercentage', 0);

  // Initiating Chat with Candidate
  const [evaluationDeadline, setEvaluationDeadline] = useState(0);

  const companyData = config.chatEnabled ? userInfoRegardingChatroom() : null;
  const companyId = get(companyData, 'id', '');

  useEffect(() => {
    const mandatorySkillsMatch = [];
    const preferedSkillsMatch = [];
    const jobsMandatorySkillsMatch = [];
    const jobsPreferedSkillsMatch = [];
    const anyOtherSkillMatch = [];
    const skills = [];
    if (userSkills) {
      userSkills.forEach((skill) => {
        const skillName = get(skill, 'skill.skill', null);
        if (skillName) {
          const matchedJobSkill = jobSkills.find((jobSkill) => {
            const jobSkillName = get(jobSkill, 'skill.skill', null);
            if (jobSkillName && skillName) {
              return jobSkillName.toLowerCase() === skillName.toLowerCase();
            }
            return false;
          });
          const skillmatch = {
            name: skillName,
            level: skill.level,
          };
          skills.push(skillmatch.name);
          if (matchedJobSkill) {
            const skillPreference = get(
              matchedJobSkill,
              'skillPreference',
              null,
            );
            skillsLevelMap[skill.level] = true;
            if (skillPreference === STATUS_ACTIVE) {
              mandatorySkillsMatch.push(skillmatch);
            } else if (skillPreference === STATUS_DELETE) {
              preferedSkillsMatch.push(skillmatch);
            }
          } else {
            anyOtherSkillMatch.push(skillmatch);
          }
        }
      });

      setSelectedSkills(skills);
      setMandatorySkillsMatch(mandatorySkillsMatch);
      setPreferedSkillsMatch(preferedSkillsMatch);
      setAnyOtherSkillMatch(anyOtherSkillMatch);
    }
    const temp = [...skills];
    if (jobSkills) {
      jobSkills.forEach((skill) => {
        const skillName = get(skill, 'skill.skill', null);
        if (!temp.includes(skillName)) {
          const skillmatch = {
            name: skillName,
            level: skill.level,
          };
          skills.push(skillmatch.name);
          const skillPreference = get(skill, 'skillPreference', null);
          if (skillPreference === STATUS_ACTIVE) {
            jobsMandatorySkillsMatch.push(skillmatch);
          } else if (skillPreference === STATUS_DELETE) {
            jobsPreferedSkillsMatch.push(skillmatch);
          }
        }
      });

      setJobsMandatorySkillsMatch(jobsMandatorySkillsMatch);
      setJobsPreferedSkillsMatch(jobsPreferedSkillsMatch);
    }
    // eslint-disable-next-line
  }, [userSkills, jobSkills]);

  const handleChange = (key, value) => {
    setSelectedJobApplicationStatus(value.value);
    setDisclaimerVisible(true);
  };

  const handleDownloadResume = async () => {
    if (!downloadInprocess) {
      Notification('info', 'Resume', 'Download started');
      setDownloadInProcess(true);
      if (get(application, 'candidate.resume', '')) {
        const resume = get(application, 'candidate.resume', '');
        const name = get(application, 'candidate.name', '');
        await downloadResume(resume, name.replace(/\s+/g, '_'))
          .then(() => {
            setDownloadInProcess(false);
            Notification('success', 'Resume Downloaded');
          })
          .catch(() => {
            setDownloadInProcess(false);
            Notification('error', 'Unable to download');
          });
      }
    }
  };

  const handleCurrentStatusChange = (status) => {
    setApplicationStatus(status);
  };

  const handleStatusChange = async () => {
    setDisclaimerVisible(false);
    if (selectedJobApplicationStatus === APPLICATION_STATUS_REJECTED) {
      setVisible(true);
      return;
    }

    /*
    This is commented out temporally, will be added back when required.
    if (selectedJobApplicationStatus === APPLICATION_STATUS_SELECTED) {
      // check if slot are created or not
      const response = await checkIfCompanyHasCreatedInterviewSlots(eventId);
      const hasRecruiterCreateInterviewSlots = get(
        response,
        'data.payload.hasRecruiterCreateInterviewSlots',
        false,
      );
      if (!hasRecruiterCreateInterviewSlots) {
        Notification(
          'error',
          'Application Status',
          'Please create Interview Slots for candidates before shortlisting.',
        );
        history.push(
          `${RecruiterRoutes.calender}?openCreateSlot=true&eventId=${eventId}`,
        );
        return;
      }
    }
    */

    const response = await jobApplicationStatusChange(application.id, {
      applicationStatus: getApplicationStatusTextForApi(
        selectedJobApplicationStatus,
      ).toLowerCase(),
    });
    if (response.status === 200) {
      updateUserActionInGTM(
        `job_application_${toLower(
          getApplicationStatusText(selectedJobApplicationStatus),
        )}`,
      );
      Notification('success', 'Job Application status updated');
      handleCurrentStatusChange(selectedJobApplicationStatus);
      if (refreshApplicants) {
        refreshApplicants();
      }
    }
  };

  const [profileData, setProfileData] = useState();
  const communityEventEta = addThreeDaysInUnixTime(application);

  useEffect(() => {
    const getApplication = async () => {
      if (application?.candidate) {
        const response = await getCandidateProfile(
          application.candidate.userId,
        );
        setProfileData(response.data.payload.profile);
      }
    };

    getApplication();
  }, [application, dispatch]);

  useEffect(() => {
    (async () => {
      if (eventId && companyId) {
        const eDeadline = await getEvaluationDeadlineDateTime(
          eventId,
          companyId,
        );
        setEvaluationDeadline(eDeadline);
      }
    })();
  }, [eventId, companyId]);

  const onInitiatingChat = async () => {
    try {
      if (config.chatEnabled) {
        const { event, candidate } = application;

        let chatRoomId;

        const existedChatroom = await isChatroomExist(
          eventId,
          candidate.userId,
          USERTYPE_INDIVIDUAL,
        );

        if (existedChatroom) {
          chatRoomId = get(existedChatroom, '_id', '');
        } else {
          const eventData = eventDataRegardingChatroom(event);
          const candidateData = candidateInfoRegardingChatroom(candidate);

          const createdChatroom = await createChatroom(
            { ...eventData, endTime: evaluationDeadline },
            [companyData, candidateData],
            CHATROOM_TYPE_PRIVATE,
            false,
          );

          chatRoomId = get(createdChatroom, 'chatRoom._id', '');
        }

        history.push({
          pathname: RecruiterRoutes.chat,
          state: { chatRoomId },
        });
      }
    } catch (err) {
      Notification('error', 'Error', 'Error in initiating chat');
    }
  };

  // Currency Data
  const { currencyData } = useSelector((st) => st.commons);

  const [job, setJob] = useState(null);
  useEffect(() => {
    if (jobId) {
      (async () => {
        const jobById = await getJobById(jobId);
        setJob(jobById);
      })();
    }
  }, [jobId]);

  const ViewCandidateExpectedSalary = () => {
    // Job not available
    if (!job) return 'Not available';

    // Expected salary or currency not available
    if (!expectedCtc || (!expectedCtcCurrency && !expectedSalaryCurrency)) {
      return 'Not available';
    }

    // Currency exchange does not occurs if job
    // - depends on experience or
    // - does not have both currency and salaryCurrency at the same time
    if (
      job.salaryDependsOnExprience ||
      (!job.currency && !job.salaryCurrency)
    ) {
      return `${formatCurrency(
        get(expectedSalaryCurrency, 'code', expectedCtcCurrency),
        expectedCtc,
      )}`;
    }

    // Populate toCurrency (exchange from candidate's expected currency to job's currency)
    const toCurrency = get(
      job,
      'salaryCurrency.code',
      currencyNameToCode(job.currency) || job.currency,
    );

    const currency = currencyConverting({
      fromCurrency: get(expectedSalaryCurrency, 'code', expectedCtcCurrency),
      fromAmount: expectedCtc,
      toCurrency,
      currenciesData: currencyData,
    });

    // Show original expected salary and exchanged one
    return `${formatCurrency(
      get(expectedSalaryCurrency, 'code', expectedCtcCurrency),
      expectedCtc,
    )} ${currency ? `Or ${currency}` : ''}`;
  };

  const addOrRemoveSkillHiglight = (skill) => {
    const skills = [...selectedSkills];
    const name = get(skill, 'name', '');
    const index = skills.indexOf(name);
    if (index > -1) {
      skills.splice(index, 1);
    } else {
      skills.push(name);
    }
    setSelectedSkills(skills);
  };

  const SkillTag3 = ({ skill, idx }) => {
    return (
      <StyledResumeSkillCol key={idx.toString()}>
        <StyledBorder isActive={!!selectedSkills.includes(skill.name)}>
          <StyledSkillButton
            bgColor={COLORS.DODGER_BLUE}
            color={COLORS.WHITE}
            borderColor={COLORS.DODGER_BLUE}
            onClick={() => addOrRemoveSkillHiglight(skill)}
          >
            <StyledSkillBagde count={get(skill, 'level', 0)} offset={[14, -10]}>
              {get(skill, 'name', '')}
            </StyledSkillBagde>
          </StyledSkillButton>
        </StyledBorder>
      </StyledResumeSkillCol>
    );
  };

  const SkillTag2 = ({ skill, idx }) => {
    return (
      <StyledResumeSkillCol key={idx.toString()}>
        <StyledBorder isActive={!!selectedSkills.includes(skill.name)}>
          <StyledSkillButton
            onClick={() => addOrRemoveSkillHiglight(skill)}
            bgColor={COLORS.LIGHT_SILVER}
            color={COLORS.CHINESE_BLACK}
            borderColor={COLORS.LIGHT_SILVER}
          >
            <StyledSkillBagde count={get(skill, 'level', 0)} offset={[14, -10]}>
              {get(skill, 'name', '')}
            </StyledSkillBagde>
          </StyledSkillButton>
        </StyledBorder>
      </StyledResumeSkillCol>
    );
  };

  const SkillTag1 = ({ skill, idx }) => {
    return (
      <StyledResumeSkillCol key={idx.toString()}>
        <StyledBorder isActive={!!selectedSkills.includes(skill.name)}>
          <StyledSkillButton
            bgColor={COLORS.TANGERINE_YELLOW}
            color={COLORS.WHITE}
            borderColor={COLORS.TANGERINE_YELLOW}
            onClick={() => addOrRemoveSkillHiglight(skill)}
          >
            <StyledSkillBagde count={get(skill, 'level', 0)} offset={[14, -10]}>
              {get(skill, 'name', '')}
            </StyledSkillBagde>
          </StyledSkillButton>
        </StyledBorder>
      </StyledResumeSkillCol>
    );
  };

  function handleModalOpen() {
    return setIsModal(true);
  }

  function handleModalClose() {
    return setIsModal(false);
  }

  const handleSkipModalClose = () => {
    setVisible(false);
    if (refreshApplicants) {
      refreshApplicants();
    }
  };

  return (
    <StyledMainContainer isInModal={isInModal}>
      <>
        <StyledApplicantNameContainer>
          <StyledApplicantName>{name}</StyledApplicantName>
          {profileLinks?.links ? (
            <UserSocialLinks links={profileLinks ? profileLinks.links : []} />
          ) : null}
        </StyledApplicantNameContainer>
        <StyledEventNameRow>
          <Col>
            <StyledEventName>{eventName}</StyledEventName>
          </Col>
        </StyledEventNameRow>
      </>
      <Row md={24}>
        {/*
        // On suggestion of vikash sir we are hiding it , we will remove or keep this code based on user feedback or requirements, for now just commenting the code.
        <Row>
          <Link
            to={RecruiterRoutes.candidateProfile(
              application.candidate ? application.candidate.userId : 0,
            )}
            className="img__user__resume__details"
          >
            <Row className="resume_quick_details--user-profile-info-container">
              {getUserProfilePicture ? (
                <Avatar
                  src={getFileS3(getUserProfilePicture)}
                  alt="user-profile-logo"
                  className="resume_quick_details--user-profile-pic-preview"
                />
              ) : (
                <UserOutlinedIcon />
              )}
              <span
                role="button"
                className="resume_quick_details--user-profile-view-text"
              >
                View Profile
              </span>
            </Row>
          </Link>
        </Row>
              */}

        <StyledButtonsContainer>
          {!hasJobApplicationEvaluationEnded(evaluationDeadline || communityEventEta) ? (
            <StyledOutlinedButton
              theme={getThemeByRoleId(RECRUITER)}
              onClick={() => onInitiatingChat()}
              id="resume_details_chat_btn"
              margin="10px 0 0 0"
              padding="6px 18px"
            >
              Chat
            </StyledOutlinedButton>
          ) : null}
          <StyledOutlinedButton
            disabled={!isJobApplicationSelected(applicationStatus)}
            theme={getThemeByRoleId(RECRUITER)}
            onClick={handleDownloadResume}
            padding="6px 18px"
            margin="10px 0 0 0"
          >
            {downloadInprocess ? (
              <LoadingOutlined spin />
            ) : (
              <DownloadOutlined />
            )}
            Download
          </StyledOutlinedButton>

          <StyledOutlinedButton
            theme="recruiter"
            margin="10px 0 0 0"
            onClick={handleModalOpen}
          >
            View Profile
          </StyledOutlinedButton>
          {profileData && (
            <ViewProfileModal
              visible={isModal}
              profileData={profileData}
              handleModalClose={handleModalClose}
              application={application}
            />
          )}
        </StyledButtonsContainer>
      </Row>

      <Row>
        <StyledLeftOptionCol xs={10} md={13} marginTop="38px">
          <span>Candidate match</span>
        </StyledLeftOptionCol>
        <StyledRightOptionCol xs={10} md={10} marginTop="38px">
          <StyledYellowTextWrapper>
            {getCandidateMatchPercentage
              ? `${Math.round(getCandidateMatchPercentage)} %`
              : initialValue}
          </StyledYellowTextWrapper>
        </StyledRightOptionCol>
      </Row>

      <Row>
        <StyledLeftOptionCol xs={10} md={13} marginTop="19px">
          <span>Expected CTC</span>
        </StyledLeftOptionCol>
        <StyledRightOptionCol xs={10} md={10} marginTop="19px">
          <span>{ViewCandidateExpectedSalary()}</span>
        </StyledRightOptionCol>
      </Row>

      <Row>
        <StyledLeftOptionCol xs={10} md={13} marginTop="19px">
          <span>Location</span>
        </StyledLeftOptionCol>
        <StyledRightOptionCol xs={10} md={10} marginTop="19px">
          <span>
            {!city && !country
              ? 'Not available'
              : `${city ? `${city}, ` : ''}${country ? `${country}` : ''}`}
          </span>
        </StyledRightOptionCol>
      </Row>

      <Row>
        <StyledLeftOptionCol xs={10} md={13} marginTop="19px">
          <span>Willing to relocate</span>
        </StyledLeftOptionCol>
        <StyledRightOptionCol xs={10} md={10} marginTop="19px">
          <span>{willingToRelocate === YES ? YES_TEXT : NO_TEXT}</span>
        </StyledRightOptionCol>
      </Row>

      <Row>
        <StyledLeftOptionCol xs={10} md={13} marginTop="19px">
          <span>Availability to start</span>
        </StyledLeftOptionCol>
        <StyledRightOptionCol xs={10} md={10} marginTop="19px">
          <span>{startAvailability}</span>
        </StyledRightOptionCol>
      </Row>

      <StyledDashed margin="15px 0 10px 0" />
      <StyledSkillsContainer>
        {anyOtherSkillMatch.length ||
          preferedSkillsMatch.length ||
          mandatorySkillsMatch.length ? (
          <StyledCollapse ghost>
            <StyledCollapsed header="Skill Match" key="1">
              <Row>
                {mandatorySkillsMatch.map((skill, idx) => (
                  <SkillTag1 skill={skill} idx={idx} />
                ))}
              </Row>
              <Row>
                {preferedSkillsMatch.map((skill, idx) => (
                  <SkillTag2 skill={skill} idx={idx} />
                ))}
              </Row>
              <Row>
                {anyOtherSkillMatch.map((skill, idx) => (
                  <SkillTag3 skill={skill} idx={idx} />
                ))}
              </Row>
            </StyledCollapsed>
          </StyledCollapse>
        ) : null}

        {jobsMandatorySkillsMatch.length || jobsPreferedSkillsMatch.length ? (
          <StyledCollapse ghost>
            <StyledCollapsed header="Jobs Skills" key="1">
              <Row>
                {jobsMandatorySkillsMatch.map((skill, idx) => (
                  <SkillTag1 skill={skill} idx={idx} />
                ))}
              </Row>
              <Row>
                {jobsPreferedSkillsMatch.map((skill, idx) => (
                  <SkillTag2 skill={skill} idx={idx} />
                ))}
              </Row>
            </StyledCollapsed>
          </StyledCollapse>
        ) : null}
      </StyledSkillsContainer>
      <StyledDashed margin="5px 0 15px 0" />
      <Row>
        <StyledApplicationStatusText>
          Application Status
        </StyledApplicationStatusText>
      </Row>
      <StyledRow>
        <StyledLeftOptionCol xs={12} lg={14} marginTop="19px">
          <StyledApplicationStatusWrapper>
            <StyledApplicationStatusSelect
              defaultValue={getApplicationStatusText(
                selectedJobApplicationStatus,
              )}
              value={getApplicationStatusText(selectedJobApplicationStatus)}
              onChange={handleChange}
              getPopupContainer={(trigger) =>
                get(
                  trigger,
                  'parentElement.parentElement',
                  get(trigger, 'parentElement'),
                )
              }
            >
              {candidateStatusFilter.map((item) => {
                const { id, name, value } = item;
                return (
                  <Option
                    id={id}
                    value={value}
                    disabled={isJobApplicationSelected(applicationStatus)}
                  >
                    {name}
                  </Option>
                );
              })}
            </StyledApplicationStatusSelect>
          </StyledApplicationStatusWrapper>
        </StyledLeftOptionCol>
        {!isJobApplicationSelected(applicationStatus) ? (
          <StyledRightOptionCol xs={10} lg={8} marginTop="19px">
            <StyledProceedButtonWrapper>
              <PrimaryButton
                disabled={selectedJobApplicationStatus === applicationStatus}
                onClick={handleStatusChange}
                theme="recruiter"
              >
                Proceed
              </PrimaryButton>
            </StyledProceedButtonWrapper>
          </StyledRightOptionCol>
        ) : null}
        {disclaimerVisible ? (
          <StyledRightOptionCol marginTop="19px">
            * Only shortlisted resumes are downloadable after the evaluation
            deadline. You may change the status multiple times (except
            “Shortlisted”) before the evaluation deadline. If you wish to save a
            resume to your ATS please shortlist to download
          </StyledRightOptionCol>
        ) : null}
      </StyledRow>

      {rejectionModalVisible ? (
        <RejectApplicationModal
          handleModalClose={handleSkipModalClose}
          visible={rejectionModalVisible}
          applicationId={application.id}
          status={selectedJobApplicationStatus}
          isInModal={isInModal}
          handleCurrentStatusChange={handleCurrentStatusChange}
        />
      ) : null}
    </StyledMainContainer>
  );
}

export default ResumeDetails;

const StyledBorder = styled.div`
  border-radius: 7px;

  ${({ isActive }) => (isActive ? 'border: 1.8px solid red;' : '')}
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 0px;
`;

const StyledCollapsed = styled(Panel)`
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .ant-collapse-arrow {
    position: relative !important;
    margin-right: 8px !important;
    padding-top: 0px !important;
    top: unset !important;
    left: unset !important;
  }
  svg {
    width: 10px;
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 12px;
    }
    margin-left: 5px;
  }
  .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 0 2px 0 !important;
    color: ${COLORS.ARSENIC}bd !important;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[15]};
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const StyledButtonsContainer = styled(Row)`
  column-gap: 20px;
  ${DISPLAY_FLEX('row', null, 'center')}
  ${MARGIN(10, 0, 0, 0)};

  @media (max-width: 1420px) {
    column-gap: 10px;
  }
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  padding: ${({ padding }) => padding || '6px 8px'};
  height: auto;
  font-size: 10px;
  font-weight: 600;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[12]};
  }

  svg {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }
`;

const StyledMainContainer = styled.div`
  ${DISPLAY_FLEX('column', 'center')};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.PHILLIPPINE_GRAY};
  font-size: ${FONT[14]};
  position: relative;
  padding: 10px;
  height: min-content;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
    height: auto;
    flex-shrink: inherit;
    padding: 15px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[20]};
  }

  ${({ isInModal }) =>
    isInModal
      ? `
    padding-top: 0;
  `
      : ''}
`;

export const StyledDashed = styled.div`
  ${({ margin }) => `margin: ${margin};`};
  overflow: hidden;
  height: 20px;
  color: #707070;
  opacity: 50%;

  &:after {
    content: '--------------------------------------';
    letter-spacing: 4px;
  }
`;

const StyledApplicantName = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 0.79;
  color: ${COLORS.CHINESE_BLACK};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }
`;

const StyledApplicantNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
  flex-wrap: wrap;
  row-gap: 12px;
  margin-bottom: 15px;

  ul {
    max-width: max-content;
    padding-right: 20px;
  }
`;

const StyledEventNameRow = styled(Row)`
  margin-bottom: 0px;
`;

const StyledEventName = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 0.94;
  color: ${COLORS.LIGHT_ORANGE};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 18px;
  }
`;

const StyledLeftOptionCol = styled(Col)`
  font-size: 13px;
  font-weight: 600;

  color: ${COLORS.ARSENIC}bd;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[15]};
  }

  span {
    font-size: inherit;
  }
`;

const StyledRow = styled(Row)`
  column-gap: 5px;
`;

const StyledRightOptionCol = styled(Col)`
  font-size: 13px;
  font-weight: 600;
  color: ${COLORS.ARSENIC}bd;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[15]};
  }

  span {
    font-size: inherit;
  }
`;

const StyledYellowTextWrapper = styled.span`
  color: ${COLORS.SUPER_NOVA};
  font-weight: 700 !important;
  opacity: 100% !important;
`;

const StyledApplicationStatusText = styled.div`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.PHILLIPPINE_GRAY};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-top: 10px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

const StyledApplicationStatusWrapper = styled.div`
  width: 100%;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 30px;
  }
`;

const StyledApplicationStatusSelect = styled(Select)`
  color: ${COLORS.EERIE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledProceedButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 113px;
  height: 30px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 116px;
    height: 32px;
  }

  button {
    width: 100%;
    min-width: unset;
    height: 32px;
  }
`;

const StyledResumeSkillCol = styled(Col)`
  margin-top: 20px;
  margin-right: 20px;
`;

const StyledSkillButton = styled(Button)`
  background-color: ${(props) => props.bgColor};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  width: min-content;
  height: min-content;
  align-self: center;
  border-radius: 5px;
  border-color: ${({ borderColor }) => borderColor};

  span {
    color: ${(props) => props.color};
    font-size: 12px;
    font-weight: 600;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 14px;
    }
  }

  &.button {
    width: min-content;
    height: min-content;
    max-width: max-content;
    padding: 6px 22px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.bgColor};
    border-color: ${({ borderColor }) => borderColor};
    box-shadow: none;
  }
`;

const StyledSkillBagde = styled(Badge)`
  .ant-badge-count {
    background-color: ${COLORS.LIGHT_SLATE_BLUE};
    box-shadow: ${SHADOWS.SHADOW2};
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding-top: 3px;
    padding-left: 7.2px;

    font-size: 10px;
    font-weight: 600;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 12px;
    }
  }
`;

const StyledSkillsContainer = styled.div`
  ${DISPLAY_FLEX('column')}
  gap: 15px;
`;
