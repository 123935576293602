import get from 'lodash/get';
import api from './api';
import {
  Method,
  AdminApis,
  DEFAULT_PAGE,
  DEFAULT_LIMIT,
  ApiType,
} from '../constants';
import { isValid } from '../utils/api';
import ERRORS from '../constants/errors';
import Notification from '../components/Notification/Notification';
import { checkIfUserIsRecruiter } from '../utils/userInfo';
import { getBulkInviteFilterQueryString } from '../utils/admin/event';
import { getExportFileS3 } from '../utils/admin/bulkInvite';
import { JOB_STATUS_FILTER_DEFAULT } from '../constants/admin';

const getSkills = (
  page,
  limit,
  populate = 'new',
  search = '',
  sorter = null,
  statusFilter = null,
) => {
  statusFilter = statusFilter?.filter((el) => el);
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }skill/?page=${page}&limit=${limit}&populate=${populate}${
      sorter ? `&sort=${sorter}` : ''
    }${search ? `&search=${search}` : ''}${
      statusFilter && statusFilter.length
        ? `&status=${JSON.stringify(statusFilter)}`
        : ''
    }`,
  });
};

const getAllSkills = (page, limit) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}skill/?populate=all&page=${page}&limit=${limit}`,
  });
};

const verifyCompany = (id, status) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}company/${id}`,
    body: { status },
  });
};

const getSkillsStats = () => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}skill/stat/`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

const getEventsSnooze = () => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}event/extend/options`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

const updateEventsSnooze = (eventIds, days) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}event/extend`,
    body: { eventIds, days },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

const approveSkill = (id) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}skill/${id}/approve/`,
  });
};

const getLiveEvents = (categoryId) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}live-event/${
      categoryId ? `?categoryId=${categoryId}` : ''
    }`,
  });
};

const mergeSkill = (redundantSkills, primarySkill) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}skill/merge/`,
    body: { redundantSkillIds: redundantSkills, mergeSkillId: primarySkill },
  });
};

const getLiveEventSkills = (ids = []) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}skill/event/?eventIds=${ids.toString()}`,
  });
};

const getRecruiters = (page, limit, search = '', sorter = null) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}client/?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }${sorter ? `&sort=${sorter}` : ''}`,
  });
};

const getCompanies = (role, page, limit, search = '') => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }company/?role=${role}&page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }`,
  });
};

const mergeCompanies = (primaryCompanyId, secondaryCompanyIds, role) => {
  return api({
    method: Method.post,
    url: `${AdminApis.baseUrl}merge/company/`,
    body: { primaryCompanyId, secondaryCompanyIds, role },
  });
};

const getMergedCompanies = (role, page, limit) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}merge/company/history/?role=${role}&page=${page}&limit=${limit}`,
  });
};

const getMergedCompanyDetails = (companyId, role) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}merge/company/${companyId}/history/?role=${role}`,
  });
};

/* Candidate */

const getCandidate = (
  page,
  limit,
  eventId = null,
  search = '',
  sorter = null,
) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}candidate/?page=${page}&limit=${limit}${
      sorter ? `&sort=${sorter}` : ''
    }&search=${search}${eventId ? `&eventId=${eventId}` : ''}`,
  });
};

const getCandidateProfile = (id) => {
  return api({
    method: Method.get,
    url: `${AdminApis.candidateProfile(id)}`,
  });
};

const getCandidateScheduledInterviews = (id, page, limit) => {
  return api({
    method: Method.get,
    url: `${AdminApis.candidateScheduledInterviews(
      id,
    )}?page=${page}&limit=${limit}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const getCandidateAppliedJobs = (
  id,
  page,
  limit,
  filter,
  search = '',
  sorter = null,
  status = null,
) => {
  return api({
    method: Method.get,
    url: `${AdminApis.candidateAppliedJobs(id)}?page=${page}&limit=${limit}${
      status ? `&status=${status}` : ''
    }${sorter ? `&sort=${sorter}` : ''}${search ? `&search=${search}` : ''}
    ${filter ? `&filter=${filter}` : ''}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const getRecruiter = (id) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}recruiter/company/${id}/`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const forwardCandidateApplication = (jobApplicationId) => {
  return api({
    url: AdminApis.forwardCandidateJobApplication(jobApplicationId),
    method: Method.put,
  });
};
export const rejectCandidateApplication = (
  jobApplicationId,
  rejectionReasons,
) => {
  return api({
    url: AdminApis.rejectCandidateApplication(jobApplicationId),
    method: Method.put,
    body: {
      rejectionReasons,
    },
  });
};

const getOrganizers = (page, limit, search = '', sorter = null) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }organizer/?page=${page}&limit=${limit}&search=${search}${
      sorter ? `&sort=${sorter}` : ''
    }`,
  });
};

const getOrganizer = (id) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}organizer/company/${id}/`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const getJobs = (
  page,
  limit,
  eventId = null,
  status,
  search = '',
  sorter = null,
  priority = null,
) => {
  const eventQuery = eventId ? `&eventId=${eventId}` : '';
  const statusQuery = status ? `&jobStatus=${status}` : '';
  const priorityQuery = priority ? `&priority=${priority}` : '';
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }job/?page=${page}&limit=${limit}&search=${search}${
      sorter ? `&sort=${sorter}` : ''
    }${eventQuery}${statusQuery}${priorityQuery}`,
  });
};

const getJobInformation = (id) => {
  return api({
    method: Method.get,
    url: AdminApis.jobInformation(id),
  });
};

const getEvents = (page, limit, fields, search = '', sorter = null) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }event/?page=${page}&limit=${limit}&search=${search}&fields=${fields}${
      sorter ? `&sort=${sorter}` : ''
    }`,
  });
};

const getEvaluationDeadlineExtendRequests = (
  id,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
  fields = null,
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.evaluationDeadlineExtendRequests + id
    }?page=${page}&limit=${limit}${fields ? `&fields=${fields}` : ''} `,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const rejectEvaluationDedlineExtendRequest = (id) => {
  return api({
    method: Method.put,
    url: AdminApis.statusChangeEvaluationDeadlineExtendRequest(id),
    body: {
      evaluationDeadlineExtensionRequestStatus: 20,
      evaluationDeadlineExtensionApproved: false,
      days: 0,
      hours: 0,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const approveEvaluationDedlineExtendRequest = (id, days, hours) => {
  return api({
    method: Method.put,
    url: AdminApis.statusChangeEvaluationDeadlineExtendRequest(id),
    body: {
      days,
      hours,
      evaluationDeadlineExtensionRequestStatus: 10,
      evaluationDeadlineExtensionApproved: true,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const allowCompanyAccess = async (companyId, body) => {
  return api({
    url: AdminApis.allowCompanyAccess(companyId),
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.success', false);
    })
    .catch(() => {
      Promise.reject(ERRORS.REQUEST_FAILED);
    });
};

export const changeCompanySubscriptionPlan = async (companyId, body) => {
  return api({
    url: AdminApis.companySubscriptionPlan(companyId),
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.success', false);
    })
    .catch(() => {
      Promise.reject(ERRORS.REQUEST_FAILED);
    });
};

export const getAdminEventAnalytics = (id) => {
  return api({
    method: Method.get,
    url: AdminApis.eventAnalytics(id),
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEventById = (id) => {
  return api({
    method: Method.get,
    url: AdminApis.event(id),
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.event', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const mergeHistory = (page, limit) => {
  return api({
    method: Method.get,
    url: `${AdminApis.mergeHistory}?page=${page}&limit=${limit}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCryptoPlan = () => {
  return api({
    method: Method.get,
    url: AdminApis.getCryptoPlans,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.cryptoIncentivePlans');
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCouponsForPricingPlan = (id) => {
  return api({
    method: Method.get,
    url: AdminApis.getCouponsForPricingPlan(id),
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.coupons');
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createCoupon = async (body) => {
  return api({
    method: Method.post,
    url: AdminApis.createCoupon,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      Notification('success', 'Coupon added Successfully');

      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editCoupon = async (id, body) => {
  return api({
    method: Method.put,
    url: AdminApis.editCoupon(id),
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      Notification('success', 'Coupon modified Successfully');

      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCoupons = (page, limit, search = '') => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}crypto-incentive-plan/coupon/?page=${page}&limit=${limit}&search=${search}`,
  });
};

export const deleteCoupon = (id) => {
  return api({
    method: Method.delete,
    url: `${AdminApis.baseUrl}crypto-incentive-plan/coupon/${id}/`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addSkill = async (name, skillsIds) => {
  return api({
    method: Method.post,
    url: AdminApis.addSkill,
    body: {
      skillName: name,
      relatedSkillIds: skillsIds,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      Notification('success', 'Skill added Successfully');

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const unMergeSkill = (primarySkillId) => {
  return api({
    method: Method.put,
    url: AdminApis.unMergeSkill,
    body: {
      primarySkillId,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateSkill = async (id, skill) => {
  return api({
    method: Method.post,
    url: AdminApis.updateSkill(id),
    body: {
      skill,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      Notification('success', 'Skill updated Successfully');

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const viewSkillMergeHistory = (primarySkillId, status) => {
  return api({
    method: Method.get,
    url: `${AdminApis.viewMergeSkillHistory}${primarySkillId}/${
      status ? `?status=${status}` : ''
    }`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
export const getJobApplicationsAnalyticsByJobId = (id, event) => {
  return api({
    method: Method.get,
    url: AdminApis.jobApplicationsAnalyticsAdmin(id, event),
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCrewMembersAdmin = (
  companyId,
  roleId,
  search = '',
  sorter = null,
) => {
  return api({
    url: `${AdminApis.companyMember(companyId, roleId)}?sort=${
      sorter || 'name_asc'
    }${search ? `&search=${search}` : ''}`,
    method: Method.get,
  });
};

export const getCompanyEventsAdmin = (
  companyId,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
  search = '',
  sorter = null,
) => {
  return api({
    url: `${AdminApis.companyEvents(companyId)}?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }${sorter ? `&sort=${sorter}` : ''}`,
    method: Method.get,
  });
};

export const inviteRegistererdMemberAdmin = (body) => {
  return api({
    url: AdminApis.addExistingMember,
    method: Method.post,
    body,
  });
};

export const inviteNewMemberAdmin = (body) => {
  return api({
    url: AdminApis.inviteNewMember,
    method: Method.post,
    body,
  });
};

export const removeMemberInviteAdmin = (body) => {
  return api({
    url: AdminApis.removeInvite,
    method: Method.post,
    body,
  });
};

export const updateOrganizerProfileAdmin = (id, body) => {
  return api({
    url: AdminApis.updateOrganizerProfile(id),
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateRecruiterProfileAdmin = (id, body) => {
  return api({
    url: AdminApis.updateRecruiterProfile(id),
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editJobAdmin = async (id, companyId, body) => {
  return api({
    url: `${AdminApis.baseUrl}jobs/${id}/${companyId}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateCandidateProfileAdmin = (id, body) => {
  return api({
    url: AdminApis.updateCandidateProfile(id),
    method: Method.put,
    body,
  });
};

export const editEventAdmin = async (id, body) => {
  return api({
    url: `${AdminApis.baseUrl}event/${id}/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getIndustries = async (page, limit, name, sorter) => {
  const nameQuery = name ? `&name=${name}` : '';
  return api({
    url: `${
      AdminApis.baseUrl
    }industries?page=${page}&limit=${limit}${nameQuery}${
      sorter ? `&sort=${sorter}` : ''
    }`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addIndustry = async (body) => {
  return api({
    url: `${AdminApis.baseUrl}industries`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editIndustry = async (id, body) => {
  return api({
    url: `${AdminApis.baseUrl}industries/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCurrencies = async (page, limit, name, sorter) => {
  const nameQuery = name ? `&name=${name}` : '';
  return api({
    url: `${
      AdminApis.baseUrl
    }currencies?page=${page}&limit=${limit}${nameQuery}${
      sorter ? `&sort=${sorter}` : ''
    }`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addCurrency = async (body) => {
  return api({
    url: `${AdminApis.baseUrl}currency`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editCurrency = async (id, body) => {
  return api({
    url: `${AdminApis.baseUrl}currency/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const rejectSkill = (skillId) => {
  return api({
    method: Method.put,
    url: AdminApis.rejectSkill,
    body: {
      skillId,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const approveRejectSkillHistory = (page, limit) => {
  return api({
    method: Method.get,
    url: `${AdminApis.approveRejectSkillHistory}?page=${page}&limit=${limit}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getInvitedCandidate = (
  eventId,
  page,
  limit,
  profileFilter = 0,
  registerFilter = 0,
  joinFilter = 0,
  search = '',
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }invited/candidates/${eventId}?page=${page}&limit=${limit}&${getBulkInviteFilterQueryString(
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    )}`,
  });
};

export const getInvitedCompanies = (
  eventId,
  page,
  limit,
  profileFilter = 0,
  registerFilter = 0,
  joinFilter = 0,
  search = '',
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }invited/company/${eventId}?page=${page}&limit=${limit}&${getBulkInviteFilterQueryString(
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    )}`,
  });
};

export const downloadInviterAttendeesCsv = async (
  eventId,
  role,
  profileFilter = 0,
  registerFilter = 0,
  joinFilter = 0,
  search = '',
) => {
  let exportType = 'candidates';
  if (checkIfUserIsRecruiter(role)) {
    exportType = `companies`;
  }
  const url = `${
    AdminApis.baseUrl
  }invited/${exportType}/csv/${eventId}?${getBulkInviteFilterQueryString(
    profileFilter,
    registerFilter,
    joinFilter,
    search,
  )}`;
  return api({
    url,
    method: Method.get,
  })
    .then((response) => response.data.payload.fileName)
    .then((name) => {
      const link = document.createElement('a');
      link.download = name;
      link.href = getExportFileS3(name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {});
};

export const getCompaniesByEventId = (
  eventId,
  page = 1,
  limit = -1,
  fields = 'basic',
) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}company/${eventId}?page=${page}&limit=${limit}&fields=${fields}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobsByCompanyId = (
  companyId,
  page = 1,
  limit = -1,
  fields = 'basic',
  status,
  sorter,
) => {
  const statusQuery = status ? `&filter=${status}` : '';
  const sortQuery = sorter ? `&sort=${sorter}` : '';
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}company/jobs?page=${page}&limit=${limit}&fields=${fields}&companyId=${companyId}${statusQuery}${sortQuery}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobsMatchCandidates = (
  eventId,
  companyIds = null,
  search = '',
  jobs = [],
  jobStatus = JOB_STATUS_FILTER_DEFAULT,
  fetchForInactiveJobs = null,
  applicationStatus = JOB_STATUS_FILTER_DEFAULT,
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }job/match/candidates/${eventId}?search=${search}&jobStatusFilter=${jobStatus}&applicationStatusFilter=${applicationStatus}${
      companyIds && companyIds.length
        ? `&companyIds=${JSON.stringify(companyIds)}`
        : ''
    }${jobs.length ? `&jobs=${JSON.stringify(jobs)}` : ''}${
      fetchForInactiveJobs
        ? `&fetchForInactiveJobs=${fetchForInactiveJobs}`
        : ''
    }
    `,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const downloadJobsMatchCandidatesCSV = (
  eventId,
  companyIds = null,
  search = '',
  jobs = [],
  jobStatus = JOB_STATUS_FILTER_DEFAULT,
  fetchForInactiveJobs = null,
  applicationStatus = JOB_STATUS_FILTER_DEFAULT,
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }job/match/candidates/${eventId}?search=${search}&jobStatusFilter=${jobStatus}&applicationStatusFilter=${applicationStatus}&isExport=true${
      companyIds && companyIds.length ? `&companyIds=${companyIds}` : ''
    }${jobs.length ? `&jobs=${JSON.stringify(jobs)}` : ''}${
      fetchForInactiveJobs
        ? `&fetchForInactiveJobs=${fetchForInactiveJobs}`
        : ''
    }`,
  })
    .then((response) => response.data.payload.fileName)
    .then((name) => {
      const link = document.createElement('a');
      link.download = name;
      link.href = getExportFileS3(name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {});
};

export const activateEventBooking = (bookingId, roleId) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}event/booking/activate`,
    body: {
      bookingId,
      roleId,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getUnverfiedAccounts = (
  roleId,
  search = '',
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
  sorter = null,
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }unverified/users?roleId=${roleId}&search=${search}&page=${page}&limit=${limit}${
      sorter ? `&sort=${sorter}` : ''
    }`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteVerificationToken = (token) => {
  return api({
    method: Method.delete,
    url: `${AdminApis.baseUrl}delete/token?referenceToken=${token}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobPageEvents = (id) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}job/events/${id}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.events', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobApplicationByJobId = (
  id,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
  event = null,
  statusFilter = null,
  sorter = null,
  locationFilter = null,
  search = '',
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }job/applications/${id}?page=${page}&limit=${limit}${
      event ? `&eventId=${event.id}` : ''
    }${statusFilter ? `&statusFilter=${statusFilter}` : ''}${
      sorter ? `&sort=${sorter}` : ''
    }${
      locationFilter && locationFilter.length
        ? `&location=${locationFilter.join()}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getSkillDemandSupply = (eventIds = [], page, limit, top = 10) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }skill/event/demand-supply/?page=${page}&limit=${limit}&top=${top}&eventIds=${eventIds.toString()}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobsByCompanyIds = (
  companyIds,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
  fields = 'basic',
) => {
  return api({
    method: Method.get,
    url: `${
      AdminApis.baseUrl
    }companies/jobs?page=${page}&limit=${limit}&fields=${fields}&companyIds=${JSON.stringify(
      companyIds,
    )}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getGeneralSettingRecords = async (
  page,
  limit,
  name,
  entity,
  sorter,
) => {
  const query = name ? `&name=${name}` : '';
  return api({
    url: `${
      AdminApis.baseUrl
    }general-setting/records?page=${page}&limit=${limit}${
      sorter ? `&sort=${sorter}` : ''
    }${query}${entity ? `&entity=${entity}` : ''}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addGeneralSettingEntity = async (body, entity) => {
  return api({
    url: `${AdminApis.baseUrl}${entity}`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editGeneralSettingEntity = async (id, body, entity) => {
  return api({
    url: `${AdminApis.baseUrl}${entity}/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const switchAutoPresentAdmin = async (eventId, companyId, data) => {
  return api({
    url: `${AdminApis.baseUrl}event/auto-present/${eventId}/${companyId}`,
    method: Method.put,
    body: data,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const suspendUserAccount = (userId, status, companyId) => {
  return api({
    method: Method.post,
    url: `${AdminApis.baseUrl}suspend/account`,
    body: { status, userId, companyId },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const verifyRecruiterCompany = (id, status) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}recruiter/company/${id}`,
    body: { status },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteJob = (id) => {
  return api({
    method: Method.delete,
    url: `${AdminApis.baseUrl}job/${id}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCategories = async (page, limit, name, sorter) => {
  const query = name ? `&name=${name}` : '';
  return api({
    url: `${
      AdminApis.baseUrl
    }general-setting/categories?page=${page}&limit=${limit}${
      sorter ? `&sort=${sorter}` : ''
    }${query}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editCategory = async (id, body) => {
  return api({
    url: `${AdminApis.baseUrl}general-setting/category/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addCategory = async (body) => {
  return api({
    url: `${AdminApis.baseUrl}category`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateSubcategories = async (id, body) => {
  return api({
    url: `${AdminApis.baseUrl}category/sub/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCompanySearchData = (value, eventId) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}company/recruiter/?search=${value}&eventId=${eventId}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.companies', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEventJobsData = (eventId) => {
  return api({
    method: Method.get,
    url: `${AdminApis.baseUrl}event/job/${eventId}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.eventJobs', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getWorkAuthorizationDataAdmin = async (id) => {
  return api({
    url: `${AdminApis.baseUrl}work-authorization/?userId=${id}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.workAuthorizations', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const bulkSignupUsers = async (file) => {
  const url = `${AdminApis.baseUrl}signup/user/csv/`;
  const method = Method.post;
  const headers = { Accept: 'application/json' };
  const contentType = 'multipart/form-data';
  const formData = new FormData();
  formData.append('file', file);

  return api({ url, method, headers, body: formData, contentType });
};

export const signupUserAdmin = async (body) => {
  return api({
    url: `${AdminApis.baseUrl}signup/user/`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addCustomCredit = async (body) => {
  return api({
    url: `${AdminApis.baseUrl}recruiter/open-source/credits/`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getAutoFillAttendingCompanyAdminData = async (search) => {
  return api({
    url: `${AdminApis.baseUrl}event/attending-companies/?search=${search}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.companies', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getBooleanQueryForJob = async (jobId) => {
  return api({
    url: `${AdminApis.baseUrl}job/${jobId}/boolean-search-query/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.booleanSearchStrings', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editCategoryVisibility = async (body) => {
  return api({
    url: `${AdminApis.baseUrl}category/visibility/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editJobPriority = async (id, body) => {
  return api({
    url: `${AdminApis.baseUrl}job/priority/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getMasterAdminDashboardAnalytics = async (
  startTime,
  endTime,
  maxGrowth,
) => {
  const url =
    startTime && endTime
      ? `${AdminApis.baseUrl}statistics/dashboard/?startTime=${startTime}&endTime=${endTime}&isMatricFromStart=${maxGrowth}`
      : `${AdminApis.baseUrl}statistics/dashboard/`;
  return api({
    url,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getMasterAdminDashboardJobs = async () => {
  return api({
    url: `${AdminApis.baseUrl}statistics/dashboard/?isForJobsPriority=true`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobsWithNoApplications = async (page, pageSize) => {
  return api({
    url: `${AdminApis.baseUrl}jobs/active/no-application/?page=${page}&limit=${pageSize}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getPreScreenJobsApplications = async (page, pageSize) => {
  return api({
    url: `${AdminApis.baseUrl}candidate/job-application/pre-screening/?page=${page}&limit=${pageSize}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobsWithNoShortlistedApplications = async (page, pageSize) => {
  return api({
    url: `${AdminApis.baseUrl}jobs/active/no-shortlist/?page=${page}&limit=${pageSize}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getColdOrWarmLeads = async (arg, page, pageSize) => {
  const query = arg === 'warm' ? true : false;
  return api({
    url: `${AdminApis.baseUrl}coldWarm/leads/?isWarmLeads=${query}&page=${page}&limit=${pageSize}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editJobVisibilityLandingPage = async (jobId, body) => {
  return api({
    url: `${AdminApis.baseUrl}jobs/${jobId}/marketing/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createJobRestrict = async (Id, body) => {
  return api({
    url: `/v2/master-admin/company/${Id}/job/restriction/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createBooleanQueryRestrict = async (Id, body) => {
  return api({
    url: `${AdminApis.baseUrl}company/${Id}/boolean-query/restriction/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const approveProfileVerification = async ({
  roleId,
  userId,
  companyId,
}) => {
  return api({
    url: `${AdminApis.baseUrl}identity/verify/`,
    method: Method.put,
    body: companyId
      ? {
          roleId,
          userId,
          companyId,
        }
      : {
          roleId,
          userId,
        },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const rejectProfileVerification = async ({
  roleId,
  userId,
  companyId,
}) => {
  return api({
    url: `${AdminApis.baseUrl}identity/reject/`,
    method: Method.put,
    body: companyId
      ? {
          roleId,
          userId,
          companyId,
        }
      : {
          roleId,
          userId,
        },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

const getJobRestrict = () => {
  return api({
    method: Method.get,
    url: `/v1/recruiter/job/create/valid/`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export {
  getSkills,
  getAllSkills,
  getSkillsStats,
  getEventsSnooze,
  updateEventsSnooze,
  approveSkill,
  mergeSkill,
  getLiveEventSkills,
  getLiveEvents,
  getRecruiters,
  getCandidateAppliedJobs,
  getCandidateScheduledInterviews,
  getCandidateProfile,
  getRecruiter,
  getCompanies,
  mergeCompanies,
  getMergedCompanies,
  getMergedCompanyDetails,
  getOrganizers,
  getOrganizer,
  getJobs,
  getJobInformation,
  getEvents,
  verifyCompany,
  getEvaluationDeadlineExtendRequests,
  rejectEvaluationDedlineExtendRequest,
  getCandidate,
  approveEvaluationDedlineExtendRequest,
  getJobRestrict,
};
