import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { MARGIN, TEXT_WRAP } from '../../../styles/constants/display';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { StyledHeading } from '../Texts';

export const EventTitle = styled(StyledHeading)`
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(0, null, 6)}

  color: ${COLORS.BLACK};
  ${TEXT_WRAP(500)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-width: 180px;
  }
`;
