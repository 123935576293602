import React, { useState, useEffect } from 'react';
import { Row, Tooltip, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import styled from 'styled-components';
import { RecruiterRoutes } from '../../constants/routes';
import VerifiedIcon from '../../assets/svg/identity-verified.svg';
import RejectedIcon from '../../assets/svg/identity-rejected.svg';
import PendingIcon from '../../assets/svg/identity-pending.svg';
import LinkIcon from '../../assets/svg/link.svg';
import {
  getRoleId,
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
  getUserClassWithRoleId,
  checkIfUserIsOrganizer,
} from '../../utils/userInfo';
import InviteMemberModal from '../CrewManagement/InviteMemberModal';
import { userProfileStore } from '../../utils/profile';
import { ToolTipsText } from '../../constants/tooltips';
import { PrimaryButton } from '../shared-components/Button';
import { COLORS, FILTERS } from '../../styles/constants/colors';
import { getTheme, getUrlVars } from '../../utils/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import {
  getUserBackgroundColorWithRoleId,
  getUserColorWithRoleId,
} from '../../styles/utils';
import { Theme } from '../../enums';
import { StyledExtraLargeHeading } from '../shared-components/Texts';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import SearchBar from '../Events/RecommendedEvents/SearchBar';
import { ORGANIZER_FILTER } from '../../styles/constants/styles';
import { getJobRestrict } from '../../service/masterAdmin';
import Notification from '../Notification/Notification';
import { IDENTITY_VERIFICATION } from '../../enums/identity-verification';
import { UnverifiedIcon, VerifiedProfileIcon } from '../svgicons';
import { STATUS_DRAFT } from '../../constants';
import { useGetCommunityPageQuery } from '../../store/features/rtkApi/common';

const DashboardHeader = ({ companyData }) => {
  const [visible, setVisible] = useState(false);
  const [isJobCreateValid, setIsJobCreateValid] = useState(false);
  const { profileData } = userProfileStore();
  const roleId = getRoleId();

  const companyId = get(companyData, 'id', null);

  const { isSuccess: isCommunityPageActive } = useGetCommunityPageQuery(
    companyId,
    {
      skip: !checkIfUserIsOrganizer(roleId),
    },
  );
  const openCrewManagementModal = () => {
    setVisible(true);
  };

  const CloseCrewManagementModal = () => {
    setVisible(false);
  };

  const isCandidate = checkIfUserIsCandidate(roleId);
  const isOrganizer = checkIfUserIsOrganizer(roleId);
  const isRecruiter = checkIfUserIsRecruiter(roleId);

  const PROFILE_NOT_VERIFIED_TEXT = (
    <StyledUnVerifiedText>Profile Not Verified</StyledUnVerifiedText>
  );
  const PROFILE_VERIFIED_TEXT = (
    <StyledVerifiedTextContainer
      color={!isCandidate ? getUserColorWithRoleId(roleId) : COLORS.GREEN}
    >
      Profile Verified
    </StyledVerifiedTextContainer>
  );

  const IDENTITY_NOT_VERIFIED_TEXT = (
    <StyledUnVerifiedText>Identification Failed</StyledUnVerifiedText>
  );
  const IDENTITY_VERIFIED_TEXT = (
    <StyledVerifiedTextContainer color={COLORS.GREEN}>
      Identification Approved
    </StyledVerifiedTextContainer>
  );
  const IDENTIFICATION_IN_PROGRESS_TEXT = (
    <StyledVerifiedTextContainer color={getUserColorWithRoleId(roleId)}>
      Identification in Progress
    </StyledVerifiedTextContainer>
  );

  let ToolTip = null;
  if (!isCandidate) {
    if (companyData && companyData.status === STATUS_DRAFT) {
      const dangerColor = getTheme(Theme.Danger);
      ToolTip = (
        <Tooltip
          placement="top"
          title={PROFILE_NOT_VERIFIED_TEXT}
          color={COLORS.WHITE}
          trigger={['click', 'hover']}
        >
          <StyledVerifiedContainer
            className="light-red-feature-tour"
            color={dangerColor}
            bgcolor={`${dangerColor}0f`}
          >
            <UnverifiedIcon />
          </StyledVerifiedContainer>
        </Tooltip>
      );
    } else {
      ToolTip = (
        <Tooltip
          placement={isRecruiter ? 'right' : 'top'}
          title={PROFILE_VERIFIED_TEXT}
          color={COLORS.WHITE}
          trigger={['click', 'hover']}
        >
          <StyledVerifiedContainer
            color={getUserColorWithRoleId(roleId)}
            bgcolor={getUserBackgroundColorWithRoleId(roleId)}
          >
            <VerifiedProfileIcon />
          </StyledVerifiedContainer>
        </Tooltip>
      );
    }
  }

  let ProfileVerificationToolTip = null;
  if (isCandidate || isOrganizer) {
    if (
      !!profileData &&
      profileData.identityVerification === IDENTITY_VERIFICATION.Rejected
    ) {
      const dangerColor = getTheme(Theme.Danger);
      ProfileVerificationToolTip = (
        <Tooltip
          placement="top"
          title={IDENTITY_NOT_VERIFIED_TEXT}
          color={COLORS.WHITE}
          trigger={['click', 'hover']}
        >
          <StyledVerifiedContainer
            className="light-red-feature-tour"
            color={dangerColor}
            bgcolor={`${dangerColor}0f`}
          >
            <img src={RejectedIcon} alt="link-icon" />
          </StyledVerifiedContainer>
        </Tooltip>
      );
    } else if (
      !!profileData &&
      profileData.identityVerification === IDENTITY_VERIFICATION.InProgress
    ) {
      const dangerColor = getTheme(Theme.Warning);
      ProfileVerificationToolTip = (
        <Tooltip
          placement="top"
          title={IDENTIFICATION_IN_PROGRESS_TEXT}
          color={COLORS.WHITE}
          trigger={['click', 'hover']}
        >
          <StyledVerifiedContainer
            className="light-red-feature-tour"
            color={dangerColor}
            bgcolor={`${dangerColor}0f`}
          >
            <img src={PendingIcon} alt="link-icon" />
          </StyledVerifiedContainer>
        </Tooltip>
      );
    } else {
      ProfileVerificationToolTip = (
        <Tooltip
          placement="top"
          title={IDENTITY_VERIFIED_TEXT}
          color={COLORS.WHITE}
          trigger={['click', 'hover']}
        >
          <StyledVerifiedContainer
            color={getUserColorWithRoleId(roleId)}
            bgcolor={getUserBackgroundColorWithRoleId(roleId)}
          >
            <img src={VerifiedIcon} alt="link-icon" />
          </StyledVerifiedContainer>
        </Tooltip>
      );
    }
  }

  const history = useHistory();

  const { search: searchString } = getUrlVars(
    get(history, 'location.search', ''),
  );

  const handleSearchChange = (value) => {
    if (!value.length) {
      return;
    }

    history.push(
      `/${
        isCandidate ? 'candidate' : 'recruiter'
      }/events/explore-events?search=${encodeURIComponent(value)}`,
    );
  };

  const color = getUserColorWithRoleId(roleId);

  const JobRestrictWarming = () => {
    Notification('warning', 'Jobs Restricted', 'Max allowed limit reached');
  };

  const fetchData = async () => {
    const res = await getJobRestrict();
    if (res.isValid) {
      setIsJobCreateValid(true);
    } else {
      setIsJobCreateValid(false);
    }
  };

  useEffect(() => {
    if (checkIfUserIsRecruiter(roleId)) {
      fetchData();
    }
  }, [roleId]);

  return (
    <div>
      <StyledRow roleId={roleId}>
        <StyledCol xs={24} sm={12} xxl={15} candidate={isCandidate}>
          <StyledExtraLarge>
            Hey, {companyData ? companyData.name : ''}
          </StyledExtraLarge>
          <div>{ToolTip}</div>
          <div>{ProfileVerificationToolTip}</div>
        </StyledCol>

        <StyledRightContainer xs={24} xxl={9} sm={12}>
          <StyledRightButtonContainer>
            {!isCandidate ? (
              <span className="manage-crew-feature-tour">
                <PrimaryButton
                  theme={getUserClassWithRoleId(roleId)}
                  onClick={openCrewManagementModal}
                >
                  <Tooltip
                    placement="bottom"
                    title={ToolTipsText.manageCrew}
                    id="dashboard_manage_crew_btn"
                  >
                    Manage Crew
                  </Tooltip>
                </PrimaryButton>
              </span>
            ) : null}

            {checkIfUserIsRecruiter(roleId) ? (
              isJobCreateValid ? (
                <Link to={RecruiterRoutes.createJob}>
                  <PrimaryButton
                    theme={getUserClassWithRoleId(roleId)}
                    id="dashboard_create_job"
                  >
                    + Create Job
                  </PrimaryButton>
                </Link>
              ) : (
                <PrimaryButton
                  theme={getUserClassWithRoleId(roleId)}
                  id="dashboard_create_job"
                  onClick={JobRestrictWarming}
                >
                  + Create Job
                </PrimaryButton>
              )
            ) : null}

            {checkIfUserIsOrganizer(roleId) &&
            get(companyData, 'slug', null) ? (
              <Tooltip
                placement="bottomRight"
                title={!isCommunityPageActive ? ToolTipsText.communityPage : ''}
                id="dashboard_community_page-btn"
                trigger={['hover', 'focus', 'click']}
              >
                <StyledCommunityButton isDisabled={!isCommunityPageActive}>
                  <PrimaryButton
                    theme={getUserClassWithRoleId(roleId)}
                    onClick={() =>
                      history.push(`/event-partner/${companyId}/community`)
                    }
                    disabled={!isCommunityPageActive}
                    padding="5px 8px"
                  >
                    <StyledFlexRow>
                      <StyledImg
                        src={LinkIcon}
                        alt="link-icon"
                        color={FILTERS.WHITE}
                      />
                      Community Page
                    </StyledFlexRow>
                  </PrimaryButton>
                </StyledCommunityButton>
              </Tooltip>
            ) : null}
          </StyledRightButtonContainer>
          {isCandidate && (
            <StyledSearchBar>
              <SearchBar
                getJoinedString={
                  searchString ? decodeURIComponent(searchString) : ''
                }
                setGetJoinedString={handleSearchChange}
                color={color}
              />
            </StyledSearchBar>
          )}
        </StyledRightContainer>
      </StyledRow>

      {visible ? (
        <InviteMemberModal
          visible={visible}
          handleClose={CloseCrewManagementModal}
          profileData={profileData}
        />
      ) : null}
    </div>
  );
};

export default DashboardHeader;

const StyledSearchBar = styled.div`
  width: 100%;
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: auto;
    margin-right: 0;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_5}) {
    width: 100%;
    margin-top: 3px;
  }

  .ant-select-selection-placeholder {
    font-size: 14.5px;

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      font-size: 11px;
    }
  }
`;

const StyledImg = styled.img`
  display: block;
  height: 12px;
  filter: ${({ color }) => color};
  margin-right: 6px;
`;

const StyledVerifiedContainer = styled.span`
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-left: 15px;
  background-color: ${(props) => props.bgcolor};
  ${DISPLAY_FLEX('row', 'center', 'center')}
  margin-top: -3px;

  svg,
  img {
    width: 23px;
    height: max-content;
    fill: ${(props) => props.color};
    transition: 0.2s;
  }

  &:hover svg,
  &:hover img {
    width: 19px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-left: 12px;
    margin-top: 17px;
  }
`;

const StyledVerifiedTextContainer = styled.span`
  color: ${(props) => props.color};
`;

const StyledUnVerifiedText = styled.span`
  color: ${COLORS.CUSTOM_RED};
`;

const StyledExtraLarge = styled(StyledExtraLargeHeading)`
  color: ${COLORS.ARSENIC};
  line-height: 46px;
  float: left;
  font-weight: ${FONT_WEIGHTS.BOLD};

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-top: 20px;
  }
`;

const StyledCol = styled(Col)`
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  justify-content: ${({ isCandidate }) =>
    isCandidate ? 'space-between' : 'flex-start'};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const StyledRightContainer = styled(Col)`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')};
  gap: 20px;
  margin-left: auto;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const StyledRightButtonContainer = styled.div`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')};
  gap: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    align-items: baseline;
    margin-top: 0;
    flex-wrap: nowrap;
  }

  margin-top: ${({ roleId }) =>
    checkIfUserIsCandidate(roleId) ? '15px' : '18px'};
  ${MARGIN(null, null, 15, null)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(22, null, null, null)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    flex-wrap: wrap !important;
  }
`;

const StyledFlexRow = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;

const StyledCommunityButton = styled.div`
  &:hover img {
    filter: ${(props) =>
      props.isDisabled === true ? props.color : ORGANIZER_FILTER};
    transition: all 0.3s ease-in-out;
  }
`;
