import { Row } from 'antd';
import first from 'lodash/first';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import config from '../../../config';
import { APIs } from '../../../constants';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { SHARP_IMAGE } from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

const { useScrapper } = require('react-tiny-link');

const VideoPreview: React.FC<any> = ({
  link = `https://www.youtube.com/embed/saWNMPL5ygk`,
  title = 'The Future of Crypto And what you should know about it',
  event,
  handleSelectLink,
}) => {
  const [data, loading] = useScrapper({
    url: link,
    proxyUrl: `${config.api_url}${APIs.public}url/preview?link=`,
  });

  return !loading ? (
    <StyledRow onClick={() => handleSelectLink(link)}>
      <StyledLeftContainer>
        <StyledImage
          src={first(get(data, 'image', [])) || get(event, 'cover', '')}
          alt="preview_image"
        />
      </StyledLeftContainer>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{get(data, 'description', '')}</StyledDescription>
      </StyledContent>
    </StyledRow>
  ) : null;
};

export default VideoPreview;

const StyledRow = styled(Row)`
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  ${SHARP_IMAGE}
  max-height: 100px;
`;

const StyledLeftContainer = styled.div`
  width: 56%;
  max-height: 100px;
`;

const StyledContent = styled.div`
  width: 44%;
  color: ${COLORS.BLACK};
  padding: 6px;
  background-color: ${COLORS.WHITE};
`;

const StyledTitle = styled.p`
  font-size: ${FONT[10]};
  width: 100%;
  margin: 0 0 8px 0;
  font-weight: ${FONT_WEIGHTS.BOLD};
  line-height: 1.3;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[12]};
  }
`;

const StyledDescription = styled.span`
  font-size: ${FONT[10]};
  word-break: break-word;
  line-height: 1.36;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[10]};
  }
`;
