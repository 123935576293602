import get from 'lodash/get';
import api from './api';
import { APIs, ApiType, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../utils/userInfo';

export const getDashboardAnalytics = async (roleId) => {
  let url = '';
  if (checkIfUserIsOrganizer(roleId)) url = APIs.organizerDashboardAnalytics;
  if (checkIfUserIsRecruiter(roleId)) url = APIs.recruiterDashboardAnalytics;
  if (checkIfUserIsCandidate(roleId)) url = APIs.candidateDashboardAnalytics;
  return api({
    url,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getOrganizerRecruiterAnalytics = async (eventId) => {
  return api({
    url: `${APIs.analytics}${APIs.organizerRecruiterAnalytics}${eventId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getOrganizerCandidateAnalytics = async (eventId) => {
  return api({
    url: `${APIs.analytics}${APIs.organizerCandidateAnalytics}${eventId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecruiterEventAnalytics = async (eventId) => {
  return api({
    url: `${APIs.analytics}${APIs.recruiterEventAnalytics}${eventId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecruiterEventAnalyticsByJobId = async (eventId, jobId) => {
  return api({
    url: `${APIs.analytics}${APIs.jobAnalytics}${eventId}/${jobId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
export const getCandidateEventAnalytics = async (eventId) => {
  return api({
    url: `${APIs.analytics}${APIs.candidateOpportunities}${eventId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecruiterSpecificEventAnalytics = async (eventId, jobs) => {
  return api({
    url: `${APIs.analytics}${APIs.recruiterSpecificEventAnalytics
      }${eventId}?jobIds=${JSON.stringify(jobs)}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecruiterApplicationAnalytics = async (eventIds = null, jobIds = null, country = null, availableStartTime = null, location = null, startDate = null, endDate = null, updated = false) => {
  return api({
    url: `${APIs.analytics}recruiter/application/?updated=${updated}${jobIds ? `&jobIds=${jobIds}` : ''}${eventIds ? `&eventIds=${eventIds}` : ''}${location ? `&jobLocationType=${location}` : ''
      }${country ? `&country=${country}` : ''}${availableStartTime ? `&startAvailability=${availableStartTime}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.analytics', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getPublicEventsStatistics = async (ids) => {
  return api({
    url: `${APIs.publicAnalytics}${ids.toString()}`,
    method: Method.get,
    auth: false,
    isAnalytics: true,
    type: ApiType.analytics
  })
    .then((response) => {
      return get(response, 'data.payload', null);
    })
    .catch(() => {
      return Promise.reject(ERRORS.REQUEST_FAILED);
    });
};
