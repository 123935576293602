import { gql } from '@apollo/client';
import { authClient, unAuthClient } from '../client';
import ERRORS from '../../constants/errors';

const GET_ALL_COUNTRIES = gql`
  query getAllCountries {
    countries {
      id
      name
      code
      status
    }
  }
`;

export const getAllCoutries = (addAuthHeader = true) => {
  const client = addAuthHeader ? authClient : unAuthClient;
  return client
    .query({
      query: GET_ALL_COUNTRIES
    })
    .then(response => response.data.countries)
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
