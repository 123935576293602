/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Row, Modal, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import WhiteLogo from '../../../../assets/svg/recorem_white_logo.svg';
import { Dropdown } from '../../../../components/shared-components/Form/Form';
import Arrow from '../../../../assets/svg/arrow.svg';
import WhiteLogoSymbol from '../../../../assets/svg/WhiteLogoSymbol.svg';

export default function ReportOverviewModal({
  handleModalClose,
  handleOk,
  visible,
}) {
  return (
    <Modal
      title={
        <>
          <p className="report-overview-modal__header large-heading">
            Report Overview
          </p>
          <div className="report__overview-modal__responsiveHeader">
            <a
              href={() => false}
              onClick={() => handleModalClose()}
              className="report__overview-modal__responsiveHeader__arrow__container"
            >
              <img
                className="report__overview-modal__responsiveHeader__arrow"
                src={Arrow}
                alt=""
              />
            </a>
            <div className="report__overview-modal__responsiveHeader__logo_Container">
              <div className="report__overview-modal__responsiveHeader__logo__title__container">
                <img
                  className="report__overview-modal__responsiveHeader__logo__title--logo"
                  alt=""
                  src={WhiteLogo}
                />
                <p className="report__overview-modal__responsiveHeader__logo__title--heading">
                  Report Overview
                </p>
              </div>
              <div className="report__overview-modal__responsiveHeader__sideLogo">
                <img
                  className="report__overview-modal__responsiveHeader__sideLogo--right"
                  src={WhiteLogoSymbol}
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleModalClose}
      className="report__overview-modal"
      width={window.screen.width < 768 ? '100%' : '70%'}
      footer={null}
    >
      <Row className="report__overview__heading-1 medium-font">
        Select a Reason
      </Row>
      <Row className="report__overview__heading-2 medium-font">
        Reason for Report
      </Row>
      <Row className="report__overview__reason medium-font">
        <Dropdown required placeholder="Reason" />
      </Row>
      <Row className="report__reason__details medium-font">Other Reason</Row>
      <Row className="report__reason__details--textarea">
        <TextArea rows={4} />
      </Row>
      <Row className="report-overview-modal__save__button--reverse">
        <Button
          className="report-overview-modal__save__button--right"
          onClick={() => {
            handleModalClose();
          }}
          type="text"
        >
          Submit
        </Button>
      </Row>
    </Modal>
  );
}
