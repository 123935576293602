import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import edit from '../../../assets/svg/edit.svg';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { COLORS, FILTERS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { SHADOWS } from '../../../styles/constants/shadows';
import {
  CircleEditButtonProps,
} from '../../../interfaces/components/CircleEditButton';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const CircleEditButton: React.FC<CircleEditButtonProps> = ({
  onClick,
  roleId,
}) => {
  return (
    <div>
      <StyledCircleButton color={getUserColorWithRoleId(roleId)} shape="circle" onClick={onClick}>
        <StyledEditIcon
          src={edit}
          alt="edit-button"
        />
      </StyledCircleButton>
    </div>
  );
};

export default CircleEditButton;

const StyledCircleButton = styled(Button)`
  position: absolute;
  right: 50px;
  padding: 12px;
  border-radius: 50%;
  box-shadow: ${SHADOWS.SHADOW2};
  color: ${COLORS.KIMBERLY};
  ${DISPLAY_FLEX('row', 'center', 'center')}
  top: 110px;
  width: 36px;
  height: 36px;
  background-color: ${(props) => props.color};

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: 8px;
    right: 10px;
  }
`;

const StyledEditIcon = styled.img`
  width: 16px;
  height: 16px;
  filter: ${FILTERS.WHITE};
`;
