/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import get from 'lodash/get';
import ResumeViewer from './ResumeViewer';
import ResumeDetails from './ResumeDetails';
import ResumeOverviewModal from './ReportOverviewModal';
import { getJobApplicationsByApplicationId } from '../../../../service/recruiter';
import { COLORS } from '../../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { SHADOWS } from '../../../../styles/constants/shadows';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../../styles/constants/display';

export default function ViewResume(props) {
  const jobId = get(props, 'jobId', null);
  const applicationId = get(props, 'applicationId', null);
  const eventId = get(props, 'eventId', null);
  const isInModal = get(props, 'isInModal', false);

  const [application, setApplication] = useState(get(props, 'application', {}));
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [visible, setModalActiveValue] = useState(false);

  /*
  const openModal = () => {
    setModalActiveValue(true);
  };
  */

  const handleOk = () => {
    setModalActiveValue(false);
  };

  const handleModalClose = () => {
    setModalActiveValue(false);
  };

  useEffect(() => {
    // Should'nt fetch in the case it's in modal as consumer component won't render as per condition
    if (!isInModal) {
      const getApplication = async () => {
        const application = await getJobApplicationsByApplicationId(
          jobId,
          applicationId,
          eventId,
        );

        setApplication(application);
      };
      getApplication();
    }
  }, [jobId, applicationId, eventId, isInModal]);

  const resumeLink = get(
    application,
    'candidate.resume',
    get(props, 'application.candidate.resume', ''),
  );

  return (
    <StyledMainContainer isInModal={isInModal}>
      <StyledViewResumeContainer isInModal={isInModal}>
        <StyledResumeViewText isInModal={isInModal}>
          Resume Viewer
        </StyledResumeViewText>
        <Row>
          <StyledColorCodeSection
            isInModal={isInModal}
            className="color-code-section-feature-tour"
          >
            <StyledMandatoryCircle isInModal={isInModal} />
            <StyledSkillInfoText isInModal={isInModal}>
              Mandatory
            </StyledSkillInfoText>
            <StyledPreferedCircle isInModal={isInModal} />
            <StyledSkillInfoText isInModal={isInModal}>
              Preferred
            </StyledSkillInfoText>
            <StyledOtherCircle isInModal={isInModal} />
            <StyledSkillInfoText isInModal={isInModal}>
              Other
            </StyledSkillInfoText>
          </StyledColorCodeSection>
        </Row>
        <StyledViewResumeInnerContainer>
          <StyledResumeWrapper isInModal={isInModal}>
            <ResumeViewer
              link={resumeLink}
              application={get(props, 'application', null) || application}
              selectedSkills={
                get(props, 'selectedSkills', null) || selectedSkills
              }
            />
          </StyledResumeWrapper>
          {isInModal ? null : (
            <StyledResumeOptions>
              <ResumeDetails
                application={application}
                jobId={jobId}
                setSelectedSkills={setSelectedSkills}
                selectedSkills={selectedSkills}
                eventId={eventId}
                isInModal={isInModal}
              />
            </StyledResumeOptions>
          )}
        </StyledViewResumeInnerContainer>
      </StyledViewResumeContainer>
      {visible ? (
        <ResumeOverviewModal
          handleModalClose={handleModalClose}
          handleOk={handleOk}
          visible={visible}
        />
      ) : null}
    </StyledMainContainer>
  );
}

const StyledMainContainer = styled.div`
  width: ${({ isInModal }) => (isInModal ? '69%' : '100%')};
  height: ${({ isInModal }) => (isInModal ? 'calc(100vh - 100px)' : 'auto')};
  border-right: 1px solid ${COLORS.MERCURY};
`;

const StyledViewResumeContainer = styled.div`
  ${DISPLAY_FLEX('column')};
  color: ${COLORS.SUPER_NOVA};
  background: ${COLORS.WHITE};

  ${({ isInModal }) =>
    isInModal
      ? `
    padding: 32px 0 23px 23px;
      `
      : `
  box-shadow: ${SHADOWS.SHADOW1};
  padding: 20px;
  `}

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) AND (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_4}) {
    ${({ isInModal }) =>
    isInModal
      ? `
    padding: 32px 0 23px 15px;
      `
      : ``}
  }

  z-index: 1;
  position: relative;
  width: 100%;
  height: auto;
`;

const StyledResumeViewText = styled(Row)`
  ${({ isInModal }) =>
    isInModal
      ? `
    display: none;
  `
      : ''}
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-bottom: 10px;
`;

const StyledColorCodeSection = styled.div`
  ${DISPLAY_FLEX('row')};

  ${({ isInModal }) => (isInModal ? `line-height: 0;` : '')}
`;

const StyledMandatoryCircle = styled.div`
  ${DISPLAY_FLEX('row')};
  ${MARGIN(10, 5, 0, 5)};
  background-color: ${COLORS.SUPER_NOVA};
  width: 14px;
  height: 14px;
  border-radius: 100%;

  ${({ isInModal }) => (isInModal ? `margin-top: 0;` : '')}
`;

const StyledPreferedCircle = styled.div`
  ${DISPLAY_FLEX('row')};
  ${MARGIN(10, 5, 0, 5)};
  background-color: ${COLORS.METALLIC_SILVER};
  width: 14px;
  height: 14px;
  border-radius: 100%;

  ${({ isInModal }) => (isInModal ? `margin-top: 0;` : '')}
`;

const StyledOtherCircle = styled.div`
  ${DISPLAY_FLEX('row')};
  ${MARGIN(10, 5, 0, 5)};
  background-color: ${COLORS.DODGER_BLUE};
  width: 14px;
  height: 14px;
  border-radius: 100%;

  ${({ isInModal }) => (isInModal ? `margin-top: 0;` : '')}
`;

const StyledSkillInfoText = styled.p`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.PHILLIPPINE_GRAY};
  padding: 0 10px;

  margin-top: ${({ isInModal }) => (isInModal ? 7 : 6)}px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledViewResumeInnerContainer = styled.div`
  ${DISPLAY_FLEX('row')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    flex-direction: column;
  }
`;

const StyledResumeWrapper = styled.div`
  ${DISPLAY_FLEX(null, 'center')};
  background: ${COLORS.WHITE};
  position: relative;
  z-index: 1;
  width: ${({ isInModal }) => (isInModal ? '100%' : '70%')};
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${({ isInModal }) =>
    isInModal ? 'calc(100vh - 170px)' : 'max-content'};
  padding-right: 23px;

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) AND (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_4}) {
    padding-right: 15px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GRAY_LIGHT_2};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.GRAY2};
  }

  div {
    width: 100%;
  }
`;

const StyledResumeOptions = styled.div`
  color: ${COLORS.DARK_SILVER};
  background: ${COLORS.WHITE};
  ${PADDING(5, 10, 5, 10)};
  display: flex;
  position: relative;
  z-index: 1;
  width: 30%;
  margin-top: 20px;
  height: max-content;
  box-shadow: ${SHADOWS.SHADOW1};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;
