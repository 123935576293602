import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Col, Row, Form, Modal, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CustomStyleFormGroup } from '../../components/Form/Form';
import { PrimaryButton } from '../../components/shared-components/Button';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { LargeFont } from '../../components/shared-components/Texts';
import { ViewDetailsIcon } from '../../components/svgicons';
import { checkIfUserIsCandidate } from '../../utils/userInfo';
import { MainWebsiteRoutes } from '../../constants/routes';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { largeFontMixin } from '../../styles/constants/mixins';
import {
  Email,
  OTPInput,
  Password,
  TextBox,
} from '../../components/shared-components/Form/Form';
import { SIGNUP_PAGE_FORMS } from '../../constants';
import { ResponsiveModalHeader } from '../../components/shared-components/ResponsiveModalHeader';
import RecoremLogo from '../../assets/svg/recorem_white_logo.svg';
import WhiteLogoSymbol from '../../assets/svg/WhiteLogoSymbol.svg';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { RightAlignContainer } from '../../components/shared-components/RightAlignContainer';

export const NextButton = () => {
  return (
    <PrimaryButton width="200" type="submit" padding="0 20px">
      <StyledNextButton>
        <LargeFont>Next</LargeFont>
        <ViewDetailsIcon />
      </StyledNextButton>
    </PrimaryButton>
  );
};

export const BackButton = ({ onClick }) => {
  return (
    <PrimaryButton type="button" padding="0 5px" onClick={onClick}>
      <LargeFont>Back</LargeFont>
    </PrimaryButton>
  );
};

export const LoginWithOTPForm = ({
  form,
  onFinish,
  setSelectedPage,
  color = COLORS.SOFT_BLUE,
  roleId,
  onSuffixClick,
  selectedPage,
}) => {
  return (
    <CustomStyleFormGroup
      form={form}
      name="loginwithOTP"
      onFinish={onFinish}
      expand
    >
      <StyledFormContainer>
        <OTPCommon
          color={color}
          roleId={roleId}
          onSuffixClick={() =>
            onSuffixClick(
              form.getFieldError('email'),
              form.getFieldValue('email'),
            )
          }
          selectedPage={selectedPage}
        />
        <StyledOtpButton bgColor={color} type="submit">
          continue
        </StyledOtpButton>
        <StyledLoginText
          color={COLORS.DODGER_BLUE}
          onClick={() => setSelectedPage(SIGNUP_PAGE_FORMS.PASSWORD_LOGIN)}
        >
          Login with password
        </StyledLoginText>
      </StyledFormContainer>
    </CustomStyleFormGroup>
  );
};

export const InitialEmailForm = ({
  form,
  onFinish,
  setSelectedPage,
  roleId,
  color,
  rememberMe,
  rememberMeOnChange,
}) => {
  return (
    <CustomStyleFormGroup form={form} name="login" onFinish={onFinish} expand>
      <StyledFormContainer>
        <StyledEmailContainer>
          <Email placeholder="Email Address" name="email" />
        </StyledEmailContainer>
        <StyledOtpButton bgColor={color} type="submit">
          get otp
        </StyledOtpButton>
        <StyledCheckbox
          color={color}
          checked={rememberMe}
          onChange={rememberMeOnChange}
        >
          Keep me logged in
        </StyledCheckbox>
        <StyledButton
          onClick={() => setSelectedPage(SIGNUP_PAGE_FORMS.PASSWORD_LOGIN)}
        >
          Login with password
        </StyledButton>
      </StyledFormContainer>
    </CustomStyleFormGroup>
  );
};

export const OTPCommon = ({
  color,
  roleId,
  onSuffixClick,
  selectedPage,
  isCandidateSignup,
  rememberMeOnChange,
  rememberMe,
}) => {
  return (
    <>
      <StyledEmailContainer>
        <Email placeholder="Email Address" name="email" required />
      </StyledEmailContainer>

      {selectedPage === 2 && !checkIfUserIsCandidate(roleId) ? (
        <>
          <StyledEmailContainer>
            <TextBox
              placeholder="Company Name"
              name="companyName"
              rule="text"
              required
            />
          </StyledEmailContainer>
          <StyledEmailContainer>
            <TextBox
              name="companyWebsite"
              placeholder="Company Website"
              rule="companyWebsite"
              required
            />
          </StyledEmailContainer>
        </>
      ) : null}
      <StyledOtpContainer>
        <OTPInput
          name="otp"
          color={color}
          onSuffixClick={onSuffixClick}
          required
        />
      </StyledOtpContainer>
      {isCandidateSignup ? (
        <StyledKeepMeLoggedIn>
          <StyledCheckbox
            color={color}
            checked={rememberMe}
            onChange={rememberMeOnChange}
          >
            Keep me logged in
          </StyledCheckbox>
        </StyledKeepMeLoggedIn>
      ) : null}
      <StyledOTPInfoRow>
        <Col xs={3}>
          <StyledInfoCircleOutlined color={color} />
        </Col>
        <StyledOtpMessage xs={21}>
          OTP has been sent to your email address. Please enter and continue
          with Recorem.
        </StyledOtpMessage>
      </StyledOTPInfoRow>
    </>
  );
};

export const SignupOrLoginForm = ({
  form,
  onFinish,
  color,
  roleId,
  onSuffixClick,
  selectedPage,
  onBackClick,
  rememberMeOnChange,
  rememberMe,
  isInProcess,
}) => {
  return (
    <CustomStyleFormGroup form={form} name="signup" onFinish={onFinish} expand>
      <StyledFormContainer>
        <OTPCommon
          color={color}
          roleId={roleId}
          onSuffixClick={() =>
            onSuffixClick(
              form.getFieldError('email'),
              form.getFieldValue('email'),
            )
          }
          selectedPage={selectedPage}
          isCandidateSignup
          rememberMeOnChange={rememberMeOnChange}
          rememberMe={rememberMe}
        />
        <StyledCheckBoxWrapper>
          By continuing, you agree to our{' '}
          <StyledLinks
            href={MainWebsiteRoutes.termsAndConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of use
          </StyledLinks>{' '}
          and{' '}
          <StyledLinks
            href={MainWebsiteRoutes.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </StyledLinks>
          , and you understand how Recorem stores and uses your data.{' '}
          <StyledLinks
            href={MainWebsiteRoutes.gdpr}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </StyledLinks>
        </StyledCheckBoxWrapper>
        <StyledOtpButton bgColor={color} type="submit" disabled={isInProcess}>
          {isInProcess ? <Spin /> : 'continue'}
        </StyledOtpButton>
        <StyledBackButton color={color} onClick={onBackClick}>
          <span>Back</span>
        </StyledBackButton>
      </StyledFormContainer>
    </CustomStyleFormGroup>
  );
};

export const PasswordLoginForm = ({
  form,
  onFinish,
  setSelectedPage,
  color,
  rememberMeOnChange,
  handleForgotPassword,
  rememberMe,
}) => {
  return (
    <CustomStyleFormGroup
      form={form}
      name="login-with-password"
      onFinish={onFinish}
      expand
    >
      <StyledFormContainer>
        <StyledEmailContainer>
          <Email placeholder="Email Address" name="email" />
        </StyledEmailContainer>

        <StyledPasswordContainer>
          <Password placeholder="Password" name="password" />
        </StyledPasswordContainer>
        <StyledForgotPasswordContainer>
          <StyledForgotPasswordButton
            color={color}
            type="button"
            onClick={() =>
              handleForgotPassword(
                form.getFieldError('email'),
                form.getFieldValue('email'),
              )
            }
          >
            Forgot password?
          </StyledForgotPasswordButton>
        </StyledForgotPasswordContainer>

        <StyledOtpButton bgColor={color} type="submit">
          continue
        </StyledOtpButton>
        <StyledCheckbox
          color={color}
          checked={rememberMe}
          onChange={rememberMeOnChange}
        >
          Keep me logged in
        </StyledCheckbox>
        <StyledButton
          onClick={() => setSelectedPage(SIGNUP_PAGE_FORMS.INITIAL)}
        >
          Login with OTP
        </StyledButton>
      </StyledFormContainer>
    </CustomStyleFormGroup>
  );
};

export const SocialSignUp = ({ roleId, visible, handleCancel, onFinish }) => {
  const [form] = Form.useForm();
  return (
    <StyledModal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      centered
      width="80%"
    >
      <ResponsiveModalHeader
        handleModalCancel={handleCancel}
        title=""
        color={COLORS.DODGER_BLUE}
      />
      <StyledWrapper id="social_sigup_modal" isSocialModal>
        <StyledLeftConatiner xs={24} md={7} color={COLORS.DODGER_BLUE}>
          <Row>
            <StyledRecoremLogo src={RecoremLogo} alt="Recorem Logo" />
          </Row>
          <Row>
            <StyledWhiteLogo
              src={WhiteLogoSymbol}
              alt="Recorem Logo Half"
              isSocialModal
            />
          </Row>
        </StyledLeftConatiner>
        <StyledRightConatiner xs={24} md={17}>
          <div>
            <StyledWelcomeText color={COLORS.DODGER_BLUE}>
              Almost There!!
            </StyledWelcomeText>
            <LargeFont>
              Please fill in your company details to complete the signup.
            </LargeFont>
            <CustomStyleFormGroup
              form={form}
              name="social-signup-company"
              onFinish={onFinish}
              expand
            >
              <StyledFormContainer>
                <TextBox
                  placeholder="Company Name"
                  name="companyName"
                  rule="text"
                  required
                />
                <TextBox
                  name="companyWebsite"
                  placeholder="Company Website"
                  rule="companyWebsite"
                  required
                />
                <RightAlignContainer>
                  <NextButton roleId={roleId} />
                </RightAlignContainer>
              </StyledFormContainer>
            </CustomStyleFormGroup>
          </div>
        </StyledRightConatiner>
      </StyledWrapper>
    </StyledModal>
  );
};

const StyledNextButton = styled(Row)`
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  width: 100%;
  svg {
    fill: ${COLORS.WHITE};
    width: 14px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    min-width: 100px;
  }
`;

const StyledFormContainer = styled.div`
  ${MARGIN(20, 0)}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const genericInputStyle = css`
  max-width: 360px;
  width: 100%;

  .ant-form-item {
    height: 47px;
    margin-bottom: 12px;
  }

  .ant-form-item-control-input {
    min-height: 37px;
  }

  .ant-input {
    background-color: rgb(243, 243, 243);
    padding: 0;
    border: none;
    &:hover,
    :focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .ant-form-item-control-input {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 49px;
    border: none;
    padding: 0 18px;
    margin-bottom: 10px;
  }

  .ant-form-item-control-input {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 49px;
    border: none;
    padding: 0 18px;
    margin-bottom: 10px;
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus {
    outline: 0;
    box-shadow: none;
    background-color: transparent;
    border: none;
  }

  .ant-input:placeholder-shown {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
    color: #00000033;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ::placeholder {
      font-size: 17px;
    }
  }
`;

const StyledEmailContainer = styled.div`
  ${genericInputStyle};

  .ant-form-item-explain.ant-form-item-explain-error {
    display: none;
  }
`;

const genericOtherInputStyle = css`
  .ant-input-affix-wrapper {
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: ${COLORS.ANTI_FLASH_WHITE};

    @media (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      padding-top: 2px;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper {
    background-color: transparent;
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
    outline: 0;
    box-shadow: none;
    background-color: transparent;
    border: none;
  }
`;

const StyledPasswordContainer = styled.div`
  ${genericInputStyle};
  ${genericOtherInputStyle}

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledOtpContainer = styled.div`
  ${genericInputStyle};
  ${genericOtherInputStyle}
`;

const StyledLoginText = styled.div`
  ${largeFontMixin}

  color: ${(props) => props.color};
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${COLORS.WHITE};
  background-color: ${(props) => props.color};
  border-radius: 50%;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const StyledOTPInfoRow = styled(Row)`
  ${MARGIN(10, 0, 15, 0)}
  ${DISPLAY_FLEX('row', null, 'center')}
  .ant-col {
    ${DISPLAY_FLEX('row', null, 'center')}
  }
`;

const StyledCheckBoxWrapper = styled.div`
  ${MARGIN(10, 0, 22)}
  font-size: 11px;
`;

export const StyledModal = styled(Modal)`
  padding: 0;
  overflow: hidden;
  max-width: 80rem;
  border-radius: 1rem;
  margin-bottom: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    overflow: unset;
  }

  .ant-modal-body {
    padding: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: fit-content !important;
  }

  .ant-modal-content {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: 100vh;
    }
  }
`;

export const StyledLeftConatiner = styled(Col)`
  height: unset;
  background-color: ${(props) => props.color};
  ${DISPLAY_FLEX('column', 'space-between')}
  ${PADDING(40, 20)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

export const StyledWhiteLogo = styled.img`
  opacity: 0.1;
  margin-right: -70px;
  margin-top: ${(props) => (props.isSocialModal ? '0' : '-60px')};
  margin-bottom: ${(props) => (props.isSocialModal ? '-40px' : '0')};
`;

export const StyledRecoremLogo = styled.img`
  width: 100%;
`;

export const StyledWrapper = styled(Row)`
  min-height: 300px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    min-height: 350px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    min-height: auto;
    height: auto;
  }
`;

export const StyledRightConatiner = styled(Col)`
  ${DISPLAY_FLEX('column', 'space-between')}
`;

export const StyledWelcomeText = styled.span`
  color: ${(props) => props.color};
  font-size: 30px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 22px;
  }
`;

const StyledLinks = styled.a`
  cursor: pointer !important;
  font-size: inherit;
  color: ${COLORS.DODGER_BLUE} !important;
`;

const StyledOtpMessage = styled(Col)`
  font-size: 12px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 14px;
  }
`;

const genericButton = css`
  height: 37px;

  background-color: ${({ bgColor }) => bgColor};
  border-radius: 49px;

  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.255em;
  color: ${COLORS.WHITE};
  border: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 39px;
    font-size: 17px;
  }
`;

const StyledOtpButton = styled.button`
  ${genericButton};
  max-width: 310px;
  width: 100%;
  margin-bottom: 22px;
`;

const StyledBackButton = styled.button`
  ${genericButton};
  max-width: 110px;
  width: 100%;
  background-color: white;
  color: ${({ color }) => color};
  border: 2px solid ${({ color }) => color};
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox + span {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    color: #636363;
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 16px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ color }) => color};
    border-color: ${({ color }) => color};
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${({ color }) => color};
  }

  margin-bottom: 13px;
`;

const genericButtonStyle = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  color: #21afff;
  border: none;
  background-color: transparent;
  cursor: pointer;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  ${genericButtonStyle};
  text-decoration: underline;
`;

const StyledForgotPasswordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledForgotPasswordButton = styled.button`
  ${genericButtonStyle};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;

  color: ${({ color }) => color};

  margin-bottom: 24px;
`;

const StyledKeepMeLoggedIn = styled.div`
  margin: 5px 0 10px;
`;
