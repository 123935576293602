export const dummyPersonalChatValues = {
  inputMessage: 'hello',
};

// Dummy Chat States
export const dummySelectedChatId = '60beeb8921a81d27e5fa3f69';

export const dummyPersonalChatrooms = [
  {
    id: '60beeb8921a81d27e5fa3f69',
    name: 'Java Learn Conf',
    description: 'Online Event',
    type: 'personal',
    eventId: 580,
    startTime: 1623124740,
    endTime: 1625025726,
    message: {
      _id: '60bf60db21a81d27e5fa3f87',
      sender: {
        _id: '608fd3fa3dc9d314dfc016f3',
        type: 'individual',
        isAnonymous: false,
        id: 437,
        name: 'Candidate_Test',
        description: 'Candidate',
        theme: '#6963aa',
        createdAt: '2021-05-03T10:44:10.654Z',
        updatedAt: '2021-05-03T10:44:10.654Z',
        __v: 0,
      },
      status: 10,
      type: 'user',
      createdAt: '2021-06-08T12:21:47.195Z',
      message: 'yupp',
    },
    users: [
      {
        id: 230,
        name: 'Organizer_Test',
        description: 'Organizer',
        theme: '#47bfa8',
        type: 'company',
        isBanned: false,
        isAnonymous: false,
      },
      {
        id: 437,
        name: 'Candidate_Test',
        description: 'Candidate',
        theme: '#6963aa',
        type: 'individual',
        isBanned: false,
        isAnonymous: false,
      },
    ],
    isUserBanned: false,
    status: 10,
  },
  {
    id: '60ba1723fde9087a9e251654',
    name: 'Tech Event3',
    description: 'Online Event',
    type: 'personal',
    eventId: 572,
    startTime: 1622624088,
    endTime: 1627030256,
    message: {
      _id: '60bf5f2f21a81d27e5fa3f86',
      sender: {
        _id: '608fd3fa3dc9d314dfc016f3',
        type: 'individual',
        isAnonymous: false,
        id: 437,
        name: 'Candidate_Test',
        description: 'Candidate',
        theme: '#6963aa',
        createdAt: '2021-05-03T10:44:10.654Z',
        updatedAt: '2021-05-03T10:44:10.654Z',
        __v: 0,
      },
      status: 10,
      type: 'user',
      createdAt: '2021-06-08T12:14:39.074Z',
      message: 'hm',
    },
    users: [
      {
        id: 437,
        name: 'Candidate_Test',
        description: 'Candidate',
        theme: '#6963aa',
        type: 'individual',
        isBanned: false,
        isAnonymous: false,
      },
      {
        id: 231,
        name: 'Recruiter_Test',
        description: 'Recruiter',
        theme: '#ffbf40',
        type: 'company',
        isBanned: false,
        isAnonymous: false,
      },
    ],
    isUserBanned: false,
    status: 20,
  },
];

export const dummySelectedChatDetails = {
  isBanned: false,
  user: {
    createdAt: '2021-05-04T12:29:37.218Z',
    description: 'Recruiter',
    id: 231,
    isAnonymous: false,
    name: 'Recruiter_Test',
    theme: '#ffbf40',
    type: 'company',
    updatedAt: '2021-05-04T12:29:37.218Z',
    __v: 0,
    _id: '60913e313dc9d314dfc01709',
  },
  userId: 231,
  userType: 'company',
  _id: '60ba1723fde9087a9e251655',
};

export const dummySelectedChatMessages = [
  {
    chatRoom: '60c2104893f578794ee19cbc',
    createdAt: '2021-06-10T13:14:48.031Z',
    message: 'Welcome to the event analytics test event',
    sender: '60a24575c9fb0930d22d61d5',
    senderCompanyId: 43,
    senderId: 43,
    status: 10,
    type: 'automated',
    updatedAt: '2021-06-10T13:14:48.031Z',
    __v: 0,
    _id: '60c2104893f578794ee19cbf',
  },
  {
    chatRoom: '60c2104893f578794ee19cbc',
    createdAt: '2021-06-11T11:48:00.244Z',
    message: 'Thanks',
    sender: '608fd3fa3dc9d314dfc016f3',
    senderCompanyId: null,
    senderId: 437,
    status: 10,
    type: 'user',
    updatedAt: '2021-06-11T11:48:00.244Z',
    __v: 0,
    _id: '60c34d70940a5a02460f9c5e',
  },
];

export const dummyUserProfilePersonalChats = {
  companys: [
    { id: 230, name: 'Zomato', profilePic: null, roleId: 2 },
    {
      id: 231,
      name: 'Flipkart',
      profilePic: null,
      roleId: 3,
    },
  ],
  users: [
    {
      email: 'c_test@mail.net',
      id: 437,
      name: 'Candidate_Test',
      profilePic: null,
      roleId: 1,
    },
  ],
};
