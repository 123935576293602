import React from 'react';
import MapAllLinks from './index';
import { RecruiterRoutes } from '../../../constants/routes';

const RecruitBreadCrumbs = ({ name = '' }) => {
  const links = [
    {
      name: 'Recruit',
      url: `${RecruiterRoutes.recruit}1`,
    },
    {
      name: 'Job opportunities',
      url: `${RecruiterRoutes.recruit}2`,
    },
    {
      name,
      url: `${RecruiterRoutes.recruiter}jobs`,
    },
  ];
  return (
    <>
      <MapAllLinks links={links} />
    </>
  );
};

export default RecruitBreadCrumbs;
