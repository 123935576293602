import { Table, Tooltip } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from '../../../components/shared-components/Modal';
import { CircleNotchIcon } from '../../../components/svgicons';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../../constants';
import { getJobsWithNoShortlistedApplicationsData } from '../../../store/features/analytics';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { getSerialNumber, serialNoColumn } from '../../../utils/common';

export const JobsShortlistApplications = () => {
  const { jobsWithNoShortlistedApplications } = useSelector(
    (state: any) => state.analytics.masterAdminDashboardAnalytics,
  );
  const { data } = jobsWithNoShortlistedApplications || {};
  const [dataSource, setDataSource] = useState([]);
  const [modalDataSource, setModalDataSource] = useState([]);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);

  useEffect(() => {
    dispatch(getJobsWithNoShortlistedApplicationsData(current, pageSize));
  }, [current, pageSize, dispatch]);

  useEffect(() => {
    const renderData = () => {
      if (current === 1) {
        setDataSource(
          data?.jobs?.map((item: any, rowId: any) => ({
            serialNo: getSerialNumber(current, pageSize, rowId),
            company: get(item, 'company.name', '-'),
            job: get(item, 'designation', '-'),
            applications: get(item, 'jobApplications', '-'),
            positions: get(item, 'positions', '-'),
          })),
        );
      }
      setModalDataSource(
        data?.jobs?.map((item: any, rowId: any) => ({
          serialNo: getSerialNumber(current, pageSize, rowId),
          company: get(item, 'company.name', '-'),
          job: get(item, 'designation', '-'),
          applications: get(item, 'jobApplications', '-'),
          positions: get(item, 'positions', '-'),
        })),
      );
    };

    renderData();
  }, [data, current, pageSize]);

  const columns = [
    { ...serialNoColumn },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Job',
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: 'Applications',
      dataIndex: 'applications',
      key: 'applications',
    },
    {
      title: 'Positions',
      dataIndex: 'positions',
      key: 'positions',
    },
  ];

  const handleModalClose = () => {
    setVisible(false);
  };

  return (
    <>
      <StyledSection onClick={() => setVisible(true)}>
        <div>
          <CircleNotchIcon />{' '}
          <h1>Jobs with no shortlisted applications - {data.totalItems}</h1>
          <Tooltip title="These are the jobs which have job applications but none of them are shortlisted.">
            <StyledEyeInvisibleOutlined />
          </Tooltip>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource?.slice(0, 3)}
          pagination={false}
          scroll={{ x: true }}
        />
      </StyledSection>

      <Modal
        title={`Jobs with no shortlisted applications - ${data.totalItems}`}
        width="80%"
        visible={visible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={modalDataSource}
          pagination={{ pageSize, total: data.totalItems, current }}
          onChange={(e: any) => {
            setCurrent(e.current);
            setPageSize(e.pageSize);
          }}
          scroll={{ x: true }}
        />
      </Modal>
    </>
  );
};

const StyledSection = styled.div`
  cursor: pointer;
  h1 {
    margin-bottom: 20px;
    color: ${COLORS.RED_4};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  & > div:first-child {
    display: flex;
    gap: 7px;
  }

  svg {
    margin-top: 5px;
  }
`;

const StyledEyeInvisibleOutlined = styled(EyeInvisibleOutlined)`
  margin-top: -2.7px;
`;
