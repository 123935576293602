import React from 'react';
import { DatePicker as Picker } from 'antd';
import moment from 'moment';
import { isDesktop } from '../../../helpers/display';
import './DatePicker.scss';

const { RangePicker } = Picker;

const DatePicker: React.FC<any> = ({
  handleDateChange,
  selectedDateRange,
  range = null,
  id = 'select_date',
  disabledDate = null,
  getPopupContainer
}) => {
  return (
    <RangePicker
      ranges={
        range || {
          'Last 3 Days': [
            moment().subtract(2, 'd').startOf('day'),
            moment().endOf('day'),
          ],
          'Last 7 Days': [
            moment().subtract(6, 'd').startOf('day'),
            moment().endOf('day'),
          ],
          'Last 30 Days': [
            moment().subtract(29, 'd').startOf('day'),
            moment().endOf('day'),
          ],
        }
      }
      name="dateTime"
      format="DD/MM/YYYY"
      id={id}
      onChange={handleDateChange}
      value={selectedDateRange}
      size={isDesktop() ? 'large' : 'middle'}
      dropdownClassName="date-picker-custom"
      disabledDate={disabledDate}
      getPopupContainer={getPopupContainer}
    />
  );
};

export default DatePicker;
