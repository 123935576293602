import React from 'react';
import Calendar from '../../components/Calendar/Calendar';
import { PageContainer } from '../../components/shared-components/PageContainer';
import { getUserColorWithRoleId } from '../../styles/utils';
import { ORGANIZER } from '../../constants';
import PageCircle from '../../components/shared-components/PageCircle';
import { StyledCalendarHeader } from '../../components/shared-components/common';
import { useSearchDebounce } from '../../components/Common/CustomHooks';

const OrganizerCalendar = () => {
  const [value, setValue] = useSearchDebounce();
  const handleChange = (e) => {
    setValue(e.target.value.toLowerCase());
  };
  return (
    <div>
      {/* events home page */}

      {/* circle */}
      <PageCircle color={getUserColorWithRoleId(ORGANIZER)} />

      {/* title search section */}
      <StyledCalendarHeader roleId={ORGANIZER} handleChange={handleChange} />

      <PageContainer>
        <Calendar searchText={value} />
      </PageContainer>
    </div>
  );
};

export default OrganizerCalendar;
