import { useState, ChangeEvent, useCallback, useEffect } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { Coupon } from '../interfaces/components/PricingPlan';
import get from 'lodash/get';
import { CARD_CHANGE } from '../constants';
import { validateCoupon } from '../service/recruiter';

// Custom hook for fetching coupon status and helper functions
export const useCoupon = () => {
  const currentCoupon: Coupon = useSelector((state) =>
    get(state, 'cryptoIncentivePlan.cryptoIncentivePlan.coupon', null),
  );
  const [coupon, setCoupon] = useState<Coupon | null>();
  const [couponText, setCouponText] = useState<string>('');
  const [showCouponInput, setShowCouponInput] = useState(true);
  const [isCouponValidating, setIsCouponValidating] = useState<boolean>(false);

  useEffect(() => {
    const handleEvent = () => {
      setCoupon(null);
      setCouponText('');
      setShowCouponInput(true);
    };
    document.addEventListener(CARD_CHANGE, handleEvent);
    return () => {
      document.removeEventListener(CARD_CHANGE, handleEvent);
    };
  }, []);
  useEffect(() => {
    if (!!currentCoupon) {
      setShowCouponInput(false);
      setCoupon(currentCoupon);
      setCouponText(currentCoupon?.code);
    }
  }, [currentCoupon]);

  const clearInput = useCallback(() => {
    setCouponText('');
  }, []);
  const onCouponChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCouponText(e.target.value);
  }, []);

  const showInput = useCallback(() => {
    setShowCouponInput(true);
  }, []);

  const closeInput = useCallback(() => {
    setShowCouponInput(false);
  }, []);

  const onValidateCoupon = useCallback(
    async (cryptoIncentivePlanId: number | string) => {
      if (!couponText) return message.error('No coupon code entered!');
      if (!cryptoIncentivePlanId) return message.info('Select a plan first');
      try {
        setIsCouponValidating(true);
        const response = await validateCoupon(
          cryptoIncentivePlanId,
          couponText,
        );
        setIsCouponValidating(false);
        if (response.isCouponValid) {
          closeInput();
          setCoupon(response?.coupon as Coupon);
          return message.success('Coupon applied successfully');
        } else {
          return message.error('Invalid coupon!');
        }
      } catch (error) {
        showInput();
        setIsCouponValidating(false);
        return message.error('Unexpected error happened, try again');
      }
    },
    [couponText, showInput, closeInput],
  );
  return {
    coupon,
    couponText,
    isCouponValidating,
    isInputShown: showCouponInput,
    showInput,
    closeInput,
    clearInput,
    onCouponChange,
    onValidateCoupon,
  };
};
