import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { Switch, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { dateFormatter } from '../../utils/jobDetails';
import { SecondaryButton } from '../../components/shared-components/Button';
import { transformSkills } from '../../utils/event';
import {
  APPLICATION_EXTENSION_TOOLTIP,
  getApplicationStatusText,
  getApplicationTextColorByStatus,
} from '../../constants';
import { convertUnixTime, currentDate } from '../../utils/formatDateTime';
import featherClock from '../../assets/svg/feather-clock.svg';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { addThreeDaysInUnixTime } from '../../utils/common';

export const applicationsAnalyticsData = [
  {
    data: '162',
  },
  {
    data: '0',
  },
  {
    data: '23',
  },
  {
    data: '39',
  },
  {
    data: '49',
  },
];

const className = 'applied-candidates__columns';

const appliedCandidateColumns = {
  name: {
    title: 'Candidate Name',
    dataIndex: 'name',
    className,
    sorter: true,
    width: '12%',
  },
  events: {
    title: 'Events',
    dataIndex: 'event',
    className,
    sorter: true,
    width: '10%',
  },
  skillMatch: {
    title: 'Skill Match',
    dataIndex: 'skillMatch',
    className,
    sorter: true,
    width: '10%',
  },
  job: {
    title: 'Job',
    dataIndex: 'job',
    className,
    sorter: true,
    width: '10%',
  },
  status: {
    title: 'Status',
    dataIndex: 'status',
    className,
    width: '10%',
  },
  deadline: {
    title: 'Deadline',
    dataIndex: 'eta',
    className,
    sorter: true,
    width: '12%',
  },
  action: {
    title: 'Action',
    dataIndex: 'action',
    className,
    width: '13%',
  },
};

export const getAppliedCandidateColumns = (columnKeys, width = null) => {
  return columnKeys.map((columnKey) =>
    width
      ? { ...appliedCandidateColumns[columnKey], width }
      : appliedCandidateColumns[columnKey],
  );
};

const initialValue = '-';

export const getCandidateTableData = (
  jobApplicationsData,
  handleShow,
  setResumeInfo,
  isTabOrSmallScreen,
  handleRequestExtension,
) => {
  const candidateTableData = [];
  let index = 0;
  if (jobApplicationsData && isArray(jobApplicationsData)) {
    jobApplicationsData.forEach((data) => {
      const getCandidateMatchPercentage = get(data, 'matchPercentage', 0);
      const getCandidateName = get(data, 'candidate.name', initialValue);
      const getEventName = get(data, 'event.name', initialValue);
      const getJobDesignation = get(data, 'job.designation', '');
      const applicationStatus = get(data, 'applicationStatus', '');
      const deadline = get(
        data,
        'event.eventCompanys[0].evaluationDeadlineDateTime',
        initialValue,
      );

      const communityEventEta = addThreeDaysInUnixTime(data);

      const res = convertUnixTime(
        deadline !== null ? deadline : communityEventEta,
      )?.diff(currentDate());

      let hours = Math.round(moment?.duration(res)?.asHours());
      const min = Math.round(moment?.duration(res)?.asMinutes());
      const days = Math.round(moment?.duration(res)?.asDays());

      if (hours < 0) {
        hours = 0;
      }

      let time = hours;

      if (hours > 72) {
        time = `${days} days`;
      } else if (hours === 0 && min === 0) {
        time = 'Over';
      } else if (hours > 0) {
        time = `${hours} ${hours === 1 ? 'Hr' : 'Hrs'}`;
      } else if (min > 0) {
        time = `${min} ${min === 1 ? 'Min' : 'Mins'}`;
      } else {
        time = 'Over';
      }

      candidateTableData.push({
        ...data,
        key: index,
        name: getCandidateName,
        event: <StyledText>{getEventName}</StyledText>,
        skillMatch: getCandidateMatchPercentage
          ? `${Math.round(data.matchPercentage)}%`
          : '-',
        job: <StyledText>{getJobDesignation}</StyledText>,
        status: (
          <StyledStatusText
            color={getApplicationTextColorByStatus(applicationStatus)}
          >
            {getApplicationStatusText(applicationStatus)}
          </StyledStatusText>
        ),
        eta: (
          <StyledETAContainer>
            <StyledETA>{time}</StyledETA>
            {min > 0 && hours < 25 ? (
              <Tooltip title={APPLICATION_EXTENSION_TOOLTIP}>
                <StyledDeadlineIcon
                  onClick={(e) =>
                    handleRequestExtension(get(data, 'event', null), e)
                  }
                >
                  <img src={featherClock} alt="feather clock" />
                </StyledDeadlineIcon>
              </Tooltip>
            ) : null}
          </StyledETAContainer>
        ),
        action: isTabOrSmallScreen ? (
          <Link
            to={{
              pathname: `/recruiter/resume/${data.jobId}/${data.id}/${data.eventId}`,
            }}
          >
            <SecondaryButton theme="recruiter" id="recruit_view_resume_btn">
              View Resume
            </SecondaryButton>
          </Link>
        ) : (
          <SecondaryButton
            onClick={() => {
              if (handleShow && setResumeInfo) {
                handleShow();
                setResumeInfo({
                  jobId: data?.jobId,
                  applicationId: data?.id,
                  eventId: data?.eventId,
                });
              }
            }}
            theme="recruiter"
            id="recruit_view_resume_btn"
          >
            View Resume
          </SecondaryButton>
        ),
      });
      index += 1;
    });
  }
  return candidateTableData;
};

export const jobColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
    sorter: true,
  },
  {
    title: 'Engagement',
    dataIndex: 'engagement',
  },
  {
    title: 'Job skills',
    dataIndex: 'jobSkills',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];

const getEngagementText = (engagements) => {
  if (!engagements) return '';
  return engagements.join(', ').trim();
};

export const getJobTableData = (jobsData, setJobStatus, setJobId) => {
  const handleStatusChange = (i) => {
    const { id } = jobsData[i];
    setJobId(id);

    if (jobsData[i].activeStatus === 10) {
      setJobStatus(false);
    } else {
      setJobStatus(true);
    }
  };

  const jobTableData = [];

  jobsData.forEach((data, i) => {
    jobTableData.push({
      key: data.id,
      date: dateFormatter(jobsData[i].createdAt),
      jobTitle: jobsData[i].designation,
      positions: data.positions,
      engagement: getEngagementText(get(data, 'meta.typesOfEngagement', [])),
      jobSkills: transformSkills(data.jobSkills),
      status: (
        <Switch
          className="recruit-switch-btn"
          checked={jobsData[i].activeStatus === 10}
          onChange={() => handleStatusChange(i)}
        />
      ),
    });
  });

  return jobTableData;
};

const StyledStatusText = styled.span`
  font-size: 12px;
  font-weight: 600;
  opacity: 70%;
  color: ${({ color }) => color};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 14px;
  }
`;

const StyledText = styled.span`
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }
`;

const StyledDeadlineIcon = styled.span`
  cursor: pointer;
  img {
    margin-bottom: 11px;
    margin-left: 4px;
    width: 14px;
  }
`;

const StyledETA = styled.span`
  font-size: inherit;
`;

const StyledETAContainer = styled.div`
  font-size: inherit;
`;
