import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getTimeZones } from '../store/features/commons';
import { UserInformation } from '../utils/userInfo';

const ProtectedRoute = ({ component, path }) => {
  const { isAuthenticated } = UserInformation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTimeZones());
  }, [dispatch]);

  return isAuthenticated ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};

export default ProtectedRoute;
