import React from 'react';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../../utils/userInfo';
import OrganizerEventAnalytics from './OrganizerEventAnalytics';
//import RecruiterEventAnalytics from './RecruiterEventAnalytics';
import CandidateEventAnalytics from './CandidateEventAnalytics';
import { OngoingEventAnalyticsStore } from '../../../utils/analytics';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { COLORS } from '../../../styles/constants/colors';
import { MARGIN } from '../../../styles/constants/display';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { smallFontMixin } from '../../../styles/constants/mixins';

const EventAnalytics = ({ roleId, index, dashboard }) => {
  const { analytics } = OngoingEventAnalyticsStore();

  if (!isArray(analytics) || analytics.length === 0) {
    return null;
  }

  const UserAnalytics = () => {
    if (checkIfUserIsOrganizer(roleId)) {
      return <OrganizerEventAnalytics analytics={analytics[index]} />;
    }

    if (checkIfUserIsRecruiter(roleId)) {
      //return <RecruiterEventAnalytics analytics={analytics[index]} />;
      return null;
    }

    return <CandidateEventAnalytics analytics={analytics[index]} />;
  };

  return (
    <StyledContainer
      bColor={getUserColorWithRoleId(roleId)}
      isCandidate={checkIfUserIsCandidate(roleId)}
    >
      {dashboard ? null : <StyledText>Quick Information</StyledText>}
      <UserAnalytics />
    </StyledContainer>
  );
};

export default EventAnalytics;

const StyledContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 90%;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    position: relative;
    width: 100%;
  }

  ul {
    display: flex;
    ${({ isCandidate }) =>
      !isCandidate ? 'justify-content: space-between;' : ''};
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin-bottom: 10px;

    li {
      margin-right: ${({ isCandidate }) => (isCandidate ? '25%' : '5px')};
    }

    p {
      font-size: ${FONT[14]};
      font-weight: ${FONT_WEIGHTS.MEDIUM};
      color: ${COLORS.DAVYS_GREY};
      line-height: 25px;

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: ${FONT[16]};
      }

      @media (min-width: 1200px) and (max-width: 1350px) {
        ${smallFontMixin};
      }
    }

    b {
      font-size: ${FONT[14]};
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      color: ${(props) => props.bColor};
      line-height: 26px;

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: ${FONT[16]};
      }
    }
  }
`;

const StyledText = styled.p`
  ${MARGIN(-45, 0, 5, 0)};
  font-size: ${FONT[12]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.DARK_SILVER};

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-top: 30px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[14]};
  }
`;
