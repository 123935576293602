/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Skeleton, Tooltip, Button, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import last from 'lodash/last';
import isArray from 'lodash/isArray';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import { createJobDetailsData } from '../../../../store/features/jobDetails';
import CreateSkillsSetRows from './CreateSkillSetRows';
import AddressAutoComplete from '../../../../components/Common/AddressAutoComplete';
import {
  JobDetailsStore,
  splitLocationToAddressFields,
  getAnySkillData,
  makeCreateJobData,
  handleCreateJobNotification,
} from '../../../../utils/jobDetails';
import { isTestModeOn } from '../../../../utils/test-helper';
import { getTextFromHTML } from '../../../../utils/common';
import { HiddenMobile } from '../../../../components/shared-components/DocumentViewerModal';
import RecruitBreadCrumbs from '../../../../components/Common/BreadCrumbs/RecruitBreadCrumbs';
import PageCircle from '../../../../components/shared-components/PageCircle';
import { COLORS } from '../../../../styles/constants/colors';
import { RecruiterRoutes } from '../../../../constants/routes';
import { getAutoFillDataForJob } from '../../../../service/recruiter';
import {
  getCategoriesForDomain,
  getRecommendedSkills,
} from '../../../../service';
import {
  getCurrencyDataForDropdown,
  getCurrencyById,
} from '../../../../utils/currencyConvertor';
import UpperSection from './UpperSection';
import BelowSection from './BelowSection';
import Textarea from './Textarea';
import Notification from '../../../../components/Notification/Notification';
import { userProfileStore } from '../../../../utils/profile';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/shared-components/Button';
import { FormGroup } from '../../../../components/shared-components/Form/Form';
import {
  ANY_SKILL_ID,
  DELETE,
  SKILL_SELECT_TEXT,
  STATUS_ACTIVE,
  ONSITE_TEXT,
  FULL_TIME_TEXT,
  PART_TIME_TEXT,
  STATUS_DELETE,
  RECRUITER_COLOR,
  MASTER_ADMIN_COLOR,
} from '../../../../constants';
import { useTagManager } from '../../../../utils/customHooks';
import JobModal from './JobModal';
import BriefcaseIcon from '../../../../assets/icons-and-sets/briefcase.svg';
import {
  StyledMainContainer,
  StyledMainFormContainer,
  StyledHeading,
  StyledBreadcrumbWrapper,
  StyledSkillSetWrapper,
  StyledCreateSkillSetWrapper,
  StyledDescriptionTextWrapper,
  StyledModal,
  StyledSubmitContainer,
  StyledAnySkillButtonWrapper,
  StyledAddSkillButtonWrapper,
  StyledSkillSetHeading,
  StyledTooltipWrapper,
  StyledTopTitle,
  StyledAnySkillButton,
} from './Common';
import { checkIfUserIsRecruiter } from '../../../../utils/userInfo';
import { getRoleId } from '../../../../utils/userInfo';
import { InputField } from '../../../../components/shared-components/Form/Form';

export default function CreateJob({
  dummySkillListOptions,
  dummyIndustryTypes,
  dummyCurrencyType,
  dummyContribution,
}) {
  const [skills, setSkills] = useState([]);
  const [skillStep, setSkillStep] = useState([]);
  const [requestSkill, setRequestSkill] = useState([]);
  const [contributedList, setContributedList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [form] = Form.useForm();
  const [areJobSkillsUpdated, setAreJobSkillsUpdated] = useState(false);
  const [jobTitle, setJobTitle] = useState('Designer');
  const [contributeOption, setContributeOption] = useState([]);
  const [mandatoryList, setMandatoryList] = useState([]);
  const [isMandatory, setIsMandatory] = useState(STATUS_DELETE);
  const [domains, setDomains] = useState([]);

  const [categories, setCategories] = useState([]);
  const [isAnySkillClicked, setIsAnySkillClicked] = useState(false);
  const [dependsOnExperience, setDependsOnExperience] = useState(true);
  const [jobBriefText, setJobBriefText] = useState('');
  const [fiveReasonsToJoinText, setFiveReasonsToJoinText] = useState('');
  const [interviewProcess, setInterviewProcess] = useState('');
  const [first90Days, setFirst90Days] = useState('');
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, error } = JobDetailsStore();
  const [createJobAutoFill, setCreateJobAutofill] = useState();
  const { currencyData } = useSelector((st) => st.commons);
  const currencyDataForDropdown = getCurrencyDataForDropdown(currencyData);
  const [jobBriefTextCount, setJobBriefTextCount] = useState(0);
  const [recommendedSkills, setRecommendedSkills] = useState([]);
  const [interviewProcessCount, setInterviewProcessCount] = useState(0);
  const [first90DaysCount, setFirst90DaysCount] = useState(0);
  const [fiveReasonsTextCount, setFiveReasonsTextCount] = useState(0);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const updateUserActionInGTM = useTagManager();
  const { profileData } = userProfileStore();
  const company = get(profileData, 'company', null);

  const roleId = getRoleId();
  const isRecruiter = checkIfUserIsRecruiter(roleId);

  useEffect(() => {
    (async () => {
      const response = await getAutoFillDataForJob();
      const {
        address,
        zipcode,
        city,
        state,
        country,
      } = splitLocationToAddressFields(response.location);
      setCreateJobAutofill({
        ...response,
        address,
        zipcode,
        city,
        state,
        country,
      });
    })();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [createJobAutoFill, form]);

  const getJobDescInitialValue = () => {
    const typesOfEngagementList = get(
      createJobAutoFill,
      'typesOfEngagement',
      null,
    );
    const jobType = get(createJobAutoFill, 'jobType', null);
    let initialValues = {
      salaryDependsOnExprience: true,
    };
    if (isArray(typesOfEngagementList) && typesOfEngagementList.length) {
      initialValues = {
        ...initialValues,
        typesOfEngagement: typesOfEngagementList[0],
      };
    }
    if (isArray(jobType) && jobType.length) {
      initialValues = {
        ...initialValues,
        jobType: jobType.map((type) => {
          if (parseInt(type) === 1) return FULL_TIME_TEXT;
          return PART_TIME_TEXT;
        }),
      };
    }
    return initialValues;
  };

  const getJobAdditionalInitialValue = () => {
    let country = get(createJobAutoFill, 'country', null);
    let zipcode = get(createJobAutoFill, 'zipcode', null);
    const city = get(createJobAutoFill, 'city', null);
    if (!zipcode) {
      zipcode = get(company, 'zip', '');
    }
    if (!country) {
      country = get(company, 'country', '');
    }
    const minQualification = get(createJobAutoFill, 'minQualification', null);
    let initialValue = {
      jobLocationType: ONSITE_TEXT,
    };
    if (country) {
      initialValue = { ...initialValue, country };
    }
    if (zipcode) {
      initialValue = { ...initialValue, zipcode };
    }
    if (city) {
      initialValue = { ...initialValue, city };
    }
    if (minQualification) {
      initialValue = { ...initialValue, minQualification };
    }
    return initialValue;
  };

  const handleSelectDomains = (options) => {
    const newSelection = first(
      options.filter((name) => !domains.find((item) => item.name === name)),
    );
    if (newSelection) {
      getCategoriesForDomain(newSelection).then((categoriesList) => {
        const newCategories = categoriesList
          .map((item) => item.id)
          .filter((category) => !categories.find((id) => id === category.id));
        let temp = [...categories, ...newCategories];
        temp = uniq(temp);
        form.setFieldsValue({ categories: temp });
        setCategories(temp);
      });
    }
    setDomains([]);
    options.forEach((name) => {
      setDomains((oldArr) => [
        ...oldArr,
        {
          name,
        },
      ]);
    });
  };

  const handleSelectCategories = (options) => {
    setCategories(options);
  };

  const ANY_SKILL_TEXT = (
    <span className="any-skill__tooltip">
      By clicking this button , candidates <br />
      with Any Skills can apply for this job
    </span>
  );

  const REMOVE_ANY_SKILL_TEXT = (
    <span className="any-skill__tooltip">
      By clicking, Any Skills will be deactivated.
    </span>
  );

  const handleChange = useCallback((value, setCount, setValue, length = 0) => {
    const text = getTextFromHTML(value);
    const finalLength = length || text.length;
    setTimeout(() => setCount(finalLength));
    if (finalLength <= 1000) {
      setValue(value);
    } else {
      setValue(text.slice(0, 1000));
    }
  }, []);

  const handleChangeForJobDescription = useCallback(
    (value, setCount, setValue, length = 0) => {
      const text = getTextFromHTML(value);
      form.setFieldsValue({
        jobDescription: text,
      });
      const finalLength = length || text.length;
      setTimeout(() => setCount(finalLength));
      if (finalLength <= 1000) {
        setValue(value);
      } else {
        setValue(text.slice(0, 1000));
      }
    },
    [form],
  );

  const handleAnySkillsSetOnClick = () => {
    if (!isAnySkillClicked) {
      setIsAnySkillClicked(true);
      return Notification(
        'success',
        'Success',
        `Any Skills has been selected.`,
      );
    }
    setIsAnySkillClicked(false);
    let filteredList = skills.filter((skill) => {
      const skillId = get(skill, 'skillId', null);

      if (skillId !== ANY_SKILL_ID) {
        return true;
      }
      return false;
    });

    setSkills(filteredList);
    return Notification('success', 'Success', `Any Skills Removed.`);
  };

  const getSkillIds = async (skillsArr) => {
    let ids = [];
    setRecommendedSkills([]);
    for (let i = 0; i < skillsArr.length; i++) {
      if (!Object.values(skillsArr[i]).includes('delete')) {
        ids.push(skillsArr[i].skill.skillId);
      }
    }

    if (ids.length) {
      const payload = await getRecommendedSkills(ids);
      setRecommendedSkills(payload);
    }
  };

  const handleSkillsSetOnClick = () => {
    const lastSkill = last(skills);
    const lastSkillText = get(lastSkill, 'skill.skillName', null);
    if (lastSkill && !lastSkillText) {
      return;
    }
    getSkillIds(skills);
    setSkills([...skills, {}]);
    setSkillStep([...skillStep, {}]);
    setRequestSkill([...requestSkill, {}]);
    setSkillsList([...skillsList, { options: [] }]);
    setContributeOption([...contributeOption, 0]);
    setMandatoryList([...mandatoryList, STATUS_DELETE]);
    setAreJobSkillsUpdated(true);
  };

  const getAllInitialFields = () => {
    const additionalInitialFields = getJobAdditionalInitialValue();
    const jobDescInitialFields = getJobDescInitialValue();
    return { ...additionalInitialFields, ...jobDescInitialFields };
  };

  const handleSubmit = ({
    jobTitle,
    jobType,
    currencyId = null,
    salaryTo = 0,
    salaryFrom = 0,
    typesOfEngagement: typesOfEngagementData,
    years,
    months,
    minQualification,
    address,
    zipcode,
    city,
    state,
    country,
    jobLocationType,
  }) => {
    /**
     * 1. Basic info submit first
     */
    setIsSubmitClicked(true);
    let basicInfoObject;
    let IsSkillEmpty = false;
    if (isAnySkillClicked) {
      const anySkill = getAnySkillData();
      basicInfoObject = {
        designation: jobTitle,
        skills: [
          ...skills.map((data) => {
            const { skillId, skillName } = data.skill;
            const { id, level, skillPreference, operation } = data;
            return {
              id,
              level,
              skillPreference,
              skillId,
              skillName,
              operation,
            };
          }),
          { ...anySkill, operation: 'add' },
        ],
      };
      setSkills(basicInfoObject.skills);
      // setJobDetails(basicInfoObject)
      setSkills(skills);
    } else {
      basicInfoObject = {
        designation: jobTitle,
        skills: skills
          .filter((skill) => !!skill.skill)
          .map((data) => {
            const { skillId, skillName } = data.skill;
            const { id, level, skillPreference, operation } = data;
            return {
              id,
              level,
              skillPreference,
              skillId,
              skillName,
              operation,
            };
          }),
      };
      setSkills(skills);
    }

    let mandatorySkillsCount = 0;
    let repeatingSkillsCount = 0;
    const skillsArray = [];
    if (!isAnySkillClicked) {
      skills.forEach((skill) => {
        const name = get(skill, 'skill.skillName', get(skill, 'skill.skill'));
        if (skill.operation !== DELETE) {
          if (skillsArray.includes(toLower(name))) {
            repeatingSkillsCount += 1;
          } else {
            skillsArray.push(toLower(name));
          }
        }
        if (
          skill.skillPreference === STATUS_ACTIVE &&
          skill.operation !== DELETE
        ) {
          mandatorySkillsCount += 1;
        }
        if (!isAnySkillClicked) {
          if (isEmpty(skill) || (skill && !skill.skill)) {
            IsSkillEmpty = true;
          }
        } else if (
          (isEmpty(skill) || (skill && !skill.skill.skillName)) &&
          !isAnySkillClicked
        ) {
          IsSkillEmpty = true;
        }
      });
    }

    if (IsSkillEmpty || (skills.length === 0 && !isAnySkillClicked)) {
      // if (isSubmitClicked) setIsSubmitClicked(false);
      setIsSubmitClicked(false);
      return Notification('error', 'Error', `Skill can not be Empty.`);
    }
    if (mandatorySkillsCount === 0 && !isAnySkillClicked) {
      setIsSubmitClicked(false);
      return Notification(
        'error',
        'Error',
        `Please add atleast one mandatory skill.`,
      );
    }
    if (repeatingSkillsCount > 0) {
      setIsSubmitClicked(false);
      return Notification('error', 'Error', `please delete repeating skills.`);
    }

    /** Job Description submit handle */

    const typesOfEngagement = [
      isArray(typesOfEngagementData)
        ? first(typesOfEngagementData)
        : typesOfEngagementData,
    ];
    let jobDescBody = {};
    jobDescBody = {
      ...basicInfoObject,
      domains,
      jobType: jobType.map((jobType) => {
        if (jobType === 'Full-time') return 1;
        return 0;
      }),
      salaryCurrencyId: currencyId,
      currency: currencyId
        ? get(getCurrencyById(currencyData, currencyId), 'value', null)
        : null,
      salaryFrom: salaryFrom || 0,
      salaryTo: salaryTo || 0,
      meta: {
        typesOfEngagement,
        ...(fiveReasonsToJoinText
          ? { fiveReasonsToJoin: fiveReasonsToJoinText }
          : {}),
        ...(interviewProcess ? { interviewProcess } : {}),
        ...(first90Days ? { first90Days } : {}),
      },
      jobBrief: jobBriefText,
      salaryDependsOnExprience: dependsOnExperience,
    };

    /** Additional Details submit */
    const postBodyObj = makeCreateJobData(
      jobDescBody,
      years,
      months,
      minQualification,
      address,
      zipcode,
      city,
      state,
      country,
      jobLocationType,
      categories,
    );
    dispatch(createJobDetailsData(postBodyObj, handleCreateJobNotification));
    if (areJobSkillsUpdated) {
      updateUserActionInGTM('new_job_skills_added');
    }
    updateUserActionInGTM('job_created');
    history.push(`${RecruiterRoutes.recruit}2`);
  };

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <StyledMainContainer>
      <PageCircle color={COLORS.SUPER_NOVA} />
      <Col>
        <StyledBreadcrumbWrapper>
          <RecruitBreadCrumbs name="Create Job" />
        </StyledBreadcrumbWrapper>
      </Col>

      <FormGroup
        form={form}
        onFinish={handleSubmit}
        initialValues={getAllInitialFields()}
      >
        <StyledMainFormContainer>
          <StyledTopTitle>
            <img src={BriefcaseIcon} alt="icon" />
            <div>
              <StyledHeading>Create Job Form</StyledHeading>
            </div>
          </StyledTopTitle>
          <UpperSection
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            dummyIndustryTypes={dummyIndustryTypes}
            handleSelectDomains={handleSelectDomains}
            categories={categories}
            handleSelectCategories={handleSelectCategories}
            setDependsOnExperience={setDependsOnExperience}
            dependsOnExperience={dependsOnExperience}
            currencyDataForDropdown={currencyDataForDropdown}
            dummyCurrencyType={dummyCurrencyType}
          />
          <Row>
            <InputField
              name="jobDescription"
              rules={[{ required: true }]}
              maxLength={1000}
              hidden
            >
              <Input hidden />
            </InputField>
            <Textarea
              xs={24}
              title="Job Description"
              name="jobBrief"
              isMandatory
              value={jobBriefText}
              placeholder="Description"
              handleChange={handleChangeForJobDescription}
              textCount={jobBriefTextCount}
              setText={setJobBriefText}
              setTextCount={setJobBriefTextCount}
            />
          </Row>
          <StyledSkillSetWrapper>
            <StyledSkillSetHeading>
              Required Skill<HiddenMobile> Set</HiddenMobile>s
              <MandatoryStar />
            </StyledSkillSetHeading>
            <StyledAnySkillButtonWrapper
              isClicked={isAnySkillClicked}
              colorString={isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR}
            >
              <StyledAnySkillButton
                type="line"
                onClick={handleAnySkillsSetOnClick}
              >
                <StyledTooltipWrapper>
                  <Tooltip
                    title={
                      isAnySkillClicked ? REMOVE_ANY_SKILL_TEXT : ANY_SKILL_TEXT
                    }
                    getPopupContainer={(triggerNode) => triggerNode}
                  >
                    <span>Any Skills</span>
                  </Tooltip>
                </StyledTooltipWrapper>
              </StyledAnySkillButton>
            </StyledAnySkillButtonWrapper>
          </StyledSkillSetWrapper>
          {!isAnySkillClicked ? (
            <>
              <StyledCreateSkillSetWrapper>
                <CreateSkillsSetRows
                  skills={skills}
                  setSkills={setSkills}
                  skillStep={skillStep}
                  setSkillStep={setSkillStep}
                  requestSkill={requestSkill}
                  setRequestSkill={setRequestSkill}
                  skillsList={skillsList}
                  setSkillsList={setSkillsList}
                  contributeOption={contributeOption}
                  setContributeOption={setContributeOption}
                  isMandatory={isMandatory}
                  setIsMandatory={setIsMandatory}
                  contributedList={contributedList}
                  setContributedList={setContributedList}
                  isAnySkillClicked={isAnySkillClicked}
                  mandatoryList={mandatoryList}
                  setMandatoryList={setMandatoryList}
                  dummySkillListOptions={dummySkillListOptions}
                  dummyContribution={dummyContribution}
                  inputSize={7}
                  isActionEditJob={false}
                  setAreJobSkillsUpdated={setAreJobSkillsUpdated}
                  recommendedSkills={recommendedSkills}
                />
              </StyledCreateSkillSetWrapper>
              <Row align="middle">
                <StyledAddSkillButtonWrapper
                  xs={18}
                  sm={10}
                  md={7}
                  lg={7}
                  xxl={5}
                >
                  <Button type="dashed" onClick={handleSkillsSetOnClick}>
                    + Add Skills
                  </Button>
                </StyledAddSkillButtonWrapper>
                <StyledDescriptionTextWrapper md={10}>
                  <Tooltip
                    placement="bottomRight"
                    color={COLORS.recruiterColor}
                    title={SKILL_SELECT_TEXT}
                    overlayInnerStyle={{
                      color: 'white',
                      backgroundColor: '#ffbf40',
                    }}
                  >
                    <InfoCircleOutlined
                      style={{
                        color: COLORS.WHITE,
                        backgroundColor: COLORS.recruiterColor,
                        borderRadius: '50%',
                        fontSize: '20px',
                      }}
                    />
                  </Tooltip>
                </StyledDescriptionTextWrapper>
              </Row>
            </>
          ) : null}
          <BelowSection />

          {!isTestModeOn() ? (
            <AddressAutoComplete form={form} showExtraFields />
          ) : null}
          <Row gutter={16}>
            <Textarea
              xs={24}
              md={12}
              title="Interview Process"
              placeholder="Details about interview process"
              name="interviewProcess"
              value={interviewProcess}
              handleChange={handleChange}
              textCount={interviewProcessCount}
              setText={setInterviewProcess}
              setTextCount={setInterviewProcessCount}
            />
            <Textarea
              xs={24}
              md={12}
              title="First 90 Days"
              name="first90Days"
              placeholder="During the first 90 days after joining the company, what will be expected of the candidates"
              value={first90Days}
              handleChange={handleChange}
              textCount={first90DaysCount}
              setText={setFirst90Days}
              setTextCount={setFirst90DaysCount}
            />
            <Textarea
              xs={24}
              md={12}
              title="5 Reasons to Join"
              name="fiveReasonsToJoinText"
              placeholder="5 reasons why candidate should join"
              value={fiveReasonsToJoinText}
              textCount={fiveReasonsTextCount}
              handleChange={handleChange}
              setText={setFiveReasonsToJoinText}
              setTextCount={setFiveReasonsTextCount}
            />
          </Row>
          <StyledSubmitContainer>
            <PrimaryButton
              padding="10px 40px"
              type="submit"
              theme="recruiter"
              disabled={isSubmitClicked}
            >
              Post Job
            </PrimaryButton>
            <SecondaryButton
              padding="10px 40px"
              theme="recruiter"
              onClick={() => setShowModal(true)}
            >
              Preview
            </SecondaryButton>
          </StyledSubmitContainer>
        </StyledMainFormContainer>
      </FormGroup>

      <StyledModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width="1125px"
      >
        <JobModal
          jobTitle={jobTitle}
          jobType={form.getFieldValue('jobType')}
          jobLocationType={form.getFieldValue('jobLocationType')}
          domains={form.getFieldValue('domains')}
          location={form.getFieldValue('country')}
          salaryFrom={form.getFieldValue('salaryFrom')}
          salaryTo={form.getFieldValue('salaryTo')}
          currency={get(
            getCurrencyById(currencyData, form.getFieldValue('currencyId')),
            'value',
            null,
          )}
          qualification={form.getFieldValue('minQualification')}
          jobBrief={jobBriefText}
          fiveReasonsToJoin={fiveReasonsToJoinText}
          interviewProcess={interviewProcess}
          first90Days={first90Days}
          months={form.getFieldValue('months')}
          years={form.getFieldValue('years')}
        />
      </StyledModal>
    </StyledMainContainer>
  );
}
