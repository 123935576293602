import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import get from 'lodash/get';
import config from '../../../config';
import { AdminApis, Method } from '../../../constants';

/** Master-admin apis using RTK-Query for better data-fetching and caching */
const masterAdminApis = createApi({
  reducerPath: 'master-admin/api',
  tagTypes: ['candidates', 'jobApplications', 'interview'],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCandidateAppliedJobs: builder.query({
      query: (args) => {
        const { id } = args;
        let params = {
          page: args?.page,
          limit: args?.limit,
          status: args?.status,
        };
        if (args.filter) {
          params = { ...params, filter: args?.filter };
        }
        return {
          url: `${AdminApis.candidateAppliedJobs(id)}`,
          params,
          method: Method.get,
        };
      },
      transformResponse: (response) => response.payload,
      providesTags: (result, error, args) => [
        { type: 'jobApplications', id: args.id },
      ],
    }),
    forwardApplication: builder.mutation({
      query: (jobApplicationId) => ({
        url: `${AdminApis.forwardCandidateJobApplication(jobApplicationId)}`,
        method: Method.put,
      }),
      invalidatesTags: ['jobApplications'],
    }),
    rejectApplication: builder.mutation({
      query: (args) => {
        const { jobApplicationId, rejectionReasons } = args;
        return {
          url: `${AdminApis.rejectCandidateApplication(jobApplicationId)}`,
          method: Method.put,
          body: {
            rejectionReasons,
          },
        };
      },
      invalidatesTags: ['jobApplications'],
    }),
    /**
     * @params page - page number
     * @params limit - response limit
     * @params sort - sort order and type
     * @params search - search query string
     * @params eventId - candidate of a specific event
     */
    getCandidates: builder.query({
      query: (args) => {
        const page = get(args, 'page', undefined);
        const limit = get(args, 'limit', undefined);
        const sort = get(args, 'sort', null);
        const search = get(args, 'search', '');
        const eventId = get(args, 'eventId', null);

        let params = {
          page,
          limit,
        };
        if (!!sort) params = { ...params, sort };
        if (!!search) params = { ...params, search };
        if (!!eventId) params = { ...params, eventId };
        return {
          url: `${AdminApis.baseUrl}candidate/`,
          method: Method.get,
          params,
        };
      },
      transformResponse: (response) => response.payload,
      providesTags: ['candidates'],
    }),
    /** API Function for promote jobs scheduling
     * @returns isQueued : true when successful
     */
    promoteJobs: builder.mutation({
      query: (args) => {
        const jobId = get(args, 'jobId');
        const ignoredOrganizerIds = get(args, 'ignoredOrganizerIds', []);
        const body = {};
        if (ignoredOrganizerIds.length > 0)
          body.ignoredOrganizerIds = ignoredOrganizerIds;
        return {
          url: `${AdminApis.promoteJob(jobId)}`,
          method: Method.post,
          body,
        };
      },
    }),
    /** get event partners for that particular job
     * @args jobId : number | string
     * @args page : number
     * @args limit : number default 10
     */
    getEventPartnerDetailsForParticularJobId: builder.query({
      query: (args) => {
        const { jobId, page, limit } = args;
        return {
          url: AdminApis.fetchEventPartnerByJobId(jobId),
          method: Method.get,
          params: { page, limit },
        };
      },
      transformResponse: (response) => response.payload,
    }),
  }),
});

export default masterAdminApis;
export const {
  useGetCandidatesQuery,
  useGetCandidateAppliedJobsQuery,
  useForwardApplicationMutation,
  useRejectApplicationMutation,
  usePromoteJobsMutation,
  useGetEventPartnerDetailsForParticularJobIdQuery,
} = masterAdminApis;
