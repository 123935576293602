import api from './api';
import { Method, APIs } from '../constants';
import { isValid } from '../utils/api';
import get from 'lodash/get';
import ERRORS from '../constants/errors';

/** This is responsible for linking the wallet address to user profile in the database
 * If anytime user change the wallet, the wallet address will be changed
 */
export const LinkWalletToCompanyProfile = async (address) => {
  return api({
    url: `${APIs.companys}wallet-address/`,
    method: Method.put,
    body: {
      address,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
