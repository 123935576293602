import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Skeleton, Menu } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import { RecruiterRoutes } from '../../../constants/routes';
import { getUrlVars } from '../../../utils/common';
import { COLORS } from '../../../styles/constants/colors';
import { KebabMenu } from '../../shared-components/KebabMenu';
import getRemainingTime from '../../../utils/getRemainingTime';
import {
  getEventByIdData,
} from '../../../store/features/events';
import {
  EventsStore,
  getUpComingEvent,
  getOnGoingEvent,
  isEventLive,
} from '../../../utils/event';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../../utils/userInfo';
import '../../../styles/components/events/view-event.scss';
import BookOrPromoteEvent from './BookAndPromoteEvent/BookAndPromoteEvent';
import AnalyticsModal from '../../ManagementHeader/AnalyticsModal';
import CancelEventModal from '../../Common/CancelEventModal';
import { confirmBooking } from '../../../service/event';
import Notification from '../../Notification/Notification';
import EventBreadCrumbs from '../../Common/BreadCrumbs/EventBreadCrumbs';
import ViewEventContent from './ViewEventContent';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  DISPLAY_FLEX,
  getTextOverflowStyle,
  MARGIN,
} from '../../../styles/constants/display';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { FONT_WEIGHTS, FONT } from '../../../styles/constants/typography';
import { currentDate } from '../../../utils/formatDateTime';
import { useTagManager } from '../../../utils/customHooks';

export const UserClassNameContext = React.createContext({});

// Calculates Event End Time
function Timer({ expiryTimestamp, roleId }) {
  const { hours, minutes, seconds } = useTimer({
    expiryTimestamp,
  });
  const timeDifference = expiryTimestamp.diff(currentDate());
  const duration = moment.duration(timeDifference);
  return (
    <>
      {duration._data.days < 1 ? (
        <>
          <StyledEventText>Event ends in</StyledEventText>
          <StyledDateTimeText color={getUserColorWithRoleId(roleId)}>
            {hours} Hr {minutes} Min {seconds} Sec
          </StyledDateTimeText>
        </>
      ) : null}
    </>
  );
}

const ViewEvent = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, event, error } = EventsStore();
  const time = moment.unix(event.endDateTime);
  const roleId = getRoleId();
  const [isCancelModalVisible, setCancelModalVisible] = React.useState(false);

  const { inviteToken, openPaymentModal, discussionRoom } = getUrlVars(
    get(history, 'location.search', {}),
  );

  const [openBookEventModal, setOpenBookEventModal] = useState(false);
  const updateUserActionInGTM = useTagManager();

  useEffect(() => {
    updateUserActionInGTM('event_detail_page_open');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id: eventId } = match.params;

  useEffect(() => {
    if (eventId) {
      dispatch(getEventByIdData(eventId));
    }
    // eslint-disable-next-line
  }, [eventId]);

  useEffect(() => {
    if (inviteToken && !checkIfUserIsOrganizer(roleId)) {
      confirmBooking(eventId, { inviteCode: inviteToken })
        .then((response) => {
          const payload = get(response, 'data.payload', {});

          if (payload.companyIsSwitched) {
            const { accessToken, companyId, refreshToken } = payload.token;

            if (localStorage.userInfo) {
              const storedData = JSON.parse(localStorage.userInfo);
              const updatedData = {
                ...storedData,
                accessToken,
                refreshToken,
                companyId,
              };
              localStorage.setItem('userInfo', JSON.stringify(updatedData));
            } else {
              const storedData = JSON.parse(sessionStorage.userInfo);
              const updatedData = {
                ...storedData,
                accessToken,
                refreshToken,
                companyId,
              };
              sessionStorage.setItem('userInfo', JSON.stringify(updatedData));
            }
            if (!payload.openBookModal && event && !event.isEventBooked) {
              Notification('success', payload.message);
            }
            window.location.reload();
            return;
          }
          if (payload.existingBooking) {
            return;
          }
          if (!payload.openBookModal && event && !event.isEventBooked) {
            dispatch(getEventByIdData(eventId)).then(() => {
              Notification('success', payload.message);
            });
            return;
          }
          if (payload.openBookModal && event && !event.isEventBooked) {
            setOpenBookEventModal(true);
          }
        })
        .catch();
    }
  }, [inviteToken, eventId, dispatch, roleId, event]);

  const [isAnalyticsModalVisible, setIsAnalyticsModalVisible] = useState(false);

  const openModal = () => {
    setIsAnalyticsModalVisible(true);
  };
  const handleOk = () => {
    setIsAnalyticsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsAnalyticsModalVisible(false);
  };

  const handleCancelEventModal = () => {
    setCancelModalVisible((prevState) => !prevState);
  };

  const manageMenu = (
    <StyledManageMenu>
      {/*
      !getPastEvent(event) && event.isEventBooked ? (
        <Menu.Item key="0">
          <a href={() => false}>Print Invoice</a>
        </Menu.Item>
      ) : null
    */}
      {getUpComingEvent(event) && event.isEventBooked ? (
        <Menu.Item key="1">
          <StyledMenuBtnSpan
            role="button"
            tabIndex={0}
            onClick={handleCancelEventModal}
          >
            Manage Event
          </StyledMenuBtnSpan>
        </Menu.Item>
      ) : null}
      {(getUpComingEvent(event) || getOnGoingEvent(event)) &&
      event.isEventBooked &&
      checkIfUserIsRecruiter(roleId) ? (
        <Menu.Item key="1">
          <StyledMenuBtnLink
            role="button"
            to={`${RecruiterRoutes.calender}?openCreateSlot=true&eventId=${eventId}`}
          >
            Create Slot
          </StyledMenuBtnLink>
        </Menu.Item>
      ) : null}
      {/*
      !getPastEvent(event) ? (
        <Menu.Item key="2">
          <a href={() => false}>Report</a>
        </Menu.Item>
      ) : null
    */}
    </StyledManageMenu>
  );

  const UserClassNameContextProvider = () => {
    return checkIfUserIsMasterAdmin(roleId) ? null : (
      <UserClassNameContext.Provider>
        <BookOrPromoteEvent
          openModal={openModal}
          event={event}
          roleId={roleId}
          inviteCode={inviteToken}
          openBookEventModal={openBookEventModal}
          setOpenBookEventModal={setOpenBookEventModal}
          openPaymentModal={openPaymentModal}
        />
      </UserClassNameContext.Provider>
    );
  };

  const isPastEventAnalyticsModal = true;

  const termsAndConditionsList = get(event, 'meta.termsAndConditions', []);

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <div>
      <StyledContentContainer>
        <StyledCircleBox color={getUserColorWithRoleId(roleId)} />
        <StyleHeaderContainer
          id="event_title"
          isCandidate={checkIfUserIsCandidate(roleId)}
          isOngoingEvent={
            getOnGoingEvent(event) && isEventLive(event) && event.isEventBooked
          }
        >
          <Row>
            <StyledEventTitleCol md={16} xs={10}>
              <Row>
                <StyledBackIconSpan onClick={() => history.goBack()}>
                  <ArrowLeftOutlined />
                </StyledBackIconSpan>
                <StyledHiddenMobileSpan>
                  <h3 id="view_event_breadcrumb">
                    <EventBreadCrumbs event={event} />
                  </h3>
                </StyledHiddenMobileSpan>

                <StyledMobileContainer id="view_event_breadcrumb">
                  {event.name}
                </StyledMobileContainer>
              </Row>
            </StyledEventTitleCol>
            <StyledMenu md={8} xs={14}>
              <StyledMenuWrapper>
                <Col>
                  {getUpComingEvent(event) ? (
                    <>
                      <StyledEventText>Event starts in</StyledEventText>
                      <StyledDateTimeText
                        color={getUserColorWithRoleId(roleId)}
                      >
                        {getRemainingTime(
                          moment.unix(event.dateTime),
                          false,
                          true,
                        )}
                      </StyledDateTimeText>
                    </>
                  ) : getOnGoingEvent(event) ? (
                    <StyledEventTimer>
                      <Timer expiryTimestamp={time} roleId={roleId} />
                    </StyledEventTimer>
                  ) : null}
                </Col>
                {!checkIfUserIsOrganizer(roleId) &&
                !checkIfUserIsMasterAdmin(roleId) &&
                (getUpComingEvent(event) ||
                  (getOnGoingEvent(event) && checkIfUserIsRecruiter(roleId))) &&
                event.isEventBooked ? (
                  <Col>
                    <KebabMenu menu={manageMenu} id="view_event_content_menu" />
                  </Col>
                ) : null}
              </StyledMenuWrapper>
            </StyledMenu>
          </Row>
        </StyleHeaderContainer>
        <ViewEventContent
          event={event}
          roleId={roleId}
          manageMenu={manageMenu}
          termsAndConditionsList={termsAndConditionsList}
          UserClassNameContextProvider={UserClassNameContextProvider}
          isLoading={isLoading}
          error={error}
          discussionRoom={discussionRoom}
        />
      </StyledContentContainer>
      {isAnalyticsModalVisible ? (
        <AnalyticsModal
          handleModalCancel={handleModalCancel}
          handleOk={handleOk}
          isPastEventAnalyticsModal={isPastEventAnalyticsModal}
          visible={isAnalyticsModalVisible}
          eventId={eventId}
          roleId={roleId}
          eventInfo={event}
        />
      ) : null}

      {isCancelModalVisible ? (
        <CancelEventModal
          visible={isCancelModalVisible}
          handleClose={handleCancelEventModal}
          eventTitle={event.name}
          eventId={event.id}
        />
      ) : null}
    </div>
  );
};

const StyledContentContainer = styled.div`
  position: relative;
`;

const StyledCircleBox = styled.div`
  background: ${(props) => props.color};
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.15;
  position: absolute;
  left: -124px;
  top: -100px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none !important;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 310px;
    height: 310px;
  }
`;

const StyledBackIconSpan = styled.span`
  display: none;
  align-items: center;
  align-self: center;
  justify-content: center;
  ${MARGIN(10, 10, 0, 0)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 5, 0)};
    display: block;

    svg {
      width: 14px;
      margin-left: 5px;
    }
  }
`;

const StyledMobileContainer = styled.span`
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-size: 16px;
  line-height: 46px;
  width: 100%;
  margin-top: -2px;
  margin-left: 8px;
  float: left;
  ${getTextOverflowStyle(1)}
  display: none;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: -webkit-box;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
    width: calc(100% - 30px);
  }
`;

const StyleHeaderContainer = styled.div`
  padding: 0px !important;
  position: relative;
  z-index: 11;
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 5px 0;
    z-index: 999;

    ${StyledBackIconSpan} {
      margin-top: ${({ isCandidate, isOngoingEvent }) =>
        isCandidate && isOngoingEvent ? '52px' : '8px'};
      position: ${({ isCandidate, isOngoingEvent }) =>
        isCandidate && isOngoingEvent && 'fixed'};
      z-index: 100;
    }

    ${StyledMobileContainer} {
      position: ${({ isCandidate, isOngoingEvent }) =>
        isCandidate && isOngoingEvent && 'fixed'};
      z-index: 100;
      ${({ isCandidate, isOngoingEvent }) =>
        isCandidate && isOngoingEvent
          ? `
      margin-top: -2px;
    position: fixed;
    left: 32px;
    width: calc(100% - 70px);
      `
          : ''};
    }
  }
`;

const StyledEventTitleCol = styled(Col)`
  h3 {
    color: ${(props) => props.color};
    font-weight: ${FONT_WEIGHTS.BOLD};
    font-size: 16px;
    line-height: 46px;
    display: flex;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    float: left;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 14px;
      width: unset;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    span {
      ${DISPLAY_FLEX('row', null, 'center')};
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      margin-top: 0;
      position: relative;
      width: 100%;
    }
  }
`;

const StyledHiddenMobileSpan = styled.span`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none !important;
  }
`;

const StyledManageMenu = styled(Menu)`
  width: 100%;
  max-width: 300px;
  min-width: 160px;
  top: -33px;
  left: -34px;
  border-radius: 5px;
`;

const StyledMenuBtnSpan = styled.span`
  &:focus {
    outline-color: transparent;
  }
`;

const StyledMenuBtnLink = styled(Link)`
  &:focus {
    outline-color: transparent;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledMenuWrapper = styled(Row)`
  text-align: right;
  position: relative;
  margin: 0 0 0 auto;
  gap: 10px;
  color: ${COLORS.PHILLIPPINE_GRAY};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    text-align: left;
  }
`;

const StyledEventText = styled.span`
  margin-right: 5px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }
`;

const StyledDateTimeText = styled.span`
  color: ${(props) => props.color} !important;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }
`;

const StyledEventTimer = styled.div`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
`;

const StyledMenu = styled(Col)`
  ${DISPLAY_FLEX('row', null, 'center')}
`;

export default ViewEvent;
