import React from 'react';
import { useDispatch } from 'react-redux';
import ActiveWallet from '../../../assets/svg/ActiveWallet.svg';
import InactiveWallet from '../../../assets/svg/InactiveWallet.svg';
import { Tooltip } from 'antd';
import { useVirtualWallet } from '../../../hooks/useVirtualWallet';
import { useHistory } from 'react-router-dom';
import { usePaymentInformation } from '../../../hooks/paymentInformation';
import { useSkipPayment } from '../../../hooks/useSkipPayment';
import { RecruiterRoutes } from '../../../constants/routes';
import { skipOrEnablePaymentAccess } from '../../../store/features/profile';
import { allowAccessWithoutCryptoIncentivePlan } from '../../../constants/crypto';

const RecruiterWallet = () => {
  const { wallet, cryptoCurrency } = useVirtualWallet();
  const { paymentPending } = usePaymentInformation();
  const { isSkipped, canNavigate } = useSkipPayment();
  const history = useHistory();
  const dispatch = useDispatch();
  const disabled = wallet?.balance <= 0 || (paymentPending && canNavigate());

  /** Navigate to pricing-plan route if payment is not done */
  const gotoPricingPlan = () => {
    // If route is same
    if (history.location.pathname === RecruiterRoutes.pricingPlan) return;
    if (!paymentPending) {
      return;
    }
    if (isSkipped()) {
      // change the access status from skipped to enabled via api-call
      dispatch(
        skipOrEnablePaymentAccess(allowAccessWithoutCryptoIncentivePlan.ENABLE),
      );
    }
    return history.push(RecruiterRoutes.pricingPlan);
  };
  return (
    <Tooltip
      title={disabled ? 'Subscribe to a plan' : 'Your credits'}
      placement="bottomLeft"
      trigger={disabled ? ['hover'] : ['click', 'hover']}
    >
      <div onClick={gotoPricingPlan}>
        <img
          src={disabled ? InactiveWallet : ActiveWallet}
          alt="active-wallet"
          height={30}
          width={30}
        />
        <span data-verified={disabled ? 'inactive' : 'active'}>
          {wallet?.balance} {cryptoCurrency}
        </span>
      </div>
    </Tooltip>
  );
};

export default RecruiterWallet;
