import React from 'react';
const ArrowDropDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.583"
      height="15.185"
      viewBox="0 0 15.583 15.185"
    >
      <path
        id="Path_831"
        data-name="Path 831"
        d="M5,13H16.17l-4.88,4.88a1.008,1.008,0,0,0,0,1.42,1,1,0,0,0,1.41,0l6.59-6.59a1,1,0,0,0,0-1.41L12.71,4.7A1,1,0,1,0,11.3,6.11L16.17,11H5a1,1,0,0,0,0,2Z"
        transform="translate(-4 -4.408)"
      />
    </svg>
  );
};

export default ArrowDropDown;
