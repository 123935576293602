import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../../config';
import { APIs, Method } from '../../../constants';

/** This api is implemented as RTK-Query for fetching the cryptoIncentivePlans so these plans can get cached for the payment skipped recruiters.
 */
const recruiterApi = createApi({
  reducerPath: 'recruiter/api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCryptoPlans: builder.query({
      query: () => ({ url: APIs.getCryptoIncentivePlans, method: Method.get }),
      transformResponse: (response) => response.payload.cryptoIncentivePlans,
    }),
    getRecruiterRedirect: builder.query({
      query: () => ({
        url: APIs.recruiterRedirect,
        method: Method.get,
      }),
      transformResponse: (response) => response.payload.dashboardRedirect,
      // unused data will be in the cache for 3 minutes
      keepUnusedDataFor: 180,
    }),
  }),
});
export default recruiterApi;
export const {
  useGetCryptoPlansQuery,
  useGetRecruiterRedirectQuery,
} = recruiterApi;
