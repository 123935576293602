import React from 'react';
import get from 'lodash/get';
import {
  FormGroup,
  SearchInputBox,
} from '../../../components/shared-components/Form/Form';
import { RightAlignContainer } from '../../../components/shared-components/RightAlignContainer';
import CustomPagination from '../../../components/Common/CustomPagination';
import ModalButtonContainer from '../../../components/profile/ModalButtonContainer';
import {
  GeneralSettingEntityBaseUnionType,
  GeneralSettingEntityUnionType,
  GeneralSettingModalLayoutProps,
  RightAlignedPaginationProps,
  RightAlignedSearchAndAddProps,
  voidTypeFunction,
} from '../../../interfaces/admin/generalSetting/common';
import Notification from '../../../components/Notification/Notification';
import {
  addGeneralSettingEntity,
  editGeneralSettingEntity,
} from '../../../service/masterAdmin';
import { PrimaryButton } from '../../../components/shared-components/Button';
import { getThemeByRoleId } from '../../../utils/common';
import { ADMIN } from '../../../constants';
import { Modal } from '../../../components/shared-components/Modal';

export const RightAlignedSearchAndAdd: React.FC<RightAlignedSearchAndAddProps> = ({
  handleSearch,
  openAddModal,
  search
}) => {
  return (
    <RightAlignContainer>
      <SearchInputBox defaultValue={search} placeholder="Search" handleChange={handleSearch} />
      <PrimaryButton
        onClick={openAddModal}
        theme={getThemeByRoleId(ADMIN)}
        margin="0 0 0 10px"
      >
        Add
      </PrimaryButton>
    </RightAlignContainer>
  );
};

export const RightAlignedPagination: React.FC<RightAlignedPaginationProps> = ({
  pagination,
  currentPage,
  currentLimit,
  onPageChangeHandler,
  onLimitChangeHandler,
}) => {
  return (
    <RightAlignContainer marginTop={20} marginBottom={20}>
      <CustomPagination
        currentPage={pagination.currentPage || currentPage}
        totalItems={get(pagination, 'totalItems', 0)}
        onPageChange={onPageChangeHandler}
        onLimitChange={onLimitChangeHandler}
        currentLimit={currentLimit}
      />
    </RightAlignContainer>
  );
};

export const GeneralSettingModalLayout: React.FC<GeneralSettingModalLayoutProps> = ({
  isEditMode,
  modalOpen,
  handleOk,
  handleCancel,
  form,
  handleSubmit,
  initialValues,
  children,
  width = '40%',
}) => {
  return (
    <Modal
      title={!isEditMode ? 'Add' : 'Edit'}
      visible={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={width}
    >
      <FormGroup
        form={form}
        name="generalForm"
        onFinish={handleSubmit}
        // TODO : remove @ts-ignore line form.jsx is converted to form.tsx
        // @ts-ignore
        initialValues={isEditMode ? initialValues : null}
        expand
        id="generalForm"
      >
        {children}

        <ModalButtonContainer
          handleResetFields={() => {
            form.resetFields();
            handleCancel();
          }}
          submitText="Save"
        />
      </FormGroup>
    </Modal>
  );
};

export const commonSubmitHandler = async (
  isEditMode: boolean,
  formData: GeneralSettingEntityBaseUnionType,
  selectedData: GeneralSettingEntityUnionType,
  entity: string = 'country',
) => {
  if (isEditMode) {
    await editGeneralSettingEntity(
      get(selectedData, 'id', null),
      formData,
      entity,
    ).then(() => {
      Notification('success', `${entity} Updated successfully`);
    });
  } else {
    await addGeneralSettingEntity(formData, entity).then(() => {
      Notification('success', `${entity} Added successfully`);
    });
  }
};

export const editStatusAndPopulateData = async (
  id: number,
  status: number,
  entity: string,
  populate: voidTypeFunction,
) => {
  await editGeneralSettingEntity(
    id,
    {
      status,
    },
    entity,
  ).then(() => {
    populate();
  });
};
