/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Page } from 'react-pdf';

const DocPages = ({ pageNumber, limit, numPages }) => {
  const pagesList = [];
  const initialPage = (pageNumber - 1) * limit + 1;
  for (let i = initialPage; i <= limit * pageNumber && i <= numPages; i += 1) {
    pagesList.push(i);
  }
  return (
    <>
      {pagesList.map((i) => (
        <Page pageNumber={i} width={window.innerWidth / 1.33} />
      ))}
    </>
  );
};

export default DocPages;
