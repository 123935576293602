import { useSelector } from 'react-redux';

export const useVirtualWallet = () => {
  const {
    wallet,
    isLoading,
    status,
    incomingBalance,
    incomingBalanceFetching,
    incomingBalanceError,
    isWithdrawing,
    isWithdrawComplete,
    error,
    withdrawError,
    withdrawTransactionHash,
  } = useSelector((state) => state.wallet);

  return {
    walletLoading: isLoading,
    status,
    wallet,
    cryptoCurrency: wallet?.cryptoCurrency?.symbol,
    incomingBalanceFetching,
    incomingBalanceError,
    isWithdrawing,
    incomingBalance: incomingBalance?.amount,
    isWithdrawComplete,
    fetchWalletError: error,
    txnHash: withdrawTransactionHash,
    withdrawError,
  };
};
