/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { checkIfUserIsOrganizer, getRoleId } from '../../utils/userInfo';
import { SearchInputBox } from '../shared-components/Form/Form';
import { PrimaryButton } from '../shared-components/Button';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';

const Header = ({ setValue, handleModalOpen }) => {
  const roleId = getRoleId();
  const isUserOrganizer = checkIfUserIsOrganizer(roleId);

  return (
    <StyledMainContainer id="event_title">
      <Row>
        <StyledHeaderWrapper xs={24} lg={10} xxl={6}>
          <Row>
            <Col xs={12}>
              <StyledHeader color={getUserColorWithRoleId(roleId)}>
                <span>Events</span>
              </StyledHeader>
            </Col>
            {isUserOrganizer ? (
              <StyledShowMobileCreateEventWrapper xs={12}>
                <StyledShowMobileLink>
                  <ButtonWrapper>
                    <PrimaryButton
                      theme="organizer"
                      id="events_create_btn_res"
                      onClick={handleModalOpen}
                    >
                      <PlusOutlined /> Create Event
                    </PrimaryButton>
                  </ButtonWrapper>
                </StyledShowMobileLink>
              </StyledShowMobileCreateEventWrapper>
            ) : null}
          </Row>
        </StyledHeaderWrapper>
        <StyledRightButtonWrapper
          xs={24}
          lg={14}
          xxl={18}
          isUserOrganizer={isUserOrganizer}
        >
          <Flex>
            <StyledSearchInputWrapper>
              <SearchInputBox
                placeholder="Filter by event name"
                handleChange={(e) => setValue(e.target.value)}
              />
            </StyledSearchInputWrapper>
            {isUserOrganizer ? (
              <StyledHiddenMobileLink>
                <PrimaryButton
                  theme="organizer"
                  id="events_create_btn"
                  onClick={handleModalOpen}
                >
                  {' '}
                  <PlusOutlined /> <div>Create Event</div>
                </PrimaryButton>
              </StyledHiddenMobileLink>
            ) : null}
          </Flex>
        </StyledRightButtonWrapper>
      </Row>
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled.div`
  padding: 0px !important;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: -20px;
  }
`;

const StyledHeaderWrapper = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 20px;
  }
`;

const StyledHeader = styled.h3`
  display: flex;
  margin-top: 22px;
  line-height: 46px;
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: ${FONT[20]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none !important;
  }
`;

const StyledShowMobileCreateEventWrapper = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('row-reverse')};
  }
`;

const StyledShowMobileLink = styled(Link)`
  display: none !important;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block !important;
  }

  button {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${MARGIN(20, 0, 10, 0)};
      font-size: ${FONT[14]};
      height: 34px;
    }
  }
`;

const StyledRightButtonWrapper = styled(Col)`
  margin-top: 20px;
  justify-content: flex-end;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ isUserOrganizer }) => (isUserOrganizer ? 'margin-top: 0;' : '')};
  }
`;

const StyledHiddenMobileLink = styled(Link)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none !important;
  }

  button {
    margin-left: 10px;
  }
`;

const StyledSearchInputWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  position: relative;
  margin: 0 0 0 auto;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: 10px;
  }

  input {
    border-color: ${COLORS.PHILLIPPINE_GRAY};
    font-weight: ${FONT_WEIGHTS.REGULAR};
    background: ${COLORS.LOTION};
    border-radius: 5px;
    margin-right: 20px;
    height: 40px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 15px;
  }

  button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: 150px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      margin-top: 10px;
    }

    div {
      font-size: 15px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: -10px;

  button {
    white-space: nowrap;
  }
`;

export default Header;
