import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import styled from 'styled-components';
import { MinusCircleOutlined } from '@ant-design/icons';
import { removeMemberInviteAdmin } from '../../service/masterAdmin';
import {
  FormGroup,
  AutoCompleteBox,
  InputBox,
} from '../shared-components/Form/Form';
import { checkIfUserExistOrNot } from '../../service/search';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  getRoleId,
} from '../../utils/userInfo';
import {
  sendInvitationToRegistedMember,
  sendInvitationToNonRegistedMember,
  getOrganizerCrewMembersData,
  getRecruiterCrewMembersData,
  getCrewMembersAdminData,
  sendInvitationToRegistedMemberAdmin,
  sendInvitationToNonRegistedMemberAdmin,
} from '../../store/features/crew';
import Notification from '../Notification/Notification';
import { crewMemberStore, userProfileStore } from '../../utils/profile';
import { removeMember, removeMemberInvite } from '../../service/crew';
import {
  handleAcceptInvitation,
  handleRejectInvitation,
  isMemberActive,
  userIsCrewMember,
} from '../../utils/crew';
import { CONFIRM_MESSAGES, STATUS_ACTIVE, STATUS_DRAFT } from '../../constants';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../shared-components/Button';
import { Heading, LargeFont } from '../shared-components/Texts';
import { getUserColorWithRoleId } from '../../styles/utils';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { ActiveText, CrossText, DangerText, InvitedText } from './common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getThemeByRoleId } from '../../utils/common';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { RightAlignContainer } from '../shared-components/RightAlignContainer';
import MandatoryStar from '../Common/mandatoryStar';
import { Modal } from '../shared-components/Modal';
import { isTestModeOn } from '../../utils/test-helper';

const InviteMemberModal = ({
  visible,
  handleClose,
  adminData,
  companyId,
  role,
}) => {
  const { profileData } = userProfileStore();
  const [members, setMembers] = useState([]);
  const { members: membersList } = crewMemberStore();
  const [form] = Form.useForm();
  const roleId = getRoleId();
  const dispatch = useDispatch();

  const isCrewMember = userIsCrewMember(profileData);

  const populateMembers = useCallback(() => {
    if (
      !checkIfUserIsCandidate(roleId) &&
      !checkIfUserIsMasterAdmin(roleId) &&
      get(profileData, 'company.status', STATUS_DRAFT) === STATUS_ACTIVE
    ) {
      checkIfUserIsOrganizer(roleId)
        ? dispatch(getOrganizerCrewMembersData())
        : dispatch(getRecruiterCrewMembersData());
    }
    if (checkIfUserIsMasterAdmin(roleId)) {
      dispatch(getCrewMembersAdminData(companyId, role));
    }
    // eslint-disable-next-line
  }, [roleId, dispatch, profileData]);

  useEffect(() => {
    populateMembers();
  }, [populateMembers]);

  const handleModalCancel = () => {
    handleClose();
    form.resetFields();
  };

  useEffect(() => {
    if (isTestModeOn()) {
      setMembers(membersList);
      return;
    }
    setMembers([
      {
        name: adminData
          ? get(adminData, 'representativeName')
          : get(profileData, 'companyAdmin.representativeName', ''),
        email: adminData
          ? get(adminData, 'representativeEmail')
          : get(profileData, 'companyAdmin.representativeEmail', ''),
        phone: adminData
          ? get(adminData, 'representativePhone')
          : get(profileData, 'companyAdmin.representativePhone', ''),
        status: STATUS_ACTIVE,
        isAdmin: true,
      },
      ...membersList,
    ]);
  }, [adminData, membersList, profileData]);

  const handleLeaveCrew = async () => {
    const companyId = get(profileData, 'company.id', null);
    const id = get(profileData, 'userId', null);
    const response = await removeMember({ companyId, userId: id });

    if (response.status === 200) {
      Notification(
        'success',
        'Crew management',
        'You successfully left the crew',
      );
      window.location.reload();
    }
  };

  const handleFinish = async ({ member }) => {
    const body = {
      email: member,
    };
    const response = await checkIfUserExistOrNot(member);
    const userExist = get(response, 'data.payload.userExist', false);
    const userId = get(response, 'data.payload.userId', null);

    const adminPayload = {
      adminId: get(adminData, 'id', null),
      companyId,
      roleId: role,
    };

    form.validateFields().then(() => {
      if (userExist) {
        if (checkIfUserIsMasterAdmin(roleId)) {
          dispatch(
            sendInvitationToRegistedMemberAdmin(role, companyId, {
              userId,
              ...adminPayload,
            }),
          );
        } else {
          dispatch(sendInvitationToRegistedMember(roleId, { userId }));
        }
      } else if (checkIfUserIsMasterAdmin(roleId)) {
        dispatch(
          sendInvitationToNonRegistedMemberAdmin(role, companyId, {
            ...body,
            ...adminPayload,
          }),
        );
      } else {
        dispatch(sendInvitationToNonRegistedMember(roleId, body));
      }
    });
    form.resetFields();
  };

  const handleRemove = async (companyId, user) => {
    const body = { companyId, userId: user };
    if (checkIfUserIsMasterAdmin(roleId)) {
      body.role = role;
    }
    const response = await removeMember(body);

    if (response.status === 200) {
      Notification(
        'success',
        'Crew management',
        'Member removed from the crew',
      );
      populateMembers();
    }
  };
  const handleRemoveInvite = async (member) => {
    if (!checkIfUserIsMasterAdmin(roleId)) {
      await removeMemberInvite({
        memberId: member.userId,
        email: member.email,
      });
    } else {
      await removeMemberInviteAdmin({
        memberId: member.userId,
        email: member.email,
        companyId,
        roleId: role,
      });
    }
    Notification('success', 'Invite is removed');
    populateMembers();
  };

  const invitations = get(profileData, 'invitations', []);

  const handleAccept = (idx, companyName) => {
    handleAcceptInvitation(idx, companyName, dispatch, profileData);
  };

  const handleReject = (idx, companyName) => {
    handleRejectInvitation(idx, companyName, dispatch, profileData);
  };

  const getRemoveContainerLeftOption = (member) => {
    switch (true) {
      case !isMemberActive(member):
        return <InvitedText>Invited</InvitedText>;
      case member.isAdmin:
        return <ActiveText>Admin</ActiveText>;
      case !isCrewMember:
        return (
          <Popconfirm
            title={CONFIRM_MESSAGES.removeMember}
            onConfirm={() =>
              handleRemove(
                !checkIfUserIsMasterAdmin(roleId)
                  ? get(profileData, 'company.id', null)
                  : companyId,
                get(member, 'userId', null),
              )
            }
            okText="Yes"
            cancelText="No"
          >
            <TextButton
              type="button"
              font={FONT[14]}
              fontWeight={FONT_WEIGHTS.SEMI_BOLD}
              color={COLORS.CUSTOM_RED}
            >
              <MinusCircleOutlined />
            </TextButton>
          </Popconfirm>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      centered
      title="Crew Management"
      theme={getThemeByRoleId(roleId)}
    >
      <div>
        <Heading color={getUserColorWithRoleId(roleId)} margin="0 0">
          Crew Members
        </Heading>
        {members.length ? (
          <StyledMemberContainer>
            {members.map((member, idx) => {
              if (
                toLower(member.email) === toLower(profileData.email) &&
                !member.isAdmin &&
                !isTestModeOn()
              ) {
                return null;
              }
              return (
                <StyledMemberItem key={idx.toString()}>
                  <Col md={6} xs={24}>
                    <StyledLabel htmlFor="member-name">
                      <LargeFont>Member Name</LargeFont>
                    </StyledLabel>
                    <Row>
                      <InputBox
                        value={member.name ? member.name : '--'}
                        id="member-name"
                        disabled
                      />
                    </Row>
                  </Col>

                  <StyledColWithCustomOffset xs={24} md={6}>
                    <StyledLabel htmlFor="phone-number">
                      <LargeFont>Phone Number</LargeFont>
                    </StyledLabel>
                    <Row>
                      <InputBox
                        value={
                          member.phone && member.phone.trim() !== ''
                            ? member.phone
                            : '--'
                        }
                        id="phone-number"
                        disabled
                      />
                    </Row>
                  </StyledColWithCustomOffset>

                  <StyledColWithCustomOffset xs={24} md={6}>
                    <StyledLabel htmlFor="email-address">
                      <LargeFont>Email Address</LargeFont>
                    </StyledLabel>
                    <Row>
                      <InputBox
                        value={member.email}
                        id="email-address"
                        disabled
                      />
                    </Row>
                  </StyledColWithCustomOffset>

                  <StyledActionsContainer xs={24} md={4}>
                    <Row>
                      <Col>{getRemoveContainerLeftOption(member)}</Col>
                      {!isMemberActive(member) && !isCrewMember ? (
                        <Col>
                          <Popconfirm
                            title={CONFIRM_MESSAGES.removeMemberInvite}
                            onConfirm={() => handleRemoveInvite(member)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <CrossText>Remove</CrossText>
                          </Popconfirm>
                        </Col>
                      ) : null}
                    </Row>
                  </StyledActionsContainer>
                </StyledMemberItem>
              );
            })}
          </StyledMemberContainer>
        ) : (
          <LargeFont>
            No Crew Members at the moment, please invite members through their
            emails.
          </LargeFont>
        )}
      </div>
      {invitations.length && !checkIfUserIsMasterAdmin(roleId) ? (
        <div>
          <Heading color={getUserColorWithRoleId(roleId)} margin="10 0">
            Pending Invitations
          </Heading>
          <StyledMemberContainer>
            {invitations.map((invitation, idx) => {
              return (
                <Row key={idx.toString()}>
                  <Col md={6} xs={24}>
                    <StyledLabel htmlFor="member-name">
                      <LargeFont>Company Name</LargeFont>
                    </StyledLabel>
                    <Row>
                      <InputBox
                        value={invitation.company.name}
                        id="member-name"
                        disabled
                      />
                    </Row>
                  </Col>

                  <StyledColWithCustomOffset xs={24} md={6}>
                    <StyledLabel htmlFor="phone-number">
                      <LargeFont>Phone Number</LargeFont>
                    </StyledLabel>
                    <Row>
                      <InputBox
                        value={invitation.company.phone}
                        id="phone-number"
                        disabled
                      />
                    </Row>
                  </StyledColWithCustomOffset>

                  <StyledColWithCustomOffset xs={24} md={6}>
                    <StyledLabel htmlFor="email-address">
                      <LargeFont>Location</LargeFont>
                    </StyledLabel>
                    <Row>
                      <InputBox
                        value={invitation.company.country}
                        id="email-address"
                        disabled
                      />
                    </Row>
                  </StyledColWithCustomOffset>

                  <StyledActionsContainer xs={24} md={3}>
                    <Row>
                      <Col>
                        <ActiveText
                          onClick={() =>
                            handleAccept(idx, invitation.company.name)
                          }
                        >
                          Accept
                        </ActiveText>
                      </Col>
                      <Col offset={1}>
                        <Popconfirm
                          title={CONFIRM_MESSAGES.removeInvitation}
                          onConfirm={() =>
                            handleReject(idx, invitation.company.name)
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <DangerText>Reject</DangerText>
                        </Popconfirm>
                      </Col>
                    </Row>
                  </StyledActionsContainer>
                </Row>
              );
            })}
          </StyledMemberContainer>
        </div>
      ) : null}
      {!isCrewMember ? (
        <FormGroup
          form={form}
          name="InviteMemberModal"
          onFinish={handleFinish}
          expand
        >
          <Heading>Add Members</Heading>

          <div>
            <Row>
              {
                // as per new signup flow we only need email and rest details we will take in onboarding page
                /*
              <Col xs={24} md={6}>
                <StyledLabel htmlFor="full-name-member">
                  <LargeFont>Member Name (Optional)</LargeFont>
                </StyledLabel>

                <TextBox
                  name="fullName"
                  placeholder="Full Name"
                  required={false}
                  rule="text"
                  maxLength={100}
                  id="full-name-member"
                />
              </Col>

              <StyledColWithCustomOffset xs={24} md={6}>
                <StyledLabel htmlFor="member-phone-number">
                  <LargeFont>Phone Number (Optional)</LargeFont>
                </StyledLabel>
                <Row>
                  <Col xs={8} lg={10}>
                    <CountryCodeInput
                      name="phoneNumberPrefix"
                      required={false}
                    />
                  </Col>
                  <Col xs={16} lg={14}>
                    <TextBox
                      name="phoneNumber"
                      placeholder="Phone Number"
                      rule="phoneNumber"
                      maxLength={12}
                      required={false}
                      id="member-phone-number"
                    />
                  </Col>
                </Row>
              </StyledColWithCustomOffset>
              */
              }
              <Col md={6} xs={24}>
                <StyledLabel htmlFor="member-email-address">
                  <LargeFont>
                    Email Address <MandatoryStar />
                  </LargeFont>
                </StyledLabel>
                <AutoCompleteBox
                  name="member"
                  placeholder="Invite a member"
                  id="member-email-address"
                />
              </Col>
            </Row>
          </div>

          <div>
            <Row gutter={[0, 0]}>
              <StyledLeaveCrew xs={12}>
                {!checkIfUserIsMasterAdmin(roleId) ? (
                  <Popconfirm
                    title={CONFIRM_MESSAGES.leaveCrew}
                    onConfirm={() => handleLeaveCrew()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <SecondaryButton id="leave_crew_btn">
                      Leave Crew
                    </SecondaryButton>
                  </Popconfirm>
                ) : null}
              </StyledLeaveCrew>
              <Col xs={12}>
                <RightAlignContainer>
                  <PrimaryButton
                    mobilePadding="6px 40px"
                    type="submit"
                    theme={getThemeByRoleId(roleId)}
                    id="invite_member_btn"
                  >
                    Invite
                  </PrimaryButton>
                </RightAlignContainer>
              </Col>
            </Row>
          </div>
        </FormGroup>
      ) : (
        <div>
          <Row gutter={[0, 0]}>
            <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4, offset: 0 }}>
              {!checkIfUserIsMasterAdmin(roleId) ? (
                <Popconfirm
                  title={CONFIRM_MESSAGES.leaveCrew}
                  onConfirm={() => handleLeaveCrew()}
                  okText="Yes"
                  cancelText="No"
                >
                  <SecondaryButton id="member_leave_crew_btn">
                    Leave Crew
                  </SecondaryButton>
                </Popconfirm>
              ) : null}
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default InviteMemberModal;

const StyledMemberContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

const StyledMemberItem = styled(Row)`
  ${MARGIN(0, 0, 10, 0)}
`;

const StyledActionsContainer = styled(Col)`
  ${DISPLAY_FLEX('column', 'flex-end')}
  ${MARGIN(0, 0, 4, 15)}

  @media (min-width:${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(0, 0, 8, 25)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(10, 0)}
  }
`;

const StyledLabel = styled.label`
  * {
    ${MARGIN(4, 0)}
    width: max-content;
  }
`;

const StyledLeaveCrew = styled(Col)`
  ${DISPLAY_FLEX('column', 'center')}

  button {
    color: ${COLORS.TART_ORANGE};
    border: 1px solid ${COLORS.TART_ORANGE};
    width: 180px;

    &:hover,
    &:focus,
    &:active {
      background-color: ${COLORS.TART_ORANGE};
      color: ${COLORS.WHITE};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 140px;
      font-size: 12px;
    }
  }
`;

const StyledColWithCustomOffset = styled(Col)`
  ${MARGIN(0, 0, 0, 20)}

  @media (max-width:${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0)}
  }
`;
