/* eslint-disable import/no-cycle */
import get from 'lodash/get';
import api from './api';
import { APIs, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import {
  getNearByEvents,
  getRecommendedEvents,
  getCalendarEvents,
} from './event';
import Notification from '../components/Notification/Notification';
import { getResume } from '../utils/profile';
import { allowAccessWithoutCryptoIncentivePlan } from '../constants/crypto';

export const getRecruiterBookedEvents = async () => {
  return api({
    url: `${APIs.recruiter}events/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.events', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createJob = async (body) => {
  return api({
    url: `${APIs.recruiter}jobs/`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const validateCoupon = async (cryptoIncentivePlanId, couponCode) => {
  return api({
    url: APIs.validateCoupon(cryptoIncentivePlanId),
    method: Method.post,
    body: {
      couponCode,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobs = async (
  fields,
  page,
  limit,
  jobStatus = null,
  sorter = null,
) => {
  return api({
    url: `${APIs.recruiter}jobs/?page=${page}&limit=${limit}${jobStatus ? `&jobStatus=${jobStatus}` : ''
      }${fields === 'all' ? '&fields=all' : ''}${sorter ? `&sort=${sorter}` : ''
      }`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return {
        jobs: get(response, 'data.payload.jobs', null),
        currentPage: get(response, 'data.payload.currentPage', null),
        totalItems: get(response, 'data.payload.totalItems', null),
      };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobById = async (id) => {
  return api({
    url: `${APIs.recruiter}jobs/${id}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.job', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getBooleanQueryAccessibilityForRecruiter = async () => {
  return api({
    url: `${APIs.recruiter}boolean-query/accessibility/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.booleanQueryAccessible', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getBooleanQueryForRecruiter = async (jobId) => {
  return api({
    url: `${APIs.recruiter}job/${jobId}/boolean-query/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.booleanSearchStrings', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editJob = async (id, body) => {
  return api({
    url: `${APIs.recruiter}jobs/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobApplicationsById = async (
  ids,
  eventIds,
  page,
  limit,
  location = null,
  country = null,
  availableStartTime = null,
  applicationFilter = null,
  sorter = null,
  startDate = null,
  endDate = null,
) => {
  return api({
    url: `${APIs.recruiterJobApplications
      }?jobIds=${ids}&eventIds=${eventIds}&page=${page}&limit=${limit}${sorter ? `&sort=${sorter}` : ''
      }${location ? `&jobLocationType=${location}` : ''}${country ? `&country=${country}` : ''
      }${availableStartTime ? `&startAvailability=${availableStartTime}` : ''}${applicationFilter ? `&applicationStatus=${applicationFilter}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''
      }`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return {
        jobApplications: get(response, 'data.payload.jobApplications', null),
        currentPage: get(response, 'data.payload.currentPage', null),
        totalItems: get(response, 'data.payload.totalItems', null),
      };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editJobStatus = async (id, body) => {
  return api({
    url: `${APIs.recruiter}job/status/${id}`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createInterviewSlot = async (body) => {
  return api({
    url: `${APIs.recruiterInterviewSlot}`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getInterviewSlots = async (body) => {
  return api({
    url: `${APIs.getRecruiterInterviewSlots}`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.jobInterviewSlots', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecruiterRecommendedEvents = async (page, limit) => {
  return getRecommendedEvents(APIs.recruiter, page, limit);
};

export const getRecruiterNearByEvents = async () => {
  return getNearByEvents(APIs.recruiter);
};

export const getRecruiterCalendarEvents = async (
  startTimestamp,
  endTimestamp,
) => {
  return getCalendarEvents(APIs.recruiter, startTimestamp, endTimestamp);
};

export const evaluationDeadlineExtendRequest = async (id) => {
  return api({
    method: Method.put,
    url: APIs.eventEvaluationDeadlineExtend(id),
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      Notification('success', 'Deadline evaluation request send');

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getJobApplicationsByApplicationId = async (
  jobId,
  applicationId,
  eventId,
) => {
  return api({
    url: `${APIs.recruiterJobApplicationById(
      jobId,
      applicationId,
    )}?eventId=${eventId}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.jobApplication', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const jobApplicationStatusChange = async (id, data) => {
  return api({
    url: APIs.recruiterJobApplicationStatus(id),
    method: Method.put,
    body: data,
  });
};

export const getCandidateProfile = (userId) => {
  return api({
    url: APIs.recruiterCandidateProfile(userId),
    method: Method.get,
  });
};

export const downloadResume = async (file, name) => {
  let extension = file.split('.');
  extension = extension[extension.length - 1];
  await fetch(getResume(file))
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}_resume.${extension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => { });
};

export const downloadResumeZip = async (files) => {
  return api({
    url: `${APIs.downloadResumeZip}`,
    method: Method.post,
    body: files,
  })
    .then((response) => response.data.payload.content)
    .then((blob) => {
      var url = 'data:application/zip;base64,' + blob;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `Candidate_resumes.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch((error) => { });
};

export const checkIfCompanyHasCreatedInterviewSlots = async (id) => {
  return api({
    url: APIs.checkIfCompanyHasCreatedInterviewSlots(id),
    method: Method.get,
  });
};

export const deleteInterviewSlot = async (id) => {
  return api({
    url: APIs.deleteInterviewSlot(id),
    method: Method.put,
  });
};

export const getAutoFillDataForJob = async () => {
  return api({
    url: `${APIs.recruiter}autofill/job`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkIfCompanyHaveAnyActiveJobs = async () => {
  return api({
    url: `${APIs.recruiter}check/active-jobs/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.doesRecruiterHaveActiveJobs', false);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const requestEvaluationExtension = async (body) => {
  return api({
    url: `${APIs.recruiter}evaluation-deadline/extend`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.success', false);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getInterviewSlotsByEvent = async (eventId, date) => {
  return api({
    url: `${APIs.recruiter}event/${eventId}/interview-slot/?date=${date}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.interviewSlots', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteSlot = async (slotId) => {
  return api({
    url: `${APIs.recruiter}interview-slot/${slotId}/`,
    method: Method.delete,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const skipOrEnablePayment = async (
  status = allowAccessWithoutCryptoIncentivePlan.SKIPPED,
) => {
  return api({
    url: `${APIs.recruiter}crypto-incentive-plan/skip-or-enable/`,
    method: Method.put,
    body: {
      status,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
