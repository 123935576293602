/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import config from '../../../config';
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from "@giphy/js-types";
import { Grid } from "@giphy/react-components";
import ResizeObserver from "react-resize-observer";
import { Gif, } from "@giphy/react-components";
import styled from 'styled-components';
import { InputBox } from '../../shared-components/Form/Form';
import { PrimaryButton } from '../Button';
import { CloseChat } from '../../svgicons';
import { SearchOutlined } from '@ant-design/icons';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../../styles/constants/display';
import { MESSAGE_CONTENT } from '../../../constants/chat';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BOX_SHADOW } from '../../../styles/constants/box-shadow';
import { COLORS } from '../../../styles/constants/colors';
import GifIcon from '../../../assets/img/gif-icon.png';

export const Giphy: React.FC<any> = ({ onSendMessage, gifBtnClickable }) => {
  const gf = new GiphyFetch(config.giphyKey)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchGif, setSearchGif] = useState("");
  const [searchQuery, setsearchQuery] = useState("")
  const [modalGif, setModalGif] = useState<IGif | null>(null);
  const [width, setWidth] = useState(window.innerWidth);

  const CustomGrid = useMemo(() => ({ onGifClick, searchQuery, width }: any) => {
    const fetchGifs = (offset: number) => {
      if (searchQuery) {
        return gf.search(searchQuery, { offset, limit: 10 });
      } else {
        return gf.trending({ offset, limit: 10 });
      }
    }
    return (
      <>
        <Grid
          onGifClick={onGifClick}
          fetchGifs={fetchGifs}
          width={width}
          columns={3}
          gutter={8}
          hideAttribution={true}
          noResultsMessage={"No result found.."}
        />
        <ResizeObserver
          onResize={({ width }: any) => {
            setWidth(width);
          }}
        />
      </>
    );
  }, [searchQuery]) //eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (e: any) => {
    e.preventDefault();
    setSearchGif(e.target.value)
  }

  const SendGifHandler = (e: any) => {
    e.preventDefault();
    onSendMessage(MESSAGE_CONTENT.GIF, modalGif && modalGif.id)
    setIsModalVisible(false);
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setsearchQuery(searchGif)
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [searchGif]);

  return (
    <>
      {
        isModalVisible &&
        <ModalMask>
          <GifModal>
            <GifContent>
              <GifHeader>
                <GifHeaderFlex>
                  <div>
                    <GifText>GIPHY</GifText>
                  </div>
                  <SearchContainer>
                    <InputBox suffix={<SearchOutlined />} placeholder="Search" handleChange={(e) => onSearch(e)} />
                    <CloseGifBox>
                      <CloseChat onClick={() => setIsModalVisible(!isModalVisible ? true : false)} />
                    </CloseGifBox>
                  </SearchContainer>
                </GifHeaderFlex>
              </GifHeader>
              <GifBody>
                {searchQuery ? (<TextMargin>{`Search : ${searchQuery}`}</TextMargin>) : (<TextMargin>Trending GIFs</TextMargin>)}
                <GridContainer>
                  <CustomGrid
                    onGifClick={(gif: any, e: any) => {
                      e.preventDefault();
                      setModalGif(gif);
                    }}
                    searchQuery={searchQuery}
                    width={width}
                  />
                  {modalGif && (
                    <ModalGifContainer
                      onClick={(e) => {
                        e.preventDefault();
                        setModalGif(null);
                      }}
                    >
                      <Gif gif={modalGif} width={200} />
                      <PrimaryButton margin='10px' onClick={SendGifHandler}> Send</PrimaryButton>
                    </ModalGifContainer>
                  )}
                </GridContainer>
              </GifBody>
            </GifContent>
          </GifModal>
        </ModalMask>
      }

      <GifButton
        onClick={(e) => setIsModalVisible(!isModalVisible ? true : false)}
        isClicked={isModalVisible}
        gifBtnClick={gifBtnClickable}
      >
        <img src={GifIcon} alt="GIF" />
      </GifButton>
    </>
  );
};


const GifButton = styled.button<{ gifBtnClick: boolean, isClicked: boolean }>`
  cursor: pointer;
  ${({ isClicked }) => isClicked ? `background:${COLORS.QUICK_SILVER};` : `background:none;`}
  ${PADDING(2, 8)};
  border-radius: 6px;
  border: none;
  ${({ isClicked }) => isClicked ? `color: ${COLORS.WHITE};` : `color: ${COLORS.PHILLIPPINE_GRAY};`}
  ${({ gifBtnClick }) => gifBtnClick ? `z-index: 99999; margin: 0 14px 0 10px;` : `z-index: 1;`}
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 5, 0, 5)};
    height: 30px;
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_7}) {
    ${PADDING(0)};
    height: 28px;
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    ${({ gifBtnClick }) => gifBtnClick ? `z-index: 1; margin: 0 14px 0 10px;` : `z-index: 1;`}
  }

  >img{
    height: 18px;
    width:auto;
    filter:opacity(0.85);

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height:14px;
    }
  }
`
const ModalMask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;`

const CloseGifBox = styled.div`
  ${MARGIN(0, 8, 0, 14)};`

const GifModal = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding:0;
  color:${COLORS.WHITE};
  font-size: ${FONT[14]};
  line-height: 1.5715;
  pointer-events: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  max-width: 600px;
  margin: 0 auto;
  ${PADDING(0, 0, 0, 0)};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: 80%;
    transform: translateY(-80%);
  }
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    top: 85%;
    transform: translateY(-90%);
  }
`
const GifContent = styled.div`
  position: relative;
  background-color: ${COLORS.RAISIN_BLACK};
  background-clip: padding-box;
  border: 0;
  border-radius: 15px;
  -webkit-box-shadow: ${BOX_SHADOW[4]};
  box-shadow: ${BOX_SHADOW[4]};
  pointer-events: auto;
`
const GifHeader = styled.div`
  ${PADDING(10, 12)};
  background-color: ${COLORS.RAISIN_BLACK};
  border-bottom: 1px solid ${COLORS.ARSENIC};
  border-radius: 20px 20px 0 0;
`
const GifHeaderFlex = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
  `
const SearchContainer = styled.div`
  
  ${DISPLAY_FLEX('row', null, 'center')}

  > span {
    background: ${COLORS.ARSENIC} !important ;
    ${PADDING(6)}
    border-radius: 10px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width:220px;
  }

    input{
      color:${COLORS.WHITE}!important;
      background: none;
    }
  }
`
const GifText = styled.p`
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[18]};
`
const GifBody = styled.div`
  ${PADDING(15)};
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
  ${PADDING(10, 5, 10, 10)};
  }
`

const GridContainer = styled.div`
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 280px;
  }
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    height: 260px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.ARSENIC};
    border-radius: 10px;
    height: 30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.QUICK_SILVER};
  }
`
const TextMargin = styled.p`
  ${MARGIN(0, 0, 8, 0)};
`
const ModalGifContainer = styled.div`
    position: fixed;
    inset: 0px;
    ${DISPLAY_FLEX("column", "center", "center")};
    background: ${COLORS.DARK_TRANSPARENT};
    border-radius: 15px;
`
