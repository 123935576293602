import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Menu, Button, Tooltip } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { ToolTipsText } from '../../constants/tooltips';
import ConfigContext from '../../store/configContext';
import { SupportIcon } from '../svgicons';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  checkIfUserIsSuperAdmin,
  getRoleId,
} from '../../utils/userInfo';
import { makeClassNameForFeatureTour } from '../../utils/common';
import {
  OrganizerRoutes,
  RecruiterRoutes,
  CandidateRoutes,
} from '../../constants/routes';
import { getFilterByRoleId, getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS, FILTERS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { checkIfUserIsNew } from '../../service/candidate';

const { Item } = Menu;

export default () => {
  const { navigationList, setNewUser } = useContext(ConfigContext);
  const [activeState, setActiveState] = useState('0');
  const roleId = getRoleId();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(true);

  const handleNavigation = (nav) => {
    setActiveState(nav.key);

    if (nav.key === '/candidate/job-result/' && isDisabled) {
      history.push((prev) => prev);
    }
  };

  const selectListItem = (location) => {
    navigationList.forEach((navItem) => {
      const hash = get(location, 'pathname', '');

      const val = get(navItem, 'selectionPath', get(navItem, 'route', ''));
      const search = get(navItem, 'selectionSearch', val);
      if (hash.includes(search)) {
        setActiveState(val);
      }
    });
  };

  useEffect(() => {
    history.listen((location) => {
      selectListItem(location);
    });

    // eslint-disable-next-line
  }, [navigationList, history]);

  useEffect(() => {
    selectListItem(history.location);

    // eslint-disable-next-line
  }, [navigationList]);

  const handleCustomClick = (path) => {
    history.push(path);
  };

  const handleMenuItemClick = async (disabled, callOnDisabled, path) => {
    if (!disabled) {
      handleCustomClick(path);
    }

    if (disabled) {
      let isCandidateNew = true;
      if (checkIfUserIsCandidate(roleId)) {
        const resp = await checkIfUserIsNew();
        isCandidateNew = get(resp, 'isCandidateNew', true);
      }
      setNewUser(isCandidateNew);
      if (isCandidateNew) {
        if (callOnDisabled) callOnDisabled();
        setIsDisabled(disabled);
      } else {
        setIsDisabled(isCandidateNew);
        handleCustomClick(path);
      }
    }
  };

  return (
    <StyledContainer id="sidebar">
      <StyledInnerContainer
        roleId={roleId}
        isAdmin={checkIfUserIsMasterAdmin(roleId)}
      >
        <Menu
          theme="light"
          onClick={handleNavigation}
          selectedKeys={[activeState]}
          mode="inline"
          inlineIndent={20}
        >
          {navigationList.map((menuItem, index) => {
            const {
              Icon,
              title,
              route,
              populate = true,
              routeToRedirect,
              disabled = false,
              callOnDisabled,
              selectionPath,
            } = menuItem;

            if (!populate) {
              return null;
            }

            return (
              <Item
                className={makeClassNameForFeatureTour(title)}
                key={selectionPath || route}
                title={title}
                onClick={() => {
                  handleMenuItemClick(
                    disabled,
                    callOnDisabled,
                    routeToRedirect || route,
                  );
                }}
              >
                <img src={Icon} alt="Icon" />
                <Link id={`side-menu-item-${index}`}>
                  {title === 'Job Results' && checkIfUserIsCandidate(roleId) ? (
                    <Tooltip
                      placement="topRight"
                      title={ToolTipsText.jobOpportunities}
                    >
                      {title}
                    </Tooltip>
                  ) : title === 'My Calendar' &&
                    checkIfUserIsRecruiter(roleId) ? (
                    <Tooltip
                      placement="topRight"
                      title={ToolTipsText.recruiterCalendar}
                    >
                      {title}
                    </Tooltip>
                  ) : (
                    title
                  )}
                </Link>
              </Item>
            );
          })}
        </Menu>

        {!checkIfUserIsMasterAdmin(roleId) &&
          !checkIfUserIsSuperAdmin(roleId) ? (
          <StyledBottomContainer>
            <StyledHelpLink
              to={
                checkIfUserIsOrganizer(roleId)
                  ? OrganizerRoutes.helpDoc
                  : checkIfUserIsRecruiter(roleId)
                    ? RecruiterRoutes.helpDoc
                    : CandidateRoutes.helpDoc
              }
            >
              Help Doc
            </StyledHelpLink>
            <StyledButton type="primary" color={getUserColorWithRoleId(roleId)}>
              <StyledSupportLink
                to={
                  checkIfUserIsOrganizer(roleId)
                    ? OrganizerRoutes.support
                    : checkIfUserIsRecruiter(roleId)
                      ? RecruiterRoutes.support
                      : CandidateRoutes.support
                }
              >
                <StyledIconWrapper>
                  <SupportIcon />
                </StyledIconWrapper>
                Support
              </StyledSupportLink>
            </StyledButton>
          </StyledBottomContainer>
        ) : null}
      </StyledInnerContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: ${COLORS.WHITE};
  max-width: 312px;
  width: 19%;
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: none !important;
  }

  @media (min-width: 1300px) and (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 18%;
  }
`;

const StyledInnerContainer = styled.div`
  position: fixed;
  max-width: 312px;
  width: 19%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 112px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 1300px) and (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 18%;
  }

  .ant-menu-inline {
    border-right: 0;
  }

  ul {
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      margin-top: 10px;
    }

    li {
      background-color: ${COLORS.WHITE} !important;
      height: 38px !important;
      line-height: 50px !important;
      vertical-align: middle;
      margin-top: ${({ isAdmin }) => (isAdmin ? '15px' : '21px')} !important;
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        margin-bottom: ${({ isAdmin }) =>
    isAdmin ? '22px' : '30px'} !important;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 0;
        position: relative;
        fill: ${COLORS.ARGENT};
        filter: ${FILTERS.DARK_GRAY};

        @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
          width: 26px;
          height: 26px;
          margin-right: 5px;
        }
      }

      a {
        color: ${COLORS.ARGENT};
        margin-left: 8px;
        cursor: pointer;
        font-size: ${FONT[14]};

        @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
          font-size: ${FONT[16]};
          margin-left: 18px;
        }
      }

      span {
        color: ${COLORS.ARGENT};
        cursor: pointer;
        font-size: ${FONT[14]};

        @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
          font-size: ${FONT[16]};
        }
      }
    }

    li:hover,
    li.ant-menu-item-selected {
      img {
        filter: ${(props) => getFilterByRoleId(props.roleId)} !important;
      }

      a , a> span {
        color: ${(props) => getUserColorWithRoleId(props.roleId)} !important;
      }

      &:after {
        border-right: 7px solid
          ${(props) => getUserColorWithRoleId(props.roleId)};
      }
    }
  }
`;

const StyledBottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  background: ${COLORS.WHITE};
  padding-top: 10px;
  margin-top: 58%;
  max-width: 312px;
  width: 19%;

  @media (min-width: 1300px) and (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 18%;
  }
`;

const StyledHelpLink = styled(Link)`
  color: ${COLORS.PHILLIPPINE_GRAY} !important;
  margin-bottom: 20px;
  padding-left: 19px;
  display: block;
  text-align: left;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${({ color }) => color};
  width: 100%;
  display: flex;
  align-items: center;
  height: 45px;
  border: 0;
  text-align: left;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ color }) => color};
  }
`;

const StyledSupportLink = styled(Link)`
  ${DISPLAY_FLEX('row', 'center', 'center')};
  margin-left: -12px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledIconWrapper = styled.span`
  text-align: left;
  padding-left: 14px;
  margin-right: 10px;
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  svg {
    fill: ${COLORS.WHITE};
    line-height: 20px;
    position: relative;
    top: 4px;
    width: 21px;
    height: 21px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 30px;
      height: 30px;
    }
  }
`;
