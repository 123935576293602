/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { Col, Row, Tooltip } from 'antd';
import get from 'lodash/get';
import max from 'lodash/max';
import {
  BriefCaseIcon,
  GraduationCapIcon,
  MoneyIcon,
  StopWatch,
  ShareIcon,
} from '../../svgicons';
import { COLORS } from '../../../styles/constants/colors';
import { MainButton } from '../../shared-components/Button';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  DISPLAY_FLEX,
  getTextOverflowStyle,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { SocialShareIcons } from '../Share/SocialShareIcons';
import { ShareOverlay } from '../Share/ShareOverlay';
import { JobCardProps } from '../../../interfaces/components/Community/JobCard';
import { getFormattedCurrency } from '../../../utils/currency';
import { LOCALES } from '../../../constants/currencyLocale';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

const Job: React.FC<JobCardProps> = ({
  jobInfo,
  handleApplyClick,
  isMockCard = false,
  onViewDetails,
}) => {
  const { id, company, image, title, location } = jobInfo;

  const handleClickTooltip = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const getJobType = (type: string[]) => {
    return max(type) === '0' ? 'Part-time' : 'Full-time';
  };

  const viewClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onViewDetails();
  };

  // Formatting the currency
  const amount = get(jobInfo, 'salaryTo');
  const jobCurrency = get(jobInfo, 'currency');
  const locale = LOCALES[jobCurrency];
  const currencyCode = get(jobInfo, 'currencyCode');
  const currencySymbol = get(jobInfo, 'currencySymbol');
  const formattedSalary = getFormattedCurrency(
    currencyCode,
    currencySymbol,
    amount,
    locale,
  );

  return (
    <StyledMainContainer isMockCard={isMockCard} onClick={viewClick}>
      <StyledUpperContent>
        <StyledTopContainer>
          <StyledCardImage src={image} alt="company" />

          <StyledCardInfoContainer>
            <StyledHeaderContainer>
              <StyledHeadingAndShareContainer>
                <Tooltip title={!isMockCard ? `${company}` : ''}>
                  <StyledHeading onClick={handleClickTooltip}>
                    {company}
                  </StyledHeading>
                </Tooltip>
                <StyledSharingIcon>
                  <ShareOverlay ShareIcons={<SocialShareIcons jobId={id} />}>
                    <ShareIcon />
                  </ShareOverlay>
                </StyledSharingIcon>
              </StyledHeadingAndShareContainer>
              <StyledSubHeading>{location}</StyledSubHeading>
            </StyledHeaderContainer>
          </StyledCardInfoContainer>

          <StyledAnimationContainer>
            <Tooltip
              title={
                !isMockCard
                  ? 'All applications are reviewed within 72 hrs and a response is provided with detailed analytics. Shortlisted or not, you are never ghosted!'
                  : ''
              }
              trigger={['click', 'hover']}
            >
              <div onClick={handleClickTooltip}>
                <StopWatch />
              </div>
            </Tooltip>
          </StyledAnimationContainer>
        </StyledTopContainer>
        <StyledBottomDescriptionContainer>
          {title}
        </StyledBottomDescriptionContainer>
        <StyledStats>
          <Col xs={12}>
            <MoneyIcon />
            <p>
              {get(jobInfo, 'salaryTo')
                ? `${formattedSalary}`
                : `Not Specified`}
            </p>
          </Col>

          <StyledCol color={COLORS.BLUE_MARGUERITE} xs={12}>
            <BriefCaseIcon />
            <p>{getJobType(get(jobInfo, 'jobType', []))}</p>
          </StyledCol>

          <Col xs={12}>
            <GraduationCapIcon />
            <p> {get(jobInfo, 'minQualification')}</p>
          </Col>
        </StyledStats>
      </StyledUpperContent>
      <StyledBottomContainer>
        <StyledButton
          onClick={!isMockCard ? handleApplyClick : () => {}}
          disabled={isMockCard}
        >
          Apply
        </StyledButton>
        <p>View details</p>
      </StyledBottomContainer>
    </StyledMainContainer>
  );
};

export default Job;

const StyledMainContainer = styled.div<{ isMockCard: boolean }>`
  box-shadow: 0px 0px 5px #0606064d;
  ${DISPLAY_FLEX('column', 'space-between')};
  background-color: ${COLORS.CHARLESTON_GREEN_2};
  border-radius: 7px;
  position: relative;
  cursor: pointer;
`;

const StyledTopContainer = styled.div`
  display: flex;
  column-gap: 18px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    column-gap: 23px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    column-gap: 21px;
    ${MARGIN(null, null, 0, null)}
  }

  @media (max-width: 570px) {
    column-gap: 16px;
    ${MARGIN(null, null, 0, null)}
  }
`;

const StyledCardImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 38px;
    height: 38px;
  }
`;

const StyledCardInfoContainer = styled.div`
  width: 55%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(null, null, 2, null)}
  }

  @media (max-width: 570px) {
    ${MARGIN(null, null, 8, null)}
  }
`;

const StyledHeaderContainer = styled.div`
  ${MARGIN(null, null, 10, null)}

  @media (max-width: 570px) {
    ${MARGIN(null, null, 3, null)}
  }
`;

const StyledHeadingAndShareContainer = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  ${MARGIN(null, null, 4, null)}
`;

const StyledHeading = styled.h3`
  font-size: 15px;
  font-weight: 600;
  line-height: 0.56;
  color: ${COLORS.WHITE};
  white-space: nowrap;
  ${getTextOverflowStyle(1)};
  display: inline-block;
  max-width: 120px;
  ${PADDING(5, 0)}
  ${MARGIN(null, 12, 0, null)}


  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 18px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
  }
  @media (min-width: 987px) and (max-width: 1200px) {
    white-space: nowrap;
    max-width: fit-content;
  }

  @media (max-width: 570px) {
    font-size: 12px;
    padding: 4px 0;
    ${MARGIN(null, 7, null, null)}
    max-width: 100px;
  }
`;

const StyledSharingIcon = styled.div`
  display: inline-block;
  line-height: 0;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    fill: ${COLORS.WHITE};
  }

  @media (max-width: 570px) {
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;
const StyledSubHeading = styled.h4`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: ${COLORS.WHITE};
  ${getTextOverflowStyle(1)};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 13px;
  }

  @media (max-width: 570px) {
    font-size: 11px;
    ${MARGIN(null, null, 2, null)}
  }
`;

const StyledAnimationContainer = styled.div`
  svg {
    width: 27px;
    height: 27px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 33px;
      height: 33px;
    }

    @media (max-width: 570px) {
      width: 22px;
      height: 22px;
    }

    animation: shake 0.4s;
    animation-iteration-count: infinite;

    @keyframes shake {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(1px, 1px) rotate(4deg);
      }
      20% {
        transform: translate(1px, 1px) rotate(6deg);
      }
      30% {
        transform: translate(1px, 1px) rotate(4deg);
      }
      40% {
        transform: translate(1px, 1px) rotate(2deg);
      }
      50% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      60% {
        transform: translate(1px, 1px) rotate(-4deg);
      }
      70% {
        transform: translate(1px, 1px) rotate(-6deg);
      }
      80% {
        transform: translate(1px, 1px) rotate(-4deg);
      }
      90% {
        transform: translate(1px, 1px) rotate(-1deg);
      }
      100% {
        transform: translate(1px, 1px) rotate(0deg);
      }
    }

    &:hover {
      animation: none;
    }
  }
`;

const StyledBottomContainer = styled.div`
  ${DISPLAY_FLEX('row-reverse', 'space-between', 'center')}
  ${PADDING(12, 16)}
  background-color: #343434;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  p {
    font-family: 'Poppins';
    color: ${COLORS.WHITE};
    cursor: pointer;
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.REGULAR};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 12)}
  }

  @media (max-width: 570px) {
    ${PADDING(9, 10)}
  }
`;

const StyledBottomDescriptionContainer = styled.h3`
  ${getTextOverflowStyle(1)};
  font-size: ${FONT[16]};
  color: ${COLORS.WHITE};
  ${MARGIN(5, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[17]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    ${MARGIN(0, 0, 8, 0)}
  }

  @media (max-width: 570px) {
    font-size: ${FONT[12]};
    ${PADDING(4, 0)}
    ${MARGIN(8, 0, 0, 0)}
  }
`;

const StyledUpperContent = styled.div`
  ${PADDING(16, 20, 0, 20)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 12, 0, 12)}
  }

  @media (max-width: 570px) {
    ${PADDING(9, 10, 0, 10)}
  }
`;

const StyledStats = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 15px !important;
  ${MARGIN(null, null, 20, null)}
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    row-gap: 10px !important;
    ${MARGIN(null, null, 15, null)}
  }

  @media (max-width: 570px) {
    row-gap: 8px !important;
    ${MARGIN(null, null, 11, null)}
  }

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        width: 15px;
        height: 15px;
      }

      @media (max-width: 570px) {
        width: 14px;
        height: 14px;
      }
    }

    p {
      font-size: ${FONT[14]};
      color: ${COLORS.WHITE};
      ${MARGIN(null, null, 0, null)}
      font-weight: ${FONT_WEIGHTS.MEDIUM};

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: ${FONT[12]};
      }

      @media (max-width: 570px) {
        padding: 4px 0;
        ${MARGIN(null, 7, null, null)}
      }
    }
  }
`;

const StyledCol = styled(Col)<{ color: String }>`
  svg {
    ${({ color }) => color && `fill: ${color}`}
  }
`;

const StyledButton = styled(MainButton)`
  width: 74px;
  height: 27px;
  min-width: unset;
  font-size: ${FONT[12]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[11]};
  }

  @media (max-width: 570px) {
    font-size: ${FONT[10]};
  }
`;
