import { ApolloClient, InMemoryCache } from '@apollo/client';
import config from '../config';

function createApolloClient(addAuthHeader = true) {
  if (addAuthHeader) {
    return new ApolloClient({
      uri: config.graphQLUri,
      cache: new InMemoryCache(),
      headers: {
        "Authorization": `apikey ${config.graphQLKey}`
      }
    });
  }

  return new ApolloClient({
    uri: config.graphQLUri,
    cache: new InMemoryCache()
  })
}

const authClient = createApolloClient();
const unAuthClient = createApolloClient(false);

export {
  authClient,
  unAuthClient
};
