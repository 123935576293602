import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Button, Spin } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  userProfileInitialValues,
  userRepProfilePayload,
  userRepProfileResetFields,
} from '../../utils/profile';
import { updateProfileData } from '../../store/features/profile';
import Notification from '../Notification/Notification';
import { Email, FormGroup, TextBox } from './Form/Form';
import MandatoryStar from '../Common/mandatoryStar';
import CountryCodeInput from '../Common/CountryCodeInput';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import ModalButtonContainer from '../profile/ModalButtonContainer';
import {
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../utils/userInfo';
import { StyledHeading } from './Texts';
import { getUserColorWithRoleId } from '../../styles/utils';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import {
  updateOrganizerProfileAdmin,
  updateRecruiterProfileAdmin,
} from '../../service/masterAdmin';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import {
  RepresentativeModalFormProps,
  RepresentativeModalProps,
} from '../../interfaces/components/RepresentativeModal';
import { getSize } from '../../utils/size';
import { OrganizerRoutes, RecruiterRoutes } from '../../constants/routes';
import { Modal } from './Modal';
import { getThemeByRoleId } from '../../utils/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { checkIfUserExist } from '../../service/auth';

const RepresentativeModal: React.FC<RepresentativeModalProps> = ({
  visible,
  handleClose,
  profileData,
  companyData,
  profilePic,
  setProfilePic,
  userRole,
}) => {
  const role = getRoleId();
  const dispatch = useDispatch();
  const initialValues = userProfileInitialValues(profileData, true);
  const [form] = Form.useForm();
  const history = useHistory();
  const [isPasswordSetOrNot, setIsPasswordSetOrNot] = useState(true);
  const [isLoding, setIsLoading] = useState(true);

  const handleModalCancel = () => {
    form.resetFields();
    handleClose();
  };

  const handleResetFields = () => {
    userRepProfileResetFields(form);
  };

  const successNotification = () => {
    Notification('success', 'Representative Information updated');
  };

  const handleFinish = async (data: RepresentativeModalFormProps) => {
    const body = userRepProfilePayload(
      userRole,
      data,
      profileData,
      companyData,
    );

    if (!checkIfUserIsMasterAdmin(role)) {
      await dispatch(updateProfileData(body));
      successNotification();
    } else if (checkIfUserIsOrganizer(userRole)) {
      updateOrganizerProfileAdmin(companyData.id, body).then(() => {
        successNotification();
      });
    } else {
      updateRecruiterProfileAdmin(companyData.id, body).then(() => {
        successNotification();
      });
    }
    handleClose();
  };

  const handleResetPassword = async () => {
    history.push(
      checkIfUserIsOrganizer(role)
        ? OrganizerRoutes.changePassword
        : RecruiterRoutes.changePassword,
    );
  };

  useEffect(() => {
    if (profileData.email) {
      checkIfUserExist(profileData.email, false, role).then((response) => {
        // @ts-ignore
        const { isPasswordSetOrNot } = response;
        setIsPasswordSetOrNot(isPasswordSetOrNot);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [profileData.email]);

  return (
    <Modal
      visible={visible}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      title="Edit Representative & Team Profile"
      theme={getThemeByRoleId(role)}
    >
      <FormGroup
        form={form}
        name="OrganizerRepresentativeModal"
        onFinish={handleFinish}
        expand
        initialValues={initialValues}
      >
        <div>
          <TitleContainer>
            <ModalTitle color={getUserColorWithRoleId(role)}>
              Basic Information
            </ModalTitle>
            {!checkIfUserIsMasterAdmin(role) ? (
              <PasswordButton>
                <Button
                  onClick={handleResetPassword}
                  className="change-password__button"
                >
                  {isLoding ? (
                    <Spin />
                  ) : (
                    `${isPasswordSetOrNot ? 'Change' : 'Create'} Password`
                  )}
                </Button>
              </PasswordButton>
            ) : null}
          </TitleContainer>

          <Row gutter={[25, 24]}>
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 18, order: 1 }}>
              <Row gutter={[18, 4]}>
                <Col xs={24} lg={8}>
                  <InputTitle>
                    Full Name <MandatoryStar />
                  </InputTitle>
                  <TextBox
                    name="fullName"
                    placeholder="Full Name"
                    maxLength={100}
                    rule="text"
                  />
                </Col>
                <Col xs={24} lg={8}>
                  <InputTitle>
                    Phone Number
                    {!checkIfUserIsRecruiter(userRole) ? (
                      <MandatoryStar />
                    ) : null}
                  </InputTitle>
                  <Row>
                    <Col xs={24} lg={10}>
                      <CountryCodeInput
                        name="phoneNumberPrefix"
                        required={!checkIfUserIsRecruiter(userRole)}
                      />
                    </Col>
                    <Col xs={24} lg={14}>
                      <TextBox
                        name="phoneNumber"
                        placeholder="Phone Number"
                        maxLength={12}
                        rule="phoneNumber"
                        required={!checkIfUserIsRecruiter(userRole)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={8}>
                  <InputTitle>
                    Email Address <MandatoryStar />
                  </InputTitle>
                  <Email
                    name="email"
                    placeholder="Email"
                    disabled={!checkIfUserIsMasterAdmin(role)}
                  />
                </Col>
              </Row>
            </Col>
            <UploadProfilePicComponent
              avatarSize={getSize(120, 100, 80)}
              option
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              removeOption
              roleId={role}
              userId={profileData.userId}
              // @ts-ignore
              userRole={userRole}
              alt="organizer-logo"
            />
          </Row>
        </div>
        <ModalButtonContainer handleResetFields={handleResetFields} />
      </FormGroup>
    </Modal>
  );
};

export default RepresentativeModal;

export const InputTitle = styled(StyledHeading)`
  ${MARGIN(0, 0, 10, 0)};
`;

export const ModalTitle = styled(StyledHeading)`
  ${MARGIN(10, 0)}

  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${(props) => props.color};
`;

export const TitleContainer = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}

  flex-wrap: wrap;
`;

export const PasswordButton = styled.div`
  ${MARGIN(0, 0, 30, 0)};

  button {
    border: none;
    background-color: ${COLORS.WHITE_SMOKE};
    width: 275px;
    height: 39px;

    span {
      font-size: ${FONT[14]};
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      color: ${COLORS.DAVYS_GREY};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: max-content;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 10, 0)};
  }
`;
