import React, { useState, useEffect, createContext } from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  getRoleId,
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { getCalendarEventsData } from '../../store/features/calendar';
import { CalendarEventsStore } from '../../utils/event';
import CalendarCustomHeader from './CalendarCustomHeader';
import StyledCalendar from './StyledCalendar';
import {
  SmallFont,
  StyledLargeHeading,
  StyledMediumFont,
} from '../shared-components/Texts';
import { getUserColorWithRoleId } from '../../styles/utils';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import CalendarInterview from '../shared-components/CalendarDetails';
import CalendarBlockItem from './CalendarBlockItem';
import {
  arrangeOrderCalendarData,
  filterCalendarData,
  getEventBagdeColor,
} from '../../utils/calendar';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { useTagManager } from '../../utils/customHooks';
// import CalendarSlotModal from './CalendarSlotModal';

export const CalendarContext = createContext(null);

const Calendar = ({
  searchText = '',
  openCreateSlot = false,
  eventId = null,
}) => {
  // const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const roleId = getRoleId();
  const [panelChange, setPanelChange] = useState(false);
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(null);
  const updateUserActionInGTM = useTagManager();

  // const handleCancel = () => {
  //   setVisible(false);
  // };
  // const [selectedListData, setSelectedList] = useState({});
  const [selectedDate, setSelectedDate] = useState({});

  const text = searchText;
  const { calendarEvents, interviews } = CalendarEventsStore();
  const [currentListItems, setCurrentListItems] = React.useState([]);
  const [eventState, setEventState] = useState({});

  const filterCalendarEventsByDate = (value) => {
    const list =
      calendarEvents && calendarEvents.length > 0
        ? calendarEvents.filter((item) => {
            const dateTime = get(item, 'dateTime', null);
            const endDateTime = get(item, 'endDateTime', null);
            if (!dateTime) {
              return false;
            }
            const date = moment.unix(dateTime);
            const endDate = moment.unix(endDateTime);
            if (
              value
                .startOf('day')
                .isBetween(
                  date.startOf('day'),
                  endDate.endOf('day'),
                  null,
                  '[]',
                ) &&
              item.name.toLowerCase().includes(text)
            ) {
              return true;
            }
            return false;
          })
        : [];

    return list || [];
  };

  /* const openModal = (date) => {
    // const list = filterCalendarEventsByDate(date);
    setVisible(true);
    // setSelectedList(list);
    setSelectedDate(date);
  };
*/
  useEffect(() => {
    if (selectedMonthAndYear) {
      const startTimestamp = selectedMonthAndYear
        ?.clone()
        ?.startOf('month')
        ?.unix();
      const endTimestamp = selectedMonthAndYear
        ?.clone()
        ?.endOf('month')
        ?.unix();
      dispatch(getCalendarEventsData(roleId, startTimestamp, endTimestamp));
    }
    // eslint-disable-next-line
  }, [selectedMonthAndYear]);

  useEffect(() => {
    if (panelChange) {
      setPanelChange(!panelChange);
      //     setVisible(false);
    }
  }, [panelChange]);

  useEffect(() => {
    updateUserActionInGTM('calendar_opened');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterCalendarInterviewsByDate = (value) => {
    let interviewsList = [];
    if (interviews && interviews.length > 0) {
      interviewsList = interviews
        .filter((item) => {
          const dateTime = get(item, 'slot.startTime', null);
          if (!dateTime) {
            return false;
          }
          const date = moment.unix(dateTime);
          const candidate = !checkIfUserIsCandidate(roleId)
            ? item.candidate
              ? item.candidate.name.toLowerCase().includes(text)
              : false
            : item.company
            ? item.company.name.toLowerCase().includes(text)
            : false;
          if (date.startOf('day').isSame(value.startOf('day')) && candidate) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const data = {};
          data.id = get(item, 'id', null);
          data.name = !checkIfUserIsCandidate(roleId)
            ? get(item, 'candidate.name', '')
            : get(item, 'company.name', '');
          data.dateTime = get(item, 'slot.startTime', null);
          data.endDateTime = get(item, 'slot.endTime', null);
          data.isInterview = true;
          data.job = get(item, 'job.designation', '');
          return data;
        });
    }

    return interviewsList || [];
  };

  /*useEffect(() => {
    if (openCreateSlot) {
      setVisible(true);
    }
  }, [openCreateSlot]);
*/
  const handleHover = (listItems, date) => {
    setSelectedDate(date);

    const result = arrangeOrderCalendarData(listItems);

    // Modify the list here to show in calendar hover
    setCurrentListItems(result);
  };

  const handleModalClose = () => {
    // Reset
    setSelectedDate({});
    setCurrentListItems([]);
  };

  const handlePanelChange = () => {
    setPanelChange(true);
  };

  const dateCellRender = (value) => {
    const interviewsList = filterCalendarInterviewsByDate(value);
    const listData = filterCalendarEventsByDate(value).concat(interviewsList);
    return (
      <div
        onMouseEnter={() => handleHover(listData, value)}
        onMouseLeave={handleModalClose}
      >
        <CalendarInterview
          interviews={interviewsList}
          currentListItems={currentListItems}
          selectedDate={selectedDate}
          roleId={roleId}
          getEventBagdeColor={getEventBagdeColor}
        >
          <ul>
            {filterCalendarData(listData, eventState).map((item) => {
              let color = COLORS.METALLIC_SILVER;
              const momentTime = moment(value);
              const currentMoment = moment();

              if (momentTime.isBefore(currentMoment)) {
                if (momentTime.isSame(currentMoment, 'day')) {
                  color = item.isInterview
                    ? getUserColorWithRoleId(roleId)
                    : getEventBagdeColor(value, item, roleId);
                }
              } else {
                color = item.isInterview
                  ? getUserColorWithRoleId(roleId)
                  : getEventBagdeColor(value, item, roleId);
              }

              return <CalendarBlockItem color={color} item={item} />;
            })}
            <SmallFont margin="3px 0" color={COLORS.ARGENT}>
              {listData.length > 1 ? 'View all details' : null}
            </SmallFont>
          </ul>
        </CalendarInterview>
      </div>
    );
  };

  function RenderHeader(props) {
    const { value } = props;
    if (value) {
      setSelectedMonthAndYear(value);
    }
    return <CalendarCustomHeader {...props} />;
  }

  return (
    <CalendarContext.Provider value={setEventState}>
      <StyledTitle color={getUserColorWithRoleId(roleId)}>
        Event Calendar
      </StyledTitle>
      {checkIfUserIsRecruiter(roleId) ? (
        <Row gutter={16}>
          <Col span={24}>
            <StyledDisclaimerContainer>
              <StyledDisclaimer>
                It is mandatory to pick slots for potential first level
                conversations/assessments/interviews to publish your jobs in the
                event. The intended purpose is to ensure applicants commit time
                for an assessment.
              </StyledDisclaimer>

              <StyledDisclaimer>
                Suggested days for slot selection are typically 3-5 days from
                after the event end date to allow time for candidate responses.
                These slots can be changed or interviews scheduled outside of
                this calendar directly with the applicants.
              </StyledDisclaimer>
            </StyledDisclaimerContainer>
          </Col>
        </Row>
      ) : null}
      <StyledCalendar
        dateCellRender={dateCellRender}
        CalendarCustomHeader={RenderHeader}
        handlePanelChange={handlePanelChange}
      />

      {/*
      checkIfUserIsRecruiter(roleId) && visible && !panelChange ? (
        <CalendarSlotModal
          visible={visible}
          handleCancel={handleCancel}
          // eventList={selectedListData}
          selectedDate={selectedDate}
          eventId={eventId}
        />
      ) : null
    */}
    </CalendarContext.Provider>
  );
};

export default Calendar;

const StyledTitle = styled(StyledLargeHeading)`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${(props) => props.color};
  ${PADDING(0, 5)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 5)}
  }
`;

const StyledDisclaimerContainer = styled.div`
  text-align: left;
  ${PADDING(10, 6)}
`;

const StyledDisclaimer = styled(StyledMediumFont)`
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${MARGIN(0, 0, 10, 0)}
`;
