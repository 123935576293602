import React from 'react';
import MapAllLinks from './index';
import { RecruiterRoutes } from '../../../constants/routes';

const ResumeBreadCrumbs = ({ jobId, appId, eventId }) => {
  const links = [
    {
      name: 'Recruit',
      url: `${RecruiterRoutes.recruit}1?event=${eventId}&jobs=[]`,
    },
    {
      name: 'Resume',
      url: `${RecruiterRoutes.recruiter}resume/${jobId}/${appId}/${eventId}`,
    },
  ];
  return (
    <>
      <MapAllLinks links={links} />
    </>
  );
};

export default ResumeBreadCrumbs;
