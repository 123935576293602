import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import CompanyHeader from './CompanyHeader';
import Footer from '../../components/Footer';
import CompanyContent from './CompanyContent';
import MainContainer from '../../components/Common/MainContainer';
import Header from '../../components/Web3Header';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';
import { checkIfUserIsCandidate, UserInformation } from '../../utils/userInfo';
import Topbar from '../../components/TopBar/TopBar';
import {
  injectedCommonEndpoints,
  useGetCommunityPageQuery,
} from '../../store/features/rtkApi/common';
import { checkEventBookingInfo } from '../../utils/event';
import getNow from '../../utils/getNow';
import { bookEvent } from '../../service/event';
import { changeRefetchStatus } from '../../store/features/jobDetails';

const handleBookEvent = async (eventId, dispatch) => {
  const res = await checkEventBookingInfo(eventId);
  if (res.status === 200) {
    const isEventBooked = get(res, 'data.payload.isEventBooked', false);
    if (!isEventBooked) {
      const freeEventBody = {
        eventId,
        paymentDate: getNow(),
      };
      await bookEvent(freeEventBody);
      // The refetchStatus had to change because otherwise the data will not be matched with the overview tab's jobs and it will show blank for the first time.
      dispatch(changeRefetchStatus({ flag: true }));

      // this is for automated job fetching after the event gets booked
      dispatch(
        injectedCommonEndpoints.endpoints.getCommunityJobsWithAuth.initiate({
          eventId,
          page: 1,
          limit: 10,
          forceRefetch: true,
        }),
      );
    }
  }
};

const CommunityPage = (props) => {
  const { isAuthenticated, user } = UserInformation();
  const companyId = get(props, 'match.params.companyId');
  const {
    data: communityPageData,
    isSuccess,
    isError,
  } = useGetCommunityPageQuery(companyId);
  const roleId = get(user, 'roleId');
  const modalRef = useRef();
  const dispatch = useDispatch();

  // this useEffect is responsible for checking if the event is booked or not and if not booked then book the event and fetch the jobs for that event
  useEffect(() => {
    if (isAuthenticated && isSuccess && checkIfUserIsCandidate(roleId)) {
      const eventId = get(communityPageData, 'communityEvent.id');
      handleBookEvent(eventId, dispatch);
    }
  }, [isAuthenticated, isSuccess, communityPageData, roleId, dispatch]);

  // if community page does not exist then redirect to 404 page
  if (isError) {
    return <Redirect to="/404" />;
  }

  return (
    <StyledMain>
      {isAuthenticated ? (
        <Topbar noRedirect />
      ) : (
        <Header modalRef={modalRef} isCommunityPage />
      )}
      <StyledSection id="community-page">
        <MainContainer>
          {/* header */}
          <CompanyHeader />
          <CompanyContent modalRef={modalRef} />
          {/* footer */}
        </MainContainer>
      </StyledSection>
      <Footer />
    </StyledMain>
  );
};

export default CommunityPage;

const StyledSection = styled.section`
  background-color: ${COLORS.ANTI_FLASH_WHITE};
  flex-grow: 1;
  & > div {
    ${PADDING(0)}

    @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
      ${PADDING(0, 10)}
    }
  }
`;
const StyledMain = styled.main`
  min-height: 100vh;
  ${DISPLAY_FLEX('column')}
`;
