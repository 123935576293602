import React from 'react';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import {
  DISCORD_TEXT,
  FACEBOOK_TEXT,
  INSTAGRAM_TEXT,
  LINKEDIN_TEXT,
  PINTEREST_TEXT,
  TWITTER_TEXT,
  YOUTUBE_TEXT,
} from '../../constants';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
  YoutubeIcon,
  PinterestIcon,
  DiscordIcon,
  GlobeIcon,
} from '../svgicons';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';

const ProfileLinks = ({ profileLinks, string = false }) => {
  const socialMediaLists = [
    {
      name: FACEBOOK_TEXT,
      value: FacebookIcon,
    },
    {
      name: TWITTER_TEXT,
      value: TwitterIcon,
    },
    {
      name: LINKEDIN_TEXT,
      value: LinkedinIcon,
    },
    {
      name: INSTAGRAM_TEXT,
      value: InstagramIcon,
    },
    {
      name: PINTEREST_TEXT,
      value: PinterestIcon,
    },
    {
      name: YOUTUBE_TEXT,
      value: YoutubeIcon,
    },
    {
      name: DISCORD_TEXT,
      value: DiscordIcon,
    },
  ];

  const findSocialMediaIcon = (name) => {
    const result = socialMediaLists.find((el) => name.includes(el.name));
    return result ? result.value : null;
  };

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <StyledProfileLinksContainer>
      {profileLinks.map((link, idx) => {
        const linkText = string ? link : link.link;
        const Icon = findSocialMediaIcon(linkText);
        return (
          <StyledProfilLinksButton key={idx.toString()}>
            <Tooltip title={linkText}>
              <StyledButton onClick={() => handleClick(linkText)}>
                {Icon ? <Icon /> : <GlobeIcon />}
              </StyledButton>
            </Tooltip>
          </StyledProfilLinksButton>
        );
      })}
    </StyledProfileLinksContainer>
  );
};

export default ProfileLinks;

const StyledProfileLinksContainer = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', null)}
  width: 100%;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: 8px;
  }
`;

const StyledProfilLinksButton = styled.div`
  margin-right: 1rem;
`;

const StyledButton = styled(Button)`
  width: 4rem;
  height: 4rem;
  border-radius: 1.2rem;
  background-color: ${COLORS.CULTURED};
  border: none;
  color: ${COLORS.ARGENT};
  padding: 0;
  ${DISPLAY_FLEX('row', 'center', 'center')};

  svg {
    filter: opacity(0.7);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${COLORS.CULTURED};
    color: ${COLORS.BLACK};
    svg {
      color: ${COLORS.BLACK};
      filter: opacity(1);
    }
  }
`;
