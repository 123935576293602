import { first } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { JobTabProps, JobTabsProps } from '../../interfaces/components/Tabs';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { LINEBREAK } from '../../styles/constants/linebreak';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { transformHtmlData } from '../../utils/general';

const JobTab = ({ data }: JobTabProps) => {
  // eslint-disable-next-line no-useless-escape
  const descriptionWithLineBreak = data.replace(/<[\/]?p>/g, `<span style="display:block;margin:${LINEBREAK.MARGIN_VERTICAL} ${LINEBREAK.MARGIN_HORIZONTAL};" />`)
  return (
    <StyledJobDescription>
      <p
        dangerouslySetInnerHTML={{
          __html: descriptionWithLineBreak
        }}
      />
    </StyledJobDescription>
  );
};

const JobTabs = ({ jobDetails, theme }: JobTabsProps) => {
  const [buttonKey, setButtonKey] = useState(0);

  const jobBrief = transformHtmlData(jobDetails, 'jobBrief');
  const fiveReasonsToJoin = transformHtmlData(
    jobDetails,
    'meta.fiveReasonsToJoin',
  );
  const interviewProcess = transformHtmlData(
    jobDetails,
    'meta.interviewProcess',
  );
  const first90Days = transformHtmlData(jobDetails, 'meta.first90Days');

  const items = [
    {
      key: 0,
      name: 'Job Description',
      component: <JobTab data={jobBrief} />,
    },
    ...(fiveReasonsToJoin
      ? [
          {
            key: 1,
            name: '5 Reasons to Join',
            component: <JobTab data={fiveReasonsToJoin} />,
          },
        ]
      : []),
    ...(interviewProcess
      ? [
          {
            key: 2,
            name: 'Interview Process',
            component: <JobTab data={interviewProcess} />,
          },
        ]
      : []),
    ...(first90Days
      ? [
          {
            key: 3,
            name: 'First 90 Days',
            component: <JobTab data={first90Days} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <StyledContainer>
        {items.map((item) => (
          <>
            <StyledTabButton
              key={item.key}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                setButtonKey(item.key);
              }}
              selected={item.key === buttonKey}
              currentTheme={theme || COLORS.DODGER_BLUE}
            >
              {item.name}
            </StyledTabButton>
          </>
        ))}
      </StyledContainer>
      {first(items.filter((item) => item.key === buttonKey))?.component}
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 25px;
  margin-bottom: 20px;
  overflow-x: scroll;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 20px;
  }
`;

const StyledTabButton = styled.button`
  font-weight: 500;
  font-size: 15px;
  color: #282828;
  border: none;
  background: none;
  transition: all 0.3s ease;
  cursor: pointer;
  color: ${({
    selected,
    currentTheme,
  }: {
    selected: boolean;
    currentTheme: string;
  }) => (selected ? currentTheme : COLORS.ARGENT_2)};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }

  &:after {
    transition: all 0.3s ease;
    content: '';
    width: 100%;
    height: 5px;
    background: ${({ selected, currentTheme }) => selected && currentTheme};
    display: block;
    border-radius: 10px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 15px;
  }
`;

const StyledJobDescription = styled.div`
  p {
    font-size: 15px;
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    opacity: 0.8;
  }

  margin-bottom: 30px;
`;

export default JobTabs;
