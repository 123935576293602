/**
 * Check & returns boolean value for test mode
 * @returns {boolean}
 */
export const isTestModeOn = () => {
  return process.env.APP_ENV === 'test';
};

/**
 * Checks & returns test URL
 * @returns {string}
 */
export const getTestURL = () => {
  return process.env.REACT_APP_TEST_URL;
};
