import React, { useCallback, useEffect } from 'react';
import { Button, AutoComplete, Spin } from 'antd';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getSearchedEventsAndCompanies } from '../../../service/event';

const SearchBar = ({ setGetJoinedString, getJoinedString, color }) => {
  const [options, setOptions] = React.useState([]);
  const [searchSkillsValue, setSearchSkillsValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [pressedEnter, setPressedEnter] = React.useState(false);

  const handleEventsSearch = (text = '') => {
    if (text) {
      setGetJoinedString(text);
    } else {
      setGetJoinedString(searchSkillsValue);
    }
  };

  const handleOnChangeAutoEmpty = (text) => {
    const userSearch = text.trim();
    setSearchSkillsValue(userSearch);
    if (!userSearch) {
      setGetJoinedString(userSearch);
    }
  };

  const onSearch = useCallback(
    debounce(async (searchText) => {
      const trimmed = searchText.trim();
      if (trimmed.length) {
        setLoading(true);
        const payload = await getSearchedEventsAndCompanies(trimmed);
        const companies = get(payload, 'companies', []);
        const events = get(payload, 'events', []);
        const skills = (get(payload, 'skills', []) || []).map((skill) => ({
          id: skill?.id,
          name: skill?.skill,
        }));
        const response = [...events, ...companies, ...skills].map(
          (item, idx) => ({
            key: idx,
            value: item?.name,
          }),
        );
        setOptions(uniqBy(response, 'value'));
        setLoading(false);
      } else {
        setOptions([]);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (getJoinedString) {
      setSearchSkillsValue(getJoinedString);
    }

    if (pressedEnter) {
      handleEventsSearch();
    }

    // eslint-disable-next-line
  }, [pressedEnter]);

  return (
    <StyledSearch>
      <StyledAutoComplete
        placeholder="Search by event, skill or company"
        onChange={handleOnChangeAutoEmpty}
        onSearch={onSearch}
        defaultValue={getJoinedString}
        options={options}
        size="large"
        onSelect={handleEventsSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
        onKeyDown={(e) => (e.keyCode === 13 ? setPressedEnter(true) : null)}
        onKeyUp={() => setPressedEnter(false)}
      />

      <StyledButton
        type="primary"
        onClick={() => handleEventsSearch()}
        color={color}
      >
        <StyledBtnText>Search</StyledBtnText>
      </StyledButton>
    </StyledSearch>
  );
};

export default SearchBar;

const StyledSearch = styled.div`
  position: relative;
  top: 0;

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 10px !important;
  width: 102px;
  background-color: ${({ color }) => color};
  border-color: ${({ color }) => color};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ color }) => color};
    border-color: ${({ color }) => color};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const StyledBtnText = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  input {
    width: calc(100% - 95px) !important;
  }
  @media (min-width: ${BREAKPOINTS.MOBILE}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    .ant-select-selection-placeholder {
      font-size: 13px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-select-selection-placeholder {
      font-size: 11px;
    }
  }
`;
