import get from 'lodash/get';

const MOBILE_WIDTH_SPECIAL_CASE_1 = 470;
const MOBILE_WIDTH = 767;
const LAPTOP_MIN_WIDTH = 1200;
const LAPTOP_MAX_WIDTH = 1599;
const DESKTOP_MIN_WIDTH = 1600;

export const isMobileSpecialCase1 = () => {
  const screenWidth = get(window, 'screen.width', null);
  return screenWidth ? screenWidth <= MOBILE_WIDTH_SPECIAL_CASE_1 : false;
};

export const isMobile = () => {
  const screenWidth = get(window, 'screen.width', null);
  return screenWidth ? screenWidth <= MOBILE_WIDTH : false;
};

export const isTablet = () => {
  const screenWidth = get(window, 'screen.width', null);
  return screenWidth
    ? screenWidth <= MOBILE_WIDTH && screenWidth <= LAPTOP_MIN_WIDTH
    : false;
};

export const isLaptop = () => {
  const screenWidth = get(window, 'screen.width', null);
  return screenWidth
    ? screenWidth >= LAPTOP_MIN_WIDTH && screenWidth <= LAPTOP_MAX_WIDTH
    : false;
};

export const isDesktop = () => {
  const screenWidth = get(window, 'screen.width', null);
  return screenWidth ? screenWidth >= DESKTOP_MIN_WIDTH : false;
};

export const isTabletAndMobile = () => {
  const screenWidth = get(window, 'screen.width', null);
  return screenWidth ? screenWidth <= LAPTOP_MIN_WIDTH : false;
};
