import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Form } from 'antd';
import { getRoleId, getUserClassWithRoleId } from '../../utils/userInfo';
import { userProfileStore } from '../../utils/profile';
import { getUserColorWithRoleId } from '../../styles/utils';
import { sendSupportMail } from '../../service/support';
import {
  StyledCircleBoxDivProps,
  StyledSupportContainerItemSubmitColProps,
} from '../../interfaces/components/Support';
import { TextBox, InputTextArea, FormGroup } from './Form/Form';
import Notification from '../Notification/Notification';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { SHADOWS } from '../../styles/constants/shadows';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';
import { StyledLargeFont } from './Texts';
import { RESPONSE } from '../../constants/response';
import { PrimaryButton } from './Button';

export const Support: React.FC = () => {
  const { profileData } = userProfileStore();
  const roleId = getRoleId();
  const [form] = Form.useForm();
  const [requestPending, setRequestPending] = useState(false);

  const handleSubmit = async (formData: any) => {
    const { email } = profileData;
    const { message, subject } = formData;

    setRequestPending(true);

    const response: any = await sendSupportMail({ email, message, subject });

    setRequestPending(false);
    if (response.status && response.status !== RESPONSE.SUCCESS) {
      return;
    }

    if (response.status === RESPONSE.SUCCESS) {
      Notification(
        'success',
        'Support Request sent',
        'Our team will get in touch with you soon.',
      );
      form.resetFields();
    }
  };

  return (
    <StyledSupportContainer>
      <StyledContentBox>
        <StyledCircleBoxDiv roleId={roleId} />
        <StyledTitle>
          <StyledHeading id="support_title">Support</StyledHeading>
          <StyledText>
            Please fill the Details and our Team will get in touch with you
            soon.
          </StyledText>
        </StyledTitle>
        <FormGroup
          form={form}
          name="supportForm"
          onFinish={handleSubmit}
          expand
        >
          <StyledSupportContainerRow>
            <StyledSupportContainerItemCol xs={24}>
              <StyledSupportContainerItemTitleRow>
                <StyledLargeFont>Subject:</StyledLargeFont>
              </StyledSupportContainerItemTitleRow>
              <TextBox
                name="subject"
                placeholder="subject"
                required
                maxLength={100}
              />
            </StyledSupportContainerItemCol>
            <StyledSupportContainerItemCol xs={24}>
              <StyledSupportContainerItemTitleRow>
                <StyledLargeFont>Message:</StyledLargeFont>
              </StyledSupportContainerItemTitleRow>

              <InputTextArea
                name="message"
                placeholder="message"
                autoSize={{ minRows: 5, maxRows: 12 }}
                required
                maxLength={1000}
              />
            </StyledSupportContainerItemCol>
            <StyledSupportContainerItemSubmitCol xs={24} roleId={roleId}>
              <PrimaryButton
                theme={getUserClassWithRoleId(roleId)}
                type="submit"
                disabled={requestPending}
                id="support_submit_btn"
              >
                Submit
              </PrimaryButton>
            </StyledSupportContainerItemSubmitCol>
          </StyledSupportContainerRow>
        </FormGroup>
      </StyledContentBox>
    </StyledSupportContainer>
  );
};

const StyledSupportContainer = styled.div`
  height: 90vh;
`;

const StyledContentBox = styled.div`
  font-weight: ${FONT_WEIGHTS.BOLD};
  position: relative;
`;

const StyledCircleBoxDiv = styled.div<StyledCircleBoxDivProps>`
  background-color: ${(props) => getUserColorWithRoleId(props.roleId)};
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.15;
  position: absolute;
  left: -124px;
  top: -100px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledTitle = styled.div`
  margin-top: 28px;

  p {
    padding-left: 0;
  }
`;

const StyledHeading = styled.h3`
  font-size: ${FONT[24]};
  font-weight: ${FONT_WEIGHTS.BOLD};
  color: ${COLORS.ARSENIC};
  margin-bottom: 8px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[30]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }
`;

const StyledText = styled.p`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.LIGHT};
  color: ${COLORS.PHILLIPPINE_GRAY};
  padding-left: 0;
  line-height: 21px;
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledSupportContainerRow = styled(Row)`
  background-color: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.SHADOW1};
  padding: 30px 20px;
  margin-top: 30px;
  z-index: 1;
  height: unset;
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${PADDING(44, 30)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(20, 10)}
  }
`;

const StyledSupportContainerItemCol = styled(Col)`
  padding-bottom: 10px;
`;

const StyledSupportContainerItemTitleRow = styled(Row)`
  font-weight: ${FONT_WEIGHTS.REGULAR};
  margin-bottom: 10px;
`;

const StyledSupportContainerItemSubmitCol = styled(Col)<
  StyledSupportContainerItemSubmitColProps
>`
  ${DISPLAY_FLEX('row', 'flex-end')};

  margin-top: 20px;

  button {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      padding: 6px 30px;
    }
  }
`;
