import { useContract, useSigner, useFeeData } from 'wagmi';
import { erc20ABI } from 'wagmi';
import config from '../config';
import Notification from '../components/Notification/Notification';
import { ethers } from 'ethers';
import { USDCAddress } from '../constants/web3';
import { QA, STAGING } from '../constants';

let usdcAddress;

if (config.appEnv === QA || config.appEnv === STAGING) {
  usdcAddress = USDCAddress.TEST;
} else {
  usdcAddress = USDCAddress.MAIN;
}

export const useGetContract = () => {
  const { data: signer } = useSigner();

  const contract = useContract({
    addressOrName: usdcAddress,
    contractInterface: erc20ABI,
    signerOrProvider: signer,
  });

  const { data: feeData } = useFeeData();

  /** Check whether the user wallet has sufficient USDC or not */
  const checkBalance = async (amount) => {
    const address = await signer.getAddress();
    const balance = await contract?.balanceOf(address);
    const decimals = await contract?.decimals();
    const parsedBalance = ethers.utils.formatUnits(balance, decimals);
    if (parsedBalance < amount) {
      Notification('error', 'Insufficient balance in your wallet!');
      throw new Error('Insufficient Balance');
    }
  };

  /** Checking if you have the sufficient amount of eth for gas to get the transaction */
  const checkGasPrice = async (parsedAmount) => {
    if (!contract) return;
    // Getting the gas limit
    const estGasLimit = await contract.estimateGas.transfer(
      String(config.recoremWalletAddress),
      parsedAmount,
    );

    /** Getting the how much gas is needed for the transaction and multiply it with the gas fee per gas to get the totalGasPrice */
    const totalGasPrice = ethers.utils.formatEther(
      estGasLimit.mul(feeData.maxFeePerGas),
    );
    /* get the balance of the user */
    const balanceOfSigner = await signer.getBalance();
    const parsedBalanceOfSigner = ethers.utils.formatEther(balanceOfSigner);
    // Comparing the totalGasPrice with the current eth balance
    if (parsedBalanceOfSigner < totalGasPrice) {
      Notification(
        'error',
        'Insufficient eth to provide gas for the transaction.',
      );
      throw new Error("Doesn't have enough eth for gas.");
    }
  };

  const transferUSDC = async (amount) => {
    try {
      const decimals = await contract?.decimals();
      const parsedAmount = ethers.utils.parseUnits(amount.toString(), decimals);

      await checkBalance(amount);
      await checkGasPrice(parsedAmount);
      const tx = await contract?.transfer(
        String(config.recoremWalletAddress),
        parsedAmount,
      );
      Notification('info', 'Transaction processing do not refresh or go back!');
      const recipt = await tx.wait();
      if (recipt.status === 1) {
        Notification('success', 'Transaction Successful');
      } else {
        Notification(
          'Error',
          'Transaction cannot be performed at this moment, please try again later!',
        );
        throw new Error('Transaction is not completed');
      }
      const txnHash = tx.hash;
      return txnHash;
    } catch (error) {
      Notification('error', "Uhoh, we couldn't make the transaction");
      throw new Error('Transaction could not be happened!');
    }
  };

  return {
    contract,
    transferUSDC,
  };
};
