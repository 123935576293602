import React, { memo } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import RecommendedEvents from '../RecommendedEvents/RecommendedEvents';
import UpcomingEventsContent from './UpcomingEventsContent';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

function UpcomingEvents({
  dashboard = false,
  locateInDashboard = false,
  colPropsUpcomingSection = { span: 24 },
  search = '',
  noTimeline = null,
  noTitle = null,
  isCandidateDashboard = false,
}) {
  return (
    <StyledWrapper {...colPropsUpcomingSection} noTitle={noTitle}>
      <div>
        {dashboard ? (
          <RecommendedEvents
            dashboard={dashboard}
            locateInDashboard={locateInDashboard}
          />
        ) : null}
      </div>
      <UpcomingEventsContent
        dashboard={dashboard}
        search={search}
        noTimeline={noTimeline}
        noTitle={noTitle}
        isCandidateDashboard={isCandidateDashboard}
      />
      {/* Note: not required */}
      {/* {checkIfUserIsOrganizer(roleId) && dashboard ? <ExploreEvents /> : null} */}
    </StyledWrapper>
  );
}

export default memo(UpcomingEvents);

const StyledWrapper = styled(Col)`
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: ${({ noTitle }) => (!noTitle ? '15px' : '0px')};
  }
`;
