import React, { useState } from 'react';
import Chat from '../../components/Chat/Chat';
import { PersonalChatContext } from '../../context/personalChat';

const ChatManagement = ({ isForTest }) => {
  // Used for setting classes in mobile view.
  const [chatOpened, setChatOpened] = useState(false);

  return (
    <PersonalChatContext.Provider value={{ chatOpened, setChatOpened }}>
      <Chat isForTest={isForTest} />
    </PersonalChatContext.Provider>
  );
};

export default ChatManagement;
