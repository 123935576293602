import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { getFileS3 } from '../../../utils/s3.storage';
import { StyledSemiLargeHeading, StyledSmallHeading } from '../../shared-components/Texts';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

export default function AttendingCompanies({ event }) {
  const { meta } = event;
  if (!isObject(meta)) {
    return null;
  }

  const { companyLogos } = meta;
  if (!isArray(companyLogos)) {
    return null;
  }

  const CompanyLogoItem = ({ name, logo }) => {
    return (
      <Col md={4} xs={8} key={name}>
        <StyledInnerContainer>
          <StyledImg
            src={getFileS3(logo)}
            alt=""
          />
          <StyledTitle>
            <StyledSmallHeading>
              {name}
            </StyledSmallHeading>
          </StyledTitle>
        </StyledInnerContainer>
      </Col>
    );
  };

  return companyLogos.length ? (
    <div>
      <Row>
        <Col xs={18}>
          <StyledHeaing>
            <StyledSemiLargeHeading>
              Attending Companies
            </StyledSemiLargeHeading>
          </StyledHeaing>
        </Col>
        {/* NOTE: Commenting this for future use case */}
        {/* <Col
          xs={6}
          className="attending-companies__button--right small-heading"
        >
          View All
        </Col> */}
      </Row>
      <div>
        <StyledWrapper>
          {companyLogos.map((item, idx) => {
            const { name, filename } = item;
            return (
              <CompanyLogoItem
                name={name}
                logo={filename}
                key={idx.toString()}
              />
            );
          })}
        </StyledWrapper>
      </div>
    </div>
  ) : null;
};

const StyledHeaing = styled.h4`
  font-weight: ${FONT_WEIGHTS.BOLD};
  color: ${COLORS.ARSENIC};
`;

const StyledWrapper = styled(Row)`
  ${DISPLAY_FLEX(null, 'center', 'center')};
  padding: 10px;
`;

const StyledInnerContainer = styled.div`
  ${DISPLAY_FLEX('column', null, 'center')};
  ${MARGIN(0, 5, 0, 5)};
  background-color: ${COLORS.LOTION};
  align-self: center;
  padding: 20px;
  border-radius: 5px;
`;

const StyledImg = styled.img`
  width: 70px;
  height: 70px;
  margin-bottom: 10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 45px;
    height: 45px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 60px;
    height: 60px;
  }
`;

const StyledTitle = styled.div`
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;
