import { MainWebsiteRoutes } from '../../../constants/routes';

export const EXPLORE_EVENTS_LINKS = [
  {
    title: 'Engineering',
    link: `${MainWebsiteRoutes.home}/explore-events?search=&category=engineering`,
  },
  {
    title: 'UI/UX Design',
    link: `${
      MainWebsiteRoutes.home
    }/explore-events?search=&category=${encodeURIComponent('ui/ux_design')}`,
  },
  {
    title: 'Product management',
    link: `${MainWebsiteRoutes.home}/explore-events?search=&category=product_management`,
  },
  {
    title: 'DevOps',
    link: `${MainWebsiteRoutes.home}/explore-events?search=&category=devops`,
  },
  {
    title: 'Security',
    link: `${MainWebsiteRoutes.home}/explore-events?search=&category=security`,
  },
  {
    title: 'Sales & Marketing',
    link: `${
      MainWebsiteRoutes.home
    }/explore-events?search=&category=${encodeURIComponent(
      'sales_&_marketing',
    )}`,
  },
  {
    title: 'Data science',
    link: `${MainWebsiteRoutes.home}/explore-events?search=&category=data_science`,
  },
];

export const FOR_BUSINESS = [
  {
    title: 'Event Managers',
    link: MainWebsiteRoutes.organizer,
  },
  {
    title: 'Hiring Companies',
    link: MainWebsiteRoutes.recruiter,
  },
];

export const QUICK_LINKS = [
  // {
  //   title: 'FAQs',
  //   link: '',
  // },
  {
    title: 'About Us',
    link: MainWebsiteRoutes.aboutUs,
  },
  {
    title: 'Log in',
    link: '/login',
  },
  {
    title: 'Sign up',
    link: '/login',
  },
  {
    title: 'Blog',
    link: MainWebsiteRoutes.blog,
  },
  /*
  {
    title: 'Blog',
    link: '',
  },
  {
    title: 'Press',
    link: '',
  },
  {
    title: 'Podcast',
    link: '',
  },
  {
    title: 'Developers',
    link: '',
  },
  {
    title: 'Help Center For Recruiters',
    link: '',
  },
  {
    title: 'Help Center For Freelancers',
    link: '',
  },
  */
];

export const COMPARE_LINKS = [
  {
    title: 'Recorem Vs Toptal',
    link: '',
  },
  {
    title: 'Toptal Alternative',
    link: '',
  },
  {
    title: 'Recorem Vs Turing',
    link: '',
  },
  {
    title: 'Turing Alternative',
    link: '',
  },
  {
    title: 'Recorem Vs Upstack',
    link: '',
  },
  {
    title: 'Recorem Vs Andela',
    link: '',
  },
  {
    title: 'Xteam Alternative',
    link: '',
  },
  {
    title: 'Lemon.io Alternative',
    link: '',
  },
  {
    title: 'Gun.io Alternative',
    link: '',
  },
  {
    title: 'Bairesdev Alternative',
    link: '',
  },
];
