import { createSlice } from '@reduxjs/toolkit';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import {
  getCandidateEventAnalytics,
  getDashboardAnalytics,
  getOrganizerRecruiterAnalytics,
  getRecruiterApplicationAnalytics,
  getRecruiterEventAnalytics,
  getRecruiterEventAnalyticsByJobId,
  getRecruiterSpecificEventAnalytics,
} from '../../service/analytics';
import {
  getColdOrWarmLeads,
  getJobsWithNoApplications,
  getJobsWithNoShortlistedApplications,
  getMasterAdminDashboardAnalytics,
  getMasterAdminDashboardJobs,
  getPreScreenJobsApplications,
} from '../../service/masterAdmin';

const onStartDashboard = (state) => {
  state.dashboardAnalytics.isLoading = true;
};

const dashboardAnalytics = (state, action) => {
  state.dashboardAnalytics.isLoading = false;
  state.dashboardAnalytics.error = null;
  state.dashboardAnalytics.analytics = action.payload;
};

const onErrorDashboard = (state, action) => {
  state.dashboardAnalytics.isLoading = false;
  state.dashboardAnalytics.error = action.payload;
};

const onStartOngoingEvent = (state) => {
  state.onGoingEventAnalytics.isLoading = true;
};

const onGoingEventAnalytics = (state, action) => {
  state.onGoingEventAnalytics.isLoading = false;
  state.onGoingEventAnalytics.error = null;
  state.onGoingEventAnalytics.analytics = action.payload;
};

const onErrorOngoingEvent = (state, action) => {
  state.onGoingEventAnalytics.isLoading = false;
  state.onGoingEventAnalytics.error = action.payload;
};

const onStartEvent = (state) => {
  state.eventAnalytics.isLoading = true;
};

const eventAnalytics = (state, action) => {
  state.eventAnalytics.isLoading = false;
  state.eventAnalytics.error = null;
  state.eventAnalytics.analytics = action.payload;
};

const applicationAnalytics = (state, action) => {
  state.applicationAnalytics.isLoading = false;
  state.applicationAnalytics.error = null;
  state.applicationAnalytics.analytics = action.payload;
};

const jobAnalytics = (state, action) => {
  state.jobAnalytics.isLoading = false;
  state.jobAnalytics.error = null;
  state.jobAnalytics.analytics = action.payload;
};
const onErrorEvent = (state, action) => {
  state.eventAnalytics.isLoading = false;
  state.eventAnalytics.error = action.payload;
};

const onStartMasterAdminDashboard = (state) => {
  state.masterAdminDashboardAnalytics.statistics.isLoading = true;
};

const masterAdminDashboardAnalytics = (state, action) => {
  state.masterAdminDashboardAnalytics.statistics.isLoading = false;
  state.masterAdminDashboardAnalytics.statistics.error = null;
  state.masterAdminDashboardAnalytics.statistics.analytics = action.payload;
};

const onErrorMasterAdminDashboardAnalytics = (state, action) => {
  state.masterAdminDashboardAnalytics.statistics.isLoading = false;
  state.masterAdminDashboardAnalytics.statistics.error = action.payload;
};

const onStartMasterAdminDashboardJobs = (state) => {
  state.masterAdminDashboardAnalytics.priorityJobs.isLoading = true;
};

const masterAdminDashboardJobs = (state, action) => {
  state.masterAdminDashboardAnalytics.priorityJobs.isLoading = false;
  state.masterAdminDashboardAnalytics.priorityJobs.error = null;
  state.masterAdminDashboardAnalytics.priorityJobs.jobs = action.payload;
};

const onErrorMasterAdminDashboardJobs = (state, action) => {
  state.masterAdminDashboardAnalytics.priorityJobs.isLoading = false;
  state.masterAdminDashboardAnalytics.priorityJobs.error = action.payload;
};

const onStartJobsWithNoApplications = (state) => {
  state.masterAdminDashboardAnalytics.jobsWithNoApplications.isLoading = true;
};

const onStartPreScreenJobApplications = (state) => {
  state.masterAdminDashboardAnalytics.preScreenJobsApplications.isLoading = true;
};

const jobsWithNoApplications = (state, action) => {
  state.masterAdminDashboardAnalytics.jobsWithNoApplications.isLoading = false;
  state.masterAdminDashboardAnalytics.jobsWithNoApplications.error = null;
  state.masterAdminDashboardAnalytics.jobsWithNoApplications.data =
    action.payload;
};

const preScreenJobsApplications = (state, action) => {
  state.masterAdminDashboardAnalytics.preScreenJobsApplications.isLoading = false;
  state.masterAdminDashboardAnalytics.preScreenJobsApplications.error = null;
  state.masterAdminDashboardAnalytics.preScreenJobsApplications.data =
    action.payload;
};

const onErrorJobsWithNoApplications = (state, action) => {
  state.masterAdminDashboardAnalytics.jobsWithNoApplications.isLoading = false;
  state.masterAdminDashboardAnalytics.jobsWithNoApplications.error =
    action.payload;
};

const onErrorPreScreenJobsApplications = (state, action) => {
  state.masterAdminDashboardAnalytics.preScreenJobsApplications.isLoading = false;
  state.masterAdminDashboardAnalytics.preScreenJobsApplications.error =
    action.payload;
};

const onStartJobsWithNoShortlistedApplications = (state) => {
  state.masterAdminDashboardAnalytics.jobsWithNoShortlistedApplications.isLoading = true;
};

const jobsWithNoShortlistedApplications = (state, action) => {
  state.masterAdminDashboardAnalytics.jobsWithNoShortlistedApplications.isLoading = false;
  state.masterAdminDashboardAnalytics.jobsWithNoShortlistedApplications.error = null;
  state.masterAdminDashboardAnalytics.jobsWithNoShortlistedApplications.data =
    action.payload;
};

const onErrorJobsWithNoShortlistedApplications = (state, action) => {
  state.masterAdminDashboardAnalytics.jobsWithNoShortlistedApplications.isLoading = false;
  state.masterAdminDashboardAnalytics.jobsWithNoShortlistedApplications.error =
    action.payload;
};

const onStartColdOrWarmLeads = (state) => {
  state.masterAdminDashboardAnalytics.coldOrWarmLeads.isLoading = true;
};

const coldOrWarmLeads = (state, action) => {
  state.masterAdminDashboardAnalytics.coldOrWarmLeads.isLoading = false;
  state.masterAdminDashboardAnalytics.coldOrWarmLeads.error = null;
  state.masterAdminDashboardAnalytics.coldOrWarmLeads.data = action.payload;
};

const onErrorColdOrWarmLeads = (state, action) => {
  state.masterAdminDashboardAnalytics.coldOrWarmLeads.isLoading = false;
  state.masterAdminDashboardAnalytics.coldOrWarmLeads.error = action.payload;
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    dashboardAnalytics: {
      isLoading: false,
      analytics: {},
      error: null,
    },
    onGoingEventAnalytics: {
      isLoading: false,
      analytics: [],
      error: null,
    },
    eventAnalytics: {
      isLoading: false,
      analytics: [],
      error: null,
    },
    applicationAnalytics: {
      isLoading: false,
      analytics: [],
      error: null,
    },
    jobAnalytics: {
      isLoading: false,
      analytics: [],
      error: null,
    },
    masterAdminDashboardAnalytics: {
      statistics: {
        isLoading: false,
        analytics: [],
        error: null,
      },
      priorityJobs: {
        isLoading: false,
        jobs: [],
        error: null,
      },
      jobsWithNoApplications: {
        isLoading: false,
        data: [],
        error: null,
      },
      preScreenJobsApplications: {
        isLoading: false,
        data: [],
        error: null,
      },
      jobsWithNoShortlistedApplications: {
        isLoading: false,
        data: [],
        error: null,
      },
      coldOrWarmLeads: {
        isLoading: false,
        data: [],
        error: null,
      },
    },
  },
  reducers: {
    onStartDashboard,
    onStartOngoingEvent,
    onStartEvent,
    onStartMasterAdminDashboard,
    onStartMasterAdminDashboardJobs,
    onStartJobsWithNoApplications,
    onStartPreScreenJobApplications,
    onStartJobsWithNoShortlistedApplications,
    onStartColdOrWarmLeads,
    dashboardAnalytics,
    onGoingEventAnalytics,
    eventAnalytics,
    applicationAnalytics,
    jobAnalytics,
    masterAdminDashboardAnalytics,
    masterAdminDashboardJobs,
    jobsWithNoApplications,
    jobsWithNoShortlistedApplications,
    preScreenJobsApplications,
    coldOrWarmLeads,
    onErrorDashboard,
    onErrorOngoingEvent,
    onErrorEvent,
    onErrorMasterAdminDashboardAnalytics,
    onErrorMasterAdminDashboardJobs,
    onErrorJobsWithNoApplications,
    onErrorPreScreenJobsApplications,
    onErrorJobsWithNoShortlistedApplications,
    onErrorColdOrWarmLeads,
  },
});

const getDashboardAnalyticsData = (roleId) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartDashboard());
    const analytics = await getDashboardAnalytics(roleId);
    dispatch(analyticsSlice.actions.dashboardAnalytics(analytics));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorDashboard(err.toString()));
  }
};

const getUserAnalyticsEndpoint = async (roleId, eventIds) => {
  const analytics =
    eventIds && eventIds.length > 0
      ? eventIds
          .map((id) => {
            switch (true) {
              case checkIfUserIsRecruiter(roleId):
                return getRecruiterEventAnalytics(id);
              case checkIfUserIsCandidate(roleId):
                return getCandidateEventAnalytics(id);
              default:
                return getOrganizerRecruiterAnalytics(id);
            }
          })
          .filter((analy) => analy)
      : {};
  return Promise.all(analytics);
};

const getOngoingEventAnalyticsData = (roleId, eventIds) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartOngoingEvent());
    const analytics = await getUserAnalyticsEndpoint(roleId, eventIds);
    dispatch(analyticsSlice.actions.onGoingEventAnalytics(analytics));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorOngoingEvent(err.toString()));
  }
};

const getEventAnalyticsData = (roleId, eventIds) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartEvent());
    const analytics = await getUserAnalyticsEndpoint(roleId, eventIds);
    dispatch(analyticsSlice.actions.eventAnalytics(analytics));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorEvent(err.toString()));
  }
};

export const getEventRecruiterSpecificAnalyticsData = (
  roleId,
  eventIds,
  jobs,
) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartEvent());
    const analytics = await getRecruiterSpecificEventAnalytics(eventIds, jobs);

    dispatch(analyticsSlice.actions.eventAnalytics([analytics]));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorEvent(err.toString()));
  }
};

export const getApplicationRecruiterSpecificAnalyticsData = (
  eventIds = null,
  jobIds = null,
  country = null,
  availableStartTime = null,
  location = null,
  startDate = null,
  endDate = null,
  updated = false,
) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartEvent());
    const analytics = await getRecruiterApplicationAnalytics(
      eventIds,
      jobIds,
      country,
      availableStartTime,
      location,
      startDate,
      endDate,
      updated,
    );

    dispatch(analyticsSlice.actions.applicationAnalytics([analytics]));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorEvent(err.toString()));
  }
};

const getEventAnalyticsDataByJobId = (jobId, eventId) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartEvent());
    const analytics = await getRecruiterEventAnalyticsByJobId(eventId, jobId);

    dispatch(analyticsSlice.actions.jobAnalytics(analytics));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorEvent(err.toString()));
  }
};

const getMasterAdminDashboardAnalyticsData = (
  startTime,
  endTime,
  maxGrowth,
) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartMasterAdminDashboard());
    const analytics = await getMasterAdminDashboardAnalytics(
      startTime,
      endTime,
      maxGrowth,
    );
    dispatch(analyticsSlice.actions.masterAdminDashboardAnalytics(analytics));
  } catch (err) {
    dispatch(
      analyticsSlice.actions.onErrorMasterAdminDashboardAnalytics(
        err.toString(),
      ),
    );
  }
};

const getMasterAdminDashboardPriorityJobs = () => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartMasterAdminDashboardJobs());
    const priorityJobs = await getMasterAdminDashboardJobs();
    dispatch(analyticsSlice.actions.masterAdminDashboardJobs(priorityJobs));
  } catch (err) {
    dispatch(
      analyticsSlice.actions.onErrorMasterAdminDashboardJobs(err.toString()),
    );
  }
};

const getJobsWithNoApplicationsData = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartJobsWithNoApplications());
    const jobs = await getJobsWithNoApplications(page, pageSize);
    dispatch(analyticsSlice.actions.jobsWithNoApplications(jobs));
  } catch (err) {
    dispatch(
      analyticsSlice.actions.onErrorJobsWithNoApplications(err.toString()),
    );
  }
};

const getPreScreenJobsApplicationsData = (page, pageSize) => async (
  dispatch,
) => {
  try {
    dispatch(analyticsSlice.actions.onStartPreScreenJobApplications());
    const jobs = await getPreScreenJobsApplications(page, pageSize);
    dispatch(analyticsSlice.actions.preScreenJobsApplications(jobs));
  } catch (err) {
    dispatch(
      analyticsSlice.actions.onErrorPreScreenJobsApplications(err.toString()),
    );
  }
};

const getJobsWithNoShortlistedApplicationsData = (page, pageSize) => async (
  dispatch,
) => {
  try {
    dispatch(analyticsSlice.actions.onStartJobsWithNoShortlistedApplications());
    const jobs = await getJobsWithNoShortlistedApplications(page, pageSize);
    dispatch(analyticsSlice.actions.jobsWithNoShortlistedApplications(jobs));
  } catch (err) {
    dispatch(
      analyticsSlice.actions.onErrorJobsWithNoShortlistedApplications(
        err.toString(),
      ),
    );
  }
};

const getColdOrWarmLeadsData = (query, page, pageSize) => async (dispatch) => {
  try {
    dispatch(analyticsSlice.actions.onStartColdOrWarmLeads());
    const leads = await getColdOrWarmLeads(query, page, pageSize);
    dispatch(analyticsSlice.actions.coldOrWarmLeads(leads));
  } catch (err) {
    dispatch(analyticsSlice.actions.onErrorColdOrWarmLeads(err.toString()));
  }
};

export default analyticsSlice.reducer;

export {
  getDashboardAnalyticsData,
  getOngoingEventAnalyticsData,
  getEventAnalyticsData,
  getEventAnalyticsDataByJobId,
  getMasterAdminDashboardAnalyticsData,
  getMasterAdminDashboardPriorityJobs,
  getJobsWithNoApplicationsData,
  getJobsWithNoShortlistedApplicationsData,
  getPreScreenJobsApplicationsData,
  getColdOrWarmLeadsData,
};
