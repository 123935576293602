import get from 'lodash/get';
import Notification from '../components/Notification/Notification';
import {
  RECRUITER_MEMBER,
  ORGANIZER_MEMBER,
  STATUS_ACTIVE,
  STATUS_PENDING,
  STATUS_DRAFT,
} from '../constants';
import {
  acceptCompanyInvitationWithoutReload,
  rejectCompanyInvitation,
  acceptCrewInvitation,
} from '../store/features/crew';

export const userIsCrewMember = (profileData) => {
  if (!profileData.companyMember) {
    return false;
  }

  const role = get(profileData, 'companyMember.role', STATUS_ACTIVE);
  return role === RECRUITER_MEMBER || role === ORGANIZER_MEMBER;
};

export const isMemberActive = (member) => {
  return member && member.status === STATUS_ACTIVE;
};

export const isMemberPending = (member) => {
  return member && member.status === STATUS_PENDING;
};

export const isMemberInvited = (member) => {
  return member && member.status === STATUS_DRAFT;
};

export const handleAcceptInvitation = (
  idx,
  companyName,
  dispatch,
  profileData,
) => {
  dispatch(
    acceptCompanyInvitationWithoutReload(
      get(profileData, `invitations[${idx}].companyId`, null),
      get(profileData, `invitations[${idx}].id`, null),
    ),
  )
    .then(() => {
      Notification(
        'success',
        'Succes',
        `You are successfully added to the ${companyName} company`,
      );
    })
    .catch((err) => {
      Notification('error', 'Invitation Acceptance Failed', `${err}`);
    });
};

export const handleRejectInvitation = (
  idx,
  companyName,
  dispatch,
  profileData,
) => {
  dispatch(
    rejectCompanyInvitation(
      get(profileData, `invitations[${idx}].companyId`, null),
      get(profileData, `invitations[${idx}].id`, null),
    ),
  )
    .then(() => {
      Notification('success', 'Succes', `Rejected ${companyName}'s invite`);
    })
    .catch((err) => {
      Notification('error', 'Failed to reject the invitation', `${err}`);
    });
};

export const handleAcceptCrew = (crewMemberId, dispatch) => {
  dispatch(acceptCrewInvitation(crewMemberId))
    .then(() => {
      Notification('success', 'Succes', `Crew member successfully accepted`);
    })
    .catch((err) => {
      Notification('error', 'Crew member acceptance Failed', `${err}`);
    });
};
