/* eslint-disable no-nested-ternary */
import React from 'react';
import MapAllLinks from './index';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../../constants/routes';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../../utils/userInfo';

const EventBreadCrumbs = ({ event = '' }) => {
  const roleId = getRoleId();

  let route = '';

  if (checkIfUserIsCandidate(roleId)) {
    route = event.isEventBooked
      ? CandidateRoutes.event
      : CandidateRoutes.exploreEvent;
  } else if (checkIfUserIsRecruiter(roleId)) {
    route = event.isEventBooked
      ? RecruiterRoutes.event
      : RecruiterRoutes.exploreEvent;
  } else {
    route = OrganizerRoutes.events;
  }

  const title = !checkIfUserIsOrganizer(roleId)
    ? event.isEventBooked
      ? 'Events'
      : 'Explore Events'
    : 'Events';

  const links = [
    {
      name: title,
      url: route,
    },
    {
      name: event.name,
      url: checkIfUserIsCandidate(roleId)
        ? `${CandidateRoutes.event}view/${event.id}`
        : checkIfUserIsRecruiter(roleId)
        ? `${RecruiterRoutes.event}view/${event.id}`
        : `${OrganizerRoutes.events}view/${event.id}`,
    },
  ];

  return (
    <>
      <MapAllLinks links={links} />
    </>
  );
};

export default EventBreadCrumbs;
