import React, { useState } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import { useDispatch } from 'react-redux';
import { TextUpload } from '../shared-components/Upload';
import UserAvatar from '../shared-components/Common/UserAvatar';
import { UploadPictureNotification } from '../Notification/UploadPictureNotification';
import {
  updateProfilePic,
  updateCompanyLogo,
  deletePicture,
} from '../../utils/profile';
import { getProfileDataWithoutReload } from '../../store/features/profile';
import { getFileS3 } from '../../utils/s3.storage';
import {
  ACCEPTED_PROFILE_IMAGE,
  IMAGE_PROFILE_PAYLOAD,
  IMAGE_LOGO_PAYLOAD,
  PICTURE_TYPE,
  ACCEPTED_IMAGE_EXTENSIONS,
  ACCEPTED_LOGO_IMAGE,
  LOGO_IMAGE_HINT,
} from '../../constants/file';
import Notification from '../Notification/Notification';
import config from '../../config';
import { imageValidator } from '../../utils/common';
import { checkIfUserIsMasterAdmin } from '../../utils/userInfo';
import { CANDIDATE, CROP_POFILE_PIC_OPTIONS } from '../../constants';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { FONT } from '../../styles/constants/typography';

const UploadLogoComponent = ({
  avatarSize,
  option = true,
  companyLogo,
  setCompanyLogo,
  fileName,
  role = null,
  companyId = null,
  userRole,
  userId,
  alt,
  userType = null,
  removeOption = true,
  roleId,
  btnText,
}) => {
  const [removeCompanyLogo, setRemoveCompanyLogo] = useState(companyLogo);

  const companyLogoOnChange = (info) => {
    UploadPictureNotification(info);
  };

  const updateCompanyLogoCustomReq = ({ onSuccess, onError, file }) => {
    imageValidator({ ...IMAGE_LOGO_PAYLOAD, file }).then(() => {
      updateCompanyLogo(file, role, companyId)
        .then((fileName) => {
          setCompanyLogo(getFileS3(fileName));
          setRemoveCompanyLogo(fileName);
          onSuccess();
        })
        .catch((err) => {
          onError();
        });
    });
  };

  const handleRemoveCompanyLogo = async () => {
    const url =
      role && checkIfUserIsMasterAdmin(role) ? companyLogo : removeCompanyLogo;
    if (url) {
      await deletePicture(
        url.replace(`${config.aws_cdn_url}/contents/`, ''),
        PICTURE_TYPE.COMPANY_LOGO,
        role,
        companyId,
        userRole,
        userId,
      ).then(() => {
        setCompanyLogo(null);
      });
    }
  };

  const handlePicBeforeUpload = (file) => {
    if (file.size > ACCEPTED_LOGO_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Logo image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...IMAGE_LOGO_PAYLOAD, file }).catch(() => false);
  };

  const defaultBtnText = 'Change Logo';

  const getUploadText = () => {
    if (!companyLogo) {
      return 'Add Logo';
    }
    return btnText || defaultBtnText;
  };

  return (
    <>
      <UserAvatar
        src={companyLogo}
        alt={alt}
        size={avatarSize}
        userType={userType}
        roleId={roleId}
      />
      {option ? (
        <div>
          <div>
            <TextUpload
              name="logoUpload"
              text={getUploadText()}
              customRequestHandler={updateCompanyLogoCustomReq}
              onChange={companyLogoOnChange}
              beforeUpload={handlePicBeforeUpload}
              cropActive
              cropOptions={CROP_POFILE_PIC_OPTIONS}
              accept={ACCEPTED_IMAGE_EXTENSIONS}
              hintText={LOGO_IMAGE_HINT}
              roleId={roleId}
            />
          </div>
          {removeOption && companyLogo ? (
            <StyledRemoveTextWrapper onClick={handleRemoveCompanyLogo}>
              Remove Logo
            </StyledRemoveTextWrapper>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

const UploadProfilePicComponent = ({
  avatarSize,
  option = true,
  removeOption = false,
  profilePic,
  setProfilePic,
  ProfilePicCustomRequest = null,
  roleId = null,
  userId = null,
  userRole = null,
  userType = null,
  alt,
  changePictureText = '',
  fullWidth = false,
  isCandidateProfile = false,
  isCandidateEdit = false,
}) => {
  const [removeProfilePic, setRemoveProfilePic] = useState(profilePic);
  const dispatch = useDispatch();

  const profilePicOnChange = (info) => {
    UploadPictureNotification(info);
  };

  const updateProfilePicCustomReq = ({ onSuccess, onError, file }) => {
    imageValidator({ ...IMAGE_PROFILE_PAYLOAD, file }).then(() => {
      updateProfilePic(file, roleId, userId, userRole)
        .then((fileName) => {
          setProfilePic(getFileS3(fileName));
          setRemoveProfilePic(fileName);
          if (!(roleId && checkIfUserIsMasterAdmin(roleId))) {
            dispatch(getProfileDataWithoutReload());
          }
          onSuccess();
        })
        .catch((err) => {
          onError();
        });
    });
  };

  const handleRemoveProfilePic = async () => {
    if (
      await deletePicture(
        removeProfilePic.replace(`${config.aws_cdn_url}/contents/`, ''),
        PICTURE_TYPE.PROFILE_PICTURE,
        roleId,
        null,
        userRole || CANDIDATE,
        userId,
      )
    ) {
      setProfilePic(null);
      dispatch(getProfileDataWithoutReload());
    }
  };

  const handlePicBeforeUpload = (file) => {
    if (file.size > ACCEPTED_PROFILE_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Profile image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...IMAGE_PROFILE_PAYLOAD, file });
  };

  return (
    <StyledWrapper
      xs={{ span: 24, order: 1 }}
      {...(isCandidateEdit
        ? {}
        : { md: fullWidth ? 24 : { span: 6, order: 2 } })}
      isCandidate={isCandidateProfile}
      isCandidateEdit={isCandidateEdit}
    >
      <UserAvatar
        src={profilePic}
        userType={userType}
        alt={alt}
        size={avatarSize}
      />
      {option ? (
        <div>
          <div>
            <TextUpload
              name="representativePictureUpload"
              text={
                changePictureText
                  ? 'Change Profile Picture'
                  : '+ Add Profile Picture'
              }
              customRequestHandler={
                ProfilePicCustomRequest || updateProfilePicCustomReq
              }
              onChange={profilePicOnChange}
              beforeUpload={handlePicBeforeUpload}
              cropActive
              cropOptions={CROP_POFILE_PIC_OPTIONS}
              accept={ACCEPTED_IMAGE_EXTENSIONS}
              roleId={roleId}
              hintText={LOGO_IMAGE_HINT}
            />
          </div>
          {removeOption && profilePic ? (
            <StyledRemoveTextWrapper onClick={handleRemoveProfilePic}>
              Remove Picture
            </StyledRemoveTextWrapper>
          ) : null}
        </div>
      ) : null}
    </StyledWrapper>
  );
};

export { UploadLogoComponent, UploadProfilePicComponent };

const StyledWrapper = styled(Col)`
  text-align: center;

  ${({ isCandidateEdit }) => (isCandidateEdit ? `max-width: max-content;` : '')}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0px;
    ${({ isCandidate }) => (isCandidate ? `margin-top: -45px;` : '')}
  }
`;

const StyledRemoveTextWrapper = styled.div`
  color: ${COLORS.ORANGE_RED};
  font-size: ${FONT[13]};
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[10]};
  }
`;
