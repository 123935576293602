import React from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { getCurrencyById, formatCurrency } from '../../utils/currencyConvertor';
import { PageContainer } from '../shared-components/PageContainer';
import { SmallHeading, StyledMediumFont } from '../shared-components/Texts';
import { getRoleId } from '../../utils/userInfo';
import { SubTitles, TitleContainer } from './common';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const WorkAndEducationInformation = ({
  profileData,
  candidateExpinYr,
  candidateExpinMon,
  currencyData,
  unauthorizedCountries,
  authorizedCountries,
}) => {
  const roleId = getRoleId();
  const educations = get(profileData, 'educations', []);

  // Sub component for countries
  const RenderCountries = ({ countries }) => {
    return countries.length ? (
      <StyledListContainer>
        {countries.map((country) => {
          const name = country?.country?.name;
          return <StyledText>{name}</StyledText>;
        })}
      </StyledListContainer>
    ) : null;
  };

  return (
    <PageContainer>
      <TitleContainer roleId={roleId}>Work Experience</TitleContainer>
      <StyledItemContainer>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={7}>
            <StyledRow>
              <SubTitles>Total Work Experience:</SubTitles>
              <SmallHeading>
                {candidateExpinYr
                  ? `${candidateExpinYr} ${candidateExpinYr > 1 ? 'Yrs' : 'Yr'}`
                  : null}{' '}
                {candidateExpinMon} Months
              </SmallHeading>
            </StyledRow>
          </Col>
          <Col xs={24} md={7}>
            <StyledRow>
              <SubTitles>Current Salary:</SubTitles>
              <SmallHeading>
                {profileData.annualCompensation &&
                  formatCurrency(
                    profileData.currentSalaryCurrency
                      ? get(
                          getCurrencyById(
                            currencyData,
                            get(profileData, 'currentSalaryCurrency.id'),
                          ),
                          'value',
                          profileData.currentCtcCurrency,
                        )
                      : profileData.currentCtcCurrency,
                    profileData.annualCompensation,
                  )}
              </SmallHeading>
            </StyledRow>
          </Col>
        </Row>
      </StyledItemContainer>

      {/* Work authorization */}

      {(unauthorizedCountries && unauthorizedCountries.length) ||
      (authorizedCountries && authorizedCountries.length) ? (
        <>
          <TitleContainer marginTop={25} roleId={roleId}>
            Work Authorization
          </TitleContainer>

          <div>
            <StyledTitle>Authorized to work in countries :</StyledTitle>
            <RenderCountries countries={authorizedCountries} />
          </div>

          <div>
            <StyledTitle>Unauthorized to work in countries :</StyledTitle>
            <RenderCountries countries={unauthorizedCountries} />
          </div>
        </>
      ) : null}

      {educations && educations.length ? (
        <>
          <TitleContainer marginTop={25} roleId={roleId}>
            Education Detail
          </TitleContainer>
          <StyledEducationListContainer>
            {educations.map((education, index) => {
              return (
                <StyledEducationItemContainer key={index}>
                  <div>
                    <SubTitles>
                      {education.degree}, {education.major}
                    </SubTitles>
                    <SmallHeading>{education.school}</SmallHeading>
                  </div>
                </StyledEducationItemContainer>
              );
            })}
          </StyledEducationListContainer>
        </>
      ) : null}
    </PageContainer>
  );
};

export default WorkAndEducationInformation;

const StyledItemContainer = styled.div`
  background-color: ${COLORS.LOTION};
  position: relative;
  white-space: break-spaces;
  margin-bottom: 30px;
  ${PADDING(30, 20)}
`;

const StyledRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 15px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 0;
  }
`;

const StyledEducationListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const StyledEducationItemContainer = styled.div`
  background-color: ${COLORS.LOTION};
  position: relative;
  white-space: break-spaces;
  ${MARGIN(10)}
  ${PADDING(30, 20)};
`;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.BLACK};
`;

const StyledListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const StyledText = styled(StyledMediumFont)`
  background-color: ${COLORS.CULTURED};
  max-width: max-content;
  ${PADDING(5, 10)}
  ${MARGIN(3)}
`;
