import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import WhiteLogo from '../../assets/svg/recorem_white_logo.svg';
import {
  EXPLORE_EVENTS_LINKS,
  FOR_BUSINESS,
  QUICK_LINKS,
} from './constants/links';
import { NewTabLinks, socailLinks, StyledLI, StyledUL } from './common';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { largeFontMixin, mediumFontMixin } from '../../styles/constants/mixins';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { LocationIcon, MailIcon } from '../Common/icons';
import MainContainer from '../Common/MainContainer';
import { getActiveCategories } from '../../service';
import { MainWebsiteRoutes } from '../../constants/routes';
import Loading from '../Common/Loading';

const FooterContent: React.FC = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const categories = await getActiveCategories();
      setCategoriesData(categories);
      setIsLoading(false);
    })();
  }, []);

  return (
    <StyledWrapper>
      <MainContainer>
        <StyledContent>
          <StyledLeftContentBox>
            <StyledColumnContainer>
              <StyledRecoremLogo src={WhiteLogo} alt="logo" />

              <StyledSpan>Discover Talent in Specialized Gatherings</StyledSpan>
            </StyledColumnContainer>
            <StyledColumnContainer>
              <StyledLocation>
                <LocationIcon />
                <span>79 Madison Avenue, FL 8, New York 10016, USA</span>
              </StyledLocation>
            </StyledColumnContainer>
            <StyledColumnContainer>
              <StyledContact>
                <MailIcon />
                <span>support@recorem.com</span>
              </StyledContact>
            </StyledColumnContainer>
            <StyledColumnContainer>
              <StyleLabel>Follow us</StyleLabel>
              <StyledUL>
                {socailLinks.map((social, idx) => (
                  <StyledLI key={idx.toString()}>
                    <NewTabLinks link={social.link}>{social.icon}</NewTabLinks>
                  </StyledLI>
                ))}
              </StyledUL>
            </StyledColumnContainer>
          </StyledLeftContentBox>
          <StyledTabletLine />
          <StyledLinks>
            <StyledColumns>
              <p>Explore Events</p>
              {isLoading ? (
                <Loading color={COLORS.WHITE} />
              ) : (
                (categoriesData && categoriesData.length
                  ? categoriesData
                  : EXPLORE_EVENTS_LINKS
                ).map((item: any, id: any) => {
                  const name: string = get(
                    item,
                    'name',
                    get(item, 'title', ''),
                  );
                  // this expression will convert all the first charater of words in this string into upper case
                  const finalName = name.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter: any) => letter.toUpperCase(),
                  );
                  return (
                    <div key={id.toString()}>
                      <StyledLink
                        href={get(
                          item,
                          'link',
                          `${
                            MainWebsiteRoutes.home
                          }/explore-events?search=&category=${encodeURIComponent(
                            toLower(name).replace(/\s+/g, '_'),
                          )}`,
                        )}
                      >
                        {finalName}
                      </StyledLink>
                    </div>
                  );
                })
              )}
            </StyledColumns>

            <nav>
              <StyledColumns>
                <p>For Business</p>
                {FOR_BUSINESS.map((item, id) => (
                  <div key={id.toString()}>
                    <StyledLink href={item.link}>{item.title}</StyledLink>
                  </div>
                ))}
              </StyledColumns>
            </nav>

            <StyledLine />

            <nav>
              <StyledColumns>
                <p>Quick Links</p>
                <div>
                  {QUICK_LINKS.map((item, id) => (
                    <div key={id.toString()}>
                      <StyledLink href={item.link}>{item.title}</StyledLink>
                    </div>
                  ))}
                </div>
              </StyledColumns>
            </nav>
            <StyledLine />

            {/* // Will add back when links are avaliable.
            <StyledColumns>
              <p>Compare</p>
              {COMPARE_LINKS.map((item, id) => (
                <div key={id}>
                  <Link href={item.link}>{item.title}</Link>
                </div>
              ))}
            </StyledColumns>
            

            <StyledLine /> */}
          </StyledLinks>
        </StyledContent>
      </MainContainer>
    </StyledWrapper>
  );
};

export default FooterContent;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.WHITE};
  opacity: 0.1;
`;

const StyledLine = styled(Line)`
  grid-column: 1/-1;

  @media (min-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_8}) {
    display: none;
  }
`;

const StyledTabletLine = styled(Line)`
  display: none;
  margin: 20px 0 0 0;

  @media (min-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_8}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: block;
  }
`;

const StyledWrapper = styled.div`
  background-color: ${COLORS.EERIE_BLACK};
  color: ${COLORS.ARGENT};
  padding: 20px 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: 0;
  }
`;

const StyledRecoremLogo = styled.img`
  width: 100%;
  max-width: 160px;
  min-width: 100px;
  margin: 0;
`;

const StyledSpan = styled.span`
  color: ${COLORS.ARGENT};
  margin-top: 10px;
  opacity: 0.6;
  ${largeFontMixin};
`;

const StyledColumnContainer = styled.div`
  ${DISPLAY_FLEX('column')}
`;

const StyledLeftContentBox = styled.div`
  ${DISPLAY_FLEX('column')};
  gap: 25px;
  padding-top: 25px;
`;

const StyleLabel = styled.label`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${largeFontMixin};
`;

const StyledLinks = styled.div`
  ${DISPLAY_FLEX()}
  gap: 60px;
  margin: 25px 0px 0 -40px;
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    justify-content: space-between;
    gap: 50px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin: 25px 0 0 0;
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_8}) {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 20px;
  }
`;

const StyledContent = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'flex-start')};

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    ${DISPLAY_FLEX('column-reverse')};
  }
`;

const StyledColumns = styled.div`
  p {
    margin-bottom: 1em;
    font-weight: ${FONT_WEIGHTS.BOLD};
    ${mediumFontMixin};
    color: ${COLORS.ARGENT};
  }

  a {
    ${mediumFontMixin};
    cursor: pointer;
    padding: 3.2px 0;
  }

  div {
    margin-bottom: 3px;
  }
`;

const StyledLocation = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 10px;

  svg {
    width: 20px;
  }

  span {
    ${largeFontMixin};
  }
`;

const StyledContact = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 10px;

  svg {
    width: 25px;
  }

  span {
    ${largeFontMixin};
  }
`;

const StyledLink = styled.a`
  &:hover {
    color: ${COLORS.LINK_BLUE} !important;
  }
`;
