// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, message } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import TagManager from 'react-gtm-module';
import Googlelogin from './GoogleLogin';
import LinkedinLogin from './LinkedinLogin';
import Twitterlogin from './TwitterLogin';
import {
  CANDIDATE,
  ORGANIZER,
  RECRUITER,
  SIGNUP_PAGE_FORMS,
} from '../../constants/index';
import {
  checkIfUserExist,
  getUserLocation,
  resentOtp,
  forgotPassword,
} from '../../service/auth';
import { COLORS } from '../../styles/constants/colors';
import {
  InitialEmailForm,
  LoginWithOTPForm,
  PasswordLoginForm,
  SignupOrLoginForm,
} from './common';
import {
  loginUser,
  loginUserWithOTP,
  signupWithOTPData,
  socialLoginUser,
  socialSignupUser,
} from '../../store/features/auth';
import { getUrlVars } from '../../utils/common';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getUserTypeWithTheme,
} from '../../utils/userInfo';
import { getProfileData } from '../../store/features/profile';
import Notification from '../../components/Notification/Notification';
import { setApplyJobDatatoLocal } from '../../store/features/commons';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MainWebsiteRoutes } from '../../constants/routes';
import { setUserAnonymousName } from '../../store/features/chat';
import { getFormInfoByUser } from '../../utils/auth';
import { getCryptoIncentivePlan } from '../../store/features/cryptoPlan';
import {
  getIncomingBalanceStore,
  getWalletInformation,
} from '../../store/features/virtualWallet';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import {
  candidateHeaderText,
  organizerHeaderText,
  recruiterHeaderText,
} from '../../constants/texts/signup';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import useQuery from '../../hooks/useQuery';

const getHeaderText = (roleId) => {
  switch (roleId) {
    case CANDIDATE:
      return candidateHeaderText;
    case RECRUITER:
      return recruiterHeaderText;
    case ORGANIZER:
      return organizerHeaderText;
    default:
      return '';
  }
};

const SignUpAndLoginModal: React.FC<any> = ({
  roleId,
  setRoleId,
  setIsSignAction,
  closeModal = null,
  isModal = false,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();
  const crewInviteToken = query.get('token');
  const [selectedPage, setSelectedPage] = useState(SIGNUP_PAGE_FORMS.INITIAL);
  const dispatch = useDispatch();
  const [isInProcess, setIsInProcess] = useState(false);
  const { inviteToken, eventId, appliedJob, event } = getUrlVars(
    get(history, 'location.search', {}),
  );
  const [isLogin, setIslogin] = useState(false);

  const [rememberMe, setRememberMe] = useState(true);

  const { isAuthenticated, user: userData } = useSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (isAuthenticated && (sessionStorage.length || localStorage.length)) {
      if (typeof closeModal === 'function') closeModal();
      dispatch(getProfileData()).then(() => {
        const { roleId: userRole, userId } = userData;
        if (checkIfUserIsCandidate(userRole)) {
          dispatch(setUserAnonymousName(true));
        }

        if (
          checkIfUserIsCandidate(userRole) ||
          checkIfUserIsOrganizer(userRole)
        ) {
          dispatch(getWalletInformation());
          dispatch(getIncomingBalanceStore());
        }

        if (checkIfUserIsRecruiter(userRole)) {
          dispatch(getCryptoIncentivePlan());
          dispatch(getWalletInformation());
        }
        TagManager.dataLayer({
          dataLayer: {
            loggedInUserId: userId,
            loggedInUserRole: toLower(getUserTypeWithTheme(roleId)),
            userAction: isLogin ? 'login' : 'signup',
          },
        });

        if (window.gtag && !isLogin) {
          window.gtag('event', 'conversion', {
            send_to: 'AW-10928506931/70EgCJjdocMDELOQj9so',
          });
        }

        const redirect =
          inviteToken && eventId
            ? `events/view/${eventId}?inviteToken=${inviteToken}`
            : eventId
            ? `events/view/${eventId}`
            : '';
        const crisp = document.getElementById('crisp-chatbox');
        if (crisp) {
          crisp.outerHTML = '';
          crisp.remove();
        }

        if (isModal) {
          // this is because the modal is still mounted after login in the community page, so the inputs are still there when we try to logout and login again.
          form.resetFields();
          setSelectedPage(SIGNUP_PAGE_FORMS.INITIAL);
          return;
        }
        if (appliedJob && event) {
          dispatch(setApplyJobDatatoLocal({ appliedJob, eventId: event }));
        }

        if (checkIfUserIsOrganizer(userRole))
          history.push(`/organizer/${redirect}`);
        else if (checkIfUserIsRecruiter(userRole)) {
          history.push(`/recruiter/${redirect}`);
        } else if (checkIfUserIsCandidate(userRole))
          history.push(`/candidate/${redirect}`);
        else message.error('...');
      });
    }

    // eslint-disable-next-line
  }, [isAuthenticated, userData, eventId, inviteToken, closeModal]);

  /*
  const recoremFor = {
    [ORGANIZER]: 'Organizers',
    [RECRUITER]: 'Recruiters',
    [CANDIDATE]: 'Attendees',
  };

  */

  const handleSignup = async (data: any) => {
    setIsInProcess(true);
    const { email, otp } = data;

    const { country_name: country } = await getUserLocation();

    let payload = {
      email,
      otp,
    };

    if (country) {
      payload = { ...payload, country };
    }
    setIsSignAction(true);
    dispatch(signupWithOTPData(payload, roleId)).then(() => {
      setIsInProcess(false);
    });
  };

  const handleResendOtp = (
    errors,
    email,
    userRole = null,
    allowSuccess = true,
  ) => {
    if ((errors && errors.length) || !email) {
      message.error('Please enter valid email');
      return;
    }
    resentOtp(email, userRole || roleId).then(() => {
      if (allowSuccess) {
        Notification(
          'success',
          'OTP sent successfully',
          'OTP has been sent to your email address',
        );
      }
    });
  };

  const handleUserStatue = ({ email }) => {
    // api request to check if user exist or not.
    // if user exist then we only show otp input , other wise all signup inputs
    checkIfUserExist(email, false, roleId).then(async (response) => {
      const { isUserExist, roleId: userRole } = response;
      if (isUserExist) {
        setSelectedPage(SIGNUP_PAGE_FORMS.LOGIN_OTP);
        setRoleId(userRole);
        await handleResendOtp(null, email, userRole, true);
      } else if (roleId === ORGANIZER) {
        handleCompanyRedirect(MainWebsiteRoutes.organizer, ORGANIZER);
      } else if (roleId === RECRUITER) {
        handleCompanyRedirect(MainWebsiteRoutes.recruiter, RECRUITER);
      } else {
        await handleResendOtp(
          null,
          form.getFieldValue('email'),
          CANDIDATE,
          true,
        );
        setSelectedPage(SIGNUP_PAGE_FORMS.SIGNUP);
      }
    });
  };

  /*
  const handleCancel = () => {
    setSelectedPage(SIGNUP_PAGE_FORMS.INITIAL);
    handleClose();
    form.resetFields();
  };
*/

  const handleLoginWithPassword = ({ email, password }) => {
    setIslogin(true);
    setIsSignAction(true);
    dispatch(loginUser(email, password, rememberMe, null, crewInviteToken));
  };

  const handleLoginWithOtp = ({ email, otp }) => {
    setIslogin(true);
    setIsSignAction(true);
    dispatch(loginUserWithOTP(email, otp, roleId, rememberMe, crewInviteToken));
  };

  const rememberMeOnChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleForgotPassword = (errors, email) => {
    if ((errors && errors.length) || !email) {
      message.error('Please enter a valid email');
      return;
    }
    forgotPassword({ email })
      .then(() => {
        Notification(
          'success',
          'Password Reset',
          'Please check your registered email to reset password',
        );
      })
      .catch(() => {
        Notification(
          'error',
          'Password Reset',
          'User with the given email does not exist',
        );
      });
  };

  const getSelectedForm = (color) => {
    switch (selectedPage) {
      case SIGNUP_PAGE_FORMS.INITIAL:
        return (
          <InitialEmailForm
            form={form}
            onFinish={handleUserStatue}
            setSelectedPage={setSelectedPage}
            color={color}
            roleId={roleId}
            rememberMeOnChange={rememberMeOnChange}
            rememberMe={rememberMe}
          />
        );
      case SIGNUP_PAGE_FORMS.SIGNUP:
        return (
          <SignupOrLoginForm
            form={form}
            onFinish={handleSignup}
            color={color}
            roleId={roleId}
            onSuffixClick={handleResendOtp}
            selectedPage={selectedPage}
            onBackClick={() => setSelectedPage(SIGNUP_PAGE_FORMS.INITIAL)}
            rememberMeOnChange={rememberMeOnChange}
            rememberMe={rememberMe}
            isInProcess={isInProcess}
          />
        );
      case SIGNUP_PAGE_FORMS.PASSWORD_LOGIN:
        return (
          <PasswordLoginForm
            form={form}
            onFinish={handleLoginWithPassword}
            setSelectedPage={setSelectedPage}
            color={color}
            roleId={roleId}
            rememberMeOnChange={rememberMeOnChange}
            rememberMe={rememberMe}
            handleForgotPassword={handleForgotPassword}
          />
        );
      case SIGNUP_PAGE_FORMS.LOGIN_OTP:
        return (
          <LoginWithOTPForm
            form={form}
            onFinish={handleLoginWithOtp}
            setSelectedPage={setSelectedPage}
            color={color}
            roleId={roleId}
            onSuffixClick={handleResendOtp}
            selectedPage={selectedPage}
            rememberMeOnChange={rememberMeOnChange}
            rememberMe={rememberMe}
          />
        );
      default:
        break;
    }
  };

  const handleSocialSignup = async (data) => {
    const candidateData = {
      provider: data.provider,
      token: data.token,
    };

    let body = candidateData;

    if (data.provider === 'twitter') {
      body = { ...data, ...body };
    }
    setIsSignAction(true);
    dispatch(socialLoginUser(body, rememberMe)).then(async (response) => {
      const userDoesNotExist = get(response, 'userDoesNotExist', null);
      /*
       const email = get(response, 'email', null);
      if (userDoesNotExist && !checkIfUserIsCandidate(roleId)) {
        setSocialSignUpData({ ...body, email });
        handleSocialModalOpen();
      } else 
      */
      if (userDoesNotExist) {
        dispatch(socialSignupUser({ ...body, role: roleId }, rememberMe));
      } else {
        setIslogin(true);
      }
    });
  };

  /*
  const handleSocialSignupForCompanies = async ({
    companyName,
    companyWebsite,
  }) => {
    const response = await checkIfCompanyExist({
      companyName,
      companyWebsite,
      roleId,
    });

    const doesCompanyExist = get(response, 'doesCompanyExist', false);

    const email = get(socialSignupData, 'email', null);
    if (doesCompanyExist) {
      // have to pass a call back for existing company, api call to send mail to crew admin.
      const companyId = get(response, 'companyId', false);
      showConfirm(() =>
        handleConfirmCrewInvite(companyId, email).then(() => {
          handleSocialModalClose();
        }),
      );
      return;
    }
    const token = get(socialSignupData, 'token', null);
    const provider = get(socialSignupData, 'provider', null);
    const data = omit(socialSignupData, ['email']);
    if (token && provider) {
      dispatch(
        socialSignupUser(
          {
            ...data,
            token,
            provider,
            companyName,
            companyWebsite,
            role: roleId,
          },
          rememberMe,
        ),
      );
    } else {
      Notification('error', 'Social Signup Failed');
    }
  };
  */

  const handleCompanyRedirect = async (baseUrl: string, role: number) => {
    const email = form.getFieldValue('email');
    await handleResendOtp(null, email, role, false);
    var url = `${baseUrl}?email=${encodeURIComponent(email)}`;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userAction: 'signup_modal_open',
      },
    });

    // eslint-disable-next-line
  }, []);

  const handleChangeUser = (userRoleId) => {
    setRoleId(userRoleId);
  };

  const infoWithUser = getFormInfoByUser(roleId);

  const heading = get(infoWithUser, 'heading', '');
  const subHeading = get(infoWithUser, 'subHeading', '');
  const iconSrc = get(infoWithUser, 'icon', '');

  const primaryColor = get(infoWithUser, 'primaryColor', '#695edd');

  const firstButtonUserType = get(infoWithUser, 'buttons.firstButton.userType');

  const firstButtonColor = get(
    infoWithUser,
    'buttons.firstButton.color',
    '#00000',
  );
  const firstButtonBgColor = get(
    infoWithUser,
    'buttons.firstButton.bgColor',
    '#47BFA8',
  );
  const firstButtonText = get(
    infoWithUser,
    'buttons.firstButton.text',
    'Post Jobs',
  );

  const secondButtonUserType = get(
    infoWithUser,
    'buttons.secondButton.userType',
  );

  const secondButtonColor = get(
    infoWithUser,
    'buttons.secondButton.color',
    COLORS.WHITE,
  );

  const secondButtonBgColor = get(
    infoWithUser,
    'buttons.secondButton.bgColor',
    '#47BFA8',
  );

  const secondButtonText = get(
    infoWithUser,
    'buttons.secondButton.text',
    'List Events',
  );

  const isCandidate = roleId === CANDIDATE;
  const isSignupPage = selectedPage === SIGNUP_PAGE_FORMS.SIGNUP;

  return (
    <StyledMainContainer color={primaryColor} isModal={isModal}>
      {/* Note: don't show anything below if candidate has started sign-up */}
      {selectedPage !== SIGNUP_PAGE_FORMS.SIGNUP ? (
        <>
          <StyledFooterContainer>
            <StyledFooterText isModal={isModal}>
              {getHeaderText(roleId)}
            </StyledFooterText>
            <StyledSwitchButtonContainer>
              <StyledSwitchButton
                bgColor={firstButtonBgColor}
                color={firstButtonColor}
                onClick={() => handleChangeUser(firstButtonUserType)}
              >
                {firstButtonText}
              </StyledSwitchButton>
              <StyledSwitchButton
                bgColor={secondButtonBgColor}
                color={secondButtonColor}
                onClick={() => handleChangeUser(secondButtonUserType)}
              >
                {secondButtonText}
              </StyledSwitchButton>
            </StyledSwitchButtonContainer>
          </StyledFooterContainer>
          <StyledDevider />
        </>
      ) : null}
      <StyledHeaderContainer>
        <StyledHeadingContainer>
          <StyledHeadingText>{heading}</StyledHeadingText>
          <StyledIconContainer>
            <img src={iconSrc} alt="icon" />
          </StyledIconContainer>
        </StyledHeadingContainer>
        <StyledSubHeading color={primaryColor}>{subHeading}</StyledSubHeading>
      </StyledHeaderContainer>
      {isCandidate ? (
        <>
          <StyledSocialLoginContainer>
            <StyledSocialText>Login with</StyledSocialText>
            <Twitterlogin setOauthLogin={handleSocialSignup} />
            <Googlelogin setOauthLogin={handleSocialSignup} />
            <LinkedinLogin setOauthLogin={handleSocialSignup} />
          </StyledSocialLoginContainer>
          <StyledTextContainer>Or</StyledTextContainer>
        </>
      ) : null}

      <StyledFormContainer isSignupPage={isSignupPage}>
        {getSelectedForm(primaryColor)}
      </StyledFormContainer>
    </StyledMainContainer>
  );
};

export default SignUpAndLoginModal;

const StyledMainContainer = styled.div`
  max-width: 407px;
  width: 100%;
  ${DISPLAY_FLEX('column', 'center', 'center')}
  font-family: Poppins;
  background-color: ${COLORS.WHITE};
  ${({ isModal }) => (isModal ? PADDING(0) : PADDING(19, 23, 29, 23))};
  ${({ isModal, color }) => !isModal && `box-shadow: 4px 4px 0px ${color};`}
  ${({ isModal, color }) => !isModal && `border: 2px solid ${color};`}
  border-radius: 12px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    max-width: 427px;
  }

  @media (max-width: 400px) {
    max-width: 375px;
  }

  @media (max-width: 383px) {
    max-width: 340px;
    ${PADDING(17, 15, 20, 14)};
  }

  @media (max-width: 350px) {
    max-width: 320px;
  }

  @media (max-width: 328px) {
    max-width: 312px;
  }
`;

// Header
const StyledHeaderContainer = styled.div`
  ${DISPLAY_FLEX('column', null, null)};
  row-gap: 2px;
  ${MARGIN(0, 0, 25, 0)}
  text-align: center;
`;

const StyledHeadingContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')};
  column-gap: 5px;
`;

const StyledHeadingText = styled.h2`
  font-size: ${FONT[25]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 38px;
  letter-spacing: 0;
  color: ${COLORS.GRAY_5};
  ${MARGIN(0, 0, 2, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[27]};
  }
`;

const StyledIconContainer = styled.div`
  ${PADDING(0, 0, 2, 0)};
`;

const StyledSubHeading = styled.h3`
  font-size: ${FONT[16]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 24px;
  letter-spacing: 0;
  color: ${({ color }) => color};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

// Social login
const StyledSocialLoginContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')};
  column-gap: 22px;
  ${MARGIN(0, 0, 18, 0)}
`;

const StyledSocialText = styled.span`
  color: ${COLORS.GRAY_4};
  font-size: ${FONT[16]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  line-height: 24px;
  letter-spacing: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  @media (max-width: 450px) {
    font-size: ${FONT[14]};
  }
`;

const StyledTextContainer = styled.span`
  font-size: ${FONT[16]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  line-height: 24px;
  letter-spacing: 0;
  color: ${COLORS.BLACK}80;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

const StyledFormContainer = styled.div`
  ${DISPLAY_FLEX('column', 'center', 'center')};
  width: 100%;

  margin-bottom: ${({ isSignupPage }) => (!isSignupPage ? `9.5px` : '0')};

  .ant-form {
    width: 100%;

    > div {
      margin-bottom: ${({ isSignupPage }) => (!isSignupPage ? `20px` : '0')};
    }

    label {
      margin-bottom: ${({ isSignupPage }) => (!isSignupPage ? `13px` : '0')};
    }
  }
`;

const StyledDevider = styled.div`
  border: 1px solid ${COLORS.BLACK}26;
  width: 354.5px;
  height: 0;
  ${MARGIN(22, 0)}
`;

// Footer
const StyledFooterContainer = styled.div`
  ${DISPLAY_FLEX('column', 'center', 'center')};
  width: 100%;
`;

const StyledFooterText = styled.span`
  font-size: ${FONT[20]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 24px;
  letter-spacing: 0.15em;
  color: ${COLORS.BLACK};
  text-align: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${({ isModal }) => !isModal && `font-size: ${FONT[18]};`}
  }
`;

const StyledSwitchButtonContainer = styled.div`
  width: 100%;
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  column-gap: 20px;
  ${PADDING(0, 14)}
  ${MARGIN(24, 0, 0, 0)};
`;

const StyledSwitchButton = styled.button`
  height: 41px;
  width: 121px;
  border-radius: 5px;
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border: none;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ bgColor }) => `0px 0px 13px ${bgColor}`};
  }

  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 21px;
  letter-spacing: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;
