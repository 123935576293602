import React from 'react';
import { Link } from 'react-router-dom';
import isInteger from 'lodash/isInteger';
import { CandidateRoutes } from '../../../constants/routes';
import { JobResultTabValue } from '../../../constants/index';
import EarthIcon from '../../../assets/icons-and-sets/047-earth-grid.svg';
import ApplicationIcons from '../../../assets/icons-and-sets/049-copy.svg';
import BreifCaseOutlinedIcon from '../../../assets/icons-and-sets/briefcase.svg';
import BussinessUserIcon from '../../../assets/icons-and-sets/031-businessman.svg';
import CancelIcon from '../../../assets/icons-and-sets/034-cancel.svg';
import { IconBadge } from '../../shared-components/IconBadge';
import { COLORS } from '../../../styles/constants/colors';

const CandidateAnalytics = ({ analytics, theme }) => {
  if (!analytics) {
    return null;
  }
  const {
    bookedEvents,
    applicationsSubmitted,
    applicationsShortlisted,
    interviewsScheduled,
    applicationsRejected,
  } = analytics;

  if (
    !isInteger(bookedEvents) ||
    !isInteger(applicationsSubmitted) ||
    !isInteger(applicationsShortlisted) ||
    !isInteger(interviewsScheduled) ||
    !isInteger(applicationsRejected)
  ) {
    return null;
  }

  const itemList = [
    {
      icon: EarthIcon,
      itemName: 'Registered Events',
      itemValue: bookedEvents,
      to: CandidateRoutes.event,
    },
    {
      icon: ApplicationIcons,
      itemName: 'Application Submitted',
      itemValue: applicationsSubmitted,
      to: `${CandidateRoutes.jobResults}`,
    },
    {
      icon: BreifCaseOutlinedIcon,
      itemName: 'Shortlisted',
      itemValue: applicationsShortlisted,
      to: `${CandidateRoutes.jobResults}`,
    },
    {
      icon: BussinessUserIcon,
      itemName: 'Interview Scheduled',
      itemValue: interviewsScheduled,
      to: `${CandidateRoutes.calender}${JobResultTabValue}`,
    },
    {
      icon: CancelIcon,
      itemName: 'Skipped',
      itemValue: applicationsRejected,
      to: `${CandidateRoutes.jobResults}`,
    },
  ];
  return (
    <>
      {itemList.map((item) => (
        <Link to={item.to}>
          <IconBadge
            icon={item.icon}
            text={item.itemName}
            textColor={COLORS.WHITE}
            status={item.itemValue}
            theme={theme}
            hoverEffect
            color={COLORS.MOODY_BLUE}
            isCandidate
          />
        </Link>
      ))}
    </>
  );
};

export default CandidateAnalytics;
