import { css } from 'styled-components';
import { BREAKPOINTS } from './breakpoints';
import { FONT } from './typography';

export const mediumHeadingMixin = css`
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const mediumLargeHeadingMixin = css`
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[20]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const smallFontMixin = css`
  font-size: ${FONT[12]};

  @media (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[12]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[10]};
  }
`;

export const mediumFontMixin = css`
  font-size: ${FONT[12]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[14]};
  }

  @media (max-width: $mobile) {
    font-size: ${FONT[12]};
  }
`;

export const largeFontMixin = css`
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const sectionHeadingMixin = css`
  font-size: ${FONT[14]};
  line-height: 21px;
  position: relative;
  padding-left: 22px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};

    &::before {
      top: -3px;
      width: 13px;
      height: 30px;
    }
`;

export const smallHeadingMixin = css`
  font-size: ${FONT[14]} !important;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }
`;

export const semiLargeHeadingMixin = css`
  font-size: ${FONT[16]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[20]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const largeHeadingMixin = css`
  font-size: ${FONT[18]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[24]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

export const extraLargeHeadingMixin = css`
  font-size: ${FONT[24]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[30]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }
`;

// alignCenter
export const alignCenterMixin = css`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const SpeciallargeFontMixin = css`
  font-size: ${FONT[13]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[15]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;
