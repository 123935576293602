import React, { useEffect, useRef } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import first from 'lodash/first';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { DISPLAY_FLEX, SHARP_IMAGE } from '../../../styles/constants/display';
import { getFileS3 } from '../../../utils/s3.storage';
import { getUpComingEvent } from '../../../utils/event';
import VideoSwitch from '../../../assets/svg/video.svg';
import VideoPreview from './VideoPreview';

export const LiveEventBroadcardMock =
  'https://www.youtube.com/embed/saWNMPL5ygk?autoplay=1&rel=0&showinfo=0' ||
  'https://player.vimeo.com/video/656583479?h=e8b7f06c52&color=00ff00&title=0&byline=0&portrait=0&badge=0';

export const EventCover =
  'https://assets.recorem.com/contents/image_5b0f21ad34b32a0859fd3a3a4db4f8bf7cceaeaa.png';

export const VideoPlayer: React.FC<any> = ({ event }: any) => {
  const links = get(event, 'streamUrlsData', []);
  const iframe = useRef<any>({});
  const overlay = useRef<any>({});
  const openOverlay = useRef<any>({});
  const closeOverlay = useRef<any>({});

  const handleSelectLink = (link: string) => {
    if (iframe.current) {
      iframe.current.src = link
        ? `${link}?showinfo=0&autoplay=1&controls=1&enablejsapi=1&modestbranding=1&cc_load_policy=1`
        : LiveEventBroadcardMock;
    }
  };

  useEffect(() => {
    const link = first(links);
    handleSelectLink(get(link, 'url', ''));
    // eslint-disable-next-line
  }, []);

  const handleCloseOverlay = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (overlay.current) {
      overlay.current.style.display = 'none';
    }
    if (closeOverlay.current) {
      closeOverlay.current.style.display = 'none';
    }
    if (openOverlay.current) {
      openOverlay.current.style.display = 'block';
    }
  };

  const handleOpenOverlay = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (overlay.current) {
      overlay.current.style.display = 'block';
    }
    if (closeOverlay.current) {
      closeOverlay.current.style.display = 'flex';
    }
    if (openOverlay.current) {
      openOverlay.current.style.display = 'none';
    }
  };

  return links.length && !getUpComingEvent(event) ? (
    <StyledVideoPlayer id="live-event-video-wrapper">
      <StyledIframeWrapper
        id="live-event-video-iframe-wrapper"
        style={{ display: 'block' }}
      >
        <StyledIframe
          id="live-event-video-iframe"
          ref={iframe}
          frameBorder="0"
          title="live-event"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <StyledOverlay ref={overlay}>
          {links && links.length ? (
            links.map((link: any, idx: number) => {
              return (
                <VideoPreview
                  key={idx.toString()}
                  handleSelectLink={handleSelectLink}
                  link={link.url}
                  title={link.title}
                  event={event}
                />
              );
            })
          ) : (
            <StyledPlaceHolderText>
              No Broadcasts at the moment.
            </StyledPlaceHolderText>
          )}
        </StyledOverlay>
        <StyledCloseOverlayWrapper ref={closeOverlay}>
          <StyledCloseOverlay onClick={handleCloseOverlay}>
            <PlusOutlined />
          </StyledCloseOverlay>
        </StyledCloseOverlayWrapper>
        <StyledOverlayButton ref={openOverlay}>
          <img
            src={VideoSwitch}
            onClick={handleOpenOverlay}
            alt="event-close"
            role="none"
          />
        </StyledOverlayButton>
      </StyledIframeWrapper>
    </StyledVideoPlayer>
  ) : (
    <StyledEventBanner
      src={getFileS3(get(event, 'cover', EventCover))}
      alt="event-cover"
      id="live-event-video-wrapper"
    />
  );
};

const StyledVideoPlayer = styled.div`
  display: block;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-color: black;
  height: 400px;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 450px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: auto;
    min-height: 250px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_7}) {
    height: auto;
    min-height: 170px;
  }

  &:hover svg {
    display: inline-block;
  }

  &:hover img {
    display: inline-block !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    z-index: 999;
    margin-bottom: -10px;
    background-color: ${COLORS.LOTION};
    border-radius: 0 0 12px 12px;
    position: fixed;
    left: 0;
    padding: 60px 20px;
  }
`;

const StyledIframeWrapper = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  border-radius: 15px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 450px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: auto;
    min-height: 250px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_7}) {
    height: auto;
    min-height: 170px;
  }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: inherit;
  min-height: inherit;
  border-radius: inherit;
`;

const StyledOverlay = styled.div`
  position: absolute;
  height: 400px;
  top: 0px;
  right: 0px;
  width: 40%;
  border-radius: 15px;
  display: none;
  background-color: rgb(0 0 0 / 75%);
  padding: 10px 12px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GRAY2};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.GRAY2};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 450px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 250px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_7}) {
    height: 170px;
  }
`;

const StyledCloseOverlayWrapper = styled.div`
  position: absolute;
  background-color: black;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  display:none;
  top: 20px;
  right: calc(40% - 5px);
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 30px;
    height: 30px;
  }
`;

const StyledCloseOverlay = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  svg {
    fill: ${COLORS.WHITE};
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
  }
`;

const StyledEventBanner = styled.img<any>`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  ${SHARP_IMAGE}
  z-index: 999;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 450px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    min-height: 180px;
    height: auto;
    margin-top: 20px;
  }
`;

const StyledOverlayButton = styled.div`
  position: absolute;
  top: 70px;
  right: 30px;
  display: block;

  img {
    cursor: pointer;
    display: none !important;
    fill: ${COLORS.WHITE};
    width: 28px !important;
    height: 28px !important;
    opacity: 0.8;

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      display: block !important;
      width: 19px !important;
      height: 19px !important;
    }
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    top: 50px;
    right: 20px;
  }
`;

const StyledPlaceHolderText = styled.span`
  color: ${COLORS.WHITE};
`;
