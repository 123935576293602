import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { getCalendarEventDetailHeading } from '../../utils/calendar';
import { getEventDateTime } from '../../utils/formatDateTime';
import { getEventTypeText } from '../../utils/analyticsModal';
import { CalendarIcon } from '../svgicons';
import { ONLINE_EVENT_TEXT } from '../../constants';
import { BOX_SHADOW } from '../../styles/constants/box-shadow';

const CalendarEventDetail = ({ items, roleId, getEventBagdeColor, date }) => {
  const getText = (item) => {
    if (item.isInterview) {
      // candidate name
      return item.name;
    }
    // Return event type
    return item.eventType ? getEventTypeText(item) : ONLINE_EVENT_TEXT;
  };

  const getColor = (item) => {
    return item.isInterview
      ? getUserColorWithRoleId(roleId)
      : getEventBagdeColor(date, item, roleId);
  };

  return (
    <StyledContainer>
      <StyledUl>
        {items.map((item, idx) => {
          return (
            <StyledCardContainer key={item.id}>
              <header>
                {/* Exp: keep heading if zero'th element */}
                {!idx ? (
                  <StyledHeading color={getColor(item)}>
                    {getCalendarEventDetailHeading(date, item, roleId)}
                  </StyledHeading>
                ) : null}

                {/* Exp: skip heading if frequent same title element */}
                {getCalendarEventDetailHeading(date, item, roleId) !==
                getCalendarEventDetailHeading(
                  date,
                  idx ? items[idx - 1] : item,
                  roleId,
                ) ? (
                  <StyledHeading color={getColor(item)}>
                    {getCalendarEventDetailHeading(date, item, roleId)}
                  </StyledHeading>
                ) : null}
                <StyledBorderContainer>
                  <StyledBorderLine />
                </StyledBorderContainer>
              </header>
              <StyledMain>
                <div>
                  <StyledCircleIcon color={getColor(item)} />
                </div>
                <div>
                  <StyledText>
                    {/* Exp: Job name for interview, else event name */}
                    {item.isInterview ? item.job : item.name}
                  </StyledText>
                  <StyledUserText>{getText(item)}</StyledUserText>
                  <StyledDateTimeContainer>
                    <div>
                      <StyledTimeDateText>
                        {getEventDateTime(item).start}
                      </StyledTimeDateText>
                      <StyledTimeDateMiddleText> to</StyledTimeDateMiddleText>
                      <StyledTimeDateText>
                        {getEventDateTime(item).end}
                      </StyledTimeDateText>
                    </div>
                    <StyledCalendarContainer>
                      <CalendarIcon />
                    </StyledCalendarContainer>
                  </StyledDateTimeContainer>
                </div>
              </StyledMain>
            </StyledCardContainer>
          );
        })}
      </StyledUl>
    </StyledContainer>
  );
};

export default CalendarEventDetail;

const StyledContainer = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  margin: 10px;
  box-shadow: ${BOX_SHADOW[1]};
  cursor: pointer;
`;

const StyledUl = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  border-radius: 6px;
`;

const StyledCardContainer = styled.li`
  background-color: ${COLORS.WHITE};
  padding: 2px 12px;
  max-width: 340px;
  min-width: 220px;
`;

const headingFontSize = css`
  font-size: 12px;
`;

const StyledHeading = styled.h4`
  ${headingFontSize};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${({ color }) => color};
  margin: 10px 0;
`;

const StyledBorderContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center')};
  ${MARGIN(0, 0, 8)}
`;

const StyledBorderLine = styled.div`
  background-color: ${COLORS.CHINESE_SILVER};
  width: 100%;
  height: 1px;
`;

const StyledMain = styled.main`
  display: flex;
`;

const StyledCircleIcon = styled.div`
  background-color: ${({ color }) => color};
  width: 15px;
  height: 15px;
  border-radius: 10px;
  ${MARGIN(5, 8, 2, 0)};
`;

const StyledText = styled.span`
  ${headingFontSize};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  letter-spacing: 0.6px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledUserText = styled.span`
  display: block;
  ${headingFontSize};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  margin-bottom: 3px;
`;

const StyledDateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -7px 0 5px;
`;

const StyledTimeDateText = styled.span`
  display: inline-block;
  ${headingFontSize};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.GRAY};
`;

const StyledTimeDateMiddleText = styled.span`
  ${headingFontSize};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.GRAY};
  margin: 0 3px 0 0;
`;

const StyledCalendarContainer = styled.div`
  height: 20px;
  margin-left: 8px;

  svg {
    width: 15px;
    height: 15px;
    fill: ${COLORS.METALLIC_SILVER};
  }
`;
