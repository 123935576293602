import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEvents, fetchLiveEvents, getEventById } from '../../service/jobOpportunities';

export const getEvents = createAsyncThunk('events/getEvents', () => {
  return fetchEvents();
});

export const fetchEvent = createAsyncThunk('events/getEvent', (id) => {
  return getEventById(id);
});

export const fetchLatestJoinedEvents = createAsyncThunk('events/getLiveEvents', () => {
  return fetchLiveEvents();
});

export const candidateBookedEventsSlice = createSlice({
  name: 'events',
  initialState: {
    events: [],
    status: null,
  },
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.status = 'Loading';
    },
    [getEvents.fulfilled]: (state, action) => {
      state.events = action.payload;
      state.status = 'Success';
    },
    [getEvents.rejected]: (state) => {
      state.status = 'Failed';
    },
  },
});

export default candidateBookedEventsSlice.reducer;
