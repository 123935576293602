/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import { getRoleId } from '../../../utils/userInfo';
import { userProfileStore } from '../../../utils/profile';
import { getSecondUserData } from '../../../utils/chat';
import {
  changeSelectedChatId,
  loadMorePersonalChats,
} from '../../../store/features/chat';
import { TestContext } from '../../../context/testMode';
import { PersonalChatContext } from '../../../context/personalChat';
import { ConversationCard } from '../../shared-components/Chat/ConversationCard';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';

const Conversations = () => {
  const dispatch = useDispatch();
  const testMode = useContext(TestContext);
  const { chatOpened, setChatOpened } = useContext(PersonalChatContext);

  const {
    personalChats,
    selectedChatId,
    personalChatsUsersProfileData: usersProfileData,
    personalChatsPagination,
    personalChatsLoading,
    hasMorePersonalChats,
  } = useSelector((state) => state.chat);

  const page = get(personalChatsPagination, 'chatRoomPage', 1);

  const clickedCard = (id) => {
    dispatch(changeSelectedChatId(id));
  };

  const {
    profileData: { userId, company },
  } = userProfileStore();

  const roleId = getRoleId();

  const loggedInUserId = company ? company.id : userId;

  // Pagination
  // We are setting up IntersectionObserver's instance to observe a reference of the element <p className="chats__loading" ref={lastThreadRef}>Loading</p>.
  // If its visible on the DOM, we are dispatching an action to load more chats.
  const observer = useRef();
  const lastThreadRef = useCallback(
    (node) => {
      if (personalChatsLoading) return;
      // testMode is used for automated testing
      if (!testMode) {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMorePersonalChats) {
            dispatch(
              loadMorePersonalChats(selectedChatId, personalChats, page),
            );
          }
        });
        if (node) observer.current.observe(node);
      }
    },
    [
      dispatch,
      hasMorePersonalChats,
      personalChats,
      personalChatsLoading,
      page,
      testMode,
      selectedChatId,
    ],
  );

  return (
    <StyledChatConversationDiv chatOpened={chatOpened}>
      {personalChats.length || personalChatsLoading ? (
        personalChats.map((item) => {
          const secondUser = getSecondUserData(
            item,
            loggedInUserId,
            usersProfileData,
            roleId,
          );
          return (
            <StyledChatConversationCardDiv
              id="chat-conversation-card"
              selected={selectedChatId === item.id}
              onClick={() => {
                clickedCard(item.id);
                setChatOpened(true);
              }}
            >
              <ConversationCard
                secondUser={secondUser}
                chatData={item}
                key={item.id}
              />
            </StyledChatConversationCardDiv>
          );
        })
      ) : (
        <h1>No Conversation</h1>
      )}
      <StyledChatLoading ref={lastThreadRef}>Loading</StyledChatLoading>
      {personalChatsLoading ? <Skeleton /> : null}
    </StyledChatConversationDiv>
  );
};

const StyledChatConversationDiv = styled.div`
  ${DISPLAY_FLEX('column')};
  width: 32%;
  margin-right: 5px;
  height: 65vh;
  overflow: scroll;
  overflow-x: hidden;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 30%;
    height: 70vh;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => (props.chatOpened ? 'none !important' : '')};
    width: 100%;
    height: 70vh;
    margin-right: 0;
    padding-left: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.ARGENT};
    border-radius: 10px;
    height: 30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.QUICK_SILVER};
  }
`;

const StyledChatConversationCardDiv = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  background-color: ${(props) =>
    props.selected ? COLORS.WHITE : COLORS.LOTION};
  margin-bottom: 4px;
  margin-right: 5px;
  min-height: 70px;
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-right: 10px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-right: 0;
  }

  .online_avatar_wrapper {
    margin-right: 15px;
  }
`;

const StyledChatLoading = styled.p`
  color: ${COLORS.WHITE};
`;

export default Conversations;
