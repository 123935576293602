import { createSlice } from '@reduxjs/toolkit';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { getCandidateRecommendedEvents } from '../../service/candidate';
import { getRecruiterRecommendedEvents } from '../../service/recruiter';
import {
  OrganizerExploreEventsLimit,
  OrganizerExploreEventsPage,
} from '../../constants';
import { getSimilarEvents } from '../../service/event';

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.recommendedEvents = action.payload;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const recommendedEventsSlice = createSlice({
  name: 'recommendedEvents',
  initialState: {
    isLoading: false,
    recommendedEvents: [],
    error: null,
  },
  reducers: { onStart, onSuccess, onError },
});

const getRecommendedEventsData = (
  roleId,
  page,
  limit,
  isLivePriority = false,
) => async (dispatch) => {
  try {
    dispatch(recommendedEventsSlice.actions.onStart());
    let events = [];
    if (checkIfUserIsRecruiter(roleId)) {
      events = await getRecruiterRecommendedEvents(page, limit);
    } else if (checkIfUserIsCandidate(roleId)) {
      events = await getCandidateRecommendedEvents(page, limit, isLivePriority);
    } else {
      // Note: explore event is a replacement of organizer recommended events
      events = await getSimilarEvents(
        OrganizerExploreEventsPage,
        OrganizerExploreEventsLimit,
      );
    }

    dispatch(recommendedEventsSlice.actions.onSuccess(events));
  } catch (err) {
    dispatch(recommendedEventsSlice.actions.onError(err.toString()));
  }
};

export default recommendedEventsSlice.reducer;

export { getRecommendedEventsData };
