import React, { useEffect } from 'react';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import ProfileLinksComponent from '../../components/ProfileLinks/ProfileLinks';
import { PrimaryButton } from '../../components/shared-components/Button';
import { Tags } from '../../components/shared-components/Tags';
import { hasJobApplicationEvaluationEnded } from '../../utils/jobApplications';
import { getFileS3 } from '../../utils/s3.storage';
import {
  addThreeDaysInUnixTime,
  getYearsAndMonths,
  isJobTypeFullTime,
} from '../../utils/common';
import { JobModalProps } from '../../interfaces/components/JobModal';
import { Modal } from '../../components/shared-components/Modal';
import { AvatarPicture } from '../../components/shared-components/AvatarPicture';
import { LargeFont } from '../../components/shared-components/Texts';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { isDesktop, isTabletAndMobile } from '../../helpers/display';
import { ShareEventButton } from '../../components/shared-components/ShareEvent';
import { useTagManager } from '../../utils/customHooks';
import JobTabs from '../../components/shared-components/JobTabs';

export const JobModal: React.FC<JobModalProps> = ({
  showModal,
  chat,
  evaluationDeadline,
  jobApplication,
  job,
  info,
  ViewJobSalary,
  jobType,
  getJobLocation,
  theme,
  title,
  eventId,
  setShowModal,
  eventName,
}) => {
  const updateUserActionInGTM = useTagManager();
  useEffect(() => {
    updateUserActionInGTM('job_detail_page_open');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const communityEventEta = addThreeDaysInUnixTime(
    get(job, 'jobApplications', {}),
  );

  const ModalFooter = () => {
    return (
      <Footer>
        {!hasJobApplicationEvaluationEnded(
          evaluationDeadline || communityEventEta,
        ) ? (
          <PrimaryButton margin="0 5px" theme="candidate" onClick={chat}>
            Chat
          </PrimaryButton>
        ) : (
          <PrimaryButton margin="0 5px" disabled>
            Chat
          </PrimaryButton>
        )}
        {jobApplication ? (
          <PrimaryButton
            disabled={!!jobApplication[job.id]}
            theme="candidate"
            onClick={() => info(job)}
            id="apply_job_modal_btn"
          >
            {jobApplication[job.id] ? 'Applied' : 'Apply'}
          </PrimaryButton>
        ) : (
          <PrimaryButton theme="candidate" disabled>
            Applied
          </PrimaryButton>
        )}
      </Footer>
    );
  };

  return (
    <div>
      <Modal
        title={title}
        theme={theme}
        visible={showModal}
        width={isDesktop() ? '75%' : '85%'}
        footer={<ModalFooter />}
        onCancel={() => {
          return setShowModal ? setShowModal(false) : null;
        }}
        zIndex={isTabletAndMobile() && 3000}
      >
        <TitleWrapper>
          {job.company.logo ? (
            <img src={getFileS3(get(job, 'company.logo'))} alt="logo" />
          ) : null}
          <LargeFont
            fontWeight={FONT_WEIGHTS.SEMI_BOLD}
            color={COLORS.RICH_BLUE}
          >
            {get(job, 'designation')}
          </LargeFont>

          <ShareEventButton eventId={eventId} name={eventName} jobId={job.id} />
        </TitleWrapper>
        <ModalWrapper>
          <Container>
            <AboutJob>
              <div>
                <StyledHeader>Salary</StyledHeader>
                <StyledText>{ViewJobSalary()}</StyledText>
              </div>
            </AboutJob>
            <JobInfo>
              <div>
                <StyledHeader>Minimum Experience</StyledHeader>
                <Tags color={COLORS.WHISPER} textColor={COLORS.RICH_BLUE}>
                  {getYearsAndMonths(job)}
                </Tags>
              </div>

              <div>
                <StyledHeader>Minimum Education</StyledHeader>
                <Tags color={COLORS.WHISPER} textColor={COLORS.RICH_BLUE}>
                  {get(job, 'minQualification')}
                </Tags>
              </div>

              <JobType>
                <StyledHeader>Job Type</StyledHeader>
                <div>
                  {isArray(jobType) &&
                    jobType.map((type: any, idx: number | undefined) =>
                      isJobTypeFullTime(type, idx),
                    )}
                </div>
              </JobType>

              <IndustryDetails>
                <StyledHeader>Industry</StyledHeader>
                <div>
                  {job.domains.map((item: any) => {
                    return (
                      <Tags color={COLORS.WHISPER} textColor={COLORS.RICH_BLUE}>
                        {item.name}
                      </Tags>
                    );
                  })}
                </div>
              </IndustryDetails>

              <div className="engagement">
                <StyledHeader>Engagement</StyledHeader>
                <div>
                  {job.meta.typesOfEngagement.map((item: any) => {
                    return (
                      <Tags color={COLORS.WHISPER} textColor={COLORS.RICH_BLUE}>
                        {item}
                      </Tags>
                    );
                  })}
                </div>
              </div>
            </JobInfo>
            <JobTabs jobDetails={job} theme={COLORS.RICH_BLUE} />
          </Container>

          <RecruiterDetails>
            <div>
              <StyledHeader>Company</StyledHeader>
              <CompanyDetails>
                {job.company.logo ? (
                  <img src={getFileS3(get(job, 'company.logo'))} alt="logo" />
                ) : (
                  <AvatarPicture picture={null} size={40} />
                )}
                <p>{get(job, 'company.name')}</p>
              </CompanyDetails>
            </div>

            <LocationDetails>
              <StyledHeader>Location</StyledHeader>
              <p>{getJobLocation(job)}</p>
            </LocationDetails>

            <CompanyWebsite>
              <StyledHeader>Website</StyledHeader>
              <p>{get(job, 'company.website')}</p>
            </CompanyWebsite>

            {get(job, 'company.profileLinks.links', []).length ? (
              <div>
                <StyledHeader>Company Profile Link</StyledHeader>
                <ProfileLinkWrapper>
                  <ProfileLinksComponent
                    profileLinks={get(job, 'company.profileLinks.links')}
                  />
                </ProfileLinkWrapper>
              </div>
            ) : null}
          </RecruiterDetails>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

const StyledHeader = styled.h1`
  font-size: 16px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: 14px;
  }
`;

const StyledText = styled.p``;

const CompanyWebsite = styled.div`
  p {
    word-break: break-word;
    margin-top: -5px;
  }
`;

const LocationDetails = styled.div`
  p {
    margin-top: -3px;
  }
`;

const JobType = styled.div`
  div {
    display: flex;
    gap: 10px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    border-radius: 100%;
    width: 50px;
    height: 50px;
  }
`;

const ModalWrapper = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  margin: 10px 20px 0 0;
  flex: 0 0 80%;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    flex: 0 0 70%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }

  .skills {
    display: flex;
    gap: 10px;
    margin: 10px 0;

    div {
      width: 150px;
      text-align: center;
    }
  }

  .engagement {
    align-self: baseline;

    div {
      column-gap: 10px;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_3}) {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const RecruiterDetails = styled.div`
  border: 2px solid #e4e7f0;
  border-radius: 3px;
  padding: 20px;
  height: fit-content;
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

  p {
    color: ${COLORS.GRAY2};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    word-break: break-word;
    font-size: 16px;

    @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
      font-size: 14px;
    }
  }

  .profile-links-btn {
    margin: -10px 0;
  }

  .domain {
    word-break: break-all;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 60px;
  }

  & > div {
    margin: 15px 0;
  }
`;

const ProfileLinkWrapper = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: -8px;
  }

  margin-top: -2px;
`;

const CompanyDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: start;
  gap: 10px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
  }
`;

const AboutJob = styled.div`
  gap: 10px;
  width: 95%;

  p {
    margin-bottom: 10px;
    margin-top: -3px;
    font-size: ${FONT[15]};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[13]};
    }
  }
`;

const JobInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: baseline;
  margin-top: -5px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-top: 0;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    grid-template-columns: 1fr;
  }

  div {
    margin: 10px 0;
    white-space: nowrap;

    &:nth-child(4) {
      div {
        gap: 10px;
      }
    }

    div {
      margin-top: 3px;
    }
  }
`;

const IndustryDetails = styled.div`
  div {
    white-space: normal;
    margin-right: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    button {
      width: 100px;
    }
  }
`;
