export const PRIMARY_FONT = '"Poppins", sans-serif';

export const FONT = {
  9: '9px',
  10: '10px',
  11: '11px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  17: '17px',
  18: '18px',
  19: '19px',
  20: '20px',
  21: '21px',
  22: '22px',
  24: '24px',
  25: '25px',
  26: '26px',
  28: '28px',
  30: '30px',
  34: '34px',
};

export const FONT_WEIGHTS = {
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
};
