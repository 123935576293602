import toLower from 'lodash/toLower';

export const crewMembersColumns = [
  {
    title: 'Member Name',
    key: 1,
    dataIndex: 'name',
  },
  {
    title: 'Phone Number',
    key: 2,
    dataIndex: 'phone',
  },
  {
    title: 'Email Address',
    key: 3,
    dataIndex: 'email',
  },
];

export const makeMembersTableData = (members, data) => {
  return members
    .filter((member) => !(toLower(member.email) === toLower(data.email)))
    .map((record) => {
      const { name, phone, email } = record;
      return {
        name,
        phone,
        email,
      };
    });
};
