import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { TextBox, NumberBox } from '../../shared-components/Form/Form';
import { StyledTitle } from './BasicInformation';
import { GrayHeading } from './Common';
import MandatoryStar from '../../Common/mandatoryStar';
import { PrimaryButton } from '../../shared-components/Button';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { COLORS } from '../../../styles/constants/colors';
import { getThemeByRoleId } from '../../../utils/common';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getRoleId } from '../../../utils/userInfo';

const TaxForm = ({ event, form, BtnDisabled }) => {
  const [isTaxFormActive, setIsTaxFormActive] = useState(false);
  const roleId = getRoleId();

  useEffect(() => {
    if (event) {
      const { taxId, taxName, taxPercentage } = event;
      if (taxName && taxPercentage && taxId) {
        setIsTaxFormActive(true);
      }
    }
  }, [event]);

  const handleRemove = () => {
    form.setFieldsValue({
      taxId: null,
      taxName: null,
      taxPercentage: null,
    });

    setIsTaxFormActive(false);
  };

  return (
    <>
      {isTaxFormActive ? (
        <>
          <StyledTitle level={5}>Tax Details</StyledTitle>
          <Row gutter={[25, 10]}>
            <Col xs={24} lg={7}>
              <GrayHeading>
                Tax Name <MandatoryStar />
              </GrayHeading>
              <TextBox
                rule="onlyCharaters"
                name="taxName"
                placeholder="Enter name"
                required
              />
            </Col>
            <Col xs={24} lg={7}>
              <GrayHeading>
                Tax ID
                <MandatoryStar />
              </GrayHeading>
              <TextBox
                rule="charAndNumber"
                name="taxId"
                placeholder="Enter tax ID"
                required
              />
            </Col>
            <Col xs={24} lg={7}>
              <GrayHeading>
                Tax Percentage % <MandatoryStar />
              </GrayHeading>
              <NumberBox
                name="taxPercentage"
                placeholder="Enter a number"
                required
                rule="percentage"
              />
            </Col>
            <Col xs={24} lg={3}>
              <StyledRemove onClick={handleRemove}>Remove</StyledRemove>
            </Col>
          </Row>
        </>
      ) : (
        <PrimaryButton
          theme={getThemeByRoleId(roleId)}
          onClick={() => setIsTaxFormActive(true)}
          disabled={BtnDisabled}
        >
          + Add Tax
        </PrimaryButton>
      )}
    </>
  );
};

export default TaxForm;

const StyledRemove = styled.div`
  font-size: ${FONT[14]};
  color: ${COLORS.LIVE_COLOR};
  padding-top: 0;
  margin-top: 40px;
  font-weight: ${FONT_WEIGHTS.REGULAR};
  cursor: pointer;
  width: max-content;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
    margin-top: 45px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0px;
  }
`;
