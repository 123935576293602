import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../constants';
import {
  bookInterviewSlot,
  fetchInterviewSlotData,
  fetchJobResults,
  fetchJobResultsAnalyticsData,
  fetchJobResultsOtherAnalyticsData,
  fetchJobResultsMoreAnalyticsData,
  fetchRecruiterAnalytics,
  fetchRecruiterAdditionalAnalytics,
} from '../../service/jobResults';

export const bookJobInterview = createAsyncThunk(
  'jobs/bookJobInterview',
  (body) => {
    return bookInterviewSlot(body);
  },
);

export const getJobResults = createAsyncThunk(
  'jobs/getJobResults',
  ({ eventId, page, limit, applicationStatus }) => {
    if (eventId) {
      return fetchJobResults(
        eventId,
        page || DEFAULT_PAGE,
        limit || DEFAULT_LIMIT,
        applicationStatus,
      );
    }
  },
);

export const getInterviewSlotData = createAsyncThunk(
  'interview-slot/getInterviewSlotData',
  (body) => {
    return fetchInterviewSlotData(body);
  },
);

export const getJobResultsAnalytics = createAsyncThunk(
  'job-results-analytics/getAnalytics',
  (jobResult) => {
    const { eventId, jobId } = jobResult;
    return fetchJobResultsAnalyticsData(eventId, jobId);
  },
);

export const getRecruiterAnalytics = createAsyncThunk(
  'job-results-analytics/getAnalytics',
  (data) => {
    const { eventId } = data;
    return fetchRecruiterAnalytics(eventId);
  },
);

export const getRecruiterAdditionalAnalytics = createAsyncThunk(
  'job-results-analytics/getAnalytics',
  (data) => {
    const { eventId, jobId } = data;
    return fetchRecruiterAdditionalAnalytics(eventId, jobId);
  },
);

export const getJobResultsOtherAnalytics = createAsyncThunk(
  'job-results-analytics/getAnalytics',
  (jobResult) => {
    const { id } = jobResult;
    return fetchJobResultsOtherAnalyticsData(id);
  },
);

export const getJobResultsMoreAnalytics = createAsyncThunk(
  'job-results-analytics/getMoreAnalytics',
  (jobResult) => {
    const { eventId } = jobResult;
    return fetchJobResultsMoreAnalyticsData(eventId);
  },
);

export const jobResultsSlice = createSlice({
  name: 'jobResults',
  initialState: {
    jobs: [],
    status: null,
  },
  extraReducers: {
    [getJobResults.pending]: (state) => {
      state.status = 'Loading';
    },
    [getJobResults.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.jobs = action.payload;
    },
    [getJobResults.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default jobResultsSlice.reducer;
