import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Skeleton } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import { NotificationTypeList } from '../Common/NotificationTypeList';
import {
  NotificationStore,
  NotificationUpdatesStore,
} from '../../../utils/notifications';
import { RoleStringProp } from '../../../interfaces/components/Notifications';
import { getColorStringByRoleId } from '../../../utils/notification';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { NotificationItem } from './NotificationItem';
import { CustomPagination } from '../Common/CustomPagination';
import { DEFAULT_LIMIT, DEFAULT_PAGE, STATUS_ACTIVE } from '../../../constants';
import {
  getNotificationsData,
  getNotificationsUpdate,
} from '../../../store/features/notifications';
import { updateNotifications } from '../../../service/notifications';
import { userProfileStore } from '../../../utils/profile';
import AppSocketContext from '../../../context/AppSocket';
import { getRoleId } from '../../../utils/userInfo';

export const GeneralNotifications: React.FC<any> = () => {
  const roleId = getRoleId();
  const { updates } = NotificationUpdatesStore();

  const dispatch = useDispatch();
  const { isLoading, error, notifications, pagination } = NotificationStore();

  const location = useLocation();

  const [currentPage, setCurrentPage] = React.useState(DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = React.useState(DEFAULT_LIMIT);
  const [notificationList, setNotificationList] = useState<any>([]);
  const client = useContext(AppSocketContext);
  const { profileData } = userProfileStore();
  const [firstFetch, setFirstFetch] = useState(true);

  const onPageChangeHandler = (
    pageNumber: number,
    pageSize: number | undefined,
  ) => {
    if (currentPage !== pageNumber) setCurrentPage(pageNumber);
    if (pageSize && currentLimit !== pageSize) setCurrentLimit(pageSize);
  };
  useEffect(() => {
    setNotificationList(notifications);
  }, [notifications]);

  useEffect(() => {
    dispatch(getNotificationsUpdate());

    // eslint-disable-next-line
  }, []);

  const params = new URLSearchParams(location.search);

  let type: any = params.get('type');
  if (!type) {
    type = '0';
  }

  const [filter, setFilter] = useState(0);

  useEffect(() => {
    if (!firstFetch) {
      // eslint-disable-next-line radix
      setFilter(parseInt(type));
      if (currentPage === DEFAULT_PAGE) {
        dispatch(getNotificationsData(-1, type, currentPage, currentLimit));
      } else {
        setCurrentPage(DEFAULT_PAGE);
      }
    }
    // eslint-disable-next-line
  }, [type]);

  const updateNotification = () => {
    let newNotifications: any[] = [];

    newNotifications =
      notificationList && notificationList.length
        ? notificationList
            .filter(
              (notification: any) => notification.isViewed !== STATUS_ACTIVE,
            )
            .map((notification: any) => notification.id)
        : [];

    if (newNotifications.length > 0) {
      setTimeout(() => {
        updateNotifications({
          notificationIds: newNotifications,
          updateAll: false,
        })
          .then(() => {
            dispatch(
              getNotificationsData(-1, type, currentPage, currentLimit, true),
            );
          })
          .then(() => {
            dispatch(getNotificationsUpdate(true));
          });
      }, 2000);
    }
  };

  useEffect(() => {
    updateNotification();
    // eslint-disable-next-line
  }, [notificationList, dispatch]);

  useEffect(() => {
    if (client && profileData.userId) {
      (async () => {
        await client.subscribe(
          `/user/${profileData.userId}/notification/`,
          (resp: any) => {
            if (currentPage === DEFAULT_PAGE) {
              setNotificationList([resp, ...notificationList]);
              updateNotification();
            }
          },
        );
      })();
    }

    // eslint-disable-next-line
  }, [profileData.userId]);

  useEffect(() => {
    dispatch(getNotificationsData(-1, filter, currentPage, currentLimit));
    setFirstFetch(false);
    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  return (
    <Col xs={24}>
      <StyledRow>
        <StyledHeaderTitleCol md={6} roleId={roleId} />
        <StyledHeaderBoxCol md={18} xs={24}>
          <StyledHeaderBoxRightDiv>
            <NotificationTypeList
              location={location}
              updates={updates}
              showViewAll
            />
          </StyledHeaderBoxRightDiv>
        </StyledHeaderBoxCol>
      </StyledRow>

      {isLoading || error ? (
        <Skeleton />
      ) : notificationList.length ? (
        <>
          <StyledColContainerItems xs={24}>
            {notificationList.map((item: any, i: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <NotificationItem
                key={i}
                notification={item}
                profileData={profileData}
                roleId={roleId}
              />
            ))}
          </StyledColContainerItems>
          <StyledPagination>
            <CustomPagination
              currentPage={currentPage}
              totalItems={get(
                pagination,
                'totalItems',
                notificationList.length,
              )}
              currentLimit={currentLimit}
              onPageChange={onPageChangeHandler}
            />
          </StyledPagination>
        </>
      ) : (
        <p>No Notifications</p>
      )}
    </Col>
  );
};

export default GeneralNotifications;

const StyledRow = styled(Row)`
  display: flex;
  align-items: baseline;
`;

const StyledHeaderTitleCol = styled(Col)<RoleStringProp>`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${(props) => getColorStringByRoleId(props.roleId)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledHeaderBoxCol = styled(Col)`
  ${DISPLAY_FLEX('row', 'flex-end')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: flex-start;
  }
`;

const StyledHeaderBoxRightDiv = styled.div`
  ${DISPLAY_FLEX('row')};

  margin-top: 0;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: ${COLORS.WHITE};
  position: relative;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    overflow-x: auto;
  }

  h5 {
    color: ${COLORS.ARGENT};
    line-height: 27px;
    font-weight: 500;
    float: left;
    font-size: ${FONT[15]};
  }

  svg {
    width: 20px;
    float: right;
    fill: ${COLORS.WHITE};
  }

  ul {
    ${DISPLAY_FLEX('row')};

    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      ${MARGIN(12, null, null, 20)};

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        margin-bottom: 20px;
        margin-left: 10px;
      }

      @media (max-width: ${BREAKPOINTS.LAPTOP_MIN}) {
        width: unset;
      }

      p {
        font-weight: ${FONT_WEIGHTS.MEDIUM};
        color: ${COLORS.GRAY};
        margin: 0;
        font-size: ${FONT[14]};
      }

      &:hover {
        cursor: pointer;

        p {
          color: ${COLORS.CHINESE_BLACK};
        }

        .black-background {
          background-color: ${COLORS.CHINESE_BLACK};
        }
      }
    }
  }
`;

const StyledColContainerItems = styled(Col)`
  height: 55vh;
  overflow-y: auto;
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 60vh;
  }
`;

const StyledPagination = styled.div`
  ${DISPLAY_FLEX('row', 'flex-end')};

  width: 100%;
  margin: 20px 0;
`;
