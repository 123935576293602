import React from 'react';
import get from 'lodash/get';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { PAYMENT_INTENT_KEYS } from '../../../../constants';
import { getPaymentIntent } from '../../../../service/event';
import {
  checkIfUserIsCandidate,
  getRoleId,
  getUserClassWithRoleId,
} from '../../../../utils/userInfo';
import { PrimaryButton } from '../../../shared-components/Button';
import Notification from '../../../Notification/Notification';
import { getEventByIdDataWithoutReload } from '../../../../store/features/events';

const AuthenticationButton = ({
  event,
  idBase = 'book',
  profileData,
  companyData,
}) => {
  const roleId = getRoleId();
  const ticket = get(event, 'ticket', '');
  const theme = getUserClassWithRoleId(roleId);
  const stripe = useStripe();
  const dispatch = useDispatch();

  const handleActionRequiredClick = async () => {
    const data = {
      billingAddressId: get(event, 'ticket.billingAddressId', null),
      description: `${
        checkIfUserIsCandidate(roleId) ? `Attendee` : `Recruiter`
      }: Payment for booking of "${event.name}" Event`,
      meta: {
        eventId: event.id,
        roleId,
        userId: get(
          profileData,
          'userId',
          get(profileData, 'companyAdmin.userId', null),
        ),
        companyId: get(companyData, 'id', null),
      },
    };
    const type = get(ticket, 'meta.actionType', 'redirect_to_url');
    if (type === 'redirect_to_url') {
      await getPaymentIntent(data, PAYMENT_INTENT_KEYS.REDIRECT_URL).then(
        async (response) => {
          const url = get(response, 'value', '');
          if (url) {
            window.open(url, '_self');
          }
        },
      );
    } else {
      await getPaymentIntent(data, PAYMENT_INTENT_KEYS.CLIENT_SECRET).then(
        async (response) => {
          const paymentIntentClientSecret = get(response, 'value', '');
          const { error: errorAction } = await stripe.confirmCardPayment(
            paymentIntentClientSecret,
          );

          if (errorAction) {
            dispatch(getEventByIdDataWithoutReload(event.id));
            return Notification('error', 'Authentication failed');
          }

          dispatch(getEventByIdDataWithoutReload(event.id));
        },
      );
    }
  };

  return (
    <PrimaryButton
      onClick={handleActionRequiredClick}
      theme={theme}
      id={`${idBase}_inapp`}
    >
      Authenticate
    </PrimaryButton>
  );
};

export default AuthenticationButton;
