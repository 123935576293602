import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import { CandidateExp } from '../../utils/candidateExperience';
import BasicCandidateInformation from '../../components/CandidateProfile/BasicInformation';
import WorkAndEducationInformation from '../../components/CandidateProfile/WorkAndEducationInformation';
import JobRelatedDetails from '../../components/CandidateProfile/JobRelatedDetails';
import CurrencySetting from '../../components/profile/CurrencySetting';
import { getFileS3 } from '../../utils/s3.storage';
import { getFilePath } from '../../utils/profile';
import MailSubscription from '../../components/profile/MailSubscription';

const Profile = ({
  profileData,
  isAdmin = false,
  currencyData,
  unauthorizedCountries,
  authorizedCountries,
}) => {
  const { candidateExpinYr, candidateExpinMon } = CandidateExp(profileData);
  const [profilePic, setProfilePic] = useState(null);
  const [skills, setSkills] = useState([]);
  const [resume, setResume] = useState([]);
  const [certificationsList, setCertificationsList] = useState([]);

  useEffect(() => {
    if (profileData) {
      const userProfilePic = get(profileData, 'profilePic', null);
      if (userProfilePic) {
        setProfilePic(getFileS3(profileData.profilePic));
      }
      const userSkills = get(profileData, 'userSkills', null);
      if (userSkills) {
        setSkills(profileData.userSkills);
      }
      const userResume = get(profileData, 'resume', null);

      if (userResume) {
        setResume([]);
        const resumeName = get(profileData, 'resumeName', null);
        setResume((oldArr) => [
          ...oldArr,
          {
            uid: 1,
            name: resumeName || `${get(profileData, 'name', '')}.pdf`,
            status: 'done',
            url: getFilePath(get(profileData, 'resume', '')),
          },
        ]);
      }
      const userCertifications = get(profileData, 'certifications', null);
      if (userCertifications) {
        setCertificationsList([]);
        profileData.certifications.forEach((certi) => {
          if (
            certi.fileName &&
            certi.fileName.trim() !== 'undefined' &&
            certi.fileName.trim() !== 'null'
          ) {
            setCertificationsList((oldArr) => [
              ...oldArr,
              {
                uid: certi.id,
                name: certi.certificateName,
                status: 'done',
                url: getFilePath(certi.fileName),
                fileName: certi.fileName,
              },
            ]);
          }
        });
      }
    }
  }, [profileData]);

  return !profileData ? (
    <Skeleton />
  ) : (
    <div>
      <BasicCandidateInformation
        profileData={profileData}
        profilePic={profilePic}
        setProfilePic={setProfilePic}
      />

      <WorkAndEducationInformation
        profileData={profileData}
        candidateExpinYr={candidateExpinYr}
        candidateExpinMon={candidateExpinMon}
        currencyData={currencyData}
        unauthorizedCountries={unauthorizedCountries}
        authorizedCountries={authorizedCountries}
      />

      <JobRelatedDetails
        profileData={profileData}
        skills={skills}
        setResume={setResume}
        setCertificationsList={setCertificationsList}
        certificationsList={certificationsList}
        resume={resume}
        isAdmin={isAdmin}
        currencyData={currencyData}
      />

      <CurrencySetting profileData={profileData} currencyData={currencyData} />

      <MailSubscription profileData={profileData} isAdmin={isAdmin} />
    </div>
  );
};

export default Profile;
