import React from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CountryCodeInput from '../Common/CountryCodeInput';
import MandatoryStar from '../Common/mandatoryStar';
import UserAvatar from '../shared-components/Common/UserAvatar';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { getProfileDataWithoutReload } from '../../store/features/profile';
import {
  userProfileInitialValues,
  updateProfilePic,
  countryCodeOnlySplit,
} from '../../utils/profile';
import { FormGroup, TextBox, Email } from '../shared-components/Form/Form';
import { TextUpload } from '../shared-components/Upload';
import { UploadPictureNotification } from '../Notification/UploadPictureNotification';
import { getFileS3 } from '../../utils/s3.storage';
import {
  ACCEPTED_PROFILE_IMAGE,
  ACCEPTED_IMAGE_EXTENSIONS,
  IMAGE_PROFILE_PAYLOAD,
} from '../../constants/file';
import Notification from '../Notification/Notification';
import { imageValidator, getTheme } from '../../utils/common';
import { CROP_POFILE_PIC_OPTIONS } from '../../constants';
import { userIsCrewMember } from '../../utils/crew';
import {
  largeFontMixin,
  largeHeadingMixin,
} from '../../styles/constants/mixins';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import {
  DISPLAY,
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { getSize } from '../../utils/size';

const RepresentativeUIBox = ({
  roleId,
  step,
  setStep,
  profile,
  setProfile,
  profileData,
  profilePic,
  setProfilePic,
  form,
  userType,
}) => {
  const dispatch = useDispatch();

  const profilePicOnChange = (info) => {
    UploadPictureNotification(info);
  };

  const updateProfilePicCustomReq = ({ onSuccess, onError, file }) => {
    imageValidator({ ...IMAGE_PROFILE_PAYLOAD, file }).then(() => {
      updateProfilePic(file)
        .then((fileName) => {
          setProfilePic(getFileS3(fileName));
          dispatch(getProfileDataWithoutReload());
          onSuccess();
        })
        .catch((err) => {
          onError();
        });
    });
  };

  const handleProfileBeforeUpload = (file) => {
    if (file.size > ACCEPTED_PROFILE_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Profile image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...IMAGE_PROFILE_PAYLOAD, file }).catch(
      () => false,
    );
  };

  const handleRepDetailsSubmit = ({
    fullName,
    phoneNumberPrefix,
    phoneNumber,
    email,
  }) => {
    // Exract representative information
    const representativeName = get(
      profileData,
      'companyAdmin.representativeName',
      '',
    );
    const representativePhone = get(
      profileData,
      'companyAdmin.representativePhone',
      '',
    );
    const representativeEmail = get(
      profileData,
      'companyAdmin.representativeEmail',
      '',
    );

    form.validateFields().then((values) => {
      const payload = !userIsCrewMember(profileData)
        ? {
            ...profile,
            roleId,
            representativeName: fullName,
            representativePhone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${
              phoneNumber ? phoneNumber : ''
            }`,
            representativeEmail: email,
          }
        : {
            ...profile,
            roleId,
            name: fullName,
            phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${
              phoneNumber ? phoneNumber : ''
            }`,
            email,
            representativeName,
            representativePhone,
            representativeEmail,
          };
      setProfile(payload);
    });
    setStep(1);
  };

  const color = getTheme(userType);
  const idBase = `representative_ui_box`;

  return (
    <StyledContainer>
      <StyledLevelThreeHeading color={color} id={`${idBase}_title`}>
        {!userIsCrewMember(profileData) ? 'Representative' : 'Member'} Profile
      </StyledLevelThreeHeading>
      <StyledLevelFiveHeading>Personal Information</StyledLevelFiveHeading>

      {/* form  basic information */}
      <FormGroup
        form={form}
        name="OrganizerCompanyInformationModal"
        onFinish={handleRepDetailsSubmit}
        expand
        initialValues={userProfileInitialValues(
          profileData,
          !userIsCrewMember(profileData),
        )}
        // initialValues={initialValue_RepDetails}
      >
        <StyledRow gutter={[35, 0]}>
          <Col xs={24} lg={5}>
            <StyledFullNamelabel htmlFor="representative_ui_box">
              Full Name <MandatoryStar />
            </StyledFullNamelabel>
            <TextBox
              name="fullName"
              placeholder="Full Name"
              className="ant-input-h42"
              rule="text"
              id="representative_ui_box"
            />
          </Col>
          <Col xs={24} lg={7}>
            <StyledPhonelabel htmlFor="phoneNumber">
              Phone Number{' '}
              {checkIfUserIsRecruiter(roleId) ? (
                <span className="hidden-star">
                  <MandatoryStar />
                </span>
              ) : null}
              {checkIfUserIsOrganizer(roleId) ? <MandatoryStar /> : null}
            </StyledPhonelabel>
            <Row>
              <Col xs={8} lg={8}>
                <CountryCodeInput
                  name="phoneNumberPrefix"
                  required={checkIfUserIsOrganizer(roleId) ? true : false}
                />
              </Col>
              <Col xs={16} lg={16}>
                <TextBox
                  name="phoneNumber"
                  placeholder="Phone Number"
                  rule="phoneNumber"
                  maxLength={12}
                  id="phoneNumber"
                  required={checkIfUserIsOrganizer(roleId) ? true : false}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={7}>
            <StyledEmailLabel htmlFor="emailAddress">
              Email Address <MandatoryStar />
            </StyledEmailLabel>
            <Email
              disabled
              name="email"
              placeholder="Email"
              id="emailAddress"
            />
          </Col>

          <Col xs={24} lg={5} className="m_relunset">
            <StyledProfileContainer>
              {/* <div>
                    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"><g><g><path d="M477.9,415.2c-2-9.4-11.2-15.5-20.6-13.5c-9.4,2-15.5,11.2-13.5,20.6c1.1,5.4-0.2,11-3.7,15.3c-2,2.5-6.5,6.6-13.9,6.6    H85.8c-7.4,0-11.9-4.2-13.9-6.6c-3.5-4.3-4.8-9.8-3.7-15.3c18.1-86.9,94.7-150.3,183.2-152.5c1.5,0.1,3.1,0.1,4.6,0.1    c1.6,0,3.1,0,4.6-0.1c62.3,1.5,119.9,33.1,154.8,85.1c5.4,8,16.2,10.1,24.2,4.8c8-5.4,10.1-16.2,4.8-24.2    c-28.6-42.6-70.1-73.7-117.4-89.2c28.8-21.6,47.5-56.1,47.5-94.8C374.5,86.1,321.3,33,256,33S137.5,86.1,137.5,151.4    c0,38.8,18.7,73.3,47.6,94.9c-26.5,8.7-51.3,22.3-73,40.1c-39.8,32.8-67.5,78.5-78,128.7c-3.3,15.7,0.6,31.9,10.8,44.4    c10.1,12.4,25,19.5,41,19.5h340.3c16,0,30.9-7.1,41-19.5C477.3,447.1,481.2,430.9,477.9,415.2z M172.4,151.4    c0-46.1,37.5-83.6,83.6-83.6s83.6,37.5,83.6,83.6c0,44.7-35.2,81.3-79.4,83.5c-1.4,0-2.8,0-4.2,0c-1.4,0-2.8,0-4.2,0    C207.6,232.8,172.4,196.2,172.4,151.4z"></path></g></g></svg>
                </div>
                <p>+ Add Profile Picture</p> */}
              {
                <StyledAvatarContainer>
                  <UserAvatar
                    src={profilePic}
                    alt="user-profile"
                    userType={userType}
                    size={getSize(120, 100, 60)}
                  />
                </StyledAvatarContainer>
              }
              <StyledTextUploadContainer>
                <TextUpload
                  name="logoUpload"
                  text={
                    !profilePic ? '+ Add Profile Picture' : 'Change Profile Pic'
                  }
                  accept={ACCEPTED_IMAGE_EXTENSIONS}
                  className="text-upload txt_upload"
                  customRequestHandler={updateProfilePicCustomReq}
                  onChange={profilePicOnChange}
                  beforeUpload={handleProfileBeforeUpload}
                  cropActive
                  cropOptions={CROP_POFILE_PIC_OPTIONS}
                  roleId={roleId}
                />
              </StyledTextUploadContainer>
            </StyledProfileContainer>
          </Col>
        </StyledRow>
        {/* Steps-action buttons */}
        <StyledStepsActionContainer>
          <Row>
            <Col xs={12} lg={12} />
            <StyledCol xs={12} lg={12}>
              <StyledButton color={color}>Next</StyledButton>
            </StyledCol>
          </Row>
        </StyledStepsActionContainer>
      </FormGroup>
    </StyledContainer>
  );
};

export default RepresentativeUIBox;

const StyledContainer = styled.div`
  overflow: auto;

  position: relative;
  height: auto;
  background-color: #fff;
  box-shadow: 0 2px 10px #00000029;
  ${PADDING(25, 25, 25, 25)};
  ${MARGIN(0, 0, 40)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(14, 13, 30, 13)};
    ${MARGIN(20, 0, 0, 0)};
    z-index: 11;
    overflow: unset;
  }
`;

const StyledLevelThreeHeading = styled.h3`
  font-size: 18px;
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 21px;
  ${MARGIN(0, 0, 35)};
  ${largeHeadingMixin}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 30, 0)};
  }
`;

const StyledLevelFiveHeading = styled.h5`
  font-size: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 19px;
  ${MARGIN(0, 0, 22)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
  }
`;

const StyledAvatarContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center')};
  ${MARGIN(0, 0, 5)};
`;

const StyledTextUploadContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center')}
  ${MARGIN(0, 5)};

  span {
    font-size: 16px;
    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      font-size: 14px;
    }
  }
`;

const StyledRow = styled(Row)`
  height: 140px;
  position: relative;

  @media (min-width: ${BREAKPOINTS.MOBILE}) and (max-width: 992px) {
    height: fit-content;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 13)}
    height: 300px;
  }
`;

const StyledFullNamelabel = styled.label`
  display: ${DISPLAY.BLOCK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  ${MARGIN(0, 0, 16)};
  font-size: 16px !important;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    font-size: 14px !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(25, 0, 12)}
  }
`;

const StyledPhonelabel = styled.label`
  display: ${DISPLAY.BLOCK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  ${MARGIN(0, 0, 16)};
  font-size: 16px !important;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    font-size: 14px !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 12)};
  }
`;

const StyledEmailLabel = styled.label`
  display: ${DISPLAY.BLOCK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  ${MARGIN(0, 0, 16)};
  font-size: 16px !important;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    font-size: 14px !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 12)}
  }
`;

const StyledProfileContainer = styled.div`
  position: absolute;
  bottom: 65px;

  @media (min-width: ${BREAKPOINTS.MOBILE}) and (max-width: 991px) {
    right: 25px;
    top: -370px;
    height: max-content;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    right: 25px;
    top: -55px;
    height: max-content;
  }
`;

const StyledStepsActionContainer = styled.div`
  ${MARGIN(34)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(60, 13, 34, 13)};
  }
`;

const StyledCol = styled(Col)`
  text-align: right;
`;

const StyledButton = styled.button`
  width: 90%;
  height: 40px;
  background-color: ${(props) => props.color};
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  max-width: 168px;
  border: none;
  cursor: pointer;
  ${largeFontMixin}
`;
