export const NotificationsType = {
  Default: 0,
  Sales: 1,
  Team: 2,
  Update: 3,
  Chat: 4,
  Jobs: 5,
};

export const OrganizerNotificationFilterOptions = [
  {
    id: 0,
    name: 'View all',
  },
  {
    id: 1,
    name: 'Sales',
  },
  {
    id: 3,
    name: 'Updates',
  },
  {
    id: 2,
    name: 'Team',
  },
];

export const RecruiterNotificationFilterOptions = [
  {
    id: 0,
    name: 'View all',
  },
  {
    id: 5,
    name: 'Recruit',
  },
  {
    id: 3,
    name: 'Updates',
  },
  {
    id: 2,
    name: 'Team',
  },
];

export const CandidateNotificationFilterOptions = [
  {
    id: 0,
    name: 'View all',
  },
  {
    id: 3,
    name: 'Updates',
  },
  {
    id: 5,
    name: 'Jobs',
  },
];

export const NOTIFICATION_CLICK_ACTION = {
  signup: 1,
  newOpportunity: 2,
  jobApplied: 3,
  applicationReceived: 4,
  interviewScheduled: 5,
  viewEvent: 6,
  eventCanceled: 7,
  applicationStatusUpdate: 8,
  crewInvite: 9,
  chat: 10,
};
