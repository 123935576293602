import React from 'react';
import { Row, Col } from 'antd';
import styled from "styled-components";
import {
  ProfileInformationHeader,
  ProfileInformationValue,
} from '../CrewManagement/common';

const Address = ({ data, candidate, margin }) => {
  return (
    <StyledRow gutter={[16, 24]} margin={margin}>
      <Col xs={24} lg={4}>
        <ProfileInformationHeader>Country</ProfileInformationHeader>
        <ProfileInformationValue>{data.country}</ProfileInformationValue>
      </Col>
      <Col xs={24} lg={4}>
        <ProfileInformationHeader>Zipcode</ProfileInformationHeader>
        <ProfileInformationValue>{data.zip}</ProfileInformationValue>
      </Col>
      {candidate ? (
        <Col xs={24} md={4}>
          <ProfileInformationHeader>Nationality</ProfileInformationHeader>
          <ProfileInformationValue>{data.nationality}</ProfileInformationValue>
        </Col>
      ) : null}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin: ${props => props.margin} !important;
`

export default Address;
