import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import Notification from '../../components/Notification/Notification';
import { deleteCertificate } from '../../service';
import { skipOrEnablePayment } from '../../service/recruiter';

import {
  getProfile,
  updateProfile,
  updateMemberProfile,
  updateMemberProfileAdmin,
  updateCandidateProfile,
} from '../../service/auth';

/** Async thunk function for skipping the payment and refresh the profile data so that we dont need to refresh again */
const skipOrEnablePaymentAccess = createAsyncThunk(
  'profile/skip-payment',
  async (status, { dispatch }) => {
    await skipOrEnablePayment(status);
    return dispatch(getProfileData());
  },
);

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.profileData = action.payload;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const onLogout = (state) => {
  state.isLoading = false;
  state.profileData = {};
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    profileData: {},
    error: null,
  },
  reducers: { onStart, onSuccess, onError, onLogout },
});

const getProfileData = () => async (dispatch) => {
  try {
    dispatch(profileSlice.actions.onStart());
    const profileData = await getProfile();
    dispatch(
      profileSlice.actions.onSuccess(
        get(profileData, 'data.payload.profile', {}),
      ),
    );
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

const updateProfileData = (data) => async (dispatch) => {
  try {
    dispatch(profileSlice.actions.onStart());
    await updateProfile(data);
    dispatch(getProfileData());
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

const updateMemberProfileData = (data) => async (dispatch) => {
  try {
    dispatch(profileSlice.actions.onStart());
    await updateMemberProfile(data);
    dispatch(getProfileData());
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

const updateMemberProfileAdminData = (data) => async (dispatch) => {
  try {
    dispatch(profileSlice.actions.onStart());
    await updateMemberProfileAdmin(data);
    dispatch(getProfileData());
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

export const getProfileDataWithoutReload = (callback = null) => async (
  dispatch,
) => {
  try {
    const profileData = await getProfile();
    if (callback) {
      callback();
    }

    dispatch(profileSlice.actions.onSuccess(profileData.data.payload.profile));
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

export const deleteCertificateData = (data) => async (dispatch) => {
  try {
    await deleteCertificate(data);
    dispatch(
      getProfileDataWithoutReload(() => {
        Notification(
          'success',
          'Certificate',
          'Certificate deleted successfully',
        );
      }),
    );
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

export const createCandidateQuickProfile = (data) => async (dispatch) => {
  try {
    dispatch(profileSlice.actions.onStart());
    await updateCandidateProfile(data);
    dispatch(getProfileData());
  } catch (err) {
    dispatch(profileSlice.actions.onError(err.toString()));
  }
};

export default profileSlice.reducer;

export {
  getProfileData,
  updateProfileData,
  updateMemberProfileData,
  updateMemberProfileAdminData,
  skipOrEnablePaymentAccess,
};
