import { message } from 'antd';
import toLower from 'lodash/toLower';
import get from 'lodash/get';
import { DELETE, STATUS_DISABLED } from '../constants';

export const getSkillNameWithSecondarySubstitution = (skill) => {
  return skill && skill.status === STATUS_DISABLED && skill.primarySkill
    ? get(skill, 'primarySkill.skill', '')
    : get(skill, 'skill', '');
};

export const getSkillName = (skill) => {
  return get(skill, 'skill', '');
};

export const getPrimarySkillName = (skill, prefix = '', suffix = '') => {
  return skill && skill.status === STATUS_DISABLED && skill.primarySkill
    ? `${prefix}${get(skill, 'primarySkill.skill', '')}${suffix}`
    : '';
};

export const areSkillsRepeating = (skills) => {
  const skillsArray = [];
  let i = 0;
  let areSkillsRepeat = false;
  for (; i < skills.length; i += 1) {
    const skill = skills[i];
    if (skill.operation !== DELETE) {
      const skillName = get(skill, 'skill.skill', '');
      if (skillsArray.includes(toLower(skillName))) {
        message.error({
          content: 'Please add unique skills',
        });
        areSkillsRepeat = true;
        break;
      }
      skillsArray.push(toLower(skillName));
    }
  }
  return areSkillsRepeat;
};
