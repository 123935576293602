import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Switch } from 'antd';
import Circle from '../../../components/shared-components/PageCircle';
import { COLORS } from '../../../styles/constants/colors';
import { DashboardStats } from './DashboardStats';
import { ConvertedLeads } from './ConvertedLeads';
import { PriorityJobs } from './PriorityJobs';
import { CompaniesOnboarded } from './CompaniesOnboarded';
import { JobsApplications } from './JobsApplications';
import { JobsShortlistApplications } from './JobsShortlistApplications';
import {
  getMasterAdminDashboardAnalyticsData,
  getMasterAdminDashboardPriorityJobs,
} from '../../../store/features/analytics';
import { convertToUnix } from '../../../utils/formatDateTime';
import DatePicker from '../../../components/Common/DatePicker';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { JobsPreScreenApplications } from './JobPreScreenApplications';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../../styles/constants/display';

const Dashboard = () => {
  const calendar = [
    {
      id: 0,
      name: 'Last 24 hours',
      days: 1,
    },
    {
      id: 1,
      name: 'Week',
      days: 6,
    },
    {
      id: 2,
      name: 'Month',
      days: 29,
    },
  ];

  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  const { statistics, priorityJobs } = useSelector(
    (state) => state.analytics.masterAdminDashboardAnalytics,
  );
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [maxGrowth, setMaxGrowth] = useState(false);

  const startTime = selectedDateRange?.length
    ? convertToUnix(selectedDateRange[0])
    : null;

  const endTime = selectedDateRange?.length
    ? convertToUnix(selectedDateRange[1])
    : null;

  useEffect(() => {
    dispatch(
      getMasterAdminDashboardAnalyticsData(
        selectedDateRange?.length ? convertToUnix(selectedDateRange[0]) : null,
        selectedDateRange?.length ? convertToUnix(selectedDateRange[1]) : null,
        maxGrowth,
      ),
    );
  }, [dispatch, selectedDateRange, maxGrowth]);

  useEffect(() => {
    dispatch(getMasterAdminDashboardPriorityJobs());
  }, [dispatch]);

  function handleDateChange(date) {
    setSelectedDateRange(date);
    setSelected();
  }

  function handleCalendarButton(item) {
    if (item.id !== selected) {
      setSelected(item.id);
      setSelectedDateRange([moment().subtract(item.days, 'd'), moment()]);
    }
  }

  return (
    <>
      <Circle color={COLORS.DODGER_BLUE} />
      <StyledTitle>Hey Admin!</StyledTitle>
      <StyledButtons>
        <DatePicker
          handleDateChange={handleDateChange}
          selectedDateRange={selectedDateRange}
          range={{
            'Last 3 Days': [moment().subtract(2, 'd'), moment()],
            'Last 7 Days': [moment().subtract(6, 'd'), moment()],
            'Last 30 Days': [moment().subtract(29, 'd'), moment()],
          }}
          disabledDate={(current) => current.isAfter(moment())}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
        <div>
          <div>
            {calendar.map((item) => (
              <StyledButton
                type="button"
                key={item.id}
                onClick={() => {
                  handleCalendarButton(item);
                }}
                selected={item.id === selected}
              >
                {item.name}
              </StyledButton>
            ))}
          </div>
          <StyledGrowthSwitch>
            <p>Max</p>
            <Switch
              disabled={!startTime || !endTime}
              onChange={() => setMaxGrowth(!maxGrowth)}
            />
          </StyledGrowthSwitch>
        </div>
      </StyledButtons>

      <StyledMain marginTop={60}>
        <div>
          <StyledContainer>
            <DashboardStats
              analytics={statistics.analytics}
              isDateSelected={startTime || endTime}
            />
          </StyledContainer>

          <StyledSection>
            <StyledContainer>
              <ConvertedLeads leads={statistics.analytics} />
            </StyledContainer>

            <StyledContainer>
              <PriorityJobs priorityJobs={priorityJobs.jobs} />
            </StyledContainer>
          </StyledSection>
        </div>

        <StyledContainer>
          <CompaniesOnboarded />
        </StyledContainer>
      </StyledMain>

      <StyledMain>
        <StyledContainer>
          <JobsApplications />
        </StyledContainer>

        <StyledContainer>
          <JobsShortlistApplications />
        </StyledContainer>
      </StyledMain>

      <StyledCandidateContainer>
        <StyledCandidate>
          <JobsPreScreenApplications />
        </StyledCandidate>
      </StyledCandidateContainer>
    </>
  );
};

export default Dashboard;

const StyledGrowthSwitch = styled.div`
  align-self: center;
  margin-left: 10px;
  display: flex;
  gap: 5px;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_4}) {
    align-self: flex-start;
    margin-top: 3px;
  }
`;

const StyledTitle = styled.h1`
  font-size: 26px;
  font-weight: ${FONT_WEIGHTS.BOLD};
  margin: 20px 0;
`;

const StyledCandidate = styled.div`
  box-shadow: 0px 0px 13px #0000001f;
  border-radius: 4px;
  background: white;
  padding: 20px;
  width: 49.3%;

  @media (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_4}) {
    width: 50%;
  }

  @media ((max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3})) {
    width: 100%;
  }
`;

const StyledCandidateContainer = styled.div`
  ${DISPLAY_FLEX()}
  flex-flow: row wrap;
  gap: 20px;
  ${MARGIN(null, null, 20, null)}
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    ${DISPLAY_FLEX('column')}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: ${({ marginTop }) => `${marginTop}px`};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_4}) {
    margin-top: ${({ marginTop }) => `${marginTop + 35}px`};
  }
`;

const StyledContainer = styled.div`
  box-shadow: 0px 0px 13px #0000001f;
  border-radius: 4px;
  background: white;
  ${PADDING(20, 20, 20, 20)}
`;

const StyledMain = styled.div`
  display: flex;
  gap: 20px;
  ${MARGIN(null, null, 20, null)}
  position: relative;

  & > div {
    flex-grow: 1;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    flex-direction: column;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: ${({ marginTop }) => `${marginTop}px`};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_4}) {
    margin-top: ${({ marginTop }) => `${marginTop + 35}px`};
  }
`;

const StyledSection = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  & > div {
    flex-grow: 1;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_8}) {
    flex-direction: column;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 20px;
  height: 35px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    flex-direction: column;
  }

  & > div {
    display: flex;
    gap: 5px;
  }

  & > div:nth-child(2) {
    z-index: 999;
    @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_5}) {
      margin-top: 5px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_5}) {
      margin-top: 5px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_4}) {
      flex-direction: column;
    }

    & > div:first-child {
      display: flex;
      gap: 5px;
    }
  }
`;

const StyledButton = styled.button`
  border: 0.5px solid ${COLORS.AMERICAN_SILVER};
  border-radius: 3px 0px 0px 3px;
  width: max-content;
  padding: 0 22px;
  height: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${({ selected }) => (selected ? 'black' : 'white')};
  color: ${({ selected }) => (selected ? 'white' : 'black')};

  &:hover {
    background: black;
    color: white;
  }
`;
