import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToolTippedInfo } from '../../../../components/shared-components/Upload';
import { PictureUploadWithListProps } from '../../../../interfaces/components/Upload';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import { getFileS3 } from '../../../../utils/s3.storage';

export const PictureUpload: React.FC<PictureUploadWithListProps> = ({
  name = 'upload',
  text = 'Click to Upload',
  icon,
  inline = true,
  style = {},
  className = '',
  onChange,
  customRequest,
  maxCount,
  beforeUpload,
  accept,
  fileList,
  cropActive = false,
  isCoverRemoved,
  id,
  imagePreview,
  hintText,
  showTooltip = true,
  roleId,
}) => {
  // eslint-disable-next-line no-unneeded-ternary
  const [imageSrc, setImageSrc] = useState(imagePreview || '');

  const removeCover = () => {
    isCoverRemoved(true);
    setImageSrc('');
  };

  useEffect(() => {
    if (imagePreview) {
      setImageSrc(imagePreview);
    }
  }, [imagePreview]);

  const color = getUserColorWithRoleId(roleId);

  const defaultHintText = (
    <span>Recommended: JPG, PNG with 400px X 400px in lesser than 3MB</span>
  );

  return cropActive ? (
    <StyledUploadChildrenContainer>
      <StyledUpload
        name={name}
        listType="picture"
        {...(inline ? { width: '100%' } : { className: 'upload-list-inline' })}
        onChange={onChange}
        showUploadList={false}
        itemRender={() => {
          return (
            <span aria-hidden="true">
              <>
                <Tooltip title="close">
                  <StyledButton onClick={removeCover}>
                    <CloseOutlined />
                  </StyledButton>
                </Tooltip>

                <StyledImg alt="" src={imageSrc && getFileS3(imageSrc)} />
              </>
            </span>
          );
        }}
        customRequest={customRequest}
        maxCount={maxCount}
        accept={accept}
        fileList={fileList}
        id={id}
      >
        <UploadButton
          icon={icon}
          className={className}
          style={style}
          block
          ghost
        >
          {text}
        </UploadButton>
        {fileList.length === 0 && showTooltip ? (
          <ToolTippedInfo
            backgroundColor={color}
            hintText={hintText}
            defaultHintText={defaultHintText}
            position="absolute"
            right="-25px"
            bottom="8px"
            ignoreTop
          />
        ) : null}
      </StyledUpload>
    </StyledUploadChildrenContainer>
  ) : (
    <StyledUploadChildrenContainer>
      <StyledUpload
        name={name}
        listType="picture"
        {...(inline ? { width: '100%' } : { className: 'upload-list-inline' })}
        onChange={onChange}
        customRequest={customRequest}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        accept={accept}
        fileList={fileList}
        id={id}
        key={id}
        itemRender={() => {
          return (
            <span aria-hidden="true">
              <>
                <Tooltip title="close">
                  <StyledButton onClick={removeCover}>
                    <CloseOutlined />
                  </StyledButton>
                </Tooltip>

                <StyledImg alt="" src={imageSrc && getFileS3(imageSrc)} />
              </>
            </span>
          );
        }}
      >
        {fileList.length > 0 ? null : (
          <UploadButton icon={icon} className={className} style={style} block>
            {text}
          </UploadButton>
        )}
        {fileList.length === 0 && showTooltip ? (
          <ToolTippedInfo
            backgroundColor={color}
            hintText={hintText}
            defaultHintText={defaultHintText}
            position="absolute"
            right="-25px"
            bottom="8px"
            ignoreTop
          />
        ) : null}
      </StyledUpload>
    </StyledUploadChildrenContainer>
  );
};

const StyledUploadChildrenContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  position: relative;
  background-color: lightgrey;
  height: 235px;
`;

const StyledUpload = styled(Upload)`
  div.ant-upload {
    position: relative;
    display: block;
    width: 100%;
  }

  .anticon svg {
    height: 0.8em;
    width: 0.8em;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_3}) {
    span .anticon-info-circle {
      position: absolute;
      right: -20px;
      bottom: 8px;
    }
  }
`;

const UploadButton = styled(Button)`
  span {
    display: block;
  }

  span.ant-icon {
    width: 100%;
    height: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 235px;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledButton = styled.div`
  color: ${COLORS.LIGHT_GRAY_CLOSE_ICON};
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  z-index: 1000;
`;
