import { gql } from '@apollo/client';
import { authClient, unAuthClient } from '../client';
import ERRORS from '../../constants/errors';

const GET_ALL_CURRENCIES = gql`
  query getAllCurrencies {
    currencies {
      id
      countryName
      code
      usdRate
      status
    }
  }
`;

export const getAllCurrencies = (addAuthHeader = true) => {
  const client = addAuthHeader ? authClient : unAuthClient;
  return client
    .query({
      query: GET_ALL_CURRENCIES
    })
    .then(response => response.data.currencies)
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
