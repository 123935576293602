import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import ChatBubbleButton from './ChatBubbleButton';
import { useMediaQuery } from '../../utils/customHooks';
import P2PChatUsers from './P2PChatUsers';
import { setPersonalChats } from '../../store/features/chat';

const ChatBubble = () => {
  // @ts-ignore
  const { chatWindow } = useSelector((state) => state.chat);
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.MOBILE})`);
  const isFullScreen = chatWindow && isMobile;
  const dispatch = useDispatch();
  const { selectedChatId } = useSelector((state) => get(state, 'chat'));

  // Load Initial Chats
  useEffect(() => {
    dispatch(setPersonalChats(selectedChatId));
  }, [dispatch, selectedChatId]);

  return (
    <div>
      <StyledChatBubble isFullScreen={isFullScreen}>
        {isFullScreen ? null : <ChatBubbleButton chatWindow={chatWindow} />}

        {chatWindow ? <P2PChatUsers /> : null}
      </StyledChatBubble>
    </div>
  );
};

export default ChatBubble;

const StyledChatBubble = styled.div<{ isFullScreen: Boolean }>`
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 999;
  ${DISPLAY_FLEX('row-reverse', 'flex-end', 'flex-end')}
  gap: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ isFullScreen }) =>
      isFullScreen
        ? `
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    `
        : `
        right: 20px;
  bottom: 20px;
        `}
  }
`;
