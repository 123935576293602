import React from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import CircleEditButton from '../Common/CircleEditButton/CircleEditButton';
import BasicInformation from './BasicInformation';
import { getRoleId } from '../../utils/userInfo';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { PADDING } from '../../styles/constants/display';
import { getSize } from '../../utils/size';
import { getColorStringByRoleId } from '../../utils/notification';
import { BigText } from '../shared-components/Texts';

const RepresentativeTeamProfile = ({
  profileData,
  profilePic,
  handleOpenRepresentativeModal,
  setProfilePic,
}) => {
  const roleId = getRoleId();

  return (
    <>
      <BigText margin="0 0 10px 0" fontWeight={700} color={getColorStringByRoleId(roleId)}>
        Representative & Team Profile
      </BigText>
      <StyledItemContainer bgColor={getColorStringByRoleId(roleId)}>
        <StyledHeadingLevelSix>Representative Info</StyledHeadingLevelSix>
        <Row gutter={[16, 24]} align="middle">
          <Col xs={{ span: 24, order: 2 }} md={{ span: 18, order: 1 }}>
            <BasicInformation profileData={profileData} />
          </Col>

          <UploadProfilePicComponent
            avatarSize={getSize(120, 100, 100)}
            option={false}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            fileName={get(profileData, 'profilePic', '')}
          />
        </Row>

        <CircleEditButton
          onClick={handleOpenRepresentativeModal}
          roleId={roleId}
        />
      </StyledItemContainer>
    </>
  );
};

export default RepresentativeTeamProfile;

const StyledItemContainer = styled.div`
  padding: 40px 20px;
  background-color: #fafafa;
  margin-bottom: 40px;
  background-color: ${(props) => `${props.bgColor}0F`};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    position: relative;
    padding: 10px;
    align-items: center;
    ${PADDING(10)};
  }
`;

const StyledHeadingLevelSix = styled.h6`
  color: #1f1f1f;
  font-weight: 600;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 16px;
  }
`;
