import React from 'react';
import { Skeleton } from 'antd';
import { userProfileStore } from '../../utils/profile';
import { CandidateProfilePercentage } from '../shared-components/CandidateProfilePercentage';

const CandidateProfileCompletionProgress = () => {
  const { profileData } = userProfileStore();

  return !profileData ? (
    <Skeleton />
  ) : (
    <CandidateProfilePercentage text="Profile Completed" />
  );
};

export default CandidateProfileCompletionProgress;
