import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { getRoleId } from '../../utils/userInfo';
import {
  crewMembersColumns,
  makeMembersTableData,
} from '../../utils/crewMembers';
import { BigText } from '../shared-components/Texts';
import { getUserColorWithRoleId } from '../../styles/utils';

export default function CrewMembers({ data, members = [] }) {
  const roleId = getRoleId();

  return (
    <>
      <BigText margin="0 0 15px 0" fontWeight={700} color={getUserColorWithRoleId(roleId)}>Team Members</BigText>
      <StyledScrollableContainer>
        <Table
          columns={crewMembersColumns}
          dataSource={makeMembersTableData(members, data)}
          pagination={false}
        />
      </StyledScrollableContainer>
    </>
  );
}

export const StyledScrollableContainer = styled.div`
  overflow: auto;
`;
