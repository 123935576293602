import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import DashboardEarningsNotifications from '../../Dashboard/DashboardEarningsNotifications';
import UpcomingEvents from './UpcomingEvents';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN } from '../../../styles/constants/display';
import { checkIfUserIsCandidate, getRoleId } from '../../../utils/userInfo';
import DashboardAnalytics from '../../Dashboard/Analytics/Analytics';
import { isTablet } from '../../../helpers/display';

export default ({
  dashboard = false,
  locateInDashboard = false,
  colPropsUpcomingSection = { span: 24 },
  search = '',
  noTimeline = null,
  noTitle = null,
  isCandidateDashboard,
}) => {
  const roleId = getRoleId();
  return (
    <StyledContainer
      candidate={checkIfUserIsCandidate(roleId)}
      noTitle={noTitle}
    >
      <UpcomingEvents
        dashboard={dashboard}
        locateInDashboard={locateInDashboard}
        colPropsUpcomingSection={colPropsUpcomingSection}
        search={search}
        noTimeline={noTimeline}
        noTitle={noTitle}
        isCandidateDashboard={isCandidateDashboard}
      />
      <StyledCard>
        {dashboard && checkIfUserIsCandidate(roleId) && isTablet ? (
          <DashboardAnalytics />
        ) : null}
      </StyledCard>
      {dashboard ? <DashboardEarningsNotifications /> : null}
    </StyledContainer>
  );
};

const StyledContainer = styled(Row)`
  ${({ noTitle }) => (noTitle ? '' : MARGIN(15, 0))}

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    flex-direction: ${({ candidate }) =>
      candidate ? 'column' : 'column-reverse'};
  }
`;

const StyledCard = styled.div`
  @media (min-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: none;
  }
`;
