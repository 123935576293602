import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { CANDIDATE_COLOR } from '../../../../constants';
import { MediumHeading } from '../../../../components/shared-components/Texts';
import { StyledCol, StyledPrevButton } from './QuickStartSkillSet';
import { largeFontMixin } from '../../../../styles/constants/mixins';
import {
  checkIfJobMatchWithCandidate,
  checkJobWorkAuthorization,
  updateWorkAuthorization,
} from '../../../../service/candidate';
import Notification from '../../../../components/Notification/Notification';

const WorkAuthorization = ({
  isVisible,
  applyJobData,
  handleSaveApplyJob,
  handleJobReloadWithProfile,
  eventId: communityEventId = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobCountry, setJobCountry] = useState();
  const [jobCountryId, setJobCountryId] = useState();
  const { id: eventId } = useParams();
  let event;
  if (eventId) {
    event = eventId;
  } else event = communityEventId;
  const handleYes = () => {
    const jobId = get(applyJobData, 'job.id');
    updateWorkAuthorization({
      workAuthorizations: [
        { operation: 'add', countryId: jobCountryId, isAuthorized: true },
      ],
    }).then(async () => {
      const isMatching = await checkIfJobMatchWithCandidate(event, jobId);
      if (isMatching) {
        handleSaveApplyJob();
      } else {
        Notification(
          'error',
          'Application',
          `Your skills don't match the job requirements. Please upgrade your skill to see more jobs`,
          null,
        );
        handleJobReloadWithProfile();
      }
    });
  };

  const handleNo = () => {
    updateWorkAuthorization({
      workAuthorizations: [
        { operation: 'add', countryId: jobCountryId, isAuthorized: false },
      ],
    }).then(() => {
      handleJobReloadWithProfile();
      // refetch jobs
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const jobId = get(applyJobData, 'job.id');
      if (jobId) {
        const response = await checkJobWorkAuthorization(jobId);
        const jobCountry = get(response, 'jobCountry', '');
        const jobCountryId = get(response, 'jobCountryId', '');
        setJobCountry(jobCountry);
        setJobCountryId(jobCountryId);
        setIsLoading(false);
      }
    })();
  }, [applyJobData]);

  /** This useEffect is responsible for applying the job automatically if there is not country id and country */
  useEffect(() => {
    if (isVisible && !jobCountry && !jobCountryId) {
      handleSaveApplyJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobCountry, jobCountryId, isVisible]);

  return isVisible && !isLoading ? (
    <>
      <StyledQuickSubmissionTitle level={4}>
        <MediumHeading margin="0px">
          Are you authorized to work in {jobCountry}?
        </MediumHeading>
      </StyledQuickSubmissionTitle>

      <Row>
        <Col xs={12} lg={12}>
          <StyledPrevButton color={CANDIDATE_COLOR} onClick={handleNo}>
            No
          </StyledPrevButton>
        </Col>
        <StyledCol xs={12} lg={12}>
          <StyledButton color={CANDIDATE_COLOR} onClick={handleYes}>
            Yes
          </StyledButton>
        </StyledCol>
      </Row>
    </>
  ) : null;
};

export default WorkAuthorization;

// Wrap around MediumHeading
const StyledQuickSubmissionTitle = styled(Title)`
  margin-top: 10px;
  margin-bottom: 20px !important;
`;

export const StyledButton = styled.button`
  width: 90%;
  height: 40px;
  background-color: ${(props) => props.color};
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  max-width: 168px;
  border: none;
  cursor: pointer;
  ${largeFontMixin}
`;
