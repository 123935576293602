import React from 'react';
import get from 'lodash/get';
import { Input, Modal, Tooltip } from 'antd';
import styled from 'styled-components';
import { TransactionArrowIcon } from '../svgicons';
import { useAccount, useFeeData } from 'wagmi';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import config from '../../config';
import { usePlanSelector } from '../../context/CryptoPlanProvider';
import { truncateWalletAddress } from '../../utils/web3utils';
import MandatoryStar from '../Common/mandatoryStar';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { cryptoIncentivePlanColor } from '../../constants/crypto';
import { useCoupon } from '../../hooks/useCoupon';
import { getDiscountAmmount } from '../../utils/event';
import RightArrow from '../Common/icons/RightArrow';
import PencilIcon from '../../assets/svg/PencilIcon.svg';
import useSocialMediaInput from '../../hooks/useSocialMediaInput';

const TransactionModal = ({
  isVisible,
  closeModal,
  handlePayment,
  ongoingTransaction,
  skipPayment,
}: Web3TransactionProps) => {
  const { address } = useAccount();
  const {
    isStartup,
    socialMediaLink,
    socialMediaChangeHandler,
    enableStartupView,
    handleStartupSocialLink,
    linkError,
    resetEveryThing,
  } = useSocialMediaInput(skipPayment);
  const receiverAddress = config.recoremWalletAddress as string;
  const { selectedPlan } = usePlanSelector();
  const { data } = useFeeData({
    formatUnits: 'gwei',
    staleTime: 30000,
    cacheTime: 40000,
  });
  const planName = get(selectedPlan, 'name', '');
  const amount = get(selectedPlan, 'amount', 0);
  const symbol = get(selectedPlan, 'cryptoCurrency.symbol', 'USDC');
  const {
    coupon,
    couponText,
    isInputShown,
    onCouponChange,
    showInput,
    isCouponValidating,
    onValidateCoupon,
  } = useCoupon();
  let discountAmount: number = 0;
  let discountedAmount: number = amount;
  if (coupon) {
    discountAmount = getDiscountAmmount(amount, coupon.type, coupon.value);
    discountedAmount = amount - discountAmount;
  }

  return (
    <StyledModal
      visible={isVisible}
      onCancel={() => {
        closeModal();
        resetEveryThing();
      }}
      footer={null}
      width={'800px'}
    >
      <StyledModalContainer>
        {/* Header */}
        <StyledModalHeader>
          <h3>Transaction Details</h3>
        </StyledModalHeader>
        {/* Content */}
        <div>
          {/* Content Header */}
          <StyledContentHeader>
            <div className="address sender_address">
              {truncateWalletAddress(address as string)}
            </div>
            <div>
              <TransactionArrowIcon />
            </div>
            <div className="address receiver_address">
              {truncateWalletAddress(receiverAddress)}
            </div>
          </StyledContentHeader>
          {/* Content Plan Info */}
          <StyledPlanInfo>
            {/* Plan Name */}
            <StyledInfoCol id="firstInfo">
              <div>
                <h4>Plan name</h4>
              </div>
              <div>
                <StyledPlanName planId={selectedPlan?.id}>
                  {planName}
                </StyledPlanName>
              </div>
            </StyledInfoCol>
            {/* Price */}
            <StyledInfoCol>
              <div>
                <h4>Price</h4>
              </div>
              <div>
                <span>
                  {discountedAmount} {symbol}
                </span>
              </div>
            </StyledInfoCol>
          </StyledPlanInfo>
          <StyledWeb3Bar />
          {/* Content Price Checkout */}
          <StyledCheckout>
            {/* Sub total */}
            <StyledCheckoutCol>
              <div>
                <span className="checkout_title">Sub total:</span>
              </div>
              <div>
                <span className="result">
                  {discountedAmount} {symbol}
                </span>
              </div>
            </StyledCheckoutCol>
            {/* Estimated Gas Fee */}
            <StyledCheckoutCol>
              <div>
                <Tooltip title="Gas Fee can be changed during transaction">
                  <span className="checkout_title">
                    Est Gas Fee:
                    <MandatoryStar />
                  </span>
                </Tooltip>
              </div>
              <div>
                <span className="result">
                  {Number(data?.formatted.maxFeePerGas).toFixed(2)} gwei / gas
                </span>
              </div>
            </StyledCheckoutCol>
            {/* Total */}
            <StyledCheckoutCol>
              <div>
                <span className="checkout_title total_price">Total:</span>
              </div>
              <div>
                <span className="total_price">
                  {discountedAmount} {symbol} +{' '}
                  {Number(data?.formatted.maxFeePerGas).toFixed(2)} gwei / gas
                </span>
              </div>
            </StyledCheckoutCol>
          </StyledCheckout>
          <StyledWeb3Bar />
          {!isStartup && (
            <StyledCouponOutputWrapper>
              <div>
                <p>
                  Have a promo code?{' '}
                  {!isInputShown ? (
                    <span data-variant="edit-icon" onClick={showInput}>
                      <img
                        src={PencilIcon}
                        height={10}
                        width={10}
                        alt={'edit'}
                      />
                    </span>
                  ) : null}
                </p>
              </div>
              <div>
                {!isInputShown && coupon ? (
                  <p>
                    Congratulations!{' '}
                    <span data-variant="coupon-code">{coupon?.code}</span> code
                    applied! An additional discount of {discountAmount} {symbol}{' '}
                    is now applied.
                  </p>
                ) : (
                  <StyledCouponInput>
                    <Input
                      name="coupon"
                      id="coupon"
                      value={couponText}
                      onChange={onCouponChange}
                      disabled={isCouponValidating}
                    />
                    {couponText.length > 0 ? (
                      <RightArrowButton
                        onClick={() => onValidateCoupon(selectedPlan.id)}
                      />
                    ) : null}
                  </StyledCouponInput>
                )}
              </div>
            </StyledCouponOutputWrapper>
          )}
          <StyledLowerSection>
            <StartupCouponSection>
              <div>
                <p>
                  Bootstrapped and pre revenue companies have special offers
                  {!isStartup ? (
                    <span>
                      ,{' '}
                      <PurpleLink onClick={enableStartupView}>
                        Click here
                      </PurpleLink>{' '}
                      to claim.
                    </span>
                  ) : null}
                </p>
                {isStartup && (
                  <>
                    {/* React throws an warning sometimes that hasError is not recognized thats why used dollar sign $ to mention it is a custom prop */}
                    <StyledSocialMediaInput $hasError={linkError.error}>
                      <Input
                        name="socialMedia"
                        type="url"
                        value={socialMediaLink}
                        placeholder={'Enter your social media'}
                        onChange={socialMediaChangeHandler}
                        onPressEnter={handleStartupSocialLink}
                      />
                      {socialMediaLink.length > 0 && (
                        <RightArrowButton onClick={handleStartupSocialLink} />
                      )}
                    </StyledSocialMediaInput>
                    {linkError.error ? (
                      <SmallError>*{linkError.message}</SmallError>
                    ) : null}
                  </>
                )}
              </div>
            </StartupCouponSection>
            <StyledButtonContainer>
              <button
                onClick={() =>
                  handlePayment(selectedPlan.id, discountedAmount, coupon?.code)
                }
                disabled={ongoingTransaction}
              >
                Pay now
              </button>
            </StyledButtonContainer>
          </StyledLowerSection>
        </div>
      </StyledModalContainer>
    </StyledModal>
  );
};

const RightArrowButton = ({ onClick }: { onClick: () => void }) => (
  <StyledIconButton type="button" onClick={onClick}>
    <RightArrow />
  </StyledIconButton>
);

type Web3TransactionProps = {
  isVisible: boolean;
  ongoingTransaction: boolean;
  closeModal: () => void;
  handlePayment: (
    id: string | number,
    amount: number,
    couponCode?: string,
  ) => Promise<void>;
  skipPayment: () => void;
};

export default React.memo(TransactionModal);

const StyledModalContainer = styled.div`
  ${PADDING(10)}
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-close-x {
    ${MARGIN(12, 0, 0, 0)}
  }
  .ant-modal-body {
    ${PADDING(10)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-modal-close-x {
      display: block;
      ${MARGIN(10, 0, 0, 0)}
    }
    .ant-modal-content {
      ${MARGIN(10)}
    }
  }
`;

const SmallError = styled.span`
  color: ${COLORS.RED};
  font-size: 11px;
`;

const StyledLowerSection = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}
  ${PADDING(0, 0, 0, 30)}
  ${MARGIN(0, 0, 6, 0)}

  @media (max-width : ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 0, 0, 10)}
  }
`;

const PurpleLink = styled.span`
  color: ${COLORS.HELIOTROPE};
  text-decoration: underline;
  cursor: pointer;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

const StartupCouponSection = styled.div`
  ${MARGIN(10, 0, 0, 0)}
  flex-basis : 50%;
  p {
    font-size: 14px;
    span {
      font-size: 14px;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    flex-basis: 64%;
    p,
    p span {
      font-size: 11px;
    }
  }
`;

const StyledIconButton = styled.button`
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid ${COLORS.HELIOTROPE};
  ${PADDING(0, 8)}
  ${DISPLAY_FLEX('row', 'center', 'center')}
  cursor : pointer;
  flex: 1;
  width: 10px;
  svg {
    fill: ${COLORS.HELIOTROPE};
  }

  &:hover {
    box-shadow: 0 0 2px ${COLORS.HELIOTROPE};
  }
`;

const StyledCouponInput = styled.div`
  width: 60%;
  ${MARGIN(6, 0, 0, 0)}
  ${DISPLAY_FLEX('row')};
  gap: 8px;
  input {
    flex-basis: 75%;
  }
  button {
    flex-grow: 0;
    flex-basis: 15%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 80%;
    ${MARGIN(6, 0, 12, 0)}
    input {
      font-size: 12px;
      flex-basis: 80%;
    }
  }
`;

const StyledSocialMediaInput = styled.div<{ $hasError: boolean }>`
  ${DISPLAY_FLEX('row')}
  gap : 6px;
  ${MARGIN(10, 0, 0, 0)}
  .ant-input {
    ${({ $hasError }) =>
      $hasError
        ? `
      border-color : ${COLORS.CORAL_RED};
      box-shadow : none;
      `
        : null};
  }

  input {
    flex-basis: 60%;
    font-size: 13px;
  }
  button {
    flex-grow: 0;
    flex-basis: 10%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    input {
      flex-basis: 80%;
      font-size: 11px;
    }
    button {
      flex-basis: 15%;
    }
  }
`;

const StyledModalHeader = styled.div`
  ${MARGIN(0, 0, 35, 0)}
  h3 {
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 18, 0)}
    h3 {
      font-size: 18px;
    }
  }
`;

const StyledCouponOutputWrapper = styled.div`
  ${PADDING(0, 0, 0, 30)}
  width : 60%;
  p {
    font-size: 14px;
    span {
      font-size: 14px;
      &[data-variant='coupon-code'] {
        color: ${COLORS.HELIOTROPE};
      }
      &[data-variant='edit-icon'] {
        cursor: pointer;
        ${MARGIN(0, 0, 0, 3)}
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 0, 0, 10)}
    width: 100%;
    text-align: justify;
    p {
      font-size: 11px;
      span {
        font-size: 11px;
      }
    }
  }
`;

const StyledContentHeader = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  gap : 20px;
  ${MARGIN(0, 0, 18, 0)}
  .address {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }
  .receiver_address {
    color: ${COLORS.ELECTRIC_VIOLET};
  }
  svg {
    ${MARGIN(0, 0, 2, 0)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 18, 0)}
    .address {
      font-size: 14px;
    }
    svg {
      width: 110px;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_2}) {
    svg {
      width: 80px;
    }
  }
`;

const StyledPlanName = styled.span<{ planId: 1 | 2 | 3 }>`
  ${({ planId }) => `color : ${cryptoIncentivePlanColor[planId]}`}
`;

const StyledPlanInfo = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}
  ${PADDING(20, 40)}
  #firstInfo {
    align-items: flex-start;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10)}
  }
`;

const StyledInfoCol = styled.div`
  ${DISPLAY_FLEX('column', null, 'center')}
  gap : 18px;
  h4 {
    text-transform: uppercase;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    letter-spacing: 0.09em;
  }
  span {
    font-weight: ${FONT_WEIGHTS.MEDIUM};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 12px;
    h4 {
      font-size: 15px;
    }
    & > div span {
      font-size: 14px;
    }
  }
`;

const StyledWeb3Bar = styled.hr`
  border-color: rgba(178, 80, 255, 0.5);
  border-top: 0;
  ${MARGIN(10, 25)}

  @media (max-width : ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(10)}
  }
`;

const StyledCheckout = styled.div`
  ${DISPLAY_FLEX('column', 'center', 'flex-end')}
  gap : 12px;
  ${PADDING(35, 40, 25, 0)}

  .total_price {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    color: ${COLORS.LAVENDER_INDIGO};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(20, 20, 20, 0)}
  }
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_7}) {
    align-items: center;
    ${PADDING(20, 0)}
  }
`;

const StyledCheckoutCol = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}
  width : 300px;

  .result {
    font-weight: ${FONT_WEIGHTS.MEDIUM};
  }
  .checkout_title {
    text-transform: capitalize;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    & > div span {
      font-size: 14px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  align-self: flex-end;
  ${PADDING(15, 0)}
  button {
    background-color: ${COLORS.HELIOTROPE};
    color: ${COLORS.WHITE};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    border: 1px solid transparent;
    ${PADDING(6, 25)}
    border-radius: 5px;
    transition: all 0.2s ease-in;
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.WHITE};
      color: ${COLORS.HELIOTROPE};
      border-color: ${COLORS.HELIOTROPE};
    }

    &:disabled {
      background-color: ${COLORS.CHINESE_SILVER};
      color: ${COLORS.WHITE};
      border: 1px solid transparent;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 13px;
      ${PADDING(5, 15)}
    }

    @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_2}) {
      font-size: 12px;
      ${PADDING(4, 15)};
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 10, 0, 0)}
  }
`;
