import React from 'react';
import { Result, Button } from 'antd';

const PrivatePageNotFound = ({
  resetErrorBoundary,
  status = '404',
  title = '404',
  subTitle = 'Sorry, the page you visited does not exist.',
}) => {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button
          type="primary"
          onClick={() => {
            if (resetErrorBoundary) {
              resetErrorBoundary();
            }
            window.history.back();
          }}
        >
          Go Back
        </Button>
      }
    />
  );
};

export default PrivatePageNotFound;
