import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { getFileS3 } from '../../../utils/s3.storage';
import { shortenText, timeAgo } from '../../../utils/chat';
import SocketContext from '../../../context/socket';
import {
  ColorFlagProps,
  ConversationCardProps,
} from '../../../interfaces/components/Chat';
import { StyledSmallFont } from '../Texts';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getUserColorWithRoleId } from '../../../styles/utils';
import AvatarOnline from '../../Chat/AvatarOnline';
import { MESSAGE_CONTENT_TYPE } from '../../../constants/chat';

export const ConversationCard: React.FC<ConversationCardProps> = ({
  chatData,
  secondUser,
}) => {
  const { message: messageObj, id } = chatData;
  const { name, roleId, profilePic, id: senderId, type } = secondUser;
  const [message, setMessage] = useState<string>();
  const [time, setTime] = useState<string>();
  const [contentType, setContentType] = useState<number>();
  const picture = getFileS3(profilePic) || '';
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const client = useContext(SocketContext);

  useEffect(() => {
    const msg = get(messageObj, 'message', '');
    setMessage(shortenText(msg, 28));
    setTime(get(messageObj, 'createdAt', ''));
    const contType = get(messageObj, 'contentType', '');
    setContentType(contType);
  }, [messageObj]);

  // Real time Socket Integration
  useEffect(() => {
    (async () => {
      await client.subscribe(`/chat-room/${id}/`, (resp: any) => {
        setMessage(resp.message);
        setTime(resp.dateTime);
        setContentType(resp.contentType);
      });
    })();
  }, [client, id]);

  useEffect(() => {
    if (senderId && type && client) {
      (async () => {
        await client.subscribe(
          `/user/${senderId}/${type}/`,
          async (resp: any) => {
            const newStatus = get(resp, 'isOnline', null);

            setIsOnline(newStatus);
          },
        );
        await client.message({ type: 40, userId: senderId, userType: type });
      })();
    }

    // eslint-disable-next-line
  }, [client, senderId, type]);

  return (
    <>
      <ColorFlag bgColor={getUserColorWithRoleId(roleId)} />
      <AvatarOnline picture={picture} isOnline={isOnline} />
      <ConversationDetail>
        <DetailSection>
          <NameText id="chat-card-name">{shortenText(name, 20)}</NameText>
          <TimeText>{time ? timeAgo(time) : time}</TimeText>
        </DetailSection>
        <DetailSection>
          <MessageText id="chat-card-message">{
            contentType === MESSAGE_CONTENT_TYPE.GIF ? "GIF" : message
          }</MessageText>
        </DetailSection>
      </ConversationDetail>
    </>
  );
};

const ColorFlag = styled.div<ColorFlagProps>`
  width: 8px;
  height: 100%;
  margin-right: 15px;
  background-color: ${(props) => props.bgColor};
`;

const ConversationDetail = styled.div`
  ${DISPLAY_FLEX('column', 'space-between')}

  flex: auto;
  padding-right: 10px;
`;

const DetailSection = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}
`;

const NameText = styled.div`
  font-size: ${FONT[12]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.BLACK};
  margin-bottom: 4px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const TimeText = styled.div`
  font-size: ${FONT[10]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.BLACK};
  margin-bottom: 4px;
  opacity: 60%;
  text-align: right;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[12]};
  }
`;

const MessageText = styled(StyledSmallFont)`
  color: ${COLORS.EERIE_BLACK};
  font-weight: ${FONT_WEIGHTS.REGULAR};
  opacity: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10vw;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 35vw;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[14]};
  }
`;
