import React from 'react';
import styled from 'styled-components';
import { MARGIN } from '../../styles/constants/display';
import { largeFontMixin } from '../../styles/constants/mixins';
import MainContainer from '../Common/MainContainer';
import Events from './Events';

const EventsTab = () => {
  return (
    <StyledSection>
      <MainContainer>
        <Events section={false} />
      </MainContainer>
    </StyledSection>
  );
};

export default EventsTab;

const StyledSection = styled.section`
  ${MARGIN(20, 0)}
  ${largeFontMixin};
`;
