/**
 * It expects two arguments with number values and return one of it
 * @param {number} runTestStep
 * @param {number} normalStep
 * @returns {number}
 */
export const getStep = (runTestStep, normalStep) => {
  if (runTestStep) {
    return runTestStep;
  }
  return normalStep;
};
