import React, { useState } from 'react';

const ConfigContext = React.createContext({});

export const ConfigProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [navigationList, setNavigationList] = useState([]);
  const [newUser, setNewUser] = React.useState(false);

  const configContextValue = {
    theme,
    setTheme,
    navigationList,
    setNavigationList,
    newUser,
    setNewUser,
  };

  return (
    <ConfigContext.Provider value={configContextValue}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
