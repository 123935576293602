import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Skeleton, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  getOrganizerCrewMembersData,
  getRecruiterCrewMembersData,
} from '../../store/features/crew';
import { crewMemberStore, getCompanyStatus } from '../../utils/profile';
import InviteMemberModal from './InviteMemberModal';
import CrewMemberStatus from './CrewMemberStatus';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsCandidate,
  getUserClassWithRoleId,
} from '../../utils/userInfo';
import { OrganizerRoutes, RecruiterRoutes } from '../../constants/routes';
import {
  handleAcceptInvitation,
  handleRejectInvitation,
  handleAcceptCrew,
  isMemberActive,
  isMemberPending,
} from '../../utils/crew';
import { STATUS_ACTIVE } from '../../constants';
import PendingInvitation from './PendingInvitations';
import { ProfileInformationHeader, ProfileInformationValue } from './common';
import { ShowMobile } from '../shared-components/common';
import { COLORS } from '../../styles/constants/colors';
import { Heading, StyledLargeFont } from '../shared-components/Texts';
import { HiddenMobile } from '../shared-components/DocumentViewerModal';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { PrimaryButton } from '../shared-components/Button';

const CrewMangement = ({ profileData, roleId }) => {
  const dispatch = useDispatch();
  const { isLoadingCrew, members, errorCrew } = crewMemberStore();

  const stableDispatch = useCallback(
    (args) => {
      return dispatch(args);
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      !checkIfUserIsCandidate(roleId) &&
      getCompanyStatus(profileData) === STATUS_ACTIVE
    ) {
      checkIfUserIsOrganizer(roleId)
        ? stableDispatch(getOrganizerCrewMembersData())
        : stableDispatch(getRecruiterCrewMembersData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, stableDispatch, getCompanyStatus(profileData)]);

  const handleAccept = (idx, companyName) => {
    handleAcceptInvitation(idx, companyName, dispatch, profileData);
  };

  const [inviteMemberModal, setInviteMemberModal] = useState(false);

  const handleOpenInviteMemberModal = () => {
    setInviteMemberModal(true);
  };

  const handleCloseInviteMemberModal = () => {
    setInviteMemberModal(false);
  };

  const invitations = get(profileData, 'invitations', []);

  const handleReject = (idx, companyName) => {
    handleRejectInvitation(idx, companyName, dispatch, profileData);
  };

  const handleAcceptPendingCrew = (crewMemberId) => {
    handleAcceptCrew(crewMemberId, dispatch);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Heading>Crew Management</Heading>
        <PrimaryButton
          theme={getUserClassWithRoleId(roleId)}
          onClick={handleOpenInviteMemberModal}
          id="manage_crew_btn"
        >
          Manage Crew
        </PrimaryButton>
      </Row>
      {isLoadingCrew || errorCrew ? (
        <Skeleton />
      ) : (
        <Col xs={24}>
          <Heading>Crew Members</Heading>
          <HiddenMobile>
            <Row gutter={[16, 24]}>
              <Col xs={24} lg={5}>
                <ProfileInformationHeader>Member Name</ProfileInformationHeader>
              </Col>
              <Col xs={24} lg={5}>
                <ProfileInformationHeader>
                  Phone Number
                </ProfileInformationHeader>
              </Col>
              <Col xs={24} lg={5}>
                <ProfileInformationHeader>
                  Email Address
                </ProfileInformationHeader>
              </Col>
              <Col xs={24} lg={2}>
                <ProfileInformationHeader />
              </Col>
              <Col xs={24} lg={4} />
            </Row>
          </HiddenMobile>
          {members.map((member, index) => (
            <ProfileInformationWrapper>
              <StyledRow gutter={[16, 24]} key={index.toString()}>
                <Col xs={24} lg={5}>
                  <ShowMobile>
                    <ProfileInformationHeader>
                      Member Name
                    </ProfileInformationHeader>
                  </ShowMobile>
                  <ProfileInformationValue>
                    {get(member, 'name', '--')}
                  </ProfileInformationValue>
                </Col>
                <Col xs={24} lg={5}>
                  <ShowMobile>
                    <ProfileInformationHeader>
                      Phone Number
                    </ProfileInformationHeader>
                  </ShowMobile>
                  <ProfileInformationValue>
                    {member.phone && member.phone.trim() !== 'undefined'
                      ? member.phone
                      : '--'}
                  </ProfileInformationValue>
                </Col>
                <Col xs={24} lg={5}>
                  <ShowMobile>
                    <ProfileInformationHeader>
                      Email Address
                    </ProfileInformationHeader>
                  </ShowMobile>
                  <ProfileInformationValue>
                    {member.email}
                  </ProfileInformationValue>
                </Col>
                <Col xs={24} lg={3}>
                  <CrewMemberStatus member={member} />
                </Col>
                <Col xs={24} lg={2}>
                  {isMemberActive(member) ? (
                    <Link
                      to={
                        checkIfUserIsOrganizer(roleId)
                          ? OrganizerRoutes.profileCrew(member.userId)
                          : RecruiterRoutes.profileCrew(member.userId)
                      }
                    >
                      <StyledViewDetailsLink>
                        View Profile
                      </StyledViewDetailsLink>
                    </Link>
                  ) : null}
                  {/* For Pending */}
                  {isMemberPending(member) ? (
                    <StyledAcceptButton
                      onClick={() => handleAcceptPendingCrew(member.id)}
                    >
                      Accept
                    </StyledAcceptButton>
                  ) : null}
                </Col>
              </StyledRow>
            </ProfileInformationWrapper>
          ))}
          <StyledRow gutter={[0, 10]}>
            <Col xs={24} lg={8}>
              <StyledAddCrewButton
                type="dashed"
                onClick={handleOpenInviteMemberModal}
              >
                + Add Crew Member
              </StyledAddCrewButton>
            </Col>
          </StyledRow>
        </Col>
      )}

      {invitations && invitations.length ? (
        <PendingInvitation
          invitations={invitations}
          roleId={roleId}
          handleAcceptInvitation={handleAccept}
          handleRejectInvitation={handleReject}
        />
      ) : null}
      {inviteMemberModal ? (
        <InviteMemberModal
          visible={inviteMemberModal}
          handleClose={handleCloseInviteMemberModal}
        />
      ) : null}
    </>
  );
};

export default CrewMangement;

const StyledViewDetailsLink = styled(StyledLargeFont)`
  cursor: pointer;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  background-color: #f2f2f2;
  ${PADDING(10, 30)};
  position: absolute;
  bottom: 3px;
  width: 180px;
  text-align: center;
  border-radius: 1px;

  @media (max-width: ${BREAKPOINTS.MOBILE}),
    (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
    position: relative;
    bottom: 0;
  }
`;

const StyledAcceptButton = styled(StyledViewDetailsLink)`
  background-color: transparent;
  border: 1px solid ${COLORS.CHINESE_SILVER};
`;

const ProfileInformationWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.MOBILE}) {
    div {
      margin-top: 20;
    }
  }
`;

const StyledAddCrewButton = styled(Button)`
  width: 100%;
  max-width: 250px;
  border: 1px dashed ${COLORS.CHINESE_BLACK};
  border-radius: 3px;
  height: 42px;
  margin-top: 20px;

  &:hover,
  &:active,
  &:focus {
    border: 1px dashed ${COLORS.CHINESE_BLACK};
  }

  span {
    color: ${COLORS.CHINESE_BLACK};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-width: none;
  }
`;

const StyledRow = styled(Row)`
  @media (min-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(50, 0, 0)}
  }

  ${MARGIN(25, 0, 0)}
`;
