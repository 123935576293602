import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { DeleteIcon, FileIcon } from '../svgicons';
import { FileContainerProps } from '../../interfaces/components/FileContainer';
import { COLORS } from '../../styles/constants/colors';
import { SmallFont } from './Texts';
import { FONT } from '../../styles/constants/typography';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';

export const FileContainer: React.FC<FileContainerProps> = ({
  link,
  fileName,
  key = 0,
  onDelete = null,
  isButton = false,
  onClick = undefined,
  isLoading = false,
}) => {
  return (
    <Wrapper>
      <FileIconContainer>
        <FileIcon />
      </FileIconContainer>
      {!isButton ? (
        <FileLink href={link} target="blank" download>
          <SmallFont>{`${fileName} ${key || ''}`}</SmallFont>
        </FileLink>
      ) : (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <ButtonLink onClick={onClick}>
          <SmallFont>{`${fileName} ${key || ''}`}</SmallFont>
        </ButtonLink>
      )}

      {onDelete ? (
        <DeleteButton onClick={onDelete}>
          <DeleteIcon />
        </DeleteButton>
      ) : null}
      {isLoading ? <StyledSpinner /> : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 2px dashed ${COLORS.ARGENT};
  max-width: max-content;
  min-width: 160px;
  border-radius: 5px;
  margin: 5px;
  ${PADDING(4, 8)}
  ${DISPLAY_FLEX('row', null, 'center')}

  color: ${COLORS.CHINESE_BLACK};
  transition: background-color 0.3s;
  position: relative;
`;

const FileIconContainer = styled.div`
  color: ${COLORS.DAVYS_GREY};
  font-size: ${FONT[14]};
  padding-top: 3px;
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const commonLink = `
display: inline-block;
width: 100%;
${PADDING(null, null, null, 10)}
overflow: hidden;
line-height: 1.5715;
white-space: nowrap;
text-overflow: ellipsis;
text-decoration: inherit;
color: inherit;
cursor: pointer;
`;

const FileLink = styled.a`
  ${commonLink}
`;

const DeleteButton = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}

  position: absolute;
  right: 5px;
  color: ${COLORS.DAVYS_GREY};
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ButtonLink = styled.span`
  ${commonLink}
  ${PADDING(0, 18, 0, 5)}
`;

const StyledSpinner = styled(Spin)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;
