import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import get from 'lodash/get';
import { getJobAnnualPay } from '../../utils/jobDetails';
import { getJobDetails } from '../../store/features/jobOpportunities';
import { CHATROOM_TYPE_PRIVATE, USERTYPE_COMPANY } from '../../constants/index';
import config from '../../config';
import {
  eventDataRegardingChatroom,
  recruiterInfoRegardingChatroom,
  userInfoRegardingChatroom,
} from '../../utils/chat';
import { createChatroom, isChatroomExist } from '../../service/chat';
import {
  getEvaluationDeadlineDateTime,
  getEventById,
} from '../../service/event';
import Notification from '../../components/Notification/Notification';
import { LargeFont } from '../../components/shared-components/Texts';
import { JobModal } from './JobModal';
import { COLORS } from '../../styles/constants/colors';
import { ToolTipsText } from '../../constants/tooltips';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { FONT } from '../../styles/constants/typography';
import {
  changeChatState,
  changeChatWindow,
  changeSelectedChatId,
} from '../../store/features/chat';

const JobDetails = (props) => {
  const [jobDetails, setJobDetails] = useState('');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const { job, eventId } = props;

  const jobType = get(jobDetails, 'jobType', []);

  const [evaluationDeadline, setEvaluationDeadline] = useState(null);

  const fetchJobData = async () => {
    if (!jobDetails) {
      const jobDetailsData = dispatch(getJobDetails(job.jobId));
      jobDetailsData.then(async (data) => {
        const jobData = get(data, 'payload.jobData', null);
        const companyId = get(jobData, 'companyId', null);
        if (eventId && companyId) {
          const eDeadline = await getEvaluationDeadlineDateTime(
            eventId,
            companyId,
          );
          setEvaluationDeadline(eDeadline);
        }
        setJobDetails(jobData);
      });
    }
  };

  // Initiating Chat with Recruiter

  const userData = config.chatEnabled ? userInfoRegardingChatroom() : null;

  const onInitiatingChat = async () => {
    try {
      if (config.chatEnabled) {
        const existedChatroom = await isChatroomExist(
          eventId,
          get(jobDetails, 'companyId', null),
          USERTYPE_COMPANY,
        );

        let chatRoomId;

        if (existedChatroom) {
          chatRoomId = get(existedChatroom, '_id', '');
        } else {
          const event = await getEventById(eventId);
          const eventData = eventDataRegardingChatroom(event);
          const companyData = recruiterInfoRegardingChatroom(jobDetails);

          const createdChatroom = await createChatroom(
            { ...eventData, endTime: evaluationDeadline },
            [userData, companyData],
            CHATROOM_TYPE_PRIVATE,
            false,
          );
          chatRoomId = get(createdChatroom, 'chatRoom._id', '');
        }

        dispatch(changeSelectedChatId(chatRoomId));
        dispatch(changeChatState(true));
        dispatch(changeChatWindow(true));
      }
    } catch (err) {
      Notification('error', 'Error', 'Error in initiating chat');
    }
  };

  // Currency Data
  const { currencyData } = useSelector((st) => st.commons);
  const { profileData } = useSelector((st) => st.profile);
  const {
    expectedCtcCurrency,
    expectedSalaryCurrency,
    currentCtcCurrency,
    currentSalaryCurrency,
  } = profileData;

  const ViewJobSalary = () => {
    // Expected salary currency is available
    if (expectedCtcCurrency || expectedSalaryCurrency) {
      return getJobAnnualPay({
        job: jobDetails,
        currency: expectedCtcCurrency,
        currencyObject: expectedSalaryCurrency,
        currenciesData: currencyData,
      });
    }

    // Current salary currency is available
    if (currentCtcCurrency || currentSalaryCurrency) {
      return getJobAnnualPay({
        job: jobDetails,
        currency: currentCtcCurrency,
        currencyObject: currentSalaryCurrency,
        currenciesData: currencyData,
      });
    }

    // Currenct exchange does not occur
    return getJobAnnualPay({ job: jobDetails });
  };

  const getJobLocation = (data) => {
    return get(data, 'jobLocationType') === 1
      ? 'Remote'
      : get(data, 'location');
  };

  const getJobDataAndOpenModal = () => {
    fetchJobData().then(() => {
      setShowModal((state) => !state);
    });
  };

  return (
    <JobTitle onClick={getJobDataAndOpenModal}>
      <LargeFont color={COLORS.DAVYS_GREY} cursor="pointer">
        <Tooltip title={ToolTipsText.jobDetails}>
          {get(job, 'job.designation')}
        </Tooltip>
      </LargeFont>
      {jobDetails && showModal ? (
        <JobModal
          showModal={showModal}
          chat={onInitiatingChat}
          evaluationDeadline={evaluationDeadline}
          job={jobDetails}
          ViewJobSalary={ViewJobSalary}
          jobType={jobType}
          getJobLocation={getJobLocation}
          theme="candidate"
          title="Job Results"
          eventId={eventId}
          eventName={job?.event?.name.toLowerCase()}
        />
      ) : null}
    </JobTitle>
  );
};

const JobTitle = styled.div`
  span {
    font-size: 14px;
    text-decoration: underline ${COLORS.RICH_BLUE} 2px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }
`;

export default withRouter(JobDetails);
