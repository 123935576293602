import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import config from '../../config';

export default function HelmetMetaData({
  url,
  quote,
  title,
  image,
  description,
  hashtag,
}) {
  let location = useLocation();
  const brief =
    'A unique web application designed to enable talent discovery at specialized events. Are you an event organizer, recruiter or a job seeker? Recorem is curated according to your needs. Sign up to Recorem Now.';
  const titleTemp = 'Recorem App | Discover Talent in specialized gatherings';
  const logo = `${config.appURL}/static/media/logo-h.png`;
  return (
    <Helmet>
      <title>{title ? `Recorem App |  ${title}` : titleTemp}</title>
      <meta charset="utf-8" data-react-helmet="true" />
      <meta
        http-equiv="X-UA-Compatible"
        content="IE=edge"
        data-react-helmet="true"
      />
      <meta name="csrf_token" content="" data-react-helmet="true" />
      <meta property="type" content="website" data-react-helmet="true" />
      <meta
        property="url"
        content={url ? url : config.appURL + location.pathname}
        data-react-helmet="true"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
        data-react-helmet="true"
      />
      <meta
        name="msapplication-TileColor"
        content="#ffffff"
        data-react-helmet="true"
      />
      <meta
        name="msapplication-TileImage"
        content="/ms-icon-144x144.png"
        data-react-helmet="true"
      />
      <meta name="theme-color" content="#000000" data-react-helmet="true" />
      <meta name="_token" content="" data-react-helmet="true" />
      <meta name="robots" content="noodp" data-react-helmet="true" />
      <meta
        property="title"
        content={title ? `Recorem App |  ${title}` : titleTemp}
        data-react-helmet="true"
      />
      <meta
        property="quote"
        content={quote ? quote : ''}
        data-react-helmet="true"
      />
      <meta
        name="description"
        content={description ? description : brief}
        data-react-helmet="true"
      />
      <meta
        property="image"
        content={image ? image : logo}
        data-react-helmet="true"
      />
      <meta property="og:locale" content="en_US" data-react-helmet="true" />
      <meta property="og:type" content="website" data-react-helmet="true" />
      <meta
        property="og:title"
        content={title ? `Recorem App |  ${title}` : titleTemp}
        data-react-helmet="true"
      />
      <meta
        property="og:quote"
        content={quote ? quote : ''}
        data-react-helmet="true"
      />
      <meta
        property="og:hashtag"
        content={hashtag ? hashtag : '#Recorem'}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content={image ? image : logo}
        data-react-helmet="true"
      />
      <meta
        content="image/*"
        property="og:image:type"
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        content={url ? url : config.appURL + location.pathname}
        data-react-helmet="true"
      />
      <meta
        property="og:site_name"
        content="Recorem"
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content={description ? description : brief}
        data-react-helmet="true"
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={url ? url : config.appURL + location.pathname}
        data-react-helmet="true"
      />
      <meta
        property="twitter:title"
        content={title ? `Recorem App |  ${title}` : titleTemp}
        data-react-helmet="true"
      />
      <meta
        property="twitter:description"
        content={description ? description : brief}
        data-react-helmet="true"
      />
      <meta
        property="twitter:image"
        content={image ? image : logo}
        data-react-helmet="true"
      />
    </Helmet>
  );
}
