import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { AdminRecruitIcon } from '../svgicons';
import { switchCompany, getCompanys } from '../../service/crew';
import { userProfileStore } from '../../utils/profile';
import { checkIfUserIsCandidate } from '../../utils/userInfo';
import Notification from '../Notification/Notification';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';
import { FONT } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const AdminRecruit = (props) => {
  const { roleId } = props;
  const [companys, setCompanys] = useState([]);
  const { profileData } = userProfileStore();
  const dispatch = useDispatch();
  const companyData = get(profileData, 'company', null);

  const handleCompanyClick = async (i) => {
    const company = companys[i];
    if (company && company.company) {
      const body = { companyId: company.companyId };
      try {
        const result = await switchCompany(body);
        const { accessToken, companyId, refreshToken } = result;

        if (localStorage.userInfo) {
          const storedData = JSON.parse(localStorage.userInfo);
          const updatedData = {
            ...storedData,
            accessToken,
            refreshToken,
            companyId,
          };
          localStorage.setItem('userInfo', JSON.stringify(updatedData));
        } else {
          const storedData = JSON.parse(sessionStorage.userInfo);
          const updatedData = {
            ...storedData,
            accessToken,
            refreshToken,
            companyId,
          };
          sessionStorage.setItem('userInfo', JSON.stringify(updatedData));
        }
        window.location.reload();
      } catch (err) {
        Notification('error', 'Error', 'Error in switching company.');
      }
    }
  };

  const isUserCandidate = checkIfUserIsCandidate(roleId);

  useEffect(() => {
    if (!isUserCandidate) {
      (async () => {
        const response = await getCompanys();
        const companysList = get(response, 'data.payload.companys', []);
        setCompanys(companysList);
      })();
    }
    // eslint-disable-next-line
  }, [isUserCandidate, dispatch]);

  const menu = (
    <StyledAdminRecruitMenu roleId={roleId}>
      {companys.map((company, index) => {
        return (
          <StyledMenuItem
            key={index.toString()}
            onClick={() => handleCompanyClick(index)}
            selected={
              get(company, 'company.id', null) === get(companyData, 'id', null)
            }
            roleId={roleId}
          >
            <div>{get(company, 'company.name', '')}</div>
          </StyledMenuItem>
        );
      })}
    </StyledAdminRecruitMenu>
  );

  return (
    <>
      {!isUserCandidate ? (
        <Dropdown overlay={menu} trigger={['click']}>
          <a
            className="ant-dropdown-link" // ant className
            onClick={(e) => e.preventDefault()}
            href={() => false}
          >
            <StyledSpan roleId={roleId}>
              <AdminRecruitIcon />
            </StyledSpan>
            {get(companyData, 'name', '')}
            <CaretDownOutlined />
          </a>
        </Dropdown>
      ) : (
        ''
      )}
    </>
  );
};

const StyledAdminRecruitMenu = styled(Menu)`
  margin-top: 16px;
  max-height: 70vh;
  overflow-y: auto;
`;

const StyledMenuItem = styled(Menu.Item)`
  margin: 0;
  ${PADDING(10, 15)};
  font-size: ${FONT[16]};

  div {
    margin: 0;
    ${PADDING(0, 0)};
    cursor: pointer;
    transition: 0.03s;
    color: ${(props) =>
      props.selected ? getUserColorWithRoleId(props.roleId) : COLORS.ARGENT};
  }

  &:hover {
    background-color: ${COLORS.WHITE};
  }

  &:hover div {
    color: ${(props) => getUserColorWithRoleId(props.roleId)};
    background-color: ${COLORS.WHITE};
  }
`;

const StyledSpan = styled.span`
  ${DISPLAY_FLEX('row', 'center', 'center')}

  svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    fill: ${(props) => getUserColorWithRoleId(props.roleId)};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    svg {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }
  }
`;

export default AdminRecruit;
