import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import { currentDate } from '../../utils/formatDateTime';
import Timer from '../Common/Timer';
import { DropdownBox } from '../shared-components/Form/Form';
import {
  getEventTimeText,
  getEventTime,
  getEventOptions,
} from '../../utils/event';
import { MediumText } from '../shared-components/Texts';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';

const Header = ({
  type,
  events,
  currentEventId,
  handleChange,
  tabEventInfo,
  adminTheme,
}) => {
  const getCurrentEventName = (event) => {
    return get(event, 'name', null);
  };

  function handleEventChange(value) {
    handleChange(value);
  }

  return (
    <div>
      <div>
        <Row>
          <Col lg={16} xs={24}>
            <Row>
              <StyledEventSelect>Select Event</StyledEventSelect>
              <Col lg={10} xs={24}>
                <StyledDropdownBox>
                  <DropdownBox
                    handleChange={handleEventChange}
                    value={getCurrentEventName(tabEventInfo[type].event)}
                    options={getEventOptions(events, true)}
                    placeholder="Select event"
                    showSearch
                  />
                </StyledDropdownBox>
              </Col>
            </Row>
          </Col>
          <Col xxl={8} xl={8} lg={8} xs={24}>
            <Row>
              {currentEventId ? (
                <StyledEventTime adminTheme={adminTheme}>
                  {getEventTimeText(tabEventInfo[type].event)}
                  <span role="button" tabIndex="0">
                    <Timer
                      dateTime={getEventTime(tabEventInfo[type].event)}
                      isFormated
                    />
                  </span>
                </StyledEventTime>
              ) : null}
            </Row>
          </Col>
        </Row>
      </div>
      <StyledAnalyticsCont>
        <Row>
          <Col>
            <MediumText
              margin="10px 0"
              fontWeight={FONT_WEIGHTS.SEMI_BOLD}
              opacity="0.7"
            >
              Event Analytics
            </MediumText>
          </Col>
          <MediumText
            color={COLORS.GRAY2}
            fontWeight={600}
            margin="10px 0 10px auto"
          >
            Today {currentDate(true, 'll')}
          </MediumText>
        </Row>
      </StyledAnalyticsCont>
    </div>
  );
};

const StyledEventSelect = styled(Col)`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: 14px;
  ${MARGIN(0, 40, 0, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledDropdownBox = styled.div`
  margin-left: -20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: 0;
    margin: 10px 0;
  }

  .ant-select-selector {
    border-color: ${COLORS.ARGENT} !important;

    span {
      font-size: 14px;

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: ${FONT[16]};
      }
    }
  }
`;

const StyledEventTime = styled.div`
  color: ${COLORS.GRAY};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  margin-left: auto;

  span {
    color: ${(props) =>
      !props.adminTheme ? COLORS.PUERTO_RICO : COLORS.DODGER_BLUE};
    font-weight: ${FONT_WEIGHTS.BOLD};
  }

  &,
  span {
    font-size: 14px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }
`;

const StyledAnalyticsCont = styled.div`
  ${MARGIN(12, 0, 0, 0)}
`;

export default Header;
