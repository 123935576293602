import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { useAccount } from 'wagmi';
import styled from 'styled-components';
import { InfoCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import WalletConditions from './WalletConditions';
import { IDENTITY_VERIFICATION } from '../../../enums/identity-verification';
import { COLORS } from '../../../styles/constants/colors';
import { MARGIN } from '../../../styles/constants/display';
import { FONT } from '../../../styles/constants/typography';

const CustomDropdown = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { isConnected: isWalletConnected } = useAccount();
  const profileIdentity = useSelector(
    (state) => state.profile.profileData.identityVerification,
  );

  /** When both the condition gets true this dropdown will be removed */
  useEffect(() => {
    if (
      isWalletConnected &&
      profileIdentity === IDENTITY_VERIFICATION.Verified
    ) {
      setIsVisible(false);
    }
  }, [isWalletConnected, profileIdentity]);

  /** If condition gets fullfilled */
  if (!isVisible) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <WalletConditions
          isWalletConnected={isWalletConnected}
          profileIdentity={profileIdentity}
        />
      }
      trigger={['click', 'hover']}
    >
      <StyledInfoCircleIcon style={{ color: COLORS.LIGHT_GRAY_2 }} />
    </Dropdown>
  );
};

export default CustomDropdown;

const StyledInfoCircleIcon = styled(InfoCircleFilled)`
  color: ${COLORS.LIGHT_GRAY_2};
  > svg {
    font-size: ${FONT[13]};
    ${MARGIN(0, 0, 0, 5)};
  }
`;
