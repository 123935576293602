import React from 'react';

const MediumIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="Group_3306" data-name="Group 3306" transform="translate(-259 -51)">
        <rect
          id="Rectangle_413"
          data-name="Rectangle 413"
          width="40"
          height="40"
          rx="18"
          transform="translate(259 51)"
          fill="#f0f0f0"
        />
        <path
          id="Icon_awesome-medium-m"
          data-name="Icon awesome-medium-m"
          d="M3.662,8.14a.878.878,0,0,0-.284-.734l-2.1-2.529V4.5H7.8l5.039,11.05L17.268,4.5h6.215v.377L21.689,6.6a.524.524,0,0,0-.2.5V19.743a.524.524,0,0,0,.2.5l1.753,1.72v.377H14.623v-.377L16.441,20.2c.177-.177.177-.232.177-.5V9.483L11.565,22.309h-.683L5.006,9.483v8.6a1.171,1.171,0,0,0,.325.985l2.361,2.864V22.3H1v-.372L3.36,19.064a1.133,1.133,0,0,0,.3-.985Z"
          transform="translate(267.165 57.58)"
        />
      </g>
    </svg>
  );
};

export default MediumIcon;
