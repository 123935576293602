import React from 'react';

const YoutubeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        id="Group_8668"
        data-name="Group 8668"
        transform="translate(-0.104 -0.417)"
      >
        <rect
          id="Rectangle_413"
          data-name="Rectangle 413"
          width="28"
          height="28"
          rx="12"
          transform="translate(0.104 0.417)"
          fill="#f0f0f0"
        />
        <path
          id="Icon_awesome-youtube"
          data-name="Icon awesome-youtube"
          d="M18.525,6.464a2.243,2.243,0,0,0-1.578-1.588A53,53,0,0,0,9.974,4.5,53,53,0,0,0,3,4.875,2.243,2.243,0,0,0,1.423,6.464a23.526,23.526,0,0,0-.373,4.324,23.526,23.526,0,0,0,.373,4.324A2.209,2.209,0,0,0,3,16.674a53,53,0,0,0,6.973.375,53,53,0,0,0,6.973-.375,2.209,2.209,0,0,0,1.578-1.563,23.526,23.526,0,0,0,.373-4.324,23.526,23.526,0,0,0-.373-4.324ZM8.149,13.441V8.134l4.665,2.654L8.149,13.441Z"
          transform="translate(4.13 3.642)"
        />
      </g>
    </svg>
  );
};

export default YoutubeIcon;
