import React, { useEffect } from 'react';
import toLowerCase from 'lodash/toLower';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import isArray from 'lodash/isArray';
import { useDispatch } from 'react-redux';
import { getOngoingEventAnalyticsData } from '../../../store/features/analytics';
import { isEventLive, isEventOngoing, EventsStore } from '../../../utils/event';
import EventAnalytics from './Analytics';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../../utils/userInfo';
import LiveEventButton from '../../Broadcast/LiveEventButton';
import UpcomingTag from '../UpcomingEvents/UpcomingTag';
import { RecruiterRoutes, CandidateRoutes } from '../../../constants/routes';
import { getFileS3 } from '../../../utils/s3.storage';
import OngoingTag from './OngoingTag';
import { EventViewDetails } from '../Common';
import { DateAndTypeSection } from '../../shared-components/Event/DateAndTypeSection';
import { StyledMainHeader } from '../../shared-components/Event/Common';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { StyledCarousel } from '../../shared-components/StyledCarousel';
import { textWrap } from '../../shared-components/EventCard';
import { getTextFromHTML } from '../../../utils/common';
import SwiftEvent from '../../../containers/Organizer/Events/SwiftEvent/SwiftEvent';
import { eventSlice } from '../../../store/features/events';

export default function OngoingEvents({
  dashboard,
  colPropsOngoingSection,
  colPropsEventInfoSection,
  colPropsEventPicSection,
  roleId,
  search,
}) {
  const dispatch = useDispatch();
  const { onGoingEvents, upComingEvents } = EventsStore();
  const isUserOrganizer = checkIfUserIsOrganizer(roleId);

  const handleModalOpen = () => {
    dispatch(eventSlice.actions.onOpenSwiftEventModal());
  };

  const handleModalClose = () => {
    dispatch(eventSlice.actions.onCloseSwiftEventModal());
  };

  const [
    filteredOngoingAndUpcomingEvents,
    setFilteredOngoingAndUpcomingEvents,
  ] = React.useState([]);

  useEffect(() => {
    const eventIds = onGoingEvents.concat(upComingEvents).map((event) => {
      return event.id;
    });
    dispatch(getOngoingEventAnalyticsData(roleId, eventIds));
  }, [dispatch, roleId, onGoingEvents, upComingEvents]);

  useEffect(() => {
    const onGoingEventsLimit =
      onGoingEvents.length > 10 ? onGoingEvents.slice(0, 10) : onGoingEvents;
    const ongoingAndUpcomingEvents = onGoingEventsLimit.concat(
      upComingEvents.length > 3
        ? upComingEvents.slice(0, 3).map((item) => {
            return { ...item, isUpcoming: true };
          })
        : upComingEvents,
    );

    const filterByEventName = isArray(ongoingAndUpcomingEvents)
      ? ongoingAndUpcomingEvents.filter((event) =>
          toLowerCase(event.name).includes(toLowerCase(search)),
        )
      : [];

    setFilteredOngoingAndUpcomingEvents(filterByEventName);
  }, [onGoingEvents, search, upComingEvents]);

  if (
    (!isArray(onGoingEvents) && !isArray(upComingEvents)) ||
    (onGoingEvents.length === 0 && upComingEvents.length === 0) ||
    !roleId
  ) {
    let PlaceHolder;
    if (checkIfUserIsCandidate(roleId)) {
      PlaceHolder = (
        <StyledSpanText>
          This space displays your ongoing and upcoming booked events.{' '}
          <StyledSpanText>
            <StyledCreateEventPathLink to={CandidateRoutes.exploreEvent}>
              "Explore Events"
            </StyledCreateEventPathLink>
          </StyledSpanText>{' '}
          to start booking.
        </StyledSpanText>
      );
    } else if (checkIfUserIsRecruiter(roleId)) {
      PlaceHolder = (
        <StyledSpanText>
          This space displays your ongoing and upcoming booked events.{' '}
          <StyledSpanText>
            <StyledCreateEventPathLink to={RecruiterRoutes.exploreEvent}>
              "Explore Events"
            </StyledCreateEventPathLink>
          </StyledSpanText>{' '}
          to start booking.
        </StyledSpanText>
      );
    } else {
      PlaceHolder = (
        <StyledSpanText>
          This space displays your ongoing and upcoming events. Let’s head to
          the{' '}
          <StyledSpanText>
            <StyledCreateEvent onClick={handleModalOpen}>
              "Create Event"
            </StyledCreateEvent>
          </StyledSpanText>{' '}
          page.
        </StyledSpanText>
      );
    }

    return (
      <Col {...colPropsOngoingSection}>
        <StyledMainHeader
          id="ongoing_event_title"
          color={getUserColorWithRoleId(roleId)}
        >
          Ongoing & Upcoming Events
        </StyledMainHeader>
        <StyledPlaceholderText>{PlaceHolder}</StyledPlaceholderText>
        {isUserOrganizer && <SwiftEvent onCancel={handleModalClose} />}
      </Col>
    );
  }

  return (
    <>
      {!checkIfUserIsCandidate(roleId) && (
        <Col {...colPropsOngoingSection}>
          <StyledHeadingWrapper>
            <Col xs={24}>
              <StyledWrapper>
                <StyledMainHeader
                  id="ongoing_event_title"
                  color={getUserColorWithRoleId(roleId)}
                >
                  Ongoing & Upcoming Events
                </StyledMainHeader>
              </StyledWrapper>
            </Col>
          </StyledHeadingWrapper>

          <StyledCarousel autoplay dotPosition="bottom">
            {filteredOngoingAndUpcomingEvents.map((event, idx) => {
              return (
                <div key={idx.toString()}>
                  <StyledMainContainer>
                    <StyledContentWrapper {...colPropsEventInfoSection}>
                      <StyledInnerWrapper>
                        <h4>{event.name}</h4>
                        <StyledDescription>
                          {getTextFromHTML(event.description)}
                        </StyledDescription>
                        <DateAndTypeSection
                          isLeft
                          event={event}
                          marginBottom={14}
                        />

                        <EventAnalytics
                          dashboard={dashboard}
                          roleId={roleId}
                          index={idx}
                        />
                      </StyledInnerWrapper>
                      <StyledShowMobileViewDetailsWrapper>
                        <Col xs={24}>
                          <EventViewDetails id={event.id} />
                        </Col>
                      </StyledShowMobileViewDetailsWrapper>
                    </StyledContentWrapper>

                    <StyledImageWrapper {...colPropsEventPicSection}>
                      <div>
                        <img src={getFileS3(event.cover)} alt="noimage" />
                      </div>
                      {isEventLive(event) && isEventOngoing(event) ? (
                        <LiveEventButton event={event} />
                      ) : isEventOngoing(event) ? (
                        <OngoingTag />
                      ) : (
                        <UpcomingTag />
                      )}
                    </StyledImageWrapper>
                  </StyledMainContainer>

                  <StyledViewWrapper>
                    <Col xs={24}>
                      <EventViewDetails id={event.id} />
                    </Col>
                  </StyledViewWrapper>
                </div>
              );
            })}
          </StyledCarousel>
        </Col>
      )}
    </>
  );
}

const StyledWrapper = styled.div`
  margin-bottom: 5px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const StyledHeadingWrapper = styled(Row)`
  ${MARGIN(0, 0, 10, 0)}
`;

const StyledMainContainer = styled(Row)`
  box-shadow: 0 0 10px #0000001a;
  border-radius: 10px;
  overflow: hidden;
  height: auto;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    ${DISPLAY_FLEX('column-reverse')};
    height: auto;
    box-shadow: none;
    margin-bottom: 0;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 300px;
  }
`;

const StyledContentWrapper = styled(Col)`
  width: 100%;
  z-index: 1;
  position: relative;
  background: ${COLORS.WHITE};
`;

const StyledInnerWrapper = styled.div`
  ${PADDING(20, 20, 0, 25)};
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    padding-left: 10px;
    padding-right: 10px;
  }

  h4 {
    font-size: ${FONT[15]};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    color: ${COLORS.EERIE_BLACK};
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[20]};
      margin-bottom: 10px;
    }
  }
`;

const StyledShowMobileViewDetailsWrapper = styled(Row)`
  display: none;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 10, 0, 10)};
    display: block !important;
    margin-top: -20px;
    text-align: right;
    height: max-content;
  }

  div {
    color: ${COLORS.ARGENT};
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    display: block;
    width: 139px;
    float: right;
    margin-bottom: 7px;
    margin-top: 10px;
  }

  a {
    height: max-content;

    svg {
      width: 15px;
      fill: ${COLORS.ARGENT};
      margin-left: 15px;
      position: relative;
      top: 4px;
      @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
        width: 12px;
        height: 10px;
        margin-left: 5px;
        position: relative;
        top: 0;
      }

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        margin-left: 5px;
      }
    }
  }
`;

const StyledImageWrapper = styled(Col)`
  position: relative;
  height: auto;

  img {
    width: 100%;
    height: 237px;
    border-radius: 0 0 10px 0;
    margin-bottom: -7px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      height: 308px;
    }
  }
`;

const StyledViewWrapper = styled(Row)`
  text-align: right;
  height: max-content;
  padding-top: 10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none !important;
  }

  div {
    color: ${COLORS.ARGENT};
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    display: block;
    width: 139px;
    float: right;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }

  a {
    height: max-content;

    svg {
      width: 15px;
      fill: ${COLORS.ARGENT};
      margin-left: 15px;
      position: relative;
      top: 4px;
      @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
        width: 12px;
        height: 10px;
        margin-left: 5px;
        position: relative;
        top: 0;
      }
    }
  }
`;

const StyledPlaceholderText = styled.p`
  color: ${COLORS.GRAY};
  font-size: ${FONT[14]};
  ${MARGIN(12, 0, 0, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledCreateEvent = styled.span`
  color: ${COLORS.DODGER_BLUE} !important;
  cursor: pointer !important;
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledCreateEventPathLink = styled(Link)`
  color: ${COLORS.DODGER_BLUE} !important;
  cursor: pointer !important;
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledSpanText = styled.span`
  color: ${COLORS.GRAY};
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledDescription = styled.span`
  font-size: ${FONT[14]};
  margin: 0 0 10px;
  line-height: 1.4;
  ${textWrap(1)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
    margin: 5px 0 20px;
    line-height: 1.6;
    ${textWrap(2)}
  }

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    font-size: ${FONT[12]};
  }
`;
