import React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import {
  BuildingIcon,
  // GroupUser,
  BreifCaseOutlinedIcon,
} from '../../svgicons';
import { EventAnalyticsStore, getIconBgColor } from '../../../utils/analytics';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getRoleId } from '../../../utils/userInfo';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { mediumHeadingMixin } from '../../../styles/constants/mixins';
import { COLORS } from '../../../styles/constants/colors';

const PastEventAnalytics = () => {
  let { analytics } = EventAnalyticsStore();
  const initialValue = '-';

  const roleId = getRoleId();

  if (analytics.length === 0) {
    analytics = [];
    analytics.push({
      noOfCompanies: initialValue,
      noOfCandidates: initialValue,
      noOfJobs: initialValue,
      noOfJobApplications: initialValue,
    });
  }

  const {
    noOfCompanies,
    // noOfCandidates,
    noOfJobs,
  } = analytics[0];

  const analyticsData = [
    // {
    //   Icon: GroupUser,
    //   key: 'Candidate',
    //   value: noOfCandidates,
    // },
    {
      Icon: BuildingIcon,
      key: 'Companies',
      value: noOfCompanies,
    },
    {
      Icon: BreifCaseOutlinedIcon,
      key: 'Jobs',
      value: noOfJobs,
    },
  ];

  return analyticsData.map((item, i) => {
    const { Icon, key, value } = item;
    return (
      <StyledCol md={12} xs={24} key={i.toString()}>
        <StyledIconContainer bgColor={getIconBgColor(roleId)}>
          <Icon />
        </StyledIconContainer>
        <StyledIconTitleContainer>
          <StyledTitleText>{key}</StyledTitleText>
          <StyledText>{value}</StyledText>
        </StyledIconTitleContainer>
      </StyledCol>
    );
  });
};

export default PastEventAnalytics;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 10px 0;
  }
`;

const StyledIconContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 45px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  float: left;
  margin-right: 15px;
  justify-content: center;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 63px;
    height: 63px;
  }

  svg {
    font-size: 20px;
    width: 25px;
    fill: #fff;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 28px;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 35px;
    }
  }
`;

const StyledIconTitleContainer = styled.div`
  color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 63px;
  }
`;

const StyledTitleText = styled.span`
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${mediumHeadingMixin};
`;

const StyledText = styled.span`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${mediumHeadingMixin};
`;
