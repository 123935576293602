import api from './api';
import get from 'lodash/get';
import { APIs, Method } from '../constants';
import { isValid } from '../utils/api';
import ERRORS from '../constants/errors';

export const getCryptoIncentivePlans = async (token) => {
  return api({
    url: APIs.getCryptoIncentivePlans,
    method: Method.get,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.cryptoIncentivePlans', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const cryptoIncentivePlanForCompany = async (token) => {
  return api({
    url: APIs.cryptoIncentivePlanForCompany,
    method: Method.get,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.companyCryptoIncentivePlan', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateCryptoPaymentStatus = async (body, token) => {
  return api({
    url: APIs.cryptoIncentivePlanForCompany,
    method: Method.put,
    body,
    cancelToken: token,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
