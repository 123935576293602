import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Avatar } from 'antd';
import get from 'lodash/get';
import TextArea from 'antd/lib/input/TextArea';
import { COLORS } from '../../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { SHADOWS } from '../../../../styles/constants/shadows';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import {
  ACCEPTED_IMAGE_EXTENSIONS,
  LOGO_IMAGE_HINT,
} from '../../../../constants/file';
import { CROP_POFILE_PIC_OPTIONS, DELETE } from '../../../../constants';
import { getFileS3 } from '../../../../utils/s3.storage';
import { TextUpload } from '../../../shared-components/Upload';
import { InputBox } from '../../../shared-components/Form/Form';
import { TrashIcon } from '../../../svgicons';
import { getRoleId } from '../../../../utils/userInfo';

const SpeakerAndHostInfo = ({
  type,
  handleEditCompany,
  entities,
  uploadEventFile,
  handleFileChange,
  handleDeleteEntity,
}) => {
  const entityType = type === 'Speakers' ? 'speakers' : 'hosts';
  const roleId = getRoleId();
  return (
    <StyledWrapper>
      {entities &&
        entities.map((entity, index) => {
          if (entity.operation === DELETE) {
            return <></>;
          }
          return (
            <StyledCompanyWrapper key={entity.id}>
              <StyledContainer>
                <StyedRow>
                  <Col xs={24} lg={4}>
                    <StyledUploadLogoWrapper>
                      <StyledInnerLogoWrapper>
                        <Avatar
                          src={getFileS3(get(entity, 'pic'))}
                          alt="recruiter-logo"
                        />
                        <TextUpload
                          style={{ backgroundColor: 'transparent' }}
                          name="changeSponsorLogo"
                          text={getFileS3(get(entity, 'pic')) ? "Upload Logo" : "Add Logo"}
                          customRequestHandler={(data) =>
                            uploadEventFile(
                              data,
                              type === 'Speakers' ? 'speaker' : 'host',
                              index,
                            )
                          }
                          onChange={(data) =>
                            handleFileChange(
                              data,
                              type === 'Speakers' ? 'speaker' : 'host',
                              index,
                            )
                          }
                          accept={ACCEPTED_IMAGE_EXTENSIONS}
                          hintText={LOGO_IMAGE_HINT}
                          maxCount={1}
                          roleId={roleId}
                          cropActive
                          cropOptions={CROP_POFILE_PIC_OPTIONS}
                          placement="bottomLeft"
                          id="add_company_logo"
                        />
                      </StyledInnerLogoWrapper>
                    </StyledUploadLogoWrapper>
                  </Col>
                  <Col xs={24} lg={6}>
                    <label htmlFor="company_name">
                      <StyledInfoHeader id="company_name_label">
                        Name
                      </StyledInfoHeader>
                    </label>
                    <InputBox
                      name="companyName"
                      placeholder="Enter Name"
                      value={entity.name}
                      rule="text"
                      id="company_name"
                      handleChange={(e) =>
                        handleEditCompany(
                          entityType,
                          index,
                          'name',
                          e.target.value,
                        )
                      }
                    />
                  </Col>
                  <Col xs={24} lg={6}>
                    <label htmlFor="company_name">
                      <StyledInfoHeader id="company_name_label">
                        Designation
                      </StyledInfoHeader>
                    </label>
                    <InputBox
                      name="designation"
                      placeholder="Enter Designation"
                      value={entity.designation}
                      handleChange={(e) =>
                        handleEditCompany(
                          entityType,
                          index,
                          'designation',
                          e.target.value,
                        )
                      }
                    />
                  </Col>
                  <Col xs={24} lg={6}>
                    <label htmlFor="company_name">
                      <StyledInfoHeader id="company_name_label">
                        Link
                      </StyledInfoHeader>
                    </label>
                    <InputBox
                      name="link"
                      placeholder="https://www.facebook.com/event-page"
                      rule="url"
                      value={get(entity, 'links[0]', '')}
                      handleChange={(e) =>
                        handleEditCompany(entityType, index, 'links', [
                          e.target.value,
                        ])
                      }
                    />
                  </Col>
                  <StyledButtonWrapper>
                    <StyledEditButton
                      tabIndex={0}
                      onClick={() =>
                        handleDeleteEntity(
                          index,
                          type === 'Hosts' ? 'hosts' : 'speakers',
                        )
                      }
                    >
                      <TrashIcon />
                    </StyledEditButton>
                  </StyledButtonWrapper>
                </StyedRow>
                {type === 'Speakers' ? (
                  <>
                    <StyedRow>
                      <Col xs={24} lg={4} />
                      <Col xs={24} lg={19}>
                        <label htmlFor="company_brief">
                          <StyledInfoHeader id="company_brief_label">
                            Brief
                          </StyledInfoHeader>
                        </label>
                        <TextArea
                          name="brief"
                          required={false}
                          placeholder="Brief"
                          value={entity.brief}
                          onChange={(e) =>
                            handleEditCompany(
                              entityType,
                              index,
                              'brief',
                              e.target.value,
                            )
                          }
                          autoSize={{ minRows: 3, maxRows: 6 }}
                          maxLength={250}
                          showCount={true}
                        />
                      </Col>
                    </StyedRow>
                  </>
                ) : null}

                {/* upload official */}
              </StyledContainer>
            </StyledCompanyWrapper>
          );
        })}
    </StyledWrapper>
  );
};

export default SpeakerAndHostInfo;

const StyledWrapper = styled.div`
  margin-top: 30px;
`;

const StyledCompanyWrapper = styled.div`
  background-color: ${COLORS.LOTION};
  margin: 2rem 0;
  padding: 20px;
  position: relative;
`;

const StyledInfoHeader = styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    margin-bottom: 8px;
  }
`;

const StyledInnerLogoWrapper = styled.div`
  text-align: center;
  max-width: 140px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 0 auto;
  }

  span.ant-avatar {
    width: 110px;
    height: 110px;
    display: block;
    box-shadow: ${SHADOWS.SHADOW2};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 20px;
`;

const StyledEditButton = styled(Button)`
  ${DISPLAY_FLEX(null, 'center', 'center')};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: ${SHADOWS.SHADOW2};

  svg {
    width: 25px;
    font-size: 25px;
    fill: ${COLORS.ORANGE_RED};
  }
`;

const StyledContainer = styled.div`
  clear: both;
  overflow: hidden;

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-explain {
    display: none;
  }
`;

const StyedRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 20px;
  row-gap: 10px !important;
`;

const StyledUploadLogoWrapper = styled.div`
  margin-top: 20px;

  button {
    height: 40px;
  }
`;
