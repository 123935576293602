import React, { useContext } from 'react';
import styled from 'styled-components';
import { getRoleId } from '../../../utils/userInfo';
import { PersonalChatContext } from '../../../context/personalChat';
import { MediumHeading } from '../Texts';
import {
  StyledChatHeaderDivProps,
  StyledChatsHeadingProps
} from '../../../interfaces/components/PersonalChatHeader';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { DISPLAY_FLEX, PADDING } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

export const PersonalChatHeader: React.FC = () => {
  const { chatOpened } = useContext(PersonalChatContext);

  const roleId = getRoleId();

  return (
    <StyledChatHeaderDiv chatOpened={chatOpened}>
      <MediumHeading>
        <StyledChatsHeading roleId={roleId}>Chats</StyledChatsHeading>
      </MediumHeading>
    </StyledChatHeaderDiv>
  );
};

const StyledChatHeaderDiv = styled.div<StyledChatHeaderDivProps>`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
  ${PADDING(20, 20, 20, 0)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => props.chatOpened ? 'none' : ''};
  }
`;

const StyledChatsHeading = styled.h3<StyledChatsHeadingProps>`
  color: ${(props) => getUserColorWithRoleId(props.roleId)};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: ${FONT[16]};
  margin-bottom: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-left: 10px;
    font-size: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: inherit;
  }
`;
