import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  BreadcrumbItem,
  BreadcrumbProps,
} from '../../interfaces/components/Breadcrumbs';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { getRoleId } from '../../utils/userInfo';

export const Breadcrumb: FC<BreadcrumbProps> = ({ links }) => {
  const roleId = getRoleId();

  return (
    <StyledUl>
      {links.map((item: BreadcrumbItem) => {
        return (
          <StyledList color={getUserColorWithRoleId(roleId)}>
            <Link to={item.url}>
              <a href="#/">{item.name}</a>
            </Link>
          </StyledList>
        );
      })}
    </StyledUl>
  );
};

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  flex-wrap: wrap;
`;

const StyledList = styled.li<{ color: String }>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    padding-left: 18px;

    &::before {
      position: absolute;
      content: '>';
      top: 0;
      left: 0;
      display: block;
    }
  }

  &:not(:last-child) {
    padding-right: 8px;
  }

  a,
  span,
  &::before {
    color: ${(props) => props.color};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    cursor: pointer;
  }
`;
