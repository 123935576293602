/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MediumHeading } from './Texts';
import { IconBadge } from './IconBadge';
import { FONT_WEIGHTS, PRIMARY_FONT } from '../../styles/constants/typography';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../utils/userInfo';
import {
  AdminRoutes,
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../constants/routes';
import { NotificationsCardProps } from '../../interfaces/components/Notifications';
import { getCircleColor } from '../../utils/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';

export const NotificationsCard: React.FC<NotificationsCardProps> = ({
  theme,
  icons,
}) => {
  const roleId = getRoleId();

  const getRoutes = () => {
    switch (true) {
      case checkIfUserIsOrganizer(roleId):
        return OrganizerRoutes.notifications;
      case checkIfUserIsRecruiter(roleId):
        return RecruiterRoutes.notifications;
      case checkIfUserIsCandidate(roleId):
        return CandidateRoutes.notifications;
      default:
        return AdminRoutes.notifications;
    }
  };

  const displayNotification = (item: any) => {
    return (
      <StyledLink to={`${getRoutes()}/?type=${item.link}`}>
        <IconBadge
          icon={item.icon}
          color={item.color}
          text={item.text}
          numberBadge
          numberBadgeText={item.updates}
          hoverEffect
          textColor={item.color}
        />
      </StyledLink>
    );
  };

  return (
    <Container role={roleId}>
      <Circle1 theme={theme} />
      <Circle2 theme={theme} />
      <StyledHeader>
        <MediumHeading fontWeight={FONT_WEIGHTS.SEMI_BOLD} margin="10px 0 0 0">
          Notifications
        </MediumHeading>
      </StyledHeader>
      <Wrapper>
        {icons &&
          (checkIfUserIsCandidate(roleId)
            ? icons.candidate.map((item: any) => displayNotification(item))
            : checkIfUserIsOrganizer(roleId)
            ? icons.organizer.map((item: any) => displayNotification(item))
            : icons.recruiter.map((item: any) => displayNotification(item)))}
      </Wrapper>
    </Container>
  );
};

interface ContainerProps {
  role: string;
}

const StyledLink = styled(Link)`
  &:link {
    cursor: pointer;
  }
`;

const StyledHeader = styled.div`
  position: relative;
  z-index: 1;
`;

const Container = styled.div<ContainerProps>`
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;
  font-family: ${PRIMARY_FONT};
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) {
    margin-top: ${(props) =>
      checkIfUserIsOrganizer(props.role) ? '-20px' : '10px'};
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-bottom: ${(props) =>
      checkIfUserIsCandidate(props.role) ? '0' : "20px"};
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  width: 180px;
  height: 180px;
  position: absolute;
  z-index: 0;
  background-color: ${({ theme }) => getCircleColor(theme)};
`;

const Circle1 = styled(Circle)`
  right: -10px;
  top: -40px;
  opacity: 0.5;
`;

const Circle2 = styled(Circle)`
  top: 70px;
  right: -35px;
  opacity: 0.3;
  height: 140px;
  width: 140px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
  ${DISPLAY_FLEX('column')}
  gap: 10px;
`;
