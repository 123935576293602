import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { Form, Row } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import PurchaseOverviewModal from './PurchaseOverviewModal';
import {
  EventsStore,
  getTicketPrice,
  getDiscountedPrice,
} from '../../../utils/event';
import '../../../styles/components/events/book-event-modal.scss';
import { FormGroup, InputBox } from '../../shared-components/Form/Form';
import Notification from '../../Notification/Notification';
import AddressAutoComplete from '../../Common/AddressAutoComplete';
import { checkIfUserIsCandidate, getRoleId } from '../../../utils/userInfo';
import {
  getBillingAddresses,
  addBillingAddress,
  updateBillingAddress,
  deleteBillingAddress,
} from '../../../service/billingAddress';
import { Modal } from '../../shared-components/Modal';
import { getThemeByRoleId } from '../../../utils/common';
import { PrimaryButton, SecondaryButton } from '../../shared-components/Button';
import { RightAlignContainer } from '../../shared-components/RightAlignContainer';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { DISPLAY_FLEX, PADDING } from '../../../styles/constants/display';
import { applyPromoCode } from '../../../service/event';
import { COLORS } from '../../../styles/constants/colors';
import { userProfileStore } from '../../../utils/profile';
import { useSelector } from 'react-redux';
import { getConvertedPriceString } from './common';
import { isMobile } from '../../../helpers/display';

const BookEventModal = ({
  bookEvent,
  handleBookEventModalOpen,
  handleBookEventModalClose,
  inviteCode,
  setOpenBookEventModal,
}) => {
  const { event } = EventsStore();
  const [form] = Form.useForm();
  const { profileData } = userProfileStore();
  const [promo, setPromo] = useState(false);
  const [promoValue, setPromoValue] = useState();
  const [purchaseOverview, setPurchaseOverview] = useState(false);
  const [bookingInfo, setBookingInfo] = useState();
  const [bookingAddressList, setBookingAddressList] = useState([]);
  const [isNewBillingAddressOpen, setIsNewBillingAddressOpen] = useState(false);
  const [isEditBillingAddressOpen, setIsEditBillingAddressOpen] = useState(
    false,
  );
  const [selectedAddress, setSelectedAddress] = useState();
  const { currencyData } = useSelector((st) => st.commons);
  const roleId = getRoleId();

  const fetchBillingAddresses = async () => {
    const address = await getBillingAddresses();
    if (address && address.length) {
      setBookingAddressList(address);
      const tempSelected = address.find(
        (add) => add.id === get(selectedAddress, 'id', null),
      );

      if (!tempSelected) {
        setSelectedAddress(address[0]);
      } else {
        setSelectedAddress(tempSelected);
      }
    }
  };

  useEffect(() => {
    fetchBillingAddresses();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (promo) {
      // Update ticket price if promo applied
      const ticketCost = getDiscountedPrice(
        bookingInfo.ticketCost,
        promo.type,
        promo.value,
      );
      setBookingInfo((values) => ({ ...values, ticketCost, promo }));
    } else {
      const ticketCost = getTicketPrice(event, roleId);
      setBookingInfo((values) => ({ ...values, ticketCost }));
    }
    // eslint-disable-next-line
  }, [promo]);

  useEffect(() => {
    const currency = get(event, 'currency', '$');
    setBookingInfo((values) => ({ ...values, currency }));
  }, [event]);

  const purchaseOverviewModalOpen = () => {
    setPurchaseOverview(true);
  };

  const purchaseOverviewModalClose = () => {
    setPurchaseOverview(false);
    handleBookEventModalClose();
  };

  const handleBookEventSubmit = async () => {
    // const formFieldsData = await form.getFieldsValue();
    if (!selectedAddress) {
      return Notification(
        'error',
        'Booking event',
        'Please select billing address',
      );
    }
    setBookingInfo((value) => {
      return {
        ...value,
        ticket: `${checkIfUserIsCandidate(roleId) ? `Attendee` : `Recruiter`
          }: Payment for booking of "${event.name}" Event`,
        billingAddress: selectedAddress,
      };
    });
    purchaseOverviewModalOpen();
  };

  const handleApply = async () => {
    const res = await applyPromoCode(event.id, promoValue);
    if (!get(res, 'isValid', false)) {
      setPromo(0);
      return Notification('error', 'Apply promo', 'Invalid Promo code.');
    }
    setPromo(get(res, 'promo', {}));
    Notification('success', 'Apply promo', 'Promo code applied.');
  };

  const handleSelectAddress = (address) => {
    // Should only allow select address when no mode opened
    if (address && !isNewBillingAddressOpen && !isEditBillingAddressOpen) {
      setSelectedAddress(address);
    }
  };

  const handleCancelAddress = () => {
    form.resetFields();
    setIsNewBillingAddressOpen(false);
    setIsEditBillingAddressOpen(false);
  };

  const handleNewAddressSubmit = async () => {
    // const formFieldsData = await form.getFieldsValue();
    const validation = form.validateFields();

    validation.then(async (response) => {
      const {
        name,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        state,
        zipcode,
      } = response;
      const res = await addBillingAddress({
        name,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        state,
        zip: zipcode,
      });

      if (res) {
        fetchBillingAddresses();
        form.resetFields();
        setIsNewBillingAddressOpen(false);
      }
    });
  };

  const ticketCost = get(bookingInfo, 'ticketCost', 0);

  const ticketCurrency = get(bookingInfo, 'currency', '$');

  const handleRemoveAddress = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    deleteBillingAddress(id).then(() => {
      if (id === get(selectedAddress, 'id', null)) {
        setSelectedAddress(null);
      }

      if (bookingAddressList && bookingAddressList.length === 1) {
        setSelectedAddress(null);
      }
      Notification(
        'success',
        'Billing Address',
        'Address deleted successfully',
      );
      setBookingAddressList([]);
      fetchBillingAddresses();
    });
  };

  const handleOpenEditBillingAddress = (address, event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsNewBillingAddressOpen(false);
    setIsEditBillingAddressOpen(true);
    form.setFieldsValue(address);
  };

  const handleEditAddressSubmit = async () => {
    // const formFieldsData = await form.getFieldsValue();
    const validation = form.validateFields();

    validation.then(async (response) => {
      const {
        name,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        state,
        zipcode,
      } = response;
      const res = await updateBillingAddress({
        id: selectedAddress.id,
        name,
        addressLineOne,
        addressLineTwo,
        city,
        country,
        state,
        zip: zipcode,
      });

      if (res) {
        fetchBillingAddresses();
        form.resetFields();
        setIsEditBillingAddressOpen(false);
        Notification(
          'success',
          'Billing Address',
          'Address edited successfully',
        );
      }
    });
  };

  const handleOpenBillingAddressOptions = () => {
    if (isNewBillingAddressOpen && isEditBillingAddressOpen) return null;

    if (!isNewBillingAddressOpen && !isEditBillingAddressOpen) {
      return (
        <PrimaryButton
          theme={getThemeByRoleId(roleId)}
          onClick={() => setIsNewBillingAddressOpen(true)}
        >
          + Add New Billing Address
        </PrimaryButton>
      );
    }

    const isAddMode = isNewBillingAddressOpen && !isEditBillingAddressOpen;

    return (
      <>
        <StyledH3>{isAddMode ? 'New' : 'Edit'} Billing Address</StyledH3>
        <StyledAddressContainer>
          <AddressAutoComplete
            form={form}
            isForBilling
            isForBillingEditMode={!isAddMode}
            showExtraFields
            showExtraFieldsWithState
          />
          <RightAlignContainer>
            <StyledGappedRow>
              <SecondaryButton
                theme={getThemeByRoleId(roleId)}
                onClick={handleCancelAddress}
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                theme={getThemeByRoleId(roleId)}
                onClick={
                  isAddMode ? handleNewAddressSubmit : handleEditAddressSubmit
                }
              >
                Submit
              </PrimaryButton>
            </StyledGappedRow>
          </RightAlignContainer>
        </StyledAddressContainer>
      </>
    );
  };

  return (
    <>
      <Modal
        visible={bookEvent}
        onCancel={handleBookEventModalClose}
        footer={null}
        width="60%"
        title="Register Event"
        theme={getThemeByRoleId(roleId)}
        zIndex={isMobile() && 3000}
      >
        <div>
          <FormGroup name="bookEvent" form={form}>
            <div>
              <StyledH3>Billing Address</StyledH3>
              <div>
                <ul>
                  {bookingAddressList.length
                    ? bookingAddressList.map((address) => {
                      return (
                        <StyledLi
                          onClick={() => handleSelectAddress(address)}
                        >
                          <div>
                            <h3>{address.name}</h3>
                            <div>
                              <span>
                                {address.addressLineOne},{' '}
                                {address.addressLineTwo},
                              </span>
                            </div>
                            <div>
                              <span>
                                {address.city}, {address.state},{' '}
                                {address.country}, {address.zip}
                              </span>
                            </div>
                          </div>
                          <StyledContainer>
                            <StyledSelectedIconContainer
                              color={getUserColorWithRoleId(roleId)}
                              selected={
                                selectedAddress &&
                                selectedAddress.id === address.id
                              }
                            >
                              <CheckCircleOutlined />
                            </StyledSelectedIconContainer>
                            {!isNewBillingAddressOpen &&
                              !isEditBillingAddressOpen ? (
                              <StyledEditIconContainer
                                color={getUserColorWithRoleId(roleId)}
                              >
                                <EditOutlined
                                  onClick={(e) =>
                                    handleOpenEditBillingAddress(address, e)
                                  }
                                />
                              </StyledEditIconContainer>
                            ) : null}
                            <StyledDeleteIconContainer>
                              <DeleteOutlined
                                onClick={(e) =>
                                  handleRemoveAddress(e, address.id)
                                }
                              />
                            </StyledDeleteIconContainer>
                          </StyledContainer>
                        </StyledLi>
                      );
                    })
                    : null}
                </ul>

                <div>{handleOpenBillingAddressOptions()}</div>
              </div>
            </div>
            {/* Note: show promo code if ticket costs more than zero */}
            {!(isEditBillingAddressOpen || isNewBillingAddressOpen) && ticketCost ? (
              <>
                <StyledHeading>Promo Code</StyledHeading>
                <div>
                  <InputBox
                    name="promoCode"
                    disabled={promo}
                    placeholder="code"
                    handleChange={(e) => setPromoValue(e.target.value)}
                    required={false}
                  />
                  <StyledGappedRow>
                    <PrimaryButton
                      margin="15px 0 0 0"
                      theme={getThemeByRoleId(roleId)}
                      disabled={promo}
                      onClick={handleApply}
                    >
                      {promo ? 'Applied' : 'Apply'}
                    </PrimaryButton>
                    {promo ? (
                      <PrimaryButton
                        margin="15px 0 0 0"
                        theme={getThemeByRoleId(roleId)}
                        onClick={() => setPromo(false)}
                      >
                        Remove
                      </PrimaryButton>
                    ) : null}
                  </StyledGappedRow>
                </div>
              </>
            ) : null}

            {!(isEditBillingAddressOpen || isNewBillingAddressOpen) ? (
              <>
                <StyledHeading2>
                  Ticket Cost -{' '}
                  {getConvertedPriceString(
                    ticketCurrency,
                    ticketCost,
                    profileData,
                    currencyData,
                  )}
                </StyledHeading2>
                <RightAlignContainer>
                  <PrimaryButton
                    theme={getThemeByRoleId(roleId)}
                    htmlType="submit"
                    type="submit"
                    onClick={handleBookEventSubmit}
                  >
                    Continue
                  </PrimaryButton>
                </RightAlignContainer>
              </>) : null
            }
          </FormGroup>
        </div>
      </Modal>
      {purchaseOverview ? (
        <PurchaseOverviewModal
          purchaseOverview={purchaseOverview}
          purchaseOverviewModalClose={purchaseOverviewModalClose}
          handleBookEventModalOpen={handleBookEventModalOpen}
          promo={promo}
          bookingInfo={bookingInfo}
          inviteCode={inviteCode}
          setOpenBookEventModal={setOpenBookEventModal}
        />
      ) : null}
    </>
  );
};

export default BookEventModal;

const StyledAddressContainer = styled.div`
  .ant-col {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledGappedRow = styled(Row)`
  gap: 20px;
`;

const StyledH3 = styled.h3`
  margin-bottom: 20px;
`;

const StyledHeading = styled.h3`
  margin-top: 10px;
`;

const StyledHeading2 = styled.h2`
  margin-top: 15px;
`;

export const StyledLi = styled.li`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${COLORS.GRAY};
  margin-top: 15px;
  gap: 20px;
  border-radius: 5px;
  width: ${(props) => (props.isFull ? '100%' : '80%')};
  ${PADDING(15)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

export const StyledSelectedIconContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  color: ${(props) => props.color};
  svg {
    font-size: 25px;
  }

  ${(props) => (props.selected ? `` : 'display: none;')}
`;

const StyledEditIconContainer = styled.span`
  margin-left: 20px;
  ${DISPLAY_FLEX('row', 'center', 'center')}

  svg {
    fill: ${(props) => props.color};
    width: 30px;
    height: 30px;
  }
`;

const StyledDeleteIconContainer = styled.span`
  margin-left: 20px;
  ${DISPLAY_FLEX('row', 'center', 'center')}

  svg {
    fill: ${COLORS.RED};
    width: 30px;
    height: 30px;
  }
`;

const StyledContainer = styled.div`
  ${DISPLAY_FLEX('row')}
`;
