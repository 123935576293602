import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  InstagramIcon,
  PinterestIcon,
  MediumIcon,
  GlobalIcon,
} from '../Common/icons';
import DiscordIcon from '../Common/icons/DiscordIcon';
import YoutubeIcon from '../Common/icons/YoutubeIcon';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MB, MR } from '../../styles/constants/display';

export const socailIcons = [
  {
    name: 'facebook',
    icon: <FacebookIcon />,
  },
  { name: 'twitter', icon: <TwitterIcon /> },
  {
    name: 'linkedin',
    icon: <LinkedInIcon />,
  },
  {
    name: 'instagram',
    icon: <InstagramIcon />,
  },
  {
    name: 'pinterest',
    icon: <PinterestIcon />,
  },
  { name: 'medium', icon: <MediumIcon /> },
  {
    name: 'youtube',
    icon: <YoutubeIcon />,
  },
  {
    name: 'discord',
    icon: <DiscordIcon />,
  },
  {
    name: '',
    icon: <GlobalIcon />,
  },
];

export const NewTabLinks: React.FC<any> = ({ children, link, isMobile }) => {
  return (
    <StyledSocialLinks
      isMobile={isMobile}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </StyledSocialLinks>
  );
};

const StyledSocialLinks = styled.a<{ isMobile: Boolean }>`
  cursor: pointer !important;
  svg {
    width: 30px;
    margin-right: 12px;
    color: ${COLORS.BLACK};
    rect,
    path {
      transition: 0.4s;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ isMobile }) =>
      isMobile &&
      `svg {
      width: 25px;
      ${MR(6)}
    }`}
  }
`;

export const socailLinks = [
  {
    link: 'https://www.facebook.com/recoremofficial',
    icon: <FacebookIcon />,
  },
  { link: 'https://twitter.com/OfficialRecorem', icon: <TwitterIcon /> },
  {
    link: 'https://www.linkedin.com/company/recorem-com',
    icon: <LinkedInIcon />,
  },
  {
    link: 'https://www.instagram.com/recorem_official/',
    icon: <InstagramIcon />,
  },
  {
    link: 'https://www.pinterest.com/recoremdm2020/',
    icon: <PinterestIcon />,
  },
  { link: 'https://medium.com/@recoremdm2020', icon: <MediumIcon /> },
  {
    link: 'https://www.youtube.com/channel/UCqczYH6S0q9acGhXPmCt9oA',
    icon: <YoutubeIcon />,
  },
  {
    link: 'https://discord.gg/F9AFMbuJ9x',
    icon: <DiscordIcon />,
  },
];

export const StyledUL = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 8px;
`;

export const StyledLI = styled.li`
  display: inline-block;

  a svg path {
    fill: ${COLORS.BLACK};
  }

  a:hover svg path {
    fill: ${COLORS.WHITE};
  }

  &:nth-child(1) a:hover svg rect {
    fill: ${COLORS.DARK_BLUE_2};
  }
  &:nth-child(2) a:hover svg rect {
    fill: ${COLORS.PICTON_BLUE};
  }
  &:nth-child(3) a:hover svg rect {
    fill: ${COLORS.LOCHMARA_BLUE};
  }
  &:nth-child(4) a:hover svg rect {
    fill: ${COLORS.LIGHT_BLUE_2};
  }
  &:nth-child(5) a:hover svg rect {
    fill: ${COLORS.THUNDERBIRD_RED};
  }
  &:nth-child(6) a:hover svg rect {
    fill: ${COLORS.BLACK};
  }
  &:nth-child(7) {
    svg {
      height: 40px;
    }

    a:hover svg rect {
      fill: ${COLORS.RED_2};
    }
  }
  &:nth-child(8) {
    a:hover svg path:first-child {
      fill: ${COLORS.VERY_LIGHT_BLUE};
    }
    a svg path:first-child {
      fill: ${COLORS.WHITE_SMOKE};
    }
  }

  &:nth-child(9) {
    svg {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      ${MB(5)}
    }
    a svg path {
      fill: revert-layer;
    }
    a:hover svg path {
      fill: ${COLORS.WHITE};
    }
    a:hover svg path:first-child {
      fill: ${COLORS.LOCHMARA_BLUE};
    }
    a svg path:first-child {
      fill: ${COLORS.GHOST_WHITE_2};
    }
    a svg path:nth-child(2) {
      scale: 0.8;
      transform: translate(13%, 13%);
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      svg {
        width: 25px;
        height: 25px;
        ${MB(8)}
      }
    }

  }
`;
