import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import getRemainingTime from '../../../utils/getRemainingTime';
import moment from 'moment';
import { styledStateText } from '../Styled-components/EventCardCommon';

const EventEndTimer = ({ dateTime }) => {
  const [endTime, setEndTime] = useState(`0 Days`);

  useEffect(() => {
    const interval = setInterval(() => {
      setEndTime(getRemainingTime(moment.unix(dateTime), false, true));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dateTime]);

  return (
    <React.Fragment>
      <StyledTime>Event ends in {endTime}</StyledTime>
    </React.Fragment>
  );
};

export default EventEndTimer;

const StyledTime = styled.time`
  ${styledStateText}
`;
