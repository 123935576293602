/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';
import {
  getRoleId,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  checkIfUserIsCandidate,
} from '../../../utils/userInfo';
import EarthIcon from '../../../assets/icons-and-sets/047-earth-grid.svg';
import SalesIcon from '../../../assets/icons-and-sets/170-ticket.svg';
import AlarmIcon from '../../../assets/icons-and-sets/082-alarm-1.svg';
import BreifCaseOutlinedIcon from '../../../assets/icons-and-sets/briefcase.svg';
import RecruitIcon from '../../../assets/icons-and-sets/031-businessman.svg';
import {
  AdminRoutes,
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../../constants/routes';
import { NotificationsType } from '../../../constants/notificationsType';
import { NotificationTypeListProps } from '../../../interfaces/components/Common';
import { IconBadge } from '../IconBadge';
import { userProfileStore } from '../../../utils/profile';
import AppSocketContext from '../../../context/AppSocket';
import { COLORS } from '../../../styles/constants/colors';

export const NotificationTypeList: React.FC<NotificationTypeListProps> = ({
  updates,
  showViewAll = false,
}) => {
  const roleId: number = getRoleId();
  const client = useContext(AppSocketContext);
  const { profileData } = userProfileStore();
  const [sales, setSales] = useState<number>(get(updates, 'sales', 0));
  const [team, setTeam] = useState<number>(get(updates, 'team', 0));
  const [update, setUpdate] = useState<number>(get(updates, 'update', 0));
  const [jobs, setJobs] = useState<number>(get(updates, 'jobs', 0));
  const [chat, setChat] = useState<number>(get(updates, 'chat', 0));

  useEffect(() => {
    if (updates) {
      setSales(get(updates, 'sales', 0));
      setTeam(get(updates, 'team', 0));
      setUpdate(get(updates, 'update', 0));
      setJobs(get(updates, 'jobs', 0));
      setChat(get(updates, 'chat', 0));
    }
  }, [updates]);

  const getRoute = (): string => {
    switch (true) {
      case checkIfUserIsOrganizer(roleId):
        return OrganizerRoutes.notifications;
      case checkIfUserIsRecruiter(roleId):
        return RecruiterRoutes.notifications;
      case checkIfUserIsCandidate(roleId):
        return CandidateRoutes.notifications;
      default:
        return AdminRoutes.notifications;
    }
  };

  const getTotalNotificationsCount = () => {
    return sales + team + update + jobs + chat;
  };

  useEffect(() => {
    if (client && profileData.userId) {
      (async () => {
        await client.subscribe(
          `/user/${profileData.userId}/notification/`,
          (resp: any) => {
            switch (resp.category) {
              case NotificationsType.Team:
                setTeam((prevTeam) => prevTeam + 1);
                break;
              case NotificationsType.Sales:
                setSales((prevSales) => prevSales + 1);
                break;
              case NotificationsType.Chat:
                setChat((prevChat) => prevChat + 1);
                break;
              case NotificationsType.Jobs:
                setJobs((prevJobs) => prevJobs + 1);
                break;
              default:
                setUpdate((prevUpdate) => prevUpdate + 1);
            }
          },
        );
      })();
    }

    // eslint-disable-next-line
  }, [profileData.userId]);

  return (
    <StyledUl>
      {showViewAll ? (
        <StyledLink to={`${getRoute()}/?type=${NotificationsType.Default}`}>
          <IconBadge
            icon={EarthIcon}
            color={COLORS.DARK_SALMON}
            text="View All"
            numberBadge
            numberBadgeText={getTotalNotificationsCount()}
            hoverEffect
            id="0"
          />
        </StyledLink>
      ) : null}

      {checkIfUserIsOrganizer(roleId) ? (
        <StyledLink to={`${getRoute()}/?type=${NotificationsType.Sales}`}>
          <IconBadge
            icon={SalesIcon}
            color={COLORS.DARK_SALMON}
            text="Sales"
            numberBadge
            numberBadgeText={sales}
            hoverEffect
            id="1"
          />
        </StyledLink>
      ) : checkIfUserIsRecruiter(roleId) ? (
        <StyledLink to={`${getRoute()}/?type=${NotificationsType.Jobs}`}>
          <IconBadge
            icon={RecruitIcon}
            color={COLORS.DARK_SALMON}
            text="Recruits"
            numberBadge
            numberBadgeText={jobs}
            hoverEffect
            id="5"
          />
        </StyledLink>
      ) : null}
      <StyledLink to={`${getRoute()}/?type=${NotificationsType.Update}`}>
        <IconBadge
          icon={AlarmIcon}
          color={COLORS.BLUSH}
          text="Updates"
          numberBadge
          numberBadgeText={update}
          hoverEffect
          id="3"
        />
      </StyledLink>
      {!checkIfUserIsCandidate(roleId) ? (
        <StyledLink to={`${getRoute()}/?type=${NotificationsType.Team}`}>
          <IconBadge
            icon={BreifCaseOutlinedIcon}
            color={COLORS.FEDORA}
            text="Team"
            numberBadge
            numberBadgeText={team}
            hoverEffect
            id="2"
          />
        </StyledLink>
      ) : (
        <StyledLink to={`${getRoute()}/?type=${NotificationsType.Jobs}`}>
          <IconBadge
            icon={BreifCaseOutlinedIcon}
            color={COLORS.FEDORA}
            text="Jobs"
            numberBadge
            numberBadgeText={jobs}
            hoverEffect
            id="5"
          />
        </StyledLink>
      )}
      {
        // When chat functionality is implemented we will use this notification item
        /*
      <NotificationTypeIcon
        color="blue"
        Icon={NotificationChatIcon}
        itemValue="13"
        itemName="Chats"
      />
      */
      }
    </StyledUl>
  );
};

const StyledUl = styled.ul`
  clear: both;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  height: max-content;
  margin: 0 10px;
  margin-top: 0;
`;
