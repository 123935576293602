export const ToolTipsText = {
  manageCrew: 'Invite new crew members or manage existing crew',
  eventAnalytics: 'Detailed Event Analytics and report',
  jobOpportunities: 'View job applications result',
  switchCrew: 'Switch Crew',
  bulkInvite: 'Invite a vast number of attendees via email in one go',
  autoPresent:
    'Marks the invited attendees present when they join the live event',
  refresh: 'Refresh',
  broadcaseEvent: 'Air your live event on Recorem!',
  availability: 'Notice Period',
  nationalities: 'Nationalities',
  countries: 'Countries',
  applicationStatus: 'Application status',
  location: 'Location',
  jobLocation: 'Job Location',
  evaluationTimer:
    'Hurry, shortlist prospective candidates before the deadline or you’ll miss out on unreviewed / skipped applications',
  recruiterCalendar:
    'Select a date to Schedule Interviews for Shortlisted Candidates',
  jobType: 'Job Type',
  jobEngagement: 'Engagement',
  jobDetails: 'View Job Details',
  claimNft:
    'By clicking here, you will be able to claim your NFT, which allows you to prove that you have successfully attended this event.',
  communityPage: 'Contact support to enable community page for your account',
};
