/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Spin } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import Address from './Address';
import { UploadLogoComponent } from '../Common/UploadComponent';
import ProfileLinksComponent from '../ProfileLinks/ProfileLinks';
import {
  ProfileInformationHeader,
  ProfileInformationValue,
  StyledProfileMediumHeading,
} from '../CrewManagement/common';
import { getUserColorWithRoleId } from '../../styles/utils';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsMasterAdmin,
  getRoleId,
} from '../../utils/userInfo';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getSize } from '../../utils/size';
import { MediumText } from '../shared-components/Texts';
import { isMobile } from '../../helpers/display';
import VerifyProfile from '../../containers/MasterAdmin/commons/VerifyProfile';
import { ORGANIZER, STATUS_ACTIVE } from '../../constants';
import { enableCommunityPage } from '../../service/organizer';
import { getCategoryOptionsByLabel } from '../../utils/common';
import { getCategories } from '../../service';
import { PrimaryButton } from '../shared-components/Button';
import { COLORS } from '../../styles/constants/colors';
import { FONT } from '../../styles/constants/typography';
import Notification from '../Notification/Notification';
import { Modal } from '../shared-components/Modal';
import LinkIcon from '../../assets/svg/link.svg';
import config from '../../config';
import { useGetCommunityPageQuery } from '../../store/features/rtkApi/common';

const CompanyInformation = ({
  companyData,
  profileData,
  companyLogo,
  setCompanyLogo,
  userRole,
  isRecruiterInfo = false,
}) => {
  const companyId = get(companyData, 'id', null);
  const roleId = getRoleId();
  const {
    isSuccess: isCommunityPageActive,
    refetch,
  } = useGetCommunityPageQuery(companyId, {
    skip:
      (!checkIfUserIsOrganizer(roleId) && !checkIfUserIsMasterAdmin(roleId)) ||
      isRecruiterInfo,
  });
  const companyStatus = get(companyData, 'status', STATUS_ACTIVE);
  const [categoryOptions, setCategoriesOptions] = useState([]);
  const [selectedCategor, setSelectedCategories] = useState([]);
  const companyProfileLinks = get(companyData, 'profileLinks.links', []);
  const profileType = get(companyData, 'role');
  const isMasterAdmin = checkIfUserIsMasterAdmin(roleId || userRole);
  const communityPageLink = `${config.appURL}/event-partner/${companyId}/community`;

  const getEmailAddress = () => {
    let email;
    if (profileData && profileData.companyAdmin) {
      email = profileData.companyAdmin.representativeEmail;
    } else if (profileData && profileData.representativeEmail) {
      email = profileData.representativeEmail;
    } else {
      email = get(
        companyData,
        'companyRecruiters[0].user.recruiter.representativeEmail',
        '',
      );
    }
    return email;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCategoriesChange = (value) => {
    setSelectedCategories(value);
  };

  const handleCommunityPage = async () => {
    setIsModalOpen(false);
    await enableCommunityPage({
      companyId: companyId,
      categoryIds: selectedCategor,
    }).then(() => {
      refetch();
    });
    // setIsCommunityPageActive(true)
    Notification('success', 'community page enabled');
  };

  useEffect(() => {
    getCategories().then((categoriesList) => {
      setCategoriesOptions(categoriesList);
    });
  }, []);

  return (
    <StyledMainRow gutter={[16, 24]}>
      <Col xs={{ span: 24 }} md={{ span: 18 }}>
        <div>
          <MediumText
            margin={isMobile() ? '0 0 5px 8px' : '15px 0'}
            fontWeight={700}
            color={getUserColorWithRoleId(roleId)}
          >
            Company Information
          </MediumText>
          <StyledRow gutter={[16, 24]}>
            <Col xs={24} lg={8}>
              <ProfileInformationHeader>Company Name</ProfileInformationHeader>
              <ProfileInformationValue>
                {get(companyData, 'name', '')}
              </ProfileInformationValue>
            </Col>
            <Col xs={24} lg={8}>
              <ProfileInformationHeader>Office Number</ProfileInformationHeader>
              <ProfileInformationValue>
                {get(companyData, 'phone', '')}
              </ProfileInformationValue>
            </Col>
            <Col xs={24} lg={8}>
              <ProfileInformationHeader>Email Address</ProfileInformationHeader>
              <ProfileInformationValue>
                {getEmailAddress()}
              </ProfileInformationValue>
            </Col>
          </StyledRow>
          <StyledRow gutter={[16, 24]}>
            <Col xs={24} lg={8}>
              <ProfileInformationHeader>Industry Type</ProfileInformationHeader>
              <ProfileInformationValue>
                {get(companyData, 'domain', '')}
              </ProfileInformationValue>
            </Col>
            <Col xs={24} lg={8}>
              <ProfileInformationHeader>
                Company Website
              </ProfileInformationHeader>
              <ProfileInformationValue>
                {get(companyData, 'website', '')}
              </ProfileInformationValue>
            </Col>
            {isMasterAdmin && profileType === ORGANIZER ? (
              <Col xs={24} lg={8}>
                <ProfileInformationHeader>
                  Community Page
                </ProfileInformationHeader>
                <ProfileInformationValue>
                  {isCommunityPageActive === null ? (
                    <Spin />
                  ) : isCommunityPageActive ? (
                    <StyledActivatedMessage>
                      {' '}
                      Activated
                      <a href={communityPageLink}>
                        <StyledLinkIcon src={LinkIcon} alt="link icon" />
                      </a>
                    </StyledActivatedMessage>
                  ) : (
                    <>
                      <PrimaryButton
                        onClick={showModal}
                        disabled={companyStatus !== STATUS_ACTIVE}
                      >
                        Enable
                      </PrimaryButton>
                    </>
                  )}
                  <Modal
                    title="Community Page"
                    visible={isModalOpen}
                    onOk={handleCommunityPage}
                    onCancel={handleCancel}
                    okButtonProps={{ disabled: !selectedCategor.length > 0 }}
                    okText="Enable"
                  >
                    <StyledLabel>Select Categories</StyledLabel>
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}
                      placeholder="select categories"
                      onChange={handleCategoriesChange}
                      optionLabelProp="label"
                      options={getCategoryOptionsByLabel(categoryOptions)}
                      value={selectedCategor}
                    />
                  </Modal>
                </ProfileInformationValue>
              </Col>
            ) : null}
          </StyledRow>
          <StyledRow gutter={[16, 24]}>
            <Col xs={24} lg={18}>
              <ProfileInformationHeader>Description</ProfileInformationHeader>
              <ProfileInformationValue>
                {get(companyData, 'brief', '')}
              </ProfileInformationValue>
            </Col>
            {checkIfUserIsOrganizer(userRole || roleId) ? (
              <Col xs={24} lg={18}>
                <ProfileInformationHeader>
                  Community Outreach Message
                </ProfileInformationHeader>
                <ProfileInformationValue>
                  {get(companyData, 'communityOutreachMessage', '')}
                </ProfileInformationValue>
              </Col>
            ) : null}
            {isMasterAdmin && profileType === ORGANIZER ? (
              <>
                <Col xs={24} lg={18}>
                  <ProfileInformationHeader>
                    Community / Event Link
                  </ProfileInformationHeader>
                  <ProfileInformationValue>
                    {get(profileData, 'meta.communityOrEventLink', '')}
                  </ProfileInformationValue>
                </Col>
                <Col xs={24} lg={18}>
                  <ProfileInformationHeader>
                    Target Audience Region
                  </ProfileInformationHeader>
                  <ProfileInformationValue>
                    {get(profileData, 'meta.targetAudienceRegion', '')}
                  </ProfileInformationValue>
                </Col>
                <Col xs={24} lg={18}>
                  <VerifyProfile
                    profileData={profileData}
                    companyData={companyData}
                    roleId={ORGANIZER}
                  />
                </Col>
              </>
            ) : null}
          </StyledRow>
        </div>

        <StyledMargedTitle>
          <MediumText
            fontWeight={600}
            margin={isMobile() ? '0 0 8px 8px' : '10px 0'}
            color={getUserColorWithRoleId(roleId)}
          >
            Address
          </MediumText>
          <Address data={companyData} margin={isMobile() && '0 0 0 8px'} />
        </StyledMargedTitle>

        {companyProfileLinks.length ? (
          <StyledMargedTitle>
            <StyledProfileMediumHeading color={getUserColorWithRoleId(roleId)}>
              Profile Link
            </StyledProfileMediumHeading>
            <ProfileLinksComponent profileLinks={companyProfileLinks} />
          </StyledMargedTitle>
        ) : null}
      </Col>
      <StyledUploadContainer>
        <UploadLogoComponent
          avatarSize={getSize(150, 140, 120)}
          option={false}
          removeOption
          companyLogo={companyLogo}
          setCompanyLogo={setCompanyLogo}
          fileName={companyData.logo}
          alt="company-logo"
        />
      </StyledUploadContainer>
    </StyledMainRow>
  );
};

export default CompanyInformation;

const StyledMainRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 80px;
  }
`;

const StyledRow = styled(Row)`
  ${MARGIN(0, 0, 15, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 8, 0)}
    ${PADDING(10, 0)}
    margin-left: 8px !important;
  }
`;

const StyledUploadContainer = styled.div`
  position: absolute;
  top: 100px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    right: 9%;
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    right: 8.6%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    right: 50%;
    top: -40px;
    max-width: fit-content;
    margin-right: -60px;
  }
`;

const StyledMargedTitle = styled.div`
  ${MARGIN(40, 0, 0, 0)}
`;

const StyledLabel = styled.div`
  ${MARGIN(0, 0, 5, 0)}
  font-size: ${FONT[14]};
`;
const StyledActivatedMessage = styled.div`
  background-color: ${COLORS.TRUE_GREEN};
  color: ${COLORS.WHITE};
  ${PADDING(2, 5, 2, 8)}
  width: fit-content;
  border-radius: 5px;
`;
const StyledLinkIcon = styled.img`
  width: 12px;
  ${MARGIN(0, 4, 0, 8)}
  filter: invert(1);
  cursor: pointer;
`;
