import React from 'react';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { UploadOutlined } from '@ant-design/icons';
import { message, Row } from 'antd';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useDispatch } from 'react-redux';
import { getFilePath, updateResume } from '../../../../utils/profile';
import {
  CANDIDATE,
  CANDIDATE_COLOR,
  UploadResumeSupportedFiles,
} from '../../../../constants';
import {
  TextUploadFileList,
  ToolTippedInfo,
} from '../../../../components/shared-components/Upload';
import { UploadDocumentNotification } from '../../../../components/Notification/UploadPictureNotification';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import {
  MediumHeading,
  SmallHeading,
} from '../../../../components/shared-components/Texts';
import { FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import GoogleDrivePicker from '../../../../components/Common/GoogleDrivePicker';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import { DocumentViewer } from '../../../../components/shared-components/DocumentViewerModal';
import { useTagManager } from '../../../../utils/customHooks';
import { largeFontMixin } from '../../../../styles/constants/mixins';
import { RightAlignContainer } from '../../../../components/shared-components/RightAlignContainer';
import { getProfileDataWithoutReload } from '../../../../store/features/profile';
import Notification from '../../../../components/Notification/Notification';

export const QuickSubmission = ({
  resume,
  setResume,
  isApplyModal,
  setStep,
  isVisible,
  profileData,
}) => {
  const updateUserActionInGTM = useTagManager();
  const dispatch = useDispatch();
  const DEFAULT_LIMIT_SIZE = 5242880;
  const [upload, setUpload] = React.useState(false);

  const updateResumeCustomReq = ({ onSuccess, onError, file }) => {
    setResume([{ ...file, name: file.name, isLoading: true }]);
    updateResume(file)
      .then((response) => {
        updateUserActionInGTM('resume_uploaded');
        setResume([
          {
            ...file,
            name: file.name,
            url: getFilePath(response),
            isLoading: false,
          },
        ]);
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const resumeOnChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    if (upload) {
      setResume(fileList);
      UploadDocumentNotification(info);
    }
  };

  const handleSubmitResume = () => {
    if (
      !isArray(resume) ||
      resume.length === 0 ||
      get(resume, '[0].isLoading', false)
    ) {
      return message.error({
        content: get(resume, '[0].isLoading', false)
          ? 'Please wait resume upload is in process'
          : 'Please add your resume',
      });
    }
    if (get(profileData, 'userSkills', []).length) {
      dispatch(getProfileDataWithoutReload()).then(() => {
        setStep((prev) => prev + 2);
      });
    } else {
      setStep((prev) => prev + 1);
    }
  };

  function checkFileBefore(file) {
    if (file.size >= DEFAULT_LIMIT_SIZE) {
      setUpload(false);
      Notification(
        'error',
        'File is too large',
        'Please upload a file of size less than 5 MB',
      );
      return false;
    }
    if (file.type !== 'application/pdf') {
      setUpload(false);
      Notification(
        'error',
        'Invalid file type',
        'Please upload a pdf file only',
      );
      return false;
    }
    setUpload(true);
    return true;
  }

  return isVisible ? (
    <>
      <StyledQuickSubmissionTitle level={4}>
        <MediumHeading margin="0px">
          {isApplyModal ? (
            <>
              Upload Your Resume <MandatoryStar />
            </>
          ) : (
            'Quick Submission'
          )}
        </MediumHeading>
      </StyledQuickSubmissionTitle>
      {isApplyModal ? null : (
        <StyledUploadTitle level={4}>
          <SmallHeading margin="0px">
            Upload Your Resume <MandatoryStar />
          </SmallHeading>
        </StyledUploadTitle>
      )}

      <StyledUploadSection>
        <StyledTextUploadFileList
          name="uploadResume"
          className="resume-upload upload_btn"
          icon={<UploadOutlined />}
          accept={UploadResumeSupportedFiles}
          customRequest={updateResumeCustomReq}
          onChange={resumeOnChange}
          beforeUpload={(file) => checkFileBefore(file)}
          showUploadList
          onBoard
          fileList={null}
          roleId={CANDIDATE}
          showTooltip={false}
        />
        <GoogleDrivePicker setResume={setResume} onSuccessResumeDrive={() => setUpload(true)} />
        <ToolTippedInfo
          backgroundColor={getUserColorWithRoleId(CANDIDATE)}
          hintText="Please upload your resume in PDF format"
          position="relative"
          top="0px"
        />
        <StyledHintText>
          <span>or </span>
          <StyledUploadInformationSpan>
            <StyledCandidateLink
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download your Linkedin Resume & Upload
            </StyledCandidateLink>{' '}
            (Only PDF)
          </StyledUploadInformationSpan>
        </StyledHintText>
      </StyledUploadSection>
      {upload &&
        resume &&
        resume.map((item) => (
          <DocumentViewer
            filePath={item.url}
            fileName={item.name}
            isLoading={item.isLoading}
          />
        ))}
      {isApplyModal ? (
        <RightAlignContainer>
          <StyledButton color={CANDIDATE_COLOR} onClick={handleSubmitResume}>
            Next
          </StyledButton>
        </RightAlignContainer>
      ) : null}
    </>
  ) : null;
};

// Wrap around MediumHeading
const StyledQuickSubmissionTitle = styled(Title)`
  margin-top: 10px;
`;

// Wrap around SmallHeading
const StyledUploadTitle = styled(Title)`
  font-weight: ${FONT_WEIGHTS.MEDIUM} !important;
  margin-top: 16.8px !important;
  margin-bottom: 7px !important;
`;

const StyledUploadSection = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 5px;
  margin-top: 10px;

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    white-space: break-spaces;
  }

  .ant-upload-list-item {
    margin-top: 25px;
  }
`;

const StyledTextUploadFileList = styled(TextUploadFileList)`
  margin: 10px 0;
  padding: 7px 22px 31px;
  border: 1px solid ${COLORS.ARGENT};
  border-radius: 3px;
  margin-right: 10px;
`;

const StyledUploadInformationSpan = styled.span`
  color: ${CANDIDATE_COLOR};

  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_2}) {
    margin-top: 15px !important;
  }
`;

const StyledCandidateLink = styled.a`
  color: inherit;
  cursor: pointer !important;

  &:hover {
    color: inherit;
  }
`;

const StyledHintText = styled.span`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  width: 90%;
  height: 40px;
  background-color: ${(props) => props.color};
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  max-width: 168px;
  border: none;
  cursor: pointer;
  ${largeFontMixin}
`;
