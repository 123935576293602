/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Checkbox,
  AutoComplete,
  DatePicker,
  Radio,
} from 'antd';
import styled from 'styled-components';
import toString from 'lodash/toString';
import PlacesAutocomplete from 'react-places-autocomplete';
import toLower from 'lodash/toLower';
import get from 'lodash/get';
import first from 'lodash/first';
import { validateMessages } from '../../Form/validateMessages';
import { isDesktop } from '../../../helpers/display';
import { SearchIcon } from '../../svgicons';
import {
  validateURL,
  passwordCheckPattern,
  phoneNumberCheckPattern,
  WebsiteCheckPattern,
  CountryCodePattern,
  PasswordRuleText,
} from '../../../constants';
import { getValidationMessage } from '../../../utils/error';
import {
  RuleProps,
  InputFieldProps,
  InputTextAreaProps,
  TextBoxProps,
  NumberBoxProps,
  EmailProps,
  DropdownProps,
  FormGroupProps,
  PasswordProps,
  ConfirmPasswordProps,
  SubmitProps,
  RememberProps,
  InputBoxProps,
  SearchInputBoxProps,
  DropdownBoxProps,
  AutoCompleteBoxProps,
  AddressAutoCompleteBoxProps,
  DateTimePickerProps,
  RadioButtonInputProps,
  OTPInputProps,
} from '../../../interfaces/components/Form';
import { SmallFont } from '../Texts';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import Notification from '../../Notification/Notification';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { mediumFontMixin } from '../../../styles/constants/mixins';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const customRules: any = {
  text: ({ label, required, customValidation }: RuleProps) => [
    {
      required,
      message: getValidationMessage(label, customValidation),
    },
  ],
  number: ({ label, required, customValidation }: RuleProps) => [
    {
      message: getValidationMessage(label, customValidation),
      pattern: new RegExp('^[0-9]+$'),
    },
    {
      required,
      message: getValidationMessage(label, customValidation),
    },
  ],
  numberWithCustomValidation: ({
    label,
    required,
    validator,
    customValidation,
  }: RuleProps) => [
    {
      message: getValidationMessage(label, customValidation),
      pattern: new RegExp('^[0-9]+$'),
    },
    {
      required,
      message: getValidationMessage(label, customValidation),
    },
    {
      validator,
    },
  ],
  phoneNumber: ({ required = false }: RuleProps) => [
    {
      required,
      pattern: phoneNumberCheckPattern,
      message: 'Please enter a valid phone number.',
    },
  ],
  email: ({ required }: RuleProps) => [
    {
      required,
      // message: 'Please input your Email',
    },
    {
      type: 'email',
    },
  ],
  password: ({ required }: RuleProps) => [
    {
      required,
      pattern: passwordCheckPattern,
      // message: 'Please input your Password',
    },
  ],
  checkPassword: ({ required }: RuleProps) => [
    {
      required,
      pattern: passwordCheckPattern,
      message: (
        <SmallFont color={COLORS.RED} margin="6px 0px">
          {PasswordRuleText}
        </SmallFont>
      ),
      // message: 'Please input your Password',
    },
    () => ({
      validator: (rule: any, value: string | any[]) => {
        if (value.length >= 6) {
          return Promise.resolve();
        }

        // return Promise.reject('Your password must contain at least 6 characters.');
        return Promise.reject('');
      },
    }),
  ],
  confirmPassword: ({ required }: RuleProps) => [
    {
      required,
      pattern: passwordCheckPattern,
      // message: 'Please confirm your password!',
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(rule: any, value: string | any[]) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        // return Promise.reject('The two passwords do not match!');
        return Promise.reject('');
      },
    }),
  ],
  onlyCharaters: ({ required }: RuleProps) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: new RegExp('^[a-zA-Z ]+$'),
      // message: "Wrong format!"
    },
  ],
  charAndNumber: ({ label, required, customValidation }: RuleProps) => [
    {
      message: getValidationMessage(label, customValidation, true),
      pattern: new RegExp('^[a-zA-Z0-9 ]*$'),
    },
    {
      required,
      message: getValidationMessage(label, customValidation),
    },
  ],
  url: ({ required }: RuleProps) => [
    {
      required,
      type: 'url',
    },
  ],

  zipCode: ({ zipValidator }: RuleProps) => [
    {
      validator: zipValidator,
      // message: "Wrong format!"
    },
  ],
  companyWebsite: ({ required }: RuleProps) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: WebsiteCheckPattern,
    },
  ],

  countryCode: ({ required }: RuleProps) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: CountryCodePattern,
    },
  ],

  priceNumber: ({ required, validator }: RuleProps) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      validator,
    },
    // {
    // 	type: 'number'
    // }
  ],
  percentage: ({ required }: RuleProps) => [
    {
      required,
    },
    {
      validator: (rule: any, value: number | any[]) => {
        if (value >= 1 && value <= 100) {
          return Promise.resolve();
        }

        // return Promise.reject('The two passwords do not match!');
        return Promise.reject('');
      },
    },
  ],

  customUrl: ({ required }: RuleProps) => [
    {
      required,
    },
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator(rule: any, value: string | any[]) {
        if (
          !value ||
          (getFieldValue('companyWebsite') === value && validateURL(value))
        ) {
          return Promise.resolve();
        }
        return Promise.reject('');
      },
    }),
  ],

  customUrlCheck: ({ required }: RuleProps) => [
    {
      required,
    },
    () => ({
      validator(rule: any, value: any) {
        if (validateURL(value)) {
          return Promise.resolve();
        }
        return Promise.reject('');
      },
    }),
  ],
};

const getUniqueIdForOptions = (id?: string, option?: any, name?: string) => {
  const parameter = get(option, 'name', name || 'option');
  return (id ? `${id}_` : '') + toString(parameter).replace(/ /g, '_');
};

const getValue = (idValue: boolean, option: any): string | number => {
  return idValue ? option.id : option.value || option.name;
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

// we might want to filter or search based on more then just value , so usign this logiv we can search will all the parameter
export const filterOptions = (input: any = '', option: any) => {
  return (
    toLower(get(option, 'props.children', '')).indexOf(toLower(input)) >= 0 ||
    toLower(get(option, 'props.value', get(option, 'value', ''))).indexOf(
      toLower(input),
    ) >= 0 ||
    toLower(
      get(option, 'props.name', get(option, 'name', get(option, 'label', ''))),
    ).indexOf(toLower(input)) >= 0 ||
    toLower(get(option, 'props.key', '')).indexOf(toLower(input)) >= 0
  );
};

export const FormGroup: React.FC<FormGroupProps> = ({
  form,
  children,
  name,
  expand = false,
  initialValues = {},
  onValuesChange,
  onFinish,
  onFailedFinish,
  id,
}) => {
  const onFinishFailed = (errors: any) => {
    const errorFields = get(errors, 'errorFields', []);
    if (onFailedFinish) onFailedFinish(errorFields);
    errorFields.forEach((error: any) => {
      const errorFieldName: any = first(get(error, 'name', []));
      if (!errorFieldName) {
        return;
      }
      const result = errorFieldName.replace(/([A-Z]{1,})/g, ' $1');
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

      Notification(
        'error',
        'Validation Error',
        `Please fill "${finalResult}" field with correct data`,
      );
    });
  };

  return (
    <Form
      form={form}
      name={name}
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={initialValues}
      id={id}
      onValuesChange={onValuesChange}
      {...(expand ? {} : layout)}
      scrollToFirstError
      onFinishFailed={onFinishFailed}
    >
      {children}
    </Form>
  );
};

export const InputField: React.FC<InputFieldProps> = ({
  label = '',
  name = '',
  rules = [],
  dependencies = [],
  hasFeedback = true,
  field,
  fieldKey,
  children,
  suffix = <></>,
  hidden = false,
  initialValue,
}) => {
  return (
    <Form.Item
      {...field}
      rules={rules}
      label={label}
      name={name}
      dependencies={dependencies}
      hasFeedback={hasFeedback}
      fieldKey={fieldKey}
      suffix={suffix}
      initialValue={initialValue}
      hidden={hidden}
    >
      {children}
    </Form.Item>
  );
};

export const TextBox: React.FC<TextBoxProps> = (props) => {
  const {
    required = true,
    label,
    prefix,
    placeholder,
    id,
    disabled = false,
    value,
    maxLength = 255,
    rule = 'onlyCharaters',
    defaultValue = '',
    suffix = null,
    handleChange,
    customValidation,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules[rule]({
        required,
        customValidation,
        label,
      })}
    >
      <Input
        prefix={prefix}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        maxLength={maxLength}
        size={isDesktop() ? 'large' : 'middle'}
        suffix={suffix}
        onChange={handleChange}
      />
    </InputField>
  );
};

export const NumberBox: React.FC<NumberBoxProps> = (props) => {
  const {
    required = true,
    label,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    maxChar = 32,
    prefix = '',
    placeholder = '',
    disabled,
    rule = 'number',
    id,
    name = '',
    value,
    defaultValue,
    validator = null,
    customValidation,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules[rule]({
        required,
        customValidation,
        label,
        validator,
      })}
      initialValue={defaultValue}
    >
      <InputNumber
        name={name}
        value={value}
        title={name}
        size={isDesktop() ? 'large' : 'middle'}
        type="number"
        prefix={prefix}
        placeholder={placeholder}
        min={min}
        max={max}
        parser={(val) => Number(val?.toString().substring(0, maxChar))}
        disabled={disabled}
        id={id}
        style={{ height: '100%' }}
      />
    </InputField>
  );
};

export const Email: React.FC<EmailProps> = (props) => {
  const {
    required = true,
    id,
    prefix,
    placeholder,
    label,
    disabled = false,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules.email({ required, label: label || placeholder })}
    >
      <Input
        prefix={prefix}
        id={id}
        placeholder={placeholder}
        size={isDesktop() ? 'large' : 'middle'}
        disabled={disabled}
      />
    </InputField>
  );
};

export const Password: React.FC<PasswordProps> = (props) => {
  const {
    required = true,
    prefix,
    placeholder,
    label,
    onFocus,
    onBlur,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules.password({ required, label: label || placeholder })}
    >
      <Input.Password
        size={isDesktop() ? 'large' : 'middle'}
        prefix={prefix}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
      />
    </InputField>
  );
};

export const CheckPassword: React.FC<PasswordProps> = (props) => {
  const {
    required = true,
    prefix,
    placeholder,
    label,
    onFocus,
    onBlur,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules.checkPassword({
        required,
        label: label || placeholder,
      })}
    >
      <Input.Password
        size={isDesktop() ? 'large' : 'middle'}
        prefix={prefix}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
      />
    </InputField>
  );
};

export const ConfirmPassword: React.FC<ConfirmPasswordProps> = (props) => {
  const { required = true, prefix, placeholder, label } = props;
  return (
    <InputField
      {...props}
      rules={customRules.confirmPassword({
        required,
        label: label || placeholder,
      })}
    >
      <Input.Password
        size={isDesktop() ? 'large' : 'middle'}
        prefix={prefix}
        placeholder={placeholder}
      />
    </InputField>
  );
};

export const Submit: React.FC<SubmitProps> = (props) => {
  const { name } = props;
  return (
    <Form.Item style={{ margin: 0 }}>
      <Button type="primary" htmlType="submit" style={{ width: '300px' }}>
        {name || 'Submit'}
      </Button>
    </Form.Item>
  );
};

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    required = false,
    label,
    placeholder,
    mode,
    options = [],
    handleChange,
    onSearch,
    width = '100%',
    allowClear = false,
    dropdownMatchSelectWidth = true,
    showSearch = false,
    idValue = false,
    disabled,
    showArrow = false,
    value,
    id,
    defaultValue,
    name,
    customValidation,
  } = props;
  return (
    <StyledInputField
      {...props}
      rules={customRules.text({
        required,
        customValidation,
        label,
      })}
    >
      <Select
        showArrow={showArrow}
        allowClear={allowClear}
        style={{ width }}
        mode={mode}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        onSearch={onSearch}
        showSearch={showSearch}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        getPopupContainer={(trigger) => trigger.parentNode}
        size={isDesktop() ? 'large' : 'middle'}
        disabled={disabled}
        value={value}
        id={id}
        filterOption={filterOptions}
      >
        {options.map((option) => {
          return (
            <Option
              id={getUniqueIdForOptions(id, option, name)}
              key={option.id}
              value={getValue(idValue, option)}
            >
              {option.name}
            </Option>
          );
        })}
      </Select>
    </StyledInputField>
  );
};

export const Remember: React.FC<RememberProps> = ({
  text = 'Remember me',
  handleChange,
}) => {
  return (
    <Form.Item name="remember" valuePropName="checked" noStyle>
      <Checkbox onChange={handleChange}>{text}</Checkbox>
    </Form.Item>
  );
};

export const InputTextArea: React.FC<InputTextAreaProps> = (props) => {
  const {
    required = true,
    label,
    prefix,
    placeholder,
    value = '',
    disabled = false,
    handleChange,
    autoSize = {},
    allowClear = false,
    maxLength = 100,
    showCount = true,
    id,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules.text({ required, label: label || placeholder })}
    >
      <TextArea
        prefix={prefix}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        autoSize={autoSize}
        allowClear={allowClear}
        maxLength={maxLength}
        size={isDesktop() ? 'large' : 'middle'}
        showCount={showCount}
        id={id}
      />
    </InputField>
  );
};

export const InputBox: React.FC<InputBoxProps> = (props) => {
  const {
    id,
    prefix,
    suffix,
    placeholder,
    disabled = false,
    value,
    handleChange,
    pressEnter,
    allowClear = false,
    required = true,
    min,
    max,
    type = 'text',
    onBlur,
    autoComplete = undefined,
  } = props;
  return (
    <Input
      id={id}
      prefix={prefix}
      suffix={suffix}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onPressEnter={pressEnter}
      allowClear={allowClear}
      min={min}
      max={max}
      type={type}
      size={isDesktop() ? 'large' : 'middle'}
      required={required}
      onBlur={onBlur}
      autoComplete={autoComplete}
    />
  );
};

export const SearchInputBox: React.FC<SearchInputBoxProps> = (props) => {
  const {
    id,
    prefix,
    disabled = false,
    value,
    handleChange,
    defaultValue = '',
    allowClear = false,
    placeholder = 'Search Here',
  } = props;
  return (
    <StyledSearchInputBoxDiv>
      <Input
        id={id}
        prefix={prefix}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        allowClear={allowClear}
        size={isDesktop() ? 'large' : 'middle'}
      />
      <SearchIcon />
    </StyledSearchInputBoxDiv>
  );
};

export const DropdownBox: React.FC<DropdownBoxProps> = (props) => {
  const {
    id,
    placeholder,
    mode,
    options = [],
    handleChange,
    width = '100%',
    allowClear = false,
    dropdownMatchSelectWidth = true,
    showSearch = false,
    value,
    disabled = false,
    onSearch,
    onSelect,
    onDeselect,
    name,
    suffixIcon,
  } = props;

  return (
    <StyledDropdownWrapper>
      <Select
        id={id}
        allowClear={allowClear}
        style={{ width }}
        mode={mode}
        placeholder={placeholder}
        onChange={handleChange}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        value={value}
        getPopupContainer={(trigger) => trigger.parentNode}
        size={isDesktop() ? 'large' : 'middle'}
        disabled={disabled}
        onSearch={onSearch}
        showSearch={showSearch}
        onSelect={onSelect}
        onDeselect={onDeselect}
        filterOption={filterOptions}
        suffixIcon={suffixIcon}
      >
        {options.map((option) => {
          return (
            <Option
              id={getUniqueIdForOptions(id, option, name)}
              key={option.id}
              value={option.id}
            >
              {option.name}
            </Option>
          );
        })}
      </Select>
    </StyledDropdownWrapper>
  );
};

export const AutoCompleteBox: React.FC<AutoCompleteBoxProps> = (props) => {
  const {
    required = true,
    id,
    label,
    placeholder,
    options = [],
    handleChange,
    handleSelect,
    text = false,
    defaultValue,
    formField = true,
    onBlur,
    value,
    onKeyDown,
    disabled = false,
  } = props;
  if (formField) {
    return (
      <InputField
        {...props}
        rules={
          text
            ? customRules.text({ required, label: label || placeholder })
            : customRules.email({ required, label: label || placeholder })
        }
      >
        <AutoComplete
          id={id}
          options={options}
          size={isDesktop() ? 'large' : 'middle'}
          onSelect={handleSelect}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
      </InputField>
    );
  }
  return (
    <AutoComplete
      id={id}
      options={options}
      size={isDesktop() ? 'large' : 'middle'}
      onSelect={handleSelect}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      getPopupContainer={(trigger) => trigger.parentElement}
      disabled={disabled}
    />
  );
};

export const AddressAutoCompleteBox: React.FC<AddressAutoCompleteBoxProps> = (
  props,
) => {
  const {
    required = true,
    label,
    placeholder,
    value,
    handleChange,
    disabled,
    handleAutoFill,
    zipValidator,
    onError,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules.zipCode({
        required,
        label: label || placeholder,
        zipValidator,
      })}
    >
      <PlacesAutocomplete
        value={value}
        shouldFetchSuggestions={Boolean(value) && value >= 3}
        debounce={300}
        onChange={handleChange}
        searchOptions={{ types: ['(regions)'] }}
        onError={onError}
      >
        {({
          getInputProps,
          suggestions,
        }: {
          getInputProps: any;
          suggestions: any;
        }) => {
          return (
            <div>
              <Input
                {...getInputProps()}
                placeholder={placeholder}
                size={isDesktop() ? 'large' : 'default'}
                disabled={disabled}
              />
              {handleAutoFill(first(suggestions))}
            </div>
          );
        }}
      </PlacesAutocomplete>
    </InputField>
  );
};

export const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
  const {
    required = true,
    ranges,
    format,
    showTime,
    onChange,
    disabledDate,
    disabledTime,
    getPopupContainer,
    customValidation,
  } = props;
  return (
    <InputField
      {...props}
      rules={customRules.text({
        required,
        customValidation,
      })}
    >
      <RangePicker
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        size={isDesktop() ? 'large' : 'middle'}
        ranges={ranges}
        showTime={showTime}
        format={format}
        onChange={onChange}
        style={{ width: '100%' }}
        getPopupContainer={getPopupContainer}
      />
    </InputField>
  );
};

export const RadioButtonInput: React.FC<RadioButtonInputProps> = (props) => {
  const { options, onTypeChange, type } = props;
  return (
    <Radio.Group
      size={isDesktop() ? 'large' : 'middle'}
      onChange={(e) => onTypeChange(e.target.value)}
      value={type}
    >
      {options.map((option: any, i: any) => (
        // eslint-disable-next-line react/no-array-index-key
        <Radio.Button key={i} value={option}>
          {option}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export const OTPInput: React.FC<OTPInputProps> = (props) => {
  const {
    required = true,
    label,
    prefix,
    placeholder = 'Enter OTP',
    id,
    disabled = false,
    value,
    maxLength = 6,
    rule = 'number',
    defaultValue = '',
    onSuffixClick,
    color,
  } = props;
  const Suffix = () => {
    return (
      <StyledResend onClick={onSuffixClick} color={color}>
        Resend OTP
      </StyledResend>
    );
  };

  return (
    <InputField
      {...props}
      rules={customRules[rule]({ required, label: label || placeholder })}
    >
      <Input
        prefix={prefix}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        maxLength={maxLength}
        size={isDesktop() ? 'large' : 'middle'}
        suffix={<Suffix />}
        inputMode="numeric"
        pattern="[0-9]*"
      />
    </InputField>
  );
};

export const AutoCompleteInputBox: React.FC<AutoCompleteBoxProps> = (props) => {
  const {
    placeholder,
    options = [],
    handleChange,
    handleSelect,
    defaultValue,
    onBlur,
    value,
    id,
  } = props;
  return (
    <AutoComplete
      options={options}
      size={isDesktop() ? 'large' : 'middle'}
      onSelect={handleSelect}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onBlur={onBlur}
      id={id}
    />
  );
};

const StyledDropdownWrapper = styled.div`
  width: 100%;

  span {
    font-size: ${FONT[16]};
  }
`;

const StyledInputField = styled(InputField)`
  width: 100%;

  span {
    font-size: ${FONT[16]};
  }
`;

const StyledSearchInputBoxDiv = styled.div`
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: 0 0 0 auto;

  svg {
    font-size: ${FONT[24]};
    fill: ${COLORS.GRAY};
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translate(0, -50%);
  }

  input {
    color: ${COLORS.CHINESE_BLACK};
    border-radius: 3px;

    @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
      height: 38px;
    }
  }
`;

const StyledResend = styled.div`
  ${mediumFontMixin}
  font-size: 10px;
  color: ${(props) => props.color};
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;
