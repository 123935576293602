import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { StyledContainer } from './LandingCommon';

const HeaderContainer: React.FC<any> = ({ children }) => {
  return <StyledHeaderContainer>{children}</StyledHeaderContainer>;
};

export default HeaderContainer;

const StyledHeaderContainer = styled(StyledContainer)`
  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 100%;
  }
`;
