import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { ACCEPTED_COVER_IMAGE, IMAGE_COVER_PAYLOAD } from '../constants/file';
import { getProfileDataWithoutReload } from '../store/features/profile';
import {
  ORGANIZER,
  RECRUITER,
  ADD,
  APIs,
  PARTTIME_TEXT,
  FULLTIME_TEXT,
  INTERNSHIP_TEXT,
  CONTRACT_TEXT,
  STATUS_DELETE,
  STATUS_ACTIVE,
  EDIT,
  CANDIDATE,
  STATUS_DRAFT,
  SkillLevelToTimelineMap,
} from '../constants';
import {
  uploadProfilePic,
  uploadCompanyLogo,
  uploadResume,
  removePicture,
  uploadCertificates,
  uploadMemberProfilePic,
  uploadCompanyCover,
  removeCompanyCover,
} from '../service/auth';
import Config from '../config';
import {
  RemovePictureNotification,
  UploadPictureNotification,
} from '../components/Notification/UploadPictureNotification';
import Notification from '../components/Notification/Notification';
import { CandidateExp } from './candidateExperience';
import { imageValidator } from './common';
import { getDefaultPaymentCurrency } from './currencyConvertor';
import { checkIfUserIsOrganizer } from './userInfo';

const UserProfileStore = () => {
  const { isLoading, profileData, error } = useSelector(
    (state) => state.profile,
  );
  return { isLoading, profileData, error };
};

const onBoarding = (profileData) => {
  if (isEmpty(profileData)) return false;
  return !!profileData.country;
};

export const onBoardingCandidate = (profileData) => {
  if (isEmpty(profileData)) return false;
  return !!profileData.resume;
};

const getSuiteValue = (companyData) => {
  const suiteValue = get(companyData, 'suite', 0);
  return suiteValue === null ? '0' : suiteValue?.toString();
};

const CrewMemberStore = () => {
  const { isLoading_Crew, members, error_Crew } = useSelector(
    (state) => state.crew,
  );
  return { isLoading_Crew, members, error_Crew };
};

const countryCodeOnlySplit = (countryCode) => {
  return countryCode ? countryCode.split(' ')[0] : '';
};

const errorField = (name) => {
  return {
    name,
    errors: [''],
  };
};

const userCompanyProfileResetFields = (form) => {
  form.resetFields();
  form.setFieldsValue({
    phoneNumberPrefix: '',
    phoneNumber: '',
    industryType: [],
    companyWebsite: '',
    description: '',
    address: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
    suiteNumber: '',
    profileLinks: [],
    // Note: 229 belongs to USD
    paymentCurrencyId: 229,
  });
  form.setFields([
    errorField('phoneNumberPrefix'),
    errorField('phoneNumber'),
    errorField('industryType'),
    errorField('companyWebsite'),
    errorField('description'),
    errorField('address'),
    errorField('zipcode'),
    errorField('city'),
    errorField('state'),
    errorField('country'),
    errorField('suiteNumber'),
  ]);
};

const userCompanyProfileInitialValues = (
  profileData,
  companyData,
  currencyData,
) => {
  // Converts string to array
  const convertToStrOfArr = (str) => {
    if (str) {
      return str.split(',');
    }
  };

  return {
    companyName: companyData.name,
    phoneNumberPrefix: companyData.phone ? companyData.phone.split(' ')[0] : '',
    phoneNumber: companyData.phone ? companyData.phone.split(' ')[1] : '',
    companyEmail: profileData.representativeEmail || profileData.email,
    // industryType: profileData.domains ? profileData.domains[0].name : null,
    industryType: convertToStrOfArr(companyData.domain),
    companyWebsite: companyData.website,
    description: companyData.brief,
    communityOutreachMessage: companyData.communityOutreachMessage,
    address: companyData.address,
    zipcode: companyData.zip,
    city: companyData.city,
    state: companyData.state,
    country: companyData.country,
    suiteNumber: companyData.suite || 0,
    profileLinks: get(companyData, 'profileLinks.links', []),
    paymentCurrencyId: profileData.paymentCurrency
      ? profileData.paymentCurrency.id
      : get(getDefaultPaymentCurrency(currencyData), 'id', null),
  };
};

const profileLinksPayload = (profileLinks) => {
  return profileLinks
    ? profileLinks.map((el, id) => {
        return {
          ...el,
          id,
        };
      })
    : [];
};

const getPhoneNumber = (profileData) => {
  return (
    profileData.representativePhone ||
    get(profileData, 'organizer.representativePhone', null) ||
    get(profileData, 'phone', null) ||
    '-'
  );
};

const userCompanyProfilePayload = (roleId, data, profileData, currencyData) => {
  const {
    companyName,
    phoneNumberPrefix,
    phoneNumber,
    companyEmail,
    industryType,
    companyWebsite,
    description,
    address,
    zipcode,
    city,
    state,
    country,
    suiteNumber,
    profileLinks,
    paymentCurrencyId,
  } = data;

  if (roleId === ORGANIZER) {
    return {
      roleId,
      company: {
        name: companyName,
        brief: description,
        ...(checkIfUserIsOrganizer(roleId)
          ? {
              communityOutreachMessage: data.communityOutreachMessage,
            }
          : {}),
        phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
        address,
        city,
        state,
        country,
        zip: zipcode,
        suite: suiteNumber || 0,
        domain: industryType.toString(),
        profileLinks: profileLinksPayload(profileLinks),
        website: companyWebsite,
      },
      representativeName:
        profileData.representativeName ||
        get(
          profileData,
          'organizer.representativeName',
          get(profileData, 'name', ''),
        ),
      representativePhone: getPhoneNumber(profileData),
      representativeEmail: companyEmail,
      profileLinks: profileLinksPayload(profileLinks),
      domains: [{ name: industryType.toString(), operation: ADD }],
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      city,
      state,
      country,
      zip: zipcode,
      paymentCurrencyId:
        paymentCurrencyId ||
        get(getDefaultPaymentCurrency(currencyData), 'id', null),
    };
  }
  if (roleId === RECRUITER) {
    return {
      roleId,
      company: {
        name: companyName,
        brief: description,
        phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
        address,
        city,
        state,
        country,
        zip: zipcode,
        suite: suiteNumber || 0,
        domain: industryType.toString(),
        profileLinks: profileLinksPayload(profileLinks),
        website: companyWebsite,
      },
      representativeName:
        profileData.representativeName ||
        get(
          profileData,
          'recruiter.representativeName',
          get(profileData, 'name', ''),
        ),
      representativePhone: getPhoneNumber(profileData),
      representativeEmail: companyEmail,
      domains: [{ name: industryType.toString(), operation: ADD }],
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      city,
      state,
      country,
      zip: zipcode,
      paymentCurrencyId:
        paymentCurrencyId ||
        get(getDefaultPaymentCurrency(currencyData), 'id', null),
    };
  }
};

const getProfilePic = (fileName) => {
  return fileName ? `${Config.baseURL}${APIs.index_file}${fileName}/` : null;
};

const updateProfilePic = async (
  file,
  roleId = null,
  userId,
  userRole = CANDIDATE,
) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await uploadProfilePic(formData, roleId, userId, userRole);
  return get(res, 'data.payload.filename', null);
};

const getCompanyLogo = (fileName) => {
  return fileName ? `${Config.baseURL}${APIs.index_file}${fileName}/` : null;
};

const updateCompanyLogo = async (file, roleId, companyId) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await uploadCompanyLogo(formData, roleId, companyId);
  return res.data.payload.filename;
};

const deletePicture = async (
  fileName,
  type,
  role,
  companyId,
  userRole,
  userId,
) => {
  const body = { file: fileName, type };
  const res = await removePicture(body, role, companyId, userRole, userId);
  if (res && res.status && res.status === 200) {
    RemovePictureNotification();
    return true;
  }
};

const userRepProfileResetFields = (form) => {
  form.resetFields();
  form.setFieldsValue({
    fullName: '',
    phoneNumberPrefix: '',
    phoneNumber: '',
  });
  form.setFields([
    errorField('fullName'),
    errorField('phoneNumberPrefix'),
    errorField('phoneNumber'),
  ]);
};

const userProfileInitialValues = (profileData, isAdmin) => {
  if (isAdmin) {
    return {
      fullName: get(
        profileData,
        'representativeName',
        get(profileData, 'name', ''),
      ),
      phoneNumberPrefix: profileData.representativePhone
        ? profileData.representativePhone.split(' ')[0]
        : '',
      phoneNumber: profileData.representativePhone
        ? profileData.representativePhone.split(' ')[1]
        : '',
      email: profileData.representativeEmail,
    };
  }
  return {
    fullName: profileData.name,
    phoneNumberPrefix: profileData.phone ? profileData.phone.split(' ')[0] : '',
    phoneNumber: profileData.phone ? profileData.phone.split(' ')[1] : '',
    email: profileData.email,
  };
};

const userRepProfilePayload = (roleId, data, profileData, companyData) => {
  const { fullName, phoneNumberPrefix, phoneNumber, email } = data;

  if (roleId === ORGANIZER) {
    return {
      roleId,
      company: {
        name: companyData.name,
        brief: companyData.brief,
        phone: companyData.phone,
        address: companyData.address,
        city: companyData.city,
        state: companyData.state,
        country: companyData.country,
        zip: companyData.zip,
        suite: getSuiteValue(companyData),
        profileLinks: get(companyData, 'profileLinks.links', []),
        website: companyData.website,
        domain: companyData.domain,
      },
      representativeName: fullName,
      representativePhone: `${countryCodeOnlySplit(
        phoneNumberPrefix,
      )} ${phoneNumber}`,
      representativeEmail: email,
      profileLinks: get(companyData, 'profileLinks.links', []),
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      city: profileData.city,
      state: profileData.state,
      country: profileData.country,
      zip: profileData.zip,
    };
  }
  if (roleId === RECRUITER) {
    const body = {
      roleId,
      company: {
        name: companyData.name,
        brief: companyData.brief,
        phone: companyData.phone,
        address: companyData.address,
        city: companyData.city,
        state: companyData.state,
        country: companyData.country,
        zip: companyData.zip,
        suite: getSuiteValue(companyData),
        profileLinks: get(companyData, 'profileLinks.links', []),
        website: companyData.website,
        domain: companyData.domain,
      },
      representativeName: fullName,
      representativePhone: `${countryCodeOnlySplit(
        phoneNumberPrefix,
      )} ${phoneNumber}`,
      representativeEmail: email,
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      city: profileData.city,
      state: profileData.state,
      country: profileData.country,
      zip: profileData.zip,
    };
    // eslint-disable-next-line no-unused-expressions
    body.representativePhone?.trim() === "" && delete body.representativePhone
    return body
  }
};

const userCrewProfilePayload = (roleId, data, profileData, companyData) => {
  const { fullName, phoneNumberPrefix, phoneNumber, email } = data;

  if (roleId === ORGANIZER) {
    return {
      roleId,
      company: {
        name: companyData.name,
        brief: companyData.brief,
        phone: companyData.phone,
        address: companyData.address,
        city: companyData.city,
        state: companyData.state,
        country: companyData.country,
        zip: companyData.zip,
        suite: getSuiteValue(companyData),
        profileLinks: get(companyData, 'profileLinks.links', []),
      },
      name: fullName,
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      email,
      representativeName: profileData.representativeName,
      representativePhone: profileData.representativePhone,
      representativeEmail: profileData.email,
      profileLinks: get(companyData, 'profileLinks.links', []),
      city: profileData.city,
      state: profileData.state,
      country: profileData.country,
      zip: profileData.zip,
    };
  }
  if (roleId === RECRUITER) {
    return {
      roleId,
      company: {
        name: companyData.name,
        brief: companyData.brief,
        phone: companyData.phone,
        address: companyData.address,
        city: companyData.city,
        state: companyData.state,
        country: companyData.country,
        zip: companyData.zip,
        suite: getSuiteValue(companyData),
        profileLinks: get(companyData, 'profileLinks.links', []),
      },
      name: fullName,
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      email,
      representativeName: profileData.representativeName,
      representativePhone: profileData.representativePhone,
      representativeEmail: profileData.email,
      city: profileData.city,
      state: profileData.state,
      country: profileData.country,
      zip: profileData.zip,
    };
  }
};

const userProfileResetFields = (form) => {
  form.resetFields();
  form.setFieldsValue({
    fullName: '',
    phoneNumberPrefix: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    nationality: '',
    years: '',
    months: '',
    startAvailability: '',
    expectedCtcCurrency: '',
    expectedCtc: '',
    willingToRelocate: '',
    currentSalary: '',
  });
  form.setFields([
    errorField('fullName'),
    errorField('phoneNumberPrefix'),
    errorField('phoneNumber'),
    errorField('address'),
    errorField('city'),
    errorField('state'),
    errorField('country'),
    errorField('zipcode'),
    errorField('nationality'),
    errorField('years'),
    errorField('months'),
    errorField('startAvailability'),
    errorField('expectedCtcCurrency'),
    errorField('expectedCtc'),
    errorField('willingToRelocate'),
    errorField('currentSalary'),
  ]);
};

const candidateSkillLevel = (level) => {
  switch (level) {
    case 'L1':
      return 0;
    case 'L2':
      return 1;
    case 'L3':
      return 2;
    case 'L4':
      return 3;
    default:
      return 0;
  }
};

const candidateSkillLevelText = (level) => {
  return `${SkillLevelToTimelineMap[level]}`;
};

const candidateSkillLevelConvert = (level) => {
  switch (level) {
    case 0:
      return 'L1';
    case 1:
      return 'L2';
    case 2:
      return 'L3';
    case 3:
      return 'L4';
    default:
      return 'L1';
  }
};
const candidateSkillPreference = (level) => {
  switch (level) {
    case STATUS_DELETE:
      return 'Preffered';
    case STATUS_ACTIVE:
      return 'Mandatory';
    default:
      return 'Preffered';
  }
};

const getResume = (fileName) => {
  return fileName ? `${Config.baseURL}${APIs.index_file}${fileName}/` : null;
};

const updateResume = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await uploadResume(formData);
  return get(res, 'data.payload.filename', '');
};

export const updateResumeDrive = async (fileObj, blob) => {
  if (blob) {
    const file = new File([blob], fileObj.name);
    const formData = new FormData();
    formData.append('file', file);
    const res = await uploadResume(formData);
    return get(res, 'data.payload.filename', '');
  }
};

const getCertificates = (fileName) => {
  return fileName ? `${Config.baseURL}${APIs.index_file}${fileName}/` : null;
};

const updateCertificates = async (certificationsList) => {
  const formData = new FormData();

  certificationsList.forEach((file) => {
    const temp = file.certificateName ? file.certificateName : file.name;
    formData.append('fileName', file.fileName);
    formData.append('certificateName', temp);
    formData.append('id', file.uid);
  });
  const res = await uploadCertificates(formData);
  return !!(res.data && res.data.payload);
};

const convertEmployementType = (type) => {
  switch (type) {
    case 1:
      return FULLTIME_TEXT;
    case 2:
      return PARTTIME_TEXT;
    case 3:
      return INTERNSHIP_TEXT;
    case 4:
      return CONTRACT_TEXT;
    default:
      return null;
  }
};
const userProfileStore = UserProfileStore;
const crewMemberStore = CrewMemberStore;

const getValueWithinParentheses = (value) => {
  const exp = /\(([^)]+)\)/;
  const matchedValue = exp.exec(value);
  return matchedValue ? matchedValue[1] : value;
};

const updateMemberProfilePic = async (file, id) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('userId', id);
  const res = await uploadMemberProfilePic(formData);
  return res.data.payload.filename;
};

const CandidateProfileStore = () => {
  const { isLoading, profileData, error } = useSelector(
    (state) => state.candidateProfile,
  );
  return { isLoading, profileData, error };
};

const candidateProfileStore = CandidateProfileStore;

/**
 * Check admin
 * @param {object} profileData
 * @returns {boolean}
 */
const isAdmin = (profileData) => {
  if (profileData) {
    return profileData.email === profileData.representativeEmail;
  }
  return false;
};

export {
  userProfileStore,
  crewMemberStore,
  userCompanyProfileInitialValues,
  userCompanyProfilePayload,
  getProfilePic,
  updateProfilePic,
  getCompanyLogo,
  updateCompanyLogo,
  userProfileInitialValues,
  userRepProfilePayload,
  candidateSkillLevel,
  getResume,
  updateResume,
  getCertificates,
  updateCertificates,
  candidateSkillLevelConvert,
  candidateSkillPreference,
  onBoarding,
  candidateSkillLevelText,
  countryCodeOnlySplit,
  deletePicture,
  userCompanyProfileResetFields,
  userRepProfileResetFields,
  userProfileResetFields,
  profileLinksPayload,
  convertEmployementType,
  getValueWithinParentheses,
  userCrewProfilePayload,
  updateMemberProfilePic,
  candidateProfileStore,
  isAdmin,
};

export const deleteFile = async (fileName) => {
  const body = { file: fileName };
  const res = await removePicture(body);
  if (res && res.status && res.status === 200) {
    Notification('success', 'File deleted');
    return true;
  }
};

export const getCandidateProfileCompletionPercentage = (profileData) => {
  if (!profileData) {
    return 0;
  }

  let score = 0;
  const requiredFields = [
    'phone',
    'email',
    'country',
    'zip',
    'nationality',
    'resume',
    'startAvailability',
    'expectedCtcCurrency',
    'currentCtcCurrency',
    'expectedCtc',
    'willingToRelocate',
    'annualCompensation',
    'workExperience',
    'links',
    'domains',
    'employmentType',
    'certifications',
    'userSkills',
  ];

  for (const requiredField of requiredFields) {
    const phone = get(profileData, 'phone', ' ');
    switch (requiredField) {
      case 'phone':
        const [countryCode, phoneNumber] = (phone || ' ').split(' ');
        if (countryCode && phoneNumber) score++;
        break;
      case 'links':
        const links = get(profileData, 'profileLinks.links', []);
        if (links && isArray(links) && links.length) score++;
        break;
      case 'employmentType':
        const employmentType = get(profileData, 'employmentType.type', []);
        if (employmentType && isArray(employmentType) && employmentType.length)
          score++;
        break;
      case 'domains':
      case 'certifications':
      case 'userSkills':
        const field = get(profileData, requiredField, []);
        if (field && isArray(field) && field.length) score++;
        break;
      case 'workExperience':
        const { candidateExpinYr, candidateExpinMon } = CandidateExp(
          profileData,
        );
        if (candidateExpinYr || candidateExpinMon) score++;
        break;
      case 'willingToRelocate':
        const value = get(profileData, requiredField, null);
        if (value === 1 || value === 2) score++;
        break;
      default:
        const requiredFieldValue = get(profileData, requiredField, null);
        if (requiredFieldValue) score++;
        break;
    }
  }

  const percentage = parseInt((score / requiredFields.length) * 100);

  return percentage;
};

export const updateCompanyCover = async (file, role, companyId) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await uploadCompanyCover(formData, role, companyId);
  return get(res, 'data.payload.filename', '');
};

export const handleCoverBeforeUpload = (file) => {
  if (file.size > ACCEPTED_COVER_IMAGE.maxFileSize) {
    Notification(
      'error',
      'Cover image',
      'Image doesn’t match the requirements. Please select another image.',
    );
    return false;
  }
  return imageValidator({ ...IMAGE_COVER_PAYLOAD, file }).catch(() => false);
};

export const updateCompanyCoverCustomReq = (
  onSuccess,
  onError,
  file,
  setCompanyCover,
  role = null,
  companyId = null,
) => {
  // Validation
  imageValidator({ ...IMAGE_COVER_PAYLOAD, file }).then(() => {
    setCompanyCover([file]);
    updateCompanyCover(file, role, companyId)
      .then((fileName) => {
        onSuccess();
      })
      .catch((err) => {
        onError();
      });
  });
};

export const companyCoverOnChange = async (
  info,
  setCompanyCover,
  role = null,
  companyId = null,
) => {
  const { file } = info;
  if (file.status === 'removed') {
    Notification('info', 'Cover', 'Company cover image Delete in process');
    await removeCompanyCover(role, companyId).then(async () => {
      setCompanyCover([]);
      Notification(
        'success',
        'Cover',
        'Company cover image successfully removed',
      );
      await getProfileDataWithoutReload();
    });
  }
  UploadPictureNotification(info);
};

export const getEditDomainsData = (domains = []) => {
  return domains.map((domain) => ({
    name: domain.name,
    operation: EDIT,
    id: domain.id,
  }));
};

export const getFilePath = (fileName) => {
  return fileName ? `${Config.baseURL}${APIs.index_file}${fileName}/` : null;
};

/**
 * Exracts & returns company status from passed object ( profileData )
 * @param {object} profileData
 * @returns {number}
 */
export const getCompanyStatus = (data) => {
  return get(data, 'company.status', STATUS_DRAFT);
};
