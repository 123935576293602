import React, { useState } from 'react';
import styled from 'styled-components';
import LinkedInIcon from '../../assets/svg/linkedin.svg';
import { poll } from '../../service/polling';
import useRecursiveTimeout from '../../utils/useRecursiveTimeout';
import { APIs } from '../../constants';
import Config from '../../config';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { openWindow } from './window';

const LINKEDIN_CLIENT_ID = Config.linkedin_client_id;
const LINKEDIN_REDIRECT_URI = `${Config.baseURL}${APIs.linkedin_callback}`;

const LinkedinLogin = ({ setOauthLogin }) => {
  const [timer, setTimer] = useState(30);
  const [nonce, setNonce] = useState(0);
  const [popup, setPopup] = useState();
  const [startPolling, setStartPolling] = useState(false);
  const [stopPolling, setStopPolling] = useState(false);

  useRecursiveTimeout(async () => {
    if (startPolling && !stopPolling && timer > 0) {
      const response = await poll({ nonce });
      if (response) {
        if (response.payload.hasOwnProperty('success')) {
          setStopPolling(true);
          setTimer(0);
          setOauthLogin({ provider: 'linkedin', token: nonce.toString() });

          if (popup) {
            popup.close();
          }
        } else {
          const existingTimer = timer;
          setTimer(existingTimer - 3);
        }
      } else {
        const existingTimer = timer;
        setTimer(existingTimer - 3);
      }
    }
  }, 3000);

  const requestLinkedInAuth = async () => {
    const client_id = LINKEDIN_CLIENT_ID;
    const state = Math.floor(Math.random() * 100000);
    setNonce(state);
    const redirect_uri = LINKEDIN_REDIRECT_URI;
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&scope=r_emailaddress&state=${state}&redirect_uri=${redirect_uri}`;
    const width = 650;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const popup = openWindow({
      url: oauthUrl,
      name: 'Log in with Linkedin',
      options: `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    });
    setPopup(popup);
    setStartPolling(true);
  };

  return (
    <StyledButtonContainer
      onClick={() => {
        requestLinkedInAuth();
      }}
    >
      <StyledLinkedInIcon src={LinkedInIcon} alt="" />
    </StyledButtonContainer>
  );
};

export default LinkedinLogin;

const StyledLinkedInIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledButtonContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  border-radius: 6px;
  cursor: pointer;
`;
