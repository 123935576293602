/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Row, Col, Skeleton } from 'antd';
import isArray from 'lodash/isArray';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import { toNumber } from 'lodash';
import { FormGroup } from '../../../components/shared-components/Form/Form';
import BasicInformation from '../../../components/Events/EventForm/BasicInformation';
import { TabSwitch } from '../../../components/shared-components/Tabs';
import { getThemeByRoleId } from '../../../utils/common';
import {
  makeCreateEventData,
  findAndRemoveFile,
  hasUniqueProfileLinks,
  makeCreateEventInitialData,
} from '../../../utils/event';
import {
  UploadPictureNotification,
  UploadDocumentNotification,
} from '../../../components/Notification/UploadPictureNotification';
import { createEventNotification } from '../../../components/Notification/Event';
import Notification from '../../../components/Notification/Notification';
import { getCategoriesForDomain, uploadFile } from '../../../service/index';
import { createEventData } from '../../../store/features/events';
import { OrganizerRoutes } from '../../../constants/routes';
import {
  BOOKING_METHOD,
  CHATROOM_TYPE_GROUP,
  isPending,
} from '../../../constants';
import OrganizerCreateEventBreadCrumbs from '../../../components/Common/BreadCrumbs/OrganizerCreateEventBreadCrumbs';
import { createChatroom } from '../../../service/chat';
import config from '../../../config';
import { userInfoRegardingChatroom } from '../../../utils/chat';
import ThirdPartyPayment from '../../../components/Events/EventForm/ThirdPartyPayment';
import { getTextFromHTML } from '../../../utils/common';
import { getRoleId } from '../../../utils/userInfo';
import { PrimaryButton } from '../../../components/shared-components/Button';
import PageCircle from '../../../components/shared-components/PageCircle';
import { getUserColorWithRoleId } from '../../../styles/utils';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { SHADOWS } from '../../../styles/constants/shadows';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import PricingAndPromoContainer from '../../../components/Events/EventForm/PricingAndPromoContainer';
import { useTagManager } from '../../../utils/customHooks';
import { getEventsAutoFillData, poapEvent } from '../../../service/organizer';
import { getFileS3 } from '../../../utils/s3.storage';
import Speakers from '../../../components/Events/EventForm/SpeakersAndHost';
import SponsorsAndPartners from '../../../components/Events/EventForm/SponsorsAndPartners';
/**
 * TODO: Refund component might be needed in future
 * Please also handle error when make api request
 */
const Events = ({ isForTest }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [speaker, setSpeakers] = useState([]);
  // const [sponsor, setSponsor] = useState([]);
  // const [partner, setPartner] = useState([]);
  //  const [host, setHost] = useState([]);
  const { status } = useSelector((state) => state.events);
  const history = useHistory();
  const roleId = getRoleId();
  const updateUserActionInGTM = useTagManager();
  const [autoFillData, setAutoFillData] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [poapImage, setPoapImage] = useState(null);
  const [bookingMethod, setBookingMethod] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [coverError, setCoverError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [customInput, setCustomInput] = React.useState({
    cover: '',
    termsAndConditions: [],
    companyLogos: [],
    speakers: [],
    sponsors: [],
    partners: [],
    hosts: [],
  });
  const [companyInfo, setCompanyInfo] = React.useState({
    name: '',
    filename: '',
  });
  const [speakerInfo, setSpeakerInfo] = React.useState({
    name: '',
    pic: '',
    designation: '',
    brief: '',
    links: [],
  });
  const [sponsorsInfo, setSponsorInfo] = React.useState({
    name: '',
    pic: '',
    type: null,
    brief: '',
    links: [],
  });
  const [partnersInfo, setPartnerInfo] = React.useState({
    name: '',
    pic: '',
    type: null,
    brief: '',
    links: [],
  });
  const [hostInfo, setHostInfo] = React.useState({
    name: '',
    pic: '',
    designation: '',
    links: [],
  });
  const [days, setDays] = useState([]);
  const [daysSchedule, setDaysSchedule] = useState({});
  const [description, setDescription] = useState('');
  const [descriptionCount, setDescriptionCount] = React.useState(0);
  const [eventSkills, setEventSkills] = React.useState({
    technical: '',
    management: '',
    technoFunctional: '',
  });
  const [categories, setCategories] = useState([]);
  const [domains, setDomains] = useState([]);
  const userData = userInfoRegardingChatroom();
  const [coverFile, setCoverFile] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [documentFileList, setDocumentFileList] = React.useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [isWeb3Event, setIsWeb3Event] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const handleSelectCategories = (options) => {
    setCategories(options);
  };

  useEffect(() => {
    if (!isForTest) {
      (async () => {
        setIsLoading(true);
        const response = await getEventsAutoFillData();
        if (response.zip === " ") {
          response.zip = '';
        }
        const {
          categories: categoriesList,
          technical,
          management,
          technoFunctional,
          termsAndConditions,
          industries,
        } = response;
        if (categoriesList && categoriesList.length) {
          setCategories(
            uniq(categoriesList.map((value) => get(value, 'id', ''))),
          );
        }

        if (industries && industries.length) {
          setDomains(industries);
        }

        setEventSkills({ technical, management, technoFunctional });
        const temp = termsAndConditions.map((doc) => {
          return { filename: doc };
        });
        setCustomInput((currentState) => ({
          ...currentState,
          termsAndConditions: temp,
        }));

        const docFiles = termsAndConditions.map((doc) => {
          return {
            name: doc,
            type: 'application/pdf',
            url: getFileS3(doc),
            uid: doc,
          };
        });

        setDocumentFileList(docFiles);
        setAutoFillData(response);
        setIsLoading(false);
        setTimeout(() => {
          setIsActive(false);
        }, 800);
      })();
    }

    // eslint-disable-next-line
  }, []);

  const handleSelectDomains = (options) => {
    const newSelection = first(
      options.filter((name) => !domains.find((item) => item === name)),
    );
    if (newSelection) {
      getCategoriesForDomain(newSelection).then((categoriesList) => {
        const newCategories = categoriesList
          .map((item) => item.id)
          .filter((category) => !categories.find((id) => id === category.id));
        if (newCategories.length) {
          let temp = [...categories, ...newCategories];
          temp = uniq(temp);
          form.setFieldsValue({ categories: temp });
          setCategories(temp);
        }
      });
    }
    setDomains([]);
    setDomains(options);
  };

  const handleNotification = (error, id) => {
    if (error) {
      return createEventNotification({
        name: form.getFieldValue('nameValue'),
        status: 'error',
        error,
      });
    }
  };

  const handleEventSubmit = async () => {
    const formFieldsData = await form.getFieldsValue();
    const {
      cover,
      companyLogos,
      termsAndConditions,
      speakers,
      sponsors,
      partners,
      hosts,
    } = customInput;
    formFieldsData.cover = cover;
    // Get companyLogos with limited properties

    if (!cover) {
      setActiveTab(1);
      setCoverError(true);
      if (descriptionCount === 0) {
        setDescriptionError(true);
        Notification(
          'error',
          'Validation failed',
          "About Event can't be empty",
        );
      }
      return Notification(
        'error',
        'Validation Failed',
        'Please Upload Cover for the Event.',
      );
    } else {
      setCoverError(false);
    }

    if (
      !termsAndConditions ||
      (isArray(termsAndConditions) && termsAndConditions.length === 0)
    ) {
      setActiveTab(1);
      setDocumentError(true);
      return Notification(
        'error',
        'Validation Failed',
        `Please Upload the Event's Terms & Policy PDF.`,
      );
    } else {
      setDocumentError(false);
    }

    if (descriptionCount === 0) {
      setActiveTab(1);
      setDescriptionError(true);
      return Notification(
        'error',
        'Validation failed',
        "About Event can't be empty",
      );
    } else {
      setDescriptionError(false);
    }

    formFieldsData.description = description;
    formFieldsData.name = formFieldsData.nameValue;
    formFieldsData.companyLogos = companyLogos.map((el) => ({
      name: el.name,
      filename: el.filename,
    }));
    formFieldsData.termsAndConditions = termsAndConditions.map(
      (doc) => doc.filename,
    );
    formFieldsData.technical = eventSkills.technical;
    formFieldsData.management = eventSkills.management;
    formFieldsData.technoFunctional = eventSkills.technoFunctional;
    formFieldsData.speakers = speakers;
    formFieldsData.sponsors = sponsors;
    formFieldsData.partners = partners;
    formFieldsData.hosts = hosts;
    formFieldsData.schedules = daysSchedule;
    formFieldsData.isWeb3Event = isWeb3Event;
    formFieldsData.isPrivate = isPrivate;

    const validationResult = await hasUniqueProfileLinks(formFieldsData);

    if (validationResult) {
      return Notification(
        'error',
        'Validation failed',
        'Multiple duplicate company profile links',
      );
    }

    const result = makeCreateEventData(formFieldsData);

    // Dispatch createEventData
    dispatch(createEventData(result, handleNotification)).then(
      async (eventData) => {
        // api call to make the poap event.
        if (poapImage) {
          const formData = new FormData();
          formData.append('file', poapImage);
          formData.append('eventId', get(eventData, 'eventId'));
          formData.append('isEndDateTimeUpdated', false);
          await poapEvent(formData);
        }
        createEventNotification({
          name: form.getFieldValue('nameValue'),
          status: 'done',
        });
        // Reset the form
        form.resetFields();
        // Redirect user
        history.push(OrganizerRoutes.viewEvent(get(eventData, 'eventId')));
        // dispatch(getOrganizerEventsDataWithoutRelo());
        updateUserActionInGTM('event_created');
        if (config.chatEnabled) {
          await createChatroom(
            eventData,
            [userData],
            CHATROOM_TYPE_GROUP,
            false,
          );
        }
      },
    );
  };

  /**
   * Uploads file
   * @param {obj} object
   * @param {str} type, expects type to compare upload file type
   * @param {str} id, expects id to change company logo
   */
  const uploadEventFile = async ({ onSuccess, onError, file }, type, id) => {
    if (type === 'cover') {
      setCoverFile([file]);
      uploadFile(file, 'image')
        .then((filename) => {
          setCustomInput((currentState) => ({
            ...currentState,
            cover: filename,
          }));
          onSuccess();
          setCoverError(false);
        })
        .catch(() => onError());
    } else if (type === 'poap') {
      setPoapImage(file);
      const img = document.getElementById('poap-image');
      if (img) {
        const reader = new FileReader();
        reader.onloadend = () => {
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    } else if (type === 'logo') {
      uploadFile(file)
        .then((filename) => {
          // Update the logo if id available
          if (id >= 0) {
            const clone = [...customInput.companyLogos];
            const item = { ...clone[id], filename };
            clone.splice(id, 1, item);
            setCustomInput((currentState) => ({
              ...currentState,
              companyLogos: clone,
            }));
          } else {
            // Just set company's file
            setCompanyInfo((prevState) => ({ ...prevState, filename }));
          }
          onSuccess();
        })
        .catch(() => onError());
    } else if (type === 'speaker') {
      // setSpeakers([file]);
      uploadFile(file)
        .then((filename) => {
          // Update the logo if id available
          if (id >= 0) {
            const clone = [...customInput.speakers];
            const item = { ...clone[id], pic: filename };
            clone.splice(id, 1, item);
            setCustomInput((currentState) => ({
              ...currentState,
              speakers: clone,
            }));
          } else {
            // Just set company's file
            setSpeakerInfo((prevState) => ({ ...prevState, pic: filename }));
          }
          onSuccess();
        })
        .catch(() => onError());
    } else if (type === 'sponsor') {
      // setSponsor([file]);
      uploadFile(file)
        .then((filename) => {
          // Update the logo if id available
          if (id >= 0) {
            const clone = [...customInput.sponsors];
            const item = { ...clone[id], pic: filename };
            clone.splice(id, 1, item);
            setCustomInput((currentState) => ({
              ...currentState,
              sponsors: clone,
            }));
          } else {
            // Just set company's file
            setSponsorInfo((prevState) => ({ ...prevState, pic: filename }));
          }
          onSuccess();
        })
        .catch(() => onError());
    } else if (type === 'partner') {
      // setPartner([file]);
      uploadFile(file)
        .then((filename) => {
          // Update the logo if id available
          if (id >= 0) {
            const clone = [...customInput.partners];
            const item = { ...clone[id], pic: filename };
            clone.splice(id, 1, item);
            setCustomInput((currentState) => ({
              ...currentState,
              partners: clone,
            }));
          } else {
            setPartnerInfo((prevState) => ({ ...prevState, pic: filename }));
          }
          onSuccess();
        })
        .catch(() => onError());
    } else if (type === 'host') {
      // setHost([file]);
      uploadFile(file)
        .then((filename) => {
          // Update the logo if id available
          if (id >= 0) {
            const clone = [...customInput.hosts];
            const item = { ...clone[id], pic: filename };
            clone.splice(id, 1, item);
            setCustomInput((currentState) => ({
              ...currentState,
              hosts: clone,
            }));
          } else {
            setHostInfo((prevState) => ({ ...prevState, pic: filename }));
          }
          onSuccess();
        })
        .catch(() => onError());
    } else {
      uploadFile(file)
        .then((filename) => {
          setCustomInput((currentState) => {
            return {
              ...currentState,
              termsAndConditions: [
                ...currentState.termsAndConditions,
                { filename, id: file.lastModified },
              ],
            };
          });
          onSuccess();
          setDocumentError(false);
        })
        .catch(() => onError());
    }
  };

  const handleFileChange = (info, type) => {
    const { file } = info;
    if (['logo', 'cover'].includes(type)) {
      // Set cover file
      if (type === 'cover' && file.status === 'done') {
        setCoverFile([file]);
      }

      // Remove the event cover
      if (type === 'cover' && file.status === 'removed') {
        setCoverFile([]);
        setCustomInput((currentState) => ({ ...currentState, cover: '' }));
      } else if (type === 'poap' && file.status === 'removed') {
        setPoapImage(null);
        const img = document.getElementById('poap-image');
        if (img) {
          img.src = '';
        }
      }
      // Remove logo, companyInfo
      else if (type === 'logo' && file.status === 'removed') {
        setCompanyInfo((currentState) => ({ ...currentState, filename: '' }));
      } else if (type === 'speaker' && file.status === 'removed') {
        // setSpeakers([]);
        setCompanyInfo((currentState) => ({ ...currentState, pic: '' }));
      } else if (type === 'sponsor' && file.status === 'removed') {
        // setSponsor([]);
        setCompanyInfo((currentState) => ({ ...currentState, pic: '' }));
      } else if (type === 'partner' && file.status === 'removed') {
        //  setPartner([]);
        setCompanyInfo((currentState) => ({ ...currentState, pic: '' }));
      } else if (type === 'host' && file.status === 'removed') {
        // setHost([]);
        setCompanyInfo((currentState) => ({ ...currentState, pic: '' }));
      }
      UploadPictureNotification(info);
    } else {
      UploadDocumentNotification(info);
    }
  };

  const handleAddEntity = (type = 'sponsors', form = null, name) => {
    let entityObject = {};
    let entitiyKey = '';
    if (type === 'sponsors') {
      entityObject = {
        ...sponsorsInfo,
        links: form
          ? (form.getFieldValue(name) || []).map((item) =>
            get(item, `link`, undefined),
          )
          : sponsorsInfo.links,
      };
      entitiyKey = 'sponsors';
    } else {
      entityObject = {
        ...partnersInfo,
        links: form
          ? (form.getFieldValue(name) || []).map((item) =>
            get(item, `link`, undefined),
          )
          : partnersInfo.links,
      };
      entitiyKey = 'partners';
    }
    if (
      entityObject.name &&
      entityObject.pic &&
      entityObject.type &&
      entityObject.brief &&
      entityObject.links.length
    ) {
      const res = customInput[entitiyKey].find(
        (entity) => toLower(entity.name) === toLower(entityObject.name),
      );

      // Show notification and prevent user to enter duplicate company
      if (res) {
        Notification('error', `Add ${type}`, `${type} already exist`);
        return;
      }

      setCustomInput((currentState) => {
        return {
          ...currentState,
          [entitiyKey]: [...currentState[entitiyKey], { ...entityObject }],
        };
      });
      if (type === 'sponsors') {
        setSponsorInfo({ name: '', pic: '', links: [], type: null, brief: '' });
      } else {
        setPartnerInfo({ name: '', pic: '', links: [], type: null, brief: '' });
      }
      if (form) {
        form.resetFields();
      }
      //   setSponsor([]);
    }
  };

  const handleAddSpeakerAndHosts = (type = 'speakers') => {
    let entityObject = {};
    let entitiyKey = '';
    if (type === 'speakers') {
      entityObject = speakerInfo;
    } else {
      entityObject = hostInfo;
    }
    entitiyKey = type;
    if (
      entityObject.name &&
      entityObject.pic &&
      entityObject.designation &&
      entityObject.links.length
    ) {
      const res = customInput[entitiyKey].find(
        (entity) => toLower(entity.name) === toLower(entityObject.name),
      );

      // Show notification and prevent user to enter duplicate company
      if (res) {
        Notification('error', `Add ${type}`, `${type} already exist`);
        return;
      }

      setCustomInput((currentState) => {
        return {
          ...currentState,
          [entitiyKey]: [...currentState[entitiyKey], { ...entityObject }],
        };
      });

      if (type === 'speakers') {
        setSpeakerInfo({
          name: '',
          pic: '',
          links: [],
          designation: '',
          brief: '',
        });
      } else {
        setHostInfo({
          name: '',
          image: '',
          links: [],
          designation: '',
        });
      }
      //   setSponsor([]);
    }
  };

  const handleEditEntity = (entity, index, key, value) => {
    const clone = [...customInput[entity]];
    // Clone object to make it modifiable
    const currentEl = { ...clone[index] };
    currentEl[key] = value;
    clone.splice(index, 1, currentEl);

    setCustomInput((currentState) => {
      return {
        ...currentState,
        [entity]: clone,
      };
    });
  };

  const handleDeleteEntity = (index, entity) => {
    const clone = [...customInput[entity]];
    clone.splice(index, 1);

    setCustomInput((currentState) => {
      return {
        ...currentState,
        [entity]: clone,
      };
    });
  };

  const handleRemoveDocument = (data) => {
    const clone = findAndRemoveFile(
      customInput.termsAndConditions,
      data.lastModified,
    );
    setCustomInput((currentState) => {
      return {
        ...currentState,
        termsAndConditions: clone,
      };
    });
    const updatedList = findAndRemoveFile(documentFileList, data.uid);
    setDocumentFileList(updatedList);
  };

  const handleDescription = (value, delta, source, editor) => {
    const text = getTextFromHTML(value);
    const length = editor.getLength() - 1;
    setTimeout(() => setDescriptionCount(length));
    if (length <= 1000) {
      setDescription(value);
    } else {
      setDescription(text.slice(0, 1000));
    }
  };

  const onFailedFinish = (errors) => {
    const errorFields = errors.map((error) => get(error, 'name[0]', ''));
    setErrorFields(errorFields);
    const basicInfoFields = ['nameValue', 'dateTime', 'tags', 'domains', 'categories', 'zip'];
    if (basicInfoFields.some((val) => errorFields.includes(val))) {
      setActiveTab(1);
    }
  };

  // tabs Data
  const tabsData = [
    {
      key: 1,
      name: 'Basic Information',
      component: (
        <>
          <BasicInformation
            form={form}
            uploadEventFile={uploadEventFile}
            handleFileChange={handleFileChange}
            description={description}
            handleDescription={handleDescription}
            descriptionCount={descriptionCount}
            eventSkills={eventSkills}
            setEventSkills={setEventSkills}
            coverFile={coverFile}
            isForTest={isForTest}
            setBookingMethod={setBookingMethod}
            categories={categories}
            handleSelectCategories={handleSelectCategories}
            handleSelectDomains={handleSelectDomains}
            event={{ eventType: get(autoFillData, 'eventType', 0) }}
            isTabActive={isActive}
            errorFields={errorFields}
            setIsWeb3Event={setIsWeb3Event}
            isWeb3Event={isWeb3Event}
            poapImage={poapImage}
            setIsPrivate={setIsPrivate}
            isPrivate={isPrivate}
            coverError={coverError}
            documentError={documentError}
            descriptionError={descriptionError}
            //termsAndPolicy
            documentFileList={documentFileList}
            handleRemoveDocument={handleRemoveDocument}
            // schedules
            customInput={customInput}
            companyInfo={companyInfo}
            daysSchedule={daysSchedule}
            setDaysSchedule={setDaysSchedule}
            days={days}
            setDays={setDays}
            isEdit={false}
          />
        </>
      ),
    },
    {
      key: 2,
      name: 'Hosts & Speakers',
      component: (
        <>
          <Speakers
            type="Speakers"
            customInput={customInput}
            info={speakerInfo}
            uploadEventFile={uploadEventFile}
            handleFileChange={handleFileChange}
            handleAddCompany={handleAddSpeakerAndHosts}
            handleEditCompany={handleEditEntity}
            handleDeleteEntity={handleDeleteEntity}
            setInfo={setSpeakerInfo}
            isTabActive={isActive}
            errorFields={errorFields}
          />
          <Speakers
            type="Hosts"
            customInput={customInput}
            info={hostInfo}
            uploadEventFile={uploadEventFile}
            handleFileChange={handleFileChange}
            handleAddCompany={handleAddSpeakerAndHosts}
            handleEditCompany={handleEditEntity}
            handleDeleteEntity={handleDeleteEntity}
            setInfo={setHostInfo}
            isTabActive={isActive}
            errorFields={errorFields}
          />
        </>
      ),
    },
    {
      key: 3,
      name: 'Sponsors & Partners',
      component: (
        <>
          <SponsorsAndPartners
            type="Sponsors"
            customInput={customInput}
            companyInfo={sponsorsInfo}
            uploadEventFile={uploadEventFile}
            handleFileChange={handleFileChange}
            handleAddEntity={handleAddEntity}
            handleEditCompany={handleEditEntity}
            handleDeleteEntity={handleDeleteEntity}
            setCompanyInfo={setSponsorInfo}
            isTabActive={isActive}
            errorFields={errorFields}
          />
          <SponsorsAndPartners
            type="Partners"
            customInput={customInput}
            companyInfo={partnersInfo}
            uploadEventFile={uploadEventFile}
            handleFileChange={handleFileChange}
            handleAddEntity={handleAddEntity}
            handleEditCompany={handleEditEntity}
            handleDeleteEntity={handleDeleteEntity}
            setCompanyInfo={setPartnerInfo}
            isTabActive={isActive}
            errorFields={errorFields}
          />
        </>
      ),
    },
    {
      key: 4,
      name: 'Pricing & Promo',
      component: (
        <>
          {bookingMethod === BOOKING_METHOD.recorem ? (
            <PricingAndPromoContainer
              form={form}
              isTabActive={isActive}
              errorFields={errorFields}
              setBookingMethod={setBookingMethod}
            />
          ) : (
            <ThirdPartyPayment
              isTabActive={isActive}
              errorFields={errorFields}
              setBookingMethod={setBookingMethod}
            />
          )}
        </>
      ),
    },
  ];

  const handleNextTab = () => {
    if (activeTab < 4) setActiveTab((pre) => toNumber(pre) + 1);
  };

  const handlePreTab = () => {
    if (activeTab > 1) setActiveTab((pre) => toNumber(pre) - 1);
  };

  const handleTabChange = (activeKey) => {
    setActiveTab(toNumber(activeKey));
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <StyledMainContainer>
      {/* circle */}
      <PageCircle color={getUserColorWithRoleId(roleId)} />

      <FormGroup
        form={form}
        name="OrganizerCreateEvent"
        onFinish={handleEventSubmit}
        initialValues={makeCreateEventInitialData(autoFillData)}
        expand
        onFailedFinish={onFailedFinish}
      >
        {/* title search section */}
        {/* Note: this component is being skipped for testing as it's recommeded to test this separately. It's also getting break when testing. */}
        {!isForTest ? (
          <StyledBreadcrumbsWrapper
            id="event_title"
            color={getUserColorWithRoleId(roleId)}
          >
            <h3>
              <OrganizerCreateEventBreadCrumbs />
            </h3>
          </StyledBreadcrumbsWrapper>
        ) : null}

        <StyledContentWrapper>
          <TabSwitch
            defaultActiveKey="1"
            activeKey={`${activeTab}`}
            onChange={handleTabChange}
            items={tabsData}
            theme={getThemeByRoleId(roleId)}
          />
        </StyledContentWrapper>

        {/* Steps-action buttons */}
        <StyledFooter>
          <Row>
            <Col xs={12}>
              <PrimaryButton disabled={activeTab === 1 ? true : false} onClick={handlePreTab} theme="organizer" >
                Pre
              </PrimaryButton>
            </Col>
            <StyledCreateButtonWrapper xs={12}>
              {activeTab === 4 ? (
                <PrimaryButton
                  type="submit"
                  disabled={isPending(status)}
                  theme="organizer"
                  id="create_event_btn"
                >
                  Create Event
                </PrimaryButton>
              ) : null}
              {activeTab < 4 ? (
                <PrimaryButton onClick={handleNextTab} theme="organizer">
                  Next
                </PrimaryButton>
              ) : null}
            </StyledCreateButtonWrapper>
          </Row>
        </StyledFooter>
      </FormGroup>
    </StyledMainContainer>
  );
};

export default Events;

const StyledMainContainer = styled.div`
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-top: 20px;
  }
`;

const StyledBreadcrumbsWrapper = styled.div`
  padding: 0px !important;
  position: relative;
  z-index: 11;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: -20px;
  }

  h3 {
    ${MARGIN(10, 0, 10, 0)};
    color: ${(props) => props.color};
    display: flex;
    line-height: 46px;
    width: 100%;
    font-size: ${FONT[16]};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[14]};
    }
  }
`;

const StyledContentWrapper = styled.div`
  ${PADDING(35, 30, 30, 30)};
  background-color: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.SHADOW2};
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(20, 0, 0, 0)};
    ${PADDING(14, 13, 30, 13)};
  }
`;

const StyledFooter = styled.div`
  ${MARGIN(34, 0, 10, 0)};
`;

const StyledCreateButtonWrapper = styled(Col)`
  ${DISPLAY_FLEX('row', 'flex-end')}
`;
