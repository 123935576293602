import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TwitterIcon from '../../assets/svg/twitter.svg';
import Config from '../../config';
import { TWITTER_REDIRECT_URL } from '../../constants';
import { getTwitterAccessToken, getTwitterToken } from '../../service/auth';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { observeWindow, openWindow } from './window';

const Twitterlogin = (props) => {
  const [CONSUMER_KEY, setConsumerKey] = useState(null);
  const [CONSUMER_SECRET, setConsumerSecret] = useState(null);
  const [popup, setPopup] = useState();
  const [isComplete, setIsComplete] = useState(false);
  const [requestToken, setRequestToken] = useState(null);

  useEffect(() => {
    if (Config.twitterConsumerKey && Config.twitterConsumerSecret) {
      setConsumerKey(Config.twitterConsumerKey);
      setConsumerSecret(Config.twitterConsumerSecret);
    }
  }, []);

  const authCallback = (err, data) => {
    if (err) return;
    const body = {
      provider: 'twitter',
      token: 'twitter',
      ...data,
    };
    props.setOauthLogin(body);
  };

  const initializeProcess = () => {
    if (window.opener) {
      const [, oauthToken, oauthVerifier] =
        window.location.search.match(
          /^(?=.*oauth_token=([^&]+)|)(?=.*oauth_verifier=([^&]+)|).+$/,
        ) || [];
      if (oauthToken && oauthVerifier) {
        window.opener.postMessage(
          {
            type: 'authorized',
            data: {
              oauthToken,
              oauthVerifier,
            },
          },
          window.origin,
        );
      }
    }
  };

  useEffect(() => {
    initializeProcess();

    // eslint-disable-next-line
  }, [window]);

  window.onmessage = async ({ data: { type, data } }) => {
    if (type === 'authorized' && popup) {
      const response = await getTwitterAccessToken({
        ...data,
        oauthToken: requestToken,
      });
      if (response) {
        setIsComplete(true);
        authCallback(undefined, {
          oauthToken: response.oauth_token,
          oauthSecret: response.oauth_token_secret,
        });
        popup.close();
      }
    }
  };

  const handleClosingPopup = () => {
    if (!isComplete) {
      authCallback('User closed OAuth popup');
    }
  };

  const handleError = (message) => {
    authCallback(message);
  };

  const authHandler = async () => {
    const obtainRequestTokenConfig = {
      callbackUrl: `${window.location.href}/`,
    };
    const requestTokenData = await getTwitterToken(obtainRequestTokenConfig);
    const popup = openWindow({
      url: ``,
      name: 'Log in with Twitter',
    });
    if (
      requestTokenData.oauth_callback_confirmed === 'true' &&
      popup !== null
    ) {
      setRequestToken(requestTokenData.oauth_token);
      setPopup(popup);
      popup.location.href = `${TWITTER_REDIRECT_URL}?oauth_token=${requestTokenData.oauth_token}`;

      if (popup) {
        observeWindow({ popup, onClose: handleClosingPopup });
      }
    } else {
      handleError(
        `Callback URL "${window.location.href}" is not confirmed. Please check that is whitelisted within the Twitter app settings.`,
      );
    }
  };

  if (CONSUMER_KEY && CONSUMER_SECRET) {
    return (
      <StyledButtonContainer onClick={authHandler}>
        <StyledTwitterIcon src={TwitterIcon} alt="" />
      </StyledButtonContainer>
    );
  }
  return null;
};

export default Twitterlogin;

const StyledTwitterIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledButtonContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  border-radius: 6px;
  cursor: pointer;
`;
