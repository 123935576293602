import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { COLORS } from '../../../styles/constants/colors';
import {
  rejectProfileVerification,
  approveProfileVerification,
} from '../../../service/masterAdmin';
import { ORGANIZER } from '../../../constants';
import Notification from '../../../components/Notification/Notification';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { MARGIN, PADDING } from '../../../styles/constants/display';
import { IDENTITY_VERIFICATION } from '../../../enums/identity-verification';

const VerifyProfile = ({ profileData, companyData, roleId }) => {
  const [identityVerification, setIdentityVerification] = useState('');
  const userId = get(profileData, 'userId', '');
  const companyId = get(companyData, 'id', '');

  const IdentityVerification = async (status) => {
    switch (status) {
      case IDENTITY_VERIFICATION.Verified:
        if (identityVerification !== IDENTITY_VERIFICATION.Verified) {
          await approveProfileVerification({ roleId, userId, companyId }).then(
            () => {
              Notification('success', 'Profile Approved', '');
              setIdentityVerification(IDENTITY_VERIFICATION.Verified);
            },
          );
        } else {
          Notification('success', 'Already Approved', '');
        }
        break;

      case IDENTITY_VERIFICATION.Rejected:
        if (identityVerification !== IDENTITY_VERIFICATION.Rejected) {
          await rejectProfileVerification({ roleId, userId, companyId }).then(
            () => {
              Notification('success', 'Profile Identification Rejected', '');
              setIdentityVerification(IDENTITY_VERIFICATION.Rejected);
            },
          );
        } else {
          Notification('success', 'Already Rejected', '');
        }
        break;

      default: {
        return null;
      }
    }
  };

  const menu = (
    <Menu>
      <StyledAcceptDiv>
        <div
          onClick={() => {
            IdentityVerification(IDENTITY_VERIFICATION.Verified);
          }}
        >
          Approve
        </div>
      </StyledAcceptDiv>
      <StyledRejectDiv>
        <div
          onClick={() => {
            IdentityVerification(IDENTITY_VERIFICATION.Rejected);
          }}
        >
          Reject/Suspend
        </div>
      </StyledRejectDiv>
    </Menu>
  );

  useEffect(() => {
    const identityVerificationStatus = get(
      roleId === ORGANIZER ? companyData : profileData,
      'identityVerification',
      '',
    );
    setIdentityVerification(identityVerificationStatus);
  }, [profileData, companyData, roleId]);

  if (identityVerification === IDENTITY_VERIFICATION.Rejected)
    setIdentityVerification(IDENTITY_VERIFICATION.Suspended);

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <StyledHeading
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
          href={() => false}
        >
          Profile Identity Verification <CaretDownOutlined />
        </StyledHeading>
      </Dropdown>
      <StyledVerifyBadge identityVerification={identityVerification}>
        {' '}
        {identityVerification}
      </StyledVerifyBadge>
    </>
  );
};
export default VerifyProfile;

const StyledHeading = styled.a`
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledVerifyBadge = styled.span`
  background: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.identityVerification === IDENTITY_VERIFICATION.Verified
      ? COLORS.TRUE_GREEN
      : props.identityVerification === IDENTITY_VERIFICATION.Suspended
        ? COLORS.RED
        : COLORS.ORANGE};
  color: ${COLORS.WHITE};
  ${PADDING(3, 8, 3, 4)};
  border-radius: 5px;
  ${MARGIN(0, 0, 0, 14)};
  text-transform: uppercase;
`;

const StyledRejectDiv = styled(Menu.Item)`
  color: ${COLORS.RED};
  &:hover {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.RED};
  }
`;

const StyledAcceptDiv = styled(Menu.Item)`
  color: ${COLORS.BLACK};
  &:hover {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.TRUE_GREEN};
  }
`;
