/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import isNumber from 'lodash/isNumber';
import AutoComplete from 'antd/es/auto-complete';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import isEmpty from 'lodash/isEmpty';
import Pagination from 'antd/es/pagination';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT } from '../../styles/constants/typography';
import EventPartnerJobCard from './JobTabs/EventPartnerJobCard';
import JobsCardDetails from './JobTabs/JobsCardDetails';
import JobDetailsModal from './JobTabs/JobDetailsModal';
import { useMediaQuery } from '../../utils/customHooks';
import { injectedCommonEndpoints } from '../../store/features/rtkApi/common';
import { checkIfUserIsCandidate, UserInformation } from '../../utils/userInfo';
import { useApplyJobInfo } from '../../hooks/useApplyJobInfo';
import ApplyJobInfo from '../../containers/Candidate/profile/ApplyJobInfo';
import { useGetCommunityJobs } from '../../hooks/community';
import NoJobsFoundImage from '../../assets/img/no-jobs-found.png';
import Notification from '../Notification/Notification';

const paginationItemRender = (page, type, originalElement) => {
  if (type === 'prev') {
    return <StyledPaginationButton icon={<LeftOutlined />} />;
  }
  if (type === 'next') {
    return <StyledPaginationButton icon={<RightOutlined />} />;
  }
  if (type === 'page') {
    return <StyledPaginationButton>{page}</StyledPaginationButton>;
  }
  return originalElement;
};

// Event Partner Component start
const JobsTab = ({ jobId, clearJobId, jobById, openSignInModal }) => {
  const { companyId } = useParams();
  const [value, setValue] = useState('');
  const [searchString, setSearchString] = useState('');
  const [selectedJob, setSelectedJob] = useState(0);
  const [jobModalVisible, setJobModalVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pressedEnter, setPressedEnter] = useState(false);
  const isTabScreenOrLess = useMediaQuery('(max-width : 1200px)');
  const { search } = useLocation();
  const history = useHistory();
  const {
    isAuthenticated,
    user: { roleId },
  } = UserInformation();
  const dispatch = useDispatch();
  const {
    jobs,
    eventId,
    refetchCandidateJobs,
    totalItems,
  } = useGetCommunityJobs({
    companyId,
    pageNumber,
    jobById,
    roleId,
    search: value,
  });
  const {
    isApplyJobInfoOpen,
    handleSaveApplyJob,
    applyJobData,
    handleJobReloadWithProfile,
    handleModalClose,
    handleClose,
    removetheApplyJobData,
    onJobApplyClick,
    jobApplications,
    profileData,
  } = useApplyJobInfo({ eventId, refetchFn: refetchCandidateJobs });

  const clearJobByIdCache = useCallback(() => {
    clearJobId();
    dispatch(
      injectedCommonEndpoints.util.updateQueryData('getJobById', jobId, () => {
        return null;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearJobId, jobId]);

  // this is for applying the job from non auth position
  useEffect(() => {
    if (
      isAuthenticated &&
      !isEmpty(profileData) &&
      localStorage.getItem('appliedJob')
    ) {
      const appliedJob = JSON.parse(localStorage.getItem('appliedJob'));
      // Only candidate can apply to jobs
      if (checkIfUserIsCandidate(roleId))
        onJobApplyClick(appliedJob, 'recommended');
      else Notification('info', 'You cannot perform this action!');
      localStorage.removeItem('appliedJob');
    }
  }, [isAuthenticated, profileData, onJobApplyClick, roleId]);

  useEffect(() => {
    if (search) {
      const searchQueryParams = new URLSearchParams(search);
      const searchValue = searchQueryParams.get('search');
      setSelectedJob(+searchValue);
    }

    return () => {
      // clearing the query params when component unmounts
      const searchQueryParams = new URLSearchParams(search);
      if (searchQueryParams.has('search')) searchQueryParams.delete('search');
      if (searchQueryParams.has('jobId')) searchQueryParams.delete('jobId');
      history.replace({
        search: '',
      });
      clearJobByIdCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, history, clearJobByIdCache]);

  const handlePageChange = (page) => {
    setPageNumber(page);
    clearJobByIdCache();
    setSelectedJob(0);
    window.scrollTo({
      top: 460,
    });
    const queryParams = new URLSearchParams(search);
    queryParams.delete('search');
    queryParams.delete('jobId');
    history.replace({
      search: '',
    });
  };

  const onChange = (data) => {
    setSearchString(data);
    if (!data) {
      setValue('');
    }
  };

  const handleSearchClick = () => {
    setPageNumber(1);
    if (isTabScreenOrLess) setSelectedJob(null);
    else setSelectedJob(0);
    setValue(searchString);
    clearJobByIdCache();
  };

  const handleJobApply = useCallback(() => {
    if (isAuthenticated && checkIfUserIsCandidate(roleId)) {
      onJobApplyClick(jobs[selectedJob], 'recommended');
    } else if (!isAuthenticated) {
      openSignInModal();
      localStorage.setItem('appliedJob', JSON.stringify(jobs[selectedJob]));
    }
    // If the user is not a candidate show this notification
    else {
      Notification('info', 'You cannot perfom this action!');
    }
  }, [
    isAuthenticated,
    openSignInModal,
    onJobApplyClick,
    jobs,
    selectedJob,
    roleId,
  ]);

  const onModalClose = useCallback(() => {
    setJobModalVisible(false);
  }, []);

  const onCardClick = useCallback(
    (id) => {
      if (isTabScreenOrLess) {
        setJobModalVisible(true);
      }

      if (!isTabScreenOrLess) {
        window.scrollTo({
          top: 460,
        });
      }

      // disable the search query if user clicks on any card
      const searchQueryParams = new URLSearchParams(search);
      if (searchQueryParams.has('search')) {
        searchQueryParams.delete(search);
      }
      history.replace({
        search: '',
      });

      setSelectedJob(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTabScreenOrLess, history],
  );

  useEffect(() => {
    // if screen gets smaller than tab width then unset the selected State and there is no job id
    if (isTabScreenOrLess && !jobId) {
      setSelectedJob(null);
    }
    // when screen gets resized to bigger screen hide the modal and check whether user selected a job or not if not show the first job details otherwise show the current one
    else if (!isTabScreenOrLess) {
      setJobModalVisible(false);
      setSelectedJob((current) => {
        return !isNumber(current) ? 0 : current;
      });
    }
    // if the user pasted a job link and the screen is small then show the job modal
    else if (isTabScreenOrLess && jobId) {
      setJobModalVisible(true);
    }
  }, [isTabScreenOrLess, jobId]);

  useEffect(() => {
    if (pressedEnter) {
      handleSearchClick();
    }

    // eslint-disable-next-line
  }, [pressedEnter]);

  return (
    <>
      <StyledJobContainer>
        {/* jobDetails Modal in mobile view */}
        {jobs && isNumber(selectedJob) && (
          <JobDetailsModal
            visible={jobModalVisible}
            onCancel={onModalClose}
            JobDetails={
              <JobsCardDetails
                jobDetails={jobs[selectedJob]}
                inModal
                handleApply={handleJobApply}
                jobApplications={jobApplications}
              />
            }
          />
        )}
        {/* Job search section start */}
        <Row>
          <Col className="gutter-row" xs={24} sm={24} xl={8}>
            <StyledSearchComponent>
              <StyledAutoComplete
                value={searchString}
                allowClear
                onClear={() => setValue('')}
                size="large"
                onChange={onChange}
                placeholder="Search for a role or company"
                onKeyDown={(e) =>
                  e.keyCode === 13 ? setPressedEnter(true) : null
                }
                onKeyUp={() => setPressedEnter(false)}
              />
              <StyledSearchButton
                type="primary"
                size="large"
                onClick={handleSearchClick}
              >
                Search
              </StyledSearchButton>
            </StyledSearchComponent>
          </Col>
        </Row>
        <Row gutter={35}>
          {/* Event job card list section */}
          <Col xs={24} sm={24} xl={8}>
            <div className="card-list-container">
              {jobs && jobs.length > 0 && (
                <EventPartnerJobCard
                  jobsData={jobs}
                  selectedCard={selectedJob}
                  onCardClick={onCardClick}
                />
              )}
            </div>
            {/* Pagination */}
            <StyledPaginationContainer>
              {jobs && jobs.length > 0 && (
                <StyledPagination
                  current={pageNumber}
                  onChange={handlePageChange}
                  total={totalItems}
                  showSizeChanger={false}
                  itemRender={paginationItemRender}
                  size="small"
                  showLessItems={true}
                />
              )}
            </StyledPaginationContainer>
          </Col>
          {/* Event card Details section  */}
          <Col xs={0} sm={0} xl={16}>
            {jobs && isNumber(selectedJob) && (
              <JobsCardDetails
                jobDetails={jobs[selectedJob]}
                inModal={false}
                handleApply={handleJobApply}
                jobApplications={jobApplications}
              />
            )}
          </Col>
        </Row>
        {jobs && jobs.length <= 0 && (
          <StyledNoJobsContainer>
            <img src={NoJobsFoundImage} alt="No jobs found" />
          </StyledNoJobsContainer>
        )}
      </StyledJobContainer>
      {isAuthenticated && profileData && (
        <ApplyJobInfo
          visible={isApplyJobInfoOpen}
          handleSaveApplyJob={handleSaveApplyJob}
          applyJobData={applyJobData}
          handleJobReloadWithProfile={handleJobReloadWithProfile}
          handleModalClose={handleModalClose}
          handleClose={handleClose}
          removetheApplyJobData={removetheApplyJobData}
          eventId={eventId}
        />
      )}
    </>
  );
};

export default JobsTab;

const StyledJobContainer = styled.div`
  ${PADDING(10)}
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(15)}
  }
`;

const StyledPaginationContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    justify-content: center;
    width: 100%;
  }
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active {
    border-color: ${COLORS.HELIOTROPE} !important;
    color: ${COLORS.HELIOTROPE} !important;
    button {
      border: 2px solid ${COLORS.HELIOTROPE} !important;
      color: ${COLORS.HELIOTROPE};
    }
  }
  .ant-pagination-item {
    border: transparent;
  }
  .ant-pagination-item-link-icon {
    color: ${COLORS.HELIOTROPE} !important;
  }
`;

const StyledPaginationButton = styled(Button)`
  &:hover:enabled {
    color: ${COLORS.HELIOTROPE} !important;
    border-width: 2px;
    border-color: ${COLORS.HELIOTROPE} !important;
  }
  border-width: 2px;
`;
const StyledSearchComponent = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  gap: 10px;
  ${MARGIN(0, 0, 33, 0)}
`;

const StyledAutoComplete = styled(AutoComplete)`
  > .ant-select-selector {
    border-radius: 10px !important;
  }
  .ant-select-clear {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  @media (min-width: ${BREAKPOINTS.MOBILE}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    .ant-select-selection-placeholder {
      font-size: ${FONT[12]} !important;
      color: ${COLORS.SPANISH_GRAY_2}47;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-select-selection-placeholder {
      font-size: ${FONT[11]};
      color: ${COLORS.SPANISH_GRAY_2}47;
    }
  }
`;
const StyledSearchButton = styled(Button)`
  border-radius: 9px;
  background-color: ${COLORS.BLUE_VIOLET_2};
  border-color: ${COLORS.BLUE_VIOLET_2};
  ${PADDING(0, 22, 0, 22)}

  &:focus {
    background-color: ${COLORS.HELIOTROPE};
    border-color: ${COLORS.HELIOTROPE};
  }
  &:hover {
    background-color: ${COLORS.HELIOTROPE};
    border-color: ${COLORS.HELIOTROPE};
  }
  > span {
    font-size: ${FONT[14]};
  }
`;

const StyledNoJobsContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  min-height: 50vh;
  > img {
    width: 400px;
    min-width: 250px;
  }
`;
