import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Row, Col, Form, Card } from 'antd';
import toLower from 'lodash/toLower';
import {
  DropdownBox,
  FormGroup,
  InputTextArea,
} from '../../../../components/shared-components/Form/Form';
import ModalButtonContainer from '../../../../components/profile/ModalButtonContainer';
import { rejectReasons } from '../../../../constants/candidateStatusFilter';
import { jobApplicationStatusChange } from '../../../../service/recruiter';
import Notification from '../../../../components/Notification/Notification';
import {
  getApplicationStatusText,
  getApplicationStatusTextForApi,
} from '../../../../constants';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import { StyledModal } from '../../../../components/shared-components/Modal';
import { ResponsiveModalHeader } from '../../../../components/shared-components/ResponsiveModalHeader';
import { HiddenMobile } from '../../../../components/shared-components/DocumentViewerModal';
import { MARGIN } from '../../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import { useTagManager } from '../../../../utils/customHooks';

export default function RejectApplicationModal({
  handleModalClose,
  visible,
  applicationId,
  status,
  isInModal = false,
  handleCurrentStatusChange,
}) {
  const [form] = Form.useForm();
  const [reason, setReason] = useState('');
  const history = useHistory();
  const updateUserActionInGTM = useTagManager();

  const handleSubmit = async (formData) => {
    const { rejectDescription } = formData;
    if (!reason) {
      Notification('error', 'Please select reason to reject application');
      return;
    }

    const body = {
      applicationStatus: getApplicationStatusTextForApi(status).toLowerCase(),
      rejection: reason,
      rejectionBrief: rejectDescription,
    };
    const response = await jobApplicationStatusChange(applicationId, body);
    if (response.status === 200) {
      updateUserActionInGTM(
        `job_application_${toLower(getApplicationStatusText(status))}`,
      );
      Notification('success', 'Job Application status changed to Skipped');
      handleCurrentStatusChange(status);
      if (!isInModal) history.goBack();
    }

    handleModalClose();
  };

  const handleChange = (key, value) => {
    setReason(value.children);
  };

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <StyledModal
      theme="recruiter"
      width="60%"
      visible={visible}
      footer={null}
      title={
        <>
          <ResponsiveModalHeader
            handleModalCancel={handleModalClose}
            title="Application Skip"
          />
          <HiddenMobile>Application Skip</HiddenMobile>
        </>
      }
      onCancel={handleModalClose}
    >
      <StyledHeading>
        Select a Reason for Skipping the Application
      </StyledHeading>
      <FormGroup
        form={form}
        name="reject-application-modal"
        onFinish={handleSubmit}
        expand
      >
        <Row>
          <Col xs={24} md={7}>
            <StyledInputHeader>
              Reason to Skip <MandatoryStar />
            </StyledInputHeader>
            <Row>
              <DropdownBox
                name="reason"
                placeholder="Reasons"
                options={rejectReasons}
                handleChange={handleChange}
              />
            </Row>
          </Col>
          <StyledDescriptionWrapper xs={24} md={17}>
            <StyledInputHeader>Describe</StyledInputHeader>
            <InputTextArea
              rows={4}
              name="rejectDescription"
              placeholder="Details related to rejection"
              autoSize={{ minRows: 4, maxRows: 5 }}
              required={false}
              maxLength={250}
            />
          </StyledDescriptionWrapper>
        </Row>

        <StyledInputHeader>Disclaimer</StyledInputHeader>
        <StyledWrapper>
          <Row gutter={16}>
            <Col span={24}>
              <Card title={null} bordered={false}>
                <p>
                  * Candidates who are not shortlisted in Recorem may directly
                  apply at a clients portal for any position as a first time
                  applicant.
                </p>
                <br />
                <StyledImportantText>
                  EEO Policy – Violation of the below policy could lead to
                  termination of your Recorem account
                </StyledImportantText>
                <br />
                <p>
                  * Recorem strictly prohibits discrimination in employment on
                  the basis of race, color, religion, sex (including pregnancy
                  and gender identity), national origin, political affiliation,
                  sexual orientation, marital status, disability, genetic
                  information, age, membership in an employee organization,
                  parental status, military service or other non-merit factor
                </p>
              </Card>
            </Col>
          </Row>
        </StyledWrapper>
        <ModalButtonContainer
          handleResetFields={handleReset}
          submitText="Submit"
          type="submit"
        />
      </FormGroup>
    </StyledModal>
  );
}

const StyledHeading = styled.p`
  ${MARGIN(10, 0, 30, 0)};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font: ${FONT[16]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

const StyledInputHeader = styled(Row)`
  margin-bottom: 10px;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledDescriptionWrapper = styled(Col)`
  padding-left: 30px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-left: 0;
    padding-top: 20px;
  }
`;

const StyledWrapper = styled.div`
  padding: 30px;
  background: ${COLORS.WHITE_SMOKE};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-bottom: 100px;
  }
`;

const StyledImportantText = styled.p`
  font-weight: ${FONT_WEIGHTS.BOLD};
`;
