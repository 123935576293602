import React from 'react';
import styled from 'styled-components';
import get from "lodash/get";
import { Avatar } from 'antd';
import { UserIcon } from '../../svgicons';
import { StyledSize, AvatarTypeProps } from '../../../interfaces/components/Common';
import { getTheme, getThemeByRoleId } from '../../../utils/common';
import { getRoleId } from '../../../utils/userInfo';

const UserAvatar: React.FC<AvatarTypeProps>  = ({ src, size, userType, alt }) => {
  let theme;

  if(!userType) {
    const role = getRoleId();
  
    theme = getThemeByRoleId(role)
  }

  const color = getTheme(userType || theme);
  
  return (
    <StyledAvatar
      src={src}
      alt={alt}
      icon={<UserIcon />}
      size={size}
      borderColor={color}
    />
  )
}

export default UserAvatar;

const StyledAvatar = styled(Avatar)<StyledSize>`
  width: ${(props) => `${get(props, 'size', 100)}px`};
  height: ${(props) => `${get(props, 'size', 100)}px`};
  border: ${(props) => `${get(props, 'size', 100) <= 50 ? '1px' : '3px'} solid ${props.borderColor}`};
  line-height: ${(props) => `${Math.floor(get(props, 'size', 100) / 1.129)}px !important`};
  text-align: center;
  & > svg {
    display: unset;
    width: 85%;
    height: 85%;
  }
`;
