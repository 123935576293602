import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import { getSelectedChatDetails } from '../../../store/features/chat';
import MessageThreads from './MessageThreads';
import MessageInput from './MessageInput';
import MessageHeader from './MessageHeader';
import { userProfileStore } from '../../../utils/profile';
import { PersonalChatContext } from '../../../context/personalChat';
import { getRoleId } from '../../../utils/userInfo';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const MessageThreadContainer = () => {
  const dispatch = useDispatch();
  const { chatOpened } = useContext(PersonalChatContext);

  const {
    selectedChatId,
    selectedChatDetails,
    personalChatsUsersProfileData: usersProfileData,
  } = useSelector((state) => state.chat);

  const {
    profileData: { userId, company },
  } = userProfileStore();
  const roleId = getRoleId();

  const loggedInUserId = company ? company.id : userId;

  useEffect(() => {
    if (selectedChatId) {
      dispatch(
        getSelectedChatDetails(
          selectedChatId,
          loggedInUserId,
          usersProfileData,
          roleId,
        ),
      );
    }

    // eslint-disable-next-line
  }, [dispatch, selectedChatId, loggedInUserId, usersProfileData]);

  return (
    <StyledMessageThread chatOpened={chatOpened}>
      {selectedChatDetails ? (
        <>
          <MessageHeader />
          <MessageThreads />
          <MessageInput />
        </>
      ) : (
        <Skeleton />
      )}
    </StyledMessageThread>
  );
};

const StyledMessageThread = styled.div`
  ${DISPLAY_FLEX('column')}

  width: 68%;
  height: 65vh;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 70%;
    height: 70vh;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => props.chatOpened ? '' : 'none'};
    width: 100%;
    height: 80vh;
  }
`;

export default MessageThreadContainer;
