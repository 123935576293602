import { useCallback } from 'react';
import { Integration } from '../interfaces/components/ProfileLinks/integrations';
import { useGetAllIntegrationsQuery } from '../store/features/rtkApi/organizerApi';
import { checkIfUserIsOrganizer, getRoleId } from '../utils/userInfo';

const useProfileIntegration = () => {
  const roleId = getRoleId();
  const {
    data: companyIntegrations,
    refetch: refetchAllIntegrationData,
  } = useGetAllIntegrationsQuery('', {
    skip: !checkIfUserIsOrganizer(roleId),
  });

  const isIntegrationDone = useCallback(
    (integrationType: string) => {
      if (companyIntegrations)
        return companyIntegrations.find(
          (integration: Integration) =>
            integration.type === integrationType &&
            (integration.info?.accessToken ||
              integration.info?.webhookUrl ||
              integration.info?.chatId),
        );
      return false;
    },
    [companyIntegrations],
  );

  return {
    companyIntegrations,
    isIntegrationDone,
    refetchAllIntegrationData,
  };
};

export default useProfileIntegration;
