import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from 'react';
import { usePaymentInformation } from '../hooks/paymentInformation';

const PlanContext = createContext();

export const usePlanSelector = () => {
  return useContext(PlanContext);
};

export const CryptoPlanProvider = ({ children }) => {
  const { cryptoIncentivePlan: cryptoPlan } = usePaymentInformation();
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    if (cryptoPlan) {
      setSelectedPlan(cryptoPlan);
    }
  }, [cryptoPlan]);

  const handleSelectPlan = useCallback((plan) => {
    setSelectedPlan(plan);
  }, []);

  const value = {
    selectedPlan,
    handleSelectPlan,
  };

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};
