import React, { useState } from 'react';
// import { Button } from 'antd';
import get from 'lodash/get';
import '../../../styles/components/events/purchase-overview-modal.scss';
// import ManageEventModal from './ManageEventModal';
import styled from 'styled-components';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { DropdownBox } from '../../shared-components/Form/Form';
import PaymentUi from '../../Payment/PaymentUi';
import { Modal } from '../../shared-components/Modal';
import { getThemeByRoleId } from '../../../utils/common';
import { getRoleId } from '../../../utils/userInfo';
import {
  EventsStore,
  getDiscountAmmount,
  getTaxAmmount,
  getTicketPrice,
} from '../../../utils/event';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { StyledLi, StyledSelectedIconContainer } from './BookEventModal';
import { PrimaryButton, SecondaryButton } from '../../shared-components/Button';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  bookEvent,
  bookEventWithInviteCode,
  getPaymentIntent,
} from '../../../service/event';
import { userProfileStore } from '../../../utils/profile';
import { getConvertedPriceString } from './common';
// import { HiddenMobile } from '../../shared-components/DocumentViewerModal';
import getNow from '../../../utils/getNow';
import { isMobile } from '../../../helpers/display';

const PurchaseOverviewModal = ({
  purchaseOverview,
  purchaseOverviewModalClose,
  handleBookEventModalOpen,
  promo,
  bookingInfo,
  inviteCode,
  setOpenBookEventModal,
}) => {
  const { profileData } = userProfileStore();
  const companyData = get(profileData, 'company', null);
  const [showPayment, setShowPayment] = React.useState(false);
  const { event } = EventsStore();
  const [clientSecret, setClientSecret] = useState(null);
  const { currencyData } = useSelector((st) => st.commons);
  const [isLoading, setIsLoading] = useState(false);
  // NOTE: Not required at the time of booking an event, saved for future usage
  // const [manageEvent, setManageEvent] = useState(false);

  // const handleManageEventModalOpen = () => {
  //   setManageEvent(true);
  // };

  const handleCancelPayment = () => {
    setShowPayment(false);
  };

  const handleBack = () => {
    purchaseOverviewModalClose();
    handleBookEventModalOpen();
  };

  // TODO: change tax and transcation charge to dynamic
  let tax = 10.0;

  let ticket;
  let ticketCost;
  let currency;
  let addressLineOne;
  let addressLineTwo;
  let city;
  let country;
  let state;
  let zipcode;

  if (bookingInfo) {
    tax = get(event, 'taxPercentage', 0);
    ticket = get(bookingInfo, 'ticket', '');
    ticketCost = get(bookingInfo, 'ticketCost', 0);
    currency = get(bookingInfo, 'currency', 0);
    addressLineOne = get(bookingInfo, 'billingAddress.addressLineOne', '');
    addressLineTwo = get(bookingInfo, 'billingAddress.addressLineTwo', '');
    city = get(bookingInfo, 'billingAddress.city', '');
    country = get(bookingInfo, 'billingAddress.country', '');
    zipcode = get(bookingInfo, 'billingAddress.zip', '');
    state = get(bookingInfo, 'billingAddress.state', '');
  }

  const totalPrice = () => {
    return ticketCost + getTaxAmmount(tax, ticketCost);
  };
  const roleId = getRoleId();

  const handleProceedToPay = async () => {
    let data = {
      billingAddressId: bookingInfo.billingAddress.id,
      description: get(bookingInfo, 'ticket', ''),
      meta: {
        eventId: event.id,
        roleId,
        userId: get(
          profileData,
          'userId',
          get(profileData, 'companyAdmin.userId', null),
        ),
        companyId: get(companyData, 'id', null),
      },
    };
    if (promo) {
      data = { ...data, promoCode: promo.code };
    }
    setIsLoading(true);
    if (!get(event, 'ticket', null)) {
      let eventBody = {
        eventId: event.id,
        paymentDate: getNow(),
        paymentDetails: {
          currencyId: get(profileData, 'paymentCurrency.id', null),
          currencyCode: get(profileData, 'paymentCurrency.code', null),
          description: ticket,
        },
        billingAddressId: get(bookingInfo, 'billingAddress.id'),
      };
      if (promo) {
        eventBody = { ...eventBody, promoCode: promo.code };
      }
      if (!inviteCode) {
        await bookEvent(eventBody);
      } else {
        await bookEventWithInviteCode({ ...eventBody, inviteCode });
      }
    }
    await getPaymentIntent(data)
      .then(async (response) => {
        const clientSecret = get(response, 'value', '');
        setClientSecret(clientSecret);
        setShowPayment(true);
        setIsLoading(false);
      })
      .catch(() => {
        handleBack();
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        visible={purchaseOverview}
        onCancel={purchaseOverviewModalClose}
        footer={null}
        title="Purchase Overview"
        theme={getThemeByRoleId(roleId)}
        width="80%"
        className="candidate"
        zIndex={isMobile() && 3000}
      >
        <div>
          <StyledOverviewFlex>
            <div>
              <StyledBorderedContainer>
                <StyledS3Heading color={getUserColorWithRoleId(roleId)}>
                  Billing Address
                </StyledS3Heading>
                {bookingInfo ? (
                  <>
                    <StyledLi isFull>
                      <div>
                        <div>
                          <h3>{get(bookingInfo, 'billingAddress.name', '')}</h3>
                        </div>
                        <div>
                          <span>
                            {addressLineOne}, {addressLineTwo},
                          </span>
                        </div>
                        <div>
                          <span>
                            {city}, {state}, {country}, {zipcode}
                          </span>
                        </div>
                      </div>
                      <StyledSelectedIconContainer
                        color={getUserColorWithRoleId(roleId)}
                        selected
                      >
                        <CheckCircleOutlined />
                      </StyledSelectedIconContainer>
                    </StyledLi>
                  </>
                ) : null}
              </StyledBorderedContainer>
              {promo ? (
                <StyledBorderedContainer>
                  <StyledS3Heading color={getUserColorWithRoleId(roleId)}>
                    Promo Code
                  </StyledS3Heading>
                  <p>
                    <StyledBolder>"{promo.code}"</StyledBolder> Applied!
                  </p>
                </StyledBorderedContainer>
              ) : null}

              <StyledBorderedContainer borderHidden>
                <StyledS3Heading color={getUserColorWithRoleId(roleId)}>
                  Select a Payment Method
                </StyledS3Heading>
                <DropdownBox
                  value="Credit / Debit Cards"
                  options={[{ id: 1, name: 'Credit / Debit Cards' }]}
                />
              </StyledBorderedContainer>
            </div>
            <StyledOverviewRecap>
              <div>
                <h2>Purchase Recap</h2>
                <h3>Ticket</h3>
                <StyledContainer>
                  <span>{ticket?.split(':')[0]}</span>
                  <span>
                    {/* <HiddenMobile>
                      <StyledCircleButton
                        className="plus"
                        shape="circle"
                        disabled
                        color={getUserColorWithRoleId(roleId)}
                      >
                        -
                      </StyledCircleButton>
                      <span>1</span>
                      <StyledCircleButton
                        className="minus"
                        shape="circle"
                        disabled
                        color={getUserColorWithRoleId(roleId)}
                      >
                        +
                      </StyledCircleButton>
                    </HiddenMobile> */}
                    <span>{`${getConvertedPriceString(
                      currency,
                      getTicketPrice(event, roleId),
                      profileData,
                      currencyData,
                    )}`}</span>
                  </span>
                </StyledContainer>
                <Styledhr />
                {promo ? (
                  <>
                    <h3>Discount</h3>
                    <StyledContainer>
                      <span>Promo Code</span>
                      <span>
                        {`- ${getConvertedPriceString(
                          currency,
                          getDiscountAmmount(
                            getTicketPrice(event, roleId),
                            promo.type,
                            promo.value,
                          ),
                          profileData,
                          currencyData,
                        )}`}
                      </span>
                    </StyledContainer>
                    <Styledhr />
                  </>
                ) : null}
                {tax ? (
                  <>
                    <h3>Extra Charges</h3>
                    <StyledContainer>
                      <span>Tax</span>
                      <span>
                        {getConvertedPriceString(
                          currency,
                          getTaxAmmount(tax, ticketCost),
                          profileData,
                          currencyData,
                        )}
                      </span>
                    </StyledContainer>

                    {/*
                  <StyledContainer>
                  <span>Transaction</span>
                  <span>{`${currency} ${transactionCharges}`}</span>
                </StyledContainer>
                  */}
                    <Styledhr />
                  </>
                ) : null}
              </div>
              <StyledContainer>
                <StyledBolder>Grand Total</StyledBolder>
                <span>{`${getConvertedPriceString(
                  currency,
                  totalPrice(),
                  profileData,
                  currencyData,
                )}`}</span>
              </StyledContainer>
            </StyledOverviewRecap>
          </StyledOverviewFlex>
          <StyledButtonsContainer>
            <SecondaryButton
              onClick={handleBack}
              theme={getThemeByRoleId(roleId)}
            >
              Back
            </SecondaryButton>
            <PrimaryButton
              onClick={handleProceedToPay}
              theme={getThemeByRoleId(roleId)}
            >
              {isLoading ? <LoadingOutlined spin /> : null}
              Proceed to Pay
            </PrimaryButton>
          </StyledButtonsContainer>
        </div>
      </Modal>
      {/* NOTE: Not required at the time of booking an event, saved for future usage */}
      {/* <ManageEventModal
        manageEvent={manageEvent}
        handleManageEventModalClose={handleManageEventModalClose}
      /> */}

      <PaymentUi
        showPayment={showPayment}
        bookingInfo={bookingInfo}
        handleClosePaymentModal={handleCancelPayment}
        purchaseOverviewModalClose={purchaseOverviewModalClose}
        setOpenBookEventModal={setOpenBookEventModal}
        inviteCode={inviteCode}
        promo={promo}
        clientSecret={clientSecret}
      />
    </>
  );
};

export default PurchaseOverviewModal;

const StyledOverviewFlex = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('column', 'space-between')}
  }
`;

const StyledOverviewRecap = styled.div`
  margin-left: 10rem;
  background-color: ${COLORS.VLIGHT_GRAY};
  width: 30vw;
  height: 60vh;
  border-radius: 5px;
  ${DISPLAY_FLEX('column', 'space-between')};
  ${PADDING(26)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${PADDING(40)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
    margin-left: 0;
    ${PADDING(10, 12)}
  }
`;

const StyledBorderedContainer = styled.div`
  padding: 15px 0;
  ${(props) =>
    props.borderHidden ? `` : `border-bottom: 1px dashed ${COLORS.GRAY};`}
`;

const StyledButtonsContainer = styled.div`
  width: 100%;
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  margin-top: 20px;
`;

const StyledS3Heading = styled.h3`
  color: ${(props) => props.color};
`;

const StyledBolder = styled.span`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

// const StyledCircleButton = styled(Button)`
//   background-color: ${(props) => props.color};
//   ${MARGIN(0, 10)}
// `;

const StyledContainer = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  ${MARGIN(0, 0, 10)}
`;

const Styledhr = styled.hr`
  ${MARGIN(10, 0)}
`;
