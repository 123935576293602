import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Steps } from 'antd';
import get from 'lodash/get';
import { QuickSubmission } from './QuickStart/QuickSubmission';
import { QuickStartSkillSet } from './QuickStart/QuickStartSkillSet';
import { Modal } from '../../../components/shared-components/Modal';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import WorkAuthorization from './QuickStart/WorkAuthorization';
import { ADD, CANDIDATE_COLOR } from '../../../constants';
import { COLORS } from '../../../styles/constants/colors';
import { isDesktop, isLaptop, isMobile } from '../../../helpers/display';
import { getFilePath, userProfileStore } from '../../../utils/profile';

const ApplyJobInfo = ({
  visible,
  handleModalClose,
  handleSaveApplyJob,
  applyJobData,
  handleJobReloadWithProfile,
  handleClose,
  removetheApplyJobData,
  eventId = null,
}) => {
  const { Step } = Steps;
  const { profileData } = userProfileStore();
  const [resume, setResume] = useState(
    get(profileData, 'resume', null)
      ? [
          {
            name: get(profileData, 'resumeName', ''),
            url: getFilePath(get(profileData, 'resume', null)),
            isLoading: false,
          },
        ]
      : null,
  );
  const [skills, setSkills] = useState([
    ...[...Array(1)].map((el) => ({
      id: el,
      skill: {
        id: 0,
        skill: '',
      },
      operation: ADD,
    })),
  ]);
  const [skillStep, setSkillStep] = useState([
    ...[...Array(5)].map(() => ({})),
  ]);
  const [requestSkill, setRequestSkill] = useState([
    ...[...Array(5)].map(() => ({})),
  ]);
  const [skillsList, setSkillsList] = useState([
    ...[...Array(5)].map(() => ({ options: [] })),
  ]);
  const [contributeOption, setContributeOption] = useState([
    ...[...Array(5)].map(() => 0),
  ]);
  const [step, setStep] = useState(0);

  const getWidth = () => {
    switch (true) {
      case isDesktop():
        return '62%';
      case isLaptop():
        return '75%';
      case !isMobile():
        return '92%';
      default:
        break;
    }
  };
  const resumePath = get(profileData, 'resume', null);

  useEffect(() => {
    if (resumePath) setStep(1);
  }, [resumePath]);

  return (
    <Modal
      visible={visible}
      footer={null}
      width={getWidth()}
      onCancel={step !== 2 ? handleClose : handleModalClose}
      destroyOnClose
      title={
        !isMobile() ? (
          <StyledSteps current={step} color={CANDIDATE_COLOR}>
            <Step title="Resume" />
            <Step title="Skills" />
            <Step title="Work Authorization" />
            {/* <Step title="Invite your Crew Member" description="Create your Crew" /> */}
          </StyledSteps>
        ) : (
          'Quick Start'
        )
      }
      theme="candidate"
      borderRadius={10}
      zIndex={isMobile() && 3000}
    >
      <div>
        {isMobile() ? (
          <StyledSteps
            current={step}
            color={CANDIDATE_COLOR}
            labelPlacement="vertical"
            size="small"
          >
            <Step title="Resume" />
            <Step title="Skills" />
            <Step title="Work Authorization" />
            {/* <Step title="Invite your Crew Member" description="Create your Crew" /> */}
          </StyledSteps>
        ) : null}
        <StyledCandidateProfile>
          <StyledQuickStart>
            <QuickSubmission
              resume={resume}
              setResume={setResume}
              isApplyModal
              setStep={setStep}
              isVisible={step === 0}
              profileData={profileData}
            />
            <QuickStartSkillSet
              skills={skills}
              isVisible={step === 1}
              setSkills={setSkills}
              isApplyModal
              setStep={setStep}
              skillStep={skillStep}
              setSkillStep={setSkillStep}
              requestSkill={requestSkill}
              setRequestSkill={setRequestSkill}
              skillsList={skillsList}
              setSkillsList={setSkillsList}
              contributeOption={contributeOption}
              setContributeOption={setContributeOption}
              removetheApplyJobData={removetheApplyJobData}
            />
            <WorkAuthorization
              isVisible={step === 2}
              skills={skills}
              setStep={setStep}
              applyJobData={applyJobData}
              handleSaveApplyJob={handleSaveApplyJob}
              handleJobReloadWithProfile={handleJobReloadWithProfile}
              eventId={eventId}
            />
          </StyledQuickStart>
        </StyledCandidateProfile>
      </div>
    </Modal>
  );
};

export default ApplyJobInfo;

const StyledCandidateProfile = styled.div`
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-top: 0px;
  }
`;

const StyledQuickStart = styled.div`
  position: relative;

  .ant-select-dropdown {
    height: 100xp !important;
    max-height: 100px !important;
  }
`;

const StyledSteps = styled(Steps)`
  width: 80%;
  margin: 0 auto;

  .ant-steps-item-icon {
    background-color: ${COLORS.candidateColor} !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${COLORS.WHITE} !important;
  }

  .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${COLORS.candidateColor} !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${COLORS.candidateColor} !important;
  }

  .ant-steps-item-icon {
    border-color: ${COLORS.candidateColor} !important;

    svg {
      fill: ${COLORS.candidateColor} !important;
    }
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff !important;
    border-color: rgba(0, 0, 0, 0.25) !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
    .ant-steps-item {
      width: 33%;
    }

    height: unset !important;

    .ant-steps-item-tail {
      top: -10px !important;

      ::after {
        background-color: ${COLORS.candidateColor} !important;
      }
    }
  }
`;
