import React from 'react';
import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { ModalProps } from '../../interfaces/components/Modal';
import { getTheme } from '../../utils/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { ResponsiveModalHeader } from './ResponsiveModalHeader';
import { PADDING } from '../../styles/constants/display';
import { semiLargeHeadingMixin } from '../../styles/constants/mixins';
import { HiddenMobile } from './DocumentViewerModal';

export const Modal: React.FC<ModalProps> = ({
  children,
  theme,
  visible,
  width,
  footer,
  title,
  onOk,
  onCancel,
  okText,
  zIndex,
  borderRadius = 0,
  okButtonProps = { disable: false },
}) => {
  return (
    <>
      <StyledModal
        visible={visible}
        title={
          <>
            <ResponsiveModalHeader handleModalCancel={onCancel} title={title} />
            <HiddenMobile>{title}</HiddenMobile>
          </>
        }
        theme={theme}
        width={width}
        footer={footer}
        onCancel={onCancel}
        onOk={onOk}
        okText={okText || 'OK'}
        zIndex={zIndex || 1000}
        borderRadius={borderRadius}
        okButtonProps={okButtonProps}
      >
        {children}
      </StyledModal>
    </>
  );
};

export const StyledModal = styled(AntModal)<any>`
  &.ant-modal {
    top: 35px !important;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      top: -10px !important;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      top: 75px !important;
    }
  }

  .ant-modal-body {
    ${PADDING(18, 24)}
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${PADDING(15)}
    }
  }

  .ant-modal-header {
    /* background-color: ${(props) => `${getTheme(props.theme)}0F`}; */
    border-radius: 8px;
    border-bottom: none;
  }

  .ant-modal-title {
    color: ${(props) => getTheme(props.theme)};
    font-weight: ${FONT_WEIGHTS.BOLD};
    ${semiLargeHeadingMixin}
  }

  .ant-modal-footer {
    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      width: 100% !important;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      position: fixed !important;
      bottom: 0px !important;
      background-color: rgb(255 255 255);
      width: 100%;
      box-shadow: 0px 4px 15px 4px rgb(0, 0, 0, 0.15);
    }
  }

  .ant-modal-content {
    ${({ borderRadius }) =>
      borderRadius ? `border-radius: ${borderRadius}px;` : ''}
  }
`;
