export const employmentType = [
  {
    id: 1,
    name: 'Full Time',
  },
  {
    id: 2,
    name: 'Part Time',
  },
  {
    id: 3,
    name: 'Internship',
  },
  {
    id: 4,
    name: 'Contract & Consulting',
  },
];
