import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';
import { OrganizerRoutes } from '../../../../constants/routes';
import { ReactComponent as EditLogo } from '../../../../assets/svg/edit.svg';
import { getRoleId } from '../../../../utils/userInfo';
import { StyledMediumHeading } from '../../../shared-components/Texts';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import { DISPLAY_FLEX, MARGIN } from '../../../../styles/constants/display';
import { COLORS } from '../../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';

export default ({ text = 'Edit', eventId }) => {
  return (
    <StyledWrapper>
      <h3>
        <StyledMediumHeading>{text}</StyledMediumHeading>
      </h3>
      <Link to={OrganizerRoutes.editEvent(eventId)}>
        <StyledButton backgroundColor={getUserColorWithRoleId(getRoleId())}>
          <EditLogo />
        </StyledButton>
      </Link>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  ${MARGIN(0, 5, 0, 5)};

  h3 {
    color: ${COLORS.DARK_SILVER};
    margin-right: 10px;
    position: relative;
    top: 2px;
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  ${DISPLAY_FLEX(null, 'center', 'center')};
  ${MARGIN(0, 5, 0, 5)};
  background-color: ${(props) => props.backgroundColor};
  width: 30px;
  height: 30px;
  border-radius: 100%;
  padding: 8px;
  border: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 36px;
    height: 36px;
  }

  &:hover {
    background-color: ${(props) => props.backgroundColor};
  }

  svg {
    width: 16px;
    height: 16px;
    fill: ${COLORS.WHITE};
  }
`;
