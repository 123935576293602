import Notification from './Notification';
import { NOTIFICATION_STATUS } from '../../constants';

const { DONE, ERROR } = NOTIFICATION_STATUS;

export const createEventNotification = (stateInfo) => {
  if (stateInfo.status === DONE) {
    Notification(
      'success',
      'Success',
      `${stateInfo.name} event created successfully.`,
    );
  } else if (stateInfo.status === ERROR) {
    Notification(
      ERROR,
      stateInfo.error,
      `${stateInfo.name} event creating failed.`,
    );
  }
};

export const editEventNotification = (stateInfo) => {
  if (stateInfo.status === DONE) {
    Notification(
      'success',
      'Success',
      `${stateInfo.name} event edited successfully.`,
    );
  } else if (stateInfo.status === ERROR) {
    Notification(
      ERROR,
      stateInfo.error,
      `${stateInfo.name} event editing failed.`,
    );
  }
};

export const cancelEventNotification = (stateInfo) => {
  if (stateInfo.status === DONE) {
    Notification(
      'success',
      'Success',
      `${stateInfo.name} event cancelled successfully.`,
    );
  } else if (stateInfo.status === ERROR) {
    Notification(
      ERROR,
      stateInfo.error,
      `${stateInfo.name} event cancellation failed.`,
    );
  }
};
