import React from 'react';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { MediumHeading } from '../shared-components/Texts';

export const TitleContainer = ({ children, roleId, marginTop = 10 }) => {
  return (
    <MediumHeading
      color={getUserColorWithRoleId(roleId)}
      fontWeight={FONT_WEIGHTS.SEMI_BOLD}
      margin={`${marginTop ? `${marginTop}px` : '0'} 0  20px 0`}
    >
      {children}
    </MediumHeading>
  );
};

export const SubTitles = ({ children, margin }) => {
  return (
    <MediumHeading
      fontWeight={FONT_WEIGHTS.SEMI_BOLD}
      color={COLORS.BLACK}
      margin={margin}
    >
      {children}
    </MediumHeading>
  );
};
