import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import CompanyInformationModal from './profile/CompanyInformationModal';
import { userProfileStore } from '../../utils/profile';
import { getRoleId } from '../../utils/userInfo';
import Header from '../../components/profile/Header';
import CompanyInformation from '../../components/profile/CompanyInformation';
import RepresentativeTeamProfile from '../../components/profile/RepresentativeTeamProfile';
import CrewManagement from '../../components/CrewManagement/CrewManagement';
import { userIsCrewMember } from '../../utils/crew';
import CrewMemberProfile from '../../components/CrewManagement/CrewMemberProfile';
import CurrencySetting from '../../components/profile/CurrencySetting';
import { getFileS3 } from '../../utils/s3.storage';
import RepresentativeModal from '../../components/shared-components/RepresentativeModal';
import MailSubscription from '../../components/profile/MailSubscription';
import { getUserColorWithRoleId } from '../../styles/utils';
import { StyledPageBottom } from '../../components/shared-components/Common/PageBottom';
import { PageContainer } from '../../components/shared-components/PageContainer';
import PageCircle from '../../components/shared-components/PageCircle';
import { StyledProfileWrapper } from '../../components/shared-components/common';
import { useTagManager } from '../../utils/customHooks';

const Profile = () => {
  const { currencyData } = useSelector((st) => st.commons);
  const { isLoading, profileData, error } = userProfileStore();
  const roleId = getRoleId();
  const { company: companyData } = profileData;
  const [profilePic, setProfilePic] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const updateUserActionInGTM = useTagManager();
  const isCrewMember = userIsCrewMember(profileData);

  useEffect(() => {
    if (!isLoading) {
      setProfilePic(getFileS3(profileData.profilePic));
      setCompanyLogo(getFileS3(companyData.logo));
    }
  }, [profileData, companyData, isLoading]);

  useEffect(() => {
    updateUserActionInGTM('profile_settings_opened');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [companyModal, setCompanyModal] = useState(false);

  const handleOpenCompanyModal = () => {
    setCompanyModal(true);
  };

  const handleCloseCompanyModal = () => {
    setCompanyModal(false);
  };

  const [representativeModal, setRepresentativeModal] = useState(false);

  const handleOpenRepresentativeModal = () => {
    setRepresentativeModal(true);
  };

  const handleCloseRepresentativeModal = () => {
    setRepresentativeModal(false);
  };

  return isLoading || error ? (
    <Skeleton />
  ) : isCrewMember ? (
    <CrewMemberProfile memberId={profileData.userId} />
  ) : (
    <>
      <PageCircle color={getUserColorWithRoleId(roleId)} />
      <StyledProfileWrapper>
        <Header
          userType="recruiter"
          data={companyData}
          handleOpenModal={handleOpenCompanyModal}
          isCrewMember={isCrewMember}
        />

        <div>
          <PageContainer>
            <CompanyInformation
              companyData={companyData}
              profileData={profileData}
              companyLogo={companyLogo}
              setCompanyLogo={setCompanyLogo}
            />
          </PageContainer>

          <PageContainer>
            <RepresentativeTeamProfile
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              profileData={profileData}
              handleOpenRepresentativeModal={handleOpenRepresentativeModal}
            />
            <CrewManagement profileData={profileData} roleId={roleId} />
          </PageContainer>

          <CurrencySetting
            profileData={profileData}
            currencyData={currencyData}
          />

          <MailSubscription profileData={profileData} />
        </div>

        {companyModal ? (
          <CompanyInformationModal
            visible={companyModal}
            handleClose={handleCloseCompanyModal}
            companyLogo={companyLogo}
            profileData={profileData}
            companyData={companyData}
            setCompanyLogo={setCompanyLogo}
            currencyData={currencyData}
          />
        ) : null}

        {representativeModal ? (
          <RepresentativeModal
            visible={representativeModal}
            handleClose={handleCloseRepresentativeModal}
            profilePic={profilePic}
            profileData={profileData}
            setProfilePic={setProfilePic}
            companyData={companyData}
            userRole={roleId}
          />
        ) : null}
        <StyledPageBottom color={getUserColorWithRoleId(roleId)} />
      </StyledProfileWrapper>
    </>
  );
};

export default Profile;
