import React, { useState, useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components';
import GoogleIcon from '../../assets/svg/google_dark.svg';
import Config from '../../config';
import { DISPLAY_FLEX } from '../../styles/constants/display';

const Googlelogin = (props) => {
  const [GOOGLE_CLIENT_ID, setGoogleClientId] = useState(null);

  useEffect(() => {
    if (!GOOGLE_CLIENT_ID) {
      setGoogleClientId(Config.google_client_id);
    }
  }, [GOOGLE_CLIENT_ID]);

  const responseGoogle = (response) => {
    const body = {
      provider: 'google',
      token: response.accessToken,
    };
    props.setOauthLogin(body);
  };

  if (GOOGLE_CLIENT_ID !== null && GOOGLE_CLIENT_ID !== undefined) {
    return (
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={responseGoogle}
        cookiePolicy="single_host_origin"
        render={(renderProps) => (
          <StyledButtonContainer
            onClick={() => {
              renderProps.onClick();
            }}
          >
            <StyledGoogleIcon src={GoogleIcon} alt="" />
          </StyledButtonContainer>
        )}
      />
    );
  }
  return null;
};

export default Googlelogin;

const StyledGoogleIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledButtonContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  border-radius: 6px;
  cursor: pointer;
`;
