import React from 'react';
import styled from 'styled-components';
import { TextUploadFileList } from '../../../../components/shared-components/Upload';
import { ACCEPTED_DOCUMENT_EXTENSIONS } from '../../../../constants/file';
import { DISPLAY_FLEX, MARGIN } from '../../../../styles/constants/display';
import { getRoleId } from '../../../../utils/userInfo';

const TermsAndPolicy = ({
  uploadEventFile,
  handleFileChange,
  handleRemove,
  fileList,
  documentError,
}) => {
  const roleId = getRoleId();

  return (
    <StyledFlex>
      <TextUploadFileList
        name="termsAndConditions"
        text="Select files"
        customRequest={(data) => uploadEventFile(data, 'terms&conditions')}
        onChange={(data) => handleFileChange(data, 'terms&conditions')}
        accept={ACCEPTED_DOCUMENT_EXTENSIONS}
        onRemove={handleRemove}
        fileList={fileList}
        roleId={roleId}
        showTooltip={false}
        id="terms_and_policy"
        style={{ borderRadius: '5px' }}
        error={documentError}
      />
    </StyledFlex>
  );
};

export default TermsAndPolicy;

const StyledFlex = styled.div`
  .ant-upload-list {
    ${DISPLAY_FLEX};
    flex-wrap: wrap;
    height: 85px;
    overflow: auto;
  }

  .ant-upload-list-text-container {
    ${MARGIN(10, 10, 0, 0)};
  }
`;
