/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import { Card, Pagination } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGetCommunityEventsQuery } from '../../../store/features/rtkApi/common';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { useGetSize, useMediaQuery } from '../../../utils/customHooks';
import EventCard from './EventCard';

const renderLoadingCards = (height: string | number, isTablet: boolean) => (
  <>
    <Card loading style={{ height, width: '100%' }} />
    {!isTablet ? (
      <>
        <Card loading style={{ height, width: '100%' }} />
        <Card loading style={{ height, width: '100%' }} />
      </>
    ) : null}
  </>
);

const Events = ({ section = false }: { section: boolean }) => {
  const height = useGetSize(370, 320, 320, 317, 318, 318);
  const { companyId } = useParams<{ companyId: string }>();
  const count = section ? 4 : 12;
  const isTablet = useMediaQuery(`(max-width: ${BREAKPOINTS.TABLET_MAX})`);
  const [pageNumber, setPageNumber] = useState(1);

  const { data: communityPageEvents, isLoading } = useGetCommunityEventsQuery({
    companyId,
    page: pageNumber,
    limit: count,
  });

  const cardsLength = communityPageEvents?.events

  // eslint-disable-next-line no-shadow
  const itemRender = (_current: number, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <a>Prev</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const handlePageChange = async (page: number) => {
    setPageNumber(page);
  };

  if (isLoading) {
    return renderLoadingCards(height, isTablet);
  }

  return (
    <StyledWrapper isVisible>
      <StyledCardWrapper section={section} noEvent={cardsLength?.length === 0}>
        {communityPageEvents?.events.map((event: any) => (
          <EventCard
            key={event.id}
            width="100%"
            height={height}
            event={event}
          />
        ))}
      </StyledCardWrapper>
      {!section && (
        <StyledPagination>
          <Pagination
            showSizeChanger={false}
            itemRender={itemRender}
            total={communityPageEvents?.totalItems}
            onChange={handlePageChange}
            hideOnSinglePage
            pageSize={+count}
            current={pageNumber}
          />
        </StyledPagination>
      )}
    </StyledWrapper>
  );
};

export const StyledWrapper = styled.div<any>`
  ${MARGIN(null, null, 20, null)}
`;

const StyledCardWrapper = styled.div<any>`
  ${({ section, noEvent }) =>
    section &&
    `display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-columns: minmax(330px, 1fr);
  grid-auto-rows: 340px;
  grid-auto-flow: column;
  grid-gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: repeat(auto-fill, ${noEvent ? `0px` : `340px`});
    grid-auto-columns: minmax(330px, 1fr);
    grid-auto-flow: column;
    column-gap: 10px;
    row-gap: 20px;
    overflow-x: auto;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: repeat(auto-fill,  ${noEvent ? `0px` : `340px`});
    grid-auto-columns: minmax(330px, 1fr);
    row-gap: 20px;
    overflow-x: auto;
  }`}

  ${({ section, noEvent }) =>
    !section &&
    `display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    row-gap: 25px;
    column-gap: 20px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill,  ${noEvent ? `0px` : `310px`});
    column-gap: 10px;
    row-gap: 20px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill,  ${noEvent ? `0px` : `310px`});
    row-gap: 20px;
  }`}
`;

const StyledPagination = styled.div`
  ${DISPLAY_FLEX('row', 'center')};
  ${MARGIN(35, null, null, null)}

  .ant-pagination {
    li {
      border: none;
    }

    .ant-pagination-disabled {
      a {
        color: #6d6d6d;
        font-weight: ${FONT_WEIGHTS.MEDIUM};
        opacity: 0.5;
      }
    }

    .ant-pagination-item-active,
    .ant-pagination-next,
    .ant-pagination-prev {
      a {
        color: ${COLORS.MARKETING_BLUE};
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(20, 0)}
  }
`;

export default Events;
