import React from 'react';
import { Col } from 'antd';
import { HeaderProps } from '../../../interfaces/components/Notifications';
import { TabSwitch } from '../Tabs';
import ChatNotifications from './ChatNotifications';
import GeneralNotifications from './GeneralNotifications';
import { getThemeByRoleId } from '../../../utils/common';
import { getRoleId } from '../../../utils/userInfo';

export const Header: React.FC<HeaderProps> = () => {
  const roleId = getRoleId();
  const tabsData = [
    {
      key: 1,
      name: 'General',
      component: (
        <>
          <GeneralNotifications />
        </>
      ),
    },
    {
      key: 2,
      name: 'Chat',
      component: <ChatNotifications />,
    },
  ];

  return (
    <Col xs={24}>
      <TabSwitch
        defaultActiveKey="3"
        items={tabsData}
        theme={getThemeByRoleId(roleId)}
      />
    </Col>
  );
};
