import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import { Header } from './Header';
import { getRoleId } from '../../../utils/userInfo';
import {
  NotificationsProps,
  RoleStringProp,
} from '../../../interfaces/components/Notifications';
import { getColorStringByRoleId } from '../../../utils/notification';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { SHADOWS } from '../../../styles/constants/shadows';

export const Notifications: React.FC<NotificationsProps> = ({ location }) => {
  const roleId = getRoleId();

  return (
    <div>
      <StyledContentBoxDiv>
        <StyledCircleBoxDiv roleId={roleId} />
        <StyledHeading roleId={roleId}>Notification Library</StyledHeading>

        <StyledRowContainer>
          <Header location={location} roleId={roleId} />
        </StyledRowContainer>
      </StyledContentBoxDiv>
    </div>
  );
};

export default Notifications;

const StyledContentBoxDiv = styled.div`
  position: relative;
`;

const StyledCircleBoxDiv = styled.div<RoleStringProp>`
  background-color: ${(props) => getColorStringByRoleId(props.roleId)};
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.15;
  position: absolute;
  left: -124px;
  top: -100px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 310px;
    height: 310px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledHeading = styled.div<RoleStringProp>`
  position: relative;
  margin-top: 30px;
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${(props) => getColorStringByRoleId(props.roleId)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 10px;
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

const StyledRowContainer = styled(Row)`
  padding: 30px 20px;
  background-color: ${COLORS.WHITE};
  z-index: 1;
  box-shadow: ${SHADOWS.SHADOW1};
  margin-top: 30px;
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 44px 30px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: 20px 10px;
  }
`;
