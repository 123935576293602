import React, { memo } from 'react';
import styled from 'styled-components';
import formatDateTime from '../../utils/formatDateTime';
import { EventDetailsCard } from '../shared-components/EventDetailsCard';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { MARGIN } from '../../styles/constants/display';
import { FONT } from '../../styles/constants/typography';

export const EventCard = memo(({
  roleId,
  events,
  isEventOver = false,
  category,
  noTimeline = null,
  noTitle = null,
}) => {
  const dateMap = new Map();
  events.map((event) => {
    const dateKey = formatDateTime(event.dateTime);
    const value = dateMap.get(dateKey);
    if (value) {
      dateMap.set(dateKey, [...value, event]);
    } else {
      dateMap.set(dateKey, [event]);
    }
    return null;
  });
  const dateKeys = [...dateMap.keys()];

  return (
    <div>
      {dateKeys.map((el, idx) => {
        return (
          <div key={idx.toString()}>
            {noTimeline ? (
              <>
                {dateMap.get(el).map((event, i) => {
                  return (
                    <EventDetailsCard
                      roleId={roleId}
                      status={isEventOver ? 'ended' : undefined}
                      eventDetails={event}
                      category={category}
                      noTitle={noTitle}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <StyledDividerWrapper orientation="left">
                  <StyledDateText>{el}</StyledDateText>
                </StyledDividerWrapper>
                {dateMap.get(el).map((event, i) => {
                  return (
                    <EventDetailsCard
                      key={i}
                      roleId={roleId}
                      status={isEventOver ? 'ended' : undefined}
                      eventDetails={event}
                      category={category}
                    />
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
});

const StyledDividerWrapper = styled.div`
  ${MARGIN(20, 0, 20, 0)};
`;

const StyledDateText = styled.h4`
  color: ${COLORS.DARK_SILVER};
  border-bottom: 1px solid ${COLORS.ARGENT};
  padding-bottom: 12px;
  font-size: ${FONT[14]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[13]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;
