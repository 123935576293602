import {
  STATUS_ACTIVE,
  STATUS_ACTIVE_TEXT,
  STATUS_DISABLED,
  STATUS_DISABLED_TEXT,
  STATUS_PENDING,
  STATUS_PENDING_TEXT,
} from '.';
import { serialNoColumn } from '../utils/common';
import { JOB_APPLICATION_STATUS } from './jobs';

export const JobApplicationFilterOption = [
  {
    id: 0,
    name: 'Show All',
  },
  {
    id: 1,
    name: 'Applied',
  },
  {
    id: 2,
    name: 'Not Applied',
  },
];

export const JOB_STATUS_FILTER_DEFAULT = 0;

const commonColumns = [
  {
    title: 'Email',
    key: 2,
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    key: 3,
    dataIndex: 'phone',
  },
  {
    title: 'Created/Updated At',
    key: 4,
    dataIndex: 'lastUpdate',
  },
  {
    title: 'Verify Account',
    key: 5,
    dataIndex: 'verify',
  },
  {
    title: 'Delete',
    key: 6,
    dataIndex: 'delete',
  },
];

export const UnverifiedCandidateColumns = [
  { ...serialNoColumn },
  {
    title: 'Candidate Name',
    key: 1,
    dataIndex: 'userName',
  },
  ...commonColumns,
];

export const UnverifiedCompanyColumns = [
  { ...serialNoColumn },
  {
    title: 'Company Name',
    key: 0,
    dataIndex: 'companyName',
    sorter: true,
  },
  {
    title: 'Representative Name',
    key: 1,
    dataIndex: 'userName',
    sorter: true,
  },
  ...commonColumns,
];

export const JobApplicationsStatusFilter = [
  { id: -1, name: 'All Job Applications' },
  { id: JOB_APPLICATION_STATUS.SUBMITTED, name: 'Submitted' },
  { id: JOB_APPLICATION_STATUS.APPROVE, name: 'Shortlisted' },
  { id: JOB_APPLICATION_STATUS.REJECT, name: 'Skipped' },
  { id: JOB_APPLICATION_STATUS.HOLD, name: 'Under Review' },
];

export const JobApplicationTableColumns = (countries) => [
  { ...serialNoColumn },
  {
    title: 'Name',
    key: 0,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Email',
    key: 1,
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    key: 2,
    dataIndex: 'phone',
  },
  {
    title: 'Location',
    key: 'location',
    dataIndex: 'location',
    filters: countries
      ? countries.map((country) => ({
          text: country.name,
          value: country.name,
        }))
      : [],
  },
  {
    title: 'Applied At',
    key: 4,
    dataIndex: 'createdAt',
    sorter: true,
  },
  {
    title: 'Status',
    key: 5,
    dataIndex: 'status',
  },
];

export const organizersCompanyColumns = [
  { ...serialNoColumn },
  {
    title: 'Company Name',
    key: 1,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Name',
    key: 2,
    dataIndex: 'userName',
    sorter: true,
  },
  {
    title: 'Industry',
    key: 3,
    dataIndex: 'domain',
  },
  {
    title: 'Events',
    key: 4,
    dataIndex: 'events',
  },

  {
    title: 'Companies',
    key: 5,
    dataIndex: 'companies',
  },
  {
    title: 'Candidates',
    key: 6,
    dataIndex: 'candidates',
  },
  {
    title: 'Members',
    key: 7,
    dataIndex: 'members',
  },
  {
    title: 'Status',
    key: 8,
    dataIndex: 'status',
  },
];

export const NATIONALITY_TEXT = 'nationality';
export const COUNTRY_TEXT = 'country';
export const STATE_TEXT = 'state';
export const CURRENCY_TEXT = 'currency';
export const COUNTRY_CODE_TEXT = 'countryPhoneCode';

export const ApplicationFilterOptions = [
  {
    id: 0,
    name: 'Show All',
  },
  {
    id: JOB_APPLICATION_STATUS.SUBMITTED,
    name: 'Submitted',
  },
  {
    id: JOB_APPLICATION_STATUS.APPROVE,
    name: 'Shortlisted',
  },
  {
    id: JOB_APPLICATION_STATUS.REJECT,
    name: 'Skipped',
  },
  {
    id: JOB_APPLICATION_STATUS.HOLD,
    name: 'Under Review',
  },
];

export const SORT_ORDER = {
  ascend: 'asc',
  descend: 'desc',
};

export const COMPANIES_SORT = {
  name: 'companyName',
  userName: 'representativeName',
  companyName: 'companyName',
};

export const JOBS_SORT = {
  jobName: 'role',
};

export const EVENTS_SORT = {
  name: 'name',
  companyName: 'company',
};

export const ATTENDEES_SORT = {
  candidateName: 'name',
  companyName: 'name',
};

export const SkillsStatusFilter = [
  {
    text: STATUS_ACTIVE_TEXT,
    value: STATUS_ACTIVE,
  },
  {
    text: STATUS_DISABLED_TEXT,
    value: STATUS_DISABLED,
  },
  {
    text: STATUS_PENDING_TEXT,
    value: STATUS_PENDING,
  },
];

// Snooze time list
export const snoozeTimes = [
  {
    id: 1,
    label: '1 day',
    value: '1',
  },
  {
    id: 2,
    label: '2 days',
    value: '2',
  },
  {
    id: 3,
    label: '5 days',
    value: '5',
  },
  {
    id: 4,
    label: '1 week',
    value: '7',
  },
  {
    id: 5,
    label: '2 weeks',
    value: '14',
  },
];

export const recruiterCryptoOptions = [
  {
    id: 1,
    value: 'enable',
    label: 'Allow',
  },
  {
    id: 2,
    value: 'disable',
    label: 'Disable',
  },
];
