/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { getTheme } from '../../utils/common';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  getRoleId,
} from '../../utils/userInfo';

interface Props {
  theme?: string;
  UserAnalytics?: any;
}

export const ApplicationStatusCard: React.FC<Props> = ({
  theme,
  UserAnalytics,
}) => {
  const roleId = getRoleId();

  return (
    <Container theme={theme} role={roleId}>
      <Circle1 />
      <Circle2 />
      <UserAnalytics />
    </Container>
  );
};

interface ContainerProps {
  role: string;
}

const Container = styled.div<ContainerProps>`
  border-radius: 10px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => getTheme(props.theme)};
  ${DISPLAY_FLEX('column', 'center', null)};
  height: ${(props) =>
    checkIfUserIsOrganizer(props.role)
      ? '85%'
      : checkIfUserIsCandidate(props.role)
      ? 'max-content'
      : '100%'};
  gap: 5px;
  margin-top: 4px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: ${(props) =>
      checkIfUserIsOrganizer(props.role)
        ? '88.5%'
        : checkIfUserIsCandidate(props.role)
        ? 'max-content'
        : '100%'};
    gap: ${(props) => (checkIfUserIsOrganizer(props.role) ? '20px' : '5px')};
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    height: 100%;
    gap: 10px;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  width: 180px;
  height: 180px;
  position: absolute;
  z-index: 0;
`;

const Circle1 = styled(Circle)`
  right: -10px;
  top: -40px;
  opacity: 0.1;
  background-color: ${COLORS.WHITE};
`;

const Circle2 = styled(Circle)`
  top: 70px;
  right: -35px;
  opacity: 0.05;
  height: 140px;
  width: 140px;
  background-color: ${COLORS.WHITE};
`;
