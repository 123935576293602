import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Col, message, Row } from 'antd';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { useDispatch } from 'react-redux';
import {
  ADD,
  CANDIDATE_COLOR,
  DELETE,
  ON_SELECT_FROM_ROW,
} from '../../../../constants';
import CreateSkillsSetRow from '../../../../components/ProfileOnboarding/SkillsRow';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import { MediumHeading } from '../../../../components/shared-components/Texts';
import { RightAlignContainer } from '../../../../components/shared-components/RightAlignContainer';
import { COLORS } from '../../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { isMobileSpecialCase1 } from '../../../../helpers/display';
import { largeFontMixin } from '../../../../styles/constants/mixins';
import { StyledButton } from './QuickSubmission';
import { areSkillsRepeating } from '../../../../utils/skill';
import {
  createCandidateQuickProfile,
  getProfileDataWithoutReload,
} from '../../../../store/features/profile';
import { userProfileStore } from '../../../../utils/profile';
import { getRecommendedSkills } from '../../../../service';

export const QuickStartSkillSet = ({
  skills,
  setSkills,
  isApplyModal,
  setStep,
  skillStep,
  setSkillStep,
  requestSkill,
  setRequestSkill,
  skillsList,
  setSkillsList,
  contributeOption,
  setContributeOption,
  isVisible,
  removetheApplyJobData,
}) => {
  const dispatch = useDispatch();
  const { profileData } = userProfileStore();
  const [recommendedSkills, setRecommendedSkills] = useState([]);

  const getSkillIds = async (skillsArr) => {
    let ids = [];
    setRecommendedSkills([]);
    for (let i = 0; i < skillsArr.length; i++) {
      if (!Object.values(skillsArr[i]).includes('delete')) {
        ids.push(skillsArr[i].skill.id);
      }
    }

    if (ids.length) {
      const payload = await getRecommendedSkills(ids);
      setRecommendedSkills(payload);
    }
  };

  const handleSkillsSetOnClick = (tempSkillsArr, type = '') => {
    let skillsArr;
    if (type === ON_SELECT_FROM_ROW) {
      skillsArr = tempSkillsArr;
    } else {
      skillsArr = skills;
    }
    const emptySkills = skillsArr.filter(
      (skill) =>
        !get(skill, 'skill.skill', null) &&
        get(skill, 'operation', DELETE) !== DELETE,
    );
    if (emptySkills.length && type !== ON_SELECT_FROM_ROW) {
      message.error({
        content: 'Please fill all the skills first to add more',
      });
      return;
    }

    getSkillIds(skillsArr);
    if (emptySkills.length > 0) {
      return;
    }
    setSkills((prev) => [
      ...prev,
      {
        id: 1,
        skill: {
          id: 0,
          skill: '',
        },
        operation: ADD,
      },
    ]);
    setSkillStep((prev) => [...prev, {}]);
    setRequestSkill((prev) => [...prev, {}]);
    setSkillsList((prev) => [...prev, { options: [] }]);
    setContributeOption((prev) => [...prev, 0]);
  };

  const handleSubmitSkills = async () => {
    if (areSkillsRepeating(skills)) {
      return;
    }

    const tempSkills = skills.filter(
      (skill) => !!get(skill, 'skill.skill', null),
    );

    const emptySkill = skills.filter(
      (skill) =>
        !get(skill, 'skill.skill', null) &&
        get(skill, 'operation', DELETE) !== DELETE,
    );

    if (emptySkill.length) {
      message.error({
        content: `Empty skill is not allowed, Please select valid skill from the dropdown.`,
      });
      return;
    }

    if (
      !isArray(skills) ||
      tempSkills.filter((skill) => skill.operation !== DELETE).length < 5
    ) {
      message.error({
        content: 'Please add atleast 5 unique skills',
      });
      return;
    }
    setSkills(tempSkills);
    const body = {
      name: profileData.name,
      email: profileData.email,
      skills: tempSkills,
    };

    await removetheApplyJobData();
    dispatch(createCandidateQuickProfile(body)).then(() => {
      dispatch(getProfileDataWithoutReload()).then(() => {
        setStep((prev) => prev + 1);
      });
    });
  };

  return isVisible ? (
    <>
      <StyledSkillSetContainer isApplyModal={isApplyModal}>
        <StyledSkillSetHeader level={4}>
          <Row>
            <Col xs={12}>
              <MediumHeading margin="0px">
                Add Your Skills <MandatoryStar />
              </MediumHeading>
            </Col>
            <Col xs={12}>
              <RightAlignContainer>
                <StyledAddSkillButton onClick={handleSkillsSetOnClick}>
                  + Add
                </StyledAddSkillButton>
              </RightAlignContainer>
            </Col>
          </Row>
        </StyledSkillSetHeader>
        <StyledAddSkillDescription>
          Please add at least 5 skills to Continue!
        </StyledAddSkillDescription>

        <StyledSkillSetLevel>
          <CreateSkillsSetRow
            skills={skills}
            setSkills={setSkills}
            skillStep={skillStep}
            setSkillStep={setSkillStep}
            requestSkill={requestSkill}
            setRequestSkill={setRequestSkill}
            skillsList={skillsList}
            setSkillsList={setSkillsList}
            contributeOption={contributeOption}
            setContributeOption={setContributeOption}
            skillInputWidth={8}
            skillInputMobileWidth={isMobileSpecialCase1() ? 20 : 22}
            isApplyModal={isApplyModal}
            recommendedSkills={recommendedSkills}
            handleOnSelect={handleSkillsSetOnClick}
          />
        </StyledSkillSetLevel>
      </StyledSkillSetContainer>
      {isApplyModal ? (
        <>
          <Row>
            <Col xs={12} lg={12}>
              <StyledPrevButton
                color={CANDIDATE_COLOR}
                onClick={() => setStep((prev) => prev - 1)}
              >
                Previous
              </StyledPrevButton>
            </Col>
            <StyledCol xs={12} lg={12}>
              <StyledButton
                color={CANDIDATE_COLOR}
                onClick={handleSubmitSkills}
              >
                Next
              </StyledButton>
            </StyledCol>
          </Row>
        </>
      ) : null}
    </>
  ) : null;
};

const StyledSkillSetContainer = styled.div`
  margin-bottom: 30px;

  @media (max-width: 500px) {
    .ant-steps-item-title {
      max-width: 75px;
    }
  }

  .ant-upload-list-item {
    margin-bottom: 30px;
  }
  .ant-steps-item {
    .ant-steps-item-icon {
      ${DISPLAY_FLEX('row', 'center', 'center')}
      .ant-steps-icon {
        margin-top: 2px;
      }
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      color: ${CANDIDATE_COLOR} !important;
    }

    .ant-steps-item-icon {
      background: ${COLORS.WHITE};
      border: 3px solid ${CANDIDATE_COLOR};
      color: ${CANDIDATE_COLOR};
      ${DISPLAY_FLEX('row', 'center', 'center')}
    }

    .ant-steps-item-icon {
      .ant-steps-icon {
        color: ${CANDIDATE_COLOR};

        svg {
          fill: ${CANDIDATE_COLOR};
        }
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border: 3px solid ${CANDIDATE_COLOR};
    }

    svg {
      fill: ${CANDIDATE_COLOR};
    }

    .ant-steps-item-tail {
      &::after {
        background-color: ${CANDIDATE_COLOR};
      }
    }

    .ant-steps-item-title {
      color: ${CANDIDATE_COLOR};
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: ${({ isApplyModal }) => (!isApplyModal ? '35px 0' : '0 0 30px')};
  }
`;

// Wrap around MediumHeading
const StyledSkillSetHeader = styled.h1`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin: 6px 0px 0px !important;
  padding-bottom: 5px !important;
`;

const StyledAddSkillButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  color: ${COLORS.BLACK};
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23BFBFBFFF' stroke-width='3' stroke-dasharray='6' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-width: 100%;
  }

  &,
  &:hover {
    border-radius: 4px;
  }

  &:hover,
  &:focus {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23389FFFB8' stroke-width='4' stroke-dasharray='6' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
  }
`;

const StyledAddSkillDescription = styled.span`
  color: ${COLORS.DARK_SILVER};
`;

const StyledSkillSetLevel = styled.div`
  .ant-steps {
    width: 100%;
  }

  .ant-steps-item-tail {
    top: -8px;
  }

  .ant-select-selector {
    border-color: ${COLORS.ARGENT} !important;
    border-radius: 3px !important;
  }

  .ant-steps-item-tail::after {
    height: 4px;
    background-color: ${COLORS.ARGENT} !important;
  }

  .ant-steps-item-icon {
    border: 2px solid ${COLORS.ARGENT};
  }

  .ant-select {
    margin-bottom: 20px;
  }
`;

export const StyledPrevButton = styled.button`
  font-size: 14px;
  height: 43px;
  width: 100%;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  background-color: #fff;
  line-height: 27px;
  max-width: 168px;
  cursor: pointer;
  ${largeFontMixin}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 90%;
    height: 40px;
  }
`;

export const StyledCol = styled(Col)`
  text-align: right;
`;
