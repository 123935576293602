import { createSlice } from '@reduxjs/toolkit';
import { getCandidateProfile } from '../../service/recruiter';

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.profileData = action.payload;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const candidateProfileSlice = createSlice({
  name: 'candidateProfile',
  initialState: {
    isLoading: false,
    profileData: {},
    error: null,
  },
  reducers: { onStart, onSuccess, onError },
});

export default candidateProfileSlice.reducer;

export const getCadidateProfileData = (userId) => async (dispatch) => {
  try {
    dispatch(candidateProfileSlice.actions.onStart());
    const profileData = await getCandidateProfile(userId);
    dispatch(
      candidateProfileSlice.actions.onSuccess(profileData.data.payload.profile),
    );
  } catch (err) {
    dispatch(candidateProfileSlice.actions.onError(err.toString()));
  }
};
