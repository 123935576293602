import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import { CustomPaginationProps } from '../../../interfaces/components/Common';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../../constants';

export const CustomPagination: React.FC<CustomPaginationProps> = ({
  currentPage = DEFAULT_PAGE,
  totalItems = DEFAULT_LIMIT,
  onPageChange,
  currentLimit = DEFAULT_LIMIT,
}) => {
  const onChange = (pageNumber: number, pageSize: number | undefined): void => {
    onPageChange(pageNumber, pageSize);
  };

  return (
    <StyledWrapper>
      <Pagination
        showSizeChanger
        showQuickJumper
        defaultCurrent={DEFAULT_PAGE}
        current={currentPage}
        total={totalItems}
        onChange={onChange}
        pageSize={currentLimit}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin: 20px 0;
`;
