import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PriorityIcon } from '../../../components/svgicons';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';

export const PriorityJobs = ({ priorityJobs }: any) => {
  const { highPriorityJobs, lowPriorityJobs } = priorityJobs || {};
  const history = useHistory();
  return (
    <StyledPriority>
      <div>
        <PriorityIcon /> <h1>Priority Jobs</h1>
      </div>
      <div>
        <p
          role="none"
          onClick={() => history.push('/master-admin/jobs/?priority=high')}
        >
          High Priority: <StyledRed>{highPriorityJobs}</StyledRed>
        </p>
        <p
          role="none"
          onClick={() => history.push('/master-admin/jobs/?priority=low')}
        >
          Low Priority: <StyledGreen>{lowPriorityJobs}</StyledGreen>
        </p>
      </div>
    </StyledPriority>
  );
};

const StyledPriority = styled.div`
  p,
  h1,
  span {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  h1 {
    margin-bottom: 0;
    color: ${COLORS.DEEP_SAFFRON};
  }

  div {
    &:first-child {
      display: flex;
      align-items: center;
      gap: 7px;
    }

    &:nth-child(2) {
      margin: 15px 0 0 24px;
    }
  }

  p {
    margin-bottom: 12px;
    cursor: pointer;
  }
`;

const StyledRed = styled.span`
  color: ${COLORS.RED_3};
`;

const StyledGreen = styled.span`
  color: ${COLORS.CARIBBEAN_GREEN};
`;
