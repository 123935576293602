/* eslint-disable no-nested-ternary */
import React from 'react';
import { Tooltip } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  BreifCaseOutlinedIcon,
  EarthIcon,
  UserIcon,
} from '../../../components/svgicons';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';

interface CardProps {
  name: string;
  users: number;
  jobs?: number;
  events?: number;
  percentage: number;
  id: string;
  isDateSelected: boolean;
  jobsGrowth?: number;
  eventsGrowth?: number;
  link: string;
}

const Card: React.FC<CardProps> = ({
  name,
  users,
  jobs,
  percentage,
  events,
  isDateSelected,
  jobsGrowth = 0,
  eventsGrowth = 0,
  link,
}) => {
  let title =
    'Total organizers sums admin companies & their crews, events sums all active events';

  if (name === 'Attendees') {
    title = 'Total candidates';
  } else if (name === 'Recruiters') {
    title =
      'Total recruiters sums admin companies & their crews, jobs sums all active jobs';
  }

  const history = useHistory();
  return (
    <StyledCard onClick={() => history.push(`/master-admin/${link}`)}>
      <StyledHeader icon={name}>
        {name === 'Attendees' ? (
          <UserIcon />
        ) : name === 'Recruiters' ? (
          <BreifCaseOutlinedIcon />
        ) : (
          <EarthIcon />
        )}
        <h3>{name}</h3>
        <Tooltip title={title}>
          <EyeInvisibleOutlined />
        </Tooltip>
      </StyledHeader>
      <StyledStatsContainer>
        <div>
          <StyledNumber>{users}</StyledNumber>
          <StyledLabel>Users</StyledLabel>
          {isDateSelected &&
            (percentage > 0 ? (
              <StyledGreenStats>
                <StyledGreenTriangle /> <span>{percentage}%</span>
              </StyledGreenStats>
            ) : percentage === 0 ? (
              <StyledOrangeStats>{percentage}</StyledOrangeStats>
            ) : (
              <StyledRedStats>
                <StyledRedTriangle /> <span>{percentage}%</span>
              </StyledRedStats>
            ))}
        </div>

        {name === 'Recruiters' ? (
          <div>
            <StyledNumber>{jobs}</StyledNumber>
            <StyledLabel>Jobs</StyledLabel>
            {isDateSelected &&
              (jobsGrowth > 0 ? (
                <StyledGreenStats>
                  <StyledGreenTriangle /> <span>{jobsGrowth}%</span>
                </StyledGreenStats>
              ) : jobsGrowth === 0 ? (
                <StyledOrangeStats>{jobsGrowth}</StyledOrangeStats>
              ) : (
                <StyledRedStats>
                  <StyledRedTriangle /> <span>{jobsGrowth}%</span>
                </StyledRedStats>
              ))}
          </div>
        ) : (
          name === 'Organizers' && (
            <div>
              <StyledNumber>{events}</StyledNumber>
              <StyledLabel>Events</StyledLabel>
              {isDateSelected &&
                (eventsGrowth > 0 ? (
                  <StyledGreenStats>
                    <StyledGreenTriangle /> <span>{eventsGrowth}%</span>
                  </StyledGreenStats>
                ) : eventsGrowth === 0 ? (
                  <StyledOrangeStats>{eventsGrowth}</StyledOrangeStats>
                ) : (
                  <StyledRedStats>
                    <StyledRedTriangle /> <span>{eventsGrowth}%</span>
                  </StyledRedStats>
                ))}
            </div>
          )
        )}
      </StyledStatsContainer>
    </StyledCard>
  );
};

export const DashboardStats = ({ analytics, isDateSelected }: any) => {
  const {
    attendeeCount,
    recruiterCount,
    OrganizerCount,
    jobsAdded,
    eventsAdded,
    attendeeCountGrowth,
    recruiterCountGrowth,
    jobsAddedGrowth,
    eventsAddedGrowth,
    OrganizerCountGrowth,
  } = analytics || {};

  return (
    <StyledMain>
      <Card
        id="1"
        name="Attendees"
        users={attendeeCount}
        percentage={Math.round(attendeeCountGrowth)}
        isDateSelected={isDateSelected}
        link="candidate/?page=1&limit=10&search="
      />
      <Card
        id="2"
        name="Recruiters"
        users={recruiterCount}
        jobs={jobsAdded}
        percentage={Math.round(recruiterCountGrowth)}
        isDateSelected={isDateSelected}
        jobsGrowth={Math.round(jobsAddedGrowth)}
        link="recruiter/?page=1&limit=10&search="
      />
      <Card
        id="3"
        name="Organizers"
        users={OrganizerCount}
        events={eventsAdded}
        percentage={Math.round(OrganizerCountGrowth)}
        isDateSelected={isDateSelected}
        eventsGrowth={Math.round(eventsAddedGrowth)}
        link="organizer/?page=1&limit=10&search="
      />
    </StyledMain>
  );
};

const StyledMain = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const StyledCard = styled.div`
  border-radius: 6px;
  padding: 10px;
  flex-grow: 1;
  border: 1px solid ${COLORS.GAINSBORO};

  &:hover {
    background: ${COLORS.BRIGHT_GRAY_2};
    cursor: pointer;
  }

  h3 {
    margin-bottom: 0;
    color: ${COLORS.GREY_3};
    font-weight: 600;
  }
`;

const StyledHeader = styled.div<{ icon: String }>`
  display: flex;
  gap: 7px;
  align-items: center;

  svg {
    fill: ${({ icon }) =>
      icon === 'Attendees'
        ? COLORS.BLUE_VIOLET
        : icon === 'Recruiters'
        ? COLORS.GOLDEN_POPPY
        : COLORS.CARIBBEAN_GREEN_2};
    width: 15px;
    height: 15px;
  }
`;

const StyledStatsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 5px 0;
`;

const StyledTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 13px solid;
`;

const StyledStats = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;

  span {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }
`;

const StyledGreenTriangle = styled(StyledTriangle)`
  border-bottom-color: ${COLORS.TRUE_GREEN_3};
`;

const StyledGreenStats = styled(StyledStats)`
  span {
    color: ${COLORS.TRUE_GREEN_3};
  }
`;

const StyledRedStats = styled(StyledStats)`
  span {
    color: ${COLORS.RED_4};
  }
`;

const StyledRedTriangle = styled(StyledTriangle)`
  border-bottom-color: ${COLORS.RED_4};
  transform: rotate(180deg);
`;

const StyledNumber = styled.span`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: 22px;
  color: ${COLORS.GREY_3};
`;

const StyledLabel = styled.p`
  color: ${COLORS.GRAY_2};
`;

const StyledOrangeStats = styled.p`
  color: orange;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-top: 10px;
`;
