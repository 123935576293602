import React, { useEffect, useContext, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import ConfigContext from '../store/configContext';
import CandidateProfile from './Candidate/CandidateProfile';
// import Calendar from './Candidate/Calendar';
// import ChatManagement from './Candidate/ChatManagement';
// import GroupChatManagement from './Candidate/GroupChatManagement';
import { userProfileStore } from '../utils/profile';
import ViewEvent from '../components/Events/ViewEvent/ViewEvent';
import DashboardIcon from '../assets/icons-and-sets/dashboard.svg';
// import EventsIcon from '../assets/icons-and-sets/events.svg';
import BriefcaseIcon from '../assets/icons-and-sets/briefcase.svg';
// import CalendarIcon from '../assets/icons-and-sets/calendar.svg';
import NftTokenIcon from '../assets/icons-and-sets/nft-token.svg';
// import ChatIcon from '../assets/icons-and-sets/chat.svg';
import { CandidateRoutes } from '../constants/routes';
import { Notifications } from '../components/shared-components/Notifications/Notifications';
import JobDetails from './Candidate/JobDetails';
import PrivatePageNotFound from './PrivatePageNotFound';
import { Support } from '../components/shared-components/Support';
import HelpDoc from '../components/Common/HelpDoc';
// import Notification from '../components/Notification/Notification';
import { CreateOrChangePassword } from '../components/shared-components/CreateOrChangePassword';
import { checkIfUserIsCandidate, getRoleId } from '../utils/userInfo';
import { redirectToProperUserRole } from '../utils/common';
import { safeLoad } from '../utils/loader';
import { RETRY_ATTEMPTS, RETRY_INTERVALS } from '../constants';
import PaymentStatus from './PaymentStatus';
import { StyledModal } from '../components/shared-components/Modal';
import { checkIfUserIsNew } from '../service/candidate';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import Events from './Candidate/Events/Events';
import MyNFTs from './Candidate/MyNFTs';

const Dashboard = React.lazy(() =>
  safeLoad(
    () => import('./Candidate/Dashboard'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);
/*
const Events = React.lazy(() =>
  safeLoad(
    () => import('./Candidate/Events/Events'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);
*/
const ExploreEvents = React.lazy(() =>
  safeLoad(
    () => import('../components/Events/RecommendedEvents/ExploreEvents'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const Jobs = React.lazy(() =>
  safeLoad(() => import('./Candidate/Jobs'), RETRY_ATTEMPTS, RETRY_INTERVALS),
);

const Candidate = () => {
  const { navigationList, setNavigationList, newUser, setNewUser } = useContext(
    ConfigContext,
  );
  const { profileData } = userProfileStore();
  const roleId = getRoleId();
  const [modal, setModal] = React.useState(false);

  if (roleId && !checkIfUserIsCandidate(roleId)) {
    redirectToProperUserRole(roleId);
  }

  function showModal() {
    setModal(true);
  }

  useEffect(() => {
    async function checkNewUser() {
      const { isCandidateNew } = await checkIfUserIsNew();

      setNewUser(isCandidateNew);
    }

    checkNewUser();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setNavigationList([
      {
        Icon: DashboardIcon,
        title: 'Dashboard',
        route: CandidateRoutes.candidate,
        exact: true,
        Component: Dashboard,
      },
      /* {
        Icon: EventsIcon,
        title: 'Events',
        route: CandidateRoutes.event,
        exact: true,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? Events : null,
      },
      */
      {
        Icon: BriefcaseIcon,
        title: 'Job Results',
        route: `${CandidateRoutes.jobResults}`,
        disabled: newUser,
        callOnDisabled: () => {
          return newUser ? showModal() : null;
        },
        Component: Jobs,
      },
      {
        Icon: NftTokenIcon,
        title: 'My NFTs',
        route: CandidateRoutes.myNfts,
        Component: MyNFTs,
      },
      /*
      {
        Icon: CalendarIcon,
        title: 'My Calendar',
        route: CandidateRoutes.calender,
        Component: Calendar,
      },
     
      {
        Icon: ChatIcon,
        title: 'Chat',
        route: CandidateRoutes.chat,
        exact: true,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? ChatManagement : null,
      },
      {
        title: 'Group Chat Management',
        route: CandidateRoutes.groupChat,
        selectionPath: CandidateRoutes.chat,
        populate: false,
        Component: onBoarding(profileData) ? GroupChatManagement : null,
      },
       */
      {
        title: 'My Profile',
        route: CandidateRoutes.profile,
        populate: false,
        Component: CandidateProfile,
      },
      {
        title: 'Events',
        route: '/candidate/events/',
        exact: true,
        populate: false,
        Component: Events,
      },
      {
        title: 'View Event',
        route: '/candidate/events/view/:id',
        selectionPath: CandidateRoutes.event,
        populate: false,
        Component: ViewEvent,
      },
      /*{
        title: 'Profile Quick Start',
        route: CandidateRoutes.profileQuickStart,
        populate: false,
        Component: QuickStart,
      },
      */
      {
        title: 'Explore Events',
        route: CandidateRoutes.exploreEvent,
        selectionPath: CandidateRoutes.event,
        populate: false,
        Component: ExploreEvents,
      },
      {
        title: 'Notifications Page',
        route: CandidateRoutes.notifications,
        populate: false,
        Component: Notifications,
      },
      {
        title: 'Job Details',
        route: CandidateRoutes.jobDetails,
        selectionPath: CandidateRoutes.jobDetails,
        populate: false,
        Component: JobDetails,
      },
      {
        title: 'Help Doc',
        route: CandidateRoutes.helpDoc,
        populate: false,
        Component: HelpDoc,
      },
      {
        title: 'Support Page',
        route: CandidateRoutes.support,
        populate: false,
        Component: Support,
      },
      {
        title: 'Modify Password',
        route: CandidateRoutes.changePassword,
        populate: false,
        Component: CreateOrChangePassword,
      },
      {
        title: 'Payment Status',
        route: CandidateRoutes.paymentStatus,
        populate: false,
        Component: PaymentStatus,
      },
      {
        title: 'Page Not Found',
        route: '*',
        populate: false,
        Component: PrivatePageNotFound,
      },
    ]);
    // eslint-disable-next-line
  }, [setNavigationList, profileData, newUser]);

  return (
    <div>
      <Suspense fallback={<Skeleton />}>
        <Switch>
          {navigationList.map((navItem, idx) => {
            const { route, Component, exact = false } = navItem;
            return (
              <Route
                key={idx.toString()}
                exact={exact}
                path={route}
                component={Component}
              />
            );
          })}
        </Switch>
      </Suspense>
      <Modal footer={null} visible={modal} onCancel={() => setModal(false)}>
        Select a live event to find jobs relevant to your skills!
      </Modal>
    </div>
  );
};

export default Candidate;

const Modal = styled(StyledModal)`
  font-weight: 500;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 50px;
    width: 90% !important;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-close-icon {
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
