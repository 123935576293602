import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';
import {
  getCandidateBookedEventsData,
  getRecruiterBookedEventsData,
} from '../../store/features/events';
import {
  checkIfUserIsRecruiter,
  getCompanyId,
  getRoleId,
  getUserClassWithRoleId,
} from '../../utils/userInfo';
import { EventDetailsCardProps } from '../../interfaces/components/EventDetailsCard';
import JoinEventButton from '../Events/ViewEvent/BookAndPromoteEvent/JoinEventButton';
import { Container } from './PageContainer';
import { MediumText } from './Texts';
import { DateAndTypeSection } from './Event/DateAndTypeSection';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { userProfileStore } from '../../utils/profile';
import { PrimaryButton } from './Button';
import {
  getLinkByRole,
  getLinkTextByRole,
  hasJoinedTheEvent,
} from '../../utils/eventDetails';
import { getEventsPageByRoleAndEvent } from '../../utils/event';

export const EventDetailsCard: React.FC<EventDetailsCardProps> = ({
  roleId,
  status,
  eventDetails,
  category = 'end',
}: EventDetailsCardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = getRoleId();

  const companyId = getCompanyId();

  const { profileData } = userProfileStore();

  const handleJoinClick = () => {
    dispatch(
      checkIfUserIsRecruiter(roleId)
        ? getRecruiterBookedEventsData()
        : getCandidateBookedEventsData(),
    );
  };

  const handleLinkClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    history.push(getLinkByRole(roleId, eventDetails.id));
  };

  const EventStatus: React.FC<any> = ({ localStatus }) => {
    switch (localStatus) {
      case 'ongoing': {
        const candidateId = get(profileData, 'userId', null);
        const id = checkIfUserIsRecruiter(roleId) ? companyId : candidateId;
        // Checked join status of the event and user
        const joinStatus = hasJoinedTheEvent(eventDetails, id, roleId);

        if (!joinStatus) {
          return (
            <JoinEventButton
              event={eventDetails}
              roleId={roleId}
              callback={handleJoinClick}
              isEventCard
            />
          );
        }
        return (
          <PrimaryButton
            theme={getUserClassWithRoleId(roleId)}
            onClick={handleLinkClick}
          >
            {getLinkTextByRole(roleId)}
          </PrimaryButton>
        );
      }
      case 'ended':
        return (
          <StyledEndedEventDiv>
            <span>Ended</span>
          </StyledEndedEventDiv>
        );
      default:
        return null;
    }
  };
  /*
  const checkStatus = (category: string) => {
    if (category) {
      return ['ongoing', 'past'].includes(category);
    }
  };

  
  const fetchEventAnalytics = (visible: boolean) => {
    if (visible && checkStatus(category)) {
      dispatch(getEventAnalyticsData(role, [eventDetails.id]));
    }
  };
*/
  const handleRedirect = (id: number) => {
    history.push(getEventsPageByRoleAndEvent(role, id));
  };

  return (
    <>
      {/*
      <Tooltip
      title={checkStatus(category) ? <PastEventAnalyticsOverlay /> : ''}
      onVisibleChange={fetchEventAnalytics}
      overlayInnerStyle={{ padding: 0, width: '200px', borderRadius: '5px' }}
      placement="topRight"
    >
  
    </Tooltip>
    */}

      <StyledContainer
        margin="15px 0 0"
        padding="16px 25px"
        onClick={() => handleRedirect(eventDetails.id)}
      >
        <Header>
          <StyledEventName>
            <MediumText>{eventDetails.name}</MediumText>
          </StyledEventName>
          <EventStatus localStatus={status} />
        </Header>
        <Details>
          <DateAndTypeSection event={eventDetails} />
        </Details>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(Container)`
  position: relative;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(16, 25)}
  }
`;

const Header = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'flex-start')};

  button {
    height: 35px;
  }
`;

const StyledEventName = styled.div`
  color: ${COLORS.EERIE_BLACK};
  font-weight: ${FONT_WEIGHTS.BOLD};
  margin-bottom: 16px;
`;

const StyledEndedEventDiv = styled.div`
  background-color: ${COLORS.WHITE_SMOKE};
  color: ${COLORS.GRAY};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${PADDING(5, 20)};
  border-radius: 10px;
  margin-right: -8px;

  span {
    font-size: ${FONT[12]} !important;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]} !important;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[12]} !important;
    }
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: block;
  }
`;
