import api from './api';
import { Method, APIs } from '../constants';

export const sendSupportMail = async (body) => {
  return api({
    method: Method.post,
    url: APIs.support,
    body,
  });
};
