import React, { useEffect } from 'react';
import { message, Spin, Space } from 'antd';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import parseInt from 'lodash/parseInt';
import { useHistory } from 'react-router';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { getUrlVars } from '../../utils/common';
import { getUserLocation } from '../../service/auth';
import { signupWithOTPData } from '../../store/features/auth';
import { getProfileData } from '../../store/features/profile';

const SignupInviteModal = (props) => {
  const { email, token, role } = getUrlVars(get(props, 'location.search'));
  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticated, user: userData } = useSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (isAuthenticated && (sessionStorage.length || localStorage.length)) {
      dispatch(getProfileData()).then(() => {
        const { roleId: userRole } = userData;
        const crisp = document.getElementById('crisp-chatbox');
        if (crisp) {
          crisp.outerHTML = '';
          crisp.remove();
        }
        if (checkIfUserIsOrganizer(userRole)) history.push(`/organizer/`);
        else if (checkIfUserIsRecruiter(userRole)) history.push(`/recruiter/`);
        else if (checkIfUserIsCandidate(userRole)) history.push(`/candidate/`);
        else message.error('...');
      });
    }

    // eslint-disable-next-line
  }, [isAuthenticated, userData]);

  useEffect(() => {
    if (token) {
      (async () => {
        const { country_name: country } = await getUserLocation();
        dispatch(signupWithOTPData({ email, token, country }, parseInt(role)));
      })();
    }

    // eslint-disable-next-line
  }, [token]);

  return (
    <div className="center-screen">
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default SignupInviteModal;
