import React from 'react';
import { Button, Space, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { STATUS_ACTIVE } from '../../constants';
import { checkIfUserIsMasterAdmin, checkIfUserIsSuperAdmin } from '../userInfo';

export const makeAdminTableData = (
  users = [],
  handleEditConfirmation,
  DeleteUserConfirm,
  handleDeleteUser,
) => {
  return users.map((user) => {
    const {
      id,
      masterAdmin: {
        country,
        updatedAt,
        department,
        email,
        name,
        phone,
        status,
      },
    } = user;

    const payload = {
      id,
      country,
      updatedAt,
      department,
      email,
      name,
      phone,
      status,
    };

    return {
      ...payload,
      status:
        status === STATUS_ACTIVE ? (
          <Tag color="blue">Active</Tag>
        ) : (
          <Tag>Disabled</Tag>
        ),
      action: (
        <Space size="small">
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditConfirmation(payload)}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => DeleteUserConfirm(payload, handleDeleteUser)}
            danger
          />
        </Space>
      ),
    };
  });
};

export const checkIfUserIsSuperAdminOrMasterAdmin = (roleId) => {
  return checkIfUserIsMasterAdmin(roleId) || checkIfUserIsSuperAdmin(roleId);
};
