import React, { useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDisconnect } from 'wagmi';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { userProfileStore } from '../../utils/profile';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { LinkWalletToProfile } from '../../service/poap';
import { getProfileDataWithoutReload } from '../../store/features/profile';
import Notification from '../Notification/Notification';
import { getRoleId } from '../../utils/userInfo';
import { checkIfUserIsCandidate } from '../../utils/userInfo';
import { LinkWalletToCompanyProfile } from '../../service/wallet';
import { COLORS } from '../../styles/constants/colors';
import { WalletButtonProps } from '../../interfaces/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getTheme } from '../../utils/common';

const { Custom } = ConnectButton;

const CustomButton: React.FC<any> = ({
  account,
  chain,
  openChainModal,
  openConnectModal,
  mounted,
  isEditProfile = false,
  editProfileTheme,
}) => {
  const { disconnect } = useDisconnect();
  const { profileData } = userProfileStore();
  const dispatch = useDispatch();
  const walletAddress = get(profileData, 'walletAddress');
  const roleId = getRoleId();

  useEffect(() => {
    const address = get(account, 'address', '');
    if (address && !isEmpty(profileData) && address !== walletAddress) {
      // api request to backend
      // refetch profile data
      (async () => {
        if (checkIfUserIsCandidate(roleId)) {
          await LinkWalletToProfile(address);
        } else {
          await LinkWalletToCompanyProfile(address);
        }
        Notification(
          'success',
          walletAddress ? 'Wallet updated' : 'Wallet connected',
        );
        dispatch(getProfileDataWithoutReload());
      })();
    }
    // eslint-disable-next-line
  }, [get(account, 'address', '')]);

  const handleWalletChange = () => {
    disconnect();
    setTimeout(() => openConnectModal());
  };

  if (mounted) {
    if (chain && chain.unsupported) {
      return (
        <StyledWalletButton data-network="wrong" onClick={openChainModal}>
          Wrong network
        </StyledWalletButton>
      );
    }
    /** Had to omit change wallet because of that the issue was coming, If someone wants to change then only disconnect first then connect*/
    if (account && account.address) {
      return (
        <StyledWalletButton
          isEditProfile={isEditProfile}
          editProfileTheme={editProfileTheme}
          onClick={handleWalletChange}
        >
          Change Wallet
        </StyledWalletButton>
      );
    }

    return (
      <StyledWalletButton
        isEditProfile={isEditProfile}
        editProfileTheme={editProfileTheme}
        onClick={openConnectModal}
      >
        Connect Wallet
      </StyledWalletButton>
    );
  }
  return null;
};

const WalletButton: React.FC<any> = ({ isEditProfile, editProfileTheme }) => {
  return (
    <>
      <Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          mounted,
        }) => {
          return (
            <CustomButton
              account={account}
              chain={chain}
              openAccountModal={openAccountModal}
              openChainModal={openChainModal}
              openConnectModal={openConnectModal}
              mounted={mounted}
              isEditProfile={isEditProfile}
              editProfileTheme={editProfileTheme}
            />
          );
        }}
      </Custom>
    </>
  );
};

export default React.memo(WalletButton);

const StyledWalletButton = styled.button<WalletButtonProps>`
  min-width: 133px;
  height: 40px;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  background: linear-gradient(266.76deg, #14ABF0 1.67%, #8465FE 47.56%, #D528E7 109.85%);
  border-radius: 4px;
  border: none;
  color: white;
  margin-right: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  padding: 0 10px;
  ${({ isEditProfile, editProfileTheme }) => {
    if (isEditProfile) {
      return `
        background : ${getTheme(editProfileTheme)};
        transition: all 0.3s ease-in-out;
        &:hover{
          background : ${COLORS.WHITE};
          color : ${getTheme(editProfileTheme)};
          border : 1px solid ${getTheme(editProfileTheme)};          
        }
      `;
    }
  }}

  &[data-network='wrong'] {
    background: ${COLORS.TART_ORANGE};
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    ${({ isEditProfile }) => (isEditProfile ? `height: 32px;` : '')}
  }
`;
