/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* Disabling the above as the array used here will not change dynamically */
// @ts-nocheck
import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useClearCache } from 'react-clear-cache';
import { message } from 'antd';
import { Detector } from 'react-detect-offline';
import TagManager from 'react-gtm-module';
import get from 'lodash/get';
import { ConfigProvider } from './store/configContext';
import { saveLoginDataToStorage, setCurrentUser } from './store/features/auth';
import { getProfileData } from './store/features/profile';
import { protectedRoutes, publicRoutes } from './routes';
import ProtectedRoute from './routes/ProtectedRoute';
import Config from './config';
import ErrorBoundaryFallback from './containers/ErrorBoundaryFallback';
import {
  getCountryCodeData,
  getCountryData,
  getCurrencyData,
  setApplyJobData,
} from './store/features/commons';
import './App.scss';
import HelmetMetaData from './components/Common/HelmetMetaData';
import Message from './components/messages/message';
import {
  InternetConnectionOnlineText,
  InternetConnectionOfflineText,
  OfflineOnlinePolling,
  PRODUCTION,
  STAGING,
  QA,
} from './constants/index';
import { setUserAnonymousName } from './store/features/chat';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
  checkIfUserIsOrganizer,
} from './utils/userInfo';
import { getLocalStorageItemWithExpiry } from './utils/chat';
import '@rainbow-me/rainbowkit/dist/index.css';
import { getCryptoIncentivePlan } from './store/features/cryptoPlan';
import {
  getIncomingBalanceStore,
  getWalletInformation,
} from './store/features/virtualWallet';

/** There are two testnets goerli and sepora we are considering goerli for now */
let providers = [];
if (Config.appEnv === QA || Config.appEnv === STAGING) {
  providers = [chain.goerli];
} else {
  // As USDC is only supported in ethereum mainnet thats why it is mainnet, for polygon it is USDT
  providers = [chain.mainnet];
}
const { chains, provider } = configureChains(providers, [
  alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
]);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const App = () => {
  const dispatch = useDispatch();
  const { isLatestVersion, emptyCacheStorage } = useClearCache({ auto: true });

  if (localStorage.userInfo || sessionStorage.userInfo) {
    dispatch(setCurrentUser());
    dispatch(setApplyJobData());

    if (getLocalStorageItemWithExpiry('anonymousName')) {
      dispatch(setUserAnonymousName());
    }
  }

  let initialRender = true;

  const handleMessageFromMarketing = (e) => {
    const origin = get(e, 'origin', '');
    if (origin !== Config.mainWebsiteUrl) return;
    // Update the div element to display the message.
    const isLoginData = get(e, 'data.isLoginData', false);
    const data = get(e, 'data.data', null);
    if (isLoginData && data) {
      saveLoginDataToStorage(data, true);
    }
  };

  useEffect(() => {
    // Show message for new app version
    if (!isLatestVersion) {
      message.open({
        type: 'info',
        className: 'app-version-update-message',
        content: 'New app version available! Click here to update.',
        duration: 0,
        onClick: () => {
          emptyCacheStorage();
          message.destroy();
        },
      });
    }
  }, [isLatestVersion]);

  useEffect(() => {
    // Initialize hotjar

    if (Config.gtmId) {
      const tagManagerArgs = {
        gtmId: Config.gtmId,
      };

      TagManager.initialize(tagManagerArgs);
    }

    if (Config.appEnv === PRODUCTION || Config.appEnv === STAGING) {
      hotjar.initialize(Config.hjid, Config.hjsv);
    }

    if (Config.appEnv === PRODUCTION) {
      const documentObject = document;
      const twitterScript = documentObject.createElement('script');
      const fbScript = documentObject.createElement('script');
      const fbMeta = documentObject.createElement('meta');
      if (twitterScript && documentObject) {
        twitterScript.innerHTML = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      // Insert Twitter Pixel ID and Standard Event data below
      twq('init','${Config.twitterAnalyticsKey}');
      twq('track','PageView');`;
        documentObject
          .getElementsByTagName('head')[0]
          .appendChild(twitterScript);
      }
      if (fbScript && documentObject) {
        fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${Config.fbPixelsKey}');
      fbq('track', 'PageView');
      `;
        documentObject.getElementsByTagName('head')[0].appendChild(fbScript);
      }
      if (fbMeta && documentObject) {
        fbMeta.content = 'mvfj3gbine7ju0zzzet09vxc0auxpp';
        fbMeta.name = 'facebook-domain-verification';
        documentObject.getElementsByTagName('head')[0].appendChild(fbMeta);
      }
      if (documentObject) {
        const st2 = documentObject.createElement('script');
        st2.type = 'text/javascript';
        st2.innerHTML = `twttr.conversion.trackPid('o6q5z', { tw_sale_amount: 0, tw_order_quantity: 0 });`;
        documentObject.getElementsByTagName('head')[0].appendChild(st2);

        const googleAds = document?.createElement('script');
        googleAds.async = true;
        googleAds.src =
          'https://www.googletagmanager.com/gtag/js?id=AW-10928506931';

        documentObject.getElementsByTagName('head')[0].appendChild(googleAds);
        const googleAdsEvents = documentObject?.createElement('script');
        googleAdsEvents.innerHTML = `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
           gtag('config', 'AW-10928506931');
           `;
        documentObject
          .getElementsByTagName('head')[0]
          .appendChild(googleAdsEvents);
      }
    }
    if (localStorage.userInfo || sessionStorage.userInfo) {
      dispatch(getProfileData());
      const userInfo = localStorage.userInfo
        ? JSON.parse(localStorage.userInfo)
        : JSON.parse(sessionStorage.userInfo);

      if (checkIfUserIsRecruiter(userInfo.roleId)) {
        dispatch(getCryptoIncentivePlan());
        dispatch(getWalletInformation());
      }
      if (
        checkIfUserIsCandidate(userInfo.roleId) ||
        checkIfUserIsOrganizer(userInfo.roleId)
      ) {
        dispatch(getWalletInformation());
        dispatch(getIncomingBalanceStore());
      }
      if (
        !getLocalStorageItemWithExpiry('anonymousName') &&
        userInfo &&
        checkIfUserIsCandidate(userInfo.roleId)
      ) {
        // console.log(data);
        dispatch(setUserAnonymousName(true));
      }
    }

    window.addEventListener('message', handleMessageFromMarketing);
  }, []);

  useEffect(() => {
    dispatch(getCountryCodeData());
    dispatch(getCountryData());
    dispatch(getCurrencyData());
  }, [dispatch]);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <ConfigProvider>
          <Router basename="/">
            <HelmetMetaData />
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Switch>
                {protectedRoutes.map((route, index) => (
                  <ProtectedRoute
                    key={index}
                    path={route.path}
                    component={route.component}
                  />
                ))}

                {publicRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    component={route.component}
                    exact={!!route.exact}
                  />
                ))}
              </Switch>
            </ErrorBoundary>
            <div>
              <Detector
                polling={OfflineOnlinePolling}
                render={({ online }) => {
                  if (!initialRender || !online) {
                    Message(
                      'info',
                      online
                        ? InternetConnectionOnlineText
                        : InternetConnectionOfflineText,
                      10,
                    );
                    initialRender = false;
                  }
                  return null;
                }}
              />
            </div>
          </Router>
        </ConfigProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
