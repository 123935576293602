import React from 'react';
import styled from 'styled-components';
import WhiteLogo from '../../assets/svg/recorem_white_logo.svg';
import DarkLogo from '../../assets/svg/recorem_logo.svg';
import Arrow from '../../assets/svg/arrow.svg';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getRoleId } from '../../utils/userInfo';
import { getUserColorWithRoleId } from '../../styles/utils';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { LightImageFilter } from '../../styles/constants/styles';
import { FONT } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { ResponsiveModalHeaderProps } from '../../interfaces/components/ResponsiveModalHeader';

export const ResponsiveModalHeader: React.FC<ResponsiveModalHeaderProps> = ({
  handleModalCancel,
  title,
  color = null,
}) => {
  const roleId = getRoleId();
  const isLight = color === 'white';
  const logo = isLight ? DarkLogo : WhiteLogo;
  return (
    <Container color={color || getUserColorWithRoleId(roleId)}>
      <CloseIconContainer onClick={(e) => handleModalCancel?.(e)}>
        <CloseIcon isLight={isLight} src={Arrow} alt="close-icon" />
      </CloseIconContainer>
      <div>
        <LogoTitleContainer>
          <Logo alt="logo" src={logo} />
        </LogoTitleContainer>
        <SideLogoContainer>
          <Title isLight={isLight}>{title}</Title>
        </SideLogoContainer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100px;
  display: none;
  background-color: ${(props) => props.color};
  position: relative;
  border-bottom: 1px solid ${COLORS.GRANITE_GRAY};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('column')}
  }
`;

const CloseIconContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}

  align-content: center;
  margin-top: 10px;
  padding-top: 10px;
`;

const CloseIcon = styled.img<{ isLight: Boolean }>`
  width: 24px;
  height: 22px;
  transform: scale(-1, -1);
  opacity: 0.7;
  ${({ isLight }) =>
    isLight
      ? `filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%)`
      : `filter: ${LightImageFilter}`}
`;

const LogoTitleContainer = styled.div`
  padding: 20px;
  width: fit-content;
  ${MARGIN(null, 0)}

  float: left;
  right: -10px;
`;

const Logo = styled.img`
  width: 127px;
  height: 22px;
`;

const Title = styled.p<{ isLight: Boolean }>`
  font-size: ${FONT[12]};
  text-align: left;
  ${MARGIN(null, null, null, 35)}

  letter-spacing: 0;
  color: ${({ isLight }) => (!isLight ? `${COLORS.WHITE}` : `${COLORS.BLACK}`)};
  opacity: 0.8;
`;

const SideLogoContainer = styled.div`
  ${MARGIN(null, 0)}

  float: right;
  position: relative;
  align-self: right;
  right: 30px;
  bottom: -19px;
  width: fit-content;
`;
