/* eslint-disable no-nested-ternary */
import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import CandidateProfileCompletionProgress from '../Common/CandidateProfileCompletionProgress';
import { IconButton, PrimaryButton } from '../shared-components/Button';
import {
  checkIfUserIsOrganizer,
  getRoleId,
  getUserClassWithRoleId,
} from '../../utils/userInfo';
import EditIcon from '../../assets/icons-and-sets/edit.svg';
import { FILTERS, COLORS } from '../../styles/constants/colors';
import DiscordIcon from '../../assets/svg/Discord.svg';
import SlackIcon from '../../assets/svg/Slack.svg';
import TelegramIcon from '../../assets/svg/Telegram.svg';
import {
  extraLargeHeadingMixin,
  mediumHeadingMixin,
} from '../../styles/constants/mixins';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import {
  DISPLAY,
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../styles/constants/display';
import WalletButton from '../Common/WalletButton';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { ORGANIZER_COLOR } from '../../constants';
import useProfileIntegration from '../../hooks/useProfileIntegration';
import { useMediaQuery } from '../../utils/customHooks';

const communityIcons = [
  {
    id: 'slack',
    name: 'Slack',
    label: 'Connect to Slack',
    icon: SlackIcon,
  },
  {
    id: 'discord',
    name: 'Discord',
    label: 'Connect to discord',
    icon: DiscordIcon,
  },
  {
    id: 'telegram',
    name: 'Telegram',
    label: 'Connect to telegram',
    icon: TelegramIcon,
  },
];

const Header = ({
  data,
  handleOpenModal,
  candidate,
  isCrewMember,
  openIntegrationModal,
}) => {
  const roleId = getRoleId();
  const isOrganizer = checkIfUserIsOrganizer(roleId);
  const timeoutRef = React.useRef({
    discord: null,
    slack: null,
    telegram: null,
  });

  const { isIntegrationDone } = useProfileIntegration();
  const isMobile = useMediaQuery(`(max-width : ${BREAKPOINTS.MOBILE})`);

  const handleMouseEnter = (name) => {
    if (timeoutRef.current[name]) clearTimeout(timeoutRef.current[name]);
    if (isMobile) return;
    const communityIcon = document.querySelector(
      `[data-integration="${name}"]`,
    );
    communityIcon.style.display = 'inline';
  };

  const handleMouseLeave = (name) => {
    if (isMobile) return;
    const communityIcon = document.querySelector(
      `[data-integration="${name}"]`,
    );
    timeoutRef.current[name] = setTimeout(() => {
      communityIcon.style.display = 'none';
    }, 520);
  };

  return (
    <StyledHeaderContainer>
      <StyledUserText>Hey, {get(data, 'name', '')}</StyledUserText>
      <div>
        <Row>
          <Col span={6}>
            <StyledText>{candidate ? '' : 'Company '}Profile</StyledText>
          </Col>
          <Col span={18} className="profile__button_container">
            {isCrewMember ? null : isMobile ? (
              <IconButton
                id="profile-edit"
                onClick={() => handleOpenModal()}
                icon={EditIcon}
                theme={getUserClassWithRoleId(roleId)}
                color={FILTERS.WHITE}
              />
            ) : (
              <PrimaryButton
                onClick={() => handleOpenModal()}
                theme={getUserClassWithRoleId(roleId)}
                id="profile_edit"
              >
                Edit Profile
              </PrimaryButton>
            )}
            {!isMobile && (
              <StyledWalletButton>
                <WalletButton
                  isEditProfile
                  editProfileTheme={getUserClassWithRoleId(roleId)}
                />
              </StyledWalletButton>
            )}
            {candidate && !isMobile ? (
              <CandidateProfileCompletionProgress />
            ) : null}
            {isOrganizer ? (
              <CommunityConnectionGroup>
                {communityIcons.map((communityIcon) => (
                  <CommunityIcon
                    key={communityIcon.id}
                    id={communityIcon.id}
                    aria-label={communityIcon.label}
                    onMouseEnter={() => handleMouseEnter(communityIcon.id)}
                    onMouseLeave={() => handleMouseLeave(communityIcon.id)}
                    onClick={() => {
                      // if(community is added already dont open the modal)
                      openIntegrationModal(communityIcon.id);
                    }}
                  >
                    <img src={communityIcon.icon} alt={communityIcon.name} />
                    {isIntegrationDone(communityIcon.id) ? (
                      <span
                        data-integration={communityIcon.id}
                        id="already-add"
                      >
                        Already added
                      </span>
                    ) : (
                      <span data-integration={communityIcon.id}>
                        {communityIcon.label}
                      </span>
                    )}
                  </CommunityIcon>
                ))}
              </CommunityConnectionGroup>
            ) : null}
          </Col>
        </Row>
      </div>
    </StyledHeaderContainer>
  );
};

export default Header;

const StyledHeaderContainer = styled.div`
  ${MARGIN(0, 0, 25, 0)}
  position: relative;
  z-index: 11;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 45, 0)}

    #profile-edit {
      height: 35px;
      width: 35px;
      border-radius: 9999px;
    }
  }
`;

const CommunityConnectionGroup = styled.div`
  ${DISPLAY_FLEX('row')}
  ${MARGIN(0, 12, 0, 0)}
  gap : 10px;

  #slack {
    ${PADDING(10, 12)}
    #already-add {
      min-width: 100px;
    }

    span {
      min-width: 112px;
    }
  }
  #telegram {
    ${PADDING(10, 9)}
    #already-add {
      min-width: 100px;
    }

    span {
      min-width: 140px;
    }
  }

  #discord {
    #already-add {
      min-width: 100px;
    }

    span {
      min-width: 125px;
    }
  }
`;

const CommunityIcon = styled.div`
  background-color: ${ORGANIZER_COLOR};
  border-radius: 9999px;
  ${PADDING(10)}
  cursor : pointer;
  ${DISPLAY_FLEX('row', null, 'center')}
  gap:10px;
  width: auto;
  max-width: 50px;
  transition: all 0.5s cubic-bezier(0.6, 0.32, 0.53, 0.82);
  -webkit-transition: all 0.5s cubic-bezier(0.6, 0.32, 0.53, 0.82);
  -moz-transition: all 0.5s cubic-bezier(0.6, 0.32, 0.53, 0.82);
  -o-transition: all 0.5s cubic-bezier(0.6, 0.32, 0.53, 0.82);
  -ms-transition: all 0.5s cubic-bezier(0.6, 0.32, 0.53, 0.82);
  overflow: hidden;
  span {
    display: none;
    visibility: hidden;
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.WHITE};
    transition: visibility 0.35s ease-out;
  }

  @media (min-width: ${BREAKPOINTS.MOBILE}) {
    &:hover {
      span {
        visibility: visible;
        display: inline;
      }
      max-width: 193px;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    border-radius: 9999px;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const StyledUserText = styled.span`
  color: ${COLORS.ARSENIC};
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(0)};
  position: relative;
  top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 55%;
  ${extraLargeHeadingMixin}
`;

const StyledText = styled.span`
  display: ${DISPLAY.INLINE_BLOCK};
  color: #8c8c8c;
  font-weight: ${FONT_WEIGHTS.LIGHT};
  ${PADDING(15, 0, 0)};
  ${mediumHeadingMixin};
  font-size: 18px;
`;

const StyledWalletButton = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(15, null, 15, null)};

    button {
      ${MARGIN(null, 0, null, null)};
    }
  }
`;
