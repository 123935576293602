import api from "./api";
import { APIs, Method } from "../constants";

const poll = async (body) => {
	const url = APIs.polling;
	const method = Method.post;
	const response = await api({ url, method, body });
	if (response && response.status && response.status === 200) {
		const { data } = response;
		return data;
	}
	return false;
};

export { poll }
