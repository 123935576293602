import { APIRequestLimit } from '../constants';

const requestMap = {};
export const getUniqueUrl = (config) => {
  return `${config.url}&${config.method}&${config.data}`;
};

export const requestUtils = {
  isLimitExceeded: (key) => {
    const now = new Date();
    const data = requestMap[key];
    if (!data || data.expiry <= now.getTime()) {
      requestMap[key] = {
        expiry: now.getTime() + APIRequestLimit.duration,
        count: 1,
      };
      return false;
    }
    if (data.expiry > now.getTime() && data.count <= APIRequestLimit.request) {
      requestMap[key] = {
        ...data,
        count: data.count + 1,
      };
      return false;
    }
    return true;
  },
};
