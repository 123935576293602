import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentStatusMessage from './PaymentStatusMessage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentStatus = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentStatusMessage />
    </Elements>
  );
};

export default PaymentStatus;
