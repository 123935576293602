import React from 'react';
import { Modal, Form, Col, Row } from 'antd';
import get from 'lodash/get';
import trim from 'lodash/trim';
import styled from 'styled-components';
import {
  FormGroup,
  Email,
  Password,
  TextBox,
} from '../../../components/shared-components/Form/Form';
import { AdminFormMODE } from '../../../constants/superAdmin';
import CountryCodeInput from '../../../components/Common/CountryCodeInput';
import { countryCodeOnlySplit } from '../../../utils/profile';
import { isMobile } from '../../../helpers/display';
import { ResponsiveModalHeader } from '../../../components/shared-components/ResponsiveModalHeader';
import { COLORS } from '../../../styles/constants/colors';
import { StyledLargeFont } from '../../../components/shared-components/Texts';

const AddAdmin = ({ handleSubmit, handleCancel, record = null }) => {
  const mode = !record ? AdminFormMODE.CREATE : AdminFormMODE.EDIT;
  const [form] = Form.useForm();
  const handleOnCancel = () => {
    form.resetFields();
    handleCancel();
  };

  const isModeEdit = () => {
    return mode === AdminFormMODE.EDIT;
  };

  const handleOnSubmit = () => {
    form.validateFields().then((values) => {
      values.phone = `${countryCodeOnlySplit(values.phoneNumberPrefix)} ${
        values.phoneNumber
      }`;

      if (isModeEdit() && !trim(values.password)) {
        delete values.password;
      }
      delete values.phoneNumberPrefix;
      delete values.phoneNumber;
      handleSubmit(values);
      form.resetFields();
    });
  };

  const phoneList = get(record, 'phone', ' ').split(' ');
  const initialValues = {
    ...record,
    phoneNumberPrefix: get(phoneList, '0', null) || '',
    phoneNumber: get(phoneList, '1', null) || '',
  };

  const isMobileMode = isModeEdit();

  return (
    <Modal
      visible
      title={
        isMobile() ? (
          <ResponsiveModalHeader
            handleModalCancel={handleCancel}
            title={`${isModeEdit() ? 'Edit' : 'Add'} Admin`}
          />
        ) : (
          `${isModeEdit() ? 'Edit' : 'Add'} Admin`
        )
      }
      onCancel={handleOnCancel}
      onOk={handleOnSubmit}
      width={!isMobileMode ? '40%' : '100%'}
    >
      <FormGroup
        form={form}
        name="form name"
        initialValues={initialValues}
        expand
      >
        <Col xs={24}>
          <label htmlFor="name">
            <StyledInputHeading>Enter Name</StyledInputHeading>
          </label>
          <TextBox placeholder="Enter Name" name="name" maxChar={100} />

          <label htmlFor="email">
            <StyledInputHeading>Enter email</StyledInputHeading>
          </label>
          <Email placeholder="admin@test.com" name="email" />
          <label htmlFor="password">
            <StyledInputHeading>Enter Password</StyledInputHeading>
          </label>
          <Password
            placeholder="Enter Password"
            name="password"
            required={!isModeEdit()}
          />
          <label htmlFor="phone">
            <StyledInputHeading>Enter Phone number</StyledInputHeading>
          </label>
          <Row>
            <Col xs={8} lg={8}>
              <CountryCodeInput name="phoneNumberPrefix" required />
            </Col>
            <Col xs={16} lg={16}>
              <TextBox
                name="phoneNumber"
                placeholder="Phone Number"
                maxLength={12}
                rule="phoneNumber"
                id="office_number"
                required
              />
            </Col>
          </Row>
          <label htmlFor="department">
            <StyledInputHeading>Enter Department</StyledInputHeading>
          </label>
          <TextBox
            placeholder="Enter Department"
            name="department"
            maxChar={100}
          />
          <label htmlFor="country">
            <StyledInputHeading>Enter Country</StyledInputHeading>
          </label>
          <TextBox placeholder="Enter Country" name="country" maxChar={100} />
        </Col>
      </FormGroup>
    </Modal>
  );
};

export default AddAdmin;

const StyledInputHeading = styled(StyledLargeFont)`
  color: ${COLORS.PHILLIPPINE_GRAY};
`;
