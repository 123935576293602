import React from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { StyledManagementTableProps } from '../../../interfaces/components/AttendeesManagement';

const StyledManagementTable: React.FC<StyledManagementTableProps> = ({
  rowSelection,
  columns,
  tableData,
  size,
  pagination,
  onChange,
}) => {
  return (
    <StyledAntTable
      rowSelection={rowSelection}
      columns={columns}
      dataSource={tableData}
      size={size}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

export default StyledManagementTable;

const StyledAntTable = styled(AntTable)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    table {
      table-layout: auto;
      width: auto;
    }
  }

  .ant-table-column-title {
    font-size: 12px;
  }
`;
