import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { CARD_CHANGE, PRICING_PLAN } from '../../constants';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { Tooltip } from 'antd';
import CuriosBullet from '../../assets/svg/CuriosBullet.svg';
import OptimisticBullet from '../../assets/svg/OptimisticBullet.svg';
import BelieverBullet from '../../assets/svg/BelieverBullet.svg';
import { ArrowDown } from '../../components/svgicons';
import { usePlanSelector } from '../../context/CryptoPlanProvider';

const bulletIconPoints = [CuriosBullet, OptimisticBullet, BelieverBullet];

const PricingCard = ({ card }) => {
  const { id, name, description, amount, cryptoCurrency } = card;
  const perks = get(card, 'meta.perks', []);
  const earlyBirdOffer = get(card, 'meta.earlyBirdOffer', null);
  const contentRef = React.useRef();
  const imageRef = React.useRef();
  const { selectedPlan, handleSelectPlan: setPlan } = usePlanSelector();

  const handleCardCollapse = (e) => {
    e.stopPropagation();
    imageRef.current.classList.toggle('rotate');
    document.querySelectorAll(`.${card.name}`).forEach((entry) => {
      entry.classList.toggle('hide');
    });
    contentRef.current.classList.toggle('collapsed');
  };

  const handlePlan = () => {
    setPlan(card);
    if (selectedPlan?.id !== card.id) {
      document.dispatchEvent(new Event(CARD_CHANGE));
    }
  };

  return (
    <StyledCardContainer
      onClick={handlePlan}
      ownId={card.id}
      selected={selectedPlan?.id}
      id={card.name}
    >
      {/* Header */}
      <StyledCardHeader>
        <StyledCardTitle className="subColor">{name}</StyledCardTitle>
      </StyledCardHeader>

      <StyledCardContent ref={contentRef}>
        {/* Price */}
        {earlyBirdOffer ? (
          <StyledBothPrice>
            <StyledCutText>
              <span className="bar bar1"></span>
              <span className="bar bar2"></span>
              {amount} {cryptoCurrency.symbol}
            </StyledCutText>
            <StyledPriceInfo>
              {earlyBirdOffer} {cryptoCurrency.symbol}
            </StyledPriceInfo>
          </StyledBothPrice>
        ) : (
          <StyledPriceInfo>
            {amount} {cryptoCurrency.symbol}
          </StyledPriceInfo>
        )}

        {/* Subtitle */}
        <StyledSubInfoHeader>{description}</StyledSubInfoHeader>
        {/* Content Points */}
        <StyledCardInfo ownId={id}>
          {perks.map((perk, idx) => (
            <div
              className={`content ${idx > 1 ? `${card.name} hide` : ''}`}
              key={perk.headline}
            >
              <div>
                <span className="bullet-point"></span>
              </div>
              <div>
                <div>
                  {perk.headline}{' '}
                  {perk.isComingSoon ? (
                    <Tooltip
                      title="Pilot feature, coming soon!"
                      trigger={['hover', 'click']}
                    >
                      <StyledMandatoryStar>*</StyledMandatoryStar>
                    </Tooltip>
                  ) : null}
                </div>
                {perk.supportingText ? (
                  <span className="subinfo">{`(${perk.supportingText})`}</span>
                ) : null}
              </div>
            </div>
          ))}
        </StyledCardInfo>
        {id !== PRICING_PLAN.CURIOUS && (
          <IconContainer
            ownId={id}
            onClick={(e) => handleCardCollapse(e)}
            ref={imageRef}
          >
            <ArrowDown />
          </IconContainer>
        )}
      </StyledCardContent>
    </StyledCardContainer>
  );
};

export default PricingCard;

const StyledCardTitle = styled.h2`
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(0)}
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 22px;

  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    font-size: 18px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 16px;
  }
`;

const StyledMandatoryStar = styled.span`
  color: ${COLORS.RED};
`;

const StyledCutText = styled.span`
  text-align: center;
  font-size: 20px;
  position: relative;
  ${MARGIN(15, 0, 10, 0)}
  color : ${COLORS.DARK_GRAY};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(5, 0, 0, 0)}
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    font-size: 18px;
  }
`;

const StyledBothPrice = styled.div`
  ${DISPLAY_FLEX('row', 'center')};
  gap: 15px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

  .bar {
    width: 100%;
    height: 2px;
    background-color: ${COLORS.CORAL_RED};
    position: absolute;
    top: 50%;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      top: 40%;
    }
  }
  .bar1 {
    transform: rotate(15deg);
  }
  .bar2 {
    transform: rotate(-15deg);
  }
`;

const StyledCardHeader = styled.div`
  height: 50px;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  ${MARGIN(0, 0, 15, 0)}

  @media (max-width : ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 5, 0)}
  }
`;

const StyledCardContainer = styled.div`
  height: min-content;
  flex: none;
  width: 340px;
  font-family: Poppins;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid transparent;
  transition: 0.2s ease;
  ${({ ownId: id, selected }) => {
    if (id === selected) return `transform : scaleY(1.1);`;
  }}
  ${({ ownId: id, selected }) => {
    if (id === selected) {
      switch (id) {
        case PRICING_PLAN.CURIOUS:
          return `box-shadow : 0px 0px 10px ${COLORS.CURIOS_BORDER_1};`;
        case PRICING_PLAN.OPTIMISTIC:
          return `box-shadow: 0px 0px 10px ${COLORS.OPTIMISTIC_BORDER_1};`;
        case PRICING_PLAN.BELIEVER:
          return `box-shadow: 0px 0px 10px ${COLORS.BELIEVER_BORDER_1};`;
        default:
          return `box-shadow : 0px 0px 10px ${COLORS.CURIOS_BORDER_1}`;
      }
    }
  }}

  .collapsed {
    height: max-content;
    max-height: 1000px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .collapsed {
      height: inherit;
    }
  }
  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    width: 290px;
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 410px;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  text-align: center;
  svg {
    path {
      ${({ ownId: id }) => {
        switch (id) {
          case PRICING_PLAN.OPTIMISTIC:
            return `fill : ${COLORS.OPTIMISTIC_BORDER_2};`;
          case PRICING_PLAN.BELIEVER:
            return `fill : ${COLORS.BELIEVER_BORDER_2}`;
          default:
            return `fill : ${COLORS.BLACK}`;
        }
      }}
    }
  }
`;
const StyledCardContent = styled.div`
  position: relative;
  overflow-y: hidden;
  height: 300px;
  ${DISPLAY_FLEX('column')}
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 250px;
  }
  .rotate {
    svg {
      rotate: 180deg;
    }
  }
`;
const StyledCardInfo = styled.div`
  ${DISPLAY_FLEX('column', null, 'flex-start')}
  gap: 30px;
  ${MARGIN(20, 20, 30, 20)}

  .content {
    ${DISPLAY_FLEX('row')};
    gap: 20px;
  }
  .hide {
    display: none;
  }
  .content div {
    font-size: 16px;
  }

  .subinfo {
    font-size: 15px;
    font-weight: ${FONT_WEIGHTS.LIGHT};
  }
  .bullet-point {
    ${({ ownId }) => `content : url(${bulletIconPoints[ownId - 1]});`}
    display: inline-block;
    zoom: 80%;
    ${MARGIN(3, 0, 0, 0)}
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    .content div {
      font-size: 14px;
    }
    .subinfo {
      font-size: 13px;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    .content div {
      font-size: 20px;
    }
    .subinfo {
      font-size: 18px;
    }
  }
`;
const StyledSubInfoHeader = styled.span`
  text-align: center;
  font-size: 16px;
  ${MARGIN(15, 0, 0, 0)};
  color: ${({ color }) => color};
  font-weight: ${FONT_WEIGHTS.MEDIUM};

  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    font-size: 15px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 22px;
  }
`;
const StyledPriceInfo = styled.span`
  text-align: center;
  font-size: 20px;
  ${MARGIN(15, 0, 0, 0)}
  color: ${({ color }) => color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    font-size: 18px;
  }
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(5, 0, 0, 0)}
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 22px;
  }
`;
