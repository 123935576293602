import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { MARGIN } from '../../styles/constants/display';
import { largeFontMixin } from '../../styles/constants/mixins';
import Brief from './About';
import MainContainer from '../Common/MainContainer';
import { useGetCommunityPageQuery } from '../../store/features/rtkApi/common';

const AboutTab = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { data: communityPageData } = useGetCommunityPageQuery(companyId);
  const brief = get(communityPageData, 'company.company.brief', '');

  return (
    <StyledSection>
      <MainContainer>
        <Brief brief={brief} />
      </MainContainer>
    </StyledSection>
  );
};

export default AboutTab;

const StyledSection = styled.section`
  ${MARGIN(20, 0)}
  ${largeFontMixin};
`;
