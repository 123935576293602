import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import UserIcon from '../../assets/svg/User.svg';
import CompanyIcon from '../../assets/svg/company.svg';
import RecruiterIcon from '../../assets/svg/bMan.svg';
import JobIcon from '../../assets/svg/jobs.svg';
import NoMatchIcon from '../../assets/svg/NoMatch.svg';
import { IconBadge } from '../shared-components/IconBadge';
import { getUserIconColorWithRoleId } from '../../styles/utils';
import { COLORS } from '../../styles/constants/colors';
import { getRoleId } from '../../utils/userInfo';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const EventAnalyticsCompany = ({ analytics }) => {
  // Initial value
  const initialValue = '-';
  const roleId = getRoleId();
  /*
  const client = useContext(AppSocketContext);
  const history = useHistory();
  const { event } = getUrlVars(get(history, 'location.search', {}));
  */

  const [noOfCompanies, setNoOfCompanies] = useState(0);
  const [noOfCompaniesPresent, setNoOfCompaniesPresent] = useState(
    initialValue,
  );
  const [noOfRecruiters, setNoOfRecruiters] = useState(initialValue);
  const [noOfJobs, setNoOfJobs] = useState(initialValue);
  const [noOfJobApplications, setNoOfJobApplications] = useState(initialValue);

  useEffect(() => {
    setNoOfCompanies(get(analytics, 'noOfCompanies', initialValue));
    setNoOfCompaniesPresent(
      get(analytics, 'noOfCompaniesPresent', initialValue),
    );
    setNoOfJobs(get(analytics, 'noOfJobs', initialValue));
    setNoOfRecruiters(get(analytics, 'noOfRecruiters', initialValue));
    setNoOfJobApplications(get(analytics, 'noOfJobApplications', initialValue));
  }, [analytics]);

  /*
  useEffect(() => {
    if (client && event) {
      (async () => {
        await client.subscribe(
          `/attendees-management/event/${event}/`,
          (resp) => {
            console.log(
              toNumber(noOfCompanies) + 1,
              noOfCompanies !== initialValue,
              noOfCompanies,
            );
            if (resp.isCompany && noOfCompanies !== initialValue) {
              console.log(toNumber(noOfCompanies) + 1);
              setNoOfCompanies(toNumber(noOfCompanies) + 1);
            }
          },
        );
      })();
    }
    // eslint-disable-next-line
  }, [event]);
  */

  const analyticsData = [
    {
      icon: UserIcon,
      key: 'Registered',
      value: noOfCompanies,
    },
    {
      icon: CompanyIcon,
      key: 'Present',
      value: noOfCompaniesPresent,
    },
    {
      icon: RecruiterIcon,
      key: 'Recruiters',
      value: noOfRecruiters,
    },
    {
      icon: JobIcon,
      key: 'Jobs',
      value: noOfJobs,
    },
    {
      icon: NoMatchIcon,
      key: 'Applications',
      value: noOfJobApplications,
    },
  ];

  return analyticsData.map((item, i) => (
    <Wrapper>
      <IconBadge
        icon={item.icon}
        color={getUserIconColorWithRoleId(roleId)}
        text={item.key}
        textColor={COLORS.WHITE}
        status={item.value}
        isCursorPointer={false}
      />
    </Wrapper>
  ));
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: flex-start;
  }
`;

export default EventAnalyticsCompany;
