import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Notifications from '../../../components/Notification/Notification';
import {
  ACCEPTED_IMAGE_EXTENSIONS,
  COVER_IMAGE_HINT,
} from '../../../constants/file';
import { PictureUploadWithList } from '../../../components/shared-components/Upload';
import { FormGroup } from '../../../components/shared-components/Form/Form';
import { updateProfileData } from '../../../store/features/profile';
import {
  companyCoverOnChange,
  handleCoverBeforeUpload,
  profileLinksPayload,
  updateCompanyCoverCustomReq,
  userCompanyProfileInitialValues,
  userCompanyProfilePayload,
  userCompanyProfileResetFields,
} from '../../../utils/profile';
import { checkIfUserIsOrganizer, getRoleId } from '../../../utils/userInfo';
import ProfilesLinksList from '../../../components/ProfileLinks/ProfileLinksList';
import CompanyAddressModalItem from '../../../components/profile/CompanyAddressModalItem';
import ModalButtonContainer from '../../../components/profile/ModalButtonContainer';
import CompanyInformationModalItem from '../../../components/profile/CompanyInformationModalItem';
import EditCurrencySetting from '../../../components/profile/EditCurrencySetting';
import { UploadLogoComponent } from '../../../components/Common/UploadComponent';
import { CROP_COVER_OPTIONS } from '../../../constants';
import { getCurrencyDataForDropdown } from '../../../utils/currencyConvertor';
import { getSize } from '../../../utils/size';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import {
  largeFontMixin,
  mediumHeadingMixin,
} from '../../../styles/constants/mixins';
import { PADDING } from '../../../styles/constants/display';
import { getThemeByRoleId } from '../../../utils/common';
import { Modal } from '../../../components/shared-components/Modal';
import { useTagManager } from '../../../utils/customHooks';

const CompanyInformationModal = (props) => {
  const {
    visible,
    handleClose,
    companyLogo,
    profileData,
    companyData,
    setCompanyLogo,
    companyCover,
    setCompanyCover,
    currencyData,
  } = props;
  const dispatch = useDispatch();
  const roleId = getRoleId();
  const updateUserActionInGTM = useTagManager();

  const currencyDataForDropdown = getCurrencyDataForDropdown(currencyData);

  const initialValues = userCompanyProfileInitialValues(
    profileData,
    companyData,
    currencyData,
  );
  const [form] = Form.useForm();

  const handleModalCancel = () => {
    form.resetFields();
    handleClose();
  };

  const handleResetFields = () => {
    userCompanyProfileResetFields(form);
  };

  const handleFinish = (data) => {
    const body = userCompanyProfilePayload(
      roleId,
      data,
      profileData,
      currencyData,
    );

    const profileLinks = profileLinksPayload(data.profileLinks);

    if (profileLinks.length === 0) {
      Notifications(
        'error',
        'Profile Links',
        'Please add atleast one company profile link',
      );
      return;
    }

    dispatch(updateProfileData(body));
    updateUserActionInGTM('profile_settings_updated');
    handleModalCancel();
  };

  const buttonProps = {
    width: getSize(160, 145, 135),
    height: getSize(42, 36, 32),
    handleResetFields,
  };

  return (
    <Modal
      className="OrganizerCompanyInformationModal"
      visible={visible}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      closable={false}
      title="Edit Company Profile"
      theme={getThemeByRoleId(roleId)}
    >
      <FormGroup
        form={form}
        name="OrganizerCompanyInformationModal"
        onFinish={handleFinish}
        expand
        initialValues={initialValues}
      >
        <CompanyInformationModalItem>
          <StyledUploadContainer>
            <UploadLogoComponent
              option
              companyLogo={companyLogo}
              setCompanyLogo={setCompanyLogo}
              fileName={companyData.logo}
              alt="organizer logo"
              avatarSize={getSize(120, 100, 80)}
              removeOption
              roleId={roleId}
              btnText="Change Profile Pic"
            />
          </StyledUploadContainer>
        </CompanyInformationModalItem>

        <CompanyAddressModalItem form={form} />

        <ProfilesLinksList form={form} />

        <EditCurrencySetting currenciesData={currencyDataForDropdown} />
        <br />

        {checkIfUserIsOrganizer(roleId) ? (
          <StyledMarketingPageContainer>
            <StyledHeadingLevelThree>Marketing Page</StyledHeadingLevelThree>
            <StyledCompanyInfoContainer>
              <StyledLabel>Upload Company Cover Pic</StyledLabel>
              <PictureUploadWithList
                name="companyCover"
                className="resume-upload upload_btn"
                roleId={roleId}
                icon={<UploadOutlined />}
                accept={ACCEPTED_IMAGE_EXTENSIONS}
                customRequest={({ onSuccess, onError, file }) =>
                  updateCompanyCoverCustomReq(
                    onSuccess,
                    onError,
                    file,
                    setCompanyCover,
                  )
                }
                onChange={(info) => companyCoverOnChange(info, setCompanyCover)}
                beforeUpload={handleCoverBeforeUpload}
                showUploadList
                fileList={companyCover}
                onBoard
                maxCount={1}
                cropOptions={CROP_COVER_OPTIONS}
                cropActive
                hintText={COVER_IMAGE_HINT}
              />
            </StyledCompanyInfoContainer>
          </StyledMarketingPageContainer>
        ) : null}

        <ModalButtonContainer {...buttonProps} />
      </FormGroup>
    </Modal>
  );
};

export default CompanyInformationModal;

const StyledUploadContainer = styled.div`
  position: absolute;
  right: 0;
  top: 90px;
  width: 20%;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: unset;
    top: 25px;
  }
`;

const StyledMarketingPageContainer = styled.div`
  border-top: 2px dashed ${COLORS.CHINESE_SILVER};
`;

const StyledHeadingLevelThree = styled.h3`
  color: ${({ color }) => color || COLORS.CHINESE_BLACK};
  margin-bottom: 24px;
  margin-top: 24px;
  font-weight: 600;
  ${mediumHeadingMixin};
`;

const StyledCompanyInfoContainer = styled.div`
  ${PADDING(10, 20, 15, 10)};
  background-color: ${COLORS.CHINESE_SILVER}1c;
`;

const StyledLabel = styled.label`
  display: block;
  color: #000;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 15px;
  ${largeFontMixin};
`;
