import React from 'react';
import styled from 'styled-components';
import { getRoleId } from '../../utils/userInfo';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { PRIMARY_FONT } from '../../styles/constants/typography';
import {
  Circle1,
  Circle2,
} from '../shared-components/AnalyticsContainerCircles';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const EventAnalytics = ({
  EventAnalyticsItemComponent,
  analytics = null,
  customStyle = null,
}) => {
  const roleId = getRoleId();
  return (
    <StyledContainer
      color={getUserColorWithRoleId(roleId)}
      customStyle={customStyle}
    >
      <Circle1 />
      <Circle2 />
      <EventAnalyticsItemComponent analytics={analytics} page="events" />
    </StyledContainer>
  );
};

export default EventAnalytics;

const StyledContainer = styled.div`
  width: 100%;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  font-family: ${PRIMARY_FONT};
  background-color: ${(props) => props.color};
  width: 100%;
  z-index: 1;
  padding: 20px 20px;
  ${({ customStyle }) => {
    return customStyle
      ? DISPLAY_FLEX('row', 'space-between', null)
      : DISPLAY_FLEX('row', 'start', null);
  }}

  @media (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_2}) {
    gap: 10px;
    flex-direction: ${({ customStyle }) => customStyle && 'column'};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 30px 30px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('column', 'center', null)};
    padding: 20px 20px;
  }
`;
