import styled from 'styled-components';
import { Row, Modal, Button } from 'antd';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { COLORS, FILTERS } from '../../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import {
  MARGIN,
  PADDING,
  DISPLAY_FLEX,
} from '../../../../styles/constants/display';
import { SHADOWS } from '../../../../styles/constants/shadows';
import { MASTER_ADMIN_COLOR, RECRUITER_COLOR } from '../../../../constants';

export const StyledHeading = styled.h2`
  ${MARGIN(0, 20, 0, 0)};
  font-size: ${FONT[18]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.SUPER_NOVA};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[20]};
  }
`;

export const StyledTooltipWrapper = styled.div`
  .ant-tooltip-placement-top {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background-color: ${({ isMasterAdmin }) => isMasterAdmin ? MASTER_ADMIN_COLOR : RECRUITER_COLOR};
      color: ${COLORS.WHITE};
      font-weight: ${FONT_WEIGHTS.MEDIUM};
      font-size: ${FONT[14]};
      width: min-content;
    }
  }

  span {
    ${PADDING(0, 15, 0, 15)}
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
  }
`;

export const StyledInfoHeading = styled.div`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  ${MARGIN(0, 0, 20, 0)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

export const StyledInputWrapper = styled.div`
  width: 100%;
  .ant-form-item div {
    max-width: 100%;
  }
`;

export const StyledCreateSkillSetWrapper = styled(Row)`
  ${DISPLAY_FLEX('column')};
  ${MARGIN(40, 0, 0, 0)};
  overflow: visible;
`;

export const StyledSkillSetWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${MARGIN(42, 0, 0, 0)};
  width: 28%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: space-between;
    width: 100%;
  }
`;

export const StyledDescriptionTextWrapper = styled.div`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${MARGIN(0, 0, 0, 25)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 0, 20)};
  }
`;

export const StyledModal = styled(Modal)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100% !important;
    height: 100vh !important;
    top: 10% !important;
    margin: 9px auto;
    max-width: calc(90vw) !important;
  }

  .ant-modal-close-x {
    ${MARGIN(20, 20, 0, 0)};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${MARGIN(10, 10, 0, 0)};
      display: block;
    }
  }

  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-body {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${PADDING(16)};
    }
  }
`;

export const StyledMainContainer = styled.div`
  position: relative;
  box-sizing: content-box;
`;

export const StyledAnySkillButtonWrapper = styled.div`
  button {
    ${PADDING(0, 10, 0, 10)};
    color: ${({ isClicked, colorString }) =>
    !isClicked ? colorString : COLORS.WHITE};
    border-color: ${({ colorString }) => colorString};
    background-color: ${({ isClicked, colorString }) =>
    isClicked ? colorString : ''};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${PADDING(0, 10, 0, 10)};
    }

    &:hover,
    &:active,
    &:focus {
      color: ${({ isClicked, colorString }) =>
    !isClicked ? colorString : COLORS.WHITE};
      border-color: ${({ colorString }) => colorString};
      background-color: ${({ isClicked, colorString }) =>
    isClicked ? colorString : ''};
    }
  }
`;
export const StyledSubmitContainer = styled.div`
  ${MARGIN(40, 20, 20, 0)};
  ${DISPLAY_FLEX('row-reverse')};
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  gap: 20px;
`;

export const StyledAddSkillButtonWrapper = styled.div`
  width: min-content;

  button {
    height: auto;
    ${PADDING(10, 90, 10, 90)};

    @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
      ${PADDING(10, 75, 10, 75)};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${PADDING(10, 45, 10, 45)};
    }

    &:hover,
    &:active,
    &:focus {
      color: ${({ isMasterAdmin }) => isMasterAdmin ? MASTER_ADMIN_COLOR : RECRUITER_COLOR};
      border-color: ${({ isMasterAdmin }) => isMasterAdmin ? MASTER_ADMIN_COLOR : RECRUITER_COLOR};
    }
  }
`;

export const StyledMainFormContainer = styled.div`
  ${PADDING(30, 20, 30, 20)};
  background: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.SHADOW1};
  position: relative;
  height: min-content;
  z-index: 1;
`;

export const StyledBreadcrumbWrapper = styled(Row)`
  ${MARGIN(20, 0, 20, 0)};
  color: ${COLORS.SUPER_NOVA};
`;

export const StyledAnySkillButton = styled(Button)`
  border-radius: 6px;
`;

export const StyledSkillSetHeading = styled.div`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.CHINESE_BLACK};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

export const StyledTopTitle = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', 'center')}
  gap: 10px;
  ${MARGIN(0, 0, 20, 0)}
  img {
    height: 25px;
    filter: ${FILTERS.RECRUITER};
    ${MARGIN(0, 0, 3, 0)}
  }
`;
