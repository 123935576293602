import React from 'react';
import styled from 'styled-components';
import { TextProps } from '../../interfaces/components/Text';
import { MARGIN } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { StyledHeading } from './Texts';

export const ProfileHeading: React.FC<TextProps> = ({ color, children }) => {
  return <Heading color={color}>{children}</Heading>;
};

const Heading = styled(StyledHeading)`
  padding-bottom: 5px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${(props) => props.color};
  ${MARGIN(15, 0)};
`;
