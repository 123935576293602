import React, { useEffect, useContext, Suspense, useCallback } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import ConfigContext from '../store/configContext';
import Dashboard from './Recruiter/Dashboard';
import Recruitment from './Recruiter/Recruitment/Recruitment';
import Profile from './Recruiter/Profile';
import ProfileOnboard from './Recruiter/ProfileOnboard';
import DashboardIcon from '../assets/icons-and-sets/dashboard.svg';
import EventsIcon from '../assets/icons-and-sets/events.svg';
import BriefcaseIcon from '../assets/icons-and-sets/briefcase.svg';
import ChatIcon from '../assets/icons-and-sets/chat.svg';
import CalendarIcon from '../assets/icons-and-sets/calendar.svg';
import RecruiterCalendar from './Recruiter/RecruiterCalendar';
import CreateJob from './Recruiter/Recruitment/Job/CreateJob';
import { RecruiterRoutes } from '../constants/routes';
import JobLists from './Recruiter/Recruitment/JobLists';
import { Support } from '../components/shared-components/Support';
import { CreateOrChangePassword } from '../components/shared-components/CreateOrChangePassword';
import { checkIfUserIsRecruiter, getRoleId } from '../utils/userInfo';
import { redirectToProperUserRole } from '../utils/common';
import { safeLoad } from '../utils/loader';
import { usePaymentInformation } from '../hooks/paymentInformation';
import { RETRY_ATTEMPTS, RETRY_INTERVALS } from '../constants';
import PaymentStatus from './PaymentStatus';
import ResumePage from './Recruiter/Recruitment/ViewResume/ResumePage';
import PricingPlan from './PricingPlan';
import { useSkipPayment } from '../hooks/useSkipPayment';

const Notifications = React.lazy(() => {
  return safeLoad(
    () => import('../components/shared-components/Notifications/Notifications'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const ChatManagement = React.lazy(() => {
  return safeLoad(
    () => import('./Recruiter/ChatManagement'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const GroupChatManagement = React.lazy(() => {
  return safeLoad(
    () => import('./Recruiter/GroupChatManagement'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Events = React.lazy(() => {
  return safeLoad(
    () => import('./Recruiter/Events/Events'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const ViewEvent = React.lazy(() => {
  return safeLoad(
    () => import('../components/Events/ViewEvent/ViewEvent'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const ExploreEvents = React.lazy(() => {
  return safeLoad(
    () => import('../components/Events/RecommendedEvents/ExploreEvents'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const CrewMemberProfile = React.lazy(() => {
  return safeLoad(
    () => import('../components/CrewManagement/CrewMemberProfile'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const ViewJobDetails = React.lazy(() => {
  return safeLoad(
    () => import('./Recruiter/Recruitment/ViewJobDetails'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const EditJob = React.lazy(() => {
  return safeLoad(
    () => import('./Recruiter/Recruitment/Job/EditJob'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const PrivatePageNotFound = React.lazy(() => {
  return safeLoad(
    () => import('./PrivatePageNotFound'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const HelpDoc = React.lazy(() => {
  return safeLoad(
    () => import('../components/Common/HelpDoc'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Recruiter = () => {
  const { navigationList, setNavigationList } = useContext(ConfigContext);
  const roleId = getRoleId();
  const { canNavigate, isSkipped } = useSkipPayment();
  const { paymentPending } = usePaymentInformation();
  const history = useHistory();
  const { search } = useLocation();
  if (roleId && !checkIfUserIsRecruiter(roleId)) {
    redirectToProperUserRole(roleId);
  }
  /** Full condition for accessing recruiter routes
   * 1. Payment status enabled or skipped
   * 2. Payment is done
   */
  const canAccessRoutes = useCallback(() => {
    return canNavigate() || !paymentPending;
  }, [canNavigate, paymentPending]);

  useEffect(() => {
    setNavigationList([
      {
        Icon: DashboardIcon,
        title: 'Dashboard',
        route: RecruiterRoutes.recruiter,
        exact: true,
        disabled: !canAccessRoutes(),
        Component: canAccessRoutes() ? Dashboard : null,
      },
      {
        Icon: BriefcaseIcon,
        title: 'Recruit',
        route: `${RecruiterRoutes.recruit}:tab`,
        routeToRedirect: `${RecruiterRoutes.recruit}2`,
        selectionPath: RecruiterRoutes.recruit,
        disabled: !canAccessRoutes(),
        Component: canAccessRoutes() ? Recruitment : null,
      },
      {
        Icon: EventsIcon,
        title: 'Events',
        route: RecruiterRoutes.event,
        exact: true,
        disabled: !canAccessRoutes(),
        Component: canAccessRoutes() ? Events : null,
      },
      {
        Icon: CalendarIcon,
        title: 'My Calendar',
        route: RecruiterRoutes.calender,
        disabled: !canAccessRoutes(),
        Component: canAccessRoutes() ? RecruiterCalendar : null,
      },
      {
        Icon: ChatIcon,
        title: 'Chat',
        route: RecruiterRoutes.chat,
        exact: true,
        disabled: !canAccessRoutes(),
        Component: canAccessRoutes() ? ChatManagement : null,
      },
      {
        title: 'Group Chat Management',
        route: RecruiterRoutes.groupChat,
        selectionPath: RecruiterRoutes.chat,
        populate: false,
        Component: canAccessRoutes() ? GroupChatManagement : null,
      },
      {
        title: 'My Profile',
        route: RecruiterRoutes.profile,
        populate: false,
        disabled: !canAccessRoutes(),
        Component: canAccessRoutes() ? Profile : null,
      },
      {
        title: 'Profile Onboard',
        route: RecruiterRoutes.profileOnboard,
        populate: false,
        Component: ProfileOnboard,
      },
      {
        title: 'View Event',
        route: '/recruiter/events/view/:id',
        selectionPath: RecruiterRoutes.event,
        populate: false,
        Component: ViewEvent,
      },
      {
        title: 'Pricing Plan',
        route: RecruiterRoutes.pricingPlan,
        populate: false,
        disabled: !paymentPending,
        Component: paymentPending ? PricingPlan : Skeleton,
      },
      {
        title: 'Explore Events',
        route: RecruiterRoutes.exploreEvent,
        selectionPath: RecruiterRoutes.event,
        populate: false,
        Component: canAccessRoutes() ? ExploreEvents : null,
      },
      {
        title: 'View Resume',
        route: RecruiterRoutes.viewResume,
        selectionPath: RecruiterRoutes.recruit,
        selectionSearch: `/recruiter/resume/`,
        populate: false,
        Component: canAccessRoutes() ? ResumePage : null,
      },
      {
        title: 'Create Job',
        route: RecruiterRoutes.createJob,
        selectionPath: RecruiterRoutes.recruit,
        populate: false,
        exact: true,
        Component: canAccessRoutes() ? CreateJob : null,
      },
      {
        title: 'Crew Member Profile',
        route: '/recruiter/crew-member-profile/:id',
        populate: false,
        Component: canAccessRoutes() ? CrewMemberProfile : null,
      },
      {
        title: 'View Job Details',
        route: RecruiterRoutes.viewJobDetails,
        selectionPath: RecruiterRoutes.recruit,
        selectionSearch: `/recruiter/job-opportunities/view-job-details/`,
        populate: false,
        Component: canAccessRoutes() ? ViewJobDetails : null,
      },
      {
        title: 'Job Opportunities',
        route: RecruiterRoutes.jobOpportunities,
        selectionPath: RecruiterRoutes.recruit,
        populate: false,
        exact: true,
        Component: canAccessRoutes() ? JobLists : null,
      },
      {
        title: 'Edit Job',
        route: RecruiterRoutes.editJob,
        selectionPath: RecruiterRoutes.recruit,
        populate: false,
        exact: true,
        Component: canAccessRoutes() ? EditJob : null,
      },
      {
        title: 'Notifications Page',
        route: RecruiterRoutes.notifications,
        populate: false,
        Component: Notifications,
      },
      {
        title: 'Help Doc',
        route: RecruiterRoutes.helpDoc,
        populate: false,
        Component: HelpDoc,
      },
      {
        title: 'Support Page',
        route: RecruiterRoutes.support,
        populate: false,
        Component: Support,
      },
      {
        title: 'Modify Password',
        route: RecruiterRoutes.changePassword,
        populate: false,
        Component: CreateOrChangePassword,
      },
      {
        title: 'Payment Status',
        route: RecruiterRoutes.paymentStatus,
        populate: false,
        Component: PaymentStatus,
      },
      {
        title: 'Page Not Found',
        route: '*',
        populate: false,
        Component: PrivatePageNotFound,
      },
    ]);
  }, [setNavigationList, paymentPending, canAccessRoutes]);

  useEffect(() => {
    /** If payment is pending and recruiter hasnot clicked on skip then they will redirected to pricing-page */
    const params = new URLSearchParams(search);
    if (paymentPending && !isSkipped()) {
      if (params.has('inviteToken') && params.has('eventId')) {
        history.push(
          `${RecruiterRoutes.pricingPlan}?inviteToken=${params.get(
            'inviteToken',
          )}&eventId=${params.get('eventId')}`,
        );
      } else {
        history.push(`${RecruiterRoutes.pricingPlan}`);
      }
    }
  }, [paymentPending, isSkipped, history, search]);

  return (
    <div>
      <Suspense fallback={<Skeleton />}>
        <Switch>
          {navigationList.map((navItem, idx) => {
            const { route, Component, exact = false } = navItem;
            return (
              <Route
                key={idx.toString()}
                exact={exact}
                path={route}
                component={Component}
              />
            );
          })}
        </Switch>
      </Suspense>
    </div>
  );
};

export default Recruiter;
