import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import get from 'lodash/get';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX, SHARP_IMAGE } from '../../../styles/constants/display';
import { getFileS3 } from '../../../utils/s3.storage';
import { socialMediaIconList } from '../../../constants';
import SocialIconGenerator from '../../Common/SocialIconGenerator';
import { getRedirectableLink } from '../../../utils/general';

const SponsorCard: React.FC<any> = ({ item, key }) => {
  const [isActive, setIsActive] = useState(false);
  const links = get(item, 'links', []);
  const profileLinks = [...links].sort((x: any, y: any) => {
    const a = socialMediaIconList.find((el) => x.includes(el.name)) ? 0 : 1;
    const b = socialMediaIconList.find((el) => y.includes(el.name)) ? 0 : 1;
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  const handleClick = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <StyledImage key={key} onClick={handleClick}>
      <img src={getFileS3(item.pic)} alt="Sponsor" />
      {isActive ? (
        <>
          {profileLinks.length !== 0 ? (
            <StyledIconsContainer onClick={(e) => e.stopPropagation()}>
              {profileLinks.map((link: any, idx: number) => {
                return (
                  <Tooltip
                    title={getRedirectableLink(link)}
                    key={idx.toString()}
                  >
                    <SocialIconGenerator link={getRedirectableLink(link)} />
                  </Tooltip>
                );
              })}
            </StyledIconsContainer>
          ) : null}
          <StyledSponsorsDescription>{item.brief}</StyledSponsorsDescription>
        </>
      ) : null}
    </StyledImage>
  );
};

export const EventSponsor = ({ sponsors, partners, eventBanner }: any) => {
  const filterData = (data: any): any => {
    return data
      ?.map((item: any) => {
        if (item.type === 'Platinum') {
          return { ...item, key: 1 };
        }
        if (item.type === 'Gold') {
          return { ...item, key: 2 };
        }
        if (item.type === 'Silver') {
          return { ...item, key: 3 };
        }
      })
      .sort((a: any, b: any) => a.key - b.key);
  };

  const isSponsors = sponsors && sponsors.length;
  const isPartners = partners && partners.length;

  return (
    <StyledMain eventBanner={eventBanner}>
      {isSponsors || isPartners ? (
        <>
          {isSponsors ? (
            <StyledSponsers>
              <h1>Sponsored By</h1>
              <div>
                {filterData(sponsors)?.map((item: any) => (
                  <SponsorCard item={item} key={item} />
                ))}
              </div>
            </StyledSponsers>
          ) : null}
          {isPartners ? (
            <StyledPartners>
              <h1>Partners</h1>
              <div>
                {filterData(partners)?.map((item: any) => (
                  <SponsorCard item={item} key={item} />
                ))}
              </div>
            </StyledPartners>
          ) : null}
        </>
      ) :
        null}
    </StyledMain>
  );
};

const StyledPartners = styled.div`
  h1 {
    margin: 30px 0;
    font-weight: 600;
    font-size: 18px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 24px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 16px;
    }
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      grid-template-columns: 1fr;
    }

    div {
      width: 100%;
    }
  }

  margin-bottom: 30px;
`;

const StyledSponsers = styled.div`
  h1 {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 24px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 16px;
    }
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      grid-template-columns: 1fr;
    }

    div {
      width: 100%;
    }
  }

  margin-bottom: 50px;
`;

const StyledImage = styled.div`
  border: 2px solid ${COLORS.CHINESE_WHITE};
  border-radius: 16px;
  width: 50%;
  padding: 35px 12px;
  cursor: pointer;
  ${DISPLAY_FLEX('column')}
  height: fit-content;

  img {
    max-width: 240px;
    height: 101px;
    ${SHARP_IMAGE};
    align-self: center;

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      max-width: 180px;
      height: 75px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      max-width: 120px;
    }
  }
`;

const StyledMain = styled.div<{ eventBanner: 'any' }>`
  ${({ eventBanner }) =>
    !eventBanner
      ? `
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: 12px;
    margin-top: 360px;
    margin-bottom: 30px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_7}) {
    margin-top: 290px;
  }
  `
      : `
      margin-top: 20px;
      margin-bottom: 30px;
      `}
`;

const StyledSponsorsDescription = styled.p`
  margin-top: 20px;
  margin-bottom: -15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
  }
`;

const StyledIconsContainer = styled.div`
  margin-top: 20px;
  ${DISPLAY_FLEX('row')}
  gap: 12px;
  flex-wrap: wrap;
`;
