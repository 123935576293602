import { gql } from '@apollo/client';
import { authClient, unAuthClient } from '../client';
import ERRORS from '../../constants/errors';

const GET_ALL_STATES = gql`
  query getAllStates {
    states {
      id
      name
      status
    }
  }
`;

export const getAllStates = async (addAuthHeader = true) => {
  const client = addAuthHeader ? authClient : unAuthClient;
  return client
    .query({
      query: GET_ALL_STATES
    })
    .then(response => response.data.states)
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const GET_STATES_BY_COUNTRY = gql`
  query getStatesByCountry($country: String!) {
    statesByCountry(country: $country) {
      id
      name
      status
    }
  }
`;

export const getStatesByCountry = (country, addAuthHeader = true) => {
  const client = addAuthHeader ? authClient : unAuthClient;
  return client
    .query({
      query: GET_STATES_BY_COUNTRY,
      variables: {
        country
      }
    })
    .then(response => response.data.statesByCountry)
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
