import React from 'react';
import styled from 'styled-components';
import { COUNTRY_TEXT, STATE_TEXT, ZIPCODE_TEXT } from '../../../constants';
import { COLORS } from '../../../styles/constants/colors';
import { MARGIN } from '../../../styles/constants/display';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { StyledLargeFont } from '../../shared-components/Texts';

export const GrayHeading = styled(StyledLargeFont)`
  color: ${COLORS.SUB_HEADER};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  margin-bottom: 12px;
`;

export const SecondaryHeading = styled(StyledLargeFont)`
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${MARGIN(0, 0, 12, 0)}
`;

const StyledError = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  color: ${COLORS.CUSTOM_RED};
`;

export const getErrorSection = () => <StyledError>Error</StyledError>;

export const JobSpecificFields = [
  'noOfJobs',
  'typesOfJob',
  'typesOfEngagement',
  'tags',
  'technical',
  'management',
  'technoFunctional',
];

export const BasicInfoFields = [
  'nameValue',
  'eventType',
  'dateTime',
  'expectedNoOfAttendees',
  'timezone',
  'domains',
  'categories',
  'description',
  'cover',
  'bookingMethod',
  ZIPCODE_TEXT,
  COUNTRY_TEXT,
  'nationality',
  STATE_TEXT,
];

export const PaymentFields = [
  'userTicketPrice',
  'recruiterTicketPrice',
  'taxName',
  'taxId',
  'taxPercentage',
  'promoCode',
];
