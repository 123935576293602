import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import get from 'lodash/get';
import { SizeMe } from 'react-sizeme';
import { Pagination, Skeleton } from 'antd';
import replace from 'lodash/replace';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { getResume } from '../../../../utils/profile';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { STATUS_DELETE } from '../../../../constants';
import { isDesktop } from '../../../../helpers/display';
import { addClickBlocker } from '../../../../utils/document';
import { COLORS } from '../../../../styles/constants/colors';
import { FONT } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { getColorBySkillPreference } from '../../../../utils/recruiter';

// The commented code is only for testing - Resume Viewer and Skills Highlighting...

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function ResumeViewer({ link, application, selectedSkills }) {
  // Using Backend url to fetch resume data because react-pdf fails to directly get file data from aamzon s3. we have implemented a work around for this. getResume to getFileS3 if proper implementation is found.
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const resume = getResume(link);

  useEffect(() => {
    setPageNumber(1);
  }, [link]);

  const { jobSkills: skills } = get(application, 'job', {});

  const { userSkills: candidateSkills } = get(application, 'candidate', {});

  let jobSkills = [];
  const skillLevel = {};

  // logic to filter and map job skills into object with skill name and skillPreference
  if (skills) {
    jobSkills = skills.filter((skill) => !!get(skill, 'skill.skill', ''));
    jobSkills = jobSkills.map((skill) => {
      const skillobj = {};
      const skillName = get(skill, 'skill.skill', null);

      const skillPreference = get(skill, 'skillPreference', null);

      skillobj.name = skillName;
      skillobj.skillPreference = skillPreference;
      skillLevel[`${skillName.toLowerCase()}`] = skillPreference;
      return skillobj;
    });
  }

  // TODO: move it to utils file later
  /**
   * Merge candidate skills and job skills
   * @param {array} userSkills
   * @param {array} jSkills
   * @returns {array | undefined}
   */
  const mergeCandidateJobSkills = (userSkills, jSkills) => {
    // "Any" should be not be in result list
    const result = jSkills.filter((skill) => skill.name !== 'Any');

    // If there's no userSkills or jobSkills
    if (!userSkills || !jSkills) return;

    userSkills.forEach((skillItem) => {
      const skill = get(skillItem, 'skill.skill', null);
      if (skill) {
        // Find if skill already exist in result array
        if (!result.find((item) => item.name === skill)) {
          result.push({ name: skill, skillPreference: STATUS_DELETE });
        }
      }
    });

    const finalSkillList = result.filter(
      (skill) => !!selectedSkills.includes(skill.name),
    );

    return finalSkillList || [];
  };
  // Assing empty array
  const [mergedSkills, setMergedSkills] = useState([]);

  // Only highlight skills on resume if job doesn't include 'Any' skill
  useEffect(() => {
    if (jobSkills.length !== 0 && !jobSkills.includes('Any')) {
      const mergedSkillsList = mergeCandidateJobSkills(
        candidateSkills,
        jobSkills,
      );
      setMergedSkills(mergedSkillsList);
    } else {
      setMergedSkills([]);
    }
    // eslint-disable-next-line
  }, [selectedSkills]);

  const highlightSkills = (text) => {
    if (mergedSkills && mergedSkills.length) {
      try {
        const pattern = new RegExp(
          mergedSkills
            .map((skill) => replace(skill.name, `++`, /\+\+/))
            .join('|'),
          'gi',
        );

        const splitText = text.split(pattern);

        if (splitText.length <= 1) {
          return text;
        }
        const matches = text.match(pattern);
        return splitText.reduce((arr, element, index) => {
          if (matches[index]) {
            const { bgColor, color } = getColorBySkillPreference(
              skillLevel,
              matches,
              index,
            );
            return [
              ...arr,
              element,
              // This span is for highlight matching job skills in candidate resume ( skillLevel here is used as a map to get value of skillPreference based on name of skill ). based on value of skillPreference we care going for different color codes.1 for maditory{yellow/orange},0 for prefered{gray}.
              <StyledHighLight
                bgColor={bgColor}
                color={color}
                key={index.toString()}
              >
                {matches[index]}
              </StyledHighLight>,
            ];
          }
          return [...arr, element];
        }, []);
      } catch (error) {
        return null;
      }
    }

    return null;
  };

  const customTextRenderer = (searchText) => (textItem) => {
    return highlightSkills(textItem.str, searchText);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handleChangePage = (page) => {
    setPageNumber(page);
  };

  useEffect(() => {
    return addClickBlocker('resume-viewer-disable-right-click');
  }, []);

  return (
    <StyledResumeContainer id="resume-viewer-disable-right-click">
      {resume && !isEmpty(application) ? (
        <>
          <SizeMe
            monitorHeight
            refreshRate={128}
            refreshMode="debounce"
            render={({ size }) => (
              <StyledInnerContainer>
                <Document
                  file={resume}
                  onLoadSuccess={onDocumentLoadSuccess}
                  externalLinkTarget="_blank"
                >
                  <Page
                    pageNumber={pageNumber}
                    width={size.width}
                    customTextRenderer={customTextRenderer()}
                  />
                </Document>
              </StyledInnerContainer>
            )}
          />
          <Pagination
            hideOnSinglePage
            pageSize={1}
            current={pageNumber}
            total={totalPages}
            size={isDesktop() ? 'default' : 'small'}
            onChange={handleChangePage}
          />
        </>
      ) : (
        <Skeleton />
      )}
    </StyledResumeContainer>
  );
}

const StyledResumeContainer = styled.div`
  background-color: ${COLORS.WHITE};
  font-size: ${FONT[18]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[16]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[24]};
  }
`;

const StyledInnerContainer = styled.div`
  padding-bottom: 10px;
`;

const StyledHighLight = styled.span`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  color: ${({ color }) => color || COLORS.WHITE};
  opacity: 0.9;
  border-radius: 5px;
  font-size: inherit;
  position: relative;
  top: -1.9px;
  left: -2px;

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) {
    font-size: 8px;
  }

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) {
    font-size: 11px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 14px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_SPECIAL_CASE_1}) {
    font-size: 18px;
  }
`;
