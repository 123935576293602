import isNumber from 'lodash/isNumber';
import api from './api';
import { Method, APIs } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';

export const fetchEvents = () => {
  return api({
    url: `${APIs.candidate}events/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchLiveEvents = () => {
  return api({
    url: `${APIs.candidate}event/live/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEventById = (id) => {
  return api({
    url: `${APIs.event}${id}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getBookedJobs = (
  event,
  page,
  limit,
  jobTypeFilter,
  engagementFilter,
  locationFilter,
  forceRefetch = false,
) => {
  return api({
    url: `${APIs.candidate}jobs/${event}/?page=${page}&limit=${limit}${
      isNumber(jobTypeFilter) ? `&jobType=${jobTypeFilter}` : ''
    }${isNumber(engagementFilter) ? `&engagement=${engagementFilter}` : ''}${
      isNumber(locationFilter) ? `&jobLocationType=${locationFilter}` : ''
    }${forceRefetch ? `&forceRefetch=${forceRefetch}` : ''}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getOtherJobsData = (eventId, page, limit) => {
  return api({
    url: `${APIs.candidate}opportunities/${eventId}/any/?page=${page}&limit=${limit}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const jobApplied = (body) => {
  return api({
    url: `${APIs.candidate}applyJob/`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const jobDetails = (jobId) => {
  return api({
    url: `${APIs.candidate}job/${jobId}`,
    method: Method.get,
    body: jobId,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const companyDetails = (companyId) => {
  return api({
    url: `${APIs.company}${companyId}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
