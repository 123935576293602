import React from 'react';

const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_7_1"
      data-name="Component 7 – 1"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_62"
        data-name="Rectangle 62"
        width="40"
        height="40"
        rx="18"
        fill="#f0f0f0"
      />
      <path
        id="Icon_awesome-twitter"
        data-name="Icon awesome-twitter"
        d="M20.8,8.073c.015.206.015.412.015.618A13.424,13.424,0,0,1,7.3,22.207,13.425,13.425,0,0,1,0,20.075a9.828,9.828,0,0,0,1.147.059,9.514,9.514,0,0,0,5.9-2.03A4.759,4.759,0,0,1,2.6,14.809a5.991,5.991,0,0,0,.9.074,5.025,5.025,0,0,0,1.25-.162A4.751,4.751,0,0,1,.941,10.058V10a4.784,4.784,0,0,0,2.147.6A4.758,4.758,0,0,1,1.618,4.249a13.5,13.5,0,0,0,9.8,4.971A5.363,5.363,0,0,1,11.3,8.132a4.755,4.755,0,0,1,8.222-3.251,9.353,9.353,0,0,0,3.015-1.147,4.738,4.738,0,0,1-2.089,2.618,9.524,9.524,0,0,0,2.736-.735A10.212,10.212,0,0,1,20.8,8.073Z"
        transform="translate(8 7.619)"
        fill="#8c8c8c"
      />
    </svg>
  );
};

export default TwitterIcon;
