import React from 'react';
import { Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { checkIfUserIsCandidate, getRoleId } from '../../utils/userInfo';
import { TextUploadFileList } from '../Upload/Upload';
import {
  DocumentViewer,
  HiddenMobile,
} from '../shared-components/DocumentViewerModal';
import Notification from '../Notification/Notification';
import { deleteCertificateData } from '../../store/features/profile';
import { MARGIN } from '../../styles/constants/display';
import { ShowMobile } from '../shared-components/common';
import { COLORS } from '../../styles/constants/colors';

const Certificates = ({
  certificationsList,
  UpdateCertificationCustomReq,
  certificationOnChange,
  UploadResumeSupportedFiles,
  checkFileBefore
}) => {
  const roleId = getRoleId();
  const dispatch = useDispatch();
  const handleDeleteCertificate = async (name) => {
    dispatch(deleteCertificateData(name));
    Notification('info', 'Certificate Deletion in Process');
  };

  return (
    <>
      <Row>
        {certificationsList.length
          ? certificationsList.map((item) => {
              return (
                <DocumentViewer
                  filePath={item.url}
                  fileName={item.name}
                  onDelete={
                    checkIfUserIsCandidate(roleId)
                      ? () => handleDeleteCertificate(item.fileName)
                      : null
                  }
                />
              );
            })
          : null}
      </Row>
      <StyledCertificateUpload>
        <TextUploadFileList
          name="uploadCertifications"
          className="upload_btn"
          icon={<UploadOutlined />}
          accept={UploadResumeSupportedFiles}
          customRequest={UpdateCertificationCustomReq}
          onChange={certificationOnChange}
          showUploadList={false}
          fileList={certificationsList}
          beforeUpload={(file) => checkFileBefore(file)}
        />
        <HiddenMobile>
          <StyledHintMessage>
            <span>Add or link external documents and presentations</span>
          </StyledHintMessage>
        </HiddenMobile>
      </StyledCertificateUpload>
      <ShowMobile>
        <StyledHintMessage>
          <span>Add or link external documents and presentations</span>
        </StyledHintMessage>
      </ShowMobile>
    </>
  );
};

export default Certificates;

const StyledCertificateUpload = styled.div`
  display: flex;
  margin-top: 20px;
`;

const StyledHintMessage = styled.div`
  font-size: small;
  color: ${COLORS.GRAY};
  ${MARGIN(8, 0, 0, 10)}
`;
