const eventTypes = [
  { id: 2, name: 'Online', value: 'online' },
  { id: 1, name: 'Hybrid', value: 'hybrid' },
];

const currencyList = [
  { id: 1, name: 'USD $', value: 'USD $' },
  { id: 2, name: 'INR ₹', value: 'INR ₹' },
];

// Job opportunities
const jobTypes = [
  { id: 1, name: 'Full-time' },
  { id: 2, name: 'Part-time' },
];

const engagementTypes = [
  { id: 1, name: 'Internship' },
  { id: 2, name: 'Permanent Employment' },
  { id: 3, name: 'Contract & Consulting' },
  { id: 4, name: 'Freelancing' },
];

export const bookingMethods = [
  { id: 1, name: 'Recorem', value: 1 },
  { id: 2, name: 'Third Party', value: 2 },
];

export { eventTypes, currencyList, jobTypes, engagementTypes };
