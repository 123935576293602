import { COLORS } from '../styles/constants/colors';

export const cryptoIncentivePlanColor = {
  1: COLORS.CURIOS_BORDER_2,
  2: COLORS.OPTIMISTIC_BORDER_2,
  3: COLORS.BELIEVER_BORDER_2,
};

export enum allowAccessWithoutCryptoIncentivePlan {
  DISABLE = 'disable',
  ENABLE = 'enable',
  SKIPPED = 'skipped',
}
