import React, { memo, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import toLowerCase from 'lodash/toLower';
import {
  EventsStore,
  getActiveBookingEvents,
  getUpComingEvent,
} from '../../../utils/event';
import {
  checkIfUserIsOrganizer,
  getRoleId,
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../../../utils/userInfo';
import { EventCard } from '../../EventCard';
import EventCardCandidate from '../../shared-components/EventCard';
import { StyledMainHeader } from '../../shared-components/Event/Common';
import { RecruiterRoutes, CandidateRoutes } from '../../../constants/routes';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { FONT } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { MARGIN } from '../../../styles/constants/display';
import AppSocketContext from '../../../context/AppSocket';
import { userProfileStore } from '../../../utils/profile';

const UpcomingEventsContent = ({
  dashboard,
  search = '',
  noTimeline = null,
  noTitle = null,
  isCandidateDashboard,
}) => {
  const { upComingEvents } = EventsStore();
  const roleId = getRoleId();
  const client = useContext(AppSocketContext);
  const { profileData } = userProfileStore();
  const [upComingEventsFilteredList, setUpComingEventsFilteredList] = useState(
    [],
  );
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(
      dashboard || checkIfUserIsOrganizer(roleId)
        ? upComingEvents
        : getActiveBookingEvents(upComingEvents, false, roleId),
    );

    // eslint-disable-next-line
  }, [upComingEvents]);

  useEffect(() => {
    const upcomming = isArray(events)
      ? events.filter((event) =>
        toLowerCase(event.name).includes(toLowerCase(search)),
      )
      : [];

    setUpComingEventsFilteredList(upcomming);

    // eslint-disable-next-line
  }, [search, events]);

  useEffect(() => {
    const companyId = get(profileData, 'company.id', null);
    if (client && companyId) {
      (async () => {
        await client.subscribe(
          `/company/${companyId}/booked-event/`,
          (resp) => {
            const event = events.find(
              (eventData) => eventData.id === get(resp, 'event.id'),
            );
            const newbookedEvent = get(resp, 'event', null);
            if (!event && getUpComingEvent(newbookedEvent)) {
              const tempEventList = [newbookedEvent, ...events];
              setEvents(tempEventList);
            }
          },
        );
      })();
    }
    // eslint-disable-next-line
  }, [get(profileData, 'company.id', null)]);

  if (!isArray(events) || !events.length) {
    return (
      <>
        <StyledPlaceholderWrapper>
          {!noTitle && (
            <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
              Upcoming Events
            </StyledMainHeader>
          )}
          <StyledPlaceholderText noTitle={noTitle}>
            {checkIfUserIsCandidate(roleId) ? (
              <StyledSpanText>
                No upcoming events at the moment. Time to{' '}
                <StyledSpanText>
                  <StyledCreateEventPathLink to={CandidateRoutes.exploreEvent}>
                    “Explore Events”
                  </StyledCreateEventPathLink>
                </StyledSpanText>
                .
              </StyledSpanText>
            ) : checkIfUserIsRecruiter(roleId) ? (
              <StyledSpanText>
                No upcoming events at the moment. Time to{' '}
                <StyledSpanText>
                  <StyledCreateEventPathLink to={RecruiterRoutes.exploreEvent}>
                    “Explore Events”
                  </StyledCreateEventPathLink>
                </StyledSpanText>
                .
              </StyledSpanText>
            ) : (
              <StyledSpanText>
                Your calendar looks free. Time to organize a new event.{' '}
              </StyledSpanText>
            )}
          </StyledPlaceholderText>
        </StyledPlaceholderWrapper>
      </>
    );
  }

  return (
    <>
      {!noTitle && (
        <StyledMainHeader
          color={getUserColorWithRoleId(roleId)}
          id="upcoming_event_title"
        >
          Upcoming Events
        </StyledMainHeader>
      )}

      {checkIfUserIsCandidate(roleId) ? (
        <Row gutter={[16, 0]} style={{ paddingTop: '20px' }}>
          {upComingEventsFilteredList.map((event, idx) => {
            return (
              <Col
                xs={24}
                sm={12}
                lg={8}
                xxl={isCandidateDashboard ? 8 : 6}
                key={idx}
              >
                <EventCardCandidate
                  roleId={roleId}
                  event={event}
                  category="upcoming"
                // noTimeline={noTimeline}
                // noTitle={noTitle}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <EventCard
          roleId={roleId}
          events={upComingEventsFilteredList}
          category="upcoming"
          noTimeline={noTimeline}
          noTitle={noTitle}
        />
      )}
    </>
  );
};

export default memo(UpcomingEventsContent);

const StyledPlaceholderWrapper = styled(Col)`
  margin-top: 0px;
`;

const StyledPlaceholderText = styled.div`
  ${MARGIN(12, 0, 0, 0)};
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${({ noTitle }) => noTitle && MARGIN(15, 0, 0, 0)}
  }
`;

const StyledSpanText = styled.span`
  color: ${COLORS.GRAY};
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledCreateEventPathLink = styled(Link)`
  color: ${COLORS.DODGER_BLUE} !important;
  cursor: pointer !important;
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;
