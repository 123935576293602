import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getSingleUserData, isSender } from '../../../utils/chat';
import { userProfileStore } from '../../../utils/profile';
import { getCompanyId } from '../../../utils/userInfo';
import { ReceiverThread } from '../../shared-components/Chat/ReceiverThread';
import { SenderThread } from '../../shared-components/Chat/SenderThread';

const ThreadList = ({ messages, isWithoutIcon = false }) => {
  const {
    profileData: { userId, profilePic: senderProfilePic },
  } = userProfileStore();

  const companyId = getCompanyId();

  const { selectedChatDetails, selectedChatUsersDetails } = useSelector(
    (state) => state.chat,
  );

  const receiverUserId = get(selectedChatDetails, 'user.id', '');

  let receiverProfileDetails;
  const userType = get(selectedChatDetails, 'user.type', '');
  if (userType === 'company') {
    receiverProfileDetails = getSingleUserData(
      null,
      receiverUserId,
      selectedChatUsersDetails,
    );
  } else {
    receiverProfileDetails = getSingleUserData(
      receiverUserId,
      null,
      selectedChatUsersDetails,
    );
  }

  const receiverProfilePic = get(receiverProfileDetails, 'profilePic', '');

  return messages.map((msg, idx) => {
    return isSender(msg, companyId, userId) ? (
      <SenderThread
        key={idx.toString()}
        messageData={msg}
        profilePic={senderProfilePic}
        isWithoutIcon={isWithoutIcon}
      />
    ) : (
      <ReceiverThread
        key={idx.toString()}
        messageData={msg}
        profilePic={receiverProfilePic}
        isWithoutIcon={isWithoutIcon}
      />
    );
  });
};

export default ThreadList;
