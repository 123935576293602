import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { AdminRoutes } from '../../../constants/routes';
import { getEvents } from '../../../service/masterAdmin';
import { DropdownBox } from '../../../components/shared-components/Form/Form';
import Candidates from './candidates';
import Companeies from './companies';
import { getUrlVars } from '../../../utils/common';
import { getEventOptions } from '../../../utils/event';
import { PageContainer } from '../../../components/shared-components/PageContainer';
import { SemiLargeHeading } from '../../../components/shared-components/Texts';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { ADMIN } from '../../../constants';
import { MARGIN } from '../../../styles/constants/display';
import { TabSwitch } from '../../../components/shared-components/Tabs';

const InvitedAttendees = ({ match, history }) => {
  const defaultTab = match.params.tab ? match.params.tab : 1;
  const [tab, setTab] = useState(defaultTab);
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState([]);

  const { eventId } = getUrlVars(get(history, 'location.search', {}));

  useEffect(() => {
    (async () => {
      const res = await getEvents(1, -1, 'basic');

      setEvents(get(res, 'data.payload.events', []));
    })();
    if (!parseInt(match.params.tab)) {
      history.push(`${AdminRoutes.bulkInvite}/${1}`);
      setTab(1);
    }

    // eslint-disable-next-line
  }, []);

  const tabChange = (currentTab) => {
    setTab(currentTab);
    const id = get(event, 'id', null);
    if (currentTab) {
      history.push(
        `${AdminRoutes.bulkInvite}/${currentTab}${id ? `?eventId=${id}` : ''}`,
      );
    }
  };

  const handleChange = (id) => {
    history.push(`${AdminRoutes.bulkInvite}/${tab}?eventId=${id}`);
  };

  useEffect(() => {
    if (eventId) {
      setEvent({ id: parseInt(eventId) });
    }
  }, [eventId]);

  const tabsItems = [
    {
      key: 1,
      name: 'Attendees',
      component: <Candidates event={event} history={history} />,
    },
    {
      key: 2,
      name: 'Companies',
      component: <Companeies event={event} history={history} />,
    },
  ];

  return (
    <div>
      <PageContainer>
        <SemiLargeHeading
          color={getUserColorWithRoleId(ADMIN)}
          margin="0 0 15px 0"
        >
          Invited Attendees
        </SemiLargeHeading>
        <StyledSelectContainer>
          <StyledSelectHeading>Select Event</StyledSelectHeading>
          <Col xs={10} md={8} lg={6}>
            <DropdownBox
              placeholder="event"
              value={event ? parseInt(get(event, 'id', null)) : null}
              options={getEventOptions(events, true)}
              handleChange={handleChange}
              showArrow
              showSearch
            />
          </Col>
        </StyledSelectContainer>
        <Row>
          <TabSwitch
            items={tabsItems}
            onChange={tabChange}
            defaultActiveKey={defaultTab}
            theme="admin"
          />
        </Row>
      </PageContainer>
    </div>
  );
};

export default InvitedAttendees;

const StyledSelectHeading = styled(Col)`
  ${MARGIN(0, 15, 0, 0)}
`;

const StyledSelectContainer = styled(Row)`
  ${MARGIN(10, 0)}
`;
