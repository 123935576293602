import { createSlice } from '@reduxjs/toolkit';
import {
  addBillingAddress,
  getBillingAddresses,
} from '../../service/billingAddress';

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.address = action.payload;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const billingAddressSlice = createSlice({
  name: 'billingAddress',
  initialState: {
    isLoading: false,
    address: [],
    error: null,
  },
  reducers: { onStart, onSuccess, onError },
});

const getBillingAddressData = () => async (dispatch) => {
  try {
    dispatch(billingAddressSlice.actions.onStart());
    const address = await getBillingAddresses();
    dispatch(billingAddressSlice.actions.onSuccess(address));
  } catch (err) {
    dispatch(billingAddressSlice.actions.onError(err.toString()));
  }
};

const addBillingAddressData = (data) => async (dispatch) => {
  try {
    dispatch(billingAddressSlice.actions.onStart());
    await addBillingAddress(data);
    dispatch(getBillingAddressData());
  } catch (err) {
    dispatch(billingAddressSlice.actions.onError(err.toString()));
  }
};

export default billingAddressSlice.reducer;

export { getBillingAddressData, addBillingAddressData };
