import React from 'react';

const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_6_1"
      data-name="Component 6 – 1"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_61"
        data-name="Rectangle 61"
        width="40"
        height="40"
        rx="18"
        fill="#f0f0f0"
      />
      <path
        id="Icon_awesome-facebook-f"
        data-name="Icon awesome-facebook-f"
        d="M12.7,12.462l.615-4.009H9.466v-2.6a2,2,0,0,1,2.26-2.166h1.749V.271A21.328,21.328,0,0,0,10.37,0C7.2,0,5.131,1.92,5.131,5.4V8.452H1.609v4.009H5.131v9.692H9.466V12.462Z"
        transform="translate(12.391 9)"
        fill="#8c8c8c"
      />
    </svg>
  );
};

export default FacebookIcon;
