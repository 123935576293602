import { useEffect, useState, useCallback } from 'react';
import Notification from '../components/Notification/Notification';
import {
  ACCEPTED_EVENT_COVER_IMAGE,
  EVENT_IMAGE_COVER_PAYLOAD,
} from '../constants/file';
import { uploadFile } from '../service';
import { useUploadEventCoverMutation } from '../store/features/rtkApi/common';
import { imageValidator } from '../utils/common';

const useCommunityPageCover = (eventId) => {
  const [coverFile, setCoverFile] = useState([]);
  const [
    changeCover,
    { isSuccess: isCoverPhotoUpdated, isError: isCoverPhotoUploadFailed },
  ] = useUploadEventCoverMutation();

  useEffect(() => {
    if (isCoverPhotoUpdated) {
      Notification('success', 'Successfully updated the cover photo');
    }
    if (isCoverPhotoUploadFailed) {
      Notification('error', 'Failed to update the cover photo');
    }
  }, [isCoverPhotoUpdated, isCoverPhotoUploadFailed]);

  const uploadEventCover = useCallback(
    async (cover) => {
      setCoverFile([cover.file]);
      uploadFile(cover.file, 'image')
        .then((fileName) => {
          cover.onSuccess();
          changeCover({ eventId, fileName });
        })
        .catch(() => cover.onError());
    },
    [eventId, changeCover],
  );

  const handleCoverChange = async (info) => {
    setCoverFile([info.file]);
  };

  const handleCoverBeforeUpload = useCallback((file) => {
    if (file.size > ACCEPTED_EVENT_COVER_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...EVENT_IMAGE_COVER_PAYLOAD, file });
  }, []);

  return {
    uploadEventCover,
    handleCoverChange,
    coverFile,
    handleCoverBeforeUpload,
  };
};

export default useCommunityPageCover;
