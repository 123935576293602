import React, { useState } from 'react';
import { Row, Col, Form, message } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import {
  TextBox,
  Dropdown,
  NumberBox,
  DateTimePicker,
} from '../shared-components/Form/Form';
import { currentDate } from '../../utils/formatDateTime';
import {
  discountType,
  applicable,
} from '../../mocks/organizer/events/promo-code';
import { GrayHeading } from '../Events/EventForm/Common';
import { PrimaryButton } from '../shared-components/Button';
import { getThemeByRoleId } from '../../utils/common';
import { getRoleId } from '../../utils/userInfo';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';
import MandatoryStar from '../Common/mandatoryStar';
import { DEFAULT_CURRENCY } from '../../constants/currency';
import { getDiscountedPrice } from '../../utils/event';
// TODO: remove these mock dependencies: discountType and applicable

const PromoForm = ({
  field,
  idx,
  promoDiscountType,
  promoApplicable,
  remove,
  selectedCurrency,
  currencyData,
  form,
}) => {
  const fields = form.getFieldsValue();
  const promoCode = get(fields, 'promoCode', []);
  const value = get(promoCode, `[${idx}]`, null);
  const tempType = get(value, 'type', null);
  const tempApplicable = get(value, 'applicable', null);
  const [type, setType] = useState(null);
  const [applicable, setApplicable] = useState(null);

  const validator = (rule, value) => {
    const validationType = type || tempType;
    const validationApplicable = applicable || tempApplicable;
    if (!validationType) {
      message.error('Please select the type of the promo code first');
      return Promise.reject('');
    }

    if (!validationApplicable) {
      message.error('Please select the user applicable for the promo');
      return Promise.reject('');
    }

    const toCurrency = selectedCurrency || 'INR';
    const firstCurrencyConverting = currencyData.find(
      (c) => c.value === DEFAULT_CURRENCY,
    );
    // Convert from usd to new currency
    const secondCurrencyConverting = currencyData.find(
      (c) => c.value === toCurrency,
    );

    const toAmount = selectedCurrency
      ? (parseFloat(0.5) / parseFloat(firstCurrencyConverting.usdRate)) *
      parseFloat(secondCurrencyConverting.usdRate)
      : 0.5;

    const ticketPrice =
      validationApplicable === 'Candidate'
        ? form.getFieldValue('userTicketPrice')
        : form.getFieldValue('recruiterTicketPrice');

    if (!isNumber(ticketPrice)) {
      message.error(`Please fill the ${validationApplicable} ticket price`);
      return Promise.reject('');
    }

    const discountedValue = getDiscountedPrice(
      ticketPrice,
      validationType,
      value,
    );

    if (discountedValue >= toAmount) {
      return Promise.resolve();
    }
    return Promise.reject('');
  };

  return (
    <StyledPromoContainer key={idx.toString()}>
      <Row gutter={[25, 10]}>
        <Col xs={24} lg={6}>
          <GrayHeading>
            Promo Code <MandatoryStar />
          </GrayHeading>
          <TextBox
            rule="charAndNumber"
            name={[field.name, 'code']}
            placeholder="Enter code"
          />
        </Col>
        <Col xs={24} lg={6}>
          <GrayHeading>
            Type of Promo <MandatoryStar />
          </GrayHeading>
          <Dropdown
            name={[field.name, 'type']}
            placeholder="Select promo type"
            options={promoDiscountType}
            handleChange={(value) => setType(value)}
            required={true}
          />
        </Col>
        <Col xs={24} lg={6}>
          <GrayHeading>
            Promo Applicable <MandatoryStar />
          </GrayHeading>
          <Dropdown
            name={[field.name, 'applicable']}
            placeholder="Select applicable"
            options={promoApplicable}
            handleChange={(value) => setApplicable(value)}
            required={true}
          />
        </Col>
      </Row>
      <Row gutter={[25, 10]}>
        <Col xs={24} lg={6}>
          <GrayHeading>
            Value <MandatoryStar />
          </GrayHeading>
          <NumberBox
            name={[field.name, 'value']}
            placeholder="Enter value"
            validator={validator}
            rule="numberWithCustomValidation"
          />
        </Col>
        <Col xs={24} lg={6}>
          <GrayHeading>
            Total Count <MandatoryStar />
          </GrayHeading>
          <NumberBox name={[field.name, 'count']} placeholder="Enter count" />
        </Col>
        <Col xs={24} lg={6}>
          <GrayHeading>
            Validity Date & Time <MandatoryStar />
          </GrayHeading>
          <DateTimePicker
            name={[field.name, 'expiresIn']}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment(), moment().endOf('month')],
            }}
            showTime
            disabledDate={(d) =>
              d.isSameOrBefore(currentDate(true, 'lll')) ||
              d.isSameOrAfter(
                get(
                  form.getFieldValue('dateTime'),
                  '[1]',
                  currentDate(true, 'lll'),
                ),
              )
            }
            format="DD/MM/YYYY HH:mm"
            getPopupContainer={(trigger) => trigger.parentElement}
          />
        </Col>
      </Row>
      <StyledPromoRemove onClick={() => remove(field.name)}>
        Remove
      </StyledPromoRemove>
    </StyledPromoContainer>
  );
};

const PromoCode = ({
  promoDiscountType = discountType,
  promoApplicable = applicable,
  selectedCurrency,
  currencyData,
  form,
  BtnDisabled
}) => {
  const roleId = getRoleId();
  return (
    <div>
      <Form.List name="promoCode">
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[0, 20]}>
              <Col xs={24} lg={8}>
                <PrimaryButton
                  theme={getThemeByRoleId(roleId)}
                  onClick={() => add()}
                  disabled={BtnDisabled}
                >
                  + Promo Code
                </PrimaryButton>
              </Col>
            </Row>
            <StyledReverseColumn>
              {fields?.map((field, idx) => (
                <PromoForm
                  field={field}
                  idx={idx}
                  remove={remove}
                  promoApplicable={promoApplicable}
                  promoDiscountType={promoDiscountType}
                  selectedCurrency={selectedCurrency}
                  currencyData={currencyData}
                  form={form}
                />
              ))}
            </StyledReverseColumn>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default PromoCode;

const StyledPromoRemove = styled.div`
  font-size: ${FONT[16]};
  color: ${COLORS.LIVE_COLOR};
  padding-top: 0;
  margin-top: 0;
  font-weight: ${FONT_WEIGHTS.REGULAR};
  cursor: pointer;
  width: max-content;
`;

const StyledPromoContainer = styled.div`
  border: 1px dashed ${COLORS.SUB_HEADER};
  border-radius: 3px;
  margin-top: 20px;

  ${PADDING(25)}
`;

const StyledReverseColumn = styled.div`
  ${DISPLAY_FLEX('column-reverse')};
`;
