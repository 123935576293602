import React, { useContext, useState } from 'react';
import { Row, Col, Tooltip } from 'antd';
import styled from 'styled-components';
import { CalendarContext } from './Calendar';
import {
  getRoleId,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { LargeSpecialCaseFont } from '../shared-components/Texts';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import {
  CALENDAR_FILTER_STATUS,
  CandidateMetaDataList,
  OrganizerMetaDataList,
  RecruiterMetaDataList,
} from '../../constants/calendar';
import { getUserColorWithRoleId } from '../../styles/utils';

const CalendarMetaInformation = () => {
  const setEventState: any = useContext(CalendarContext);
  const roleId = getRoleId();
  const getMetaDataList = () => {
    if (checkIfUserIsOrganizer(roleId)) {
      return OrganizerMetaDataList;
    }
    if (checkIfUserIsRecruiter(roleId)) {
      return RecruiterMetaDataList;
    }
    return CandidateMetaDataList;
  };

  const [clicked, setClicked] = useState<any>(getMetaDataList().length - 1);

  const handleClick = (item: any, i: number) => {
    if (i === item.id) {
      if (i === clicked) {
        // Set to show all if same clicked
        setClicked(4);
        const list = getMetaDataList();
        const newEventState = list?.[list?.length - 1] || {};
        setEventState(newEventState);
      } else {
        setEventState(item);
        setClicked(i);
      }
    }
  };

  const filterApplyTitleText = 'Click to filter';
  const filterRemoveTitleText = 'Click to remove filter';

  return (
    <>
      <StyledWrapper className="calendar-filter-feature-tour">
        {getMetaDataList().map((item, i) =>
          item?.status !== CALENDAR_FILTER_STATUS.ALL ? (
            <Tooltip
              title={
                clicked === i ? filterRemoveTitleText : filterApplyTitleText
              }
              color={getUserColorWithRoleId(roleId)}
            >
              <StyledMetaContainer
                key={i.toString()}
                buttonClicked={clicked === i}
                color={item.colorName}
                onClick={() => handleClick(item, i)}
              >
                <ColoredCircle color={item.colorName} />
                <LargeSpecialCaseFont>{item.name}</LargeSpecialCaseFont>
              </StyledMetaContainer>
            </Tooltip>
          ) : null,
        )}
      </StyledWrapper>
    </>
  );
};

export default CalendarMetaInformation;

const StyledWrapper = styled(Row)`
  ${DISPLAY_FLEX('row')}
  width: fit-content;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, null, 15)}
    width: 100%;
  }
`;

// eslint-disable-next-line no-undef
const StyledMetaContainer = styled.button<{ buttonClicked: boolean }>`
  ${DISPLAY_FLEX('row', null, 'center')}
  cursor: pointer;
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${MARGIN(null, 0)}
  background: none;
  border: none;

  &:not(:first-child) {
    ${MARGIN(null, null, null, 15)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    &:not(:first-child) {
      ${MARGIN(null, null, null, 0)}
    }

    margin: 10px 0;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(null, 10)}
  }

  div,
  span {
    padding: 2px 8px;
    border-radius: 3px;
    background-color: ${({ buttonClicked, color }) =>
      buttonClicked ? color : ''};
    color: ${({ buttonClicked }) => (buttonClicked ? COLORS.WHITE : '')};
  }
`;

const ColoredCircle = styled(Col)`
  position: relative;
  width: 14px;
  height: 14px;
  ${MARGIN(0, 5)}
  padding: 0!important;
  border-radius: 100% !important;
  background-color: ${(props) => props.color};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 20px;
    height: 20px;

    ${MARGIN(0, 10)}
  }
`;
