import get from 'lodash/get';
import api from './api';
import { APIs, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import {
  getNearByEvents,
  getRecommendedEvents,
  getCalendarEvents,
} from './event';

export const getCandidateBookedEvents = async () => {
  return api({
    url: `${APIs.candidate}events/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.events', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCandidateRecommendedEvents = async (
  page,
  limit,
  isLivePriority = false,
) => {
  return getRecommendedEvents(APIs.candidate, page, limit, isLivePriority);
};

export const getCandidateNearByEvents = async () => {
  return getNearByEvents(APIs.candidate);
};

export const getCandidateCalendarEvents = async (
  startTimestamp,
  endTimestamp,
) => {
  return getCalendarEvents(APIs.candidate, startTimestamp, endTimestamp);
};

export const requestNewInterviewSlot = async (id) => {
  return api({
    url: APIs.requestNewInterviewRequest(id),
    method: Method.get,
  });
};

export const getWorkAuthorizationData = async () => {
  return api({
    url: `${APIs.candidate}work-authorization/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.workAuthorizations', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkJobWorkAuthorization = async (jobId) => {
  return api({
    url: `${APIs.candidate}work-authorization/job/${jobId}/check/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateWorkAuthorization = async (body) => {
  return api({
    url: `${APIs.candidate}work-authorization/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkIfJobMatchWithCandidate = async (eventId, jobId) => {
  return api({
    url: `${APIs.candidate}job/check/${eventId}/${jobId}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.isMatching', false);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkIfUserIsNew = async () => {
  return api({
    url: `${APIs.candidate}check/new/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
