import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { MARGIN } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import {
  StyledLargeFont,
  StyledLargeHeading,
  StyledMediumHeading,
  StyledSmallHeading,
} from '../shared-components/Texts';

export const InvitedText = styled(StyledLargeFont)`
  color: ${COLORS.SUPER_NOVA};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

export const PendingText = styled(StyledLargeFont)`
  color: ${COLORS.SUPER_NOVA};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

export const CrossText = styled(StyledLargeFont)`
  color: ${COLORS.CUSTOM_RED};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  &:hover {
    cursor: pointer;
  }
  ${MARGIN(0, 0, 0, 15)}
`;

export const DangerText = styled(StyledLargeFont)`
  color: ${COLORS.CUSTOM_RED};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  &:hover {
    cursor: pointer;
  }
`;

export const ActiveText = styled(StyledLargeFont)`
  color: ${COLORS.PUERTO_RICO};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  &:hover {
    cursor: pointer;
  }
`;

export const ProfileInformationValue = styled(StyledSmallHeading)`
  word-wrap: break-word;
  ${MARGIN(0, 0, 10, 0)}
  ${({color}) => color ? `color: ${color}` : ''}

  @media (max-width:${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[13]};
  }
`;

export const ProfileInformationHeader = styled(StyledSmallHeading)`
  word-wrap: break-word;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${({ color }) => color || COLORS.PHILLIPPINE_GRAY};
  ${MARGIN(0, 0, 10, 0)}

  @media (max-width:${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[13]};
  }
`;

export const StyledRepresentativeHeading = styled(StyledLargeFont)`
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${MARGIN(15, 0)}
`;

export const StyledProfileMediumHeading = styled(StyledMediumHeading)`
  color: ${(props) => props.color || COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${MARGIN(20, 0)}

  @media (max-width:${BREAKPOINTS.MOBILE}) {
    ${MARGIN(10)}
  }
`;

export const StyledProfileLargeHeading = styled(StyledLargeHeading)`
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(20, 0)}

  @media (max-width:${BREAKPOINTS.MOBILE}) {
    ${MARGIN(15, 0)}
  }
`;
