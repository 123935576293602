/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Input, Switch } from 'antd';
import SendIcon from '../../../assets/svg/send.svg';
import { sendMessage } from '../../../service/chat';
import { ChatInputProps } from '../../../interfaces/components/Chat';
import { InputBox } from '../Form/Form';
import Notification from '../../Notification/Notification';
import { DISPLAY_FLEX, PADDING } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT } from '../../../styles/constants/typography';
import { useTagManager } from '../../../utils/customHooks';
import { PrimaryButton } from '../Button';
import { getProfile } from '../../../service/auth';
import { setUserAnonymous } from '../../../store/features/chat';
import { isChatDisabled } from '../../../utils/chat';
import { BOX_SHADOW } from '../../../styles/constants/box-shadow';
import { EmojiPicker } from './common';
import { Giphy } from './Giphy';
import { MESSAGE_CONTENT_TYPE } from '../../../constants/chat';
import { MESSAGE_CONTENT } from '../../../constants/chat';
import { CHAT_ID_TYPE } from '../../../constants/chat'

export const ChatInput: React.FC<ChatInputProps> = ({
  type,
  discussionRoom,
}) => {
  const {
    selectedChatId,
    selectedGroupChatId,
    selectedGroupChatUsersDetails,
    selectedGroupChatDetails,
  } = useSelector((state: any) => state.chat);

  const [message, setMessage] = useState('');
  const [inProcess, setInProcess] = useState(false);
  const [anonymity, setUserAnonymity] = useState(false);
  const updateUserActionInGTM = useTagManager();
  const latestValue = useRef(message);

  const dispatch = useDispatch();

  React.useEffect(() => {
    async function getUserData() {
      const response: any = await getProfile();
      if (response) {
        return selectedGroupChatUsersDetails?.users?.map(async (item: any) => {
          if (item.id === response.data.payload.profile.userId) {
            setUserAnonymity(item.isAnonymous);
            await dispatch(setUserAnonymous(item.isAnonymous));
          }
        });
      }
      return response;
    }

    getUserData();
  }, [selectedGroupChatUsersDetails, dispatch]);

  const handleSetMessage = (value: any) => {
    if (typeof value === 'function') {
      setMessage((prev) => {
        const newValue = value(prev);
        latestValue.current = newValue;
        return newValue;
      });
    } else {
      latestValue.current = value;
      setMessage(value);
    }
  };

  const onSendMessage = async (content: string, gifOrImageString?: string) => {
    let contentType: number = MESSAGE_CONTENT_TYPE.TEXT;
    let finalMessage: string = latestValue.current || message;

    switch (content) {
      case MESSAGE_CONTENT.GIF:
        contentType = MESSAGE_CONTENT_TYPE.GIF;
        finalMessage = gifOrImageString || "";
        break;
      case MESSAGE_CONTENT.TEXT:
        if (finalMessage.length === 0) {
          return;
        }
        if (finalMessage.length > 500) {
          Notification('error', 'Message has to be less than 500 characters! ');
        }
        break;
    }

    if (!inProcess) {
      setInProcess(true);
      handleSetMessage('');
      await sendMessage(
        type === CHAT_ID_TYPE.PERSONAL ? selectedChatId : selectedGroupChatId,
        finalMessage,
        !!anonymity,
        contentType
      );
      await updateUserActionInGTM('event_personal_chat_sent');
      setInProcess(false);
    }
  };

  const handler = useCallback((e: any) => {
    if (e.key === 'Enter') {
      onSendMessage(MESSAGE_CONTENT.TEXT);
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    document.addEventListener('keypress', handler);

    return () => document.removeEventListener('keypress', handler);

    // eslint-disable-next-line
  }, []);

  async function handleAnonymity() {
    setUserAnonymity(!anonymity);
    // await setAnonymity(!anonymity);
    await dispatch(setUserAnonymous(!anonymity));
  }

  return (
    <>
      {!discussionRoom ? (
        <>
          <InputContainer>
            <EmojiPicker
              setMessage={handleSetMessage}
              onSendMessage={onSendMessage}
            />
            <InputBox
              disabled={
                selectedGroupChatId
                  ? isChatDisabled(selectedGroupChatDetails?.status)
                  : false
              }
              id={
                type === CHAT_ID_TYPE.PERSONAL
                  ? CHAT_ID_TYPE.CHAT_MESSAGE_INPUT
                  : CHAT_ID_TYPE.GROUP_CHAT_MESSAGE_INPUT
              }
              placeholder="Write Something.."
              value={message}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSetMessage(e.target.value)
              }
              pressEnter={() => onSendMessage(MESSAGE_CONTENT.TEXT)}
              autoComplete="off"
            />
            <Giphy onSendMessage={onSendMessage} gifBtnClickable={true} />
            <StyledImg
              src={SendIcon}
              alt="Send"
              onClick={() => onSendMessage(MESSAGE_CONTENT.TEXT)}
              isActive={!!message}
            />
          </InputContainer>

        </>
      ) : (
        <StyledCommentBox isAnonymous={anonymity}>
          <StyledBorderBox>
            <StyledInput
              id={
                type === CHAT_ID_TYPE.PERSONAL
                  ? CHAT_ID_TYPE.CHAT_MESSAGE_INPUT
                  : CHAT_ID_TYPE.GROUP_CHAT_MESSAGE_INPUT
              }
              onPressEnter={() => onSendMessage(MESSAGE_CONTENT.TEXT)}
              placeholder="Post your comments"
              value={message}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSetMessage(e.target.value)
              }
              autoComplete="off"
            />
            <Giphy onSendMessage={onSendMessage} gifBtnClickable={false} />
            <EmojiContainer>
              <EmojiPicker
                setMessage={handleSetMessage}
                onSendMessage={onSendMessage}
              />
            </EmojiContainer>
          </StyledBorderBox>
          <PrimaryButton
            theme="candidate"
            onClick={() => onSendMessage(MESSAGE_CONTENT.TEXT)}
            height="42"
          >
            Comment
          </PrimaryButton>
          <StyledSwitch anonymity={anonymity}>
            <span>Private</span>
            <Switch checked={anonymity} onChange={() => handleAnonymity()} />
          </StyledSwitch>
        </StyledCommentBox>
      )}
    </>
  );
};

const StyledSwitch = styled.div<any>`
  display: flex;
  gap: 10px;
  height: 28px;
  position: absolute;
  top: 16px;
  right: 22px;

  .ant-switch {
    background: ${COLORS.WHITE};
  }
  
  .ant-switch-checked {
    background: ${COLORS.RICH_BLUE};
  }

  button {
    box-shadow: ${BOX_SHADOW[5]};
  }

  .ant-switch-handle::before {
    background:${(props) => props.anonymity === true ? COLORS.WHITE : COLORS.RICH_BLUE};
  }

  .ant-switch:focus:hover {
    box-shadow: ${BOX_SHADOW[5]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: 12px;
    right: 10px;
  }
`;

const InputContainer = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}

  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: ${COLORS.LOTION};
  ${PADDING(0, 10)};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 60px;
  }
  input {
    font-size: ${FONT[13]};
    background: none;
    border: none;
  }
`;

const StyledImg = styled.img<any>`
  filter: ${({ isActive }) =>
    isActive
      ? 'brightness(1)'
      : `grayscale(1) opacity(0.6)`};

  cursor: pointer;
`;

const StyledCommentBox = styled.div<{ isAnonymous: Boolean }>`
  ${DISPLAY_FLEX('row')};
  gap: 10px;
  margin-top: -5px;
  margin-bottom: 10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 5px;
  }

  input {
    width: 100%;
  }

  ${StyledSwitch} {
    span {
      color: ${({ isAnonymous }: any) => isAnonymous && 'white'};
    }
  }
`;

const StyledBorderBox = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  width: 100%;
  border: 1px solid ${COLORS.BLACK_TONE_3};
  border-radius: 5px;
  background-color: ${COLORS.WHITE};

  >input {
    border: none;
    height:40px;
    
    :focus{
      border: none;
      box-shadow: none;
    };
    ::placeholder{
      font-size: ${FONT[12]};
    };

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height:40px;
    };
  }

  >div{
    ${PADDING(0, 5, 0, 0)};
  }

  >div>img{
    height: 18px;
    width: 18px;
    filter:opacity(0.8);

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height:15px;
      width: 15px;
    }
  }
`;

const EmojiContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')};
`

const StyledInput = styled(Input)`
  width: 100%;
  border-radius: 6px;
  height: 45px;
`;
