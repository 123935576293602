import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import styled from 'styled-components';
import { FormGroup } from '../../../components/shared-components/Form/Form';
import {
  profileLinksPayload,
  userCompanyProfileInitialValues,
  userCompanyProfilePayload,
  userCompanyProfileResetFields,
} from '../../../utils/profile';
import { getRoleId } from '../../../utils/userInfo';
import { updateProfileData } from '../../../store/features/profile';
import ProfilesLinksList from '../../../components/ProfileLinks/ProfileLinksList';
import CompanyAddressModalItem from '../../../components/profile/CompanyAddressModalItem';
import ModalButtonContainer from '../../../components/profile/ModalButtonContainer';
import CompanyInformationModalItem from '../../../components/profile/CompanyInformationModalItem';
import Notifications from '../../../components/Notification/Notification';
import EditCurrencySetting from '../../../components/profile/EditCurrencySetting';
import { UploadLogoComponent } from '../../../components/Common/UploadComponent';
import { getCurrencyDataForDropdown } from '../../../utils/currencyConvertor';
import { getSize } from '../../../utils/size';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { Modal } from '../../../components/shared-components/Modal';
import { getThemeByRoleId } from '../../../utils/common';
import { useTagManager } from '../../../utils/customHooks';

const CompanyInformationModal = (props) => {
  const dispatch = useDispatch();
  const {
    visible,
    handleClose,
    companyLogo,
    profileData,
    companyData,
    setCompanyLogo,
    currencyData,
  } = props;
  const roleId = getRoleId();
  const updateUserActionInGTM = useTagManager();

  const currencyDataForDropdown = getCurrencyDataForDropdown(currencyData);

  const initialValues = userCompanyProfileInitialValues(
    profileData,
    companyData,
    currencyData,
  );
  const [form] = Form.useForm();

  const handleModalCancel = () => {
    form.resetFields();
    handleClose();
  };

  const handleResetFields = () => {
    userCompanyProfileResetFields(form);
  };

  const handleFinish = (data) => {
    const body = userCompanyProfilePayload(
      roleId,
      data,
      profileData,
      currencyData,
    );

    const profileLinks = profileLinksPayload(data.profileLinks);

    if (profileLinks.length === 0) {
      Notifications(
        'error',
        'Profile Links',
        'Please add atleast one company profile link',
      );
      return;
    }

    dispatch(updateProfileData(body));
    updateUserActionInGTM('profile_settings_updated');
    handleModalCancel();
  };

  const buttonProps = {
    width: getSize(160, 145, 135),
    height: getSize(42, 36, 32),
    handleResetFields,
  };

  return (
    <Modal
      className="RecruiterCompanyInformationModal"
      visible={visible}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      closable={false}
      title="Edit Company Profile"
      theme={getThemeByRoleId(roleId)}
    >
      <FormGroup
        form={form}
        name="RecruiterCompanyInformationModal"
        onFinish={handleFinish}
        expand
        initialValues={initialValues}
      >
        <CompanyInformationModalItem>
          <StyledUploadContainer>
            <UploadLogoComponent
              companyLogo={companyLogo}
              setCompanyLogo={setCompanyLogo}
              fileName={companyData.logo}
              alt="recruiter logo"
              avatarSize={getSize(120, 100, 80)}
              roleId={roleId}
              option
              removeOption
              btnText="Change Profile Pic"
            />
          </StyledUploadContainer>
        </CompanyInformationModalItem>

        <CompanyAddressModalItem form={form} />

        <ProfilesLinksList form={form} />

        <br />
        <EditCurrencySetting currenciesData={currencyDataForDropdown} />

        <ModalButtonContainer {...buttonProps} />
      </FormGroup>
    </Modal>
  );
};

export default CompanyInformationModal;

const StyledUploadContainer = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  width: 20%;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: unset;
    top: 20px;
  }
`;
