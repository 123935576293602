/* eslint-disable no-nested-ternary */
import React from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Switch, Tooltip } from 'antd';
import { userInfoRegardingChatroom } from '../../../../utils/chat';
import {
  handleJoinEvent,
  getLatestBooking,
  isEventOngoing,
  getUpComingEvent,
  getOnGoingEvent,
} from '../../../../utils/event';
import { ATTENDANCE_PRESENT } from '../../../../constants';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
  getUserClassWithRoleId,
} from '../../../../utils/userInfo';
import { userProfileStore } from '../../../../utils/profile';
import { PrimaryButton } from '../../../shared-components/Button';
import { useTagManager } from '../../../../utils/customHooks';
import { VideoPlayer } from '../../VideoPlayer';
import { COLORS } from '../../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { ArrowDown } from '../../../svgicons';
import { checkAndReGenerate } from '../../../../store/features/chat';

const JoinEventButton = ({
  bookedEventDescription = null,
  nonBookedEventDescription = null,
  setCandidatePresent = null,
  expand = null,
  setExpand = null,
  setPipSwitchOn = null,
  pipSwitchOn = null,
  event,
  roleId,
  callback,
  eventPage = null,
  discussionRoom = null,
  Tabs = null,
  EventDetails = null,
  UserClassNameContextProvider = null,
  isEventCard = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profileData } = userProfileStore();
  const updateUserActionInGTM = useTagManager();
  const userData = userInfoRegardingChatroom();

  const getCurrentUser = (candidates = []) => {
    let candidate;
    if (checkIfUserIsRecruiter(roleId)) {
      candidate = getLatestBooking(
        null,
        '',
        candidates.filter(
          (candidate) =>
            candidate.companyId === get(profileData, 'company.id', null),
        ),
      );
    } else {
      candidate = getLatestBooking(
        null,
        '',
        candidates.filter(
          (candidate) => get(candidate, 'userId', null) === profileData.userId,
        ),
      );
    }
    return candidate;
  };

  const getCandidateBookings = () => {
    if (
      !get(event, 'isEventBooked', false) &&
      checkIfUserIsCandidate(roleId) &&
      !isEventCard
    ) {
      return null;
    }
    const eventTicket = get(event, 'ticket', null);
    const bookingDefault = eventTicket ? [eventTicket] : null;
    return checkIfUserIsRecruiter(roleId)
      ? get(event, 'eventCompanys', bookingDefault)
      : get(event, 'eventCandidates', bookingDefault);
  };

  const candidateBookings = getCandidateBookings();

  if (
    !candidateBookings &&
    (isEventOngoing(event) || getUpComingEvent(event)) &&
    checkIfUserIsCandidate(roleId) &&
    nonBookedEventDescription
  ) {
    return <>{nonBookedEventDescription()}</>;
  }

  if (!candidateBookings && checkIfUserIsCandidate(roleId)) {
    return null;
  }

  const candidate = getCurrentUser(candidateBookings);

  if (
    candidateBookings &&
    getUpComingEvent(event) &&
    checkIfUserIsCandidate(roleId)
  ) {
    return <>{bookedEventDescription()}</>;
  }

  const handleJoinEventClick = () => {
    handleJoinEvent(event, roleId, userData, history, 2000, callback).then(
      () => {
        updateUserActionInGTM('event_joined');
        if (checkIfUserIsCandidate(roleId)) {
          dispatch(checkAndReGenerate());
        }
        setTimeout(() => window.location.reload(), 100);
      },
    );
  };

  const JoinEventAndRecommendedJobs = () => {
    if (
      candidate &&
      eventPage &&
      candidate.attendance === ATTENDANCE_PRESENT &&
      !getUpComingEvent(event)
    ) {
      return (
        <>
          {expand ? (
            <StyledPIPSwitch expand={expand}>
              <Tooltip title={`${pipSwitchOn ? `Disable` : 'Enable'} PIP mode`}>
                PIP <Switch checked={pipSwitchOn} onChange={setPipSwitchOn} />
              </Tooltip>
            </StyledPIPSwitch>
          ) : null}

          <VideoPlayer event={event} />
          {Tabs}

          <StyledExpand expand={expand}>
            <span onClick={() => setExpand(!expand)}>
              <h1>{expand ? 'Expand' : 'Collapse'}</h1>
              <ArrowDown />
            </span>
          </StyledExpand>
        </>
      );
    }

    if (candidate && eventPage && candidateBookings && getOnGoingEvent(event)) {
      return <>{bookedEventDescription()}</>;
    }

    if (candidate && eventPage && candidate.attendance !== ATTENDANCE_PRESENT) {
      return <>{nonBookedEventDescription()}</>;
    }

    if (candidate && candidate.attendance !== ATTENDANCE_PRESENT) {
      return (
        <PrimaryButton
          type="button"
          onClick={handleJoinEventClick}
          theme={getUserClassWithRoleId(roleId)}
          id="join_event_btn"
        >
          Join Event
        </PrimaryButton>
      );
    }
    return null;
  };

  return JoinEventAndRecommendedJobs();
};

const StyledExpand = styled.div`
  h1 {
    color: ${COLORS.RICH_BLUE};
    margin-bottom: 0;
  }

  ${DISPLAY_FLEX('row', null, 'center')};

  span {
    ${DISPLAY_FLEX('row', null, 'center')};
    gap: 5px;
  }
  gap: 10px;
  position: absolute;
  right: 0;
  top: ${({ expand }) => (expand ? '432px' : '5px')};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    top: ${({ expand }) => (expand ? '482px' : '5px')};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  svg {
    transform: ${({ expand }) => (expand ? 'rotate(180deg)' : 'rotate(0)')};
  }

  cursor: pointer;
`;

const StyledPIPSwitch = styled.div`
  color: ${COLORS.RICH_BLUE};
  margin-bottom: 0;
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 15px;
  position: absolute;
  font-weight: 500;
  right: 0;
  top: -33px;
  z-index: 500;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  cursor: pointer;
`;

export default JoinEventButton;
