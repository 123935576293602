import React from 'react';
import { Row, Col, Button, Form } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import styled from 'styled-components';
import { TextBox } from '../../components/shared-components/Form/Form';
import { validateURL } from '../../constants';
import { getUserColorWithRoleId } from '../../styles/utils';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const BroadcastLinks = ({ form, roleId }) => {
  const handleAddLink = async (add) => {
    const value = await form.getFieldValue('broadcastLinks');

    if (isArray(value)) {
      const link = await get(value, `[${value.length - 1}].url`, undefined);
      const title = await get(value, `[${value.length - 1}].title`, undefined);

      if (link && link.length > 0 && validateURL(link) && title) {
        add();
      } else if (value.length === 0) {
        add();
      }
    } else if (!value) {
      add();
    }
  };

  return (
    <StyledContainer>
      <Form.List name="broadcastLinks">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <Row key={idx.toString()}>
                <Col md={8} xs={24}>
                  <TextBox
                    field={field}
                    name={[field.name, 'title']}
                    placeholder="Enter the title"
                  />
                </Col>
                <StyledColWithOffset md={13} xs={24}>
                  <TextBox
                    field={field}
                    name={[field.name, 'url']}
                    placeholder="Enter the Embeded video link"
                    rule="url"
                  />
                </StyledColWithOffset>
                <StyledColWithOffset
                  xs={4}
                  md={2}
                  onClick={() => remove(field.name)}
                >
                  <StyledMinusCircleOutlined />
                </StyledColWithOffset>
              </Row>
            ))}
            <Row>
              <Col xs={24} lg={8}>
                <StyledAddLinkButton
                  type="dashed"
                  color={getUserColorWithRoleId(roleId)}
                  onClick={() => handleAddLink(add)}
                >
                  + Broadcast Link
                </StyledAddLinkButton>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </StyledContainer>
  );
};

export default BroadcastLinks;

const StyledContainer = styled.div`
  ${MARGIN(20, 0, 0, 0)}
`;

const StyledAddLinkButton = styled(Button)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  ${MARGIN(20, 0, 0, 0)}
  ${PADDING(20)}

  width:100%;
  border-color: ${(props => props.color)};

  span {
    color: ${(props => props.color)};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 30)}
  }
`;

const StyledMinusCircleOutlined = styled(MinusCircleOutlined)`
  color: ${COLORS.CUSTOM_RED};
  ${MARGIN(6, 0, 0, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 15, 0)}
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(10, 0, 0, 0)}
  }
`;

export const StyledColWithOffset = styled(Col)`
  ${MARGIN(0, 0, 0, 15)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(-10, 0, 0, 0)}
  }
`;
