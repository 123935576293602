import React from 'react';
import { OngoingAnalytics } from '../Common';

export default function CandidateEventAnalytics({ analytics }) {
  if (!analytics) {
    analytics = {
      noOfCompanies: 0,
      noOfJobs: 0,
      averageAnnualCompensation: 0,
    };
  }
  const itemList = [
    {
      name: 'Jobs',
      value: analytics.noOfJobs,
    },
    {
      name: 'Companies',
      value: analytics.noOfCompanies,
    },
    /*
    // Hiding until we figure out a way to normalize the average pay 
    {
      name: 'Average Pay',
      value: analytics.averageAnnualCompensation,
    },
    */
  ];

  return (
    <OngoingAnalytics
      itemList={itemList}
      className="candidate-event-analytics"
    />
  );
}
