import React from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import Address from '../profile/Address';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import BasicInformation from '../profile/BasicInformation';
import DisabledTag from '../../containers/MasterAdmin/commons/DisabledTag';
import { STATUS_ACTIVE } from '../../constants';
import { getSize } from '../../utils/size';
import { checkIfUserIsMasterAdmin, getRoleId } from '../../utils/userInfo';
import { PageContainer } from '../shared-components/PageContainer';
import { TitleContainer } from './common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const BasicCandidateInformation = ({
  profileData,
  profilePic,
  setProfilePic,
}) => {
  const roleId = getRoleId();
  return (
    <PageContainer>
      <DisabledTag
        status={get(profileData, 'status', STATUS_ACTIVE)}
        isCandidate
      />

      <StyledRow>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 18, order: 1 }}>
          <TitleContainer roleId={roleId}>Basic information</TitleContainer>

          <BasicInformation profileData={profileData} />

          <TitleContainer marginTop={25} roleId={roleId}>
            Address
          </TitleContainer>

          <Address data={profileData} candidate />
        </Col>
        <StyledUploadContainer>
          <UploadProfilePicComponent
            profilePic={profilePic}
            option={false}
            setProfilePic={setProfilePic}
            fileName={profileData.profilePic}
            removeOption
            avatarSize={getSize(150, 140, 110)}
            userType={checkIfUserIsMasterAdmin(roleId) ? 'admin' : 'candidate'}
            isCandidateProfile
          />
        </StyledUploadContainer>
      </StyledRow>
    </PageContainer>
  );
};

export default BasicCandidateInformation;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 60px;
  }
`;

const StyledUploadContainer = styled.div`
  position: absolute;
  top: 26%;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    right: 9%;
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    right: 8.6%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: 7%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
