import { Col, Row, Popconfirm } from 'antd';
import last from 'lodash/last';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CONFIRM_MESSAGES } from '../../constants';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { ShowMobile } from '../shared-components/common';
import { HiddenMobile } from '../shared-components/DocumentViewerModal';
import { StyledMediumHeading } from '../shared-components/Texts';
import {
  ActiveText,
  DangerText,
  ProfileInformationHeader,
  ProfileInformationValue,
} from './common';

const PendingInvitation = ({
  invitations = [],
  handleAcceptInvitation,
  roleId,
  handleRejectInvitation,
}) => {
  useEffect(() => {
    const hash = get(window, 'location.hash', '');
    const id = last(hash.split('#'));
    if (id && id === 'pendingInvitations') {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }, 600);
    }
  }, []);

  return (
    <Col xs={24}>
      <StyledPageHeading
        color={getUserColorWithRoleId(roleId)}
        id="pendingInvitations"
      >
        Pending Invitations
      </StyledPageHeading>
      <HiddenMobile>
        <Row gutter={[16, 24]}>
          <Col xs={24} lg={6}>
            <ProfileInformationHeader>Company Name</ProfileInformationHeader>
          </Col>
          <Col xs={24} lg={6}>
            <ProfileInformationHeader>Phone Number</ProfileInformationHeader>
          </Col>
          <Col xs={24} lg={6}>
            <ProfileInformationHeader>Location</ProfileInformationHeader>
          </Col>
          <Col xs={24} lg={4} />
        </Row>
      </HiddenMobile>
      {invitations.map((invitation, index) => (
        <Row gutter={[16, 10]} key={index.toString()}>
          <Col xs={24} lg={6}>
            <ShowMobile>
              <ProfileInformationHeader>Company Name</ProfileInformationHeader>
            </ShowMobile>
            <ProfileInformationValue>
              {get(invitation, 'company.name', '-')}
            </ProfileInformationValue>
          </Col>
          <Col xs={24} lg={6}>
            <ShowMobile>
              <ProfileInformationHeader>Phone Number</ProfileInformationHeader>
            </ShowMobile>
            <ProfileInformationValue>
              {get(invitation, 'company.phone', '-')}
            </ProfileInformationValue>
          </Col>
          <Col xs={24} lg={6}>
            <ShowMobile>
              <ProfileInformationHeader>Location</ProfileInformationHeader>
            </ShowMobile>
            <ProfileInformationValue>
              {get(invitation, 'company.country', '-')}
            </ProfileInformationValue>
          </Col>
          <Col xs={24} lg={2}>
            <ActiveText
              onClick={() =>
                handleAcceptInvitation(index, invitation.company.name)
              }
            >
              Accept
            </ActiveText>
          </Col>
          <Col xs={24} lg={2}>
            <Popconfirm
              title={CONFIRM_MESSAGES.removeInvitation}
              onConfirm={() =>
                handleRejectInvitation(index, invitation.company.name)
              }
              okText="Yes"
              cancelText="No"
            >
              <DangerText>Reject</DangerText>
            </Popconfirm>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

export default PendingInvitation;

const StyledPageHeading = styled(StyledMediumHeading)`
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${MARGIN(15, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(25, 0)}
  }
`;
