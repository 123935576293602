/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { Row, Col, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ViewAllRecommendedEvents } from '../Common';
import { RecommendedEventsStore } from '../../../utils/event';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsCandidate,
  getRoleId,
  checkIfUserIsRecruiter,
} from '../../../utils/userInfo';
import { getRecommendedEventsData } from '../../../store/features/recommendedEvents';
import EventCard from '../../shared-components/EventCard';
import { StyledMainHeader } from '../../shared-components/Event/Common';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { COLORS } from '../../../styles/constants/colors';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { isDesktop } from '../../../helpers/display';
import { getPublicEventsStatistics } from '../../../service/analytics';
// import { useScrollToTop } from '../../Common/CustomHooks';

// Commenting out only for Recorem Lite
/*
import { DropdownBox } from '../../Form/Form';
import { CaretDownOutlined } from '@ant-design/icons';
*/
const RecommendedEvents = ({
  dashboard,
  locateInDashboard,
  showViewMore = true,
  isCandidate,
}) => {
  const dispatch = useDispatch();
  const { isLoading, recommendedEvents, error } = RecommendedEventsStore();
  const roleId = getRoleId();
  const [analytics, setAnalytics] = React.useState(null);
  /*
  const [loading, setLoading] = React.useState(false);
  const [seconds, setSeconds] = React.useState(3);

  React.useEffect(() => {
    if (seconds > 0 && loading) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [seconds, loading]);

  useScrollToTop(loading);
*/
  /* 
    dispatch comes from a custom hook so it doesn't have an stable signature therefore will change on each render (reference equality). 
    Add an aditional layer of dependencies by wrapping the handler inside an useCallback hook
  */
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(
      getRecommendedEventsData(
        roleId,
        1,
        showViewMore ? 5 : 100,
        isCandidate && dashboard,
      ),
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (recommendedEvents.length) {
        const ids = recommendedEvents.map((event) => get(event, 'id', null));
        const response = await getPublicEventsStatistics(ids);
        setAnalytics(response && response);
      }
    }

    fetchData();
  }, [recommendedEvents]);

  const getTopFourRecommendedEvents = () => {
    const isLaptop =
      window.innerWidth >= parseInt(BREAKPOINTS.LAPTOP_SPECIAL_CASE_1) &&
      window.innerWidth < parseInt(BREAKPOINTS.DESKTOP_SPECIAL_CASE_1);
    const isExtraLarge =
      window.innerWidth >= parseInt(BREAKPOINTS.DESKTOP_SPECIAL_CASE_1);
    const initial = checkIfUserIsCandidate(roleId) && isExtraLarge ? 4 : 3;

    if (recommendedEvents.length > 3) {
      const count =
        !locateInDashboard && isDesktop()
          ? initial + 1
          : isLaptop
            ? initial
            : initial + 1;
      return recommendedEvents.slice(0, count);
    }
    return recommendedEvents;
  };

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <StyledMainContainer
      candidateDashboard={locateInDashboard && checkIfUserIsCandidate(roleId)}
      recruiterDashboard={locateInDashboard && checkIfUserIsRecruiter(roleId)}
      organizerDashboard={locateInDashboard && checkIfUserIsOrganizer(roleId)}
      isCandidate={isCandidate}
    >
      <Row>
        <Col md={12} xs={18}>
          <StyledMainHeader
            color={getUserColorWithRoleId(roleId)}
            id="recommended_event_title"
          >
            {checkIfUserIsOrganizer(roleId)
              ? 'Explore Similar Events'
              : checkIfUserIsCandidate(roleId)
                ? 'Select an event to apply to jobs!'
                : 'Recommended Events'}
          </StyledMainHeader>
        </Col>
        <StyledRightHeaderCol md={12} xs={6}>
          {showViewMore && !checkIfUserIsOrganizer(roleId) ? (
            <ViewAllRecommendedEvents />
          ) : (
            <StyledFilterWrapper>
              {/* TODO: Change this DropdownBox options at the time of filter integration */}
              {
                // Commenting out only for Recorem Lite
                /*
              <DropdownBox
                value="new"
                options={[
                  { id: 1, name: 'new' },
                  { id: 2, name: 'upcomming' },
                ]}
              />
              <CaretDownOutlined />
              */
              }
            </StyledFilterWrapper>
          )}
        </StyledRightHeaderCol>
      </Row>
      {
        /*loading ? (
        <StyledSpin>
          <Spin
            spinning={loading}
            tip={
              <StyledLoadingText>
                Event registered! Fetching jobs for you in
                <StyledSecond>{seconds}</StyledSecond>
              </StyledLoadingText>
            }
            size="large"
          />
        </StyledSpin>
      ) :*/
        isArray(recommendedEvents) && recommendedEvents.length ? (
          <StyledCardsContainer dashboard={dashboard} isCandidate={isCandidate}>
            {(dashboard || showViewMore
              ? getTopFourRecommendedEvents()
              : recommendedEvents
            ).map((event, i) => {
              return (
                <EventCard
                  event={event}
                  roleId={roleId}
                  key={i}
                  category="recommended"
                  analytics={get(analytics, `[${get(event, 'id', 0)}]`, {})}
                  dashboard={dashboard}
                />
              );
            })}
          </StyledCardsContainer>
        ) : (
          <StyledCardsPlacholderContainer>
            {!checkIfUserIsOrganizer(roleId)
              ? 'Events matching your skills and domain are coming soon. Expect a notification once registrations open. Stay tuned!'
              : 'Events matching your previous events. Stay tuned!'}
          </StyledCardsPlacholderContainer>
        )
      }
    </StyledMainContainer>
  );
};

export default RecommendedEvents;

/*
const StyledLoadingText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 380px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const StyledSpin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  svg,
  div {
    color: ${COLORS.RICH_BLUE};
  }

  .ant-spin-text {
    font-size: 16px;
  }

  .ant-spin-dot-item {
    background: ${COLORS.RICH_BLUE};
  }
`;

const StyledSecond = styled.span`
  font-weight: 600;
  font-size: 28px;

  @media (max-width: 380px) {
    margin-top: -10px;
  }
`;
*/
const StyledMainContainer = styled(Col)`
  ${MARGIN(0, 0, 30, 0)};
  margin-top: ${(props) =>
    props.candidateDashboard
      ? '0px'
      : props.recruiterDashboard
        ? '5px'
        : props.organizerDashboard
          ? '-5px'
          : ' 30px'};

  ${({ isCandidate }) => (isCandidate ? 'margin: 0;' : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(0, 0, 40, 0)};
    margin-top: ${(props) =>
    props.candidateDashboard
      ? '-0px'
      : props.recruiterDashboard
        ? '0px'
        : props.organizerDashboard
          ? '-10px'
          : '30px'};

    ${({ isCandidate }) => (isCandidate ? 'margin: 0;' : '')}
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-top: 15px;

    span {
      div {
        margin-top: 3px;
      }
    }
  }
`;

export const StyledCardsContainer = styled(Row)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${PADDING(20, 0)}

  @media (min-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_5}) {
    padding-bottom: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_1}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    grid-template-columns: ${({ dashboard, isCandidate }) =>
    `repeat(${dashboard && isCandidate ? 4 : dashboard ? 3 : 4
    }, minmax(0, 1fr))`};
    column-gap: 30px;
    row-gap: 15px !important;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_SPECIAL_CASE_1}) {
    grid-template-columns: ${({ dashboard }) =>
    `repeat(${dashboard ? 4 : 5}, minmax(0, 1fr))`};
  }
`;

const StyledCardsPlacholderContainer = styled.div`
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${PADDING(20, 0)}
`;

const StyledRightHeaderCol = styled(Col)`
  ${DISPLAY_FLEX('row-reverse')};
`;

const StyledFilterWrapper = styled.div`
  margin: 0 0 0 auto;
  max-width: 168px;
  width: 100%;
  border-radius: 3px;
  position: relative;
`;
