export const COLORS = {
  primaryColor: '#47bfa8',
  candidateColor: '#6963aa',
  recruiterColor: '#ffbf40',
  COSMOS_RED: '#ffd3d3',
  WHISPER: '#EEECF5',
  LAVENDER: '#E1DFF5',
  RAISIN_BLACK_2: '#242424',
  SILVER_SAND: '#c2c2c2',
  MINT_GREEN: '#7effa2',
  SOLTITUDE: '#DDDCEA',
  TOOLBOX: '#A3A0CA',
  MOODY_BLUE: '#8680C5',
  RICH_BLUE: '#6963AA',
  LIBERTY: '#5E58A0',
  KIMBERLY: '#6D597A',
  FEDORA: '#624F6A',
  PEARL_AQUA: '#84caba',
  SINBAD: '#A3D6CD',
  SOFT_BLUE: '#695edd',
  MEDIUM_AQUAMARINE: '#6DC9B7',
  PUERTO_RICO: '#47BFA8',
  VERDIGRIS: '#49C0A9',
  COSMIC_LATTE: '#FFF7E6',
  NAPLES_YELLOW: '#ffc65b',
  SALOMIE: '#FED27C',
  SUPER_NOVA: '#FFBF40',
  TANGERINE_YELLOW: '#FC0',
  TAPESTRY: '#B86B7C',
  TORCH_RED: '#F5222D',
  BLUSH: '#AC5A6A',
  RED: '#F00',
  TART_ORANGE: '#FF4646',
  PASTEL_ORANGE: '#FFB950',
  DODGER_BLUE: '#1890FF',
  LIGHT_SLATE_BLUE: '#717FFF',
  SLATE_BLUE: '#675CC8',
  WHITE: '#FFFFFF',
  LOTION: '#FAFAFA',
  CHINESE_BLACK: '#141414',
  LIGHT_BLACK: '#14141408',
  LIGHT_ORANGE: '#fcb62a',
  RAISIN_BLACK: '#262626',
  EERIE_BLACK: '#1f1f1f',
  BLACK: '#000000',
  LIGHT_GRAY: '#D4D4D4',
  LIGHT_GRAY_2: '#D7D7D7',
  LIGHT_GRAY_3: '#b5b5b5',
  WHITE_SMOKE: '#F0F0F0',
  CULTURED: '#F5F5F5',
  LIGHT_SILVER: '#D9D9D9',
  ANTI_FLASH_WHITE: '#F3F3F3',
  BRIGHT_GRAY: '#EFEFEF',
  BRIGHT_GRAY_3: '#E5E5E5',
  METALLIC_SILVER: '#A2ACAB',
  PHILLIPPINE_GRAY: '#8C8C8C',
  ARGENT: '#BFBFBF',
  ARGENT_2: '#B7B7B7',
  ARSENIC: '#434343',
  DAVYS_GREY: '#595959',
  DARK_SILVER: '#707070',
  GRAY: '#818181',
  QUICK_SILVER: '#9f9f9f',
  GRAY2: '#808080',
  GRAY3: '#353535',
  ORANGE_RED: '#FF5050',
  CHINESE_SILVER: '#CCCCCC',
  PLATINUM: '#E4E4E4',
  DUTCH_WHITE: '#f3d2c0',
  OLD_LAVENDER: '#6f5b7b',
  DARK_GRAY: '#7d7d7d',
  LIGHT_BLUE: '#66b5ff',
  CUSTOM_RED: '#ff5050',
  ALICE_BLUE: '#e7f4ff',
  LAVENDER_WEB: '#e0edfd',
  DARK_BLUE: '#0a66c2',
  ORANGE_YELLOW: '#ffcc66',
  LIGHT_ORGANIZER_COLOR: '#ecf8f6',
  LIGHT_RECRUITER_COLOR: '#fff8e9',
  LIGHT_CANDIDATE_COLOR: '#f0eff6',
  LIGHT_ADMIN_COLOR: '#e7f4ff',
  CIRCLE_1_ORGANIZER_COLOR: '#6ac8b6',
  CIRCLE_2_ORGANIZER_COLOR: '#5ac4af',
  LIGHT_ICON_BG_ORGANIZER: '#a3d6cd',
  LIGHT_ICON_BG_RECRUITER: '#fed27c',
  LIGHT_ICON_BG_CANDIDATE: '#8680c5',
  LIGHT_ICON_BG_ADMIN: '#1890ff',
  CIRCLE_1_RECRUITER_COLOR: '#ffce6e',
  CIRCLE_2_RECRUITER_COLOR: '#ffc95d',
  ORGANIZER_MODAL_HEADER: '#e9f7f5',
  RECRUITER_MODAL_HEADER: '#fff7e6',
  MASTER_ADMIN_MODAL_HEADER_COLOR: '#e3f2ff',
  SUB_HEADER: `#8c8c8c`,
  GRAY_LIGHT_2: `#a2acab`,
  PLACEHOLDER: `#bfbfbf`,
  VLIGHT_GRAY: '#f0f0f0',
  DARK_SALMON: '#e7a280',
  LIVE_COLOR: '#f5222d',
  LIGHT_GREEN: `#d6eeea`,
  BRILLIANT_AZURE: '#46a6ff',
  ADMIN_APP_CIRCLE_1: '#3fa3ff',
  ADMIN_APP_CIRCLE_2: '#2d9aff',
  ADMIN_CAN_CIRCLE_1: '#6f69b0',
  ADMIN_CAN_CIRCLE_2: '#6c66ad',
  ADMIN_REC_CIRCLE_1: '#ffcf6e',
  ADMIN_REC_CIRCLE_2: '#ffc758',
  BACKGROUND_GRAY: '#d4d4d41f',
  DARK_TRANSPARENT: '#000000cc',
  THUNDERBIRD_RED: '#c61118',
  LIGHT_BLUE_2: '#3f729b',
  LIGHT_BLUE_3: '#4d85e6',
  LIGHT_BLUE_4: '#00A3FF',
  LOCHMARA_BLUE: '#0082ca',
  PICTON_BLUE: '#55acee',
  DARK_BLUE_2: '#3b5998',
  DARK_RED: '#5a5a5a',
  MARKETING_BLUE: '#1890FC',
  MERCURY: '#e6e6e6',
  DIVIDER_BLUE: '#0069cc',
  HOVER_BLACK: '#1A1A1A',
  PATTENS_BLUE: '#c5e3ff',
  RADDICAL_RED: '#ff5051',
  SCIENCE_BLUE: '#005cd5',
  TURQUOISE_BLUE: '#1e93ff',
  TRUE_GREEN: '#00a100',
  ANONYMOUS: '#8e4cdf',
  LINK_BLUE: '#40a9ff',
  RED_2: '#FF0000',
  TRUE_GREEN_2: '#3a8d76',
  DEEP_SAFFRON: '#FF8F3A',
  CARIBBEAN_GREEN: '#10C18B',
  RED_3: '#f84242',
  CORAL_RED: '#ff3c3c',
  CARIBBEAN_GREEN_2: '#0EC895',
  RED_4: '#ff2727',
  GRAY_2: '#ababab',
  GREY_3: '#363636',
  GRAY_4: '#323232',
  GRAY_5: '#393939',
  TRUE_GREEN_3: '#0d9e68',
  BLUE_VIOLET: '#8A38E9',
  BLUE_VIOLET_2: '#AC42FF',
  ELECTRIC_VIOLET: '#8f00ff',
  LAVENDER_INDIGO: '#b250ff',
  HELIOTROPE: '#bd69ff',
  GOLDEN_POPPY: '#FFC400',
  BRIGHT_GRAY_2: '#e9f1f4',
  GAINSBORO: '#DBDBDB',
  AMERICAN_SILVER: '#cfcfcf',
  DARK_SILVER_2: '#6d6d6d',
  SLATE_BLUE_2: '#695EDD',
  SPANISH_GRAY: '#9B9B9B',
  SPANISH_GRAY_2: '#aaaaaa',
  GHOST_WHITE_2: '#f9fdff',
  CHINESE_WHITE: '#E1E0DF',
  WEB3PINK: '#dc4181',
  WEB3BLUE: '#0c071f',
  VERY_LIGHT_BLUE: '#6563FF',
  LIGHT_GRAY_DIVIDER: '#DDDDDD',
  LIGHT_GRAY_CLOSE_ICON: '#777777',
  PURPLE: '#6E00BC',
  ORANGE: '#E88B00',
  DARK_ANONYMOUS: '#272727',
  GRANITE_GRAY: '#666666',
  PURPLE_SHADE_1: '#C841FF',
  SKY_BLUE_LIGHT: '#11C5D1',
  CURIOS_BORDER_1: '#ffbf61',
  CURIOS_BORDER_2: '#ff9800',
  OPTIMISTIC_BORDER_1: '#8cff8a',
  OPTIMISTIC_BORDER_2: '#2caf2a',
  BELIEVER_BORDER_1: '#00c2ff',
  BELIEVER_BORDER_2: '#04c0fc',
  ONGOING_EVENT_GRADIENT_1: '#cd91f3',
  ONGOING_EVENT_GRADIENT_2: '#757bd3',
  LIVE_EVENT_GRADIENT_1: '#E90031',
  LIVE_EVENT_GRADIENT_2: '#f0748c',
  LIVE_EVENT_GRADIENT_3: '#F74444',
  PAST_EVENT_GRADIENT_1: '#4E728A',
  PAST_EVENT_GRADIENT_2: '#92c0e0',
  PAST_EVENT_GRADIENT_3: '#8dbbdf',
  UPCOMING_EVENT_GRADIENT_1: '#764CF0',
  UPCOMING_EVENT_GRADIENT_2: '#7491F5',
  UPCOMING_EVENT_GRADIENT_3: '#9CB3FF',
  GREEN: '#00FF00',
  BLACK_TONE_2: '#646464',
  BLACK_TONE_3: '#b5b5b5',
  GREEN_TONE_2: '#14AE5C',
  GREEN_TONE_3: '#14CD53',
  SHAMROCK_GREEN: '#009E60',
  SUNSHINE_SUPRISE: '#fcb62a',
  BLUE_BERRY: '#4f86f7',
  DARK_PINK: '#BD5CC8',
  CHARLESTON_GREEN_2: '#2D2D2D',
  BLUE_MARGUERITE: '#675CC8',
  AMETHYST: '#BD5CC8',
  SAIL: '#b6d7fb',
  GRANITE_GRAY_2: '#626262',
  STEEL_GRAY: '#71797E'
};

export const FILTERS = {
  WHITE:
    'invert(100%) sepia(100%) saturate(0%) hue-rotate(347deg) brightness(105%) contrast(101%)',
  BLACK:
    'invert(0%) sepia(0%) saturate(7486%) hue-rotate(324deg) brightness(104%) contrast(103%)',
  LIGHT_GRAY:
    'invert(81%) sepia(98%) saturate(0%) hue-rotate(175deg) brightness(93%) contrast(84%)',
  ARGENT:
    'invert(80%) sepia(10%) saturate(8%) hue-rotate(334deg) brightness(96%) contrast(86%)',
  RICH_BLUE:
    'invert(40%) sepia(21%) saturate(1069%) hue-rotate(206deg) brightness(97%) contrast(92%)',
  ORGANIZER:
    'invert(69%) sepia(9%) saturate(2268%) hue-rotate(119deg) brightness(94%) contrast(89%)',
  RECRUITER: `invert(63%) sepia(96%) saturate(329%) hue-rotate(352deg)
  brightness(104%) contrast(104%)`,
  CANDIDATE: `invert(40%) sepia(13%) saturate(1753%) hue-rotate(206deg)
  brightness(98%) contrast(89%)`,
  ADMIN: `invert(40%) sepia(88%) saturate(2354%) hue-rotate(194deg) brightness(104%) contrast(102%)`,
  DARK_GRAY:
    'invert(79%) sepia(1%) saturate(2258%) hue-rotate(24deg) brightness(99%) contrast(87%)',
};
