import React from 'react';

const DiscordIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z"
        fill="white"
      />
      <path
        d="M36.0367 15.7797C33.8349 14.0182 31.1927 13.1375 28.4037 12.9907L27.9633 13.4311C30.4587 14.0182 32.6606 15.1925 34.7156 16.8072C32.2202 15.4861 29.4312 14.6054 26.4954 14.3118C25.6147 14.165 24.8807 14.165 24 14.165C23.1193 14.165 22.3853 14.165 21.5046 14.3118C18.5688 14.6054 15.7798 15.4861 13.2844 16.8072C15.3394 15.1925 17.5413 14.0182 20.0367 13.4311L19.5963 12.9907C16.8073 13.1375 14.1651 14.0182 11.9633 15.7797C9.46789 20.477 8.14679 25.7614 8 31.1925C10.2018 33.5412 13.2844 35.0091 16.5138 35.0091C16.5138 35.0091 17.5413 33.8348 18.2752 32.8072C16.367 32.3669 14.6055 31.3393 13.4312 29.7247C14.4587 30.3118 15.4862 30.899 16.5138 31.3393C17.8349 31.9265 19.156 32.2201 20.4771 32.5137C21.6514 32.6604 22.8257 32.8072 24 32.8072C25.1743 32.8072 26.3486 32.6604 27.5229 32.5137C28.844 32.2201 30.1651 31.9265 31.4862 31.3393C32.5138 30.899 33.5413 30.3118 34.5688 29.7247C33.3945 31.3393 31.633 32.3669 29.7248 32.8072C30.4587 33.8348 31.4862 35.0091 31.4862 35.0091C34.7156 35.0091 37.7982 33.5412 40 31.1925C39.8532 25.7614 38.5321 20.477 36.0367 15.7797ZM19.156 28.5503C17.6881 28.5503 16.367 27.2292 16.367 25.6146C16.367 23.9999 17.6881 22.6788 19.156 22.6788C20.6239 22.6788 21.945 23.9999 21.945 25.6146C21.945 27.2292 20.6239 28.5503 19.156 28.5503ZM28.844 28.5503C27.3761 28.5503 26.055 27.2292 26.055 25.6146C26.055 23.9999 27.3761 22.6788 28.844 22.6788C30.3119 22.6788 31.633 23.9999 31.633 25.6146C31.633 27.2292 30.3119 28.5503 28.844 28.5503Z"
        fill="black"
      />
    </svg>
  );
};

export default DiscordIcon;
