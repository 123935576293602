import {
  APPLICATION_STATUS_SELECTED,
  APPLICATION_STATUS_REJECTED,
  APPLICATION_STATUS_HOLD,
} from './index';

export const candidateStatusFilter = [
  {
    id: '1',
    name: 'Shortlist',
    value: APPLICATION_STATUS_SELECTED,
  },
  {
    id: '2',
    name: 'Skip',
    value: APPLICATION_STATUS_REJECTED,
  },
  {
    id: '4',
    name: 'Hold',
    value: APPLICATION_STATUS_HOLD,
  },
];

export const rejectReasons = [
  {
    id: 1,
    name: 'Experience',
  },
  {
    id: 2,
    name: 'Qualification',
  },
  {
    id: 3,
    name: 'Both',
  },
  {
    id: 4,
    name: 'Other',
  },
];
