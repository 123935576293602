import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TestContext } from '../../context/testMode';
import ChatBreadCrumbs from '../Common/BreadCrumbs/ChatBreadCrumbs';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../utils/userInfo';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../constants/routes';
import GroupBody from './GroupChatBody/GroupBody';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { BOX_SHADOW } from '../../styles/constants/box-shadow';
import { FONT } from '../../styles/constants/typography';
import { useTagManager } from '../../utils/customHooks';

const GroupChat = ({ isForTest }) => {
  const [chatLink, setChatLink] = useState('');

  const roleId = getRoleId();

  const updateUserActionInGTM = useTagManager();

  useEffect(() => {
    updateUserActionInGTM('event_group_chat_opened');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkIfUserIsOrganizer(roleId)) {
      setChatLink(OrganizerRoutes.chat);
    } else if (checkIfUserIsRecruiter(roleId)) {
      setChatLink(RecruiterRoutes.chat);
    } else if (checkIfUserIsCandidate(roleId)) {
      setChatLink(CandidateRoutes.chat);
    }
  }, [roleId]);

  return (
    <div>
      <StyledContainer>
        <StyledCircleBox roleId={roleId} />
        <StyledChatMainHeading>
          <StyledChatMainHeadingBreadcrumb>
            <ChatBreadCrumbs />
          </StyledChatMainHeadingBreadcrumb>
          <StyledChatMainHeadingBack>
            <Link to={chatLink}>
              <ArrowLeftOutlined />
            </Link>
            <StyledHeading roleId={roleId}>Chat Room </StyledHeading>
          </StyledChatMainHeadingBack>
        </StyledChatMainHeading>
      </StyledContainer>
      <StyledGroupChatContainer>
        <TestContext.Provider value={!!isForTest}>
          <GroupBody />
        </TestContext.Provider>
      </StyledGroupChatContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  position: relative;
`;

const StyledCircleBox = styled.div`
  background-color: ${(props) => getUserColorWithRoleId(props.roleId)};
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.15;
  position: absolute;
  left: -124px;
  top: -100px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 310px;
    height: 310px;
  }
`;

const StyledChatMainHeading = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}

  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, -10, 0)}
  }
`;

const StyledChatMainHeadingBreadcrumb = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(10, 0)}
  }
`;

const StyledChatMainHeadingBack = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('row')}
    margin-top: 20px;

    svg {
      margin-right: 8px;
    }

    a {
      margin-top: 1.5px;
    }

    h5 {
      font-size: ${FONT[14]};
    }
  }
`;

const StyledHeading = styled.h5`
  color: ${(props) => getUserColorWithRoleId(props.roleId)};
`;

const StyledGroupChatContainer = styled.div`
  background-color: ${COLORS.WHITE};
  box-shadow: ${BOX_SHADOW[4]};
  z-index: 1;
  ${PADDING(5, 20, 20, 20)};
  position: relative;
  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 0, 10, 0)}
    margin-bottom: 20px;
  }
`;

export default React.memo(GroupChat);
