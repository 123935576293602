import React from 'react';
import { FormGroup, TextBox } from '../shared-components/Form/Form';
import { getRoleId } from '../../utils/userInfo';
import { Modal } from '../shared-components/Modal';
import { getThemeByRoleId } from '../../utils/common';
import { RightAlignContainer } from '../shared-components/RightAlignContainer';
import { PrimaryButton } from '../shared-components/Button';

const ContributeSkillModal = ({
  isContributeModalOpen,
  closeOpenContributeModal,
  form,
  handleRequestNewSkill,
}) => {
  const roleId = getRoleId();

  return (
    <Modal
      title="Contribute skill"
      visible={isContributeModalOpen}
      onCancel={closeOpenContributeModal}
      theme={getThemeByRoleId(roleId)}
      footer={null}
    >
      <FormGroup
        form={form}
        name="addSkills"
        onFinish={handleRequestNewSkill}
        expand
      >
        <TextBox
          id="skill-contribution-category"
          rule="text"
          name="category"
          placeholder="Category"
          required
          maxLength={50}
        />
        <TextBox
          id="skill-contribution-description"
          rule="text"
          name="description"
          placeholder="Description"
          required
          maxLength={100}
        />
        <RightAlignContainer>
          <PrimaryButton theme={getThemeByRoleId(roleId)} type="submit">
            Submit
          </PrimaryButton>
        </RightAlignContainer>
      </FormGroup>
    </Modal>
  );
};

export default ContributeSkillModal;
