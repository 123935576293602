import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import PricingCard from './PricingCard';
import { DISPLAY_FLEX, PADDING } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import Loading from '../../components/Common/Loading';
import { useGetCryptoPlansQuery } from '../../store/features/rtkApi/recruiterApi';

/** Implemented with RTK-Query for caching which is useful for payment skipped recruiters */
const PricingCardList = () => {
  const { data: cryptoIncentivePlans, isLoading } = useGetCryptoPlansQuery();

  return (
    <StyledWrapper>
      {isLoading && <Loading color={COLORS.GRAY} />}
      {cryptoIncentivePlans &&
        cryptoIncentivePlans?.map((card) => (
          <PricingCard key={card.id} card={card} />
        ))}
    </StyledWrapper>
  );
};

export default React.memo(PricingCardList);

const StyledWrapper = styled.div`
  ${DISPLAY_FLEX('row', 'space-evenly')};
  gap: 20px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scrollbar-width: thin;
  scrollbar-color: ${COLORS.WHITE} rgba(255, 255, 255, 0.2);
  ${PADDING(25, 10, 25, 10)}

  &::-webkit-scrollbar {
    height: 0.5vh;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.GRANITE_GRAY};
    border-radius: 48px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${COLORS.LIGHT_GRAY};
    border-radius: 48px;
    margin-inline: 8em;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_MAX}) and (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3}) {
    justify-content: start;
    width: 100%;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_4}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 100%;
    gap: 20px;
    justify-content: center;
    ${PADDING(35, 10, 40, 10)}
  }
  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_4}) {
    width: 100%;
    justify-content: start;
    gap: 20px;
    ${PADDING(35, 10, 40, 10)}
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${PADDING(45, 5, 45, 30)}
  }

  #Curious {
    background: linear-gradient(${COLORS.LOTION}, ${COLORS.LOTION}) padding-box,
      linear-gradient(
          to bottom right,
          ${COLORS.CURIOS_BORDER_1} 40%,
          ${COLORS.CURIOS_BORDER_2}
        )
        border-box;

    &:hover {
      box-shadow: 0px 0px 10px ${COLORS.CURIOS_BORDER_1};
    }
    .subColor {
      color: ${COLORS.CURIOS_BORDER_1};
    }
    .subinfo {
      color: ${COLORS.CURIOS_BORDER_2};
    }
  }

  #Optimistic {
    background: linear-gradient(${COLORS.LOTION}, ${COLORS.LOTION}) padding-box,
      linear-gradient(
          to bottom right,
          ${COLORS.OPTIMISTIC_BORDER_1} 40%,
          ${COLORS.OPTIMISTIC_BORDER_2}
        )
        border-box;
    &:hover {
      box-shadow: 0px 0px 10px ${COLORS.OPTIMISTIC_BORDER_1};
    }
    .subColor {
      color: ${COLORS.OPTIMISTIC_BORDER_2};
    }
    .subinfo {
      color: ${COLORS.OPTIMISTIC_BORDER_2};
    }
  }

  #Believer {
    background: linear-gradient(${COLORS.LOTION}, ${COLORS.LOTION}) padding-box,
      linear-gradient(
          to bottom right,
          ${COLORS.BELIEVER_BORDER_1} 40%,
          ${COLORS.BELIEVER_BORDER_2}
        )
        border-box;
    &:hover {
      box-shadow: 0px 0px 10px ${COLORS.BELIEVER_BORDER_1};
    }
    .subColor {
      color: ${COLORS.BELIEVER_BORDER_2};
    }
    .subinfo {
      color: ${COLORS.BELIEVER_BORDER_2};
    }
  }
`;
