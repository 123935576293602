import React from 'react';
import { Tag, Button } from 'antd';
import first from 'lodash/first';
import get from 'lodash/get';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import { CANDIDATE, RECRUITER, STATUS_DRAFT } from '../../constants';
import { getLatestBooking } from '../event';
import { isDesktop } from '../../helpers/display';
import { activateEventBooking } from '../../service/masterAdmin';
import { AdminRoutes } from '../../constants/routes';
import Notification from '../../components/Notification/Notification';
import { CopyIcon } from '../../components/svgicons';
import getNow from '../getNow';
import { COLORS } from '../../styles/constants/colors';
import { getSkillName } from '../skill';
import { ShareEventButton } from '../../components/shared-components/ShareEvent';
import { StyledTag } from './Styled';
import { getSerialNumber, serialNoColumn } from '../common';
import { getIsCommunityEvent } from '../master-admin';

export const profileCompletionFilterOptions = [
  { id: '0', name: 'All' },
  { id: '1', name: 'Completed' },
  { id: '2', name: 'Pending' },
];

export const eventRegistrationFilterOptions = [
  { id: '0', name: 'All' },
  { id: '1', name: 'Registered' },
  { id: '2', name: 'Pending' },
];

export const eventJoinFilterOptions = [
  { id: '0', name: 'All' },
  { id: '1', name: 'Joined' },
  { id: '2', name: 'Didn"t join' },
];

export const companyColumns = [
  {
    title: 'Company Name',
    key: 1,
    dataIndex: 'companyName',
  },
  {
    title: 'Name',
    key: 2,
    dataIndex: 'userName',
  },
  {
    title: 'Email',
    key: 4,
    dataIndex: 'email',
  },
  {
    title: 'Domain',
    key: 5,
    dataIndex: 'domain',
  },
  {
    title: 'Booking Status',
    key: 6,
    dataIndex: 'bookingStatus',
  },
  {
    title: 'Action',
    key: 7,
    dataIndex: 'action',
  },
  {
    title: 'Invite Link',
    key: 8,
    dataIndex: 'inviteLink',
  },
];

export const makeCompaniesTableData = (recruiters, populateData, history) => {
  return recruiters.map((record) => {
    const {
      name,
      jobs,
      companyRecruiters,
      eventCompanys,
      id,
      inviteLink,
      isNewCompany,
    } = record;
    const data = first(companyRecruiters);
    const userName = get(data, 'user.name', '');
    const email = get(data, 'user.email', '');
    const eventCompany = getLatestBooking({}, 'updatedAt', eventCompanys);

    const handleActivate = () => {
      activateEventBooking(eventCompany.id, RECRUITER).then(() => {
        Notification('success', 'Booking', 'Event booking activated');
        populateData();
      });
    };

    const onRedirect = () => {
      history.push(`${AdminRoutes.recruiter.recruiterInformation}/${id}`);
    };
    return {
      ...record,
      companyName: (
        <StyledNewIndicatorText isNewUser={isNewCompany} onClick={onRedirect}>
          {name}
        </StyledNewIndicatorText>
      ),
      userName,
      jobs: jobs.length,
      email,
      bookingStatus:
        eventCompany.status === STATUS_DRAFT ? (
          <Tag color="red">Draft</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        ),
      action:
        eventCompany.status === STATUS_DRAFT ? (
          <Button
            type="primary"
            size={isDesktop() ? 'large' : 'default'}
            onClick={handleActivate}
          >
            Activate
          </Button>
        ) : null,
      inviteLink: (
        <CopyToClipboard
          text={inviteLink}
          onCopy={() => {
            Notification('success', 'Invite Link Copied');
          }}
          title="Copy link"
        >
          <StyledInvitedButton>
            <CopyIcon />
          </StyledInvitedButton>
        </CopyToClipboard>
      ),
    };
  });
};

export const invitedCandidateColumns = [
  { ...serialNoColumn },
  {
    title: 'Candidate Name',
    key: 1,
    dataIndex: 'name',
  },
  {
    title: 'Email',
    key: 2,
    dataIndex: 'email',
  },
  {
    title: 'Location',
    key: 3,
    dataIndex: 'location',
  },
  {
    title: 'Phone',
    key: 4,
    dataIndex: 'phone',
  },
  {
    title: 'Industry',
    key: 5,
    dataIndex: 'domains',
  },
  {
    title: 'Skills',
    key: 6,
    dataIndex: 'skills',
  },
  {
    title: 'Booking Status',
    key: 6,
    dataIndex: 'bookingStatus',
  },
  {
    title: 'Action',
    key: 7,
    dataIndex: 'action',
  },
  {
    title: 'Invite Link',
    key: 8,
    dataIndex: 'inviteLink',
  },
];

export const makeInvitedCandidateTableData = (
  candidates,
  populateData,
  history,
  page,
  limit,
) => {
  return candidates.map((record, rowId) => {
    const {
      name,
      user: { id, domains, eventCandidates },
      country,
      email,
      phone,
      userSkills,
      inviteLink,
      isNewUser,
    } = record;

    const eventCandidate = getLatestBooking({}, 'updatedAt', eventCandidates);

    const handleActivate = () => {
      activateEventBooking(eventCandidate.id, CANDIDATE).then(() => {
        Notification('success', 'Booking', 'Event booking activated');
        populateData();
      });
    };

    const onRedirect = () => {
      history.push(`${AdminRoutes.candidate.candidateInformation}/${id}`);
    };

    return {
      serialNo: getSerialNumber(page, limit, rowId),
      id,
      name: (
        <StyledNewIndicatorText isNewUser={isNewUser} onClick={onRedirect}>
          {name}
        </StyledNewIndicatorText>
      ),
      location: `${country || '-'}`,
      email,
      phone,
      domains: domains.map((domain) => domain.name).join(', '),
      skills: userSkills
        .map((skill) => get(skill, 'skill.skill', ''))
        .join(', '),
      bookingStatus:
        eventCandidate.status === STATUS_DRAFT ? (
          <Tag color="red">Draft</Tag>
        ) : (
          <Tag color="blue">Active</Tag>
        ),
      action:
        eventCandidate.status === STATUS_DRAFT ? (
          <Button
            type="primary"
            size={isDesktop() ? 'large' : 'default'}
            onClick={handleActivate}
          >
            Activate
          </Button>
        ) : null,
      inviteLink: (
        <CopyToClipboard
          text={inviteLink}
          onCopy={() => {
            Notification('success', 'Invite Link Copied');
          }}
          title="Copy link"
        >
          <StyledInvitedButton>
            <CopyIcon />
          </StyledInvitedButton>
        </CopyToClipboard>
      ),
    };
  });
};

export const getBulkInviteFilterQueryString = (
  profileFilter = 0,
  registerFilter = 0,
  joinFilter = 0,
  search = '',
) => {
  return `profileFilter=${profileFilter}&registerFilter=${registerFilter}&joinFilter=${joinFilter}&search=${search}`;
};

export const eventNameColumn = [
  {
    title: 'Event Name',
    key: 1,
    dataIndex: 'name',
    sorter: true,
  },
];

export const commonEventTableColumns = [
  {
    title: 'Location',
    key: 4,
    dataIndex: 'country',
  },
  {
    title: 'Companies Attending',
    key: 5,
    dataIndex: 'eventRecruiters',
  },
  {
    title: ' Attendees',
    key: 6,
    dataIndex: 'eventCandidates',
  },
  {
    title: 'Status',
    key: 7,
    dataIndex: 'status',
  },
];

export const EventsTableAdminColumns = [
  { ...serialNoColumn },
  ...eventNameColumn,
  {
    title: 'Organizer',
    key: 2,
    dataIndex: 'companyName',
    sorter: true,
  },
  {
    title: 'Industry',
    key: 3,
    dataIndex: 'domains',
  },
  ...commonEventTableColumns,
];

export const getEventStatusTag = (dateTime, endDateTime, isCommunityEvent) => {
  if (isCommunityEvent) {
    return (
      <StyledTag bgColor={COLORS.WHISPER} color={COLORS.METALLIC_SILVER}>
        Community Event
      </StyledTag>
    );
  }
  const currentTime = getNow();
  switch (true) {
    case endDateTime > currentTime && currentTime > dateTime:
      return (
        <StyledTag
          bgColor={COLORS.PATTENS_BLUE}
          color={COLORS.TURQUOISE_BLUE}
          width="123px"
        >
          Live
        </StyledTag>
      );
    case endDateTime < currentTime:
      return (
        <StyledTag
          bgColor={COLORS.COSMOS_RED}
          color={COLORS.RADDICAL_RED}
          width="123px"
        >
          Finished
        </StyledTag>
      );
    case dateTime > currentTime:
      return (
        <Tag color="cyan" style={{ width: '123px', textAlign: 'center' }}>
          Not started
        </Tag>
      );
    default:
      return (
        <Tag color="yellow" style={{ width: '123px', textAlign: 'center' }}>
          Not Available
        </Tag>
      );
  }
};

export const makeEventsTableDataAdmin = (events, page, limit) => {
  return events.map((event, rowId) => {
    const {
      name,
      domains,
      eventCompanys,
      eventCandidates,
      endDateTime,
      dateTime,
      company,
      id,
    } = event;
    const isCommunityEvent = getIsCommunityEvent(event);
    return {
      serialNo: getSerialNumber(page, limit, rowId),
      id,
      name,
      companyName: get(company, 'name', ''),
      domains: domains?.map((domain) => <Tag color="blue">{domain.name}</Tag>),
      country: get(event, 'country') || 'Online',
      eventRecruiters: eventCompanys || 0,
      eventCandidates: eventCandidates || 0,
      status: getEventStatusTag(dateTime, endDateTime, isCommunityEvent),
      isRowClickable: !isCommunityEvent,
    };
  });
};

export const StyledNewIndicatorText = styled.p`
  cursor: pointer;
  color: ${(props) => (props.isNewUser ? COLORS.CUSTOM_RED : 'inhert')};
`;

const StyledInvitedButton = styled.div`
  width: 30px;
  height: 30px;
`;

export const shareAbleJobsColumns = [
  { ...serialNoColumn },
  {
    title: 'Role',
    key: 1,
    sorter: true,
    dataIndex: 'jobName',
  },
  {
    title: 'Skills',
    key: 2,
    dataIndex: 'skills',
  },
  {
    title: 'Company',
    key: 3,
    dataIndex: 'company',
  },
  {
    title: 'Positions',
    key: 4,
    dataIndex: 'positions',
  },
  {
    title: 'Share',
    key: 5,
    dataIndex: 'share',
  },
];

export const makeShareableJobTableData = (
  jobs = [],
  eventId,
  eventName,
  page,
  limit,
) => {
  return jobs.map((job, rowId) => {
    const getSkills = () => {
      return isArray(job.jobSkills)
        ? job.jobSkills.map((jobSkill) => {
            const skill = getSkillName(jobSkill.skill);
            return skill ? <Tag color="blue">{skill}</Tag> : null;
          })
        : '';
    };
    return {
      serialNo: getSerialNumber(page, limit, rowId),
      id: job.id,
      positions: get(job, 'positions', 0),
      company: get(job, 'company.name', ' '),
      skills: getSkills(),
      jobName: <styledJobName>{job.designation}</styledJobName>,
      designation: job.designation,
      jobApplications: job.jobApplications,
      share: (
        <ShareEventButton eventId={eventId} name={eventName} jobId={job.id} />
      ),
    };
  });
};
