import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { BarChartOutlined } from '@ant-design/icons';
import { getRoleId, getUserClassWithRoleId } from '../../../../utils/userInfo';
import '../../../../styles/components/events/event-analytics-and-report.scss';
import { getJobResults } from '../../../../store/features/jobResults';
import { ToolTipsText } from '../../../../constants/tooltips';
import { PrimaryButton } from '../../../shared-components/Button';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import ViewJobAnalyticsModal from '../../../../containers/Recruiter/Recruitment/ViewJobAnalyticsModal';
import { FONT } from '../../../../styles/constants/typography';

export const EventAnalyticsAndReport = ({ event }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [jobResults, setJobResults] = useState([]);

  const [jobId, setJobId] = useState(null);

  const dispatch = useDispatch();

  const showModal = async () => {
    setIsModalVisible(true);

    const response = await dispatch(
      getJobResults({ eventId: event.id, page: 1, limit: -1 }),
    );
    const data = await response.payload.jobData;

    setJobResults(data);

    setJobId(data?.length && data[0].jobId);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const roleId = getRoleId();

  return (
    <>
      <PrimaryButton
        onClick={showModal}
        theme={getUserClassWithRoleId(roleId)}
        id="candidate_view_event_analytics_btn"
      >
        <StyledTooltip
          className="view-analytics"
          title={ToolTipsText.eventAnalytics}
          svgColor={getUserColorWithRoleId(roleId)}
        >
          <BarChartOutlined />
          <StyledText>View Analytics</StyledText>
        </StyledTooltip>
      </PrimaryButton>

      {isModalVisible ? (
        <ViewJobAnalyticsModal
          jobId={jobId}
          candidateEvent
          jobResults={jobResults}
          handleCancel={handleCancel}
          visible={isModalVisible}
          event={event}
        />
      ) : null}
    </>
  );
};

const StyledText = styled.span`
  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: ${FONT[14]};
  }

  font-size: ${FONT[15]};
`;

const StyledTooltip = styled(Tooltip)`
  font-size: 15px;
  svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    fill: ${COLORS.WHITE};
  }
  &:hover {
    svg {
      fill: ${(props) => props.svgColor};
    }
  }
  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 11px;
    padding: 0;

    svg {
      width: 15px;
      height: 15px;
    }
  } ;
`;
