import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import moment from 'moment';
import get from 'lodash/get';
import { EventCardProps } from '../../../interfaces/components/Community/EventCard';
import { smallFontMixin } from '../../../styles/constants/mixins';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getSizeInPx } from '../../../utils/size';
import {
  EventStatusLabel,
  getEventFormaterDate,
  isEventOnline,
} from '../../../utils/event';
import { EventStatus, ONLINE_EVENT_CODE } from '../../../constants';
import { getFileS3 } from '../../../utils/s3.storage';
import { getParsedEventName } from '../../../utils/common';
import { EventCalendarIcon, PinIcon } from '../../svgicons';
import { FONT } from '../../../styles/constants/typography';
import { MainWebsiteRoutes } from '../../../constants/routes';

const EventCard: React.FC<EventCardProps> = ({ event, width, height }) => {
  const eventStatus = EventStatusLabel(event);
  const url = `${MainWebsiteRoutes.home}/spectator-mode/${get(
    event,
    'id',
    null,
  )}/${getParsedEventName(get(event, 'name', ''))}`;

  return (
    <StyledMainContainer width={width} height={height}>
      <a href={url}>
        <StyledCardFrontContainer>
          <StyledImageContainer>
            <StyledImage
              src={getFileS3(get(event, 'cover', ''))}
              alt="event card"
              decoding="async"
            />
            <StyledStatus eventStatus={eventStatus.toLowerCase()}>
              <p>{eventStatus} event</p>
            </StyledStatus>
          </StyledImageContainer>
          <StyledEventInfoContainer>
            <StyledEventHeaderContainer>
              <StyledHeadingContainer>
                <h4>{get(event, 'name', '')}</h4>
              </StyledHeadingContainer>
            </StyledEventHeaderContainer>

            <StyledWrapper>
              <StyledIconAndTextContainer>
                <StyledInfoIconContainer>
                  <EventCalendarIcon />
                </StyledInfoIconContainer>
                {
                  <StyledTime
                    dateTime={moment
                      .unix(get(event, 'dateTime', null))
                      ?.format()}
                  >
                    {getEventFormaterDate(get(event, 'dateTime', null))}
                  </StyledTime>
                }
              </StyledIconAndTextContainer>

              <StyledIconAndTextContainer>
                <StyledInfoIconContainer>
                  <PinIcon />
                </StyledInfoIconContainer>
                <StyledAddress>
                  <span>
                    {isEventOnline(get(event, 'eventType', ONLINE_EVENT_CODE))
                      ? 'Online'
                      : `${get(event, 'country', '')}, ${get(
                          event,
                          'zip',
                          '',
                        )}`}
                  </span>
                </StyledAddress>
              </StyledIconAndTextContainer>
            </StyledWrapper>
          </StyledEventInfoContainer>
        </StyledCardFrontContainer>
      </a>
    </StyledMainContainer>
  );
};

export default EventCard;

const StyledMainContainer = styled.div<{
  width?: number | string;
  height?: number | string;
}>`
  position: relative;
  width: ${({ width }) => (width ? getSizeInPx(width) : '380px')};
  height: ${({ height }) => (height ? getSizeInPx(height) : '')};
  perspective: 1000px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  background-color: ${COLORS.WHITE};
  cursor: pointer;
  &:hover img {
    transform: scale(1.1);
    border-radius: 5px;
  }
  & > * {
    cursor: pointer;
  }
`;

const StyledCardFrontContainer = styled.div`
  backface-visibility: hidden;
  width: 100%;
  cursor: pointer;
`;

const StyledImageContainer = styled.div`
  width: 100% !important;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const keyframe = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const StyledStatus = styled.div<{ eventStatus: string }>`
  background: ${({ eventStatus }) =>
    eventStatus === EventStatus.ONGOING
      ? 'linear-gradient(to right, #cd91f3 0%, #757bd3 100%)'
      : eventStatus === EventStatus.LIVE
      ? 'linear-gradient(to right, #E90031 0%, #f0748c 50%, #F74444 100%)'
      : eventStatus === EventStatus.PAST
      ? 'linear-gradient(to right, #4E728A 0%, #92c0e0 50%,  #8dbbdf 100%)'
      : 'linear-gradient(to right, #764CF0 0%, #7491F5 50%,  #9CB3FF 100%)'};
  animation: ${keyframe} 2s ease infinite;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
  width: 90px;
  height: 26px;
  border-radius: 50px;
  background-size: 250% 400%;
  text-align: center;
  ${DISPLAY_FLEX('row', 'center', 'center')};
  position: absolute;
  right: 10px;
  top: 11px;
  color: ${COLORS.WHITE};
  font-size: ${FONT[11]};

  p {
    font-size: 9px;
    font-weight: 500;
  }

  svg {
    ${MARGIN(0, 4, 0, 8)}
    fill: white;
  }
`;

const StyledEventInfoContainer = styled.div`
  position: relative;
  ${PADDING(13, 10)}
  ${DISPLAY_FLEX('column', 'space-between')};
`;

const StyledWrapper = styled.div`
  ${MARGIN(10, 0, 0, 0)}

  span {
    font-size: 12px;
    color: ${COLORS.BLACK};
  }
`;

export const StyledEventCostTypeText = styled.span`
  ${smallFontMixin};
  font-weight: 800;
  color: ${COLORS.BLUE_BERRY};
`;
const StyledEventHeaderContainer = styled.div``;

const StyledHeadingContainer = styled.div`
  h4 {
    font-weight: 600;
    ${MARGIN(5, 0, 0, 0)}
    font-size: 14px;
    line-height: 19px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
    color: ${COLORS.BLACK};
    max-width: 100%;
  }
`;

const styledStateText = css`
  display: inline-block;
  font-size: 11px;
  white-space: nowrap;
  color: ${COLORS.BLACK};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 10px;
    ${MARGIN(null, null, null, 4)}
  }
`;

const StyledAddress = styled.address`
  ${styledStateText}
  ${MARGIN(null, null, 0, null)}
  font-weight: 500;

  span {
    font-size: 11px;
    color: ${COLORS.GRANITE_GRAY_2};
  }
`;

export const StyledStatsText = styled.span`
  ${styledStateText}
`;

const StyledTime = styled.time`
  ${styledStateText}
  font-weight: 500;
`;

export const StyledIconAndTextContainer = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 7px;
  ${MARGIN(null, null, 10, null)}
`;

export const StyledInfoIconContainer = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 14px;
    height: 14px;
  }

  svg {
    width: inherit;
    height: inherit;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  border-radius: 5px 5px 0 0;
  height: 180px;
  transition: all 0.4s;

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    max-height: 240px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-height: 230px;
  }
`;
