export const candidateAvailability = [
  {
    id: '1',
    name: 'Immediate joiner',
  },
  {
    id: '2',
    name: 'Within 15 days',
  },
  {
    id: '3',
    name: 'Within a month',
  },
  {
    id: '4',
    name: 'More than a month',
  },
];
