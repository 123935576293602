import React from 'react';
import isInteger from 'lodash/isInteger';
import { OngoingAnalytics } from '../Common';

export default function OrganizerEventAnalytics({ analytics }) {
  if (!analytics) {
    analytics = {
      noOfCompanies: 0,
      noOfRecruiters: 0,
      noOfCandidates: 0,
      noOfJobs: 0,
      noOfJobApplications: 0,
    };
  }
  const {
    noOfCompanies,
    noOfRecruiters,
    noOfCandidates,
    noOfJobs,
    noOfJobApplications,
  } = analytics;

  if (
    !isInteger(noOfCompanies) ||
    !isInteger(noOfRecruiters) ||
    !isInteger(noOfCandidates) ||
    !isInteger(noOfJobs) ||
    !isInteger(noOfJobApplications)
  ) {
    return null;
  }

  const itemList = [
    {
      name: 'Candidates',
      value: noOfCandidates,
    },
    {
      name: 'Companies',
      value: noOfCompanies,
    },
    {
      name: 'Recruiters',
      value: noOfRecruiters,
    },
    {
      name: 'Jobs',
      value: noOfJobs,
    },
    {
      name: 'Applications',
      value: noOfJobApplications,
    },
  ];

  return <OngoingAnalytics itemList={itemList} />;
}
