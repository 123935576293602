import axios from 'axios';

const slackWebHook = process.env.REACT_APP_SLACK_WEBHOOK_COUPON_URL;

const sendMessageToSlack = async (message = '') => {
  const response = await axios.post(
    slackWebHook,
    JSON.stringify({
      text: message,
    }),
    {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        },
      ],
    },
  );
  return response.data;
};

export { sendMessageToSlack };
