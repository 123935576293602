import React from 'react';
import styled from 'styled-components';
import AddressAutoComplete from '../Common/AddressAutoComplete';
import { mediumHeadingMixin } from '../../styles/constants/mixins';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';

const CompanyAddressModalItem = ({ form }) => {
  return (
    <div>
      <StyleHeadingLevelThree id="company_address_title">
        Company Address
      </StyleHeadingLevelThree>

      <div>
        <AddressAutoComplete form={form} />
      </div>

      {/*
      <Row>
        <Col md={6} xs={24}>
          <p className="profile__information__header small-heading">
            Suite Number <MandatoryStar />
          </p>
          <NumberBox name="suiteNumber" placeholder="Suite" maxChar={5} />
        </Col>
      </Row>
      */}
    </div>
  );
};

export default CompanyAddressModalItem;

const StyleHeadingLevelThree = styled.h3`
  margin-bottom: 24px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${mediumHeadingMixin}
  color: ${COLORS.CHINESE_BLACK};
`;
