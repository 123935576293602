import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Form, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { CustomStyleFormGroup } from '../Form/Form';
import { CheckPassword, ConfirmPassword } from './Form/Form';
import { PrimaryButton } from './Button';
import { getThemeByRoleId } from '../../utils/common';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../utils/userInfo';
import { PageContainer } from './PageContainer';
import { RightAlignContainer } from './RightAlignContainer';
import {
  changePassword,
  checkIfUserExist,
  createPassword,
} from '../../service/auth';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { LargeFont, SemiLargeHeading } from './Texts';
import { userProfileStore } from '../../utils/profile';
import { HiddenMobile } from './DocumentViewerModal';
import { getUserColorWithRoleId } from '../../styles/utils';
import Circle from './PageCircle';
import Notification from '../Notification/Notification';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../constants/routes';

export const CreateOrChangePassword = ({ onSkip }) => {
  const [form] = Form.useForm();
  const { profileData } = userProfileStore();
  const history = useHistory();
  const [isPasswordSetOrNot, setIsPasswordSetOrNot] = useState(true);
  const [isLoding, setIsLoading] = useState(true);

  const roleId = getRoleId();
  const handleSubmit = async ({ password, oldPassword }) => {
    if (password?.toLowerCase() === oldPassword?.toLowerCase()) {
      return Notification('warning', 'Password should not be the same');
    }
    if (!isPasswordSetOrNot) {
      await createPassword({ password }).then(() => {
        if (onSkip) {
          return onSkip();
        }
        Notification('success', 'Password Created successfully');
        setIsPasswordSetOrNot(true);
        form.resetFields();
      });
    } else {
      await changePassword({ password, oldPassword }).then(() => {
        Notification('success', 'Password Changed successfully');
        form.resetFields();
      });
    }
    if (checkIfUserIsRecruiter(roleId)) {
      return history.push(RecruiterRoutes.profile);
    } else if (checkIfUserIsOrganizer(roleId)) {
      return history.push(OrganizerRoutes.profile);
    }
    return history.push(CandidateRoutes.profile);
  };

  useEffect(() => {
    if (profileData.email) {
      checkIfUserExist(profileData.email, false, roleId).then((response) => {
        const { isPasswordSetOrNot } = response;
        setIsPasswordSetOrNot(isPasswordSetOrNot);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [profileData.email]);

  return (
    <>
      <HiddenMobile>
        <Circle color={getUserColorWithRoleId(roleId)} />
      </HiddenMobile>

      <Col xs={24} lg={12} xxl={6}>
        <SemiLargeHeading color={getUserColorWithRoleId(roleId)}>
          Password
        </SemiLargeHeading>
      </Col>
      <PageContainer>
        {isLoding ? (
          <StyledSpinContainer>
            <Spin />
          </StyledSpinContainer>
        ) : (
          <StyledContainer>
            <CustomStyleFormGroup
              form={form}
              name="resetpassword"
              onFinish={handleSubmit}
              expand
            >
              <div>
                <StyledTitle>
                  <LargeFont>
                    {isPasswordSetOrNot
                      ? 'Change password'
                      : 'Create a New password'}
                  </LargeFont>
                </StyledTitle>

                {isPasswordSetOrNot ? (
                  <CheckPassword
                    name="oldPassword"
                    placeholder="Current Password"
                  />
                ) : null}

                <CheckPassword name="password" placeholder="New Password" />

                <ConfirmPassword
                  name="checkPassword"
                  placeholder="Confirm Password"
                />

                <RightAlignContainer>
                  <PrimaryButton theme={getThemeByRoleId(roleId)} type="submit">
                    Submit & Continue
                  </PrimaryButton>
                </RightAlignContainer>
              </div>
            </CustomStyleFormGroup>
          </StyledContainer>
        )}
      </PageContainer>
    </>
  );
};

const StyledContainer = styled.div``;

const StyledTitle = styled.div`
  ${MARGIN(0, 0, 15, 0)}
`;

const StyledSpinContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;
