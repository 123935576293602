import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  extraLargeHeadingMixin,
  mediumHeadingMixin,
  smallFontMixin,
} from '../../../styles/constants/mixins';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

const Header = ({ name }) => {
  return (
    <StyledContainer>
      <StyledHeadingLevelThree id="header_welcome">
        Welcome {name}
      </StyledHeadingLevelThree>
      <StyledParagraph>
        Please fill the Details to Create Profile with Recorem.
      </StyledParagraph>
    </StyledContainer>
  );
};

export default Header;

export const StyledContainer = styled.div`
  margin-top: 28px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 82px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      margin-top: 20px;
    }

    h3 {
      margin-bottom: 0px;
    }

    p {
      ${smallFontMixin};
      padding-left: 0px;
    }
  }
`;

export const StyledHeadingLevelThree = styled.h3`
  ${extraLargeHeadingMixin};
  font-weight: ${FONT_WEIGHTS.BOLD};
  color: #434343;
  margin-bottom: 8px;
`;

export const StyledParagraph = styled.p`
  ${mediumHeadingMixin};
  font-size: ${FONT[14]};
  line-height: 21px;
  position: relative;
  font-weight: 300;
  color: #8c8c8c;
  padding-left: 0;
`;
