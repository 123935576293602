/* eslint-disable jsx-a11y/anchor-is-valid */
import { Drawer, Menu, Row, Col, Button, Tooltip } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import { HelloText } from '../Common/HelloText';
import { userProfileStore } from '../../utils/profile';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
  Routes,
} from '../../constants/routes';
import InviteMemberModal from '../CrewManagement/InviteMemberModal';
import ConfigContext from '../../store/configContext';
import { logoutUser } from '../../store/features/auth';
import AdminRecruit from '../TopBar/AdminRecruit';
import CancelIcon from '../../assets/svg/cancel.svg';
import {
  checkIfUserIsRecruiter,
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsSuperAdmin,
  checkIfUserIsMasterAdmin,
  getRoleId,
} from '../../utils/userInfo';
import { SupportIcon } from '../svgicons';
import { checkIfUserIsSuperAdminOrMasterAdmin } from '../../utils/superAdmin/admin';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { getFilerByUserRole, getUserColorWithRoleId } from '../../styles/utils';
import { RightAlignContainer } from '../shared-components/RightAlignContainer';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { MENU_FILTER } from '../../styles/constants/styles';
import { checkIfUserIsNew } from '../../service/candidate';
import WalletButton from '../Common/WalletButton';
import WalletDropdown from '../shared-components/Topbar/WalletDropdown';
import RecruiterWallet from '../shared-components/Topbar/RecruiterWallet';
import { useSkipPayment } from '../../hooks/useSkipPayment';
import { usePaymentInformation } from '../../hooks/paymentInformation';
import { clearAllRTKQueryCaches } from '../../store/features/rtkApi/helper';
import { ToolTipsText } from '../../constants/tooltips';
import { useGetCommunityPageQuery } from '../../store/features/rtkApi/common';

const ResponsiveMenu = ({ onClose, visible, noRedirect = false }) => {
  const { navigationList, setNewUser } = useContext(ConfigContext);
  const roleId = getRoleId();
  const [userClassName, setUserClassName] = useState('');
  const [routingProfile, setRoutingProfile] = useState('');
  const [activeState, setActiveState] = useState('0');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  const { canNavigate } = useSkipPayment();
  const { paymentCompleted } = usePaymentInformation();

  const handleNavigation = (nav) => {
    setActiveState(nav.key);
    if (nav.key === '/candidate/job-result/' && isDisabled) {
      history.push((prev) => prev);
    }
    /**
     * If wallet is clicked and route is already pricing-plan
     * or payment is done then sidebar will not be closed.
     */
    if (
      nav.key === 'Wallet' &&
      (location.pathname === RecruiterRoutes.pricingPlan || paymentCompleted)
    ) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    if (checkIfUserIsOrganizer(roleId)) {
      setRoutingProfile('/organizer/profile/');
      setUserClassName('organizer');
    } else if (checkIfUserIsRecruiter(roleId)) {
      setRoutingProfile('/recruiter/profile/');
      setUserClassName('recruiter');
    } else if (checkIfUserIsCandidate(roleId)) {
      setRoutingProfile('/candidate/profile/');
      setUserClassName('candidate');
    }
  }, [roleId]);
  const handleLogout = () => {
    if (checkIfUserIsMasterAdmin(roleId) || checkIfUserIsSuperAdmin(roleId)) {
      clearAllRTKQueryCaches(dispatch);
      dispatch(logoutUser(roleId));
      if (!noRedirect) history.push(Routes.adminLogin);
    } else {
      clearAllRTKQueryCaches(dispatch);
      dispatch(logoutUser(roleId));
      if (!noRedirect) history.push(Routes.login);
    }
  };

  const selectListItem = (location) => {
    navigationList.forEach((navItem) => {
      const hash = get(location, 'pathname', '');

      const val = get(navItem, 'selectionPath', get(navItem, 'route', ''));
      const search = get(navItem, 'selectionSearch', val);
      if (hash.includes(search)) {
        setActiveState(val);
      }
    });
  };

  useEffect(() => {
    history.listen((location) => {
      selectListItem(location);
    });

    // eslint-disable-next-line
  }, [navigationList, history]);

  useEffect(() => {
    selectListItem(history.location);

    // eslint-disable-next-line
  }, [navigationList]);

  const handleCustomClick = (path) => {
    history.push(path);
  };

  const handleMenuItemClick = async (disabled, callOnDisabled, path) => {
    if (!disabled) {
      handleCustomClick(path);
    }

    if (disabled) {
      let isCandidateNew = true;
      if (checkIfUserIsCandidate(roleId)) {
        const resp = await checkIfUserIsNew();
        isCandidateNew = get(resp, 'isCandidateNew', true);
      }
      setNewUser(isCandidateNew);
      if (isCandidateNew) {
        if (callOnDisabled) callOnDisabled();
        setIsDisabled(disabled);
      } else {
        setIsDisabled(isCandidateNew);
        handleCustomClick(path);
      }
    }
  };

  const { profileData } = userProfileStore();
  const companyId = get(profileData, 'company.id', null);
  const { isSuccess: isCommunityPageActive } = useGetCommunityPageQuery(
    companyId,
    {
      skip: !checkIfUserIsOrganizer(roleId),
    },
  );
  const [visibleMemberModal, setVisible] = useState(false);

  const CloseCrewManagementModal = () => {
    setVisible(false);
  };

  const openCrewManagementModal = () => {
    setVisible(true);
  };

  const onCommunityLinkClick = () => {
    onClose();
    history.push(`/event-partner/${get(profileData, 'company.id')}/community`);
  };

  return (
    <>
      <StyledDrawer
        title={null}
        placement="left"
        closable={0}
        width="100%"
        onClose={onClose}
        visible={visible}
        key="left"
        id={`${userClassName}__res_menu`}
      >
        <StyledCol color={getUserColorWithRoleId(roleId)}>
          <StyledInnerHeading>
            <Col md={16} xs={6}>
              <StyledAlignRow onClick={onClose}>
                <StyledClose src={CancelIcon} alt="" />
                <StyledCloseText>{'  Close'}</StyledCloseText>
              </StyledAlignRow>
            </Col>
            {!checkIfUserIsSuperAdminOrMasterAdmin(roleId) ? (
              <Col md={8} xs={18}>
                <RightAlignContainer>
                  <AdminRecruit userClassName={userClassName} roleId={roleId} />
                </RightAlignContainer>
              </Col>
            ) : null}
            <Col md={16} xs={16}>
              <StyledRowWelcome color={getUserColorWithRoleId(roleId)}>
                <HelloText profileData={profileData} />
              </StyledRowWelcome>
            </Col>
            {!checkIfUserIsSuperAdminOrMasterAdmin(roleId) ? (
              <Col md={8} xs={8}>
                <RightAlignContainer>
                  <Link to={!canNavigate() ? '#' : routingProfile}>
                    <StyledViewProfile onClick={onClose}>
                      View profile
                    </StyledViewProfile>
                  </Link>
                </RightAlignContainer>
              </Col>
            ) : null}

            <StyledMenuContainer>
              <div>
                <StyledMenu
                  theme="Light"
                  mode="inline"
                  selectedKeys={[activeState]}
                  onClick={handleNavigation}
                >
                  {[
                    ...navigationList.map((menuItem, i) => {
                      const {
                        Icon,
                        title,
                        route,
                        populate = true,
                        routeToRedirect,
                        disabled = false,
                        callOnDisabled,
                        selectionPath,
                      } = menuItem;
                      return populate ? (
                        <StyledMenuItemContainer
                          key={selectionPath || route}
                          title={title}
                          filter={getFilerByUserRole(roleId)}
                          color={getUserColorWithRoleId(roleId)}
                          onClick={() =>
                            handleMenuItemClick(
                              disabled,
                              callOnDisabled,
                              routeToRedirect || route,
                            )
                          }
                        >
                          <img src={Icon} alt="Icon" />
                          <Link id={`res-menu-item${i}`}>{title}</Link>
                        </StyledMenuItemContainer>
                      ) : null;
                    }),
                    <StyledWalletMenuItem
                      key={'Wallet'}
                      title="Wallet"
                      color={getUserColorWithRoleId(roleId)}
                    >
                      {checkIfUserIsRecruiter(roleId) ? (
                        <RecruiterWallet />
                      ) : (
                        <WalletDropdown isSidebar={true} />
                      )}
                    </StyledWalletMenuItem>,
                  ]}
                </StyledMenu>
              </div>
            </StyledMenuContainer>
          </StyledInnerHeading>

          <div>
            {checkIfUserIsRecruiter(roleId) ||
            checkIfUserIsCandidate(roleId) ||
            checkIfUserIsOrganizer(roleId) ? (
              <>
                <StyledConnectButtonWrapper>
                  <WalletButton />
                </StyledConnectButtonWrapper>
              </>
            ) : null}
            {checkIfUserIsOrganizer(roleId) ? (
              <StyledMenuItem>
                <Tooltip
                  placement="bottomLeft"
                  title={
                    !isCommunityPageActive ? ToolTipsText.communityPage : ''
                  }
                  id="dashboard_community_page-btn"
                  trigger={['click']}
                >
                  <div>
                    <StyledCommunityLink
                      onClick={onCommunityLinkClick}
                      disabled={!isCommunityPageActive}
                    >
                      Community Page
                    </StyledCommunityLink>
                  </div>
                </Tooltip>
              </StyledMenuItem>
            ) : (
              ''
            )}
            {!checkIfUserIsCandidate(roleId) &&
            !checkIfUserIsSuperAdminOrMasterAdmin(roleId) ? (
              <>
                <StyledMenuItem onClick={openCrewManagementModal}>
                  Manage Crew
                </StyledMenuItem>
                {visibleMemberModal ? (
                  <InviteMemberModal
                    visible={visibleMemberModal}
                    handleClose={CloseCrewManagementModal}
                    profileData={profileData}
                  />
                ) : null}
              </>
            ) : (
              ''
            )}
            {!checkIfUserIsSuperAdminOrMasterAdmin(roleId) ? (
              <StyledMenuItem onClick={onClose}>
                <Link
                  to={
                    checkIfUserIsOrganizer(roleId)
                      ? OrganizerRoutes.helpDoc
                      : checkIfUserIsRecruiter(roleId)
                      ? RecruiterRoutes.helpDoc
                      : CandidateRoutes.helpDoc
                  }
                >
                  Help Doc
                </Link>
              </StyledMenuItem>
            ) : null}
            <StyledMenuItem onClick={handleLogout}>Logout</StyledMenuItem>
          </div>
        </StyledCol>
        {!checkIfUserIsSuperAdminOrMasterAdmin(roleId) ? (
          <StyledSupportButton
            type="primary"
            color={getUserColorWithRoleId(roleId)}
            onClick={onClose}
          >
            <SupportIcon />
            <Link
              to={
                checkIfUserIsOrganizer(roleId)
                  ? OrganizerRoutes.support
                  : checkIfUserIsRecruiter(roleId)
                  ? RecruiterRoutes.support
                  : CandidateRoutes.support
              }
            >
              Support
            </Link>
          </StyledSupportButton>
        ) : null}
      </StyledDrawer>
    </>
  );
};
export default React.memo(ResponsiveMenu);

const StyledDrawer = styled(Drawer)`
  z-index: 1000;
  .ant-drawer-body {
    padding: 0px;
  }
`;

const StyledCol = styled(Col)`
  ${PADDING(20, 20, 0, 20)}
  min-height: calc(100vh - 63px);
  min-height: calc(var(--vh, 1vh) * 100 - 63px);
  ${DISPLAY_FLEX('column', 'space-between')}

  .ant-dropdown-trigger.ant-dropdown-link {
    color: ${(props) => props.color};
  }
`;

const StyledSupportButton = styled(Button)`
  width: 100%;
  height: 53px;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.color};
  ${MARGIN(10, 0, 0, 0)}

  span svg {
    fill: ${COLORS.WHITE};
    width: 30px;
    height: 30px;
    ${MARGIN(5, 10, 0, 0)}
  }
  a {
    cursor: pointer;
    &:hover {
      color: ${COLORS.WHITE};
    }
  }
`;

const StyledMenuContainer = styled.div`
  ${DISPLAY_FLEX('column', 'space-between')}
  height: 100%;
  width: 100%;
`;

const StyledInnerHeading = styled(Row)`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  row-gap: 12px !important;
`;

const StyledAlignRow = styled(Row)`
  align-content: center;
  align-items: center;
  ${MARGIN(8, 0, 0, 0)}
`;

const StyledClose = styled.img`
  width: 17px;
  height: 18px;
  display: flex;
  align-items: center;
  align-content: center;
  filter: ${COLORS.DARK_SILVER};
  ${MARGIN(0, 5, 0, 0)}
`;

const StyledCloseText = styled.span`
  color: ${COLORS.DARK_SILVER};
  font-size: ${FONT[16]};
`;

const StyledRowWelcome = styled(Row)`
  align-content: center;
  align-items: center;
  font-size: ${FONT[20]};
  color: ${(props) => props.color};
  ${MARGIN(8, 0, 0, 0)}
`;

const StyledViewProfile = styled.div`
  color: ${COLORS.GRAY_LIGHT_2};
  font-size: ${FONT[16]};
  ${MARGIN(4, 0, 0, 0)}
  cursor: pointer;
`;

const StyledMenuItem = styled.div`
  color: ${COLORS.SUB_HEADER};
  font-size: ${FONT[16]};
  justify-content: space-around;
  ${MARGIN(0, 0, 10, 0)}
  ${PADDING(0, 0, 0, 22)}
  cursor: pointer;
  a {
    cursor: pointer;
  }
`;

const StyledMenuItemContainer = styled(Menu.Item)`
  img {
    position: relative;
    fill: ${COLORS.PLACEHOLDER};
    width: 28px;
    filter: ${MENU_FILTER};
    ${MARGIN(0, 20, 0, 0)}
  }

  a {
    color: ${COLORS.PLACEHOLDER};
    cursor: pointer;
  }

  &.ant-menu-item-selected {
    background-color: unset !important;
    img {
      filter: ${({ filter }) => filter};
    }

    a {
      color: ${({ color }) => color};
    }

    &:after {
      border-right: 5px solid ${({ color }) => color};
    }
  }
  padding-left: 12px !important;
`;

const StyledWalletMenuItem = styled(Menu.Item)`
  padding-left: 12px !important;
  img {
    ${MARGIN(0, 20, 7, 0)}
  }
  span {
    &[data-verified='inactive'] {
      color: ${COLORS.GRAY};
    }

    &[data-verified='active'] {
      color: ${COLORS.LAVENDER_INDIGO};
    }
  }
  &.ant-menu-item-selected {
    background-color: unset !important;

    &:after {
      border-right: 5px solid ${({ color }) => color};
    }
  }
`;

const StyledMenu = styled(Menu)`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

const StyledConnectButtonWrapper = styled.div`
  padding: 15px 0 !important;
`;

const StyledCommunityLink = styled.a`
  color: ${COLORS.PHILLIPPINE_GRAY};
`;
