import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getFileS3 } from '../../../utils/s3.storage';
import { getSingleUserData } from '../../../utils/chat';
import { TestContext } from '../../../context/testMode';
import { dummyUserProfilePersonalChats } from '../../../__mocks__/chatPersonal';
import { DISPLAY_FLEX, PADDING } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import SocketContext from '../../../context/socket';
import AvatarOnline from '../AvatarOnline';
import { getUserTypeWithRole } from '../../../utils/userInfo';
import { RECRUITER } from '../../../constants';

const MessageHeader = () => {
  const testMode = useContext(TestContext);

  const { selectedChatDetails, selectedChatUsersDetails } = useSelector(
    (state) => state.chat,
  );

  const userId = get(selectedChatDetails, 'user.id', '');
  const [isOnline, setIsOnline] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const client = useContext(SocketContext);

  useEffect(() => {
    const userType = get(selectedChatDetails, 'user.type', '');
    if (userType === 'company') {
      setUserProfileDetails(
        getSingleUserData(null, userId, selectedChatUsersDetails),
      );
    } else {
      setUserProfileDetails(
        getSingleUserData(userId, null, selectedChatUsersDetails),
      );
    }
  }, [selectedChatDetails, selectedChatUsersDetails, userId]);

  const description = getUserTypeWithRole(
    get(userProfileDetails, 'roleId', RECRUITER),
  );
  const profilePic = get(userProfileDetails, 'profilePic', '');
  const name = get(userProfileDetails, 'name', '');
  const picture = getFileS3(profilePic);
  const userTheme = get(selectedChatDetails, 'user.theme', '');
  const senderId = get(userProfileDetails, 'id', '');
  const type = get(userProfileDetails, 'type', '');

  // Will add once menu options are available
  // const moreOptionsList = [
  //   {
  //     name: 'Help.?',
  //     link: '#',
  //   },
  // ];

  // const moreOptions = (
  //   <Menu className="chat_menu">
  //     {moreOptionsList.map((item, idx) => (
  //       <Menu.Item key={idx.toString()}>
  //         <Link to={item.link}>{item.name}</Link>
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  useEffect(() => {
    if (senderId && type && client) {
      (async () => {
        await client.subscribe(`/user/${senderId}/${type}/`, async (resp) => {
          const newStatus = get(resp, 'isOnline', null);
          setIsOnline(newStatus);
        });
        await client.message({ type: 40, userId: senderId, userType: type });
      })();
    }

    // eslint-disable-next-line
  }, [client, senderId, type]);

  return (
    <StyledThreadHeader>
      <StyledThreadHeaderLeft>
        <StyledColorFlag bgColor={userTheme} />
        <AvatarOnline picture={picture} isOnline={isOnline} />
        <StyledThreadHeaderLeftDetails id="chat-header-left-details">
          {/* in test mode, dummy data is assigned */}
          {testMode ? (
            <h5 id="chat-header-name">
              {dummyUserProfilePersonalChats.companys[1].name}
            </h5>
          ) : (
            <h5 id="chat-header-name">{name}</h5>
          )}
          <p id="chat-header-description">{description}</p>
        </StyledThreadHeaderLeftDetails>
      </StyledThreadHeaderLeft>
      {/* Will add once menu options are available */}
      {/* <div className="message__thread__header--right">
        <KebabMenu menu={moreOptions} />
      </div> */}
    </StyledThreadHeader>
  );
};

const StyledThreadHeader = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  border-bottom: 1px solid ${COLORS.CULTURED};
  height: 10%;
`;

const StyledThreadHeaderLeft = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  height: 100%;
`;

const StyledColorFlag = styled.div`
  width: 8px;
  height: 100%;
  margin-right: 10px;
  background-color: ${(props) => props.bgColor};
`;

const StyledThreadHeaderLeftDetails = styled.div`
  ${DISPLAY_FLEX('column')};
  ${PADDING(15)};

  h5 {
    margin: 0;
    font-size: ${FONT[13]};
    color: ${COLORS.CHINESE_BLACK};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }

  p {
    font-size: ${FONT[11]};
    color: ${COLORS.EERIE_BLACK};
    font-weight: ${FONT_WEIGHTS.REGULAR};
    opacity: 60%;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[10]};
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]};
    }
  }
`;

export default MessageHeader;
