import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getRedirectableLink } from '../../../utils/general';
import { getFileS3 } from '../../../utils/s3.storage';

export const EventHosts = ({ hosts }: any) => {
  return (
    <>
      {hosts && hosts.length ? (
        <div>
          <StyledTitleHeading>Hosts</StyledTitleHeading>
          <StyledListContainer>
            {hosts?.map((host: any) => {
              const { name, pic, designation, links } = host;
              return (
                <StyledListItem key={host}>
                  <StyledImageContainer>
                    <img src={getFileS3(pic)} alt={name} />
                  </StyledImageContainer>
                  <StyledTextContainer>
                    <a
                      target="blank"
                      href={getRedirectableLink(get(links, '[0]'))}
                    >
                      <StyledTitle>{name}</StyledTitle>
                    </a>
                    <StyledSubTextInfo>{`${designation}`}</StyledSubTextInfo>
                  </StyledTextContainer>
                </StyledListItem>
              );
            })}
          </StyledListContainer>
          <StyledLine />
        </div>
      ) : null}
    </>
  );
};

const StyledLine = styled.hr`
  width: 30%;
  margin: 30px auto 0px auto;
`;

const StyledTitleHeading = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 24px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 16px;
  }
`;

const StyledListContainer = styled.ul`
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  row-gap: 30px;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 12px;
`;

const StyledImageContainer = styled.div`
  width: 80px;
  height: 80px;
  border: 2px solid #695edd;
  box-sizing: border-box;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #000000;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 18px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledSubTextInfo = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  text-transform: capitalize;
  color: #9b9b9b;
`;
