import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Skeleton } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import moment from 'moment';
import { ChatNotificationStore } from '../../../utils/notifications';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { NotificationItem } from './NotificationItem';
import { CustomPagination } from '../Common/CustomPagination';
import { DEFAULT_LIMIT, DEFAULT_PAGE, STATUS_ACTIVE } from '../../../constants';
import { getChatNotificationsData } from '../../../store/features/notifications';
import { userProfileStore } from '../../../utils/profile';
import SocketContext from '../../../context/socket';
import { updateChatNotifications } from '../../../service/chat';
import {
  NotificationsType,
  NOTIFICATION_CLICK_ACTION,
} from '../../../constants/notificationsType';
import { getRoleId } from '../../../utils/userInfo';

export const ChatNotifications: React.FC<any> = () => {
  const roleId = getRoleId();

  const dispatch = useDispatch();
  const {
    isChatLoading: isLoading,
    chatError: error,
    chatNotifications: notifications,
    chatPagination: pagination,
  } = ChatNotificationStore();
  const location = useLocation();
  const [currentPage, setCurrentPage] = React.useState(DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = React.useState(DEFAULT_LIMIT);
  const [notificationList, setNotificationList] = useState<any>([]);
  const client = useContext(SocketContext);
  const { profileData } = userProfileStore();
  const [firstFetch, setFirstFetch] = useState(true);

  const onPageChangeHandler = (
    pageNumber: number,
    pageSize: number | undefined,
  ) => {
    if (pageNumber !== currentPage) setCurrentPage(pageNumber);
    if (pageSize && pageSize !== currentLimit) setCurrentLimit(pageSize);
  };

  useEffect(() => {
    const mappedData = notifications.map((item: any) => {
      const type = get(item, 'chatRoom.type', 'group');
      const isGroup = type === 'group';
      return {
        isViewed: get(item, 'isViewed', ''),
        title: `${get(item, 'notifications')} new messages${
          isGroup ? ` in ${get(item, 'chatRoom.name', '')}` : ''
        }.`,
        text: isGroup
          ? ''
          : `${get(item, 'sender.name', '')} has sent ${get(
              item,
              'notifications',
            )} new messages.`,
        dateTime: moment(get(item, 'latestNotificationTimestamp')).unix(),
        category: get(item, 'type', NotificationsType.Chat),
        action: get(item, 'action', NOTIFICATION_CLICK_ACTION.chat),
        chatRoom: get(item, 'chatRoom', ''),
        isGroup,
      };
    });
    setNotificationList(mappedData);
  }, [notifications]);

  const params = new URLSearchParams(location.search);

  let type: any = params.get('type');
  if (!type) {
    type = '0';
  }

  useEffect(() => {
    if (!firstFetch) {
      // eslint-disable-next-line radix
      if (currentPage === DEFAULT_PAGE) {
        dispatch(getChatNotificationsData(currentPage, currentLimit));
      } else {
        setCurrentPage(DEFAULT_PAGE);
      }
    }
    // eslint-disable-next-line
  }, [type]);

  const updateNotification = () => {
    let newNotifications: any[] = [];

    newNotifications =
      notificationList && notificationList.length
        ? notificationList
            .filter(
              (notification: any) => notification.isViewed !== STATUS_ACTIVE,
            )
            .map((notification: any) => get(notification, 'chatRoom._id'))
        : [];

    if (newNotifications.length > 0) {
      setTimeout(() => {
        updateChatNotifications({
          chatRoomIds: newNotifications,
        }).then(() => {
          dispatch(getChatNotificationsData(currentPage, currentLimit, true));
        });
      }, 2000);
    }
  };

  useEffect(() => {
    updateNotification();
    // eslint-disable-next-line
  }, [notificationList, dispatch]);

  useEffect(() => {
    if (client && profileData.userId) {
      (async () => {
        await client.subscribe(
          `/chat/notification/${profileData.userId}/`,
          (resp: any) => {
            if (currentPage === DEFAULT_PAGE) {
              const type = get(resp, 'chatRoom.type', 'group');
              const isGroup = type === 'group';
              setNotificationList([
                {
                  isViewed: get(resp, 'isViewed', STATUS_ACTIVE),
                  title: `New message ${
                    isGroup
                      ? ` in ${get(resp, 'chatRoom.name', '')}`
                      : ` from ${get(resp, 'sender.name')}`
                  }`,
                  text: isGroup ? '' : `${get(resp, 'text', '')}`,
                  dateTime: moment(get(resp, 'timestamp')).unix(),
                  category: get(resp, 'type', NotificationsType.Chat),
                  action: get(resp, 'action', NOTIFICATION_CLICK_ACTION.chat),
                  chatRoom: get(resp, 'chatRoom', ''),
                  isGroup,
                },
                ...notificationList,
              ]);
              updateNotification();
            }
          },
        );
      })();
    }

    // eslint-disable-next-line
  }, [profileData.userId, client]);

  useEffect(() => {
    dispatch(getChatNotificationsData(currentPage, currentLimit));
    setFirstFetch(false);

    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  return (
    <Col xs={24}>
      {isLoading || error ? (
        <Skeleton />
      ) : notificationList.length ? (
        <>
          <StyledColContainerItems xs={24}>
            {notificationList.map((item: any, i: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <NotificationItem
                key={i}
                notification={item}
                profileData={profileData}
                roleId={roleId}
                isChat
              />
            ))}
          </StyledColContainerItems>
          <StyledPagination>
            <CustomPagination
              currentPage={currentPage}
              totalItems={get(
                pagination,
                'totalItems',
                notificationList.length,
              )}
              currentLimit={currentLimit}
              onPageChange={onPageChangeHandler}
            />
          </StyledPagination>
        </>
      ) : (
        <p>No Chat Notifications</p>
      )}
    </Col>
  );
};

export default ChatNotifications;

const StyledColContainerItems = styled(Col)`
  height: 55vh;
  overflow-y: auto;
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 60vh;
  }
`;

const StyledPagination = styled.div`
  ${DISPLAY_FLEX('row', 'flex-end')};

  width: 100%;
  margin: 20px 0;
`;
