import { useState, useCallback } from 'react';

const useSignupOrLogin = (modalRef) => {
  const [showModal, setShowModal] = useState(false);
  modalRef.current = () => setShowModal(true);
  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModalWithoutClearingStorage = useCallback(() => {
    setShowModal(false);
  }, []);

  const closeModal = useCallback(() => {
    if (localStorage.getItem('appliedJob'))
      localStorage.removeItem('appliedJob');
    setShowModal(false);
  }, []);

  return {
    openSignInModal: openModal,
    closeSignInModal: closeModal,
    isSignInModalVisible: showModal,
    closeModalWithoutClearingStorage,
  };
};

export { useSignupOrLogin };
