import React from 'react';
import isArray from 'lodash/isArray';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { EventsStore, getActiveBookingEvents } from '../../../utils/event';
import {
  getRoleId,
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
  checkIfUserIsOrganizer,
} from '../../../utils/userInfo';
import { CandidateRoutes, RecruiterRoutes } from '../../../constants/routes';
import { EventCard } from '../../EventCard';
import { StyledMainHeader } from '../../shared-components/Event/Common';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { MARGIN } from '../../../styles/constants/display';
import { FONT } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';

const PastEvents = ({
  search = '',
  noTimeline = null,
  noTitle = null,
  handleModalOpen,
}) => {
  const { pastEvents } = EventsStore();
  const roleId = getRoleId();

  const events = checkIfUserIsOrganizer(roleId)
    ? pastEvents
    : getActiveBookingEvents(pastEvents, false, roleId);

  if (!isArray(events) || events.length === 0) {
    return (
      <Row>
        <Col>
          {!noTitle && (
            <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
              Past Events
            </StyledMainHeader>
          )}
          <StyledPlaceholderText>
            {checkIfUserIsCandidate(roleId) ? (
              <StyledSpanText>
                You don't have any past events. Head to the{' '}
                <StyledSpanText>
                  <StyledCreateEventPathLink to={CandidateRoutes.exploreEvent}>
                    "Explore Events"
                  </StyledCreateEventPathLink>
                </StyledSpanText>{' '}
                page to book events and find exciting jobs.
              </StyledSpanText>
            ) : checkIfUserIsRecruiter(roleId) ? (
              <StyledSpanText>
                You don't have any past events. Head to the{' '}
                <StyledCreateEventPathLink to={RecruiterRoutes.exploreEvent}>
                  "Explore Events"
                </StyledCreateEventPathLink>
              </StyledSpanText>
            ) : (
              <StyledSpanText>
                You don't have any past events. Head to the{' '}
                <StyledSpanText>
                  <StyledCreateEventPathLink onClick={handleModalOpen}>
                    “Create Event”
                  </StyledCreateEventPathLink>
                </StyledSpanText>{' '}
                page and schedule a new event now!
              </StyledSpanText>
            )}
          </StyledPlaceholderText>
        </Col>
      </Row>
    );
  }

  const pastEventsFilteredList =
    events.length > 0
      ? events.filter((event) =>
          event.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

  return (
    <StyledMainContainer noTitle={noTitle}>
      {!noTitle && (
        <StyledMainHeader
          color={getUserColorWithRoleId(roleId)}
          id="past_event_title"
        >
          Past Events
        </StyledMainHeader>
      )}
      <EventCard
        events={pastEventsFilteredList}
        isEventOver
        category="past"
        noTimeline
      />
    </StyledMainContainer>
  );
};

export default PastEvents;

const StyledMainContainer = styled.div`
  ${({ noTitle }) => (!noTitle ? MARGIN(25, 0) : MARGIN(15, 0))}
`;

// const StyledPlaceholderWrapper = styled(Col)`
//   margin-top: 15px;
// `;

const StyledPlaceholderText = styled.div`
  ${MARGIN(12, 0, 0, 0)}
`;

const StyledSpanText = styled.span`
  color: ${COLORS.GRAY};
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledCreateEventPathLink = styled.span`
  color: ${COLORS.DODGER_BLUE} !important;
  cursor: pointer !important;
  font-size: ${FONT[16]};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;
