import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  Password,
  FormGroup,
  Submit,
  Email,
} from '../components/shared-components/Form/Form';
import LoginImage from '../assets/placeholders/hey.svg';
import { adminLoginUser } from '../store/features/auth';
import { DISPLAY_FLEX, PADDING } from '../styles/constants/display';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import { SHADOWS } from '../styles/constants/shadows';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  checkIfUserIsSuperAdmin,
} from '../utils/userInfo';
import { getProfileData } from '../store/features/profile';

const AdminLogin = ({ history }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, user: userData } = useSelector(
    (state) => state.auth,
  );

  const onFinish = ({ username, password }) => {
    dispatch(adminLoginUser(username, password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      const { roleId } = userData;
      if (checkIfUserIsSuperAdmin(roleId)) history.push('/super-admin/');
      else if (checkIfUserIsMasterAdmin(roleId)) history.push('/master-admin/');
      else if (sessionStorage.userInfo || localStorage.userInfo) {
        dispatch(getProfileData()).then(() => {
          const { roleId: userRole } = userData;
          const crisp = document.getElementById('crisp-chatbox');
          if (crisp) {
            crisp.outerHTML = '';
            crisp.remove();
          }
          if (checkIfUserIsOrganizer(userRole)) history.push(`/organizer`);
          else if (checkIfUserIsRecruiter(userRole)) history.push(`/recruiter`);
          else if (checkIfUserIsCandidate(userRole)) history.push(`/candidate`);
        });
      }
    }

    // eslint-disable-next-line
  }, [isAuthenticated, history, userData]);

  return (
    <StyledWrapper>
      <StyledImageContainer>
        <StyledLoginImage src={LoginImage} alt="login_image" />
      </StyledImageContainer>
      <StyledCardWrapper>
        <StyledCard hoverable title="Recorem Admin Portal">
          <FormGroup
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            expand
          >
            <Email
              prefix={<UserOutlined />}
              placeholder="Email"
              name="username"
            />
            <Password
              prefix={<LockOutlined />}
              placeholder="Password"
              name="password"
            />
            <StyledSubmitContainer>
              <Submit />
            </StyledSubmitContainer>
          </FormGroup>
        </StyledCard>
      </StyledCardWrapper>
    </StyledWrapper>
  );
};

export default AdminLogin;

const StyledSubmitContainer = styled.div`
  ${DISPLAY_FLEX('row', ' center', 'center')}
`;

const StyledCard = styled(Card)`
  width: 400px;
  box-shadow: ${SHADOWS.SHADOW4};
`;

const StyledCardWrapper = styled.div`
  ${PADDING(100)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0)}
  }
`;

const StyledWrapper = styled.div`
  height: 100vh;
  ${DISPLAY_FLEX('row', ' center', 'center')}
  ${PADDING(100)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${DISPLAY_FLEX('column', ' center', 'center')}
  }
`;

const StyledImageContainer = styled.div`
  height: 500px;
  width: 500px;
  ${DISPLAY_FLEX('row', ' center', 'center')}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const StyledLoginImage = styled.img`
  width: 500px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;
