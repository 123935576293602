import React from 'react';
import { Redirect } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout/MainLayout';
import AdminLogin from '../containers/AdminLogin';
import SuperAdmin from '../containers/SuperAdmin';
import MasterAdmin from '../containers/MasterAdmin';
import Login from '../containers/LandingPage';
import Organizer from '../containers/Organizer';
import Recruiter from '../containers/Recruiter';
import Candidate from '../containers/Candidate';
import CommingSoon from '../containers/CommingSoon';
import EmailVerifyModal from '../containers/login-signup/EmailVerifyModal';
import ResetPassMoadal from '../containers/login-signup/ResetPassMoadal';
import SignupInviteModal from '../containers/login-signup/SignupInviteModal';
import { RecruiterRoutes, Routes } from '../constants/routes';
import ViewEventPublic from '../components/Events/ViewEvent/ViewEventPublic';
import PageNotFound from '../containers/PageNotFound';
import CreatePassword from '../containers/login-signup/CreatePassword';
import InviteHandler from '../containers/login-signup/InviteHandler';
import MailUnsubscribeModal from '../containers/login-signup/MailUnsubscribeModal';
import Unsubscribe from '../containers/Unsubscribe';
import PricingPlan from '../containers/PricingPlan';
import CommunityPage from '../containers/Community';

const wrapMainComponent = (Component) => () => {
  return (
    <MainLayout>
      <Component />
    </MainLayout>
  );
};

export const protectedRoutes = [
  { path: Routes.superAdmin, component: wrapMainComponent(SuperAdmin) },
  { path: Routes.masterAdmin, component: wrapMainComponent(MasterAdmin) },
  { path: Routes.organizer, component: wrapMainComponent(Organizer) },
  { path: Routes.recruiter, component: wrapMainComponent(Recruiter) },
  { path: Routes.candidate, component: wrapMainComponent(Candidate) },
  { path: RecruiterRoutes.pricingPlan, component: PricingPlan },
];

export const publicRoutes = [
  { path: Routes.adminLogin, component: AdminLogin },
  { path: Routes.login, component: Login },
  { path: Routes.signupInvite, component: SignupInviteModal },
  { path: Routes.verify, component: EmailVerifyModal },
  { path: Routes.createPassword, component: CreatePassword },
  {
    path: Routes.unsubscribeInviteMail,
    exact: true,
    component: MailUnsubscribeModal,
  },
  {
    path: Routes.communityPage,
    exact: true,
    component: CommunityPage,
  },
  { path: Routes.inviteEvent, exact: true, component: InviteHandler },
  { path: Routes.resetPassword, component: ResetPassMoadal },
  { path: Routes.commingSoon, component: CommingSoon },
  { path: Routes.viewPublicEvent, component: ViewEventPublic },
  { path: Routes.unsubscribe, component: Unsubscribe },
  { path: '/', exact: true, component: () => <Redirect to="/login" /> },
  { path: '*', component: PageNotFound },
];
