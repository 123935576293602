import React from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { InfoCircleFilled } from '@ant-design/icons';
import { COLORS } from '../../styles/constants/colors';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const CreditInfoDropdown = () => {
  return (
    <Dropdown overlay={<TextOverlay />} trigger={['click', 'hover']}>
      <StyledInfoCircleIcon style={{ color: COLORS.LIGHT_GRAY_2 }} />
    </Dropdown>
  );
};

export default CreditInfoDropdown;

const TextOverlay = () => {
  return (
    <StyledTextInfoContainer>
      <p>
        <span>Incoming Credits:</span> Earned credits for applying to jobs,
        you’ll receive them once the event ends and your verification is
        complete.
      </p>
      <p>
        <span>Withdrawable Credits:</span> Earned credits for applying to jobs.
        Click withdraw to claim.
      </p>
    </StyledTextInfoContainer>
  );
};

const StyledInfoCircleIcon = styled(InfoCircleFilled)`
  color: ${COLORS.LIGHT_GRAY_2};
  > svg {
    font-size: ${FONT[13]};
  }
`;

const StyledTextInfoContainer = styled.div`
  max-width: 300px;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  ${PADDING(5)};
  p {
    font-size: ${FONT[14]};
    ${MARGIN(10)}
    color : ${COLORS.DARK_GRAY};
    span {
      font-size: ${FONT[14]};
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      color: ${COLORS.BLACK};
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    position: relative;
    left:40px;
  }
`;
