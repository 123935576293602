import React, { useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import '../../styles/components/CrewManagement/crew-member-profile-modal.scss';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getThemeByRoleId } from '../../utils/common';
import { getRoleId } from '../../utils/userInfo';
import { PrimaryButton } from '../shared-components/Button';
import {
  ExtraLargeHeading,
  StyledMediumHeading,
} from '../shared-components/Texts';
import { EditIcon } from '../svgicons';
import CrewMemberEditProfileModal from './CrewMemberEditProfileModal';
import { HiddenMobile } from '../shared-components/DocumentViewerModal';
import { ShowMobile } from '../shared-components/common';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import WalletButton from '../Common/WalletButton';

const CrewMemberProfileHeader = (props) => {
  const [visible, setVisible] = useState(false);

  const handleModalCancel = () => setVisible(false);
  const roleId = getRoleId();

  const { data, profilePic, setProfilePic, companyData, members } = props;

  return (
    <>
      <ExtraLargeHeading fontWeight={FONT_WEIGHTS.SEMI_BOLD} margin="15px 0">
        Hey, {get(companyData, 'name', '')}
      </ExtraLargeHeading>
      <StyledHeadingContainer>
        <StyledMediumProfileHeading>Profile</StyledMediumProfileHeading>
        <StyledConnectButtonWrapper>
          <WalletButton
            isEditProfile
            editProfileTheme={getThemeByRoleId(roleId)}
          />
        </StyledConnectButtonWrapper>
        <HiddenMobile>
          <PrimaryButton
            theme={getThemeByRoleId(roleId)}
            onClick={() => setVisible(true)}
            id="crew_member_profile_edit"
          >
            Edit Profile
          </PrimaryButton>
        </HiddenMobile>
        <ShowMobile>
          <StyledIcon
            color={getUserColorWithRoleId(roleId)}
            onClick={() => setVisible(true)}
          >
            <EditIcon />
          </StyledIcon>
        </ShowMobile>
      </StyledHeadingContainer>
      {visible ? (
        <CrewMemberEditProfileModal
          visible={visible}
          handleModalCancel={handleModalCancel}
          data={data}
          profilePic={profilePic}
          setProfilePic={setProfilePic}
          members={members}
        />
      ) : null}
    </>
  );
};

export default CrewMemberProfileHeader;

const StyledMediumProfileHeading = styled(StyledMediumHeading)`
  color: ${COLORS.PHILLIPPINE_GRAY};
  margin-top: 15px;
`;

const StyledHeadingContainer = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
  ${MARGIN(-25, 0, 0, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(-15, 0, 0, 0)}
  }
`;

const StyledIcon = styled.div`
  margin-right: 5px;
  z-index: 1;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  ${DISPLAY_FLEX('row', 'center', 'center')}
  ${PADDING(8)}
  
  svg {
    width: 22px;
    fill: white;
  }
`;

export const StyledConnectButtonWrapper = styled.div`
  margin-left: auto;
`;
