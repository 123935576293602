/**
 * Takes following argument and returns a promise
 * @param {number} interval
 * @returns {Promise}
 */
const wait = (interval) => {
  return new Promise((resolve) => setTimeout(resolve, interval));
};

/**
 * Takes following arguments and returns the loaded component else retries and throw error at last
 * @param {function} fn
 * @param {number} retriesLeft
 * @param {number} interval
 * @returns {ReactNode} react component
 */
export const safeLoad = async (fn, retriesLeft = 3, interval = 200) => {
  try {
    return await fn();
  } catch (error) {
    await wait(interval);
    if (retriesLeft === 0) {
      throw new Error(error);
    }
    // eslint-disable-next-line no-plusplus
    return safeLoad(fn, --retriesLeft, interval);
  }
};
