import React, { memo } from 'react';
import { Row, Col, message } from 'antd';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import CountryCodeInput from '../Common/CountryCodeInput';
import MandatoryStar from '../Common/mandatoryStar';
import {
  FormGroup,
  TextBox,
  InputTextArea,
  Email,
} from '../shared-components/Form/Form';
import {
  TextUploadFileList,
  PictureUploadWithList,
  ToolTippedInfo,
} from '../shared-components/Upload';
import {
  ADD,
  ORGANIZER,
  RECRUITER,
  CANDIDATE,
  CROP_COVER_OPTIONS,
  CROP_POFILE_PIC_OPTIONS,
} from '../../constants';
import {
  updateCompanyLogo,
  countryCodeOnlySplit,
  profileLinksPayload,
  isAdmin,
  updateCompanyCoverCustomReq,
  companyCoverOnChange,
  handleCoverBeforeUpload,
  getEditDomainsData,
} from '../../utils/profile';
import { UploadPictureNotification } from '../Notification/UploadPictureNotification';
import {
  updateProfileData,
  updateMemberProfileData,
} from '../../store/features/profile';
import AddressAutoComplete from '../Common/AddressAutoComplete';
import IndustryTypes from '../Common/IndustryTypes';
import ProfilesLinksList from '../ProfileLinks/ProfileLinksList';
import { checkIfUserIsOrganizer } from '../../utils/userInfo';
import { userIsCrewMember } from '../../utils/crew';
import {
  ACCEPTED_PROFILE_IMAGE,
  ACCEPTED_IMAGE_EXTENSIONS,
  IMAGE_PROFILE_PAYLOAD,
  COVER_IMAGE_HINT,
  LOGO_IMAGE_HINT,
} from '../../constants/file';
import Notification from '../Notification/Notification';
import { imageValidator, getTheme } from '../../utils/common';
import { getDefaultPaymentCurrency } from '../../utils/currencyConvertor';
import { DISPLAY, MARGIN, PADDING } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import {
  largeFontMixin,
  largeHeadingMixin,
} from '../../styles/constants/mixins';

const CompanyProfileUIBox = ({
  roleId,
  step,
  setStep,
  profile,
  setProfile,
  profileData,
  form,
  companyLogo,
  setCompanyLogo,
  setCompanyCover,
  companyCover,
  dispatch,
  history,
  isForTest,
  userType,
}) => {
  const isCrewMember = userIsCrewMember(profileData);

  const companyData = get(profileData, 'company', {});
  const { currencyData } = useSelector((st) => st.commons);
  const initialValueCompanyDetails =
    isCrewMember && companyData
      ? {
        companyName: get(companyData, 'name', '-'),
        companyEmail: get(
          profileData,
          'companyAdmin.representativeEmail',
          '',
        ),
        companyPhoneNumberPrefix: companyData.phone
          ? companyData.phone.split(' ')[0]
          : '+91',
        companyPhoneNumber: companyData.phone
          ? companyData.phone.split(' ')[1]
          : '',
        industryType: companyData.domain,
        companyWebsite: companyData.website,
        description: companyData.brief,
        communityOutreachMessage: companyData.communityOutreachMessage,
        address: companyData.address,
        city: companyData.city,
        state: companyData.state,
        country: companyData.country,
        zipcode: companyData.zip,
        suiteNumber: companyData.suite || 0,
      }
      : {
        companyName: get(companyData, 'name', '-'),
        companyEmail: profileData.representativeEmail,
        companyWebsite: get(companyData, 'website', ''),
      };

  const companyLogoOnChange = (info) => {
    const { file } = info;
    if (file.status === 'removed') {
      setCompanyLogo([]);
    }
    UploadPictureNotification(info);
  };

  const getUserClassName = () => {
    return checkIfUserIsOrganizer(roleId)
      ? 'organizer-error'
      : 'recruiter-error';
  };

  const updateCompanyLogoCustomReq = ({ onSuccess, onError, file }) => {
    // Validation
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      const loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = loadedImageUrl;
      image.addEventListener('load', () => {
        const { width, height } = image;
        // set image width and height to your state here
        // If image widht or height doesn't match minimum requirements
        if (
          width < ACCEPTED_PROFILE_IMAGE.minWidth ||
          height < ACCEPTED_PROFILE_IMAGE.minHeight
        ) {
          Notification(
            'error',
            'Profile image',
            'Image doesn’t match the requirements. Please select another image.',
          );
        } else if (!ACCEPTED_PROFILE_IMAGE.fileTypes.includes(file.type)) {
          Notification(
            'error',
            'Profile image',
            'Image doesn’t match the requirements. Please select another image.',
          );
        } else {
          setCompanyLogo([file]);
          updateCompanyLogo(file)
            .then((fileName) => {
              onSuccess();
            })
            .catch((err) => {
              onError();
            });
        }
      });
    });
  };

  const handleProfileBeforeUpload = (file) => {
    if (file.size > ACCEPTED_PROFILE_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Profile image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({ ...IMAGE_PROFILE_PAYLOAD, file }).catch(
      () => false,
    );
  };

  const handleCompanyProfSubmit = ({
    companyName,
    companyPhoneNumberPrefix = ' ',
    companyPhoneNumber = ' ',
    companyEmail,
    industryType = '-',
    communityOutreachMessage = '-',
    companyWebsite,
    description = '-',
    address = '-',
    city = '-',
    state = '-',
    country = '-',
    zipcode = '-',
    suiteNumber = 0,
    profileLinks = [],
    paymentCurrencyId = get(
      getDefaultPaymentCurrency(currencyData),
      'id',
      null,
    ),
  }) => {
    form.validateFields().then((values) => {
      let body = {};
      if (
        (!isArray(profileLinks) || profileLinks.length === 0) &&
        !isCrewMember
      ) {
        message.error({
          content: 'Please add company profile links.',
          className: getUserClassName(),
        });

        return;
      }
      body = {
        ...profile,
        name: isAdmin(profileData)
          ? get(profile, 'representativeName', '-')
          : '',
        company: {
          name: initialValueCompanyDetails.companyName || companyName,
          brief: initialValueCompanyDetails.description || description,
          phone: `${countryCodeOnlySplit(
            initialValueCompanyDetails.companyPhoneNumberPrefix ||
            companyPhoneNumberPrefix,
          )} ${initialValueCompanyDetails.companyPhoneNumber ||
          companyPhoneNumber ||
          ''
            }`,
          address: initialValueCompanyDetails.address || address,
          city: initialValueCompanyDetails.city || city,
          state: initialValueCompanyDetails.state || state,
          country: initialValueCompanyDetails.country || country,
          zip: initialValueCompanyDetails.zipcode || zipcode,
          suite: initialValueCompanyDetails.suiteNumber || suiteNumber,
          ...(checkIfUserIsOrganizer(roleId)
            ? {
              communityOutreachMessage:
                initialValueCompanyDetails.communityOutreachMessage ||
                communityOutreachMessage,
            }
            : {}),
          domain:
            initialValueCompanyDetails.industryType || industryType.toString(),
          website: companyWebsite || initialValueCompanyDetails.companyWebsite,
          profileLinks: isCrewMember
            ? profileData.company.profileLinks
              ? profileData.company.profileLinks.links
              : []
            : profileLinksPayload(profileLinks),
        },
        domains: isCrewMember
          ? profileData.domains
            ? getEditDomainsData(profileData.domains)
            : []
          : [{ name: industryType.toString(), operation: ADD }],
        phone: `${countryCodeOnlySplit(companyPhoneNumberPrefix)} ${companyPhoneNumber || ''
          }`,
        city: initialValueCompanyDetails.city || city,
        state: initialValueCompanyDetails.state || state,
        country: initialValueCompanyDetails.country || country,
        zip: initialValueCompanyDetails.zipcode || zipcode,
        nationality: initialValueCompanyDetails.country || country,
        paymentCurrencyId,
      };

      if (checkIfUserIsOrganizer(roleId)) {
        body = {
          ...body,
          profileLinks: isCrewMember
            ? profileData.company.profileLinks
              ? profileData.company.profileLinks.links
              : []
            : profileLinksPayload(profileLinks),
        };
      }

      if (roleId !== CANDIDATE && !isAdmin(profileData)) {
        const payload = {
          email: profile.email,
          memberId: profileData.companyMember.userId,
          name: profile.name,
          phone: profile.phone,
        };
        dispatch(updateMemberProfileData(payload)).then(() => {
          // Iterate over the properties & delete selected
          Object.keys(body).forEach((key) => {
            if (['name', 'email'].includes(key)) {
              delete body[key];
            }
          });

          dispatch(updateProfileData(body)).then(() => {
            if (roleId === ORGANIZER) history.push('/organizer/profile/');
            else if (roleId === RECRUITER) history.push('/recruiter/profile/');
          });
        });
      } else {
        dispatch(updateProfileData(body)).then(() => {
          if (roleId === ORGANIZER) history.push('/organizer/profile/');
          else if (roleId === RECRUITER) history.push('/recruiter/profile/');
        });
      }
    });
  };

  const color = getTheme(userType);
  const idBase = `company_profile_box`;

  return (
    <StyledContainer>
      <StyledLevelThreeHeading
        color={color}
        className="content_title mb35 large-heading"
        id={`${idBase}_title`}
      >
        Company Profile
      </StyledLevelThreeHeading>

      <FormGroup
        form={form}
        name="OrganizerCompanyInformationModal"
        onFinish={handleCompanyProfSubmit}
        expand
        initialValues={initialValueCompanyDetails}
      >
        <StyledFormInputsContainer>
          <StyledLevelFiveHeading>Basic Information</StyledLevelFiveHeading>
          {/* comapny-info-sec */}
          <StyledRow gutter={[25, 10]}>
            <Col xs={24} lg={8}>
              <StyledCompanyName htmlFor="company_name">
                Company Name <MandatoryStar />
              </StyledCompanyName>
              <TextBox
                disabled
                name="companyName"
                placeholder="Company Name"
                rule="text"
                required
                id="company_name"
              />
            </Col>
            {!isCrewMember ? (
              <Col xs={24} lg={7}>
                <StyledGenericlabel htmlFor="office_number">
                  Office Number
                </StyledGenericlabel>
                <Row>
                  <Col xs={8} lg={8}>
                    <CountryCodeInput
                      name="companyPhoneNumberPrefix"
                      disabled={isCrewMember}
                      required={false}
                    />
                  </Col>
                  <Col xs={16} lg={16}>
                    <TextBox
                      name="companyPhoneNumber"
                      placeholder="Phone Number"
                      disabled={isCrewMember}
                      maxLength={12}
                      rule="phoneNumber"
                      id="office_number"
                      required={false}
                    />
                  </Col>
                </Row>
              </Col>
            ) : null}

            <Col xs={24} lg={7}>
              <StyledGenericlabel htmlFor="company_email">
                Company Email Address <MandatoryStar />
              </StyledGenericlabel>
              <Email
                name="companyEmail"
                placeholder="Company Email"
                disabled
                required
                id="company_email"
              />
            </Col>
          </StyledRow>
          <Row gutter={[25, 10]}>
            {!isCrewMember ? (
              <Col xs={24} lg={8}>
                <StyledGenericlabel htmlFor="industry_type_company_profile_box">
                  Industry Type <MandatoryStar />
                </StyledGenericlabel>
                <IndustryTypes
                  disabled={isCrewMember}
                  required={!isCrewMember}
                  mode="multiple"
                  showArrow
                  id="industry_type_company_profile_box"
                />
              </Col>
            ) : null}
            <Col xs={24} lg={7}>
              <StyledGenericlabel htmlFor="company_website">
                Company Website <MandatoryStar />
              </StyledGenericlabel>
              <TextBox
                name="companyWebsite"
                placeholder="Company Website"
                rule="companyWebsite"
                disabled={isCrewMember}
                required
                id="company_website"
              />
            </Col>
          </Row>
          {!isCrewMember ? (
            <Row gutter={[25, 10]}>
              <Col xs={24} lg={8}>
                <>
                  <StyledGenericlabel htmlFor="about_company">
                    About Company <MandatoryStar />
                  </StyledGenericlabel>
                  <InputTextArea
                    name="description"
                    placeholder="Description"
                    autoSize={{ minRows: 8, maxRows: 8 }}
                    disabled={isCrewMember}
                    maxLength={250}
                    showCount
                    required={!isCrewMember}
                    id="about_company"
                  />
                </>
              </Col>
              {checkIfUserIsOrganizer(roleId) ? (
                <Col xs={24} lg={7}>
                  <>
                    <StyledGenericlabel htmlFor="community_outreach">
                      Community Outreach Message{' '}
                      <ToolTippedInfo
                        backgroundColor={color}
                        hintText="This message will be shown in your events community access section."
                        position="absolute"
                        top="2px"
                        margin="0 0 0 5px"
                      />
                    </StyledGenericlabel>
                    <InputTextArea
                      name="communityOutreachMessage"
                      placeholder="Community Outreach Message"
                      autoSize={{ minRows: 8, maxRows: 8 }}
                      disabled={isCrewMember}
                      maxLength={250}
                      showCount
                      required={false}
                      id="community_outreach"
                    />
                  </>
                </Col>
              ) : null}
            </Row>
          ) : null}
          {!isCrewMember ? (
            <StyledAdditionalContainer>
              <StyledLevelFiveHeading>Company Address</StyledLevelFiveHeading>

              {/* Note: this component is being skipped for testing as it's recommeded to test this separately. It's also getting break when testing. */}
              {!isForTest ? (
                <StyledAddressContiner>
                  <AddressAutoComplete
                    form={form}
                    required={!isCrewMember}
                    disabled={isCrewMember}
                  />
                </StyledAddressContiner>
              ) : null}

              {/* upload official */}
              {isCrewMember ? null : (
                <>
                  <StyledUploadLabel htmlFor="official_company_logo">
                    Upload Company Logo
                  </StyledUploadLabel>
                  <div>
                    <TextUploadFileList
                      name="uploadResume"
                      className="resume-upload upload_btn"
                      roleId={roleId}
                      icon={<UploadOutlined />}
                      accept={ACCEPTED_IMAGE_EXTENSIONS}
                      customRequest={updateCompanyLogoCustomReq}
                      onChange={companyLogoOnChange}
                      beforeUpload={handleProfileBeforeUpload}
                      showUploadList
                      fileList={companyLogo}
                      onBoard
                      maxCount={1}
                      id="official_company_logo"
                      hintText={LOGO_IMAGE_HINT}
                      cropActive
                      cropOptions={CROP_POFILE_PIC_OPTIONS}
                    />
                  </div>
                </>
              )}
            </StyledAdditionalContainer>
          ) : null}

          {checkIfUserIsOrganizer(roleId) && !isCrewMember ? (
            <>
              <StyledUploadLabel>Upload Company Cover Pic</StyledUploadLabel>
              <div>
                <PictureUploadWithList
                  name="companyCover"
                  className="resume-upload upload_btn"
                  roleId={roleId}
                  icon={<UploadOutlined />}
                  accept={ACCEPTED_IMAGE_EXTENSIONS}
                  customRequest={({ onSuccess, onError, file }) =>
                    updateCompanyCoverCustomReq(
                      onSuccess,
                      onError,
                      file,
                      setCompanyCover,
                    )
                  }
                  onChange={(info) =>
                    companyCoverOnChange(info, setCompanyCover)
                  }
                  beforeUpload={handleCoverBeforeUpload}
                  showUploadList
                  fileList={companyCover}
                  onBoard
                  maxCount={1}
                  cropOptions={CROP_COVER_OPTIONS}
                  cropActive
                  hintText={COVER_IMAGE_HINT}
                />
              </div>
            </>
          ) : null}

          {isCrewMember ? null : (
            <StyledProfileLinksContainer>
              <ProfilesLinksList customize form={form} />
            </StyledProfileLinksContainer>
          )}
        </StyledFormInputsContainer>
        {/* Steps-action buttons */}
        <StyledAdditionalContainer>
          <Row>
            <Col xs={12} lg={12}>
              <StyledPrevButton color={color} onClick={() => setStep(step - 1)}>
                Previous
              </StyledPrevButton>
            </Col>
            <StyledCol xs={12} lg={12}>
              <StyledButton color={color}>Save & Continue</StyledButton>
            </StyledCol>
          </Row>
        </StyledAdditionalContainer>
      </FormGroup>
    </StyledContainer>
  );
};

export default memo(CompanyProfileUIBox);

const StyledContainer = styled.div`
  overflow: auto;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  ${PADDING(35, 30, 30, 30)};
  ${MARGIN(0, 0, 40)};
  background-color: ${COLORS.WHITE};
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(14, 13, 30, 13)};
    ${MARGIN(20, 0, 0)};
    z-index: 11;
    height: auto;
    overflow: unset;
  }
`;

const StyledLevelThreeHeading = styled.h3`
  font-size: ${FONT[18]};
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 21px;
  ${MARGIN(0, 0, 35)};
  ${largeHeadingMixin}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 30, 0)};
  }
`;

const StyledFormInputsContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 0, 0, 0)};
  }
`;

const StyledProfileLinksContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 50px;
  }
`;

const StyledLevelFiveHeading = styled.h5`
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  line-height: 19px;
  ${MARGIN(0, 0, 22)};
  font-size: 14px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledRow = styled(Row)`
  align-items: center;
`;

const StyledUploadLabel = styled.label`
  display: ${DISPLAY.BLOCK};
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${MARGIN(35, 0, 16)};
  font-size: 14px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledCompanyName = styled.label`
  display: ${DISPLAY.BLOCK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  ${MARGIN(0, 0, 16)}
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 12)}
  }
`;

const StyledGenericlabel = styled.label`
  display: ${DISPLAY.BLOCK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.CHINESE_BLACK};
  ${MARGIN(0, 0, 16)}

  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 12)};
  }
`;

const StyledAdditionalContainer = styled.div`
  ${MARGIN(34, 0, 0)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(34, 0, 34, 0)};
  }
`;

const StyledCol = styled(Col)`
  text-align: right;
`;

const StyledPrevButton = styled.button`
  font-size: ${FONT[14]};
  height: 43px;
  width: 100%;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  background-color: ${COLORS.WHITE};
  line-height: 27px;
  max-width: 168px;
  cursor: pointer;
  ${largeFontMixin}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 90%;
    height: 40px;
  }
`;

const StyledButton = styled.button`
  font-size: ${FONT[14]};
  width: 100%;
  height: 43px;
  background-color: ${(props) => props.color};
  color: ${COLORS.WHITE};
  line-height: 27px;
  max-width: 168px;
  border: none;
  cursor: pointer;
  ${largeFontMixin}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 95%;
    height: 40px;
  }
`;
const StyledAddressContiner = styled.div`
  p {
    display: ${DISPLAY.BLOCK};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    color: ${COLORS.CHINESE_BLACK};
    ${MARGIN(0, 0, 16)};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${MARGIN(0, 0, 12)};
    }
  }
`;
