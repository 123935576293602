import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import RecoremLogo from '../assets/svg/recorem_color_logo.svg';
import WhiteLogoSymbol from '../assets/svg/WhiteLogoSymbol.svg';
import Vec from '../assets/svg/404vec.svg';
import errorVec from '../assets/svg/errorvec.svg';
import Text404 from '../assets/svg/404.svg';
import Text500 from '../assets/svg/error.svg';
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  InstagramIcon,
} from '../components/svgicons';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import { COLORS } from '../styles/constants/colors';
import {
  StyledExtraLargeHeading,
  StyledLargeHeading,
  StyledMediumHeading,
  ExtraLargeHeading,
} from '../components/shared-components/Texts';
import { ShowMobile } from '../components/shared-components/common';
import { DISPLAY_FLEX, PADDING } from '../styles/constants/display';
import { largeHeadingMixin } from '../styles/constants/mixins';
import { HiddenMobile } from '../components/shared-components/DocumentViewerModal';
import { PrimaryButton } from '../components/shared-components/Button';
import { getThemeByRoleId } from '../utils/common';
import { ADMIN } from '../constants';
import { FONT_WEIGHTS } from '../styles/constants/typography';
import { ERROR_PAGE_FILTER } from '../styles/constants/styles';

const hasErrorBoundaryStatus = (status) => {
  return status === '500';
};

const PageNotFound = ({
  resetErrorBoundary,
  subTitle = 'Page not found.!',
  status = '403',
}) => {
  return (
    <StyledContainer>
      <StyledLogoImage src={WhiteLogoSymbol} alt="right-logo" />
      <StyledInnerContainer>
        <Col md={12} xs={24}>
          <StyledLeftIcon>
            <img src={RecoremLogo} alt="" />
          </StyledLeftIcon>

          <StyledErrorDiv>
            <ExtraLargeHeading>{subTitle}</ExtraLargeHeading>
            <div>
              <PrimaryButton
                theme={getThemeByRoleId(ADMIN)}
                onClick={() => {
                  if (resetErrorBoundary) {
                    resetErrorBoundary();
                  }
                  window.history.back();
                }}
              >
                Go Back
              </PrimaryButton>
            </div>
          </StyledErrorDiv>

          <StyledLeftBottomTitle is500Error={hasErrorBoundaryStatus(status)}>
            <HiddenMobile>
              <img
                alt="events"
                src={hasErrorBoundaryStatus(status) ? Text500 : Text404}
              />
            </HiddenMobile>
          </StyledLeftBottomTitle>
        </Col>
        <StyledRightCol
          is500Error={hasErrorBoundaryStatus(status)}
          md={12}
          xs={24}
        >
          <img
            alt="events"
            id="vector"
            src={hasErrorBoundaryStatus(status) ? errorVec : Vec}
          />
          <ShowMobile>
            <img
              alt="events"
              id="text_404"
              src={hasErrorBoundaryStatus(status) ? Text500 : Text404}
            />
          </ShowMobile>
        </StyledRightCol>
      </StyledInnerContainer>
      <StyledFooter>
        <StyledExtraLargeHeadingCustom>Oops!</StyledExtraLargeHeadingCustom>
        <StyledLargeHeadingCustom>{subTitle}</StyledLargeHeadingCustom>
        <StyledSeperator />
        <StyledMediumHeadingCustom>Follow Us</StyledMediumHeadingCustom>
        <StyledSocialButtons>
          <a
            href="https://www.facebook.com/recoremofficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/recorem-com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon />
          </a>
          <a
            href="https://twitter.com/OfficialRecorem"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.instagram.com/recorem_official/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </StyledSocialButtons>
      </StyledFooter>
    </StyledContainer>
  );
};

export default PageNotFound;

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

const StyledFooter = styled(Row)`
  height: 30vh;
  background-color: ${COLORS.DODGER_BLUE};
  ${PADDING(35, 100)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 40vh;
    ${PADDING(20, 20)}
  }
`;

const StyledExtraLargeHeadingCustom = styled(StyledExtraLargeHeading)`
  width: 100%;
  color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHTS.EXTRA_BOLD};
`;

const StyledLargeHeadingCustom = styled(StyledLargeHeading)`
  width: 100%;
  color: ${COLORS.WHITE};
`;

const StyledSeperator = styled.div`
  width: 50%;
  border-top: 1px solid ${COLORS.WHITE};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const StyledMediumHeadingCustom = styled(StyledMediumHeading)`
  width: 100%;
  color: ${COLORS.WHITE};
`;

const StyledSocialButtons = styled.div`
  a {
    margin: 5px;
  }

  a:hover {
    cursor: pointer;
  }
`;

const StyledLogoImage = styled.img`
  height: 50vh;
  position: absolute;
  top: -20px;
  right: 0;
  opacity: 0.1;
  filter: ${ERROR_PAGE_FILTER};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 55vh;
  }
`;

const StyledInnerContainer = styled(Row)`
  height: 70vh;
  position: relative;
  ${PADDING(50, 100)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 60vh;
    ${PADDING(10, 20)}
  }
`;

const StyledRightCol = styled(Col)`
  margin-top: -200px;

  img#vector {
    width: 90%;
    position: absolute;
    bottom: -50px;
    max-width: 600px;
    left: 20px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 150px;
      bottom: -10px;
      left: unset;
      right: 0;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 100%;
      left: 60px;
    }
  }

  img#text_404 {
    position: absolute;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 140px;
      bottom: ${({ is500Error }) => (is500Error ? `-22` : `-32`)}px;
      right: unset;
      left: -10px;
    }
  }
`;

const StyledLeftBottomTitle = styled.div`
  ${DISPLAY_FLEX('column')}

  pointer-events: none;
  bottom: -190px;
  position: absolute;
  font-weight: bolder;
  color: ${COLORS.DODGER_BLUE};
  max-width: 420px;
  justify-content: flex-end;

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) {
    bottom: ${({ is500Error }) => (is500Error ? `-84` : `-115`)}px;

    img {
      width: 420px;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    bottom: ${({ is500Error }) => (is500Error ? `-94` : `-135`)}px;

    img {
      width: 560px;
    }
  }
`;

const StyledLeftIcon = styled.div`
  img {
    max-width: 250px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      max-width: 160px;
      padding-top: 20px;
    }
  }
`;

const StyledErrorDiv = styled.div`
  margin-top: 80px;
  color: ${COLORS.DODGER_BLUE};

  & > div {
    font-weight: 500;
  }

  & button {
    ${DISPLAY_FLEX('row')}

    margin-top: 20px;
    background-color: ${COLORS.DODGER_BLUE};
    min-width: 190px;
    justify-content: center;
    align-items: center;

    span {
      ${largeHeadingMixin}

      color: ${COLORS.WHITE};
    }

    @mixin pseudo-normal-effect($element) {
      &:#{$element} {
        background-color: ${COLORS.DODGER_BLUE};
        color: ${COLORS.WHITE};
      }
    }

    @include pseudo-normal-effect(hover);
    @include pseudo-normal-effect(focus);
    @include pseudo-normal-effect(active);
  }
`;
