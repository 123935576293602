import { createSlice } from '@reduxjs/toolkit';

import { STATUS } from '../../constants';
import {
  getTimeZonesData,
  getCountriesData,
  getCountryCodeData as getCountryPhoneCodeData,
  getCurrenciesData,
} from '../../utils/common';

const { IDLE, RESOLVED, PENDING, REJECTED } = STATUS;

const onStart = (state) => {
  state.status = PENDING;
};

const onSuccess = (state, action) => {
  state.status = RESOLVED;
  state.error = null;
  state.timeZones = action.payload;
};

const onError = (state, action) => {
  state.status = REJECTED;
  state.error = action.payload;
};

const onEnd = (state) => {
  state.status = IDLE;
};

const onSuccessCountry = (state, action) => {
  state.status = RESOLVED;
  state.countryData = action.payload;
  state.error = null;
};

const onSuccessCountryCode = (state, action) => {
  state.status = RESOLVED;
  state.countryCodeData = action.payload;
  state.error = null;
};

const onSuccessCurrencyData = (state, action) => {
  state.status = RESOLVED;
  state.currencyData = action.payload;
  state.error = null;
};

const onSuccessApplyJobData = (state, action) => {
  state.status = RESOLVED;
  state.applyJobData = action.payload;
  state.error = null;
};

export const commonSlice = createSlice({
  name: 'commons',
  initialState: {
    status: IDLE,
    error: null,
    timeZones: [],
    countryData: [],
    countryCodeData: [],
    currencyData: [],
    applyJobData: null,
  },
  reducers: {
    onStart,
    onSuccess,
    onError,
    onEnd,
    onSuccessCountry,
    onSuccessCurrencyData,
    onSuccessCountryCode,
    onSuccessApplyJobData,
  },
});

export const getTimeZones = () => async (dispatch) => {
  try {
    dispatch(commonSlice.actions.onStart());
    const timeZoneList = await getTimeZonesData();
    dispatch(commonSlice.actions.onSuccess(timeZoneList));
    dispatch(commonSlice.actions.onEnd());
  } catch (err) {
    dispatch(commonSlice.actions.onError(err.toString()));
  }
};

export const getCountryData = (addAuthHeader = true) => async (dispatch) => {
  try {
    dispatch(commonSlice.actions.onStart());
    const countryList = await getCountriesData(addAuthHeader);
    dispatch(commonSlice.actions.onSuccessCountry(countryList));
    dispatch(commonSlice.actions.onEnd());
  } catch (error) {
    dispatch(commonSlice.actions.onError(error.toString()));
  }
};

export const getCountryCodeData = (addAuthHeader = true) => async (
  dispatch,
) => {
  try {
    dispatch(commonSlice.actions.onStart());
    const countriesCode = await getCountryPhoneCodeData(addAuthHeader);
    dispatch(commonSlice.actions.onSuccessCountryCode(countriesCode));
    dispatch(commonSlice.actions.onEnd());
  } catch (error) {
    dispatch(commonSlice.actions.onError(error.toString()));
  }
};

export const getCurrencyData = (addAuthHeader = true) => async (dispatch) => {
  try {
    dispatch(commonSlice.actions.onStart());
    const currencies = await getCurrenciesData(addAuthHeader);
    dispatch(commonSlice.actions.onSuccessCurrencyData(currencies));
    dispatch(commonSlice.actions.onEnd());
  } catch (error) {
    dispatch(commonSlice.actions.onError(error.toString()));
  }
};

export const setApplyJobData = (updatedData = null) => (dispatch) => {
  try {
    dispatch(commonSlice.actions.onStart());
    const data = updatedData || localStorage.getItem('applyJobData');
    if (data) {
      dispatch(commonSlice.actions.onSuccessApplyJobData(JSON.parse(data)));
    }
  } catch (err) {
    dispatch(commonSlice.actions.onError(err.toString()));
  }
};

export const setApplyJobDatatoLocal = (data) => (dispatch) => {
  try {
    dispatch(commonSlice.actions.onStart());
    if (data) {
      localStorage.setItem('applyJobData', JSON.stringify(data));
      dispatch(commonSlice.actions.onSuccessApplyJobData(data));
    }
  } catch (err) {
    dispatch(commonSlice.actions.onError(err.toString()));
  }
};

export const removeApplyJobData = () => (dispatch) => {
  try {
    dispatch(commonSlice.actions.onStart());
    localStorage.removeItem('applyJobData');
    dispatch(commonSlice.actions.onSuccessApplyJobData(null));
  } catch (err) {
    dispatch(commonSlice.actions.onError(err.toString()));
  }
};

export default commonSlice.reducer;
