import { createSlice } from '@reduxjs/toolkit';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import {
  createInterviewSlot,
  getInterviewSlots,
  getInterviewSlotsByEvent,
  getRecruiterCalendarEvents,
} from '../../service/recruiter';
import { getCandidateCalendarEvents } from '../../service/candidate';
import { getOrganizerCalendarEvents } from '../../service/organizer';
import { STATUS } from '../../constants';

const { RESOLVED } = STATUS;

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.calendarEvents = action.payload.events;
  state.interviews = action.payload.interviews;
};

const onSuccessInterviewSlots = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.interviewSlots = action.payload;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const calendarEventsSlice = createSlice({
  name: 'calendarEvents',
  initialState: {
    isLoading: false,
    calendarEvents: [],
    interviews: [],
    interviewSlots: [],
    error: null,
  },
  reducers: { onStart, onSuccess, onSuccessInterviewSlots, onError },
});

const getCalendarEventsData = (roleId, startTimestamp, endTimestamp) => async (
  dispatch,
) => {
  try {
    dispatch(calendarEventsSlice.actions.onStart());
    let events = [];
    if (checkIfUserIsRecruiter(roleId)) {
      events = await getRecruiterCalendarEvents(startTimestamp, endTimestamp);
    } else if (checkIfUserIsCandidate(roleId)) {
      events = await getCandidateCalendarEvents(startTimestamp, endTimestamp);
    } else {
      events = await getOrganizerCalendarEvents(startTimestamp, endTimestamp);
    }
    dispatch(calendarEventsSlice.actions.onSuccess(events));
  } catch (err) {
    dispatch(calendarEventsSlice.actions.onError(err.toString()));
  }
};

export const getInterviewSlotsByEventData = (eventId, date) => async (
  dispatch,
) => {
  try {
    dispatch(calendarEventsSlice.actions.onStart());
    const interviewSlots = await getInterviewSlotsByEvent(eventId, date);
    dispatch(
      calendarEventsSlice.actions.onSuccessInterviewSlots(interviewSlots),
    );
  } catch (err) {
    dispatch(calendarEventsSlice.actions.onError(err.toString()));
  }
};

const createInterviewSlotsData = (data, eventId, date) => async (dispatch) => {
  try {
    dispatch(calendarEventsSlice.actions.onStart());
    await createInterviewSlot(data);
    dispatch(getInterviewSlotsByEventData(eventId, date));
  } catch (err) {
    dispatch(calendarEventsSlice.actions.onError(err.toString()));
  }
};

const getInterviewSlotsData = (data) => async (dispatch) => {
  try {
    dispatch(calendarEventsSlice.actions.onStart());
    const interviewSlots = await getInterviewSlots(data);
    dispatch(
      calendarEventsSlice.actions.onSuccessInterviewSlots(interviewSlots),
    );
  } catch (err) {
    dispatch(calendarEventsSlice.actions.onError(err.toString()));
  }
};

export default calendarEventsSlice.reducer;

export {
  getCalendarEventsData,
  createInterviewSlotsData,
  getInterviewSlotsData,
};
