import React, { useEffect } from 'react';
import { Modal, Row, Col, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import CountryCodeInput from '../../components/Common/CountryCodeInput';
import RecoremLogo from '../../assets/svg/recorem_white_logo.svg';
import {
  CustomStyleFormGroup,
  CustomStyleSubmit,
} from '../../components/Form/Form';
import {
  TextBox,
  ConfirmPassword,
  CheckPassword,
} from '../../components/shared-components/Form/Form';
import { resetPassword, updatePhone } from '../../service/auth';
import { getUrlVars } from '../../utils/common';
import Notification from '../../components/Notification/Notification';
import { loginUser } from '../../store/features/auth';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { getProfileData } from '../../store/features/profile';
import message from '../../constants/message';
import { countryCodeOnlySplit } from '../../utils/profile';

const CreatePassword = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { history } = props;

  const { passwordToken: token, inviteToken, eventId } = getUrlVars(
    get(props, 'history.location.search', {}),
  );
  if (!token) props.history.push('/login');

  const { isAuthenticated, user: userData } = useSelector(
    (state) => state.auth,
  );

  const handleSubmit = async ({
    password,
    checkPassword,
    countryCode,
    phone,
  }) => {
    const response = await resetPassword({
      password,
      referenceToken: token,
      isValidForOneDay: false,
    });

    if (response) {
      Notification('success', 'Password created successfully');
      const user = get(response, 'payload.user', null);
      updatePhone({
        user,
        phone: `${countryCodeOnlySplit(countryCode)} ${phone || ''}`,
      })
        .then((response) => {
          if (response) {
            dispatch(loginUser(user.email, password, false));
          }
        })
        .catch();
    }
  };

  useEffect(() => {
    if (isAuthenticated && (sessionStorage.length || localStorage.length)) {
      dispatch(getProfileData()).then(() => {
        const { roleId } = userData;
        const redirect = `events/view/${eventId}?inviteToken=${inviteToken}`;
        if (checkIfUserIsOrganizer(roleId))
          history.push(`/organizer/${redirect}`);
        else if (checkIfUserIsRecruiter(roleId))
          history.push(`/recruiter/${redirect}`);
        else if (checkIfUserIsCandidate(roleId))
          history.push(`/candidate/${redirect}`);
        else message.error('Getting profile data failed.Try logging in again');
      });
    }
  }, [isAuthenticated, history, userData, dispatch, eventId, inviteToken]);

  return (
    <div>
      <Modal
        visible
        footer={null}
        centered
        className="modal login-signup"
        width="80%"
        bodyStyle={{ padding: 0, overflow: 'hidden', maxWidth: '800px' }}
      >
        <div className="mob_show svg_arrw">
          <svg height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
          </svg>
        </div>
        <Row className="modal-login" id="login_modal">
          <Col xs={24} md={7} className="modal-login-logo left_side">
            <Row align="bottom">
              <Col xs={12} md={24}>
                <div className="modal-login-logo-full">
                  <img
                    className="modal-login-logo-full-1"
                    src={RecoremLogo}
                    alt="Recorem Logo"
                  />
                </div>
              </Col>
              <Col xs={12} md={24}>
                <div className="modal-login-logo-half">
                  <img
                    className="modal-login-logo-half-1"
                    src={RecoremLogo}
                    alt="Recorem Logo Half"
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={17} className="modal-login-content right_side">
            <div className="align-verifymodal">
              <span className="modal-login-content-verify3">
                Create a New password
              </span>

              <CustomStyleFormGroup
                form={form}
                name="resetpassword"
                onFinish={handleSubmit}
                expand
                style={{ width: '100%', textAlign: 'center' }}
              >
                <div className="mob_div reset-password">
                  <div className="reset-password">
                    <CheckPassword name="password" placeholder="New Password" />
                  </div>
                  <div className="confirm-reset-password">
                    <ConfirmPassword
                      name="checkPassword"
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div>
                    <Row>
                      <Col span={8}>
                        <CountryCodeInput name="countryCode" />
                      </Col>
                      <Col span={16} className="phonenum">
                        <TextBox
                          placeholder="Phone Number"
                          name="phone"
                          maxLength={12}
                          rule="phoneNumber"
                          required={false}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="modal-login-content-form-btn"
                    style={{ width: '100%' }}
                  >
                    <CustomStyleSubmit
                      name="Submit & Continue"
                      block
                      style={{ width: '100%', maxWidth: '250px' }}
                    />
                  </div>
                </div>
              </CustomStyleFormGroup>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default CreatePassword;
