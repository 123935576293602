import React from 'react';
import styled from 'styled-components';
import { Calendar as AntCalendar } from 'antd';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { StyledCalendarProps } from '../../interfaces/components/Calendar';

const StyledCalendar: React.FC<StyledCalendarProps> = ({
  dateCellRender,
  CalendarCustomHeader,
  openModal,
  handlePanelChange,
}) => {
  return (
    <StyledAntCalendar
      dateCellRender={dateCellRender}
      headerRender={CalendarCustomHeader}
      onPanelChange={handlePanelChange}
    />
  );
};

export default StyledCalendar;

const StyledAntCalendar = styled(AntCalendar)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-picker-panel {
      overflow-x: auto;

      .ant-picker-body th,
      .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
        width: 120px;
      }
    }
  }

  .ant-picker-calendar-date-content {
    height: 120px !important;

    @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
      height: 140px !important;
    }
  }
`;
