import React, { memo } from 'react';
import styled from 'styled-components';
import Modal from 'antd/es/modal';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN, PADDING } from '../../../styles/constants/display';

const JobDetailsModal = ({ visible, onCancel, JobDetails }) => {
  return (
    <>
      <StyledModal
        visible={visible}
        onCancel={onCancel}
        width={1000}
        footer={null}
        destroyOnClose
      >
        {JobDetails}
      </StyledModal>
    </>
  );
};

export default memo(JobDetailsModal);

const StyledModal = styled(Modal)`
  .ant-modal-content {
    ${MARGIN(5, 10, 0, 10)};
    top: 10%;
  }
  .ant-modal-close-x {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      display: block;
      ${MARGIN(0, 0, 0, 0)}
    }
  }
  .ant-modal-body {
    @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
      ${PADDING(30, 24, 24, 24)}
    }
  }
`;
