import { useSelector } from 'react-redux';
import { CANDIDATE, ORGANIZER, RECRUITER } from '../constants';
import { COLORS } from '../styles/constants/colors';

export const DashboardAnalyticsStore = () => {
  const {
    dashboardAnalytics: { isLoading, analytics, error },
  } = useSelector((state) => state.analytics);
  return { isLoading, analytics, error };
};

export const OngoingEventAnalyticsStore = () => {
  const {
    onGoingEventAnalytics: { isLoading, analytics, error },
  } = useSelector((state) => state.analytics);
  return { isLoading, analytics, error };
};

export const EventAnalyticsStore = () => {
  const {
    eventAnalytics: { isLoading, analytics, error },
  } = useSelector((state) => state.analytics);
  return { isLoading, analytics, error };
};

export const ApplicationAnalyticsStore = () => {
  const {
    applicationAnalytics: { isLoading, analytics, error },
  } = useSelector((state) => state.analytics);
  return { isLoading, analytics, error };
};

export const EventAnalyticsForJobStore = () => {
  const {
    jobAnalytics: { isLoading, analytics, error },
  } = useSelector((state) => state.analytics);
  return { isLoading, analytics, error };
};

/**
 * Takes userType and returns light background color of icon
 * @param {number} user
 * @returns {string} color
 */
export const getIconBgColor = (userType) => {
  switch (userType) {
    case ORGANIZER:
      return COLORS.LIGHT_ICON_BG_ORGANIZER;
    case RECRUITER:
      return COLORS.LIGHT_ICON_BG_RECRUITER;
    case CANDIDATE:
      return COLORS.LIGHT_ICON_BG_CANDIDATE;
    default:
      return COLORS.LIGHT_ICON_BG_ADMIN;
  }
};
