import React from 'react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import './styles/index.scss';
import config from './config';
import { AppSocketProvider } from './context/AppSocket';
import { SocketProvider } from './context/socket';

if (['production', 'staging'].includes(config.appEnv)) {
  Sentry.init({
    dsn: config.sentry_dsn,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: config.appEnv,
    debug: config.under_dev,
  });
}
// if someone wants hot reloading uncomment this
// if (module.hot) {
//   module.hot.accept();
// }
const history = createBrowserHistory();
const app = document.getElementById('root');
if (config.appEnv === 'production' && app) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppSocketProvider>
          <SocketProvider>
            <App />
          </SocketProvider>
        </AppSocketProvider>
      </Provider>
    </React.StrictMode>,
    app,
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppSocketProvider>
          <SocketProvider>
            <App />
          </SocketProvider>
        </AppSocketProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
