import React from 'react';
import { Menu } from 'antd';
import { useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';
import CopyPng from '../../../assets/img/copy.png';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import Notification from '../../Notification/Notification';
import { COLORS } from '../../../styles/constants/colors';
import config from '../../../config';

export const SocialShareIcons = ({ jobId }) => {
  const { companyId } = useParams();
  const url = `${config.appURL}/event-partner/${companyId}/community?jobId=${jobId}`;

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={onClick}>
      <StyledMenu selectable={false}>
        <SocialIcon>
          <FacebookShareButton url={url}>
            <FacebookIcon round />
          </FacebookShareButton>
        </SocialIcon>
        {/* <SocialIcon>
          @ts-ignore
          <FacebookMessengerShareButton url={url}>
            <FacebookMessengerIcon round />
          </FacebookMessengerShareButton>
        </SocialIcon> */}
        <SocialIcon>
          <TelegramShareButton url={url}>
            <TelegramIcon round />
          </TelegramShareButton>
        </SocialIcon>
        <SocialIcon>
          <TwitterShareButton url={url}>
            <TwitterIcon round />
          </TwitterShareButton>
        </SocialIcon>
        <SocialIcon>
          <WhatsappShareButton url={url}>
            <WhatsappIcon round />
          </WhatsappShareButton>
        </SocialIcon>
        <SocialIcon>
          <LinkedinShareButton url={url}>
            <LinkedinIcon round />
          </LinkedinShareButton>
        </SocialIcon>
        <SocialIcon>
          <CopyToClipboard
            text={url}
            onCopy={() => {
              Notification('success', 'Job Link Copied', '');
            }}
            // @ts-ignore
            title="Copy link"
          >
            <div>
              <StyledCopyImg src={CopyPng} alt="copy-icon" />
            </div>
          </CopyToClipboard>
        </SocialIcon>
      </StyledMenu>
    </div>
  );
};

const SocialIcon = styled(Menu.Item)`
  ${DISPLAY_FLEX('row', 'center', 'center')}

  button,div {
    width: 32px;
    height: 32px;
    ${DISPLAY_FLEX('row', 'center', 'center')};
  }
`;

const StyledMenu = styled(Menu)`
  ${DISPLAY_FLEX('row')}
  background: ${COLORS.WHITE_SMOKE};
  border-radius: 5px;
  box-shadow: 0px 3px 15px ${COLORS.BLACK}29;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    ${MARGIN(0, 0, 0, -70)};
    &:before {
      display: none;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_2}) {
    ${DISPLAY_FLEX('column')}
    ${MARGIN(0, 0, 0, -15)}
  }
`;

const StyledCopyImg = styled.img`
  max-width: 32px;
  height: 32px;
`;
