import React from 'react';
import styled from 'styled-components';
import { Button, Col, DatePicker, Row, TimePicker } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import first from 'lodash/first';
import { getRoleId } from '../../../utils/userInfo';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { currentDate } from '../../../utils/formatDateTime';
import { DropdownBox, InputBox } from '../../shared-components/Form/Form';
import { PrimaryButton } from '../../shared-components/Button';
import { getThemeByRoleId } from '../../../utils/common';
import { isDesktop } from '../../../helpers/display';
import { SHADOWS } from '../../../styles/constants/shadows';
import { TrashIcon } from '../../svgicons';
import Notification from '../../Notification/Notification';
import { ADD, DELETE } from '../../../constants';

const Schedule = ({
  customInput,
  isTabActive,
  form,
  daysSchedule,
  setDaysSchedule,
  days,
  setDays,
  isEdit,
}) => {
  // const [isActive, setIsActive] = React.useState(false);
  const roleId = getRoleId();

  const handleAddDay = () => {
    if (!first(days.filter((day) => !day.date))) {
      setDays((prev) => [...prev, { date: undefined, operation: ADD }]);
    }
  };

  const handleDeleteEntity = (key, index) => {
    const temp = [...daysSchedule[key]];
    if (temp[index].operation === ADD || !isEdit) {
      temp.splice(index, 1);
    } else {
      temp.splice(index, 1, { ...temp[index], operation: DELETE });
    }
    setDaysSchedule({ ...daysSchedule, [key]: temp });
  };

  const handleAddSlot = (key) => {
    if (key) {
      const temp = { ...daysSchedule };
      const slotsLength = temp[key].length;
      if (
        (slotsLength &&
          temp[key][slotsLength - 1].topic &&
          temp[key][slotsLength - 1].startTime &&
          temp[key][slotsLength - 1].endTime) ||
        !temp[key].length
      ) {
        temp[key].push({
          topic: '',
          startTime: '',
          endTime: '',
          speakers: [],
          ...(isEdit ? { operation: ADD } : {}),
        });
      }
      setDaysSchedule(temp);
    }
  };

  const disabledDate = (d) =>
    d.isSameOrBefore(
      get(form.getFieldValue('dateTime'), '[0]', currentDate(true, 'lll')),
    ) ||
    d.isSameOrBefore(currentDate(true, 'lll')) ||
    d.isSameOrAfter(
      get(form.getFieldValue('dateTime'), '[1]', currentDate(true, 'lll')),
    );

  const onDateSelect = (index, date) => {
    const temp = [...days];
    date = date.startOf('day');
    const key = date.unix();
    if (temp.find((x) => x.date === key && x.operation !== DELETE)) {
      Notification('error', 'This date is already added.');
      return;
    }

    temp.splice(index, 1, { date: date.unix(), operation: ADD });

    setDays(temp);
    daysSchedule[date.unix()] = [
      ...(get(daysSchedule, `[${date.unix()}]`, null)
        ? daysSchedule[date.unix()]
        : []),
    ];
  };

  const handleSlotChange = (key, index, entity, data) => {
    const unixKey = key;
    const temp = [...daysSchedule[unixKey]];
    const isEndTimeEntity = entity === 'endTime';
    if (isEndTimeEntity && !get(temp, `[${index}].startTime`, null)) {
      Notification('error', 'Please select the startTime first');
      return;
    }

    if (
      isEndTimeEntity &&
      data.isSameOrBefore(get(temp, `[${index}].startTime`, null))
    ) {
      Notification('error', 'Please select the Valid end time');
      return;
    }

    if (isEndTimeEntity && temp && temp.length) {
      const { startTime } = temp[index];
      const start = startTime.unix();
      const end = data.unix();
      for (let j = 0; j < temp.length; j += 1) {
        if (index !== j) {
          const oldStart = temp[j].startTime.unix();
          const oldEnd = temp[j].endTime.unix();
          if (
            (oldStart >= start && oldEnd <= end) ||
            (oldStart <= start && oldEnd >= end) ||
            (oldStart > start && oldStart < end) ||
            (oldEnd > start && oldEnd < end)
          ) {
            Notification('error', 'Overlapping slots are not allowed');
            return;
          }
        }
      }
    }

    temp.splice(index, 1, {
      ...temp[index],
      [entity]: data,
      ...(isEdit && temp[index].operation === 'none'
        ? { operation: 'edit' }
        : {}),
    });
    setDaysSchedule({ ...daysSchedule, [unixKey]: temp });
  };

  const handleDeleteDay = (key, index) => {
    const unixKey = key;
    const temp = [...days];
    if (temp[index].operation === ADD || !isEdit) {
      temp.splice(index, 1);
      const scheduleTemp = { ...daysSchedule };
      delete scheduleTemp[unixKey];
      setDaysSchedule(scheduleTemp);
    } else {
      temp.splice(index, 1, { ...temp[index], operation: DELETE });
      const scheduleTemp = { ...daysSchedule };
      const tempSlots = scheduleTemp[unixKey].map((entity) => ({
        ...entity,
        operation: DELETE,
      }));
      scheduleTemp[unixKey] = tempSlots;
      setDaysSchedule(scheduleTemp);
    }
    setDays(temp);
  };

  return (
    <>
      <StyledContainer>
        {days && days.filter((item) => item.operation !== DELETE).length ? (
          days.map((dateObject, index) => {
            if (dateObject.operation === DELETE) {
              return <></>;
            }
            return (
              <StyledDayContainer>
                <StyledDateHeader>
                  Day:{' '}
                  {dateObject.date ? (
                    <>
                      <StyledBoldDay>
                        {moment.unix(dateObject.date).format('ll')}
                      </StyledBoldDay>
                      {'  '}
                      <StyledAddSlotButton
                        onClick={() => handleAddSlot(dateObject.date)}
                        backgroundColor={getUserColorWithRoleId(roleId)}
                      >
                        + Add Slot
                      </StyledAddSlotButton>
                      <StyledDeleteButton
                        tabIndex={0}
                        onClick={() =>
                          handleDeleteDay(dateObject.date, index)
                        }
                      >
                        <TrashIcon />
                      </StyledDeleteButton>
                    </>
                  ) : (
                    <>
                      <DatePicker
                        disabledDate={disabledDate}
                        onChange={(date) => onDateSelect(index, date)}
                        name="dayDate"
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                      <StyledDeleteButton
                        tabIndex={0}
                        onClick={() =>
                          handleDeleteDay(dateObject.date, index)
                        }
                      >
                        <TrashIcon />
                      </StyledDeleteButton>
                    </>
                  )}
                </StyledDateHeader>
                {dateObject.date ? (
                  <>
                    {daysSchedule[dateObject.date].map((slot, index2) => {
                      if (slot.operation === DELETE) {
                        return <></>;
                      }
                      return (
                        <StyledInputContainer>
                          <Col xs={24} lg={6}>
                            <label htmlFor="schedule_topic">
                              <StyledInfoHeader id="schedule_topic_label">
                                Topic
                              </StyledInfoHeader>
                            </label>
                            <InputBox
                              name="topic"
                              placeholder="Enter topic"
                              value={slot.topic}
                              handleChange={(e) =>
                                handleSlotChange(
                                  dateObject.date,
                                  index2,
                                  'topic',
                                  e.target.value,
                                )
                              }
                              rule="text"
                              id="schedule_title"
                            />
                          </Col>
                          <Col xs={24} lg={3}>
                            <label htmlFor="schedule_startTime">
                              <StyledInfoHeader id="schedule_startTime_label">
                                Start Time
                              </StyledInfoHeader>
                            </label>
                            <TimePicker
                              value={slot.startTime}
                              name="startTime"
                              onChange={(date) =>
                                handleSlotChange(
                                  dateObject.date,
                                  index2,
                                  'startTime',
                                  date,
                                )
                              }
                              defaultOpenValue={moment.unix(dateObject.date)}
                              size={isDesktop() ? 'large' : 'medium'}
                              id="schedule_startTime"
                              format="HH:mm"
                              getPopupContainer={(trigger) => trigger.parentElement}
                            />
                          </Col>
                          <Col xs={24} lg={3}>
                            <label htmlFor="schedule_endTime">
                              <StyledInfoHeader id="schedule_title_label">
                                End Time
                              </StyledInfoHeader>
                            </label>
                            <TimePicker
                              onChange={(date) =>
                                handleSlotChange(
                                  dateObject.date,
                                  index2,
                                  'endTime',
                                  date,
                                )
                              }
                              name="endTime"
                              defaultOpenValue={moment.unix(dateObject.date)}
                              value={slot.endTime}
                              size={isDesktop() ? 'large' : 'medium'}
                              id="schedule_endTime"
                              format="HH:mm"
                              getPopupContainer={(trigger) => trigger.parentElement}
                            />
                          </Col>
                          {customInput.speakers.length ? (
                            <Col xs={24} lg={6}>
                              <label htmlFor="schedule_speakers">
                                <StyledInfoHeader id="schedule_speakers_label">
                                  Speakers
                                </StyledInfoHeader>
                              </label>
                              <DropdownBox
                                options={customInput.speakers
                                  .filter((date) => date.operation !== DELETE)
                                  .map((speaker) => ({
                                    id: speaker.name,
                                    name: speaker.name,
                                  }))}
                                name="speakers"
                                value={slot.speakers}
                                placeholder="Select speakers"
                                mode="multiple"
                                handleChange={(val) =>
                                  handleSlotChange(
                                    dateObject.date,
                                    index2,
                                    'speakers',
                                    val,
                                  )
                                }
                              />
                            </Col>
                          ) : null}
                          <Col xs={24} lg={2}>
                            <label htmlFor="company_name">
                              <StyledHiddenInfoHeader id="company_name_label">
                                Delete
                              </StyledHiddenInfoHeader>
                            </label>
                            <StyledEditButton
                              tabIndex={0}
                              onClick={() =>
                                handleDeleteEntity(dateObject.date, index2)
                              }
                            >
                              <TrashIcon />
                            </StyledEditButton>
                          </Col>
                        </StyledInputContainer>
                      );
                    })}
                  </>
                ) : null}
              </StyledDayContainer>
            );
          })
        ) : (
          <StyleHeadingLevelThree>Add event schedule</StyleHeadingLevelThree>
        )}
        <PrimaryButton
          onClick={handleAddDay}
          theme={getThemeByRoleId(roleId)}
        >
          + Add Day
        </PrimaryButton>
      </StyledContainer>
    </>
  );
};

export default Schedule;

const StyledContainer = styled.div`
  clear: both;
  @media (max-width: 400px) {
    overflow: visible;
  }
`;

const StyledDateHeader = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 8px;
`;

const StyledAddSlotButton = styled(Button)`
  width: max-content;
  height: 32px;
  border-radius: 3px;
  float: right;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.WHITE};
  background-color: ${(props) => props.backgroundColor};

  &:hover {
    border: 1px solid ${(props) => props.backgroundColor};
    color: ${(props) => props.backgroundColor};
  }
`;

const StyledInfoHeader = styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledInputContainer = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 10px;
  margin-top: 10px;
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-picker {
    width: 100%;
  }
`;

const StyledDayContainer = styled.div`
  margin-bottom: 15px;
`;

const StyledEditButton = styled(Button)`
  ${DISPLAY_FLEX(null, 'center', 'center')};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 5px;
  padding: 10px;
  box-shadow: ${SHADOWS.SHADOW2};

  svg {
    width: 25px;
    font-size: 25px;
    fill: ${COLORS.ORANGE_RED};
  }
`;

const StyledHiddenInfoHeader = styled.span`
  visibility: hidden;
  margin-bottom: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledDeleteButton = styled(Button)`
  ${DISPLAY_FLEX(null, 'center', 'center')};
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: ${SHADOWS.SHADOW2};

  @media (max-width: 400px) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
  }

  svg {
    width: 25px;
    font-size: 25px;
    fill: ${COLORS.ORANGE_RED};

    @media (max-width: 400px) {
      width: 14px;
      font-size: 10px;
    }
  }
`;

const StyledBoldDay = styled.span`
  font-weight: 500;
  min-width: 100px;

  @media (max-width: 400px) {
    min-width: 95px;
  }
`;

const StyleHeadingLevelThree = styled.h3`
  ${MARGIN(0, 0, 24, 0)}
  font-weight: ${({ isSponsors }) =>
    isSponsors ? FONT_WEIGHTS.MEDIUM : FONT_WEIGHTS.SEMI_BOLD};
  color: ${({ color }) => color || COLORS.CHINESE_BLACK};
  font-size:${FONT[16]};
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size:${FONT[14]};
  }
`;
