import get from 'lodash/get';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { getRedirectableLink } from '../../../utils/general';
import { getFileS3 } from '../../../utils/s3.storage';

export const EventSpeakers = ({ speakers, speakerId }: any) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (speakerId) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [speakerId]);

  return (
    <>
      {speakers && speakers.length ? (
        <div>
          <StyledGuest>
            <h1>Guest Speakers</h1>
            {speakers.map((speaker: any) => (
              <div key={speaker} ref={speaker.id === speakerId ? ref : null}>
                <StyledImage>
                  <img src={getFileS3(speaker.pic)} alt="speaker" />
                  <div>
                    <a
                      target="blank"
                      href={getRedirectableLink(get(speaker, 'links[0]'))}
                    >
                      <h4>{speaker.name}</h4>
                    </a>
                    <p>{speaker.designation}</p>
                  </div>
                </StyledImage>

                <StyledContent>
                  <p>{speaker.brief}</p>
                </StyledContent>
              </div>
            ))}
            <StyledLine />
          </StyledGuest>
        </div>
      ) : null}
    </>
  );
};

const StyledLine = styled.hr`
  width: 30%;
  margin: 50px auto 0px auto;
`;

const StyledGuest = styled.div`
  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 24px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 16px;
    }
  }

  h4,
  p {
    font-weight: 500;
  }

  & > div {
    margin-bottom: 40px;
  }

  h4 {
    transition: 0.4s;
  }

  h4:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledImage = styled.div`
  display: flex;
  gap: 12px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid ${COLORS.SLATE_BLUE_2};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 60px;
      height: 60px;
    }
  }

  & > div {
    margin-top: 3px;
  }

  h4 {
    margin-bottom: 0;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 18px;
    }
  }

  p {
    color: ${COLORS.SPANISH_GRAY};
    font-size: 15px;
  }
`;

const StyledContent = styled.div`
  p {
    font-weight: 500;
    color: ${COLORS.DARK_SILVER_2};
  }

  margin-top: 15px;
`;
