import get from 'lodash/get';
import api from './api';
import { APIs, DEFAULT_LIMIT, DEFAULT_PAGE, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import { getCalendarEvents } from './event';

export const getOrganizerEvents = async () => {
  return api({
    url: `${APIs.organizer}events/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.events', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createEvent = async (body) => {
  return api({
    url: APIs.event,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const poapEvent = async (body) => {
  return api({
    url: `${APIs.event}nft/poap/upload/`,
    method: Method.post,
    body,
    contentType: 'multipart/form-data',
    header: { Accept: 'application/json' },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const editEvent = async (id, body) => {
  return api({
    url: APIs.event + id,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch((err) => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getOrganizerCalendarEvents = async (
  startTimestamp,
  endTimestamp,
) => {
  return getCalendarEvents(APIs.organizer, startTimestamp, endTimestamp);
};

export const cancelEvent = async (id, body) => {
  return api({
    url: APIs.event + id + /cancel/,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEventRecruiter = async (
  id,
  page,
  limit,
  filter = null,
  sorter = null,
) => {
  return api({
    url: `${APIs.eventRecruiter}?eventId=${id}&page=${page}&limit=${limit}${
      filter ? `&filter=${filter}` : ''
    }${sorter ? `&sort=${sorter}` : ''}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return {
        recruiters: get(response, 'data.payload.recruiters', null),
        currentPage: get(response, 'data.payload.currentPage', null),
        totalItems: get(response, 'data.payload.totalItems', null),
      };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEventCandidate = async (
  id,
  page,
  limit,
  filter = null,
  sorter = null,
) => {
  return api({
    url: `${APIs.eventCandidate}?eventId=${id}&page=${page}&limit=${limit}${
      filter ? `&filter=${filter}` : ''
    }${sorter ? `&sort=${sorter}` : ''}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return {
        candidates: get(response, 'data.payload.candidates', null),
        currentPage: get(response, 'data.payload.currentPage', null),
        totalItems: get(response, 'data.payload.totalItems', null),
      };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateEventRecruiterAttendance = async (
  ids,
  body,
  eventId,
  isUpdateAll = false,
) => {
  return api({
    url: `${APIs.eventRecruiter}attendance/`,
    method: Method.put,
    body: {
      ...body,
      eventId,
      eventCompanyIds: ids,
      isUpdateAll,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateEventCandidateAttendance = async (
  ids,
  body,
  eventId,
  isUpdateAll = false,
) => {
  return api({
    url: `${APIs.eventCandidate}attendance/`,
    method: Method.put,
    body: {
      ...body,
      eventId,
      eventCandidateIds: ids,
      isUpdateAll,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const validateCsvFile = async (file) => {
  const url = APIs.validateCsvFile;
  const method = Method.post;
  const headers = { Accept: 'application/json' };

  const contentType = 'multipart/form-data';

  const formData = new FormData();
  formData.append('file', file);

  return api({ url, method, headers, body: formData, contentType });
};

export const bulkBooking = async (id, body) => {
  return api({
    url: APIs.bulkBooking(id),
    method: Method.post,
    body,
  });
};

export const getCommunityAccessRequests = async (
  search = '',
  eventId = null,
  page = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
) => {
  return api({
    url: `${APIs.organizer}community/access/?page=${page}&limit=${limit}${
      eventId ? `&eventId=${eventId}` : ''
    }${search ? `&search=${search}` : ''}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.companyCommunityAccessRequests', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEventsAutoFillData = async () => {
  return api({
    url: `${APIs.organizer}event/autocomplete/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getAutoFillAttendingCompanyData = async (search) => {
  return api({
    url: `${APIs.organizer}event/attending-companies/?search=${search}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.companies', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const enableCommunityPage = async (body) => {
  return api({
    url: APIs.eventPartnerCommunityPage,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
