import first from 'lodash/first';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_WITH_COUNTRY,
} from '../constants/currency';

export const currencyNameToCode = (currencyName) => {
  if (!currencyName) return null;

  const arr = currencyName.split(' ');

  // No country name so just return currency
  if (arr.length === 1) return currencyName;

  let code = arr[arr.length - 1];

  // No currency code
  if (code === '()') return null;

  code = code.slice(1, code.length - 1);

  return code;
};

export const formatCurrency = (currency, amount) => {
  // currency and amount can be '-' when the states of component have not been updated yet
  let formatted = '';
  let locale = 'en-US'; // default
  if (currency === 'INR') locale = 'en-IN';
  try {
    if (!currency || !amount || (currency === '-' && amount === '-')) {
      return formatted;
    }
    formatted = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyNameToCode(currency) || null,
      minimumFractionDigits: 0
    }).format(Math.ceil(amount * 100) / 100);
    return formatted;
  } catch (error) {
    return `${currency} ${amount}`;
  }
};

export const getCurrencyDataForDropdown = (currenciesData) => {
  if (!currenciesData) return [];

  const data = currenciesData.map((c) => ({
    id: c.id,
    name: c.name,
    value: c.id,
    code: c.value,
  }));

  // Remove currency object which do not have currency code
  return data.filter((c) => c.code !== '');
};

export const getDefaultPaymentCurrency = (currenciesData) => {
  if (!currenciesData) return {};
  return first(
    currenciesData.filter((c) => c.name === DEFAULT_CURRENCY_WITH_COUNTRY),
  );
};

export const getFirstCurrencyByCode = (currenciesData, code) => {
  if (!currenciesData) return {};
  return first(currenciesData.filter((c) => c.value === code));
};

export const getCurrencyById = (currenciesData, id) => {
  if (!currenciesData) return {};
  return first(currenciesData.filter((c) => c.id === id));
};

export const getConvertedPrice = ({
  fromCurrency,
  fromAmount,
  toCurrency = DEFAULT_CURRENCY,
  currenciesData,
}) => {
  if (fromCurrency === toCurrency) return fromAmount;
  // Convert from original to usd
  const firstCurrencyConverting = currenciesData.find(
    (c) => c.value === fromCurrency,
  );
  // Convert from usd to new currency
  const secondCurrencyConverting = currenciesData.find(
    (c) => c.value === toCurrency,
  );

  // Not found at least one rate
  if (
    !firstCurrencyConverting ||
    !secondCurrencyConverting ||
    !firstCurrencyConverting.usdRate ||
    !secondCurrencyConverting.usdRate
  )
    return -1;

  return (
    (parseFloat(fromAmount) / parseFloat(firstCurrencyConverting.usdRate)) *
    parseFloat(secondCurrencyConverting.usdRate)
  );
};

export const currencyConverting = ({
  fromCurrency,
  fromAmount,
  toCurrency = DEFAULT_CURRENCY,
  currenciesData,
}) => {
  if (!currenciesData) return null;

  // The same, no need to exchange
  if (fromCurrency === toCurrency) return null;

  const toAmount = getConvertedPrice({
    fromCurrency,
    fromAmount,
    toCurrency,
    currenciesData,
  });

  if (toAmount < 0) {
    return null;
  }

  const formatted = formatCurrency(toCurrency, toAmount);

  return `${formatted}`;
};
