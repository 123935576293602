import { isDesktop, isLaptop } from '../helpers/display';

/**
 * Returns size based on current width ( device screen )
 * @param {number} desktopSize
 * @param {number} laptopSize
 * @param {number} mobileSize
 * @returns {number} size
 */
export const getSize = (desktopSize, laptopSize, mobileSize) => {
  switch (true) {
    case isDesktop():
      return desktopSize;
    case isLaptop():
      return laptopSize;
    default:
      return mobileSize;
  }
};

/**
 * This helper functions takes following value and returns pixels or same value based on input type.
 * This function is inteded to be used for size values for css. Ex: width, height etc
 * @param value
 * @returns
 */
export const getSizeInPx = (value) => {
  if (typeof value === 'number') {
    // eslint-disable-next-line prefer-template
    return value + 'px';
  }
  return value;
};
