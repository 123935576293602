import React from 'react';
import { Pagination } from 'antd';
import styled from 'styled-components';
import { MARGIN } from '../../styles/constants/display';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../constants';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const CustomPagination = ({
  currentPage = DEFAULT_PAGE,
  totalItems = DEFAULT_LIMIT,
  onPageChange,
  onLimitChange,
  currentLimit = DEFAULT_LIMIT,
  showQuickJumper = true,
}) => {
  // This function is triggering in both page change and limit change
  const onChange = (pageNumber, limit) => {
    onPageChange(pageNumber, limit);
  };

  const onShowSizeChange = (currentPageNumber, pageSize) => {
    onLimitChange(currentPageNumber, pageSize);
  };

  return (
    <StyledPaginationContainer>
      <Pagination
        showSizeChanger
        showQuickJumper={showQuickJumper}
        onShowSizeChange={onShowSizeChange}
        defaultCurrent={DEFAULT_PAGE}
        current={currentPage}
        total={totalItems}
        onChange={onChange}
        pageSize={currentLimit}
      />
    </StyledPaginationContainer>
  );
};

export default CustomPagination;

const StyledPaginationContainer = styled.div`
  ${MARGIN(20, 0, 0, 0)}

  .ant-pagination-options {
    display: inline-block;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-pagination-prev,
    .ant-pagination-item,
    .ant-pagination-next {
      ${MARGIN(0, 6, 6, 0)}
    }

    .ant-pagination-options {
      ${MARGIN(0, 0, 6, 0)}
    }
  }
`;
