/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Spin, Space } from 'antd';
import RecoremLogo from '../../assets/svg/recorem_white_logo.svg';
import SuccessIcon from '../../assets/svg/success.svg';
import { verify } from '../../service/auth';
import { getUrlVars } from '../../utils/common';
import config from '../../config';
import { unsubscribeInviteMail } from '../../service/event';
import get from 'lodash/get';

const MailUnsubscribeModal = (props) => {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  const { inviteToken, roleId } = getUrlVars(props.location.search);
  if (!inviteToken) props.history.push('/login');

  const unsubscribe = async () => {
    const response = await unsubscribeInviteMail(inviteToken, roleId);
    if (get(response, 'status') === 200) {
      setIsUnsubscribed(true);
    }
  };

  useEffect(() => {
    unsubscribe();
  }, [inviteToken]);

  const handleModalCancel = () => {
    window.open(config.appURL, '_self');
    window.close();
  };

  return isUnsubscribed ? (
    <div>
      <Modal
        visible={true}
        onCancel={handleModalCancel}
        footer={null}
        centered
        className="modal login-signup"
        width="80%"
        bodyStyle={{ padding: 0, overflow: 'hidden', maxWidth: '800px' }}
      >
        <div className="mob_show svg_arrw">
          <svg height="24" viewBox="0 0 24 24" width="24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
          </svg>
        </div>
        <Row className="modal-login" id="login_modal">
          <Col xs={24} md={7} className="modal-login-logo left_side">
            <Row align="bottom">
              <Col xs={12} md={24}>
                <div className="modal-login-logo-full">
                  <img
                    className="modal-login-logo-full-1"
                    src={RecoremLogo}
                    alt="Recorem Logo"
                  />
                </div>
              </Col>
              <Col xs={12} md={24}>
                <div className="modal-login-logo-half">
                  <img
                    className="modal-login-logo-half-1"
                    src={RecoremLogo}
                    alt="Recorem Logo Half"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={17} className="modal-login-content right_side">
            <div className="align-verifymodal">
              <div className="success-icon">
                <img src={SuccessIcon} alt="" />
              </div>
              <span className="modal-login-content-verify2">
                You have unsubscribed daily event invite mails.
              </span>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  ) : (
    <div className="center-screen">
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default MailUnsubscribeModal;
