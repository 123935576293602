import get from 'lodash/get';
import api from './api';
import { Method, APIs, ApiType } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';

export const getChatRoomWithId = async (id) => {
  return api({
    url: `${APIs.chatRoom}/${id}/`,
    method: Method.get,
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data.payload', []);
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchChats = async ({ type }, page, limit) => {
  return api({
    url: `/user${APIs.chatRoom}/?page=${page}&limit=${limit}&type=${type}`,
    method: Method.get,
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const chats = get(response, 'data.payload.response', []);
      const totalPages = get(response, 'data.payload.totalPages', 1);
      return { chats, totalPages };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchChatroomMessages = async (id, page, limit) => {
  return api({
    url: `${APIs.chatRoom}/${id}/message/?page=${page}&limit=${limit}`,
    method: Method.get,
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const messages = get(response, 'data.payload.messages', []);
      const totalPages = get(response, 'data.payload.totalPages', 1);
      return { messages, totalPages };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateGroupChatStatus = async (chatRoomId, status) => {
  return api({
    url: `${APIs.chatRoom}/${chatRoomId}/status/`,
    method: Method.put,
    body: { status },
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const sendMessage = async (id, message, isAnonymous = false, contentType) => {
  return api({
    url: `${APIs.chatRoom}/${id}/message/`,
    method: Method.post,
    body: { message, isAnonymous, contentType },
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const setAnonymity = async (anonymity) => {
  return api({
    url: `${APIs.anonymity}/`,
    method: Method.put,
    body: { anonymity },
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteThread = async (
  chatRoomId,
  threadId,
  isDifferentMessage = false,
) => {
  return api({
    url: `${APIs.chatRoom}/${chatRoomId}/message/${threadId}/${isDifferentMessage ? '?isDifferentMessage=true' : ''
      }`,
    method: Method.delete,
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      return true;
    })
    .catch(() => {
      Promise.reject(ERRORS.REQUEST_FAILED);
      return false;
    });
};

export const isChatroomExist = async (eventId, userId, userType) => {
  return api({
    url: `${APIs.chatRoom}/private/${eventId}/?userId=${userId}&userType=${userType}`,
    method: Method.get,
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data.payload', '');
      return data;
    })
    .catch(() => {
      Promise.reject(ERRORS.REQUEST_FAILED);
    });
};

export const createChatroom = async (eventData, users, type, triggerMsg) => {
  const triggerData = {};
  if (triggerMsg) {
    triggerData.triggerMessage = true;
    triggerData.message = `Welcome to the event ${eventData.name}`;
  }
  return api({
    url: `${APIs.chatRoom}/`,
    method: Method.post,
    body: {
      ...eventData,
      type,
      users,
      ...triggerData,
    },
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateChatroom = async (eventId, eventData, type) => {
  return api({
    url: `${APIs.chatRoom}/event/${eventId}/`,
    method: Method.put,
    body: { ...eventData, type },
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      const statusCode =
        get(response, 'statusCode', null) || get(response, 'status', null);
      const data = get(response, 'data.payload', null);
      return { statusCode, data };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateChatroomEndtime = async (data) => {
  return api({
    url: `${APIs.chatRoom}/end-time/`,
    method: Method.put,
    body: data,
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addUserToChatroom = async (eventId, userInfo) => {
  return api({
    url: `${APIs.chatRoom}/event/${eventId}/user/`,
    method: Method.post,
    body: userInfo,
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      const statusCode =
        get(response, 'statusCode', null) || get(response, 'status', null);
      const data = get(response, 'data.payload', null);
      return { statusCode, data };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getUserProfileData = async ({ userIdArray, companyIdArray }) => {
  const userIds = userIdArray?.filter((item) => !!item);
  const companyIds = companyIdArray?.filter((item) => !!item);
  const userIdStr =
    userIds && userIds.length ? `userIds=${userIds.join()}` : '';
  const companyIdStr =
    companyIds && companyIds.length ? `companyIds=${companyIds.join()}` : '';
  let queryStr = '';
  if (userIdStr && companyIdStr) {
    queryStr = `${userIdStr}&${companyIdStr}`;
  } else {
    queryStr = userIdStr + companyIdStr;
  }
  return api({
    url: `${APIs.index}profile/basic/?${queryStr}`,
    method: Method.get,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data.payload.data', []);
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const generateUserAnonymousName = () => {
  return api({
    url: `/user/anonymous-name/`,
    method: Method.get,
    errorHandlingMessage: false,
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data.payload.anonymousName', '');
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteUserAnonymousName = (name) => {
  return api({
    url: `/user/anonymous-name/?anonymousName=${name}`,
    method: Method.delete,
    errorHandlingMessage: false,
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data.anonymousName', '');
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRoomByEventId = async (eventId) => {
  return api({
    url: `/chat-room/group/${eventId}/`,
    method: Method.get,
    errorHandlingMessage: false,
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data', '');
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getChatNotifications = async (page = 1, limit = 10) => {
  return api({
    url: `/user/notifications/?page=${page}&limit=${limit}`,
    method: Method.get,
    errorHandlingMessage: false,
    type: ApiType.chat,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const data = get(response, 'data.payload', []);
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateChatNotifications = async ({ chatRoomIds }) => {
  return api({
    url: `/user/notifications/read/`,
    method: Method.put,
    errorHandlingMessage: false,
    type: ApiType.chat,
    body: { chatRoomIds },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.message', '');
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const setPinnedMessage = async ({ chatRoomId, messageId, isPin }) => {
  return api({
    url: `/chat-room/message/pin/`,
    method: Method.put,
    errorHandlingMessage: false,
    type: ApiType.chat,
    body: { chatRoomId, messageId, pinned: isPin },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.message', '');
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getPinnedMessages = async (chatRoomId) => {
  return api({
    url: `/chat-room/${chatRoomId}/message/pinned/`,
    method: Method.get,
    type: ApiType.chat,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      const pinnedMessages = get(response, 'data.payload.pinnedMessages', []);
      return { pinnedMessages };
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
