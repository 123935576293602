import React from 'react';
import styled from 'styled-components';
import { Row, Col, Avatar, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import TextArea from 'antd/lib/input/TextArea';
import isArray from 'lodash/isArray';
import { FormGroup, InputBox } from '../../shared-components/Form/Form';
import {
  ACCEPTED_PNG_IMAGE_EXTENSIONS,
  ACCEPTED_PNG_LOGO_IMAGE,
  IMAGE_PNG_LOGO_PAYLOAD,
} from '../../../constants/file';
// import { validateURL } from '../../../constants';
import Notification from '../../Notification/Notification';
import { TextUpload } from '../../shared-components/Upload';
import { imageValidator } from '../../../utils/common';
import { getRoleId } from '../../../utils/userInfo';
import { PrimaryButton } from '../../shared-components/Button';
import { getThemeByRoleId } from '../../../utils/common';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { getFileS3 } from '../../../utils/s3.storage';
import { SHADOWS } from '../../../styles/constants/shadows';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { DropdownBox } from '../../Form/Form';
import SponsorsInfo from './extras/SponsorsInfo';
import ProfilesLinksList from '../../ProfileLinks/ProfileLinksList';
import { EVENT_SPONSORS_PARTNERS_OPTIONS } from '../../../constants/index';

const SponsorsAndPartners = ({
  type,
  customInput,
  companyInfo,
  uploadEventFile,
  handleFileChange,
  handleAddEntity,
  handleEditCompany,
  handleDeleteEntity,
  setCompanyInfo,
  isTabActive,
}) => {
  const [form] = Form.useForm();
  const linkFrom = `${type}Link`;
  const [isActive, setIsActive] = React.useState(false);
  const roleId = getRoleId();

  const handleAddButtonActivation = () => {
    const { name, pic, type, brief } = companyInfo;
    const links = (form.getFieldValue(linkFrom) || []).map((item) =>
      get(item, `link`, undefined),
    );
    // setCompanyInfo((prevValue) => ({ ...prevValue, links }));
    let validLinks = false;
    if (isArray(links)) {
      const link = get(links, `[${links.length - 1}]`, undefined);
      // Condition: check if link exists & link has length more than 0 ( zero ) & it's valid URL
      if (link && link.length > 0) {
        validLinks = true;
      }
    }

    setIsActive(name && pic && type && links.length && validLinks && brief);
  };

  React.useEffect(() => {
    handleAddButtonActivation();
    // eslint-disable-next-line
  }, [companyInfo, form.getFieldValue(linkFrom)]);

  const handleLogoBeforeUpload = (file) => {
    if (file.size > ACCEPTED_PNG_LOGO_IMAGE.maxFileSize) {
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }

    if (!ACCEPTED_PNG_LOGO_IMAGE.fileTypes.includes(file.type)) {
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }

    return imageValidator({ ...IMAGE_PNG_LOGO_PAYLOAD, file });
  };

  const handleSponsorsName = (e) => {
    const value = get(e, 'target.value');
    const entity = type === 'Partners' ? 'partners' : 'sponsors';
    const res = customInput[entity].find(
      (entityObject) => toLower(entityObject.name) === toLower(value),
    );
    if (res) {
      Notification('error', `Add ${entity}`, `${entity} already exist`);
    }

    setCompanyInfo((prevValue) => ({ ...prevValue, name: value }));
  };

  const handleSponsorsType = (val) => {
    setCompanyInfo((prevValue) => ({ ...prevValue, type: val }));
  };

  const handleDescription = (val) => {
    const value = get(val, 'target.value');
    setCompanyInfo((prevValue) => ({
      ...prevValue,
      brief: value,
    }));
  };

  return (
    <>
      <SponsorsParnersText> {type === 'Sponsors' ? 'Sponsors' : 'Partners'}</SponsorsParnersText>

      <StyledContainer>
        <StyedRow>
          <Col xs={24} md={4}>
            <StyledUploadLogoWrapper>
              <StyledInnerLogoWrapper
                isValidImage={!!getFileS3(companyInfo.pic)}
              >
                <Avatar
                  src={getFileS3(companyInfo.pic)}
                  alt="recruiter-logo"
                />
                <TextUpload
                  roleId={roleId}
                  name="companyLogo"
                  text={getFileS3(companyInfo.pic) ? "Upload logo" : "Add Logo"}
                  icon={<UploadOutlined />}
                  customRequestHandler={(data) =>
                    uploadEventFile(
                      data,
                      type === 'Partners' ? 'partner' : 'sponsor',
                    )
                  }
                  onChange={(data) =>
                    handleFileChange(
                      data,
                      type === 'Partners' ? 'partner' : 'sponsor',
                    )
                  }
                  accept={ACCEPTED_PNG_IMAGE_EXTENSIONS}
                  maxCount={1}
                  beforeUpload={handleLogoBeforeUpload}
                  placement="bottomLeft"
                  id="add_company_logo"
                  hintText="Recommended: PNG with 400px X 400px in lesser than 3MB"
                  cropActive="true"
                  cropOptions={EVENT_SPONSORS_PARTNERS_OPTIONS}
                />
              </StyledInnerLogoWrapper>
            </StyledUploadLogoWrapper>
          </Col>
          <Col xs={24} md={6}>
            <label htmlFor="sponsors_name">
              <StyledInfoHeader id="sponsors_name_label">
                Company
              </StyledInfoHeader>
            </label>
            <InputBox
              name="name"
              placeholder="Enter Name"
              value={companyInfo.name}
              handleChange={(val) => handleSponsorsName(val)}
              rule="text"
              id="sponsors_name"
              required={false}
            />
          </Col>
          <Col xs={24} md={5}>
            <label htmlFor="sponsors_type">
              <StyledInfoHeader id="sponsors_type_label">
                Type
              </StyledInfoHeader>
            </label>
            <DropdownBox
              name="type"
              placeholder="Select Type"
              options={[
                { id: 'Platinum', name: 'Platinum' },
                { id: 'Gold', name: 'Gold' },
                { id: 'Silver', name: 'Silver' },
              ]}
              value={companyInfo.type}
              handleChange={(val) => handleSponsorsType(val)}
              rule="text"
              id="sponsors_type"
              required={false}
            />
          </Col>
        </StyedRow>
        <StyedRow>
          <Col xs={24} md={4} />
          <Col xs={24} md={19}>
            <label htmlFor="speaker_description">
              <StyledInfoHeader id="speaker_designation_label">
                Description
              </StyledInfoHeader>
            </label>
            <TextArea
              name="description"
              required={false}
              placeholder="Description"
              value={get(companyInfo, 'brief', '')}
              onChange={handleDescription}
              autoSize={{ minRows: 3, maxRows: 6 }}
              maxLength={250}
              showCount={true}
              id="speaker_description"
            />
          </Col>
        </StyedRow>
        <StyedRow>
          <Col xs={24} md={4} />
          <StyledCol xs={24} md={19}>
            <FormGroup form={form} name="sponsorsPartners" expand>
              <ProfilesLinksList
                profileLinkText="Profile Link"
                form={form}
                name={linkFrom}
                isSponsors
                rule="customUrlCheck"
                onChangeCallback={handleAddButtonActivation}
              />
            </FormGroup>

            <PrimaryButton
              theme={getThemeByRoleId(roleId)}
              onClick={
                type === 'Partners'
                  ? () => handleAddEntity('partners', form, linkFrom)
                  : () => handleAddEntity('sponsors', form, linkFrom)
              }
              disabled={!isActive}
              margin="10px 0 0 0"
            >
              + Add as {type.slice(0, -1)}
            </PrimaryButton>
          </StyledCol>
        </StyedRow>

        {/* upload official */}
      </StyledContainer>

      <SponsorsInfo
        type={type}
        handleEditCompany={handleEditCompany}
        sponsors={get(
          customInput,
          type === 'Partners' ? 'partners' : 'sponsors',
        )}
        handleFileChange={handleFileChange}
        uploadEventFile={uploadEventFile}
        handleDeleteEntity={handleDeleteEntity}
      />
    </>
  );
};

export default SponsorsAndPartners;

const StyledContainer = styled.div`
  clear: both;
  overflow: hidden;

  .ant-form-item {
    ${MARGIN(0)}
  }

  .ant-form-item-explain {
    display: none;
  }
`;

const StyledInfoHeader = styled.span`
  display: block;
  ${MARGIN(0, 0, 16, 0)}
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    ${MARGIN(0, 0, 8, 0)}
    
  }
`;

const StyledUploadLogoWrapper = styled.div`
  ${MARGIN(20, 0, 0, 0)}
  ${DISPLAY_FLEX('row', 'center', 'center')}

  button {
    height: 40px;
  }
`;

const StyledInnerLogoWrapper = styled.div`
  text-align: center;
  max-width: 140px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 0 auto;
  }

  span.ant-avatar {
    width: 110px;
    height: 110px;
    box-shadow: ${SHADOWS.SHADOW2};
  }
`;

const StyedRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 20px;
  row-gap: 10px !important;
`;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(20, 0, 0, 0)}
  }
`;

const SponsorsParnersText = styled.h2`
  ${MARGIN(10, 0, 0, 0)}
  font-size: ${FONT[17]};
  font-weight: ${FONT_WEIGHTS.BOLD};
`
