import { createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../../constants';
import {
  createJob,
  getJobs,
  getJobById,
  editJob,
  getJobApplicationsById,
  editJobStatus,
} from '../../service/recruiter';
import { isTestModeOn } from '../../utils/test-helper';

const { IDLE, RESOLVED, PENDING, REJECTED } = STATUS;

const onStart = (state) => {
  state.status = PENDING;
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.jobDetailsData = action.payload;
};
const onSuccessJob = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.job = action.payload;
};
const onSuccessJobApplicationsData = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.jobApplicationsData = action.payload;
};

const onError = (state, action) => {
  state.status = REJECTED;
  state.isLoading = false;
  state.error = action.payload;
};

const onEnd = (state) => {
  state.status = IDLE;
};

const onSuccessJobApplicationsPagination = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.jobApplicationPagination = action.payload;
};

const onSuccessJobOpportunitiesPagination = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.jobOpportunitiesPagination = action.payload;
};

// this reducer is changing the refetch status
const onChangeCommunityJobsRefetch = (state, action) => {
  const { flag } = action.payload;
  state.communityJobsRefetch = flag;
};

export const jobDetailsSlice = createSlice({
  name: 'jobDetails',
  initialState: {
    isLoading: false,
    jobDetailsData: [],
    job: {},
    jobApplicationsData: [],
    error: null,
    jobApplicationPagination: null,
    jobOpportunitiesPagination: null,
    communityJobsRefetch: false,
  },
  reducers: {
    onStart,
    onSuccess,
    onError,
    onSuccessJob,
    onSuccessJobApplicationsData,
    onEnd,
    onSuccessJobOpportunitiesPagination,
    onSuccessJobApplicationsPagination,
    onChangeCommunityJobsRefetch,
  },
});

const getJobDetailsData = (
  fields,
  page,
  limit,
  jobStatus = null,
  sorter = null,
) => async (dispatch) => {
  try {
    dispatch(jobDetailsSlice.actions.onStart());
    const { jobs, currentPage, totalItems } = await getJobs(
      fields,
      page,
      limit,
      jobStatus,
      sorter,
    );
    dispatch(jobDetailsSlice.actions.onSuccess(jobs));
    dispatch(
      jobDetailsSlice.actions.onSuccessJobOpportunitiesPagination({
        currentPage,
        totalItems,
      }),
    );
  } catch (err) {
    dispatch(jobDetailsSlice.actions.onError(err.toString()));
  }
};
const getJobByIdData = (id) => async (dispatch) => {
  try {
    dispatch(jobDetailsSlice.actions.onStart());
    const job = await getJobById(id);

    dispatch(jobDetailsSlice.actions.onSuccessJob(job));
  } catch (err) {
    dispatch(jobDetailsSlice.actions.onError(err.toString()));
  }
};

const getJobApplicationsByIdData = (
  id,
  eventId,
  page,
  limit,
  location = null,
  country = null,
  availableStartTime = null,
  applicationFilter = null,
  sorter = null,
  startDate = null,
  endDate = null,
) => async (dispatch) => {
  try {
    dispatch(jobDetailsSlice.actions.onStart());
    const {
      jobApplications,
      currentPage,
      totalItems,
    } = await getJobApplicationsById(
      id,
      eventId,
      page,
      limit,
      location,
      country,
      availableStartTime,
      applicationFilter,
      sorter,
      startDate,
      endDate,
    );
    dispatch(
      jobDetailsSlice.actions.onSuccessJobApplicationsData(jobApplications),
    );
    dispatch(
      jobDetailsSlice.actions.onSuccessJobApplicationsPagination({
        currentPage,
        totalItems,
      }),
    );
  } catch (err) {
    dispatch(jobDetailsSlice.actions.onError(err.toString()));
  }
};
const createJobDetailsData = (data, callback) => async (dispatch) => {
  try {
    dispatch(jobDetailsSlice.actions.onStart());
    await createJob(data);
    callback(null, data.designation);
    dispatch(getJobDetailsData('all', 1, 10));
  } catch (err) {
    callback(err, data.designation);
    dispatch(jobDetailsSlice.actions.onError(err.toString()));
  }
};

const updateJobDetailsData = (id, data, callback) => async (dispatch) => {
  try {
    dispatch(jobDetailsSlice.actions.onStart());
    await editJob(id, data);
    callback(null, data.designation);
    if (isTestModeOn()) {
      dispatch(getJobDetailsData('all', 1, 10));
    }
  } catch (err) {
    callback(err, data.designation);
    dispatch(jobDetailsSlice.actions.onError(err.toString()));
  }
};

const updateJobStatus = (id, data, currentPage, currentLimit) => async (
  dispatch,
) => {
  try {
    dispatch(jobDetailsSlice.actions.onStart());
    await editJobStatus(id, data);
    dispatch(
      getJobDetailsData(
        'all',
        currentPage ? currentPage : 1,
        currentLimit ? currentLimit : 10,
      ),
    );
  } catch (err) {
    dispatch(jobDetailsSlice.actions.onError(err.toString()));
  }
};

export default jobDetailsSlice.reducer;
export const {
  onChangeCommunityJobsRefetch: changeRefetchStatus,
} = jobDetailsSlice.actions;

export {
  getJobDetailsData,
  createJobDetailsData,
  getJobByIdData,
  updateJobDetailsData,
  getJobApplicationsByIdData,
  updateJobStatus,
};
