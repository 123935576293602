/* eslint-disable import/no-cycle */
import get from 'lodash/get';
import api from './api';
import {
  APIs,
  Method,
  OrganizerExploreEventsLimit,
  OrganizerExploreEventsPage,
  PAYMENT_INTENT_KEYS,
} from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../utils/userInfo';
import { getRecruiterRecommendedEvents } from './recruiter';
import { getCandidateRecommendedEvents } from './candidate';

export const getEventById = async (id) => {
  return api({
    url: `${APIs.event}${id}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.event', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const applyPromoCode = async (eventId, promoValue) => {
  return api({
    url: `${APIs.event}check/promo?eventId=${eventId}&promo=${promoValue}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const isCompanysEvent = async (id) => {
  return api({
    url: `${APIs.event}${id}`,
    method: Method.get,
  })
    .then(() => true)
    .catch(() => false);
};

export const getPublicEventById = async (id) => {
  return api({
    url: `${APIs.publicEvent}${id}/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const bookEvent = async (body) => {
  return api({
    url: `${APIs.event}book/`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecommendedEvents = async (
  userUrl,
  page,
  limit,
  isLivePriority = false,
) => {
  return api({
    url: `${userUrl}events/recommended/?page=${page}&limit=${limit}&isLivePriority=${isLivePriority}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.recommendedEvents', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getRecommendedEventsByRole = async (roleId, page, limit) => {
  let events = [];
  if (checkIfUserIsRecruiter(roleId)) {
    events = await getRecruiterRecommendedEvents(page, limit);
  } else if (checkIfUserIsCandidate(roleId)) {
    events = await getCandidateRecommendedEvents(page, limit);
  } else {
    events = await getSimilarEvents(
      OrganizerExploreEventsPage,
      OrganizerExploreEventsLimit,
    );
  }
  return events;
};

export const getNearByEvents = async (userUrl) => {
  return api({
    url: `${userUrl}nearby-events/`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.events', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const broadCastEvent = async (id, body) => {
  return api({
    url: `${APIs.event}${id}/broadcast-link/`,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getCalendarEvents = async (
  userUrl,
  startTimestamp,
  endTimestamp,
) => {
  const timeParam =
    startTimestamp && endTimestamp
      ? `?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`
      : '';

  return api({
    url: `${userUrl}calender/${timeParam}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      const obj = {
        events: get(response, 'data.payload.events', null),
        interviews: get(response, 'data.payload.interviews', null),
      };
      return obj;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEvents = async () => {
  return api({
    url: `${APIs.event}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.events', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getBroadcastEvents = (
  page,
  limit,
  fields = '',
  eventId = null,
) => {
  return api({
    method: Method.get,
    url: `${APIs.eventBroadcast}?page=${page}&limit=${limit}${
      fields ? `&fields=${fields}` : ''
    }${eventId ? `&eventId=${eventId}` : ''}`,
  }).then((response) => {
    if (!isValid(response)) {
      throw new Error(ERRORS.REQUEST_FAILED);
    }

    return get(response, 'data.payload', null);
  });
};

export const switchAutoPresent = async (id, data) => {
  return api({
    url: APIs.turnOnOrOffAutoPresent(id),
    method: Method.put,
    body: data,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const joinEvent = async (id, data, roleId) => {
  return api({
    url: checkIfUserIsCandidate(roleId)
      ? APIs.eventCandidateAttendence(id)
      : APIs.eventRecruiterAttendence(id),
    method: Method.put,
    body: data,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const payment = async (data) => {
  return api({
    url: APIs.payment,
    method: Method.post,
    body: data,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.success', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const confirmBooking = async (id, body) => {
  return api({
    url: APIs.confirmBooking(id),
    method: Method.put,
    body,
  });
};

export const bookEventWithInviteCode = async (body) => {
  return api({
    url: `${APIs.event}book/invite`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkIfEventIsOver = async (id) => {
  return api({
    url: `${APIs.event}${id}/over`,
    method: Method.get,
  });
};

export const getBookedEventInfo = (eventId) => {
  return api({
    url: `${APIs.event}check/event/${eventId}`,
    method: Method.get,
    errorHandlingMessage: false,
  });
};

export const unsubscribeInviteMail = (inviteToken, roleId) => {
  return api({
    url: `${APIs.event}unsubscribe/invite/mail`,
    method: Method.put,
    body: {
      inviteToken,
      roleId,
    },
  });
};
export const checkIfCandidateIsPresentInEvent = async (id) => {
  return api({
    url: APIs.checkIfCandidateIsPresent(id),
    method: Method.get,
  });
};

export const checkIfCompanyIsPresentInEvent = async (id) => {
  return api({
    url: APIs.checkIfCompanyIsPresent(id),
    method: Method.get,
  });
};

export const getSimilarEvents = async (page = 1, limit = 10) => {
  return api({
    url: `${APIs.organizerSimilarEvents}?page=${page}&limit=${limit}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.similarEvents', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getEvaluationDeadlineDateTime = async (eventId, companyId) => {
  return api({
    url: `${APIs.eventRecruiterEvaluationDeadlineDateTime(eventId, companyId)}`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      return get(response, 'data.payload.evaluationDeadlineDateTime', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getPaymentIntent = async (
  body,
  key = PAYMENT_INTENT_KEYS.CLIENT_SECRET,
) => {
  return api({
    url: `${APIs.event}create/payment-intent?key=${key}`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getSearchedEventsAndCompanies = async (searchText) => {
  return api({
    url: `${APIs.public}search/event/autocomplete/?search=${searchText}`,
    method: Method.get,
    auth: false,
  })
    .then((response) => {
      return get(response, 'data.payload', {});
    })
    .catch(() => {
      return Promise.reject(ERRORS.REQUEST_FAILED);
    });
};

export const getSearchedAndFilteredEvents = async (
  page,
  limit,
  search = '',
  category = '',
  filter = 'liveandupcoming',
  eventType = '',
) => {
  return api({
    url: `${
      APIs.public
    }search/event?page=${page}&limit=${limit}&search=${search}&category=${category}&filter=${filter}${
      eventType?.length ? `&eventType=${eventType}` : ''
    }`,
    method: Method.get,
  })
    .then((response) => {
      const totalItems = get(response, 'data.payload.totalItems', 9);
      const currentPage = get(response, 'data.payload.currentPage', 1);
      const events = get(response, 'data.payload.events', []);

      return {
        totalItems,
        currentPage,
        events,
      };
    })
    .catch(() => {
      return Promise.reject(ERRORS.REQUEST_FAILED);
    });
};

export const getCandidatesSimilarEvents = async (id, filter) => {
  return api({
    url: `${APIs.event}${id}/recommended/?page=1&limit=-1${
      filter ? `&filter=${filter}` : ''
    }`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload.recommendedEvents', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const removePromoUse = async (id) => {
  return api({
    url: `${APIs.event}${id}/ticket/promo-code/remove/`,
    method: Method.put,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
