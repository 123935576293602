import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isInteger from 'lodash/isInteger';
import PieChartAndSkills from './PieChartAndSkills';
import { RenderButtonTags } from '../Common';
import { StyledMediumHeading } from '../../shared-components/Texts';
import {
  getUserBackgroundColorWithRoleId,
  getUserColorWithRoleId,
} from '../../../styles/utils';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { checkIfUserIsCandidate } from '../../../utils/userInfo';

export const SkillsAndTypesSection = React.memo(({
  event,
  roleId,
  customStyle = null,
}) => {
  const { meta, domains } = event;
  if (!isObject(meta) || !isArray(domains)) {
    return null;
  }

  const { jobOpportunities, eventSkills } = meta;
  if (!isObject(jobOpportunities) || !isObject(eventSkills)) {
    return null;
  }

  const { typesOfJob, typesOfEngagement } = jobOpportunities;
  if (!isArray(typesOfJob) || !isArray(typesOfEngagement)) {
    return null;
  }

  const { technical, management, technoFunctional } = eventSkills;
  if (
    !isInteger(technical) ||
    !isInteger(management) ||
    !isInteger(technoFunctional)
  ) {
    return null;
  }

  return customStyle ? (
    <StyledMainContainer>
      <StyledWrapper>
        <div xs={24} lg={12} xl={12}>
          <StyledMediumHeading
            color={COLORS.ARSENIC}
            fontWeight={FONT_WEIGHTS.SEMI_BOLD}
            margin="0 0 15px 0"
          >
            Industry
          </StyledMediumHeading>
          <StyledFlex>
            {domains.map((domain, i) => {
              return (
                <RenderButtonTags
                  text={domain.name}
                  key={i.toString()}
                  color={getUserColorWithRoleId(roleId)}
                  backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                />
              );
            })}
          </StyledFlex>
        </div>
        <div xs={24} lg={12} xl={12}>
          <StyledMediumHeading
            color={COLORS.ARSENIC}
            fontWeight={FONT_WEIGHTS.SEMI_BOLD}
            margin="0 0 15px 0"
          >
            Job Types
          </StyledMediumHeading>
          <StyledFlex>
            {typesOfJob.map((type, i) => {
              return (
                <RenderButtonTags
                  text={type}
                  key={i.toString()}
                  color={getUserColorWithRoleId(roleId)}
                  backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                />
              );
            })}
          </StyledFlex>
        </div>
        <div xs={24} lg={12} xl={12}>
          <StyledMediumHeading
            color={COLORS.ARSENIC}
            fontWeight={FONT_WEIGHTS.SEMI_BOLD}
            margin="0 0 15px 0"
          >
            Engagement
          </StyledMediumHeading>
          <StyledFlex>
            {typesOfEngagement.map((type, i) => {
              return (
                <RenderButtonTags
                  text={type}
                  key={i.toString()}
                  color={getUserColorWithRoleId(roleId)}
                  backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                />
              );
            })}
          </StyledFlex>
        </div>
        <StyledPieChart
          xs={24}
          lg={12}
          xl={12}
          isCandidate={checkIfUserIsCandidate(roleId)}
        >
          <PieChartAndSkills
            technical={technical}
            management={management}
            technoFunctional={technoFunctional}
          />
        </StyledPieChart>
      </StyledWrapper>
    </StyledMainContainer>
  ) : (
    <StyledMainContainer>
      <Row gutter={[0, 0]}>
        <Col xs={24} lg={12} xl={14}>
          <StyledInnerLeftWrapper gutter={[10, 10]}>
            <StyledCol xs={24} lg={12} xl={10}>
              <StyledMediumHeading
                color={COLORS.ARSENIC}
                fontWeight={FONT_WEIGHTS.SEMI_BOLD}
                margin="0 0 15px 0"
              >
                Industry
              </StyledMediumHeading>
              {domains.map((domain, i) => {
                return (
                  <RenderButtonTags
                    text={domain.name}
                    key={i.toString()}
                    color={getUserColorWithRoleId(roleId)}
                    backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                  />
                );
              })}
            </StyledCol>
            <Col xs={24} lg={12} xl={7}>
              <StyledMediumHeading
                color={COLORS.ARSENIC}
                fontWeight={FONT_WEIGHTS.SEMI_BOLD}
                margin="0 0 15px 0"
              >
                Job Types
              </StyledMediumHeading>
              {typesOfJob.map((type, i) => {
                return (
                  <RenderButtonTags
                    text={type}
                    key={i.toString()}
                    color={getUserColorWithRoleId(roleId)}
                    backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                  />
                );
              })}
            </Col>
            <Col xs={24} lg={12} xl={7}>
              <StyledMediumHeading
                color={COLORS.ARSENIC}
                fontWeight={FONT_WEIGHTS.SEMI_BOLD}
                margin="0 0 15px 0"
              >
                Engagement
              </StyledMediumHeading>
              {typesOfEngagement.map((type, i) => {
                return (
                  <RenderButtonTags
                    text={type}
                    key={i.toString()}
                    color={getUserColorWithRoleId(roleId)}
                    backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                  />
                );
              })}
            </Col>
          </StyledInnerLeftWrapper>
        </Col>

        <StyledRightCol xs={24} lg={12} xl={10}>
          <PieChartAndSkills
            technical={technical}
            management={management}
            technoFunctional={technoFunctional}
          />
        </StyledRightCol>
      </Row>
    </StyledMainContainer>
  );
},
);

const StyledFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledPieChart = styled.div`
  display: flex;
  margin-left: -20px;
  margin-top: 25px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: 0;
    margin-top: 0;
  }
`;

const StyledMainContainer = styled.div`
  margin-top: 10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0px;
  }
`;

const StyledWrapper = styled.div`
  row-gap: 30px;
  margin-top: 20px;
  column-gap: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  button {
    ${MARGIN(0, 8, 8, 0)};
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

const StyledInnerLeftWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-top: 15px;
  margin-left: -5px;
  margin-right: -5px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0px;
    row-gap: 15px;
  }

  button {
    ${MARGIN(0, 8, 8, 0)};

    span {
      font-size: 12px;
      word-break: break-all;

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: 16px;
      }
    }
  }
`;

const StyledCol = styled(Col)`
  overflow: hidden;
`;

const StyledRightCol = styled(Col)`
  ${DISPLAY_FLEX(null, 'flex-end', 'center')};

  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
    margin: 20px 0;
    column-gap: 30px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 15px 0;
    column-gap: 20px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_4}) {
    column-gap: 0;
  }
`;
