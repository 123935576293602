import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Tooltip } from 'antd';
import {
  ClockCircleOutlined,
  UploadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import get from 'lodash/get';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import { getProfileDataWithoutReload } from '../../store/features/profile';
import {
  convertEmployementType,
  updateResume,
  updateCertificates,
  getFilePath,
} from '../../utils/profile';
import {
  NO_TEXT,
  STATUS_PENDING,
  UploadResumeSupportedFiles,
  YES,
  YES_TEXT,
  STATUS_DELETE,
  SkillLevelToTimelineMap,
  CANDIDATE_COLOR,
  CANDIDATE,
} from '../../constants';
import ProfileLinksComponent from '../ProfileLinks/ProfileLinks';
import { UploadDocumentNotification } from '../Notification/UploadPictureNotification';
import { checkIfUserIsRecruiter, getRoleId } from '../../utils/userInfo';
import Certificates from './Certificates';
import { getSkillName } from '../../utils/skill';
import { getCurrencyById, formatCurrency } from '../../utils/currencyConvertor';
import { DocumentViewer } from '../shared-components/DocumentViewerModal';
import {
  ProfileInformationHeader,
  ProfileInformationValue,
} from '../CrewManagement/common';
import { SubTitles, TitleContainer } from './common';
import { PageContainer } from '../shared-components/PageContainer';
import { DISPLAY_FLEX, MARGIN, MT, PADDING } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import {
  StyledMediumFont,
  StyledMediumHeading,
  StyledSmallHeading,
} from '../shared-components/Texts';
import GoogleDrivePicker from '../Common/GoogleDrivePicker';
import { TextUploadFileList, ToolTippedInfo } from '../shared-components/Upload';
import { useTagManager } from '../../utils/customHooks';
import Notification from '../Notification/Notification';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getUserColorWithRoleId } from '../../styles/utils';


const JobRelatedDetails = ({
  profileData,
  skills,
  setResume,
  setCertificationsList,
  certificationsList,
  resume,
  role,
  isAdmin = false,
  currencyData,
}) => {
  const roleId = getRoleId();
  const dispatch = useDispatch();
  const updateUserActionInGTM = useTagManager();
  const resumeOnChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    setResume(fileList);
    UploadDocumentNotification(info);
  };

  const DEFAULT_LIMIT_SIZE = 5242880;
  const [upload, setUpload] = React.useState(false);

  const groupedSkills = groupBy(skills, 'level');

  const updateResumeCustomReq = ({ onSuccess, onError, file }) => {
    setResume([{ ...file, name: file.name, isLoading: true }]);
    updateResume(file)
      .then((response) => {
        // setResume([])
        setResume([
          {
            ...file,
            name: file.name,
            url: getFilePath(response),
            isLoading: false,
          },
        ]);
        onSuccess();
        dispatch(getProfileDataWithoutReload());
        updateUserActionInGTM('resume_uploaded');
      })
      .catch(() => {
        onError();
      });
  };

  const certificationOnChange = (info) => {
    const fileList = [...info.fileList];

    if (upload) {
      setCertificationsList(fileList);
      UploadDocumentNotification(info);
    }
  };

  const UpdateCertificationCustomReq = ({ onSuccess, onError, file }) => {
    const certificatesPayload = [...certificationsList];
    certificatesPayload.push({
      uid: file.uid,
      certificateName: file.name,
      fileName: file,
    });

    updateCertificates(certificatesPayload)
      .then(() => {
        onSuccess();
        dispatch(getProfileDataWithoutReload());
      })
      .catch(() => {
        onError();
      });
  };

  const onSuccessResumeDrive = () => {
    dispatch(getProfileDataWithoutReload());
  };

  const SkillRow = ({ skill, index }) => {
    const skillName = getSkillName(skill.skill);
    const status = get(skill, 'skill.status', STATUS_PENDING);
    return (
      <StyledSkillRow key={index.toString()}>
        <Col md={12} xs={16}>
          <StyledSKillBlack>{skillName}</StyledSKillBlack>
        </Col>
        {status === STATUS_PENDING ? (
          <Col md={12} xs={8}>
            <StyledSKillLevelValue>
              <Tooltip title="Pending">
                <ClockCircleOutlined />
              </Tooltip>
            </StyledSKillLevelValue>
          </Col>
        ) : null}
        {status === STATUS_DELETE ? (
          <Col md={12} xs={8}>
            <StyledSKillLevelValue>
              <Tooltip title="Rejected">
                <CloseOutlined />
              </Tooltip>
            </StyledSKillLevelValue>
          </Col>
        ) : null}
      </StyledSkillRow>
    );
  };

  const GroupedSkills = () => {
    const tempList = Object.entries(groupedSkills).sort(
      (a, b) => b[1].length - a[1].length,
    );
    return (
      <Row>
        {tempList.map((item) => {
          const key = item[0];
          return (
            <Col lg={6} md={12} xs={24}>
              <SubTitles margin="0 0 10px 0">
                <Tooltip
                  title={`Skill experience: ${SkillLevelToTimelineMap[key]}`}
                >
                  {key}
                </Tooltip>
              </SubTitles>

              {groupedSkills[key]
                .map((skill, index) => <SkillRow skill={skill} index={index} />)
                .reverse()}
            </Col>
          );
        })}
      </Row>
    );
  };

  function checkFileBefore(file) {
    if (file.size >= DEFAULT_LIMIT_SIZE) {
      setUpload(false);
      Notification(
        'error',
        'File is too large',
        'Please upload a file of size less than 5 MB',
      );
      return;
    }
    if (file.type !== 'application/pdf') {
      setUpload(false);
      Notification(
        'error',
        'Invalid file type',
        'Please upload a pdf file only',
      );
      return;
    }
    setUpload(true);
  }

  return (
    <PageContainer>
      <TitleContainer roleId={roleId}>Job Profile</TitleContainer>

      <div>
        <TitleContainer roleId={roleId} marginTop={25}>
          Resume
        </TitleContainer>
        {checkIfUserIsRecruiter(role) ? (
          resume.map((item) => (
            <DocumentViewer filePath={item.url} fileName={item.name} />
          ))
        ) : (
          <>
            {resume.map((item) => (
              <DocumentViewer
                filePath={item.url}
                fileName={item.name}
                isLoading={item.isLoading}
              />
            ))}

            {!isAdmin ? (
              <>
                <StyledResumeText>Upload Your Resume</StyledResumeText>
                <StyledRow>
                  <TextUploadFileList
                    name="uploadResume"
                    icon={<UploadOutlined />}
                    accept={UploadResumeSupportedFiles}
                    customRequest={updateResumeCustomReq}
                    onChange={upload && resumeOnChange}
                    showUploadList={false}
                    fileList={resume}
                    id="candidate_resume_upload"
                    showTooltip={false}
                    beforeUpload={(file) => checkFileBefore(file)}
                  />
                  <GoogleDrivePicker
                    setResume={setResume}
                    onSuccessResumeDrive={onSuccessResumeDrive}
                  />
                  <ToolTippedInfo
                    backgroundColor={getUserColorWithRoleId(CANDIDATE)}
                    hintText="Please upload your resume in PDF format"
                    top="0px"
                  />
                  <StyledHintText>
                    <span>or </span>
                    <StyledUploadInformationSpan>
                      <StyledCandidateLink
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download your Linkedin Resume & Upload (Only PDF)
                      </StyledCandidateLink>
                    </StyledUploadInformationSpan>
                  </StyledHintText>
                </StyledRow>
              </>
            ) : null}
          </>
        )}
      </div>

      <div>
        <TitleContainer roleId={roleId} marginTop={35}>
          Skill Set
        </TitleContainer>
        <GroupedSkills />
      </div>

      <div>
        <TitleContainer roleId={roleId} marginTop={40}>
          Additional Information
        </TitleContainer>

        <Row gutter={[16, 24]}>
          <Col xs={24} md={6}>
            <ProfileInformationHeader>
              Availability to Start
            </ProfileInformationHeader>
            <ProfileInformationValue>
              {profileData.startAvailability}
            </ProfileInformationValue>
          </Col>
          <Col xs={24} md={6}>
            <ProfileInformationHeader>Expected Salary</ProfileInformationHeader>
            <ProfileInformationValue>
              {profileData.expectedCtc &&
                formatCurrency(
                  profileData.expectedSalaryCurrency
                    ? get(
                      getCurrencyById(
                        currencyData,
                        profileData.expectedSalaryCurrency.id,
                      ),
                      'value',
                      profileData.expectedCtcCurrency,
                    )
                    : profileData.expectedCtcCurrency,
                  profileData.expectedCtc,
                )}
            </ProfileInformationValue>
          </Col>
          <Col xs={24} md={6}>
            <ProfileInformationHeader>
              Willing to Relocate
            </ProfileInformationHeader>
            <ProfileInformationValue>
              {profileData.willingToRelocate
                ? profileData.willingToRelocate === YES
                  ? YES_TEXT
                  : NO_TEXT
                : null}
            </ProfileInformationValue>
          </Col>
          <Col xs={24} md={6}>
            <ProfileInformationHeader>Employment Type</ProfileInformationHeader>
            <StyledRowValue>
              {profileData.employmentType && profileData.employmentType.type
                ? profileData.employmentType.type.map((el, idx) => {
                  return (
                    <StyledCandidateTags key={idx}>
                      {convertEmployementType(el)}
                    </StyledCandidateTags>
                  );
                })
                : null}
            </StyledRowValue>
          </Col>
        </Row>

        <StyledIndustryContainer gutter={[16, 24]}>
          <Col xs={24} md={8}>
            <ProfileInformationHeader>Industry</ProfileInformationHeader>
            <StyledRowValue>
              {profileData &&
                profileData.domains &&
                profileData.domains.map((el, idx) => {
                  return (
                    <StyledCandidateTags key={idx}>
                      {el.name}
                    </StyledCandidateTags>
                  );
                })}
            </StyledRowValue>
          </Col>
        </StyledIndustryContainer>

        <div>
          <TitleContainer roleId={roleId} marginTop={35}>
            Upload Certifications
          </TitleContainer>

          {isAdmin || checkIfUserIsRecruiter(role) ? (
            certificationsList.map((item, i) => (
              <>
                <DocumentViewer
                  filePath={item.url}
                  fileName={item.name}
                  key={i.toString()}
                />
              </>
            ))
          ) : (
            <Certificates
              certificationsList={certificationsList}
              UploadResumeSupportedFiles={UploadResumeSupportedFiles}
              UpdateCertificationCustomReq={UpdateCertificationCustomReq}
              certificationOnChange={certificationOnChange}
              checkFileBefore={checkFileBefore}
            />
          )}
        </div>

        <div>
          <TitleContainer roleId={roleId} marginTop={40}>
            Profile Link
          </TitleContainer>
          {profileData.profileLinks && profileData.profileLinks.links ? (
            <ProfileLinksComponent
              profileLinks={profileData.profileLinks.links}
            />
          ) : null}
        </div>
      </div>
    </PageContainer>
  );
};

export default JobRelatedDetails;

const StyledRowValue = styled(Row)`
  ${ProfileInformationHeader}
`;

const StyledIndustryContainer = styled(Row)`
  ${MARGIN(20, 0, 0, 0)}
`;

const StyledResumeText = styled(ProfileInformationValue)`
  ${MARGIN(10, 0, 20, 0)}
`;

const StyledSkillRow = styled(Row)`
  ${MARGIN(0, 0, 16, 0)}
`;

const StyledSKillBlack = styled(StyledMediumHeading)`
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledSKillLevelValue = styled(StyledSmallHeading)`
  color: ${COLORS.RICH_BLUE};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledCandidateTags = styled(StyledMediumFont)`
  background-color: ${COLORS.CULTURED};
  max-width: max-content;
  ${PADDING(5, 10)}
  ${MARGIN(3)}
`;

const StyledRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 10px;

  .ant-upload {
    button {
      height: 40px;
    }
  }
`;

const StyledHintText = styled.span`
  ${MT(6)};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const StyledUploadInformationSpan = styled.span`
  color: ${CANDIDATE_COLOR};

  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_2}) {
    ${MT(15)};
  }
`;

const StyledCandidateLink = styled.a`
  color: inherit;
  cursor: pointer !important;
  font-size: ${FONT[14]};
  &:hover {
    color: inherit;
  }
`;
