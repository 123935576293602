import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Table } from 'antd';
import toLower from 'lodash/toLower';
import { getInvitedCompanies } from '../../../service/masterAdmin';
import CustomPagination from '../../../components/Common/CustomPagination';
import {
  companyColumns,
  makeCompaniesTableData,
} from '../../../utils/admin/event';
import { handleExportCsv } from '../../../utils/admin/bulkInvite';
import { DEFAULT_LIMIT, DEFAULT_PAGE, RECRUITER } from '../../../constants';
import { InvitedAttendeesTableHeader } from './commons';
import { useSearchDebounce } from '../../../components/Common/CustomHooks';
import { StyledScrollableContainer } from '../../../components/CrewManagement/CrewMembers';
import { RightAlignContainer } from '../../../components/shared-components/RightAlignContainer';

const Companeies = ({ history, event }) => {
  const [recruiters, setRecruiters] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT);
  const [profileFilter, setProfileFilter] = useState(0);
  const [registerFilter, setRegisterFilter] = useState(0);
  const [search, setSearch] = useSearchDebounce();
  const [joinFilter, setJoinFilter] = useState(0);
  const [pagination, setPagination] = useState({
    currentPage: null,
    totalItems: null,
  });

  const populateData = async (
    page,
    limit,
    profileFilter,
    registerFilter,
    joinFilter,
    search,
  ) => {
    if (event && event.id) {
      const res = await getInvitedCompanies(
        event.id,
        page,
        limit,
        profileFilter,
        registerFilter,
        joinFilter,
        search,
      );

      const recruiterTableData = makeCompaniesTableData(
        get(res, 'data.payload.clients', []),
        () =>
          populateData(
            currentPage,
            currentLimit,
            profileFilter,
            registerFilter,
            joinFilter,
            search,
          ),
        history,
      );
      setRecruiters(recruiterTableData);
      setFilteredDataSource(recruiterTableData);
      setPagination({
        currentPage: get(res, 'data.payload.currentPage', null),
        totalItems: get(res, 'data.payload.totalItems', null),
      });
    }
  };

  useEffect(() => {
    populateData(
      DEFAULT_PAGE,
      DEFAULT_LIMIT,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    );

    // eslint-disable-next-line
  }, [event]);

  const handleSearch = (e) => {
    const text = toLower(get(e, 'target.value', ''));
    setSearch(text);
  };

  useEffect(() => {
    populateData(
      currentPage,
      currentLimit,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    );

    // eslint-disable-next-line
  }, [
    currentPage,
    currentLimit,
    profileFilter,
    registerFilter,
    joinFilter,
    search,
  ]);

  const onPageChangeHandler = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onLimitChangeHandler = async (pageNumber, limit) => {
    setCurrentPage(pageNumber);
    setCurrentLimit(limit);
  };

  const handleProfileFilterChange = async (id) => {
    setProfileFilter(id);
  };

  const handleEventRegistrationFilterChange = async (id) => {
    setRegisterFilter(id);
  };

  const handleEventJoinFilterChange = async (id) => {
    setJoinFilter(id);
  };

  const handleExport = (setIsExporting) => {
    handleExportCsv(
      event,
      RECRUITER,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
      setIsExporting,
    );
  };

  return (
    <>
      <InvitedAttendeesTableHeader
        searchPlaceHolder="Search By company"
        handleSearch={handleSearch}
        handleExport={handleExport}
        handleProfileFilterChange={handleProfileFilterChange}
        handleEventRegistrationFilterChange={
          handleEventRegistrationFilterChange
        }
        handleEventJoinFilterChange={handleEventJoinFilterChange}
      />
      <StyledScrollableContainer>
        <Table
          columns={companyColumns}
          dataSource={filteredDataSource}
          pagination={false}
        />
      </StyledScrollableContainer>
      <RightAlignContainer>
        <CustomPagination
          currentPage={pagination.currentPage || currentPage}
          totalItems={pagination.totalItems || recruiters.length}
          onPageChange={onPageChangeHandler}
          onLimitChange={onLimitChangeHandler}
          currentLimit={currentLimit}
        />
      </RightAlignContainer>
    </>
  );
};

export default Companeies;
