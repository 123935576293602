import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Button, Col, Row } from 'antd';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { LargeFont, MediumFont } from '../Texts';
import { DateAndTypeSection } from '../Event/DateAndTypeSection';
import { EditEventIcon } from '../../svgicons';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  BroadcastEventProps,
  IBroadcastLink,
} from '../../../interfaces/components/Event/broadcastEvent';
import {
  DISPLAY_FLEX,
  MARGIN,
  TEXT_WRAP,
} from '../../../styles/constants/display';
import { EventTitle } from '../Event/Title';
import { HiddenMobile } from '../DocumentViewerModal';
import { ORGANIZER } from '../../../constants';
import { getUserColorWithRoleId } from '../../../styles/utils';

export const BroadcastEvent: React.FC<BroadcastEventProps> = ({
  event,
  onEdit,
  roleId = ORGANIZER,
}) => {
  const links = get(event, 'streamUrlsData', []);
  return (
    <BroadcastEventContainer>
      <Col xs={24}>
        <EventTitle>
          <HiddenMobile>Event Info : &nbsp;&nbsp;</HiddenMobile>
          {event.name}
        </EventTitle>
        <div className="action_sec">
          <DateAndTypeSection event={event} isLeft={false} />
        </div>
        <BroadCastLinkContainer>
          <Col xs={8}>
            <LargeFont>Broadcast Title</LargeFont>
          </Col>
          <Col offset={1} xs={14}>
            <LargeFont>Broadcast Link</LargeFont>
          </Col>
        </BroadCastLinkContainer>
        {links && links.length ? (
          links.map((link: IBroadcastLink) => {
            return (
              <BroadcastLinks>
                <BroadcastLinkContent xs={8}>
                  <MediumFont> {link.title}</MediumFont>
                </BroadcastLinkContent>
                <BroadcastLinkContent offset={1} xs={14}>
                  <MediumFont> {link.url}</MediumFont>
                </BroadcastLinkContent>
              </BroadcastLinks>
            );
          })
        ) : (
          <MediumFont>
            Please add stream urls here to make the event broadcast avaliable to
            the attendees
          </MediumFont>
        )}
      </Col>
      <EditLinkButton
        icon={<EditEventIcon />}
        color={getUserColorWithRoleId(roleId)}
        onClick={onEdit}
      >
        Edit Link
      </EditLinkButton>
    </BroadcastEventContainer>
  );
};

const BroadcastEventContainer = styled(Row)`
  background-color: ${COLORS.LIGHT_BLACK};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23707070C9' stroke-width='1' stroke-dasharray='5%2c5' stroke-dashoffset='25' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  ${MARGIN(20, 0, 0)}

  padding: 25px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: 15px;
  }
`;

const BroadCastLinkContainer = styled(Row)`
  ${MARGIN(8, 0)}

  font-weight: ${FONT_WEIGHTS.BOLD};
`;

const BroadcastLinks = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}

  margin: 10px 0;
`;

const BroadcastLinkContent = styled(Col)`
  ${TEXT_WRAP()}

  display: block;
`;

const EditLinkButton = styled(Button)`
  &.ant-btn {
    ${DISPLAY_FLEX('row', null, 'center')}

    position: absolute;
    right: 20px;
    top: 20px;
    border-color: ${(props) => props.color};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      right: 10px;
      top: 10px;
      padding: 2px 8px;
    }

    svg {
      width: 15px;
      height: 15px;
      fill: ${(props) => props.color};
      margin-right: 5px;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        width: 10px;
        height: 10px;
      }
    }

    span {
      font-size: ${FONT[12]};
      color: ${(props) => props.color};
    }
  }
`;
