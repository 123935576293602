import React from 'react';
import styled from 'styled-components';
import { StyledMediumFont } from '../Texts';
import { COLORS } from '../../../styles/constants/colors';
import { CalendarIcon, LocationPinIcon, MonitorWaveIcon } from '../../svgicons';
import { ONLINE_EVENT_TEXT } from '../../../constants';
import { isEventOnline } from '../../../utils/event';
import { ongoingEventDateTimeFormat } from '../../../utils/formatDateTime';
import {
  DateAndTimeContainer,
  DateAndTimeContent,
} from '../../../interfaces/components/Event/viewEvent';
import {
  DISPLAY,
  DISPLAY_FLEX,
  MARGIN,
} from '../../../styles/constants/display';
import { DateAndTypeSectionProps } from '../../../interfaces/components/Event/broadcastEvent';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { useTimeZone } from '../../../utils/customHooks';

export const DateAndTypeSection: React.FC<DateAndTypeSectionProps> = ({
  event,
  isLeft = false,
  color = COLORS.PHILLIPPINE_GRAY,
  marginBottom,
}) => {
  const timeZone = useTimeZone(event);
  return (
    <StyledDateTypeContainer display={isLeft ? DISPLAY.BLOCK : DISPLAY.FLEX}>
      <StyledDateTypeContent
        display={isLeft ? DISPLAY.BLOCK : DISPLAY.FLEX}
        marginRight={isLeft ? 0 : 25}
        color={color}
        marginBottom={marginBottom}
      >
        <StyledMediumFont>
          <span>
            <CalendarIcon />
          </span>
          {`${ongoingEventDateTimeFormat(
            event.dateTime,
          )} to ${ongoingEventDateTimeFormat(event.endDateTime)} ${timeZone}`}
        </StyledMediumFont>
      </StyledDateTypeContent>

      {isEventOnline(event.eventType) ? (
        <StyledDateTypeContent
          display={isLeft ? DISPLAY.BLOCK : DISPLAY.FLEX}
          color={color}
          marginBottom={marginBottom}
        >
          <StyledMediumFont>
            <span>
              <MonitorWaveIcon />
            </span>
            {ONLINE_EVENT_TEXT}
          </StyledMediumFont>
        </StyledDateTypeContent>
      ) : (
        <StyledDateTypeContent
          display={isLeft ? DISPLAY.BLOCK : DISPLAY.FLEX}
          color={color}
        >
          <StyledMediumFont>
            <span>
              <LocationPinIcon />
            </span>
            {`${event.country}, ${event.zip}`}
          </StyledMediumFont>
        </StyledDateTypeContent>
      )}
    </StyledDateTypeContainer>
  );
};

const StyledDateTypeContainer = styled.div<DateAndTimeContainer>`
  display: ${(props) => props.display};
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block;
  }
`;

const StyledDateTypeContent = styled.h6<DateAndTimeContent>`
  font-weight: ${FONT_WEIGHTS.REGULAR};
  color: ${(props) => props.color};
  ${(props) => MARGIN(0, props.marginRight, 6)};
  line-height: 23px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${(props) => MARGIN(0, props.marginRight, props.marginBottom || 10)};
  }

  div {
    display: flex;
  }

  span {
    ${DISPLAY_FLEX('row', null, 'center')}
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: max-content;
      margin-top: 4px;
    }

    svg {
      fill: ${(props) => props.color};
      ${MARGIN(null, 15)};

      width: 18px;
      height: 18px;
      position: relative;
      top: 0;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        width: 16px;
        height: 16px;
      }

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
