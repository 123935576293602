import get from 'lodash/get';

// Manage companies helpers

export const manageCompaniesTabs = {
  organizer: '1',
  recruiter: '2',
};

export const manageCompaniesTabItems = [
  { name: "Organizer's List", key: '1' },
  { name: "Recruiter's List", key: '2' },
];

export const getSelectedCompanies = (rows, companies) => {
  return companies.filter((company) => rows.includes(company.id));
};

export const handleDisableButton = (row) => {
  return row.length < 2;
};

export const getTabBasedOnPath = (match, AdminRoutes) => {
  const path = get(match, 'path', '');
  if (path === AdminRoutes.recruiter.recruiters) {
    return manageCompaniesTabs.recruiter;
  }
  return manageCompaniesTabs.organizer;
};

// API role for api payload
export const APIRoles = {
  organizer: 2,
  recruiter: 3,
};

// Merge companies confirmation

export const getSecondaryCompanies = (companies, selectedCompanyId) => {
  if (companies.length && selectedCompanyId) {
    return companies.filter((company) => company.id !== selectedCompanyId);
  }
};

export const getPrimaryCompany = (companies, selectedCompanyId) => {
  if (companies.length && selectedCompanyId) {
    return companies.filter((company) => company.id === selectedCompanyId);
  }
};

// Merged company details

export const getSecondaryMergedCompanies = (companyDetails) => {
  return get(companyDetails, 'secondaryCompanies', []);
};

export const getPrimaryMergedCompany = (companyDetails) => {
  const primaryCompany = get(companyDetails, 'primaryCompany', null);
  const representativeName = get(
    primaryCompany,
    'companyOrganizers[0].user.name',
    '',
  );
  const representativeEmail = get(
    primaryCompany,
    'companyOrganizers[0].user.email',
    '',
  );

  return [{ ...primaryCompany, representativeName, representativeEmail }];
};

// Merged company history
export const handleDisableContinueButton = (row) => {
  return row.length !== 1;
};

export const getMergedHistoryTabBasedOnPath = (match, AdminRoutes) => {
  const path = get(match, 'path', '');
  if (path === AdminRoutes.recruiter.mergedCompaniesHistory) {
    return manageCompaniesTabs.recruiter;
  }
  return manageCompaniesTabs.organizer;
};

export const getIsCommunityEvent = (event) => {
  return get(event, 'communityEvent', false)
    ? true
    : get(event, 'endDateTime', null) === null
    ? true
    : false;
};

export const hasSameDay = (timestamp1, timestamp2) => {
  const date1 = new Date(timestamp1 * 1000);
  const date2 = new Date(timestamp2 * 1000);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
