import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';

const MandatoryStar = () => {
  return <StyledSpan>*</StyledSpan>;
};

export default MandatoryStar;

const StyledSpan = styled.span`
  color: ${COLORS.CUSTOM_RED};
`;
