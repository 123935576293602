import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Skeleton } from 'antd';
// import { ACTIVE_CRISP_COUNT, CRISP_CLIENT_URL } from '../constants';
import { useDispatch } from 'react-redux';
import { getUrlVars } from '../utils/common';
// import config from '../config';
import SignUpAndLoginModal from './login-signup/SIgnUpAndLogin';
import { DISPLAY_FLEX } from '../styles/constants/display';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import { COLORS } from '../styles/constants/colors';
import Footer from '../components/Footer';
import Header from '../components/Web3Header';
import MainContainer from '../components/Common/MainContainer';
import { CANDIDATE, RETRY_ATTEMPTS, RETRY_INTERVALS } from '../constants';
import { redirectAfterLogin } from '../utils/auth';
import { getRoleId, UserInformation } from '../utils/userInfo';

import { safeLoad } from '../utils/loader';

const AppTour = React.lazy(() => {
  return safeLoad(
    () => import(/* webpackPreload: true */ './AppTour'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const LandingPage = ({ history }) => {
  // const [loginModalOpen, setLoginModalOpen] = useState(true);
  const { isAuthenticated } = UserInformation();
  const userRoleId = getRoleId();
  const dispatch = useDispatch();
  const [isLoginOrSignup, setIsLoginOrSignup] = useState(false);
  const { role } = getUrlVars(get(history, 'location.search', {}));
  const [roleId, setRoleId] = useState(parseInt(role, 10) || CANDIDATE);
  const { inviteToken, eventId, appliedJob, event } = getUrlVars(
    get(history, 'location.search', {}),
  );

  if (isAuthenticated && userRoleId && !isLoginOrSignup) {
    redirectAfterLogin(
      dispatch,
      history,
      userRoleId,
      inviteToken,
      eventId,
      appliedJob,
      event,
    );
    return <></>;
  }

  /*
   const [state, setState] = useState({});

  const handleSocialModalClose = () => {
    setOpenSocialModal(false);
    setLoginModalOpen(true);
  };

  const handleSocialModalOpen = () => {
    setOpenSocialModal(true);
    setLoginModalOpen(false);
  };

  const handleLoginModalCancel = () => {
    setLoginModalOpen(false);
  };

  const handleLoginModalOpen = () => {
    setLoginModalOpen(true);
  };
  */

  // Sign up modal, handlers

  // DISABLED FOR NOW
  // useState(() => {
  //   if (config.crispId && config.crispId.length) {
  //     window.$crisp = [];
  //     window.CRISP_WEBSITE_ID = config.crispId;
  //     const documentObject = document;
  //     const script = documentObject.createElement('script');
  //     if (script && documentObject) {
  //       script.src = CRISP_CLIENT_URL;
  //       script.async = ACTIVE_CRISP_COUNT;
  //       documentObject.getElementsByTagName('head')[0].appendChild(script);
  //     }
  //     return () => {
  //       if (script) {
  //         documentObject.getElementsByTagName('head')[0].removeChild(script);
  //         window.$crisp = [];
  //         window.CRISP_WEBSITE_ID = null;
  //       }
  //     };
  //   }
  // }, []);

  return (
    <div>
      <Header />
      <StyledMainWrapper>
        <MainContainer>
          <StyledInnerContainer>
            <React.Suspense fallback={<Skeleton />}>
              <AppTour roleId={roleId} />
            </React.Suspense>
            <StyledButtonContainer>
              <SignUpAndLoginModal
                roleId={roleId}
                setRoleId={setRoleId}
                setIsSignAction={setIsLoginOrSignup}
              />
            </StyledButtonContainer>
          </StyledInnerContainer>
        </MainContainer>
      </StyledMainWrapper>
      <Footer />
    </div>
  );
};

export default LandingPage;

const StyledMainWrapper = styled.div`
  ${DISPLAY_FLEX('row', 'center')};
  width: 100%;
  padding: 65px 0;
  min-height: 600px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgb(24, 144, 255, 0.108)
  );

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 70px 0;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    padding: 60px 0;
  }

  @media (max-width: 1024px) {
    padding: 50px 0;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    min-height: auto;
    height: auto;
    padding: 20px 0;
    overflow: hidden;
  }
`;

const StyledInnerContainer = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')};
  column-gap: 50px;
  row-gap: 80px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    ${DISPLAY_FLEX('column-reverse')};
  }
`;

const StyledButtonContainer = styled.div`
  align-self: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 15px;
  }
`;

export const StyledWhiteLogo = styled.span`
  position: absolute;
  opacity: 0.15;
  position: absolute;
  z-index: -1;
  svg {
    fill: ${COLORS.GRAY_LIGHT_2};
  }
  width: 620px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 90%;
  }
`;
