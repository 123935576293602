import { Skeleton } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';
import { loadMoreChatMessages } from '../../../store/features/chat';
import SocketContext from '../../../context/socket';
import { TestContext } from '../../../context/testMode';
import ThreadList from './ThreadList';
import { DISPLAY_FLEX, PADDING } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const MessageThreads = () => {
  const dispatch = useDispatch();
  const client = useContext(SocketContext);
  const testMode = useContext(TestContext);

  const {
    selectedChatId,
    selectedChatMessages,
    personalChatsPagination,
    personalChatMessagesLoading,
    hasMorePersonalChatMessages,
  } = useSelector((state) => state.chat);

  const page = get(personalChatsPagination, 'messagesPage', 1);

  // Pagination
  // We are setting up IntersectionObserver's instance to observe a reference of the element <p className="chats__loading" ref={lastThreadRef}>Loading</p>.
  // If its visible on the DOM, we are dispatching an action to load more chats.
  const observer = useRef();
  const lastThreadRef = useCallback(
    (node) => {
      if (personalChatMessagesLoading) return;
      if (!testMode) {
        // isTestMode is used for automated testing
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMorePersonalChatMessages) {
            dispatch(
              loadMoreChatMessages(selectedChatId, selectedChatMessages, page),
            );
          }
        });
        if (node) observer.current.observe(node);
      }
    },
    [
      dispatch,
      personalChatMessagesLoading,
      hasMorePersonalChatMessages,
      selectedChatId,
      page,
      selectedChatMessages,
      testMode,
    ],
  );

  // Real time Chat - Socket Messages Array
  const [newMessages, setNewMessages] = useState([]);

  const messageRef = useRef();

  useEffect(() => {
    setNewMessages([]);
  }, [selectedChatId]);

  useEffect(() => {
    (async () => {
      const roomUrl = `/chat-room/${selectedChatId}/`;
      await client.subscribe(roomUrl, (msg) => {
        setNewMessages((prevMessages) =>
          uniqBy([msg, ...prevMessages], 'messageId'),
        );
        if (messageRef.current) {
          messageRef.current.scrollTo(0, 0);
        }
      });
    })();
  }, [client, selectedChatId]);

  return (
    <StyledMessageThreadBody ref={messageRef}>
      {selectedChatMessages.length ||
      newMessages.length ||
      personalChatMessagesLoading ? (
        <>
          <ThreadList messages={newMessages} />
          <ThreadList messages={selectedChatMessages} />
        </>
      ) : (
        <h1>No new Messages</h1>
      )}
      <StyledChatsLoadingText ref={lastThreadRef}>
        Loading
      </StyledChatsLoadingText>
      {personalChatMessagesLoading ? <Skeleton /> : null}
    </StyledMessageThreadBody>
  );
};

const StyledMessageThreadBody = styled.div`
  ${DISPLAY_FLEX('column-reverse')}
  color: ${COLORS.CHINESE_BLACK};
  ${PADDING(10, 25)}
  overflow: scroll;
  overflow-x: hidden;
  flex-grow: 1;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 15)}
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.ARGENT};
    border-radius: 10px;
    min-height: 30px;
    max-height: 30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.QUICK_SILVER};
  }
`;

const StyledChatsLoadingText = styled.p`
  color: ${COLORS.WHITE};
`;

export default MessageThreads;
