/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown, Menu, Col, Tooltip } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import take from 'lodash/take';
import moment from 'moment';
import {
  CANDIDATE,
  DASHBOARD_ROUTES,
  RECRUITER,
  STATUS_ACTIVE,
} from '../../constants';
import {
  AdminRoutes,
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
  Routes,
} from '../../constants/routes';
import { ToolTipsText } from '../../constants/tooltips';
// Commenting out only for Recorem Lite
/*
import PlacesAutoComplete from '../PlacesAutoComplete/PlacesAutoComplete';
import PinIcon from '../../assets/svg/location_icon.svg';
import RecoremLogo from '../../assets/svg/recorem_color_logo.svg';
*/
import RecoremBetaLogo from '../../assets/svg/Recorem-Beta.svg';
import MenuLogo from '../../assets/svg/hammenu.svg';
import notificationSVG from '../../assets/svg/notificationss.svg';
import { LogoutIcon, UserIcon } from '../svgicons';
import { logoutUser } from '../../store/features/auth';
import { getNotificationsData } from '../../store/features/notifications';
import {
  userProfileStore,
  onBoarding,
  candidateProfileStore,
} from '../../utils/profile';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  checkIfUserIsSuperAdmin,
  getRoleId,
} from '../../utils/userInfo';
import { NotificationStore } from '../../utils/notifications';
import { getFileS3 } from '../../utils/s3.storage';
import { isMobile } from '../../helpers/display';
import { getUpdates } from '../../service/notifications';
import ResponsiveMenu from '../Menu/ResponsiveMenu';
import AdminRecruit from './AdminRecruit';
import { NotificationItem } from '../shared-components/Notifications/NotificationItem';
import { HelloText } from '../Common/HelloText';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import AppSocketContext from '../../context/AppSocket';
import NotificationAudio from '../../assets/audio/notification.wav';
import { getChatNotifications } from '../../service/chat';
import SocketContext from '../../context/socket';
import {
  NotificationsType,
  NOTIFICATION_CLICK_ACTION,
} from '../../constants/notificationsType';
import WalletButton from '../Common/WalletButton';
import { CHAT_USER_TYPE } from '../../constants/chat';
import { useAccount } from 'wagmi';
import {
  showVirtualWalletButton,
  showWalletButton,
} from '../../utils/web3utils';
import WalletDropdown from '../shared-components/Topbar/WalletDropdown';
import RecruiterWallet from '../shared-components/Topbar/RecruiterWallet';
import { useSkipPayment } from '../../hooks/useSkipPayment';
import { clearAllRTKQueryCaches } from '../../store/features/rtkApi/helper';

const TopBar = ({ noRedirect = false }) => {
  const dispatch = useDispatch();
  const roleId = getRoleId();
  const client = useContext(AppSocketContext);
  const chatClient = useContext(SocketContext);
  const { profileData } = userProfileStore();
  const [routingProfile, setRoutingProfile] = useState('');
  const [visible, setVisible] = useState(false);
  const { notifications } = NotificationStore();
  const [profilePic, setProfilePic] = useState(null);
  const { isLoading } = candidateProfileStore();
  const [newUpdates, setNewUpdates] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [chatNotification, setChatNotification] = useState([]);
  const notificationAudio = new Audio(NotificationAudio);
  const { canNavigate, isBlocked } = useSkipPayment();
  const { isConnected: isWalletConnected } = useAccount();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    if (!isLoading && profileData !== {} && isMounted) {
      setProfilePic(getFileS3(profileData.profilePic));
    }
    return () => {
      isMounted = false;
    };
  }, [profileData, isLoading]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setNotificationList(notifications);
    return () => {
      isMounted = false;
    };
  }, [notifications]);

  useEffect(() => {
    async function populateData() {
      if (checkIfUserIsOrganizer(roleId)) {
        setRoutingProfile(OrganizerRoutes.profile);
      } else if (checkIfUserIsRecruiter(roleId)) {
        setRoutingProfile(RecruiterRoutes.profile);
      } else if (checkIfUserIsCandidate(roleId)) {
        setRoutingProfile(CandidateRoutes.profile);
      }
    }
    populateData();
  }, [roleId]);

  const handleLogout = () => {
    if (checkIfUserIsMasterAdmin(roleId) || checkIfUserIsSuperAdmin(roleId)) {
      clearAllRTKQueryCaches(dispatch);
      dispatch(logoutUser(roleId));
      !noRedirect && history.push(Routes.adminLogin);
    } else {
      clearAllRTKQueryCaches(dispatch);
      dispatch(logoutUser(roleId));
      !noRedirect && history.push(Routes.login);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const getNotificationPageUrl = () => {
    if (checkIfUserIsOrganizer(roleId)) {
      return OrganizerRoutes.notifications;
    }
    if (checkIfUserIsRecruiter(roleId)) {
      return RecruiterRoutes.notifications;
    }
    if (checkIfUserIsCandidate(roleId)) {
      return CandidateRoutes.notifications;
    }
    return AdminRoutes.notifications;
  };

  const NotificationList = (
    <StyledNotifyBoxDiv>
      <StyledNotifyMenu>
        <StyledHeaderMenuItem key="Notifications">
          <Col xs={12}>
            <p>Notifications</p>
          </Col>
          <StyledHeaderItemLinkCol xs={12}>
            <StyledHeaderItemViewAllLink to={getNotificationPageUrl()}>
              View All
            </StyledHeaderItemViewAllLink>
          </StyledHeaderItemLinkCol>
        </StyledHeaderMenuItem>
        <StyledHeaderMenuTitle key="general">
          <Col xs={12}>
            <p>General</p>
          </Col>
        </StyledHeaderMenuTitle>
        {!isArray(notificationList) || notificationList.length === 0 ? (
          <StyledTopBarPlaceholderDiv>
            <StyledPlaceholderText>
              You’re all caught up! :)
            </StyledPlaceholderText>
          </StyledTopBarPlaceholderDiv>
        ) : (
          take(notificationList, 4).map((notification, i) => {
            return (
              <NotificationItem
                notification={notification}
                isTopbar
                profileData={profileData}
                key={`general ${i.toString()}`}
                roleId={roleId}
              />
            );
          })
        )}
        <StyledHeaderMenuTitle key="chat">
          <Col xs={12}>
            <p>Chat</p>
          </Col>
        </StyledHeaderMenuTitle>
        {!isArray(chatNotification) || chatNotification.length === 0 ? (
          <StyledTopBarPlaceholderDiv>
            <StyledPlaceholderText>
              You will get all notifications related to chat here.
            </StyledPlaceholderText>
          </StyledTopBarPlaceholderDiv>
        ) : (
          <>
            {take(chatNotification, 4).map((notification, i) => {
              return (
                <NotificationItem
                  notification={notification}
                  isTopbar
                  profileData={profileData}
                  key={`chat ${i.toString()}`}
                  roleId={roleId}
                  isChat
                />
              );
            })}
          </>
        )}
      </StyledNotifyMenu>
    </StyledNotifyBoxDiv>
  );

  const ProfileList = (
    <StyledProifleMenu roleId={roleId}>
      {checkIfUserIsMasterAdmin(roleId) ? null : (
        <Menu.Item
          key="0"
          /** Disabled has now two conditions because of new recruiter
           * For the recruiter flow payment is mandatory now, so after payment recruiter can access all the links they can create profile later so when a new recruiter can view and edit their profile after completing the payment.
           */
          disabled={
            (checkIfUserIsRecruiter(roleId) && !canNavigate()) ||
            (!onBoarding(profileData) &&
              !checkIfUserIsCandidate(roleId) &&
              !checkIfUserIsRecruiter(roleId))
          }
        >
          <Link to={routingProfile}>
            <div>
              <span>
                <UserIcon />
              </span>
              View profile
            </div>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="1">
        <div onClick={handleLogout}>
          <span>
            <LogoutIcon />
          </span>
          Logout
        </div>
      </Menu.Item>
    </StyledProifleMenu>
  );

  const getNotifications = async () => {
    dispatch(getNotificationsData(5));
    const chatNotifications = await getChatNotifications(1, 4);
    const mappedData = get(chatNotifications, 'notifications', []).map(
      (item) => {
        const type = get(item, 'chatRoom.type', 'group');
        const isGroup = type === 'group';
        return {
          isViewed: get(item, 'isViewed', ''),
          title: `${get(item, 'notifications')} new messages${
            isGroup ? ` in ${get(item, 'chatRoom.name', '')}` : ''
          }.`,
          text: isGroup
            ? ''
            : `${get(item, 'sender.name', '')} has sent ${get(
                item,
                'notifications',
              )} new messages.`,
          dateTime: moment(get(item, 'latestNotificationTimestamp')).unix(),
          category: get(item, 'type', NotificationsType.Chat),
          action: get(item, 'action', NOTIFICATION_CLICK_ACTION.chat),
          chatRoom: get(item, 'chatRoom', ''),
          isGroup,
        };
      },
    );
    setChatNotification(mappedData);
    setTimeout(() => setNewUpdates(false), 1000);
  };

  const checkForNewNotifications = async () => {
    const updates = await getUpdates();

    let total = 0;
    let sales = 0;
    let team = 0;
    let update = 0;
    let jobs = 0;
    let chat = 0;
    if (updates) {
      sales = get(updates, 'sales', 0);
      team = get(updates, 'team', 0);
      update = get(updates, 'update', 0);
      jobs = get(updates, 'jobs', 0);
      chat = get(updates, 'chat', 0);
      total = sales + team + update + jobs + chat;
    }
    if (total > 0) {
      setNewUpdates(true);
    } else {
      setNewUpdates(false);
    }
  };

  useEffect(() => {
    checkForNewNotifications();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (client && profileData.userId) {
      (async () => {
        await client.subscribe(
          `/user/${profileData.userId}/notification/`,
          (resp) => {
            setNotificationList([resp, ...notificationList]);
            setNewUpdates(true);
            notificationAudio.play();
          },
        );
      })();
    }

    if (chatClient && profileData.userId) {
      (async () => {
        const chatUserId =
          roleId === CANDIDATE
            ? get(profileData, 'userId', null)
            : get(profileData, 'company.id', null);

        const chatNotificationSubscriptionUrl = `/chat/notification/${CHAT_USER_TYPE[roleId]}/${chatUserId}/`;

        await chatClient.subscribe(chatNotificationSubscriptionUrl, (resp) => {
          const type = get(resp, 'chatRoom.type', 'group');
          const isGroup = type === 'group';
          setChatNotification([
            {
              isViewed: get(resp, 'isViewed', STATUS_ACTIVE),
              title: `New message ${
                isGroup
                  ? ` in ${get(resp, 'chatRoom.name', '')}`
                  : ` from ${get(resp, 'sender.name')}`
              }`,
              text: isGroup ? '' : `${get(resp, 'text', '')}`,
              dateTime: moment(get(resp, 'timestamp')).unix(),
              category: get(resp, 'type', NotificationsType.Chat),
              action: get(resp, 'action', NOTIFICATION_CLICK_ACTION.chat),
              chatRoom: get(resp, 'chatRoom', ''),
              isGroup,
            },
            ...chatNotification,
          ]);
          setNewUpdates(true);
          notificationAudio.play();
        });
      })();
    }

    // eslint-disable-next-line
  }, [profileData.userId]);

  const getRedirectPath = useCallback(() => {
    if (roleId === RECRUITER && isBlocked()) {
      return;
    }
    return get(DASHBOARD_ROUTES, `${roleId}`, '');
  }, [roleId, isBlocked]);

  return (
    <StyledTopBarHeader>
      <StyledHeaderResideMenuDiv>
        <StyledHeaderMenuIconImg
          src={MenuLogo}
          onClick={() => {
            showDrawer();
          }}
          alt=""
        />
      </StyledHeaderResideMenuDiv>

      <StyledHeaderLogoDiv>
        <Link to={getRedirectPath()}>
          <img src={RecoremBetaLogo} alt="beta-logo" />
        </Link>
      </StyledHeaderLogoDiv>

      <StyledTopBarContainer>
        <StyledInnerMenu>
          {showVirtualWalletButton(false) ? (
            <StyledVirtualWalletWrapper>
              <WalletDropdown />
            </StyledVirtualWalletWrapper>
          ) : null}
          {showVirtualWalletButton(true) ? (
            <StyledVirtualWalletWrapper>
              <RecruiterWallet />
            </StyledVirtualWalletWrapper>
          ) : null}
          {showWalletButton() && !isWalletConnected ? (
            <>
              <StyledConnectButtonWrapper>
                <WalletButton />
              </StyledConnectButtonWrapper>
            </>
          ) : null}

          {!checkIfUserIsMasterAdmin(roleId) &&
          !checkIfUserIsCandidate(roleId) ? (
            <StyledAdminRecruitMenuItem
              roleId={roleId}
              className="switch-crew-feature-tour"
            >
              <Tooltip title={ToolTipsText.switchCrew} placement="left">
                <div>
                  <AdminRecruit roleId={roleId} />
                </div>
              </Tooltip>
            </StyledAdminRecruitMenuItem>
          ) : null}

          {!checkIfUserIsMasterAdmin(roleId) &&
          !checkIfUserIsSuperAdmin(roleId) ? (
            <StyledNotifyWrapper>
              <Dropdown
                overlayStyle={{ position: 'fixed' }}
                overlay={NotificationList}
                trigger={['click']}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={() => getNotifications()}
                  href={!isMobile() ? () => false : getNotificationPageUrl()}
                >
                  <StyledNotificationBellWrapper
                    roleId={roleId}
                    newUpdates={newUpdates}
                  >
                    <img src={notificationSVG} alt="notification" />
                  </StyledNotificationBellWrapper>
                </a>
              </Dropdown>
            </StyledNotifyWrapper>
          ) : null}

          <StyledUserMenuItem>
            <Dropdown
              overlay={ProfileList}
              trigger={['click']}
              overlayStyle={{
                position: 'fixed'
              }}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                href={() => false}
              >
                <StyledTopBarPictureWrapper>
                  <UploadProfilePicComponent
                    profilePic={profilePic}
                    option={false}
                    setProfilePic={setProfilePic}
                    fileName={profileData.profilePic}
                    avatarSize={35}
                  />
                </StyledTopBarPictureWrapper>
                <HelloText profileData={profileData} />
                <CaretDownOutlined />
              </a>
            </Dropdown>
          </StyledUserMenuItem>
        </StyledInnerMenu>
      </StyledTopBarContainer>
      <ResponsiveMenu visible={visible} onClose={onClose} noRedirect />
    </StyledTopBarHeader>
  );
};

const StyledNotifyBoxDiv = styled.div`
  position: relative;
  top: 20px;
  left: 80px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: 35px;
    right: 2px;
    left: 0;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    right: 2px;
    left: 0;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    top: 35px;
    left: 70px;
  }

  .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLACK};
  }
`;

const StyledVirtualWalletWrapper = styled(Menu.Item)`
  display: inline-block;
  margin-right: 35px !important;
  border-radius: 9999px !important;
  background-color: ${COLORS.WHITE} !important;
  position: relative;
  img {
    ${MARGIN(0, 0, 5, 0)}
  }
  span {
    ${MARGIN(0, 0, 0, 10)}
    font-size: 14px;

    &[data-verified='inactive'] {
      color: ${COLORS.GRAY};
    }

    &[data-verified='active'] {
      color: ${COLORS.LAVENDER_INDIGO};
    }
  }

  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_8}) {
    display: none;
  }
`;

const StyledNotifyMenu = styled(Menu)`
  position: sticky;
  top: 100px;
  width: 454px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 280px;
  }
`;

const StyledHeaderMenuItem = styled(Menu.Item)`
  ${DISPLAY_FLEX('row')};

  &:hover {
    cursor: unset;
    color: ${COLORS.BLACK};
  }
`;

const StyledHeaderMenuTitle = styled.h3`
  ${DISPLAY_FLEX('row')};
  margin: 16px 15px;

  &:hover {
    cursor: unset;
    color: ${COLORS.BLACK};
  }
`;

const StyledHeaderItemLinkCol = styled(Col)`
  ${DISPLAY_FLEX('row-reverse')}

  color: ${COLORS.METALLIC_SILVER};

  a {
    font-size: ${FONT[12]};
    color: ${COLORS.METALLIC_SILVER};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[12]};
    }
  }
`;

const StyledHeaderItemViewAllLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledTopBarPlaceholderDiv = styled.div`
  ${PADDING(10, 20)}
`;

const StyledPlaceholderText = styled.p`
  color: ${COLORS.GRAY};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[10]};
  }
`;

const StyledProifleMenu = styled(Menu)`
  position: sticky;
  margin: 0 auto;
  width: fit-content;
  margin-top: 10px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: 20px;
  }

  li {
    ${PADDING(10, 15)};
    margin: 0;

    div {
      padding: 0;
      margin: 0;
      padding-left: 42px;
      font-size: ${FONT[16]};
      color: ${COLORS.ARGENT};
      cursor: pointer;
      position: relative;
      transition: 0.03s;

      svg {
        width: 21px;
        position: absolute;
        left: 6px;
        fill: ${COLORS.ARGENT};
        transition: 0.09s;
      }
    }

    &:hover div {
      color: ${(props) => getUserColorWithRoleId(props.roleId)};
      background-color: ${COLORS.WHITE};
    }

    &:hover div svg {
      fill: ${(props) => getUserColorWithRoleId(props.roleId)};
      background-color: ${COLORS.WHITE};
    }

    &:hover {
      background-color: ${COLORS.WHITE};
    }
  }
`;

const StyledTopBarHeader = styled.header`
  ${PADDING(2, 0)};
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 111;
  width: 100%;
  background: ${COLORS.WHITE};
  border-bottom: 0.1rem solid ${COLORS.BRIGHT_GRAY};
  overflow: hidden;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: 80px;
    ${PADDING(5, 0)};
  }
`;

const StyledHeaderResideMenuDiv = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: block;
    float: left;
    padding-left: 30px;
    text-align: left;
    width: 100%;
    position: relative;
  }
`;

const StyledHeaderMenuIconImg = styled.img`
  max-width: 106px;
  color: ${COLORS.GRAY};

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    width: 38px;
    height: 28px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 28px;
    height: 22px;
  }
`;

const StyledHeaderLogoDiv = styled.div`
  max-width: 312px;
  float: left;
  width: 19.3%;
  text-align: left;
  position: relative;

  @media (min-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3}) and (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 18.3%;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    ${DISPLAY_FLEX(null, 'center', 'center')};
    align-self: center;
    padding-left: 0;
  }

  img {
    margin-left: 30px;
    width: 160px;
    height: 40px;
    cursor: pointer;
    align-self: center;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      max-width: 106px;
      margin-left: -40px;
    }

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      margin-left: -40px;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      height: auto;
      width: 190px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    background: ${COLORS.PLATINUM};
    right: 0;
    height: 42px;

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      width: 0;
    }
  }
`;

const StyledTopBarContainer = styled.div`
  ${DISPLAY_FLEX('row')};
  width: 83.28%;
`;

const StyledInnerMenu = styled(Menu)`
  float: right;
  width: 100%;
  text-align: right;
  vertical-align: middle;
  line-height: 13px;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    border: 0;
  }
`;

const StyledAdminRecruitMenuItem = styled(Menu.Item)`
  display: inline-block;
  background-color: ${COLORS.WHITE} !important;
  padding: 0 5px !important;

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) {
    margin-right: 30px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 0 14px !important;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: none !important;
  }

  a {
    color: ${(props) => getUserColorWithRoleId(props.roleId)};
    font-size: 16px;
    ${DISPLAY_FLEX('row', 'center', 'center')};

    &:hover {
      color: ${(props) => getUserColorWithRoleId(props.roleId)};
    }

    span.anticon-caret-down {
      ${DISPLAY_FLEX('row', 'center', 'center')};

      svg {
        width: 10px;
        transition: 0.8s;
        ${MARGIN(5, 0, 5, 10)};
        fill: ${(props) => getUserColorWithRoleId(props.roleId)} !important;
      }
    }
  }

  a.ant-dropdown-open span.anticon-caret-down svg {
    transform: rotate(180deg);
  }
`;

const StyledNotifyWrapper = styled(Menu.Item)`
  display: inline-block;
  background-color: ${COLORS.WHITE} !important;
  padding: 0 5px !important;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 0 14px 0 0 !important;
  }
`;

const StyledNotificationBellWrapper = styled.span`
  position: relative;

  img {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    position: relative;
    top: 0;
    fill: ${COLORS.ARGENT};
    filter: invert(97%) sepia(0%) saturate(31%) hue-rotate(153deg)
      brightness(88%) contrast(71%);
    transition: 0.03s;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 24px;
      height: 24px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 19px;
      height: 20px;
    }
  }

  &::after {
    width: 9px;
    height: 9px;
    content: '';
    position: absolute;
    left: 11px;
    top: 2px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.newUpdates
        ? getUserColorWithRoleId(props.roleId)
        : COLORS.LIGHT_GRAY};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      left: 13px;
      top: 1px;
    }
  }
`;

const StyledUserMenuItem = styled(Menu.Item)`
  display: inline-block;
  background-color: ${COLORS.WHITE} !important;
  padding: 0 0 0 5px !important;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 0 0 10px 14px !important;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: none !important;
  }

  a {
    color: ${COLORS.DARK_SILVER};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    font-size: ${FONT[14]};
    height: 33px;
    display: flex;
    align-items: center;

    &:hover {
      color: ${COLORS.DARK_SILVER};
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }

    svg {
      width: 14px;
      margin-left: 10px;
      fill: ${COLORS.DARK_SILVER};
      transition: 0.8s;
    }
  }

  a.ant-dropdown-open span.anticon-caret-down svg {
    transform: rotate(180deg);
  }
`;

const StyledTopBarPictureWrapper = styled.div`
  margin-right: 10px;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: 1px;
  }

  svg {
    width: 20px !important;
    margin-left: 1px !important;
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 22px !important;
    }
  }
`;

const StyledConnectButtonWrapper = styled(Menu.Item)`
  display: inline-block;
  background-color: ${COLORS.WHITE} !important;
  padding: 0 5px !important;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 0 14px 0 0 !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

export default TopBar;
