import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import toLower from 'lodash/toLower';
import { Skeleton, Spin } from 'antd';
import get from 'lodash/get';
import { getRoleId } from '../../utils/userInfo';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { BOX_SHADOW } from '../../styles/constants/box-shadow';
import {
  changeChatState,
  changeChatWindow,
  changeSelectedChatId,
  getSelectedChatUsersDetails,
  loadMorePersonalChats,
} from '../../store/features/chat';
import { getChatRoomWithId } from '../../service/chat';
import { getSecondUserData, getUsersDetailsInChatroom } from '../../utils/chat';
import { UserCard } from './UserCard';
import { userProfileStore } from '../../utils/profile';
import ThreadContainer from './ThreadContainer';
import { CloseChat } from '../svgicons';
import { SearchInputBox } from '../shared-components/Form/Form';

const P2PChatUsers = () => {
  const [search, setSearch] = useState('');
  const roleId = getRoleId();

  const dispatch = useDispatch();
  const {
    personalChats,
    personalChatsLoading,
    selectedChatId,
    personalChatsUsersProfileData: usersProfileData,
    isChatOpen,
    chatWindow,
    hasMorePersonalChats,
    personalChatsPagination,
    // @ts-ignore
  } = useSelector((state) => state.chat);

  const page = get(personalChatsPagination, 'chatRoomPage', 1);

  const clickedCard = (id: any) => {
    dispatch(changeSelectedChatId(id));
  };

  const {
    profileData: { userId, company },
  } = userProfileStore();

  const loggedInUserId = company ? company.id : userId;

  // Fetching User Details in a chatroom
  useEffect(() => {
    if (personalChats.length && selectedChatId) {
      (async () => {
        await getChatRoomWithId(selectedChatId).then((chatRoomDetails) => {
          const usersArr = chatRoomDetails.users;
          const userDetails = getUsersDetailsInChatroom(
            usersArr,
            usersProfileData,
          );
          dispatch(getSelectedChatUsersDetails(userDetails));
        });
      })();
    }
  }, [dispatch, personalChats, selectedChatId, usersProfileData]);

  const observer = useRef();

  const lastThreadRef = useCallback(
    (node) => {
      if (personalChatsLoading) return;
      // @ts-ignore
      if (observer && observer.current) observer.current.disconnect();
      // @ts-ignore
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePersonalChats) {
          dispatch(loadMorePersonalChats(selectedChatId, personalChats, page));
        }
      });
      // @ts-ignore
      if (node) observer.current.observe(node);
    },
    [
      dispatch,
      hasMorePersonalChats,
      personalChats,
      personalChatsLoading,
      page,
      selectedChatId,
    ],
  );

  return (
    <StyledChatContainer>
      <StyledChatBody>
        {isChatOpen ? (
          <ThreadContainer />
        ) : (
          <StyledUserContainer>
            <StyledChatUserHeader>
              Chatbox{' '}
              <CloseChat
                onClick={() => dispatch(changeChatWindow(!chatWindow))}
              />
            </StyledChatUserHeader>

            <StyledChatConversationDiv chatOpened={isChatOpen}>
              <SearchInputBox
                placeholder="Filter by name"
                handleChange={(e) => setSearch(e.target.value)}
              />

              {personalChats
                .filter((item: any) => {
                  const secondUser = getSecondUserData(
                    item,
                    loggedInUserId,
                    usersProfileData,
                    roleId,
                  );
                  return toLower(secondUser.name).includes(toLower(search));
                })
                .map((item: any, idx: number) => {
                  const secondUser = getSecondUserData(
                    item,
                    loggedInUserId,
                    usersProfileData,
                    roleId,
                  );
                  return (
                    <StyledChatConversationCardDiv
                      id="chat-conversation-card"
                      selected={selectedChatId === item.id}
                      onClick={() => {
                        clickedCard(item.id);
                        dispatch(changeChatState(true));
                      }}
                      key={idx.toString()}
                    >
                      <UserCard
                        secondUser={secondUser}
                        chatData={item}
                        key={item.id}
                      />
                    </StyledChatConversationCardDiv>
                  );
                })}
              <StyledChatLoading ref={lastThreadRef}>
                <Skeleton />
              </StyledChatLoading>
              {personalChatsLoading ? <Spin /> : null}
            </StyledChatConversationDiv>
          </StyledUserContainer>
        )}
      </StyledChatBody>
    </StyledChatContainer>
  );
};

const StyledChatBody = styled.div`
  ${DISPLAY_FLEX('row')}
`;

const StyledChatContainer = styled.div`
  position: relative;
  width: 340px;
  border-radius: 10px;
  box-shadow: ${BOX_SHADOW[4]};
  background: ${COLORS.WHITE};
  z-index: 1;
  position: relative;
  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    border-radius: unset;
  }
`;

const StyledUserContainer = styled.div`
  ${DISPLAY_FLEX('column')};
  width: 100%;
`;

const StyledChatConversationDiv = styled.div<any>`
  ${DISPLAY_FLEX('column')};
  max-height: 500px;
  min-height: 400px;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  gap: 20px;
  padding: 10px;

  & > div:first-child {
    max-width: unset;
    input {
      height: 33px;
      width: 100%;
      &:placeholder-shown {
        font-size: 13px;
      }
    }
  }
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-height: unset;
    min-height: unset;
    width: 100%;
    height: calc(100vh - 53.5px);
    height: calc(var(--vh, 1vh) * 100 - 53.5px);
  }

  #chat-conversation-card:after {
    content: ' ';
    bottom: -10px;
    border-bottom: 1px solid #707070;
    opacity: 0.2;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;
  }

  #chat-conversation-card:last-child:after {
    content: '';
    border-bottom: unset;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.ARGENT};
    border-radius: 10px;
    height: 30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.QUICK_SILVER};
  }
`;

const StyledChatConversationCardDiv = styled.div<any>`
  ${DISPLAY_FLEX('row', null, 'center')};

  margin-bottom: 4px;
  cursor: pointer;
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-right: 0;
  }

  .online_avatar_wrapper {
    margin-right: 15px;
  }
`;

const StyledChatUserHeader = styled.div`
  background-color: ${COLORS.DODGER_BLUE};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px 9px 0px 0px;
  opacity: 1;
  color: white;
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
  padding: 14px 11px;
  font: 16px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    border-radius: unset;
  }
`;

const StyledChatLoading = styled.p`
  color: ${COLORS.BLACK};
  font-size: 12px;
`;

export default React.memo(P2PChatUsers);
