import React, { useEffect, useState } from 'react';
import { Space, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { getUrlVars } from '../../utils/common';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { getProfileData } from '../../store/features/profile';
import message from '../../constants/message';
import { magicLinksLoginData } from '../../store/features/auth';
import { checkIfEventIsOver } from '../../service/event';
import Notification from '../../components/Notification/Notification';

const CreatePassword = (props) => {
  const dispatch = useDispatch();

  const { history } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { inviteToken, eventId } = getUrlVars(
    get(history, 'location.search', {}),
  );

  const { isAuthenticated, user: userData } = useSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      (sessionStorage.length || localStorage.length) &&
      isLoggedIn
    ) {
      dispatch(getProfileData()).then(() => {
        const { roleId } = userData;
        const redirect = `events/view/${eventId}?inviteToken=${inviteToken}&eventId=${eventId}`;
        if (checkIfUserIsOrganizer(roleId))
          history.push(`/organizer/${redirect}`);
        else if (checkIfUserIsRecruiter(roleId))
          history.push(`/recruiter/${redirect}`);
        else if (checkIfUserIsCandidate(roleId))
          history.push(`/candidate/${redirect}`);
        else message.error('Getting profile data failed.Try logging in again');
      });
    }

    // eslint-disable-next-line
  }, [isAuthenticated, isLoggedIn, eventId, inviteToken]);

  useEffect(() => {
    checkIfEventIsOver(eventId).then((response) => {
      const isEventOver = get(response, 'data.payload.isEventOver', false);
      if (isEventOver) {
        Notification('error', 'Invite link Expired.');
        props.history.push(`/login`);
        return;
      }
      dispatch(magicLinksLoginData(eventId, inviteToken)).then(() => {
        setIsLoggedIn(true);
      });
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className="center-screen">
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default CreatePassword;
