import get from 'lodash/get';
import api from './api';
import { APIs, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';

const url = APIs.billingAddress;

export const getBillingAddresses = async () => {
  return api({
    url,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.billingAddresses', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getBillingAddressById = async (id) => {
  return api({
    url: url + id,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.billingAddresses', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const addBillingAddress = async (body) => {
  return api({
    url,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateBillingAddress = async (body) => {
  return api({
    url,
    method: Method.put,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const deleteBillingAddress = async (id) => {
  return api({
    url: url + id,
    method: Method.delete,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
