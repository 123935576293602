import React from 'react';
import { Dropdown } from 'antd';
import CalendarEventDetail from "../Calendar/CalendarEventDetail";
import { CalendarProps } from '../../interfaces/components/CalendarDetails';

export const CalendarDetails: React.FC<CalendarProps> = ({
  children,
  currentListItems,
  selectedDate,
  roleId,
  getEventBagdeColor
}) => {
  return (
    <Dropdown
      overlay={
        <CalendarEventDetail
          items={currentListItems}
          date={selectedDate}
          roleId={roleId}
          getEventBagdeColor={getEventBagdeColor}
        />
      }
    >
      {children}
    </Dropdown>
  );
};

export default CalendarDetails;
