/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import CompanyInformationModal from './profile/CompanyInformationModal';
import { userProfileStore } from '../../utils/profile';
import { getRoleId } from '../../utils/userInfo';
import Header from '../../components/profile/Header';
import CompanyInformation from '../../components/profile/CompanyInformation';
import CrewManagement from '../../components/CrewManagement/CrewManagement';
import { userIsCrewMember } from '../../utils/crew';
import CrewMemberProfile from '../../components/CrewManagement/CrewMemberProfile';
import CurrencySetting from '../../components/profile/CurrencySetting';
import { getFileS3 } from '../../utils/s3.storage';
import RepresentativeModal from '../../components/shared-components/RepresentativeModal';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import MailSubscription from '../../components/profile/MailSubscription';
import { StyledProfileWrapper } from '../../components/shared-components/common';
import PageCircle from '../../components/shared-components/PageCircle';
import { ORGANIZER, RETRY_ATTEMPTS, RETRY_INTERVALS } from '../../constants';
import { getUserColorWithRoleId } from '../../styles/utils';
import { useTagManager } from '../../utils/customHooks';
import { safeLoad } from '../../utils/loader';
import { createIntegration } from '../../service/integrations';
import { OrganizerRoutes } from '../../constants/routes';
import useProfileIntegration from '../../hooks/useProfileIntegration';
import Notification from '../../components/Notification/Notification';
import { useLazyGetParticularIntegrationQuery } from '../../store/features/rtkApi/organizerApi';

const RepresentativeTeamProfile = React.lazy(() => {
  return safeLoad(
    () => import('../../components/profile/RepresentativeTeamProfile'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const IntegrationConfirmationModal = React.lazy(() => {
  return safeLoad(
    () => import('../../components/profile/IntegrationModal'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Profile = () => {
  const { currencyData } = useSelector((st) => st.commons);
  const { isLoading, profileData, error } = userProfileStore();
  const roleId = getRoleId();
  const updateUserActionInGTM = useTagManager();
  const { company: companyData } = profileData;
  const [profilePic, setProfilePic] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyCover, setCompanyCover] = useState([]);
  const [companyModal, setCompanyModal] = useState(false);
  const [representativeModal, setRepresentativeModal] = useState(false);
  const [
    isCommunityIntegrationModalOpen,
    setCommunityIntegrationModalOpen,
  ] = useState(false);

  const [integrationType, setIntegrationType] = useState(null);
  const { search } = useLocation();
  const history = useHistory();
  const { refetchAllIntegrationData } = useProfileIntegration();
  const [isPolling, setIsPolling] = useState(false);
  const pollingRef = React.useRef();

  /* RTK query hook for fetching details for telegram used lazy because we want to only start polling when its telegram
   * polling interval is 3000ms which means after every 3 seconds it will call the api, when the api returns 200 response means
   * we got the data then the isSuccess will be true then we will stop the polling.
   */
  const [
    fetchIntegrationDetails,
    { isSuccess },
  ] = useLazyGetParticularIntegrationQuery({
    pollingInterval: isPolling ? 3000 : 0,
  });

  // This useEffect is responsible for handle the integration for slack and discord when first load
  useEffect(() => {
    const params = new URLSearchParams(search);

    const authCode = params.get('code');
    const type = params.get('state');
    (async () => {
      if (authCode && type && typeof refetchAllIntegrationData === 'function') {
        try {
          const response = await createIntegration({ code: authCode, type });
          if (!!response.success) {
            Notification(
              'success',
              `${capitalize(type.toString())} integration done successfully`,
            );
            refetchAllIntegrationData();
          } else {
            Notification(
              'error',
              `${capitalize(type.toString())} integration could not be done!`,
            );
          }
        } catch (err) {
          Notification('error', 'Integration not done');
        } finally {
          // so that same code cannot be used again and again when refresh or something
          history.replace(OrganizerRoutes.profile);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is responsible for looking at the success value of telegram
  useEffect(() => {
    /** If the data gets returned successfully then make the polling close and close the modal */
    if (isSuccess) {
      setIsPolling(false);
      refetchAllIntegrationData();
      setCommunityIntegrationModalOpen(false);
      if (pollingRef.current) clearTimeout(pollingRef.current);
    }
  }, [isSuccess, refetchAllIntegrationData]);

  // this useEffect is responsible for creating a timer for 30 seconds after we start polling for telegram data
  useEffect(() => {
    if (pollingRef.current) clearTimeout(pollingRef.current);
    if (isPolling) {
      pollingRef.current = setTimeout(() => {
        setIsPolling(false);
        if (!isSuccess) {
          Notification('error', 'Sorry could not integrate Telegram');
        }
      }, 30 * 1000);
    } else {
      if (pollingRef.current) clearTimeout(pollingRef.current);
    }

    return () => {
      if (pollingRef.current) clearTimeout(pollingRef.current);
    };
  }, [isPolling, isSuccess]);

  useEffect(() => {
    updateUserActionInGTM('profile_settings_opened');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setProfilePic(getFileS3(profileData.profilePic));
      setCompanyLogo(getFileS3(companyData.logo));
      if (get(profileData, 'company.cover', null)) {
        setCompanyCover([
          {
            uid: 1,
            name: 'Company cover',
            status: 'done',
            url: getFileS3(get(profileData, 'company.cover', '')),
          },
        ]);
      }
    }
  }, [profileData, companyData, isLoading]);

  const openConfirmationModalForIntegration = useCallback((integrationType) => {
    setCommunityIntegrationModalOpen(true);
    setIntegrationType((prev) => {
      if (prev === integrationType) return prev;
      setIsPolling(false);
      return integrationType;
    });
  }, []);

  const closeConfirmationModalForIntegration = useCallback(() => {
    setCommunityIntegrationModalOpen(false);
  }, []);

  const handleOpenCompanyModal = useCallback(() => {
    setCompanyModal(true);
  }, []);

  const handleCloseCompanyModal = useCallback(() => {
    setCompanyModal(false);
  }, []);

  const isCrewMember = userIsCrewMember(profileData);

  const handleOpenRepresentativeModal = useCallback(() => {
    setRepresentativeModal(true);
  }, []);

  const handleCloseRepresentativeModal = useCallback(() => {
    setRepresentativeModal(false);
  }, []);

  return isLoading || error ? (
    <Skeleton />
  ) : isCrewMember ? (
    <CrewMemberProfile memberId={profileData.userId} />
  ) : (
    <StyledProfileContainer>
      <PageCircle color={getUserColorWithRoleId(ORGANIZER)} />
      <StyledProfileWrapper>
        <Header
          userType="organizer"
          data={companyData}
          handleOpenModal={handleOpenCompanyModal}
          openIntegrationModal={openConfirmationModalForIntegration}
        />
        <StyledContainer>
          <CompanyInformation
            companyData={companyData}
            profileData={profileData}
            companyLogo={companyLogo}
            setCompanyLogo={setCompanyLogo}
          />
        </StyledContainer>

        <StyledContainer>
          <React.Suspense fallback={Skeleton}>
            <RepresentativeTeamProfile
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              profileData={profileData}
              handleOpenRepresentativeModal={handleOpenRepresentativeModal}
            />
          </React.Suspense>
          <CrewManagement profileData={profileData} roleId={roleId} />
        </StyledContainer>

        <CurrencySetting
          profileData={profileData}
          currencyData={currencyData}
        />

        <MailSubscription profileData={profileData} />

        {companyModal ? (
          <CompanyInformationModal
            visible={companyModal}
            handleClose={handleCloseCompanyModal}
            companyLogo={companyLogo}
            profileData={profileData}
            companyData={companyData}
            setCompanyLogo={setCompanyLogo}
            companyCover={companyCover}
            setCompanyCover={setCompanyCover}
            currencyData={currencyData}
          />
        ) : null}
        {representativeModal ? (
          <RepresentativeModal
            visible={representativeModal}
            handleClose={handleCloseRepresentativeModal}
            profilePic={profilePic}
            profileData={profileData}
            setProfilePic={setProfilePic}
            companyData={companyData}
            userRole={roleId}
          />
        ) : null}
        <React.Suspense fallback={<Skeleton />}>
          {integrationType && (
            <IntegrationConfirmationModal
              isVisible={isCommunityIntegrationModalOpen}
              onCancel={closeConfirmationModalForIntegration}
              integrationType={integrationType}
              isPolling={isPolling}
              setIsPolling={setIsPolling}
              fetchIntegrationDetails={fetchIntegrationDetails}
            />
          )}
        </React.Suspense>
        <StyledProfileFooter />
      </StyledProfileWrapper>
    </StyledProfileContainer>
  );
};

export default Profile;

const StyledProfileContainer = styled.div`
  position: relative;
  background-color: #fafafa;
  z-index: 100;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(10, 0, 0)}
  }
`;

const StyledContainer = styled.div`
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: relative;
  ${MARGIN(10, 0, 30)};
  ${PADDING(22, 30, 42, 30)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10)};
  }
`;

const StyledProfileFooter = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    background-color: #47bfa8;
    width: 110%;
    height: 70px;
    ${MARGIN(0, -15, 0, -15)}
  }
`;
