import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { getRoleId } from '../../utils/userInfo';
import { AllEventsValue, DEFAULT_LIMIT, DEFAULT_PAGE } from '../../constants';
import EditBroadCastLinkModal from './EditBroadCastLinkModal';
import { BroadcastEvent as EventLinks } from '../../components/shared-components/Broadcast/BroadcastEvent';
import Circle from '../../components/shared-components/PageCircle';
import { HiddenMobile } from '../../components/shared-components/DocumentViewerModal';
import { DropdownBox } from '../../components/shared-components/Form/Form';
import { getUserColorWithRoleId } from '../../styles/utils';
import {
  FlexPage,
  PageContainer,
  PageLayout,
} from '../../components/shared-components/PageContainer';
import {
  LargeFont,
  LargeHeading,
  SemiLargeHeading,
} from '../../components/shared-components/Texts';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';
import { RightAlignedPagination } from '../MasterAdmin/generalSettings/common';
import { getBroadcastEvents } from '../../service/event.js';

const BroadcastEvent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(AllEventsValue);
  const [dropdownEvents, setDropdownEvents] = useState([])


  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT);
  const [pagination, setPagination] = useState({
    currentPage: null,
    totalItems: null,
  });

  const [firstFetch, setFirstFetch] = useState(true);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  const [editEventData, setEditEventData] = useState(null);
  const [isEditLinkModalOpen, setIsEditLinkModalOpen] = useState(false);


  const onPageChangeHandler = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onLimitChangeHandler = async (pageNumber, limit) => {
    setCurrentPage(pageNumber);
    setCurrentLimit(limit);
  };

  const getDropdownEvents = async (page = DEFAULT_PAGE, limit = DEFAULT_LIMIT, fields = "basic") => {
    const res = await getBroadcastEvents(page, limit, fields);

    const eventList = get(res, 'broadcastEvents', []);
    setDropdownEvents(eventList);
  }

  const populateEvents = async (page = DEFAULT_PAGE, limit = DEFAULT_LIMIT) => {
    const res = await getBroadcastEvents(page, limit);
    const eventList = get(res, 'broadcastEvents', []);
    setEvents(eventList);
    setPagination({
      currentPage: get(res, 'currentPage', null),
      totalItems: get(res, 'totalItems', null),
    });
  };

  const roleId = getRoleId();

  const getEventbyId = async (eventId) => {
    const res = await getBroadcastEvents(DEFAULT_PAGE, currentLimit, '', eventId);
    return res;
  }

  const handleEventSelect = async (e) => {
    if (e === -1) {
      setSelectedEvent(AllEventsValue);
      setSelectedEventIndex(null);
      return;
    }
    if (selectedEventIndex === e) {
      return;
    }
    setSelectedEventIndex(e)
    const res = await getEventbyId(get(dropdownEvents[e], 'id', null) || get(selectedEvent, 'id'))
    setSelectedEvent(get(res, 'broadcastEvents', null)[0]);
  };

  const getEventOptions = () => {
    if (dropdownEvents && dropdownEvents.length) {
      const options = dropdownEvents.map((event, i) => {
        return { id: i, name: event.name };
      });
      const allEventOption = [AllEventsValue];
      return allEventOption.concat(options);
    }
    return [];
  };

  const openEditModal = (event) => {
    setEditEventData(event);
    setIsEditLinkModalOpen(true);
  };

  const handleClose = () => {
    setIsEditLinkModalOpen(false);
  };

  const handelUpdate = () => {
    if (get(selectedEvent, 'id') !== -1) {
      handleEventSelect();
    }
    populateEvents(currentPage, currentLimit);
    setIsEditLinkModalOpen(false);
  };

  useEffect(() => {
    populateEvents(currentPage, currentLimit);
    setFirstFetch(false);
    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  useEffect(() => {
    getDropdownEvents();
    if (!firstFetch) {
      if (currentPage === DEFAULT_PAGE) {
        populateEvents(currentPage, currentLimit);
      } else {
        setCurrentPage(DEFAULT_PAGE);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {/* events home page */}
      <PageLayout>
        {/* circle */}
        <HiddenMobile>
          <Circle color={getUserColorWithRoleId(roleId)} />
        </HiddenMobile>

        <Col xs={24} lg={12} xxl={6}>
          <SemiLargeHeading color={getUserColorWithRoleId(roleId)}>
            Broadcast Event
          </SemiLargeHeading>
        </Col>

        <FlexPage>
          <PageContainer margin="0 0">
            <Col xs={24}>
              <LargeHeading margin="0 0" color={getUserColorWithRoleId(roleId)}>
                Broadcast Live Events
              </LargeHeading>
            </Col>
            <Col xs={24}>
              <SemiLargeHeading color={COLORS.DARK_SILVER}>
                Publish your broadcast link
              </SemiLargeHeading>
            </Col>
            <StyledEventDropdownContainer>
              <Col>
                <LargeFont fontWeight={FONT_WEIGHTS.SEMI_BOLD}>
                  Select Event
                </LargeFont>
              </Col>
              <Col xs={17} md={8}>
                <StyledDropdownWrapper>
                  <DropdownBox
                    value={get(selectedEvent, 'name', '')}
                    placeholder="Select Event"
                    options={getEventOptions()}
                    handleChange={handleEventSelect}
                    showSearch
                  />
                </StyledDropdownWrapper>
              </Col>
            </StyledEventDropdownContainer>
            <Col xs={24}>
              {get(selectedEvent, 'id') === -1 ? (
                events.map((event) => (
                  <EventLinks
                    event={event}
                    roleId={roleId}
                    onEdit={() => openEditModal(event)}
                  />
                ))
              ) : (
                selectedEvent &&
                <EventLinks
                  event={selectedEvent}
                  roleId={roleId}
                  onEdit={() => openEditModal(selectedEvent)}
                />
              )}
            </Col>
          </PageContainer>
          {get(selectedEvent, 'id') === -1 ? (
            <RightAlignedPagination
              pagination={pagination}
              currentPage={currentPage}
              currentLimit={currentLimit}
              onPageChangeHandler={onPageChangeHandler}
              onLimitChangeHandler={onLimitChangeHandler}
            />) : null
          }
        </FlexPage>

        {isEditLinkModalOpen ? (
          <EditBroadCastLinkModal
            visible={isEditLinkModalOpen}
            handleClose={handleClose}
            event={editEventData}
            handelUpdate={handelUpdate}
          />
        ) : null}
      </PageLayout>
    </div>
  );
};

export default BroadcastEvent;

const StyledEventDropdownContainer = styled(Row)`
  align-items: center;
  width: 100%;
`;

const StyledDropdownWrapper = styled.div`
  ${MARGIN(0, 0, 0, 20)}

  min-width: 350px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 0, 5)}

    min-width: 100%;
  }
`;
