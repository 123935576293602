/* eslint-disable no-nested-ternary */
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IconBadgeProps } from '../../interfaces/components/IconBadge';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS, FILTERS } from '../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { SmallFont, SmallText } from './Texts';

export const IconBadge: React.FC<IconBadgeProps> = ({
  icon,
  color,
  text,
  status,
  theme,
  hoverEffect,
  numberBadge = false,
  numberBadgeText,
  textColor,
  id,
  isCursorPointer = true,
  isCandidate,
}) => {
  const location = useLocation();
  return (
    <Wrapper hoverEffect={hoverEffect} isCursorPointer={isCursorPointer}>
      <Container
        color={color}
        theme={theme}
        selected={location.search.includes(id)}
        isCandidate
        isCursorPointer={isCursorPointer}
      >
        <img src={icon} alt="Icon" />
        {numberBadge ? (
          <NumberBadge color={color} selected={location.search.includes(id)}>
            <SmallFont color={COLORS.WHITE} fontWeight={FONT_WEIGHTS.MEDIUM}>
              {numberBadgeText}
            </SmallFont>
          </NumberBadge>
        ) : null}
      </Container>
      <TextWrapper>
        <SmallText color={textColor}>{text}</SmallText>
        <SmallText id="analytic-value" color={COLORS.WHITE}>
          {status}
        </SmallText>
      </TextWrapper>
    </Wrapper>
  );
};

interface ContainerProps {
  selected: boolean;
  isCandidate?: boolean;
  isCursorPointer?: boolean;
}

interface WrapperProps {
  hoverEffect: boolean;
  isCursorPointer?: boolean;
}
const Container = styled.div<ContainerProps>`
  padding: ${({ isCandidate }) => (isCandidate ? '8px' : '10px')};
  margin: ${({ isCandidate }) => (isCandidate ? '8px 0 0 0' : '10px 0')};
  border-radius: 10px;
  position: relative;
  background-color: ${(props) => (props.selected ? COLORS.BLACK : props.color)};
  ${({ isCursorPointer }) => (isCursorPointer ? 'cursor: pointer;' : '')}
  img {
    width: 22px;
    height: 22px;
    filter: ${FILTERS.WHITE};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 10px;
    margin: 10px 0;
  }
`;

const NumberBadge = styled.div<ContainerProps>`
  background-color: ${(props) => (props.selected ? COLORS.BLACK : props.color)};
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;

  div {
    margin-top: 3px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

    &:first-child {
      opacity: 0.8;
    }
  }
`;

const Wrapper = styled.div<WrapperProps>`
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 10px;
  ${({ isCursorPointer }) => (isCursorPointer ? 'cursor: pointer;' : '')}

  ${(props) =>
    props.hoverEffect
      ? `&:hover {
    ${Container},
    ${NumberBadge} {
      background-color: ${COLORS.BLACK};
    }

    img {
      transform: scale(1.1);
    }

    ${TextWrapper} {
      div {
        color: ${COLORS.BLACK};
      }
    }
  }`
      : null}
`;
