import React from 'react';
import MapAllLinks from './index';
import { OrganizerRoutes } from '../../../constants/routes';

const OrganizerCreateEventBreadCrumbs = () => {
  const links = [
    {
      name: 'Events',
      url: `${OrganizerRoutes.events}`,
    },
    {
      name: 'Create Event',
    },
  ];
  return (
    <div>
      <MapAllLinks links={links} />
    </div>
  );
};

export default OrganizerCreateEventBreadCrumbs;
