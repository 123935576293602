import React, { useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import PageCircle from '../../components/shared-components/PageCircle';
import { CANDIDATE } from '../../constants';
import { getUserColorWithRoleId } from '../../styles/utils';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getClaimedPoaps } from '../../service/poap';
import { MARGIN } from '../../styles/constants/display';
import NFTCard from './NFTCard';
import NTFPlaceholder from '../../assets/img/NFT.png';
import { userProfileStore } from '../../utils/profile';

const MyNFTs = () => {
  const { profileData } = userProfileStore();
  const color = getUserColorWithRoleId(CANDIDATE);
  const [poaps, setPoaps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const walletAddress = get(profileData, 'walletAddress');

  useEffect(() => {
    if (walletAddress) {
      setIsLoading(true);
      getClaimedPoaps()
        .then((response) => {
          setPoaps(response);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [walletAddress]);

  return isLoading && walletAddress ? (
    <Skeleton />
  ) : (
    <div>
      <PageCircle color={color} />
      <StyledTitle color={color}>My NFT Collection</StyledTitle>
      {poaps.length ? (
        <StyledNftContainer>
          {poaps.map((poap, i) => (
            <NFTCard poap={poap} i={i} />
          ))}
        </StyledNftContainer>
      ) : (
        <StyledPlaceholder src={NTFPlaceholder} alt="placeh" />
      )}
    </div>
  );
};

export default memo(MyNFTs);

const StyledTitle = styled.p`
  font-size: ${FONT[16]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${({ color }) => color};
  ${MARGIN(20, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(25, 0)}
    font-size: ${FONT[18]};
  }
`;

const StyledNftContainer = styled.div`
  margin-top: 20px;

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 25px;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const StyledPlaceholder = styled.img`
  width: 60%;
  margin: 0 auto;
  display: block;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 90%;
  }
`;
