import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';
import {
  TextBox,
  NumberBox,
  Dropdown,
} from '../../shared-components/Form/Form';
import MandatoryStar from '../../Common/mandatoryStar';
import { getRoleId } from '../../../utils/userInfo';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import get from 'lodash/get';
import { bookingMethods } from '../../../mocks/organizer/events/create-event';
import { BOOKING_METHOD_TEXT, } from '../../../constants/index';
import { MARGIN } from '../../../styles/constants/display';

const { Title } = Typography;

const ThirdPartyPayment = ({ isTabActive = false, setBookingMethod, event }) => {
  const { currencyData } = useSelector((st) => st.commons);
  const roleId = getRoleId();

  const handleBookingMethodChange = (value) => {
    setBookingMethod(value);
  };

  const getBookingMethodDefautValue = () => {
    return get(event, 'thirdPartyData', []).length
      ? BOOKING_METHOD_TEXT.thirdParty
      : BOOKING_METHOD_TEXT.recorem;
  };

  return (
    <>
      <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
        Booking Platform Information
      </StyledMainHeader>

      <Row gutter={[25, 10]}>
        <Col xs={24} md={5}>
          <StyledInfoHeader id="booking_method_label">
            Booking Method
          </StyledInfoHeader>
          <Dropdown
            name="bookingMethod"
            placeholder="Select the Booking Method"
            options={bookingMethods}
            defaultValue={getBookingMethodDefautValue}
            showArrow
            handleChange={handleBookingMethodChange}
            required={false}
            id="booking_method"
          />
        </Col>
        <Col xs={24} md={7}>
          <label htmlFor="bookingwebsiteName">
            <StyledInfoHeader id="bookingwebsiteName_label">
              Platform Name <MandatoryStar />
            </StyledInfoHeader>
          </label>
          <TextBox
            rule="text"
            name="bookingwebsiteName"
            placeholder="Please Enter Name of the Booking Platform"
            required
            maxLength={100}
            id="bookingwebsiteName"
          />
        </Col>
        <Col xs={24} md={7}>
          <label htmlFor="bookingWebisteUrl">
            <StyledInfoHeader id="bookingWebisteUrl_label">
              Url <MandatoryStar />
            </StyledInfoHeader>
          </label>
          <TextBox
            rule="url"
            name="bookingWebisteUrl"
            placeholder="Please Enter Booking Page URL"
            required
            id="bookingWebisteUrl"
          />
        </Col>
      </Row>
      <StyledTitle level={5}>Ticket Price</StyledTitle>
      <Row gutter={[25, 10]}>
        <Col xs={24} md={6}>
          <StyledInfoHeader id="thirdPartTicketCurrency_label">
            Currency <MandatoryStar />
          </StyledInfoHeader>
          <Dropdown
            name="thirdPartTicketCurrency"
            placeholder="Select Currency"
            showSearch
            showArrow
            options={currencyData}
            required
            id="thirdPartTicketCurrency"
          />
        </Col>
        <Col xs={24} md={6}>
          <StyledInfoHeader>
            Recruiter <MandatoryStar />
          </StyledInfoHeader>
          <NumberBox
            name="thirdPartyRecruiterTickerPrice"
            placeholder="Enter Recruiter Ticket Price"
            maxChar={9}
            required
            id="thirdPartyRecruiterTickerPrice"
          />
        </Col>
        <Col xs={24} md={6}>
          <StyledInfoHeader>
            Attendee <MandatoryStar />
          </StyledInfoHeader>
          <NumberBox
            name="thirdPartyJobseekerTickerPrice"
            placeholder="Enter Attendee Ticket Price"
            maxChar={9}
            required
            id="thirdPartyJobseekerTickerPrice"
          />
        </Col>
      </Row>
    </>
  );
};

export default ThirdPartyPayment;

const StyledMainHeader = styled.div`
  font-size: ${FONT[18]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${(props) => props.color};
  line-height: 21px;
  ${MARGIN(8, 0, 20, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 30, 0)}
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[24]};
  }
`;

const StyledInfoHeader = styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 20px !important;
  margin-bottom: 20px !important;
`;
