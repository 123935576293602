import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { getDefaultPaymentCurrency } from '../../utils/currencyConvertor';
import { getRoleId } from '../../utils/userInfo';
import { PageContainer } from '../shared-components/PageContainer';
import { ProfileHeading } from '../shared-components/ProfileHeadings';
import { StyledLargeFont } from '../shared-components/Texts';

const CurrencySetting = ({ profileData, currencyData }) => {
  const defaultPaymentCurrency = getDefaultPaymentCurrency(currencyData);
  const roleId = getRoleId();

  return (
    <PageContainer>
      <ProfileHeading color={getUserColorWithRoleId(roleId)}>
        Currency Setting
      </ProfileHeading>
      <CurrencyValue>
        {profileData.paymentCurrency
          ? `${get(profileData, 'paymentCurrency.countryName', '')} (${get(
              profileData,
              'paymentCurrency.code',
              '',
            )})`
          : get(defaultPaymentCurrency, 'name', 'Not available')}
      </CurrencyValue>
    </PageContainer>
  );
};

export default CurrencySetting;

const CurrencyValue = styled(StyledLargeFont)`
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.REGULAR};
  word-wrap: break-word;
`;
