/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import get from 'lodash/get';
import React from 'react';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Job from './Job';
import { getFileS3 } from '../../../utils/s3.storage';
import { StyledWrapper } from '../Events';
import { UserInformation } from '../../../utils/userInfo';
import EmptyImg from '../../../assets/placeholders/placeholder.jpg';
import { useGetCommunityJobs } from '../../../hooks/community';

type JobsSectionProps = {
  companyId: string | number;
  changeTab: (key: number) => void;
};
const JobsSection = ({ companyId, changeTab }: JobsSectionProps) => {
  const {
    user: { roleId },
  } = UserInformation();

  const { jobs: jobsData } = useGetCommunityJobs({
    companyId,
    roleId,
  });

  const history = useHistory();

  const onViewDetails = (job: number | string) => {
    // move to jobs tab
    changeTab(2);
    history.replace(`${history.location.pathname}?search=${job}`);
  };

  if (!jobsData) {
    return <Skeleton />;
  }

  return (
    jobsData && (
      <>
        <StyledWrapper>
          <StyledCardWrapper>
            {jobsData.map((job: any, idx: number) => {
              const image =
                getFileS3(get(job, 'company.logo', null)) || EmptyImg;
              const company = get(job, 'company.name', '');
              const title = get(job, 'designation', '');
              const location =
                get(job, 'jobLocationType', null) === 1
                  ? 'Remote'
                  : get(job, 'location', '');

              const engagements = get(job, 'jobType', [])
                .map((type: any) => (type === '0' ? 'Part-time' : 'Full-time'))
                .join(', ');
              const id = get(job, 'id', null);
              const salaryTo = get(job, 'salaryTo', 0);
              const currency = get(job, 'currency', '');
              const currencyCode = get(job, 'salaryCurrency.code');
              const currencySymbol = get(job, 'salaryCurrency.symbol');
              const jobType = get(job, 'jobType', []);
              const minQualification = get(job, 'minQualification');
              const positions = get(job, 'positions');
              return (
                <div key={id}>
                  <Job
                    jobInfo={{
                      title,
                      company,
                      image,
                      location,
                      engagements,
                      id,
                      salaryTo,
                      currency,
                      currencyCode,
                      currencySymbol,
                      jobType,
                      minQualification,
                      positions,
                    }}
                    onViewDetails={() => onViewDetails(idx)}
                  />
                </div>
              );
            })}
          </StyledCardWrapper>
        </StyledWrapper>
      </>
    )
  );
};

export default JobsSection;

const StyledCardWrapper = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-columns: minmax(330px, 1fr);
  grid-auto-flow: column;
  grid-gap: 16px;
  overflow-x: auto;
`;
