import { APIs } from '../constants/index';
import config from '../config';
import getNow from './getNow';

export const getResume = (fileName) => {
  return fileName ? `${config.baseURL}${APIs.index_file}${fileName}/` : null;
};

export const hasJobApplicationEvaluationEnded = (
  evaluationDeadlineDateTime,
) => {
  if (evaluationDeadlineDateTime === null) return false;
  const currentTime = getNow();
  return currentTime > evaluationDeadlineDateTime;
};
