/* eslint-disable no-nested-ternary */
import { INVALID_VALUE_ERROR, VALIDATION_ERROR } from '../constants';

export const getValidationMessage = (
  label?: string,
  customValidation?: boolean,
  invalidValue?: boolean,
) =>
  customValidation
    ? invalidValue
      ? INVALID_VALUE_ERROR
      : VALIDATION_ERROR
    : label
    ? invalidValue
      ? `Invalid value for ${label}`
      : `Please input your ${label}`
    : invalidValue
    ? INVALID_VALUE_ERROR
    : VALIDATION_ERROR;
