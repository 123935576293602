import { Button } from 'antd';
import React from 'react';
import GooglePicker from 'react-google-picker';
import styled from 'styled-components';
import first from 'lodash/first';
import get from 'lodash/get';
import config from '../../config';
import DriveLogo from '../../assets/svg/drive.svg';
import { UploadDocumentFromDriveNotification } from '../Notification/UploadPictureNotification';
import { getFilePath, updateResumeDrive } from '../../utils/profile';
import Notification from '../Notification/Notification';

const GoogleDrivePicker = ({ setResume, onSuccessResumeDrive = () => {} }) => {
  const onAuthFailed = (data) => {
    UploadDocumentFromDriveNotification(data);
  };

  const onChange = (data) => {
    if (get(data, 'action', null) === 'picked') {
      const file = first(data.docs);
      setResume([{ ...file, isLoading: true }]);
      UploadDocumentFromDriveNotification(data);
      updateResumeDrive(file, get(data, 'blob', null)).then((response) => {
        setResume([{ ...file, url: getFilePath(response) }]);
        UploadDocumentFromDriveNotification({ action: 'done' });
        onSuccessResumeDrive();
      });
    }
  };

  return (
    <GooglePicker
      clientId={config.google_client_id}
      developerKey={config.driveApiKey}
      scope={[
        'https://www.googleapis.com/auth/drive.readonly',
        'https://www.googleapis.com/auth/drive.file',
        'https://www.googleapis.com/auth/drive.metadata.readonly',
      ]}
      onAuthFailed={onAuthFailed}
      multiselect={false}
      authImmediate={false}
      viewId="PDFS"
      createPicker={(google, oauthToken) => {
        const googleViewId = google.picker.ViewId.PDFS;
        const docsView = new google.picker.DocsView(
          googleViewId,
        ).setIncludeFolders(true);

        const picker = new window.google.picker.PickerBuilder()
          .addView(docsView)
          .setOAuthToken(oauthToken)
          .setDeveloperKey(config.driveApiKey)
          .setCallback(async (data) => {
            const file = first(data.docs);
            let blob = null;

            if (file) {
              try {
                blob = await fetch(
                  `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media&key=${config.driveApiKey}`,
                  {
                    method: 'get',
                    headers: new Headers({
                      Authorization: `Bearer ${oauthToken}`,
                    }),
                  },
                ).then((response) => {
                  return response.blob();
                });
              } catch {
                Notification(
                  'error',
                  'Upload',
                  'Failed to load file from google drive',
                );
              }
            }

            onChange({ ...data, blob });
          });

        picker.build().setVisible(true);
      }}
    >
      <StyledDriveButton>
        <StyledDriveImage src={DriveLogo} alt="google drive" />
      </StyledDriveButton>
    </GooglePicker>
  );
};

export default GoogleDrivePicker;

const StyledDriveButton = styled(Button)`
  width: max-content;
  height: max-content;
`;

const StyledDriveImage = styled.img`
  width: 30px;
  height: 30px;
`;
