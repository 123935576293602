import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../../config';
import { APIs, Method } from '../../../constants';

const organizerApis = createApi({
  reducerPath: 'organizer/apis',
  tagTypes: ['integrations'],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
});

const injectedOrganizerApis = organizerApis.injectEndpoints({
  endpoints: (builder) => ({
    getParticularIntegration: builder.query({
      query: (type) => ({
        url: APIs.particularIntegration(type),
        method: Method.get,
      }),
      transformResponse: (response) => response.payload.integration,
    }),
    getAllIntegrations: builder.query({
      query: () => ({
        url: APIs.allIntegrations,
        method: Method.get,
      }),
      keepUnusedDataFor: 30,
      transformResponse: (response) => response.payload.integrations,
    }),
  }),
});

export default organizerApis;
export const {
  useLazyGetParticularIntegrationQuery,
  useGetAllIntegrationsQuery,
} = injectedOrganizerApis;
