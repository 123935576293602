import { createSlice } from '@reduxjs/toolkit';
import { getChatNotifications } from '../../service/chat';
import { getNotifications, getUpdates } from '../../service/notifications';

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.notifications = action.payload;
};

const onSuccessUpdates = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.updates = action.payload;
};

const onSuccessPagination = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.pagination = action.payload;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const onSuccessChat = (state, action) => {
  state.isChatLoading = false;
  state.chatError = null;
  state.chatNotifications = action.payload;
};

const onSuccessChatPagination = (state, action) => {
  state.isChatLoading = false;
  state.chatError = null;
  state.chatPagination = action.payload;
};

const onChatError = (state, action) => {
  state.isChatLoading = false;
  state.chatError = action.payload;
};

const onChatStart = (state) => {
  state.isChatLoading = true;
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    isLoading: false,
    notifications: [],
    pagination: {},
    updates: {},
    error: null,

    chatNotifications: [],
    isChatLoading: false,
    chatPagination: {},
    chatError: null,
  },
  reducers: {
    onStart,
    onSuccess,
    onError,
    onSuccessUpdates,
    onSuccessPagination,
    onSuccessChat,
    onSuccessChatPagination,
    onChatError,
    onChatStart,
  },
});

const getNotificationsData = (
  take = -1,
  category = 0,
  page,
  limit,
  withoutReload = false,
) => async (dispatch) => {
  try {
    if (!withoutReload) {
      dispatch(notificationsSlice.actions.onStart());
    }
    const { notifications, currentPage, totalItems } = await getNotifications(
      take,
      category,
      page,
      limit,
    );

    dispatch(
      notificationsSlice.actions.onSuccessPagination({
        currentPage,
        totalItems,
      }),
    );
    dispatch(notificationsSlice.actions.onSuccess(notifications));
  } catch (err) {
    dispatch(notificationsSlice.actions.onError(err.toString()));
  }
};

const getNotificationsUpdate = (withoutReload = false) => async (dispatch) => {
  try {
    if (!withoutReload) {
      dispatch(notificationsSlice.actions.onStart());
    }
    const updates = await getUpdates();
    dispatch(notificationsSlice.actions.onSuccessUpdates(updates));
  } catch (err) {
    dispatch(notificationsSlice.actions.onError(err.toString()));
  }
};

const getChatNotificationsData = (page, limit, withoutReload = false) => async (
  dispatch,
) => {
  try {
    if (!withoutReload) {
      dispatch(notificationsSlice.actions.onChatStart());
    }
    const {
      notifications,
      currentPage,
      totalItems,
    } = await getChatNotifications(page, limit);
    dispatch(
      notificationsSlice.actions.onSuccessChatPagination({
        currentPage,
        totalItems,
      }),
    );
    dispatch(notificationsSlice.actions.onSuccessChat(notifications));
  } catch (err) {
    dispatch(notificationsSlice.actions.onChatError(err.toString()));
  }
};

export default notificationsSlice.reducer;

export {
  getNotificationsData,
  getNotificationsUpdate,
  getChatNotificationsData,
};
