import React from 'react';
import styled from 'styled-components';
import { MARGIN } from '../../../styles/constants/display';

const About: React.FC<any> = ({ brief, letterCount }) => {
  const originalBrief = brief && brief.trim().split('');
  let about = '';
  const ellipsis = originalBrief && originalBrief.length < letterCount ? '' : '...';
  // eslint-disable-next-line no-unused-expressions
  !letterCount
    ? (about = brief)
    : (about = originalBrief && `${originalBrief.splice(0, letterCount).join('')}${ellipsis}`);

  return (
    <StyledContainer>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: (about || '').replace(/<p><br><\/p>/g, ''),
        }}
      />
    </StyledContainer>
  );
};

export default About;

const StyledContainer = styled.div`
  p {
    ${MARGIN(0, 0, 22, 0)}
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
`;
