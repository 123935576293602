// Discount type
export const discountType = [
  { id: 1, name: 'Percentage' },
  { id: 2, name: 'Flat' },
];

// Discount applicable on
export const applicable = [
  { id: 1, name: 'Candidate' },
  { id: 2, name: 'Recruiter' },
];
