import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { ToolTipsText } from '../../../../constants/tooltips';
import { PrimaryButton } from '../../../shared-components/Button';
import { getRoleId, getUserClassWithRoleId } from '../../../../utils/userInfo';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import { COLORS } from '../../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';

export default ({ openModal, event }) => {
  const roleId = getRoleId();

  return (
    <StyledWrapper svgColor={getUserColorWithRoleId(roleId)}>
      <Tooltip title={ToolTipsText.eventAnalytics}>
        <PrimaryButton
          onClick={() => (openModal ? openModal(event) : null)}
          theme={getUserClassWithRoleId(roleId)}
          id="view_analytics_btn"
        >
          <BarChartOutlined />
          View Analytics
        </PrimaryButton>
      </Tooltip>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.span`
  button {
    display: flex;
    align-items: center;
    height: 36px;
    width: max-content;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      padding: 0 5px;
    }

    &:hover {
      svg {
        fill: ${(props) => props.svgColor};
      }
    }
  }

  svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    fill: ${COLORS.WHITE};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
