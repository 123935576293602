import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Row, Col } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import {
  CalendarIcon,
  MonitorWaveIcon,
  ViewDetailsIcon,
  EditEventIcon,
  LocationPinIcon,
} from '../svgicons';
import { ongoingEventDateTimeFormat } from '../../utils/formatDateTime';
import { ONLINE_EVENT_TEXT, FREE_TEXT } from '../../constants';
import { isEventOnline } from '../../utils/event';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../utils/userInfo';
import EventImage from '../../assets/img/event-themes-featured.jpg';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../constants/routes';
import { useTimeZone } from '../../utils/customHooks';
import { getFileS3 } from '../../utils/s3.storage';
import {
  currencyConverting,
  formatCurrency,
} from '../../utils/currencyConvertor';
import { DEFAULT_CURRENCY } from '../../constants/currency';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { largeFontMixin } from '../../styles/constants/mixins';

/**
 * @deprecated The old one will be replaced by DateAndTypeSection component
 * in shared-components folder.
 */
export const EventDateAndTypeSection = ({ event, left = false, ongoing }) => {
  const timeZone = useTimeZone(event);

  return (
    <div className={left ? 'event-date-type--left' : 'date_sec'}>
      <h6 className={`${ongoing && left ? '' : 'mr25'} medium-font`}>
        <span>
          <CalendarIcon />
        </span>
        {`${ongoingEventDateTimeFormat(
          event.dateTime,
        )} to ${ongoingEventDateTimeFormat(event.endDateTime)} ${timeZone}`}
      </h6>
      {isEventOnline(event.eventType) ? (
        <h6 className="medium-font">
          <span>
            <MonitorWaveIcon />
          </span>
          {ONLINE_EVENT_TEXT}
        </h6>
      ) : (
        <h6 className="medium-font">
          <span>
            <LocationPinIcon />
          </span>
          {`${event.country}, ${event.zip}`}
        </h6>
      )}
    </div>
  );
};

export const EventViewDetails = ({ id: eventId }) => {
  const roleId = getRoleId();
  let url = '';
  if (checkIfUserIsOrganizer(roleId)) url = OrganizerRoutes.viewEvent(eventId);
  if (checkIfUserIsRecruiter(roleId)) url = RecruiterRoutes.viewEvent(eventId);
  if (checkIfUserIsCandidate(roleId)) url = CandidateRoutes.viewEvent(eventId);

  return (
    <StyledLink to={url}>
      <div id="event_view_details_btn">
        View Details
        <span>
          <ViewDetailsIcon />
        </span>
      </div>
    </StyledLink>
  );
};

export const EventViewDetailsLink = ({ id: eventId, children }) => {
  const roleId = getRoleId();
  let url = '';
  if (checkIfUserIsOrganizer(roleId)) url = OrganizerRoutes.viewEvent(eventId);
  if (checkIfUserIsRecruiter(roleId)) url = RecruiterRoutes.viewEvent(eventId);
  if (checkIfUserIsCandidate(roleId)) url = CandidateRoutes.viewEvent(eventId);

  return <StyledLink to={url}>{children}</StyledLink>;
};

export const ViewAllRecommendedEvents = () => {
  const roleId = getRoleId();
  let url = '';
  if (checkIfUserIsRecruiter(roleId)) url = `/recruiter/events/explore-events`;
  if (checkIfUserIsCandidate(roleId)) url = `/candidate/events/explore-events`;

  return (
    <Link to={url} className="hover">
      <div>
        <span>
          <div className="recommended-events__button--right small-heading">
            View More
          </div>
        </span>
      </div>
    </Link>
  );
};

export const ShareEventSection = () => {
  const roleId = getRoleId();

  const EditEventSection = () => {
    return (
      <>
        <span className="promote_eve">Edit Event</span>
        <Button className="blue_icon_btn mr0 round_icon">
          <EditEventIcon />
        </Button>
      </>
    );
  };

  return (
    <Row>
      <Col xs={24} lg={12} xl={9} xxl={8} className="flex_center_end">
        <span className="promote_eve">
          {checkIfUserIsOrganizer(roleId) ? 'Promote Event' : 'Share Event'}
        </span>
        <Button className="blue_icon_btn mr70">
          <ShareAltOutlined />
        </Button>
        {checkIfUserIsOrganizer(roleId) ? <EditEventSection /> : null}
      </Col>
    </Row>
  );
};

export const TicketPricing = ({
  currency,
  userTicketPrice,
  recruiterTicketPrice,
}) => {
  const roleId = getRoleId();
  const { currencyData } = useSelector((st) => st.commons);
  const { profileData } = useSelector((st) => st.profile);
  const { paymentCurrency } = profileData;

  const checkTicketPrice = (price) => {
    const currecyFormat = formatCurrency(currency, price);
    const currencyLocalFormat = currencyConverting({
      fromCurrency: currency,
      fromAmount: price,
      toCurrency: paymentCurrency ? paymentCurrency.code : DEFAULT_CURRENCY,
      currenciesData: currencyData,
    });
    return price
      ? `${currecyFormat || `${currency} ${price}`}${
          currencyLocalFormat ? ` Or ${currencyLocalFormat}` : ''
        }`
      : FREE_TEXT;
  };

  if (checkIfUserIsRecruiter(roleId)) {
    return checkTicketPrice(recruiterTicketPrice);
  }
  if (checkIfUserIsCandidate(roleId)) {
    return checkTicketPrice(userTicketPrice);
  }
  return '';
};

export const EventCoverPicture = ({ cover, className }) => {
  const [eventCover, setEventCover] = useState(EventImage);

  useEffect(() => {
    setEventCover(getFileS3(cover));
  }, [cover]);

  return <img src={eventCover} className={className} alt="noimage" />;
};

export const viewEventUrl = (roleId, eventId, isDiscussionRoom = false) => {
  let url = '';
  if (checkIfUserIsOrganizer(roleId))
    url = `${OrganizerRoutes.viewEvent(eventId)}`;
  if (checkIfUserIsRecruiter(roleId))
    url = `${RecruiterRoutes.viewEvent(eventId)}`;
  if (checkIfUserIsCandidate(roleId))
    url = `${CandidateRoutes.viewEvent(eventId)}${
      isDiscussionRoom ? `?discussionRoom=true` : ''
    }`;

  return url;
};

export const RenderButtonTags = ({ text, color, backgroundColor }) => {
  return (
    <StyledRenderButtonTags color={color} backgroundColor={backgroundColor}>
      {text}
    </StyledRenderButtonTags>
  );
};

export const OngoingAnalyticsItem = ({ name, value }) => {
  return (
    <li>
      <StyledOngoingAnalyticsEventName>{name}</StyledOngoingAnalyticsEventName>
      <StyledOngoingAnalyticsEventValue>
        {value || 0}
      </StyledOngoingAnalyticsEventValue>
    </li>
  );
};

export const OngoingAnalytics = ({ itemList, className }) => {
  return (
    <ul className={className || ''}>
      {itemList.map((item, idx) => {
        const { name, value } = item;
        return (
          <OngoingAnalyticsItem
            name={name}
            value={value}
            key={idx.toString()}
          />
        );
      })}
    </ul>
  );
};

const StyledRenderButtonTags = styled(Button)`
  color: ${(props) => `${props.color} !important` || ''};
  background-color: ${(props) => `${props.backgroundColor} !important` || ''};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  border: none;
  white-space: initial;
  height: fit-content;

  &:hover {
    cursor: unset;
  }
`;

// Added this because the pointer works while testing in local but on qa it does not work dues to some default css , so to overcome added important tag to also make cursor pointer for this.
const StyledLink = styled(Link)`
  cursor: pointer !important;
`;

const StyledOngoingAnalyticsEventName = styled.p`
  ${largeFontMixin};
`;

const StyledOngoingAnalyticsEventValue = styled.b`
  ${largeFontMixin};
`;
