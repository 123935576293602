import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  CHATROOM_TYPE_GROUP,
  CHATROOM_TYPE_PRIVATE,
  GROUPCHAT_CHATROOM_LIMIT,
  GROUPCHAT_MESSAGES_LIMIT,
  INITIAL_PAGE,
  PERSONALCHAT_CHATROOM_LIMIT,
  PERSONALCHAT_MESSAGES_LIMIT,
} from '../../constants';
import {
  fetchChatroomMessages,
  fetchChats,
  generateUserAnonymousName,
  getChatRoomWithId,
  getPinnedMessages,
} from '../../service/chat';
import {
  extractUsersProfileData,
  getSecondUserData,
  fetchCompanyUsersDetails,
  setLocalStorageItemWithExpiry,
  getLocalStorageItemWithExpiry,
} from '../../utils/chat';

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccess = (state) => {
  state.isLoading = false;
  state.error = false;
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

// Personal Chats
const onLoadPersonalChats = (state, action) => {
  const chats = get(action, 'payload.chats', []);
  const profileData = get(action, 'payload.profileData', []);
  state.personalChats = chats;
  state.selectedChatId = get(action, 'payload.chatRoomId', '');
  state.personalChatsUsersProfileData = profileData;
  state.isPersonalChatsLoaded = true;
  state.personalChatsLoading = false;
};
const onFetchingChats = (state) => {
  state.personalChatsLoading = true;
};
const onNotHavingMoreChats = (state) => {
  state.hasMorePersonalChats = false;
};

const onUpdateSelectedChatId = (state, action) => {
  if (state.selectedChatId !== action.payload) {
    state.selectedChatDetails = null;
    state.selectedChatMessages = [];
  }
  state.selectedChatId = action.payload;
};

const onFetchSelectedChat = (state, action) => {
  state.selectedChatDetails = action.payload;
};
const onFetchingChatMessages = (state) => {
  state.personalChatMessagesLoading = true;
};
const onNotHavingMoreChatMessages = (state) => {
  state.hasMorePersonalChatMessages = false;
};
const onFetchSelectedChatMessages = (state, action) => {
  state.selectedChatMessages = action.payload;
  state.personalChatMessagesLoading = false;
};
const onFetchSelectedChatUsersDetails = (state, action) => {
  state.selectedChatUsersDetails = action.payload;
};
const onChatStateChange = (state, action) => {
  state.isChatOpen = action.payload;
};

const onChatWindowChange = (state, action) => {
  state.chatWindow = action.payload;
};

// Group Chats
const onLoadGroupChats = (state, action) => {
  const chats = get(action, 'payload.chats', []);
  const profileData = get(action, 'payload.profileData', []);
  const room = get(action, 'payload.room', null);
  state.groupChats = chats;
  state.selectedGroupChatId = room || get(chats[0], 'id', null);
  state.groupChatsUsersProfileData = profileData;
  state.isGroupChatsLoaded = true;
};

const onUpdateSelectedGroupChatId = (state, action) => {
  if (state.selectedGroupChatId !== action.payload) {
    state.selectedGroupChatMessages = [];
    state.selectedGroupChatPinnedMessages = [];
  }
  state.selectedGroupChatId = action.payload;
};

const onFetchSelectedGroupChat = (state, action) => {
  state.selectedGroupChatDetails = action.payload;
};
const onFetchingGroupChatMessages = (state) => {
  state.groupChatMessagesLoading = true;
};
const onNotHavingMoreGroupChatMessages = (state) => {
  state.hasMoreGroupChatMessages = false;
};
const onFetchSelectedGroupChatMessages = (state, action) => {
  state.selectedGroupChatMessages = action.payload.messages;
  state.selectedGroupChatCompanyUserDetails =
    action.payload.companyUsersDetails;
  state.groupChatMessagesLoading = false;
};

const onFetchGroupChatPinnedMessages = (state, action) => {
  state.selectedGroupChatPinnedMessages = action.payload.pinnedMessages;
  state.groupChatMessagesLoading = false;
};

const onFetchNewCompanyUsersDetails = (state, action) => {
  state.selectedGroupChatCompanyUserDetails = action.payload;
};

const onDeleteGroupChatMessage = (state, action) => {
  const threadId = action.payload;
  state.selectedGroupChatMessages = state.selectedGroupChatMessages.filter(
    (msg) => {
      const id = get(msg, '_id', '');
      return id !== threadId;
    },
  );
  state.selectedGroupChatPinnedMessages = state.selectedGroupChatPinnedMessages.filter(
    (msg) => {
      const id = get(msg, '_id', '');
      return id !== threadId;
    },
  );
};

const onDeleteGroupChatMessages = (state, action) => {
  const threadIds = action.payload;
  state.selectedGroupChatMessages = state.selectedGroupChatMessages.filter(
    (msg) => {
      const id = get(msg, '_id', '');
      return !threadIds.includes(id);
    },
  );
  state.selectedGroupChatPinnedMessages = state.selectedGroupChatPinnedMessages.filter(
    (msg) => {
      const id = get(msg, '_id', '');
      return !threadIds.includes(id);
    },
  );
};

const onPinnedGroupChatMessage = (state, action) => {
  const threadId = action.payload;
  state.selectedGroupChatMessages = state.selectedGroupChatMessages.map(
    (msg) => {
      const id = get(msg, '_id', '');
      return id === threadId ?
        { ...msg, isPinned: true }
        : msg;
    }
  );
};

const onUnpinnedGroupChatMessage = (state, action) => {
  const threadId = action.payload;
  state.selectedGroupChatMessages = state.selectedGroupChatMessages.map(
    (msg) => {
      const id = get(msg, '_id', '');
      return id === threadId ?
        { ...msg, isPinned: false }
        : msg;
    }
  );

  state.selectedGroupChatPinnedMessages = state.selectedGroupChatPinnedMessages.filter(
    (msg) => {
      const id = get(msg, '_id', '');
      return id !== threadId;
    },
  );
};

const onFetchSelectedGroupChatUsersDetails = (state, action) => {
  state.selectedGroupChatUsersDetails = action.payload;
};

const onSetUserAnonymity = (state, action) => {
  state.userAnonymity = action.payload;
};

const onSetUserAnonymousName = (state, action) => {
  state.userAnonymousName = action.payload;
};

// Pagination
const onResetPersonalChatsPagination = (state) => {
  state.personalChatsPagination.chatRoomPage = 1;
  state.hasMorePersonalChats = true;
};
const onResetGroupChatsPagination = (state) => {
  state.groupChatsPagination.chatRoomPage = 1;
  state.hasMoreGroupChats = true;
};

const onResetPersonalChatMessagesPagination = (state) => {
  state.personalChatsPagination.messagesPage = 1;
  state.hasMorePersonalChatMessages = true;
};
const onResetGroupChatMessagesPagination = (state) => {
  state.groupChatsPagination.messagesPage = 1;
  state.hasMoreGroupChatMessages = false;
};

const onPersonalChatsPageIncrement = (state) => {
  state.personalChatsPagination = {
    ...state.personalChatsPagination,
    chatRoomPage: state.personalChatsPagination.chatRoomPage + 1,
  };
};

const onPersonalChatMessagesPageIncrement = (state) => {
  state.personalChatsPagination = {
    ...state.personalChatsPagination,
    messagesPage: state.personalChatsPagination.messagesPage + 1,
  };
};
const onGroupChatMessagesPageIncrement = (state) => {
  state.groupChatsPagination = {
    ...state.groupChatsPagination,
    messagesPage: state.groupChatsPagination.messagesPage + 1,
  };
  state.hasMoreGroupChatMessages = true;
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    isLoading: false,
    error: null,

    // Personal Chats
    personalChats: [],
    personalChatsLoading: true,
    hasMorePersonalChats: true,
    personalChatsPagination: {
      chatRoomPage: 1,
      messagesPage: 1,
    },
    selectedChatId: null,
    selectedChatDetails: null,
    selectedChatMessages: [],
    personalChatMessagesLoading: true,
    hasMorePersonalChatMessages: true,
    selectedChatUsersDetails: [],
    personalChatsUsersProfileData: null,
    isChatOpen: null,
    chatWindow: null,

    // Group Chats
    isGroupChatsLoaded: false,
    groupChats: [],
    groupChatsLoading: true,
    hasMoreGroupChats: true,
    groupChatsPagination: {
      chatRoomPage: 1,
      messagesPage: 1,
    },
    selectedGroupChatId: null,
    selectedGroupChatDetails: null,
    selectedGroupChatMessages: [],
    selectedGroupChatPinnedMessages: [],
    groupChatMessagesLoading: true,
    hasMoreGroupChatMessages: false,
    selectedGroupChatUsersDetails: [],
    selectedGroupChatCompanyUserDetails: [],
    groupChatsUsersProfileData: null,
    userAnonymity: false,
    userAnonymousName: '',
  },
  reducers: {
    onStart,
    onSuccess,
    onError,
    // Personal Chats
    onLoadPersonalChats,
    onFetchingChats,
    onNotHavingMoreChats,
    onUpdateSelectedChatId,
    onFetchSelectedChat,
    onFetchingChatMessages,
    onNotHavingMoreChatMessages,
    onFetchSelectedChatMessages,
    onFetchSelectedChatUsersDetails,
    onChatStateChange,
    onChatWindowChange,
    // Group Chats
    onLoadGroupChats,
    onUpdateSelectedGroupChatId,
    onFetchSelectedGroupChat,
    onFetchingGroupChatMessages,
    onNotHavingMoreGroupChatMessages,
    onFetchSelectedGroupChatMessages,
    onFetchGroupChatPinnedMessages,
    onFetchNewCompanyUsersDetails,
    onDeleteGroupChatMessage,
    onDeleteGroupChatMessages,
    onPinnedGroupChatMessage,
    onUnpinnedGroupChatMessage,
    onFetchSelectedGroupChatUsersDetails,
    onSetUserAnonymity,
    onSetUserAnonymousName,
    // Pagination
    onResetPersonalChatsPagination,
    onResetGroupChatsPagination,
    onResetPersonalChatMessagesPagination,
    onResetGroupChatMessagesPagination,
    onPersonalChatsPageIncrement,
    onPersonalChatMessagesPageIncrement,
    onGroupChatMessagesPageIncrement,
  },
});

export const setUserAnonymousName = (saveLocally = false) => async (
  dispatch,
) => {
  const name = saveLocally
    ? await generateUserAnonymousName()
    : getLocalStorageItemWithExpiry('anonymousName');
  dispatch(chatSlice.actions.onSetUserAnonymousName(name));
  if (saveLocally) {
    setLocalStorageItemWithExpiry('anonymousName', name);
    // sessionStorage.setItem('anonymousName', name);
  }
};

export const removeUserAnonymousName = () => async (dispatch) => {
  // const name = sessionStorage.getItem('anonymousName');
  // if (name) await deleteUserAnonymousName(name);
  localStorage.removeItem('anonymousName');
  dispatch(chatSlice.actions.onSetUserAnonymousName(null));
};

export const checkAndReGenerate = () => async (dispatch) => {
  if (!getLocalStorageItemWithExpiry('anonymousName')) {
    dispatch(setUserAnonymousName(true));
  }
};

// Personal Chats
export const setPersonalChats = (loadChatRoomId) => async (dispatch) => {
  try {
    dispatch(chatSlice.actions.onStart());
    dispatch(chatSlice.actions.onResetPersonalChatsPagination());
    dispatch(chatSlice.actions.onFetchingChats());
    const { chats, totalPages } = await fetchChats(
      { type: CHATROOM_TYPE_PRIVATE },
      INITIAL_PAGE,
      PERSONALCHAT_CHATROOM_LIMIT,
    );
    const profileData = await extractUsersProfileData(chats);
    const chatRoomId = loadChatRoomId || get(chats[0], 'id', '');
    dispatch(
      chatSlice.actions.onLoadPersonalChats({
        chats,
        profileData,
        chatRoomId,
      }),
    );
    if (totalPages <= 1) {
      dispatch(chatSlice.actions.onNotHavingMoreChats());
    } else {
      dispatch(chatSlice.actions.onPersonalChatsPageIncrement());
    }
    dispatch(chatSlice.actions.onSuccess());
  } catch (err) {
    dispatch(chatSlice.actions.onError(err.toString()));
  }
};

export const loadMorePersonalChats = (
  loadChatRoomId,
  prevChats,
  page,
) => async (dispatch) => {
  dispatch(chatSlice.actions.onFetchingChats());
  const { chats, totalPages } = await fetchChats(
    { type: CHATROOM_TYPE_PRIVATE },
    page,
    PERSONALCHAT_CHATROOM_LIMIT,
  );
  const totalChats = [...prevChats, ...chats];
  const profileData = await extractUsersProfileData(totalChats);
  const chatRoomId = loadChatRoomId || get(chats[0], 'id', '');
  dispatch(
    chatSlice.actions.onLoadPersonalChats({
      chats: totalChats,
      profileData,
      chatRoomId,
    }),
  );
  if (page === totalPages) {
    dispatch(chatSlice.actions.onNotHavingMoreChats());
  } else {
    dispatch(chatSlice.actions.onPersonalChatsPageIncrement());
  }
};

export const changeSelectedChatId = (id) => async (dispatch) => {
  dispatch(chatSlice.actions.onUpdateSelectedChatId(id));
};

export const changeChatState = (value) => async (dispatch) => {
  dispatch(chatSlice.actions.onChatStateChange(value));
};

export const changeChatWindow = (value) => async (dispatch) => {
  dispatch(chatSlice.actions.onChatWindowChange(value));
};

export const fetchInitialChatMessages = (chatRoomId) => async (dispatch) => {
  dispatch(chatSlice.actions.onResetPersonalChatMessagesPagination());
  dispatch(chatSlice.actions.onFetchingChatMessages());
  const { messages, totalPages } = await fetchChatroomMessages(
    chatRoomId,
    INITIAL_PAGE,
    PERSONALCHAT_MESSAGES_LIMIT,
  );
  dispatch(chatSlice.actions.onFetchSelectedChatMessages(messages));
  if (totalPages <= 1) {
    dispatch(chatSlice.actions.onNotHavingMoreChatMessages());
  } else {
    dispatch(chatSlice.actions.onPersonalChatMessagesPageIncrement());
  }
};

export const loadMoreChatMessages = (chatRoomId, prevMessages, page) => async (
  dispatch,
) => {
  dispatch(chatSlice.actions.onFetchingChatMessages());
  const { messages, totalPages } = await fetchChatroomMessages(
    chatRoomId,
    page,
    PERSONALCHAT_MESSAGES_LIMIT,
  );
  const totalMessages = [...prevMessages, ...messages];
  dispatch(chatSlice.actions.onFetchSelectedChatMessages(totalMessages));

  if (page === totalPages) {
    dispatch(chatSlice.actions.onNotHavingMoreChatMessages());
  } else {
    dispatch(chatSlice.actions.onPersonalChatMessagesPageIncrement());
  }
};

export const getSelectedChatDetails = (
  selectedChatId,
  loggedInUserId,
  usersProfileData,
  roleId,
) => async (dispatch) => {
  const chatRoom = await getChatRoomWithId(selectedChatId);
  const userDetail = await getSecondUserData(
    chatRoom,
    loggedInUserId,
    usersProfileData,
    roleId,
  );
  dispatch(chatSlice.actions.onFetchSelectedChat(userDetail));
  dispatch(fetchInitialChatMessages(selectedChatId));
};

export const getSelectedChatUsersDetails = (userDetails) => (dispatch) => {
  dispatch(chatSlice.actions.onFetchSelectedChatUsersDetails(userDetails));
};

// Group Chats
export const setGroupChats = (room = null) => async (dispatch) => {
  try {
    dispatch(chatSlice.actions.onStart());
    dispatch(chatSlice.actions.onResetGroupChatsPagination());
    const { chats } = await fetchChats(
      { type: CHATROOM_TYPE_GROUP },
      INITIAL_PAGE,
      GROUPCHAT_CHATROOM_LIMIT,
    );
    const profileData = await extractUsersProfileData(chats);
    dispatch(chatSlice.actions.onLoadGroupChats({ chats, profileData, room }));
    dispatch(chatSlice.actions.onSuccess());
  } catch (err) {
    dispatch(chatSlice.actions.onError(err.toString()));
  }
};

export const changeSelectedGroupChatId = (id) => async (dispatch) => {
  dispatch(chatSlice.actions.onUpdateSelectedGroupChatId(id));
};

export const fetchInitialGroupChatMessages = (chatRoom) => async (dispatch) => {
  dispatch(chatSlice.actions.onResetGroupChatMessagesPagination());
  dispatch(chatSlice.actions.onFetchingGroupChatMessages());
  const chatRoomId = get(chatRoom, '_id', '');
  const { messages, totalPages } = await fetchChatroomMessages(
    chatRoomId,
    INITIAL_PAGE,
    GROUPCHAT_MESSAGES_LIMIT,
  );
  const companyUsersDetails = await fetchCompanyUsersDetails(messages);
  dispatch(
    chatSlice.actions.onFetchSelectedGroupChatMessages({
      messages,
      companyUsersDetails,
    }),
  );
  const { pinnedMessages } = await getPinnedMessages(chatRoomId);
  dispatch(chatSlice.actions.onFetchGroupChatPinnedMessages({ pinnedMessages }));
  if (totalPages <= 1) {
    dispatch(chatSlice.actions.onNotHavingMoreGroupChatMessages());
  } else {
    dispatch(chatSlice.actions.onGroupChatMessagesPageIncrement());
  }
};

export const loadMoreGroupChatMessages = (
  chatRoomId,
  prevMessages,
  page,
  reFetch = true,
  companyUsersDetails,
) => async (dispatch) => {
  dispatch(chatSlice.actions.onFetchingGroupChatMessages());
  const { messages, totalPages } = await fetchChatroomMessages(
    chatRoomId,
    page,
    GROUPCHAT_MESSAGES_LIMIT,
  );
  const totalMessages = [...prevMessages, ...messages];

  // Refetch if required
  if (reFetch) {
    companyUsersDetails = await fetchCompanyUsersDetails(totalMessages);
  }

  dispatch(
    chatSlice.actions.onFetchSelectedGroupChatMessages({
      messages: totalMessages,
      companyUsersDetails,
    }),
  );
  if (totalPages === page) {
    dispatch(chatSlice.actions.onNotHavingMoreGroupChatMessages());
  } else {
    dispatch(chatSlice.actions.onGroupChatMessagesPageIncrement());
  }
};

export const loadNewCompanyUserDetails = (
  newCompanyUserDetails,
  existingCompanyUsersDetails,
) => async (dispatch) => {
  dispatch(
    chatSlice.actions.onFetchNewCompanyUsersDetails(
      existingCompanyUsersDetails.concat(newCompanyUserDetails),
    ),
  );
};

export const getSelectedGroupChatDetails = (chatRoomDetails) => async (
  dispatch,
) => {
  dispatch(chatSlice.actions.onFetchSelectedGroupChat(chatRoomDetails));
  dispatch(fetchInitialGroupChatMessages(chatRoomDetails));
};

export const deleteGroupChatThread = (threadId) => async (dispatch) => {
  dispatch(chatSlice.actions.onDeleteGroupChatMessage(threadId));
};

export const pinnedGroupChatThread = (threadId, chatRoomId, isPin) => async (dispatch) => {
  if (!isPin) {
    dispatch(chatSlice.actions.onPinnedGroupChatMessage(threadId));
    const { pinnedMessages } = await getPinnedMessages(chatRoomId);
    dispatch(chatSlice.actions.onFetchGroupChatPinnedMessages({ pinnedMessages }));
  } else {
    dispatch(chatSlice.actions.onUnpinnedGroupChatMessage(threadId));
  }
};

export const unpinnedGroupChatThread = (threadId) => async (dispatch) => {
  dispatch(chatSlice.actions.onUnpinnedGroupChatMessage(threadId));
};

export const RealTimePinnedMessage = (chatRoomId) => async (dispatch) => {
  const { pinnedMessages } = await getPinnedMessages(chatRoomId);
  dispatch(chatSlice.actions.onFetchGroupChatPinnedMessages({ pinnedMessages }));
};

export const deleteGroupChatThreads = (threadIds) => async (dispatch) => {
  dispatch(chatSlice.actions.onDeleteGroupChatMessages(threadIds));
};

export const getSelectedGroupChatUsersDetails = (userDetails) => (dispatch) => {
  dispatch(chatSlice.actions.onFetchSelectedGroupChatUsersDetails(userDetails));
};

export const setUserAnonymous = (anonymity) => (dispatch) => {
  dispatch(chatSlice.actions.onSetUserAnonymity(anonymity));
};

export default chatSlice.reducer;
