import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)<{
  color: string;
  bgColor?: string;
  width?: string;
}>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor} !important;
  border-color: ${({ color }) => color};
  width: ${({ width }) => width || 'min-content'};
  text-align: center;
`;
