export const PieData = [
  {
    type: 'Technical',
    value: 50,
  },
  {
    type: 'Management',
    value: 35,
  },
  {
    type: 'Techno Functional',
    value: 15,
  },
];

export const PieColors = ['#1890FF', '#FFB81C', '#47BFA8'];
