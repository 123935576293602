import React from 'react';
import styled from 'styled-components';
import { EventsStore, getCancelledBookingEvents } from '../../../utils/event';
import { getRoleId, checkIfUserIsOrganizer } from '../../../utils/userInfo';
import { EventCard } from '../../EventCard';
import { StyledMainHeader } from '../../shared-components/Event/Common';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { MARGIN } from '../../../styles/constants/display';

const CancelledEvents = ({ search = '', noTimeline = null, noTitle = '' }) => {
  const { cancelledEvents } = EventsStore();
  const roleId = getRoleId();

  if (!checkIfUserIsOrganizer(roleId)) {
    const { upComingEvents, onGoingEvents, pastEvents } = EventsStore();
    const allEvents = onGoingEvents.concat(upComingEvents, pastEvents);

    const events = getCancelledBookingEvents(allEvents, false, roleId);

    const filteredEvents = events.length
      ? events.filter((event) =>
          event.name.toLowerCase().includes(search.toLowerCase()),
        )
      : [];

    if (filteredEvents.length) {
      return (
        <StyledMainContainer noTitle={noTitle}>
          {noTitle ? (
            <>
              <EventCard
                events={events}
                category="cancelled"
                noTimeline={noTimeline}
              />
            </>
          ) : (
            <>
              <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
                Cancelled Events
              </StyledMainHeader>
              <EventCard events={events} category="cancelled" />
            </>
          )}
        </StyledMainContainer>
      );
    }
  }

  // For organizer
  const cancelledEventsFilteredList = cancelledEvents.length
    ? cancelledEvents.filter((event) =>
        event.name.toLowerCase().includes(search.toLowerCase()),
      )
    : [];

  if (cancelledEvents.length) {
    return (
      <StyledMainContainer>
        <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
          Cancelled Events
        </StyledMainHeader>
        <EventCard events={cancelledEventsFilteredList} category="cancelled" />
      </StyledMainContainer>
    );
  }
  return null;
};

export default CancelledEvents;

const StyledMainContainer = styled.div`
  ${({ noTitle }) => (noTitle ? '' : MARGIN(25, 0))}
`;
