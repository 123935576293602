import { TWELVE } from '../constants';

const CandidateExp = (profileData) => {
  if (!profileData) {
    return { candidateExpinYr: '-', candidateExpinMon: '-' };
  }
  const candidateExpinYr = Math.floor(profileData.experience / TWELVE);
  const candidateExpinMon = profileData.experience % TWELVE;
  return { candidateExpinYr, candidateExpinMon };
};

export { CandidateExp };
