import React, { useEffect, useState } from 'react';
import { Row, Col, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Dropdown, NumberBox } from '../../shared-components/Form/Form';
import PromoCode from '../../PromoCode/PromoCode';
import { StyledTitle } from './BasicInformation';
import TaxForm from './TaxForm';
import { SecondaryHeading } from './Common';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { currencyConverting } from '../../../utils/currencyConvertor';
import { DEFAULT_CURRENCY } from '../../../constants/currency';
import MandatoryStar from '../../Common/mandatoryStar';
import get from 'lodash/get';
import { bookingMethods } from '../../../mocks/organizer/events/create-event';
import { BOOKING_METHOD_TEXT, } from '../../../constants/index';

const BasicPricing = ({ form, event, errorFields, isDisabled = "true", setBookingMethod, }) => {
  const { currencyData } = useSelector((st) => st.commons);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const handleChange = (val) => {
    if (val !== DEFAULT_CURRENCY) {
      setSelectedCurrency(val);
    } else {
      setSelectedCurrency(null);
    }
  };

  useEffect(() => {
    const currency = form.getFieldValue('currency');
    handleChange(currency);

    // eslint-disable-next-line
  }, []);

  const getMininumAmmountTicket = () => {
    // Populate toCurrency (exchange from USD to selected currency)
    const toCurrency = selectedCurrency || 'INR';

    // Show original minimum and exchanged one
    return `${currencyConverting({
      fromCurrency: DEFAULT_CURRENCY,
      fromAmount: 0.5,
      toCurrency,
      currenciesData: currencyData,
    })}`;
  };

  const validator = (rule, value) => {
    const toCurrency = selectedCurrency || 'INR';
    const firstCurrencyConverting = currencyData.find(
      (c) => c.value === DEFAULT_CURRENCY,
    );
    // Convert from usd to new currency
    const secondCurrencyConverting = currencyData.find(
      (c) => c.value === toCurrency,
    );

    const toAmount = selectedCurrency
      ? (parseFloat(0.5) / parseFloat(firstCurrencyConverting.usdRate)) *
      parseFloat(secondCurrencyConverting.usdRate)
      : 0.5;

    if (value === 0 || value >= toAmount) {
      return Promise.resolve();
    }

    return Promise.reject('');
  };

  const handleBookingMethodChange = (value) => {
    setBookingMethod(value);
  };

  const getBookingMethodDefautValue = () => {
    return get(event, 'thirdPartyData', []).length
      ? BOOKING_METHOD_TEXT.thirdParty
      : BOOKING_METHOD_TEXT.recorem;
  };

  return (
    <>
      <Row gutter={[25, 10]}>
        <Col xs={24} md={5}>
          <SecondaryHeading id="booking_method_label">
            Booking Method <MandatoryStar />
          </SecondaryHeading>
          <Dropdown
            name="bookingMethod"
            placeholder="Select the booking method"
            options={bookingMethods}
            defaultValue={getBookingMethodDefautValue}
            showArrow
            handleChange={handleBookingMethodChange}
            required={false}
            id="booking_method"
          />
        </Col>
        <Col xs={24} md={6}>
          <SecondaryHeading>
            Currency <MandatoryStar />
          </SecondaryHeading>
          <Dropdown
            name="currency"
            placeholder="Select currency"
            showSearch
            options={currencyData}
            handleChange={handleChange}
            disabled={isDisabled}
            required={isDisabled ? false : true}
          />
        </Col>
      </Row>

      {
        !isDisabled ? (
          <Row>
            <Col>
              <StyledTitle level={5}>Event Pricing </StyledTitle>
            </Col>
            <StyledCenterAligned>
              <Tooltip
                color={COLORS.WHITE}
                title={`Minimun ticket price should be equivalent to $0.50 ${selectedCurrency ? ` or ${getMininumAmmountTicket()}` : ''
                  }`}
                overlayInnerStyle={{ color: 'black', backgroundColor: 'white' }}
              >
                <InfoCircleOutlined
                  style={{
                    color: COLORS.WHITE,
                    backgroundColor: COLORS.PUERTO_RICO,
                    borderRadius: '50%',
                    fontSize: '20px',
                    position: 'relative',
                    left: '15px',
                  }}
                />
              </Tooltip>
            </StyledCenterAligned>
          </Row>
        ) : (null)
      }

      <Row gutter={[25, 10]}>
        {
          !isDisabled ? (
            <>
              <Col xs={24} md={6}>
                <SecondaryHeading>
                  Attendees ticket price <MandatoryStar />
                </SecondaryHeading>
                <NumberBox
                  rule="priceNumber"
                  name="userTicketPrice"
                  placeholder="Enter Attendees ticket price"
                  required
                  validator={validator}
                  disabled={isDisabled}
                />
              </Col>
              <Col xs={24} md={6}>
                <SecondaryHeading>
                  Recruiter ticket price {' '} <MandatoryStar />
                </SecondaryHeading>
                <NumberBox
                  rule="priceNumber"
                  name="recruiterTicketPrice"
                  placeholder="Enter Recruiters ticket price (Optional)"
                  required={false}
                  validator={validator}
                  disabled={isDisabled}
                />
              </Col>
            </>
          ) : <Col>
            <SecondaryHeading>
              Event Price: 0
            </SecondaryHeading>
          </Col>
        }
      </Row>

      <TaxForm event={event} form={form} BtnDisabled={isDisabled} />

      {/* promo code */}
      <SecondaryHeading style={{ marginTop: '20px' }}>Promo Code Details</SecondaryHeading>
      <PromoCode
        form={form}
        selectedCurrency={selectedCurrency}
        currencyData={currencyData}
        BtnDisabled={isDisabled}
      />
    </>
  );
};

export default BasicPricing;

const StyledCenterAligned = styled(Col)`
  ${DISPLAY_FLEX('column', 'center', 'center')}
`;
