import { useSelector } from 'react-redux';
import { STATUS_PENDING, STATUS_ACTIVE } from '../constants';
import { PAYMENT_STATUS } from '../enums/payment-status';

export const isPaymentPending = (paymentStatus) => {
  return paymentStatus === STATUS_PENDING;
};

export const isPaymentCompleted = (paymentStatus) => {
  return paymentStatus === STATUS_ACTIVE;
};

export const usePaymentInformation = () => {
  const cryptoPlan = useSelector(
    (state) => state.cryptoIncentivePlan?.cryptoIncentivePlan,
  );

  const isRecruiterExisting = useSelector(
    (state) => state.cryptoIncentivePlan?.isRecruiterExisting,
  );

  if (cryptoPlan === null) {
    return {
      paymentStatusCode: STATUS_PENDING,
      paymentStatus: PAYMENT_STATUS.Pending,
      transactionHash: null,
      paymentPending: true,
      paymentCompleted: false,
      isRecruiterExisting,
      cryptoIncentivePlan: null,
    };
  }
  const {
    status,
    paymentStatus,
    transactionHash,
    cryptoIncentivePlan,
  } = cryptoPlan;

  return {
    paymentStatusCode: status,
    paymentStatus,
    transactionHash,
    paymentPending: isPaymentPending(status),
    paymentCompleted: isPaymentCompleted(status),
    isRecruiterExisting,
    cryptoIncentivePlan,
  };
};
