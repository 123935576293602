import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import DashboardAnalytics from '../../Dashboard/Analytics/Analytics';
import { checkIfUserIsCandidate, getRoleId } from '../../../utils/userInfo';
import OngoingEvents from './OngoingEvents';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

export default ({
  dashboard = false,
  colPropsOngoingSection = { xs: 24 },
  colPropsEventInfoSection = { xxl: 13, xl: 13, lg: 13, sm: 24, xs: 24 },
  colPropsEventPicSection = { xxl: 11, xl: 11, lg: 11, sm: 24, xs: 24 },
  search,
  handleModalOpen,
}) => {
  const roleId = getRoleId();

  return (
    <StyledRow>
      <OngoingEvents
        colPropsOngoingSection={colPropsOngoingSection}
        colPropsEventInfoSection={colPropsEventInfoSection}
        colPropsEventPicSection={colPropsEventPicSection}
        dashboard={dashboard}
        roleId={roleId}
        search={search}
        handleModalOpen={handleModalOpen}
      />
      {checkIfUserIsCandidate(roleId) ? (
        <StyledCard>{dashboard ? <DashboardAnalytics /> : null}</StyledCard>
      ) : dashboard ? (
        <DashboardAnalytics />
      ) : null}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  justify-content: flex-end;
  width: auto;
`;

const StyledCard = styled.div`
  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    display: none;
    width: 100%;
  }

  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
