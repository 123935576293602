import Notification from './Notification';
import { NOTIFICATION_STATUS } from '../../constants';

const { DONE, ERROR } = NOTIFICATION_STATUS;

export const createJobNotification = (stateInfo) => {
  if (stateInfo.status === DONE) {
    Notification(
      'success',
      'Success',
      `${stateInfo.name} job created successfully.`,
    );
  } else if (stateInfo.status === ERROR) {
    Notification(
      ERROR,
      stateInfo.error,
      `${stateInfo.name} job creation failed.`,
    );
  }
};

export const editJobNotification = (stateInfo) => {
  if (stateInfo.status === DONE) {
    Notification(
      'success',
      'Success',
      `${stateInfo.name} Job edited successfully.`,
    );
  } else if (stateInfo.status === ERROR) {
    Notification(
      ERROR,
      stateInfo.error,
      `${stateInfo.name} Job editing failed.`,
    );
  }
};
