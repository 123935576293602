export const ACCEPTED_IMAGE_EXTENSIONS = '.jpg, .png, .jpeg';
export const ACCEPTED_POAP_EXTENSIONS = '.png';
export const ACCEPTED_PNG_IMAGE_EXTENSIONS = '.png';
export const ACCEPTED_DOCUMENT_EXTENSIONS = '.pdf';
export const BULK_INVITE_EXTENSIONS = '.csv';

const AllowedTypes = ['image/jpeg', 'image/png'];
const AllowedTypedHD = ['image/jpg', 'image/jpeg', 'image/png'];
const AllowedPoapTypes = ['image/gif', 'image/png'];
const AllowedImageSize = 5242880;
const AllowedImageSizeFullHD = 8242880;
const AllowedPoapImageSize = 4194304;

// Max file size 5mb
export const ACCEPTED_COVER_IMAGE = {
  minWidth: 900,
  minHeight: 300,
  fileTypes: AllowedTypes,
  maxFileSize: AllowedImageSize,
};

export const ACCEPTED_EVENT_COVER_IMAGE = {
  minWidth: 656,
  minHeight: 369,
  fileTypes: AllowedTypes,
  maxFileSize: AllowedImageSize,
};

export const ACCEPTED_EVENT_COVER_IMAGE_FULL_HD = {
  minWidth: 1920,
  minHeight: 1080,
  fileTypes: AllowedTypedHD,
  maxFileSize: AllowedImageSizeFullHD,
}

export const ACCEPTED_EVENT_POAP_IMAGE = {
  minWidth: 8,
  minHeight: 8,
  fileTypes: AllowedPoapTypes,
  maxFileSize: AllowedPoapImageSize,
};

// Max file size 2mb
export const ACCEPTED_LOGO_IMAGE = {
  minWidth: 128,
  minHeight: 128,
  fileTypes: AllowedTypes,
  maxFileSize: AllowedImageSize,
};

export const ACCEPTED_PNG_LOGO_IMAGE = {
  minWidth: 128,
  minHeight: 128,
  fileTypes: ['image/png'],
  maxFileSize: AllowedImageSize,
};

// Note: object ref is passed as requirements are identical
// It is recommended to create a new object if there's any changes in requirements
export const ACCEPTED_PROFILE_IMAGE = ACCEPTED_LOGO_IMAGE;

export const PICTURE_TYPE = {
  COMPANY_LOGO: 'logo',
  PROFILE_PICTURE: 'profilePic',
};

export const IMAGE_TYPES = {
  COVER: 'Cover',
  LOGO: 'Logo',
  PROFILE: 'Profile',
};

export const IMAGE_LOGO_PAYLOAD = {
  ACCEPTED_IMAGE: ACCEPTED_LOGO_IMAGE,
  imageType: IMAGE_TYPES.LOGO,
};

export const IMAGE_PNG_LOGO_PAYLOAD = {
  ACCEPTED_IMAGE: ACCEPTED_PNG_LOGO_IMAGE,
  imageType: IMAGE_TYPES.LOGO,
};

export const IMAGE_PROFILE_PAYLOAD = {
  ACCEPTED_IMAGE: ACCEPTED_PROFILE_IMAGE,
  imageType: IMAGE_TYPES.PROFILE,
};

export const IMAGE_COVER_PAYLOAD = {
  ACCEPTED_IMAGE: ACCEPTED_COVER_IMAGE,
  imageType: IMAGE_TYPES.COVER,
};

export const EVENT_IMAGE_COVER_PAYLOAD = {
  ACCEPTED_IMAGE: ACCEPTED_EVENT_COVER_IMAGE,
  imageType: IMAGE_TYPES.COVER,
};

export const EVENT_IMAGE_POAP_PAYLOAD = {
  ACCEPTED_IMAGE: ACCEPTED_EVENT_POAP_IMAGE,
  imageType: IMAGE_TYPES.LOGO,
};

export const COVER_IMAGE_HINT = `Hint: Supporting format as JPG, PNG having minimum resolution of ${ACCEPTED_COVER_IMAGE.minWidth}px x ${ACCEPTED_COVER_IMAGE.minHeight}px with max size of 5MB.`;

export const LOGO_IMAGE_HINT = `Hint: Supporting format as JPG, PNG having minimum resolution of ${ACCEPTED_LOGO_IMAGE.minWidth}px x ${ACCEPTED_LOGO_IMAGE.minHeight}px with max size of 5MB.`;

export const EVENT_COVER_IMAGE_HINT = `Hint: Supporting format as JPG, PNG having minimum resolution of ${ACCEPTED_EVENT_COVER_IMAGE.minWidth}px x ${ACCEPTED_EVENT_COVER_IMAGE.minHeight}px with max size of 5MB.`;
