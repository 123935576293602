import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Form } from 'antd';
import RecoremLogo from '../../assets/svg/recorem_color_logo.svg';
import ModalImage from '../../assets/svg/reset_modal.svg';
import SuccessIcon from '../../assets/svg/success.svg';
import {
  ConfirmPassword,
  CheckPassword,
  FormGroup,
  Submit,
} from '../../components/shared-components/Form/Form';
import { resetPassword } from '../../service/auth';
import { getUrlVars } from '../../utils/common';
import config from '../../config';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';

const ResetPassModal = ({ location, history }) => {
  const [form] = Form.useForm();

  const { token } = getUrlVars(location.search);
  if (!token) history.push('/login');

  const [success, setSuccess] = useState(false);

  const handleLoginModalCancel = () => {
    window.open(config.appURL, '_self');
    window.close();
  };

  const handleSubmit = async ({ password }) => {
    const response = await resetPassword({
      password,
      referenceToken: token,
      isValidForOneDay: true,
    });

    if (response) {
      setSuccess(true);
    }
  };

  return (
    <StyledModal
      visible
      onCancel={handleLoginModalCancel}
      footer={null}
      centered
      width="80%"
      bodyStyle={{ padding: 0, overflow: 'hidden', maxWidth: '800px' }}
    >
      <StyledMainContainer id="login_modal">
        <StyledLogoContainer xs={0} sm={11}>
          <Col>
            <StyledModalImage alt="" src={ModalImage} />
          </Col>
        </StyledLogoContainer>

        {success ? (
          <StyledContentContainer xs={24} sm={13}>
            <StyledInnerWrapper>
              <StyledSucessIconWrapper>
                <img src={SuccessIcon} alt="" />
              </StyledSucessIconWrapper>
              <StyledVerifyText1>Congratulations.!</StyledVerifyText1>
              <StyledVerifyText2>
                You have successfully reset your password.
              </StyledVerifyText2>
              <div>
                Good to go with{' '}
                <StyledText onClick={() => window.open(config.appURL, '_self')}>
                  Recorem
                </StyledText>
              </div>
            </StyledInnerWrapper>
          </StyledContentContainer>
        ) : (
          <StyledContentContainer xs={24} sm={13}>
            <StyledLogoHeader src={RecoremLogo} alt="Recorem Logo" />

            <StyledInnerWrapper>
              <StyledHeader>ACCOUNT RECOVERY</StyledHeader>

              <FormGroup
                form={form}
                name="resetpassword"
                onFinish={handleSubmit}
                expand
              >
                <StyledTextWrapper>
                  <span>Create a new password</span>
                </StyledTextWrapper>
                <div>
                  <StyledCheckPassword>
                    <CheckPassword
                      name="password"
                      placeholder="New Password"
                      className="new-password"
                    />
                  </StyledCheckPassword>
                </div>
                <div>
                  <StyledCheckPassword>
                    <ConfirmPassword
                      name="checkPassword"
                      placeholder="Confirm Password"
                    />
                  </StyledCheckPassword>
                </div>
                <StyledSubmitWrapper>
                  <Submit name="Confirm" />
                </StyledSubmitWrapper>
              </FormGroup>
            </StyledInnerWrapper>
          </StyledContentContainer>
        )}
      </StyledMainContainer>
    </StyledModal>
  );
};

export default ResetPassModal;

const StyledModal = styled(Modal)`
  overflow: hidden;
  max-width: 800px;
  border-radius: 10px;
  ${PADDING(0, 0, 0, 0)}
  ${MARGIN(null, null, 0, null)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 100vh !important;
    top: 0 !important;
    ${MARGIN(0, 0, 0, 0)}
    border-radius: 0;

    .ant-modal-content {
      height: 100%;
    }
  }
`;

const StyledMainContainer = styled(Row)`
  display: flex;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10, 10, 10, 10)}
  }
`;

const StyledLogoContainer = styled(Col)`
  height: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(31, 0, 6, 0)};
  }
`;

const StyledModalImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const StyledLogoHeader = styled.img`
  height: 100px;
  aspect-ratio: 16 / 9;
  ${MARGIN(-20, null, null, null)}
  margin-bottom: auto;
`;

const StyledContentContainer = styled(Col)`
  ${DISPLAY_FLEX('column', 'center', 'center')};
  padding: 30px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(30, 20)};
  }

  @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_4}) {
    ${PADDING(0, 0, 8.5, 0)}
  }
`;

const StyledInnerWrapper = styled.div`
  ${DISPLAY_FLEX('column', 'flex-start', 'center')};
  width: 100%;
  text-align: center;

  form {
    width: 100%;

    @media (min-width: ${BREAKPOINTS.MOBILE}) {
      width: 90%;
    }
  }
`;

const StyledHeader = styled.span`
  color: ${COLORS.PURPLE};
  font-weight: 510;
  font-size: 16px;
  ${PADDING(0, 0, 30, 0)}
  letter-spacing: 2px;
`;

const StyledTextWrapper = styled.div`
  text-align: left;
  ${MARGIN(null, null, 15, null)}
  font-weight: 500;
  color: ${COLORS.GRANITE_GRAY};
`;

const StyledSubmitWrapper = styled.div`
  ${MARGIN(40, 0, 0, 0)}
  display: flex;
  align-self: flex-start;

  @media (min-width: 768px) and (max-width: 1000px) {
    ${MARGIN(0, null, null, null)}
  }

  button {
    height: auto;
    width: 120px !important;
    border-radius: 6px;
    border: none;
  }

  .ant-btn-primary {
    background: ${COLORS.PURPLE};
  }
`;

const StyledSucessIconWrapper = styled.div`
  width: 80px;
  height: 80px;
`;

const StyledVerifyText1 = styled.span`
  color: ${COLORS.ARSENIC};
  font-size: 25px;
  font-weight: bold;
  ${PADDING(0, 0, 10, 0)}
`;

const StyledVerifyText2 = styled.span`
  font-size: 17px;
  ${PADDING(0, 0, 30, 0)}
`;

const StyledText = styled.span`
  font-weight: bold;
  cursor: pointer;
`;

const StyledCheckPassword = styled.span`
  .ant-form-item-control-input-content > .ant-input-affix-wrapper {
    border: 0px;
    border-bottom: 2px solid #eee;
    ${PADDING(null, null, null, 0)}
  }
`;
