import React from 'react';
import styled from 'styled-components';
import first from 'lodash/first';
import JobTab from './JobTab';
import { FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { COLORS } from '../../../../styles/constants/colors';
import { PADDING } from '../../../../styles/constants/display';
import {
  BuildingIcon,
  GraduationCapIcon,
  MoneyIcon,
  PinIcon,
  TimeIcon,
} from '../../../../components/svgicons';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';

const JobModal = ({
  jobTitle,
  jobType,
  currency,
  location,
  years,
  months,
  domains,
  jobLocationType,
  salaryTo,
  salaryFrom,
  qualification,
  jobBrief,
  fiveReasonsToJoin,
  interviewProcess,
  first90Days,
}) => {
  const [buttonKey, setButtonKey] = React.useState(0);

  const items = [
    ...(jobBrief
      ? [
          {
            key: 0,
            name: 'Job Description',
            component: <JobTab data={jobBrief} />,
          },
        ]
      : []),
    ...(fiveReasonsToJoin
      ? [
          {
            key: 1,
            name: '5 Reasons to Join',
            component: <JobTab data={fiveReasonsToJoin} />,
          },
        ]
      : []),
    ...(interviewProcess
      ? [
          {
            key: 2,
            name: 'Interview Process',
            component: <JobTab data={interviewProcess} />,
          },
        ]
      : []),
    ...(first90Days
      ? [
          {
            key: 3,
            name: 'First 90 Days',
            component: <JobTab data={first90Days} />,
          },
        ]
      : []),
  ];

  return (
    <StyledWrapper id="job-modal-view">
      <StyledDesignation>
        {jobTitle && (
          <h1>
            {jobTitle} - <span>{jobType && jobType.join(' | ')}</span>
          </h1>
        )}
      </StyledDesignation>

      <StyledStats>
        <div>
          <MoneyIcon />
          <p>{salaryTo ? `${salaryFrom} - ${salaryTo} ${currency}` : 'N/A'}</p>
        </div>

        {jobLocationType && location && (
          <div>
            <PinIcon />
            <p>{jobLocationType === 1 ? 'Remote' : location}</p>
          </div>
        )}

        {qualification && (
          <div>
            <GraduationCapIcon />
            <p> {qualification}</p>
          </div>
        )}
        {years || months ? (
          <div>
            <TimeIcon />
            <p>
              {years > 1 ? `${years} years` : `${years} year`}{' '}
              {months === 0
                ? ''
                : months > 1
                ? `${months} months`
                : `${months} month`}
            </p>
          </div>
        ) : null}

        {domains && (
          <div>
            <BuildingIcon />
            <p>{domains.join(', ')}</p>
          </div>
        )}
      </StyledStats>

      <StyledContainer>
        {items.map((item) => (
          <StyledTabButton
            key={item.key}
            onClick={() => setButtonKey(item.key)}
            selected={item.key === buttonKey}
          >
            {item.name}
          </StyledTabButton>
        ))}
      </StyledContainer>
      {first(items.filter((item) => item.key === buttonKey))?.component}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding: ${PADDING(10)};
`;
const StyledDesignation = styled.div`
  h1 {
    font-size: 19px;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  span {
    color: ${COLORS.RICH_BLUE};
  }

  margin-bottom: 25px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 30px;
  overflow-x: scroll;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 20px;
  }
`;

const StyledTabButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  color: #282828;
  border: none;
  background: none;
  transition: all 0.3s ease;
  cursor: pointer;
  color: ${({ selected }) => (selected ? '#368DFF' : '#b1b1b1')};

  &:after {
    transition: all 0.3s ease;
    content: '';
    width: 100%;
    height: 5px;
    background: ${({ selected }) => selected && '#368DFF'};
    display: block;
    border-radius: 10px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 15px;
  }
`;

const StyledStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  column-gap: 40px;
  row-gap: 10px;
  margin-bottom: 20px;

  & > div {
    display: flex;
    gap: 10px;

    svg {
      width: 22px;
      height: 22px;
    }

    p {
      font-size: 14px;
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      opacity: 0.7;
    }
  }
`;

export default JobModal;
