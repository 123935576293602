import { COLORS } from '../styles/constants/colors';

export const INTERVIEW_TEXT = 'interview';
export const PAST_INTERVIEW_TEXT = 'past interview';

export const CALENDAR_DATA_ORDER = {
  [INTERVIEW_TEXT]: 0,
  Live: 1,
  'Upcoming Event': 2,
  'Upcoming': 2,
  Completed: 3,
  [PAST_INTERVIEW_TEXT]: 4, 
  Cancelled: 5,
};

export const CALENDAR_FILTER_STATUS = {
  ALL: 'all',
  UPCOMING: 'Upcoming',
  EVENTS: 'Events',
  LIVE: 'Live',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  INTERVIEWS: 'Interviews',
  PAST_INTERVIEWS: 'Past Interview'
}

export const OrganizerMetaDataList = [
  {
    id: 0,
    colorName: COLORS.PUERTO_RICO,
    name: 'Upcoming Event',
    status: CALENDAR_FILTER_STATUS.UPCOMING
  },
  {
    id: 1,
    colorName: COLORS.DODGER_BLUE,
    name: 'Live Event',
    status: CALENDAR_FILTER_STATUS.LIVE
  },
  {
    id: 2,
    colorName: COLORS.CUSTOM_RED,
    name: 'Cancelled Event',
    status: CALENDAR_FILTER_STATUS.CANCELLED
  },
  {
    id: 3,
    colorName: COLORS.METALLIC_SILVER,
    name: 'Past Event',
    status: CALENDAR_FILTER_STATUS.COMPLETED
  },
  {
    id: 4,
    colorName: COLORS.DARK_GRAY,
    name: 'Show All',
    status: CALENDAR_FILTER_STATUS.ALL
  },
];
export const RecruiterMetaDataList = [
  {
    id: 0,
    colorName: COLORS.SUPER_NOVA,
    name: 'Interviews',
    status: true
  },
  {
    id: 1,
    colorName: COLORS.DODGER_BLUE,
    name: 'Events',
    status: CALENDAR_FILTER_STATUS.EVENTS
  },
  {
    id: 2,
    colorName: COLORS.CUSTOM_RED,
    name: 'Cancelled Event',
    status: CALENDAR_FILTER_STATUS.CANCELLED
  },
  {
    id: 3,
    colorName: COLORS.DARK_GRAY,
    name: 'Show All',
    status: CALENDAR_FILTER_STATUS.ALL
  },
];

export const CandidateMetaDataList = [
  {
    id: 0,
    colorName: COLORS.RICH_BLUE,
    name: 'Interviews',
    status: true
  },
  {
    id: 1,
    colorName: COLORS.DODGER_BLUE,
    name: 'Events',
    status: CALENDAR_FILTER_STATUS.EVENTS
  },
  {
    id: 2,
    colorName: COLORS.CUSTOM_RED,
    name: 'Cancelled Event',
    status: CALENDAR_FILTER_STATUS.CANCELLED
  },
  {
    id: 3,
    colorName: COLORS.DARK_GRAY,
    name: 'Show All',
    status: CALENDAR_FILTER_STATUS.ALL
  },
];
