import React, { memo } from 'react';
import Copyright from './Copyright';
import FooterContent from './FooterContent';

const Footer: React.FC = () => {
  return (
    <footer style={{ marginTop: 'auto' }}>
      <section>
        <FooterContent />
      </section>
      <section>
        <Copyright />
      </section>
    </footer>
  );
};

export default memo(Footer);
