import React from 'react';
import MapAllLinks from './index';
import {
  CandidateRoutes,
  RecruiterRoutes,
  OrganizerRoutes,
} from '../../../constants/routes';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../../utils/userInfo';

const ChatBreadCrumbs = () => {
  const roleId = getRoleId();

  let route = '';
  if (checkIfUserIsCandidate(roleId)) {
    route = CandidateRoutes;
  } else if (checkIfUserIsRecruiter(roleId)) {
    route = RecruiterRoutes;
  } else {
    route = OrganizerRoutes;
  }

  const links = [
    {
      name: 'Chat Management',
      url: `${route.chat}`,
    },
    {
      name: 'Chat Room',
      url: `${route.groupChat}`,
    },
  ];

  return (
    <>
      <MapAllLinks links={links} />
    </>
  );
};

export default ChatBreadCrumbs;
