import { useEffect, useLayoutEffect, useState } from 'react';

export const useSearchDebounce = (delay: number = 600, initialValue = '') => {
  const [search, setSearch] = useState(initialValue);
  const [searchQuery, setSearchQuery] = useState(initialValue);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery] as const;
};

export const useScrollToTop = (data?: any) => {
  const item = data || null;
  useLayoutEffect(() => {
    if (item) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [item]);
};
