import React from 'react';
import styled from 'styled-components';
import { Pie } from '@ant-design/charts';
import { PieColors } from '../../../mocks/recruiter/viewEvent';
import {
  TECHNICAL_TEXT,
  MANAGEMENT_TEXT,
  TECHNOFUNCTIONAL_TEXT,
} from '../../../constants';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';

export default function PieChartAndSkills({
  technical,
  management,
  technoFunctional,
}) {
  const PieData = [
    {
      type: TECHNICAL_TEXT,
      value: technical,
    },
    {
      type: MANAGEMENT_TEXT,
      value: management,
    },
    {
      type: TECHNOFUNCTIONAL_TEXT,
      value: technoFunctional,
    },
  ];

  const config = {
    appendPadding: 0,
    data: PieData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.8,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
    },
    legend: false,
    color: PieColors,
    statistic: {
      title: false,
      content: {
        formatter: () => {
          return 'Skills';
        },
      },
    },
  };

  const RenderSkillsInfo = ({ color, skill, text }) => {
    return skill ? (
      <StyledParagraph>
        <StyledSpan color={color} />
        {`${text} -`}
        <StyledColorDotValue>{`${skill}%`}</StyledColorDotValue>
      </StyledParagraph>
    ) : null;
  };

  const skillsInformation = [
    {
      color: COLORS.DODGER_BLUE,
      text: TECHNICAL_TEXT,
      skill: technical,
    },
    {
      color: COLORS.SUPER_NOVA,
      text: MANAGEMENT_TEXT,
      skill: management,
    },
    {
      color: COLORS.PUERTO_RICO,
      text: TECHNOFUNCTIONAL_TEXT,
      skill: technoFunctional,
    },
  ];

  return (
    <>
      <StyledPie {...config} />
      <div>
        <StyledColorDot>
          {skillsInformation.map((el, idx) => {
            const { color, text, skill } = el;
            return (
              <RenderSkillsInfo
                key={idx.toString()}
                color={color}
                text={text}
                skill={skill}
              />
            );
          })}
        </StyledColorDot>
      </div>
    </>
  );
}

const StyledPie = styled(Pie)`
  width: 130px !important;
  height: 85px !important;
  font-size: ${FONT[15]};
  margin-bottom: 5px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: -20px;
  }

  div {
    font-size: ${FONT[16]} !important;
    color: ${COLORS.DODGER_BLUE} !important;
    font-weight: ${FONT_WEIGHTS.MEDIUM} !important;
  }

  span {
    font-size: 12px;
  }

  .g2-tooltip {
    width: 200px;
    font-size: ${FONT[10]};
    ${DISPLAY_FLEX(null, 'center', 'center')};

    &-title {
      margin-right: -5px;
    }
  }
`;

const StyledColorDot = styled.div`
  margin-left: 0;
  float: right;
`;

const StyledParagraph = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${COLORS.DARK_SILVER};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${(props) => props.color};
`;

const StyledColorDotValue = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(0, 0, 0, 3)};
`;
