import React from 'react';

export const First90Days = () => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 511.977 511.977"
      height="512"
      viewBox="0 0 511.977 511.977"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m14.977 390.988h392c3.516 0 6.914-1.23 9.609-3.472 3.765-3.153 89.652-77.038 94.889-236.528h-421c-5.217 144.774-84.315 212.822-85.137 213.501-4.819 4.072-6.592 10.723-4.424 16.641 2.153 5.903 7.764 9.858 14.063 9.858z" />
        <path d="m496.976 60.988h-75v-15c0-8.4-6.6-15-15-15s-15 6.6-15 15v15h-76v-15c0-8.4-6.6-15-15-15s-15 6.6-15 15v15h-75v-15c0-8.4-6.6-15-15-15s-15 6.6-15 15v15h-75c-8.4 0-15 6.6-15 15v45h421v-45c0-8.4-6.6-15-15-15z" />
        <path d="m435.849 410.515c-8.145 6.782-18.369 10.474-28.872 10.474h-316v45c0 8.291 6.709 15 15 15h391c8.291 0 15-6.709 15-15v-168.146c-28.92 70.951-69.276 106.937-76.128 112.672z" />
      </g>
    </svg>
  );
};

export const JobDescription = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m310 1.509v58.491h58.491c-5.981-29.344-29.147-52.51-58.491-58.491z" />
      <path d="m321.01 432.591c-5.891 2.718-12.328 4.142-18.837 4.142-16.043 0-30.986-8.621-38.998-22.498l-22.499-38.97c-11.717-20.292-5.779-46.016 13.018-59.265-18.797-13.25-24.734-38.973-13.017-59.265l22.5-38.97c8.011-13.877 22.953-22.498 38.995-22.498 6.508 0 12.945 1.423 18.839 4.142 2.072-22.904 21.381-40.909 44.815-40.909h4.174v-68.5h-75c-8.284 0-15-6.716-15-15v-75h-265c-8.284 0-15 6.716-15 15v482c0 8.284 6.716 15 15 15h340c8.284 0 15-6.716 15-15v-23.5h-4.175c-23.434 0-42.743-18.004-44.815-40.909zm-256.01-311.591h240c8.284 0 15 6.716 15 15s-6.716 15-15 15h-240c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 60h160c8.284 0 15 6.716 15 15s-6.716 15-15 15h-160c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 60h130c8.284 0 15 6.716 15 15s-6.716 15-15 15h-130c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 60h130c8.284 0 15 6.716 15 15s-6.716 15-15 15h-130c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 60h130c8.284 0 15 6.716 15 15s-6.716 15-15 15h-130c-8.284 0-15-6.716-15-15s6.716-15 15-15zm160 90h-160c-8.284 0-15-6.716-15-15s6.716-15 15-15h160c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
      <path d="m504.503 339.774-14.806-8.548c.749-5.028 1.128-10.13 1.128-15.226s-.379-10.198-1.128-15.226l14.807-8.548c7.162-4.136 9.625-13.328 5.489-20.491l-22.5-38.971c-4.136-7.163-13.329-9.625-20.49-5.491l-14.902 8.604c-8.01-6.4-16.812-11.499-26.275-15.221v-17.156c0-8.271-6.729-15-15-15h-45c-8.271 0-15 6.729-15 15v17.157c-9.464 3.722-18.266 8.821-26.276 15.221l-14.902-8.604c-7.165-4.136-16.354-1.673-20.49 5.491l-22.5 38.971c-4.135 7.163-1.673 16.354 5.49 20.491l14.806 8.548c-.749 5.027-1.127 10.128-1.127 15.226s.378 10.199 1.127 15.226l-14.807 8.548c-7.162 4.136-9.625 13.328-5.489 20.491l22.5 38.971c4.136 7.164 13.33 9.626 20.49 5.491l14.902-8.604c8.011 6.4 16.812 11.499 26.276 15.221v17.155c0 8.271 6.729 15 15 15h45c8.271 0 15-6.729 15-15v-17.157c9.463-3.722 18.266-8.821 26.275-15.221l14.902 8.604c7.162 4.134 16.354 1.672 20.49-5.491l22.5-38.971c4.136-7.162 1.673-16.354-5.49-20.49zm-116.178 18.726c-23.435 0-42.5-19.065-42.5-42.5s19.065-42.5 42.5-42.5 42.5 19.065 42.5 42.5-19.065 42.5-42.5 42.5z" />
    </g>
  </svg>
);

export const InterviewProcess = () => (
  <svg
    height="512pt"
    viewBox="0 0 512 512"
    width="512pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m454.976562 94.539062c0 34.46875-27.941406 62.410157-62.40625 62.410157-34.46875 0-62.410156-27.941407-62.410156-62.410157 0-34.464843 27.941406-62.40625 62.410156-62.40625 34.464844 0 62.40625 27.941407 62.40625 62.40625zm0 0" />
    <path d="m284.550781 175.378906-29.621093-44.417968v-75.789063c0-30.242187-24.390626-55.171875-55.160157-55.171875h-144.597656c-30.613281 0-55.171875 24.78125-55.171875 55.171875v88.359375c0 30.320312 24.46875 55.167969 55.171875 55.167969h216.898437c11.949219 0 19.121094-13.359375 12.480469-23.320313zm-157.503906-32.910156h-47.359375c-8.078125 0-15.027344-6.214844-15.402344-14.285156-.394531-8.617188 6.457032-15.714844 14.984375-15.714844h48.203125c8.511719 0 15.378906 7.097656 14.980469 15.710938-.371094 8.074218-7.324219 14.289062-15.40625 14.289062zm48.625-56.238281h-95.976563c-8.074218 0-15.019531-6.199219-15.40625-14.261719-.414062-8.617188 6.445313-15.738281 14.980469-15.738281h95.976563c8.082031 0 15.035156 6.214843 15.40625 14.289062.398437 8.613281-6.46875 15.710938-14.980469 15.710938zm0 0" />
    <path d="m116.484375 372.152344c-64.605469.222656-116.484375 53.558594-116.484375 118.164062v3.289063c0 10.160156 8.234375 18.394531 18.394531 18.394531h197.003907c10.160156 0 18.394531-8.234375 18.394531-18.394531v-4.558594c0-64.699219-52.558594-117.121094-117.308594-116.894531zm0 0" />
    <path d="m190.507812 298.542969c0 40.652343-32.953124 73.609375-73.609374 73.609375-40.652344 0-73.609376-32.957032-73.609376-73.609375 0-40.652344 32.957032-73.609375 73.609376-73.609375 40.65625 0 73.609374 32.957031 73.609374 73.609375zm0 0" />
    <path d="m487.230469 241h-189.332031c5.582031-47.328125 45.832031-84.050781 94.671874-84.050781 48.828126 0 89.078126 36.722656 94.660157 84.050781zm0 0" />
    <path d="m497 272c8.285156 0 15 6.714844 15 15v177.871094c0 8.519531-7.109375 15.390625-15.730469 14.980468-8.058593-.382812-14.269531-7.332031-14.269531-15.410156v-16.710937h-224.128906c-10.082032-34.320313-32.371094-63.480469-61.839844-82.390625 15.257812-18.050782 24.480469-41.371094 24.480469-66.800782 0-9.167968-1.203125-18.058593-3.453125-26.539062zm0 0" />
  </svg>
);

export const FiveReasonsToJoin = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M438.09,273.32h-39.596c4.036,11.05,6.241,22.975,6.241,35.404v149.65c0,5.182-0.902,10.156-2.543,14.782h65.461
			c24.453,0,44.346-19.894,44.346-44.346v-81.581C512,306.476,478.844,273.32,438.09,273.32z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M107.265,308.725c0-12.43,2.205-24.354,6.241-35.404H73.91c-40.754,0-73.91,33.156-73.91,73.91v81.581
			c0,24.452,19.893,44.346,44.346,44.346h65.462c-1.641-4.628-2.543-9.601-2.543-14.783V308.725z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M301.261,234.815h-90.522c-40.754,0-73.91,33.156-73.91,73.91v149.65c0,8.163,6.618,14.782,14.782,14.782h208.778
			c8.164,0,14.782-6.618,14.782-14.782v-149.65C375.171,267.971,342.015,234.815,301.261,234.815z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256,38.84c-49.012,0-88.886,39.874-88.886,88.887c0,33.245,18.349,62.28,45.447,77.524
			c12.853,7.23,27.671,11.362,43.439,11.362c15.768,0,30.586-4.132,43.439-11.362c27.099-15.244,45.447-44.28,45.447-77.524
			C344.886,78.715,305.012,38.84,256,38.84z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M99.918,121.689c-36.655,0-66.475,29.82-66.475,66.475c0,36.655,29.82,66.475,66.475,66.475
			c9.298,0,18.152-1.926,26.195-5.388c13.906-5.987,25.372-16.585,32.467-29.86c4.98-9.317,7.813-19.946,7.813-31.227
			C166.393,151.51,136.573,121.689,99.918,121.689z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M412.082,121.689c-36.655,0-66.475,29.82-66.475,66.475c0,11.282,2.833,21.911,7.813,31.227
			c7.095,13.276,18.561,23.874,32.467,29.86c8.043,3.462,16.897,5.388,26.195,5.388c36.655,0,66.475-29.82,66.475-66.475
			C478.557,151.509,448.737,121.689,412.082,121.689z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
