import { useState, useRef, useEffect } from 'react';
import getRemainingTime from '../../utils/getRemainingTime';
import { convertUnixTime } from '../../utils/formatDateTime';

const Timer = ({ dateTime, isFormated = false }) => {
  const refreshInterval = useRef();

  const [timer, setTimer] = useState('');

  useEffect(() => {
    clearInterval(refreshInterval.current);
    refreshInterval.current = setInterval(() => {
      if (isFormated) {
        setTimer(dateTime);
      } else {
        setTimer(getRemainingTime(convertUnixTime(dateTime)));
      }
    }, 1000);
  }, [dateTime, isFormated]);

  useEffect(() => {
    return () => {
      clearInterval(refreshInterval.current);
    };
  }, []);

  return `${timer}`;
};

export default Timer;
