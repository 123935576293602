import get from 'lodash/get';
import api from './api';
import {
  APIs,
  Method,
  ORGANIZER,
  RECRUITER,
  CANDIDATE,
  AdminApis,
} from '../constants';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
} from '../utils/userInfo';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';

export const getTwitterToken = (body) => {
  return api({
    method: Method.post,
    url: APIs.twitter_request_token,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getTwitterAccessToken = (body) => {
  return api({
    method: Method.post,
    url: APIs.twitter_access_token,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const adminLogin = (email, password, mockResponse) => {
  return api({
    method: Method.post,
    url: APIs.adminLogin,
    auth: false,
    body: { email, password },
    errorHandlingMessage: false,
    mockResponse,
  });
};

const userLogin = (email, password, mockResponse, token) => {
  return api({
    method: Method.post,
    url: APIs.userLogin,
    auth: false,
    body: { email, password, token },
    mockResponse,
    errorHandlingMessage: false,
  });
};

const signup = async (data) => {
  let url = '';

  const { role } = data;
  if (role === ORGANIZER) url = APIs.organizerSignup;
  if (role === RECRUITER) url = APIs.recruiterSignup;
  if (role === CANDIDATE) url = APIs.candidateSignup;

  delete data.role;

  return api({ url, method: Method.post, body: data })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      const { data } = response;
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const verify = async ({ token }) => {
  const url = APIs.verify + token;
  const method = Method.get;

  const response = await api({ url, method });
  if (response && response.status === 200) {
    const { data } = response;
    return data;
  }

  return false;
};

const forgotPassword = async (body) => {
  const url = APIs.forgotPassword;

  return api({ url, method: Method.post, body })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      const { data } = response;
      return data;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const resetPassword = async (body) => {
  const url = APIs.resetPassword;
  const method = Method.post;

  const response = await api({ url, method, body });
  if (response && response.status === 200) {
    const { data } = response;
    return data;
  }

  return false;
};

const getProfile = () => {
  return api({
    method: Method.get,
    url: APIs.profile,
  });
};

const updateProfile = (data) => {
  let url = '';
  const { roleId } = data;
  if (roleId === ORGANIZER) url = APIs.organizerProfile;
  if (roleId === RECRUITER) url = APIs.recruiterProfile;
  if (roleId === CANDIDATE) url = APIs.candidateProfile;
  delete data.roleId;

  return api({
    method: Method.put,
    url,
    body: data,
  });
};

const uploadProfilePic = (body, roleId, userId, userRole) => {
  let url = APIs.profilePic;
  const method = Method.post;
  const contentType = 'multipart/form-data';

  if (roleId && checkIfUserIsMasterAdmin(roleId)) {
    url = checkIfUserIsCandidate(userRole)
      ? AdminApis.uploadCandidateProfilePic(userId)
      : AdminApis.uploadRepresentativeProfilePic(userId, userRole);
  }

  return api({
    url,
    method,
    body,
    contentType,
  });
};

const uploadCompanyLogo = (body, roleId, companyId) => {
  let url = APIs.companyLogo;
  const method = Method.post;
  const contentType = 'multipart/form-data';

  if (roleId && checkIfUserIsMasterAdmin(roleId)) {
    url = AdminApis.updateCompanyLogo(companyId);
  }

  return api({
    url,
    method,
    body,
    contentType,
  });
};

const socialLogin = (body) => {
  return api({
    method: Method.post,
    url: APIs.socialLogin,
    body,
  });
};

const socialSignup = async (body) => {
  const url = APIs.socialSignup;
  const method = Method.post;
  const response = await api({ url, method, body });
  if (response && response.status && response.status === 200) {
    const { data } = response;
    return data;
  }

  return false;
};

const uploadResume = async (body) => {
  const url = APIs.resume;
  const method = Method.post;
  const contentType = 'multipart/form-data';

  return api({
    url,
    method,
    body,
    contentType,
  });
};

const uploadCertificates = async (body) => {
  const url = APIs.certificate;
  const method = Method.post;
  const contentType = 'multipart/form-data';

  return api({
    url,
    method,
    body,
    contentType,
  });
};

const removePicture = async (body, role, companyId, userRole, userId) => {
  let url = `${APIs.index}delete/file/`;
  let payload = body;
  const method = Method.put;

  if (role && checkIfUserIsMasterAdmin(role)) {
    url = AdminApis.deleteFile;
    payload = { ...body, roleId: userRole, companyId, userId };
  }

  return api({
    url,
    method,
    body: payload,
  });
};

const updateMemberProfile = (data) => {
  return api({
    method: Method.put,
    url: APIs.crewProfile,
    body: data,
  });
};

const updateMemberProfileAdmin = (data) => {
  return api({
    method: Method.put,
    url: APIs.crewAdminProfile,
    body: data,
  });
};

const uploadMemberProfilePic = (body) => {
  const url = APIs.memberProfilePic;
  const method = Method.post;
  const contentType = 'multipart/form-data';

  return api({
    url,
    method,
    body,
    contentType,
  });
};

export const checkIfUserHasSetPasswordOrNot = (data) => {
  return api({
    method: Method.post,
    url: APIs.checkIfUserHasSetPasswordOrNot,
    body: data,
  });
};

export const updatePhone = (data) => {
  return api({
    method: Method.post,
    url: APIs.updatePhone,
    body: data,
  });
};

export const updateCandidateProfile = (data) => {
  return api({
    method: Method.put,
    url: APIs.quickStartPage,
    body: data,
  });
};

export {
  adminLogin,
  userLogin,
  signup,
  verify,
  forgotPassword,
  resetPassword,
  getProfile,
  updateProfile,
  uploadProfilePic,
  uploadCompanyLogo,
  socialLogin,
  socialSignup,
  uploadResume,
  uploadCertificates,
  removePicture,
  updateMemberProfile,
  updateMemberProfileAdmin,
  uploadMemberProfilePic,
};

export const uploadCompanyCover = (body, role, companyId) => {
  let url = APIs.companyCover;
  const method = Method.post;
  const contentType = 'multipart/form-data';

  if (role && checkIfUserIsMasterAdmin(role)) {
    url = AdminApis.uploadCompanyCover(companyId);
  }

  return api({
    url,
    method,
    body,
    contentType,
  });
};

export const removeCompanyCover = (role, companyId) => {
  let url = APIs.removeCompanyCover;
  const method = Method.put;

  if (role && checkIfUserIsMasterAdmin(role)) {
    url = AdminApis.deleteCompanyCover(companyId);
  }

  return api({
    url,
    method,
  });
};

export const getUserLocation = async () => {
  return await fetch(`https://geolocation-db.com/json/`)
    .then((res) => res.json())
    .catch(() => ({
      country_name: null,
    }));
};

export const checkIfUserExist = async (email, sendOtp, roleId) => {
  return api({
    method: Method.post,
    url: APIs.checkIfUserExist,
    auth: false,
    body: { email, sendOtp, roleId },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const resentOtp = async (email, roleId) => {
  return api({
    method: Method.post,
    url: APIs.resendOtp,
    auth: false,
    body: { email, roleId },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const loginWithOTP = async (email, roleId, otp, token) => {
  return api({
    method: Method.post,
    url: APIs.loginWithOTP,
    auth: false,
    body: { email, roleId, otp: `${otp}`, token },
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const signupWithOtp = async (body, url) => {
  return api({
    method: Method.post,
    url,
    auth: false,
    body,
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkIfCompanyExist = async (body) => {
  return api({
    method: Method.post,
    url: APIs.checkIfCompanyExist,
    auth: false,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createPassword = async (body) => {
  return api({
    method: Method.post,
    url: APIs.createPassword,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const changePassword = async (body) => {
  return api({
    method: Method.post,
    url: APIs.changePassword,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const requestCrewInvite = async (body) => {
  return api({
    method: Method.post,
    url: APIs.requestCrewInvite,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const checkIfMailsUnsubscribed = async (token, userId) => {
  return api({
    method: Method.get,
    url: `${APIs.checkIfMailsUnsubscribed}?${token ? `token=${token}` : ''}${
      userId ? `userId=${userId}` : ''
    }`,
    auth: false,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const unsubscribeMails = async (token) => {
  return api({
    method: Method.get,
    url: `${APIs.unsubscribeMails}?token=${token}`,
    auth: false,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const subscribeMails = async () => {
  return api({
    method: Method.get,
    url: `${APIs.subscribeMails}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const subscribeMailsAdmin = async (userId, mailSubscription) => {
  return api({
    method: Method.put,
    url: `${AdminApis.baseUrl}user/${userId}/mail-subscription/`,
    body: { mailSubscription },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const magicLinksLogin = async (eventId, token) => {
  return api({
    method: Method.post,
    url: APIs.loginWithMagicLinks,
    auth: false,
    body: { token, eventId },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
