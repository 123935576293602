import React, { memo } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import IndustryTypes from '../Common/IndustryTypes';
import { TextBox, InputTextArea, Email } from '../shared-components/Form/Form';
import CountryCodeInput from '../Common/CountryCodeInput';
import MandatoryStar from '../Common/mandatoryStar';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { mediumHeadingMixin } from '../../styles/constants/mixins';
import { DISPLAY, MARGIN } from '../../styles/constants/display';
import { checkIfUserIsOrganizer, getRoleId } from '../../utils/userInfo';
import { ToolTippedInfo } from '../shared-components/Upload';
import { getUserColorWithRoleId } from '../../styles/utils';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';

const CompanyInformationModalItem = ({
  children,
  masterAdmin = null,
  userRole,
}) => {
  const idBase = `company_info_item`;
  const roleId = getRoleId();
  const color = getUserColorWithRoleId(roleId);

  return (
    <StyledMainContainer>
      <StyledHeadingLevelThree
        masterAdmin={masterAdmin}
        id={`${idBase}_basic`}
      >
        Basic Information
      </StyledHeadingLevelThree>
      <Row gutter={[16, 24]}>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 18, order: 1 }}>
          <Row gutter={[25, 5]}>
            <Col xs={24} lg={8}>
              <StyledLabel>
                Company Name <MandatoryStar />
              </StyledLabel>
              <TextBox
                name="companyName"
                placeholder="Company Name"
                disabled={!masterAdmin}
                rule="text"
              />
            </Col>
            <Col xs={24} lg={8}>
              <StyledLabel>Office Number</StyledLabel>
              <Row>
                <Col xs={8} lg={10}>
                  <CountryCodeInput name="phoneNumberPrefix" />
                </Col>
                <Col xs={16} lg={14}>
                  <TextBox
                    name="phoneNumber"
                    placeholder="Phone Number"
                    maxChar={12}
                    rule="phoneNumber"
                    maxLength={12}
                    required={false}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={8}>
              <StyledLabel>
                Email Address <MandatoryStar />
              </StyledLabel>
              <Email name="companyEmail" placeholder="Company Email" disabled />
            </Col>
          </Row>

          <Row gutter={[25, 5]}>
            <Col xs={24} lg={8} className="industry-type">
              <StyledLabel>
                Industry Type <MandatoryStar />
              </StyledLabel>
              <IndustryTypes mode="multiple" />
            </Col>
            <Col xs={24} lg={8}>
              <StyledLabel>
                Company Website <MandatoryStar />
              </StyledLabel>
              <TextBox
                name="companyWebsite"
                placeholder="Company Website"
                rule="companyWebsite"
                disabled={!masterAdmin}
              />
            </Col>
          </Row>

          <Row gutter={[25, 5]}>
            <Col xs={24} lg={12}>
              <StyledLabel>
                Description <MandatoryStar />
              </StyledLabel>
              <InputTextArea
                name="description"
                placeholder="Description"
                autoSize={{ minRows: 8, maxRows: 8 }}
                maxLength={250}
              />
            </Col>
            {checkIfUserIsOrganizer(userRole || roleId) ? (
              <Col xs={24} lg={12}>
                <>
                  <StyledLabel htmlFor="community_outreach">
                    Community Outreach Message{' '}
                    <ToolTippedInfo
                      backgroundColor={color}
                      hintText="This message will be shown in your events community access section."
                      position="absolute"
                      top="2px"
                      right="-25px"
                      margin="0 0 0 5px"
                    />
                  </StyledLabel>
                  <InputTextArea
                    name="communityOutreachMessage"
                    placeholder="Community Outreach Message"
                    autoSize={{ minRows: 8, maxRows: 8 }}
                    maxLength={250}
                    showCount
                    required={false}
                    id="community_outreach"
                  />
                </>
              </Col>
            ) : null}
          </Row>
        </Col>
        <StyledUploadCol xs={{ span: 24, order: 1 }} lg={{ span: 6, order: 2 }}>
          {children}
        </StyledUploadCol>
      </Row>
    </StyledMainContainer>
  );
};

export default memo(CompanyInformationModalItem);

const StyledMainContainer = styled.div`
  position: relative;
`;

const StyledHeadingLevelThree = styled.h3`
  color: ${({ color }) => color};
  ${MARGIN(0, 0, 24, 0)};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${mediumHeadingMixin};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: ${(props) => (props.masterAdmin ? '' : '0px')};
    letter-spacing: 0;
  }

  @media (min-width: ${BREAKPOINTS.MOBILE}) {
    letter-spacing: 0;
  }

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) {
  ${MARGIN(0, 0, 24, 0)};

  }
`;

const StyledLabel = styled.label`
  display: ${DISPLAY.INLINE_BLOCK};
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${MARGIN(0, 0, 12, 0)};
  font-size: ${FONT[14]};
  position: relative;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledUploadCol = styled(Col)`
  > div {
    position: unset !important;
    width: auto !important;
  }
`
