import React from 'react';
import { Upload, message, Button } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

const { Dragger } = Upload;

const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },
  // showUploadList: false,
  onChange(info) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const DefaultUpload = ({
  name = 'upload',
  text = 'Click to Upload',
  style = {},
  className = '',
  action,
  onChange,
}) => {
  return (
    <Upload
      // {...props}
      name={name}
      action={action}
      onChange={onChange}
      listType="picture"
      className="upload-list-inline"
      multiple={false}
    >
      <Button icon={<UploadOutlined />} style={style} className={className}>
        {text}
      </Button>
    </Upload>
  );
};

const DragUpload = ({
  name = 'upload',
  text = 'Click or drag file to this area to upload',
  hint = 'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
  style = {},
}) => {
  return (
    <Dragger {...props} name={name} style={style}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{text}</p>
      <p className="ant-upload-hint">{hint}</p>
    </Dragger>
  );
};

const PictureUploadWithList = ({
  name = 'upload',
  text = 'Click to Upload',
  inline = true,
  style = {},
  className = '',
  onChange,
  customRequest,
  maxCount,
  beforeUpload,
  accept,
  fileList,
  cropActive = false,
  cropOptions,
}) => {
  return cropActive ? (
    <ImgCrop zoom={false} rotate beforeCrop={beforeUpload} {...cropOptions}>
      <Upload
        name={name}
        {...props}
        listType="picture"
        {...(inline ? {} : { className: 'upload-list-inline' })}
        onChange={onChange}
        customRequest={customRequest}
        maxCount={maxCount}
        accept={accept}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />} style={style} className={className}>
          {text}
        </Button>
      </Upload>
    </ImgCrop>
  ) : (
    <Upload
      name={name}
      {...props}
      listType="picture"
      {...(inline ? {} : { className: 'upload-list-inline' })}
      onChange={onChange}
      customRequest={customRequest}
      maxCount={maxCount}
      beforeUpload={beforeUpload}
      accept={accept}
      fileList={fileList}
    >
      <Button icon={<UploadOutlined />} style={style} className={className}>
        {text}
      </Button>
    </Upload>
  );
};

const TextUpload = ({
  name = 'upload',
  text = 'Click to Upload',
  style = {},
  className = '',
  action,
  onChange,
  icon = false,
  showUploadList = false,
  customRequest,
  accept,
  maxCount = 10,
  beforeUpload,
  fileList,
  testId,
  cropActive = false,
  cropOptions,
}) => {
  return cropActive ? (
    <ImgCrop zoom={false} rotate beforeCrop={beforeUpload} {...cropOptions}>
      <Upload
        name={name}
        action={action}
        onChange={onChange}
        showUploadList={showUploadList}
        customRequest={customRequest}
        accept={accept}
        maxCount={maxCount}
        fileList={fileList}
        data-testid={testId}
        // {...props}
      >
        <Button icon={icon} className={className} style={style}>
          {text}
        </Button>
      </Upload>
    </ImgCrop>
  ) : (
    <Upload
      name={name}
      action={action}
      onChange={onChange}
      showUploadList={showUploadList}
      customRequest={customRequest}
      accept={accept}
      maxCount={maxCount}
      beforeUpload={beforeUpload}
      fileList={fileList}
      data-testid={testId}
      // {...props}
    >
      <Button icon={icon} className={className} style={style}>
        {text}
      </Button>
    </Upload>
  );
};

const TextUploadFileList = ({
  name = 'upload',
  text = 'Click to Upload',
  style = {},
  className = '',
  action,
  onChange,
  customRequest,
  icon,
  accept,
  onRemove,
  onBoard,
  fileList,
  beforeUpload,
  showUploadList = true,
  cropActive = false,
  cropOptions,
}) => {
  return cropActive ? (
    <ImgCrop zoom={false} rotate beforeCrop={beforeUpload} {...cropOptions}>
      <Upload
        name={name}
        action={action}
        onChange={onChange}
        customRequest={customRequest}
        accept={accept}
        fileList={fileList}
        onRemove={onRemove}
        showUploadList={showUploadList}
      >
        <Button icon={icon} className={className} style={style}>
          {text}
        </Button>
      </Upload>
    </ImgCrop>
  ) : (
    <Upload
      name={name}
      action={action}
      onChange={onChange}
      customRequest={customRequest}
      accept={accept}
      fileList={fileList}
      onRemove={onRemove}
      showUploadList={showUploadList}
      beforeUpload={beforeUpload}
    >
      <Button icon={icon} className={className} style={style}>
        {text}
      </Button>
    </Upload>
  );
};

export {
  DefaultUpload,
  DragUpload,
  PictureUploadWithList,
  TextUpload,
  TextUploadFileList,
};
