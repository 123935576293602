import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from '../shared-components/Form/Form';

const CountriesDropdown = ({
  mode,
  handleChange,
  disabled,
  id,
  required = true,
  showArrow = false,
  name = 'country',
  placeholder = 'Please select country',
  hideOptions = [],
}) => {
  const { countryData } = useSelector((st) => st.commons);

  return (
    <Dropdown
      required={required}
      name={name}
      placeholder={placeholder}
      options={countryData
        ?.filter((country) => !hideOptions.includes(`${country.id}`))
        .map((item) => ({ ...item, id: `${item.id}` }))}
      showSearch
      disabled={disabled}
      mode={mode}
      handleChange={handleChange}
      id={id}
      showArrow={showArrow}
      idValue
    />
  );
};

export default CountriesDropdown;
