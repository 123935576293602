import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUsersDetailsInChatroom } from '../../utils/chat';
import {
  getSelectedChatUsersDetails,
  setPersonalChats,
} from '../../store/features/chat';
import Conversations from './Conversations/Conversations';
import MessageThreadContainer from './MessageThreads/MessageThreadContainer';
import { getChatRoomWithId } from '../../service/chat';
import { DISPLAY_FLEX } from '../../styles/constants/display';

const ChatBox = ({ loadChatRoomId }) => {
  const dispatch = useDispatch();

  const {
    personalChats,
    personalChatsLoading,
    selectedChatId,
    personalChatsUsersProfileData: usersProfileData,
  } = useSelector((state) => state.chat);

  // Load Initial Chats
  useEffect(() => {
    dispatch(setPersonalChats(loadChatRoomId));
  }, [dispatch, loadChatRoomId]);

  // Fetching User Details in a chatroom
  useEffect(() => {
    if (personalChats.length && selectedChatId) {
      (async () => {
        await getChatRoomWithId(selectedChatId).then((chatRoomDetails) => {
          const usersArr = chatRoomDetails.users;
          const userDetails = getUsersDetailsInChatroom(
            usersArr,
            usersProfileData,
          );
          dispatch(getSelectedChatUsersDetails(userDetails));
        });
      })();
    }
  }, [dispatch, personalChats, selectedChatId, usersProfileData]);

  return (
    <StyledChatBody>
      {personalChats.length || personalChatsLoading ? (
        <>
          <Conversations />
          <MessageThreadContainer />
        </>
      ) : (
        <h1>No Conversation</h1>
      )}
    </StyledChatBody>
  );
};

const StyledChatBody = styled.div`
  ${DISPLAY_FLEX('row')}
`

export default ChatBox;
