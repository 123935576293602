export enum Theme {
  Candidate = 'candidate',
  Organizer = 'organizer',
  Recruiter = 'recruiter',
  MasterAdmin = 'master-admin',
  Danger = 'danger',
  Warning = 'warning',
}

export enum EXPIRY_TYPE {
  OneWeek = '1 week',
  OneMonth = '1 month',
  ThreeMonths = '3 months',
  SixMonths = '6 months',
  OneYear = '1 year',
  Custom = 'custom',
  NoExpiry = 'no expiry',
}
