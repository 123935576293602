import React from 'react';
import round from 'lodash/round';
import { useSelector, useDispatch } from 'react-redux';
import { useAccount } from 'wagmi';
import styled from 'styled-components';
import { useVirtualWallet } from '../../../hooks/useVirtualWallet';
import { STATUS } from '../../../constants';
import { TransactionStatus } from '../../../constants/web3';
import { IDENTITY_VERIFICATION } from '../../../enums/identity-verification';
import LoaderIcon from '../../Common/Loaders/LoaderIcon';
import Loading from '../../Common/Loading';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import CustomDropdown from './CustomDropdown';
import CreditInfoDropdown from '../CreditInfoDropdown';
import { withdrawFromWallet } from '../../../store/features/virtualWallet';

const DropdownWalletModal = ({ isSidebar, dropdownRef, txnStatus }) => {
  const {
    status: walletStatus,
    wallet,
    incomingBalance,
    cryptoCurrency,
  } = useVirtualWallet();
  const dispatch = useDispatch();
  const identityVerification = useSelector(
    (state) => state.profile.profileData.identityVerification,
  );
  const currentBalance = wallet?.balance;
  const { isConnected: isWalletConnected } = useAccount();

  const handleWithdraw = async () => {
    const withdrawValue = {
      amount: round(+currentBalance, 4),
      cryptoCurrency,
    };

    dispatch(withdrawFromWallet(withdrawValue));
  };

  return (
    <StyledDropdownWallet isSidebar={isSidebar} ref={dropdownRef}>
      {walletStatus === STATUS.RESOLVED ? (
        <>
          {/* Incoming balance */}
          <div>
            <StyledFlexDiv>
              <StyledCreditsHeading>Credits</StyledCreditsHeading>
              <CreditInfoDropdown />
            </StyledFlexDiv>
            <StyledIncomingText>
              Incoming Credits: {incomingBalance} {cryptoCurrency}
            </StyledIncomingText>
            <StyledWithdrawText>
              Withdrawable Credits: {currentBalance} {cryptoCurrency}
            </StyledWithdrawText>
          </div>
          {/* Withdraw button */}
          <StyledWithdrawButtonDiv>
            {txnStatus !== TransactionStatus.LOADING ? (
              <>
                <StyledButton
                  onClick={handleWithdraw}
                  disabled={
                    identityVerification !== IDENTITY_VERIFICATION.Verified ||
                    !isWalletConnected ||
                    Number(currentBalance) <= 0
                  }
                >
                  Withdraw
                </StyledButton>
                <CustomDropdown />
              </>
            ) : (
              <StyledButton data-button="loading">
                <LoaderIcon />
              </StyledButton>
            )}
          </StyledWithdrawButtonDiv>
        </>
      ) : (
        <Loading color={COLORS.GRAY} />
      )}
      {/* Wallet balance */}
    </StyledDropdownWallet>
  );
};

export default DropdownWalletModal;

const StyledDropdownWallet = styled.div`
  background-color: ${COLORS.WHITE};
  position: absolute;
  top: 22px;
  width: 272px;
  left: ${({ isSidebar }) => (!isSidebar ? `-240px` : 0)};
  ${PADDING(8, 12)}
  border-radius: 10px;
  ${DISPLAY_FLEX('column')}
  gap: 10px;
  border: 1px solid ${COLORS.LIGHT_GRAY_3};
  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    left: ${({ isSidebar }) => (!isSidebar ? `-240px` : 0)};
  }
`;

const StyledFlexDiv = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  ${MARGIN(0, 0, 10, 0)}
  gap:12px;

  > span {
    top: 0 !important;
  }
`;

const StyledCreditsHeading = styled.p`
  font-size: ${FONT[18]};
  color: ${COLORS.BLACK_TONE_2};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

const StyledButton = styled.button`
  cursor: pointer;
  font-size: ${FONT[12]};
  background-color: ${(props) =>
    props.disabled === true ? COLORS.BLACK_TONE_3 : COLORS.GREEN_TONE_2};
  color: ${COLORS.WHITE};
  border: none;
  ${PADDING(5, 24)}
  border-radius: 3px;
  transition: all 0.2s ease;
  > .loader-spinner {
    height: 20px !important;
    width: 20px !important;
  }
  &[data-button='loading'] {
    border: 1px solid ${COLORS.GREEN_TONE_2};
    background-color: transparent;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    &:enabled {
      background-color: ${COLORS.GREEN_TONE_3};
    }
    &[data-button='loading'] {
      background-color: transparent;
    }
  }
`;

const StyledIncomingText = styled.p`
  ${MARGIN(0, 0, 5, 0)};
  color: ${COLORS.LIGHT_BLUE_4};
  font-size: ${FONT[13]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

const StyledWithdrawText = styled.p`
  color: ${COLORS.GREEN_TONE_3};
  font-size: ${FONT[13]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;

const StyledWithdrawButtonDiv = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')};
  gap: 10px;

  > span {
    top: 0 !important;
  }
`;
