import React from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LiveEventIcon } from '../svgicons';
import Notification from '../Notification/Notification';
import {
  launchPip,
  checkEventBookingInfo,
  handleJoinEvent,
} from '../../utils/event';
import { ATTENDANCE_ABSENT, STATUS_DELETE } from '../../constants';
import { userInfoRegardingChatroom } from '../../utils/chat';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsCandidate,
} from '../../utils/userInfo';
import { checkAndReGenerate } from '../../store/features/chat';

import { StyledBroadcastTagContainer } from '../shared-components/Broadcast/Common';

const LiveEventButton = ({ event }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const userData = userInfoRegardingChatroom();
  const dispatch = useDispatch();

  const checkBookingInfo = async (event) => {
    const roleId = get(user, 'roleId', null);

    if (checkIfUserIsOrganizer(roleId)) {
      return launchPip(event);
    }

    const eventId = event.id;
    const res = await checkEventBookingInfo(eventId);

    if (res.status === 200) {
      const payload = await get(res, 'data.payload', null);

      const isEventBooked = await get(payload, 'isEventBooked', false);
      const attendance = await get(payload, 'booking.attendance', null);

      const eventBookingStatus = get(payload, 'booking.status', STATUS_DELETE);

      if (
        !isEventBooked ||
        (isEventBooked &&
          attendance === ATTENDANCE_ABSENT &&
          eventBookingStatus === STATUS_DELETE)
      ) {
        return Notification(
          'info',
          'Join Event',
          'Please book event to watch it live!',
        );
      }

      await handleJoinEvent(event, roleId, userData, history, 500);
      if (checkIfUserIsCandidate(roleId)) {
        dispatch(checkAndReGenerate());
      }
    }
  };
  return (
    <StyledBroadcastTagContainer blink onClick={() => checkBookingInfo(event)}>
      Live Event
      <span>
        <LiveEventIcon />
      </span>
    </StyledBroadcastTagContainer>
  );
};

export default LiveEventButton;
