import React from 'react';

const InstagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_9_1"
      data-name="Component 9 – 1"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_64"
        data-name="Rectangle 64"
        width="40"
        height="40"
        rx="18"
        fill="#f0f0f0"
      />
      <path
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M10.628,7.416a5.45,5.45,0,1,0,5.45,5.45A5.441,5.441,0,0,0,10.628,7.416Zm0,8.993a3.543,3.543,0,1,1,3.543-3.543A3.55,3.55,0,0,1,10.628,16.409Zm6.944-9.216A1.271,1.271,0,1,1,16.3,5.922,1.268,1.268,0,0,1,17.572,7.193Zm3.61,1.29a6.291,6.291,0,0,0-1.717-4.454A6.332,6.332,0,0,0,15.01,2.312c-1.755-.1-7.015-.1-8.77,0A6.323,6.323,0,0,0,1.786,4.025,6.311,6.311,0,0,0,.069,8.478c-.1,1.755-.1,7.015,0,8.77A6.291,6.291,0,0,0,1.786,21.7,6.34,6.34,0,0,0,6.24,23.419c1.755.1,7.015.1,8.77,0A6.291,6.291,0,0,0,19.464,21.7a6.332,6.332,0,0,0,1.717-4.454c.1-1.755.1-7.01,0-8.765ZM18.914,19.132a3.587,3.587,0,0,1-2.021,2.021c-1.4.555-4.719.427-6.266.427s-4.871.123-6.266-.427a3.587,3.587,0,0,1-2.021-2.021c-.555-1.4-.427-4.719-.427-6.266S1.791,7.995,2.341,6.6A3.587,3.587,0,0,1,4.362,4.58c1.4-.555,4.719-.427,6.266-.427s4.871-.123,6.266.427A3.587,3.587,0,0,1,18.914,6.6c.555,1.4.427,4.719.427,6.266S19.469,17.737,18.914,19.132Z"
        transform="translate(9.005 6.763)"
        fill="#8c8c8c"
      />
    </svg>
  );
};

export default InstagramIcon;
