import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import BasicInformation from '../profile/BasicInformation';
import { getRoleId } from '../../utils/userInfo';
import { BigText } from '../shared-components/Texts';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

export default function RepresentativeAndTeamProfile({
  data,
  setProfilePic,
  profilePic,
  theme
}) {
  const roleId = getRoleId();
  return (
    <Container theme={theme}>
      <BigText margin="0 0 15px 0" fontWeight={700} color={getUserColorWithRoleId(roleId)}>
        Representative Info
      </BigText>
      <Row gutter={[16, 24]}>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 18, order: 1 }}>
          <BasicInformation profileData={data.companyAdmin} />
        </Col>
        <StyledPictureContainer>
          <UploadProfilePicComponent
            option={false}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            fileName={data.profilePic}
          />
        </StyledPictureContainer>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${props => props.theme === 'recruiter' ? "#fff9ee" : "#f0f9f8"};
  padding: 40px 15px;
`

const StyledPictureContainer = styled.div`
  position: absolute;
  right: 9%;
  bottom: 18%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    bottom: 46%;
  }
`
