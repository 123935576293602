import React from 'react';

const PinterestIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="Group_3305" data-name="Group 3305" transform="translate(-206 -51)">
        <rect
          id="Rectangle_412"
          data-name="Rectangle 412"
          width="40"
          height="40"
          rx="18"
          transform="translate(206 51)"
          fill="#f0f0f0"
        />
        <path
          id="Icon_awesome-pinterest"
          data-name="Icon awesome-pinterest"
          d="M24.972,13.048A12.487,12.487,0,0,1,8.79,24.975,14.035,14.035,0,0,0,10.341,21.7c.151-.584.775-2.97.775-2.97a3.344,3.344,0,0,0,2.86,1.435c3.766,0,6.48-3.464,6.48-7.768,0-4.123-3.368-7.21-7.7-7.21-5.387,0-8.252,3.615-8.252,7.557,0,1.833.977,4.113,2.532,4.838.237.111.362.06.418-.166C7.5,17.247,7.708,16.4,7.8,16a.374.374,0,0,0-.086-.357A4.917,4.917,0,0,1,6.8,12.8a5.4,5.4,0,0,1,5.639-5.417,4.929,4.929,0,0,1,5.216,5.08c0,3.378-1.707,5.719-3.927,5.719a1.811,1.811,0,0,1-1.848-2.256,25.089,25.089,0,0,0,1.032-4.159,1.568,1.568,0,0,0-1.581-1.757c-1.254,0-2.261,1.294-2.261,3.031a4.5,4.5,0,0,0,.373,1.853s-1.233,5.226-1.46,6.2a12.086,12.086,0,0,0-.045,3.585,12.487,12.487,0,1,1,17.037-11.63Z"
          transform="translate(214 58.438)"
        />
      </g>
    </svg>
  );
};

export default PinterestIcon;
