import get from 'lodash/get';
import { isEventOnline } from './event';
import { convertUnixTime } from './formatDateTime';
import {
  ONLINE_EVENT_TEXT,
  OFFLINE_EVENT_TEXT,
  STATUS_DELETE,
  STATUS_ACTIVE,
} from '../constants';

const formatDate = (date) => {
  return convertUnixTime(date).format('lll').substring(0, 12);
};

const formatTime = (time) => {
  return convertUnixTime(time).format('lll').substring(12);
};

export const formatDateTime = (start, end) => {
  const startDate = formatDate(start);
  const startTime = formatTime(start);

  const endDate = formatDate(end);
  const endTime = formatTime(end);

  if (convertUnixTime(start).isSame(convertUnixTime(end), 'day')) {
    // Skip endDate if same day
    return `${startDate} at ${startTime} - ${endTime}`;
  }

  return `${startDate} at ${startTime} - ${endDate} at ${endTime}`;
};

export const getEventTypeText = (event) => {
  const eventType = get(event, 'eventType', null);
  return isEventOnline(eventType) ? ONLINE_EVENT_TEXT : OFFLINE_EVENT_TEXT;
};

export const getEventDateTime = (event) => {
  return get(event, 'dateTime', '');
};

export const getEventEndDateTime = (event) => {
  return get(event, 'endDateTime', '');
};

/**
 * Takes events, filters with status and returns
 * @param {object} events
 * @returns {object} array
 */
export const filterByStatus = (events) => {
  return events
    .filter(
      (event) =>
        get(event, 'eventCompanys[0].status', STATUS_DELETE) === STATUS_ACTIVE,
    )
    .map((event, i) => ({
      id: event.id,
      name: event.name,
    }));
};

export const filterJobs = (jobResults, eventId) => {
  if (eventId) {
    return jobResults
      .filter((jobResult) => jobResult?.event?.id === eventId)
      .map((data) => ({ id: data.jobId, name: data.job.designation }));
  }
  return jobResults.map((data) => ({
    id: data.jobId,
    name: data.job.designation,
  }));
};
