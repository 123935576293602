import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';
import { getCandidateBookedEventsData } from '../../../store/features/events';
import { EventsStore } from '../../../utils/event';
import { getRoleId } from '../../../utils/userInfo';
import OngoingBookedEvents from '../../../components/Events/OngoingEvents/OngoingBookedEvents';
import UpcomingEvents from '../../../components/Events/UpcomingEvents';
import PastEvents from '../../../components/Events/PastEvent/PastEvents';
import Header from '../../../components/Events/Header';
import PageCircle from '../../../components/shared-components/PageCircle';
import { HiddenMobile } from '../../../components/shared-components/DocumentViewerModal';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { useTagManager } from '../../../utils/customHooks';
import { TabSwitch } from '../../../components/shared-components/Tabs';
import { BOX_SHADOW } from '../../../styles/constants/box-shadow';
import CancelledEvents from '../../../components/Events/CancelledEvent/CancelledEvents';

const Events = () => {
  const dispatch = useDispatch();
  const { isLoading, error } = EventsStore();
  const roleId = getRoleId();
  const updateUserActionInGTM = useTagManager();

  useEffect(() => {
    updateUserActionInGTM('events_opened');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getCandidateBookedEventsData());
  }, [dispatch]);

  const [value, setValue] = useState('');

  const RegisteredEvents = [
    {
      key: 1,
      name: 'Ongoing',
      component: <OngoingBookedEvents search={value} noTitle />,
    },
    {
      key: 2,
      name: 'Upcoming',
      component: <UpcomingEvents search={value} noTimeline noTitle />,
    },
    {
      key: 3,
      name: 'Past',
      component: <PastEvents search={value} noTimeline noTitle />,
    },
    {
      key: 4,
      name: 'Cancelled',
      component: <CancelledEvents search={value} noTimeline noTitle />,
    },
  ];

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <StyledMainContainer>
      <HiddenMobile>
        <PageCircle color={getUserColorWithRoleId(roleId)} />
      </HiddenMobile>
      <Header value={value} setValue={setValue} />
      <StyledHeaderWrapper id="event_title">
        <Row>
          <StyledEventTitleWrapper xs={24} lg={12} xl={12} xxl={6}>
            <StyledTitle>Registered Events</StyledTitle>
          </StyledEventTitleWrapper>
        </Row>
      </StyledHeaderWrapper>

      <StyledPageContainer borderRadius="4px">
        <TabSwitch
          theme="candidate"
          items={RegisteredEvents}
          defaultActiveKey="1"
        />
      </StyledPageContainer>
    </StyledMainContainer>
  );
};

export default Events;

const StyledPageContainer = styled.div`
  box-shadow: ${BOX_SHADOW[1]};
  background-color: ${COLORS.WHITE};
  padding: 25px;
  position: relative;
  width: 100%;
  border-radius: 4px;

  .ant-tabs {
    overflow: visible;
  }
`;

const StyledMainContainer = styled.div`
  position: relative;
`;

const StyledHeaderWrapper = styled.div`
  padding: 0px !important;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: -20px;
  }
`;

const StyledEventTitleWrapper = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 20px;
  }
`;

const StyledTitle = styled.h3`
  display: flex;
  line-height: 46px;
  color: ${COLORS.EERIE_BLACK};
  font-size: ${FONT[20]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;
