import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';

export const Circle1 = styled.div`
  position: absolute;
  border-radius: 50%;
  background-color: ${COLORS.LAVENDER};
  right: -10px;
  top: -50px;
  opacity: 0.2;
  z-index: 1;
  width: 170px;
  height: 160px;
`;

export const Circle2 = styled.div`
  position: absolute;
  background-color: ${COLORS.ANTI_FLASH_WHITE};
  border-radius: 50%;
  top: 60px;
  right: -20px;
  z-index: 2;
  opacity: 0.1;
  height: 120px;
  width: 120px;
`;
