import React from 'react';
import styled from 'styled-components';
import { LikeIcon } from '../../../components/svgicons';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';

export const ConvertedLeads = ({ leads }: any) => {
  const { coldLeads, coldLeadsToWarmLeads } = leads || {};
  const conversion = Math.round((coldLeadsToWarmLeads / coldLeads) * 100) || 0;
  return (
    <>
      <StyledHeader>
        <LikeIcon />
        <h1>Converted Leads</h1>
      </StyledHeader>
      <StyledMain>
        <StyledLeads>
          <p>
            Cold Leads: <StyledYellow>{coldLeads}</StyledYellow>
          </p>
          <p>
            Warm Leads: <StyledGreen>{coldLeadsToWarmLeads}</StyledGreen>
          </p>
        </StyledLeads>

        <StyledConversion>
          <span>{conversion}%</span>
          <p>Conversion</p>
        </StyledConversion>
      </StyledMain>
    </>
  );
};

const StyledHeader = styled.div`
  display: flex;
  gap: 10px;

  h1 {
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    color: ${COLORS.LIGHT_BLUE_3};
  }

  svg {
    margin-top: 3px;
  }
`;

const StyledMain = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

const StyledLeads = styled.div`
  p {
    margin: 10px 0;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }
`;

const StyledConversion = styled.div`
  span {
    text-align: center;
    display: inline-block;
    width: 100%;
    color: ${COLORS.LIGHT_BLUE_3};
    font-size: 29px;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  p {
    font-weight: ${FONT_WEIGHTS.MEDIUM};
  }
`;

const StyledYellow = styled.span`
  color: ${COLORS.DEEP_SAFFRON};
`;

const StyledGreen = styled.span`
  color: ${COLORS.CARIBBEAN_GREEN};
`;
