import React from 'react';
import { Upload, Tooltip, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';
import {
  StyledSpanProps,
  TextUploadProps,
  TextUploadFileListProps,
  PictureUploadWithListProps,
  StyledInfoCircleOutlinedProps,
} from '../../interfaces/components/Upload';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { isMobile } from '../../helpers/display';

export const ToolTippedInfo: React.FC<any> = ({
  backgroundColor,
  hintText,
  defaultHintText,
  position = 'relative',
  top = '4px',
  left = null,
  right = null,
  bottom = null,
  margin = null,
  ignoreTop = false,
}) => {
  const isResponsive = isMobile();
  return (
    <Tooltip
      placement="bottomRight"
      color={COLORS.WHITE}
      title={hintText || defaultHintText}
      overlayInnerStyle={{ color: 'black', backgroundColor: 'white' }}
      trigger={['hover', 'focus', 'click']}
    >
      {!isResponsive ? (
        <StyledInfoCircleOutlined
          backgroundColor={backgroundColor}
          position={position}
          top={!ignoreTop && top}
          left={left}
          right={right}
          bottom={bottom}
          margin={margin}
        />
      ) : (
        <StyledResponsiveIcon
          backgroundColor={backgroundColor}
          position={position}
          top={!ignoreTop && top}
          left={left}
          right={right}
          bottom={bottom}
          margin={margin}
          onClick={(event) => event.stopPropagation()}
        >
          <span>i</span>
        </StyledResponsiveIcon>
      )}
    </Tooltip>
  );
};

export const TextUpload: React.FC<TextUploadProps> = ({
  roleId,
  name = 'upload',
  text = 'Click to Upload',
  action,
  onChange,
  showUploadList = false,
  customRequestHandler,
  accept,
  maxCount = 10,
  beforeUpload,
  fileList,
  testId,
  cropActive = false,
  cropOptions,
  hintText,
  uploadButtonVisible = true,
  top = '0',
}) => {
  const defaultHintText = (
    <span>Recommended: JPG, PNG with 400px X 400px in lesser than 3MB</span>
  );

  return cropActive ? (
    <StyledInfoContainer>
      {uploadButtonVisible ? (
        <ImgCrop zoom={false} rotate beforeCrop={beforeUpload} {...cropOptions}>
          <Upload
            name={name}
            action={action}
            onChange={onChange}
            showUploadList={showUploadList}
            customRequest={customRequestHandler}
            accept={accept}
            maxCount={maxCount}
            fileList={fileList}
            data-testid={testId}
          >
            <StyledSpan roleId={roleId}>{text}</StyledSpan>
          </Upload>
        </ImgCrop>
      ) : null}
      <ToolTippedInfo
        backgroundColor={getUserColorWithRoleId(roleId)}
        hintText={hintText}
        defaultHintText={defaultHintText}
        top={top}
      />
    </StyledInfoContainer>
  ) : (
    <StyledInfoContainer>
      <Upload
        name={name}
        action={action}
        onChange={onChange}
        showUploadList={showUploadList}
        customRequest={customRequestHandler}
        accept={accept}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        fileList={fileList}
        data-testid={testId}
      >
        <StyledSpan roleId={roleId}>{text}</StyledSpan>
      </Upload>
      <ToolTippedInfo
        backgroundColor={getUserColorWithRoleId(roleId)}
        hintText={hintText}
        defaultHintText={defaultHintText}
      />
    </StyledInfoContainer>
  );
};

export const TextUploadFileList: React.FC<TextUploadFileListProps> = ({
  roleId,
  name = 'upload',
  text = 'Click to Upload',
  style = {},
  className = '',
  action,
  onChange,
  customRequest,
  icon,
  accept,
  onRemove,
  fileList,
  beforeUpload,
  showUploadList = true,
  cropActive = false,
  cropOptions,
  hintText,
  id,
  showTooltip = true,
  error,
}) => {
  const defaultHintText = (
    <span>Recommended: JPG, PNG with 400px X 400px in lesser than 3MB</span>
  );

  const color = getUserColorWithRoleId(roleId);

  return cropActive ? (
    <StyledUploadChildrenContainer>
      <ImgCrop zoom={false} rotate beforeCrop={beforeUpload} {...cropOptions}>
        <StyledUpload
          name={name}
          action={action}
          onChange={onChange}
          customRequest={customRequest}
          accept={accept}
          fileList={fileList}
          onRemove={onRemove}
          showUploadList={showUploadList}
          id={id}
        >
          <Button
            icon={icon}
            danger={error}
            className={className}
            style={style}
          >
            {text}
          </Button>
          {showTooltip ? (
            <ToolTippedInfo
              backgroundColor={color}
              hintText={hintText}
              defaultHintText={defaultHintText}
              position="absolute"
              top="0px"
              right="-30px"
            />
          ) : null}
        </StyledUpload>
      </ImgCrop>
    </StyledUploadChildrenContainer>
  ) : (
    <StyledUploadChildrenContainer>
      <StyledUpload
        name={name}
        action={action}
        onChange={onChange}
        customRequest={customRequest}
        accept={accept}
        onRemove={onRemove}
        fileList={fileList}
        showUploadList={showUploadList}
        beforeUpload={beforeUpload}
        id={id}
      >
        <Button icon={icon} danger={error} className={className} style={style}>
          {text}
        </Button>
        {showTooltip ? (
          <ToolTippedInfo
            backgroundColor={color}
            hintText={hintText}
            defaultHintText={defaultHintText}
            position="absolute"
            top="0px"
            right="-30px"
          />
        ) : null}
      </StyledUpload>
    </StyledUploadChildrenContainer>
  );
};

export const PictureUploadWithList: React.FC<PictureUploadWithListProps> = ({
  name = 'upload',
  text = 'Click to Upload',
  icon,
  roleId,
  inline = true,
  style = {},
  className = '',
  onChange,
  customRequest,
  maxCount,
  beforeUpload,
  accept,
  fileList,
  cropActive = false,
  hideToolTip = false,
  cropOptions,
  hintText,
  // eslint-disable-next-line no-unused-vars
  placement = 'bottomRight',
  id,
  error,
}) => {
  const defaultHintText = (
    <span>Recommended: JPG, PNG with 400px X 400px in lesser than 3MB</span>
  );
  const bgColor = getUserColorWithRoleId(roleId);

  return cropActive ? (
    <StyledUploadChildrenContainer>
      <ImgCrop zoom={false} rotate beforeCrop={beforeUpload} {...cropOptions}>
        <StyledUpload
          name={name}
          listType="picture"
          {...(inline ? {} : { className: 'upload-list-inline' })}
          onChange={onChange}
          customRequest={customRequest}
          maxCount={maxCount}
          accept={accept}
          fileList={fileList}
          id={id}
        >
          <Button
            icon={icon}
            danger={error}
            className={className}
            style={style}
          >
            {text}
          </Button>
          {!hideToolTip && (
            <ToolTippedInfo
              backgroundColor={bgColor}
              hintText={hintText}
              defaultHintText={defaultHintText}
              position="absolute"
              top="0px"
              right="-30px"
            />
          )}
        </StyledUpload>
      </ImgCrop>
    </StyledUploadChildrenContainer>
  ) : (
    <StyledUploadChildrenContainer>
      <StyledUpload
        name={name}
        listType="picture"
        {...(inline ? {} : { className: 'upload-list-inline' })}
        onChange={onChange}
        customRequest={customRequest}
        maxCount={maxCount}
        beforeUpload={beforeUpload}
        accept={accept}
        fileList={fileList}
        id={id}
      >
        <Button icon={icon} className={className} style={style}>
          {text}
        </Button>
        {!hideToolTip && (
          <ToolTippedInfo
            backgroundColor={bgColor}
            hintText={hintText}
            defaultHintText={defaultHintText}
            position="absolute"
            top="0px"
            right="-30px"
          />
        )}
      </StyledUpload>
    </StyledUploadChildrenContainer>
  );
};

const StyledResponsiveIcon = styled.span<StyledInfoCircleOutlinedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.WHITE};
  background-color: ${(props) => props.backgroundColor};
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1.5px solid ${COLORS.WHITE_SMOKE};
  position: ${(props) => props.position};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.margin && `margin: ${props.margin}`};

  span {
    align-self: center;
    font-size: ${FONT[12]};
  }
`;

const StyledSpan = styled.span<StyledSpanProps>`
  color: ${(props) => getUserColorWithRoleId(props.roleId)};
  font-size: ${FONT[11]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  text-align: center;
  margin-right: 5px;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;

const StyledInfoContainer = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;

const StyledUploadChildrenContainer = styled.div`
  ${DISPLAY_FLEX('row', null, null)}
  position: relative;
`;

const StyledUpload = styled(Upload)`
  span.ant-upload {
    position: relative;
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<
  StyledInfoCircleOutlinedProps
>`
  color: ${COLORS.WHITE};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  font-size: 20px;
  height: max-content;
  position: ${(props) => props.position};
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.left && `left: ${props.left}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.margin && `margin: ${props.margin}`};

  &:active {
    pointer-events: none;
  }
`;
