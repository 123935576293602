import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel, Skeleton } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getPublicEventByIdData } from '../../../store/features/events';
import { EventsStore } from '../../../utils/event';
import AttendingCompanies from './AttendingCompanies';
import '../../../styles/components/events/view-event.scss';
import BookOrPromoteEvent from './BookAndPromoteEvent/BookAndPromoteEvent';
import { DateInfoSection } from './DateInfoSection';
import { SkillsAndTypesSection } from './SkillsAndTypesSection';
import Header from '../../../containers/Header';
import { OrganizerInfoSection } from './OrganizerInfoSection';
import TermsAndConditions from './termsAndCondition';
import { getFileS3 } from '../../../utils/s3.storage';
import HelmetMetaData from '../../Common/HelmetMetaData';
import { PublicRoutes } from '../../../constants/routes';
import { getTextFromHTML } from '../../../utils/common';

export const UserClassNameContext = React.createContext({});
const ViewEventPublic = ({ history }) => {
  const dispatch = useDispatch();
  const { id: eventId } = useParams();
  const { isLoading, event, error } = EventsStore();
  const [userClassName, setUserClassName] = useState('');
  const isPublicEvent = true;

  useEffect(() => {
    dispatch(getPublicEventByIdData(eventId));
  }, [eventId, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUserClassName('public_events');
  }, []);

  const UserClassNameContextProvider = () => {
    return (
      <UserClassNameContext.Provider value={userClassName}>
        <BookOrPromoteEvent
          openModal={() => {}}
          event={event}
          isPublicEvent={isPublicEvent}
        />
      </UserClassNameContext.Provider>
    );
  };

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <>
      <HelmetMetaData
        url={`${PublicRoutes.publicEvent(eventId, event ? event.name : '')}`}
        image={getFileS3(event.cover)}
        description={getTextFromHTML(event.description)}
        title={event.name}
      />
      <Header />
      <div className="public-event__container">
        <div className="custom_container">
          <div
            className={`content_box relative create_event_sec back_itsubmit ${userClassName}`}
          >
            <div className="title_explore">
              <RightOutlined />
              <h3
                className="exploretitle large-heading"
                onClick={() => history.push('/login')}
              >
                Explore Events
              </h3>
            </div>
            <div className="fullslider relative">
              <Row>
                <Col xs={24} className="right_slider">
                  <Carousel autoplay className="eve-slider">
                    <div>
                      <img src={getFileS3(event.cover)} className="" alt="" />
                    </div>
                  </Carousel>
                </Col>
              </Row>
            </div>
            <div className="book-event-section bookeventsec mt25">
              <div className="book-event-section__header">
                <h2 className="extra-large-heading">{event.name}</h2>
              </div>
              <div className="hidden-mobile">
                <UserClassNameContextProvider />
              </div>
            </div>

            <DateInfoSection event={event} />

            <div className="book-event-section view-public-event__book-section show-mobile">
              <UserClassNameContextProvider />
            </div>

            <SkillsAndTypesSection event={event} />
            {/* Description Section */}
            <div className="tag_sec view-event-public__description">
              <h6>Description</h6>
            </div>
            <p className="para_content">{getTextFromHTML(event.description)}</p>

            <AttendingCompanies event={event} />

            <OrganizerInfoSection event={event} />

            <TermsAndConditions event={event} />
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewEventPublic;
