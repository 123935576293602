import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useRouteMatch } from 'react-router';
import Information from '../../../components/ManagementHeader/Information';
import EventAnalytics from '../../../components/ManagementHeader/EventAnalytics';
import PastEventAnalytics from '../../../components/Events/PastEvent/PastEventAnalytics';
import { isMobile } from '../../../helpers/display';
import { DropdownBox } from '../../../components/shared-components/Form/Form';
import { EventsStore } from '../../../utils/event';
import {
  getEventAnalyticsData,
  getEventAnalyticsDataByJobId,
} from '../../../store/features/analytics';
import { EventAnalyticsForJobStore } from '../../../utils/analytics';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { MediumHeading } from '../../../components/shared-components/Texts';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { JOB_DETAILS_PAGE, RECRUITER_EVENTS_PAGE } from '../../../constants';
import { getRoleId, getUserClassWithRoleId } from '../../../utils/userInfo';
import { mediumHeadingMixin } from '../../../styles/constants/mixins';
import { filterByStatus, filterJobs } from '../../../utils/analyticsModal';
import { Modal } from '../../../components/shared-components/Modal';
import { getRecruiterBookedEventsData } from '../../../store/features/events';

const ViewJobAnalyticsModal = ({
  handleCancel,
  handleOk,
  visible,
  jobId,
  jobTitle,
  candidateEvent = null,
  event,
  jobResults = [],
  recruiterEvent,
  page,
}) => {
  // analyticsUserCode is 1 for company and 2 for candidate
  const { onGoingEvents, pastEvents } = EventsStore();
  const isPastEventAnalyticsModal = true;
  const events = onGoingEvents.concat(pastEvents);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentJobId, setCurrentJobId] = useState();
  const [jobEventId, setJobEventId] = useState(null);

  const roleId = getRoleId();

  const match = useRouteMatch();

  const dispatch = useDispatch();

  useEffect(() => {
    if (page === JOB_DETAILS_PAGE && !events.length) {
      dispatch(getRecruiterBookedEventsData());
    }
    // eslint-disable-next-line
  }, [events.length, dispatch]);

  useEffect(() => {
    if (events.length > 0) {
      setCurrentEvent(events[0].id);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobId && event?.id && jobResults?.length) {
      // Incase jobId event.id available through props
      setJobEventId(event.id);
      setCurrentJobId(jobId);
    } else if (match.params.id) {
      setJobEventId(match.params.id);
      setCurrentJobId(jobResults.length ? jobResults[0].jobId : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, jobResults.length]);

  const handleChangeEvent = (id) => {
    setCurrentEvent(id);
  };

  const handleChangeJob = (id) => {
    setCurrentJobId(id);
  };

  const { analytics } = EventAnalyticsForJobStore();

  useEffect(() => {
    if (!candidateEvent && !recruiterEvent && jobId && currentEvent) {
      dispatch(getEventAnalyticsData(3, [currentEvent]));
      dispatch(getEventAnalyticsDataByJobId(jobId, currentEvent));
    }

    if (candidateEvent && jobEventId) {
      dispatch(getEventAnalyticsData(1, [jobEventId]));
      dispatch(getEventAnalyticsDataByJobId(Number(currentJobId), jobEventId));
    }

    if (recruiterEvent && jobEventId) {
      dispatch(getEventAnalyticsData(3, [jobEventId]));
      dispatch(getEventAnalyticsDataByJobId(Number(currentJobId), jobEventId));
    }
  }, [
    dispatch,
    jobId,
    currentEvent,
    candidateEvent,
    recruiterEvent,
    currentJobId,
    jobEventId,
  ]);

  const getEventAnalytics = () => {
    if (isPastEventAnalyticsModal) {
      return (
        <EventAnalytics EventAnalyticsItemComponent={PastEventAnalytics} />
      );
    }
  };

  const handleModalCancel = () => {
    handleCancel();
  };

  return (
    <Modal
      theme={getUserClassWithRoleId(roleId)}
      title="Event Analytics & Report"
      visible={visible}
      onOk={handleOk}
      onCancel={handleModalCancel}
      width={isMobile() ? '100%' : '80%'}
      footer={null}
      closable={false}
    >
      <div>
        <StyledHeaderRow>
          <Col xxl={16} xl={16} lg={16} xs={12}>
            <StyledLevelTwoHeading data-testid="event-jobs-analytics">
              Jobs Information
            </StyledLevelTwoHeading>
          </Col>
        </StyledHeaderRow>

        <StyledLevelThreeHeading>
          {candidateEvent || recruiterEvent ? 'Select Job' : 'Select Event'}
        </StyledLevelThreeHeading>
        <StyledMiddleRow align="middle">
          <Col lg={6} xs={24}>
            {candidateEvent || recruiterEvent ? (
              <DropdownBox
                handleChange={handleChangeJob}
                options={filterJobs(
                  jobResults,
                  page !== RECRUITER_EVENTS_PAGE ? event?.id : null,
                )}
                placeholder="Job"
                value={currentJobId}
                showSearch
              />
            ) : (
              <DropdownBox
                handleChange={handleChangeEvent}
                options={filterByStatus(events)}
                placeholder="Event"
                value={currentEvent}
                showSearch
              />
            )}
          </Col>
          <StyledJobTextCol lg={10} xs={24}>
            <Row align="middle">
              <Col lg={6} xs={24}>
                <StyledJobTitleHeading>
                  {candidateEvent || recruiterEvent
                    ? 'Event Title:'
                    : 'Job Title:'}
                </StyledJobTitleHeading>
              </Col>
              <Col lg={16} xs={24}>
                <StyledJobTitleText>
                  {candidateEvent || recruiterEvent ? event.name : jobTitle}
                </StyledJobTitleText>
              </Col>
            </Row>
          </StyledJobTextCol>
        </StyledMiddleRow>
      </div>
      <StyledAnalyticsHeading>Overall Event Analytics</StyledAnalyticsHeading>

      {getEventAnalytics()}

      <Information
        isPastEventAnalyticsModal={isPastEventAnalyticsModal}
        data={analytics}
      />
    </Modal>
  );
};

export default ViewJobAnalyticsModal;

const StyledHeaderRow = styled(Row)`
  display: flex;
  flex-direction: flex-start;
  font-size: 14px;
  color: ${COLORS.ARSENIC};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  margin-bottom: 15px;
`;

const StyledLevelTwoHeading = styled.h2`
  font-size: 16px;
  font-weight: ${FONT_WEIGHTS.BOLD};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 18px;
  }
`;

const StyledMiddleRow = styled(Row)`
  margin-top: 5px;

  .ant-select-selector {
    border-color: ${COLORS.ARGENT} !important;
  }
`;

const levelThreeHeading = css`
  ${MediumHeading};
  color: ${COLORS.CHINESE_BLACK};
`;

const StyledJobTextCol = styled(Col)`
  margin-left: 50px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 15px;
    margin-left: 0;
  }
`;
const StyledLevelThreeHeading = styled.h3`
  ${levelThreeHeading};
`;

const StyledJobTitleHeading = styled.h3`
  ${levelThreeHeading};
  margin-top: 12px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  opacity: 0.7;
`;

const StyledJobTitleText = styled.span`
  ${levelThreeHeading};
  margin-top: 12px;
  margin-left: 2px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  opacity: 0.7;
`;

const StyledAnalyticsHeading = styled.div`
  ${mediumHeadingMixin};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-top: 25px;
  margin-bottom: 25px;
`;
