import React from 'react';
import { Col } from 'antd';
import get from 'lodash/get';
import {
  BuildingIcon,
  ApplicationIcons,
  GroupUser,
  BreifCaseOutlinedIcon,
  CalendarIcon,
} from '../../svgicons';
import { EventAnalyticsStore } from '../../../utils/analytics';

const OrganizerPastEventsAnalytics = () => {
  const { analytics } = EventAnalyticsStore();

  const initialValue = '-';

  let candidate = initialValue;
  let companies = initialValue;
  let jobs = initialValue;
  let application = initialValue;
  let interviewScheduled = initialValue;

  if (analytics) {
    candidate = get(analytics[0], 'noOfCandidates', initialValue);
    companies = get(analytics[0], 'noOfCompanies', initialValue);
    application = get(analytics[0], 'noOfJobApplications', initialValue);
    jobs = get(analytics[0], 'noOfJobs', initialValue);
    interviewScheduled = get(
      analytics[0],
      'noOfInterviewScheduled',
      initialValue,
    );
  }

  const analyticsData = [
    {
      Icon: GroupUser,
      key: 'Candidate',
      value: candidate,
    },
    {
      Icon: BuildingIcon,
      key: 'Companies',
      value: companies,
    },
    {
      Icon: BreifCaseOutlinedIcon,
      key: 'Jobs',
      value: jobs,
    },
    {
      Icon: ApplicationIcons,
      key: 'Application',
      value: application,
    },
    {
      Icon: CalendarIcon,
      key: 'Interview Scheduled',
      value: interviewScheduled,
    },
  ];

  return analyticsData.map((item, i) => {
    const { Icon, key, value } = item;
    return (
      <Col
        md={4}
        xs={24}
        className="event-analytics__container__wrapper__columns analytics-item"
        key={i.toString()}
      >
        <div className="event-analytics__container__wrapper--icon--left">
          <Icon />
        </div>
        <div className="event-analytics__container__wrapper__columns--right">
          <p className="event-analytics__container__wrapper__columns__text--right">
            {key}
          </p>
          <b>{value}</b>
        </div>
      </Col>
    );
  });
};

export default OrganizerPastEventsAnalytics;
