import React from 'react';
import { Link } from 'react-router-dom';
import isInteger from 'lodash/isInteger';
import { OrganizerRoutes } from '../../../constants/routes';
import EarthIcon from '../../../assets/icons-and-sets/047-earth-grid.svg';
import BuildingIcon from '../../../assets/icons-and-sets/company.svg';
import TotalCandidatesIcon from '../../../assets/icons-and-sets/Crew.svg';
import { COLORS } from '../../../styles/constants/colors';
import { IconBadge } from '../../shared-components/IconBadge';

export default function OrganizerAnalytics({ analytics, theme }) {
  const { eventsCreated, companysRegistered, candidatesRegistered } = analytics;

  if (
    !isInteger(eventsCreated) ||
    !isInteger(companysRegistered) ||
    !isInteger(candidatesRegistered)
  ) {
    return null;
  }

  const itemList = [
    {
      icon: EarthIcon,
      itemName: 'Created Events',
      itemValue: eventsCreated,
      to: OrganizerRoutes.events,
    },
    {
      icon: BuildingIcon,
      itemName: 'Registered Companies',
      itemValue: companysRegistered,
      to: `${OrganizerRoutes.attendees}1`,
    },
    {
      icon: TotalCandidatesIcon,
      itemName: 'Total Candidates',
      itemValue: candidatesRegistered,
      to: `${OrganizerRoutes.attendees}2`,
    },
  ];
  return (
    <>
      {itemList.map((item) => (
        <Link to={item.to}>
          <IconBadge
            icon={item.icon}
            text={item.itemName}
            textColor={COLORS.WHITE}
            status={item.itemValue}
            theme={theme}
            hoverEffect
            color={COLORS.MEDIUM_AQUAMARINE}
          />
        </Link>
      ))}
    </>
  );
}
