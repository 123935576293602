import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { COLORS } from '../../styles/constants/colors';
import { largeFontMixin } from '../../styles/constants/mixins';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { FONT } from '../../styles/constants/typography';
import MainContainer from '../Common/MainContainer';
import { RightAlignedContainer } from '../Common/LandingCommon';
import { MainWebsiteRoutes } from '../../constants/routes';

const Copyright = () => {
  return (
    <StyledWrapper>
      <MainContainer>
        <Row>
          <StyledCopyRightText md={12} xs={24}>
            © {new Date().getFullYear()}. Recorem. All Rights Reserved
          </StyledCopyRightText>
          <Col md={12} xs={24}>
            <StyledRightLinks>
              <RightAlignedContainer gap={6} mobileJustify="flex-start">
                <StyledLinks href={MainWebsiteRoutes.privacyPolicy}>
                  Privacy Policy
                </StyledLinks>
                <>|</>
                <StyledLinks href={MainWebsiteRoutes.data}>
                  Data Protection
                </StyledLinks>
                <>|</>
                <StyledLinks href={MainWebsiteRoutes.termsAndConditions}>
                  Terms & Conditions
                </StyledLinks>
              </RightAlignedContainer>
            </StyledRightLinks>
          </Col>
        </Row>
      </MainContainer>
    </StyledWrapper>
  );
};

export default Copyright;

const StyledWrapper = styled.div`
  background-color: ${COLORS.BLACK};
  color: ${COLORS.ARGENT};
  padding: 25px 0;
  ${largeFontMixin};
`;

const StyledLinks = styled.a`
  text-decoration: underline !important;
  min-width: max-content;
  cursor: pointer !important;

  &:hover {
    color: ${COLORS.LINK_BLUE} !important;
    text-decoration: unset !important;
  }
`;

const StyledRightLinks = styled.ul`
  margin: 0;
  a:not(:first-child) {
    &:before {
      margin-right: 6px;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}){
    margin-top: 10px;
    padding-left 0px;
    font-size: ${FONT[14]};
  }
`;

const StyledCopyRightText = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;
