import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TestContext } from '../../context/testMode';
import { PersonalChatContext } from '../../context/personalChat';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
  getUserClassWithRoleId,
} from '../../utils/userInfo';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../../constants/routes';
import { PrimaryButton } from '../shared-components/Button';
import ChatBox from './ChatBox';
import { PersonalChatHeader } from '../shared-components/Chat/PersonalChatHeader';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { BOX_SHADOW } from '../../styles/constants/box-shadow';
import PageCircle from '../shared-components/PageCircle';

const Chat = ({ isForTest }) => {
  const { chatOpened, setChatOpened } = useContext(PersonalChatContext);

  const history = useHistory();
  const chatRoomId = get(history, 'location.state.chatRoomId', '');

  const [groupChatLink, setGroupChatLink] = useState('');

  const roleId = getRoleId();

  useEffect(() => {
    if (checkIfUserIsOrganizer(roleId)) {
      setGroupChatLink(OrganizerRoutes.groupChat);
    } else if (checkIfUserIsRecruiter(roleId)) {
      setGroupChatLink(RecruiterRoutes.groupChat);
    } else if (checkIfUserIsCandidate(roleId)) {
      setGroupChatLink(CandidateRoutes.groupChat);
    }
  }, [roleId]);

  return (
    <StyledContainer>
      <PageCircle hideForMobile color={getUserColorWithRoleId(roleId)} />
      <StyledChatMainHeading>
        <StyledChatMainHeadingLeft>
          <StyledArrow
            chatOpened={chatOpened}
            onClick={() => setChatOpened(false)}
          />
          <StyledHeading roleId={roleId}>Chat Management </StyledHeading>
        </StyledChatMainHeadingLeft>
        <StyledChatMainHeadingRight chatOpened={chatOpened}>
          <h5>Live Events </h5>
          <Link to={groupChatLink}>
            <PrimaryButton
              theme={getUserClassWithRoleId(roleId)}
              margin="0 10px"
              id="chat_room_btn"
            >
              Chat Room
            </PrimaryButton>
          </Link>
        </StyledChatMainHeadingRight>
      </StyledChatMainHeading>
      <StyledChatContainer>
        <TestContext.Provider value={!!isForTest}>
          <PersonalChatHeader />
          <ChatBox loadChatRoomId={chatRoomId} />
        </TestContext.Provider>
      </StyledChatContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
`;

const StyledChatMainHeading = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}

  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(15, 0, -5, 0)}
  }

  h5 {
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    margin: 0;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[18]};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[14]};
    }
  }
`;

const StyledChatMainHeadingLeft = styled.div`
  ${DISPLAY_FLEX('row')}
  margin-right: 10px;

  h5 {
    align-items: center;
  }

  svg {
    display: none;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      display: block;
      margin-right: 8px;
    }
  }
`;

const StyledArrow = styled(ArrowLeftOutlined)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => (props.chatOpened ? '' : 'none')};
  }
`;

const StyledHeading = styled.h5`
  color: ${(props) => getUserColorWithRoleId(props.roleId)};
`;

const StyledChatMainHeadingRight = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: ${(props) => (props.chatOpened ? 'none' : '')};
    font-size: ${FONT[14]};
    ${MARGIN(10, 0)}
  }

  h5 {
    font-size: ${FONT[14]};
    color: ${COLORS.CHINESE_BLACK};
    opacity: 80%;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[18]};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[12]};
    }
  }

  p {
    font-size: ${FONT[14]};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    margin-left: 20px;
    padding: 8px 37px;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[18]};
      margin-left: 20px;
      padding: 6px 45px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[12]};
      margin-left: 15px;
      padding: 5px 20px;
      text-align: center;
    }
  }
`;

const StyledChatContainer = styled.div`
  box-shadow: ${BOX_SHADOW[4]};
  background: ${COLORS.WHITE};
  z-index: 1;
  padding: 20px;
  position: relative;
  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 0, 10, 0)}
    margin-bottom: 10px;
  }
`;

export default React.memo(Chat);
