import React from 'react';
import { TextBox } from '../shared-components/Form/Form';

const CountryCodeInput = ({ name, disabled = false, required = false }) => {
  return (
    <TextBox
      placeholder="Country Code"
      name={name}
      rule="countryCode"
      required={required}
      disabled={disabled}
    />
  );
};

export default CountryCodeInput;
