import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Col, Spin } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { HiddenMobile } from '../components/shared-components/DocumentViewerModal';
import { getUserColorWithRoleId } from '../styles/utils';
import { checkIfUserIsCandidate, getRoleId } from '../utils/userInfo';
import { SemiLargeHeading } from '../components/shared-components/Texts';
import Circle from '../components/shared-components/PageCircle';
import { PageContainer } from '../components/shared-components/PageContainer';
import { DISPLAY_FLEX } from '../styles/constants/display';
import { FONT_WEIGHTS } from '../styles/constants/typography';
import { PAYMENT } from '../constants';
import Paid from '../assets/img/paid.png';
import Error from '../assets/img/canceled.png';
import Inprocess from '../assets/img/inprocess.png';
import {
  extraLargeHeadingMixin,
  mediumHeadingMixin,
} from '../styles/constants/mixins';
import config from '../config';
import { CandidateRoutes, RecruiterRoutes } from '../constants/routes';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import { removeApplyJobData, setApplyJobData } from '../store/features/commons';

const PaymentHeader = ({ message, subMessage, icon }) => {
  return (
    <>
      <StyledRow>
        <StyledStatusIcon src={icon} alt="payment icons" />
        <StyledSpanMessage>{message}</StyledSpanMessage>
      </StyledRow>
      <StyledRow>
        <StyledSubMessage>{subMessage}</StyledSubMessage>
      </StyledRow>
    </>
  );
};

const PaymentStatusMessage = () => {
  const stripe = useStripe();
  const roleId = getRoleId();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState();
  const search = new URLSearchParams(window.location.search);
  const clientSecret = search.get('payment_intent_client_secret');
  const eventId = search.get('eventId');

  const dispatch = useDispatch();
  const { applyJobData } = useSelector((st) => st.commons);
  const [remainingTime, setRemainingTime] = useState(5);

  useEffect(() => {
    if (remainingTime <= 0) {
      if (applyJobData && !isEmpty(applyJobData)) {
        const parsedData = applyJobData;
        if (parseInt(parsedData.eventId) === parseInt(eventId)) {
          dispatch(
            setApplyJobData({
              ...parsedData,
              isPaidEventBooked: true,
            }),
          );
        } else {
          dispatch(removeApplyJobData());
        }
      }
      window.location.href = `${config.appURL}${checkIfUserIsCandidate(roleId)
        ? CandidateRoutes.viewEvent(eventId)
        : RecruiterRoutes.viewEvent(eventId)
        }`;
    }
    // eslint-disable-next-line
  }, [remainingTime]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setStatus(PAYMENT.PAID);
          setMessage(
            `Thank you! Your payment of ${paymentIntent.amount / 100} ${paymentIntent.currency
            } has been received.`,
          );
          break;
        case 'processing':
          setStatus(PAYMENT.PENDING);
          setMessage(
            "Payment processing. We'll update you when payment is received.",
          );
          break;
        default:
          setStatus(PAYMENT.ERROR);
          setMessage('Something went wrong.');
          break;
      }
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    });

    // eslint-disable-next-line
  }, [stripe]);

  const getStatusContainer = () => {
    switch (status) {
      case PAYMENT.PAID:
        return (
          <PaymentHeader
            message="Payment Successfull"
            icon={Paid}
            subMessage={message}
          />
        );
      case PAYMENT.ERROR:
        return (
          <PaymentHeader
            message="Payment Canceled"
            icon={Error}
            subMessage={message}
          />
        );
      default:
        return (
          <PaymentHeader
            message="Payment Pending"
            icon={Inprocess}
            subMessage={message}
          />
        );
    }
  };

  return (
    <>
      <HiddenMobile>
        <Circle color={getUserColorWithRoleId(roleId)} />
      </HiddenMobile>
      <Col xs={24} lg={12} xxl={6}>
        <SemiLargeHeading color={getUserColorWithRoleId(roleId)}>
          Payment Status
        </SemiLargeHeading>
      </Col>
      <PageContainer>
        <StyledContainer>
          {status ? (
            <>
              {getStatusContainer()}
              <StyledRedirect>
                Redirecting in <span>{remainingTime}</span>
              </StyledRedirect>
            </>
          ) : (
            <StyledSpinner />
          )}
        </StyledContainer>
      </PageContainer>
    </>
  );
};

export default PaymentStatusMessage;

const StyledContainer = styled.div`
  ${DISPLAY_FLEX('column', 'center', 'center')}
`;

const StyledRedirect = styled.p`
  span {
    font-weight: ${FONT_WEIGHTS.BOLD};
  }
`;

const StyledSpinner = styled(Spin)`
  .ant-spin-dot {
    width: 80px;
    height: 80px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 60px;
      height: 60px;
    }

    .ant-spin-dot-item {
      width: 40px;
      height: 40px;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

const StyledRow = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
`;

const StyledStatusIcon = styled.img`
  width: 50px;
  height: 50px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 35px;
    height: 35px;
  }
`;

const StyledSpanMessage = styled.span`
  ${extraLargeHeadingMixin}
  margin-left:10px;
`;

const StyledSubMessage = styled.span`
  ${mediumHeadingMixin}
  font-weight: ${FONT_WEIGHTS.BOLD};
  margin-top: 10px;
  text-align: center;
`;
