import React from 'react';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { ACCEPTED_DOCUMENT_EXTENSIONS } from '../../../constants/file';
import MandatoryStar from '../../Common/mandatoryStar';
import { TextUploadFileList } from '../../shared-components/Upload';
import { getRoleId } from '../../../utils/userInfo';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { MARGIN } from '../../../styles/constants/display';

const TermsAndPolicy = ({
  uploadEventFile,
  handleFileChange,
  handleRemove,
  fileList,
  isTabActive = false,
  documentError,
}) => {
  const roleId = getRoleId();

  return (
    <>
      {/* upload official */}
      <StyledInfoHeader id="terms_and_policy_label">
        Upload your document <MandatoryStar />
      </StyledInfoHeader>

      {/* TODO: Add preview list uploaded files for edit event */}
      <StyledFlex>
        <StyledUploadWrapper>
          <TextUploadFileList
            name="termsAndConditions"
            text="Upload Document"
            icon={<UploadOutlined />}
            customRequest={(data) => uploadEventFile(data, 'terms&conditions')}
            onChange={(data) => handleFileChange(data, 'terms&conditions')}
            accept={ACCEPTED_DOCUMENT_EXTENSIONS}
            onRemove={handleRemove}
            fileList={fileList}
            roleId={roleId}
            hintText="PDF format only"
            id="terms_and_policy"
            error={documentError}
          />
        </StyledUploadWrapper>
      </StyledFlex>
    </>
  );
};

export default TermsAndPolicy;

const StyledFlex = styled.div`
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-upload-list-text-container {
    margin-right: 10px;
    margin-top: 10px;
  }
`;

const StyledInfoHeader = styled.span`
  display: block;
  ${MARGIN(0, 0, 10, 0)};
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledUploadWrapper = styled.div`
  button {
    height: 42px;
  }
`;
