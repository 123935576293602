import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import PersonDetailModal from './profile/PersonDetailModal';
import { userProfileStore } from '../../utils/profile';
import Header from '../../components/profile/Header';
import Profile from './Profile';
import { getFileS3 } from '../../utils/s3.storage';
import { StyledPageBottom } from '../../components/shared-components/Common/PageBottom';
import { getUserColorWithRoleId } from '../../styles/utils';
import { CANDIDATE } from '../../constants';
import PageCircle from '../../components/shared-components/PageCircle';
import { StyledProfileWrapper } from '../../components/shared-components/common';
import { getWorkAuthorizationData } from '../../service/candidate';
import { useTagManager } from '../../utils/customHooks';

const CandiateProfile = () => {
  const { isLoading, profileData, error } = userProfileStore();
  const [profilePic, setProfilePic] = useState(null);
  const updateUserActionInGTM = useTagManager();
  const [personModal, setPersonModal] = useState(false);
  const { currencyData } = useSelector((st) => st.commons);
  const [unauthorizedCountryIds, setUnauthorizedCountryIds] = useState([]);
  const [authorizedCountryIds, setAuthorizedCountryIds] = useState([]);

  const [unauthorizedCountries, setUnauthorizedCountries] = useState([]);
  const [authorizedCountries, setAuthorizedCountries] = useState([]);

  const [workAuthorizationsData, setWorkAuthorizationsData] = useState([]);

  const getWorkAuthorizationInfo = async () => {
    const workauthorizations = await getWorkAuthorizationData();
    setWorkAuthorizationsData(workauthorizations);
    const authorizedCount = workauthorizations.filter(
      (item) => !!get(item, 'isAuthorized', false),
    );
    const unauthorizedCount = workauthorizations.filter(
      (item) => !get(item, 'isAuthorized', false),
    );
    // Set countries
    setUnauthorizedCountries(unauthorizedCount);
    setAuthorizedCountries(authorizedCount);

    const authorizedCountIds = authorizedCount.map(
      (item) => `${item.countryId}`,
    );
    const unauthorizedCountIds = unauthorizedCount.map(
      (item) => `${item.countryId}`,
    );
    //
    setUnauthorizedCountryIds(unauthorizedCountIds);
    setAuthorizedCountryIds(authorizedCountIds);
  };

  useEffect(() => {
    getWorkAuthorizationInfo();
  }, []);

  React.useEffect(() => {
    if (profileData) {
      const userProfilePic = get(profileData, 'profilePic', null);
      if (userProfilePic) {
        setProfilePic(getFileS3(profileData.profilePic));
      }
    }
  }, [profileData]);

  useEffect(() => {
    updateUserActionInGTM('profile_settings_opened');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenPersonModal = () => {
    setPersonModal(true);
  };

  const handleClosePersonModal = () => {
    setPersonModal(false);
  };

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <>
      <PageCircle color={getUserColorWithRoleId(CANDIDATE)} />
      <StyledProfileWrapper>
        <Header
          data={profileData}
          handleOpenModal={handleOpenPersonModal}
          candidate
        />
        <Profile
          profileData={profileData}
          currencyData={currencyData}
          unauthorizedCountries={unauthorizedCountries}
          authorizedCountries={authorizedCountries}
          workAuthorizationsData={workAuthorizationsData}
        />
        {personModal ? (
          <PersonDetailModal
            visible={personModal}
            handleClose={handleClosePersonModal}
            profileData={profileData}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            currencyData={currencyData}
            unauthorizedCountries={unauthorizedCountryIds}
            authorizedCountries={authorizedCountryIds}
            workAuthorizationsData={workAuthorizationsData}
            setAuthorizedCountryIds={setAuthorizedCountryIds}
            setUnauthorizedCountryIds={setUnauthorizedCountryIds}
            getWorkAuthorizationInfo={getWorkAuthorizationInfo}
          />
        ) : null}
        <StyledPageBottom color={getUserColorWithRoleId(CANDIDATE)} />
      </StyledProfileWrapper>
    </>
  );
};

export default CandiateProfile;
