import { CANDIDATE, ORGANIZER, RECRUITER } from '../../constants';
import { RoleIdProps } from '../../interfaces/common/index';
import { COLORS, FILTERS } from '../constants/colors';
import {
  ADMIN_FILTER,
  CANDIDATE_FILTER,
  ORGANIZER_FILTER,
  RECRUITER_FILTER,
} from '../constants/styles';

export const getUserColorWithRoleId = (
  roleId: RoleIdProps,
  isSignUpModal = false,
) => {
  switch (roleId) {
    case CANDIDATE:
      return isSignUpModal ? COLORS.DODGER_BLUE : COLORS.RICH_BLUE;
    case ORGANIZER:
      return COLORS.PUERTO_RICO;
    case RECRUITER:
      return COLORS.SUPER_NOVA;
    default:
      return COLORS.DODGER_BLUE;
  }
};

export const getUserBackgroundColorWithRoleId = (roleId: RoleIdProps) => {
  switch (roleId) {
    case CANDIDATE:
      return COLORS.LIGHT_CANDIDATE_COLOR;
    case ORGANIZER:
      return COLORS.LIGHT_ORGANIZER_COLOR;
    case RECRUITER:
      return COLORS.LIGHT_RECRUITER_COLOR;
    default:
      return COLORS.LIGHT_ADMIN_COLOR;
  }
};

export const getUserSecondaryColorWithRoleId = (roleId: RoleIdProps) => {
  switch (roleId) {
    case CANDIDATE:
      return COLORS.MOODY_BLUE;
    case ORGANIZER:
      return COLORS.SINBAD;
    case RECRUITER:
      return COLORS.SALOMIE;
    default:
      return COLORS.LIGHT_BLUE;
  }
};

export const getUserCircle1ColorWithRoleId = (roleId: RoleIdProps) => {
  switch (roleId) {
    case ORGANIZER:
      return COLORS.CIRCLE_1_ORGANIZER_COLOR;
    default:
      return COLORS.LIGHT_BLUE;
  }
};

export const getUserCircle2ColorWithRoleId = (roleId: RoleIdProps) => {
  switch (roleId) {
    case ORGANIZER:
      return COLORS.CIRCLE_2_ORGANIZER_COLOR;
    default:
      return COLORS.LIGHT_BLUE;
  }
};
export const getUserIconColorWithRoleId = (roleId: RoleIdProps) => {
  switch (roleId) {
    case CANDIDATE:
      return COLORS.WHISPER;

    case ORGANIZER:
      return COLORS.PEARL_AQUA;

    case RECRUITER:
      return COLORS.NAPLES_YELLOW;

    default:
      return '';
  }
};

export const getFilterByRoleId = (roleId: RoleIdProps) => {
  switch (roleId) {
    case CANDIDATE:
      return FILTERS.CANDIDATE;
    case ORGANIZER:
      return FILTERS.ORGANIZER;
    case RECRUITER:
      return FILTERS.RECRUITER;
    default:
      return FILTERS.ADMIN;
  }
};

export const getFilerByUserRole = (roleId: RoleIdProps) => {
  switch (roleId) {
    case CANDIDATE:
      return CANDIDATE_FILTER;
    case ORGANIZER:
      return ORGANIZER_FILTER;
    case RECRUITER:
      return RECRUITER_FILTER;
    default:
      return ADMIN_FILTER;
  }
};
