import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
// import RecoremLogo from '../assets/svg/recorem_color_logo.svg'; //Commented out for beta version.
import RecoremBetaLogo from '../assets/svg/Recorem-Beta.svg';
import { MainWebsiteRoutes } from '../constants/routes';
import { DISPLAY_FLEX, PADDING } from '../styles/constants/display';
import { SHADOWS } from '../styles/constants/shadows';
import { COLORS } from '../styles/constants/colors';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import { FONT } from '../styles/constants/typography';

const Header = (props) => {
  return (
    <StyledHeader>
      <StyledOuterContainer id="header">
        <StyledInnerContainer>
          <Row>
            <StyledLogoWrapper xs={12}>
              <a href={MainWebsiteRoutes.home}>
                <img
                  src={RecoremBetaLogo}
                  alt="logo"
                />
              </a>
            </StyledLogoWrapper>
            <StyledMenuWrapper xs={12}>
              <ul>
                <li>
                  <a href={MainWebsiteRoutes.home}>
                    Home
                  </a>
                </li>
              </ul>
            </StyledMenuWrapper>
          </Row>
        </StyledInnerContainer>
      </StyledOuterContainer>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  ${DISPLAY_FLEX('row', 'center')};
  box-shadow: ${SHADOWS.SHADOW2};
  background-color: ${COLORS.WHITE};
  padding: 0px;
  margin-bottom: 30px;
  position: relative;
`;

const StyledOuterContainer = styled.div`
  position: sticky;
  width: 100%;
  background: ${COLORS.WHITE};
  z-index: 111;
  top: 0;
  right: 0;
  left: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    box-shadow: ${SHADOWS.SHADOW3};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: auto !important;
  }
`;

const StyledInnerContainer = styled.div`
  margin: 0;
  padding: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(15, 20)};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 25px;
  }
`;

const StyledLogoWrapper = styled(Col)`
  ${DISPLAY_FLEX(null, 'flex-start', 'center')};

  a, img {
    max-width: 180px;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 100px;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      max-width: 210px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  img {
    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      display: flex;
    }
  }
`;

const StyledMenuWrapper = styled(Col)`
  ${DISPLAY_FLEX(null, 'flex-end', 'center')};

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      font-size: ${FONT[16]};
      color: ${COLORS.DAVYS_GREY};
      text-align: left;
      letter-spacing: 0;
      opacity: 0.75;
      position: relative;
      max-width: 180px;
      width: 100%;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: ${FONT[14]};
        width: 100px;
      }

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: ${FONT[20]};
        max-width: 210px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
`;
