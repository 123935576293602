import { APIs, ApiType, Method } from '../constants';
import { isValid } from '../utils/api';
import api from './api';
import ERRORS from '../constants/errors';

export const bookInterviewSlot = (body) => {
  return api({
    url: `${APIs.candidate}job/interview/book/`,
    method: Method.post,
    body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchJobResults = (
  eventId,
  page,
  limit,
  applicationStatus = null,
) => {
  return api({
    url: `${APIs.candidate}job-application/?page=${page}&limit=${limit}${
      eventId && eventId !== -1 ? `&eventId=${eventId}` : ''
    }${
      applicationStatus && applicationStatus.length
        ? `&applicationStatus=${applicationStatus.join(',')}`
        : ''
    }`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchInterviewSlotData = (body) => {
  return api({
    url: `${APIs.candidate}event/company/interview/slots/`,
    method: Method.post,
    body: body,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchJobResultsAnalyticsData = (eventId, jobId) => {
  return api({
    url: `${APIs.analytics}event/job/${eventId}/${jobId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchRecruiterAnalytics = (eventId) => {
  return api({
    url: `${APIs.analytics}recruiter/event/${eventId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchRecruiterAdditionalAnalytics = (eventId, jobId) => {
  return api({
    url: `${APIs.analytics}event/job/${eventId}/${jobId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
export const fetchJobResultsOtherAnalyticsData = (jobApplicationId) => {
  return api({
    url: `${APIs.analytics}candidate/job-application/${jobApplicationId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const fetchJobResultsMoreAnalyticsData = (eventId) => {
  return api({
    url: `${APIs.analytics}candidate/opportunities/${eventId}`,
    method: Method.get,
    type: ApiType.analytics,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return response.data.payload;
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
