import React from 'react';
import { Row } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { COLORS } from '../../../../styles/constants/colors';
import PageCircle from '../../../../components/shared-components/PageCircle';
import ResumeBreadCrumbs from '../../../../components/Common/BreadCrumbs/ResumeBreadCrumbs';
import { MARGIN } from '../../../../styles/constants/display';
import ViewResume from './ViewResume';

const ResumePage = (props) => {
  const { jobId, applicationId, eventId } = get(props, 'match.params', null);

  return (
    <>
      <StyledBreadcrumbContainer>
        <PageCircle color={COLORS.SUPER_NOVA} />
        <StyledBreadcrumbRow>
          <ResumeBreadCrumbs
            jobId={jobId}
            appId={applicationId}
            eventId={eventId}
          />
        </StyledBreadcrumbRow>
      </StyledBreadcrumbContainer>
      <ViewResume
        jobId={jobId}
        applicationId={applicationId}
        eventId={eventId}
      />
    </>
  );
};

export default ResumePage;

const StyledBreadcrumbContainer = styled.div`
  box-sizing: content-box;
  position: relative;
`;

const StyledBreadcrumbRow = styled(Row)`
  ${MARGIN(20, 0, 20, 0)};
  color: ${COLORS.SUPER_NOVA};
`;
