export const BREAKPOINTS = {
  MOBILE: '767px',
  TABLET_MIN: '768px',
  TABLET_MAX: '1200px',
  LAPTOP_MIN: '1201px',
  LAPTOP_MAX: '1599px',
  DESKTOP_MIN: '1600px',
  DESKTOP_SPECIAL_CASE_1: '1950px',
  DESKTOP_SPECIAL_CASE_2: '1700px',
  DESKTOP_SPECIAL_CASE_3: '1800px',
  DESKTOP_SPECIAL_CASE_4: '1650px',
  DESKTOP_SPECIAL_CASE_5: '1900px',
  MOBILE_SPECIAL_CASE_1: '525px',
  MOBILE_SPECIAL_CASE_2: '400px',
  MOBILE_SPECIAL_CASE_3: '320px',
  MOBILE_SPECIAL_CASE_4: '430px',
  MOBILE_SPECIAL_CASE_5: '600px',
  MOBILE_SPECIAL_CASE_6: '360px',
  MOBILE_SPECIAL_CASE_7: '480px',
  MOBILE_SPECIAL_CASE_8: '560px',
  MOBILE_SPECIAL_CASE_9: '766px',
  MOBILE_SPECIAL_CASE_10: '575px',
  LAPTOP_SPECIAL_CASE_1: '960px',
  LAPTOP_SPECIAL_CASE_2: '1350px',
  LAPTOP_SPECIAL_CASE_3: '1300px',
  LAPTOP_SPECIAL_CASE_4: '1400px',
  LAPTOP_SPECIAL_CASE_5: '1450px',
  LAPTOP_SPECIAL_CASE_6: '1465px',
  TABLET_SPECIAL_CASE_1: '970px',
  TABLET_SPECIAL_CASE_2: '886px',
  TABLET_SPECIAL_CASE_3: '1100px',
  TABLET_SPECIAL_CASE_4: '900px',
  TABLET_SPECIAL_CASE_5: '880px',
  TABLET_SPECIAL_CASE_6: '980px',
  TABLET_SPECIAL_CASE_7: '1062px',
  TABLET_SPECIAL_CASE_8: '1000px',
  TABLET_MID: '1100px',
};
