import styled from 'styled-components';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

export const StyledMainHeader = styled.h5<any>`
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-bottom: 0;
  padding-left: 22px;
  line-height: 21px;
  position: relative;
  font-size: ${FONT[14]};

  ${({ margin }) => (margin ? `margin: ${margin};` : '')}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }

  &::before {
    background-color: ${(props) => props.color};
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    width: 9px;
    height: 22px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 9px;
      height: 30px;
    }
  }
`;
