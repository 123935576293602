import React from 'react';
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Checkbox,
  AutoComplete,
  DatePicker,
  Radio,
} from 'antd';
import toString from 'lodash/toString';
import PlacesAutocomplete from 'react-places-autocomplete';
import toLower from 'lodash/toLower';
import get from 'lodash/get';
import first from 'lodash/first';
import { validateMessages } from './validateMessages';
import { isDesktop } from '../../helpers/display';
import { SearchIcon } from '../svgicons';
import {
  validateURL,
  passwordCheckPattern,
  phoneNumberCheckPattern,
  WebsiteCheckPattern,
  CountryCodePattern,
  PasswordRuleText,
} from '../../constants';
import Notification from '../Notification/Notification';
import { SmallFont } from '../shared-components/Texts';
import { COLORS } from '../../styles/constants/colors';

const { Option } = Select;
const { TextArea } = Input;

const { RangePicker } = DatePicker;

const rules = {
  text: ({ required, label }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
  ],
  number: ({ required, label }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    // {
    // 	type: 'number'
    // }
  ],
  phoneNumber: ({ required = false, label }) => [
    {
      required,
      pattern: phoneNumberCheckPattern,
      message: 'Please enter a valid phone number.',
    },
  ],
  email: ({ required }) => [
    {
      required,
      // message: 'Please input your Email',
    },
    {
      type: 'email',
    },
  ],
  password: ({ required }) => [
    {
      required,
      pattern: passwordCheckPattern,
    },
  ],
  checkPassword: ({ required }) => [
    {
      required,
      pattern: passwordCheckPattern,
      message: (
        <SmallFont color={COLORS.RED} margin="6px 0px">
          {PasswordRuleText}
        </SmallFont>
      ),
      // message: 'Please input your Password',
    },
    () => ({
      validator(rule, value) {
        if (value.length >= 6) {
          return Promise.resolve();
        }

        // return Promise.reject('Your password must contain at least 8 characters.');
        return Promise.reject('');
      },
    }),
  ],
  confirmPassword: ({ required }) => [
    {
      required,
      pattern: passwordCheckPattern,
      // message: 'Please confirm your password!',
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        // return Promise.reject('The two passwords do not match!');
        return Promise.reject('');
      },
    }),
  ],
  onlyCharaters: ({ required, label }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: new RegExp('^[a-zA-Z ]+$'),
      // message: "Wrong format!"
    },
  ],
  charAndNumber: ({ required, label }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: new RegExp('^[a-zA-Z0-9 ]*$'),
      // message: "Wrong format!"
    },
  ],
  url: ({ required }) => [
    {
      required,
      type: 'url',
    },
  ],

  zipCode: ({ required, label, zipValidator }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      validator: zipValidator,
      // message: "Wrong format!"
    },
  ],
  companyWebsite: ({ required, label }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: WebsiteCheckPattern,
    },
  ],

  countryCode: ({ required, label }) => [
    {
      required,
      // message: `Please input your ${label}`,
    },
    {
      required,
      pattern: CountryCodePattern,
    },
  ],

  customUrl: ({ required }) => [
    {
      required,
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (
          !value ||
          (getFieldValue('companyWebsite') === value && validateURL(value))
        ) {
          return Promise.resolve();
        }
        return Promise.reject('');
      },
    }),
  ],
};

const getUniqueIdForOptions = (id, option, name) => {
  const parameter = get(option, 'name', name || 'option');
  return (id ? `${id}_` : '') + toString(parameter).replace(/ /g, '_');
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const FormGroup = ({
  form = null,
  children,
  onFinish,
  name,
  expand = false,
  initialValues,
  onValuesChange = null,
  id = '',
}) => {
  const onFinishFailed = ({ errorFields }) => {
    errorFields.forEach((error) => {
      const errorFieldName = first(get(error, 'name', []));
      if (!errorFieldName) {
        return;
      }
      const result = errorFieldName.replace(/([A-Z]{1,})/g, ' $1');
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

      Notification(
        'error',
        'Validation Error',
        `Please fill "${finalResult}" field with correct data`,
      );
    });
  };

  return (
    <Form
      form={form}
      name={name}
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={initialValues}
      id={id}
      onValuesChange={onValuesChange}
      {...(expand ? {} : layout)}
      scrollToFirstError
      onFinishFailed={onFinishFailed}
    >
      {children}
    </Form>
  );
};

const InputField = ({
  label = '',
  name = '',
  rules = [],
  dependencies = [],
  hasFeedback = true,
  field,
  fieldKey,
  children,
}) => {
  return (
    <Form.Item
      {...field}
      rules={rules}
      label={label}
      name={name}
      dependencies={dependencies}
      hasFeedback={hasFeedback}
      fieldKey={fieldKey}
    >
      {children}
    </Form.Item>
  );
};

const TextBox = (props) => {
  const {
    required = true,
    label,
    prefix,
    placeholder,
    id,
    disabled = false,
    value,
    maxLength = 255,
    rule = 'onlyCharaters',
  } = props;
  return (
    <InputField
      {...props}
      rules={rules[rule]({ required, label: label || placeholder })}
    >
      <Input
        prefix={prefix}
        id={id}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        size={isDesktop() ? 'large' : 'default'}
      />
    </InputField>
  );
};

const NumberBox = (props) => {
  const {
    required = true,
    label,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    maxChar = 32,
    prefix = '',
    placeholder = '',
    disabled,
    rule = 'number',
    id,
    name = '',
  } = props;
  return (
    <InputField
      {...props}
      rules={rules[rule]({ required, label: label || placeholder })}
    >
      <InputNumber
        name={name}
        title={name}
        size={isDesktop() ? 'large' : 'default'}
        type="number"
        label={label}
        prefix={prefix}
        placeholder={placeholder}
        min={min}
        max={max}
        parser={(value) => value.toString().substring(0, maxChar)}
        disabled={disabled}
        id={id}
      />
    </InputField>
  );
};

const Email = (props) => {
  const {
    required = true,
    id,
    prefix,
    placeholder,
    label,
    disabled = false,
  } = props;
  return (
    <InputField {...props} rules={rules.email({ required })}>
      <Input
        prefix={prefix}
        id={id}
        placeholder={placeholder}
        label={label}
        size={isDesktop() ? 'large' : 'default'}
        disabled={disabled}
      />
    </InputField>
  );
};

const Password = (props) => {
  const {
    required = true,
    prefix,
    placeholder,
    label,
    onFocus,
    onBlur,
  } = props;
  return (
    <InputField {...props} rules={rules.password({ required })}>
      <Input.Password
        size={isDesktop() ? 'large' : 'default'}
        prefix={prefix}
        placeholder={placeholder}
        label={label}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InputField>
  );
};

const CheckPassword = (props) => {
  const {
    required = true,
    prefix,
    placeholder,
    label,
    onFocus,
    onBlur,
  } = props;
  return (
    <InputField {...props} rules={rules.checkPassword({ required })}>
      <Input.Password
        size={isDesktop() ? 'large' : 'default'}
        prefix={prefix}
        placeholder={placeholder}
        label={label}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InputField>
  );
};

const ConfirmPassword = (props) => {
  const { required = true, prefix, placeholder, label } = props;
  return (
    <InputField {...props} rules={rules.confirmPassword({ required })}>
      <Input.Password
        size={isDesktop() ? 'large' : 'default'}
        prefix={prefix}
        placeholder={placeholder}
        label={label}
      />
    </InputField>
  );
};

const Submit = (props) => {
  return (
    <Form.Item style={{ margin: 0 }}>
      <Button type="primary" htmlType="submit" style={{ width: '300px' }}>
        {props.name ? props.name : 'Submit'}
      </Button>
    </Form.Item>
  );
};

/**
 * Checks & returns appropriate value
 * @param {boolean} idValue
 * @param {oject} option
 * @returns {string|number}
 */
const getValue = (idValue, option) => {
  return idValue ? option.id : option.value || option.name;
};

const Dropdown = (props) => {
  const {
    required = false,
    label,
    placeholder,
    mode,
    options = [],
    handleChange,
    onSearch,
    width = '100%',
    allowClear = false,
    dropdownMatchSelectWidth = true,
    showSearch = false,
    idValue = false,
    disabled,
    showArrow = false,
    value,
    id,
    defaultValue,
    name,
  } = props;

  // we might want to filter or search based on more then just value , so usign this logiv we can search will all the parameter
  const filterOptions = (input, option) => {
    return (
      toLower(get(option, 'props.children', '')).indexOf(toLower(input)) >= 0 ||
      toLower(get(option, 'props.value', '')).indexOf(toLower(input)) >= 0 ||
      toLower(get(option, 'props.key', '')).indexOf(toLower(input)) >= 0
    );
  };

  return (
    <InputField
      {...props}
      rules={rules.text({ required, label: label || placeholder })}
    >
      <Select
        showArrow={showArrow}
        allowClear={allowClear}
        style={{ width }}
        mode={mode}
        defaultValue={defaultValue}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        onSearch={onSearch}
        showSearch={showSearch}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        getPopupContainer={(trigger) => trigger.parentNode}
        size={isDesktop() ? 'large' : 'default'}
        disabled={disabled}
        value={value}
        id={id}
        filterOption={filterOptions}
      >
        {options.map((option) => {
          return (
            <Option
              id={getUniqueIdForOptions(id, option, name)}
              key={option.id}
              value={getValue(idValue, option)}
            >
              {option.name}
            </Option>
          );
        })}
      </Select>
    </InputField>
  );
};

const CustomStyleFormGroup = ({
  form = null,
  children,
  onFinish,
  name = 'myForm',
  expand = false,
  initialValues = {},
  style = {},
  className = '',
}) => {
  return (
    <Form
      form={form}
      name={name}
      className={className}
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={initialValues}
      {...(expand ? {} : layout)}
      style={style}
    >
      {children}
    </Form>
  );
};

const CustomStyleSubmit = ({
  name,
  block = false,
  style = {},
  handleClick,
  className = '',
  disabled,
}) => {
  return (
    <Form.Item name="submit" style={{ margin: 0, width: '100%' }}>
      <Button
        type="primary"
        htmlType="submit"
        className={className}
        style={style}
        block={block}
        onClick={handleClick}
        disabled={disabled}
      >
        {name || 'Submit'}
      </Button>
    </Form.Item>
  );
};

const Remember = ({ text = 'Remember Me', style = {} }) => {
  return (
    <Form.Item name="remember" valuePropName="checked" noStyle>
      <Checkbox value={false} style={style}>
        {text}
      </Checkbox>
    </Form.Item>
  );
};

const InputTextArea = (props) => {
  const {
    required = true,
    label,
    prefix,
    placeholder,
    value = '',
    disabled = false,
    handleChange,
    autoSize = {},
    allowClear = false,
    maxLength = 100,
    showCount = true,
    id,
  } = props;
  return (
    <InputField
      {...props}
      rules={rules.text({ required, label: label || placeholder })}
    >
      <TextArea
        prefix={prefix}
        label={label}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        autoSize={autoSize}
        allowClear={allowClear}
        maxLength={maxLength}
        size={isDesktop() ? 'large' : 'default'}
        showCount={showCount}
        id={id}
      />
    </InputField>
  );
};

const InputBox = (props) => {
  const {
    id,
    prefix,
    suffix,
    placeholder,
    disabled = false,
    value,
    handleChange,
    pressEnter,
    allowClear = false,
    required = true,
    min,
    max,
    type = 'text',
    onBlur,
  } = props;
  return (
    <Input
      id={id}
      prefix={prefix}
      suffix={suffix}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onPressEnter={pressEnter}
      allowClear={allowClear}
      min={min}
      max={max}
      type={type}
      size={isDesktop() ? 'large' : 'default'}
      required={required}
      onBlur={onBlur}
    />
  );
};

const SearchInputBox = (props) => {
  const {
    id,
    prefix,
    disabled = false,
    value,
    handleChange,
    allowClear = false,
    placeholder = 'Search Here',
  } = props;
  return (
    <div className="form-group search_input">
      <Input
        id={id}
        prefix={prefix}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        allowClear={allowClear}
        size={isDesktop() ? 'large' : 'default'}
      />
      <SearchIcon />
    </div>
  );
};

const DropdownBox = (props) => {
  const {
    id,
    label,
    placeholder,
    mode,
    options = [],
    handleChange,
    width = '100%',
    allowClear = false,
    dropdownMatchSelectWidth = true,
    showSearch = false,
    value,
    className,
    required = true,
    disabled = false,
    onSearch,
    onSelect = null,
    onDeselect = null,
    name,
  } = props;
  return (
    <Select
      id={id}
      allowClear={allowClear}
      style={{ width }}
      mode={mode}
      label={label}
      placeholder={placeholder}
      onChange={handleChange}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      value={value}
      getPopupContainer={(trigger) => trigger.parentNode}
      size={isDesktop() ? 'large' : 'default'}
      className={className}
      required={required}
      disabled={disabled}
      onSearch={onSearch}
      showSearch={showSearch}
      onSelect={onSelect}
      onDeselect={onDeselect}
    >
      {options.map((option) => {
        return (
          <Option
            id={getUniqueIdForOptions(id, option, name)}
            key={option.id}
            value={option.id}
          >
            {option.name}
          </Option>
        );
      })}
    </Select>
  );
};

const AutoCompleteBox = (props) => {
  const {
    required = true,
    label,
    placeholder,
    options = [],
    handleChange,
    handleSelect,
    text = false,
    defaultValue,
    formField = true,
    onBlur,
  } = props;
  if (formField) {
    return (
      <InputField
        {...props}
        rules={
          text
            ? rules.text({ required, label: label || placeholder })
            : rules.email({ required, label: label || placeholder })
        }
      >
        <AutoComplete
          options={options}
          size={isDesktop() ? 'large' : 'default'}
          onSelect={handleSelect}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </InputField>
    );
  }
  return (
    <AutoComplete
      options={options}
      size={isDesktop() ? 'large' : 'default'}
      onSelect={handleSelect}
      onChange={handleChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onBlur={onBlur}
    />
  );
};

const AddressAutoCompleteBox = (props) => {
  const {
    required = true,
    label,
    placeholder,
    value,
    handleChange,
    disabled,
    handleAutoFill,
    zipValidator,
    onError,
  } = props;
  return (
    <InputField
      {...props}
      rules={rules.zipCode({
        required,
        label: label || placeholder,
        zipValidator,
      })}
    >
      <PlacesAutocomplete
        value={value}
        shouldFetchSuggestions={Boolean(value) && value >= 3}
        debounce={300}
        onChange={handleChange}
        size={isDesktop() ? 'large' : 'default'}
        searchOptions={{ types: ['(regions)'] }}
        disabled={disabled}
        onError={onError}
      >
        {({ getInputProps, suggestions }) => {
          return (
            <div>
              <Input
                {...getInputProps()}
                className="px-2 w-100"
                placeholder={placeholder}
                size={isDesktop() ? 'large' : 'default'}
                disabled={disabled}
              />
              {handleAutoFill(first(suggestions))}
            </div>
          );
        }}
      </PlacesAutocomplete>
    </InputField>
  );
};

const DateTimePicker = (props) => {
  const {
    required = true,
    ranges,
    format,
    showTime,
    onChange,
    disabledDate,
    disabledTime,
  } = props;
  return (
    <InputField {...props} rules={rules.text({ required })}>
      <RangePicker
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        size={isDesktop() ? 'large' : 'default'}
        ranges={ranges}
        showTime={showTime}
        format={format}
        onChange={onChange}
      />
    </InputField>
  );
};

const RadioButtonInput = (props) => {
  const { options, onTypeChange, type } = props;
  return (
    <Radio.Group
      size={isDesktop() ? 'large' : 'default'}
      onChange={(e) => onTypeChange(e.target.value)}
      value={type}
    >
      {options.map((option, i) => (
        <Radio.Button key={i} value={option}>
          {option}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export {
  Email,
  Password,
  CheckPassword,
  ConfirmPassword,
  Submit,
  TextBox,
  NumberBox,
  FormGroup,
  Dropdown,
  CustomStyleFormGroup,
  CustomStyleSubmit,
  Remember,
  InputField,
  InputTextArea,
  InputBox,
  DropdownBox,
  AutoCompleteBox,
  AddressAutoCompleteBox,
  DateTimePicker,
  SearchInputBox,
  RadioButtonInput,
};
