import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loading: React.FC<any> = ({ color }) => {
  return (
    <StyledLoading color={color}>
      <div />
      <div />
      <div />
    </StyledLoading>
  );
};

export default Loading;

const BounceAnimation = keyframes`
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
  `;

const StyledLoading = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  padding-top: 20px;

  div {
    width: 1rem;
    height: 1rem;
    margin: 0 0.3rem;
    background: ${({ color }) => color || 'black'};
    border-radius: 50%;
    margin-top: -5px;
    animation: 0.9s ${BounceAnimation} infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
`;
