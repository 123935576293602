import { checkIfUserIsRecruiter } from './userInfo';
import { NotificationsType } from '../constants/notificationsType';
import {
  CANDIDATE,
  RECRUITER,
  ORGANIZER,
  CANDIDATE_COLOR,
  RECRUITER_COLOR,
  ORGANIZER_COLOR,
} from '../constants';

export const getColorName = (type?: number, roleId?: number) => {
  if (type === NotificationsType.Sales) {
    return 'orange';
  }
  if (checkIfUserIsRecruiter(roleId) && type === NotificationsType.Jobs) {
    return 'orange';
  }
  if (type === NotificationsType.Team || type === NotificationsType.Jobs) {
    return 'violet';
  }
  if (type === NotificationsType.Chat) {
    return 'blue';
  }
  return 'red';
};

export const getColorStringByRoleId = (roleId?: number) => {
  switch(roleId) {
    case ORGANIZER:
      return ORGANIZER_COLOR;
    case RECRUITER:
      return RECRUITER_COLOR
    case CANDIDATE:
      return CANDIDATE_COLOR;
    default:
      return '';
  }
};
