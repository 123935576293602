import { createSlice } from '@reduxjs/toolkit';
import {
  getOrganizerEvents,
  createEvent,
  editEvent,
  cancelEvent,
  getEventRecruiter,
  getEventCandidate,
  updateEventRecruiterAttendance,
  updateEventCandidateAttendance,
} from '../../service/organizer';
import Messages from '../../constants/message';
import {
  getOrganizerRecruiterAnalytics,
  getOrganizerCandidateAnalytics,
} from '../../service/analytics';
import {
  bookEvent,
  getEventById,
  getPublicEventById,
  getEvents,
  bookEventWithInviteCode,
  getSimilarEvents,
} from '../../service/event';
import {
  getOnGoingEvents,
  getPastEvents,
  getUpComingEvents,
  sortEventsWithDateTime,
  getCancelledEvents,
} from '../../utils/event';
import { getRecruiterBookedEvents } from '../../service/recruiter';
import { getCandidateBookedEvents } from '../../service/candidate';
import { STATUS } from '../../constants';
import Notification from '../../components/Notification/Notification';
import { eventDataRegardingChatroom } from '../../utils/chat';
import { editEventAdmin } from '../../service/masterAdmin';

const { IDLE, RESOLVED, PENDING, REJECTED } = STATUS;

const onStart = (state) => {
  state.status = PENDING;
  state.isLoading = true;
};

const onSuccess = (state, action) => {
  state.status = RESOLVED;
  state.isLoading = false;
  state.error = null;
  state.onGoingEvents = getOnGoingEvents(action.payload);
  state.upComingEvents = getUpComingEvents(action.payload);
  state.pastEvents = getPastEvents(action.payload);
  state.cancelledEvents = getCancelledEvents(action.payload);
};

const OnSuccessEventRecruiters = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.eventRecruiter = action.payload;
};

const onSuccessEventRecruitersPagination = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.eventRecruiterPagination = action.payload;
};

const OnSuccessEventCandidates = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.eventCandidate = action.payload;
};

const onSuccessEventCandidatesPagination = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.eventCandidatePagination = action.payload;
};

const onSuccessAnalyticsRecruiter = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.analyticsRecruiter = action.payload;
};

const onSuccessAnalyticsCandidate = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.analyticsCandidate = action.payload;
};

const onSuccessEvent = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.event = action.payload;
};

const onSuccessSimilarEvent = (state, action) => {
  state.isSimilarEventsLoading = false;
  state.similarEventsError = null;
  state.similarEvents = action.payload;
};

const onSimilarEventsFetchStart = (state) => {
  state.status = PENDING;
  state.isSimilarEventsLoading = true;
  state.similarEventsError = null;
};

const onSimilarEventsFetchError = (state, action) => {
  state.status = REJECTED;
  state.isSimilarEventsLoading = false;
  state.similarEventsError = action.payload;
};

const onError = (state, action) => {
  state.status = REJECTED;
  state.isLoading = false;
  state.error = action.payload;
};

const onEnd = (state) => {
  state.status = IDLE;
  state.isLoading = false;
};

const onOpenSwiftEventModal = (state) => {
  state.openSwiftEventModal = true;
};

const onCloseSwiftEventModal = (state) => {
  state.openSwiftEventModal = false;
};

const onOpenSpeakerAndHostModel = (state) => {
  state.disableCreateEventButton = false;
  state.openSpeakerAndHostModel = true;
};

const onCloseSpeakerAndHostModel = (state) => {
  state.openSpeakerAndHostModel = false;
  state.openSpeakerAndHostTab = true;
};

const onEnableCreateEventButton = (state) => {
  state.disableCreateEventButton = false;
};

const onDisableCreateEventButton = (state) => {
  state.disableCreateEventButton = true;
};

export const eventSlice = createSlice({
  name: 'events',
  initialState: {
    status: IDLE,
    isLoading: false,
    onGoingEvents: [],
    upComingEvents: [],
    pastEvents: [],
    cancelledEvents: [],
    event: {},
    eventRecruiter: [],
    eventCandidate: [],
    analyticsRecruiter: null,
    analyticsCandidate: null,
    error: null,
    eventRecruiterPagination: null,
    eventCandidatePagination: null,
    similarEvents: [],
    isSimilarEventsLoading: false,
    similarEventsError: null,
    openSwiftEventModal: false,
    openSpeakerAndHostModel: false,
    openSpeakerAndHostTab: false,
    disableCreateEventButton: false,
  },
  reducers: {
    onStart,
    onSuccess,
    onSuccessEvent,
    OnSuccessEventRecruiters,
    OnSuccessEventCandidates,
    onSuccessAnalyticsRecruiter,
    onSuccessAnalyticsCandidate,
    onError,
    onEnd,
    onSuccessEventRecruitersPagination,
    onSuccessEventCandidatesPagination,
    onSuccessSimilarEvent,
    onSimilarEventsFetchStart,
    onSimilarEventsFetchError,
    onOpenSwiftEventModal,
    onCloseSwiftEventModal,
    onOpenSpeakerAndHostModel,
    onCloseSpeakerAndHostModel,
    onEnableCreateEventButton,
    onDisableCreateEventButton,
  },
});

const getOrganizerEventsData = () => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const events = await getOrganizerEvents();
    dispatch(eventSlice.actions.onSuccess(sortEventsWithDateTime(events)));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getOrganizerEventsDataForSwiftEvent = () => async (dispatch) => {
  try {
    const events = await getOrganizerEvents();
    dispatch(eventSlice.actions.onSuccess(sortEventsWithDateTime(events)));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const createSwiftEventData = (data, callback, fetchData = true) => async (
  dispatch,
) => {
  try {
    dispatch(eventSlice.actions.onDisableCreateEventButton());
    const event = await createEvent(data);
    // Invoke callback with args to confirm request success
    callback(null, event.id);
    if (fetchData) {
      dispatch(getOrganizerEventsDataForSwiftEvent());
    }
    dispatch(eventSlice.actions.onCloseSwiftEventModal());
    dispatch(eventSlice.actions.onOpenSpeakerAndHostModel());
    // Returning back data for chatroom
    return eventDataRegardingChatroom(event);
  } catch (err) {
    dispatch(eventSlice.actions.onEnableCreateEventButton());
    // Invoke callback with error arg to confirm request failed
    callback(err);
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const createEventData = (data, callback, fetchData = true) => async (
  dispatch,
) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const event = await createEvent(data);
    // Invoke callback with args to confirm request success
    callback(null, event.id);
    if (fetchData) {
      dispatch(getOrganizerEventsData());
    }
    dispatch(eventSlice.actions.onOpenSpeakerAndHostModel());
    dispatch(eventSlice.actions.onEnd());
    // Returning back data for chatroom
    return eventDataRegardingChatroom(event);
  } catch (err) {
    // Invoke callback with error arg to confirm request failed
    callback(err);
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const editEventData = (id, data, callback, fetchData = true) => async (
  dispatch,
) => {
  try {
    dispatch(eventSlice.actions.onStart());
    await editEvent(id, data);
    callback(null, id);
    if (fetchData) {
      dispatch(getOrganizerEventsData());
    }
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    callback(err);
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

export const editEventDataAdmin = (id, data, callback) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    await editEventAdmin(id, data);
    callback(null, id);
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    callback(err);
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const cancelEventData = (id, data, callback) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    await cancelEvent(id, data);
    callback(null);
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    callback(err);
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getEventByIdData = (id) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const event = await getEventById(id);
    dispatch(eventSlice.actions.onSuccessEvent(event));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getEventsData = () => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const events = await getEvents();
    dispatch(eventSlice.actions.onSuccess(sortEventsWithDateTime(events)));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getRecruiterBookedEventsData = () => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const events = await getRecruiterBookedEvents();
    dispatch(eventSlice.actions.onSuccess(sortEventsWithDateTime(events)));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getCandidateBookedEventsData = () => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const events = await getCandidateBookedEvents();
    dispatch(eventSlice.actions.onSuccess(sortEventsWithDateTime(events)));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const bookEventData = (body, id, isFree) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    await bookEvent(body).then((data) => {
      if (isFree) {
        Notification('success', Messages.SUCCESS, Messages.SUCCESS_EVENT);
        return data;
      }
    });
    dispatch(getEventByIdData(id));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onEnd());
  }
};

export const bookEventWithInviteCodeData = (body, id) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const data = await bookEventWithInviteCode(body);
    dispatch(getEventByIdData(id));
    dispatch(eventSlice.actions.onEnd());
    if (data && data.payload && !data.payload.isBooked) {
      Notification('success', Messages.SUCCESS, Messages.SUCCESS_EVENT);
    }
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getEventRecruitersData = (id, page, limit) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const { recruiters, currentPage, totalItems } = await getEventRecruiter(
      id,
      page,
      limit,
    );
    dispatch(eventSlice.actions.OnSuccessEventRecruiters(recruiters));
    dispatch(
      eventSlice.actions.onSuccessEventRecruitersPagination({
        currentPage,
        totalItems,
      }),
    );
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getPublicEventByIdData = (id) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const event = await getPublicEventById(id);
    dispatch(eventSlice.actions.onSuccessEvent(event));
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getEventCandidatesData = (id, page, limit) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const { candidates, currentPage, totalItems } = await getEventCandidate(
      id,
      page,
      limit,
    );
    dispatch(eventSlice.actions.OnSuccessEventCandidates(candidates));
    dispatch(
      eventSlice.actions.onSuccessEventCandidatesPagination({
        currentPage,
        totalItems,
      }),
    );
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getEventAnalyticsRecruiterData = (id) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const analytics = await getOrganizerRecruiterAnalytics(id);
    dispatch(eventSlice.actions.onSuccessAnalyticsRecruiter(analytics));
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const getEventAnalyticsCandidateData = (id) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onStart());
    const analytics = await getOrganizerCandidateAnalytics(id);
    dispatch(eventSlice.actions.onSuccessAnalyticsCandidate(analytics));
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const updateEventRecruiterAttendanceData = (ids, body, eventId) => async (
  dispatch,
) => {
  try {
    dispatch(eventSlice.actions.onStart());
    await updateEventRecruiterAttendance(ids, body, eventId, false);
    dispatch(getEventAnalyticsRecruiterData(eventId));
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

const updateEventCandidateAttendanceData = (ids, body, eventId) => async (
  dispatch,
) => {
  try {
    dispatch(eventSlice.actions.onStart());
    await updateEventCandidateAttendance(ids, body, eventId, false);
    dispatch(getEventAnalyticsCandidateData(eventId));
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

export const getSimilarEventsData = (page, limit) => async (dispatch) => {
  try {
    dispatch(eventSlice.actions.onSimilarEventsFetchStart());
    const events = await getSimilarEvents(page, limit);
    dispatch(eventSlice.actions.onSuccessSimilarEvent(events));
    dispatch(eventSlice.actions.onEnd());
  } catch (err) {
    dispatch(eventSlice.actions.onSimilarEventsFetchError(err.toString()));
  }
};

export const getEventByIdDataWithoutReload = (id) => async (dispatch) => {
  try {
    const event = await getEventById(id);
    dispatch(eventSlice.actions.onSuccessEvent(event));
  } catch (err) {
    dispatch(eventSlice.actions.onError(err.toString()));
  }
};

export default eventSlice.reducer;

export {
  getOrganizerEventsData,
  getOrganizerEventsDataForSwiftEvent,
  createSwiftEventData,
  createEventData,
  editEventData,
  cancelEventData,
  getEventByIdData,
  getRecruiterBookedEventsData,
  getEventsData,
  getCandidateBookedEventsData,
  bookEventData,
  getEventRecruitersData,
  getEventCandidatesData,
  updateEventRecruiterAttendanceData,
  updateEventCandidateAttendanceData,
  getEventAnalyticsRecruiterData,
  getEventAnalyticsCandidateData,
  getPublicEventByIdData,
};
