import React, { useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import get from 'lodash/get';
import '../../styles/components/CrewManagement/crew-member-profile-modal.scss';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CountryCodeInput from '../Common/CountryCodeInput';
import { getThemeByRoleId, imageValidator } from '../../utils/common';
import { IMAGE_PROFILE_PAYLOAD } from '../../constants/file';
import { TextBox, Email } from '../shared-components/Form/Form';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import { checkIfUserIsMasterAdmin, checkIfUserIsOrganizer, getRoleId } from '../../utils/userInfo';
import {
  userProfileStore,
  countryCodeOnlySplit,
  updateMemberProfilePic,
} from '../../utils/profile';
import {
  updateMemberProfileData,
  updateMemberProfileAdminData,
  getProfileDataWithoutReload,
} from '../../store/features/profile';
import { userIsCrewMember } from '../../utils/crew';
import { STATUS_ACTIVE } from '../../constants';
import ModalButtonContainer from '../profile/ModalButtonContainer';
import { getUserColorWithRoleId } from '../../styles/utils';
import { ActiveText } from './common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import {
  getOrganizerCrewMembersData,
  getRecruiterCrewMembersData,
} from '../../store/features/crew';
import { Modal } from '../shared-components/Modal';
import { useHistory } from 'react-router-dom';
import { OrganizerRoutes, RecruiterRoutes } from '../../constants/routes';
import { checkIfUserExist } from '../../service/auth';
import { ModalTitle, PasswordButton, TitleContainer } from '../shared-components/RepresentativeModal';

const CrewMemberEditProfileModal = (props) => {
  const { handleModalCancel, data, profilePic, setProfilePic, visible } = props;
  const [isPasswordSetOrNot, setIsPasswordSetOrNot] = useState(true);
  const [isLoding, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { profileData } = userProfileStore();

  const isCrewMember = userIsCrewMember(profileData);
  const roleId = getRoleId();
  const history = useHistory();

  const handleResetPassword = async () => {
    history.push(
      checkIfUserIsOrganizer(roleId)
        ? OrganizerRoutes.changePassword
        : RecruiterRoutes.changePassword,
    );
  };

  const handleFinish = (formData) => {
    const body = {
      name: formData.fullName,
      phone: `${countryCodeOnlySplit(formData.phoneNumberPrefix)} ${formData.phoneNumber
        }`,
      memberId: data.userId,
      email: formData.email,
    };

    dispatch(
      isCrewMember
        ? updateMemberProfileData(body)
        : updateMemberProfileAdminData(body),
    ).then(() => {
      // Fetch after profile gets updated
      if (checkIfUserIsOrganizer(roleId)) {
        dispatch(getOrganizerCrewMembersData());
      } else {
        dispatch(getRecruiterCrewMembersData());
      }
    });

    handleModalCancel();
  };

  const ProfilePicCustomRequest = ({ onSuccess, onError, file }) => {
    imageValidator({ ...IMAGE_PROFILE_PAYLOAD, file }).then(() => {
      updateMemberProfilePic(file, data.userId)
        .then((fileName) => {
          checkIfUserIsOrganizer(roleId)
            ? dispatch(getOrganizerCrewMembersData())
            : dispatch(getRecruiterCrewMembersData());
          dispatch(getProfileDataWithoutReload());
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    });
  };

  const phone = get(data, 'phone', get(data, 'representativePhone', null));
  const email = get(data, 'email', get(profileData, 'representativeEmail', ''));

  const initialValue = {
    fullName: get(data, 'name', ''),
    phoneNumberPrefix: phone ? phone.split(' ')[0] : '',
    phoneNumber: phone ? phone.split(' ')[1] : '',
    email,
  };

  useEffect(() => {
    if (profileData.email) {
      checkIfUserExist(profileData.email, false, roleId).then((response) => {
        // @ts-ignore
        const { isPasswordSetOrNot } = response;
        setIsPasswordSetOrNot(isPasswordSetOrNot);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [profileData.email]);

  return (
    <Modal
      visible={visible}
      onCancel={handleModalCancel}
      footer={null}
      width="70%"
      title="Edit Profile"
      theme={getThemeByRoleId(roleId)}
    >
      <Form initialValues={initialValue} onFinish={handleFinish}>
        <div>
          <TitleContainer>
            <ModalTitle color={getUserColorWithRoleId(roleId)}>
              Basic Information
            </ModalTitle>
            {!checkIfUserIsMasterAdmin(roleId) && isCrewMember ? (
              <PasswordButton>
                <Button
                  onClick={handleResetPassword}
                  className="change-password__button"
                >
                  {isLoding ? (
                    <Spin />
                  ) : (
                    `${isPasswordSetOrNot ? 'Change' : 'Create'} Password`
                  )}
                </Button>
              </PasswordButton>
            ) : null}
          </TitleContainer>

          <Wrapper>
            <Container>
              <div>
                <h2>Full Name</h2>
                <div className="name">
                  <TextBox
                    name="fullName"
                    placeholder="Full Name"
                    rule="text"
                    maxLength={100}
                  />
                </div>
              </div>

              <Phone>
                <h2>Phone Number</h2>
                <div>
                  <CountryCodeInput name="phoneNumberPrefix" />

                  <TextBox
                    name="phoneNumber"
                    placeholder="Phone Number"
                    rule="phoneNumber"
                    maxLength={12}
                    required={false}
                  />
                </div>
              </Phone>

              <div className="email">
                <h2>Email Address</h2>
                <Email
                  name="email"
                  placeholder="Email"
                  disabled={!!isCrewMember}
                />
              </div>
            </Container>

            <Picture>
              <UploadProfilePicComponent
                changePictureText
                option
                profilePic={profilePic}
                setProfilePic={setProfilePic}
                ProfilePicCustomRequest={ProfilePicCustomRequest}
                roleId={roleId}
              />
            </Picture>
          </Wrapper>

          <div>
            <h1>Status</h1>
            <div>
              {get(data, 'status') === STATUS_ACTIVE ? (
                <ActiveText>Active</ActiveText>
              ) : (
                ''
              )}
            </div>
          </div>
          <ModalButtonContainer
            handleResetFields={handleModalCancel}
            resetCancleText="Cancel"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CrewMemberEditProfileModal;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MIN}) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) {
    justify-items: center;
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP_MIN}) {
    grid-template-columns: 1fr;
  }
`;

const Phone = styled.div`
  div {
    display: flex;

    div {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
`;

const Picture = styled.div`
  .ant-col-md-6 {
    max-width: fit-content;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-bottom: 50px;
    grid-row: 1;
  }

  justify-self: center;

  span {
    white-space: nowrap;
  }
`;
