import { Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ShareButton } from '../../components/shared-components/ShareEvent';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import {
  DISPLAY_FLEX,
  getTextOverflowStyle,
  SHARP_IMAGE,
} from '../../styles/constants/display';
import { getFileS3 } from '../../utils/s3.storage';

const NFTCard = ({ poap, i }) => {
  const [isModalOpen, setIsModelOpen] = useState(false);
  return (
    <StyledCardsWrapper key={i.toString()}>
      <StyledImage src={poap.imageUrl} alt="poap" />
      <StyledCardTitle>
        Library #{poap.token}{' '}
        <ShareButton url={`https://app.poap.xyz/token/${poap.token}`} />
      </StyledCardTitle>

      <StyledCustomRow>
        <StyledCompanyLogo
          src={
            poap.organizerLogo ? getFileS3(poap.organizerLogo) : poap.imageUrl
          }
        />
        <StyledContent>
          <StyledEventName>{poap.eventName || 'Event'}</StyledEventName>
          <StyledOrganizer>{poap.organizer || '@Event'}</StyledOrganizer>
        </StyledContent>
      </StyledCustomRow>

      <StyledViewImageButton onClick={() => setIsModelOpen(true)}>
        View Artwork
      </StyledViewImageButton>
      <StyledModel
        visible={isModalOpen}
        onCancel={() => setIsModelOpen(false)}
        footer={null}
        centered
      >
        <StyledModelImage src={poap.imageUrl} alt="poap" />
      </StyledModel>
    </StyledCardsWrapper>
  );
};

export default NFTCard;

const StyledCardsWrapper = styled.div`
  width: 100%;
  padding: 10px 15px;

  background: #ffffff;
  border: 1.5px solid #9b20d0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

const StyledImage = styled.img`
  width: 35%;
  margin: 0px auto 10px;
  display: block;
  border-radius: 100%;
  ${SHARP_IMAGE};
`;

const StyledCardTitle = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #000000;
  margin-top: 15px;
  ${DISPLAY_FLEX('row', 'center', 'center')}

  svg {
    width: 14.5px;
    margin-left: 6px;
    cursor: pointer;
    fill: ${COLORS.candidateColor};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 17px;
  }
`;

const StyledCustomRow = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  margin: 22px 0;
`;

const StyledCompanyLogo = styled.img`
  width: 45px;
  height: 45px;
  background-color: lightgray;
  border-radius: 100%;
  ${SHARP_IMAGE};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 50px;
    height: 50px;
  }
`;

const StyledContent = styled.div`
  width: calc(100% - 55px);
  margin-left: 10px;
`;

const StyledEventName = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  color: #000000;
  ${getTextOverflowStyle(1)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }
`;

const StyledOrganizer = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #999999;
  ${getTextOverflowStyle(1)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 14px;
  }
`;

const StyledViewImageButton = styled.button`
  background: linear-gradient(90deg, #b933d0 0%, #5b19c0 100%);
  width: 100%;
  min-height: 42px;
  margin-top: 10px;
  border: none;
  color: white;
  cursor: pointer;
  display: block;
`;

const StyledModel = styled(Modal)`
  .ant-modal-content {
    border-radius: 30px;
    height: 700px;
    display: flex;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: unset;
    }
  }

  &.ant-modal {
    width: 700px !important;
    height: 700px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: unset !important;
    }
  }

  .ant-modal-body {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-close-x {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      display: block !important;
    }
  }
`;

const StyledModelImage = styled.img`
  ${SHARP_IMAGE};
  width: 90%;
`;
