import React from 'react';
import styled from 'styled-components';
import { Row, Col, Form } from 'antd';
import get from 'lodash/get';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import { FormGroup } from '../../../shared-components/Form/Form';
import ProfilesLinksList from '../../../ProfileLinks/ProfileLinksList';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';

const ProfileLinkContainer = ({
  type,
  company,
  index,
  handleLinkChangeCallBack,
}) => {
  const linkFrom = `${type}Link`;
  const [form] = Form.useForm();

  return (
    <StyedRow>
      <Col xs={24} lg={4} />
      <StyledCol xs={24} lg={19}>
        <FormGroup
          form={form}
          name="sponsorsPartners"
          expand
          initialValues={{
            [linkFrom]: get(company, 'links', []).map((link) => ({
              link,
            })),
          }}
        >
          <ProfilesLinksList
            profileLinkText="Profile Link"
            form={form}
            name={linkFrom}
            isSponsors
            rule="customUrlCheck"
            onChangeCallback={() => {
              handleLinkChangeCallBack(form, linkFrom, index);
            }}
            canNotBeEmpty
          />
        </FormGroup>
      </StyledCol>
    </StyedRow>
  );
};

export default ProfileLinkContainer;

const StyedRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 20px;
  row-gap: 10px !important;
`;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 20px;
  }
`;
