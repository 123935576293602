/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Icon, { CheckCircleFilled, DeleteFilled } from '@ant-design/icons';
import { Col, Form, Row, Switch, Tooltip } from 'antd';
import { get, lowerCase, uniq, isArray } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ImageGalleryIcon from '../../../../assets/svg/image-gallery.svg';
import AddressAutoComplete from '../../../../components/Common/AddressAutoComplete';
import CategoryDropdown from '../../../../components/Common/CategoryDropdown';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import QuillEditor from '../../../../components/Common/QuillEditor';
import { createEventNotification } from '../../../../components/Notification/Event';
import { UploadDocumentNotification } from '../../../../components/Notification/UploadPictureNotification';
import { PrimaryButton } from '../../../../components/shared-components/Button';
import { HiddenMobile } from '../../../../components/shared-components/DocumentViewerModal';
import {
  DateTimePicker,
  Dropdown,
  FormGroup,
  TextBox,
} from '../../../../components/shared-components/Form/Form';
import { StyledModal } from '../../../../components/shared-components/Modal';
import { ResponsiveModalHeader } from '../../../../components/shared-components/ResponsiveModalHeader';
import config from '../../../../config';
import {
  CHATROOM_TYPE_GROUP,
  EVENT_CROP_COVER_OPTIONS,
} from '../../../../constants';
import {
  ACCEPTED_EVENT_COVER_IMAGE_FULL_HD,
  ACCEPTED_IMAGE_EXTENSIONS,
  EVENT_COVER_IMAGE_HINT,
  EVENT_IMAGE_COVER_PAYLOAD,
} from '../../../../constants/file';
import { OrganizerRoutes } from '../../../../constants/routes';
import { eventTypes } from '../../../../mocks/organizer/events/create-event';
import { uploadFile } from '../../../../service';
import { createChatroom } from '../../../../service/chat';
import { getEventsAutoFillData } from '../../../../service/organizer';
import { createSwiftEventData } from '../../../../store/features/events';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import {
  DISPLAY,
  DISPLAY_FLEX,
  MARGIN,
} from '../../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import {
  getUserBackgroundColorWithRoleId,
  getUserColorWithRoleId,
} from '../../../../styles/utils';
import { userInfoRegardingChatroom } from '../../../../utils/chat';
import {
  getCategoryOptions,
  getQuerySkillsData,
  getTextFromHTML,
  imageValidator,
} from '../../../../utils/common';
import { useTagManager } from '../../../../utils/customHooks';
import {
  findAndRemoveFile,
  makeCreateEventData,
  makeCreateEventInitialData,
} from '../../../../utils/event';
import { currentDate } from '../../../../utils/formatDateTime';
import { getFileS3 } from '../../../../utils/s3.storage';
import { getRoleId, getUserClassWithRoleId } from '../../../../utils/userInfo';
import PricingAndPromoContainer from './PricingAndPromoContainer';
import TermsAndPolicy from './TermsAndPolicy';
import { PictureUpload } from './UploadImage';
import Notification from '../../../../components/Notification/Notification';
import { privateEventText } from '../../../../constants/texts/organizer';
import { ToolTippedInfo } from '../../../../components/shared-components/Upload';

const SwiftEvent = ({
  onCancel,
  isForTest = false,
  handleSelectType = null,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const screenDimension = get(window, 'screen.width', null);
  const modalDimension =
    screenDimension <= +BREAKPOINTS.TABLET_MAX.slice(0, -2) ? '90%' : '50%';
  const desktopScreen = +BREAKPOINTS.DESKTOP_SPECIAL_CASE_4.slice(0, -2);

  const { openSwiftEventModal, disableCreateEventButton } = useSelector(
    (state) => state.events,
  );

  const [coverFile, setCoverFile] = useState([]);
  const [customInput, setCustomInput] = useState({
    cover: '',
    termsAndConditions: [],
  });
  const [documentFileList, setDocumentFileList] = React.useState([]);

  // form data
  const [eventType, setEventType] = useState(eventTypes[0].value);
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState('');
  const [descriptionCount, setDescriptionCount] = React.useState(0);
  const [isActive, setIsActive] = useState(true);
  const [errorFields, setErrorFields] = useState([]);
  const [querySkills, setQuerySkills] = React.useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [autoFillData, setAutoFillData] = useState({});
  const [imagePreview, setImagePreview] = useState('');
  const [date, setDate] = useState('');
  const [dateTime, setDateTime] = useState([]);
  const [categoryOptions, setCategoriesOptions] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCoverRemoved, setIsCoverRemoved] = useState(false);
  const [errorCoverImage, setErrorCoverImage] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const roleId = getRoleId();
  const color = getUserColorWithRoleId(roleId);
  const updateUserActionInGTM = useTagManager();
  const userData = userInfoRegardingChatroom();

  const imageGallery = () => (
    <img alt="gallery icon" src={ImageGalleryIcon} width={50} height={50} />
  );

  const handleEventTypeChange = (value) => {
    setEventType(value);
  };

  const handleSelectCategories = (options) => {
    setCategories(options);
  };

  const handleDescription = (value, delta, source, editor) => {
    const text = getTextFromHTML(value);
    const length = editor.getLength() - 1;
    setTimeout(() => setDescriptionCount(length));
    if (length <= 1000) {
      setDescription(value);
    } else {
      setDescription(text.slice(0, 1000));
    }
  };

  const handleCoverBeforeUpload = (file) => {
    if (file.size > ACCEPTED_EVENT_COVER_IMAGE_FULL_HD.maxFileSize) {
      Notification(
        'error',
        'Cover image',
        'Image doesn’t match the requirements. Please select another image.',
      );
      return false;
    }
    return imageValidator({
      ...EVENT_IMAGE_COVER_PAYLOAD,
      file,
      setErrorCoverImage,
    });
  };

  /**
   * Uploads file
   * @param {obj} object
   * @param {str} type, expects type to compare upload file type
   * @param {str} id, expects id to change company logo
   */
  const uploadEventFile = async ({ onSuccess, onError, file }, type, id) => {
    if (type === 'cover') {
      setCoverFile([file]);
      uploadFile(file, 'image')
        .then((filename) => {
          setCustomInput((currentState) => ({
            ...currentState,
            cover: filename,
          }));
          onSuccess();
        })
        .catch(() => onError());
    } else {
      uploadFile(file)
        .then((filename) => {
          setCustomInput((currentState) => {
            return {
              ...currentState,
              termsAndConditions: [filename],
            };
          });
          setDocumentFileList((prevList) => {
            return [...prevList, file];
          });
          setDocumentError(false);
          onSuccess();
        })
        .catch(() => onError());
    }
  };

  const handleFileChange = (info, type) => {
    const { file } = info;
    if (type === 'cover' && !errorCoverImage) {
      // Set cover file
      setCoverFile([file]);
      setImagePreview(file.thumbUrl);

      // Remove the event cover
      if (type === 'cover' && file.status === 'removed') {
        setCoverFile([]);
        setCustomInput((currentState) => ({ ...currentState, cover: '' }));
      }
    } else {
      UploadDocumentNotification(info);
    }
    setErrorCoverImage(false);
  };

  // Terms and Policy
  const handleRemoveDocument = (data) => {
    const clone = findAndRemoveFile(
      customInput.termsAndConditions,
      data.lastModified,
    );
    setCustomInput((currentState) => {
      return {
        ...currentState,
        termsAndConditions: clone,
      };
    });
    const updatedList = findAndRemoveFile(documentFileList, data.uid);
    setDocumentFileList(updatedList);
  };

  // tags
  const handleQueryTagSkills = async (words) => {
    if (words.length > 1) {
      const skills = await getQuerySkillsData(words);
      // Set state only if there's result
      if (skills.length) {
        setQuerySkills(skills);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const handleNotification = (error, id) => {
    if (error) {
      return createEventNotification({
        name: form.getFieldValue('nameValue'),
        status: 'error',
        error,
      });
    }
  };

  const validateInputs = (inputName) => {
    const modalHeight = document.querySelector('div.ant-modal-body')
      .scrollHeight;
    switch (inputName) {
      case 'cover':
      case 'nameValue':
      case 'dateTime':
      case 'eventType':
      case 'categories':
        document.querySelector('div.ant-modal-body').scrollTop = 0;
        break;
      default:
        document.querySelector('div.ant-modal-body').scrollTop = modalHeight;
    }
  };

  const onFailedFinish = (errors) => {
    // eslint-disable-next-line no-shadow
    const errorFields = errors.map((error) => get(error, 'name[0]', ''));
    setErrorFields(errorFields);
    validateInputs(errorFields[0]);
  };

  // form submit
  const handleEventSubmit = async () => {
    const formFieldsData = await form.getFieldsValue();
    const { cover, termsAndConditions } = customInput;

    if (!cover) {
      validateInputs('cover');
      return Notification(
        'error',
        'Validation Failed',
        'Please Upload Cover for the Event.',
      );
    }

    formFieldsData.cover = cover;

    if (descriptionCount === 0) {
      validateInputs('description');
      return Notification(
        'error',
        'Validation failed',
        "Event Description can't be empty",
      );
    }

    if (
      !termsAndConditions ||
      (isArray(termsAndConditions) && termsAndConditions.length === 0)
    ) {
      setDocumentError(true);
      validateInputs('termsAndConditions');
      return Notification(
        'error',
        'Validation Failed',
        `Please Upload the Event's Terms & Policy PDF.`,
      );
    }

    formFieldsData.description = description;
    formFieldsData.name = formFieldsData.nameValue;
    formFieldsData.termsAndConditions =
      termsAndConditions.length === 0
        ? [documentFileList[0].name]
        : termsAndConditions;
    formFieldsData.dateTime = dateTime;
    formFieldsData.eventType = eventType;
    formFieldsData.categories = categories;
    formFieldsData.currency = selectedCurrency;
    formFieldsData.isPrivate = isPrivate;
    formFieldsData.recruiterTicketPrice = formFieldsData.userTicketPrice;

    const result = makeCreateEventData(formFieldsData);

    // Dispatch createEventData
    dispatch(createSwiftEventData(result, handleNotification)).then(
      async (eventData) => {
        form.resetFields();
        // Redirect user
        history.push(OrganizerRoutes.viewEvent(get(eventData, 'eventId')));
        updateUserActionInGTM('event_created');
        if (config.chatEnabled) {
          await createChatroom(
            eventData,
            [userData],
            CHATROOM_TYPE_GROUP,
            false,
          );
        }
      },
    );
  };

  // Date picker
  const onDateTimeChange = (dateArr, dateString) => {
    setDate(dateString[0]);
    setDateTime([...dateArr]);
  };

  const handleClose = () => {
    onCancel();
    setCoverFile([]);
  };

  // Remove cover inside upload
  const handleRemoveCover = (val) => {
    setIsCoverRemoved(val);
    setCoverFile([]);
    setImagePreview('');
  };

  useEffect(() => {
    let subscribed = true;
    if (!isForTest && subscribed) {
      (async () => {
        setIsLoading(true);
        const response = await getEventsAutoFillData();
        const { categories: categoriesList, termsAndConditions } = response;
        if (subscribed) {
          if (categoriesList && categoriesList.length) {
            setCategories(
              uniq(categoriesList.map((value) => get(value, 'id', ''))),
            );
          }

          setCustomInput((currentState) => ({
            ...currentState,
            termsAndConditions,
          }));
          const docFiles = termsAndConditions.map((doc) => {
            return {
              name: doc,
              type: 'application/pdf',
              url: getFileS3(doc),
              uid: doc,
            };
          });

          setDocumentFileList(docFiles);
          setAutoFillData(response);
          setIsLoading(false);
        }
      })();
    }

    return () => {
      subscribed = false;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <StyledModal
      theme={getUserClassWithRoleId(roleId)}
      visible={openSwiftEventModal}
      onCancel={handleClose}
      title={
        <>
          <ResponsiveModalHeader
            handleModalCancel={onCancel}
            title="Create Event"
            color={COLORS.WHITE}
          />
          <HiddenMobile>Create Event</HiddenMobile>
        </>
      }
      width={modalDimension}
      borderRadius={8}
      footer={false}
      destroyOnClose
    >
      <FormGroup
        form={form}
        name="OrganizerCreateEvent"
        onFinish={handleEventSubmit}
        initialValues={makeCreateEventInitialData(autoFillData)}
        expand
        onFailedFinish={onFailedFinish}
      >
        <StyledUploadWrapper>
          {!imagePreview && (
            <PictureUpload
              name="cover"
              text="Upload your event banner"
              icon={<Icon component={imageGallery} />}
              customRequest={(data) => uploadEventFile(data, 'cover')}
              onChange={(data) => handleFileChange(data, 'cover')}
              accept={ACCEPTED_IMAGE_EXTENSIONS}
              maxCount={1}
              beforeUpload={handleCoverBeforeUpload}
              fileList={coverFile}
              cropOptions={EVENT_CROP_COVER_OPTIONS}
              cropActive={false}
              hideToolTip
              placement="center"
              roleId={roleId}
              hintText={EVENT_COVER_IMAGE_HINT}
              id="event_upload_cover_input"
              isCoverRemoved={handleRemoveCover}
              style={{
                outline: 'none',
                border: 'none',
                color: 'grey',
                display:
                  coverFile.length === 0 || isCoverRemoved ? 'block' : 'none',
                backgroundColor: 'lightgray',
                height: '100%',
              }}
              imagePreview={customInput.cover}
            />
          )}
        </StyledUploadWrapper>

        {/* Title */}
        <Row>
          <Col md={24}>
            <label htmlFor="event_title">
              <StyledInfoHeader id="event_name_label">
                Title <MandatoryStar />
              </StyledInfoHeader>
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <TextBox
              rule="text"
              name="nameValue"
              placeholder="Enter Event Name"
              required
              id="event_name"
            />
          </Col>
        </Row>

        {/* Date, Time, Type, Category */}
        <Row gutter={[10, 8]}>
          <Col md={12}>
            <label htmlFor="date_time">
              <StyledInfoHeader id="date_time_label">
                Date & Time <MandatoryStar />
              </StyledInfoHeader>
            </label>
            <StyledDateTimeWrapper>
              <DateTimePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment(), moment().endOf('month')],
                }}
                disabledDate={(d) => d.isSameOrBefore(currentDate(true, 'lll'))}
                getPopupContainer={(trigger) => trigger.parentElement}
                name="dateTime"
                showTime
                format="MMM Do, YYYY [at] h:mm a"
                required
                id="date_time"
                onChange={onDateTimeChange}
              />
            </StyledDateTimeWrapper>
          </Col>
          <Col xs={12} md={5}>
            <label htmlFor="event_type">
              <StyledInfoHeader id="event_type_label">
                Type <MandatoryStar />
              </StyledInfoHeader>
            </label>
            <Dropdown
              name="eventType"
              placeholder="Select event type"
              options={eventTypes}
              showArrow
              handleChange={handleEventTypeChange}
              required
              initialValue={eventTypes[0].value}
              defaultValue={eventTypes[0].name}
              id="event_type"
            />
          </Col>
          <Col xs={12} md={7}>
            <label htmlFor="category">
              <StyledInfoHeader id="category_label">
                Category <MandatoryStar />
              </StyledInfoHeader>
            </label>
            <CategoryDropdown
              name="categories"
              value={categories}
              options={getCategoryOptions(categoryOptions)}
              handleChange={handleSelectCategories}
              required
            />
          </Col>
        </Row>

        {/* Event live date and time */}
        <Row>
          <StyledCheckedIconCol md={1}>
            {date && (
              <StyledCheckedCircleIcon>
                <CheckCircleFilled style={{ color: 'green' }} />
              </StyledCheckedCircleIcon>
            )}
          </StyledCheckedIconCol>
          <Col md={22}>
            {date ? `Your event will go live on ${date}` : null}
          </Col>
        </Row>

        {/* City, country, Zip code */}
        {isForTest || lowerCase(eventType) === 'online' ? null : (
          <AddressAutoComplete form={form} isCreateEvent />
        )}

        {/* about event */}
        <Row>
          <Col xs={8} md={16}>
            <label htmlFor="about_event">
              <StyledDescriptionText id="about_event_label">
                Description <MandatoryStar />
              </StyledDescriptionText>
            </label>
          </Col>
          <Col xs={16} md={8}>
            <StyledCharacterCountText>
              Characters {descriptionCount}/1000
            </StyledCharacterCountText>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            {/* Using React quill instead of InputTextArea
            For now, we are skipping this component in testing aspect. */}
            {!isForTest ? (
              <StyledQuillEditor
                id="about_event"
                name="description"
                placeholder="Type your Event Description here"
                value={description}
                maxLength={1000}
                handleChange={handleDescription}
              />
            ) : null}
          </Col>
        </Row>

        <Row gutter={[25, 10]}>
          {/* Company Links */}
          <Col xs={24} md={12}>
            <Row>
              <Col xs={24}>
                <label htmlFor="company_link">
                  <StyledCompanyLinksText id="company_link">
                    Company Link <MandatoryStar />
                  </StyledCompanyLinksText>
                </label>
              </Col>
            </Row>
            <Row gutter={[25, 10]}>
              <Col xs={24}>
                <TextBox
                  name={['profileLinks', 0, 'link']}
                  placeholder="https://www.recorem.com"
                  rule="url"
                />
              </Col>
            </Row>
          </Col>

          {/* Private event */}
          <Col xs={24} md={12}>
            <Row>
              <Col xs={24}>
                <label htmlFor="event_web3">
                  <StyledInfoHeader id="event_web3_label">
                    Private Event?
                  </StyledInfoHeader>
                </label>
                <ToolTippedInfo
                  backgroundColor={color}
                  hintText={privateEventText}
                  defaultHintText={privateEventText}
                  position="absolute"
                  top="18px"
                  left={screenDimension < desktopScreen ? '105px' : '120px'}
                />
                <StyledSwitch
                  onChange={() => setIsPrivate(!isPrivate)}
                  checked={isPrivate}
                  name="isPrivate"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Events terms and policy & tags */}
        <Row gutter={[25, 10]}>
          <Col xs={24} md={12}>
            <Row>
              <Col xs={24}>
                <label htmlFor="event_terms_and_policy">
                  <StyledInfoHeader id="terms_and_policy_label">
                    Event Terms &#38; Policy <MandatoryStar />
                  </StyledInfoHeader>
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <StyledOuterContainer>
                  <StyledInnerContainer>
                    {/* events terms policy */}
                    <TermsAndPolicyContainer>
                      <TermsAndPolicy
                        handleFileChange={handleFileChange}
                        uploadEventFile={uploadEventFile}
                        handleRemove={handleRemoveDocument}
                        fileList={documentFileList}
                        documentError={documentError}
                      />
                    </TermsAndPolicyContainer>
                  </StyledInnerContainer>
                </StyledOuterContainer>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col>
                <label htmlFor="tag">
                  <StyledInfoHeader id="tag_label">
                    Tags <MandatoryStar />
                  </StyledInfoHeader>
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <StyledDropdownWrapper
                  color={color}
                  backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
                >
                  <Dropdown
                    name="tags"
                    mode="tags"
                    showSearch
                    style={{ width: '100%' }}
                    handleChange={(options) => {
                      // Optional function prop
                      if (handleSelectType) {
                        handleSelectType(options, 'tags');
                      }
                    }}
                    onSearch={(value) => {
                      handleQueryTagSkills(value);
                    }}
                    options={querySkills}
                    id="tag"
                    required
                  />
                </StyledDropdownWrapper>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Pricing and Broadcast Link */}
        <Row gutter={[25, 10]}>
          <Col md={12}>
            <PricingAndPromoContainer
              isTabActive={setIsActive}
              isActive={isActive}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
            />
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Col>
                <label htmlFor="broadcast_links">
                  <StyledInfoHeader id="broadcast_links">
                    Broadcast Link
                  </StyledInfoHeader>
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24}>
                <TextBox
                  name="broadcastLink"
                  placeholder="https://www.recorem.com"
                  rule="url"
                  required={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Create Event button */}
        <Row>
          <StyledCreateButtonWrapper xs={24}>
            <PrimaryButton
              type="submit"
              disabled={disableCreateEventButton}
              theme="organizer"
              id="create_event_btn"
              fullWidth
              height={50}
              minWidth="100%"
              customTheme="#0d6efdcc"
            >
              Create Event
            </PrimaryButton>
          </StyledCreateButtonWrapper>
        </Row>
      </FormGroup>
    </StyledModal>
  );
};

export default SwiftEvent;

const StyledUploadWrapper = styled.div`
  button {
    height: 42px;
  }
  ${MARGIN(0, 0, 5, 0)};
`;

const StyledImage = styled.img`
  width: 528px;
  height: 235px;
  object-fit: cover;
`;

const StyledInfoHeader = styled.span`
  display: block;
  ${MARGIN(16, 0, 8, 0)};
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledCheckedIconCol = styled(Col)`
  align-self: center;
  ${MARGIN(0, 5, 0, 0)};
`;

const StyledCheckedCircleIcon = styled.div`
  display: ${DISPLAY.FLEX};
  ${MARGIN(0, 8, 0, 0)};
`;

const StyledCharacterCountText = styled.span`
  display: block;
  text-align: right;
  color: ${COLORS.PHILLIPPINE_GRAY};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};
  ${MARGIN(16, 0, 8, 0)};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledDescriptionText = styled.span`
  display: block;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};
  ${MARGIN(16, 0, 8, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledQuillEditor = styled(QuillEditor)`
  ${MARGIN(0, 0, 60, 0)};
  width: 100%;
  height: 200px;
`;

const StyledDateTimeWrapper = styled.span`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-picker-footer {
      .ant-picker-ok {
        float: none;
      }
    }
  }
`;

const StyledCompanyLinksText = styled.span`
  display: block;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};
  ${MARGIN(16, 0, 8, 0)};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledOuterContainer = styled.div`
  background-color: ${COLORS.LIGHT_GRAY};
  ${DISPLAY_FLEX('row')}
  height: 130px;
`;

const StyledInnerContainer = styled.div`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
  ${MARGIN(10, 10, 10, 10)};
`;

const StyledSwitch = styled(Switch)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 20px;
  }
`;

const TermsAndPolicyContainer = styled.div``;

const StyledDropdownWrapper = styled.span`
  span.ant-select-selection-item {
    color: ${(props) => props.color};
    background: ${(props) => props.backgroundColor};

    span.ant-select-selection-item-remove {
      color: ${(props) => props.color};
    }
  }

  div.ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: ${COLORS.LIGHT_GRAY};
    height: 130px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .ant-select-selector::-webkit-scrollbar {
    display: none;
  }

  div.ant-select-selection-overflow {
    align-self: flex-start;
  }
`;

const StyledCreateButtonWrapper = styled(Col)`
  ${DISPLAY_FLEX('row', 'center')}
`;
