import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import { userProfileStore } from '../../utils/profile';
import OngoingEvents from '../../components/Events/OngoingEvents';
import UpcomingEvents from '../../components/Events/UpcomingEvents';
import { EventsStore } from '../../utils/event';
import { getOrganizerEventsData } from '../../store/features/events';
import DashboardHeader from '../../components/Dashboard/Header';
import {
  colPropsOngoingSection,
  colPropsEventInfoSection,
  colPropsEventPicSection,
  colPropsUpcomingSection,
} from '../../constants/layouts/column';
import PageCircle from '../../components/shared-components/PageCircle';
import { getUserColorWithRoleId } from '../../styles/utils';
import { ORGANIZER } from '../../constants';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    profileData: { company: companyData },
    error,
  } = userProfileStore();
  const { isLoading: isLoadingEvents, error: errorEvents } = EventsStore();

  useEffect(() => {
    dispatch(getOrganizerEventsData());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading || error || isLoadingEvents || errorEvents ? (
    <Skeleton />
  ) : (
    <div>
      <PageCircle color={getUserColorWithRoleId(ORGANIZER)} />

      <DashboardHeader companyData={companyData} />

      <OngoingEvents
        colPropsOngoingSection={colPropsOngoingSection}
        colPropsEventInfoSection={colPropsEventInfoSection}
        colPropsEventPicSection={colPropsEventPicSection}
        dashboard
      />

      <UpcomingEvents
        colPropsUpcomingSection={colPropsUpcomingSection}
        dashboard
        locateInDashboard
      />
    </div>
  );
};

export default Dashboard;
