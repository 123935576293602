import React, { memo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Col, Row } from 'antd';
import { COLORS } from '../../../styles/constants/colors';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { MoneyIcon, PinIcon } from '../../svgicons';
import { getFileS3 } from '../../../utils/s3.storage';
import EmptyImg from '../../../assets/placeholders/placeholder.jpg';
import { LOCALES } from '../../../constants/currencyLocale';
import { getFormattedCurrency } from '../../../utils/currency';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const EventPartnerJobCard = ({ jobsData, selectedCard, onCardClick }) => {
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <>
      {jobsData &&
        jobsData.map((job, idx) => (
          <StyledJobCardContainer
            key={job.id}
            onClick={() => onCardClick(idx)}
            ownId={idx}
            selectedId={selectedCard}
          >
            <Row gutter={[14, 10]} align="middle" style={{ flexFlow: 'row' }}>
              <Col flex="65px">
                <StyledJobLogo>
                  <StyledLogoContainerImg
                    src={getFileS3(get(job, 'company.logo', null)) || EmptyImg}
                    alt="logo"
                  />
                  <StyledLogoImg
                    src={getFileS3(get(job, 'company.logo', null)) || EmptyImg}
                    alt="logo"
                  />
                </StyledJobLogo>
              </Col>
              <Col flex="auto">
                <StyledJobName>{get(job, 'designation', '')}</StyledJobName>
                <StyledSalaryAndLocationContainer>
                  <StyledFlexContainer data-icon="customIcon">
                    <PinIcon />
                    <StyledMediumText title={job.location}>
                      {' '}
                      {job.jobLocationType === 1 ? "Remote" : job.location.split(',', 2).join(',')}
                    </StyledMediumText>
                  </StyledFlexContainer>
                  <StyledFlexContainer data-icon="customIcon">
                    <MoneyIcon />
                    <StyledMediumText>
                      {get(job, 'salaryTo')
                        ? getFormattedCurrency(
                          get(job, 'salaryCurrency.code'),
                          get(job, 'salaryCurrency.symbol'),
                          get(job, 'salaryTo'),
                          LOCALES[get(job, 'currency')],
                        )
                        : `Not Specified`}
                    </StyledMediumText>
                  </StyledFlexContainer>
                </StyledSalaryAndLocationContainer>
              </Col>
            </Row>
            <StyledJobInfo>
              <div dangerouslySetInnerHTML={createMarkup(job.jobBrief)} />
            </StyledJobInfo>
          </StyledJobCardContainer>
        ))}
    </>
  );
};

export default memo(EventPartnerJobCard);

const StyledJobCardContainer = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 18px;
  ${MARGIN(0, 0, 25, 0)};
  ${PADDING(16)};
  cursor: pointer;
  height:162px;
  overflow: hidden;
  position:relative;
  box-shadow: ${({ ownId: id, selectedId }) => {
    return id === selectedId
      ? `0 0 0 2px ${COLORS.BLUE_VIOLET_2}`
      : `0 0 0 1px ${COLORS.LIGHT_GRAY_2}`;
  }};
  &:after{
    content: "";
    height: 30px;
    width: 100%;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    background-image: linear-gradient(to top, ${COLORS.WHITE}, #ffffff00);
    background-color: #00000000;
    border-radius:70px;
  }
`;

const StyledJobLogo = styled.div`
  position: relative;
  height: 65px;
  background-color: ${COLORS.ANTI_FLASH_WHITE};
  ${DISPLAY_FLEX('row', 'flex-start', 'center')};
  border-radius: 10px;
  overflow: hidden;
`;

const StyledLogoContainerImg = styled.img`
  height: 65px;
  max-height: 65px;
  max-width: 65px;
  border-radius: 8px;
  filter: opacity(0.4);
  scale: 1.3;
`;

const StyledLogoImg = styled.img`
  height: 65px;
  max-height: 65px;
  max-width: 65px;
  position: absolute;
  border-radius: 8px;
  backdrop-filter: blur(20px);
  ${PADDING(10)}
  object-fit: contain;
`;

const StyledJobInfo = styled.div`
  color: ${COLORS.DARK_GRAY};
  ${MARGIN(14, 0, 0, 0)}
  ${FONT_WEIGHTS.MEDIUM};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  height:50px;
  p {
    font-size: 14px;
  }

  p, h1, h2, h3, h4, h5, h6, ul,li,div {
    display: -webkit-inline-box;
  }
`;
const StyledFlexContainer = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', 'center')};
  gap: 5px;
  -webkit-gap: 5px;

  &[data-icon='customIcon'] {
    svg {
      transform: scale(1.2);
    }
  }
`;
const StyledSalaryAndLocationContainer = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', 'center')};
  flex-wrap: wrap;
  gap: 12px;
  -webkit-gap: 12px
`;
const StyledMediumText = styled.p`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  max-width: 105px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    max-width: 95px;
  }
`;

const StyledJobName = styled.h3`
  ${MARGIN(0, 0, 5, 0)};
  font-size: ${FONT[16]};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
