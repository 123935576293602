import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

export const StyledPageBottom = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block;
    background-color: ${({ color }) => color};
    height: 50px;
    width: 109%;
    margin: 0 -15px;
    right: -34px;
  }
`;
