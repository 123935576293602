import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { CalendarIcon, MonitorWaveIcon, LocationPinIcon } from '../../svgicons';
import { ongoingEventDateTimeFormat } from '../../../utils/formatDateTime';
import { isEventOnline } from '../../../utils/event';
import { useTimeZone } from '../../../utils/customHooks';
import { ONLINE_EVENT_TEXT } from '../../../constants';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../styles/constants/display';

export const DateInfoSection = ({ event, roleId }) => {
  const timeZone = useTimeZone(event);

  return (
    <StyledMainContainer>
      <Row gutter={[35, 40]}>
        <Col xs={24}>
          <ul>
            <li>
              <StyledTextWrapper>
                <span>
                  <CalendarIcon />
                </span>
                {`${ongoingEventDateTimeFormat(
                  event.dateTime,
                )} to ${ongoingEventDateTimeFormat(
                  event.endDateTime,
                )} ${timeZone}`}
              </StyledTextWrapper>
            </li>
            <li>
              <StyledTextWrapper>
                {isEventOnline(event.eventType) ? (
                  <>
                    <span>
                      <MonitorWaveIcon />
                    </span>
                    {ONLINE_EVENT_TEXT}
                  </>
                ) : (
                  <>
                    <span>
                      <LocationPinIcon />
                    </span>
                    {`${event.country}, ${event.zip}`}
                  </>
                )}
              </StyledTextWrapper>
            </li>
          </ul>
        </Col>
      </Row>
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled.div`
  margin-top: 15px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0px;
  }

  ul {
    list-style: none;
    margin-bottom: 0px;
    ${DISPLAY_FLEX('column')}
    gap: 1px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_SPECIAL_CASE_3}) {
      gap: 8px;
    }

    li {
      color: ${COLORS.DAVYS_GREY};
      font-weight: ${FONT_WEIGHTS.MEDIUM};
      display: inline-block;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        margin-top: 5px;
      }

      p {
        @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) and (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_4}) {
          margin-bottom: 0px;
        }
      }

      svg {
        fill: ${COLORS.DAVYS_GREY};
        width: 18px;
        margin-right: 10px;
        position: relative;
        top: 2px;

        @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
          width: 21px;
          margin-right: 15px;
        }

        @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
          top: 0px;
        }
      }
    }
  }
`;

const StyledTextWrapper = styled.p`
  display: flex;
  margin-bottom: 5px;
  font-size: ${FONT[13]};

  @media (min-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_3}) and (max-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[15]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;
