import api from './api';
import { APIs, Method } from '../constants';

export const searchUsers = async (value) => {
  const url = `${APIs.index_user}?param=${value}`;
  return api({
    method: Method.get,
    url,
  });
};

export const searchSkills = async (value) => {
  const url = `${APIs.skill}?find=${encodeURIComponent(value)}`;
  return api({
    method: Method.get,
    url,
  });
};

export const checkIfUserExistOrNot = async (email) => {
  const url = APIs.indexCheckIfUserExist;
  return api({
    method: Method.post,
    url,
    body: { email },
  });
};
