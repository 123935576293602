import React from 'react';
import styled from 'styled-components';
import Skeleton from 'antd/es/skeleton';
import Upload from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { EditIcon, PinIcon } from '../../components/svgicons';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { BOX_SHADOW } from '../../styles/constants/box-shadow';
import { useGetCommunityPageQuery } from '../../store/features/rtkApi/common';
import { getFileS3 } from '../../utils/s3.storage';
import { ACCEPTED_IMAGE_EXTENSIONS } from '../../constants/file';
import { EVENT_CROP_COVER_OPTIONS } from '../../constants';
import useCommunityPageCover from '../../hooks/useCommunityPageCover';
import { UserInformation } from '../../utils/userInfo';
import UserIcon from '../../assets/svg/user-1.svg';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import {
  NewTabLinks,
  StyledLI,
  StyledUL,
  socailIcons,
} from '../../components/Footer/common';

const CompanyHeader = () => {
  const { companyId } = useParams();
  const { isAuthenticated } = UserInformation();
  const { data: communityPageData, isLoading } = useGetCommunityPageQuery(
    companyId,
  );
  const eventId = get(communityPageData, 'communityEvent.id');
  const {
    handleCoverBeforeUpload,
    uploadEventCover,
    handleCoverChange,
  } = useCommunityPageCover(eventId);

  if (isLoading) {
    return <Skeleton avatar />;
  }

  const companyName = get(communityPageData, 'company.company.name', '');
  const profileLogo = get(communityPageData, 'company.company.logo', '');
  const state = get(communityPageData, 'company.company.state', '');
  const country = get(communityPageData, 'company.company.country', '');
  const communityCover = get(communityPageData, 'communityEvent.cover', null);
  const companyCover = get(communityPageData, 'company.company.cover', null);
  const profileLinks = get(
    communityPageData,
    'company.company.profileLinks.links',
    null,
  );

  const coverPhoto = communityCover ?? companyCover;
  // used local-storage because profileData is not becoming null when logout
  // reason - no reducer is used when logout for profileSlice
  const isCommunityEventOwner = () => {
    if (isAuthenticated)
      return (
        +companyId ===
        get(JSON.parse(localStorage.getItem('userInfo')), 'companyId', '')
      );
    return false;
  };
  const companyLogo = getFileS3(profileLogo);

  return (
    <header>
      {/* container */}
      <StyledHeaderContainer>
        {/* cover-photo container */}
        <StyledImageContainer>
          {coverPhoto && (
            <StyledCover src={getFileS3(coverPhoto)} alt="cover" />
          )}
          {isCommunityEventOwner() && (
            <ImgCrop
              {...EVENT_CROP_COVER_OPTIONS}
              beforeCrop={handleCoverBeforeUpload}
            >
              <Upload
                name="cover"
                maxCount={1}
                accept={ACCEPTED_IMAGE_EXTENSIONS}
                id="community-cover-upload"
                onChange={(data) => {
                  handleCoverChange(data);
                }}
                customRequest={(data) => {
                  uploadEventCover(data);
                }}
                showUploadList={false}
              >
                <StyledCoverEditButton
                  type="button"
                  aria-label="edit-cover-photo"
                >
                  <EditIcon />
                </StyledCoverEditButton>
              </Upload>
            </ImgCrop>
          )}
          {/* Share icons container */}
          <StyledShareIcons>
            <StyledUL>
              {socailIcons.map(({ icon, name }, idx) => {
                const social = profileLinks?.find(({ link }) =>
                  link.includes(name),
                );
                return (
                  <StyledLI key={idx.toString()}>
                    <NewTabLinks name={name} link={social?.link} isMobile>
                      {social?.link && icon}
                    </NewTabLinks>
                  </StyledLI>
                );
              })}
            </StyledUL>
          </StyledShareIcons>
        </StyledImageContainer>
        {/* profile-photo and name container */}
        <StyledCompanyDetailsSection>
          <StyledLeftImage>
            <StyledProfileImage isProfile={companyLogo}>
              <img src={companyLogo || UserIcon} alt="profilepic" />
            </StyledProfileImage>
          </StyledLeftImage>
          <StyledCompanyInfo>
            <>
              <StyledName>
                <span>{companyName}</span>
              </StyledName>
              <StyledLocation>
                <span>
                  <PinIcon />
                </span>
                <span id="location">
                  {state !== '-' ? `${state}, ${country}` : country}
                </span>
              </StyledLocation>
            </>
          </StyledCompanyInfo>
        </StyledCompanyDetailsSection>
      </StyledHeaderContainer>
    </header>
  );
};

export default CompanyHeader;

const StyledHeaderContainer = styled.section`
  ${MARGIN(0, 0, 60, 0)}

  @media (max-width : ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0)}
  }
`;

const StyledShareIcons = styled.section`
  position: absolute;
  right: 45px;
  bottom: -15px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    right: 0px;
    bottom: -15px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_6}) {
    bottom: 0px;
  }
`;

const StyledLeftImage = styled.div`
  position: relative;
  width: 200px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 220px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 110px;
  }
  @media (max-width: ${BREAKPOINTS.LAPTOP_MIN}) and (min-width: ${BREAKPOINTS.MOBILE}) {
    width: 170px;
  }
`;

const StyledName = styled.div`
  span {
    font-size: ${FONT[28]};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    @media (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
      font-size: ${FONT[22]};
    }
  }
`;
const StyledLocation = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap : 5px;

  span {
    ${DISPLAY_FLEX(null, null, 'center')}
  }
  svg {
    width: 16px;
    height: 21px;
  }
  #location {
    color: ${COLORS.GRANITE_GRAY};
    margin-bottom: 5px;
    font-size: 18px;
  }
`;

const StyledProfileImage = styled.span`
  width: max-content;
  position: absolute;
  background: ${COLORS.WHITE};
  border-radius: 100%;
  box-shadow: ${BOX_SHADOW[7]};
  right: 10px;
  top: -60px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    top: -70px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: -25px;
  }
  @media (max-width: ${BREAKPOINTS.LAPTOP_MIN}) and (min-width: ${BREAKPOINTS.MOBILE}) {
    top: -50px;
  }
  #location {
  }

  & > img {
    width: 160px;
    height: 160px;
    scale: ${(props) => (props.isProfile ? 1 : `65%`)};
    opacity: ${(props) => (props.isProfile ? 1 : `0.5`)};
    border-radius: ${(props) => (props.isProfile ? `100% ` : 0)};
    ${PADDING(7)}

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 180px;
      height: 180px;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 95px;
      height: 95px;
    }
    @media (max-width: ${BREAKPOINTS.LAPTOP_MIN}) and (min-width: ${BREAKPOINTS.MOBILE}) {
      width: 140px;
      height: 140px;
    }
  }
`;

const StyledCover = styled.img`
  width: 100%;
  max-height: 420px;
  min-height: 300px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: -25px;
    min-height: 160px;
  }
`;
const StyledCompanyDetailsSection = styled.div`
  ${DISPLAY_FLEX('row')};
  column-gap: 5px;
`;

const StyledImageContainer = styled.div`
  position: relative;
  background: linear-gradient(
    to right,
    ${COLORS.DEEP_SAFFRON},
    ${COLORS.PASTEL_ORANGE}
  );
  max-height: 420px;
  min-height: 300px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    min-height: 160px;
  }
`;

const StyledCompanyInfo = styled.div`
  ${DISPLAY_FLEX('column')}
`;

const StyledCoverEditButton = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 10px;
  right: 10px;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  border : none;
  border-radius: 999px;
  background: ${COLORS.WHITE};
  cursor: pointer;
  svg {
    width: 22px;
    fill: ${COLORS.CHINESE_BLACK};
  }

  &:hover {
    background: ${COLORS.WHITE_SMOKE};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    bottom: 50px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_6}) {
    bottom: 65px;
  }
`;
