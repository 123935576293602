import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import { Row } from 'antd';
import { userProfileStore } from '../../../../utils/profile';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from '../../../../utils/userInfo';
import {
  isEventOngoing,
  isPastEvent,
  getOnGoingEvent,
  getUpComingEvent,
} from '../../../../utils/event';
import BookEventButton from './BookEventButton';
import EditEventButton from './EditEventButton';
import ViewAnalyticsButton from './ViewAnalyticsButton';
import { PAYMENT, STATUS_DELETE } from '../../../../constants';
import { EventAnalyticsAndReport } from './EventAnalyticsAndReport';
import JoinEventButton from './JoinEventButton';
// import RecruiterAnalyticsAndReport from './RecruiterAnalyticsAndReport';
import { DISPLAY_FLEX } from '../../../../styles/constants/display';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { PrimaryButton } from '../../../shared-components/Button';
import { getThemeByRoleId } from '../../../../utils/common';
import AppSocketContext from '../../../../context/AppSocket';
import { getEventByIdDataWithoutReload } from '../../../../store/features/events';
import { ClaimPoapButton } from './ClaimPoapButton';

export default function BookAndPromoteEvent({
  event,
  openModal,
  isPublicEvent,
  roleId,
  openBookEventModal,
  inviteCode,
  setOpenBookEventModal,
  openPaymentModal,
}) {
  const client = useContext(AppSocketContext);
  // Return null if event cancelled
  const dispatch = useDispatch();
  const { profileData } = userProfileStore();
  const companyId = get(profileData, 'company.id', -1);

  useEffect(() => {
    (async () => {
      if (client && event && event.id) {
        const userId = get(profileData, 'userId', '');
        await client.subscribe(
          `/event/booking/status/${event.id}/${userId}${companyId > 0 ? `/${companyId}` : ''
          }`,
          (data) => {
            if (
              get(data, 'paymentStatus', '') === PAYMENT.PAID &&
              event.isEventPaymentProcessing
            ) {
              // refetch data
              dispatch(getEventByIdDataWithoutReload(event.id));
            }
          },
        );
      }
    })();

    // eslint-disable-next-line
  }, [get(event, 'id', null)]);

  if (event.status === STATUS_DELETE) {
    return null;
  }

  return (
    // TODO: Will be refactoring the below codes at the time of integration
    <StyledMainContainer>
      {checkIfUserIsOrganizer(roleId) ? (
        <>
          {isPastEvent(event.dateTime, event.endDateTime) ? (
            <ViewAnalyticsButton openModal={openModal} />
          ) : (
            <>
              {companyId === get(event, 'companyId', null) ? (
                <EditEventButton eventId={event.id} />
              ) : null}

              {isEventOngoing(event) ? (
                <ViewAnalyticsButton openModal={openModal} />
              ) : null}
            </>
          )}
        </>
      ) : event.isEventBooked ? (
        getOnGoingEvent(event) ? (
          <>
            {checkIfUserIsCandidate(roleId) ? (
              <>
                <JoinEventButton
                  event={event}
                  candidate
                  roleId={roleId}
                  eventPage
                />
                <EventAnalyticsAndReport event={event} />
              </>
            ) : (
              <>
                <>
                  <JoinEventButton event={event} recruiter roleId={roleId} />
                  {/* <RecruiterAnalyticsAndReport event={event} /> */}
                </>
              </>
            )}
          </>
        ) : !getUpComingEvent(event) && checkIfUserIsCandidate(roleId) ? (
          <>
            <EventAnalyticsAndReport event={event} />
            <ClaimPoapButton event={event} />
          </>
        ) : !getUpComingEvent(event) && checkIfUserIsRecruiter(roleId) ? (
          <>
            {/* <RecruiterAnalyticsAndReport event={event} /> */}
          </>
        ) : null
      ) : (
        <>
          {event.isEventBooked && event.isEventPaymentProcessing ? (
            <StyledButtonWrapper>
              <PrimaryButton theme={getThemeByRoleId(roleId)} disabled>
                Processing
              </PrimaryButton>
            </StyledButtonWrapper>
          ) : (
            <StyledButtonWrapper>
              <BookEventButton
                event={event}
                isPublicEvent={isPublicEvent}
                openBookEventModal={openBookEventModal}
                inviteCode={inviteCode}
                setOpenBookEventModal={setOpenBookEventModal}
                profileData={profileData}
                openPaymentModal={openPaymentModal}
              />
            </StyledButtonWrapper>
          )}
        </>
      )}
    </StyledMainContainer>
  );
}

const StyledMainContainer = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')};
  row-gap: 10px !important;
  gap: 20px;

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) and (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_4}) {
    row-gap: 5px !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 15px;
  }
`;

const StyledButtonWrapper = styled.div`
  ${DISPLAY_FLEX(null, 'space-around', null)};
`;
