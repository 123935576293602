import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import {
  getCrewMembersAdmin,
  inviteNewMemberAdmin,
  inviteRegistererdMemberAdmin,
} from '../../service/masterAdmin';
import { ORGANIZER_MEMBER, RECRUITER_MEMBER } from '../../constants';
import {
  getCrewMembers,
  inviteRegistererdMember,
  inviteNonRegistererdMember,
  acceptInvitation,
  rejectInvitation,
  acceptCrewMember,
} from '../../service/crew';
import { getProfileData, getProfileDataWithoutReload } from './profile';
import Notification from '../../components/Notification/Notification';
import { checkIfUserIsOrganizer } from '../../utils/userInfo';

const onStart = (state) => {
  state.isLoading = true;
};

const onSuccessOrganizer = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.members = [];
  action.payload.map((data) => {
    if (data.role === ORGANIZER_MEMBER) {
      state.members.push({
        ...data,
        userId: data.isInvitedThroughtEmaildata ? null : data.userId,
      });
    }
    return null;
  });
};

const onSuccessRecruiter = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.members = [];
  action.payload.map((data) => {
    if (data.role === RECRUITER_MEMBER) {
      data.phone = data.phone?.trim() ? data.phone : null
      state.members.push({
        ...data,
        userId: data.isInvitedThroughtEmaildata ? null : data.userId,
      });
    }
    return null;
  });
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const crewSlice = createSlice({
  name: 'crew',
  initialState: {
    isLoading: false,
    members: [],
    error: null,
  },
  reducers: { onStart, onSuccessOrganizer, onSuccessRecruiter, onError },
});

const getOrganizerCrewMembersData = () => async (dispatch) => {
  try {
    dispatch(crewSlice.actions.onStart());
    const members = await getCrewMembers();
    dispatch(
      crewSlice.actions.onSuccessOrganizer(members.data.payload.members),
    );
  } catch (err) {
    dispatch(crewSlice.actions.onError(err.toString()));
  }
};

const getRecruiterCrewMembersData = () => async (dispatch) => {
  try {
    dispatch(crewSlice.actions.onStart());
    const members = await getCrewMembers();
    dispatch(
      crewSlice.actions.onSuccessRecruiter(members.data.payload.members),
    );
  } catch (err) {
    dispatch(crewSlice.actions.onError(err.toString()));
  }
};

const sendInvitationToRegistedMember = (roleId, body) => async (dispatch) => {
  try {
    dispatch(crewSlice.actions.onStart());
    const response = await inviteRegistererdMember(body);
    if (get(response, 'statusCode', 200) === 200) {
      Notification('success', 'Member invited to join the crew');
      checkIfUserIsOrganizer(roleId)
        ? dispatch(getOrganizerCrewMembersData())
        : dispatch(getRecruiterCrewMembersData());
    }
  } catch (err) {
    dispatch(crewSlice.actions.onError(err.toString()));
  }
};

const sendInvitationToNonRegistedMember =
  (roleId, body) => async (dispatch) => {
    try {
      dispatch(crewSlice.actions.onStart());
      const response = await inviteNonRegistererdMember(body);
      if (get(response, 'statusCode', 200) === 200) {
        Notification(
          'success',
          'Invitation Sent!',
          `Email Invite sent to ${body.email}. You will be notified once collaborator have accepted the invitation`,
        );
        checkIfUserIsOrganizer(roleId)
          ? dispatch(getOrganizerCrewMembersData())
          : dispatch(getRecruiterCrewMembersData());
      }
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

const acceptCompanyInvitation =
  (companyId, companyMemberId) => async (dispatch) => {
    try {
      dispatch(crewSlice.actions.onStart());
      await acceptInvitation(companyId, companyMemberId);
      dispatch(getProfileData());
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

export const getCrewMembersAdminData =
  (companyId, roleId, search, sorter) => async (dispatch) => {
    try {
      const members = await getCrewMembersAdmin(
        companyId,
        roleId,
        search,
        sorter,
      );
      if (checkIfUserIsOrganizer(roleId)) {
        dispatch(
          crewSlice.actions.onSuccessOrganizer(members.data.payload.members),
        );
      } else {
        dispatch(
          crewSlice.actions.onSuccessRecruiter(members.data.payload.members),
        );
      }
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

export const sendInvitationToRegistedMemberAdmin =
  (roleId, companyId, body) => async (dispatch) => {
    try {
      dispatch(crewSlice.actions.onStart());
      await inviteRegistererdMemberAdmin(body);
      dispatch(getCrewMembersAdminData(companyId, roleId));
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

export const sendInvitationToNonRegistedMemberAdmin =
  (roleId, companyId, body) => async (dispatch) => {
    try {
      dispatch(crewSlice.actions.onStart());
      const response = await inviteNewMemberAdmin(body);
      if (get(response, 'statusCode', 200) === 200) {
        Notification(
          'success',
          'Invitation Sent!',
          `Email Invite sent to ${body.email}. You will be notified once collaborator have accepted the invitation`,
        );
        dispatch(getCrewMembersAdminData(companyId, roleId));
      }
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

export const rejectCompanyInvitation =
  (companyId, companyMemberId) => async (dispatch) => {
    try {
      await rejectInvitation(companyId, companyMemberId);
      dispatch(getProfileDataWithoutReload());
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

export const acceptCompanyInvitationWithoutReload =
  (companyId, companyMemberId) => async (dispatch) => {
    try {
      await acceptInvitation(companyId, companyMemberId);
      dispatch(getProfileDataWithoutReload());
    } catch (err) {
      dispatch(crewSlice.actions.onError(err.toString()));
    }
  };

export const acceptCrewInvitation = (crewMemberId) => async (dispatch) => {
  try {
    await acceptCrewMember(crewMemberId);
    dispatch(getProfileData());
  } catch (err) {
    dispatch(crewSlice.actions.onError(err.toString()));
  }
};

export default crewSlice.reducer;

export {
  getOrganizerCrewMembersData,
  getRecruiterCrewMembersData,
  sendInvitationToRegistedMember,
  sendInvitationToNonRegistedMember,
  acceptCompanyInvitation,
};
