import get from 'lodash/get';
import { currencyConverting } from '../../../utils/currencyConvertor';

export const getConvertedPriceString = (
  currency,
  cost,
  profileData,
  currencyData,
) => {
  const convertedPriceString = currencyConverting({
    fromCurrency: currency,
    fromAmount: cost,
    toCurrency: get(profileData, 'paymentCurrency.code', null),
    currenciesData: currencyData,
  });
  return `${currency} ${cost} ${
    convertedPriceString ? ` Or ${convertedPriceString}` : ''
  }`;
};
