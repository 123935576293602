import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { Row, Col, Button, Form, message, Spin } from 'antd';
import last from 'lodash/last';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import toString from 'lodash/toString';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import Notification from '../../../components/Notification/Notification';
import CountryCodeInput from '../../../components/Common/CountryCodeInput';
import MandatoryStar from '../../../components/Common/mandatoryStar';
import {
  FormGroup,
  Dropdown,
  TextBox,
  NumberBox,
  Email,
} from '../../../components/shared-components/Form/Form';
import { areSkillsRepeating } from '../../../utils/skill';
import { updateProfileData } from '../../../store/features/profile';
import { checkIfUserIsMasterAdmin, getRoleId } from '../../../utils/userInfo';
import { ADD, EDIT, DELETE, ON_SELECT_FROM_ROW } from '../../../constants';
import { CandidateExp } from '../../../utils/candidateExperience';
import CreateEducationInfosRow from '../../../components/ProfileOnboarding/EducationInfoRow';
import { candidateAvailability } from '../../../mocks/profile/candidateAvailability';
import {
  countryCodeOnlySplit,
  userProfileResetFields,
  candidateSkillLevel,
  profileLinksPayload,
} from '../../../utils/profile';
import AddressAutoComplete from '../../../components/Common/AddressAutoComplete';
import { UploadProfilePicComponent } from '../../../components/Common/UploadComponent';
import ProfilesLinksList from '../../../components/ProfileLinks/ProfileLinksList';
import CreateSkillsSetRow from '../../../components/ProfileOnboarding/SkillsRow';
import IndustryTypes from '../../../components/Common/IndustryTypes';
import { employmentType } from '../../../mocks/profile/employmentType';
import ModalButtonContainer from '../../../components/profile/ModalButtonContainer';
import EditCurrencySetting from '../../../components/profile/EditCurrencySetting';
import { updateCandidateProfileAdmin } from '../../../service/masterAdmin';
import {
  getCurrencyDataForDropdown,
  getDefaultPaymentCurrency,
  getFirstCurrencyByCode,
  getCurrencyById,
} from '../../../utils/currencyConvertor';
import Label from '../../../components/shared-components/HiddenLable';
import { getSize } from '../../../utils/size';
import { CandidateRoutes } from '../../../constants/routes';
import { ProfileInformationHeader } from '../../../components/CrewManagement/common';
import {
  SmallHeading,
  StyledMediumHeading,
} from '../../../components/shared-components/Texts';
import { getUserColorWithRoleId } from '../../../styles/utils';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { Modal } from '../../../components/shared-components/Modal';
import { getThemeByRoleId, processCategory } from '../../../utils/common';
import { COLORS } from '../../../styles/constants/colors';
import { ShowMobile } from '../../../components/shared-components/common';
import { HiddenMobile } from '../../../components/shared-components/DocumentViewerModal';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import CountriesDropdown from '../../../components/Common/CountriesDropdown';
import { useTagManager } from '../../../utils/customHooks';
import { getCategoriesForDomain, getRecommendedSkills } from '../../../service';
import CategoryDropdown from '../../../components/Common/CategoryDropdown';
import { checkIfUserExist } from '../../../service/auth';
import { isMobile } from '../../../helpers/display';

const PersonDetailModal = (props) => {
  const {
    visible,
    handleClose,
    profileData,
    profilePic,
    setProfilePic,
    handleSuccess,
    currencyData,
    workAuthorizationsData,
    authorizedCountries,
    unauthorizedCountries,
    setAuthorizedCountryIds,
    setUnauthorizedCountryIds,
    getWorkAuthorizationInfo,
    isMasterAdminOfCandidate = null,
  } = props;
  const dispatch = useDispatch();
  const roleId = getRoleId();
  const history = useHistory();
  const [isPasswordSetOrNot, setIsPasswordSetOrNot] = useState(true);
  const { candidateExpinYr, candidateExpinMon } = CandidateExp(profileData);
  const [educations, setEducations] = useState([]);
  const [form] = Form.useForm();
  const updateUserActionInGTM = useTagManager();
  const [skills, setSkills] = useState([]);
  const [skillStep, setSkillStep] = useState([]);
  const [requestSkill, setRequestSkill] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [contributeOption, setContributeOption] = useState([]);
  const [areSkillsUpdated, setSkillsUpdated] = useState(false);
  const [domainsList, setDomainsList] = useState([]);
  const [isLoding, setIsLoading] = useState(true);
  const [domains, setDomains] = useState([]);
  const [recommendedSkills, setRecommendedSkills] = useState([]);

  useEffect(() => {
    if (profileData.educations) {
      setEducations([]);
      profileData.educations.forEach((education) => {
        setEducations((oldArr) => [
          ...oldArr,
          {
            id: education.id,
            school: education.school,
            degree: education.degree,
            major: education.major,
            operation: EDIT,
          },
        ]);
      });
    }
    if (profileData.userSkills) {
      setSkills([]);
      setSkillStep([]);
      setSkillsList([]);
      profileData.userSkills.forEach((skill) => {
        setSkills((oldArr) => [
          ...oldArr,
          {
            id: skill.id,
            skill: {
              id: skill.skill ? skill.skill.id : '',
              skill: skill.skill ? skill.skill.skill : '',
            },
            level: skill.level,
            operation: EDIT,
          },
        ]);

        setSkillStep((oldArr) => [
          ...oldArr,
          {
            id: skill.id,
            value: candidateSkillLevel(skill.level),
          },
        ]);

        setSkillsList((oldArr) => [
          ...oldArr,
          {
            options: [],
          },
        ]);
      });
    }
  }, [profileData.educations, profileData.userSkills]);

  const getSkillIds = async (skillsArr) => {
    let ids = [];
    setRecommendedSkills([]);
    for (let i = 0; i < skillsArr.length; i++) {
      if (!Object.values(skillsArr[i]).includes('delete')) {
        ids.push(skillsArr[i].skill.id);
      }
    }

    if (ids.length) {
      const payload = await getRecommendedSkills(ids);
      setRecommendedSkills(payload);
    }
  };

  const handleSkillsSetOnClick = (tempSkillsArr, type = "") => {
    let skillsArr;
    if (type === ON_SELECT_FROM_ROW) {
      skillsArr = tempSkillsArr
    } else {
      skillsArr = skills
    }
    const lastSkill = last(skillsArr);
    const lastSkillText = get(lastSkill, 'skill.skill', null);
    const operation = get(lastSkill, 'operation', DELETE);
    if (!lastSkillText && operation !== DELETE) {
      return;
    }
    getSkillIds(skillsArr);
    setSkills([
      ...skillsArr,
      {
        id: 1,
        skill: {
          id: 0,
          skill: '',
        },
        operation: ADD,
      },
    ]);
    setSkillStep([...skillStep, {}]);
    setRequestSkill([...requestSkill, {}]);
    setSkillsList([...skillsList, { options: [] }]);
    setContributeOption([...contributeOption, 0]);
    setSkillsUpdated(true);
  };

  const handleEducationInfoOnClick = () => {
    setEducations([...educations, { id: 1, operation: ADD }]);
  };

  const currencyDataForDropdown = getCurrencyDataForDropdown(currencyData);
  const employmentTypeList = get(profileData, 'employmentType.type', []);

  const initialValues = {
    fullName: profileData.name,
    phoneNumberPrefix: profileData.phone ? profileData.phone.split(' ')[0] : '',
    phoneNumber: profileData.phone ? profileData.phone.split(' ')[1] : '',
    email: profileData.email,
    address: profileData.address,
    city: profileData.city,
    state: profileData.state,
    country: profileData.country,
    zipcode: profileData.zip,
    nationality: profileData.nationality,
    years: candidateExpinYr || '',
    months: candidateExpinMon || '',
    startAvailability: profileData.startAvailability,
    // Check if expected salary currency available first
    expectedCtcCurrencyId: get(
      profileData,
      'expectedSalaryCurrency.id',
      get(
        getFirstCurrencyByCode(currencyData, profileData.expectedCtcCurrency),
        'id',
        null,
      ),
    ),
    // Check if current salary currency available first
    currentCtcCurrencyId: get(
      profileData,
      'currentSalaryCurrency.id',
      get(
        getFirstCurrencyByCode(currencyData, profileData.currentCtcCurrency),
        'id',
        null,
      ),
    ),
    expectedCtc: profileData.expectedCtc,
    willingToRelocate: profileData.willingToRelocate,
    currentSalary: profileData.annualCompensation,
    paymentCurrencyId: profileData.paymentCurrency
      ? profileData.paymentCurrency.id
      : get(getDefaultPaymentCurrency(currencyData), 'id', null),
    industryType: get(profileData, 'domains', []).map((domain) => domain.name),
    employmentType: isArray(employmentTypeList) ? employmentTypeList : [],
    profileLinks: get(profileData, 'profileLinks.links', []),
    unauthorizedCountries,
    authorizedCountries,
    categories: uniq(
      get(profileData, 'categories', []).map((category) =>
        get(category, 'category.id', ''),
      ),
    ),
  };

  const handleModalCancel = () => {
    form.resetFields();
    handleClose();
  };

  const handleResetFields = () => {
    userProfileResetFields(form);
  };

  const handleUpdateProfilePostEvent = () => {
    getWorkAuthorizationInfo();
  };

  const handleSelectDomains = (options) => {
    const newSelection = first(
      options.filter((name) => !domainsList.find((item) => item === name)),
    );
    if (newSelection) {
      getCategoriesForDomain(newSelection).then((categoriesList) => {
        const newCategories = categoriesList
          .map((item) => item.id)
          .filter((category) => !categories.find((id) => id === category.id));
        let temp = [...categories, ...newCategories];
        temp = uniq(temp);
        form.setFieldsValue({ categories: temp });
        setCategories(temp);
      });
    }
    setDomainsList(options);
  };

  const handleIndustryType = (industryType) => {
    handleSelectDomains(industryType);
    setDomains([]);
    profileData.domains.forEach((domain) => {
      setDomains((oldArr) => [
        ...oldArr,
        {
          id: domain.id,
          name: domain.name,
          operation: DELETE,
        },
      ]);
    });

    industryType.forEach((name) => {
      setDomains((oldArr) => [
        ...oldArr,
        {
          name,
          operation: ADD,
        },
      ]);
    });
  };

  const handleUnauthorizedCountriesChange = (countries) => {
    setUnauthorizedCountryIds(countries);
  };

  const handleAuthorizedCountriesChange = (countries) => {
    setAuthorizedCountryIds(countries);
  };

  const processWorkAuthorization = (unauthData, authData) => {
    const list = unauthData.concat(authData);
    const deleteList = workAuthorizationsData.filter(
      (item) => isArray(list) && !list.includes(toString(item.countryId)),
    );

    const finalUnauthList = unauthData.map((item) => {
      const workAuth = workAuthorizationsData.find(
        (o) => +o.countryId === +item,
      );
      return workAuth && !workAuth.isAuthorized
        ? null
        : {
          ...(workAuth && { id: workAuth.id }),
          operation: workAuth ? 'edit' : 'add',
          countryId: item,
          isAuthorized: false,
        };
    });
    const finalAuthList = authData.map((item) => {
      const workAuth = workAuthorizationsData.find(
        (o) => +o.countryId === +item,
      );
      return workAuth && workAuth.isAuthorized
        ? null
        : {
          ...(workAuth && { id: workAuth.id }),
          operation: workAuth ? 'edit' : 'add',
          countryId: item,
          isAuthorized: true,
        };
    });
    const finalDeletedList = deleteList.map((item) => {
      return {
        id: item.id,
        operation: 'delete',
        countryId: +item.countryId,
        isAuthorized: true,
      };
    });
    return finalAuthList
      .concat(finalUnauthList)
      .concat(finalDeletedList)
      .filter((item) => !!item);
  };

  const handleFinish = (data) => {
    const {
      fullName,
      phoneNumberPrefix,
      phoneNumber,
      address,
      city,
      state,
      country,
      zipcode,
      nationality,
      years = 0,
      months = 0,
      startAvailability,
      expectedCtcCurrencyId,
      currentCtcCurrencyId,
      expectedCtc,
      willingToRelocate,
      currentSalary,
      profileLinks,
      employmentType,
      paymentCurrencyId,
      authorizedCountries,
      unauthorizedCountries,
    } = data;
    const experience = years * 12 + months;

    const workAuthorizations = processWorkAuthorization(
      unauthorizedCountries,
      authorizedCountries,
    );

    const body = {
      name: fullName,
      phone: `${countryCodeOnlySplit(phoneNumberPrefix)} ${phoneNumber}`,
      address,
      city,
      state,
      country,
      zip: zipcode,
      nationality,
      experience,
      skills: skills.filter((el) => el.skill.id !== 0),
      startAvailability,
      expectedSalaryCurrencyId: expectedCtcCurrencyId,
      currentSalaryCurrencyId: currentCtcCurrencyId,
      expectedCtcCurrency: expectedCtcCurrencyId
        ? get(
          getCurrencyById(currencyData, expectedCtcCurrencyId),
          'value',
          null,
        )
        : null,
      currentCtcCurrency: currentCtcCurrencyId
        ? get(
          getCurrencyById(currencyData, currentCtcCurrencyId),
          'value',
          null,
        )
        : null,
      expectedCtc,
      willingToRelocate,
      profileLinks: profileLinksPayload(profileLinks),
      annualCompensation: currentSalary,
      educations,
      domains,
      employmentType: employmentType ? { type: employmentType } : null,
      paymentCurrencyId:
        paymentCurrencyId ||
        get(getDefaultPaymentCurrency(currencyData), 'id', null),
      workAuthorizations,
      categories: processCategory(
        get(profileData, 'categories', []).map((category) => ({
          id: category.id,
          categoryId: +get(category, 'category.id', null),
        })),
        categories,
      ),
    };

    if (!checkIfUserIsMasterAdmin(roleId)) {
      body.roleId = roleId;
    }

    const copyOfSkills = [...skills];

    const emptySkill = copyOfSkills.filter(
      (skill) =>
        !get(skill, 'skill.skill', null) &&
        get(skill, 'operation', DELETE) !== DELETE,
    );

    if (emptySkill.length) {
      message.error({
        content: `Empty skill is not allowed, Please select valid skill from the dropdown.`,
      });
      return;
    }

    if (areSkillsRepeating(skills)) {
      return;
    }

    if (
      copyOfSkills.filter((skill) => skill.operation !== 'delete').length > 4
    ) {
      if (checkIfUserIsMasterAdmin(roleId)) {
        updateCandidateProfileAdmin(profileData.userId, body).then(() => {
          Notification('success', 'Profile Updated successfully');
          handleUpdateProfilePostEvent();
          handleSuccess();
        });
        return;
      }
      dispatch(updateProfileData(body)).then(() => {
        if (areSkillsUpdated) {
          updateUserActionInGTM('candidate_skills_updated');
        }
        updateUserActionInGTM('profile_settings_updated');
        handleModalCancel();
        handleUpdateProfilePostEvent();
      });
      return;
    }
    Notification('error', 'Skills', 'Please add atleast 5 unique skills');
  };

  const handleResetPassword = async () => {
    history.push(CandidateRoutes.changePassword);
  };

  const idBase = 'personal_detail_modal';

  useEffect(() => {
    if (profileData.email) {
      checkIfUserExist(profileData.email, false, roleId).then((response) => {
        const { isPasswordSetOrNot } = response;
        setIsPasswordSetOrNot(isPasswordSetOrNot);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [profileData.email]);

  return (
    <Modal
      visible={visible}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      title="Profile Edit"
      theme={getThemeByRoleId(roleId)}
      zIndex={isMobile() && 1000}
    >
      <FormGroup
        form={form}
        name="PersonDetailModal"
        onFinish={handleFinish}
        expand
        initialValues={initialValues}
      >
        <div>
          <StyledDetailsContainer>
            <StyledProfileDetails color={getUserColorWithRoleId(roleId)}>
              Personal Details
            </StyledProfileDetails>
            {!checkIfUserIsMasterAdmin(roleId) ? (
              <StyledChangePassword onClick={handleResetPassword}>
                {isLoding ? (
                  <Spin />
                ) : (
                  `${isPasswordSetOrNot ? 'Change' : 'Create'} Password`
                )}
              </StyledChangePassword>
            ) : null}
          </StyledDetailsContainer>
        </div>
        <div>
          <Row>
            <UploadProfilePicComponent
              option
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              removeOption={!!profilePic}
              roleId={roleId}
              userId={profileData.userId}
              avatarSize={getSize(150, 140, 110)}
              userType="candidate"
              isCandidateEdit
            />
            <StyledBasicInfoContainer
              xs={{ span: 24, order: 2 }}
              lg={{ span: 18, order: 1 }}
              xl={{ span: 19, order: 1 }}
              xxl={{ span: 20, order: 1 }}
            >
              <Row gutter={24} align="middle">
                <Col xs={24} lg={8}>
                  <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                    Full Name <MandatoryStar />
                  </ProfileInformationHeader>
                  <TextBox
                    name="fullName"
                    placeholder="Full Name"
                    rule="text"
                  />
                </Col>
                <Col xs={24} lg={8}>
                  <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                    Phone Number
                  </ProfileInformationHeader>
                  <Row>
                    <Col xs={8} md={10}>
                      <CountryCodeInput name="phoneNumberPrefix" />
                    </Col>
                    <Col xs={16} md={14}>
                      <TextBox
                        name="phoneNumber"
                        placeholder="Phone Number"
                        maxLength={12}
                        rule="phoneNumber"
                        required={false}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={8}>
                  <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                    Email Address <MandatoryStar />
                  </ProfileInformationHeader>
                  <Email name="email" placeholder="Email Address" disabled />
                </Col>
              </Row>
              <AddressAutoComplete
                form={form}
                candidateAddressProfileEdit
                isCandidateEdit
              />
            </StyledBasicInfoContainer>
          </Row>
          <StyledWorkExpContainer>
            <div>
              <StyledHeader color={getUserColorWithRoleId(roleId)}>
                Work Experience
              </StyledHeader>
              <StyledDateAndTime>
                <NumberBox
                  name="years"
                  placeholder="Years"
                  max={50}
                  maxChar={2}
                  required={false}
                />

                <NumberBox
                  name="months"
                  placeholder="Months"
                  min={0}
                  max={11}
                  maxChar={2}
                  required={false}
                />
              </StyledDateAndTime>
              <SmallHeading color={COLORS.SUB_HEADER}>
                Share your overall work experience in Years/Months
              </SmallHeading>
            </div>
            <div>
              <StyledHeader color={getUserColorWithRoleId(roleId)}>
                Current Salary
              </StyledHeader>

              <Row>
                <Col xs={10}>
                  <Label isHidden id="currentCtcCurrencyId" />
                  <Dropdown
                    id="currentCtcCurrencyId"
                    name="currentCtcCurrencyId"
                    placeholder="Current Salary"
                    options={currencyDataForDropdown}
                    dropdownMatchSelectWidth={false}
                    required={false}
                    showArrow
                    showSearch
                  />
                </Col>

                <Col xs={14}>
                  <Label isHidden id="currentSalary" />
                  <NumberBox
                    id="currentSalary"
                    name="currentSalary"
                    placeholder="Current Salary"
                    maxChar={9}
                    required={false}
                  />
                </Col>
              </Row>
            </div>
          </StyledWorkExpContainer>

          <StyledEducationContainer>
            <StyledHeader color={getUserColorWithRoleId(roleId)}>
              Education Info
            </StyledHeader>
            <div>
              <div>
                <StyledAddEducationButton
                  type="dashed"
                  onClick={handleEducationInfoOnClick}
                >
                  + Add Educational Detail
                </StyledAddEducationButton>
              </div>
            </div>
            <HiddenMobile>
              <CreateEducationInfosRow
                educations={educations}
                setEducations={setEducations}
                headingColor={COLORS.CHINESE_BLACK}
              />
            </HiddenMobile>

            <ShowMobile>
              <CreateEducationInfosRow
                educations={educations}
                setEducations={setEducations}
                headingColor={COLORS.CHINESE_BLACK}
              />
            </ShowMobile>
          </StyledEducationContainer>

          <StyledCandidateSkillSet>
            <StyledHeader color={getUserColorWithRoleId(roleId)}>
              Skill Set <MandatoryStar />
            </StyledHeader>

            <div>
              <StyledAddEducationButton
                type="dashed"
                onClick={handleSkillsSetOnClick}
                id={`${idBase}_add-skill`}
              >
                + Add Skills
              </StyledAddEducationButton>
            </div>

            <CreateSkillsSetRow
              skills={skills}
              setSkills={setSkills}
              skillStep={skillStep}
              setSkillStep={setSkillStep}
              requestSkill={requestSkill}
              setRequestSkill={setRequestSkill}
              skillsList={skillsList}
              setSkillsList={setSkillsList}
              contributeOption={contributeOption}
              setContributeOption={setContributeOption}
              setSkillsUpdated={setSkillsUpdated}
              recommendedSkills={recommendedSkills}
              handleOnSelect={handleSkillsSetOnClick}
              personDetailModal={true}
            />
          </StyledCandidateSkillSet>

          <StyledAdditionalInfoContainer>
            <StyledHeader color={getUserColorWithRoleId(roleId)}>
              Additional Information
            </StyledHeader>
            <Row gutter={[16, 24]}>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Availability to Start
                </ProfileInformationHeader>
                <Dropdown
                  name="startAvailability"
                  placeholder=""
                  options={candidateAvailability}
                  required={false}
                />
              </Col>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Expected Salary
                </ProfileInformationHeader>
                <Row>
                  <Col xs={8} sm={8}>
                    <Label isHidden id="expectedCtcCurrencyId" />
                    <Dropdown
                      id="expectedCtcCurrencyId"
                      required={false}
                      name="expectedCtcCurrencyId"
                      placeholder="Expected Salary"
                      options={currencyDataForDropdown}
                      dropdownMatchSelectWidth={false}
                      showArrow
                      showSearch
                    />
                  </Col>
                  <Col xs={16} sm={16}>
                    <Label isHidden id="expectedCtc" />
                    <NumberBox
                      id="expectedCtc"
                      name="expectedCtc"
                      placeholder="Expected Salary"
                      maxChar={9}
                      required={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Willing to Relocate
                </ProfileInformationHeader>
                <Dropdown
                  name="willingToRelocate"
                  placeholder="Yes"
                  options={[
                    {
                      id: 1,
                      name: 'Yes',
                    },
                    {
                      id: 2,
                      name: 'No',
                    },
                  ]}
                  idValue
                  required={false}
                />
              </Col>
            </Row>

            <Row gutter={[16, 24]}>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Employement Type
                </ProfileInformationHeader>
                <Dropdown
                  name="employmentType"
                  placeholder="Employement Type"
                  options={employmentType}
                  mode="multiple"
                  idValue
                  required={false}
                />
              </Col>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Industry Type
                </ProfileInformationHeader>
                <IndustryTypes
                  mode="tags"
                  handleChange={handleIndustryType}
                  required={false}
                />
              </Col>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Your preferred event categories
                </ProfileInformationHeader>
                <CategoryDropdown
                  name="categories"
                  value={categories}
                  handleChange={setCategories}
                  required={false}
                />
              </Col>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Countries you are authorized to work
                </ProfileInformationHeader>
                <CountriesDropdown
                  mode="multiple"
                  handleChange={handleAuthorizedCountriesChange}
                  required={false}
                  name="authorizedCountries"
                  hideOptions={unauthorizedCountries}
                />
              </Col>
              <Col xs={24} md={8}>
                <ProfileInformationHeader color={COLORS.CHINESE_BLACK}>
                  Countries you are Unauthorized to work
                </ProfileInformationHeader>
                <CountriesDropdown
                  mode="multiple"
                  handleChange={handleUnauthorizedCountriesChange}
                  required={false}
                  name="unauthorizedCountries"
                  hideOptions={authorizedCountries}
                />
              </Col>
            </Row>
          </StyledAdditionalInfoContainer>

          <StyledSection>
            <ProfilesLinksList
              form={form}
              color={getUserColorWithRoleId(roleId)}
              isMasterAdminOfCandidate={isMasterAdminOfCandidate}
            />
          </StyledSection>

          <StyledSection>
            <EditCurrencySetting
              currenciesData={currencyDataForDropdown}
              color={getUserColorWithRoleId(roleId)}
            />
          </StyledSection>
          <div>
            <ModalButtonContainer handleResetFields={handleResetFields} />
          </div>
        </div>
      </FormGroup>
    </Modal>
  );
};

export default PersonDetailModal;

const StyledProfileDetails = styled(StyledMediumHeading)`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  letter-spacing: 1px;
  height: max-content;
`;

const StyledDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  ${MARGIN(-10, 0, 20, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, 0, 20, 0)}
  }
`;

const StyledChangePassword = styled(Button)`
  border: none;
  background-color: ${COLORS.VLIGHT_GRAY};
  width: 275px;
  height: 39px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 185px;
    height: 30px;

    span {
      font-size: 12px;
    }
  }
`;

const StyledHeader = styled(StyledMediumHeading)`
  padding-bottom: 24px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${MARGIN(25, 0, 0, 0)}
`;

const StyledWorkExpContainer = styled.div`
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    gap: 0;
  }
`;

const StyledDateAndTime = styled.div`
  display: flex;
  grid-gap: 30px;
  gap: 30px;
`;

const StyledAddEducationButton = styled(Button)`
  width: 100%;
  height: auto;
  border: 1px dashed ${COLORS.PLACEHOLDER};
  border-radius: 3px;
  max-width: 415px;
  color: ${COLORS.PLACEHOLDER};
  ${PADDING(8, 0)}
  ${MARGIN(0, 0, 5, 0)}
`;

const StyledCandidateSkillSet = styled.div`
  .ant-steps-small .ant-steps-item-tail {
    top: -6px;
  }

  .ant-steps-item {
    .ant-steps-item-icon {
      ${DISPLAY_FLEX('row', 'center', 'center')}
      .ant-steps-icon {
        margin-top: 2px;
      }
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      color: ${COLORS.RICH_BLUE};
    }

    .ant-steps-item-icon {
      border: 3px solid ${COLORS.RICH_BLUE};
      color: ${COLORS.RICH_BLUE};
      background-color: ${COLORS.WHITE};
      z-index: 11;
      position: relative;
    }

    .ant-steps-item-icon {
      .ant-steps-icon {
        color: ${COLORS.RICH_BLUE};

        svg {
          fill: ${COLORS.RICH_BLUE};
        }
      }
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      z-index: 11;
      position: relative;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border: 3px solid ${COLORS.RICH_BLUE};
      z-index: 11;
      position: relative;
    }

    svg {
      fill: ${COLORS.RICH_BLUE};
    }

    .ant-steps-item-tail {
      &::after {
        background-color: ${COLORS.RICH_BLUE};
      }
    }

    .ant-steps-item-title {
      color: ${COLORS.RICH_BLUE};
    }
  }

  .ant-steps-item-title {
    font-size: ${FONT[13]};
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[10]};
    }
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]};
    }
  }

  .ant-select {
    margin-bottom: 20px;
  }
`;

const StyledEducationContainer = styled.div`
  margin-top: 35px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 30px;
  }
`;

const StyledAdditionalInfoContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 30px;
  }
`;

const StyledSection = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 25px 0;
  }
`;

const StyledBasicInfoContainer = styled(Col)`
  margin-left: 40px;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-left: 0px;
  }
`;
