/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import styled from 'styled-components';
import {
  HiddenLabelProps,
  LabelProps,
} from '../../interfaces/components/label';
import { LargeFont } from './Texts';

export const HiddenLabel: React.FC<HiddenLabelProps> = ({ id }) => {
  return (
    <Hidden>
      <label htmlFor={id} />
    </Hidden>
  );
};

const Label: React.FC<LabelProps> = ({ isHidden = false, title, id }) => {
  return isHidden ? (
    <HiddenLabel id={id} />
  ) : (
    <label htmlFor={id}>
      <LargeFont>{title}</LargeFont>
    </label>
  );
};

export default Label;

const Hidden = styled.div`
  display: none;
`;
