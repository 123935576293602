import React from 'react';
import styled from 'styled-components';
import { Row, Col, Avatar } from 'antd';
import get from 'lodash/get';
import TextArea from 'antd/lib/input/TextArea';
import toLower from 'lodash/toLower';
import { InputBox } from '../../shared-components/Form/Form';
import {
  ACCEPTED_IMAGE_EXTENSIONS,
  LOGO_IMAGE_HINT,
} from '../../../constants/file';
import { CROP_POFILE_PIC_OPTIONS, validateURL } from '../../../constants';
import Notification from '../../Notification/Notification';
import { TextUpload } from '../../shared-components/Upload';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { getFileS3 } from '../../../utils/s3.storage';
import { SHADOWS } from '../../../styles/constants/shadows';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import SpeakerAndHostInfo from './extras/SpeakerAndHostInfo';
import { getRoleId } from '../../../utils/userInfo';
import { PrimaryButton } from '../../shared-components/Button';
import { getThemeByRoleId } from '../../../utils/common';

const SpeakersAndHost = ({
  type,
  customInput,
  info,
  uploadEventFile,
  handleFileChange,
  handleAddCompany,
  handleEditCompany,
  handleDeleteEntity,
  fileList,
  setInfo,
  isTabActive,
  isEdit,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const roleId = getRoleId();

  React.useEffect(() => {
    const { name, pic, designation, brief, links } = info;
    setIsActive(
      name &&
      pic &&
      designation &&
      links.length &&
      validateURL(links[0]) &&
      ((type === 'Speakers' && brief) || type !== 'Speakers'),
    );
    // eslint-disable-next-line
  }, [info]);

  const handleName = (e) => {
    const value = get(e, 'target.value');
    const entity = type === 'Speakers' ? 'speakers' : 'hosts';
    const res = customInput[entity].find(
      (enity) => toLower(enity.name) === toLower(value),
    );
    if (res) {
      Notification('error', `Add ${entity}`, `${entity} already exist`);
    }

    setInfo((prevValue) => ({
      ...prevValue,
      name: value,
      ...(isEdit && type === 'Speakers' && prevValue.operation === 'none'
        ? { operation: 'edit' }
        : {}),
    }));
  };

  const handleDesignation = (val) => {
    const value = get(val, 'target.value');
    setInfo((prevValue) => ({
      ...prevValue,
      designation: value,
      ...(isEdit && type === 'Speakers' && prevValue.operation === 'none'
        ? { operation: 'edit' }
        : {}),
    }));
  };

  const handleLink = (val) => {
    const value = get(val, 'target.value');
    setInfo((prevValue) => ({
      ...prevValue,
      links: [value],
      ...(isEdit && type === 'Speakers' && prevValue.operation === 'none'
        ? { operation: 'edit' }
        : {}),
    }));
  };

  const handleDescription = (val) => {
    const value = get(val, 'target.value');
    setInfo((prevValue) => ({
      ...prevValue,
      brief: value,
      ...(isEdit && type === 'Speakers' && prevValue.operation === 'none'
        ? { operation: 'edit' }
        : {}),
    }));
  };

  return (
    <>
      <HostSpeakerText> {type === 'Speakers' ? 'Speakers' : 'Hosts'}</HostSpeakerText>
      <StyledContainer>
        <StyedRow>
          <Col xs={24} md={4}>
            <StyledUploadLogoWrapper>
              <StyledInnerLogoWrapper>
                <Avatar
                  src={getFileS3(get(info, 'pic'))}
                  alt="recruiter-logo"
                />
                <TextUpload
                  style={{ backgroundColor: 'transparent' }}
                  name="changeSponsorLogo"
                  text={getFileS3(get(info, 'pic')) ? "Upload Logo" : "Add Logo"}
                  customRequestHandler={(data) =>
                    uploadEventFile(
                      data,
                      type === 'Speakers' ? 'speaker' : 'host',
                    )
                  }
                  onChange={(data) =>
                    handleFileChange(
                      data,
                      type === 'Speakers' ? 'speaker' : 'host',
                    )
                  }
                  accept={ACCEPTED_IMAGE_EXTENSIONS}
                  hintText={LOGO_IMAGE_HINT}
                  roleId={roleId}
                  maxCount={1}
                  cropActive
                  cropOptions={CROP_POFILE_PIC_OPTIONS}
                  placement="bottomLeft"
                  id="add_company_logo"
                />
              </StyledInnerLogoWrapper>
            </StyledUploadLogoWrapper>
          </Col>
          <Col xs={24} md={5} lg={6}>
            <label htmlFor="speaker_name">
              <StyledInfoHeader id="speaker_name_label">
                Name
              </StyledInfoHeader>
            </label>
            <InputBox
              name="name"
              placeholder="Enter Name"
              value={info.name}
              handleChange={handleName}
              rule="text"
              id="speaker_name"
              required={false}
            />
          </Col>
          <Col xs={24} md={5} lg={6}>
            <label htmlFor="speaker_designation">
              <StyledInfoHeader id="speaker_designation">
                Designation
              </StyledInfoHeader>
            </label>
            <InputBox
              name="designation"
              placeholder="Enter Designation"
              value={info.designation}
              handleChange={handleDesignation}
              id="speaker_designation"
              required={false}
            />
          </Col>
          <Col xs={24} md={5} lg={6}>
            <label htmlFor="speaker_link">
              <StyledInfoHeader id="speaker_link">Link</StyledInfoHeader>
            </label>
            <InputBox
              name="link"
              placeholder="https://www.facebook.com/event-page"
              rule="url"
              value={get(info, 'links[0]', '')}
              handleChange={handleLink}
              id="speaker_link"
              required={false}
            />
          </Col>
        </StyedRow>
        {type === 'Speakers' ? (
          <>
            <StyedRow>
              <Col xs={24} md={4} />
              <Col xs={24} md={19}>
                <label htmlFor="speaker_description">
                  <StyledInfoHeader id="speaker_designation_label">
                    Description
                  </StyledInfoHeader>
                </label>
                <TextArea
                  name="description"
                  required={false}
                  placeholder="Description"
                  value={info.brief}
                  onChange={handleDescription}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  maxLength={250}
                  id="speaker_description"
                  showCount={true}
                />
              </Col>
            </StyedRow>
          </>
        ) : null}

        {/* upload official */}
      </StyledContainer>

      <ButtonWrapper>
        <StyedRow>
          <Col xs={24} md={4} />
          <PrimaryButton
            theme={getThemeByRoleId(roleId)}
            onClick={
              type === 'Speakers'
                ? () => handleAddCompany('speakers')
                : () => handleAddCompany('hosts')
            }
            disabled={!isActive}
          >
            + Add {type}
          </PrimaryButton>
        </StyedRow>
      </ButtonWrapper>

      <SpeakerAndHostInfo
        type={type}
        handleEditCompany={handleEditCompany}
        entities={get(
          customInput,
          type === 'Hosts' ? 'hosts' : 'speakers',
          [],
        )}
        handleFileChange={handleFileChange}
        uploadEventFile={uploadEventFile}
        handleDeleteEntity={handleDeleteEntity}
      />
    </>
  );
};

export default SpeakersAndHost;

const StyledContainer = styled.div`
  clear: both;
  overflow: hidden;
`;

const HostSpeakerText = styled.h2`
  ${MARGIN(10, 0, 0, 0)};
  font-size: ${FONT[17]};
  font-weight: ${FONT_WEIGHTS.BOLD};
`

const ButtonWrapper = styled.div`
  ${MARGIN(30, 0, 0, 0)};
`

const StyledInfoHeader = styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    ${MARGIN(0, 0, 8, 0)};
  }
`;

const StyledUploadLogoWrapper = styled.div`
  ${MARGIN(20, 0, 0, 0)};
  ${DISPLAY_FLEX('row', 'center', 'center')}

  button {
    height: 40px;
  }
`;

const StyledInnerLogoWrapper = styled.div`
  text-align: center;
  max-width: 140px;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 0 auto;
  }

  span.ant-avatar {
    width: 110px;
    height: 110px;
    box-shadow: ${SHADOWS.SHADOW2};
  }

  .anticon{
    top: 4px;
  }
`;

const StyedRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap: 20px;
  row-gap: 10px !important;
`;
