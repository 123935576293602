import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import { userProfileStore } from '../../utils/profile';
import { getRoleId, getUserTypeWithRole } from '../../utils/userInfo';
import {
  changeChatState,
  changeChatWindow,
  getSelectedChatDetails,
} from '../../store/features/chat';
import MessageInput from '../Chat/MessageThreads/MessageInput';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import Thread from './Thread';
import { getSingleUserData } from '../../utils/chat';
import { getFileS3 } from '../../utils/s3.storage';
import { CloseChat, LeftArrow } from '../svgicons';
import SocketContext from '../../context/socket';
import { COLORS } from '../../styles/constants/colors';
import AvatarOnline from '../Chat/AvatarOnline';
import { RECRUITER } from '../../constants';

const ThreadContainer = () => {
  const dispatch = useDispatch();
  const client = useContext(SocketContext);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const {
    selectedChatId,
    selectedChatDetails,
    selectedChatUsersDetails,
    personalChatsUsersProfileData: usersProfileData,
    isChatOpen,
    chatWindow,
    // @ts-ignore
  } = useSelector((state) => state.chat);

  const {
    profileData: { userId, company },
  } = userProfileStore();
  const roleId = getRoleId();

  const receiverUserId = get(selectedChatDetails, 'user.id', '');
  let receiverProfileDetails;
  const userType = get(selectedChatDetails, 'user.type', '');
  if (userType === 'company') {
    receiverProfileDetails = getSingleUserData(
      null,
      receiverUserId,
      selectedChatUsersDetails,
    );
  } else {
    receiverProfileDetails = getSingleUserData(
      receiverUserId,
      null,
      selectedChatUsersDetails,
    );
  }

  const receiverProfilePic = get(receiverProfileDetails, 'profilePic', '');
  const description = getUserTypeWithRole(
    get(receiverProfileDetails, 'roleId', RECRUITER),
  );

  const name = get(receiverProfileDetails, 'name', '');
  const senderId = get(receiverProfileDetails, 'id', '');
  const type = get(receiverProfileDetails, 'type', '');

  const loggedInUserId = company ? company.id : userId;

  useEffect(() => {
    if (selectedChatId && loggedInUserId && usersProfileData) {
      dispatch(
        getSelectedChatDetails(
          selectedChatId,
          loggedInUserId,
          usersProfileData,
          roleId,
        ),
      );
    }

    // eslint-disable-next-line
  }, [dispatch, selectedChatId, loggedInUserId, usersProfileData]);

  useEffect(() => {
    if (senderId && type) {
      (async () => {
        await client.subscribe(
          `/user/${senderId}/${type}/`,
          async (resp: any) => {
            const newStatus = get(resp, 'isOnline', null);

            setIsOnline(newStatus);
          },
        );
        await client.message({ type: 40, userId: senderId, userType: type });
      })();
    }
    // eslint-disable-next-line
  }, [client, senderId, type]);

  return (
    <StyledMessageThread chatOpened={isChatOpen}>
      {selectedChatDetails ? (
        <>
          <StyledChatUserHeader>
            <LeftArrow onClick={() => dispatch(changeChatState(!isChatOpen))} />
            <StyledAvatarInfoContainer>
              <AvatarOnline
                picture={getFileS3(receiverProfilePic) || ''}
                isOnline={isOnline}
              />
              <StyledInfoContainer>
                <h3>{description}</h3>
                <p>{name}</p>
              </StyledInfoContainer>
            </StyledAvatarInfoContainer>
            <CloseChat
              onClick={() => dispatch(changeChatWindow(!chatWindow))}
            />
          </StyledChatUserHeader>
          <Thread />
          <MessageInput isChatBubble />
        </>
      ) : (
        <Skeleton />
      )}
    </StyledMessageThread>
  );
};

const StyledMessageThread = styled.div<any>`
  ${DISPLAY_FLEX('column')}
  min-height: 450px;
  max-height: 500px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    min-height: unset;
    max-height: unset;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const StyledChatUserHeader = styled.div`
  background-color: ${COLORS.DODGER_BLUE};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px 9px 0px 0px;
  opacity: 1;
  color: white;
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
  padding: 14px;
  font: 16px;
  z-index: 1;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    border-radius: unset;
  }

  svg {
    &:first-child {
      width: 14px;
      height: 14px;
    }

    &:last-child {
      width: 13px;
      height: 13px;
    }
  }
`;

const StyledInfoContainer = styled.div`
  ${DISPLAY_FLEX('column', 'center')}
  color: white;

  h3 {
    color: white;
    margin: 0;
    font-size: 16px;
  }

  p {
    opacity: 0.5;
    font-size: 12px;
  }
`;

const StyledAvatarInfoContainer = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}
  gap:8px;
`;

export default ThreadContainer;
