import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import styled from 'styled-components';
import { getFileS3 } from '../../../utils/s3.storage';
import { timeAgo } from '../../../utils/chat';
import { getUserTypeWithTheme } from '../../../utils/userInfo';
import {
  ReceiverThreadProps,
  ThreadDetailHeadingProps,
} from '../../../interfaces/components/Chat';
import { AvatarPicture } from '../AvatarPicture';
import { StyledLargeFont, StyledSmallFont } from '../Texts';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { GIF } from './GIF';
import { MESSAGE_CONTENT_TYPE } from '../../../constants/chat';

export const ReceiverThread: React.FC<ReceiverThreadProps> = ({
  messageData,
  profilePic,
  isWithoutIcon,
}) => {
  const { message, createdAt, dateTime, contentType } = messageData;
  const time = createdAt || dateTime;
  const picture = getFileS3(profilePic) || '';

  const { selectedChatDetails } = useSelector((state: any) => state.chat);

  const userTheme = get(selectedChatDetails, 'user.theme', '');
  const userType = getUserTypeWithTheme(userTheme).toLowerCase();

  const getBgColor = (localUserType: string) => {
    if (localUserType === 'candidate') return COLORS.RICH_BLUE;
    if (localUserType === 'recruiter') return COLORS.SUPER_NOVA;
    return COLORS.PUERTO_RICO;
  };

  const getColor = (localUserType: string) => {
    return localUserType === 'candidate' ? COLORS.WHITE : COLORS.CHINESE_BLACK;
  };

  return (
    <Thread id="chat-receiver-thread">
      {isWithoutIcon ? null : <AvatarPicture picture={picture} size={35} />}

      <ThreadDetail isWithoutIcon={isWithoutIcon}>

        {
          contentType !== MESSAGE_CONTENT_TYPE.GIF ?
            (<MediumHeading
              bgColor={getBgColor(userType)}
              color={getColor(userType)}
              isWithoutIcon={isWithoutIcon}
            >
              {message}
            </MediumHeading>) : <GIF message={message} contentType={contentType} />
        }

        {time ? (
          <TimeHeading isWithoutIcon={isWithoutIcon}>
            {timeAgo(time)}
          </TimeHeading>
        ) : null}
      </ThreadDetail>
    </Thread>
  );
};

const Thread = styled.div`
  ${DISPLAY_FLEX('row', null, 'center')}

  margin: 10px 0;
  align-items: center;

  span {
    align-self: flex-end;
    margin-bottom: 20px;
  }
`;

const ThreadDetail = styled(StyledSmallFont) <any>`
  ${DISPLAY_FLEX('column')}

  margin-left: ${({ isWithoutIcon }) => (isWithoutIcon ? '5px' : '10px')};
  color: ${COLORS.ARSENIC};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  width: ${({ isWithoutIcon }) => (isWithoutIcon ? '85%' : '50%')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const MediumHeading = styled(StyledLargeFont) <ThreadDetailHeadingProps>`
  ${DISPLAY_FLEX('row', null, 'center')}

  align-items: center;
  border-radius: 10px 10px 10px 0;
  padding: ${({ isWithoutIcon }) => (isWithoutIcon ? `11px` : '14px')};
  width: fit-content;
  max-width: 100%;
  min-height: 0;
  word-break: break-word;
  white-space: normal;
  hyphens: auto;
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  background-color: ${(props) => props.bgColor};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    min-height: ${({ isWithoutIcon }) => (isWithoutIcon ? `unset` : '66px')};
  }
`;

const TimeHeading = styled(StyledSmallFont) <any>`
  font-size: ${FONT[10]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.EERIE_BLACK};
  opacity: 60%;
  margin-top: 5px;
  ${({ isWithoutIcon }) =>
    isWithoutIcon
      ? ''
      : `
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: inherit;
  }
  `};
`;
