import {
  CONTRACT_TEXT,
  FREELANCING_TEXT,
  FULLTIME_TEXT,
  INTERNSHIP_TEXT,
  JobApplicationSelectedText,
  LOCATION_TEXT,
  PARTTIME_TEXT,
  PERMANENTEMPLOYMENT_TEXT,
  REMOTE_TEXT,
  SHOW_ALL_TEXT,
} from './index';

export const jobs = [
  { id: '1', value: 'Site Engineer' },
  { id: '2', value: 'Data Scientist' },
  { id: '3', value: 'Architect' },
  { id: '4', value: 'Product Engineer' },
  { id: '5', value: 'Adobe' },
  { id: '6', value: 'Figma' },
  { id: '7', value: 'UI Design' },
  { id: '8', value: 'UX Design' },
];

export const JOB_APPLICATION_STATUS = {
  APPROVE: 10,
  REJECT: 20,
  REVIEWED: 30,
  HOLD: 40,
  SUBMITTED: 50,
};

export const JobStatusOptions = [
  { id: '1', name: 'All jobs' },
  { id: '2', name: 'Active' },
  { id: '3', name: 'Inactive' },
];

export const JobPriorityOptions = [
  { id: '1', name: 'Any' },
  { id: '2', name: 'High' },
  { id: '3', name: 'Low' },
];

export const CandidateStatusFilterOptions = [
  { id: 1, name: 'Show All' },
  { id: 2, name: 'Submitted' },
  { id: 3, name: JobApplicationSelectedText },
  { id: 4, name: 'Under Review/Skipped' },
];

export const JobsTypeFilterOptions = [
  { id: null, name: SHOW_ALL_TEXT },
  { id: 1, name: FULLTIME_TEXT },
  { id: 0, name: PARTTIME_TEXT },
];

export const JobsEngagementFilterOptions = [
  { id: null, name: SHOW_ALL_TEXT },
  { id: 1, name: INTERNSHIP_TEXT },
  { id: 2, name: PERMANENTEMPLOYMENT_TEXT },
  { id: 3, name: CONTRACT_TEXT },
  { id: 4, name: FREELANCING_TEXT },
];

export const JobsLocationFilterOptions = [
  { id: null, name: SHOW_ALL_TEXT },
  { id: 1, name: REMOTE_TEXT },
  { id: 2, name: LOCATION_TEXT },
];

export const applicationStatusFilterOptions = [
  { id: 1, name: 'Show all' },
  { id: JOB_APPLICATION_STATUS.SUBMITTED, name: 'By unreviewed' },
  { id: JOB_APPLICATION_STATUS.APPROVE, name: 'By shortlist' },
  { id: JOB_APPLICATION_STATUS.REJECT, name: 'By skip' },
  { id: JOB_APPLICATION_STATUS.HOLD, name: 'By hold' },
];

export const jobApplicationStatusFilterOptions = [
  { id: 1, name: 'Show all' },
  { id: JOB_APPLICATION_STATUS.SUBMITTED, name: 'By unreviewed' },
  { id: JOB_APPLICATION_STATUS.REJECT, name: 'By rejected' },
];

export const applicationAnalyticsFilterOptions = [
  { name: 'Applications', statusCode: 0 },
  { name: 'Unreviewed', statusCode: JOB_APPLICATION_STATUS.SUBMITTED },
  { name: 'Shortlisted', statusCode: JOB_APPLICATION_STATUS.APPROVE },
  { name: 'On Hold', statusCode: JOB_APPLICATION_STATUS.HOLD },
  { name: 'Skipped', statusCode: JOB_APPLICATION_STATUS.REJECT },
];

export const getApplicationFilterOptions = () => {
  return applicationAnalyticsFilterOptions.map((option) => {
    return {
      id: option.statusCode,
      name: option.name,
      status: option.statusCode,
    };
  });
};
