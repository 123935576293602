import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styles/constants/colors';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { getRoleId, getUserId } from '../../utils/userInfo';
import { checkIfUserIsCandidate } from '../../utils/userInfo';
import CelebrateIcon from '../../assets/svg/celebrate_icon.svg';
import PoapIcon from '../../assets/svg/poap_icon.svg';
import Cross from '../../assets/svg/cross_sign.svg';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';


const HeaderNotification = () => {
  const [isVisible, setIsVisible] = useState(false);

  const roleId = getRoleId();
  const isCandidate = checkIfUserIsCandidate(roleId);
  const userId = getUserId();

  const closeHandler = () => {
    setIsVisible(false);
    localStorage.setItem(`headerMessage${userId}`, JSON.stringify({ isVisible: false }));
  }

  // we can use this component to show the header message if user is login for the first time.
  // useEffect(() => {
  //   const isVisibleInStorage = JSON.parse(localStorage.getItem(`headerMessage${userId}`));
  //   if (isVisibleInStorage === null || isVisibleInStorage === undefined) {
  //     // if user's 'headerMessage' key is not in localStorage, or new user is login then we set the
  //     // 'headerMessage' with userId dynamically in local storage.
  //     setIsVisible(true);
  //     localStorage.setItem(`headerMessage${userId}`, JSON.stringify({ isVisible: true }));
  //   } else {
  //     isVisibleInStorage.isVisible === true ? setIsVisible(true) : setIsVisible(false);
  //   }
  // }, [userId])

  return (
    <>
      {isCandidate && isVisible ?
        <StyledPopupContainer>
          <span>You are early <StyledIcon src={CelebrateIcon} alt="" /> . The Apply2Earn feature is coming soon! In the meantime, attend events and claim your free POAP NFT <StyledIcon src={PoapIcon} alt="" />
          </span>
          <CloseIcon src={Cross} alt="Close" onClick={closeHandler} />
        </StyledPopupContainer>
        : null
      }
    </>
  )
}

export default HeaderNotification

const StyledPopupContainer = styled.div`
  text-align: center; 
  color:${COLORS.WHITE};
  background-image: linear-gradient(45deg,${COLORS.PURPLE_SHADE_1},${COLORS.SKY_BLUE_LIGHT});
  ${MARGIN(0)};
  ${PADDING(10, 40)};
  position: relative;
  >span{
    font-size: 14px;
  }
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(5, 40)};
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
    ${PADDING(5, 30)};
  }
  `
const StyledIcon = styled.img`
height: 20px;
width: 20px;
`
const CloseIcon = styled.img`
height: 14px;
width: 14px;
position: absolute;
right:30px;
top: 30%;

@media (min-width:${BREAKPOINTS.MOBILE}) and (max-width:${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
  right: 20px;
}

@media (max-width: ${BREAKPOINTS.MOBILE}) {
  right:20px;
  top: 40%;
}
@media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1}) {
  right:15px;
  top: 40%;
  }
`
