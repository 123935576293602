import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { checkIfUserIsCandidate, getRoleId } from '../../utils/userInfo';
import { getCompanyDetails } from '../../store/features/jobOpportunities';
import { getFileS3 } from '../../utils/s3.storage';
import ProfileLinksComponent from '../ProfileLinks/ProfileLinks';
import { StyledModal } from '../shared-components/Modal';
import { StyledLargeFont } from '../shared-components/Texts';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MainButton } from '../shared-components/Button';
import { ResponsiveModalHeader } from '../shared-components/ResponsiveModalHeader';
import { HiddenMobile } from '../shared-components/DocumentViewerModal';
import UserAvatar from '../shared-components/Common/UserAvatar';

function OrganizerProfileInfoDetails({ event, visible, handleModalClose }) {
  const roleId = getRoleId();

  const dispatch = useDispatch();

  const currentTitle = 'Organizer Profile Info';

  const [companyDetails, setCompanyDetails] = useState();

  useEffect(() => {
    const getDetailsOfCompany = dispatch(getCompanyDetails(event.companyId));
    getDetailsOfCompany.then((data) => {
      const { company } = data.payload.company;
      setCompanyDetails(company);
    });
  }, [roleId, event.companyId, event.id, dispatch]);

  const links = get(companyDetails, 'profileLinks.links', []);

  return (
    <Modal
      visible={visible}
      theme={checkIfUserIsCandidate(roleId) ? 'candidate' : 'recruiter'}
      width="80%"
      title={
        <>
          <ResponsiveModalHeader
            handleModalCancel={handleModalClose}
            title={currentTitle}
          />
          <HiddenMobile>{currentTitle}</HiddenMobile>
        </>
      }
      footer={
        <MainButton
          theme={checkIfUserIsCandidate(roleId) ? 'candidate' : 'recruiter'}
          onClick={handleModalClose}
        >
          Close
        </MainButton>
      }
      onCancel={handleModalClose}
    >
      {companyDetails && (
        <>
          <StyledBasicDetails>
            <StyledInfo>
              <div>
                <StyledLabel>Company Name</StyledLabel>
                <StyledLabelText fontWeight={FONT_WEIGHTS.MEDIUM}>
                  {companyDetails.name}
                </StyledLabelText>
              </div>

              <div>
                <StyledLabel>Industry Type</StyledLabel>
                <StyledLabelText>{companyDetails.domain}</StyledLabelText>
              </div>

              <div>
                <StyledLabel>Company Website</StyledLabel>
                <StyledLabelText>{companyDetails.website}</StyledLabelText>
              </div>
            </StyledInfo>

            <StyledLogo>
              {companyDetails.logo ? (
                <img src={getFileS3(companyDetails.logo)} alt="logo" />
              ) : (
                <UserAvatar />
              )}
            </StyledLogo>
          </StyledBasicDetails>

          <StyledDescription>
            <div>
              <StyledLabel>Description</StyledLabel>
              <StyledLabelText>{companyDetails.brief}</StyledLabelText>
            </div>
          </StyledDescription>

          {links.length !== 0 ? (
            <StyledProfileLink>
              <StyledLabel>Company Profile Link</StyledLabel>
              <ProfileLinksComponent
                profileLinks={get(companyDetails, 'profileLinks.links', [])}
              />
            </StyledProfileLink>
          ) : null}
        </>
      )}
    </Modal>
  );
}

const StyledProfileLink = styled.div`
  margin-bottom: 15px;
`;

const Modal = styled(StyledModal)`
  .ant-modal-content {
    height: 100%;
    overflow-y: scroll;
  }
`;

const StyledLabel = styled(StyledLargeFont)`
  opacity: 0.6;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  margin: 10px 0;
`;

const StyledLabelText = styled(StyledLargeFont)`
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  flex-wrap: wrap;
  column-gap: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    & > div:not(:last-child) {
      margin-bottom: 15px;
    }

    width: 100%;
    margin-top: 20px;
  }

  & > div:nth-child(2) {
    max-width: 100%;
  }
`;

const StyledBasicDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 120px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 0;
  }
`;

const StyledDescription = styled.div`
  margin-bottom: 15px;

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin: 15px 0;
  }
`;

const StyledLogo = styled.div`
  margin-left: -100px;

  img,
  span {
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    align-self: center;
    margin-top: 20px;
    position: inherit;
    margin-left: 0;

    img,
    span {
      width: 100px;
      height: 100px;
    }
  }
`;

export default OrganizerProfileInfoDetails;
