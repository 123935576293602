import React from 'react';
import styled from 'styled-components';
import { ChatInput } from '../../shared-components/Chat/ChatInput';
// import { SmallHeading } from '../../shared-components/Texts';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN, PADDING } from '../../../styles/constants/display';
// import { COLORS } from '../../../styles/constants/colors';

const ThreadInput = ({ discussionRoom }) => {
  return (
    <StyledThreadInputDiv discussionRoom={discussionRoom}>
      {/* TODO */}
      {/**
       * <SmallHeading>
            <StyledSomeoneTypingText>
              {name ? `${name.split(' ')[0]} is typing...` : ''}
            </StyledSomeoneTypingText>
          </SmallHeading>
      */}
      <ChatInput type="group" discussionRoom={discussionRoom} />
    </StyledThreadInputDiv>
  );
};

const StyledThreadInputDiv = styled.div`
  ${PADDING(10, 16, 0, 0)};
  justify-self: flex-end;
  flex-shrink: 0;
  margin-top: ${({ discussionRoom }) => discussionRoom && '15px'};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ discussionRoom }) => (discussionRoom ? 'margin: 0' : MARGIN(0, 10))};
    ${PADDING(10, 6, 0, 0)};
  }
`;

// const StyledSomeoneTypingText = styled.p`
//   @include small-heading-mixin;

//   ${MARGIN(5, 0)};
//   color: ${COLORS.ARGENT};
// `;

export default ThreadInput;
