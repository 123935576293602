import React from 'react';
import styled from 'styled-components';
import { PageCircleProps } from '../../interfaces/common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';

const Circle: React.FC<PageCircleProps> = ({ color, hideForMobile }) => {
  return (
    <StyledContainer>
      <StyledCircle color={color} hideForMobile={hideForMobile} />
    </StyledContainer>
  );
};

export default Circle;

const StyledContainer = styled.div`
  position: relative;
`;

const StyledCircle = styled.div<PageCircleProps>`
  background: ${(props) => props.color || COLORS.DODGER_BLUE};
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.16;
  position: absolute;
  left: -100px;
  top: -95px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    width: 280px;
    height: 280px;
    left: -110px;
    top: -100px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 250px;
    height: 250px;
    display: ${({hideForMobile}) => hideForMobile && "none"};
  }
`;
