import get from 'lodash/get';
import api from './api';
import { APIs, Method } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';

const url = APIs.candidate;

export const LinkWalletToProfile = async (walletAddress) => {
  return api({
    url: `${url}poap/wallet-address`,
    method: Method.put,
    body: {
      walletAddress,
    },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getClaimedPoaps = async () => {
  return api({
    url: `${url}poap/claimed`,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.poaps', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const claimPoapByEvent = async (eventId) => {
  return api({
    url: `${url}poap/claim`,
    method: Method.post,
    body: { eventId },
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload');
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
