import { ChangeEvent, useState, useCallback } from 'react';
import { sendMessageToSlack } from '../service/slackUtils';
import { userProfileStore } from '../utils/profile';
import get from 'lodash/get';
import Notification from '../components/Notification/Notification';

const useSocialMediaInput = (skipPayment: () => void) => {
  const [isStartup, setIsStartup] = useState<boolean>(false);
  const [socialMediaLink, setSocialMediaLink] = useState<string>('');
  const { profileData } = userProfileStore();
  const [linkError, setLinkError] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });

  const companyName = get(profileData, 'company.name');
  const companyEmail = get(profileData, 'representativeEmail');
  const companyPhoneNo = get(profileData, 'representativePhone');

  const socialMediaChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSocialMediaLink(e.target.value);
    setLinkError({
      error: false,
      message: '',
    });
  };

  const resetEveryThing = () => {
    setIsStartup(false);
    setSocialMediaLink('');
    setLinkError({
      error: false,
      message: '',
    });
  };

  const enableStartupView = useCallback(() => {
    setIsStartup(true);
  }, []);

  const handleStartupSocialLink = useCallback(async () => {
    if (socialMediaLink.length === 0) {
      setLinkError({
        error: true,
        message: 'Please enter a way for us to reach out to you.',
      });
      return;
    }
    if (
      !socialMediaLink.startsWith('http://') &&
      !socialMediaLink.startsWith('https://') &&
      !socialMediaLink.startsWith('www.')
    ) {
      setLinkError({
        error: true,
        message: 'please enter a valid url',
      });
      return;
    }
    const message = `Recruiter Information:\nName : ${companyName}\nEmail : ${companyEmail}\nPhone : ${companyPhoneNo}\nSocial Media Link : ${socialMediaLink}`;
    const response = await sendMessageToSlack(message);
    if (response === 'ok' && typeof skipPayment === 'function') {
      Notification(
        'success',
        'Thank you, someone from our team will reach out to you, Continue to explore our app',
      );
      skipPayment();
    } else {
      return Notification(
        'error',
        "Something isn't right, please try again later",
      );
    }
  }, [socialMediaLink, companyName, companyEmail, companyPhoneNo, skipPayment]);

  return {
    isStartup,
    socialMediaChangeHandler,
    enableStartupView,
    handleStartupSocialLink,
    linkError,
    socialMediaLink,
    resetEveryThing,
  };
};

export default useSocialMediaInput;
