import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import styled from 'styled-components';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import {
  TextBox,
  Dropdown,
  NumberBox,
} from '../../../../components/shared-components/Form/Form';
import {
  engagementTypes,
  jobTypes,
} from '../../../../mocks/organizer/events/create-event';
import CategoryDropdown from '../../../../components/Common/CategoryDropdown';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import { StyledInputWrapper, StyledInfoHeading } from './Common';
import { getDomainsData } from '../../../../utils/common';
import { MARGIN } from '../../../../styles/constants/display';
import { checkIfUserIsRecruiter } from '../../../../utils/userInfo';
import { getRoleId } from '../../../../utils/userInfo';
import { MASTER_ADMIN_COLOR, RECRUITER_COLOR } from '../../../../constants';

const UpperSection = ({
  jobTitle,
  setJobTitle,
  dummyIndustryTypes,
  handleSelectDomains,
  handleSelectDomainsEdit,
  categories,
  handleSelectCategories,
  setDependsOnExperience,
  dependsOnExperience,
  currencyDataForDropdown,
  dummyCurrencyType,
}) => {
  const [industryTypes, setIndustryTypes] = React.useState([]);
  React.useEffect(() => {
    getDomainsData().then((domains) => setIndustryTypes(domains));
  }, []);
  const roleId = getRoleId();
  const isRecruiter = checkIfUserIsRecruiter(roleId);
  return (
    <>
      <Row gutter={16}>
        <Col md={12} lg={6} xs={24}>
          <StyledInfoHeading>
            Job Title <MandatoryStar />
          </StyledInfoHeading>
          <TextBox
            id="jobTitle"
            name="jobTitle"
            placeholder="Job Title"
            value={jobTitle}
            handleChange={(e) => setJobTitle(e.target.value)}
            required
            maxLength={120}
            rule="text"
          />
        </Col>
        <Col md={12} lg={6} xs={24}>
          <StyledInfoHeading>
            Type of Job <MandatoryStar />
          </StyledInfoHeading>
          <div>
            <Dropdown
              name="jobType"
              mode="multiple"
              options={jobTypes}
              placeholder="Job Type"
              required
              showArrow
              id="job-description-job-type"
            />
          </div>
        </Col>
        <Col md={12} lg={6} xs={24}>
          <StyledInfoHeading>
            Type of Engagement <MandatoryStar />
          </StyledInfoHeading>
          <div>
            <Dropdown
              name="typesOfEngagement"
              placeholder="Job Engagement"
              options={engagementTypes}
              required
              showArrow
              id="job-description-engagement-type"
            />
          </div>
        </Col>
        <Col md={12} lg={6} xs={24}>
          <StyledInfoHeading>
            Industry <MandatoryStar />
          </StyledInfoHeading>
          <div>
            <Dropdown
              name="domains"
              placeholder="Domains"
              options={dummyIndustryTypes || industryTypes}
              mode="multiple"
              id="job-description-domain"
              handleChange={(options) => {
                handleSelectDomains(options);
                if (!isRecruiter) {
                  handleSelectDomainsEdit(options);
                }
              }}
              required
              showArrow
              showSearch
            />
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={10} lg={7} xs={24}>
          <StyledInfoHeading>
            Category <MandatoryStar />
          </StyledInfoHeading>
          <div>
            <CategoryDropdown
              value={categories}
              handleChange={handleSelectCategories}
            />
          </div>
        </Col>
        <Col md={14} lg={17} xs={24}>
          <StyledInfoHeading>
            Annual Salary <MandatoryStar />
          </StyledInfoHeading>
          <Row gutter={8}>
            <Col md={8} lg={6} xs={12}>
              <StyledInputWrapper>
                <Dropdown
                  name="currencyId"
                  options={dummyCurrencyType || currencyDataForDropdown}
                  placeholder="$"
                  dropdownMatchSelectWidth={false}
                  required={!dependsOnExperience}
                  disabled={dependsOnExperience}
                  showArrow
                  showSearch
                  id="job-description-currency-type"
                />
              </StyledInputWrapper>
            </Col>
            <Col md={8} lg={6} xs={12}>
              <StyledInputWrapper>
                <NumberBox
                  name="salaryFrom"
                  placeholder="Minimum salary"
                  maxChar={9}
                  required={!dependsOnExperience}
                  disabled={dependsOnExperience}
                />
              </StyledInputWrapper>
            </Col>
            <Col md={8} lg={6} xs={12}>
              <StyledInputWrapper>
                <NumberBox
                  name="salaryTo"
                  placeholder="Maximum salary"
                  maxChar={9}
                  required={!dependsOnExperience}
                  disabled={dependsOnExperience}
                />
              </StyledInputWrapper>
            </Col>
          </Row>
          <StyledCheckboxWrapper isRecruiter={isRecruiter}>
            <StyledCheckbox
              id="salaryDependsOnExprience"
              defaultChecked={dependsOnExperience}
              indeterminate={dependsOnExperience}
              onChange={(e) => setDependsOnExperience(e.target.checked)}
            />
            <span>Depends on experience</span>
          </StyledCheckboxWrapper>
        </Col>
      </Row>
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  ${MARGIN(0, 10, 0, 0)}
`;

const StyledCheckboxWrapper = styled(Row)`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${MARGIN(-10, 0, 0, 0)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  // Override antd
  .ant-checkbox-inner {
    border-color: ${({ isRecruiter }) => isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR} !important;

    &::after {
      background-color: ${({ isRecruiter }) => isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR} !important;
    }
  }

  .ant-checkbox-checked::after {
    border-color: ${({ isRecruiter }) => isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR} !important;
  }
`;

export default UpperSection;
