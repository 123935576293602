import get from 'lodash/get';
import Notification from './Notification';

const UploadPictureNotification = (info) => {
  if (info.file.status === 'uploading') {
    Notification(
      'info',
      'File uploading',
      `${info.file.name} file being uploaded`,
    );
  } else if (info.file.status === 'done') {
    Notification(
      'success',
      'Success',
      `${info.file.name} file uploaded successfully`,
    );
  } else if (info.file.status === 'error') {
    Notification(
      'error',
      'Invalid Format',
      `${info.file.name} file upload failed. Supported Types: jpg | jpeg | png`,
    );
  }
};

const UploadDocumentNotification = (info) => {
  if (info.file.status === 'uploading') {
    Notification(
      'info',
      'Document uploading',
      `${info.file.name} file being uploaded`,
    );
  } else if (info.file.status === 'done') {
    Notification(
      'success',
      'Success',
      `${info.file.name} file uploaded successfully`,
    );
  } else if (info.file.status === 'error') {
    Notification(
      'error',
      'Invalid Format | File is too large',
      `${info.file.name} file upload failed. Supported Types: pdf`,
    );
  }
};

const RemovePictureNotification = () => {
  Notification('success', 'Success', `File removed successfully`);
};

export const UploadDocumentFromDriveNotification = (info) => {
  const fileName = get(info, 'docs[0].name', '');
  if (info.action === 'picked') {
    Notification(
      'info',
      'Document uploading',
      `${fileName} file being uploaded`,
    );
  } else if (info.action === 'done') {
    Notification(
      'success',
      'Success',
      `${fileName} file uploaded successfully`,
    );
  } else if (info.action === 'error') {
    Notification(
      'error',
      'Invalid Format | File is too large',
      `${fileName} file upload failed. Supported Types: pdf`,
    );
  }
};

export {
  UploadPictureNotification,
  UploadDocumentNotification,
  RemovePictureNotification,
};
