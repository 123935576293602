import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { DropdownBox } from '../shared-components/Form/Form';
import CalendarMetaInformation from './CalendarMetaInformation';
import { RightAlignContainer } from '../shared-components/RightAlignContainer';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const CalendarCustomHeader = ({ value, onChange }) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];

  // Here we are getting a moment and based on that we are creating list on months name using moment .......logic used same as used in antd calendar from there custom header example.
  // https://codepen.io/pen/?&editors=001
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let index = start; index < end; index++) {
    monthOptions.push({
      id: index,
      name: months[index],
    });
  }
  const month = value.month();

  const year = value.year();
  const options = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    options.push({
      id: i,
      name: i,
    });
  }
  return (
    <div>
      <StyledRow gutter={8}>
        <Col xs={24} md={16}>
          <CalendarMetaInformation />
        </Col>
        <StyledCol xs={24} md={8}>
          <RightAlignContainer>
            <Col>
              <DropdownBox
                dropdownMatchSelectWidth={false}
                handleChange={(newYear) => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
                options={options}
                value={String(year)}
              />
            </Col>
            <Col>
              <DropdownBox
                dropdownMatchSelectWidth={false}
                value={months[month]}
                handleChange={(selectedMonth) => {
                  const newValue = value.clone();
                  newValue.month(parseInt(selectedMonth, 10));
                  onChange(newValue);
                }}
                options={monthOptions}
              />
            </Col>
          </RightAlignContainer>
        </StyledCol>
      </StyledRow>
    </div>
  );
};

export default CalendarCustomHeader;

const StyledCol = styled(Col)`
  div {
    border-radius: 5px !important;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-select-selector {
      span {
        font-size: 12px;
      }
    }
  }
`;

const StyledRow = styled(Row)`
  ${DISPLAY_FLEX('row', null, 'center')}
  ${MARGIN(null, null, 20, null)};
`;
