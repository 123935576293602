import store from '../store/store';
import get from 'lodash/get';
import { isMobile } from '../helpers/display';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from './userInfo';
import { CANDIDATE } from '../constants';

export const truncateWalletAddress = (address: string): string => {
  return address.slice(0, 5) + '...' + address.slice(-4);
};
/** utility function check for showing connect wallet button */
export const showWalletButton = (): boolean => {
  const state = store.getState();
  const roleId = get(state, 'auth.user.roleId', CANDIDATE);
  const isRecruiter = checkIfUserIsRecruiter(roleId);
  const isCandidate = checkIfUserIsCandidate(roleId);
  const isOrganizer = checkIfUserIsOrganizer(roleId);

  return (isRecruiter || isCandidate || isOrganizer) && !isMobile();
};

/** utility function for showing virtual wallet icon
 *  for now recruiter has no dropdown thats why two conditions with the same function
 */
export const showVirtualWalletButton = (forRecruiter: boolean): boolean => {
  const state = store.getState();
  const roleId = get(state, 'auth.user.roleId', CANDIDATE);
  const profileWalletAddress = get(
    state,
    'profile.profileData.walletAddress',
    null,
  );

  return !forRecruiter
    ? (checkIfUserIsCandidate(roleId) || checkIfUserIsOrganizer(roleId)) &&
        profileWalletAddress !== null
    : checkIfUserIsRecruiter(roleId) && profileWalletAddress !== null;
};
