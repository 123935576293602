import React from 'react';
import { ActiveText, InvitedText, PendingText } from './common';
import {
  isMemberPending,
  isMemberInvited,
  isMemberActive,
} from '../../utils/crew';

const CrewMemberStatus = ({ member }) => {
  switch (true) {
    case isMemberActive(member): {
      return <ActiveText>Active</ActiveText>;
    }
    case isMemberPending(member): {
      return <PendingText>Pending</PendingText>;
    }
    case isMemberInvited(member): {
      return <InvitedText>Invited</InvitedText>;
    }
    default: {
      return null;
    }
  }
};
export default CrewMemberStatus;
