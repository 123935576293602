import isNumber from 'lodash/isNumber';
import {
  DirectionProps,
  JustifyContentProps,
  AlignItemsProps,
} from '../../interfaces/styles/display';

export const DISPLAY_FLEX = (
  direction: DirectionProps = 'row',
  justifyContent: JustifyContentProps = null,
  alignItems: AlignItemsProps = null,
) => {
  return `
    display: flex;
    flex-direction: ${direction};
    ${justifyContent ? `justify-content: ${justifyContent};` : ''}
    ${alignItems ? `align-items: ${alignItems};` : ''}
    `;
};

export const TEXT_WRAP = (
  maxWidth: number | null = null,
  overflow: 'clip' | 'ellipsis' | 'initial' | 'inherit' | 'string' = 'ellipsis',
) => `
overflow: hidden;
text-overflow: ${overflow};
white-space: nowrap;
${maxWidth ? `max-width: ${maxWidth}px;` : ''}
`;

export const DISPLAY = {
  INLINE: 'inline',
  FLEX: 'flex',
  CONTENTS: 'contents',
  BLOCK: 'block',
  GRID: 'grid',
  NONE: 'none',
  TABLE: 'table',
  INLINE_BLOCK: 'inline-block',
};

export const MARGIN = (
  top: number | null = null,
  right: number | null = null,
  bottom: number | null = null,
  left: number | null = null,
) => {
  const isValidTop = isNumber(top);
  const isValidRight = isNumber(right);
  const isValidBottom = isNumber(bottom);
  const isValidLeft = isNumber(left);
  if (isValidTop && !isValidRight && !isValidBottom && !isValidLeft) {
    return `margin: ${top}px;`;
  }
  if (isValidTop && isValidRight && !isValidBottom && !isValidLeft) {
    return `margin: ${top}px ${right}px;`;
  }
  return `
  ${isValidTop ? `margin-top: ${top}px;` : ''}
  ${isValidRight ? `margin-right: ${right}px;` : ''}
  ${isValidBottom ? `margin-bottom: ${bottom}px;` : ''}
  ${isValidLeft ? `margin-left: ${left}px;` : ''}
  `;
};

export const PADDING = (
  top: number | null = null,
  right: number | null = null,
  bottom: number | null = null,
  left: number | null = null,
) => {
  const isValidTop = isNumber(top);
  const isValidRight = isNumber(right);
  const isValidBottom = isNumber(bottom);
  const isValidLeft = isNumber(left);
  if (isValidTop && !isValidRight && !isValidBottom && !isValidLeft) {
    return `padding: ${top}px;`;
  }
  if (isValidTop && isValidRight && !isValidBottom && !isValidLeft) {
    return `padding: ${top}px ${right}px;`;
  }
  return `
  ${isValidTop ? `padding-top: ${top}px;` : ''}
  ${isValidRight ? `padding-right: ${right}px;` : ''}
  ${isValidBottom ? `padding-bottom: ${bottom}px;` : ''}
  ${isValidLeft ? `padding-left: ${left}px;` : ''}
  `;
};
/** utility function for margin-top only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override margin-top*
 */
export const MT = (value: number, important: boolean = false): string => {
  return `margin-top : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for margin-bottom only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override margin-bottom*
 */
export const MB = (value: number, important: boolean = false): string => {
  return `margin-bottom : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for margin-left only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override margin-left*
 */
export const ML = (value: number, important: boolean = false): string => {
  return `margin-left : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for margin-right only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override margin-right*
 */
export const MR = (value: number, important: boolean = false): string => {
  return `margin-right : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for padding-top only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override padding-top*
 */
export const PT = (value: number, important: boolean = false): string => {
  return `padding-top : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for padding-right only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override padding-right*
 */
export const PR = (value: number, important: boolean = false): string => {
  return `padding-right : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for padding-bottom only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override padding-bottom*
 */
export const PB = (value: number, important: boolean = false): string => {
  return `padding-bottom : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for padding-left only
 * @params value : number **required**
 * @params important : boolean optional -*if you want it to be important to override padding-left*
 */
export const PL = (value: number, important: boolean = false): string => {
  return `padding-left : ${value}px${important ? ' !important' : ''};`;
};
/** utility function for margin-horizontal
 * @params value number **required**
 * @params important boolean optional for overriding margin horizontal
 */
export const MX = (value: number, important: boolean = false): string => {
  if (important)
    return `
      ${ML(value, true)}
      ${MR(value, true)}
    `;
  return `${ML(value)}
    ${MR(value)}
  `;
};
/** utility function for margin-vertical
 * @params value number **required**
 * @params important boolean optional for overriding margin vertical
 */
export const MY = (value: number, important: boolean = false): string => {
  if (important)
    return `
    ${MT(value, true)}
    ${MB(value, true)}
  `;
  return `${MT(value)}
    ${MB(value)}
  `;
};
/** utility function for padding-horizontal
 * @params value number **required**
 * @params important boolean optional for overriding padding horizontal
 */
export const PX = (value: number, important: boolean = false): string => {
  if (important)
    return `
      ${PL(value, true)}
      ${PR(value, true)}
    `;
  return `${PL(value)}
    ${PR(value)}
  `;
};
/** utility function for padding-vertical
 * @params value number **required**
 * @params important boolean optional for overriding padding vertical
 */
export const PY = (value: number, important: boolean = false): string => {
  if (important)
    return `
    ${PT(value, true)}
    ${PB(value, true)}
  `;
  return `${PT(value)}
    ${PB(value)}
  `;
};

export const getTextOverflowStyle = (line: number) => `
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: ${line};
line-clamp: ${line};
-webkit-box-orient: vertical;
`;

export const SHARP_IMAGE = `
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
`;
