import React, { useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { ConversationCardProps } from '../../interfaces/components/Chat';
import { getFileS3 } from '../../utils/s3.storage';
import SocketContext from '../../context/socket';
import { shortenText } from '../../utils/chat';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { StyledSmallFont } from '../shared-components/Texts';
import { getRemainingTimeShort } from '../../utils/general';
import AvatarOnline from '../Chat/AvatarOnline';
import { MESSAGE_CONTENT_TYPE } from '../../constants/chat';

export const UserCard: React.FC<ConversationCardProps> = ({
  chatData,
  secondUser,
}) => {
  const { message: messageObj, id } = chatData;
  const { name, profilePic, id: senderId, type } = secondUser;

  const [message, setMessage] = useState<string>();
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [time, setTime] = useState<string>();
  const [contentType, setContentType] = useState<number>();

  const picture = getFileS3(profilePic) || '';

  const client = useContext(SocketContext);

  useEffect(() => {
    const msg = get(messageObj, 'message', '');
    setMessage(shortenText(msg, 28));
    setTime(get(messageObj, 'createdAt', ''));
    const contType = get(messageObj, 'contentType', '');
    setContentType(contType);
  }, [messageObj]);

  // Real time Socket Integration
  useEffect(() => {
    (async () => {
      await client.subscribe(`/chat-room/${id}/`, (resp: any) => {
        setMessage(resp.message);
        setTime(resp.dateTime);
        setContentType(resp.contentType);
      });
    })();
  }, [client, id]);

  useEffect(() => {
    if (senderId && type) {
      (async () => {
        await client.subscribe(
          `/user/${senderId}/${type}/`,
          async (resp: any) => {
            const newStatus = get(resp, 'isOnline', null);

            setIsOnline(newStatus);
          },
        );
        await client.message({ type: 40, userId: senderId, userType: type });
      })();
    }
    // eslint-disable-next-line
  }, [client, senderId, type]);

  return (
    <>
      <AvatarOnline picture={picture} isOnline={isOnline} />

      <ConversationDetail>
        <DetailSection>
          <NameText id="chat-card-name">{shortenText(name, 20)}</NameText>
        </DetailSection>
        <DetailSection>
          <MessageText id="chat-card-message">{
            contentType !== MESSAGE_CONTENT_TYPE.GIF ? message : "GIF"
          }</MessageText>
        </DetailSection>
        <TimeText>
          {time ? `${getRemainingTimeShort(time, true) || 'Moment'} ago` : time}
        </TimeText>
      </ConversationDetail>
    </>
  );
};

const ConversationDetail = styled.div`
  ${DISPLAY_FLEX('column', 'space-between')}
  flex: auto;
  position: relative;
`;

const DetailSection = styled.div`
  ${DISPLAY_FLEX('row', 'space-between')}
`;

const NameText = styled.div`
  font-size: ${FONT[16]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.BLACK};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[17]};
  }
`;

const TimeText = styled.div`
  font-size: 8px;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.BLACK};
  opacity: 60%;
  text-align: right;
  opacity: 0.5;
  position: absolute;
  right: 0px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[10]};
  }
`;

const MessageText = styled(StyledSmallFont)`
  color: ${COLORS.EERIE_BLACK};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  opacity: 0.5;
  font-size: ${FONT[11]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 35vw;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[13]};
  }
`;
