export const getFormattedCurrency = (
  currencyCode,
  currencySymbol,
  amount,
  localeString,
) => {
  const formatter = new Intl.NumberFormat(localeString, {
    minimumFractionDigits: 0,
  });
  const formattedCurrency = formatter.format(amount);
  const formattedSalary = `${
    currencySymbol || currencyCode
  } ${formattedCurrency}`;
  return formattedSalary;
};
