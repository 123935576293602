import { gql } from '@apollo/client';
import { authClient, unAuthClient } from '../client';
import ERRORS from '../../constants/errors';

const GET_ALL_COUNTRY_PHONE_CODES = gql`
  query getAllCountryPhoneCodes {
    countryPhoneCodes {
      id
      countryName
      code
      status
    }
  }
`;

export const getAllCountryPhoneCodes = (addAuthHeader = true) => {
  const client = addAuthHeader ? authClient : unAuthClient;
  return client
    .query({
      query: GET_ALL_COUNTRY_PHONE_CODES
    })
    .then(response => response.data.countryPhoneCodes)
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
