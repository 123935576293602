export const imageFilter = () => {
  return 'filter: invert(39%) sepia(35%) saturate(654%) hue-rotate(205deg) brightness(97%) contrast(94%);';
};

export const LightImageFilter =
  'invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%)';

export const ORGANIZER_FILTER = `invert(66%) sepia(14%) saturate(1445%) hue-rotate(
    119deg) brightness(97%) contrast(91%)`;

export const MENU_FILTER = `invert(79%) sepia(1%) saturate(2258%) hue-rotate(24deg)
  brightness(99%) contrast(87%)`;

export const RECRUITER_FILTER = `invert(83%) sepia(42%) saturate(1046%) hue-rotate(
  325deg) brightness(105%) contrast(103%)`;

export const CANDIDATE_FILTER = `invert(42%) sepia(17%) saturate(1370%) hue-rotate(206deg)
  brightness(92%) contrast(91%)`;

export const ADMIN_FILTER = `invert(48%) sepia(92%) saturate(3438%) hue-rotate(
  193deg) brightness(103%) contrast(103%)`;

export const ERROR_PAGE_FILTER = `invert(74%) sepia(9%) saturate(182%) hue-rotate(125deg)
brightness(90%) contrast(94%)`;
