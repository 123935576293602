import styled from 'styled-components';
import React from 'react';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { RightAlign } from '../../interfaces/components/RightAlign';

export const RightAlignContainer: React.FC<RightAlign> = ({
  children,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}) => {
  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<RightAlign>`
  gap: 10px;
  ${DISPLAY_FLEX('row', 'flex-end', 'center')}

  width: 100%;
  ${(props) =>
    MARGIN(
      props.marginTop || 0,
      props.marginRight || 0,
      props.marginBottom || 0,
      props.marginLeft || 0,
    )}
`;
