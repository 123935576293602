import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import ResponsiveMenu from './ResponsiveMenu';
import { StyledMenuItem } from './common';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import HeaderContainer from '../Common/HeaderContainer';
import { MainWebsiteRoutes } from '../../constants/routes';
import Logo from '../../assets/svg/Recorem-Beta.svg';

const Header: React.FC<any> = ({
  isHomePage,
  isShared,
  isCommunityPage,
  modalRef,
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <StyledHeaderWrapper isHomePage={isHomePage}>
      <HeaderContainer>
        <StyledHeader>
          <StyledLeftMenu md={5} xs={24}>
            <StyledLogoLink href={MainWebsiteRoutes.home}>
              <StyledRecoremLogo src={Logo} alt="logo" />
            </StyledLogoLink>
            <StyledMenuIcon
              onClick={() => {
                showDrawer();
              }}
            />
          </StyledLeftMenu>
          {!isShared ? (
            <>
              <StyledMiddleMenu md={14}>
                <StyledNav>
                  <ul>
                    <StyledMenuItemLink>
                      <a href={MainWebsiteRoutes.events}>Events</a>
                    </StyledMenuItemLink>
                    <StyledMenuItemLink>
                      <a href={MainWebsiteRoutes.howItWorks}>How It Works</a>
                    </StyledMenuItemLink>
                    <StyledMenuItemLink>
                      <a href={MainWebsiteRoutes.organizer}>Partner with us</a>
                    </StyledMenuItemLink>
                    <StyledMenuItemLink>
                      <a href={MainWebsiteRoutes.recruiter}>Post Jobs</a>
                    </StyledMenuItemLink>
                  </ul>
                </StyledNav>
              </StyledMiddleMenu>
              <StyledRightMenu md={5}>
                <StyledNavButton
                  onClick={
                    !isCommunityPage
                      ? () => {}
                      : () => {
                          modalRef.current();
                        }
                  }
                >
                  {isCommunityPage ? (
                    <span>Login</span>
                  ) : (
                    <a href="/login">Login</a>
                  )}
                </StyledNavButton>
              </StyledRightMenu>
            </>
          ) : null}
        </StyledHeader>
      </HeaderContainer>
      <ResponsiveMenu visible={visible} onClose={onClose} />
    </StyledHeaderWrapper>
  );
};

export default memo(Header);

const StyledHeaderWrapper = styled.div<any>`
  ${({ isHomePage, scrolled }) =>
    isHomePage
      ? `
  background-color: ${scrolled ? COLORS.WEB3BLUE : 'none'} ;
  box-shadow: ${scrolled ? '0 3px 15px rgb(0 0 0 / 8%)' : 'none'}; 
  `
      : `background-color: ${COLORS.WHITE};
      box-shadow:0 3px 15px rgb(0 0 0 / 8%); 
      `}

  position: sticky;
  color: ${COLORS.BLACK};
  font-family: 'Montserrat';
  padding: 20px 0;
  top: 0;
  z-index: 999;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    padding: 22px 0;
  }
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: 15px 0;
  }
`;

const StyledLeftMenu = styled(Col)`
  min-height: 44px !important;
  ${DISPLAY_FLEX('row', null, 'center')}
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 30px;
    ${DISPLAY_FLEX('column', 'center', 'center')}
    & > figure > span:first-of-type {
      left: 45px !important;
      width: 155px !important;
      height: 30px !important;
    }
  }
`;

const StyledMiddleMenu = styled(Col)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledRightMenu = styled(Col)`
  ${DISPLAY_FLEX('row-reverse')};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledRecoremLogo = styled.img<any>`
  width: 100% !important;
  max-width: 170px !important;
  min-width: 100px !important;
  margin: 0 !important;
  ${({ isShared }) => (isShared ? '' : 'cursor: pointer;')}
`;

const StyledMenuIcon = styled(MenuOutlined)<any>`
  position: absolute;
  display: none;
  left: 0;
  top: 2px;
  svg {
    width: 26px;
    height: 26px;
    ${({ isHomePage }) => (isHomePage ? `fill: white;` : '')}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  height: 44px;
`;

const StyledNav = styled.nav<any>`
  ul {
    ${DISPLAY_FLEX('row', 'center')}
    gap: 40px;
    padding-inline-start: 40px;
    margin-bottom: 0px;
    color: ${({ isHomePage }) => (isHomePage ? COLORS.WHITE : COLORS.BLACK)};

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      gap: 20px;
    }

    @media (max-width: 825px) {
      gap: 5px;
    }
  }
`;

const StyledNavButton = styled.button`
  border: none;
  padding: 0px 40px;
  height: 44px;
  border-radius: 5px;
  cursor: pointer;
  background: ${COLORS.WEB3PINK};
  transition: all 0.2s ease-in-out;

  @media (min-width: ${BREAKPOINTS.MOBILE}) AND (max-width: 800px) {
    padding: 0px 20px;
  }

  &:hover {
    opacity: 0.9;
  }

  span {
    font-weight: 700;
    font-family: Montserrat;
    font-size: 16px;
    color: white;
    &:hover {
      color: ${COLORS.WHITE};
    }
  }

  a {
    font-weight: 700;
    font-family: Montserrat;
    font-size: 16px;
    color: white;
    cursor: pointer;
    &:hover {
      color: ${COLORS.WHITE};
    }
  }
`;

const StyledLogoLink = styled.a`
  width: 100%;
  max-width: 170px;
  min-width: 100px;
  cursor: pointer;
`;

const StyledMenuItemLink = styled(StyledMenuItem)`
  a,
  span {
    cursor: pointer;
    font-family: Montserrat;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: 17px;
    }
  }
`;
