import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { checkIfStatusIsDisabled } from '../../../utils/admin/common';

const DisabledTag = ({ status, normal = false, isCandidate = false }) => {
  return checkIfStatusIsDisabled(status) ? (
    <StyledSpan isCandidate={isCandidate}>
      <StyledDisabledTag normal={normal}>Suspended Account</StyledDisabledTag>
    </StyledSpan>
  ) : null;
};

export default DisabledTag;

const StyledSpan = styled.span`
  position: absolute;
  ${(props) =>
    props.isCandidate
      ? `
  right: 10px;
  top: 2px;
  `
      : `
  right: 30px;
  top: 20px;
  `}
`;

export const StyledDisabledTag = styled.span`
  font-weight: ${(props) =>
    props.normal ? FONT_WEIGHTS.MEDIUM : FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.RED};
`;
