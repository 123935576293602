import React from 'react';
import { Row, Col, Button, Form, Tooltip } from 'antd';
import styled from 'styled-components';
import { MinusCircleOutlined } from '@ant-design/icons';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { TextBox } from '../shared-components/Form/Form';
import { checkIfUserIsCandidate, getRoleId } from '../../utils/userInfo';
import { validateURL } from '../../constants';
import MandatoryStar from '../Common/mandatoryStar';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';
import Notification from '../Notification/Notification';

// TODO: Move this function to utilities
/**
 * Add links if condition meets
 * @param {object} form
 * @param {function} add
 */
const handleAddLink = async (form, add, name, onChangeCallback = null) => {
  const value = await form.getFieldValue(name);

  // Note: four conditions are mandatory for this function
  // Condition: if value is an array
  if (isArray(value)) {
    const link = await get(value, `[${value.length - 1}].link`, undefined);
    // Condition: check if link exists & link has length more than 0 ( zero ) & it's valid URL
    if (link && link.length > 0 && validateURL(link)) {
      add();
      if (onChangeCallback) onChangeCallback();
    }
    // Condition: check if value has length of 0 ( zero )
    else if (value.length === 0) {
      add();
      if (onChangeCallback) onChangeCallback();
    }
  }
  // Condition: check if value is undefiend or falsy
  else if (!value) {
    add();
    if (onChangeCallback) onChangeCallback();
  }
};

const ProfileLinksList = ({
  form,
  color,
  isMasterAdminOfCandidate,
  name = 'profileLinks',
  isSponsors = false,
  rule = 'url',
  onChangeCallback,
  canNotBeEmpty,
}) => {
  const roleId = getRoleId();
  const idBase = `profile_link`;

  function getProfileLabel() {
    if (checkIfUserIsCandidate(roleId) || isMasterAdminOfCandidate) {
      return 'Profile Link';
    }

    return 'Company Profile Link';
  }

  const handleDelete = (remove, field) => {
    const value = form.getFieldValue(name);

    if (canNotBeEmpty && (!isArray(value) || value.length === 1)) {
      Notification(
        'error',
        'Delete',
        'There should be atleast one company profile links',
      );
      return;
    }
    remove(field.name);
    if (onChangeCallback) onChangeCallback();
  };

  return (
    <StyledMainContainer>
      <StyleHeadingLevelThree
        id={`${
          !checkIfUserIsCandidate(roleId) ? 'company_' : ''
        }${idBase}_title`}
        color={color}
        isSponsors={isSponsors}
      >
        {getProfileLabel()}
        {'  '}
        {!checkIfUserIsCandidate(roleId) && !isSponsors ? (
          <MandatoryStar />
        ) : null}
      </StyleHeadingLevelThree>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[0, 20]}>
              <Col xs={24} lg={8}>
                <StyledButton
                  type="dashed"
                  onClick={() =>
                    handleAddLink(form, add, name, onChangeCallback)
                  }
                  id={`${idBase}_add-skill`}
                >
                  + Add Link
                </StyledButton>
              </Col>
            </Row>
            {fields.map((field, idx) => (
              <StyledRow gutter={[25, 24]} key={idx.toString()}>
                <Tooltip title="Enter a Facebook, Twitter or Website URL.">
                  <Col xs={20} md={12} lg={8}>
                    <TextBox
                      field={field}
                      name={[field.name, 'link']}
                      placeholder="https://www.facebook.com/event-page"
                      rule={rule}
                      handleChange={onChangeCallback}
                    />
                  </Col>
                </Tooltip>
                <StyledCol xs={4} md={2}>
                  <StyledIconContainer
                    onClick={() => handleDelete(remove, field)}
                    id={`${idBase}_minus_btn`}
                  >
                    <StyledMinusCircleOutlined />
                  </StyledIconContainer>
                </StyledCol>
              </StyledRow>
            ))}
          </>
        )}
      </Form.List>
    </StyledMainContainer>
  );
};

export default ProfileLinksList;

const StyledMainContainer = styled.div`
  margin-top: 40px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: -20px;
  }
`;

const StyledButton = styled(Button)`
  height: 42px;
  max-width: 300px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px dashed ${COLORS.ARGENT};
  border-radius: 3px;
  color: ${COLORS.ARGENT};
  padding: 8px 0;
  margin-bottom: 20px;
  background: #fff;
`;

const StyleHeadingLevelThree = styled.h3`
  margin-bottom: 24px;
  font-weight: ${({ isSponsors }) =>
    isSponsors ? FONT_WEIGHTS.MEDIUM : FONT_WEIGHTS.SEMI_BOLD};
  color: ${({ color }) => color || COLORS.CHINESE_BLACK};
  font-size: 16px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 14px;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 20px;
`;

const StyledCol = styled(Col)`
  ${MARGIN(-3, 0, 0, -10)};
  height: 40px;
  display: grid;
  place-items: center;
  padding-left: 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(3, 0, 0, -20)};
    height: 32px;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(-5, 0, 0, -10)};
  }
`;

const StyledIconContainer = styled.div`
  ${MARGIN(-4, 0, 0, 0)};
  text-align: center;
  font-size: 20px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 0;
  }
`;

const StyledMinusCircleOutlined = styled(MinusCircleOutlined)`
  color: ${COLORS.ORANGE_RED};
  display: grid;
  place-items: center;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-right: 300px;
  }
`;
