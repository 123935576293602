import React from 'react';

const PlaceHolder = ({image}) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 'calc(50vh - 250px)' }}>
			<img className='placeholder' src={image} alt="" />
			<div style={{ fontSize: 22, marginTop: 20 }}>Under Construction</div>
		</div>
	);
}

export default PlaceHolder;