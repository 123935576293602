import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Table, Col, Row, Typography } from 'antd';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { Link, useHistory } from 'react-router-dom';
import parseInt from 'lodash/parseInt';
import { Dropdown } from '../../../components/shared-components/Form/Form';
import {
  getJobTableData,
  jobColumns,
} from '../../../mocks/recruiter/ApplicationsAnalytics';
import {
  getJobDetailsData,
  updateJobStatus,
} from '../../../store/features/jobDetails';
import {
  JobDetailsStore,
  jobStatusActiveOrInactive,
} from '../../../utils/jobDetails';
import {
  redirectRoutes,
  ACTIVE_JOB_TEXT,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  INACTIVE_JOB_TEXT,
  JOBOPPORTUNITIES,
  STATUS_ACTIVE,
  STATUS_DISABLED,
} from '../../../constants';
import { SORT_ORDER } from '../../../constants/admin';
import { MARGIN, DISPLAY_FLEX } from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { RecruiterRoutes } from '../../../constants/routes';
import { useTagManager } from '../../../utils/customHooks';
import { getUrlVars } from '../../../utils/common';
import { CustomPagination } from '../../../components/shared-components/Common/CustomPagination';
import { StyledLargeFont } from '../../../components/shared-components/Texts';

const { Title } = Typography;

export default function JobLists({ tab }) {
  const history = useHistory();
  const {
    page: initialPage,
    limit: initialLimit,
    sort: initialSort,
    statusFilter: initialStatusFilter,
  } = getUrlVars(get(history, 'location.search', ''));
  const dispatch = useDispatch();
  const [jobStatus, setJobStatus] = useState(true);
  const [jobId, setJobId] = useState(null);
  const [jobsData, setJobsData] = useState([]);
  const { jobDetailsData, jobOpportunitiesPagination } = JobDetailsStore();
  const [currentPage, setCurrentPage] = React.useState(
    initialPage && initialPage !== 'undefined' ? initialPage : DEFAULT_PAGE,
  );
  const [currentLimit, setCurrentLimit] = React.useState(
    initialPage && initialLimit !== 'undefined' ? initialLimit : DEFAULT_LIMIT,
  );
  const restrictions = useSelector((state) => {
    return get(state, 'profile.profileData.company.restrictions', null);
  });
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter || null);
  const firstFetchRef = React.useRef(true);
  const [sorter, setSorter] = useState(initialSort || null);
  const updateUserActionInGTM = useTagManager();

  const populateData = (page = DEFAULT_PAGE, limit = DEFAULT_LIMIT) => {
    dispatch(getJobDetailsData('all', page, limit, statusFilter, sorter));
  };

  const updateUrl = () => {
    history.replace(
      `${
        RecruiterRoutes.recruit
      }${JOBOPPORTUNITIES}?page=${currentPage}&limit=${currentLimit}${
        sorter ? `&sort=${sorter}` : ''
      }${statusFilter ? `&statusFilter=${statusFilter}` : ''}`,
    );
  };

  useEffect(() => {
    if (!firstFetchRef.current) {
      if (tab === JOBOPPORTUNITIES) {
        if (currentPage === DEFAULT_PAGE) {
          populateData(DEFAULT_PAGE, currentLimit);
        } else {
          setCurrentPage(DEFAULT_PAGE);
        }
        updateUrl();
      }
    }
    // eslint-disable-next-line
  }, [dispatch, tab, statusFilter, sorter]);

  const body = {
    activeStatus: jobStatus ? STATUS_ACTIVE : STATUS_DISABLED,
  };

  useEffect(() => {
    if (jobId) {
      dispatch(updateJobStatus(jobId, body, currentPage, currentLimit));
      updateUserActionInGTM('job_status_updated');
    }
    // eslint-disable-next-line
  }, [jobId, dispatch, jobStatus]);

  useEffect(() => {
    setJobsData(jobDetailsData);
  }, [jobDetailsData]);

  const handleJobStatusChange = (value) => {
    const val =
      // eslint-disable-next-line no-nested-ternary
      value === ACTIVE_JOB_TEXT
        ? STATUS_ACTIVE
        : value === INACTIVE_JOB_TEXT
        ? STATUS_DISABLED
        : null;
    setStatusFilter(val);
  };

  useEffect(() => {
    populateData(currentPage, currentLimit);
    if (firstFetchRef.current) firstFetchRef.current = false;
    updateUrl();

    // eslint-disable-next-line
  }, [currentPage, currentLimit]);

  const onPageChangeHandler = (pageNumber, pageSize) => {
    if (pageNumber !== currentPage) setCurrentPage(pageNumber);
    if (pageSize && pageSize !== currentLimit) setCurrentLimit(pageSize);
  };
  const onTableChange = (_pagination, _filters, sorter) => {
    const field = get(sorter, 'field', '');
    const order = SORT_ORDER[get(sorter, 'order', '')];

    setSorter(order ? `${field}_${order}` : null);
  };

  return (
    <>
      <StyledContainerRow>
        <StyledHeadingLeftCol xs={24} md={12}>
          <StyledHeadingTextLeft level={4}>List of Jobs</StyledHeadingTextLeft>
        </StyledHeadingLeftCol>
        <StyledFilterRightCol xs={24} md={12}>
          <StyledFilterBtnContainer>
            <Dropdown
              className="filter-select"
              options={jobStatusActiveOrInactive}
              handleChange={handleJobStatusChange}
              defaultValue={
                statusFilter
                  ? parseInt(statusFilter) === STATUS_ACTIVE
                    ? ACTIVE_JOB_TEXT
                    : INACTIVE_JOB_TEXT
                  : null
              }
              placeholder="Filter"
              dropdownMatchSelectWidth={false}
              showArrow
              id="filter-select"
            />
          </StyledFilterBtnContainer>
        </StyledFilterRightCol>
      </StyledContainerRow>
      <>
        <StyledTableContainer>
          {!isArray(jobDetailsData) || jobDetailsData.length === 0 ? (
            <styledEmptyText>
              It looks like you haven’t posted any jobs yet. Head to the{' '}
              <StyledLinks to={RecruiterRoutes.createJob}>
                Create Job
              </StyledLinks>{' '}
              page to create your first job posting :)
            </styledEmptyText>
          ) : (
            <>
              <StyledTable
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      // Skip if that's a button
                      if (e.target.type === 'button') {
                        return undefined;
                      }
                      // Here key points to ID
                      history.push(
                        `${redirectRoutes.recruiter.viewJobDetails}${
                          record.key
                        }?page=${currentPage}&limit=${currentLimit}${
                          sorter ? `&sort=${sorter}` : ''
                        }${
                          statusFilter ? `&statusFilter=${statusFilter}` : ''
                        }`,
                      );
                    },
                  };
                }}
                columns={jobColumns}
                dataSource={getJobTableData(jobsData, setJobStatus, setJobId)}
                pagination={false}
                data-testId="job-list-table"
                onChange={onTableChange}
              />
            </>
          )}
        </StyledTableContainer>
        <StyledPaginationDiv
          data-testid="pagination"
          isRestrictText={restrictions && restrictions?.jobs}
        >
          {restrictions && restrictions?.jobs ? (
            <StyledRemainingJobs>{`${
              restrictions?.jobs -
              get(
                jobOpportunitiesPagination,
                'totalItems',
                jobDetailsData.length,
              )
            } out of ${restrictions?.jobs} jobs left.`}</StyledRemainingJobs>
          ) : null}
          <CustomPagination
            currentPage={
              get(jobOpportunitiesPagination, 'currentPage', currentPage) ||
              currentPage
            }
            totalItems={get(
              jobOpportunitiesPagination,
              'totalItems',
              jobDetailsData.length,
            )}
            currentLimit={currentLimit}
            onPageChange={onPageChangeHandler}
          />
        </StyledPaginationDiv>
      </>
    </>
  );
}

const StyledContainerRow = styled(Row)`
  ${MARGIN(20, 0, 20, 0)};
`;

const StyledHeadingLeftCol = styled(Col)`
  ${DISPLAY_FLEX('row')};
`;

const StyledHeadingTextLeft = styled(Title)`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-bottom: 0px;
`;

const StyledFilterRightCol = styled(Col)`
  ${DISPLAY_FLEX('row-reverse')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    flex: 0 !important;
  }
`;

const StyledFilterBtnContainer = styled.div`
  ${DISPLAY_FLEX(null, 'space-around', 'center')};
  font-size: ${FONT[12]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  width: 200px;
  position: relative;
  border-radius: 5px;
  margin: 0 0 0 auto;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[14]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: 0px;
  }

  .ant-select-selector {
    background-color: ${COLORS.WHITE_SMOKE} !important;
    color: ${COLORS.CHINESE_BLACK};
    border-radius: 6px !important;
    border: none !important;
  }

  .ant-select-selection-placeholder {
    color: ${COLORS.CHINESE_BLACK};
  }
`;

const StyledTableContainer = styled.div`
  overflow-x: auto;

  .ant-switch-checked {
    background-color: ${COLORS.recruiterColor};
  }

  .ant-table-cell {
    font-size: ${FONT[12]};

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[12]};
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]};
    }
  }
`;

const styledEmptyText = styled.div`
  margin-top: -15px;
  margin-bottom: 20px;
  color: ${COLORS.GRAY};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
    margin-top: 0;
  }
`;

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;

    th,
    th span {
      white-space: nowrap;
      font-size: ${FONT[14]} !important;
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};

      @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
        font-size: ${FONT[16]} !important;
      }

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        font-size: ${FONT[14]} !important;
      }
    }

    td {
      font-weight: ${FONT_WEIGHTS.MEDIUM};

      button {
        min-width: 100px;
      }

      button.ant-switch {
        width: 50px;
        min-width: unset;
      }
    }
  }
`;

const StyledPaginationDiv = styled.div`
  ${({ isRestrictText }) =>
    isRestrictText
      ? `${DISPLAY_FLEX('row', 'space-between', 'center')}`
      : `${DISPLAY_FLEX('row', 'end')}`}
  margin: 20px 0;
`;

// Link component does not allow css rewrite, so have to use important tag
const StyledLinks = styled(Link)`
  color: ${COLORS.DODGER_BLUE} !important;
  cursor: pointer !important;
`;

const StyledRemainingJobs = styled(StyledLargeFont)`
  font-weight: bold;
`;
