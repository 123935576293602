import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  checkIfUserIsMasterAdmin,
  checkIfUserIsOrganizer,
  getRoleId,
} from '../../utils/userInfo';
import { DropdownBox } from '../shared-components/Form/Form';
import Notification from '../Notification/Notification';
import { EventsStore, getEventOptions, getPastEvent } from '../../utils/event';
import { getOrganizerEventsData } from '../../store/features/events';
import { TextUpload } from '../Upload/Upload';
import { BULK_INVITE_EXTENSIONS } from '../../constants/file';
import { validateCsvFile, bulkBooking } from '../../service/organizer';
import { isValid } from '../../utils/api';
import ERRORS from '../../constants/errors';
import { getEvents } from '../../service/masterAdmin';
import InvitedAttendees from '../../containers/MasterAdmin/InvitedAttendees';
import { PageContainer } from '../shared-components/PageContainer';
import { PrimaryButton } from '../shared-components/Button';
import { getThemeByRoleId } from '../../utils/common';
import { RightAlignContainer } from '../shared-components/RightAlignContainer';
import Circle from '../shared-components/PageCircle';
import { getUserColorWithRoleId } from '../../styles/utils';
import {
  ExtraLargeHeading,
  MediumHeading,
  StyledLargeFont,
} from '../shared-components/Texts';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import Template from '../../assets/docs/bulkTemplate.csv';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const BulkEventInvite = ({ mockData, match, history }) => {
  const dispatch = useDispatch();
  const { onGoingEvents, upComingEvents } = EventsStore();
  const [eventId, setEventId] = useState(null);
  const [errors, setErrors] = useState([]);
  const [data, setdata] = useState([]);
  const [validated, setValidated] = useState(false);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const roleId = getRoleId();
  const [events, setEvents] = useState([]);
  // Merge all events

  useEffect(() => {
    setEvents(onGoingEvents.concat(upComingEvents));
  }, [onGoingEvents, upComingEvents]);

  useEffect(() => {
    if (checkIfUserIsOrganizer(roleId)) {
      dispatch(getOrganizerEventsData());
    }
  }, [dispatch, roleId]);

  useEffect(() => {
    if (checkIfUserIsMasterAdmin(roleId)) {
      (async () => {
        const { data } = await getEvents(1, -1, 'all');
        const events = get(data, 'payload.events', []);
        const filtered = events.filter((event) => !getPastEvent(event));
        setEvents(filtered);
      })();
    }
  }, [roleId]);

  const handleChange = (value) => {
    if (value >= 0) {
      const eventID = events[value].id;
      setEventId(eventID);
    }
  };

  const uploadEventFile = async ({ file }, type) => {
    if (type === 'csv') {
      setValidated(false);
      validateCsvFile(file)
        .then((response) => {
          if (!isValid(response)) {
            throw new Error(ERRORS.REQUEST_FAILED);
          }

          // How to show these errors
          const errors = get(response, 'data.payload.errors', []);
          const dataList = get(response, 'data.payload.data', []);
          if (errors.length === 0) {
            setValidated(true);
            Notification(
              'success',
              'CSV file validated successfully , Select the event and click on confirm to send event invites.',
            );
            setErrors([]);
            setdata(dataList);
            return;
          }
          setErrors(errors);
          setdata([]);
          Notification('error', get(response, 'data.message', ''));
        })
        .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
    }
  };

  const onConfirmClick = () => {
    if (!eventId) {
      Notification('error', 'Please select the Event first');
      return;
    }

    if (!data || data.length === 0) {
      Notification('error', 'Please upload CSV file');
      return;
    }

    setRequestInProcess(true);
    bulkBooking(eventId, { data })
      .then(() => {
        Notification(
          'success',
          'please wait few minutes while we send all the invite mails to the users , we will send you a confirmation mail once all the invitations are sent.',
        );
        setdata([]);
        setRequestInProcess(false);
      })
      .catch(() => {
        setRequestInProcess(false);
      });
  };

  const getHandleSubmit = (mock, handleSubmit) => {
    return get(mock, 'handleSubmit', handleSubmit);
  };

  const getBtnDisabledInfo = (mock, validation, reqInProcess) => {
    const validatedFromTest = get(mock, 'validated', validation);
    const requestInProcessFromTest = get(
      mockData,
      'requestInProcess',
      reqInProcess,
    );
    return !validatedFromTest || requestInProcessFromTest;
  };

  const getSelectDisabledInfo = (mock, validation) => {
    return !get(mock, 'validated', validation);
  };

  return (
    <div>
      <Circle color={getUserColorWithRoleId(roleId)} />
      <StyledTitleContainer>
        <h1>
          <ExtraLargeHeading fontWeight={FONT_WEIGHTS.BOLD}>
            Bulk Invite
          </ExtraLargeHeading>
        </h1>
        <MediumHeading>
          Please Upload the csv file and invite/register users to an event.
        </MediumHeading>
      </StyledTitleContainer>
      <PageContainer>
        <Row>
          <Col xs={24} md={6}>
            <StyledInputLabel>CSV File:</StyledInputLabel>
            <TextUpload
              name="CSVBulk"
              text="Upload CSV"
              icon={<UploadOutlined />}
              customRequest={(data) => uploadEventFile(data, 'csv')}
              accept={BULK_INVITE_EXTENSIONS}
              testId="text-upload"
            />
          </Col>
          <Col xs={24} md={18}>
            <StyledInputLabel>Event:</StyledInputLabel>
            <DropdownBox
              handleChange={handleChange}
              options={getEventOptions(events)}
              placeholder="Select event"
              disabled={getSelectDisabledInfo(mockData, validated)}
              id="select-event"
              showSearch
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <a href={Template} download="Bulk Invite Template.csv">
              <PrimaryButton
                theme={getThemeByRoleId(roleId)}
                margin="20px 0 0 0"
              >
                <StyledHiddenSpan>Download</StyledHiddenSpan> Template
              </PrimaryButton>
            </a>
          </Col>
          <Col xs={12}>
            <RightAlignContainer marginTop={20}>
              <PrimaryButton
                onClick={getHandleSubmit(mockData, onConfirmClick)}
                disabled={getBtnDisabledInfo(
                  mockData,
                  validated,
                  requestInProcess,
                )}
                theme={getThemeByRoleId(roleId)}
                id="bulk_invite_confirm_btn"
              >
                Confirm
              </PrimaryButton>
            </RightAlignContainer>
          </Col>
        </Row>
      </PageContainer>
      {errors && errors.length ? (
        <PageContainer>
          <h5>Errors</h5>
          <Col xs={24}>
            {errors.map((error, idx) => (
              <Row key={idx.toString()}>
                <StyledErrorRow>Row No: {error.row + 1}</StyledErrorRow>
                <StyledErrorMessage offset={1}>
                  {error.message}
                </StyledErrorMessage>
              </Row>
            ))}
          </Col>
        </PageContainer>
      ) : null}
      {checkIfUserIsMasterAdmin(roleId) ? (
        <InvitedAttendees history={history} match={match} />
      ) : null}
    </div>
  );
};

export default BulkEventInvite;

const StyledErrorRow = styled(Col)`
  border-left: 10px solid ${COLORS.CUSTOM_RED};
  color: ${COLORS.GRAY};

  ${PADDING(0, 0, 0, 10)}
  ${MARGIN(0, 0, 10, 0)}
`;

const StyledErrorMessage = styled(Col)`
  color: ${COLORS.GRAY};
`;

const StyledTitleContainer = styled.div`
  ${MARGIN(20, 0)}
`;

const StyledInputLabel = styled(StyledLargeFont)`
  ${MARGIN(10, 0)}
`;

const StyledHiddenSpan = styled.span`
  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_6}) {
    display: none;
  }
`;
