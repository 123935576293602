import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Switch } from 'antd';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { getRoleId } from '../../utils/userInfo';
import { PageContainer } from '../shared-components/PageContainer';
import {
  ExtraLargeFont,
  LargeFont,
  StyledHeading,
  StyledLargeFont,
} from '../shared-components/Texts';
import {
  checkIfMailsUnsubscribed,
  subscribeMails,
  subscribeMailsAdmin,
} from '../../service/auth';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { STATUS_ACTIVE } from '../../constants';

const MailSubscription = ({ profileData, isAdmin = false }) => {
  const roleId = getRoleId();
  const [isChecked, setIsChecked] = useState(true);

  const { status, userId } = profileData || {};

  useEffect(() => {
    if (userId && status === STATUS_ACTIVE) {
      checkIfMailsUnsubscribed(null, userId).then((response) => {
        setIsChecked(!get(response, 'isUnsubscribed', false));
      });
    }
  }, [status, userId]);

  const handleStatusChange = () => {
    if (isAdmin) {
      subscribeMailsAdmin(userId, !isChecked).then(() => {
        setIsChecked(!isChecked);
      });
    } else {
      subscribeMails().then(() => {
        setIsChecked(!isChecked);
      });
    }
  };

  return (
    <PageContainer>
      <Heading color={getUserColorWithRoleId(roleId)}>Email and Alerts</Heading>
      <CurrencyValue>
        <Row>
          <StyledCol>
            <ExtraLargeFont>Updates and News</ExtraLargeFont>{' '}
          </StyledCol>
          <Col offset={1}>
            <StyledSwitch
              color={getUserColorWithRoleId(roleId)}
              checked={isChecked}
              onChange={handleStatusChange}
            />
          </Col>
        </Row>
      </CurrencyValue>
      <LargeFont>Get announcements and updates via email.</LargeFont>
    </PageContainer>
  );
};

export default MailSubscription;

const CurrencyValue = styled(StyledLargeFont)`
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.REGULAR};
  word-wrap: break-word;
  ${MARGIN(15, 0)}
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${(props) => props.color};
  }
`;

const StyledCol = styled(Col)`
  ${DISPLAY_FLEX('column', 'center', 'center')}
`;

const Heading = styled(StyledHeading)`
  padding-bottom: 5px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${(props) => props.color};
  ${MARGIN(0, 0, 15, 0)};
`;
