import React, { useState, useEffect } from 'react';
import { Steps, Skeleton, Form } from 'antd';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { userProfileStore } from '../../utils/profile';
// import { updateProfileData } from '../../store/features/profile';
import RepresentativeUIBox from '../../components/ProfileOnboarding/RepresentativeUIBox';
import CompanyProfileUIBox from '../../components/ProfileOnboarding/CompanyProfileUIBox';
import Header from '../../components/shared-components/Profile-onboard/Header';
import {
  StyledBoard,
  StyledSteps,
} from '../../components/shared-components/Styled-components/ProfileOnboard';
// import CrewManagementUIBox from '../../components/ProfileOnboarding/CrewManagementUIBox';
import { getRoleId } from '../../utils/userInfo';
import { TickIcon } from '../../components/svgicons';
import { getStep } from '../../utils/onboarding';
import { getFileS3 } from '../../utils/s3.storage';
import PageCircle from '../../components/shared-components/PageCircle';
import { getUserColorWithRoleId } from '../../styles/utils';
import { StyledPageBottom } from '../../components/shared-components/Common/PageBottom';
const { Step } = Steps;

const ProfileOnboard = ({ history, isForTest, testStep }) => {
  const { isLoading, profileData, error } = userProfileStore();
  const roleId = getRoleId();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [form] = Form.useForm();
  const [profile, setProfile] = useState({});
  const [profilePic, setProfilePic] = useState(null);
  const [companyLogo, setCompanyLogo] = useState([]);
  const [companyCover, setCompanyCover] = useState([]);
  // const [isPasswordSetOrNot, setIsPasswordSetOrNot] = useState(true);
  // const [crewMemberInvite, setCrewMembersInvite] = useState([]);
  /*
  useEffect(() => {
    if (profileData.email) {
      checkIfUserExist(profileData.email, false, roleId).then((response) => {
        const { isPasswordSetOrNot } = response;
        const localSkipValue = localStorage.getItem(
          `Onboarding_Password_Skip_${profileData.email}`,
        );
        setIsPasswordSetOrNot(isPasswordSetOrNot || !!localSkipValue);
      });
    }
    // eslint-disable-next-line
  }, [profileData.email]);
*/

  useEffect(() => {
    if (!isLoading) {
      setProfilePic(getFileS3(profileData.profilePic));
      if (profileData.company && profileData.company.logo) {
        setCompanyLogo([
          {
            uid: 1,
            name: 'Company Logo',
            status: 'done',
            url: getFileS3(profileData.company.logo),
          },
        ]);
      }
      if (profileData.company && profileData.company.cover) {
        setCompanyCover([
          {
            uid: 1,
            name: 'Company cover',
            status: 'done',
            url: getFileS3(profileData.company.cover),
          },
        ]);
      }
    }
  }, [profileData, isLoading]);

  // const handleCrewMembersSubmit = (data) => {

  // 	form.resetFields();
  // 	dispatch(updateProfileData(profile));
  // }

  // console.log(profile)

  useEffect(() => {
    if (step === 0) {
      const input = document.getElementById('representative_ui_box');
      if (input) input.focus();
    } else if (step === 1) {
      const input = document.getElementById(
        'industry_type_company_profile_box',
      );
      if (input) input.focus();
    }
  }, [step]);

  const name = get(profileData, 'name', '');

  /*
  const onSkip = () => {
    setIsPasswordSetOrNot(true);
  };

  if (!isPasswordSetOrNot) {
    return <CreateOrChangePassword onSkip={onSkip} />;
  }
*/

  return isLoading || error ? (
    <Skeleton />
  ) : (
    <>
      <StyledBoard color={getUserColorWithRoleId(roleId)}>
        {/* circle */}
        <PageCircle color={getUserColorWithRoleId(roleId)} />

        {/* title */}
        <Header name={name} />

        {/* steps */}
        <StyledSteps
          current={step}
          size="small"
          color={getUserColorWithRoleId(roleId)}
          className="organizer-board-feature-tour"
        >
          <Step
            title="Organizer Profile"
            description="Fill your Representative Details"
            icon={<TickIcon />}
          />
          <Step
            title="Company Profile"
            description="Share your company details"
            icon={<TickIcon />}
          />
          {/* <Step title="Invite your Crew Member" description="Create your Crew" /> */}
        </StyledSteps>

        {getStep(testStep, step) === 0 ? (
          <RepresentativeUIBox
            roleId={roleId}
            step={step}
            setStep={setStep}
            profile={profile}
            setProfile={setProfile}
            profileData={profileData}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            form={form}
            isForTest={isForTest}
            userType="organizer"
          />
        ) : getStep(testStep, step) === 1 ? (
          <CompanyProfileUIBox
            roleId={roleId}
            step={step}
            setStep={setStep}
            profile={profile}
            setProfile={setProfile}
            profileData={profileData}
            form={form}
            companyLogo={companyLogo}
            setCompanyLogo={setCompanyLogo}
            setCompanyCover={setCompanyCover}
            companyCover={companyCover}
            dispatch={dispatch}
            history={history}
            isForTest={isForTest}
            userType="organizer"
          />
        ) : // : step === 2 ? CrewManagementUIBox(roleId, step, setStep, crewMemberInvite, setCrewMembersInvite, handleCrewMembersSubmit, form)
          null}

        {/* end box3 */}
        {/* mobile bottom box */}
        <StyledPageBottom color={getUserColorWithRoleId(roleId)} />
      </StyledBoard>
    </>
  );
};

export default ProfileOnboard;
