import React, { FC } from 'react';
import styled from 'styled-components';
import { timeAgo } from '../../../utils/chat';
import { getFileS3 } from '../../../utils/s3.storage';
import { SenderThreadProps } from '../../../interfaces/components/Chat';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import { StyledLargeFont, StyledSmallFont } from '../Texts';
import { AvatarPicture } from '../AvatarPicture';
import { GIF } from './GIF';
import { MESSAGE_CONTENT_TYPE } from '../../../constants/chat';

export const SenderThread: FC<SenderThreadProps> = ({
  messageData,
  profilePic,
  isWithoutIcon = false,
}) => {
  const { message, createdAt, dateTime, contentType } = messageData;
  const time = createdAt || dateTime;
  const picture = getFileS3(profilePic) || '';

  return (
    <Thread id="chat-sender-thread">
      <ThreadDetail isWithoutIcon={isWithoutIcon}>

        {
          contentType !== MESSAGE_CONTENT_TYPE.GIF ?
            (<MediumHeading
              isWithoutIcon={isWithoutIcon}
            >
              {message}
            </MediumHeading>) : <GIF message={message} contentType={contentType} />
        }
        {time ? (
          <TimeHeading isWithoutIcon={isWithoutIcon}>
            {timeAgo(time)}
          </TimeHeading>
        ) : null}
      </ThreadDetail>
      {isWithoutIcon ? null : <AvatarPicture picture={picture} size={35} />}
    </Thread>
  );
};

const Thread = styled.div`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')}

  margin: 10px 0;

  span {
    align-self: flex-end;
    margin-bottom: 20px;
  }
`;

const ThreadDetail = styled(StyledSmallFont) <any>`
  ${DISPLAY_FLEX('column', null, 'flex-end')}

  margin-right:  ${({ isWithoutIcon }) => (isWithoutIcon ? '0px' : '10px')};
  color: ${COLORS.ARSENIC};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  width: ${({ isWithoutIcon }) => (isWithoutIcon ? '85%' : '50%')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
  }
`;

const MediumHeading = styled(StyledLargeFont) <any>`
  ${DISPLAY_FLEX('row', null, 'center')}

  align-items: center;
  border-radius: 10px 10px 0 10px;
  padding: ${({ isWithoutIcon }) => (isWithoutIcon ? `11px` : '14px')};
  width: fit-content;
  max-width: 100%;
  min-height: 0;
  word-break: break-word;
  white-space: normal;
  hyphens: auto;
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  background-color: ${COLORS.LIGHT_SILVER};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    min-height: ${({ isWithoutIcon }) => (isWithoutIcon ? `unset` : '66px')};
  }
`;

const TimeHeading = styled(StyledSmallFont) <any>`
  font-size: ${FONT[10]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.EERIE_BLACK};
  opacity: 60%;
  text-align: end;
  margin-top: 5px;

  ${({ isWithoutIcon }) =>
    isWithoutIcon
      ? ''
      : `
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: inherit;
  }
  `};
`;
