import first from 'lodash/first';
import get from 'lodash/get';
import isString from 'lodash/isString';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { NOTIFICATION_STATUS, STATUS_ACTIVE } from '../constants';
import { COLORS } from '../styles/constants/colors';
import {
  convertFromUnixToNormalTime,
  convert24HourTimeTo12HourTime,
} from './formatDateTime';
import { checkIfUserIsOrganizer } from './userInfo';
import {
  CALENDAR_DATA_ORDER,
  CALENDAR_FILTER_STATUS,
  INTERVIEW_TEXT,
  PAST_INTERVIEW_TEXT,
} from '../constants/calendar';
import Notification from '../components/Notification/Notification';

export const timeRange = [
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '13:00 PM',
  '13:30 PM',
  '14:00 PM',
  '14:30 PM',
  '15:00 PM',
  '15:30 PM',
  '16:00 PM',
  '16:30 PM',
  '17:00 PM',
  '17:30 PM',
  '18:00 PM',
  '18:30 PM',
  '19:00 PM',
  '19:30 PM',
  '20:00 PM',
];

export const formattedDate = (currDate) => {
  return moment(currDate).format('MM/DD/YYYY');
};

/**
 * this function filters scheduled Interviews for the day
 */
export const scheduledInterviewsSlotsForDay = (interviews, currDate) => {
  const slots =
    interviews &&
    interviews
      .filter((slot) => {
        const st = moment.unix(get(slot, 'slot.startTime', null));
        const en = moment.unix(get(slot, 'slot.endTime', null));
        if (
          currDate.startOf('day') <= st.startOf('day') &&
          currDate.endOf('day') >= en.endOf('day')
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map((int) => {
        const st = get(int, 'slot.startTime', null);
        const en = get(int, 'slot.endTime', null);
        const name = get(int, 'candidate.name', null);
        const job = get(int, 'job.designation', null);

        return {
          startTime: convertFromUnixToNormalTime(st),
          endTime: convertFromUnixToNormalTime(en),
          candidateName: name,
          job,
        };
      });
  return slots;
};

/**
 * this function filters available booked slots for the day
 */

export const scheduledAvailableSlotsForDay = (interviewSlots, currDate) => {
  const slots =
    interviewSlots &&
    interviewSlots
      .filter((slot) => {
        const st = moment.unix(get(slot, 'startTime', null));
        const en = moment.unix(get(slot, 'endTime', null));

        if (
          currDate.startOf('day') <= st.startOf('day') &&
          currDate.endOf('day') >= en.endOf('day')
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map((item) => {
        return {
          ...item,
          startTime: convertFromUnixToNormalTime(item.startTime),
          endTime: convertFromUnixToNormalTime(item.endTime),
        };
      });
  return slots;
};

/**
 * this function handles the timeIntervals
 */
export const handleSlotTimings = (bookedSlots, array) => {
  let tempOriginalArray = [];
  array.map((i) => tempOriginalArray.push(i));

  bookedSlots.forEach((slot) => {
    const temp = [];
    let startFound = false;
    let endFound = false;
    tempOriginalArray.forEach((time) => {
      const { startTime, endTime } = calendarSlotTimeHelper(
        slot.startTime,
        slot.endTime,
      );

      if (!startTime || !endTime) {
        return;
      }

      if (time === startTime) {
        startFound = true;
        temp.push(time);
      } else if (
        moment(time, ['HH:mm A']).diff(moment(endTime, ['HH:mm A'])) >= 0 &&
        !endFound
      ) {
        endFound = true;
        temp.push(time);
      } else if (!startFound || endFound) {
        temp.push(time);
      }
    });
    tempOriginalArray = temp;
  });
  return tempOriginalArray;
};
/**
 * this function is for creating new slot
 */
export const createSlot = (timeArray, slot) => {
  const temp = [];
  let startFound = false;
  let endFound = false;
  timeArray.forEach((time) => {
    const { startTime, endTime } = calendarSlotTimeHelper(
      slot.startTime,
      slot.endTime,
    );

    if (!startTime || !endTime) {
      return;
    }

    if (time === startTime) {
      startFound = true;
      temp.push(time);
    } else if (
      moment(time, ['HH:mm A']).diff(moment(endTime, ['HH:mm A'])) >= 0 &&
      !endFound
    ) {
      endFound = true;
      temp.push(time);
    } else if (!startFound || endFound) {
      temp.push(time);
    }
  });
  return temp;
};

export const calendarSlotTimeHelper = (startTimeData, endTimeData) => {
  let startTime = startTimeData;
  let endTime = endTimeData;

  if (!isString(startTime) || !isString(endTime)) {
    return { startTime, endTime };
  }
  let startTimeList = startTime.split(' ');
  let startTimeHour = first(startTimeList).split(':');
  let endTimeList = endTime.split(' ');
  let endTimeHour = first(endTimeList).split(':');

  if (first(endTimeHour) === '24') {
    endTime = `12:${endTimeHour[1]} ${endTimeList[1]}`;
  }

  if (first(startTimeHour) === '24') {
    startTime = `12:${startTimeHour[1]} ${startTimeList[1]}`;
  }

  return { startTime, endTime };
};

export const getInvalidHours = (slotsArray, slotStartTime) => {
  // eslint-disable-next-line
  const val = slotsArray.map((slot) => {
    if (Number(slot.startTime.split(':')[0]) > Number(slotStartTime)) {
      return slot.startTime.split(':')[0];
    }
  });

  val.sort();
  return val[0] !== undefined ? val[0] : 24;
};

export const concatenateSelectedDateAndTime = (currDate, time) => {
  const response = moment(
    `${currDate.format('ll')} ${convert24HourTimeTo12HourTime(time)}`,
  ).unix();
  return response;
};

export const getEventBagdeColor = (value, item, roleId) => {
  const currDate = new Date();

  const renderDate = new Date(value);
  if (item.status && item.status !== STATUS_ACTIVE) {
    return COLORS.CUSTOM_RED;
  }

  const startDate = new Date(moment.unix(item.dateTime));
  const endDateTime = new Date(moment.unix(item.endDateTime));
  if (currDate >= startDate && currDate <= endDateTime) {
    return COLORS.DODGER_BLUE;
  }

  if (renderDate > currDate && checkIfUserIsOrganizer(roleId)) {
    return COLORS.PUERTO_RICO;
  }
  if (renderDate < currDate) {
    return COLORS.METALLIC_SILVER;
  }
  return COLORS.DODGER_BLUE;
};

/**
 * Takes following params and returns event detail heading
 * @param {object} time
 * @param {object} item
 * @param {number} roleId
 * @returns {string}
 */
export const getCalendarEventDetailHeading = (time, item, roleId) => {
  const currDate = new Date();

  const renderDate = new Date(time);
  if (item.status && item.status !== STATUS_ACTIVE) {
    return 'Cancelled event';
  }

  const startDate = new Date(moment.unix(item.dateTime));
  const endDateTime = new Date(moment.unix(item.endDateTime));
  if (currDate >= startDate && currDate <= endDateTime) {
    return checkIfUserIsOrganizer(roleId) ? 'Live event' : 'Event';
  }

  if (item?.isInterview && currDate < startDate) {
    return 'Upcoming Interview';
  }

  if (renderDate > currDate) {
    return 'Upcoming event';
  }

  if (renderDate < currDate) {
    return item.isInterview ? 'Past Interview' : 'Past event';
  }

  return item.isInterview ? 'Interview' : 'Event';
};

/**
 * Takes following argument, arrenge order and returns the array
 * @param {array} list
 * @returns {array}
 */
export const arrangeOrderCalendarData = (list) => {
  if (!isArray(list)) return list;

  return sortBy(list, (item) => {
    if (item?.isInterview) {
      const interviewStatus =
        moment().unix() > item.endDateTime
          ? PAST_INTERVIEW_TEXT
          : INTERVIEW_TEXT;
      return CALENDAR_DATA_ORDER[interviewStatus];
    }

    return CALENDAR_DATA_ORDER[item.eventStatus];
  });
};

/**
 * filterCalendarData takes following args, based on that it returns filtered and re-arranged data
 * @param {array} listData
 * @param {object} eventState
 * @returns {array}
 */
export const filterCalendarData = (listData, eventState) => {
  if (!isArray(listData) || !isPlainObject(eventState)) {
    // Show error notification in the case if values are not as expected
    Notification(
      NOTIFICATION_STATUS.ERROR,
      'Calendar',
      'Something went wrong!',
    );
    return [];
  }

  const filteredList = uniqBy(listData, (data) => {
    return data.status;
  }).filter((data) => {
    if (
      isEmpty(eventState) ||
      eventState.status === CALENDAR_FILTER_STATUS.ALL
    ) {
      return data;
    }
    if (eventState.name === CALENDAR_FILTER_STATUS.EVENTS) {
      return [
        CALENDAR_FILTER_STATUS.UPCOMING,
        CALENDAR_FILTER_STATUS.LIVE,
        CALENDAR_FILTER_STATUS.COMPLETED,
      ].includes(data.eventStatus);
    }
    if (eventState.name !== CALENDAR_FILTER_STATUS.INTERVIEWS) {
      return data.eventStatus === eventState.status;
    }
    if (eventState.name === CALENDAR_FILTER_STATUS.INTERVIEWS) {
      return data.isInterview === eventState.status;
    }

    return data;
  });

  return arrangeOrderCalendarData(filteredList);
};
