import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { allowAccessWithoutCryptoIncentivePlan } from '../constants/crypto';

// react-hook-function for checking for skip payment if payment is not done
export const useSkipPayment = () => {
  const allowAccess = useSelector((state) =>
    get(
      state,
      'profile.profileData.allowAccessWithoutCryptoIncentivePlan',
      allowAccessWithoutCryptoIncentivePlan.SKIPPED,
    ),
  );

  /** It is to check whether the access for the recruiter is disabled if they have not paid yet */
  const isBlocked = useCallback(() => {
    if (allowAccess === allowAccessWithoutCryptoIncentivePlan.DISABLE) {
      return true;
    }
    return false;
  }, [allowAccess]);

  /** If recruiters can skip the payment if not paid yet */
  const canSkip = useCallback(() => {
    if (allowAccess === allowAccessWithoutCryptoIncentivePlan.ENABLE) {
      return true;
    }
    return false;
  }, [allowAccess]);

  /** recruiters can navigate to other routes if have not paid yet */
  const canNavigate = useCallback(() => {
    if (
      allowAccess === allowAccessWithoutCryptoIncentivePlan.SKIPPED ||
      allowAccess === allowAccessWithoutCryptoIncentivePlan.ENABLE
    ) {
      return true;
    }
    return false;
  }, [allowAccess]);

  /** If recruiter clicked on skip button in the payment page */
  const isSkipped = useCallback(() => {
    return allowAccess === allowAccessWithoutCryptoIncentivePlan.SKIPPED;
  }, [allowAccess]);

  return {
    canNavigate,
    canSkip,
    isBlocked,
    isSkipped,
  };
};
