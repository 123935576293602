import React from 'react';
import styled from 'styled-components';
import { PageContainerProps } from '../../interfaces/components/PageContainer';
import { BOX_SHADOW } from '../../styles/constants/box-shadow';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { PADDING } from '../../styles/constants/display';

export const PageLayout: React.FC = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

export const FlexPage: React.FC = ({ children }) => {
  return <StyledFlexPage>{children}</StyledFlexPage>;
};

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  margin = null,
  padding = null,
  borderRadius = null
}) => {
  return (
    <Container margin={margin} padding={padding} borderRadius={borderRadius}>
      {children}
    </Container>
  );
};

export const Container = styled.div<PageContainerProps>`
  box-shadow: ${BOX_SHADOW[1]};
  background-color: ${COLORS.WHITE};
  padding: ${(props) => props.padding || '25px'};
  margin: ${(props) => props.margin || '20px 0'};
  position: relative;
  width: 100%;
  border-radius: ${(props) => props.borderRadius || '0'};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(10)}
  }
`;

const StyledLayout = styled.div`
  position: relative;
`;

const StyledFlexPage = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;
