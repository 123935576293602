import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import auth from './features/auth';
import profile from './features/profile';
import chat from './features/chat';
import crew from './features/crew';
import events from './features/events';
import analytics from './features/analytics';
import billingAddress from './features/billingAddress';
import recommendedEvents from './features/recommendedEvents';
import jobDetails from './features/jobDetails';
import calendarEvents from './features/calendar';
import candidateProfile from './features/recruiterCandidate';
import candidateBookedEventsReducer from './features/candidateBookedEvents';
import jobOpportunitiesReducer from './features/jobOpportunities';
import cryptoIncentivePlan from './features/cryptoPlan';
import commons from './features/commons';
import notifications from './features/notifications';
import wallet from './features/virtualWallet';
import config from '../config';
import recruiterApi from './features/rtkApi/recruiterApi';
import masterAdminApis from './features/rtkApi/masterAdminApi';
import organizerApis from './features/rtkApi/organizerApi';
import commonApis from './features/rtkApi/common';

const reducer = {
  auth,
  profile,
  wallet,
  chat,
  crew,
  events,
  recommendedEvents,
  analytics,
  billingAddress,
  jobDetails,
  calendarEvents,
  candidateBookedEventsReducer,
  jobOpportunitiesReducer,
  commons,
  candidateProfile,
  notifications,
  cryptoIncentivePlan,
  [recruiterApi.reducerPath]: recruiterApi.reducer,
  [masterAdminApis.reducerPath]: masterAdminApis.reducer,
  [organizerApis.reducerPath]: organizerApis.reducer,
  [commonApis.reducerPath]: commonApis.reducer,
};

export const middleware = config.under_dev
  ? getDefaultMiddleware().concat([
      logger,
      recruiterApi.middleware,
      masterAdminApis.middleware,
      organizerApis.middleware,
      commonApis.middleware,
    ])
  : getDefaultMiddleware().concat([
      recruiterApi.middleware,
      masterAdminApis.middleware,
      organizerApis.middleware,
      commonApis.middleware,
    ]);

const store = configureStore({
  reducer,
  middleware,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
