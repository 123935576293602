import React from 'react';
import { Progress, Row, Skeleton } from 'antd';
import styled from 'styled-components';
import {
  getCandidateProfileCompletionPercentage,
  userProfileStore,
} from '../../utils/profile';
import {
  CandidateProgressDefaultType,
  CandidateProgressDefaultWidth,
  CandidateProgressDefaultWidthSMALL,
  CANDIDATE_COLOR,
  StrokeWidth,
} from '../../constants/index';
import { StyledMediumFont } from './Texts';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { isLaptop } from '../../helpers/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

interface CandidateProfilePercentageProps {
  text?: string;
  percent?: any;
}

export const CandidateProfilePercentage: React.FC<CandidateProfilePercentageProps> = ({
  text,
  percent,
}) => {
  const { profileData } = userProfileStore();

  return !profileData ? (
    <Skeleton />
  ) : (
    <Wrapper className="candidate-profile-completion-feature-tour">
      {text ? <Title>{text}</Title> : null}
      <Progress
        type={CandidateProgressDefaultType}
        percent={
          Math.round(percent) ||
          getCandidateProfileCompletionPercentage(profileData)
        }
        width={
          isLaptop()
            ? CandidateProgressDefaultWidthSMALL
            : CandidateProgressDefaultWidth
        }
        strokeColor={CANDIDATE_COLOR}
        strokeWidth={StrokeWidth}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  margin: auto 20px auto 0;
  align-items: center;
  width: max-content;
`;

const Title = styled(StyledMediumFont)`
  margin: 0px 8px;
  color: ${COLORS.RICH_BLUE};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: 14px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 15px;
  }
`;
