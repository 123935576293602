import { ORGANIZER, CANDIDATE, RECRUITER } from './index';

export const DELETE_ALL = 'delete_all';

export const CHAT_STATUS = {
  DISABLED: 30,
  ACTIVE: 10,
};

export const MESSAGE_CONTENT_TYPE = {
  TEXT: 1,
  IMAGE: 2,
  GIF: 3,
};
export const MESSAGE_CONTENT = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  GIF: 'GIF',
};

export const CHAT_ID_TYPE = {
  PERSONAL: 'personal',
  CHAT_MESSAGE_INPUT: 'chat-message-input',
  GROUP_CHAT_MESSAGE_INPUT: 'group-chat-message-input',
};

export const CHAT_USER_TYPE = {
  [ORGANIZER]: 'company',
  [RECRUITER]: 'company',
  [CANDIDATE]: 'individual',
};
