export enum USDCAddress {
  TEST = '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  MAIN = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
}

export enum TransactionStatus {
  SUCCESS = 'success',
  IDLE = 'idle',
  ERROR = 'error',
  LOADING = 'loading',
}
