import React from 'react';
import { LiveEventIcon } from '../../svgicons';
import { StyledBroadcastTagContainer } from '../../shared-components/Broadcast/Common';

const UpcomingTag = ({ event }) => {
  return (
    <StyledBroadcastTagContainer>
      Upcoming
      <span>
        <LiveEventIcon />
      </span>
    </StyledBroadcastTagContainer>
  );
};

export default UpcomingTag;
