import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import { AvatarPictureProps } from '../../interfaces/AvatarPicture';

export const AvatarPicture: React.FC<AvatarPictureProps> = ({
  picture = '',
  size,
}) => {
  return (
    <>
      {picture ? (
        <Avatar size={size} src={picture} />
      ) : (
        <Avatar size={size} icon={<UserOutlined />} />
      )}
    </>
  );
};
