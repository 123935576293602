export const currency = [
  {
    id: 1,
    name: 'USD $',
  },
  {
    id: 2,
    name: 'INR ',
  },
];

export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_CURRENCY_WITH_COUNTRY = 'USA (USD)';
