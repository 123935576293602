import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { getActionLink, getPostingTime } from '../../../utils/notifications';
import { STATUS_ACTIVE } from '../../../constants';
import {
  NotificationItemProps,
  StyledNotificationItemRowProps,
  StyledDivNotificationItemDescriptionProps,
} from '../../../interfaces/components/Notifications';
import { getTextColor } from '../../../utils/common';
import { getColorName } from '../../../utils/notification';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import {
  DISPLAY_FLEX,
  getTextOverflowStyle,
} from '../../../styles/constants/display';
import {
  changeChatState,
  changeChatWindow,
  changeSelectedChatId,
  checkAndReGenerate,
} from '../../../store/features/chat';
import { viewEventUrl } from '../../Events/Common';
import { checkIfUserIsCandidate } from '../../../utils/userInfo';


export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  isTopbar = false,
  roleId,
  profileData,
  isChat = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (notificationData: any) => {
    // Get action link based on action and roleId

    const actionLink = getActionLink(notificationData, profileData, roleId);
    if (typeof actionLink === 'string') {
      history.push(actionLink);
    }

    if (isChat) {
      if (!notificationData.isGroup) {
        setTimeout(() => {
          dispatch(checkAndReGenerate());
          dispatch(
            changeSelectedChatId(
              get(
                notification,
                'chatRoom._id',
                get(notification, 'chatRoom.id'),
              ),
            ),
          );
          dispatch(changeChatState(true));
          dispatch(changeChatWindow(true));
        }, 4000);
      } else if (checkIfUserIsCandidate(roleId)) {
        history.push(
          viewEventUrl(roleId, get(notificationData, 'chatRoom.eventId'), true),
        );
      }

    }
  };

  return (
    <StyledNotificationItemRow
      newItem={notification.isViewed !== STATUS_ACTIVE && !isTopbar}
      color={getColorName(notification.category, roleId)}
      isTopbar={isTopbar}
      onClick={() => handleClick(notification)}
    >
      <StyledNotificationItemContainerCol xs={20}>
        <StyledDivNotificationItemTitle
          color={getColorName(notification.category, roleId)}
          isTopbar={isTopbar}
        >
          {notification.title}
        </StyledDivNotificationItemTitle>
        <StyledDivNotificationItemDescription isTopbar={isTopbar}>
          {notification.text}
        </StyledDivNotificationItemDescription>
      </StyledNotificationItemContainerCol>
      <StyledDivNotificationItemTime isTopbar={isTopbar}>
        {`${getPostingTime(notification.dateTime)} ago`}
      </StyledDivNotificationItemTime>
    </StyledNotificationItemRow>
  );
};

const StyledNotificationItemRow = styled(Row)<StyledNotificationItemRowProps>`
  ${DISPLAY_FLEX('row')};
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  height: ${(props) => (props.isTopbar ? '50px' : '80px')};
  margin-left: ${(props) => (props.isTopbar ? '10px' : '')};
  padding-left: ${(props) => (props.isTopbar ? '15px' : '30px')};
  background-color: ${(props) => (props.newItem ? COLORS.CULTURED : '')};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: ${(props) => (props.isTopbar ? '' : '100px')};
    padding-left: ${(props) => (props.isTopbar ? '' : ' 40px')};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: ${(props) => (props.isTopbar ? '' : '60px')};
    padding-left: ${(props) => (props.isTopbar ? '' : ' 20px')};
  }

  &::before {
    width: ${(props) => (props.isTopbar ? '8px' : '12px')};
    height: ${(props) => (props.isTopbar ? '50px' : '80px')};
    content: '';
    position: absolute;
    left: 0;
    background-color: ${(props) => getTextColor(props.color)};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: ${(props) => (props.isTopbar ? '' : '14px')};
      height: ${(props) => (props.isTopbar ? '' : '100px')};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: ${(props) => (props.isTopbar ? '' : '8px')};
      height: ${(props) => (props.isTopbar ? '' : '60px')};
    }
  }
`;

const StyledNotificationItemContainerCol = styled(Col)`
  ${DISPLAY_FLEX('column', 'center')};
`;

const StyledDivNotificationItemTitle = styled.div<
  StyledDivNotificationItemDescriptionProps
>`
  font-size: ${(props) => (props.isTopbar ? FONT[12] : FONT[14])};
  font-weight: ${FONT_WEIGHTS.BOLD};
  color: ${(props) => getTextColor(props.color)};
  ${getTextOverflowStyle(1)}

  line-height: 15px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[12]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${(props) => (props.isTopbar ? FONT[16] : FONT[20])};
    line-height: 18px;
  }
`;

const StyledDivNotificationItemDescription = styled.div<
  StyledDivNotificationItemDescriptionProps
>`
  font-weight: ${FONT_WEIGHTS.REGULAR};
  font-size: ${(props) => (props.isTopbar ? FONT[12] : FONT[14])};
  margin-top: ${(props) => (props.isTopbar ? '8px' : '10px')};
  margin-bottom: ${(props) => (props.isTopbar ? '-8px' : '0')};
  line-height: ${(props) => (props.isTopbar ? '1.4' : '')};
  text-overflow: ${(props) => (props.isTopbar ? 'ellipsis' : '')};
  white-space: ${(props) => (props.isTopbar ? 'nowrap' : '')};
  overflow: ${(props) => (props.isTopbar ? 'hidden' : '')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: ${(props) => (props.isTopbar ? '-' : '5px')};
    margin-bottom: ${(props) => (props.isTopbar ? '-10px' : '')};
    font-size: ${FONT[10]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${(props) => (props.isTopbar ? FONT[15] : FONT[18])};
  }
`;

const StyledDivNotificationItemTime = styled.div<
  StyledDivNotificationItemDescriptionProps
>`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${COLORS.PHILLIPPINE_GRAY};
  font-size: ${(props) => (props.isTopbar ? FONT[12] : FONT[14])};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    top: 10px;
    right: 5px;
    font-size: ${FONT[10]};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${(props) => (props.isTopbar ? FONT[14] : FONT[16])};
  }
`;
