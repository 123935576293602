import React, { useEffect, useContext, Suspense } from 'react';
import { Skeleton } from 'antd';
import { Switch, Route } from 'react-router-dom';
import get from 'lodash/get';
import ConfigContext from '../store/configContext';
import TeamIcon from '../assets/icons-and-sets/Crew.svg';
import DashboardIcon from '../assets/icons-and-sets/dashboard.svg';
import CouponsSvg from '../assets/icons-and-sets/promo-code.svg';
import BussinessUser from '../assets/svg/bMan.svg';
import JobIcon from '../assets/svg/jobs.svg';
import CandidateIcon from '../assets/svg/User.svg';
import EventIcon from '../assets/icons-and-sets/events.svg';
import SkillIcon from '../assets/icons-and-sets/menu1.svg';
import SkillNIcon from '../assets/icons-and-sets/menu2.svg';
import MeterIcon from '../assets/icons-and-sets/menu4.svg';
import NotificationIcon from '../assets/icons-and-sets/alarm.svg';
import AbuseIcon from '../assets/icons-and-sets/menu5.svg';
import Dashboard from './MasterAdmin/Dashboard/Dashboard';
import { AdminRoutes } from '../constants/routes';
import Settings from '../assets/svg/settings.svg';
import AttendeesIcon from '../assets/icons-and-sets/attendees.svg';
import { checkIfUserIsMasterAdmin, getRoleId } from '../utils/userInfo';
import { redirectToProperUserRole } from '../utils/common';
import { safeLoad } from '../utils/loader';
import { RETRY_ATTEMPTS, RETRY_INTERVALS } from '../constants';

const CommingSoon = React.lazy(() =>
  safeLoad(() => import('./CommingSoon'), RETRY_ATTEMPTS, RETRY_INTERVALS),
);

const Candidate = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Candidate'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const ViewMergeSkillHistory = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/Skills/ViewMergeSkillHistory'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const UnMatchedCandidates = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/Skills/UnMatchedCandidates'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const Skills = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/Skills/Skills'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const GeneralSetting = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/generalSettings/index'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const SkillsNormalization = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/Skills/SkillsNormalization'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const AdminUserSignup = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Signup'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const RecruiterDetails = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Recruiter/RecruiterDetails'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const MergeHistory = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/Skills/MergeHistory'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const MatchedCandidates = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Events/MatchedCandidates'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Coupons = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Coupons/index'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const RecruiterInformation = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Recruiter/RecruiterInfomation'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const RecruiterOverview = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Recruiter/RecruiterOverview'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Attendees = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/AttendeesManagement'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Organizer = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Organizer/Organizers'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const OrganizerInformation = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Organizer/OrganizerInformation'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const MergedCompanyHistory = React.lazy(() =>
  safeLoad(
    () => import('./MasterAdmin/commons/manage-companies/MergedCompanyHistory'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const MergeCompanyManagement = React.lazy(() =>
  safeLoad(
    () =>
      import('./MasterAdmin/commons/manage-companies/MergeCompanyManagement'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const SnoozeEvents = React.lazy(() => {
  return safeLoad(
    () => import('../components/SnoozeEvents/SnoozeEvents'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const BulkEventInvite = React.lazy(() => {
  return safeLoad(
    () => import('../components/BulkEventInvite/BulkEventInvite'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Jobs = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Jobs'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});
const BroadcastEvent = React.lazy(() => {
  return safeLoad(
    () => import('./Organizer/BroadcastEvent'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});
const Application = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Events/Application'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Events = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Events'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const EventInformation = React.lazy(() => {
  return safeLoad(
    () => import('./MasterAdmin/Events/EventInformation'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const MasterAdmin = () => {
  const { navigationList, setNavigationList } = useContext(ConfigContext);
  const roleId = getRoleId();

  if (roleId && !checkIfUserIsMasterAdmin(roleId)) {
    redirectToProperUserRole(roleId);
  }

  useEffect(() => {
    setNavigationList([
      {
        Icon: DashboardIcon,
        title: 'Dashboard',
        route: '/master-admin/',
        exact: true,
        Component: Dashboard,
      },
      {
        Icon: TeamIcon,
        title: 'Organizer',
        exact: true,
        route: get(AdminRoutes, 'organizer.organizers'),
        Component: Organizer,
      },
      {
        title: 'recruiter-overview',
        route: `${get(AdminRoutes, 'recruiter.recruiterOverview')}/:id`,
        selectionPath: get(AdminRoutes, 'recruiter.recruiterOverview'),
        Component: RecruiterOverview,
        populate: false,
      },
      {
        title: 'recruiter-Information',
        route: `${get(AdminRoutes, 'recruiter.recruiterInformation')}/:id`,
        selectionPath: get(AdminRoutes, 'recruiter.recruiters'),
        Component: RecruiterInformation,
        populate: false,
      },
      {
        Icon: AttendeesIcon,
        title: 'Attendees Management',
        route: `${AdminRoutes.attendeesManagement}:tab`,
        selectionPath: AdminRoutes.attendeesManagement,
        routeToRedirect: `${AdminRoutes.attendeesManagement}1`,
        Component: Attendees,
      },
      {
        title: 'SnoozeEvents',
        route: `${AdminRoutes.snoozeEvents}`,
        selectionPath: get(AdminRoutes, 'event.event', ''),
        selectionSearch: AdminRoutes.snoozeEvents,
        Component: SnoozeEvents,
        populate: false,
      },
      {
        title: 'Bulk-Event_Booking',
        route: `${AdminRoutes.bulkInvite}/:tab`,
        selectionPath: get(AdminRoutes, 'event.event', ''),
        selectionSearch: AdminRoutes.bulkInvite,
        routeToRedirect: `${AdminRoutes.bulkInvite}/1`,
        Component: BulkEventInvite,
        populate: false,
      },
      {
        Icon: BussinessUser,
        title: 'Recruiter',
        exact: true,
        route: get(AdminRoutes, 'recruiter.recruiters', ''),
        selectionPath: get(AdminRoutes, 'recruiter.recruiters', ''),
        Component: RecruiterDetails,
      },
      {
        Icon: JobIcon,
        title: 'Jobs',
        route: get(AdminRoutes, 'job.jobs', ''),
        selectionPath: get(AdminRoutes, 'job.jobs', ''),
        Component: Jobs,
      },
      {
        Icon: CandidateIcon,
        title: 'Candidate',
        route: get(AdminRoutes, 'candidate.candidates', ''),
        selectionPath: get(AdminRoutes, 'candidate.candidates', ''),
        Component: Candidate,
      },
      {
        title: 'Event-Information',
        route: `${get(AdminRoutes, 'event.eventInfo', '')}/:id`,
        selectionPath: get(AdminRoutes, 'event.event', ''),
        Component: EventInformation,
        populate: false,
      },
      {
        Icon: EventIcon,
        title: 'Events',
        exact: true,
        route: get(AdminRoutes, 'event.event', ''),
        selectionPath: get(AdminRoutes, 'event.event', ''),
        Component: Events,
      },
      {
        Icon: CouponsSvg,
        title: 'Coupons',
        exact: true,
        route: get(AdminRoutes, 'coupons', ''),
        selectionPath: get(AdminRoutes, 'coupons', ''),
        Component: Coupons,
      },
      {
        title: 'Broadcast-Links',
        route: get(AdminRoutes, 'event.broadcast', ''),
        selectionPath: get(AdminRoutes, 'event.event', ''),
        Component: BroadcastEvent,
        populate: false,
      },
      {
        Icon: SkillIcon,
        title: 'Skills',
        route: get(AdminRoutes, 'skill.skills', ''),
        selectionPath: get(AdminRoutes, 'skill.skills', ''),
        Component: Skills,
      },
      {
        Icon: SkillNIcon,
        exact: true,
        title: 'Skills Normalization',
        route: get(AdminRoutes, 'skillsNormalization.index', ''),
        Component: SkillsNormalization,
      },
      {
        title: 'Merge History',
        exact: true,
        route: get(AdminRoutes, 'skillsNormalization.mergeHistory', ''),
        selectionPath: get(AdminRoutes, 'skillsNormalization.index', ''),
        Component: MergeHistory,
        populate: false,
      },
      {
        title: 'View Merge History',
        route: get(
          AdminRoutes,
          'skillsNormalization.viewSkillMergeHistory',
          '',
        ),
        exact: true,
        selectionPath: get(AdminRoutes, 'skillsNormalization.index', ''),
        Component: ViewMergeSkillHistory,
        populate: false,
      },
      {
        exact: true,
        title: 'Skill Demand Supply',
        route: get(AdminRoutes, 'skillsNormalization.skillDemandSupply', ''),
        selectionPath: get(AdminRoutes, 'skillsNormalization.index', ''),
        Component: UnMatchedCandidates,
        populate: false,
      },
      {
        Icon: TeamIcon,
        title: 'Signup',
        exact: true,
        route: get(AdminRoutes, 'signup', ''),
        Component: AdminUserSignup,
      },
      {
        Icon: Settings,
        title: 'General Setting',
        route: `${AdminRoutes.generalSetting}:tab`,
        selectionPath: AdminRoutes.generalSetting,
        routeToRedirect: `${AdminRoutes.generalSetting}1`,
        Component: GeneralSetting,
      },
      {
        Icon: MeterIcon,
        title: 'Metering',
        route: '/master-admin/metering/',
        Component: CommingSoon,
      },
      {
        Icon: NotificationIcon,
        title: 'Notifications',
        route: '/master-admin/notifications/',
        Component: CommingSoon,
      },
      {
        Icon: AbuseIcon,
        title: 'Abuse Management',
        route: '/master-admin/abuse-management/',
        Component: CommingSoon,
      },

      {
        title: 'Job Match Candidates',
        route: `${get(AdminRoutes, 'event.matchCandidates', '')}:eventId`,
        selectionPath: get(AdminRoutes, 'event.event', ''),
        Component: MatchedCandidates,
        populate: false,
      },
      {
        title: 'Applications',
        route: get(AdminRoutes, 'event.eventApplication', ''),
        selectionPath: get(AdminRoutes, 'event.event', ''),
        exact: true,
        Component: Application,
        populate: false,
      },
      {
        title: 'Organizer Information',
        route: get(AdminRoutes, 'organizer.organizerInformation', ''),
        selectionPath: get(AdminRoutes, 'organizer.organizers', ''),
        exact: true,
        Component: OrganizerInformation,
        populate: false,
      },
      {
        title: 'Organizer Merge Company History',
        route: get(AdminRoutes, 'organizer.mergedCompaniesHistory', ''),
        selectionPath: get(AdminRoutes, 'organizer.organizers', ''),
        exact: true,
        Component: MergedCompanyHistory,
        populate: false,
      },
      {
        title: 'Organizer Merge Companies',
        route: get(AdminRoutes, 'organizer.manageCompanies', ''),
        selectionPath: get(AdminRoutes, 'organizer.organizers', ''),
        exact: true,
        Component: MergeCompanyManagement,
        populate: false,
      },
      {
        title: 'Recruiter Merge Company History',
        route: get(AdminRoutes, 'recruiter.mergedCompaniesHistory', ''),
        selectionPath: get(AdminRoutes, 'recruiter.recruiters', ''),
        exact: true,
        Component: MergedCompanyHistory,
        populate: false,
      },
      {
        title: 'Recruiter Merge Companies',
        route: get(AdminRoutes, 'recruiter.manageCompanies', ''),
        selectionPath: get(AdminRoutes, 'recruiter.recruiters', ''),
        exact: true,
        Component: MergeCompanyManagement,
        populate: false,
      },
    ]);
  }, [setNavigationList]);

  return (
    <div>
      <Suspense fallback={<Skeleton />}>
        <Switch>
          {navigationList.map((navItem, idx) => {
            const { route, Component, exact } = navItem;
            return (
              <Route
                key={idx}
                path={route}
                component={Component}
                exact={!!exact}
              />
            );
          })}
        </Switch>
      </Suspense>
    </div>
  );
};

export default MasterAdmin;
