import React from 'react';
import { Link } from 'react-router-dom';
import isInteger from 'lodash/isInteger';
import { RecruiterRoutes } from '../../../constants/routes';
import EarthIcon from '../../../assets/icons-and-sets/047-earth-grid.svg';
import BreifCaseOutlinedIcon from '../../../assets/icons-and-sets/briefcase.svg';
import ApplicationIcons from '../../../assets/icons-and-sets/049-copy.svg';
import CalendarIcon from '../../../assets/icons-and-sets/calendar.svg';
import { IconBadge } from '../../shared-components/IconBadge';
import { COLORS } from '../../../styles/constants/colors';

export default function RecruiterAnalytics({ analytics, theme }) {
  if (!analytics) {
    return null;
  }
  const { bookedEvents, jobs, jobApplications, interviews } = analytics;

  if (
    !isInteger(bookedEvents) ||
    !isInteger(jobs) ||
    !isInteger(jobApplications) ||
    !isInteger(interviews)
  ) {
    return null;
  }

  const itemList = [
    {
      icon: EarthIcon,
      itemName: 'Registered Events',
      itemValue: bookedEvents,
      to: RecruiterRoutes.event,
    },
    {
      icon: BreifCaseOutlinedIcon,
      itemName: 'Jobs',
      itemValue: jobs,
      to: `${RecruiterRoutes.recruit}2`,
    },
    {
      icon: ApplicationIcons,
      itemName: 'Applications Received',
      itemValue: jobApplications,
      to: `${RecruiterRoutes.recruit}1`,
    },
    {
      icon: CalendarIcon,
      itemName: 'Interview Scheduled',
      itemValue: interviews,
      to: RecruiterRoutes.calender,
    },
  ];
  return (
    <>
      {itemList.map((item) => (
        <Link to={item.to}>
          <IconBadge
            icon={item.icon}
            text={item.itemName}
            textColor={COLORS.WHITE}
            status={item.itemValue}
            theme={theme}
            hoverEffect
            color={COLORS.SALOMIE}
          />
        </Link>
      ))}
    </>
  );
}
