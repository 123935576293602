import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Col, Skeleton } from 'antd';
import { getDashboardAnalyticsData } from '../../../store/features/analytics';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
} from '../../../utils/userInfo';
import { DashboardAnalyticsStore } from '../../../utils/analytics';
import OrganizerAnalyticsItem from './OrganizerAnalytics';
import RecruiterAnalyticsItem from './RecruiterAnalytics';
import CandidateAnalyticsItem from './CandidateAnalytics';
import { ApplicationStatusCard } from '../../shared-components/ApplicationStatusCard';
import { getUser } from '../../../utils/common';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

const DashboardAnalytics = () => {
  const roleId = getRoleId();
  const { isLoading, analytics, error } = DashboardAnalyticsStore();
  const dispatch = useDispatch();

  const UserAnalytics = () => {
    if (checkIfUserIsOrganizer(roleId)) {
      return <OrganizerAnalyticsItem analytics={analytics} theme="organizer" />;
    }

    if (checkIfUserIsRecruiter(roleId)) {
      return <RecruiterAnalyticsItem analytics={analytics} theme="recruiter" />;
    }

    return <CandidateAnalyticsItem analytics={analytics} theme="candidate" />;
  };

  useEffect(() => {
    dispatch(getDashboardAnalyticsData(roleId));
  }, [dispatch, roleId]);

  return isLoading || error ? (
    <StyledCol xl={6} xs={24} role={checkIfUserIsCandidate(roleId)}>
      <Skeleton />
    </StyledCol>
  ) : (
    <StyledCol xl={6} xs={24} role={checkIfUserIsCandidate(roleId)}>
      <ApplicationStatusCard
        theme={getUser(roleId)}
        analytics={analytics}
        UserAnalytics={UserAnalytics}
      />
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  margin-top: ${({ role }) => (role ? '15px' : '30px')};
  padding-left: 30px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: ${({ role }) => (role ? '25px' : '48px')};
  }

  @media (max-width: ${BREAKPOINTS.DESKTOP_SPECIAL_CASE_2}) {
    padding-left: 15px;
  }

  @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
    padding-left: 0;
    margin-bottom: ${({ role }) => role && '40px'};
  }
`;

export default DashboardAnalytics;
