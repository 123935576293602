import { CurrencyProps } from '../interfaces/common/';

// Country Locale
export const LOCALES: CurrencyProps = {
  INR: 'en-IN',
  USA: 'en-US',
  EUR: 'en-EU',
  JPY: 'jp-JP',
  CNY: 'zh-CN',
  DEFAULT: 'en-US',
};
