import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import styled from 'styled-components';
import EventAnalyticsCandidate from './EventAnalyticsCandidate';
import EventAnalyticsCompany from './EventAnalyticsCompany';
import Information from './Information';
import EventAnalytics from './EventAnalytics';
import PastEventAnalytics from '../Events/PastEvent/PastEventAnalytics';
import { CandidateCode, CompanyCode } from '../../constants/attendees';
import {
  checkIfUserIsRecruiter,
  getRoleId,
  checkIfUserIsOrganizer,
  checkIfUserIsMasterAdmin,
} from '../../utils/userInfo';
import { DropdownBox } from '../shared-components/Form/Form';
import OrganizerPastEventsAnalytics from '../Events/PastEvent/OrganizerPastEventsAnalytics';
import { currentDate } from '../../utils/formatDateTime';
import { EventsStore, getEventName, getEventOptions } from '../../utils/event';
import {
  EventAnalyticsForJobStore,
  EventAnalyticsStore,
} from '../../utils/analytics';
import {
  getEventAnalyticsData,
  getEventAnalyticsDataByJobId,
} from '../../store/features/analytics';
import { getJobDetailsData } from '../../store/features/jobDetails';
import { JobDetailsStore } from '../../utils/jobDetails';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import {
  largeFontMixin,
  mediumHeadingMixin,
} from '../../styles/constants/mixins';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { DateAndTypeSection } from '../shared-components/Event/DateAndTypeSection';
import { Modal } from '../shared-components/Modal';
import { getThemeByRoleId } from '../../utils/common';

// Note: these features will be added in future version
// const menu = (
//   <Menu className="crew_menu">
//     <Menu.Item key="0">
//       <a href={() => false}>Export Report</a>
//     </Menu.Item>
//     <Menu.Item key="1">
//       <a href={() => false}>Delete</a>
//     </Menu.Item>
//   </Menu>
// );

const AnalyticsModal = ({
  analysisFor,
  handleModalCancel,
  handleOk,
  visible,
  isPastEventAnalyticsModal = false,
  tabEventInfo,
  type,
  roleId,
  eventInfo,
}) => {
  // Note: It's required to work timezone properly
  const eventData = tabEventInfo && type ? tabEventInfo[type].event : eventInfo;
  const dispatch = useDispatch();
  const { jobDetailsData } = JobDetailsStore();
  const { onGoingEvents, pastEvents, event } = EventsStore();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);

  const { analytics: eventAnalytics } = EventAnalyticsStore();

  if (!roleId) {
    roleId = getRoleId();
  }

  const events = onGoingEvents.concat(pastEvents);

  useEffect(() => {
    if (selectedEvent) {
      dispatch(getEventAnalyticsData(roleId, [selectedEvent]));
    }
  }, [dispatch, roleId, selectedEvent]);

  useEffect(() => {
    if (checkIfUserIsRecruiter(roleId)) {
      dispatch(getJobDetailsData('basic', 1, -1));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (checkIfUserIsRecruiter(roleId)) {
      if (selectedJob && selectedEvent) {
        dispatch(getEventAnalyticsDataByJobId(selectedJob, selectedEvent));
      }
    }
  }, [dispatch, selectedEvent, selectedJob, roleId]);

  const { analytics } = EventAnalyticsForJobStore();

  let name;

  // Extract re-assign values if available
  if (eventData) {
    name = getEventName(eventData);
  } else if (isPastEventAnalyticsModal) {
    name = getEventName(event);
  }

  const handleChangeEvent = (key, value) => {
    setSelectedEvent(key);
  };

  const handleChangeJobs = (key, value) => {
    setSelectedJob(key);
  };

  // analyticsUserCode is 1 for company and 2 for candidate
  const getModalHeading = (analyticsUserCode) => {
    return analyticsUserCode === CompanyCode
      ? 'Company Analytics & Report'
      : 'Candidate Analytics & Report';
  };

  const getEventAnalytics = () => {
    if (isPastEventAnalyticsModal) {
      if (checkIfUserIsOrganizer(roleId)) {
        return (
          <EventAnalytics
            EventAnalyticsItemComponent={OrganizerPastEventsAnalytics}
            isCursorPointer={false}
          />
        );
      }
      return (
        <EventAnalytics
          EventAnalyticsItemComponent={PastEventAnalytics}
          isCursorPointer={false}
        />
      );
    }
    if (analysisFor === CompanyCode) {
      return (
        <EventAnalytics
          EventAnalyticsItemComponent={EventAnalyticsCompany}
          analytics={tabEventInfo[type].analytics}
          isCursorPointer={false}
        />
      );
    }
    return (
      <EventAnalytics
        EventAnalyticsItemComponent={EventAnalyticsCandidate}
        analytics={tabEventInfo[type].analytics}
        isCursorPointer={false}
      />
    );
  };

  const analyticsCandidate = get(
    tabEventInfo,
    `${CandidateCode}.analytics`,
    null,
  );

  const analyticsCompany = get(tabEventInfo, `${CompanyCode}.analytics`, null);

  const getAnalyticsData = () => {
    if (checkIfUserIsOrganizer(roleId) || checkIfUserIsMasterAdmin(roleId)) {
      switch (analysisFor) {
        case CandidateCode:
          return analyticsCandidate;
        case CompanyCode:
          return analyticsCompany;
        default:
          return eventAnalytics && eventAnalytics[0];
      }
    } else {
      return analytics;
    }
  };

  return (
    <Modal
      title={
        isPastEventAnalyticsModal
          ? 'Event Analytics & Report'
          : getModalHeading(analysisFor)
      }
      visible={visible}
      onOk={handleOk}
      closable={false}
      onCancel={handleModalCancel}
      className={`analytics-modal ${
        isPastEventAnalyticsModal && !checkIfUserIsOrganizer(roleId)
          ? checkIfUserIsRecruiter(roleId)
            ? 'recruiter-analytics-modal'
            : 'candidate-analytics-modal'
          : null
      }`}
      width="80%"
      footer={null}
      theme={getThemeByRoleId(roleId)}
    >
      <div>
        <StyledAnalyticsHeadingRow>
          {/* Note: not required */}
          {/* <Col xxl={16} xl={16} lg={16} xs={12}>
            <Row className="analytics-modal__heading__title--left">
              {isPastEventAnalyticsModal
                ? 'Jobs Information'
                : 'Event Information'}
            </Row>
          </Col> */}
          <Col xxl={8} xl={8} lg={8} xs={12}>
            <Row>
              {/* TODO add button for organizer Earning and report ....to be done after refratoring */}
              {/* Note: these features will be added in future version */}
              {/* <div className="form-group righttimer">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Tooltip placement="left">
                    <span className="analytics-modal__heading__icon--right">
                      <div className="analytics-modal__heading__icon--right--gray" />
                      <div className="analytics-modal__heading__icon--right--gray" />
                      <div className="analytics-modal__heading__icon--right--gray" />
                    </span>
                  </Tooltip>
                </Dropdown>
              </div> */}
            </Row>
          </Col>
        </StyledAnalyticsHeadingRow>
        {isPastEventAnalyticsModal && !checkIfUserIsOrganizer(roleId) ? (
          <StyledAnalyticsItemRow>
            <Col lg={10} xs={24}>
              <Row>
                <Col lg={6} xs={24}>
                  Select Event
                </Col>
                <Col lg={16} xs={24}>
                  <DropdownBox
                    className="header-Select"
                    handleChange={handleChangeEvent}
                    options={getEventOptions(events, true)}
                    placeholder="Select Event"
                    showSearch
                  />
                </Col>
              </Row>
            </Col>
            <StyledModalCol lg={10} xs={24}>
              <Row>
                <StyledHeaderCol lg={6} xs={24}>
                  Select Job
                </StyledHeaderCol>
                <Col lg={16} xs={24}>
                  <DropdownBox
                    className="header-Select"
                    handleChange={handleChangeJobs}
                    options={jobDetailsData.map((job, i) => ({
                      id: job.id,
                      name: job.designation,
                    }))}
                    placeholder="Select Job"
                  />
                </Col>
              </Row>
            </StyledModalCol>
          </StyledAnalyticsItemRow>
        ) : (
          <>
            <StyleHeadingLevelFive>Event Name: {name}</StyleHeadingLevelFive>
            <DateAndTypeSection event={eventData} />
          </>
        )}
      </div>
      {isPastEventAnalyticsModal ? (
        <StyledHeadingLevelThree id="analytics_modal_title">
          Overall Event Analytics
        </StyledHeadingLevelThree>
      ) : (
        <StyledAnalyticsRow justify="space-between">
          <Col>
            <StyledAnalyticsText>Event Analytics</StyledAnalyticsText>
          </Col>
          <Col>
            <StyledAnalyticsText>
              Today {currentDate(true, 'll')}
            </StyledAnalyticsText>
          </Col>
        </StyledAnalyticsRow>
      )}

      {getEventAnalytics()}

      <Information
        isPastEventAnalyticsModal={isPastEventAnalyticsModal}
        analysisFor={analysisFor}
        data={getAnalyticsData()}
      />
    </Modal>
  );
};

export default AnalyticsModal;

const StyleHeadingLevelFive = styled.h5`
  color: ${COLORS.ARSENIC};
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-size: 16px;
  margin-top: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 13px;
    letter-spacing: 0;
    color: ${COLORS.DAVYS_GREY};
    margin-top: 10px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-weight: ${FONT_WEIGHTS.BOLD};
    font-size: 18px;
  }
`;

const StyledAnalyticsText = styled.span`
  ${largeFontMixin};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledHeadingLevelThree = styled.h3`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-top: 25px;
  margin-bottom: 25px;

  ${mediumHeadingMixin};
`;

const StyledAnalyticsRow = styled(Row)`
  margin-bottom: 16px;
  margin-top: 15px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 10px;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-bottom: 15px;
    margin-top: 25px;
  }
`;

const StyledModalCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-top: 10px;
  }
`;

const StyledHeaderCol = styled(Col)`
  color: ${COLORS.CHINESE_BLACK};
  ${DISPLAY_FLEX('row', null, 'center')};
  margin-right: 10px;
  ${largeFontMixin};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    color: #8c8c8c;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 16px;
  }
`;

const StyledAnalyticsItemRow = styled(Row)`
  margin-top: 20px;
`;

const StyledAnalyticsHeadingRow = styled(Row)`
  color: black;
  font-weight: ${FONT_WEIGHTS.BOLD};
  font-size: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
