import get from 'lodash/get';
import api from './api';
import { Method, APIs } from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';

export const getNotifications = async (take, category, page, limit) => {
  return api({
    method: Method.get,
    url: `${APIs.notifications}?take=${take}&category=${category}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', []);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const getUpdates = async () => {
  return api({
    method: Method.get,
    url: `${APIs.notificationUpdates}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.updates', {});
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const updateNotifications = async ({ notificationIds, updateAll }) => {
  return api({
    method: Method.post,
    url: APIs.notificationsMarkViewed,
    body: { notificationIds, updateAll },
    errorHandlingMessage: false,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }

      return get(response, 'data.payload.message', '');
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
