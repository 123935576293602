import React, { useState, useCallback } from 'react';
import { Steps, Row, Col, Form, Tooltip, Checkbox } from 'antd';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { AutoCompleteBox } from '../../../../components/shared-components/Form/Form';
import {
  ADD,
  DELETE,
  EDIT,
  MASTER_ADMIN_COLOR,
  RECRUITER_COLOR,
  SKILLSTEPS,
  STATUS_ACTIVE,
  STATUS_DELETE,
} from '../../../../constants';
import { candidateSkillLevelConvert } from '../../../../utils/profile';
import { searchSkills } from '../../../../service/search';
import { requestNewSkill } from '../../../../service/requestSkill';
import Notification from '../../../../components/Notification/Notification';
import ContributeSkillModal from '../../../../components/Common/ContributeSkillModal';
import { SecondaryButton } from '../../../../components/shared-components/Button';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { COLORS } from '../../../../styles/constants/colors';
import { MARGIN, PADDING } from '../../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { checkIfUserIsRecruiter } from '../../../../utils/userInfo';
import { getRoleId } from '../../../../utils/userInfo';

// Store current contribute index

const CreateSkillsSetRow = ({
  skills,
  setSkills,
  skillStep,
  setSkillStep,
  requestSkill,
  setRequestSkill,
  skillsList,
  setSkillsList,
  contributeOption,
  setContributeOption,
  setContributedList,
  contributedList,
  isAnySkillClicked,
  mandatoryList,
  setMandatoryList,
  dummySkillListOptions,
  dummyContribution,
  inputSize = 5,
  isActionEditJob = false,
  setAreJobSkillsUpdated,
  recommendedSkills,
}) => {
  const [form] = Form.useForm();
  const [currentContributeIndex, setCurrentContributeIndex] = useState(0);
  const [skillName, setSkillName] = useState('');
  // const [skillsList, setSkillsList] = useState([]);
  // const [requestSkill, setRequestSkill] = useState({});
  // const [contributeOption, setContributeOption] = useState(0);

  const roleId = getRoleId();
  const isRecruiter = checkIfUserIsRecruiter(roleId);
  const themeColor = isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR;
  const [isContributeModalOpen, setIsContributeModalOpen] = useState(false);
  const SearchSkillsHandler = useCallback(
    debounce(async (i, value, skillsList, contributeOption) => {
      setSkillName(value);
      const response = await searchSkills(value);
      if (
        response &&
        response.data &&
        response.data.payload &&
        response.data.payload.skills
      ) {
        const data = response.data.payload.skills.map((skill) => {
          return { id: skill.id, value: skill.skill, label: skill.skill };
        });

        const temp2 = [...contributeOption];
        temp2[i] = 1;
        setContributeOption(temp2);

        const temp = [...skillsList];
        temp[i] = {
          id: i,
          options: data,
        };
        setSkillsList(temp);
      }
    }, 500),
    [],
  );

  const handleRemoveSkillsSetRow = (i) => {
    if (skills[i].operation !== EDIT) {
      const temp_skills = [...skills];
      temp_skills.splice(i, 1);
      setSkills(temp_skills);

      const temp_skillStep = [...skillStep];
      temp_skillStep.splice(i, 1);
      setSkillStep(temp_skillStep);

      const temp_requestSkill = [...requestSkill];
      temp_requestSkill.splice(i, 1);
      setRequestSkill(temp_requestSkill);

      const temp_skillsList = [...skillsList];
      temp_skillsList.splice(i, 1);
      setSkillsList(temp_skillsList);

      const cont = [...contributeOption];
      cont.splice(i, 1);
      setContributeOption(cont);

      const mandatoryListTemp = [...mandatoryList];
      mandatoryListTemp.splice(i, 1);
      setMandatoryList(mandatoryListTemp);
    } else {
      const temp_skills = [...skills];
      temp_skills[i] = { ...temp_skills[i], operation: DELETE };
      setSkills(temp_skills);
    }
    let contributeList = [...contributedList];
    const index = contributeList.indexOf(i);
    contributeList.splice(index, 1);
    contributeList = contributeList.map((id) => {
      if (id > i) {
        return id - 1;
      }
      return id;
    });
    setContributedList(contributeList);
    setAreJobSkillsUpdated(true);
  };

  // const handleSkillChange = (i, val) => {
  //     let temp = [...skills];
  //     temp[i] = {
  //         ...skills[i],
  //         id: i,
  //         skill: {
  //             id: val.value,
  //             skill: val.children
  //         },
  //         level: candidateSkillLevelConvert(skillStep),
  //         operation: ADD
  //     }
  //     setSkills(temp)
  // }
  const updateId = (skill, index) => {
    if (skill && (!isActionEditJob || !skill.id)) {
      skill.id = index;
    }
  };

  const handleSkillChange = (i, val) => {
    if (val.length > 1) {
      SearchSkillsHandler(i, val, skillsList, contributeOption);
    }
    if (!val.length) {
      const temp = [...contributeOption];
      temp[i] = 0;
      setContributeOption(temp);

      const tempSkills = [...skills];
      tempSkills[i] = [];
      setSkills(tempSkills);
    }

    const temp = [...requestSkill];
    temp[i] = {
      ...requestSkill[i],
      id: i,
      newSkill: {
        skill: val,
        category: val,
        description: val,
      },
    };
    setRequestSkill(temp);
  };

  const handleSkillOnSelect = (i, val) => {
    if (val && !isEmpty(val)) {
      const temp = [...skills];

      temp[i] = {
        ...skills[i],
        skill: {
          skillId: val.id,
          skillName: val.value,
        },
        level: candidateSkillLevelConvert(
          skillStep[i] ? skillStep[i].value : 1,
        ),
        skillPreference: mandatoryList[i],
        operation: skills[i].operation === EDIT ? EDIT : ADD,
      };
      updateId(temp[i], i);
      setSkills(temp);
      const tempContributeOptions = [...contributeOption];
      tempContributeOptions[i] = 0;
      setContributeOption(tempContributeOptions);
      const skillList = [...skillsList];
      if (skillList[i]?.options) {
        skillList[i].options = [];
      }
      setSkillsList(skillList);
      setAreJobSkillsUpdated(true);
    }
  };

  const handleSkillLevelChange = (i, val) => {
    if (isNumber(val)) {
      const temp = [...skills];
      temp[i] = {
        ...skills[i],
        level: candidateSkillLevelConvert(val),
        operation: skills[i].operation === EDIT ? EDIT : ADD,
      };
      updateId(temp[i], i);
      setSkills(temp);
      const temp2 = [...skillStep];
      temp2[i] = {
        value: val,
      };
      updateId(temp2[i], i);
      setSkillStep(temp2);
      setAreJobSkillsUpdated(true);
    }
  };

  const openContributeModal = (index) => {
    setCurrentContributeIndex(index);
    setIsContributeModalOpen(true);
  };

  const closeOpenContributeModal = () => {
    setIsContributeModalOpen(false);
  };

  const handleRequestNewSkill = async () => {
    const formFieldsData = await form.getFieldsValue();
    const { category, description } = formFieldsData;

    const skill = await get(
      requestSkill,
      `${[currentContributeIndex]}.newSkill.skill`,
      '',
    );

    const payload = {
      skill,
      category,
      description,
    };

    requestNewSkill(payload)
      .then((res) => {
        if (res && res.data && res.data.payload) {
          const newSkill = res.data.payload;
          const temp = [...skills];
          temp[currentContributeIndex] = {
            ...skills[currentContributeIndex],
            id: currentContributeIndex,
            skill: {
              skillId: newSkill.id,
              skillName: newSkill.skill,
            },
            level: candidateSkillLevelConvert(skillStep),
            skillPreference: mandatoryList[currentContributeIndex],
            operation: ADD,
          };
          setSkills(temp);
          Notification(
            'success',
            'Success',
            `You have successfully contributed ${requestSkill[currentContributeIndex].newSkill.skill} skill.`,
          );

          setContributedList((prevList) => [
            ...prevList,
            currentContributeIndex,
          ]);
          form.resetFields();
          closeOpenContributeModal();
        }
      })
      .catch(() => {
        Notification('error', 'Failed', 'Something went wrong.');
      });
  };

  const handleSwitchChange = (i, val) => {
    const idx = val?.target?.checked ? STATUS_ACTIVE : STATUS_DELETE;
    const mandatoryListTemp = [...mandatoryList];
    mandatoryListTemp[i] = idx;
    setMandatoryList(mandatoryListTemp);
    const temp = [...skills];
    temp[i] = {
      ...skills[i],
      level: candidateSkillLevelConvert(skillStep[i].value),
      skillPreference: idx,
      operation: skills[i].operation === EDIT ? EDIT : ADD,
    };
    updateId(temp[i], i);
    setSkills(temp);
    setAreJobSkillsUpdated(true);
  };

  const isContributedIn = (index) => {
    const res = contributedList.find((itemIndex) => itemIndex === index);
    return res >= 0;
  };

  const handleOnSkillBlur = (i) => {
    const skillList = [...skillsList];
    const skillOptions = skillList[i]?.options;

    const matchOption = skillOptions.filter((option) => {
      return option.value.toLowerCase() === skillName.toLowerCase();
    })[0];

    if (matchOption) {
      const temp = [...skills];

      temp[i] = {
        ...skills[i],
        skill: {
          skillId: matchOption.id,
          skillName: matchOption.value,
        },
        level: candidateSkillLevelConvert(
          skillStep[i] ? skillStep[i].value : 1,
        ),
        skillPreference: mandatoryList[i],
        operation: skills[i].operation === EDIT ? EDIT : ADD,
      };
      updateId(temp[i], i);
      setSkills(temp);
    } else {
      const skill = get(skills[i], 'skill.skillId', null);
      const skillNameTemp = get(skills[i], 'skill.skillName', '');

      if (!skill || skillNameTemp.toLowerCase() !== skillName.toLowerCase()) {
        const skillList = [...skillsList];
        skillList[i].options = [];
        setSkillsList(skillList);
      }
    }
  };

  const isSkillPreferenceMandatory = (skill) => {
    return skill.skillPreference === STATUS_ACTIVE;
  };

  function showRecommendedSkills(idx, skill) {
    if (idx !== 0 && !Object.keys(skill).includes('skill')) {
      return recommendedSkills.map((skill) => {
        const value = {
          id: skill.relatedSkill.id,
          value: skill.relatedSkill.skill,
          label: skill.relatedSkill.skill,
        };
        return (
          <SecondaryButton
            onClick={() => {
              handleSkillChange(idx, value.value);
              handleSkillOnSelect(idx, value);
            }}
            theme="recruiter"
          >
            {skill.relatedSkill.skill}
          </SecondaryButton>
        );
      });
    }
  }

  const onKeyDownHandler = (e, i) => {
    if (e.key === 'Enter') {
      const skillList = [...skillsList];
      const skillOptions = skillList[i]?.options;

      const matchOption = skillOptions.filter((option) => {
        return option.value.toLowerCase() === skillName.toLowerCase();
      })[0];

      if (matchOption) {
        const temp = [...skills];

        temp[i] = {
          ...skills[i],
          skill: {
            skillId: matchOption.id,
            skillName: matchOption.value,
          },
          level: candidateSkillLevelConvert(
            skillStep[i] ? skillStep[i].value : 1,
          ),
          skillPreference: mandatoryList[i],
          operation: skills[i].operation === EDIT ? EDIT : ADD,
        };
        updateId(temp[i], i);
        setSkills(temp);
      } else {
        const skill = get(skills[i], 'skill.skillId', null);
        const skillNameTemp = get(skills[i], 'skill.skillName', '');

        if (!skill || skillNameTemp.toLowerCase() !== skillName.toLowerCase()) {
          const skillList = [...skillsList];
          skillList[i].options = [];
          setSkillsList(skillList);
        }
      }
    }
  };

  return skills.length !== 0 && !isAnySkillClicked ? (
    <>
      {skills
        .map((skill, idx) => {
          const skillName = get(skill, 'skill.skillName', '');
          const skillNameAny = get(skill, 'skillName', '');
          const operation = get(skill, 'operation', ADD);
          const options = get(skillsList[idx], 'options', []);
          const step = get(skillStep[idx], 'value', 0);
          return operation !== DELETE &&
            skillName.toLowerCase() !== 'any' &&
            skillNameAny.toLowerCase() !== 'any' ? (
            <StyledRow key={idx.toString()}>
              <StyledValueCol md={inputSize}>
                <AutoCompleteBox
                  id="skillInputBox"
                  disabled={skillName && operation === EDIT ? true : false}
                  placeholder={skillName ? skillName : `Select...`}
                  text
                  defaultValue={skillName}
                  key={skillName}
                  options={dummySkillListOptions || options}
                  handleChange={(val) => handleSkillChange(idx, val)}
                  handleSelect={(val, option) =>
                    handleSkillOnSelect(idx, option)
                  }
                  onKeyDown={(e) => onKeyDownHandler(e, idx)}
                  formField={false}
                  required
                  onBlur={() => handleOnSkillBlur(idx)}
                />
                <StyledCheckBox themeColor={themeColor}>
                  <Tooltip title="Change skill preference. Mark required skill preference as mandatory for the job">
                    <Checkbox
                      id="skill-mandatory-checkbox"
                      indeterminate={isSkillPreferenceMandatory(skills[idx])}
                      onChange={(val) => handleSwitchChange(idx, val)}
                    />
                  </Tooltip>
                  <StyledSpan themeColor={themeColor}>Mandatory skill</StyledSpan>
                </StyledCheckBox>
                <StyledSkillsButton>
                  {recommendedSkills ? showRecommendedSkills(idx, skill) : null}
                </StyledSkillsButton>

                {/* Show contribute button for special test case */}
                {dummyContribution ||
                  (!options.length && contributeOption[idx] === 1) ? (
                  <StyledHintWrapper>
                    <StyledNoMatchText>
                      Seems like the skill is not in our database. Would you
                      like to contribute?
                    </StyledNoMatchText>
                    {isContributedIn(idx) ? (
                      <StyledYellowText themeColor={themeColor}> Contributed.!</StyledYellowText>
                    ) : (
                      // <Button onClick={() => openContributeModal(idx)}>
                      //   Like to contribute.?
                      // </Button>
                      <SecondaryButton
                        theme="recruiter"
                        onClick={() => openContributeModal(idx)}
                      >
                        Contribute?
                      </SecondaryButton>
                    )}
                  </StyledHintWrapper>
                ) : null}
              </StyledValueCol>

              <StyledStepLevelCol md={11} themeColor={themeColor}>
                <StyledSteps
                  size="small"
                  current={step}
                  labelPlacement="vertical"
                  onChange={(val) => handleSkillLevelChange(idx, val)}
                  key={idx}
                >
                  {SKILLSTEPS.map((stepValue) => (
                    <StyledStep title={stepValue} />
                  ))}
                </StyledSteps>
              </StyledStepLevelCol>

              <StyledDeleteCol md={1}>
                <Tooltip title="Click to delete the skill">
                  <StyledMinusCircle
                    id="remove-job-skill"
                    onClick={() => handleRemoveSkillsSetRow(idx)}
                  />
                </Tooltip>
              </StyledDeleteCol>
            </StyledRow>
          ) : null;
        })
        .reverse()}
      {isContributeModalOpen ? (
        <ContributeSkillModal
          isContributeModalOpen={isContributeModalOpen}
          closeOpenContributeModal={closeOpenContributeModal}
          form={form}
          handleRequestNewSkill={handleRequestNewSkill}
        />
      ) : null}
    </>
  ) : null;
};

export default CreateSkillsSetRow;

const StyledCheckBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
  gap: 10px;

  .ant-checkbox-inner {
    border-color: ${({ themeColor }) => themeColor};

    &::after {
      background-color: ${({ themeColor }) => themeColor};
    }
  }

  .ant-checkbox-checked::after {
    border-color: ${({ themeColor }) => themeColor};
  }
`;

export const StyledSpan = styled.div`
  color: ${({ themeColor }) => themeColor};
`;

const StyledSkillsButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: hidden;
  max-height: 90px;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    max-height: 75px;
  }

  & > button {
    flex: 1;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const StyledSteps = styled(Steps)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: auto !important;
    row-gap: 20px;
    margin-left: -20px;
  }

  white-space: nowrap;
`;

const StyledStep = styled(Steps.Step)`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 22%;
  }

  @media (max-width: 410px) {
    width: 25%;
  }
`;

const StyledValueCol = styled(Col)`
  ${PADDING(20, 10, 20, 0)};
  width: min-content;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
    padding-left: 0;
  }
  
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: ${COLORS.DARK_ANONYMOUS};
    background: ${COLORS.BRIGHT_GRAY};
  }
`;

const StyledHintWrapper = styled.div`
  margin-top: 15px;
`;

const StyledNoMatchText = styled.p`
  margin-bottom: 10px;
  font-weight: ${FONT_WEIGHTS.MEDIUM};
`;

const StyledYellowText = styled.span`
  color: ${({ themeColor }) => themeColor};
`;

const StyledStepLevelCol = styled(Col)`
  ${PADDING(20, 10, 20, 0)};

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: 1045px) {
    max-width: 455px;
  }

  @media (max-width: 525px) {
    margin-left: -8px;
  }

  .ant-steps-item-icon {
    background-color: transparent;
    border: 2px solid ${({ themeColor }) => themeColor};
    border-radius: 50%;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  .ant-steps-item-tail {
    padding-left: 35px;
    margin-top: -9px;

    &::after {
      height: 4px;
    }
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${({ themeColor }) => themeColor};
    color: ${COLORS.WHITE};
  }
  .ant-steps-item-wait .ant-steps-item-icon span {
    color: ${({ themeColor }) => themeColor};
  }

  .ant-steps-item-wait .ant-steps-item-icon span {
    color: ${({ themeColor }) => themeColor};
  }

  .ant-steps-item-finish .ant-steps-item-icon span {
    color: ${({ themeColor }) => themeColor};
  }

  .ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: ${({ themeColor }) => themeColor};
    height: 3px;
  }

  .ant-steps-item-title {
    font-size: ${FONT[13]};
    color: black !important;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      ${MARGIN(0, 0, 0, 6)};
      font-size: ${FONT[9]};
    }
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[14]};
    }

    @media (max-width: 410px) {
      font-size: ${FONT[8]};
    }
  }
`;

const StyledDeleteCol = styled(Col)`
  ${PADDING(22, 10, 20, 18)};

  @media (min-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_2}) {
    ${PADDING(22, 30, 20, 10)};
  }

  svg {
    font-size: 25px;
    margin-top: 2px;
  }
`;

const StyledMinusCircle = styled(MinusCircleOutlined)`
  color: ${COLORS.ORANGE_RED};
`;

const StyledRow = styled(Row)`
  width: 100%;
`;
