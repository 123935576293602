import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import get from 'lodash/get';
import { Table, Tooltip } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { JobApplicationIcon } from '../../../components/svgicons';
import { Modal } from '../../../components/shared-components/Modal';
import { getJobsWithNoApplicationsData } from '../../../store/features/analytics';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../../constants';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { getSerialNumber, serialNoColumn } from '../../../utils/common';

export const JobsApplications = () => {
  const { jobsWithNoApplications } = useSelector(
    (state: any) => state.analytics.masterAdminDashboardAnalytics,
  );
  const { data } = jobsWithNoApplications || {};
  const [dataSource, setDataSource] = useState([]);
  const [modalDataSource, setModalDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobsWithNoApplicationsData(current, pageSize));
  }, [current, pageSize, dispatch]);

  useEffect(() => {
    const renderData = () => {
      if (current === 1) {
        setDataSource(
          data?.jobs?.map((item: any, rowId: any) => ({
            serialNo: getSerialNumber(current, pageSize, rowId),
            jobs: get(item, 'designation', '-'),
            company: get(item, 'companyName', '-'),
            positions: get(item, 'positions', '-'),
            date: moment.unix(get(item, 'updatedAt', '-')).format('DD/MM/YYYY'),
          })),
        );
      }
      setModalDataSource(
        data?.jobs?.map((item: any, rowId: any) => ({
          serialNo: getSerialNumber(current, pageSize, rowId),
          jobs: get(item, 'designation', '-'),
          company: get(item, 'companyName', '-'),
          positions: get(item, 'positions', '-'),
          date: moment.unix(get(item, 'updatedAt', '-')).format('DD/MM/YYYY'),
        })),
      );
    };

    renderData();
  }, [data, current, pageSize]);

  const columns = [
    { ...serialNoColumn },
    {
      title: 'Jobs',
      dataIndex: 'jobs',
      key: 'jobs',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Positions',
      dataIndex: 'positions',
      key: 'positions',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  const handleModalClose = () => {
    setVisible(false);
  };

  return (
    <>
      <StyledSection onClick={() => setVisible(true)}>
        <div>
          <JobApplicationIcon />{' '}
          <h1>Jobs with no applications - {data.totalItems}</h1>
          <Tooltip title="These are the jobs which are active for more then a week and got no job application in that time period.">
            <StyledEyeInvisibleOutlined />
          </Tooltip>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource?.slice(0, 3)}
          pagination={false}
          scroll={{ x: true }}
        />
      </StyledSection>

      <Modal
        title={`Jobs with no applications - ${data.totalItems}`}
        width="80%"
        visible={visible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={modalDataSource}
          pagination={{ pageSize, total: data.totalItems, current }}
          onChange={(e: any) => {
            setCurrent(e.current);
            setPageSize(e.pageSize);
          }}
          scroll={{ x: true }}
        />
      </Modal>
    </>
  );
};

const StyledSection = styled.div`
  cursor: pointer;
  h1 {
    margin-bottom: 20px;
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  & > div:first-child {
    display: flex;
    gap: 7px;
  }
`;

const StyledEyeInvisibleOutlined = styled(EyeInvisibleOutlined)`
  padding-top: 2.5px;
`;
