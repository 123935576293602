import React from 'react';
import { Form, Row, Col } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { broadCastEvent } from '../../service/event';
import Notification from '../../components/Notification/Notification';
import { FormGroup } from '../../components/shared-components/Form/Form';
import ModalButtonContainer from '../../components/profile/ModalButtonContainer';
import BroadcastLinks, { StyledColWithOffset } from './BroadcastLinks';
import { DateAndTypeSection } from '../../components/shared-components/Event/DateAndTypeSection';
import {
  LargeFont,
  SemiLargeHeading,
} from '../../components/shared-components/Texts';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { HiddenMobile } from '../../components/shared-components/DocumentViewerModal';
import { ShowMobile } from '../../components/shared-components/common';
import { MARGIN } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { getThemeByRoleId } from '../../utils/common';
import { getRoleId } from '../../utils/userInfo';
import { Modal } from '../../components/shared-components/Modal';

const EditBroadCastLinkModal = ({
  visible,
  handleClose,
  event,
  handelUpdate,
}) => {
  const [form] = Form.useForm();
  const links = get(event, 'streamUrlsData', []);
  const handleModalCancel = () => {
    form.resetFields();
    handleClose();
  };
  const roleId = getRoleId();

  const handleResetFields = () => {
    form.resetFields();
  };

  const handleFinish = async (data) => {
    await broadCastEvent(event.id, {
      streamUrls: data.broadcastLinks,
    })
      .then(() => {
        Notification('success', 'Publish', 'Broadcast links updated');
        form.resetFields();
      })
      .catch(() => {
        Notification('error', 'Publish', 'Failed to Update Broadcast links');
      });
    handelUpdate();
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      width="80%"
      onCancel={handleModalCancel}
      title="Broadcast Links"
      theme={getThemeByRoleId(roleId)}
    >
      <FormGroup
        form={form}
        name="editBroadcastLinkModal"
        onFinish={handleFinish}
        expand
        initialValues={{
          broadcastLinks: links,
        }}
      >
        <Row>
          <Col xs={24}>
            <SemiLargeHeading fontWeight={FONT_WEIGHTS.BOLD} margin="0">
              Event Info : {event.name}
            </SemiLargeHeading>

            <StyledDateTimeContainer>
              <DateAndTypeSection
                event={event}
                isLeft={false}
                color={COLORS.CHINESE_BLACK}
              />
            </StyledDateTimeContainer>

            <HiddenMobile>
              <StyledHeadingContainer>
                <Col xs={8}>
                  <LargeFont fontWeight={FONT_WEIGHTS.BOLD}>
                    Broadcast Title
                  </LargeFont>
                </Col>
                <StyledColWithOffset xs={8}>
                  <LargeFont fontWeight={FONT_WEIGHTS.BOLD}>
                    Broadcast Link
                  </LargeFont>
                </StyledColWithOffset>
              </StyledHeadingContainer>
            </HiddenMobile>
            <ShowMobile>
              <StyledHeadingContainer>
                <Col>
                  <LargeFont fontWeight={FONT_WEIGHTS.BOLD}>
                    Broadcast Links
                  </LargeFont>
                </Col>
              </StyledHeadingContainer>
            </ShowMobile>
            <BroadcastLinks form={form} links={links} roleId={roleId} />
          </Col>
        </Row>
        <ModalButtonContainer
          handleResetFields={handleResetFields}
          submitText="Publish"
        />
      </FormGroup>
    </Modal>
  );
};

export default EditBroadCastLinkModal;

const StyledHeadingContainer = styled(Row)`
  ${MARGIN(10, 0, 0, 0)}
`;

const StyledDateTimeContainer = styled.div`
  ${MARGIN(10, 0, 20, 0)}
`;
