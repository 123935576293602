import React, { useContext, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import get from 'lodash/get';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Menu from '../../components/Menu/Menu';
import TopBar from '../../components/TopBar/TopBar';
import 'antd/dist/antd.css';
import {
  checkIfUserIsCandidate,
  checkIfUserIsMasterAdmin,
  getRoleId,
  UserInformation,
} from '../../utils/userInfo';
import PIPWindow from '../../components/shared-components/PIP';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { PADDING } from '../../styles/constants/display';
import { userProfileStore } from '../../utils/profile';
import { checkEventBookingInfo } from '../../utils/event';
import getNow from '../../utils/getNow';
import { bookEvent, joinEvent } from '../../service/event';
import { viewEventUrl } from '../../components/Events/Common';
import {
  ATTENDANCE_PRESENT,
  CANDIDATE,
  COMPANY_USER,
  INDIVIDUAL_USER,
  STATUS_DELETE,
} from '../../constants';
import { checkIfJobMatchWithCandidate } from '../../service/candidate';
import { jobApplied } from '../../service/jobOpportunities';
import { CandidateRoutes } from '../../constants/routes';
import Notification from '../../components/Notification/Notification';
import { removeApplyJobData } from '../../store/features/commons';
import ChatBubble from '../../components/ChatBubble';
import SocketContext from '../../context/socket';
import CandidateName from '../../containers/Candidate/profile/CandidateName';
import HeaderNotification from '../../components/shared-components/HeaderNotification';

export default ({ children }) => {
  const roleId = getRoleId();
  const { profileData, isLoading } = userProfileStore();
  const { user } = UserInformation();
  const { applyJobData } = useSelector((st) => st.commons);
  const history = useHistory();
  const dispatch = useDispatch();
  const client = useContext(SocketContext);
  const isCandidate = checkIfUserIsCandidate(roleId);
  const id = isCandidate ? user.userId : user.companyId;
  const type = isCandidate ? INDIVIDUAL_USER : COMPANY_USER;

  useEffect(() => {
    if (id && type) {
      (async () => {
        await client.subscribe(`/user/status/${id}/${type}/`, async (resp) => {
          // answer to if online.
          if (resp.areYouOnline) {
            await client.message({ type: 10 });
          }
        });
      })();
    }
  }, [client, id, type]);

  useEffect(() => {
    if (checkIfUserIsCandidate(roleId) && get(profileData, 'name', null)) {
      (async () => {
        const parsedData = applyJobData;
        if (parsedData && !isEmpty(parsedData)) {
          const { eventId, appliedJob } = parsedData;
          if (eventId && appliedJob) {
            try {
              const res = await checkEventBookingInfo(eventId);
              // check if event is booked.
              if (res.status === 200) {
                const payload = await get(res, 'data.payload', null);

                let isEventBooked = await get(payload, 'isEventBooked', false);
                const isFreeEvent = await get(payload, 'isFreeEvent', false);
                if (!isEventBooked) {
                  if (isFreeEvent) {
                    // book the event
                    const freeEventBody = {
                      eventId,
                      paymentDate: getNow(),
                    };
                    await bookEvent(freeEventBody);
                    isEventBooked = true;
                  } else {
                    history.push(
                      `${viewEventUrl(roleId, eventId)}?openPaymentModal=true`,
                    );
                    return;
                  }
                }
                const attendance = await get(
                  payload,
                  'booking.attendance',
                  STATUS_DELETE,
                );
                if (isEventBooked && attendance === STATUS_DELETE) {
                  // make call to join the event
                  await joinEvent(
                    eventId,
                    { attendance: ATTENDANCE_PRESENT },
                    roleId,
                  );
                }

                if (
                  get(profileData, 'resume', '') &&
                  get(profileData, 'userSkills', []).length
                ) {
                  // we just check if matching with job or not, if not then we just show error message that you can see other jobs in opportunity page.
                  // if matched the apply for job and then redirect to job result page so user can see the application.
                  // also remove the data from local storage if event booking is cancelled or job apply complete or job apply failed.
                  const isMatching = await checkIfJobMatchWithCandidate(
                    eventId,
                    appliedJob,
                  );

                  if (isMatching) {
                    await jobApplied({ id: appliedJob, eventId }).then(() => {
                      dispatch(removeApplyJobData());
                      Notification(
                        'success',
                        'Application',
                        'Job application submitted successfully',
                        null,
                      );
                      history.push(
                        `${CandidateRoutes.jobResults}?event=${eventId}`,
                      );
                    });
                  } else {
                    // show some error message and redirect to
                    dispatch(removeApplyJobData());
                    Notification(
                      'error',
                      'Application',
                      `Your skills don't match the job requirements. Please upgrade your skill to see more jobs`,
                      null,
                    );
                    history.push(viewEventUrl(CANDIDATE, eventId));
                  }
                } else {
                  history.push(CandidateRoutes.viewEvent(eventId));
                }
              }
            } catch (err) {
              if (axios.isAxiosError(err)) {
                const { response } = err;
                Notification('error', response.data.message);
              }
            }
          }
        }
      })();
    }
    // eslint-disable-next-line
  }, [profileData, applyJobData, roleId]);

  return (
    <StyledMainLayout>
      <TopBar />
      <StyledParentWrapper>
        <Menu />
        <StyledContainer id="main-container">
          {isCandidate ? <HeaderNotification /> : null}
          <StyledMainContainer
            className={`${
              roleId && checkIfUserIsMasterAdmin(roleId) ? 'admin' : ''
            }`}
          >
            {children}
          </StyledMainContainer>
        </StyledContainer>
      </StyledParentWrapper>

      <PIPWindow />
      {isCandidate ? <ChatBubble /> : null}
      {isCandidate && !isLoading && !get(profileData, 'name', null) ? (
        <CandidateName visible />
      ) : null}
      <StyledCookieConsentContainer>
        <CookieConsent
          location="bottom"
          buttonText="Accept!"
          cookieName="user_cookie"
        >
          This website uses cookies to enhance your experience. Please Accept ,
          So you have the best experience.
        </CookieConsent>
      </StyledCookieConsentContainer>
    </StyledMainLayout>
  );
};

const StyledCookieConsentContainer = styled.div`
  .CookieConsent {
    background-color: ${COLORS.BLACK};
    color: ${COLORS.WHITE};
    opacity: 0.8;
    z-index: 3000 !important;

    #rcc-confirm-button {
      font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
      background: ${COLORS.WHITE} !important;
      color: ${COLORS.BLACK} !important;
    }
  }
`;

const StyledMainLayout = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const StyledParentWrapper = styled.div`
  display: flex;
  clear: both;
  margin-bottom: 2rem;
  height: calc(100vh - 59px);

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    height: calc(100vh - 80px);
  }
`;

const StyledContainer = styled.div`
  flex: 1 1;
  overflow: hidden;
  overflow-y: auto !important;
`;

const StyledMainContainer = styled.div`
  flex: 1 1;
  background-color: ${COLORS.LOTION};
  overflow: hidden;
  ${PADDING(0, 20)}
  padding-bottom: 1rem;
  overflow-y: auto !important;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${PADDING(0, 25)}
    padding-bottom: 2rem;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${PADDING(0, 15)}
  }
`;
