import React, { useEffect } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { EventsStore } from '../../utils/event';
import Notification from '../Notification/Notification';
import { Modal } from '../shared-components/Modal';
import { getThemeByRoleId } from '../../utils/common';
import { checkIfUserIsCandidate, getRoleId } from '../../utils/userInfo';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { PrimaryButton, SecondaryButton } from '../shared-components/Button';
import { COLORS } from '../../styles/constants/colors';
import config from '../../config';
import { CandidateRoutes, RecruiterRoutes } from '../../constants/routes';
import { getEventByIdDataWithoutReload } from '../../store/features/events';
import { isMobile } from '../../helpers/display';
import { removePromoUse } from '../../service/event';

const CheckoutForm = ({
  showPayment,
  handleClosePaymentModal,
  purchaseOverviewModalClose,
}) => {
  const { event } = EventsStore();
  const roleId = getRoleId();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${config.appURL}${checkIfUserIsCandidate(roleId)
          ? CandidateRoutes.paymentStatus
          : RecruiterRoutes.paymentStatus
          }?eventId=${event.id}`,
      },
    });

    if (error) {
      Notification('error', 'Booking failed', error.message);
      setTimeout(() => {
        dispatch(getEventByIdDataWithoutReload(event.id));
      }, 1200);
    } else {
      Notification('info', 'Event', 'Event registration in process');
    }
    handleClosePaymentModal();
    purchaseOverviewModalClose();
  };

  const handleModelCloeWithoutPayment = async () => {
    await removePromoUse(event.id);
    dispatch(getEventByIdDataWithoutReload(event.id));

    handleClosePaymentModal();
  };

  useEffect(() => {
    return async () => {
      await removePromoUse(event.id);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      visible={showPayment}
      onCancel={handleModelCloeWithoutPayment}
      closable={false}
      title="Payment"
      theme={getThemeByRoleId(roleId)}
      footer={null}
      zIndex={isMobile() && 3000}
    >
      <PaymentElement />
      <StyledButtonsContainer>
        <SecondaryButton
          onClick={handleModelCloeWithoutPayment}
          theme={getThemeByRoleId(roleId)}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={handleSubmit} theme={getThemeByRoleId(roleId)}>
          Pay
        </PrimaryButton>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default CheckoutForm;

const StyledButtonsContainer = styled.div`
  width: 100%;
  ${DISPLAY_FLEX('row', 'space-between', 'center')};
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${COLORS.VLIGHT_GRAY};
`;
