import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const QuillEditor = ({
  id,
  placeholder,
  value,
  maxLength,
  handleChange,
  className,
}) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      ['clean'],
    ],
  };

  const quillRef = useRef();

  const checkCharacterCount = (event) => {
    const editor = quillRef.current.getEditor();
    if (editor.getLength() > maxLength && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  return (
    <div id={id}>
      <ReactQuill
        theme="snow"
        ref={quillRef}
        modules={modules}
        placeholder={placeholder}
        value={value}
        onKeyDown={checkCharacterCount}
        onChange={handleChange}
        className={className}
      />
    </div>
  );
};

export default QuillEditor;
