import { useSelector } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import Notification from '../components/Notification/Notification';
import { NOTIFICATION_CLICK_ACTION } from '../constants/notificationsType';
import { checkIfUserIsCandidate, checkIfUserIsRecruiter } from './userInfo';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../constants/routes';
import { onBoarding } from './profile';
import { viewEventUrl } from '../components/Events/Common';
import { canNavigateAccrossRoutes } from './recruiter';

export const NotificationStore = () => {
  const { isLoading, error, notifications, pagination } = useSelector(
    (state) => state.notifications,
  );
  return { isLoading, error, notifications, pagination };
};

export const ChatNotificationStore = () => {
  const {
    isChatLoading,
    chatError,
    chatNotifications,
    chatPagination,
  } = useSelector((state) => state.notifications);
  return { isChatLoading, chatError, chatNotifications, chatPagination };
};

export const NotificationUpdatesStore = () => {
  const { isLoading, error, updates } = useSelector(
    (state) => state.notifications,
  );
  return { isLoading, error, updates };
};

export const sortNotifications = (notifications) => {
  return notifications.sort((notification1, notification2) =>
    notification1.dateTime > notification2.dateTime ? -1 : 1,
  );
};

export const getPostingTime = (dateTime) => {
  const diff = moment().diff(moment.unix(dateTime));

  const { days, hours, minutes, months, year, seconds } = moment.duration(
    diff,
  )._data;

  let result = '';

  if (year) {
    result = year > 1 ? `${year} Years` : `${year} Year`;
  }

  if (months) {
    result = months > 1 ? `${months} Months` : `${months} Month`;
  }
  if (days) {
    result = days > 1 ? `${days} Days` : `${days} Day`;
  }
  if (hours && (!days || days <= 0)) {
    result = hours > 1 ? `${result}  ${hours} Hrs` : `${result}  ${hours} Hr`;
  }
  if (minutes && (!days || days <= 0) && (!hours || hours <= 0)) {
    result =
      minutes > 1 ? `${result}  ${minutes} Mins` : `${result}  ${minutes} Min`;
  }

  if (seconds && result === '') {
    result =
      seconds > 1
        ? `${result}  ${seconds} Seconds`
        : `${result}  ${minutes} Seconds`;
  }

  return result;
};

export const selectEventFirst = (eventSelected) => {
  if (!eventSelected) {
    Notification('error', 'Please Select Event First');
  }
};

/**
 * getActionLink function takes following arguments and returns link or null based on input
 * @param {object} notificationInfo
 * @param {number} roleId
 * @returns {string | null}
 */
export const getActionLink = (notificationInfo, profileData, roleId) => {
  const clickAction =
    notificationInfo?.meta?.clickAction || notificationInfo?.action;

  if (clickAction && roleId) {
    const eventId = notificationInfo?.meta?.eventId;
    const jobId = notificationInfo?.meta?.jobId;
    const jobApplicationId = notificationInfo?.meta?.jobApplicationId;

    if (checkIfUserIsCandidate(roleId)) {
      switch (clickAction) {
        case NOTIFICATION_CLICK_ACTION.signup:
          return CandidateRoutes.profile;
        case NOTIFICATION_CLICK_ACTION.newOpportunity:
          return viewEventUrl(roleId, eventId);
        case NOTIFICATION_CLICK_ACTION.viewEvent:
        case NOTIFICATION_CLICK_ACTION.eventCanceled:
          return `${CandidateRoutes.event}view/${eventId}`;
        case NOTIFICATION_CLICK_ACTION.jobApplied:
        case NOTIFICATION_CLICK_ACTION.interviewScheduled:
        case NOTIFICATION_CLICK_ACTION.applicationStatusUpdate:
          return `${CandidateRoutes.jobResults}?event=${eventId}`;
        default:
          return null;
      }
    } else if (checkIfUserIsRecruiter(roleId)) {
      switch (clickAction) {
        case NOTIFICATION_CLICK_ACTION.signup:
          return canNavigateAccrossRoutes() ? RecruiterRoutes.profile : null;
        case NOTIFICATION_CLICK_ACTION.applicationReceived:
          return `/recruiter/resume/${jobId}/${jobApplicationId}/${eventId}`;
        case NOTIFICATION_CLICK_ACTION.interviewScheduled:
          return `${RecruiterRoutes.calender}`;
        case NOTIFICATION_CLICK_ACTION.viewEvent:
        case NOTIFICATION_CLICK_ACTION.eventCanceled:
          return `${RecruiterRoutes.event}view/${eventId}`;
        case NOTIFICATION_CLICK_ACTION.crewInvite:
          return `${RecruiterRoutes.profile}#pendingInvitations`;
        case NOTIFICATION_CLICK_ACTION.chat:
          return notificationInfo.isGroup
            ? `${RecruiterRoutes.groupChat}?event=${get(
                notificationInfo,
                'chatRoom._id',
              )}`
            : `${RecruiterRoutes.chat}`;
        default:
          return null;
      }
    }

    switch (clickAction) {
      case NOTIFICATION_CLICK_ACTION.signup:
        return onBoarding(profileData)
          ? OrganizerRoutes.profile
          : OrganizerRoutes.profileOnboard;
      case NOTIFICATION_CLICK_ACTION.viewEvent:
      case NOTIFICATION_CLICK_ACTION.eventCanceled:
        return `${OrganizerRoutes.events}view/${eventId}`;
      case NOTIFICATION_CLICK_ACTION.crewInvite:
        return `${OrganizerRoutes.profile}#pendingInvitations`;
      case NOTIFICATION_CLICK_ACTION.chat:
        return notificationInfo.isGroup
          ? `${OrganizerRoutes.groupChat}?event=${get(
              notificationInfo,
              'chatRoom._id',
            )}`
          : `${OrganizerRoutes.chat}`;
      default:
        return null;
    }
  }

  return null;
};
