import React, { useState, useEffect } from "react";
import config from '../../../config';
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from "@giphy/js-types";
import { Gif } from "@giphy/react-components";
import { MESSAGE_CONTENT_TYPE } from '../../../constants/chat'

export const GIF: React.FC<any> = ({ message, contentType }) => {
    const gf = new GiphyFetch(config.giphyKey) //GIPHY SDK key
    const [singleGif, setSingleGif] = useState<IGif | null>(null);

    useEffect(() => {
        async function fetchGif() {
            if (contentType && contentType === MESSAGE_CONTENT_TYPE.GIF) {
                const { data } = await gf.gif(message);
                setSingleGif(data);
            }
        }
        fetchGif();
    }, [contentType, message, gf])
    return (
        <>
            {contentType && contentType === MESSAGE_CONTENT_TYPE.GIF && singleGif ? (
                <Gif gif={singleGif} width={150} hideAttribution={true} noLink={true} />) : (null)
            }
        </>
    )
}   
