import React from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { getTheme } from '../../utils/common';
import { COLORS } from '../../styles/constants/colors';
import {
  TabSwitchProps,
  TabsProps,
} from '../../interfaces/components/Tabs/index';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';

export const TabSwitch: React.FC<TabSwitchProps> = ({
  theme,
  items,
  onChange,
  type,
  defaultActiveKey,
  activeKey,
  props,
  fullWidthInkbar = false,
}) => {
  return (
    <StyledTabs
      theme={theme}
      role={type}
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
      activeKey={activeKey}
      fullWidthInkbar={fullWidthInkbar}
      {...props}
    >
      {items.map((item) => (
        <Tabs.TabPane
          tab={
            <span>
              {item.icon}
              {item.name}
            </span>
          }
          key={item.key}
          disabled={item.disabled}
        >
          {item.component}
        </Tabs.TabPane>
      ))}
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)<TabsProps>`
  width: 100%;
  .ant-tabs-nav {
    ${MARGIN(0, 0, 16, 0)}

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      ${MARGIN(0, 0, 16, 0)}
    }
  }
  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-ink-bar {
    ${(props) =>
      props.fullWidthInkbar === true ? null : 'width: 60px !important;'}
    height: 6px !important;
    border-radius: 5px;
    background-color: ${(props) => getTheme(props.theme)};
  }

  .ant-tabs-tab {
    color: ${COLORS.ARSENIC};

    &.ant-tabs-tab-active {
      div {
        color: ${(props) => getTheme(props.theme)};
      }

      svg {
        fill: ${(props) => getTheme(props.theme)};
      }
    }

    svg {
      ${MARGIN(0, 7, -4, 0)}
      width: 20px;
      height: 20px;
      fill: ${COLORS.ARGENT};
    }
  }

  .ant-tabs-nav-operations {
    display: none;
  }
`;
