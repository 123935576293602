import organizerApis from './organizerApi';
import masterAdminApis from './masterAdminApi';
import recruiterApi from './recruiterApi';

const rtkApis = [organizerApis, masterAdminApis, recruiterApi];

/** Helper function for clearing all the query cache when logout
 * @params takes the dispatch function as a parameter
 */
export function clearAllRTKQueryCaches(dispatch) {
  return rtkApis.forEach((api) => dispatch(api.util.resetApiState()));
}
