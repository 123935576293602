import moment from 'moment';

const unixToDate = (dateTime, format) => {
  return dateTime ? moment.unix(dateTime).format(format || 'LL') : '-';
};

export default unixToDate;

export const ongoingEventDateTimeFormat = (dateTime) => {
  const time = moment.unix(dateTime);
  return time.format('lll');
};

export const convertToUnix = (dateTime) => {
  return moment(dateTime).unix();
};

export const currentTime = () => {
  return moment().unix();
};

const getHourTime = (time) => {
  return time.split(':')[0];
};

export const currentDate = (format = false, type = 'lll') => {
  if (format) {
    return moment().format(type);
  }
  return moment();
};

export const convert24HourTimeTo12HourTime = (time) => {
  if (getHourTime(time) >= 12) {
    time = `${getHourTime(time) - 12}:${time.split(':')[1]} PM`;
  } else {
    time += ' AM';
  }
  return time;
};

export const addAMorPMAfterTime = (time) => {
  const hour = getHourTime(time);
  return hour >= 12 ? `${time} PM` : `${time} AM`;
};
export const formatDisplayTime = (time) => {
  if (!time) return null;
  if (getHourTime(time) > 12) {
    time = `${getHourTime(time) - 12}:${time.split(':')[1]} `;
  }
  return time;
};

export const convertFromUnixToNormalTime = (timestamp) => {
  const time = moment.unix(timestamp).format('LT');
  let val = time;
  if (time.split(':')[1].split(' ')[1] === 'PM') {
    let temp = Number(getHourTime(time));
    val = temp === 12 ? temp : temp + 12;
    val += ':' + time.split(':')[1];
  }
  return val;
};
/**
 * Converts unix time
 * @param {number} dateTime
 */
export const convertUnixTime = (dateTime) => {
  return moment.unix(dateTime);
};

/**
 * Returns formated timeZone
 * @param {array} timeZones ( time zone list )
 * @param {string} timeZone ( current time zone )
 * @return {string} string or matched string
 */
export const getFormatedTimeZone = async (timeZones, timeZone) => {
  const result = await timeZones.find(
    (timeZoneItem) => timeZoneItem.name === timeZone,
  );
  if (!result) {
    return '';
  }
  return result.label.match(/\(([^)]+)\)/)[0];
};

/**
 * Takes following arguments and returns object with start & end date time
 * @param {object} item {dateTime, endDateTime}
 * @return {object} result {start, end}
 */
export const getEventDateTime = (item) => {
  if (!item || !item.dateTime || !item.endDateTime) {
    throw Error(
      'Please pass object containing dateTime & endDateTime as an argument',
    );
  }

  const result = {};

  const startDate = unixToDate(item.dateTime, 'll');
  const endDate = unixToDate(item.endDateTime, 'll');

  result.start = `${startDate} ${formatDisplayTime(
    convertFromUnixToNormalTime(item.dateTime),
  )}`;

  if (startDate === endDate) {
    result.end = formatDisplayTime(
      convertFromUnixToNormalTime(item.endDateTime),
    );
  } else {
    result.end = `${endDate} ${formatDisplayTime(
      convertFromUnixToNormalTime(item.endDateTime),
    )}`;
  }

  return result;
};
