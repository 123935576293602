import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import get from 'lodash/get';
import Notification from '../../components/Notification/Notification';
import { APIs, RECRUITER } from '../../constants';
import {
  adminLogin,
  userLogin,
  socialLogin,
  loginWithOTP,
  signupWithOtp,
  socialSignup,
  magicLinksLogin,
} from '../../service/auth';
import {
  checkIfUserIsCandidate,
  checkIfUserIsRecruiter,
} from '../../utils/userInfo';
import { removeUserAnonymousName } from './chat';
import { profileSlice } from './profile';

const onStart = (state) => {
  state.isLoading = true;
};

const onLogin = (state, action) => {
  state.isLoading = false;
  state.error = null;
  state.isAuthenticated = true;
  state.user = action.payload;
};

const onLogout = (state) => {
  state.isLoading = false;
  state.error = null;
  state.isAuthenticated = false;
  state.user = {};
};

const onError = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
  message.error('Invalid Credentials');
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    user: {},
    error: null,
  },
  reducers: { onStart, onLogin, onLogout, onError },
});

const adminLoginUser = (username, password, mockResponse) => async (
  dispatch,
) => {
  try {
    dispatch(authSlice.actions.onStart());
    const userData = await adminLogin(username, password, mockResponse);
    const { name, email, roleId, accessToken, refreshToken } = userData.data;
    sessionStorage.setItem(
      'userInfo',
      JSON.stringify({ name, email, roleId, accessToken, refreshToken }),
    );
    dispatch(authSlice.actions.onLogin(userData.data));
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

export const saveLoginDataToStorage = (userData, rememberMe = false) => {
  const { email, roleId, accessToken, refreshToken } = userData;

  const name = get(userData, 'name', '');
  const companyId = get(userData, 'companyId', '');
  const userId = get(userData, 'id', '');

  const loginData = {
    name: name || '',
    email,
    roleId,
    companyId,
    accessToken,
    refreshToken,
    userId,
  };

  rememberMe
    ? localStorage.setItem('userInfo', JSON.stringify(loginData))
    : sessionStorage.setItem('userInfo', JSON.stringify(loginData));
};

export const emailError = (userData) => {
  Notification('error', userData.error, userData.message);
};

const loginUser = (
  username,
  password,
  rememberMe,
  mockResponse,
  token,
) => async (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    let userData;
    if (token) {
      userData = await userLogin(username, password, mockResponse, token);
    } else {
      userData = await userLogin(username, password, mockResponse);
    }
    if (userData.statusCode === 400) {
      emailError(userData);
    } else {
      saveLoginDataToStorage(userData.data, rememberMe);
      dispatch(authSlice.actions.onLogin(userData.data));
    }
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

const setCurrentUser = () => (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    localStorage.userInfo
      ? dispatch(authSlice.actions.onLogin(JSON.parse(localStorage.userInfo)))
      : dispatch(
          authSlice.actions.onLogin(JSON.parse(sessionStorage.userInfo)),
        );
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

const logoutUser = (roleId = RECRUITER) => async (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    if (checkIfUserIsCandidate(roleId)) {
      dispatch(removeUserAnonymousName());
    }
    sessionStorage.clear();
    Object.keys(localStorage).forEach((key) => {
      return !key.includes('tour' && 'headerMessage')
        ? localStorage.removeItem(key)
        : '';
    });
    dispatch(authSlice.actions.onLogout());
    dispatch(profileSlice.actions.onLogout());
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

const socialLoginUser = (body, rememberMe) => async (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    const userData = await socialLogin(body);
    const res = get(userData, 'data.payload', null);
    if (res) {
      return res;
    }
    saveLoginDataToStorage(userData.data, rememberMe);
    dispatch(authSlice.actions.onLogin(userData.data));
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

export const loginUserWithOTP = (
  emailId,
  otp,
  role,
  rememberMe,
  token,
) => async (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    let userData;
    if (token) {
      userData = await loginWithOTP(emailId, role, otp, token);
    } else {
      userData = await loginWithOTP(emailId, role, otp);
    }
    saveLoginDataToStorage(userData, rememberMe);
    dispatch(authSlice.actions.onLogin(userData));
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

export const signupWithOTPData = (body, role) => async (dispatch) => {
  try {
    let url;
    switch (true) {
      case checkIfUserIsCandidate(role):
        url = APIs.candidateOtpSignup;
        break;
      case checkIfUserIsRecruiter(role):
        url = APIs.recruiterOtpSignup;
        break;
      default:
        url = APIs.organizerOtpSignup;
        break;
    }
    dispatch(authSlice.actions.onStart());
    const userData = await signupWithOtp(body, url);
    saveLoginDataToStorage(userData, true);
    dispatch(authSlice.actions.onLogin(userData));
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

export const socialSignupUser = (body, rememberMe) => async (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    const userData = await socialSignup(body);
    saveLoginDataToStorage(userData.payload, rememberMe);
    dispatch(authSlice.actions.onLogin(userData.payload));
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

export const magicLinksLoginData = (eventId, token) => async (dispatch) => {
  try {
    dispatch(authSlice.actions.onStart());
    const userData = await magicLinksLogin(eventId, token);
    saveLoginDataToStorage(userData, true);
    dispatch(authSlice.actions.onLogin(userData));
  } catch (err) {
    dispatch(authSlice.actions.onError(err.toString()));
  }
};

export default authSlice.reducer;

export {
  adminLoginUser,
  loginUser,
  setCurrentUser,
  logoutUser,
  socialLoginUser,
};
