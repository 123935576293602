import React from 'react';
import { Carousel as AntCarousel } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { MARGIN } from '../../styles/constants/display';

const Carousel = (props) => {
  return <StyledCarousel {...props}>{get(props, 'children')}</StyledCarousel>;
};

export default Carousel;

export const StyledCarousel = styled(AntCarousel)`
  &.slick-slider .slick-dots {
    position: absolute;
    left: 77%;
    transform: translateX(-50%);
    ${MARGIN(0)}
    z-index: 15;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;

    @media (max-width: ${BREAKPOINTS.TABLET_MAX}) {
      bottom: unset;
      top: 210px;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
      ${MARGIN(0)}
    }

    li,
    li.slick-active {
      width: 13px;
      height: 13px;

      button {
        width: 13px;
        height: 13px;
        border-radius: 100%;
        border: 2px solid black;
      }
    }

    li {
      button {
        opacity: 0.5;
      }
    }
    li.slick-active {
      button {
        opacity: 1;
      }
    }
  }
`;
