import React from 'react';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';
import { UploadProfilePicComponent } from '../Common/UploadComponent';
import BasicInformation from '../profile/BasicInformation';
import { getRoleId } from '../../utils/userInfo';
import { STATUS_ACTIVE } from '../../constants';
import { ActiveText } from './common';
import { StyledLargeFont } from '../shared-components/Texts';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { getUserColorWithRoleId } from '../../styles/utils';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { getSize } from '../../utils/size';

export default function RepresentativeOrBasicInformation(props) {
  const { data, profilePic, setProfilePic, isCrewProfile } = props;

  const roleId = getRoleId();

  return (
    <>
      <StyledRepresentativeHeading color={getUserColorWithRoleId(roleId)}>
        {!isCrewProfile ? 'Representative Information' : 'Basic Information'}
      </StyledRepresentativeHeading>
      <StyledProfileDataContainer>
        <Row gutter={[16, 32]}>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 18, order: 1 }}>
            <BasicInformation profileData={data} isCrewMember />
          </Col>

          <UploadProfilePicComponent
            avatarSize={getSize(150, 140, 120)}
            option={false}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
          />
        </Row>
        <StyledStatus>
          <h4>Status</h4>
          <div>
            {get(data, 'status') === STATUS_ACTIVE ? (
              <ActiveText>Active</ActiveText>
            ) : null}
          </div>
        </StyledStatus>
      </StyledProfileDataContainer>
    </>
  );
}

const StyledRepresentativeHeading = styled(StyledLargeFont)`
  color: ${(props) => props.color};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${MARGIN(15, 0)}
`;

const StyledProfileDataContainer = styled.div`
  background-color: ${COLORS.LOTION};
  ${PADDING(20)}
`;

const StyledStatus = styled.div`
  margin-top: 40px;

  @media (min-width: ${BREAKPOINTS.TABLET_MAX}) {
    margin-top: -50px;
  }

  h4 {
    color: ${COLORS.GRAY};
  }

  div {
    display: inline-block;
  }
`;
