/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { getRoleId } from '../../../../utils/userInfo';
import { getUserColorWithRoleId } from '../../../../styles/utils';
import { MediumHeading } from '../../../../components/shared-components/Texts';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { DISPLAY_FLEX, MARGIN } from '../../../../styles/constants/display';
import { COLORS } from '../../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import {
  Dropdown,
  NumberBox,
} from '../../../../components/shared-components/Form/Form';
import { DEFAULT_CURRENCY } from '../../../../constants/currency';

const PricingAndPromoContainer = ({
  event,
  isTabActive,
  isActive,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const roleId = getRoleId();
  const { currencyData } = useSelector((st) => st.commons);

  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (event && (event.recruiterTicketPrice || event.userTicketPrice)) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [event]);

  const handleStatusChange = () => {
    setIsChecked(!isChecked);
    isTabActive(!isChecked);
  };

  // Currency
  const handleCurrencyChange = (val) => {
    if (val !== DEFAULT_CURRENCY) {
      setSelectedCurrency(val);
    } else {
      setSelectedCurrency(null);
    }
  };

  // currency validator
  const validator = (rule, value = 0) => {
    const toCurrency = selectedCurrency || 'INR';
    const firstCurrencyConverting = currencyData.find(
      (c) => c.value === DEFAULT_CURRENCY,
    );
    // Convert from usd to new currency
    const secondCurrencyConverting = currencyData.find(
      (c) => c.value === toCurrency,
    );

    const toAmount = selectedCurrency
      ? (parseFloat(0.5) / parseFloat(firstCurrencyConverting.usdRate)) *
        parseFloat(secondCurrencyConverting.usdRate)
      : 0.5;

    if (value === 0 || value >= toAmount) {
      return Promise.resolve();
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('');
  };

  return (
    <>
      <StyledPricingRow>
        <Col>
          <label htmlFor="event_terms_and_policy">
            <StyledInfoHeader id="terms_and_policy_label">
              Pricing
            </StyledInfoHeader>
          </label>
        </Col>
        <Col>
          <StyledRow>
            <StyledSwitch
              color={getUserColorWithRoleId(roleId)}
              checked={isChecked}
              onChange={handleStatusChange}
            />
            <MediumHeading
              fontWeight={FONT_WEIGHTS.SEMI_BOLD}
              margin="0 10px"
              color={getUserColorWithRoleId(roleId)}
            >
              Free Event
            </MediumHeading>
          </StyledRow>
        </Col>
      </StyledPricingRow>
      <Row>
        <Col md={24}>
          <Row gutter={[15, 5]}>
            <Col xs={15} md={12}>
              <Dropdown
                name="currency"
                placeholder="Currency"
                showSearch
                showArrow
                disabled={isActive || isChecked}
                options={currencyData}
                handleChange={handleCurrencyChange}
              />
            </Col>
            <Col xs={24} md={12}>
              <NumberBox
                rule="priceNumber"
                name="userTicketPrice"
                placeholder="Ticket price"
                required
                defaultValue={0}
                disabled={isActive || isChecked}
                validator={validator}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PricingAndPromoContainer;

const StyledPricingRow = styled(Row)`
  ${DISPLAY_FLEX('row', 'space-between', 'center')}
`;

const StyledInfoHeader = styled.span`
  display: block;
  ${MARGIN(16, 0, 8, 0)};
  color: ${COLORS.CHINESE_BLACK};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  font-size: ${FONT[14]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${(props) => props.color};
  }
`;

const StyledRow = styled(Row)``;
