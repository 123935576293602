import React, { useEffect, useContext, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Skeleton } from 'antd';
import ConfigContext from '../store/configContext';
import Dashboard from './Organizer/Dashboard';
import AttendeesManagement from './Organizer/Attendees/AttendeesManagement';
import ChatManagement from './Organizer/ChatManagement';
import GroupChatManagement from './Organizer/GroupChatManagement';
import BroadcastEvent from './Organizer/BroadcastEvent';
import Profile from './Organizer/Profile';
import ProfileOnboard from './Organizer/ProfileOnboard';
import { userProfileStore, onBoarding } from '../utils/profile';
import CreateEvent from './Organizer/Events/CreateEvent';
import ViewEvent from '../components/Events/ViewEvent/ViewEvent';
import DashboardIcon from '../assets/icons-and-sets/dashboard.svg';
import EventsIcon from '../assets/icons-and-sets/events.svg';
import AttendeesIcon from '../assets/icons-and-sets/attendees.svg';
import ChatIcon from '../assets/icons-and-sets/chat.svg';
import BroadcastIcon from '../assets/icons-and-sets/broadcast.svg';
import CalendarIcon from '../assets/icons-and-sets/calendar.svg';
// import TeamIcon from '../assets/icons-and-sets/Crew.svg';
import OrganizerCalendar from './Organizer/OrganizerCalendar';
import CrewMemberProfile from '../components/CrewManagement/CrewMemberProfile';
import { OrganizerRoutes } from '../constants/routes';
import { Notifications } from '../components/shared-components/Notifications/Notifications';
import BulkEventInvite from '../components/BulkEventInvite/BulkEventInvite';
import { Support } from '../components/shared-components/Support';
import { CreateOrChangePassword } from '../components/shared-components/CreateOrChangePassword';
import { checkIfUserIsOrganizer, getRoleId } from '../utils/userInfo';
import { redirectToProperUserRole } from '../utils/common';
import { safeLoad } from '../utils/loader';
import { RETRY_ATTEMPTS, RETRY_INTERVALS } from '../constants';
// import CommunityAccessRequest from './Organizer/CommunityAccessRequest';

const Events = React.lazy(() => {
  return safeLoad(
    () => import('./Organizer/Events/Events'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});
/*
const RefundManagement = React.lazy(() =>
  import('./Organizer/RefundManagement'),
);
*/
const EditEvent = React.lazy(() => {
  return safeLoad(
    () => import('./Organizer/Events/EditEvent'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const PrivatePageNotFound = React.lazy(() => {
  return safeLoad(
    () => import('./PrivatePageNotFound'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const HelpDoc = React.lazy(() => {
  return safeLoad(
    () => import('../components/Common/HelpDoc'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
});

const Organizer = () => {
  const { navigationList, setNavigationList } = useContext(ConfigContext);
  const { profileData } = userProfileStore();
  const roleId = getRoleId();

  if (roleId && !checkIfUserIsOrganizer(roleId)) {
    redirectToProperUserRole(roleId);
  }

  useEffect(() => {
    setNavigationList([
      {
        Icon: DashboardIcon,
        title: 'Dashboard',
        route: OrganizerRoutes.organizer,
        exact: true,
        Component: onBoarding(profileData) ? Dashboard : ProfileOnboard,
      },
      {
        Icon: EventsIcon,
        title: 'Events',
        route: OrganizerRoutes.events,
        exact: true,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? Events : null,
      },
      {
        Icon: AttendeesIcon,
        title: 'Attendees Management',
        route: `${OrganizerRoutes.attendees}:tab`,
        selectionPath: OrganizerRoutes.attendees,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? AttendeesManagement : null,
      },
      /*
      {
        Icon: EarningsIcon,
        title: 'Earnings',
        route: OrganizerRoutes.earnings,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? Earnings : null,
      },
      */
      {
        Icon: ChatIcon,
        title: 'Chat',
        route: OrganizerRoutes.chat,
        exact: true,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? ChatManagement : null,
      },
      {
        title: 'Group Chat Management',
        route: OrganizerRoutes.groupChat,
        selectionPath: OrganizerRoutes.chat,
        populate: false,
        Component: onBoarding(profileData) ? GroupChatManagement : null,
      },
      {
        Icon: BroadcastIcon,
        title: 'Broadcast Event',
        route: OrganizerRoutes.broadcast,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? BroadcastEvent : null,
      },
      {
        Icon: CalendarIcon,
        title: 'My Calendar',
        route: OrganizerRoutes.calender,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? OrganizerCalendar : null,
      },
      /* {
        Icon: TeamIcon,
        title: 'Community Access',
        route: OrganizerRoutes.communityAccess,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? CommunityAccessRequest : null,
      },
      */
      {
        title: 'My Profile',
        route: OrganizerRoutes.profile,
        populate: false,
        exact: true,
        Component: onBoarding(profileData) ? Profile : null,
      },
      /* {
        Icon: RefundIcon,
        title: 'Refund Management',
        route: OrganizerRoutes.refund,
        disabled: !onBoarding(profileData),
        Component: onBoarding(profileData) ? RefundManagement : null,
      },
      */
      {
        title: 'Profile Onboard',
        route: OrganizerRoutes.profileOnboard,
        populate: false,
        Component: ProfileOnboard,
      },
      {
        title: 'Create Event',
        route: OrganizerRoutes.createEvent,
        selectionPath: OrganizerRoutes.events,
        populate: false,
        Component: onBoarding(profileData) ? CreateEvent : null,
      },
      {
        title: 'View Event',
        route: '/organizer/events/view/:id',
        selectionPath: OrganizerRoutes.events,
        populate: false,
        Component: onBoarding(profileData) ? ViewEvent : null,
      },
      {
        title: 'Edit Event',
        route: '/organizer/events/edit/:id',
        selectionPath: OrganizerRoutes.events,
        populate: false,
        Component: onBoarding(profileData) ? EditEvent : null,
      },
      {
        title: 'Crew Member Profile',
        route: '/organizer/crew-member-profile/:id',
        populate: false,
        Component: onBoarding(profileData) ? CrewMemberProfile : null,
      },
      {
        title: 'Notifications Page',
        route: OrganizerRoutes.notifications,
        populate: false,
        Component: Notifications,
      },
      {
        title: 'Support Page',
        route: OrganizerRoutes.support,
        populate: false,
        Component: Support,
      },
      {
        title: 'Help Doc',
        route: OrganizerRoutes.helpDoc,
        populate: false,
        Component: HelpDoc,
      },
      {
        title: 'Bulk Invite',
        route: OrganizerRoutes.bulkInvite,
        populate: false,
        Component: onBoarding(profileData) ? BulkEventInvite : null,
      },
      {
        title: 'Modify Password',
        route: OrganizerRoutes.changePassword,
        populate: false,
        Component: CreateOrChangePassword,
      },
      {
        title: 'Page Not Found',
        route: '*',
        populate: false,
        Component: PrivatePageNotFound,
      },
    ]);
  }, [setNavigationList, profileData]);

  return (
    <div>
      <Suspense fallback={<Skeleton />}>
        <Switch>
          {navigationList.map((navItem, idx) => {
            const { route, Component, exact = false } = navItem;
            return (
              <Route
                key={idx.toString()}
                exact={exact}
                path={route}
                component={Component}
              />
            );
          })}
        </Switch>
      </Suspense>
    </div>
  );
};

export default Organizer;
