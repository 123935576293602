/* eslint-disable jsx-a11y/anchor-is-valid */
import { PlusOutlined } from '@ant-design/icons';
import { Drawer, Col } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/svg/Recorem-Beta.svg';
import { MainWebsiteRoutes } from '../../constants/routes';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { NewTabLinks, socailLinks, StyledLI } from '../Footer/common';

const ResponsiveMenu: React.FC<any> = ({ onClose, visible }) => {
  return (
    <>
      <StyledDrawer
        title={null}
        placement="left"
        width="90%"
        closable={false}
        onClose={onClose}
        visible={visible}
        key="left"
      >
        <StyledWrapper>
          <StyledLogoLink href={MainWebsiteRoutes.home}>
            <figure>
              <StyledImageContainer>
                <StyledRecoremLogo src={Logo} alt="logo" />
              </StyledImageContainer>
            </figure>
          </StyledLogoLink>

          <StyledNavButton>
            <a href="/login">Login</a>
          </StyledNavButton>

          <StyledDivider />
          <StyledCol>
            <StyledMenuItemLink>
              <a href={MainWebsiteRoutes.events}>Events</a>
            </StyledMenuItemLink>
            <StyledMenuItemLink>
              <a href={MainWebsiteRoutes.howItWorks}>How It Works</a>
            </StyledMenuItemLink>
            <StyledMenuItemLink>
              <a href={MainWebsiteRoutes.organizer}>Partner with us</a>
            </StyledMenuItemLink>
            <StyledMenuItemLink>
              <a href={MainWebsiteRoutes.recruiter}>Post Jobs</a>
            </StyledMenuItemLink>
          </StyledCol>
        </StyledWrapper>
        <div>
          <StyledDivider />
          <StyledColumnContainer>
            <StyleLabel>Follow us</StyleLabel>
            <StyledUL>
              {socailLinks.map((social, idx) => (
                <StyledSocialLI key={idx.toString()}>
                  <NewTabLinks link={social.link}>{social.icon}</NewTabLinks>
                </StyledSocialLI>
              ))}
            </StyledUL>
          </StyledColumnContainer>
        </div>

        <StyledCross onClick={() => onClose()}>
          <PlusOutlined />
        </StyledCross>
      </StyledDrawer>
    </>
  );
};
export default ResponsiveMenu;

const StyledCross = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 5px;
  cursor: pointer;

  svg {
    fill: ${COLORS.WHITE};
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 20px 0 20px 20px;
    background-color: ${COLORS.WEB3BLUE};
    ${DISPLAY_FLEX('column', 'space-between')}
  }
  .anticon-close {
    svg {
      color: ${COLORS.WHITE};
    }
  }
`;

const StyledRecoremLogo = styled.img`
  width: 100%;
  max-width: 170px;
  min-width: 100px;
  margin: 0;
  cursor: pointer;
`;

const StyledImageContainer = styled.div`
  position: relative;
  height: 40px;
  margin-bottom: 20px;
  filter: brightness(0) invert(1);
`;

const StyledDivider = styled.div`
  margin-top: 25px;
  border-top: 1px solid ${COLORS.DIVIDER_BLUE};
`;

export const StyledMenuItem = styled.span`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${DISPLAY_FLEX('row')}
  cursor: pointer;
  color: ${COLORS.WHITE};
  font-size: ${FONT[16]};
  margin-top: 18px;
`;

export const StyledCol = styled(Col)`
  margin-top: 10px;
  gap: 12px;
  ${DISPLAY_FLEX('column')}
`;

const StyledColumnContainer = styled.div`
  ${DISPLAY_FLEX('column')}
`;
const StyleLabel = styled.label`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.WHITE};
  margin-top: 12px;
  font-size: ${FONT[14]};
`;

const StyledSocialLI = styled(StyledLI)`
  display: inline-block;

  a svg path {
    fill: ${COLORS.WEB3BLUE};
  }
`;

export const StyledUL = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 8px;
  margin-bottom: 0px;
`;

const StyledMenuItemLink = styled(StyledMenuItem)`
  a {
    color: ${COLORS.WHITE} !important;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      color: ${COLORS.ANONYMOUS} !important;
    }
  }
`;

const StyledNavButton = styled.button`
  border: none;
  padding: 8px 40px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  background: ${COLORS.WEB3PINK};
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  a {
    font-weight: 700;
    font-family: Montserrat;
    font-size: 16px;
    color: white;

    &:hover {
      color: ${COLORS.WHITE};
    }
  }
`;

const StyledLogoLink = styled.a`
  width: 100%;
  max-width: 170px;
  min-width: 100px;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  margin-top: 18px;
`;
