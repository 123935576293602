import React, { useState, useEffect, useContext } from 'react';
import toLowerCase from 'lodash/toLower';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import {
  EventsStore,
  getActiveBookingEvents,
  getOnGoingEvent,
} from '../../../utils/event';
import { getRoleId, checkIfUserIsRecruiter } from '../../../utils/userInfo';
import { RecruiterRoutes, CandidateRoutes } from '../../../constants/routes';
import { checkIfUserIsCandidate } from '../../../utils/userInfo';
import { EventDetailsCard } from '../../shared-components/EventDetailsCard';
import EventCard from '../../shared-components/EventCard';
import { StyledMainHeader } from '../../shared-components/Event/Common';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT } from '../../../styles/constants/typography';
import { DISPLAY_FLEX, MARGIN } from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';
import AppSocketContext from '../../../context/AppSocket';
import { userProfileStore } from '../../../utils/profile';

const UpcomingEvents = ({
  dashboard,
  category = 'ongoing',
  search,
  noTitle = null,
  isCandidateDashboard = false,
}) => {
  const { onGoingEvents } = EventsStore();
  const roleId = getRoleId();
  const client = useContext(AppSocketContext);
  const { profileData } = userProfileStore();

  const [events, setEvents] = useState([]);

  const [filteredOngoingEvents, setFilteredOngoingEvents] = useState([]);

  useEffect(() => {
    const ongoing = isArray(events)
      ? events.filter((event) =>
        toLowerCase(event.name).includes(toLowerCase(search)),
      )
      : [];

    setFilteredOngoingEvents(ongoing);
  }, [search, events]);

  useEffect(() => {
    const companyId = get(profileData, 'company.id', null);
    if (client && companyId) {
      (async () => {
        await client.subscribe(
          `/company/${companyId}/booked-event/`,
          (resp) => {
            const event = events.find(
              (eventData) => eventData.id === get(resp, 'event.id'),
            );
            const newbookedEvent = get(resp, 'event', null);
            if (!event && getOnGoingEvent(newbookedEvent)) {
              const tempEventList = [newbookedEvent, ...events];
              setEvents(tempEventList);
            }
          },
        );
      })();
    }
    // eslint-disable-next-line
  }, [get(profileData, 'company.id', null), events]);

  useEffect(() => {
    setEvents(getActiveBookingEvents(onGoingEvents, dashboard, roleId));
  }, [onGoingEvents, dashboard, roleId]);

  if (!isArray(events) || events.length === 0) {
    return (
      <>
        <StyledRow>
          <Col>
            {!noTitle && (
              <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
                Ongoing Events
              </StyledMainHeader>
            )}
            <StyledPlaceholderText>
              <StyledSpanText>
                Your schedule is free at the moment. To find live events, head to
                the{' '}
                <StyledSpanText>
                  <StyledCreateEventPathLink
                    to={
                      checkIfUserIsRecruiter(roleId)
                        ? RecruiterRoutes.exploreEvent
                        : CandidateRoutes.exploreEvent
                    }
                  >
                    "Explore Events"
                  </StyledCreateEventPathLink>
                </StyledSpanText>{' '}
                page.
              </StyledSpanText>
            </StyledPlaceholderText>
          </Col>
        </StyledRow>
      </>
    );
  }

  return (
    <Row>
      <StyledWrapperCol span={24} noTitle={noTitle}>
        {events.length ? (
          <Row>
            <Col xs={12}>
              {!noTitle && (
                <StyledMainHeader color={getUserColorWithRoleId(roleId)}>
                  Ongoing Events
                </StyledMainHeader>
              )}
            </Col>
            <StyledFilterWrapper xs={12}>
              <StyledInnerFilterWrapper>
                {
                  // Commenting out only for Recorem Lite
                  /* <DropdownBox value={'filter'} />
                   */
                }
              </StyledInnerFilterWrapper>
            </StyledFilterWrapper>
          </Row>
        ) : null}

        {checkIfUserIsCandidate(roleId) ? (
          <Row gutter={[16, 0]} style={{ paddingTop: '20px' }}>
            {filteredOngoingEvents.map((event, idx) => {
              return (
                <Col
                  xs={24}
                  sm={12}
                  lg={8}
                  xxl={isCandidateDashboard ? 8 : 6}
                  key={idx}
                >
                  <EventCard
                    event={event}
                    roleId={roleId}
                    key={idx}
                    category={category}
                  />
                </Col>
              );
            })}
          </Row>
        ) : (
          filteredOngoingEvents.map((event, idx) => {
            return (
              <div key={idx}>
                <Col xs={24}>
                  <EventDetailsCard
                    roleId={roleId}
                    status="ongoing"
                    eventDetails={event}
                    category={category}
                  />
                </Col>
              </div>
            );
          })
        )}
      </StyledWrapperCol>
    </Row>
  );
};

export default UpcomingEvents;

const StyledRow = styled(Row)`
  flex-direction:column;
`;

const StyledWrapperCol = styled(Col)`
  @media (min-width: ${BREAKPOINTS.MOBILE}) and (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    margin-top: ${({ noTitle }) => !noTitle && '25px'};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-top: ${({ noTitle }) => !noTitle && '15px'};
  }
`;

const StyledFilterWrapper = styled(Col)`
  ${DISPLAY_FLEX('row-reverse')};
`;

const StyledInnerFilterWrapper = styled.div`
  width: 150px;
`;

const StyledPlaceholderText = styled.div`
  ${MARGIN(12, 0, 0, 0)}
`;

const StyledSpanText = styled.span`
  color: ${COLORS.GRAY};
  font-size: ${FONT[16]};
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;

const StyledCreateEventPathLink = styled(Link)`
  color: ${COLORS.DODGER_BLUE} !important;
  cursor: pointer !important;
  font-size: ${FONT[16]};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONT[14]};
  }
`;
