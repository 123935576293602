import get from 'lodash/get';
import moment from 'moment';
import { currentDate } from './formatDateTime';

export const mode = (array) => {
  if (array.length === 0) return null;

  const modeMap = {};
  let maxEl = array[0];
  let maxCount = 1;

  array.forEach((el) => {
    if (modeMap[el]) {
      modeMap[el] = (modeMap[el] || 0) + 1;
    } else {
      modeMap[el] = 1;
    }
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  });

  return maxEl;
};

export const modeOfArrayOfObjects = (array, key) => {
  if (array.length === 0) return null;

  const modeMap = {};
  let maxEl = array[0][key];
  let maxCount = 1;

  array.forEach((el) => {
    if (modeMap[el[key]]) {
      modeMap[el[key]] = (modeMap[el[key]] || 0) + 1;
    } else {
      modeMap[el[key]] = 1;
    }
    if (modeMap[el[key]] > maxCount) {
      maxEl = el[key];
      maxCount = modeMap[el[key]];
    }
  });

  return maxEl;
};

export const getDay = (startTime, endTime) => {
  const startOfTime = moment
    .unix(startTime, endTime)
    .format(`MMM Do, YYYY | ddd h:mm a`);
  const endOfTime = moment.unix(endTime).format('h:mm a');
  return { startOfTime, endOfTime };
};

export const numberToLPA = (value) => {
  let val = Math.abs(value);

  if (val >= 10000000) {
    val = `${(val / 10000000).toFixed(2)} Cr`;
  } else if (val >= 100000) {
    val = `${(val / 100000).toFixed(2)} Lac`;
  }
  return val;
};

export const getRemainingTimeShort = (
  dateTime,
  isDateTimeInPast = false,
  shortenResult = false,
) => {
  // Reverse the logic based on 2nd argument, if available
  const res = isDateTimeInPast
    ? currentDate().diff(dateTime)
    : dateTime.diff(currentDate());

  let { days, hours, minutes, months } = moment.duration(res)._data;

  let result = '';

  if (days < 0) {
    days = 0;
  }

  if (hours < 0) {
    hours = 0;
  }

  if (minutes < 0) {
    minutes = 0;
  }

  if (months < 0) {
    months = 0;
  }

  if (months > 0) {
    return months > 1 ? `${months} Mons` : `${months} Mon`;
  }

  if (days > 0) {
    return days > 1 ? `${result} ${days} Days` : `${result} ${days} Day`;
  }

  // Sometime we only need shorted version of datetime result
  if (shortenResult && months > 0 && days > 0) return result;

  if (hours > 0) {
    return hours > 1 ? `${result}  ${hours} Hrs` : `${result}  ${hours} Hr`;
  }

  if (shortenResult && days > 0 && hours > 0) return result;

  if (minutes > 0) {
    return minutes > 1
      ? `${result}  ${minutes} Mins`
      : `${result}  ${minutes} Min`;
  }

  return result || 'Over';
};

export const getRedirectableLink = (link) => {
  if (link.includes('https://') || link.includes('http://')) {
    return link;
  }
  return `https://${link}`;
}

export const transformHtmlData = (data, field) => {
  const value = get(data, field) || '';
  return value?.replace(/<p><br><\/p>/g, '');
};
