import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row, Tooltip } from 'antd';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToolTipsText } from '../../../constants/tooltips';
import { EventsStore } from '../../../utils/event';
import CompanyManagement from './CompanyManagement';
import Header from '../../../components/ManagementHeader/Header';
import { getOrganizerEventsData } from '../../../store/features/events';
import {
  getOrganizerRecruiterAnalytics,
  getOrganizerCandidateAnalytics,
} from '../../../service/analytics';
import {
  ATTENDEES_MANAGEMENT_ROLES,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  initialTabEventInfo,
  RETRY_ATTEMPTS,
  RETRY_INTERVALS,
} from '../../../constants';
import { OrganizerRoutes } from '../../../constants/routes';
import { getUrlVars } from '../../../utils/common';
import { isTestModeOn } from '../../../utils/test-helper';
import {
  checkIfUserIsOrganizer,
  getRoleId,
  getUserClassWithRoleId,
} from '../../../utils/userInfo';
import { PrimaryButton } from '../../../components/shared-components/Button';
import { TabSwitch } from '../../../components/shared-components/Tabs';
import { COLORS } from '../../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { safeLoad } from '../../../utils/loader';

const loadCandidateManagement = () => {
  return safeLoad(
    () => import('./CandidateManagement'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  );
};

const CandidateManagement = React.lazy(loadCandidateManagement);

const { company } = ATTENDEES_MANAGEMENT_ROLES;

const AttendeesManagement = ({ match, history, testMockData }) => {
  const { filter, page, limit, sort } = getUrlVars(
    get(history, 'location.search', ''),
  );
  const [role, setRole] = useState(company);
  const [tabEventInfo, setTabEventInfo] = useState(() => initialTabEventInfo);
  const [currentEventId, setCurrentEventId] = useState();
  const dispatch = useDispatch();
  const { onGoingEvents, upComingEvents, pastEvents } = EventsStore();
  const roleId = getRoleId();
  const [companyModal, setCompanyModal] = useState();
  const [candidateModal, setCandidateModal] = useState();
  const [headerModal, setHeaderModal] = useState();
  const [currentFilter, setCurrentFilter] = useState(parseInt(filter) || 1);
  const [currentPage, setCurrentPage] = useState(page || DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = useState(limit || DEFAULT_LIMIT);
  const [currentSorter, setCurrentSorter] = useState(sort || '');

  // Merge all events
  const events = onGoingEvents.concat(upComingEvents, pastEvents);

  useEffect(() => {
    // NOTE: Remove condition & fix a non-serializable value was detected in the state, in the path: `getState`...
    if (!parseInt(get(match, 'params.tab', null))) {
      // Open default tab if there's no tab data in url
      history.push(`${OrganizerRoutes.attendees}${company}`);
    } else {
      const tab = get(match, 'params.tab', company);
      // Set tab role
      setRole(tab);
      // Grab event id from search query in url
      const { event } = getUrlVars(get(history, 'location.search', {}));
      if (event) {
        const eventId = parseInt(event);
        setCurrentEventId(eventId);
        setCompanyModal(true);
        setCandidateModal(true);
        setHeaderModal(true);
      }
    }
  }, [dispatch, match, history]);

  const populateEvents = () => {
    dispatch(getOrganizerEventsData());
  };

  useEffect(() => {
    // NOTE: Remove condition & fix a non-serializable value was detected in the state, in the path: `getState`...
    if (!isTestModeOn() && checkIfUserIsOrganizer(roleId)) {
      // Fetch all events
      populateEvents();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Fetch & return event analytics based on current tab
   * @param {object} eventId
   * @param {string} tab
   */
  const fetchAnalyticsData = (eventId, tab) => {
    if (tab === company) {
      return getOrganizerRecruiterAnalytics(eventId);
    }
    return getOrganizerCandidateAnalytics(eventId);
  };

  /**
   * Set state with tab role and event index
   * @param {string} currentRole
   * @param {number} index
   */
  const handleTabInfoChange = async (currentRole, eventId) => {
    if (events && eventId) {
      const event = await events.find((event) => event.id === eventId);
      const analytics = await fetchAnalyticsData(event.id, role);
      if (event) {
        setTabEventInfo((prevState) => {
          // Set new state with previous state
          return {
            ...prevState,
            [currentRole]: {
              ...prevState[currentRole],
              analytics,
              event,
            },
          };
        });
      }
    }
  };

  React.useEffect(() => {
    // Note: here currentEventId's assignment depends on first useEffect hook
    // We only take care for first time component load
    if (currentEventId) {
      handleTabInfoChange(role, currentEventId);
      setCompanyModal(true);
      setCandidateModal(true);
      setHeaderModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGoingEvents, upComingEvents, pastEvents]);

  const valChange = (value) => {
    setRole(value);
    if (tabEventInfo[value].event) {
      const { id } = tabEventInfo[value].event;
      return history.push(`${value}?event=${id}`);
    }
    history.push(value);
    setCandidateModal(false);
    setCompanyModal(false);
    setHeaderModal(false);
  };

  const handleChange = (eventId) => {
    if (eventId) {
      history.push(
        `${role}?event=${eventId}&filter=${currentFilter}&sort=${currentSorter}&page=${currentPage}&limit=${currentLimit}`,
      );
    }
    setCurrentEventId(eventId);
    // Invoke with tab role and value ( index )
    handleTabInfoChange(role, eventId);
    setCompanyModal(true);
    setCandidateModal(true);
    setHeaderModal(true);
  };

  const updateTableData = (currentRole, tableData) => {
    const { event } = getUrlVars(get(history, 'location.search', {}));

    if (event) {
      setTabEventInfo((prevState) => {
        // Set new state with previous state
        return {
          ...prevState,
          [currentRole]: {
            ...prevState[currentRole],
            tableData,
          },
        };
      });
    }
  };

  /**
   * Return function based on condition
   */
  const getHandleChange = () => {
    return isTestModeOn() ? testMockData.handleChange : handleChange;
  };

  const getTabEventInfo = () => {
    return isTestModeOn() ? testMockData.tabEventInfo : tabEventInfo;
  };

  function handleAttendeesFilter(filter) {
    setCurrentFilter(filter);
    if (filter && currentEventId) {
      history.push(
        `${role}?event=${currentEventId}&filter=${filter}&sort=${currentSorter}&page=${currentPage}&limit=${currentLimit}`,
      );
    }
  }

  function handlePagination(newPage, newLimit) {
    setCurrentPage(newPage);
    setCurrentLimit(newLimit);
    history.push(
      `${role}?event=${currentEventId}&filter=${currentFilter}&sort=${currentSorter}&page=${newPage}&limit=${newLimit}`,
    );
  }

  function handleCurrentSorter(value) {
    setCurrentSorter(value);

    history.push(
      `${role}?event=${currentEventId}&filter=${currentFilter}&sort=${value}&page=${currentPage}&limit=${
        currentLimit || 10
      }`,
    );
  }

  const attendeesManagementItems = [
    {
      key: 1,
      name: 'Company Management',
      component: (
        <>
          <Header
            type={role}
            events={events}
            currentEventId={currentEventId}
            handleChange={getHandleChange()}
            tabEventInfo={getTabEventInfo()}
            headerModal={headerModal}
          />

          <CompanyManagement
            currentEventId={currentEventId}
            tabEventInfo={getTabEventInfo()}
            updateTableData={updateTableData}
            events={events}
            populateEvents={populateEvents}
            type={role}
            companyModal={companyModal}
            setTabEventInfo={setTabEventInfo}
            setAttendeesFilter={handleAttendeesFilter}
            currentFilter={currentFilter}
            getPagination={handlePagination}
            selectedPage={currentPage}
            selectedLimit={currentLimit}
            handleCurrentSorter={handleCurrentSorter}
            currentSorter={currentSorter}
          />
        </>
      ),
    },

    {
      key: 2,
      name: 'Candidate Management',
      component: (
        <>
          <Header
            type={role}
            events={events}
            currentEventId={currentEventId}
            handleChange={getHandleChange()}
            tabEventInfo={getTabEventInfo()}
            headerModal={headerModal}
          />

          <CandidateManagement
            currentEventId={currentEventId}
            tabEventInfo={getTabEventInfo()}
            updateTableData={updateTableData}
            events={events}
            populateEvents={populateEvents}
            type={role}
            candidateModal={candidateModal}
            setTabEventInfo={setTabEventInfo}
            setAttendeesFilter={handleAttendeesFilter}
            currentFilter={currentFilter}
            getPagination={handlePagination}
            selectedPage={currentPage}
            selectedLimit={currentLimit}
            handleCurrentSorter={handleCurrentSorter}
            currentSorter={currentSorter}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <StyledCircleWrapper>
        <StyledCircle />
      </StyledCircleWrapper>
      <Col>
        <StyledAttendeesManagementHeader>
          Attendees Management
          <div>
            {'  > '}
            {role === company ? 'Company Management' : 'Candidate Management'}
          </div>
        </StyledAttendeesManagementHeader>
        <StyledTab>
          <StyledTabContainer>
            <TabSwitch
              items={attendeesManagementItems}
              defaultActiveKey={company}
              activeKey={role}
              onChange={valChange}
            />
            <StyledBulkInvite>
              {!isTestModeOn() ? (
                <Link to={OrganizerRoutes.bulkInvite}>
                  {/* <Button>Bulk Invite</Button> */}
                  <PrimaryButton theme={getUserClassWithRoleId(roleId)}>
                    <Tooltip title={ToolTipsText.bulkInvite}>
                      Bulk Invite
                    </Tooltip>
                  </PrimaryButton>
                </Link>
              ) : null}
            </StyledBulkInvite>
          </StyledTabContainer>
        </StyledTab>
      </Col>
    </div>
  );
};

const StyledCircleWrapper = styled.div`
  position: relative;
`;

const StyledCircle = styled.div`
  background: ${COLORS.PUERTO_RICO};
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.2;
  position: absolute;
  left: -124px;
  top: -100px;
`;

const StyledAttendeesManagementHeader = styled(Row)`
  color: ${COLORS.PUERTO_RICO};
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  padding: 10px 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }

  div {
    margin-left: 5px;
  }

  &,
  div {
    font-size: 14px;
    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    margin-bottom: 12px;
    margin-top: 12px;
  }
`;

const StyledTab = styled(Row)`
  box-shadow: 0 0 10px #0000001a;
`;

const StyledTabContainer = styled.div`
  width: 100%;
  position: relative;
  background: ${COLORS.WHITE};
  padding: 20px;

  .ant-tabs-tab-btn {
    font-size: ${FONT[14]};
    color: ${COLORS.DARK_GRAY};
    font-weight: ${FONT_WEIGHTS.MEDIUM};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    div {
      color: ${COLORS.PUERTO_RICO} !important;
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${COLORS.PUERTO_RICO};
  }

  .ant-tabs {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      margin: 40px 0;
    }
  }
`;

const StyledBulkInvite = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  span {
    font-size: ${FONT[14]};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }
  }
`;

export default AttendeesManagement;
