import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import CompanyInformation from '../profile/CompanyInformation';
import {
  crewMemberStore,
  userProfileStore,
  getCompanyStatus,
} from '../../utils/profile';
import {
  checkIfUserIsOrganizer,
  checkIfUserIsCandidate,
  getRoleId,
  getUserClassWithRoleId,
} from '../../utils/userInfo';
import {
  getOrganizerCrewMembersData,
  getRecruiterCrewMembersData,
} from '../../store/features/crew';
import CrewMemberProfileHeader from './CrewMemberProfileHeader';
import RepresentativeOrBasicInformation from './RepresentativeOrBasicInformation';
import RepresentativeAndTeamProfile from './RepresentativeAndTeamProfile';
import CrewMembers from './CrewMembers';
import { STATUS_ACTIVE } from '../../constants';
import { getFileS3 } from '../../utils/s3.storage';
import PendingInvitation from './PendingInvitations';
import {
  handleAcceptInvitation,
  handleRejectInvitation,
} from '../../utils/crew';
import { PageContainer } from '../shared-components/PageContainer';
import Circle from '../shared-components/PageCircle';
import { getUserColorWithRoleId } from '../../styles/utils';
import { BigText } from '../shared-components/Texts';
import { FONT_WEIGHTS } from '../../styles/constants/typography';

export default function CrewMemberProfile({ match, memberId }) {
  const { isLoading, profileData } = userProfileStore();
  const dispatch = useDispatch();

  const roleId = getRoleId();

  const stableDispatch = useCallback(
    (args) => {
      return dispatch(args);
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      !checkIfUserIsCandidate(roleId) &&
      getCompanyStatus(profileData) === STATUS_ACTIVE
    ) {
      checkIfUserIsOrganizer(roleId)
        ? stableDispatch(getOrganizerCrewMembersData())
        : stableDispatch(getRecruiterCrewMembersData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, stableDispatch, getCompanyStatus(profileData)]);

  const [profilePic, setProfilePic] = useState(null);

  const [adminProfilePic, setAdminProfilePic] = useState(null);

  const [companyLogo, setCompanyLogo] = useState(null);

  const { company: companyData } = profileData;

  const [crewMemberId, setCrewMemberId] = useState(0);
  const { members } = crewMemberStore();
  const [crewMemberProfileData, setCrewMemberProfileData] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setCompanyLogo(getFileS3(companyData.logo));
      const { id: userId } = match ? match.params : memberId;
      setCrewMemberId(userId);

      const crewMemberProfileData = members.filter(
        (member) =>
          parseInt(member.userId) === parseInt(crewMemberId || memberId),
      )[0];

      setCrewMemberProfileData(crewMemberProfileData);

      const userProfilePic = getFileS3(
        checkIfUserIsOrganizer(roleId)
          ? get(crewMemberProfileData, 'profilePic', '')
          : get(crewMemberProfileData, 'profilePic', ''),
      );
      setProfilePic(userProfilePic);

      const adminPic = getFileS3(
        get(
          profileData,
          'companyAdmin.profilePic',
          get(profileData, 'profilePic', ''),
        ),
      );
      setAdminProfilePic(adminPic);
    }
  }, [
    profileData,
    companyData,
    isLoading,
    roleId,
    members,
    crewMemberId,
    memberId,
    match,
  ]);

  const invitations = get(profileData, 'invitations', []);

  const handleAccept = (idx, companyName) => {
    handleAcceptInvitation(idx, companyName, dispatch, profileData);
  };

  const handleReject = (idx, companyName) => {
    handleRejectInvitation(idx, companyName, dispatch, profileData);
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <div>
      <Circle color={getUserColorWithRoleId(roleId)} />
      <CrewMemberProfileHeader
        data={crewMemberProfileData}
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        companyData={companyData}
        members={members}
      />
      <div>
        <PageContainer>
          <RepresentativeOrBasicInformation
            data={crewMemberProfileData}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            members={members}
            isCrewProfile
          />
        </PageContainer>
        <PageContainer>
          <CompanyInformation
            companyData={companyData}
            profileData={profileData}
            companyLogo={companyLogo}
            setCompanyLogo={setCompanyLogo}
          />
        </PageContainer>
        <PageContainer>
          <BigText
            fontWeight={FONT_WEIGHTS.SEMI_BOLD}
            color={getUserColorWithRoleId(roleId)}
            margin="0 0 15px 0"
          >
            Representative & Team Profile
          </BigText>
          <RepresentativeAndTeamProfile
            data={profileData}
            profilePic={adminProfilePic}
            setProfilePic={setAdminProfilePic}
            theme={getUserClassWithRoleId(roleId)}
          />
        </PageContainer>
        <PageContainer>
          <CrewMembers data={profileData} members={members} />
          {invitations && invitations.length ? (
            <PendingInvitation
              invitations={invitations}
              roleId={roleId}
              handleAcceptInvitation={handleAccept}
              handleRejectInvitation={handleReject}
            />
          ) : null}
        </PageContainer>
      </div>
    </div>
  );
}
