/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { isPastEvent } from '../../../utils/event';
import { checkIfUserIsCandidate } from '../../../utils/userInfo';
import { MARGIN, PADDING } from '../../../styles/constants/display';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { safeLoad } from '../../../utils/loader';
import { RETRY_ATTEMPTS, RETRY_INTERVALS } from '../../../constants';
import { EventHosts } from './EventHosts';
import { EventSpeakers } from './EventSpeakers';
import { EventSchedule } from './EventSchedule';
import { EventSponsor } from './EventSponsor';

const CandidateEventDetails = React.lazy(() =>
  safeLoad(
    () => import('./CandidateEventDetails'),
    RETRY_ATTEMPTS,
    RETRY_INTERVALS,
  ),
);

const TopBanner = React.lazy(() =>
  safeLoad(() => import('./TopBar'), RETRY_ATTEMPTS, RETRY_INTERVALS),
);

const EventDetails = React.lazy(() =>
  safeLoad(() => import('./EventDetails'), RETRY_ATTEMPTS, RETRY_INTERVALS),
);

const SpeakersAndHost = React.lazy(() =>
  safeLoad(() => import('../../../containers/Organizer/Events/SwiftEvent/SpeakersAndHost'), RETRY_ATTEMPTS, RETRY_INTERVALS),
);

export const UserClassNameContext = React.createContext({});

const ViewEvent = ({
  event,
  roleId,
  termsAndConditionsList,
  UserClassNameContextProvider,
  isLoading,
  error,
  discussionRoom,
}) => {
  const [speakerId, setSpeakerId] = useState();

  return isLoading || error || !event || isEmpty(event) ? (
    <Skeleton />
  ) : (
    <StyledMainContainer
      isCandidate={checkIfUserIsCandidate(roleId)}
      isPastEventContainer={
        !(
          checkIfUserIsCandidate(roleId) &&
          !isPastEvent(event.dateTime, event.endDateTime)
        )
      }
    >
      {checkIfUserIsCandidate(roleId) &&
        !isPastEvent(event.dateTime, event.endDateTime) ? (
        <React.Suspense fallback={<Skeleton />}>
          <CandidateEventDetails
            event={event && event}
            roleId={roleId}
            termsAndConditionsList={termsAndConditionsList}
            UserClassNameContextProvider={UserClassNameContextProvider}
            discussionRoom={discussionRoom}
          />
        </React.Suspense>
      ) : (
        <>
          <React.Suspense fallback={<Skeleton />}>
            <TopBanner
              event={event}
              roleId={roleId}
              UserClassNameContextProvider={UserClassNameContextProvider}
            />
          </React.Suspense>
          <React.Suspense fallback={<Skeleton />}>
            <SpeakersAndHost event={event} />
          </React.Suspense>
          <React.Suspense fallback={<Skeleton />}>
            <EventDetails
              event={event}
              roleId={roleId}
              termsAndConditionsList={termsAndConditionsList}
              eventHosts={<EventHosts hosts={event.meta.hosts} />}
              eventSpeakers={
                <EventSpeakers
                  speakers={event.speakers}
                  speakerId={speakerId}
                  setSpeakerId={setSpeakerId}
                />
              }
              eventSchedule={
                <EventSchedule
                  schedule={event.schedule}
                  setSpeakerId={setSpeakerId}
                />
              }
              eventSponsors={
                <EventSponsor
                  sponsors={event.meta.sponsors}
                  partners={event.meta.partners}
                />
              }
            />
          </React.Suspense>
        </>
      )}
    </StyledMainContainer>
  );
};

export default React.memo(ViewEvent);

const StyledMainContainer = styled.div`
  position: relative;
  ${({ isPastEventContainer }) =>
    isPastEventContainer
      ? `padding: 35px 30px;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 10px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 40px;
  overflow: hidden;`
      : ''}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ isPastEventContainer }) =>
    !isPastEventContainer ? MARGIN(-35, 0, 0, 0) : ''}

    z-index: 11;
    ${({ isCandidate }) =>
    !isCandidate ? PADDING(14, 10, 30, 10) : PADDING(14, 0, 30, 0)}

    ${({ isPastEventContainer }) =>
    isPastEventContainer ? PADDING(14, 12, 0, 12) : ''}
  }
`;
