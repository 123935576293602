/* eslint-disable no-nested-ternary */
import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CANDIDATE } from '../../../constants';
import SignUpAndLoginModal from '../../../containers/login-signup/SIgnUpAndLogin';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { ISignInModal } from '../../../interfaces/community';

const SignInModal = ({
  isModalVisible,
  closeModalWithoutLogin: closeModal,
  closeModalAfterLogin,
}: ISignInModal) => {
  const [roleId, setRoleId] = React.useState(CANDIDATE);
  const [, setIsLoginOrSignup] = React.useState(false);
  return (
    <StyledModal
      style={{ width: '100%', resize: 'none' }}
      visible={isModalVisible}
      centered
      onCancel={closeModal}
      footer={null}
      zIndex={5666}
      closable={false}
    >
      <SignUpAndLoginModal
        isModal
        roleId={roleId}
        setRoleId={setRoleId}
        setIsSignAction={setIsLoginOrSignup}
        closeModal={closeModalAfterLogin}
      />
    </StyledModal>
  );
};

export default SignInModal;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    width: fit-content;
  }
  @media (max-width: ${BREAKPOINTS.TABLET_MID}) {
    .ant-modal-content {
      left: 10%;
    }
  }
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-modal-content {
      position: absolute;
      top: 20%;
      left: 15%;
    }
    .ant-modal-close-x {
      display: block;
    }
  }
  @media (max-width: 500px) {
    .ant-modal-content {
      width: 90%;
      position: absolute;
      top: 15%;
      left: 5%;
    }
  }
`;
