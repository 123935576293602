import React from 'react';
import { PageHeader } from 'antd';
import Placeholder from '../../components/PlaceHolder/PlaceHolder';
import UnderConstruction from '../../assets/placeholders/under-construction.svg';

const Profile = () => {
  return (
    <div>
      <PageHeader title="Profile">
        <Placeholder image={UnderConstruction} />
      </PageHeader>
    </div>
  );
};

export default Profile;
