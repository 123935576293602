import { setApplyJobDatatoLocal } from '../store/features/commons';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
} from './userInfo';
import recorem from '../assets/svg/recorem.svg';
import target from '../assets/svg/target.svg';
import rocket from '../assets/svg/rocket.svg';
import { CANDIDATE, ORGANIZER, RECRUITER } from '../constants';
import { COLORS } from '../styles/constants/colors';

export const getDemonstrateDataByUser = (user) => {
  if (user && checkIfUserIsRecruiter(user)) {
    return ['Create profile ', 'Post job', 'Hire talent'];
  }
  if (user && checkIfUserIsOrganizer(user)) {
    return ['Create profile  ', 'List events', 'Find leads'];
  }
  return ['Create profile   ', 'Join event', 'Get hired'];
};

export const getFormInfoByUser = (roleId) => {
  switch (roleId) {
    case ORGANIZER:
      return {
        heading: 'Let’s Reach’em',
        subHeading: 'Boost viewership by 70% ',
        primaryColor: COLORS.PUERTO_RICO,
        bgColor: COLORS.WHITE,
        color: COLORS.BLACK,
        icon: rocket,
        buttons: {
          firstButton: {
            color: COLORS.WHITE,
            bgColor: COLORS.SOFT_BLUE,
            text: 'Get Hired',
            userType: CANDIDATE,
          },
          secondButton: {
            color: COLORS.BLACK,
            bgColor: '#FFCE70',
            text: 'Post Jobs',
            userType: RECRUITER,
          },
        },
      };
    case RECRUITER:
      return {
        heading: 'Let’s Recruit’em',
        subHeading: 'Discover passive talent',
        primaryColor: '#FBBF4C',
        bgColor: COLORS.BLACK,
        color: COLORS.BLACK,
        icon: target,
        buttons: {
          firstButton: {
            color: COLORS.WHITE,
            bgColor: COLORS.SOFT_BLUE,
            text: 'Get Hired',
            userType: CANDIDATE,
          },
          secondButton: {
            color: COLORS.WHITE,
            bgColor: COLORS.PUERTO_RICO,
            text: 'List Events',
            userType: ORGANIZER,
          },
        },
      };
    default:
      return {
        heading: 'Launch Recorem',
        subHeading: 'Unlock 100+ jobs & events',
        primaryColor: COLORS.SOFT_BLUE,
        bgColor: COLORS.SOFT_BLUE,
        icon: recorem,
        buttons: {
          firstButton: {
            color: COLORS.BLACK,
            bgColor: '#FFCE70',
            text: 'Post Jobs',
            userType: RECRUITER,
          },
          secondButton: {
            color: COLORS.WHITE,
            bgColor: COLORS.PUERTO_RICO,
            text: 'List Events',
            userType: ORGANIZER,
          },
        },
      };
  }
};

export const redirectAfterLogin = (
  dispatch,
  history,
  roleId,
  inviteToken = null,
  eventId = null,
  appliedJob = null,
  event = null,
) => {
  const redirect =
    inviteToken && eventId
      ? `events/view/${eventId}?inviteToken=${inviteToken}`
      : eventId
      ? `events/view/${eventId}`
      : '';
  if (appliedJob && event) {
    dispatch(setApplyJobDatatoLocal({ appliedJob, eventId: event }));
  }

  if (checkIfUserIsOrganizer(roleId)) history.push(`/organizer/${redirect}`);
  else if (checkIfUserIsRecruiter(roleId))
    history.push(`/recruiter/${redirect}`);
  else if (checkIfUserIsCandidate(roleId))
    history.push(`/candidate/${redirect}`);
};
