import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { Table } from 'antd';
import styled from 'styled-components';
import toLower from 'lodash/toLower';
import { getInvitedCandidate } from '../../../service/masterAdmin';
import CustomPagination from '../../../components/Common/CustomPagination';
import {
  invitedCandidateColumns,
  makeInvitedCandidateTableData,
} from '../../../utils/admin/event';
import { CANDIDATE, DEFAULT_LIMIT, DEFAULT_PAGE } from '../../../constants';
import { handleExportCsv } from '../../../utils/admin/bulkInvite';
import { InvitedAttendeesTableHeader } from './commons';
import { RightAlignContainer } from '../../../components/shared-components/RightAlignContainer';
import { useSearchDebounce } from '../../../components/Common/CustomHooks';

const Candidates = ({ history, event }) => {
  const [candidates, setCandidates] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT);
  const [profileFilter, setProfileFilter] = useState(0);
  const [registerFilter, setRegisterFilter] = useState(0);
  const [search, setSearch] = useSearchDebounce();
  const [joinFilter, setJoinFilter] = useState(0);
  const [pagination, setPagination] = useState({
    currentPage: null,
    totalItems: null,
  });

  const populate = async (
    page,
    limit,
    profileFilter,
    registerFilter,
    joinFilter,
    search,
  ) => {
    if (event && event.id) {
      const res = await getInvitedCandidate(
        event.id,
        page,
        limit,
        profileFilter,
        registerFilter,
        joinFilter,
        search,
      );
      const candidateTableData = makeInvitedCandidateTableData(
        get(res, 'data.payload.candidates', []),
        defaultDataFetch,
        history,
        currentPage,
        currentLimit,
      );
      setCandidates(candidateTableData);
      setFilteredDataSource(candidateTableData);
      setPagination({
        currentPage: get(res, 'data.payload.currentPage', null),
        totalItems: get(res, 'data.payload.totalItems', null),
      });
    }
  };

  const defaultDataFetch = () => {
    populate(
      currentPage,
      currentLimit,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    );
  };

  useEffect(() => {
    populate(
      currentPage,
      currentLimit,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    );

    // eslint-disable-next-line
  }, [event]);

  const handleSearch = (e) => {
    const text = toLower(get(e, 'target.value', ''));
    setSearch(text);
  };

  useEffect(() => {
    populate(
      currentPage,
      currentLimit,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
    );

    // eslint-disable-next-line
  }, [
    currentPage,
    currentLimit,
    profileFilter,
    registerFilter,
    joinFilter,
    search,
  ]);

  const onPageChangeHandler = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onLimitChangeHandler = async (pageNumber, limit) => {
    setCurrentPage(pageNumber);
    setCurrentLimit(limit);
  };

  const handleProfileFilterChange = async (id) => {
    setProfileFilter(id);
  };

  const handleEventRegistrationFilterChange = async (id) => {
    setRegisterFilter(id);
  };

  const handleEventJoinFilterChange = async (id) => {
    setJoinFilter(id);
  };

  const handleExport = (setIsExporting) => {
    handleExportCsv(
      event,
      CANDIDATE,
      profileFilter,
      registerFilter,
      joinFilter,
      search,
      setIsExporting,
    );
  };

  return (
    <>
      <InvitedAttendeesTableHeader
        searchPlaceHolder="Search By email"
        handleSearch={handleSearch}
        handleExport={handleExport}
        handleProfileFilterChange={handleProfileFilterChange}
        handleEventRegistrationFilterChange={
          handleEventRegistrationFilterChange
        }
        handleEventJoinFilterChange={handleEventJoinFilterChange}
      />
      <StyledTableContainer>
        <Table
          columns={invitedCandidateColumns}
          dataSource={filteredDataSource}
          pagination={false}
        />
      </StyledTableContainer>
      <RightAlignContainer>
        <CustomPagination
          currentPage={get(pagination, 'currentPage', currentPage)}
          totalItems={get(pagination, 'totalItems', candidates.length)}
          onPageChange={onPageChangeHandler}
          onLimitChange={onLimitChangeHandler}
          currentLimit={currentLimit}
        />
      </RightAlignContainer>
    </>
  );
};

export default Candidates;

const StyledTableContainer = styled.div`
  overflow: auto;
`;
