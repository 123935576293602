import { APIs, Method } from '../constants';
import api from './api';
import { isValid } from '../utils/api';
import get from 'lodash/get';
import ERRORS from '../constants/errors';

export const getAllIntegrations = () => {
  return api({
    url: APIs.allIntegrations,
    method: Method.get,
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      return get(response, 'payload.integrations', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

export const createIntegration = async (info) => {
  return api({
    url: APIs.createIntegrationByCode,
    method: Method.post,
    body: {
      info,
    },
  })
    .then((response) => {
      if (!isValid(response)) throw new Error(ERRORS.REQUEST_FAILED);
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};
