import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import {
  createChatroom,
  getUserProfileData,
  updateChatroom,
} from '../service/chat';
import {
  CANDIDATE,
  CANDIDATE_COLOR,
  CANDIDATE_TEXT,
  CHATROOM_TYPE_GROUP,
  DELETE_STATUS_CODE,
  ORGANIZER_COLOR,
  ORGANIZER_TEXT,
  RECRUITER,
  RECRUITER_COLOR,
  RECRUITER_TEXT,
  USERTYPE_COMPANY,
  USERTYPE_INDIVIDUAL,
} from '../constants';
import { UserInformation } from './userInfo';
import { userProfileStore } from './profile';
import { getTextFromHTML } from './common';
import { CHAT_STATUS } from '../constants/chat';
import getNow from './getNow';

export const getSenderIds = (chat) => {
  const sender = get(chat, 'sender', {});
  if (isEmpty(sender) || !sender)
    return {
      senderId: get(chat, 'senderId', null),
      senderCompanyId: get(chat, 'senderCompanyId', null),
    };
  const isCompany = !(sender.type === 'individual');
  const senderId = isCompany
    ? get(chat, 'senderId', null)
    : get(sender, 'id', null);
  const senderCompanyId = isCompany ? get(sender, 'id', null) : null;

  return { senderId, senderCompanyId };
};

export const extractUsersProfileData = async (chats) => {
  let userIdArray = [];
  let companyIdArray = [];

  chats.forEach((chat) => {
    chat.users.forEach((user) => {
      if (user.type === USERTYPE_COMPANY) {
        companyIdArray.push(user.id);
      } else {
        userIdArray.push(user.id);
      }
    });
  });
  userIdArray = uniq(userIdArray);
  companyIdArray = uniq(companyIdArray);

  const usersProfile = await getUserProfileData({
    userIdArray,
    companyIdArray,
  });
  return usersProfile;
};

export const getUsersDetailsInChatroom = (usersArr, usersProfileData) => {
  const usersList = [];
  const companyList = [];

  const { users, companys } = usersProfileData;

  usersArr.forEach((item) => {
    const { user } = item;
    if (get(user, 'type', null) === USERTYPE_COMPANY) {
      companys.forEach((us) => {
        if (get(user, 'id', null) === get(us, 'id', null))
          companyList.push({ ...user, ...us });
      });
    } else {
      users.forEach((us) => {
        if (get(user, 'id', null) === get(us, 'id', null))
          usersList.push({ ...user, ...us });
      });
    }
  });

  return {
    users: uniq(usersList),
    companys: uniq(companyList),
  };
};

const getChatType = (roleId) => {
  switch (roleId) {
    case CANDIDATE:
      return 'individual';
    default:
      return 'company';
  }
};

export const getSecondUserData = (
  chatRoom,
  userId,
  usersProfileData,
  roleId = CANDIDATE,
) => {
  const { users, companys } = usersProfileData;

  let data1;
  let data2;
  const usersList = get(chatRoom, 'users', []);
  usersList.forEach((it) => {
    const checkId = get(it, 'userId', get(it, 'id', null));
    const checkType = get(it, 'userType', get(it, 'type', 'individual'));
    if (checkType !== getChatType(roleId) || checkId !== userId) data1 = it;
  });

  if (get(data1, 'type', null) === USERTYPE_COMPANY) {
    companys.forEach((user) => {
      if (get(user, 'id', null) === get(data1, 'id', null)) data2 = user;
    });
  } else {
    users.forEach((user) => {
      if (get(user, 'id', null) === get(data1, 'id', null)) data2 = user;
    });
  }

  return {
    ...data1,
    ...data2,
  };
};

export const getSingleUserData = (id, companyId, usersDetails) => {
  const users = get(usersDetails, 'users', []);
  const companys = get(usersDetails, 'companys', []);

  let resUser;

  if (companyId) {
    companys.forEach((user) => {
      if (get(user, 'id', null) === companyId) resUser = user;
    });
  } else {
    users.forEach((user) => {
      if (get(user, 'id', null) === id) resUser = user;
    });
  }
  return resUser;
};

export const timeAgo = (dateString) => {
  TimeAgo.addLocale(en);
  const timeAgoo = new TimeAgo('en-US');

  const date = new Date(dateString);
  return timeAgoo.format(date);
};

export const getUsersDetailsAndProfileData = async (usersArr) => {
  let userIdArray = [];
  let companyIdArray = [];
  usersArr.forEach((user) => {
    if (user.type === USERTYPE_COMPANY) {
      companyIdArray.push(user.id);
    } else {
      userIdArray.push(user.id);
    }
  });
  userIdArray = uniq(userIdArray);
  companyIdArray = uniq(companyIdArray);

  const usersProfile = await getUserProfileData({
    userIdArray,
    companyIdArray,
  });
  return usersProfile;
};

export const userInfoRegardingChatroom = () => {
  const {
    user: { name, roleId, companyId },
  } = UserInformation();

  const {
    profileData: { userId, name: userName, representativeName },
  } = userProfileStore();

  let description;
  if (roleId === CANDIDATE) {
    description = CANDIDATE_TEXT;
  } else if (roleId === RECRUITER) {
    description = RECRUITER_TEXT;
  } else {
    description = ORGANIZER_TEXT;
  }

  let theme;
  if (roleId === CANDIDATE) {
    theme = CANDIDATE_COLOR;
  } else if (roleId === RECRUITER) {
    theme = RECRUITER_COLOR;
  } else {
    theme = ORGANIZER_COLOR;
  }

  return {
    id: roleId === CANDIDATE ? userId : companyId,
    name: name || userName || representativeName,
    description,
    theme,
    type: roleId === CANDIDATE ? USERTYPE_INDIVIDUAL : USERTYPE_COMPANY,
  };
};

export const eventDataRegardingChatroom = (event) => {
  const { id, name, description, dateTime, endDateTime } = event;
  return {
    eventId: id,
    name,
    description: getTextFromHTML(description),
    startTime: dateTime,
    endTime: endDateTime,
  };
};

export const eventOrgainizerDataRegardingChatroom = async (companyId) => {
  const data = await getUserProfileData({ companyIdArray: [companyId] });
  const org = data.companys[0];
  return {
    id: companyId,
    name: org.name,
    description: ORGANIZER_TEXT,
    theme: ORGANIZER_COLOR,
    type: USERTYPE_COMPANY,
  };
};

export const candidateInfoRegardingChatroom = (data) => {
  const { userId, name } = data;

  return {
    id: userId,
    name,
    description: CANDIDATE_TEXT,
    theme: CANDIDATE_COLOR,
    type: USERTYPE_INDIVIDUAL,
  };
};

export const recruiterInfoRegardingChatroom = (data) => {
  const {
    companyId,
    company: { name },
  } = data;

  return {
    id: companyId,
    name,
    description: RECRUITER_TEXT,
    theme: RECRUITER_COLOR,
    type: USERTYPE_COMPANY,
  };
};

export const fetchCompanyUsersDetails = async (messages) => {
  let ids = [];
  messages.forEach((msg) => {
    const { senderId, senderCompanyId } = getSenderIds(msg);
    if (senderCompanyId) {
      ids.push(senderId);
    }
  });
  ids = uniq(ids);
  const data = await getUserProfileData({ userIdArray: ids });
  return data.users;
};

export const getIndividualName = async (
  senderId,
  existingCompanyUserDetails,
  loadNewCompanyUserDetails,
  dispatch,
) => {
  let res;
  existingCompanyUserDetails.forEach((user) => {
    if (senderId === user.id) res = user.name;
  });

  if (res) return res;

  const { users: companyUsers } = await getUserProfileData({
    userIdArray: [senderId],
  });

  const newCompanyUsers = get(companyUsers, '[0]', []);

  if (isFunction(dispatch) && isFunction(loadNewCompanyUserDetails)) {
    dispatch(
      loadNewCompanyUserDetails(newCompanyUsers, existingCompanyUserDetails),
    );
  }

  res = get(newCompanyUsers, 'name', '');

  return res;
};

export const extractUpdateChatroomData = (data) => {
  const { dateTime, description, endDateTime, name } = data;
  return {
    name,
    description: getTextFromHTML(description),
    startTime: dateTime,
    endTime: endDateTime,
  };
};

export const updateGroupChatroom = async (event, createEventData) => {
  const updateChatroomData = extractUpdateChatroomData(createEventData);
  const chatroomUpdated = await updateChatroom(
    event.id,
    updateChatroomData,
    CHATROOM_TYPE_GROUP,
  );
  // 400 defines that the chatroom doesn't exist.
  if (chatroomUpdated.statusCode === 400) {
    const organizerData = await eventOrgainizerDataRegardingChatroom(
      event.companyId,
    );
    const eventData = eventDataRegardingChatroom({
      id: event.id,
      ...createEventData,
    });
    await createChatroom(
      eventData,
      [organizerData],
      CHATROOM_TYPE_GROUP,
      false,
    );
  }
};

export const isSender = (message, companyId, userId) => {
  const { senderId } = getSenderIds(message);
  const id = senderId || message.senderId;
  if (message.type === 'automated') return id === companyId;
  return id === userId;
};

export const updatedEndTimeOnEvaluationDeadlineChange = (
  endTime,
  days,
  hours,
) => {
  return endTime + days * 24 * 3600 + hours * 3600;
};

export const shortenText = (fullMessage, n) => {
  if (fullMessage && fullMessage.length > n) {
    fullMessage = `${fullMessage.substring(0, n)}...`;
  }
  return fullMessage;
};

/**
 * Takes following arguments & returns array of thread/message ids
 * @param {array} chatMessages
 * @param {boolean} isOrganizer
 * @param {number} userId
 * @returns {array}
 */
export const getThreadIds = (chatMessages, isOrganizer, userId) => {
  if (isArray(chatMessages)) {
    return (
      chatMessages
        .filter((chats) => {
          let result = false;
          if (!isOrganizer) {
            // Filter by status code & senderId
            result =
              chats.status !== DELETE_STATUS_CODE && userId === chats.senderId;
          } else {
            // Filter by status code only
            result = chats.status !== DELETE_STATUS_CODE;
          }
          return result;
        })
        // eslint-disable-next-line no-underscore-dangle
        .map((threads) => threads?._id || threads?.messageId)
    );
  }
  return [];
};

/**
 * Takes following params, finds unqiue company sender ids, store in an array and returns unqiue ids
 * @param {array} messages
 * @returns {array} unique ids
 */
export const getUniqueIdsFromMessages = (messages) => {
  return messages.reduce((ids, msgPayload) => {
    const { senderId, senderCompanyId } = getSenderIds(msgPayload);
    if (senderCompanyId && senderId && !ids.includes(senderId)) {
      ids.push(senderId);
    }
    return ids;
  }, []);
};

/**
 * Takes following params, find new ids by comparing both params payloads and returns newUnqiueIds
 * @param {array} prevMessages
 * @param {array} newMessages
 * @returns {array} newUnqiueIds
 */
export const getNewUniqueIdsFromMessages = (userDetails, newMessages) => {
  const newUnqiueIds = [];

  const availableUserIds = userDetails.map((userDetail) => userDetail?.id);

  const newMessageSenderIds = getUniqueIdsFromMessages(newMessages);

  // Check length and return if it's zero
  if (!newMessageSenderIds.length) {
    return newUnqiueIds;
  }

  // Check if unfetched ids
  const filtered = newMessageSenderIds.filter(
    (id) => !availableUserIds.includes(id),
  );

  return filtered;
};

/**
 * Takes following arguments and based on that it returns boolean
 * @param {array} messages
 * @param {number} senderId
 * @returns {boolean}
 */
export const messageExistWithSenderId = (messages, senderId) => {
  let result = false;
  if (isArray(messages) && messages?.length && typeof senderId === 'number') {
    for (let k = 0; k < messages.length; k += 1) {
      const { senderId: id } = getSenderIds(get(messages, `[${k}]`, {}));
      if (id === senderId) {
        result = true;
        break;
      }
    }
  }
  return result;
};

export const isChatDisabled = (status) => {
  return status === CHAT_STATUS.DISABLED;
};

export const setLocalStorageItemWithExpiry = (key, value, ttl = 21600) => {
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: ttl ? getNow() + ttl : null,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItemWithExpiry = (key) => {
  let item = localStorage.getItem(key);

  if (!item) return null;

  item = JSON.parse(item);
  // compare the expiry time of the item with the current time
  if (item.expiry && getNow() > item.expiry) {
    // If the item is expired, delete the item from storage and return null
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
};
