import React from 'react';
import styled from 'styled-components';
import { MARGIN } from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

const TabDetails = ({ data }) => {
  const dataWithSpanRemoved = data.replace(/<\/?span[^>]*>/g, '');
  const styledData = dataWithSpanRemoved.replace(
    /<p>(.*?)<\/p>/g,
    '<p style="font-weight:700;">$1</p>',
  );
  return (
    <StyledJobDescription>
      <p
        dangerouslySetInnerHTML={{
          __html: styledData,
        }}
      />
    </StyledJobDescription>
  );
};

const StyledJobDescription = styled.div`
  p {
    font-size: ${FONT[15]};
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    opacity: 0.8;
  }
  ${MARGIN(0, 0, 30, 0)}
`;

export default TabDetails;
