import React from 'react';
import styled from 'styled-components';
import { ChatInput } from '../../shared-components/Chat/ChatInput';
// import { SmallHeading } from '../../shared-components/Texts';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN } from '../../../styles/constants/display';
// import { COLORS } from '../../../styles/constants/colors';

const MessageInput = ({ isChatBubble = false }) => {
  return (
    <StyledThreadInputDiv isChatBubble={isChatBubble}>
      {/* TODO */}
      {/**
       * <SmallHeading>
            <StyledSomeoneTypingText>
              {name ? `${name.split(' ')[0]} is typing...` : ''}
            </StyledSomeoneTypingText>
          </SmallHeading>
       */}
      <ChatInput type="personal" />
    </StyledThreadInputDiv>
  );
};

const StyledThreadInputDiv = styled.div`
  padding-top: 10px;
  justify-self: flex-end;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ isChatBubble }) => (isChatBubble ? '' : `${MARGIN(0, 10)};`)}
  }
`;

// const StyledSomeoneTypingText = styled.p`
//   @include small-heading-mixin;

//   ${MARGIN(5, 0)};
//   color: ${COLORS.ARGENT};
// `;

export default MessageInput;
