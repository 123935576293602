import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getBookedJobs,
  getEventById,
  getOtherJobsData,
  jobApplied,
  jobDetails,
  companyDetails,
} from '../../service/jobOpportunities';

export const getJobPercentage = createAsyncThunk(
  'jobs/getJobDetails',
  (jobResult) => {
    const { jobId } = jobResult;
    return jobDetails(jobId);
  },
);

export const getAnEvent = createAsyncThunk('event/getEvent', (id) => {
  return getEventById(id);
});

export const getJobs = createAsyncThunk(
  'jobs/getBookedJobs',
  ({
    eventId,
    page,
    limit,
    jobTypeFilter,
    engagementFilter,
    locationFilter,
    forceRefetch,
  }) => {
    return getBookedJobs(
      eventId,
      page,
      limit,
      jobTypeFilter,
      engagementFilter,
      locationFilter,
      forceRefetch,
    );
  },
);

export const getOtherJobs = createAsyncThunk(
  'jobs/getOtherJobs',
  ({ eventId, page, limit }) => {
    return getOtherJobsData(eventId, page, limit);
  },
);

export const applyToJob = createAsyncThunk('jobs/jobApplied', (body) => {
  return jobApplied(body);
});

export const getJobDetails = createAsyncThunk('jobs/getJobDetails', (jobId) => {
  return jobDetails(jobId);
});

export const getCompanyDetails = createAsyncThunk(
  'company/getDetails',
  (companyId) => {
    return companyDetails(companyId);
  },
);

export const jobOpportunitiesSlice = createSlice({
  name: 'jobOpportunites',
  initialState: {
    jobs: [],
    status: null,
  },
  extraReducers: {
    [getJobs.pending]: (state) => {
      state.status = 'Loading';
    },
    [getJobs.fulfilled]: (state, action) => {
      state.status = 'Fulfilled';
      state.jobs = action.payload;
    },
    [getJobs.rejected]: (state) => {
      state.status = 'Rejected';
    },
  },
});

export default jobOpportunitiesSlice.reducer;
