import React from 'react';
import { Card } from 'antd';
import UnderConstruction from '../assets/placeholders/under-construction.svg';
import RecoremLogo from '../assets/svg/recorem_color_logo.svg';

const CommingSoon = ({ history }) => {

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 100,
            }}
        >
            <div style={{ padding: 100 }}>
                <Card
                    hoverable
                    style={{ width: 400, boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)' }}
                    // title="Recorem Landing Page"
                    cover={<img style={{ padding: "20px 20px 0px 20px" }} alt="" src={RecoremLogo} />}
                >
                    <hr />
                    <div style={{ fontSize: "15px" }}>
                        <div>Thank you for checking us out!</div>
                        <div>We will be live in October. See you soon.</div>
                        <div>Cheers!</div>
                    </div>
                </Card>
            </div>
            <div style={{ height: 500, width: 500, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <img src={UnderConstruction} style={{ width: 500 }} alt="" />
            </div>
        </div>
    );
}

export default CommingSoon; 
