import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { MainWebsiteRoutes } from '../../constants/routes';

export const StyledButton = styled(Button)<any>`
  background-color: ${({ scrolled }) =>
    scrolled ? COLORS.MARKETING_BLUE : COLORS.WHITE};
  border: 2px solid
    ${({ scrolled }) => (scrolled ? COLORS.MARKETING_BLUE : COLORS.WHITE)};
  border-radius: 5px;
  padding: 20px 10px;
  width: 100px;
  height: 44px;
  ${DISPLAY_FLEX('row', 'center', 'center')}
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}px;` : '')}

  span {
    color: ${({ scrolled }) =>
      scrolled ? COLORS.WHITE : COLORS.MARKETING_BLUE};
    font-weight: ${FONT_WEIGHTS.BOLD};
    font-size: ${FONT[16]};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ scrolled }) =>
      scrolled ? COLORS.MARKETING_BLUE : COLORS.WHITE};
    border: 2px solid
      ${({ scrolled }) => (scrolled ? COLORS.MARKETING_BLUE : COLORS.BLACK)};
    span {
      color: ${({ scrolled }) =>
        scrolled ? COLORS.WHITE : COLORS.MARKETING_BLUE};
    }
  }
`;

export const StyledMenuItem = styled.li`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${DISPLAY_FLEX('row', 'center', 'center')}
  cursor: pointer;
  padding: 0 5px;
  font-size: ${FONT[16]};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[17]};
  }
`;

export const StyledArrow = styled(LeftOutlined)<any>`
  transform: rotate(-90deg);
  svg {
    width: 12px;

    path {
      stroke-width: 100px;
      stroke: ${({ scrolled }) => (scrolled ? COLORS.BLACK : COLORS.WHITE)};
      stroke-linejoin: round;
    }
  }
`;

const StyledMenuLinks = styled.a`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  cursor: pointer !important;
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[16]};
  }
`;

const StyledMenu = styled(Menu)<any>`
  top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;

  @media (min-width: ${BREAKPOINTS.TABLET_MIN}) {
    left: -18px;
    top: 23px;
  }

  li {
    padding: 10px 20px;
    a {
      color: ${COLORS.BLACK};
      font-weight: ${FONT_WEIGHTS.MEDIUM};
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: ${COLORS.WHITE};
  }

  .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-submenu-title > a:hover {
    color: ${COLORS.BLACK};
  }
`;

export const dropMenu = (scrolled: boolean = true) => (
  <StyledMenu scrolled={scrolled}>
    <Menu.Item key="0">
      <StyledMenuLinks href={MainWebsiteRoutes.organizer}>
        Event Managers
      </StyledMenuLinks>
    </Menu.Item>
    <Menu.Item key="1">
      <StyledMenuLinks href={MainWebsiteRoutes.recruiter}>
        Hiring Companies
      </StyledMenuLinks>
    </Menu.Item>
  </StyledMenu>
);
