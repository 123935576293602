import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { StatusBoxProps } from '../../interfaces/components/Common';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { SearchInputBox } from './Form/Form';
import { COLORS } from '../../styles/constants/colors';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { RightAlignContainer } from './RightAlignContainer';
import { MARGIN, PADDING } from '../../styles/constants/display';
import { getUserColorWithRoleId } from '../../styles/utils';
import { checkIfUserIsOrganizer } from '../../utils/userInfo';
import { OrganizerRoutes } from '../../constants/routes';
import { Link } from 'react-router-dom';
import { PrimaryButton } from './Button';
import { PlusOutlined } from '@ant-design/icons';

export const ShowMobile = styled.span`
  display: none;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block;
  }
`;

export const StatusBox: React.FC<StatusBoxProps> = ({
  bgColor,
  width,
  height,
  statusText,
}) => {
  if (statusText) {
    return (
      <StyledStatusContainer>
        <Styled bgColor={bgColor} width={width} height={height} />
        <StyledText color={bgColor}>{statusText}</StyledText>
      </StyledStatusContainer>
    );
  }

  return <Styled bgColor={bgColor} width={width} height={height} />;
};

const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledText = styled.span<{ color: String }>`
  color: ${({ color }) => `${color}`};
`;

const Styled = styled.div<{ bgColor: String; width: Number; height: Number }>`
  background-color: ${({ bgColor }) => `${bgColor}`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

export const StyledCalendarHeader: React.FC<any> = ({
  handleChange,
  roleId,
}) => {
  return (
    <div>
      <StyledHeaderContainer>
        <StyledCol xs={12} lg={12} xxl={6}>
          <StyledTitle
            color={getUserColorWithRoleId(roleId)}
          >
            Calendar
          </StyledTitle>
        </StyledCol>
        <Col xs={12} lg={12} xxl={18}>
          <RightAlignContainer>
            <StyledSearchInputWrapper>
              <SearchInputBox handleChange={handleChange} />
            </StyledSearchInputWrapper>

            {checkIfUserIsOrganizer(roleId) ? (
              <>
                <Link to={OrganizerRoutes.createEvent}>
                  <PrimaryButton
                    theme="organizer"
                    id="organizer_calendar_create_btn"
                    margin="0 0 0 10px"
                    padding="0 10px"
                    mobilePadding="0 4px"
                  >
                    <PlusOutlined /> Create Event
                  </PrimaryButton>
                </Link>
              </>
            ) : null}
          </RightAlignContainer>
        </Col>
      </StyledHeaderContainer>
    </div>
  );
};

const StyledTitle = styled.p`
  font-size: ${FONT[16]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${({color}) => color};
  width: 30%;
  margin: auto 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: ${FONT[18]};
  }
`;

const StyledSearchInputWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  position: relative;
  margin: 0 0 0 auto;

  input {
    border-color: ${COLORS.PHILLIPPINE_GRAY};
    font-weight: ${FONT_WEIGHTS.REGULAR};
    background: ${COLORS.LOTION};
    border-radius: 5px;
    margin-right: 20px;
  }
`;

const StyledHeaderContainer = styled(Row)`
  ${MARGIN(15, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${MARGIN(20, 0)}
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const StyledProfileWrapper = styled.div`
  ${PADDING(8, 0, 0, 0)}

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${PADDING(12, 0, 0, 0)}
  }
`;
