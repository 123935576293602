import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { Button } from 'antd';
import { COLORS } from '../../../styles/constants/colors';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';
import CapIcon from '../../../assets/svg/graduation-cap.svg';
import TimeIcon from '../../../assets/svg/material-access-time.svg';
import { TabSwitch } from '../../shared-components/Tabs';
import { MoneyIcon, PinIcon } from '../../svgicons';
import EmptyImg from '../../../assets/placeholders/placeholder.jpg';
import { getFileS3 } from '../../../utils/s3.storage';
import { getFormattedCurrency } from '../../../utils/currency';
import { LOCALES } from '../../../constants/currencyLocale';
import { getYearsAndMonths } from '../../../utils/common';
import { transformHtmlData } from '../../../utils/general';
import TabDetails from './TabDetails';
import {
  First90Days,
  FiveReasonsToJoin,
  InterviewProcess,
  JobDescription,
} from '../Icons/JobDetails';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { SocialShareIcons } from '../Share/SocialShareIcons';
import { ShareOverlay } from '../Share/ShareOverlay';
import ShareIcon from '../../../assets/svg/share-icon.svg';

const JobsCardDetails = ({
  jobDetails,
  inModal,
  handleApply,
  jobApplications = {},
}) => {
  // Formatting the currency
  const amountFrom = get(jobDetails, 'salaryFrom');
  const amountTo = get(jobDetails, 'salaryTo');
  const jobCurrency = get(jobDetails, 'currency');
  const locale = LOCALES[jobCurrency];
  const currencyCode = get(jobDetails, 'salaryCurrency.code');
  const currencySymbol = get(jobDetails, 'salaryCurrency.symbol');
  const formattedSalaryTo = getFormattedCurrency(
    currencyCode,
    currencySymbol,
    amountTo,
    locale,
  );
  const formattedSalaryFrom = getFormattedCurrency(
    currencyCode,
    currencySymbol,
    amountFrom,
    locale,
  );
  const jobId = get(jobDetails, 'id');

  const jobBrief = transformHtmlData(jobDetails, 'jobBrief');
  const fiveReasonsToJoin = transformHtmlData(
    jobDetails,
    'meta.fiveReasonsToJoin',
  );
  const interviewProcess = transformHtmlData(
    jobDetails,
    'meta.interviewProcess',
  );
  const first90Days = transformHtmlData(jobDetails, 'meta.first90Days');

  const items = [
    ...(first90Days
      ? [
          {
            key: 0,
            name: 'First 90 Days',
            component: <TabDetails data={first90Days} />,
            icon: <First90Days />,
          },
        ]
      : []),
    ...(fiveReasonsToJoin
      ? [
          {
            key: 1,
            name: '5 Reasons to Join',
            component: <TabDetails data={fiveReasonsToJoin} />,
            icon: <FiveReasonsToJoin />,
          },
        ]
      : []),
    {
      key: 2,
      name: 'Job Description',
      component: <TabDetails data={jobBrief} />,
      icon: <JobDescription />,
    },

    ...(interviewProcess
      ? [
          {
            key: 3,
            name: 'Interview Process',
            component: <TabDetails data={interviewProcess} />,
            icon: <InterviewProcess />,
          },
        ]
      : []),
  ];

  return jobDetails ? (
    <>
      <StyledJobDetailsContainer $inModal={inModal}>
        <StyledFlexHeader>
          <StyledImageContainer>
            <img
              src={getFileS3(get(jobDetails, 'company.logo', null)) || EmptyImg}
              alt="logo"
            />
          </StyledImageContainer>
          <StyledTitle>{get(jobDetails, 'company.name', '')}</StyledTitle>
          <ShareOverlay ShareIcons={<SocialShareIcons jobId={jobId} />}>
            <StyledLogo src={ShareIcon} alt="share Icon" />
          </ShareOverlay>
        </StyledFlexHeader>
        <StyledJobSubtitle>
          {' '}
          {get(jobDetails, 'designation', '') + ' - '}
          <span>
            {get(jobDetails, 'jobType', [])
              .map((type) => (type === '0' ? 'Part time' : 'Full time'))
              .join(' | ')}
          </span>
        </StyledJobSubtitle>
        {/* overview Section */}
        <StyledOverviewContainer>
          <StyledFlexContainer data-icon="customIcon">
            <MoneyIcon />
            <StyledBoldText>
              {get(jobDetails, 'salaryTo')
                ? `${
                    jobDetails.salaryFrom ? `${formattedSalaryFrom} - ` : ''
                  }${formattedSalaryTo}`
                : `Not Specified`}
            </StyledBoldText>
          </StyledFlexContainer>
          <StyledFlexContainer data-icon="customIcon">
            <PinIcon />
            <StyledBoldText>
              {get(jobDetails, 'jobLocationType', null) === 1
                ? 'Remote'
                : get(jobDetails, 'location', '')}{' '}
            </StyledBoldText>
          </StyledFlexContainer>
          <StyledFlexContainer>
            <StyledIcon src={CapIcon} alt="qualification-icon" />
            <StyledBoldText>
              {' '}
              {get(jobDetails, 'minQualification')}
            </StyledBoldText>
          </StyledFlexContainer>

          {get(jobDetails, 'minExperience', null) ? (
            <StyledFlexContainer>
              <StyledIcon src={TimeIcon} alt="experience-icon" />
              <StyledBoldText>{getYearsAndMonths(jobDetails)}</StyledBoldText>
            </StyledFlexContainer>
          ) : null}
        </StyledOverviewContainer>
        {/* Tabs Section */}
        <StyledTabsContainer>
          <TabSwitch
            defaultActiveKey="1"
            items={items}
            fullWidthInkbar={true}
          />
        </StyledTabsContainer>
        {/* ApplyButton */}
        <StyledApplyButton
          type="primary"
          size="large"
          onClick={handleApply}
          disabled={jobApplications[jobDetails.id]}
        >
          {jobApplications[jobDetails.id] ? 'Applied' : 'Apply'}
        </StyledApplyButton>
      </StyledJobDetailsContainer>
    </>
  ) : null;
};

export default JobsCardDetails;

const StyledJobDetailsContainer = styled.div`
  background-color: ${COLORS.WHITE};
  ${({ $inModal }) =>
    $inModal
      ? ''
      : `
  border: 1px solid ${COLORS.LIGHT_GRAY_2};
  border-radius : 18px;
  ${PADDING(22)}
  `}
`;
const StyledImageContainer = styled.div`
  max-height: 60px;
  max-width: 60px;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  background-color: ${COLORS.ANTI_FLASH_WHITE};
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: contain;
  }
`;

const StyledLogo = styled.img`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 20px;
    width: 20px;
  }
`;

const StyledTitle = styled.h3`
  font-size: ${FONT[22]};
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(0)};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 17px;
  }
`;

const StyledFlexHeader = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', 'center')};
  column-gap: 20px;
`;
const StyledJobSubtitle = styled.p`
  font-size: ${FONT[18]};
  font-weight: ${FONT_WEIGHTS.BOLD};
  ${MARGIN(35, 0, 35, 0)};

  > span {
    color: ${COLORS.SLATE_BLUE};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    &,
    & > span {
      font-size: 15px;
    }
  }
`;
const StyledOverviewContainer = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', 'center')};
  flex-wrap: wrap;
  column-gap: 36px;
  row-gap: 25px;
`;

const StyledFlexContainer = styled.div`
  ${DISPLAY_FLEX('row', 'flex-start', 'center')};
  gap: 8px;
  &[data-icon='customIcon'] {
    svg {
      transform: scale(1.5);
    }
  }
`;

const StyledBoldText = styled.p`
  font-size: ${FONT[15]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;
const StyledIcon = styled.img`
  height: 18px;
  width: auto;
`;
const StyledTabsContainer = styled.div`
  ${MARGIN(30, 0, 0, 0)};

  .ant-tabs-tab {
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    color: ${COLORS.GRANITE_GRAY};
    svg {
      transition: all 0.3s ease;
      fill: ${COLORS.GRANITE_GRAY};
    }
    &:hover {
      color: ${COLORS.DODGER_BLUE} !important;
      svg {
        fill: ${COLORS.DODGER_BLUE};
      }
    }
  }
  .ant-tabs-nav {
    margin-left: 0 !important;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.DODGER_BLUE} !important;
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.DODGER_BLUE} !important;
  }
  .ant-tabs-nav:before {
    border-bottom-color: ${COLORS.SILVER_SAND};
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;
const StyledApplyButton = styled(Button)`
  border-radius: 9px;
  background-color: ${COLORS.BLUE_VIOLET_2};
  border-color: ${COLORS.BLUE_VIOLET_2};
  ${PADDING(0, 32, 0, 32)};
  ${MARGIN(34, 0, 0, 0)};

  &:focus {
    background-color: ${COLORS.HELIOTROPE};
    border-color: ${COLORS.HELIOTROPE};
  }
  &:hover {
    background-color: ${COLORS.HELIOTROPE};
    border-color: ${COLORS.HELIOTROPE};
  }
  > span {
    font-size: ${FONT[15]};
  }
`;
