/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Table, Tooltip } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserIcon } from '../../../components/svgicons';
import { Modal } from '../../../components/shared-components/Modal';
import { getPreScreenJobsApplicationsData } from '../../../store/features/analytics';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../../../constants';
import { FONT_WEIGHTS } from '../../../styles/constants/typography';
import { getSerialNumber, serialNoColumn } from '../../../utils/common';
import { AdminRoutes } from '../../../constants/routes';
import {
  DISPLAY_FLEX,
  MARGIN,
  PADDING,
} from '../../../styles/constants/display';
import { COLORS } from '../../../styles/constants/colors';

export const JobsPreScreenApplications = () => {
  const history = useHistory();
  const { preScreenJobsApplications } = useSelector(
    (state: any) => state.analytics.masterAdminDashboardAnalytics,
  );
  const { data } = preScreenJobsApplications || {};
  const [dataSource, setDataSource] = useState([]);
  const [modalDataSource, setModalDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPreScreenJobsApplicationsData(current, pageSize));
  }, [current, pageSize, dispatch]);

  useEffect(() => {
    const renderData = () => {
      if (current === 1) {
        setDataSource(
          data?.jobs?.map((item: any, rowId: any) => ({
            serialNo: getSerialNumber(current, pageSize, rowId),
            candidate: get(item, 'name'),
            preScreeningApplications: get(
              item,
              'preScreeningRoundJobApplications',
            ),
            candidateId: get(item, 'userId'),
          })),
        );
      }
      setModalDataSource(
        data?.jobs?.map((item: any, rowId: any) => ({
          serialNo: getSerialNumber(current, pageSize, rowId),
          candidate: get(item, 'name'),
          preScreeningApplications: get(
            item,
            'preScreeningRoundJobApplications',
          ),
          candidateId: get(item, 'userId'),
        })),
      );
    };

    renderData();
  }, [data, current, pageSize]);

  const columns = [
    { ...serialNoColumn },
    {
      title: 'Candidate',
      dataIndex: 'candidate',
      key: 'candidate',
    },
    {
      title: 'Pre-Screening Applications',
      dataIndex: 'preScreeningApplications',
      key: 'preScreeningApplications',
    },
  ];

  const handleRowClick = (record: any) => {
    // eslint-disable-next-line dot-notation
    const id = record['candidateId'];
    history.push(`${AdminRoutes.candidate.candidateOverview}/${id}`);
  };

  const handleModalClose = () => {
    setVisible(false);
  };

  return (
    <>
      <StyledSection onClick={() => setVisible(true)}>
        <div>
          <StyledIcon>
            <UserIcon />{' '}
          </StyledIcon>
          <h1>Candidates in Pre-Screening Round - {data.totalItems}</h1>
          <Tooltip title="Candidates in live event currently in the pre-screening round who needs to be reviewed.">
            <StyledEyeInvisibleOutlined />
          </Tooltip>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource?.slice(0, 3)}
          pagination={false}
          scroll={{ x: true }}
        />
      </StyledSection>

      <Modal
        title={`Candidates in Pre-Screening Round - ${data.totalItems}`}
        width="80%"
        visible={visible}
        onCancel={handleModalClose}
        footer={null}
      >
        <CustomTable
          columns={columns}
          dataSource={modalDataSource}
          pagination={{ pageSize, total: data.totalItems, current }}
          onChange={(e: any) => {
            setCurrent(e.current);
            setPageSize(e.pageSize);
          }}
          scroll={{ x: true }}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
        />
      </Modal>
    </>
  );
};

const CustomTable = styled(Table)`
  .ant-table table {
    cursor: pointer;
  }
`;

const StyledSection = styled.div`
  cursor: pointer;
  h1 {
    ${MARGIN(null, null, 20, null)}
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  }

  & > div:first-child {
    display: flex;
    gap: 7px;
  }
`;

const StyledEyeInvisibleOutlined = styled(EyeInvisibleOutlined)`
  ${PADDING(2.5, null, null, null)}
`;

const StyledIcon = styled.div`
  ${DISPLAY_FLEX('row', null, 'flex-start')}
  gap: 7px;
  ${MARGIN(3, null, null, null)}

  svg {
    fill: ${COLORS.BLUE_VIOLET};
    width: 18.5px;
    height: 18.5px;
  }
`;
