import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import isNumber from 'lodash/isNumber';
const LoaderIcon = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <StyledLoader
      width={width}
      height={height}
      className="loader-spinner"
    ></StyledLoader>
  );
};

export default LoaderIcon;

const ldsRing = keyframes`    
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const StyledLoader = styled.span<{
  width?: string | number;
  height?: string | number;
}>`
  width: ${({ width }) =>
    width ? (isNumber(width) ? `${width}px` : width) : '30px'};
  height: ${({ height }) =>
    height ? (isNumber(height) ? `${height}px` : height) : '30px'};
  border: 3px solid ${COLORS.GREEN_TONE_2};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${ldsRing} 1s linear infinite;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;
