/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { Steps, Row, Col, Button, Form } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { DELETE, EDIT, ON_SELECT_FROM_ROW, SKILLSTEPS } from '../../constants';
import { candidateSkillLevelConvert } from '../../utils/profile';
import { searchSkills } from '../../service/search';
import { requestNewSkill } from '../../service/requestSkill';
import Notification from '../Notification/Notification';
import ContributeSkillModal from '../Common/ContributeSkillModal';
import { AutoCompleteBox } from '../shared-components/Form/Form';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { SecondaryButton } from '../shared-components/Button';

// Store current contribute index
let currentContributeIndex = 0;

const CreateSkillsSetRow = ({
  skills,
  setSkills,
  skillStep,
  setSkillStep,
  requestSkill,
  setRequestSkill,
  skillsList,
  setSkillsList,
  contributeOption,
  setContributeOption,
  skillInputWidth = null,
  skillInputMobileWidth = null,
  setSkillsUpdated,
  isApplyModal,
  recommendedSkills,
  handleOnSelect,
  personDetailModal = false,
}) => {
  const [contributedList, setContributedList] = useState([]);
  const [isContributeModalOpen, setIsContributeModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [skillName, setSkillName] = useState('');
  const [skillClicked, setSkillClicked] = useState(true);


  const SearchSkillsHandler = useCallback(
    debounce(async (i, value, localSkillsList, localContributeOption) => {
      setSkillName(value);
      const response = await searchSkills(value);
      const skillsListTemp = get(response, 'data.payload.skills', []);
      const data = skillsListTemp.map((skill) => {
        return { id: skill.id, value: skill.skill, label: skill.skill };
      });

      const temp2 = [...localContributeOption];
      temp2[i] = 1;
      setContributeOption(temp2);

      const temp = [...localSkillsList];
      temp[i] = {
        id: i,
        options: data,
      };
      setSkillsList(temp);
    }, 500),
    [],
  );

  const handleRemoveSkillsSetRow = (i) => {
    const temp = [...skills];
    temp[i] = {
      ...skills[i],
      operation: DELETE,
    };
    const cont = [...contributeOption];
    cont[i] = 0;
    setContributeOption(cont);
    setSkills(temp);

    const contributeList = [...contributedList];
    const index = contributeList.indexOf(i);
    contributeList.splice(index, 1);
    setContributedList(contributeList);
  };

  const handleSkillChange = (i, val) => {
    if (val.length > 1) {
      SearchSkillsHandler(i, val, skillsList, contributeOption);
    }
    if (!val.length) {
      const temp = [...contributeOption];
      temp[i] = 0;
      setContributeOption(temp);

      const tempSkills = [...skills];
      tempSkills[i] = {
        ...skills[i],
        skill: { ...skills[i].skill, skill: '' },
      };
      setSkills(tempSkills);
    }

    const temp = [...requestSkill];
    temp[i] = {
      ...requestSkill[i],
      id: i,
      newSkill: {
        skill: val,
        category: val,
        description: val,
      },
    };
    setRequestSkill(temp);
  };

  const handleSkillOnSelect = (i, val) => {
    if (val && !isEmpty(val)) {
      const tempSkillsArr = [...skills];
      tempSkillsArr[i] = {
        ...skills[i],
        // id: i,
        skill: {
          id: val.id,
          skill: val.value,
        },
        level: candidateSkillLevelConvert(skillStep),
      };
      setSkills(tempSkillsArr);
      if (setSkillsUpdated) {
        setSkillsUpdated(true);
      }
      const skillSize = tempSkillsArr.filter(
        (skill) =>
          get(skill, 'operation', DELETE) !== DELETE,
      );
      if (skillSize.length <= 4) {
        handleOnSelect(tempSkillsArr, ON_SELECT_FROM_ROW);
        setSkillClicked(true)
      }
    }
  };

  const handleSkillLevelChange = (i, val) => {
    if (isNumber(val)) {
      setSkillStep((prev) => {
        const temp2 = [...prev];
        temp2[i] = {
          id: i,
          value: val,
        };
        return temp2;
      });
      let tempSkillsArr = [...skills];
      tempSkillsArr[i] = {
        ...skills[i],
        // id: i,
        level: candidateSkillLevelConvert(val),
      };
      setSkills(tempSkillsArr);
      if (setSkillsUpdated) {
        setSkillsUpdated(true);
      }
    }
  };

  const openContributeModal = (index) => {
    currentContributeIndex = index;
    setIsContributeModalOpen(true);
  };

  const closeOpenContributeModal = () => {
    setIsContributeModalOpen(false);
  };

  const handleRequestNewSkill = async () => {
    const formFieldsData = await form.getFieldsValue();
    const { category, description } = formFieldsData;

    const skill = await get(
      requestSkill,
      `${[currentContributeIndex]}.newSkill.skill`,
      '',
    );

    const payload = {
      skill,
      category,
      description,
    };

    requestNewSkill(payload)
      .then((res) => {
        const payload = get(res, 'data.payload', null);
        if (payload) {
          const newSkill = payload;
          const temp = [...skills];
          temp[currentContributeIndex] = {
            ...skills[currentContributeIndex],
            id: currentContributeIndex,
            skill: {
              id: newSkill.id,
              skill: newSkill.skill,
            },
            level: candidateSkillLevelConvert(skillStep),
          };
          setSkills(temp);
          Notification(
            'success',
            'Succes',
            `You successfully contributed ${get(
              requestSkill[currentContributeIndex],
              'newSkill.skill',
              '',
            )} skill.`,
          );
        }
        setContributedList((prevList) => [...prevList, currentContributeIndex]);
        form.resetFields();
        closeOpenContributeModal();
      })
      .catch(() => {
        Notification('error', 'Failed', 'Something went wrong.');
      });
  };

  const isContributedIn = (index) => {
    const res = contributedList.find((itemIndex) => itemIndex === index);
    return res >= 0;
  };

  const handleOnSkillBlur = async (i) => {
    const skillList = [...skillsList];
    const skillOptions = get(skillList[i], 'options', []);

    const matchOption = skillOptions.filter((option) => {
      return option.value.toLowerCase() === skillName.toLowerCase();
    })[0];
    if (matchOption) {
      const temp = [...skills];
      temp[i] = {
        ...skills[i],
        skill: {
          id: matchOption.id,
          skill: matchOption.value,
        },
        level: candidateSkillLevelConvert(get(skillStep[i], 'value', 1)),
      };
      setSkills(temp);
    }
  };

  function showRecommendedSkills(idx, skill) {
    if (idx !== 0 && Object.values(skill?.skill).includes('')) {
      return recommendedSkills.map((skill) => {
        const value = {
          id: skill.relatedSkill.id,
          value: skill.relatedSkill.skill,
          label: skill.relatedSkill.skill,
        };
        return (
          <SecondaryButton
            onClick={() => {
              handleSkillChange(idx, value.value);
              handleSkillOnSelect(idx, value);
            }}
            theme="candidate"
          >
            {skill.relatedSkill.skill}
          </SecondaryButton>
        );
      });
    }
  }

  React.useEffect(() => {
    if (personDetailModal) {
      setSkillClicked(false);
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    const input = document.getElementById(`skill-${skills.length - 1}`);
    if (input && skillClicked) {
      input.focus();
      setSkillClicked(false);
    }
  }, [skills, skillClicked]);

  return (skills || [])
    .map((skill, idx) => {
      const name = get(skill, 'skill.skill', get(skill, 'skill', ''));
      const options = get(skillsList[idx], 'options', null);

      return skill.operation !== DELETE ? (
        <>
          <StyledSkillLineRow key={idx} isApplyModal={isApplyModal}>
            <Col xs={24}>
              <StyledWrapperRow>
                <StyledSkillNameCol
                  xs={skillInputMobileWidth || 20}
                  md={skillInputWidth || 10}
                  isApplyModal={isApplyModal}
                >
                  <AutoCompleteBox
                    name="name"
                    placeholder={name ? name : `Select...`}
                    disabled={name && skill.operation === EDIT ? true : false}
                    text
                    defaultValue={name}
                    key={name}
                    options={options}
                    handleChange={(val) => handleSkillChange(idx, val)}
                    handleSelect={(val, option) =>
                      handleSkillOnSelect(idx, option)
                    }
                    formField={false}
                    onBlur={() => handleOnSkillBlur(idx)}
                    required
                    id={`skill-${idx}`}
                  />
                  <StyledSkillsButton>
                    {recommendedSkills && recommendedSkills.length
                      ? showRecommendedSkills(idx, skill)
                      : null}
                  </StyledSkillsButton>
                  {!(options && options.length) &&
                    contributeOption[idx] === 1 ? (
                    <StyledNoMatchText>
                      No match found,
                      {isContributedIn(idx) ? (
                        <StyledPurpleColorText>
                          Contributed.
                        </StyledPurpleColorText>
                      ) : (
                        <Button onClick={() => openContributeModal(idx)}>
                          Like to contribute?
                        </Button>
                      )}
                    </StyledNoMatchText>
                  ) : null}
                </StyledSkillNameCol>
                <StyledShowMobileSkillRemoveCol xs={4} flex="20px">
                  <StyledMinusCircle
                    onClick={() => handleRemoveSkillsSetRow(idx)}
                  />
                </StyledShowMobileSkillRemoveCol>
                <StyledSkillValueCol xs={24} md={isApplyModal ? 12 : 10}>
                  <Steps
                    size="small"
                    current={get(skillStep[idx], 'value', 0)}
                    labelPlacement="vertical"
                    onChange={(val) => handleSkillLevelChange(idx, val)}
                    key={idx}
                  >
                    {SKILLSTEPS.map((stepValue) => (
                      <Steps.Step title={stepValue} />
                    ))}
                  </Steps>
                </StyledSkillValueCol>
                <StyledHiddenMobileSkillRemoveCol xs={4} flex="20px">
                  <StyledMinusCircle
                    onClick={() => handleRemoveSkillsSetRow(idx)}
                  />
                </StyledHiddenMobileSkillRemoveCol>
              </StyledWrapperRow>
            </Col>
          </StyledSkillLineRow>
          {isContributeModalOpen ? (
            <ContributeSkillModal
              isContributeModalOpen={isContributeModalOpen}
              closeOpenContributeModal={closeOpenContributeModal}
              form={form}
              handleRequestNewSkill={handleRequestNewSkill}
            />
          ) : null}
        </>
      ) : null;
    })
    .reverse();
};

const StyledSkillsButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: hidden;
  max-height: 90px;

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    max-height: 75px;
  }

  & > button {
    flex: 1;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const StyledSkillLineRow = styled(Row)`
  ${({ isApplyModal }) =>
    isApplyModal ? `${MARGIN(10, 0, 16, 0)};` : `${MARGIN(20, 0, 30, 0)};`}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${({ isApplyModal }) =>
    isApplyModal ? `${MARGIN(8, 0, 28, 0)};` : `${MARGIN(20, 0, 30, 0)};`}
  }
`;

const StyledWrapperRow = styled(Row)`
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 25px;
  }
`;

const StyledSkillNameCol = styled(Col)`
  margin-bottom: 30px;
  width: 410px;

  @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;

    ${({ isApplyModal }) =>
    isApplyModal ? ` margin-bottom: 12px;` : `margin-bottom: 30px;`}
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: ${COLORS.DARK_ANONYMOUS};
    background: ${COLORS.BRIGHT_GRAY};
  }
`;

const StyledNoMatchText = styled.p`
  margin-top: 15px;
`;

const StyledPurpleColorText = styled.span`
  color: ${COLORS.KIMBERLY};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin-bottom: 30px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledShowMobileSkillRemoveCol = styled(Col)`
  display: none !important;
  text-align: center;
  font-size: 20px;
  margin-left: 30px;
  margin-top: -10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: block !important;
    margin-top: -7px;
    margin-left: 15px;
    width: 10px;
  }

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) and (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_2}) {
    margin-left: 70px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_2}) and (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
    margin-left: -30px;
  }
`;

const StyledMinusCircle = styled(MinusCircleOutlined)`
  margin-top: 9px;
  color: ${COLORS.ORANGE_RED};

  @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-right: 300px;
  }

  svg {
    font-size: 26px;
  }
`;

const StyledSkillValueCol = styled(Col)`
  ${DISPLAY_FLEX(null, 'space-between', 'center')};
  width: 500px;
  text-align: left;
  position: relative;
  top: 6px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: -20px;
    margin-right: auto;
    width: auto;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_6}) {
    margin-left: -32px;
  }
`;

const StyledHiddenMobileSkillRemoveCol = styled(Col)`
  position: relative;
  top: 5px;
  text-align: center;
  font-size: 20px;
  margin-left: 30px;
  margin-top: -10px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none !important;
    margin-top: 0;
    margin-left: 15px;
    width: 10px;
  }

  @media (min-width: ${BREAKPOINTS.LAPTOP_MIN}) and (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_2}) {
    margin-left: 70px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_2}) and (max-width: ${BREAKPOINTS.TABLET_SPECIAL_CASE_1}) {
    margin-left: -30px;
  }
`;

export default CreateSkillsSetRow;
