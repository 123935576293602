import React, { useState } from 'react';
import { Row, Col } from 'antd';
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import {
  SearchInputBox,
  DropdownBox,
} from '../../../components/shared-components/Form/Form';
import {
  eventJoinFilterOptions,
  eventRegistrationFilterOptions,
  profileCompletionFilterOptions,
} from '../../../utils/admin/event';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import { PrimaryButton } from '../../../components/shared-components/Button';
import { getUserColorWithRoleId } from '../../../styles/utils';
import { ADMIN } from '../../../constants';
import { LargeFont } from '../../../components/shared-components/Texts';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';

export const InvitedAttendeesTableHeader = ({
  searchPlaceHolder,
  handleSearch,
  handleExport,
  handleProfileFilterChange,
  handleEventRegistrationFilterChange,
  handleEventJoinFilterChange,
}) => {
  const [isExporting, setIsExporting] = useState(false);
  return (
    <>
      <StyledFiltersRow marginTop={15}>
        <Col md={7}>
          <Styledlabel htmlFor="filter_by_profile_completion">
            <LargeFont color={COLORS.ARSENIC}>
              Filter by profile completion
            </LargeFont>
          </Styledlabel>
          <DropdownBox
            placeholder="Filter by profile completion"
            options={profileCompletionFilterOptions}
            handleChange={handleProfileFilterChange}
            showArrow
            id="filter_by_profile_completion"
          />
        </Col>
        <Col md={7}>
          <Styledlabel htmlFor="filter_by_booking_completion">
            <LargeFont color={COLORS.ARSENIC}>
              Filter by booking completion
            </LargeFont>
          </Styledlabel>
          <DropdownBox
            placeholder="filter by booking completion"
            options={eventRegistrationFilterOptions}
            handleChange={handleEventRegistrationFilterChange}
            showArrow
            id="filter_by_booking_completion"
          />
        </Col>
        <Col md={7}>
          <Styledlabel htmlFor="id='filter_by_join_event'">
            <LargeFont color={COLORS.ARSENIC}>Filter by Join event</LargeFont>
          </Styledlabel>
          <DropdownBox
            placeholder="Filter by join event"
            options={eventJoinFilterOptions}
            handleChange={handleEventJoinFilterChange}
            showArrow
            id="filter_by_join_event"
          />
        </Col>
      </StyledFiltersRow>
      <StyledActionsRow marginTop={20}>
        <Col md={8}>
          <SearchInputBox
            placeholder={searchPlaceHolder}
            handleChange={handleSearch}
          />
        </Col>
        <Col offset={1}>
          <PrimaryButton
            color={getUserColorWithRoleId(ADMIN)}
            onClick={() => handleExport(setIsExporting)}
            disabled={isExporting}
          >
            {isExporting ? (
              <LoadingOutlined spin />
            ) : (
              <VerticalAlignBottomOutlined />
            )}
            Export CSV
          </PrimaryButton>
        </Col>
      </StyledActionsRow>
    </>
  );
};

const StyledFiltersRow = styled(Row)`
  ${DISPLAY_FLEX('row', 'space-between', 'flex-end')};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    justify-content: flex-start;

    div {
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }

  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : '')}
`;

const StyledActionsRow = styled(Row)`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')}
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : '')}

  margin-bottom: 20px;

  @media (max-width: 372px) {
    margin-bottom: 10px;

    div:nth-child(1) {
      margin-bottom: 8px;
    }
  }
`;

const Styledlabel = styled.label`
  margin-bottom: 8px;
  display: inline-block;
`;
