import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from 'antd';
import { DragOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import styled from 'styled-components';
import first from 'lodash/first';
import CancelIcon from '../../assets/svg/cancel.svg';
// import { MinimizeIcon, ExpandIcon } from '../svgicons';
import { isLaptop, isMobile } from '../../helpers/display';
import PlayListIcon from '../../assets/svg/playList.svg';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { PIP_POSITION, PIP_WIDTH } from '../../constants';
import { PIPExtendedProps } from '../../interfaces/components/pip';
import { MARGIN } from '../../styles/constants/display';
import LinkSwitchModal from '../Broadcast/LinkSwitchModal';

const PIPWindow: React.FC = () => {
  const iframe = useRef(null);
  const containerElement = useRef(null);
  const closeIcon = useRef<HTMLImageElement>(null);

  const [position, setPosition] = useState({
    x: PIP_POSITION.X,
    y: PIP_POSITION.Y,
  });
  const [isInitialPostion, setInitialPosition] = useState(true);
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState(false);
  let relativePosition = { x: PIP_POSITION.X, y: PIP_POSITION.Y };
  const dragIcon = useRef<HTMLSpanElement>(null);
  const isLaptopScreen = isLaptop();
  const isMobileScreen = isMobile();

  /*const handelExpandIconClick = () => {
    setIsExpanded(true);

    if (closeIcon.current) {
      // @ts-ignore
      closeIcon.current.style.display = 'none';
    }
  };

  const handelMinimizeIconClick = () => {
    setIsExpanded(false);

    if (closeIcon.current) {
      // @ts-ignore
      closeIcon.current.style.display = 'block';
    }
  };
*/
  const calculatePosition = async () => {
    const winHeight = window.screen.height;
    if (isLaptopScreen) {
      setPosition({
        x: PIP_POSITION.X,
        y: winHeight - PIP_POSITION.WIDTH_LAPTOP,
      });
      return;
    }
    if (isMobileScreen) {
      setPosition({
        x: PIP_POSITION.X,
        y: winHeight - PIP_POSITION.WIDTH_MOBILE,
      });
      return;
    }
    setPosition({
      x: PIP_POSITION.X,
      y: winHeight - PIP_POSITION.WIDTH_DESKTOP,
    });
  };

  useEffect(() => {
    calculatePosition();
    setInitialPosition(true);

    // eslint-disable-next-line
  }, []);

  const getPipWidth = () => {
    if (isLaptopScreen) {
      return PIP_WIDTH.LAPTOP;
    }
    if (!isMobileScreen) {
      return PIP_WIDTH.DESKTOP;
    }
    const mobileWidth = get(window, 'screen.width', 0) * 0.7;

    return mobileWidth > PIP_WIDTH.MOBILE ? PIP_WIDTH.MOBILE : mobileWidth;
  };

  const onMove = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (isInitialPostion) {
      setInitialPosition(false);
    }
    const touchPositionObject = first(get(e, 'touches', []));
    const pageX =
      e.type === 'touchmove'
        ? get(touchPositionObject, 'pageX', 0)
        : get(e, 'pageX', 0);
    const pageY =
      e.type === 'touchmove'
        ? get(touchPositionObject, 'pageY', 0)
        : get(e, 'pageY', 0);

    let newX = pageX - get(relativePosition, 'x', 0);
    let newY = pageY - get(relativePosition, 'y', 0);
    const pipWidth = getPipWidth();
    const maxX = get(window, 'screen.width', 0) - pipWidth - 20;
    const maxY = get(window, 'screen.height', 0) - (isMobileScreen ? 0 : 180);
    if (newX < 0) {
      newX = 0;
    }
    if (newY < 0) {
      newY = 0;
    }
    if (newY > maxY) {
      newY = maxY;
    }
    if (newX > maxX) {
      newX = maxX;
    }

    setPosition({
      x: newX,
      y: newY,
    });
  };

  const onEnd = (e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    e.preventDefault();
    document.body.style.overflow = 'auto';
    document.removeEventListener('mousemove', onMove, true);
    document.removeEventListener('mouseup', onEnd, true);
    document.removeEventListener('touchmove', onMove, true);
    document.removeEventListener('touchend', onEnd, true);
  };

  const getCurrentPosition = () => ({
    left: get(containerElement, 'current.offsetLeft', PIP_POSITION.X),
    top: get(containerElement, 'current.offsetTop', PIP_POSITION.Y),
  });

  const onStart: React.MouseEventHandler<HTMLSpanElement> | undefined = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.button !== 0) return;
    const currenctPosition = getCurrentPosition();
    relativePosition = {
      x: e.pageX - currenctPosition.left,
      y: e.pageY - currenctPosition.top,
    };
    document.addEventListener('mousemove', onMove, true);
    document.addEventListener('mouseup', onEnd, true);
  };

  const onTouchStart: React.TouchEventHandler<HTMLSpanElement> | undefined = (
    e,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    const pageX = get(first(e.touches), 'pageX', 0);
    const pageY = get(first(e.touches), 'pageY', 0);

    const currenctPosition = getCurrentPosition();
    relativePosition = {
      x: pageX - currenctPosition.left,
      y: pageY - currenctPosition.top,
    };
    document.body.style.overflow = 'hidden';
    document.addEventListener('touchmove', onMove, true);
    document.addEventListener('touchend', onEnd, true);
  };

  const getPostionFromTop = () => {
    if (isInitialPostion) {
      return 'unset';
    }
    return `${get(position, 'y', 0)}px`;
  };

  const getPostionFromLeft = () => {
    return `${get(position, 'x', 0)}px`;
  };

  const getPostionFromBottom = () => {
    if (isInitialPostion) {
      return `${PIP_POSITION.Y}px`;
    }

    return `unset`;
  };

  const styles: React.CSSProperties = {
    position: 'fixed',
    left: getPostionFromLeft(),
    top: getPostionFromTop(),
    bottom: getPostionFromBottom(),
    display: 'none',
  };

  const onClose: React.MouseEventHandler<HTMLImageElement> | undefined = (
    e,
  ) => {
    e.preventDefault();
    if (containerElement.current) {
      // @ts-ignore
      containerElement.current.style.display = 'none';
    }
    if (iframe.current) {
      // @ts-ignore
      iframe.current.src = '';
    }

    let iframeNode: any = document.getElementById('live-event-iframe');
    const iframeParent: any = document.getElementById(
      'live-event-iframe-wrapper',
    );

    if (!iframeNode) {
      iframeNode = document.createElement('iframe');
      iframeNode.id = 'live-event-iframe';

      if (iframeParent.firstChild.id === 'live-event-video-iframe') {
        iframeParent.firstChild.remove();
      }
      iframeParent.prepend(iframeNode);
    }
  };

  return (
    <StyledWrapper
      id="live-event"
      ref={containerElement}
      isFullscreen={false}
      style={styles}
    >
      <CloseIcon
        ref={closeIcon}
        src={CancelIcon}
        onClick={onClose}
        id="live-event-close-icon"
        alt="close-icon"
      />

      <StyledDraggedIcon
        onTouchStart={onTouchStart}
        onMouseDown={onStart}
        id="live-event-close-icon"
        ref={dragIcon}
        isFullscreen={false}
      />
      <StyledTootip title="Switch Between different Streams">
        <LinkSwitch
          alt="playList"
          src={PlayListIcon}
          isFullscreen={false}
          onClick={() => setIsSwitchModalOpen(true)}
        />
      </StyledTootip>

      <StyledIframeContainer
        id="live-event-iframe-wrapper"
        isFullscreen={false}
      >
        <StyledIframe
          isFullscreen={false}
          id="live-event-iframe"
          ref={iframe}
          frameBorder="0"
          title="live-event"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <EventDetails id="live-event-id" />

        {/* isExpanded ? (
          <StyledMinimizeIcon handelClick={handelMinimizeIconClick} />
        ) : (
          <StyledMaximizeIcon handelClick={handelExpandIconClick} />
        )*/}
        {/* isExpanded ? (
          <Tooltip title="Switch Between different Streams">
            <ExpendedLinkSwitch
              alt="playList"
              src={PlayListIcon}
              onClick={() => setIsSwitchModalOpen(true)}
            />
          </Tooltip>
        ) : null */}
      </StyledIframeContainer>
      {isSwitchModalOpen ? (
        <LinkSwitchModal
          visible={isSwitchModalOpen}
          handleClose={() => setIsSwitchModalOpen(false)}
        />
      ) : null}
    </StyledWrapper>
  );
};

export default PIPWindow;

export const StyledWrapper = styled.div<PIPExtendedProps>`
  bottom: 10px;
  z-index: 999;
  position: fixed;
  left: 10px;
  display: none;

  ${(props) =>
    props.isFullscreen
      ? `
      width: 100vw;
      height: 100vh;
      `
      : ''}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    left: 10px;

    ${(props) => {
      return props.isFullscreen
        ? `
        max-width:unset;
        width:100%
          `
        : `
        width: 70%;
        max-width: 340px;
        `;
    }}
  }
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;

  ${MARGIN(null, null, 5, 5)}

  &:hover {
    cursor: pointer;
  }
`;

const StyledDraggedIcon = styled(DragOutlined)<PIPExtendedProps>`
  position: absolute;
  top: 0;
  right: 5px;
  cursor: move;
  ${MARGIN(null, null, 5, 5)}

  svg {
    width: 20px;
    height: 20px;
    cursor: move;
  }

  ${(props) => {
    return props.isFullscreen
      ? `
       display: none;
      `
      : `display: block;`;
  }}
`;

const LinkSwitch = styled.img<PIPExtendedProps>`
  position: absolute;
  top: -3px;
  right: 35px;
  ${MARGIN(null, null, 5, 5)}

  ${(props) => {
    return props.isFullscreen
      ? `
      display: none;
      `
      : 'display: block;';
  }}
`;

const StyledIframeContainer = styled.div<PIPExtendedProps>`
  position: relative;
  background-color: ${COLORS.BLACK};
  border-radius: 8px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${(props) => {
      return props.isFullscreen
        ? `
          width: 100vw;
          height: 100vh;
          background-color: #000;
          transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));
          `
        : `
        width: 430px;
        height: 230px;
        `;
    }}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${(props) => {
      return props.isFullscreen
        ? `
          width: 100vh;
          max-width:unset;
          height: 100vw;
          transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%))
        rotate(90deg);
          `
        : `
        width: 100%;
        height: 160px;
        max-width: 340px;
        `;
    }}
  }

  ${(props) => {
    return props.isFullscreen
      ? `
      width: 100vw;
      height: 100vh;
      background-color: #000;
      transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));
      `
      : `
      width: 340px;
      height: 195px;
      `;
  }}
`;

const StyledIframe = styled.iframe<PIPExtendedProps>`
  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    ${(props) => {
      return props.isFullscreen
        ? `
        width: 100%;
        height: 100vh;
        `
        : `
        width: 430px;
        height: 230px;
        `;
    }}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${(props) => {
      return props.isFullscreen
        ? `
          width: 100vh;
          height: 100vw;
          max-width:unset;
          `
        : `
        width: 100%;
        max-width: 340px;
        height: 160px;
        `;
    }}
  }

  ${(props) => {
    return props.isFullscreen
      ? `
      width: 100%;
      height: 100vh;
      `
      : `
      width: 340px;
      height: 195px;
      `;
  }}
`;
/*
const ExpendedLinkSwitch = styled.img`
  top: 85px;
  right: 30px;
  width: 42px;
  height: 42px;
  position: absolute;
  ${MARGIN(null, null, 5, 5)}

  display: block;
  filter: ${FILTERS.WHITE};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 30px;
    height: 30px;
    top: 60px;
    right: 15px;
  }
`;

const StyledMinimizeIcon = styled(MinimizeIcon)<IconsEventProps>`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 42px;
  height: 42px;
  fill: ${COLORS.WHITE};
  opacity: 0.8;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 30px;
    height: 30px;
    top: 15px;
    right: 15px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledMaximizeIcon = styled(ExpandIcon)<IconsEventProps>`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  fill: ${COLORS.WHITE};

  @media (min-width: ${BREAKPOINTS.MOBILE}) {
    width: 25px;
    height: 25px;
    top: 15px;
    right: 15px;
  }

  &:hover {
    cursor: pointer;
  }
`;
*/
const EventDetails = styled.p`
  display: none;
`;

const StyledTootip = styled(Tooltip)`
  cursor: pointer;
`;
