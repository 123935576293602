import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IGetCommunityJobsProps } from '../interfaces/community';
import {
  useGetCommunityJobsQuery,
  useGetCommunityJobsWithAuthQuery,
  useGetCommunityPageQuery,
} from '../store/features/rtkApi/common';
import { RootState } from '../store/store';
import { checkIfUserIsCandidate, UserInformation } from '../utils/userInfo';

type jobArgs = {
  companyId?: string | number;
  eventId?: string | number;
  page: number;
  limit: number;
  search?: string;
  forceRefetch?: boolean;
};

const useGetCommunityJobs = ({
  companyId,
  pageNumber = 1,
  search = '',
  roleId,
  jobById,
}: IGetCommunityJobsProps) => {
  const { isAuthenticated } = UserInformation();
  // the boolean value for refetching, when it will change to true it will automatically trigger another call
  const { communityJobsRefetch } = useSelector(
    (state: RootState) => state.jobDetails,
  );
  const { eventId } = useGetCommunityPageQuery(companyId, {
    selectFromResult: ({ data }) => ({
      eventId: data?.communityEvent.id,
    }),
  });

  let jobsArgs: jobArgs = {
    companyId,
    page: pageNumber,
    limit: 10,
  };

  if (search && search.length > 0) jobsArgs = { ...jobsArgs, search };

  const { data: communityJobsList } = useGetCommunityJobsQuery(jobsArgs, {
    skip: isAuthenticated && checkIfUserIsCandidate(roleId),
  });

  let candidateJobArgs: jobArgs = {
    eventId,
    page: pageNumber,
    limit: 10,
    forceRefetch: communityJobsRefetch,
  };

  if (search && search.length > 0)
    candidateJobArgs = { ...candidateJobArgs, search };

  const {
    data: candidateJobsList,
    refetch: refetchCandidateJobs,
    isUninitialized,
  } = useGetCommunityJobsWithAuthQuery(candidateJobArgs, {
    skip: !isAuthenticated || !checkIfUserIsCandidate(roleId),
  });
  const jobs = useMemo(() => {
    if (candidateJobsList && !isUninitialized) {
      if (jobById) {
        return {
          totalItems: candidateJobsList.totalItems,
          jobs: [jobById, ...candidateJobsList.jobs.slice(1, 10)],
        };
      }
      return {
        totalItems: candidateJobsList.totalItems,
        jobs: candidateJobsList.jobs,
      };
    }
    if (communityJobsList) {
      if (jobById) {
        return {
          totalItems: communityJobsList.totalItems,
          jobs: [jobById, ...communityJobsList.jobs.slice(1, 10)],
        };
      }
      return {
        totalItems: communityJobsList.totalItems,
        jobs: communityJobsList.jobs,
      };
    }

    return null;
  }, [jobById, communityJobsList, candidateJobsList, isUninitialized]);

  return {
    eventId,
    jobs: jobs?.jobs,
    totalItems: jobs?.totalItems,
    refetchCandidateJobs,
  };
};

export { useGetCommunityJobs };
