import React from 'react';
import get from 'lodash/get';
import PageCircle from '../../components/shared-components/PageCircle';
import Calendar from '../../components/Calendar/Calendar';
import { getUrlVars } from '../../utils/common';
import { PageContainer } from '../../components/shared-components/PageContainer';
import { getUserColorWithRoleId } from '../../styles/utils';
import { RECRUITER } from '../../constants';
import { StyledCalendarHeader } from '../../components/shared-components/common';
import { useSearchDebounce } from '../../components/Common/CustomHooks';

const RecruiterCalendar = (props) => {
  const [value, setValue] = useSearchDebounce();
  const handleChange = (e) => {
    setValue(e.target.value.toLowerCase());
  };

  const { openCreateSlot, eventId } = getUrlVars(
    get(props, 'history.location.search', false),
  );

  return (
    <div>
      {/* events home page */}

      {/* circle */}
      <PageCircle color={getUserColorWithRoleId(RECRUITER)} />

      {/* title search section */}
      <StyledCalendarHeader roleId={RECRUITER} handleChange={handleChange} />

      <PageContainer>
        <Calendar
          searchText={value}
          openCreateSlot={openCreateSlot}
          eventId={eventId}
        />
      </PageContainer>
    </div>
  );
};

export default RecruiterCalendar;
