import React from 'react';
import styled from 'styled-components';
import { Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { StyledInputWrapper, StyledInfoHeading } from './Common';
import MandatoryStar from '../../../../components/Common/mandatoryStar';
import {
  Dropdown,
  NumberBox,
} from '../../../../components/shared-components/Form/Form';
import { FONT, FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { MARGIN, PADDING } from '../../../../styles/constants/display';
import { COLORS } from '../../../../styles/constants/colors';
import { minimumEducation } from '../../../../constants/minimumEducation';
import { jobLocation } from '../../../../utils/jobDetails';
import { MASTER_ADMIN_COLOR, RECRUITER_COLOR } from '../../../../constants';
import { checkIfUserIsRecruiter } from '../../../../utils/userInfo';
import { getRoleId } from '../../../../utils/userInfo';

const BelowSection = ({ handleJobTypeChange }) => {
  const roleId = getRoleId();
  const isRecruiter = checkIfUserIsRecruiter(roleId);

  return (
    <Row
      gutter={32}
      style={{
        marginTop: '30px',
      }}
    >
      <Col md={10} lg={6} xs={24}>
        <StyledInfoHeading>
          Minimum Education <MandatoryStar />
        </StyledInfoHeading>
        <StyledInputWrapper>
          <Dropdown
            name="minQualification"
            options={minimumEducation}
            required
            showArrow
            showSearch
            id="additional-details-minimum-qualification"
          />
        </StyledInputWrapper>
      </Col>
      <Col md={10} lg={6} xs={24}>
        <StyledInfoHeading>
          Job Location <MandatoryStar />
        </StyledInfoHeading>
        <StyledInputWrapper>
          <Dropdown
            name="jobLocationType"
            options={jobLocation}
            required
            showArrow
            placeholder="Location"
            id="additional-details-job-location-type"
            handleChange={handleJobTypeChange}
          />
        </StyledInputWrapper>
      </Col>
      <Col md={12} lg={8} xs={18}>
        <StyledInfoHeading>
          Minimum Work Experience <MandatoryStar />
        </StyledInfoHeading>
        <Row gutter={16}>
          <Col xs={10} lg={10} xl={10}>
            <StyledInputWrapper>
              <NumberBox
                name="years"
                placeholder="Years"
                max={80}
                maxChar={2}
                required
              />
            </StyledInputWrapper>
          </Col>
          <Col xs={10}>
            <StyledInputWrapper>
              <NumberBox
                name="months"
                placeholder="Months"
                defaultValue={0}
                min={0}
                max={11}
                maxChar={2}
                required
              />
            </StyledInputWrapper>
          </Col>
          <StyledHelpTextWrapper xs={3}>
            <Tooltip
              placement="bottomRight"
              color={isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR}
              title="Minimum Work Experience in Years/Months"
              overlayInnerStyle={{
                color: COLORS.WHITE,
                backgroundColor: isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR,
              }}
            >
              <InfoCircleOutlined
                style={{
                  color: COLORS.WHITE,
                  backgroundColor: isRecruiter ? RECRUITER_COLOR : MASTER_ADMIN_COLOR,
                  borderRadius: '50%',
                  fontSize: '20px',
                }}
              />
            </Tooltip>
          </StyledHelpTextWrapper>
        </Row>
      </Col>
    </Row>
  );
};

const StyledHelpTextWrapper = styled(Col)`
  font-size: ${FONT[14]};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  color: ${COLORS.PHILLIPPINE_GRAY};
  ${MARGIN(0, 0, 0, 6)}
  ${PADDING(8, 0, 0, 8)}
`;

export default BelowSection;
