import React, { useEffect } from 'react';
import { getCategories } from '../../service';
import { getCategoryOptions } from '../../utils/common';
import { Dropdown } from '../shared-components/Form/Form';

const CategoryDropdown = ({
  mode = 'multiple',
  handleChange,
  disabled = false,
  id = 'category',
  required = true,
  showArrow = true,
  showSearch = true,
  name = 'categories',
  placeholder = 'Select Category',
  categories,
}) => {
  const [categoryOptions, setCategoriesOptions] = React.useState([]);

  useEffect(() => {
    getCategories().then((categoriesList) =>
      setCategoriesOptions(categoriesList),
    );
  }, []);

  return (
    <Dropdown
      name={name}
      placeholder={placeholder}
      options={getCategoryOptions(categoryOptions)}
      mode={mode}
      value={categories}
      showArrow={showArrow}
      showSearch={showSearch}
      handleChange={(options) => {
        // Optional function
        handleChange(options);
      }}
      required={required}
      id={id}
      disabled={disabled}
    />
  );
};

export default CategoryDropdown;
