import get from 'lodash/get';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  Method,
  SuperAdminApis,
} from '../constants';
import ERRORS from '../constants/errors';
import { isValid } from '../utils/api';
import api from './api';

const getAdmins = (page = DEFAULT_PAGE, limit = DEFAULT_LIMIT) => {
  return api({
    method: Method.get,
    url: `${SuperAdminApis.baseUrl}?page=${page}&limit=${limit}`,
  })
    .then((response) => {
      if (!isValid(response)) {
        throw new Error(ERRORS.REQUEST_FAILED);
      }
      return get(response, 'data.payload', null);
    })
    .catch(() => Promise.reject(ERRORS.REQUEST_FAILED));
};

const createAdmin = ({ name, email, password, phone, department, country }) => {
  return api({
    method: Method.post,
    url: SuperAdminApis.baseUrl,
    body: {
      name,
      email,
      password,
      username: email,
      phone,
      department,
      country,
    },
  });
};

const updateAdmin = (data, userId) => {
  const { email } = data;
  return api({
    method: Method.put,
    url: `${SuperAdminApis.baseUrl}${userId}/`,
    body: {
      ...data,
      username: email,
    },
  });
};

const deleteAdmin = ({ userId }) => {
  return api({
    method: Method.delete,
    url: `${SuperAdminApis.baseUrl}${userId}/`,
  });
};

export { getAdmins, createAdmin, updateAdmin, deleteAdmin };
