import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import get from 'lodash/get';
import { DocumentViewer } from '../../shared-components/DocumentViewerModal';
import { getFilePath } from '../../../utils/profile';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { FONT, FONT_WEIGHTS } from '../../../styles/constants/typography';

const TermsAndConditions = ({ event }) => {
  const { Panel } = Collapse;
  const termsAndConditions = get(event, 'meta.termsAndConditions', []);
  return termsAndConditions.length > 0 ? (
    <StyledCollapse bordered={false} defaultActiveKey={['1']}>
      <Panel header="Terms & Conditions" key="1">
        <StyledWrapper>
          {termsAndConditions.length
            ? termsAndConditions.map((fileName, i) => (
                <DocumentViewer
                  filePath={getFilePath(fileName)}
                  fileName={`Terms & Conditions ${
                    termsAndConditions.length > 1 ? `(${i + 1})` : ''
                  }`}
                  key={i.toString()}
                />
              ))
            : null}
        </StyledWrapper>
      </Panel>
    </StyledCollapse>
  ) : null;
};

export default TermsAndConditions;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
    padding-bottom: 15px !important;
    position: relative;
    margin-bottom: 0;
    color: ${COLORS.RAISIN_BLACK};
    font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
    background: ${COLORS.WHITE};
    font-size: ${FONT[16]};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[20]};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[14]};
    }

    .anticon {
      position: absolute;
      right: 0;
      top: -10px;

      svg {
        position: absolute;
        right: 0;
        display: block;
        top: -10px;
      }
    }
  }

  .ant-collapse-content-box {
    padding: 20px 0 !important;
    font-size: ${FONT[14]};

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      font-size: ${FONT[16]};
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: ${FONT[14]};
    }

    p {
      padding-left: 0 !important;
    }
  }
`;
