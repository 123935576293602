import React from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import moment from 'moment';
import maxBy from 'lodash/maxBy';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import lowerCase from 'lodash/lowerCase';
import omit from 'lodash/omit';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import config from '../config';
import { addUserToChatroom, createChatroom } from '../service/chat';
import {
  ONLINE_EVENT,
  FULLTIME_TEXT,
  PARTTIME_TEXT,
  INTERNSHIP_TEXT,
  CONTRACT_TEXT,
  PERMANENTEMPLOYMENT_TEXT,
  OFFLINE_EVENT,
  EVENT_ATTENDEES,
  CANDIDATE,
  RECRUITER,
  RECRUITER_TEXT,
  CANDIDATE_TEXT,
  ONLINE_EVENT_TEXT,
  OFFLINE_EVENT_TEXT,
  ATTENDANCE_PRESENT,
  ATTENDANCE_ABSENT,
  STATUS_DELETE,
  STATUS_ACTIVE,
  LIVE_EVENT,
  UPCOMING_EVENT,
  PAST_EVENT,
  UPCOMING_EVENT_TIME_TEXT,
  PAST_EVENT_TIME_TEXT,
  LIVE_EVENT_TIME_TEXT,
  STATUS_DRAFT,
  LIVE_TEXT,
  ONGOING_TEXT,
  PAST_TEXT,
  UPCOMING_TEXT,
  lastModifiedType,
  CHATROOM_TYPE_PRIVATE,
  RECRUITER_JOIN_FAIL_MESSAGE,
  CANDIDATE_JOIN_FAIL_MESSAGE,
  JOIN_EVENT_SUCCESS_MESSAGE,
  RECRUITER_JOIN_EVENT_SUCCESS_MESSAGE,
  CHATROOM_TYPE_GROUP,
  DELETE,
  ADD,
  EventStatus,
} from '../constants';
import getRemainingTime from './getRemainingTime';
import getNow from './getNow';
import { convertToUnix, convertUnixTime, currentTime } from './formatDateTime';
import { MonitorWaveIcon, LiveEventIcon } from '../components/svgicons';
import { checkIfUserIsRecruiter, checkIfUserIsCandidate } from './userInfo';
import { checkIfCompanyHaveAnyActiveJobs } from '../service/recruiter';
import { joinEvent, getBookedEventInfo } from '../service/event';
import Notification from '../components/Notification/Notification';
import {
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
} from '../constants/routes';
import {
  eventDataRegardingChatroom,
  eventOrgainizerDataRegardingChatroom,
} from './chat';
import { DISPLAY_FLEX } from '../styles/constants/display';
import { COLORS } from '../styles/constants/colors';
import { viewEventUrl } from '../components/Events/Common';
import { getSerialNumber, serialNoColumn } from './common';
import { getIsCommunityEvent } from './master-admin';

export const EventsStore = () => {
  const {
    isLoading,
    onGoingEvents,
    upComingEvents,
    pastEvents,
    cancelledEvents,
    event,
    error,
  } = useSelector((state) => state.events);
  return {
    isLoading,
    onGoingEvents,
    upComingEvents,
    pastEvents,
    cancelledEvents,
    event,
    error,
  };
};

const isEventActive = (status) => {
  return status === STATUS_ACTIVE;
};

export const isAutoPresentModeActive = (event) => {
  return event && event.isAutoPresentEnabled;
};

export const getOnGoingEvent = (event) => {
  const { dateTime, endDateTime, status } = event;
  return (
    getNow() > dateTime &&
    getNow() < endDateTime &&
    (isEventActive(status) || status === STATUS_DRAFT)
  );
};

export const EventStatusLabel = (event) => {
  return getOnGoingEvent(event)
    ? isEventLive(event)
      ? LIVE_TEXT
      : ONGOING_TEXT
    : getUpComingEvent(event)
    ? UPCOMING_TEXT
    : PAST_TEXT;
};

export const getPastEvent = (event) => {
  const { dateTime, endDateTime, status } = event;
  return (
    getNow() > dateTime &&
    getNow() > endDateTime &&
    (isEventActive(status) || status === STATUS_DRAFT)
  );
};

export const getCancelledEvent = (event) => {
  return event.status === STATUS_DELETE;
};

export const getUpComingEvent = (event) => {
  const { dateTime, status } = event;
  return (
    getNow() < dateTime && (status === STATUS_ACTIVE || status === STATUS_DRAFT)
  );
};

export const getOnGoingEvents = (events) => {
  return events.filter((event) => getOnGoingEvent(event));
};

export const getPastEvents = (events) => {
  return events.filter((event) => getPastEvent(event));
};

export const getCancelledEvents = (events) => {
  return events.filter((event) => getCancelledEvent(event));
};

export const getUpComingEvents = (events) => {
  return events.filter((event) => getUpComingEvent(event));
};

export const isEventOnline = (type) => {
  return type === ONLINE_EVENT;
};

export const isEventLive = (event) => {
  const streamUrlsData = get(event, 'streamUrlsData', []);
  return streamUrlsData && streamUrlsData.length;
};

export const isEventOngoing = (event) => {
  return moment().isBetween(
    moment.unix(event.dateTime),
    moment.unix(event.endDateTime),
  );
};

export const checkIfEventEnded = (eventEndDate) => {
  const presentDate = moment().unix();
  return presentDate > eventEndDate;
};

export const getEventFormaterDate = (dateTime) => {
  const start = moment.unix(dateTime);
  const eventStartMonth = start.format('MMM');
  const eventStartDay = start.format('DD');
  const eventStartYear = start.format('YYYY');
  const eventStartTime = start.format('hh:mm A');

  return `${eventStartMonth} ${eventStartDay}, ${eventStartYear} at ${eventStartTime}`;
};

export const getEventStatus = (event) => {
  if (isEventOngoing(event.dateTime, event.endDateTime) && isEventLive(event))
    return EventStatus.LIVE;
  if (isEventOngoing(event.dateTime, event.endDateTime) && !isEventLive(event))
    return EventStatus.ONGOING;
  if (checkIfEventEnded(event.endDateTime)) return EventStatus.PAST;
  return EventStatus.UPCOMING;
};

export const jobTypes = (type) => {
  switch (type) {
    case 1:
      return FULLTIME_TEXT;
    case 2:
      return PARTTIME_TEXT;
    default:
      return null;
  }
};

export const engagementTypes = (type) => {
  switch (type) {
    case 1:
      return INTERNSHIP_TEXT;
    case 2:
      return CONTRACT_TEXT;
    case 3:
      return PERMANENTEMPLOYMENT_TEXT;
    default:
      return null;
  }
};

export const RecommendedEventsStore = () => {
  const { isLoading, recommendedEvents, error } = useSelector(
    (state) => state.recommendedEvents,
  );
  return { isLoading, recommendedEvents, error };
};

export const CalendarEventsStore = () => {
  const {
    isLoading,
    calendarEvents,
    interviews,
    interviewSlots,
    error,
  } = useSelector((state) => state.calendarEvents);
  return { isLoading, calendarEvents, interviews, interviewSlots, error };
};
export const eventTypeNames = {
  1: OFFLINE_EVENT_TEXT,
  2: ONLINE_EVENT_TEXT,
};

export const eventTypeIcon = {
  1: <MonitorWaveIcon />,
  2: <LiveEventIcon />,
};

export const splitEventTypeName = (type) => {
  return eventTypeNames[type].split(' ')[0];
};

export const eventHeader = (name, type) => {
  return `${name} - ${splitEventTypeName(type)}`;
};

export const sortEventsWithDateTime = (events) => {
  return events.sort((a, b) => a.dateTime - b.dateTime);
};

export const makeCreateEventData = (formData, isEdit, promoCodes) => {
  // Constant required values
  const {
    name,
    dateTime,
    address,
    zipcode,
    city,
    state,
    country,
    description,
    tickets,
    bookingwebsiteName,
    bookingWebisteUrl,
    thirdPartTicketCurrency,
    thirdPartyRecruiterTickerPrice,
    thirdPartyJobseekerTickerPrice,
    recruiterTicketPrice = 0,
    userTicketPrice = 0,
  } = formData;
  // Values to be modified
  let { profileLinks, eventType, promoCode, schedules } = formData;
  // Extract startDateTime & endDateTime from dateTime
  const startDateTime = convertToUnix(dateTime[0]);
  const endDateTime = convertToUnix(dateTime[1]);
  const getTransformedPromoData = (promo) => {
    return omit(promo, [
      'status',
      'updatedAt',
      'createdAt',
      'createdBy',
      'updatedBy',
      'eventId',
      'applied',
    ]);
  };
  /**
   * Set ticket price for candidate and recruiter
   * @param {object} result
   * @param {array} ticketForm
   */
  const setTicketPrice = (result, ticketForm) => {
    [
      { attendee: EVENT_ATTENDEES.CANDIDATE, resultKey: 'userTicketPrice' },
      {
        attendee: EVENT_ATTENDEES.RECRUITER,
        resultKey: 'recruiterTicketPrice',
      },
    ].forEach((attendeeAndResultKey) => {
      const userTicketForm = find(
        ticketForm,
        (ticket) => ticket.attendee === attendeeAndResultKey.attendee,
      );

      const ticketPrice = get(userTicketForm, 'price', 0);

      set(result, attendeeAndResultKey.resultKey, ticketPrice);

      return result;
    });
  };
  if (profileLinks && profileLinks.length) {
    // Transform profileLink, arr of objects to arr of strings
    profileLinks = profileLinks.map((el) => el.link);
  }
  if (schedules) {
    let temp = [];
    const keys = Object.keys(schedules);
    for (let i = 0; i < keys.length; i += 1) {
      let schedulesForDay = schedules[keys[i]];
      schedulesForDay = schedulesForDay.map((slot) => {
        return {
          ...slot,
          startTime: slot.startTime.unix(),
          endTime: slot.endTime.unix(),
        };
      });
      temp = [...temp, ...schedulesForDay];
    }
    schedules = temp;
  }
  if (promoCode && promoCode.length) {
    // Modify Promo code
    // const CANDIDATE = 0;
    // const RECRUITER = 3;

    promoCode = promoCode.map((el) => {
      const applicable = el.applicable === 'Candidate' ? CANDIDATE : RECRUITER;
      let promo = {
        ...el,
        applicable,
        expiresIn: convertToUnix(el.expiresIn[1]),
        startDateTime: convertToUnix(el.expiresIn[0]),
      };
      if (isEdit) {
        promo = getTransformedPromoData(promo);
        if (!el.id) {
          promo = { ...promo, operation: 'add' };
        } else if (
          promoCodes.find((element) => {
            return element.id === el.id;
          })
        ) {
          promo = { ...promo, operation: 'edit' };
        }
      }
      return promo;
    });
  }
  if (promoCodes && promoCodes.length && isEdit) {
    promoCodes.forEach((promo) => {
      promo = getTransformedPromoData(promo);
      if (
        !promoCode.find((element) => {
          return element.id === promo.id;
        })
      ) {
        promoCode.unshift({
          ...promo,
          operation: 'delete',
          expiresIn: convertToUnix(promo.expiresIn[1]),
          startDateTime: convertToUnix(promo.expiresIn[0]),
          applicable: promo.applicable === 'Candidate' ? CANDIDATE : RECRUITER,
        });
      }
    });
  }
  // Converts array of strings to array of objects with key name
  const convertToArrOfObj = (key, arr) => {
    return arr.map((value) => ({ [key]: value }));
  };
  // Transform refundForm
  // refundPolicies = refundPolicies.map(refund => {
  //   const { userType, ticketType, refundPercentage, refundValidity } = refund;
  //   // TODO: Usertype, ticket should be a number: 0 or 1 like
  //   // Validity times must be converted to supported times
  //   return {
  //     userType: userType[0] === "Candidate" ? 2 : 3,
  //     ticket: ticketType[0],
  //     refundPercentage,
  //     validityStartTime: convertToUnix(refundValidity[0]),
  //     validityEndTime: convertToUnix(refundValidity[1])
  //   }
  // });
  if (eventType) {
    // Convert data type
    eventType =
      lowerCase(eventType) === 'online' ? ONLINE_EVENT : OFFLINE_EVENT;
  }
  // TODO: add refundPolicies to payload later

  // Required and unmodified properties
  const result = {
    name,
    description,
    location: address || '-',
    city,
    state,
    country,
    zip: zipcode || ' ',
    dateTime: startDateTime,
    recruiterTicketPrice: recruiterTicketPrice || 0,
    userTicketPrice: userTicketPrice || 0,
  };
  /**
   * Update or create meta with fields and value
   * @param {*} obj
   * @param {*} fieldName
   * @param {*} nestedFieldName
   * @param {*} value
   */
  const updateMetaValue = (obj, fieldName, nestedFieldName, value) => {
    if (nestedFieldName) {
      // Create a nested field to fieldName if available
      set(obj, `meta.${[fieldName]}.${[nestedFieldName]}`, value);
    } else {
      set(obj, `meta.${[fieldName]}`, value);
    }
  };

  const optionalFieldNames = [
    'profileLinks',
    'noOfJobs',
    'typesOfJob',
    'typesOfEngagement',
    'eventSkills',
    'technical',
    'management',
    'technoFunctional',
    'expectedNoOfAttendees',
    'termsAndConditions',
    'companyLogos',
    'cover',
    'endDateTime',
    'timezone',
    'currency',
    'eventType',
    'taxName',
    'taxPercentage',
    'taxId',
    'promoCode',
    'refundPolicies',
    'tags',
    'domains',
    'thirdPartyData',
    'categories',
    'speakers',
    'sponsors',
    'partners',
    'hosts',
    'schedule',
    'isWeb3Event',
    'isPrivate',
    'broadcastLink',
  ];
  optionalFieldNames.forEach((optionalFieldName) => {
    switch (optionalFieldName) {
      case 'tags':
      case 'domains':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            result[optionalFieldName] = convertToArrOfObj('name', value);
          }
        }
        break;
      case 'endDateTime':
        {
          const value = get(formData, 'dateTime', null);
          if (value) {
            // Assign modified value
            result[optionalFieldName] = endDateTime;
          }
        }
        break;
      case 'timezone':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            // Assign modified value
            updateMetaValue(result, optionalFieldName, null, value);
          }
        }
        break;
      case 'tickets':
        setTicketPrice(result, tickets);
        break;
      case 'eventType':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            // Assign modified value
            result[optionalFieldName] = eventType;
          }
        }
        break;
      case 'isPrivate':
      case 'isWeb3Event':
        {
          const value = get(formData, optionalFieldName, null);

          if (value) {
            updateMetaValue(result, optionalFieldName, null, value);
          }
        }
        break;
      case 'profileLinks':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            // Assign modified value
            updateMetaValue(result, optionalFieldName, null, profileLinks);
          }
        }
        break;
      case 'sponsors':
      case 'hosts':
      case 'partners':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            // Assign modified value
            updateMetaValue(result, optionalFieldName, null, value);
          }
        }
        break;
      case 'speakers':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            // Assign modified value
            set(result, `${optionalFieldName}`, value);
          }
        }
        break;
      case 'schedule':
        if (schedules?.length) {
          // Assign modified value
          set(result, `${optionalFieldName}`, schedules);
        }
        break;
      case 'noOfJobs':
      case 'typesOfJob':
      case 'typesOfEngagement':
        {
          // Dynamic fallback value for different date types
          const fallbackValue = optionalFieldName === 'noOfJobs' ? null : [];
          const value = get(formData, optionalFieldName, fallbackValue);
          if (Array.isArray(value) && value.length) {
            updateMetaValue(
              result,
              'jobOpportunities',
              optionalFieldName,
              value,
            );
          }
          // fallbackValue prevents empty array to be passed and converted
          else if (!fallbackValue && value) {
            updateMetaValue(
              result,
              'jobOpportunities',
              optionalFieldName,
              `${value}`,
            );
          }
        }
        break;
      case 'technical':
      case 'management':
      case 'technoFunctional':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            updateMetaValue(result, 'eventSkills', optionalFieldName, value);
          }
        }
        break;
      case 'expectedNoOfAttendees':
      case 'termsAndConditions':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            updateMetaValue(result, optionalFieldName, null, value);
          }
        }
        break;
      case 'companyLogos':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            updateMetaValue(result, optionalFieldName, null, value);
          }
        }
        break;
      case 'categories':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            result[optionalFieldName] = isEdit
              ? value
              : value.map((id) => ({
                  categoryId: id,
                }));
          }
        }
        break;
      case 'promoCode':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            // Assign modified value
            result[optionalFieldName] = promoCode;
          }
        }
        break;
      case 'thirdPartyData':
        // Current implementation is done for single external booking link , we are getting name and url from form and then adding there property in a object and then sending data as list because in future we might be sending more then one object to backend.will require to change the implementation from here to cutsom state handler.
        result[optionalFieldName] = [];
        if (bookingwebsiteName && bookingWebisteUrl) {
          result[optionalFieldName] = [
            {
              name: bookingwebsiteName,
              url: bookingWebisteUrl,
              currency: thirdPartTicketCurrency || '',
              userTicketPrice: thirdPartyJobseekerTickerPrice || 0,
              recruiterTicketPrice: thirdPartyRecruiterTickerPrice || 0,
            },
          ];
        }

        break;
      default: {
        const value = get(formData, optionalFieldName, null);
        // Check if value exist and not assigned before, to prevent overriding
        if (value && !result[value]) {
          result[optionalFieldName] = value;
        }
      }
    }
  });

  return result;
};

export const transformPromoCode = (promoCodes) => {
  // Modify Promo code
  return promoCodes.map((el) => {
    const applicable =
      el.applicable === CANDIDATE ? CANDIDATE_TEXT : RECRUITER_TEXT;
    // Skipping first array item's value, Don't receive it
    // Convert unix dateTime
    const expiresIn = [
      convertUnixTime(el.startDateTime),
      convertUnixTime(el.expiresIn),
    ];
    return { ...el, applicable, expiresIn };
  });
};

export const makeEditEventData = (formData) => {
  // Constant required values
  const {
    name,
    location,
    zip,
    city,
    state,
    country,
    description,
    userTicketPrice,
    recruiterTicketPrice,
  } = formData;

  /**
   * Set date time
   * @param {object} result
   * @param {number} value
   */
  const setDateTime = (result, value) => {
    const dateTimeArr = get(result, 'dateTime', []);

    const temp = convertUnixTime(value);

    if (dateTimeArr.length) {
      return [...dateTimeArr, temp];
    }
    return [temp];
  };

  /**
   * Create tickets from available tickets
   * @param {object} result
   * @param {string} ticketType
   * @param {number} price
   */
  const createTickets = (result, ticketType, price) => {
    const attendee =
      ticketType === 'userTicketPrice'
        ? EVENT_ATTENDEES.CANDIDATE
        : EVENT_ATTENDEES.RECRUITER;

    const temp = {
      name: '',
      attendee,
      price,
      count: 0,
    };

    // Get tickets if there are available
    const tickets = get(result, 'tickets', []);

    if (tickets.length) {
      return [...tickets, temp];
    }
    return [temp];
  };

  // Transform profileLink, arr of strings to arr of objects
  const convertProfileLinksFormat = (links) => {
    return links.map((link) => ({ link }));
  };

  // Converts array of objects with key name to array of strings
  const convertToArrOfStr = (key, arr) => {
    return arr.map((obj) => obj[key]);
  };

  const setEventType = (eventType) => {
    return eventType === ONLINE_EVENT ? 'Online' : 'Hybrid';
  };

  // TODO: add refundPolicies to payload later

  // Required and unmodified properties
  const result = {
    name,
    description,
    address: location,
    city,
    state,
    country,
    zipcode: zip,
    recruiterTicketPrice,
    userTicketPrice,
  };

  /**
   * Exract the value from meta and nested fields
   * @param {obj} obj
   * @param {str} fieldName
   * @param {str} nestedFieldName
   * @param {bool} nestedFieldName
   * @param {*} value
   */
  const exractValueFromMeta = (
    obj,
    fieldName,
    nestedFieldName,
    defaultValue,
  ) => {
    if (nestedFieldName) {
      return get(obj, `meta.${[fieldName]}.${[nestedFieldName]}`, defaultValue);
    }
    return get(obj, `meta.${[fieldName]}`, defaultValue);
  };

  const optionalFieldNames = [
    'dateTime',
    'profileLinks',
    'userTicketPrice',
    'recruiterTicketPrice',
    'noOfJobs',
    'typesOfJob',
    'typesOfEngagement',
    'eventSkills',
    'technical',
    'management',
    'technoFunctional',
    'expectedNoOfAttendees',
    'termsAndConditions',
    'companyLogos',
    'cover',
    'endDateTime',
    'timezone',
    'currency',
    'eventType',
    'taxName',
    'taxPercentage',
    'taxId',
    'promoCode',
    'refundPolicies',
    'tags',
    'domains',
    'thirdPartyData',
    'categories',
    'nameValue',
  ];

  optionalFieldNames.forEach((optionalFieldName) => {
    switch (optionalFieldName) {
      case 'tags':
      case 'domains':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            result[optionalFieldName] = convertToArrOfStr('name', value);
          }
        }
        break;
      case 'dateTime':
      case 'endDateTime':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            result.dateTime = setDateTime(result, value);
          }
        }
        break;
      case 'timezone':
        {
          const value = exractValueFromMeta(
            formData,
            optionalFieldName,
            null,
            null,
          );
          if (value) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'userTicketPrice':
      case 'recruiterTicketPrice':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            const tickets = createTickets(result, optionalFieldName, value);
            result.tickets = tickets;
          }
        }
        break;
      case 'eventType':
        {
          const value = get(formData, optionalFieldName, null);
          if (value) {
            result[optionalFieldName] = setEventType(value);
          }
        }
        break;
      case 'profileLinks':
        {
          const value = exractValueFromMeta(
            formData,
            optionalFieldName,
            null,
            [],
          );
          if (value.length) {
            result[optionalFieldName] = convertProfileLinksFormat(value);
          }
        }
        break;
      case 'noOfJobs':
      case 'typesOfJob':
      case 'typesOfEngagement':
        {
          const fallbackValue = optionalFieldName === 'noOfJobs' ? null : [];
          const value = exractValueFromMeta(
            formData,
            'jobOpportunities',
            optionalFieldName,
            fallbackValue,
          );
          if (Array.isArray(value) && value.length) {
            result[optionalFieldName] = value;
          }
          // fallback value prevents empty array to get in & converted
          else if (!fallbackValue && value) {
            result[optionalFieldName] = Number(value);
          }
        }
        break;
      case 'technical':
      case 'management':
      case 'technoFunctional':
        {
          const value = exractValueFromMeta(
            formData,
            'eventSkills',
            optionalFieldName,
            null,
          );
          if (value) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'expectedNoOfAttendees':
      case 'termsAndConditions':
        {
          const fallbackValue =
            optionalFieldName === 'expectedNoOfAttendees' ? null : [];
          const value = exractValueFromMeta(
            formData,
            optionalFieldName,
            null,
            fallbackValue,
          );
          if (value) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'companyLogos':
        {
          const value = exractValueFromMeta(
            formData,
            optionalFieldName,
            null,
            [],
          );
          if (value.length) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'promoCode':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            result[optionalFieldName] = transformPromoCode(value);
          }
        }
        break;
      case 'nameValue':
        {
          const value = get(formData, 'name', '');
          if (value) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'categories':
        {
          const value = get(formData, optionalFieldName, []);
          if (value.length) {
            result[optionalFieldName] = uniq(
              value.map((category) => get(category, 'category.id', '')),
            );
          }
        }
        break;
      case 'thirdPartyData':
        // Current implementation is done for single external booking link , we are only taking out on record from this list and filling name and url into form initial value, in future we might require to change this implementaion to list and manage the initail value and payload using custom state.
        result.bookingwebsiteName = get(
          formData,
          `${optionalFieldName}[0].name`,
          '',
        );
        result.bookingWebisteUrl = get(
          formData,
          `${optionalFieldName}[0].url`,
          '',
        );
        result.thirdPartTicketCurrency = get(
          formData,
          `${optionalFieldName}[0].currency`,
          '',
        );
        result.thirdPartyRecruiterTickerPrice = get(
          formData,
          `${optionalFieldName}[0].recruiterTicketPrice`,
          0,
        );
        result.thirdPartyJobseekerTickerPrice = get(
          formData,
          `${optionalFieldName}[0].userTicketPrice`,
          0,
        );

        break;
      default: {
        const value = get(formData, optionalFieldName, null);
        // Check if value exist and not assigned before, to prevent overriding
        if (value && !result[value]) {
          result[optionalFieldName] = value;
        }
      }
    }
  });

  return result;
};

// Attendees managment

export const eventCandidateColumns = [
  { ...serialNoColumn },
  {
    title: 'ID',
    className: 'management-table__id-column',
    dataIndex: 'id',
  },
  {
    title: 'Candidate Name',
    dataIndex: 'candidateName',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    ellipsis: true,
  },
  {
    title: 'Attendance',
    className: 'management-table__attendance-column',
    dataIndex: 'attendance',
  },
  {
    title: 'Status',
    className: 'management-table__status-column',
    dataIndex: 'status',
  },
];

export const eventCompanyColumns = [
  { ...serialNoColumn },
  {
    title: 'ID',
    className: 'management-table__id-column',
    dataIndex: 'id',
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    sorter: true,
  },
  {
    title: 'Representative Name',
    dataIndex: 'representativeName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Jobs posted',
    dataIndex: 'jobs',
  },
  {
    title: 'Attendance',
    className: 'management-table__attendance-column',
    dataIndex: 'attendance',
  },
  {
    title: 'Status',
    className: 'management-table__status-column',
    dataIndex: 'status',
  },
];

export const isLiveEvent = (startDateTime, endDateTime) => {
  return startDateTime <= currentTime() && endDateTime >= currentTime();
};

export const isPastEvent = (startDateTime, endDateTime) => {
  return startDateTime < currentTime() && endDateTime < currentTime();
};

/**
 * Returns a label number based on time
 * @param {number} startDateTime
 * @param {number} endDateTime
 */
const getEventLabel = (startDateTime, endDateTime) => {
  switch (true) {
    case isLiveEvent(startDateTime, endDateTime):
      return LIVE_EVENT;
    case isPastEvent(startDateTime, endDateTime):
      return PAST_EVENT;
    default:
      return UPCOMING_EVENT;
  }
};

/**
 * Checks if event is live by event's start and end date time
 * @param {object} event
 * @returns {boolean}
 */
export const isEventLiveNow = (event) => {
  if (!event) return false;
  return isLiveEvent(event.dateTime, event.endDateTime);
};

const isEventBookingCancelled = (status) => {
  return status === STATUS_DELETE;
};

const isEventBookingActive = (status) => {
  return status === STATUS_ACTIVE;
};

export const transformEventRecruiters = (
  recruiters,
  event,
  handleChange,
  attendence,
  page,
  limit,
) => {
  if (recruiters && recruiters.length) {
    return recruiters.map((recruiter, rowId) => {
      const status = get(recruiter, 'status', STATUS_ACTIVE);
      let data = {};
      const setAttendace = attendence[recruiter.id]
        ? ATTENDANCE_ABSENT
        : ATTENDANCE_PRESENT;

      const body = {
        attendance: setAttendace,
      };
      if (get(recruiter, 'convertedData', false)) {
        data = {
          ...recruiter,
          representativeName:
            get(recruiter, 'name', '') ||
            get(recruiter, 'recruiter.representativeName', ''),
          id: recruiter.ticketId,
        };
      } else {
        const {
          id,
          company: { name: companyName, jobs, id: companyId },
        } = recruiter;

        const userName =
          get(recruiter, 'user.name', '') ||
          get(recruiter, 'user.recruiter.representativeName', '');
        const recruiterPhone = get(recruiter, 'user.recruiter.phone', '');
        const email = get(recruiter, 'user.email', '');
        data = {
          key: id,
          id,
          companyId,
          companyName,
          representativeName: userName,
          phoneNumber: recruiterPhone,
          email,
          jobs,
        };
      }
      return {
        serialNo: getSerialNumber(page, limit, rowId),
        ...data,
        attendance: (
          <StyledAttendeesManagementStatus>
            <Switch
              className="switch-btn"
              style={{ color: '#47BFA8' }}
              disabled={
                (!isEventLiveNow(event) && !getIsCommunityEvent(event)) ||
                isEventBookingCancelled(status)
              }
              checked={!!attendence[data.id]}
              onChange={() => handleChange(data.id, body)}
            />
          </StyledAttendeesManagementStatus>
        ),
        status: isEventBookingCancelled(status) ? (
          <StyledRedInfoText>Cancelled</StyledRedInfoText>
        ) : (
          <span>Registered</span>
        ),
      };
    });
  }
};

// Transform skills
export const transformSkills = (skills) => {
  return (
    skills &&
    skills
      .map((userSkill) => {
        const { skill } = userSkill;
        return skill ? skill.skill : '';
      })
      .join(', ')
      .trim()
  );
};

export const transformSkillsData = (skills = []) => {
  return (
    isArray(skills) &&
    skills
      .map((skill) => {
        return get(skill, 'skill', '');
      })
      .join(', ')
      .trim()
  );
};

export const transformEventCandidates = (
  candidates,
  event,
  handleChange,
  attendance,
  page,
  limit,
) => {
  if (candidates && candidates.length) {
    return candidates.map((candidate, rowId) => {
      const { status } = candidate;
      let data = {};

      // Set attandance
      const setAttendace = attendance[candidate.id]
        ? ATTENDANCE_ABSENT
        : ATTENDANCE_PRESENT;

      const body = {
        attendance: setAttendace,
      };
      if (get(candidate, 'convertedData', false)) {
        data = {
          ...candidate,
          candidateName: candidate.name,
          id: candidate.ticketId,
          skills: transformSkillsData(candidate.skills),
        };
      } else {
        const { id, user } = candidate;

        const email = get(user, 'email', '');
        const userId = get(user, 'id', null);
        const name = get(user, 'candidate.name', '');
        const phone = get(user, 'candidate.phone', '');
        const userSkills = get(user, 'candidate.userSkills', []);

        data = {
          key: id,
          id,
          userId,
          candidateName: name,
          email,
          phoneNumber: phone,
          skills: transformSkills(userSkills),
        };
      }
      return {
        serialNo: getSerialNumber(page, limit, rowId),
        ...data,
        attendance: (
          <StyledAttendeesManagementStatus>
            <Switch
              disabled={
                (!isEventLiveNow(event) && !getIsCommunityEvent(event)) ||
                isEventBookingCancelled(status)
              }
              checked={!!attendance[data.id]}
              onChange={() => handleChange(data.id, body)}
            />
          </StyledAttendeesManagementStatus>
        ),
        status: isEventBookingCancelled(status) ? (
          <StyledRedInfoText>Cancelled</StyledRedInfoText>
        ) : (
          <span>Registered</span>
        ),
      };
    });
  }
};

export const getEventTime = (event) => {
  if (!event) {
    return '';
  }

  if (getIsCommunityEvent(event)) {
    return '';
  }
  const { dateTime, endDateTime } = event;

  const eventLabel = getEventLabel(dateTime, endDateTime);

  switch (eventLabel) {
    case PAST_EVENT:
      return ` ${getRemainingTime(convertUnixTime(endDateTime), true)}`;
    case UPCOMING_EVENT:
      return ` ${getRemainingTime(convertUnixTime(dateTime))}`;
    case LIVE_EVENT:
    default:
      return ` ${getRemainingTime(convertUnixTime(endDateTime))}`;
  }
};

/**
 * Returns text based on event time
 * @param {number} index
 * @return {string}
 */
export const getEventTimeText = (event) => {
  if (!event) {
    return '';
  }
  if (getIsCommunityEvent(event)) {
    return 'Community Event';
  }
  const { dateTime, endDateTime } = event;

  switch (true) {
    case isLiveEvent(dateTime, endDateTime):
      return LIVE_EVENT_TIME_TEXT;
    case isPastEvent(dateTime, endDateTime):
      return PAST_EVENT_TIME_TEXT;
    default:
      return UPCOMING_EVENT_TIME_TEXT;
  }
};

/**
 * Get event name from event
 * @param {object} event
 * @returns {string}
 */
export const getEventName = (event) => {
  return get(event, 'name', '');
};

/**
 * Filter events based on status
 * @param {array} events
 * @param {boolean} dashboard
 * @param {number} roleId
 * @returns {array}
 */
export const getActiveBookingEvents = (events, dashboard, roleId) => {
  switch (true) {
    case checkIfUserIsCandidate(roleId) && !dashboard:
      return events.filter(
        (event) =>
          event.eventCandidates &&
          isEventBookingActive(
            getLatestBookingStatus(event, 'eventCandidates'),
          ),
      );
    case checkIfUserIsRecruiter(roleId) && !dashboard:
      return events.filter(
        (event) =>
          event.eventCompanys &&
          isEventBookingActive(getLatestBookingStatus(event, 'eventCompanys')),
      );
    default:
      return events;
  }
};

/**
 * Filter events based on status
 * @param {array} events
 * @param {boolean} dashboard
 * @param {number} roleId
 * @returns {array}
 */
export const getLatestBooking = (
  event = {},
  property = 'updatedAt',
  bookingArray = null,
) => {
  const bookings = bookingArray || get(event, property, []);
  const latestBooking = maxBy(bookings, 'updatedAt');
  return latestBooking;
};

export const getLatestBookingStatus = (event, property) => {
  return get(getLatestBooking(event, property), 'status', STATUS_DELETE);
};

export const getCancelledBookingEvents = (events, dashboard, roleId) => {
  switch (true) {
    case checkIfUserIsCandidate(roleId) && !dashboard:
      return events.filter(
        (event) =>
          event.eventCandidates &&
          isEventBookingCancelled(
            getLatestBookingStatus(event, 'eventCandidates'),
          ),
      );
    case checkIfUserIsRecruiter(roleId) && !dashboard:
      return events.filter(
        (event) =>
          event.eventCompanys &&
          isEventBookingCancelled(
            getLatestBookingStatus(event, 'eventCompanys'),
          ),
      );
    default:
      return events;
  }
};

/**
 * Finds and removes the file with lastModified as id
 * @param {array} files
 * @param {number} id
 * @returns {array} new array
 */
export const findAndRemoveFile = (files, id, type) => {
  // Find the document with lastModified time as ID
  let index;
  if (type === lastModifiedType) {
    index = files.findIndex((doc) => doc.id === id);
  } else {
    index = files.findIndex((doc) => doc.uid === id);
  }
  // Clone files and remove the file ( object )
  const clone = [...files];
  clone.splice(index, 1);

  return clone;
};

export const isEvaluationDeadlineOver = (event) => {
  return (
    get(
      getLatestBooking(event, 'eventCompanys'),
      'evaluationDeadlineDateTime',
      null,
    ) <= getNow()
  );
};

export const isEvaluationDeadlineExtendRequestPending = (event) => {
  const { evaluationDeadlineExtensionRequestStatus } = getLatestBooking(
    event,
    'eventCompanys',
  );

  return evaluationDeadlineExtensionRequestStatus === STATUS_DRAFT;
};

export const isMarkPresentDisabled = (currentEvent, activeRows) => {
  if (isEventLiveNow(currentEvent)) {
    return !activeRows.length;
  }
  return true;
};

/**
 * Ticket price of the event for current user
 * @param {object} event
 * @returns {number}
 */
export const getTicketPrice = (event, roleId) => {
  if (!event) {
    return 0;
  }
  // Get ticket price with role
  if (checkIfUserIsCandidate(roleId)) {
    return get(event, 'userTicketPrice', 0);
  }
  return get(event, 'recruiterTicketPrice', 0);
};

/**
 * Calculate promo code discount
 * @param {number} cost
 * @param {string} type
 * @return {number}
 */
export const getDiscountedPrice = (cost, type, value) => {
  switch (type) {
    case 'Percentage':
      const valPercentage = 1 - value / 100;
      return Math.floor(cost * valPercentage * 100) / 100;
    default:
      return cost - value;
  }
};

export const getDiscountAmmount = (cost, type, value) => {
  switch (type) {
    case 'Percentage':
      const valPercentage = value / 100;
      return Math.floor(cost * valPercentage * 100) / 100;
    default:
      return value;
  }
};

export const getTaxAmmount = (percentage, cost) => {
  return Math.ceil(cost * percentage) / 100;
};

/**
 * Launch Pip mode
 * @param {object} event
 * @returns {undefined}
 */

export const launchPip = (event) => {
  const streamUrlsData = get(event, 'streamUrlsData', []);
  const container = document.getElementById('live-event');
  if (container) {
    container.style.display = 'block';
    const iframe = document.getElementById('live-event-iframe');
    if (iframe) {
      const url = get(streamUrlsData[0], 'url', null);
      iframe.src = `${url}?autoplay=1&enablejsapi=1`;
    }
    const name = document.getElementById('live-event-name');
    if (name) {
      name.innerHTML = get(streamUrlsData[0], 'title', '');
    }
    const id = document.getElementById('live-event-id');
    if (id) {
      id.innerHTML = JSON.stringify(event);
    }
  }
};

export const checkEventBookingInfo = (eventId) => {
  return getBookedEventInfo(eventId);
};

/**
 * Checks if company profile links are unique
 * @param {object} formData
 * @return {boolean}
 */
export const hasUniqueProfileLinks = async (formData) => {
  // Mark flag
  let markDuplicate = false;
  // Vailidate profile links
  const profileLinks = await get(formData, 'profileLinks', []);

  let temp = [];

  profileLinks.forEach((item) => {
    const link = get(item, 'link', null);

    if (link && !temp.includes(link)) {
      temp = temp.concat(link);
    } else {
      markDuplicate = true;
    }
  });

  return markDuplicate;
};

/**
 * Checks if skills sum is 100 or not
 * @param {object} formData
 * @return {boolean}
 */
export const validateSkillsSum = async (formData) => {
  const technical = get(formData, 'technical', 0);
  const management = get(formData, 'management', 0);
  const technoFunctional = get(formData, 'technoFunctional', 0);

  const res = +technical + +management + +technoFunctional;

  if (res > 100 || res < 100) {
    return true;
  }
  return false;
};

export const handleJoinEvent = async (
  event,
  roleId,
  userData,
  history,
  launchPipDelay,
  callback,
) => {
  const id = get(event, 'id', null);

  if (!id) {
    return;
  }

  if (checkIfUserIsRecruiter(roleId)) {
    const doesRecruiterHaveActiveJobs = await checkIfCompanyHaveAnyActiveJobs();
    if (!doesRecruiterHaveActiveJobs) {
      Notification(
        'error',
        'Join Event',
        'It seems you don’t have any active Job Postings. Please consider creating a new Job Posting or activating an old one from the Job Opportunities page.',
      );
      history.push(`${RecruiterRoutes.recruit}2`);
      return;
    }
  }

  if (checkIfUserIsRecruiter(roleId) || checkIfUserIsCandidate(roleId)) {
    await joinEvent(id, { attendance: ATTENDANCE_PRESENT }, roleId)
      .then(async (response) => {
        const isAutoPresentEnabled = get(
          response,
          'isAutoPresentEnabled',
          true,
        );
        if (!isAutoPresentEnabled) {
          Notification(
            'info',
            'Join event',
            checkIfUserIsRecruiter(roleId)
              ? RECRUITER_JOIN_FAIL_MESSAGE
              : CANDIDATE_JOIN_FAIL_MESSAGE,
          );
        }
        if (checkIfUserIsRecruiter(roleId)) {
          history.push(`${RecruiterRoutes.recruit}1?event=${id}&jobs=[]`);
          if (isAutoPresentEnabled) {
            Notification(
              'success',
              'Join event',
              RECRUITER_JOIN_EVENT_SUCCESS_MESSAGE,
            );
          }
        } else {
          history.push(viewEventUrl(CANDIDATE, event.id));
          if (isAutoPresentEnabled) {
            Notification('success', 'Join event', JOIN_EVENT_SUCCESS_MESSAGE);
          }
        }

        // Adding User to chatroom and Sending default message from organizer
        try {
          if (config.chatEnabled) {
            const eventData = eventDataRegardingChatroom(event);
            const organizerData = await eventOrgainizerDataRegardingChatroom(
              event?.companyId,
            );

            const userAdded = await addUserToChatroom(id, userData);

            // In case groupchat doesn't exists
            // 400 defines that the chatroom doesn't exist.
            if (userAdded.statusCode === 404) {
              await createChatroom(
                eventData,
                [userData, organizerData],
                CHATROOM_TYPE_GROUP,
                true,
              );
            }
            await createChatroom(
              eventData,
              [userData, organizerData],
              CHATROOM_TYPE_PRIVATE,
              true,
            );
          }
        } catch (error) {
          Notification('error', 'Join event', 'Failed to join in chatroom');
        }

        // Launch Pip after launchPipDelay miliseconds
        if (!checkIfUserIsCandidate(roleId)) {
          setTimeout(() => launchPip(event), launchPipDelay);
        }
        if (callback) callback();
      })
      .catch(() => {
        Notification('error', 'Join event', 'Join event failed');
      });
  }
};

export const SimilarEventsStore = () => {
  const {
    isSimilarEventsLoading,
    similarEvents,
    similarEventsError,
  } = useSelector((state) => state.events);
  return {
    isSimilarEventsLoading,
    similarEvents,
    similarEventsError,
  };
};

export const getTicketPriceAndCurrency = (event) => {
  let recruiterTicketPrice = get(event, 'recruiterTicketPrice', null);
  let candidateTickerPrice = get(event, 'userTicketPrice', null);
  const thirdPartyBookings = get(event, 'thirdPartyData', []);
  const thirdPartyBookingData = first(thirdPartyBookings);

  // if Third party booking is active then we will display ticket price for that.
  recruiterTicketPrice =
    get(thirdPartyBookingData, 'recruiterTicketPrice', null) ||
    recruiterTicketPrice;

  candidateTickerPrice =
    get(thirdPartyBookingData, 'userTicketPrice', null) || candidateTickerPrice;

  const currency = thirdPartyBookingData
    ? get(thirdPartyBookingData, 'currency', '')
    : get(event, 'currency', '');

  return { recruiterTicketPrice, candidateTickerPrice, currency };
};

export const getEventOptions = (events, idKey = false) => {
  return events && isArray(events)
    ? events.map((event, i) => ({
        id: !idKey ? i : event.id,
        name: event.name,
      }))
    : [];
};

/**
 * Re-arrange order by updatedAt & desc
 * @param {array} jobs
 * @returns {array}
 */
export const jobsReorder = (jobs) => {
  if (jobs && jobs.length) {
    return orderBy(jobs, 'updatedAt', 'desc');
  }
  return [];
};

/**
 * Finds current company with companyId from events & returns updatedAt
 * @param {object} event
 * @param {number} companyId
 * @returns {number}
 */
const getCurrentCompanyUpdatedAt = (event, companyId) => {
  // Find company with company id
  const company = event.companies.find(
    (company) => company.companyId === companyId,
  );

  return company.updatedAt;
};

/**
 *  Checks for recently updatedAt value of event companies of events and returns recent event
 * @param {array} events
 * @param {number} companyId
 * @returns {object}
 */
export const getRecentlyUpdatedAtEvent = (events, companyId) => {
  if (!companyId) {
    return events[0];
  }

  // Order events with company
  const res = orderBy(
    events,
    (event) => getCurrentCompanyUpdatedAt(event, companyId),
    'desc',
  );

  // Return the event with recently company updateAt
  return res[0];
};

const StyledAttendeesManagementStatus = styled.div`
  ${DISPLAY_FLEX('row')}
`;

const StyledRedInfoText = styled.span`
  color: ${COLORS.CUSTOM_RED};
`;

export const getEventsPageByRoleAndEvent = (roleId, eventId) => {
  switch (true) {
    case checkIfUserIsCandidate(roleId):
      return CandidateRoutes.viewEvent(eventId);
    case checkIfUserIsRecruiter(roleId):
      return RecruiterRoutes.viewEvent(eventId);
    default:
      return OrganizerRoutes.viewEvent(eventId);
  }
};

export const makeCreateEventInitialData = (data) => {
  // Constant required values
  if (!data || isEmpty(data)) {
    return {};
  }
  const { country, zip, profileLinks, industries, timezone, eventType } = data;

  const setEventType = (eventType) => {
    return eventType === ONLINE_EVENT ? 'Online' : 'Hybrid';
  };

  const result = {
    country,
    zipcode: zip,
  };

  const optionalFieldNames = [
    'profileLinks',
    'technical',
    'management',
    'technoFunctional',
    'expectedNoOfAttendees',
    'termsAndConditions',
    'timezone',
    'eventType',
    'domains',
    'categories',
    'typesOfEngagement',
    'typesOfJob',
  ];

  optionalFieldNames.forEach((optionalFieldName) => {
    switch (optionalFieldName) {
      case 'domains':
        {
          const value = industries;
          if (value.length) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'timezone':
        {
          const value = timezone;
          if (value) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'eventType':
        {
          const value = eventType;
          if (value) {
            result[optionalFieldName] = setEventType(value);
          }
        }
        break;
      case 'profileLinks':
        {
          const value = profileLinks;
          if (value.length) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'technical':
      case 'management':
      case 'technoFunctional':
        {
          const value = get(data, optionalFieldName, 0);
          if (isNumber(value)) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'expectedNoOfAttendees':
      case 'termsAndConditions':
        {
          const fallbackValue =
            optionalFieldName === 'expectedNoOfAttendees' ? null : [];
          const value = get(data, optionalFieldName, fallbackValue);
          if (value) {
            result[optionalFieldName] = value;
          }
        }
        break;
      case 'categories':
        {
          const value = get(data, optionalFieldName, []);
          if (value.length) {
            result[optionalFieldName] = uniq(
              value.map((category) => get(category, 'id', '')),
            );
          }
        }
        break;
      case 'typesOfJob':
        result[optionalFieldName] = ['Full-time', 'Part-time'];
        break;
      case 'typesOfEngagement':
        result[optionalFieldName] = [
          'Internship',
          'Permanent Employment',
          'Contract & Consulting',
          'Freelancing',
        ];
        break;
      default: {
        const value = get(data, optionalFieldName, null);
        // Check if value exist and not assigned before, to prevent overriding
        if (value && !result[value]) {
          result[optionalFieldName] = value;
        }
      }
    }
  });

  return result;
};

/**
 * Modifies array data and make it suitable for Select ( ant )
 * @param {array} items
 * @param {boolean} idKey
 * @returns
 */
export const transformToOptions = (items, idKey = false) => {
  return items && isArray(items)
    ? items.map((item, i) => ({
        key: !idKey ? i : item?.key,
        name: item?.title,
      }))
    : [];
};

export const isFormErrors = (errors, fields) => {
  for (let i = 0; i < fields.length; i++) {
    if (errors.includes(fields[i])) {
      return true;
    }
  }
  return false;
};

export const handleEditEventData = (origialList, newList) => {
  if (!newList.length) {
    return [];
  }
  const deleteList = origialList
    .filter((item) => isArray(newList) && !newList.includes(item.name))
    .map((item) => {
      return {
        ...item,
        operation: DELETE,
      };
    });
  const addList = newList
    .filter((item) => {
      const workAuth = origialList.find((o) => o.name === item);
      return !workAuth;
    })
    .map((item) => {
      return {
        name: item,
        operation: ADD,
      };
    });

  return addList.concat(deleteList);
};
