import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Tabs from 'antd/es/tabs';
import { MARGIN } from '../../styles/constants/display';
import Overview from '../../components/Community/OverviewTab';
import JobsTab from '../../components/Community/JobsTab';
import EventsTab from '../../components/Community/EventsTab';
import AboutTab from '../../components/Community/AboutTab';
import { COLORS } from '../../styles/constants/colors';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { useLazyGetJobByIdQuery } from '../../store/features/rtkApi/common';
import { useSignupOrLogin } from '../../hooks/useSignupOrLogin';
import SignInModal from '../../components/Community/SignInModal';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const CompanyContent = ({ modalRef }) => {
  const [currentTab, setCurrentTab] = React.useState(1);
  const { search } = useLocation();
  const [customJobId, setCustomJobId] = React.useState();
  const [getJobById, { data: jobById }] = useLazyGetJobByIdQuery();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    let jobId;
    if (searchParams.has('jobId')) {
      jobId = searchParams.get('jobId');
      setCurrentTab(2);
      setCustomJobId(jobId);
      getJobById(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearJobId = React.useCallback(() => {
    setCustomJobId();
  }, []);
  const {
    isSignInModalVisible,
    openSignInModal,
    closeSignInModal,
    closeModalWithoutClearingStorage,
  } = useSignupOrLogin(modalRef);

  return (
    <StyledContentSection>
      <StyledTabs
        tabBarGutter={32}
        activeKey={currentTab.toString()}
        onTabClick={(key) => setCurrentTab(key)}
        destroyInactiveTabPane
        size="large"
      >
        <Tabs.TabPane
          tab="Overview"
          key="1"
          className="outside-tabs"
          style={{
            fontWeight: 'bold',
          }}
        >
          <Overview changeTab={setCurrentTab} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Jobs" key="2">
          <JobsTab
            jobId={customJobId}
            clearJobId={clearJobId}
            jobById={jobById}
            openSignInModal={openSignInModal}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Events" key="3">
          <EventsTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="About" key="4">
          <AboutTab />
        </Tabs.TabPane>
      </StyledTabs>
      <SignInModal
        isModalVisible={isSignInModalVisible}
        closeModalAfterLogin={closeModalWithoutClearingStorage}
        closeModalWithoutLogin={closeSignInModal}
      />
    </StyledContentSection>
  );
};

export default CompanyContent;

const StyledContentSection = styled.section`
  ${MARGIN(30, 0)}
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    ${MARGIN(0, 0, 16, 15)}
  }

  & > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    & > .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: ${COLORS.DARK_GRAY};
        font-weight: ${FONT_WEIGHTS.MEDIUM};
        &:hover {
          color: ${COLORS.HELIOTROPE};
        }
      }
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${COLORS.HELIOTROPE};
      }
      padding: 16px 40px !important;

      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        padding: 16px 25px !important;
      }
    }
    & .ant-tabs-ink-bar {
      height: 3px;
      border-radius: 5px;
      background: ${COLORS.HELIOTROPE};
    }
  }
  .ant-tabs-nav:before {
    border-bottom-color: ${COLORS.SILVER_SAND};
  }
`;
