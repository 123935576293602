import React from 'react';
import styled from 'styled-components';
import { ButtonsProps } from '../../interfaces/components/Buttons';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { COLORS } from '../../styles/constants/colors';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../../styles/constants/display';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';
import { getTheme } from '../../utils/common';

export const PrimaryButton: React.FC<ButtonsProps> = ({
  onClick,
  theme,
  children,
  type,
  disabled = false,
  mobilePadding,
  padding,
  margin,
  icon,
  width,
  height,
  minWidth,
  id,
  fullWidth,
  customTheme,
}) => {
  return (
    <MainButton
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      theme={theme}
      mobilePadding={mobilePadding}
      padding={padding}
      icon={icon}
      margin={margin}
      width={width}
      minWidth={minWidth}
      height={height}
      id={id}
      fullWidth={fullWidth}
      customTheme={customTheme}
    >
      {children}
    </MainButton>
  );
};

export const SecondaryButton: React.FC<ButtonsProps> = ({
  onClick,
  theme,
  children,
  disabled = false,
  type,
  margin,
  padding,
  mobilePadding,
  width,
  height,
  id,
}) => {
  return (
    <OutlinedButton
      type={type || 'button'}
      onClick={onClick}
      theme={theme}
      disabled={disabled}
      margin={margin}
      padding={padding}
      mobilePadding={mobilePadding}
      width={width}
      height={height}
      id={id}
    >
      {children}
    </OutlinedButton>
  );
};

export const TextButton: React.FC<ButtonsProps> = ({
  children,
  color,
  onClick,
  fontWeight,
  font,
  type,
  id,
  className = '',
}) => {
  return (
    <StyledTextButton
      type={type || 'button'}
      color={color}
      onClick={onClick}
      fontWeight={fontWeight}
      font={font}
      id={id}
      className={className}
    >
      {children}
    </StyledTextButton>
  );
};

export const IconButton: React.FC<ButtonsProps> = ({
  icon,
  onClick,
  color,
  type,
  ref,
  theme,
  borderRadius,
  disabled = false,
  id,
}) => {
  return (
    <StyledIconButton
      disabled={disabled}
      type={type || 'button'}
      onClick={onClick}
      ref={ref}
      theme={theme}
      borderRadius={borderRadius}
      id={id}
    >
      <Icon src={icon} alt="icon-button" color={color} />
    </StyledIconButton>
  );
};

const Button = styled.button`
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
  font-size: ${FONT[15]};
  padding: 5px;
  ${DISPLAY_FLEX('row', 'center', 'center')}

  span {
    font-size: ${FONT[15]};

    @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
      font-size: ${FONT[14]};
    }
  }

  @media (max-width: ${BREAKPOINTS.LAPTOP_MAX}) {
    font-size: ${FONT[14]};
    height: 32px;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${COLORS.ARGENT};
  }

  &:hover {
    border-radius: 3px;
  }
`;

export const MainButton = styled(Button)<ButtonsProps>`
  white-space: nowrap;
  color: ${COLORS.WHITE};
  background-color: ${(props) => getTheme(props.theme)};
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  ${(props) =>
    props.padding ? `padding: ${props.padding};` : `padding: 5px 16px;`}
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  ${({ width }) => (width ? `width: ${width}px;` : '')};
  ${({ height }) => (height ? `height: ${height}px;` : '')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')};
  font-weight: ${FONT_WEIGHTS.MEDIUM};
  ${({ fullWidth }) => (fullWidth ? `width: 100%;` : '')};
  ${({ customTheme }) =>
    customTheme ? `background-color: ${customTheme};` : ''};

  svg {
    fill: ${COLORS.WHITE};
    ${MARGIN(0, 5, 0, 0)}
  }

  &:hover:enabled,
  &:focus,
  &:active {
    background-color: ${COLORS.WHITE};
    color: ${(props) => getTheme(props.theme)};
    border: 1px solid ${(props) => getTheme(props.theme)};
    ${({ customTheme }) =>
      customTheme ? `background-color: ${COLORS.WHITE};` : ''};
    ${({ customTheme }) => (customTheme ? `color: ${customTheme}` : '')};
    ${({ customTheme }) =>
      customTheme ? `border : 1px solid ${customTheme};` : ''}

    svg {
      fill: ${(props) => getTheme(props.theme)};
    }
  }

  &:disabled {
    &:hover,
    &:focus,
    &:active {
      background-color: #ccc;
      color: ${COLORS.WHITE};
      border: 1px solid transparent;
    }
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${(props) => props.mobilePadding || `5px 10px`};
    width: fit-content;
  }
`;

export const OutlinedButton = styled(Button)<ButtonsProps>`
  border: 1px solid ${(props) => getTheme(props.theme)};
  color: ${(props) => getTheme(props.theme)};
  background-color: ${COLORS.WHITE};
  transition: all 0.3s ease-in-out;
  margin: ${(props) => props.margin};
  ${(props) =>
    props.padding ? `padding: ${props.padding};` : `padding: 5px 16px;`}
  ${({ width }) => (width ? `width: ${width}px;` : '')};
  ${({ height }) => (height ? `height: ${height}px;` : '')};

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => getTheme(props.theme)};
    color: ${COLORS.WHITE};
  }

  &:disabled {
    color: ${COLORS.WHITE};
    background: ${COLORS.ARGENT};
    border: ${COLORS.WHITE};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${(props) =>
      props.mobilePadding ? `padding: ${props.mobilePadding};` : ''}
  }
`;

export const StyledTextButton = styled.div<ButtonsProps>`
  border: none;
  ${PADDING(0)}
  font: ${(props) => props.font || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};

  cursor: pointer;
  color: ${(props) => props.color};
  background-color: ${COLORS.WHITE};
  max-width: max-content;
  ${DISPLAY_FLEX('row', null, 'center')}
`;

const StyledIconButton = styled.button<ButtonsProps>`
  background-color: ${(props) => getTheme(props.theme)};
  border: none;
  border-radius: ${(props) => props.borderRadius};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    transform: none;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 31px;
    width: 31px;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  filter: ${(props) => props.color};
  margin-left: -1px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: 15px;
    width: 15px;
  }
`;
