import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { MainButton } from '../../../../components/shared-components/Button';
import {
  DocumentViewer,
  HiddenMobile,
} from '../../../../components/shared-components/DocumentViewerModal';
import { StyledModal } from '../../../../components/shared-components/Modal';
import { MediumFont } from '../../../../components/shared-components/Texts';
import { BREAKPOINTS } from '../../../../styles/constants/breakpoints';
import { FONT_WEIGHTS } from '../../../../styles/constants/typography';
import { getTheme } from '../../../../utils/common';
import { getFilePath } from '../../../../utils/profile';
import { ResponsiveModalHeader } from '../../../../components/shared-components/ResponsiveModalHeader';
import { currencyConverting } from '../../../../utils/currencyConvertor';
import { DEFAULT_CURRENCY } from '../../../../constants/currency';

export const ViewProfileModal = ({
  visible,
  profileData,
  handleModalClose,
  application,
}: {
  visible: boolean;
  profileData: any;
  handleModalClose: () => void;
  application: any;
}) => {
  const {
    annualCompensation,
    experience,
    currentSalaryCurrency,
    currentCtcCurrency,
  } = profileData;

  const educations = get(profileData, 'educations', []);
  const certifications = get(profileData, 'certifications', []);

  const { currencyData } = useSelector((st: any) => st.commons);

  const recruiterCurrencyLocalFormat = currencyConverting({
    fromCurrency: get(
      currentSalaryCurrency,
      'code',
      currentCtcCurrency || DEFAULT_CURRENCY,
    ),
    fromAmount: annualCompensation,
    toCurrency: application
      ? get(application, 'job.currency')
      : DEFAULT_CURRENCY,
    currenciesData: currencyData,
  });

  const [certificationsList, setCertificationsList] = useState<any>([]);

  function getExperience(value: number) {
    const years = Math.floor(value / 12);
    const months = value % 12;
    return `${years} ${years > 1 ? 'Years' : 'Year'} ${months} ${
      months > 1 ? 'Months' : 'Month'
    }`;
  }

  const displayEducation = (data: any) => {
    return data.map((item: any) => (
      <div>
        <MediumFont fontWeight={FONT_WEIGHTS.SEMI_BOLD}>
          {item.major}
        </MediumFont>
        <MediumFont opacity="0.5">{item.degree}</MediumFont>
        <MediumFont opacity="0.5">{item.school}</MediumFont>
      </div>
    ));
  };

  useEffect(() => {
    if (certifications && certifications.length) {
      setCertificationsList([]);
      certifications.forEach((certificate: any) => {
        if (
          certificate.fileName &&
          certificate.fileName.trim() !== 'undefined' &&
          certificate.fileName.trim() !== 'null'
        ) {
          setCertificationsList((oldArr: any) => [
            ...oldArr,
            {
              uid: certificate.id,
              name: certificate.certificateName,
              status: 'done',
              url: getFilePath(certificate.fileName),
              fileName: certificate.fileName,
            },
          ]);
        }
      });
    }
    // eslint-disable-next-line
  }, [certifications]);

  return (
    <Modal
      theme="recruiter"
      width="60%"
      visible={visible}
      title={
        <>
          <ResponsiveModalHeader
            handleModalCancel={handleModalClose}
            title="Candidate Profile"
          />
          <HiddenMobile>Candidate Profile</HiddenMobile>
        </>
      }
      footer={
        <MainButton theme="recruiter" onClick={handleModalClose}>
          Close
        </MainButton>
      }
      onCancel={handleModalClose}
    >
      <StyledWrapper>
        <StyledHeading>Education Detail</StyledHeading>
        <StyledContainer>
          {educations.length ? (
            displayEducation(educations)
          ) : (
            <MediumFont opacity="0.5">Not Available</MediumFont>
          )}
        </StyledContainer>
      </StyledWrapper>

      <StyledWrapper>
        <StyledHeading>Work Experience</StyledHeading>
        <StyledContainer>
          <StyledFlex>
            <MediumFont fontWeight={FONT_WEIGHTS.SEMI_BOLD}>
              Total Work Experience:
            </MediumFont>
            <MediumFont opacity="0.5" margin="0 10px">
              {experience === null
                ? 'Not Available'
                : getExperience(experience)}
            </MediumFont>
          </StyledFlex>

          <StyledFlex>
            <MediumFont fontWeight={FONT_WEIGHTS.SEMI_BOLD}>
              Current Salary:
            </MediumFont>
            <MediumFont opacity="0.5" margin="0 10px">
              {annualCompensation === null || currentSalaryCurrency === null
                ? 'Not Available'
                : `${get(
                    currentSalaryCurrency,
                    'code',
                    currentCtcCurrency || DEFAULT_CURRENCY,
                  )} ${annualCompensation} Or ${recruiterCurrencyLocalFormat}`}
            </MediumFont>
          </StyledFlex>
        </StyledContainer>
      </StyledWrapper>

      <StyledWrapper>
        <StyledHeading>Certifications</StyledHeading>
        <StyledCertifications>
          {certificationsList.length ? (
            certificationsList.map((data: any) => (
              <DocumentViewer filePath={data.url} fileName={data.name} />
            ))
          ) : (
            <MediumFont opacity="0.5">Not Available</MediumFont>
          )}
        </StyledCertifications>
      </StyledWrapper>
    </Modal>
  );
};

const Modal = styled(StyledModal)`
  .ant-modal-content {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: 100%;
    }
  }
`;

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 20px;
  margin: 10px 0;
  background-color: ${() => `${getTheme('recruiter')}0F`};
  gap: 20px;
`;

const StyledHeading = styled.h1`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  font-size: 16px;

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-size: 18px;
  }
`;

const StyledWrapper = styled.div`
  margin: 20px 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin: 20px 0 50px 0;
  }
`;

const StyledFlex = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_2}) {
    margin: 10px 0;
  }

  & > div {
    white-space: nowrap;
  }
`;

const StyledCertifications = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: ${() => `${getTheme('recruiter')}0F`};
  padding: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-bottom: 40px;
  }
`;
