import { css } from 'styled-components';
import { COLORS } from '../../../styles/constants/colors';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { MARGIN } from '../../../styles/constants/display';

export const styledStateText = css`
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  color: ${COLORS.EERIE_BLACK};
  ${MARGIN(1, 0, 0, 0)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 12px;
    ${MARGIN(1, 0, 0, 4)}
  }
`;
