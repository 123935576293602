import moment from 'moment';
import { currentDate } from './formatDateTime';

export default (dateTime, isDateTimeInPast = false, shortenResult = false) => {
  // Reverse the logic based on 2nd argument, if available
  const res = isDateTimeInPast
    ? currentDate().diff(dateTime)
    : dateTime.diff(currentDate());

  let { days, hours, minutes, months } = moment.duration(res)._data;

  let result = '';

  if (days < 0) {
    days = 0;
  }

  if (hours < 0) {
    hours = 0;
  }

  if (minutes < 0) {
    minutes = 0;
  }

  if (months < 0) {
    months = 0;
  }

  if (months > 0) {
    result = months > 1 ? `${months} Mons` : `${months} Mon`;
  }

  if (days > 0) {
    result = days > 1 ? `${result} ${days} Days` : `${result} ${days} Day`;
  }

  // Sometime we only need shorted version of datetime result
  if (shortenResult && months > 0 && days > 0) return result;

  if (hours > 0) {
    result = hours > 1 ? `${result}  ${hours} Hrs` : `${result}  ${hours} Hr`;
  }

  if (shortenResult && days > 0 && hours > 0) return result;

  if (minutes > 0) {
    result =
      minutes > 1 ? `${result}  ${minutes} Mins` : `${result}  ${minutes} Min`;
  }

  return result || 'Over';
};
