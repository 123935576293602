import get from 'lodash/get';
import { viewEventUrl } from '../components/Events/Common';
import { ATTENDANCE_PRESENT } from '../constants';
import { RecruiterRoutes } from '../constants/routes';
import { getLatestBooking } from './event';
import { checkIfUserIsCandidate, checkIfUserIsRecruiter } from './userInfo';

/**
 * Takes event, id & roleId and returns join event status of the user or a company
 */
export const hasJoinedTheEvent = (event: any, id: number, roleId: number) => {
  if (!event || !id || !roleId) {
    return;
  }

  let currentProfile;
  if (checkIfUserIsCandidate(roleId)) {
    currentProfile = getLatestBooking(
      undefined,
      '',
      get(event, 'eventCandidates', []),
    );
  } else {
    currentProfile = getLatestBooking(
      undefined,
      '',
      get(event, 'eventCompanys', []),
    );
  }

  if (currentProfile) {
    const status = get(currentProfile, 'attendance', null);
    return ATTENDANCE_PRESENT === status;
  }
};

/**
 * Takes roleId & returns link text
 */
export const getLinkTextByRole = (roleId: number) => {
  let text;

  if (checkIfUserIsRecruiter(roleId)) {
    text = 'Job applications';
  } else if (checkIfUserIsCandidate(roleId)) {
    text = 'Jobs';
  }

  return text;
};

/**
 * Takes roleId & eventId as args and returns link
 */
export const getLinkByRole = (roleId: number, eventId: number) => {
  let link = `${viewEventUrl(roleId, eventId)}`;
  if (checkIfUserIsRecruiter(roleId)) {
    link = `${RecruiterRoutes.recruit}1?event=${eventId}&jobs=[]`;
  }
  return link;
};
