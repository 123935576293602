import React, { useEffect } from 'react';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { Col, Form, Modal, Row } from 'antd';
// import { Modal } from '../../../components/shared-components/Modal';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { BREAKPOINTS } from '../../../styles/constants/breakpoints';
import { CANDIDATE_COLOR } from '../../../constants';
import {
  FormGroup,
  TextBox,
} from '../../../components/shared-components/Form/Form';
import { largeFontMixin } from '../../../styles/constants/mixins';
import { COLORS } from '../../../styles/constants/colors';
import { DISPLAY_FLEX } from '../../../styles/constants/display';
import HI from '../../../assets/img/hi.png';
import { userProfileStore } from '../../../utils/profile';
import { createCandidateQuickProfile } from '../../../store/features/profile';

const CandidateName = ({ visible }) => {
  const [form] = Form.useForm();
  const { profileData } = userProfileStore();
  const dispatch = useDispatch();

  const handleFinish = ({ firstName, lastName }) => {
    const body = {
      name: `${firstName} ${lastName}`,
      email: get(profileData, 'email', null),
    };

    dispatch(createCandidateQuickProfile(body));
  };

  useEffect(() => {
    const input = document.getElementById('first-name');
    if (input) input.focus();
  }, []);

  return (
    <StyledModal
      centered
      visible={visible}
      footer={null}
      closable={false}
      header={null}
      zIndex={4000}
    >
      <div>
        <StyledCandidateProfile>
          <StyledQuickStart>
            <StyledQuickSubmissionTitle level={4}>
              Hi
              <span>
                <img src={HI} alt="hi-icon" />
              </span>
              What&apos;s your name?
            </StyledQuickSubmissionTitle>
            <FormGroup
              form={form}
              name="candidate_name"
              onFinish={handleFinish}
              expand
            >
              <StyledRow>
                <Col>
                  <TextBox
                    name="firstName"
                    placeholder="First Name"
                    rule="text"
                    id="first-name"
                  />
                </Col>
                <Col>
                  <TextBox
                    name="lastName"
                    placeholder="Last Name"
                    rule="text"
                  />
                </Col>
              </StyledRow>

              <StyledButton color={CANDIDATE_COLOR}>Get Started</StyledButton>
            </FormGroup>
          </StyledQuickStart>
        </StyledCandidateProfile>
      </div>
    </StyledModal>
  );
};

export default CandidateName;

const StyledCandidateProfile = styled.div`
  position: relative;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-top: 0px;
  }
`;

const StyledQuickStart = styled.div`
  position: relative;
`;

const StyledQuickSubmissionTitle = styled(Title)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  text-align: center;
  color: ${COLORS.WHITE} !important;
  margin-bottom: 20px !important;

  span {
    margin: 0 10px 0 6px;
  }
`;

export const StyledButton = styled.button`
  background-color: ${(props) => props.color};
  color: #fff;
  border-radius: 20px;
  padding: 4px 15px;
  border: 1px solid white;
  font-size: 14px;

  cursor: pointer;
  float: right;
  ${largeFontMixin}
`;

const StyledModal = styled(Modal)`
  .ant-modal-body,
  .ant-modal-content {
    border-radius: 20px;
    background-color: #6963aa;
  }
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    .ant-modal-content {
      top: 50%;
      transform: translateY(-50%);
      margin: 0 8px;
    }
    .ant-modal-body {
      padding: 18px;
    }
  }
`;

const StyledRow = styled(Row)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  gap: 14px;
  .ant-col {
    width: calc(50% - 7px);

    input {
      border-radius: 8px;
    }
  }
`;
