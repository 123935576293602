import { useSelector } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import trim from 'lodash/trim';
import {
  ACTIVE_JOB_TEXT,
  ADD,
  ANY_SKILL_ID,
  ANY_SKILL_LEVEL,
  ANY_SKILL_NAME,
  ANY_SKILL_PREFERENCE,
  CONTRACT_AND_CONSULTING_SELECT,
  DELETE,
  EDIT,
  FREELANCING,
  FULL_TIME_SELECT,
  HYBRID,
  HYBRID_TEXT,
  INACTIVE_JOB_TEXT,
  INTERNSHIP_SELECT,
  JOB_TYPE,
  LOCATION_SELECT,
  LOCATION_TYPE,
  ONSITE,
  ONSITE_TEXT,
  PERMANENT_EMPLOYMENT_SELECT,
  REMOTE,
  REMOTE_SELECT,
  REMOTE_TEXT,
  SHOW_ALL_TEXT,
  SkillLevelToTimelineMap,
  STATUS_ACTIVE,
} from '../constants';
import { candidateSkillLevel } from './profile';
import {
  createJobNotification,
  editJobNotification,
} from '../components/Notification/Jobs';
import {
  currencyConverting,
  currencyNameToCode,
  formatCurrency,
} from './currencyConvertor';

let skillEditArray = [];
export const JobDetailsStore = () => {
  const {
    isLoading,
    jobDetailsData,
    job,
    jobApplicationsData,
    jobOpportunitiesPagination,
    error,
  } = useSelector((state) => state.jobDetails);
  return {
    isLoading,
    jobDetailsData,
    jobApplicationsData,
    jobOpportunitiesPagination,
    job,
    error,
  };
};

export const jobStatusActiveOrInactive = [
  {
    id: '1',
    name: SHOW_ALL_TEXT,
  },
  {
    id: '2',
    name: ACTIVE_JOB_TEXT,
  },
  {
    id: '3',
    name: INACTIVE_JOB_TEXT,
  },
];

export const jobLocation = [
  {
    id: '1',
    name: REMOTE_TEXT,
  },
  {
    id: '2',
    name: ONSITE_TEXT,
  },
  {
    id: '3',
    name: HYBRID_TEXT,
  },
];

export const handleSkillsForInitialValues = (
  skills,
  setSkills,
  skillStep,
  setSkillStep,
  skillsList,
  setSkillsList,
  skillsArray,
  setMandatoryList,
  setContributedList,
) => {
  setSkills([]);
  setSkillStep([]);
  skillEditArray = skillsArray;
  skillsArray.forEach((skill) => {
    if (skill.skill) {
      setSkills((oldArr) => [
        ...oldArr,
        {
          id: skill.id,
          skill: {
            skillId: get(skill, 'skillId', null),
            skillName: get(skill, 'skill.skill', null),
          },
          level: get(skill, 'level', null),
          skillPreference: get(skill, 'skillPreference', null),
          operation: !skill.operation ? EDIT : skill.operation,
        },
      ]);

      setSkillStep((oldArr) => [
        ...oldArr,
        {
          id: get(skill, 'id', null),
          value: candidateSkillLevel(get(skill, 'level', null)),
        },
      ]);

      setSkillsList((oldArr) => [
        ...oldArr,
        {
          options: [],
        },
      ]);
      setMandatoryList((oldArr) => [...oldArr, skill.skillPreference]);
    }
  });
};

export const handleSkills = (
  skills,
  setSkills,
  skillStep,
  setSkillStep,
  skillsList,
  setSkillsList,
  skillsArray,
) => {
  setSkills([]);
  setSkillStep([]);
  setSkillsList([]);

  skillEditArray.forEach((skill) => {
    if (skill.skill) {
      setSkills((oldArr) => [
        ...oldArr,
        {
          id: skill.id,
          skillId: skill.skillId,
          skillName: skill.skill.skill,
          level: skill.level,
          skillPreference: skill.skillPreference,
          operation: DELETE,
        },
      ]);

      setSkillStep((oldArr) => [
        ...oldArr,
        {
          id: skill.id,
          value: candidateSkillLevel(skill.level),
        },
      ]);

      setSkillsList((oldArr) => [
        ...oldArr,
        {
          options: [],
        },
      ]);
    }
  });
  skillsArray.forEach((skill) => {
    setSkills((oldArr) => [
      ...oldArr,
      {
        id: skill.id,
        skillId: skill.skillId,
        skillName: skill.skillName,
        level: skill.level,
        skillPreference: skill.skillPreference,
        operation: ADD,
      },
    ]);

    setSkillStep((oldArr) => [
      ...oldArr,
      {
        id: skill.id,
        value: candidateSkillLevel(skill.level),
      },
    ]);

    setSkillsList((oldArr) => [
      ...oldArr,
      {
        options: [],
      },
    ]);
  });
};

export const yearsToMonths = (years, months) => {
  return years * 12 + months;
};

export const setLocation = (
  address = '',
  zipcode = '',
  city = '',
  state = '',
  country = '',
) => {
  return `${city}, ${country} , ${zipcode}`;
};

export const splitLocationToAddressFields = (location) => {
  let address = null;
  let zipcode = null;
  let city = null;
  let state = null;
  let country = null;

  if (!isString(location)) {
    return { address, zipcode, city, state, country };
  }

  [city, country, zipcode, state, address] = location.split(',');

  return {
    country: trim(country) || null,
    zipcode: trim(zipcode) || null,
    city: trim(city) || null,
    state: trim(state) || null,
    address: trim(address) || null,
  };
};

export const makeCreateJobData = (
  jobDetails,
  years,
  months,
  minQualification,
  address,
  zipcode,
  city,
  state,
  country,
  jobLocationType,
  categories,
) => {
  return {
    ...jobDetails,
    minExperience: yearsToMonths(years, months),
    minQualification,
    location: setLocation(address, zipcode, city, state, country),
    activeStatus: STATUS_ACTIVE,
    jobLocationType:
      jobLocationType === REMOTE_TEXT
        ? REMOTE
        : jobLocationType === HYBRID_TEXT
        ? HYBRID
        : ONSITE,
    categories: categories.map((id) => ({ categoryId: id })),
  };
};

// TODO: Fix tags issue, tags are not being updated.

export const makeEditJobData = (
  jobDetails,
  address,
  zipcode,
  city,
  state,
  country,
  minQualification,
  years,
  months,
  domains,
  jobLocationType,
  categories,
) => {
  let industryType;
  const {
    designation,
    jobBrief,
    jobType,
    salaryTo,
    salaryFrom,
    currency,
    salaryDependsOnExprience,
    salaryCurrencyId,
    skills,
  } = jobDetails;

  if (jobDetails.domains[0]?.id === undefined) {
    industryType = domains.map((domain, idx) => {
      return { id: idx, name: domain, operation: EDIT };
    });
  }

  return {
    designation,
    jobBrief,
    jobType,
    salaryCurrencyId,
    location: setLocation(address, zipcode, city, state, country),
    salaryFrom: salaryFrom || 0,
    salaryTo,
    skills,
    domains: domains[0].id !== undefined ? domains : industryType,
    minQualification,
    minExperience: yearsToMonths(years, months),
    currency,
    meta: {
      ...jobDetails.meta,
      typesOfEngagement: jobDetails.meta.typesOfEngagement,
    },
    jobLocationType:
      jobLocationType === REMOTE_TEXT
        ? REMOTE
        : jobLocationType === HYBRID_TEXT
        ? HYBRID
        : ONSITE,
    salaryDependsOnExprience,
    categories,
  };
};

export const objToArray = (obj) => {
  const arr = obj.map((att) => {
    return att.name;
  });
  return arr;
};

export const dateFormatter = (value) => {
  return moment.unix(value).format('l');
};

export const hasBodyData = (body) => {
  return body === undefined || body.skills.length === 0;
};

export const getAnySkillData = () => {
  const res = {
    id: 0,
    skillId: ANY_SKILL_ID,
    skillName: ANY_SKILL_NAME,
    level: ANY_SKILL_LEVEL,
    skillPreference: ANY_SKILL_PREFERENCE,
  };

  return res;
};

export const handleCreateJobNotification = (error, jobTitle) => {
  if (error) {
    return createJobNotification({
      name: jobTitle,
      status: 'error',
      error,
    });
  }
  createJobNotification({
    name: jobTitle,
    status: 'done',
  });
};
export const handleEditJobNotification = (error, jobTitle) => {
  if (error) {
    return editJobNotification({
      name: jobTitle,
      status: 'error',
      error,
    });
  }
  editJobNotification({
    name: jobTitle,
    status: 'done',
  });
};

export function filterJobsOpportunities(
  jobs,
  jobTypeFilter,
  engagementFilter,
  locationFilter,
) {
  const filterJobsOpp = jobs
    .filter((job) => {
      const jobType = get(job, 'jobType', []);
      if (jobTypeFilter === 1 || jobTypeFilter === null) {
        return true;
      }
      if (jobTypeFilter === FULL_TIME_SELECT) {
        return (
          isArray(jobType) && jobType.includes(JOB_TYPE.FULL_TIME.toString())
        );
      }
      return (
        isArray(jobType) && jobType.includes(JOB_TYPE.PART_TIME.toString())
      );
    })
    .filter((job) => {
      if (engagementFilter === 1 || engagementFilter === null) {
        return true;
      }
      if (engagementFilter === CONTRACT_AND_CONSULTING_SELECT) {
        return job.meta.typesOfEngagement.includes('Contract & Consulting');
      }
      if (engagementFilter === INTERNSHIP_SELECT) {
        return job.meta.typesOfEngagement.includes('Internship');
      }
      if (engagementFilter === PERMANENT_EMPLOYMENT_SELECT) {
        return job.meta.typesOfEngagement.includes('Permanent Employment');
      }
      if (engagementFilter === FREELANCING) {
        return job.meta.typesOfEngagement.includes('Freelancing');
      }
      return job;
    })
    .filter((job) => {
      if (locationFilter === REMOTE_SELECT) {
        return job.jobLocationType === LOCATION_TYPE.REMOTE;
      }
      if (locationFilter === LOCATION_SELECT) {
        return job.jobLocationType === LOCATION_TYPE.ONSITE;
      }
      return true;
    });
  return filterJobsOpp;
}

export const skillLevelName = (level) => {
  return get(SkillLevelToTimelineMap, level, null);
};

export const getJobAnnualPay = ({
  job,
  currency = null,
  currencyObject = null,
  currenciesData = null,
}) => {
  if (
    !job ||
    job.salaryDependsOnExprience ||
    (!job.currency && !job.salaryCurrency)
  ) {
    return 'Salary Depends On Experience';
  }

  // Populate code of job's currency
  const code = get(
    job,
    'salaryCurrency.code',
    currencyNameToCode(job.currency) || job.currency,
  );

  const salaryFrom = job.salaryFrom
    ? `${formatCurrency(code, job.salaryFrom)} - `
    : '';
  const salaryTo = formatCurrency(code, job.salaryTo);

  // Currency exchange does not occurs
  if (!currenciesData || (!currency && !currencyObject)) {
    return `${salaryFrom}${salaryTo}`;
  }

  // Occurs
  const toCurrency = get(currencyObject, 'code', currency);
  const convertedCurrency = currencyConverting({
    fromCurrency: code,
    fromAmount: job.salaryTo,
    toCurrency,
    currenciesData,
  });

  const convertedStartingSalary = currencyConverting({
    fromCurrency: code,
    fromAmount: job.salaryFrom,
    toCurrency,
    currenciesData,
  });

  // Failed to exchange
  if (!convertedCurrency) {
    return `${salaryFrom}${salaryTo}`;
  }

  return `${salaryFrom}${salaryTo}  Or  ${
    job.salaryFrom ? `${convertedStartingSalary} - ` : ''
  }${convertedCurrency}`;
};
