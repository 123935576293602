export default {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  chatBaseURL: process.env.REACT_APP_CHAT_BACKEND_URL,
  appEnv: process.env.REACT_APP_APP_ENV || 'local',
  google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebook_client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  linkedin_client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  under_dev: !(process.env.REACT_APP_UNDER_DEV === 'false'),
  appURL: process.env.REACT_APP_APP_URL,
  mainWebsiteUrl: process.env.REACT_APP_MAIN_WEBSITE_URL,
  hjid: process.env.REACT_APP_HJID,
  hjsv: process.env.REACT_APP_HJSV,
  aws_cdn_url: process.env.REACT_APP_AWS_CDN_URL,
  sentry_dsn: process.env.REACT_APP_SENTRY_DSN || '',
  chatEnabled: process.env.REACT_APP_CHAT_ENABLED === 'true',
  crispId: process.env.REACT_APP_CRISP_WEBSITE_ID || '',
  graphQLUri: process.env.REACT_APP_STEPZEN_API_URI || '',
  graphQLKey: process.env.REACT_APP_STEPZEN_API_KEY || '',
  analyticsBaseUrl: process.env.REACT_APP_ANALYTICS_BACKEND_URL,
  twitterAnalyticsKey: process.env.REACT_APP_TWITTER_ANALYTICS_KEY,
  fbPixelsKey: process.env.REACT_APP_FB_PIXELS_KEY,
  gtmId: process.env.REACT_APP_GTM_ID,
  twitterConsumerKey: process.env.REACT_APP_TWITTER_CLIENT_ID,
  twitterConsumerSecret: process.env.REACT_APP_TWITTER_CLIENT_SECRET_KEY,
  driveApiKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
  api_url: process.env.API_URL,
  giphyKey: process.env.REACT_APP_GIPHY_KEY || '',
  recoremWalletAddress: process.env.REACT_APP_RECOREM_WALLET_ADDRESS,
  marketingPublicApiUrl: process.env.REACT_APP_MARKETING_PUBLIC_API_URL,
  slackAuthUrl: process.env.REACT_APP_SLACK_AUTH_URL || '',
  discordAuthUrl: process.env.REACT_APP_DISCORD_AUTH_URL || '',
  telegramBotUrl: process.env.REACT_APP_TELEGRAM_BOT_URL || '',
};
