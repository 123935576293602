import React from 'react';
import styled from 'styled-components';
import { TagsProps } from '../../interfaces/components/Tags';
import { FONT, FONT_WEIGHTS } from '../../styles/constants/typography';

export const Tags: React.FC<TagsProps> = ({
  color,
  textColor,
  children,
  margin,
}) => {
  return (
    <Wrapper color={color} textColor={textColor} margin={margin}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<TagsProps>`
  border-radius: 3px;
  background-color: ${(props) => props.color};
  display: inline-block;
  padding: 6px 15px;
  color: ${(props) => props.textColor};
  font-size: ${FONT[12]};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  margin: ${(props) => props.margin || ''};
`;
