import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import isArray from 'lodash/isArray';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { CompanyCode } from '../../constants/attendees';
import { checkIfUserIsOrganizer, getRoleId } from '../../utils/userInfo';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import {
  largeFontMixin,
  mediumHeadingMixin,
  mediumFontMixin,
  SpeciallargeFontMixin,
} from '../../styles/constants/mixins';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';
import { RenderButtonTags } from '../Events/Common';
import {
  getUserBackgroundColorWithRoleId,
  getUserColorWithRoleId,
} from '../../styles/utils';

const Information = ({
  analysisFor = CompanyCode,
  isPastEventAnalyticsModal,
  data,
}) => {
  const roleId = getRoleId();

  const getSkillsTag = (skills = []) => {
    return (
      <StyledSkillRow>
        {skills.map((skill, idx) => {
          return (
            <Col key={idx.toString()}>
              <RenderButtonTags
                text={skill}
                color={getUserColorWithRoleId(roleId)}
                backgroundColor={getUserBackgroundColorWithRoleId(roleId)}
              />
            </Col>
          );
        })}
      </StyledSkillRow>
    );
  };

  const organizerEventInformation = [
    {
      key: 'Jobs With No Application',
      itemValue: data ? data.noOfJobsWithNoApplication : 0,
    },
    {
      key:
        analysisFor === CompanyCode
          ? 'Interview call letters issued'
          : 'Interview request',
      itemValue: data ? data.noOfInterviewScheduled : 0,
    },
    {
      key: 'Candidate with 0% skill match',
      itemValue: data ? data.noOfZeroSkillMatchCandidates : 0,
    },
    {
      key: 'Most Popular Skill in job opportunities',
      itemValue: data ? getSkillsTag(get(data, 'majorSkills', [])) : 'N/A',
      mobileNewLine: true,
    },
  ];

  const initialValue = 0;
  if (!data) {
    data = {
      noOfSimilarJobs: initialValue,
      noOfQualifiedCandidate: initialValue,
      noOfJobApplications: initialValue,
      noOfJobApplicationsSelected: initialValue,
      noOfInterviews: initialValue,
    };
  }

  const {
    noOfSimilarJobs,
    noOfQualifiedCandidate,
    noOfJobApplications,
    noOfJobApplicationsSelected,
    //  noOfInterviews,
  } = data;
  const pastEventInformation = [
    {
      key: 'Similar Jobs',
      itemValue: noOfSimilarJobs,
    },
    {
      key: (
        <StyledHorizontalAlignRow>
          Qualified Candidates
          <StyledTootip
            placement="top"
            title="Applications with more than 65% skill match"
          >
            <StyledInfoIcon />
          </StyledTootip>
        </StyledHorizontalAlignRow>
      ),
      itemValue: noOfQualifiedCandidate,
    },

    {
      key: 'Applications Received',
      itemValue: noOfJobApplications,
    },

    {
      key: 'Shortlisted Candidates',
      itemValue: noOfJobApplicationsSelected,
    },
    /*
    {
      key: 'Interviews Scheduled',
      itemValue: noOfInterviews,
    },
    */
  ];

  const getInformationRow = (item, idx) => {
    const { itemValue } = item;
    return (
      <StyledRow key={idx.toString()}>
        <Col lg={8} xs={22}>
          <StyledItemTitle>{item.key}</StyledItemTitle>
        </Col>

        <Col lg={16} xs={get(item, 'mobileNewLine', false) ? 24 : 2}>
          {isArray(itemValue) ? (
            itemValue.length > 1 ? (
              <Row>
                {itemValue.map((value, i) => {
                  return (
                    <StyledItemText key={i.toString()}>{value}</StyledItemText>
                  );
                })}
              </Row>
            ) : (
              <StyledItemTextCont> {itemValue[0]}</StyledItemTextCont>
            )
          ) : (
            <StyledItemTextCont> {itemValue}</StyledItemTextCont>
          )}
        </Col>
      </StyledRow>
    );
  };

  return (
    <StyledContainer>
      <StyledLevelThreeHeading>
        {isPastEventAnalyticsModal
          ? 'Job Specific Additional Information'
          : 'Additional Information'}
      </StyledLevelThreeHeading>
      <StyledInformationContainer>
        {isPastEventAnalyticsModal && !checkIfUserIsOrganizer(getRoleId())
          ? pastEventInformation.map((item, i) => getInformationRow(item, i))
          : organizerEventInformation.map((item, i) =>
              getInformationRow(item, i),
            )}
      </StyledInformationContainer>
    </StyledContainer>
  );
};

export default Information;

const StyledRow = styled(Row)`
  margin-top: 20px;
`;

const text = css`
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  ${SpeciallargeFontMixin};
  opacity: 0.7;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    letter-spacing: 0;
    ${mediumFontMixin};
  }
`;

const StyledItemTitle = styled.span`
  ${text};
`;

const StyledItemText = styled.span`
  ${text};
`;

const StyledContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledLevelThreeHeading = styled.h3`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  color: ${COLORS.CHINESE_BLACK};
  ${mediumHeadingMixin};

  @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
    font-weight: ${FONT_WEIGHTS.BOLD};
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    letter-spacing: 0px;
    color: ${COLORS.DAVYS_GREY};
  }
`;

const StyledInformationContainer = styled.div`
  padding-left: 20px;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding-left: 0;
  }
`;

const StyledItemTextCont = styled.span`
  ${largeFontMixin};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  opacity: 0.7;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${mediumFontMixin};
  }
`;

const StyledSkillRow = styled(Row)`
  gap: 5px;
  row-gap: 5px !important;
`;

const StyledTootip = styled(Tooltip)`
  font-size: inherit;
  font-weight: inherit;
`;

const StyledInfoIcon = styled(InfoCircleOutlined)`
  svg {
    width: 15px;
    height: 15px;

    @media (min-width: ${BREAKPOINTS.DESKTOP_MIN}) {
      width: 18px;
      height: 18px;
    }
  }
`;

const StyledHorizontalAlignRow = styled.span`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: inherit;
  gap: 5px;
  ${DISPLAY_FLEX('row', null, 'center')}
`;
