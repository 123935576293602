import api from './api';
import { APIs, Method } from '../constants';

const requestNewSkill = async (body) => {
  const url = APIs.skill;
  return api({
    method: Method.post,
    url,
    body,
  });
};

export { requestNewSkill };
