import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Form } from 'antd';
import ResponsiveModalHeader from './ResponsiveModalHeader';
import { FormGroup, InputTextArea } from '../shared-components/Form/Form';
import { cancelEventData } from '../../store/features/events';
import {
  OrganizerRoutes,
  RecruiterRoutes,
  CandidateRoutes,
  AdminRoutes,
} from '../../constants/routes';
import { cancelEventNotification } from '../Notification/Event';
import {
  getRoleId,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  checkIfUserIsCandidate,
} from '../../utils/userInfo';
import { StyledModal } from '../shared-components/Modal';
import { HiddenMobile } from '../shared-components/DocumentViewerModal';
import { LargeFont } from '../shared-components/Texts';
import { FONT_WEIGHTS } from '../../styles/constants/typography';
import { MainButton, OutlinedButton } from '../shared-components/Button';
import { COLORS } from '../../styles/constants/colors';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const CancelEventModal = ({ eventTitle, eventId, handleClose, visible }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const roleId = getRoleId();

  const handleNotification = (error) => {
    if (error) {
      handleClose();
      return cancelEventNotification({
        name: eventTitle,
        status: 'error',
        error,
      });
    }
    cancelEventNotification({
      name: eventTitle,
      status: 'done',
    });

    let redirectURL;

    switch (true) {
      case checkIfUserIsOrganizer(roleId):
        redirectURL = OrganizerRoutes.events;
        break;
      case checkIfUserIsRecruiter(roleId):
        redirectURL = RecruiterRoutes.event;
        break;
      case checkIfUserIsCandidate(roleId):
        redirectURL = CandidateRoutes.event;
        break;
      default:
        redirectURL = AdminRoutes.event.event;
    }

    history.push(redirectURL);
  };

  const handleCancel = () => {
    form.resetFields();
    handleClose();
  };

  const handleFinish = async (formData) => {
    let { reason } = formData;
    if (!reason) {
      reason =
        'No reason specified. Please contact organizer for more details.';
    }
    // Dispatch cancelEventData with event ID and payload
    dispatch(cancelEventData(eventId, { reason }, handleNotification));
  };

  const modalTitle = 'Manage Event';

  return (
    <Modal
      zIndex={3000}
      theme="danger"
      visible={visible}
      footer={
        <StyledFooter>
          <StyledOutlinedButton onClick={handleCancel}>
            Close
          </StyledOutlinedButton>
          <MainButton onClick={handleFinish} type="submit" theme="danger">
            Proceed to Cancel
          </MainButton>
        </StyledFooter>
      }
      width="60%"
      onCancel={handleCancel}
      title={
        <>
          <ResponsiveModalHeader
            handleModalCancel={handleCancel}
            title={modalTitle}
            type="danger"
          />
          <HiddenMobile>{modalTitle}</HiddenMobile>
        </>
      }
    >
      <FormGroup
        form={form}
        name="cancelEventModal"
        onFinish={handleFinish}
        expand
      >
        <div>
          <LargeFont fontWeight={FONT_WEIGHTS.SEMI_BOLD}>
            Event Info : {eventTitle}
          </LargeFont>
        </div>

        <Row>
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
            <Row gutter={[0, 2]}>
              <Col xs={24}>
                <LargeFont
                  fontWeight={FONT_WEIGHTS.MEDIUM}
                  margin="20px 0 10px 0"
                >
                  Reason for cancellation
                </LargeFont>
                <InputTextArea
                  name="reason"
                  required={false}
                  placeholder="Reason"
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  maxLength={250}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </FormGroup>
    </Modal>
  );
};

const Modal = styled(StyledModal)`
  .ant-modal-content {
    height: inherit;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    textarea {
      height: 200px !important;
    }
  }
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  opacity: 0.6;
  color: ${COLORS.CHINESE_BLACK};
  border-color: ${COLORS.CHINESE_BLACK};

  &:hover {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.CHINESE_BLACK};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default CancelEventModal;
