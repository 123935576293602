import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../../config';
import { APIs, Method } from '../../../constants';

const commonApis = createApi({
  reducerPath: 'common/apis',
  tagTypes: ['communityPage', 'jobById'],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
});

const injectedCommonEndpoints = commonApis.injectEndpoints({
  endpoints: (builder) => ({
    getCommunityPage: builder.query({
      query: (companyId) => ({
        url: APIs.communityPageDetails(companyId),
        method: Method.get,
      }),
      transformResponse: (response) => response.payload,
      keepUnusedDataFor: 300,
      providesTags: ['communityPage'],
    }),
    getCommunityEvents: builder.query({
      query: (args) => {
        const page = args?.page;
        const limit = args?.limit;
        const params = new Map();
        if (params) params.set('page', page);
        if (limit) params.set('limit', limit);
        return {
          url: APIs.communityPageEvents(args.companyId),
          params,
          method: Method.get,
        };
      },
      transformResponse: (response) => response.payload,
      keepUnusedDataFor: 300,
    }),
    getCommunityJobs: builder.query({
      query: (args) => {
        const page = args?.page;
        const limit = args?.limit;
        const search = args?.search;
        const params = new Map();
        if (params) params.set('page', page);
        if (limit) params.set('limit', limit);
        if (search) params.set('search', search);
        return {
          url: APIs.communityPageJobs(args.companyId),
          params,
          method: Method.get,
        };
      },
      transformResponse: (response) => response.payload,
      keepUnusedDataFor: 300,
    }),
    getCommunityJobsWithAuth: builder.query({
      query: ({
        page,
        limit = 10,
        search = '',
        forceRefetch = false,
        eventId,
      }) => {
        const params = new Map();
        if (params) params.set('page', page);
        if (limit) params.set('limit', limit);
        if (search) params.set('search', search);
        params.set('forceRefetch', forceRefetch);
        return {
          url: `${APIs.candidate}jobs/${eventId}/`,
          params,
          method: Method.get,
        };
      },
      transformResponse: (response) => response.payload,
      keepUnusedDataFor: 300,
    }),
    uploadEventCover: builder.mutation({
      query: ({ eventId, fileName }) => {
        return {
          url: APIs.uploadCommunityPageCover(eventId),
          body: {
            cover: fileName,
          },
          method: Method.put,
        };
      },
      invalidatesTags: ['communityPage'],
    }),
    getJobById: builder.query({
      query: (jobId) => ({
        url: `${config.marketingPublicApiUrl}/v1/public/job/${jobId}/`,
        method: Method.get,
      }),
      providesTags: ['jobById'],
      transformResponse: (response) => response.payload.job,
      keepUnusedDataFor: 0,
    }),
  }),
});

export default commonApis;
export { injectedCommonEndpoints };
export const {
  useGetCommunityPageQuery,
  useGetCommunityEventsQuery,
  useGetCommunityJobsQuery,
  useGetCommunityJobsWithAuthQuery,
  useUploadEventCoverMutation,
  useLazyGetJobByIdQuery,
  useGetJobByIdQuery,
} = injectedCommonEndpoints;
