import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { getRoleId } from '../../utils/userInfo';
import { PrimaryButton, SecondaryButton } from '../shared-components/Button';
import { getThemeByRoleId } from '../../utils/common';
import { RightAlignContainer } from '../shared-components/RightAlignContainer';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';

const ModalButtonContainer = (props) => {
  const {
    handleResetFields,
    submitText = 'Save & Update',
    resetCancleText = 'Reset',
  } = props;

  const roleId = getRoleId();
  return (
    <StyledContainer>
      <StyledLeftContainer xs={8} lg={12}>
        <SecondaryButton
          onClick={handleResetFields}
          {...props}
          id="modal_reset_button"
          theme={getThemeByRoleId(roleId)}
        >
          {resetCancleText}
        </SecondaryButton>
      </StyledLeftContainer>
      <Col xs={16} lg={12}>
        <RightAlignContainer>
          <PrimaryButton
            theme={getThemeByRoleId(roleId)}
            type="submit"
            {...props}
            id="modal_submit_button"
          >
            {submitText}
          </PrimaryButton>
        </RightAlignContainer>
      </Col>
    </StyledContainer>
  );
};

export default ModalButtonContainer;

const StyledContainer = styled(Row)`
  ${MARGIN(30, 0, 0, 0)}
  ${DISPLAY_FLEX('row', null, 'center')}
`;

const StyledLeftContainer = styled(Col)`
  text-align: left;
`;
