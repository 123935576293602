import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  cryptoIncentivePlanForCompany,
  updateCryptoPaymentStatus,
} from '../../service/cryptoPlans';
import { STATUS } from '../../constants';
import { getWalletInformation } from './virtualWallet';

export const getCryptoIncentivePlan = createAsyncThunk(
  'company/getCryptoIncentivePlan',
  async () => {
    return await cryptoIncentivePlanForCompany();
  },
);

export const updateCryptoIncentivePlan = createAsyncThunk(
  'company/updateCryptoIncentivePlan',
  async (body, { dispatch }) => {
    const response = await updateCryptoPaymentStatus(body);
    dispatch(getWalletInformation());
    dispatch(getCryptoIncentivePlan());
    return response;
  },
);

export const cryptoPlanSlice = createSlice({
  name: 'cryptoIncentivePlan',
  initialState: {
    isLoading: false,
    status: null,
    cryptoIncentivePlan: {},
    error: null,
    isRecruiterExisting: false,
    isPaymentUpdationProcessing: false,
    isPaymentUpdationCompleted: false,
    paymentError: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getCryptoIncentivePlan.pending, (state) => {
      state.isLoading = true;
      state.status = STATUS.PENDING;
    });
    builder.addCase(getCryptoIncentivePlan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = STATUS.RESOLVED;
      /**
       This ensures that when the component gets re-rendered for updating the payment this boolean flag will stay a single value so the redirection for existing and non-existing recruiters remains different       
       */
      if (action.payload === null) state.isRecruiterExisting = true;
      state.cryptoIncentivePlan = action.payload;
    });
    builder.addCase(getCryptoIncentivePlan.rejected, (state, action) => {
      state.isLoading = false;
      state.status = STATUS.REJECTED;
      state.error = action.error.message;
    });
    builder.addCase(updateCryptoIncentivePlan.pending, (state) => {
      state.isPaymentUpdationProcessing = true;
      state.isPaymentUpdationCompleted = false;
    });
    builder.addCase(updateCryptoIncentivePlan.fulfilled, (state) => {
      state.isPaymentUpdationProcessing = false;
      state.isPaymentUpdationCompleted = true;
    });
    builder.addCase(updateCryptoIncentivePlan.rejected, (state, action) => {
      state.isPaymentUpdationCompleted = false;
      state.isPaymentUpdationProcessing = false;
      state.paymentError = action.error.message;
    });
  },
});

export default cryptoPlanSlice.reducer;
