import get from 'lodash/get';
import Config from '../config';
import { ApiType } from '../constants';

const SUCCESS_OK = 200;
const ERROR_UNAUTHORISED = 401;

const checkStatusCode = (response, statusCode) => {
  const status = get(response, 'status', null);
  return status && status === statusCode;
};

export const isValid = (response) => {
  return checkStatusCode(response, SUCCESS_OK);
};

export const isUnauthorised = (response) => {
  return checkStatusCode(response, ERROR_UNAUTHORISED);
};

/**
 * API url concatenation
 * @param {string} path
 * @returns {string}
 */
export const apiUrl = (path, type) => {
  let apiPath = Config.baseURL;
  if (type === ApiType.analytics) {
    apiPath = Config.analyticsBaseUrl;
  }
  return new URL(path, apiPath).toString();
};
