import config from '../config';
import { COLORS } from '../styles/constants/colors';
import {
  AdminRoutes,
  CandidateRoutes,
  OrganizerRoutes,
  RecruiterRoutes,
  SuperAdminRoutes,
} from './routes';

// Routes constants
export const APIs = {
  adminLogin: '/v1/admin/login/',
  userLogin: '/v1/auth/login/',
  recruiterSignup: '/v1/auth/signup/recruiter/',
  organizerSignup: '/v1/auth/signup/organizer/',
  candidateSignup: '/v1/auth/signup/candidate/',
  verify: '/v1/auth/verify/',
  forgotPassword: '/v1/auth/forgot-password/',
  resetPassword: '/v1/auth/reset-password/',
  profile: '/v1/auth/me/',
  companys: '/v1/company/',
  switchCompanys: '/v1/company/switch/',
  memberProfilePic: '/v1/index/upload/profile/member',
  removeInvite: '/v1/company/member/invite/remove',
  organizerProfile: '/v1/auth/organizer/me/',
  recruiterProfile: '/v1/auth/recruiter/me/',
  candidateProfile: '/v1/auth/candidate/me/',
  crewProfile: '/v1/auth/crew/member/profile/',
  crewAdminProfile: '/v1/auth/admin/crew/member/profile/',
  index: '/v1/index/',
  index_file: '/v1/index/file/',
  index_user: '/v1/index/user/',
  profilePic: '/v1/index/upload/profile/',
  company: '/v1/company/information/',
  companyLogo: '/v1/company/upload/profile/',
  companyMember: '/v1/company/member/',
  socialLogin: '/v1/auth/social/login/',
  socialSignup: '/v1/auth/social/signup/',
  polling: '/v1/polling/',
  linkedin_callback: '/v1/auth/linkedin/callback/',
  resume: '/v1/candidate/upload/resume/',
  certificate: '/v1/candidate/upload/certifications/',
  skill: '/v1/skill/',
  walletInformation: '/v2/crypto-wallet/',
  walletIncomingBalance: '/v2/crypto-wallet/incoming-balance/',
  walletWithdraw: '/v2/crypto-wallet/withdraw/',
  revertTransaction: '/v2/crypto-wallet/withdraw/failed/',
  inviteRegisteredMember: '/v1/company/member/',
  inviteNonRegistererdMember: '/v1/company/invite/',
  organizer: '/v1/organizer/',
  recruiterRedirect: '/v1/recruiter/redirect/',
  event: '/v1/event/',
  eventBroadcast: `/v1/event/broadcast/`,
  communityEvents: (organizerId) =>
    `/v1/organizer/${organizerId}/community-page/event/`,
  eventPartnerCommunityPage: `/v2/master-admin/event-partner/community-page/`,
  validateCoupon: (cryptoIncentivePlanId) =>
    `/v1/recruiter/crypto-incentive-plan/${cryptoIncentivePlanId}/coupon/validate/`,
  turnOnOrOffAutoPresent: (id) => `/v1/event/auto-present/${id}`,
  eventRecruiter: '/v1/event-recruiter/',
  eventCandidate: '/v1/event-candidate/',
  chatRoom: '/chat-room',
  anonymity: '/user/anonymity',
  allIntegrations: '/v1/organizer/integration/',
  particularIntegration: (type) =>
    `/v1/organizer/integration/particular/?type=${type}`,
  createIntegrationByCode: '/v1/organizer/integration/token/',
  organizerDashboardAnalytics: '/v1/organizer/dashboard/statistic/',
  recruiterDashboardAnalytics: '/v1/recruiter/dashboard/statistic/',
  candidateDashboardAnalytics: '/v1/candidate/dashboard/statistic/',
  recruiter: '/v1/recruiter/',
  analytics: '/v1/statistics/',
  candidate: '/v1/candidate/',
  billingAddress: '/v1/billing/address/',
  organizerRecruiterAnalytics: 'organizer/recruiter/event/',
  organizerCandidateAnalytics: 'organizer/candidate/event/',
  recruiterEventAnalytics: 'recruiter/event/',
  recruiterSpecificEventAnalytics: 'recruiter/specific/event/',
  candidateEventAnalytics: 'candidate/event/',
  candidateOpportunities: 'candidate/opportunities/',
  recruiterJobApplications: '/v1/recruiter/jobApplications/',
  jobAnalytics: 'event/job/',
  publicEvent: '/v1/public/event/',
  public: '/v1/public/',
  publicAnalytics: '/v1/public/statistics/event/?eventIds=',
  recruiterInterviewSlot: '/v1/recruiter/job/interview/slots/',
  getRecruiterInterviewSlots: '/v1/recruiter/event/company/interview/slots/',
  communityPageDetails: (companyId) =>
    `/v1/organizer/${companyId}/community-page/`,
  communityPageJobs: (companyId) =>
    `/v1/organizer/${companyId}/community-page/job/`,
  communityPageEvents: (companyId) =>
    `/v1/organizer/${companyId}/community-page/event/`,
  uploadCommunityPageCover: (eventId) =>
    `/v1/organizer/community-page/${eventId}/cover/`,
  cryptoIncentivePlanForCompany:
    '/v1/company/crypto-incentive-plan/subscription/',
  getCryptoIncentivePlans: '/v1/company/crypto-incentive-plan/',
  eventRecruiterEvaluationDeadlineDateTime: (eventId, companyId) =>
    `/v1/event-recruiter/evaluation-deadline/${eventId}/${companyId}`,
  eventEvaluationDeadlineExtend: (id) =>
    `/v1/recruiter/event/${id}/evaluationDeadline/extend`,
  recruiterJobApplicationById: (jobId, applicationId) =>
    `/v1/recruiter/jobApplication/${jobId}/${applicationId}/`,
  recruiterJobApplicationStatus: (id) =>
    `/v1/recruiter/jobApplications/status/${id}`,
  recruiterCandidateProfile: (id) =>
    `/v1/recruiter/jobApplications/candidate/profile/${id}`,
  eventRecruiterAttendence: (id) =>
    `/v1/event-recruiter/recruiter/attendance/${id}`,
  eventCandidateAttendence: (id) =>
    `/v1/event-candidate/candidate/attendance/${id}`,
  payment: `/v1/payment/`,
  downloadResumeZip: '/v1/recruiter/resume/zip',
  notifications: '/v1/notifications/',
  notificationUpdates: '/v1/notifications/statistics',
  notificationsMarkViewed: `/v1/notifications/seen`,
  support: `/v1/support/mails`,
  validateCsvFile: `/v1/organizer/csv/validation`,
  bulkBooking: (id) => `/v1/organizer/event/${id}/bulk-booking`,
  confirmBooking: (id) => `/v1/event/confirm-booking/${id}`,
  checkIfUserHasSetPasswordOrNot: `/v1/auth/password/set/check`,
  updatePhone: '/v1/auth/phone/update',
  checkIfCompanyHasCreatedInterviewSlots: (id) =>
    `/v1/recruiter/check/interviewSlots/${id}`,
  deleteInterviewSlot: (id) => `/v1/recruiter/slot/${id}`,
  requestNewInterviewRequest: (id) => `/v1/candidate/new/interview-slot/${id}`,
  indexCheckIfUserExist: `/v1/index/check/exist`,
  quickStartPage: '/v1/candidate/quick-start',
  checkIfCompanyIsPresent: (id) => `/v1/event-recruiter/check/attendance/${id}`,
  checkIfCandidateIsPresent: (id) =>
    `/v1/event-candidate/check/attendance/${id}`,
  organizerSimilarEvents: `/v1/organizer/similar/events`,
  removeMember: '/v1/company/delete/member',
  removeCompanyCover: '/v1/company/delete/cover',
  companyCover: '/v1/company/upload/cover/',
  rejectCompanyInvite: (companyId, companyMemberId) =>
    `/v1/company/${companyId}/invite/${companyMemberId}/reject/`,
  acceptCrewMember: '/v1/company/approve/member/',
  checkIfUserExist: `/v1/auth/user/check/`,
  resendOtp: `/v1/auth/otp/resend/`,
  loginWithOTP: `/v1/auth/login/otp/`,
  candidateOtpSignup: `/v1/auth/signup/candidate/otp/`,
  recruiterOtpSignup: `/v1/auth/signup/recruiter/otp/`,
  organizerOtpSignup: `/v1/auth/signup/organizer/otp/`,
  checkIfCompanyExist: `/v1/auth/company/check/`,
  createPassword: `/v1/auth/user/password/`,
  changePassword: `/v1/auth/user/change/password/`,
  requestCrewInvite: `/v1/auth/crew/request/send/`,
  checkIfMailsUnsubscribed: '/v1/auth/mail/subscription/check',
  unsubscribeMails: '/v1/auth/mail/unsubscribe/',
  subscribeMails: `/v1/auth/mail/subscribe/`,
  loginWithMagicLinks: `/v1/auth/login/magic-link/`,
  twitter_access_token: `/v1/auth/twitter/access-token`,
  twitter_request_token: `/v1/auth/twitter/request-token`,
};

export const AdminApis = {
  baseUrl: '/v2/master-admin/',
  uploadRepresentativeProfilePic: (userId, roleId) =>
    `/v2/master-admin/representative/upload/profile/${userId}/${roleId}`,
  evaluationDeadlineExtendRequests:
    '/v2/master-admin/recruiter/event/evaluation/deadline/request/',
  statusChangeEvaluationDeadlineExtendRequest: (id) =>
    `/v2/master-admin/recruiter/event/company/${id}`,
  mergeHistory: '/v2/master-admin/skill/merge/history/',
  unMergeSkill: '/v2/master-admin/skill/unmerge/',
  viewMergeSkillHistory: '/v2/master-admin/skill/merge/history/',
  eventAnalytics: (id) => `/v2/master-admin/statistics/event/${id}`,
  event: (id) => `/v2/master-admin/event/${id}`,
  addSkill: `/v2/master-admin/skill`,
  updateSkill: (id) => `/v2/master-admin/skill/${id}`,
  candidateProfile: (id) => `/v2/master-admin/profile/candidate/${id}`,
  jobInformation: (id) => `/v2/master-admin/job/${id}/`,
  promoteJob: (id) => `/v2/master-admin/job/${id}/promote/`,
  fetchEventPartnerByJobId: (jobId) =>
    `/v2/master-admin/job/${jobId}/promote/event-partner/`,
  rejectSkill: '/v2/master-admin/skill/reject/',
  jobApplicationsAnalyticsAdmin: (id, event) =>
    `/v2/master-admin/statistics/job/applications/${id}${
      event && event.id ? `?eventId=${event.id}` : ''
    }`,
  candidateScheduledInterviews: (userId) =>
    `/v2/master-admin/candidate/scheduled/interviews/${userId}`,
  forwardCandidateJobApplication: (applicationId) =>
    `/v2/master-admin/candidate/job-application/${applicationId}/forward/`,
  rejectCandidateApplication: (applicationId) =>
    `/v2/master-admin/candidate/job-application/${applicationId}/reject/`,
  candidateAppliedJobs: (userId) => `/v2/master-admin/candidate/jobs/${userId}`,
  companyMember: (companyId, roleId) =>
    `/v2/master-admin/company/members/${companyId}/${roleId}`,
  companyEvents: (companyId) => `/v2/master-admin/company/events/${companyId}`,
  addExistingMember: `/v2/master-admin/company/member/`,
  inviteNewMember: `/v2/master-admin/company/invite/`,
  removeInvite: '/v2/master-admin/company/member/invite/remove',
  updateOrganizerProfile: (id) => `/v2/master-admin/organizer/company/${id}/`,
  updateRecruiterProfile: (id) => `/v2/master-admin/recruiter/company/${id}/`,
  updateCompanyLogo: (companyId) =>
    `/v2/master-admin/company/upload/profile/${companyId}`,
  deleteFile: `/v2/master-admin/delete/file/`,
  uploadCompanyCover: (companyId) =>
    `/v2/master-admin/company/upload/cover/${companyId}`,
  deleteCompanyCover: (companyId) =>
    `/v2/master-admin/company/delete/cover/${companyId}`,
  updateCandidateProfile: (userId) => `/v2/master-admin/candidate/${userId}`,
  uploadCandidateProfilePic: (userId) =>
    `/v2/master-admin/candidate/upload/profile/${userId}`,
  approveRejectSkillHistory: '/v2/master-admin/skill/approve-reject-history/',
  allowCompanyAccess: (companyId) =>
    `/v2/master-admin/company/${companyId}/crypto-incentive-plan/access/`,
  companySubscriptionPlan: (companyId) =>
    `/v2/master-admin/recruiter/company/${companyId}/crypto-incentive-plan/subscription/`,
  createCoupon: `/v2/master-admin/crypto-incentive-plan/coupon/`,
  editCoupon: (id) => `/v2/master-admin/crypto-incentive-plan/coupon/${id}/`,
  getCryptoPlans: `/v2/master-admin/crypto-incentive-plan/`,
  getCouponsForPricingPlan: (cryptoIncentivePlanId) =>
    `/v2/master-admin/crypto-incentive-plan/${cryptoIncentivePlanId}/coupon/`,
};

// Constant redirect routes
export const redirectRoutes = {
  recruiter: {
    viewJobDetails: '/recruiter/job-opportunities/view-job-details/',
  },
};

export const CARD_CHANGE = 'cardChange';

export const PRICING_PLAN = {
  CURIOUS: 1,
  OPTIMISTIC: 2,
  BELIEVER: 3,
};

export const Method = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
};

export const ApiType = {
  chat: 'chatBaseUrl',
  analytics: 'analyticsBaseUrl',
};

export const discount_types = {
  FLAT: 'Flat',
  PERCENTAGE: 'Percentage',
};

// Role constants
export const CANDIDATE = 1;
export const ORGANIZER = 2;
export const RECRUITER = 3;
export const ADMIN = 4;
export const SUPER_ADMIN = 5;
export const EVERYONE = [CANDIDATE, ORGANIZER, RECRUITER, ADMIN, SUPER_ADMIN];
export const ROLES = {
  CANDIDATE,
  ORGANIZER,
  RECRUITER,
  ADMIN,
  SUPER_ADMIN,
};

// Status constants
export const STATUS_ACTIVE = 10;
export const STATUS_DELETE = 20;
export const STATUS_PENDING = 30;
export const STATUS_DISABLED = 40;
export const STATUS_DRAFT = 50;

export const STATUS_ACTIVE_TEXT = 'Active';
export const STATUS_DRAFT_TEXT = 'Draft';
export const STATUS_PENDING_TEXT = 'Pending';
export const STATUS_DISABLED_TEXT = 'Disabled';

export const YES_TEXT = 'Yes';
export const NO_TEXT = 'No';

// Role names
export const CANDIDATE_TEXT = 'Candidate';
export const JOBSEEKER_TEXT = 'Jobseeker';
export const RECRUITER_TEXT = 'Recruiter';
export const HIRING_COMPANY_TEXT = 'Hiring Company';
export const ORGANIZER_TEXT = 'Organizer';
export const JOBS_TEXT = 'Jobs';
export const EVENTS_TEXT = 'Events';
export const ADMIN_TEXT = 'Admin';

// Role Color
export const CANDIDATE_COLOR = '#6963aa';
export const RECRUITER_COLOR = '#ffbf40';
export const ORGANIZER_COLOR = '#47bfa8';
export const MASTER_ADMIN_COLOR = '#1890ff';
export const GRAY_COLOR = '#8c8c8c';

// Skill Preference
export const MANDATORY = STATUS_ACTIVE;
export const PREFERRED = STATUS_DELETE;
export const MANDATORY_TEXT = 'Mandatory';
export const PREFERRED_TEXT = 'Preferred';

// Operations
export const NONE = 'none';
export const ADD = 'add';
export const DELETE = 'delete';
export const EDIT = 'edit';

export const NUMBER_TYPE = 'number';
export const STRING_TYPE = 'string';

export const TWELVE = 12;

export const UploadResumeSupportedFiles = 'application/pdf';
export const UploadPictureSupportedFiles = '';

// Social Media
export const FACEBOOK_TEXT = 'facebook';
export const TWITTER_TEXT = 'twitter';
export const LINKEDIN_TEXT = 'linkedin';
export const INSTAGRAM_TEXT = 'instagram';
export const PINTEREST_TEXT = 'pinterest';
export const YOUTUBE_TEXT = 'youtube';
export const DISCORD_TEXT = 'discord';

export const YES = 1;
export const NO = 2;

export const ONLINE_EVENT = 2;
export const ONLINE_EVENT_TEXT = 'Online Event';
export const OFFLINE_EVENT = 1;
export const OFFLINE_EVENT_TEXT = 'Hybrid Event';
export const FULLTIME_TEXT = 'Full Time';
export const PARTTIME_TEXT = 'Part Time';
export const INTERNSHIP_TEXT = 'Internship';
export const CONTRACT_TEXT = 'Contract';
export const PERMANENTEMPLOYMENT_TEXT = 'Permanent';
export const FREELANCING_TEXT = 'Freelancing';
export const REMOTE_TEXT = 'Remote';
export const SHOW_ALL_TEXT = 'Show All';
export const LOCATION_TEXT = 'Onsite';
export const APPLIED_JOBS_TEXT = 'Applied Jobs';
export const NEW_JOBS_TEXT = 'New Jobs';
export const ADDRESS_TEXT = 'address';
export const ADDRESS_NAME = 'name';
export const ADDRESS_LINE_ONE = 'addressLineOne';
export const ADDRESS_LINE_TWO = 'addressLineTwo';
export const ZIPCODE_TEXT = 'zipcode';
export const COUNTRY_TEXT = 'country';
export const STATE_TEXT = 'state';
export const CITY_TEXT = 'city';
export const FREE_TEXT = 'Free';
export const ANY_SKILL_ID = 28936;
export const ANY_SKILL_NAME = 'Any';
export const ANY_SKILL_LEVEL = 'L1';
export const ANY_SKILL_PREFERENCE = 2;
export const HOUR_LIMIT = 24;
export const ACTIVE_JOB_TEXT = 'Active Jobs';
export const INACTIVE_JOB_TEXT = 'Inactive Jobs';
export const ONSITE_TEXT = 'Onsite';
export const REMOTE = 1;
export const ONSITE = 2;
export const HYBRID = 3;
export const SKILL_SELECT_TEXT =
  'Please add atleast one mandatory skill or select Any Skills button to Continue';
export const SKILLS_NORMALIZATION_TEXT = 'Skills-Normalization';

// Recruitment tabs
export const JOBAPPLICATION = '1';
export const JOBOPPORTUNITIES = '2';

// Errors status code
export const ERROR_UNAUTHORISED = 401;
export const BAD_REQUEST = 400;
export const RATE_LIMIT_EXCEED = 429;
export const SERVER_DOWN = -1;

// Env var text
export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const QA = 'qa';

/* App level vars */
export const RETRY_ATTEMPTS = 3;
export const RETRY_INTERVALS = 600;

export const getErrorMessage = (message) => {
  // Show notifcation based on env
  return [PRODUCTION, STAGING].includes(config.appEnv)
    ? 'Something went wrong'
    : message;
};

// Generic form validation error messages
export const VALIDATION_ERROR = 'This field is required';
export const INVALID_VALUE_ERROR = 'Invalid value provided';

// Event
export const EVENT_ATTENDEES = {
  CANDIDATE: 'Candidate',
  RECRUITER: 'Recruiter',
};

export const EVENT_ATTENDEES_TYPE = [
  { id: 1, name: EVENT_ATTENDEES.CANDIDATE },
  { id: 2, name: EVENT_ATTENDEES.RECRUITER },
];

export const TECHNICAL_TEXT = 'Technical';
export const MANAGEMENT_TEXT = 'Management';
export const TECHNOFUNCTIONAL_TEXT = 'Techno Functional';

// Attendance constants
export const ATTENDANCE_PRESENT = 10;
export const ATTENDANCE_ABSENT = 20;

export const ATTENDEES_MANAGEMENT_ROLES = {
  company: '1',
  candidate: '2',
};

// Initial state
export const initialTabEventInfo = {
  [ATTENDEES_MANAGEMENT_ROLES.company]: {
    event: null,
    analytics: null,
    tableData: null,
  },
  [ATTENDEES_MANAGEMENT_ROLES.candidate]: {
    event: null,
    analytics: null,
    tableData: null,
  },
};

export const STATUS = {
  IDLE: 'idle',
  RESOLVED: 'resolved',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

// Notification status
export const isPending = (status) => {
  return STATUS.PENDING === status;
};

export const NOTIFICATION_STATUS = {
  DONE: 'done',
  ERROR: 'error',
};

// Event category labels
export const UPCOMING_EVENT = 1;
export const PAST_EVENT = 2;
export const LIVE_EVENT = 3;

export const UPCOMING_EVENT_TIME_TEXT = 'Event yet to start in';
export const PAST_EVENT_TIME_TEXT = 'Event completed';
export const LIVE_EVENT_TIME_TEXT = 'Event completes in';

// Crew member code
export const RECRUITER_ADMIN = 10;
export const RECRUITER_MEMBER = 20;
export const ORGANIZER_ADMIN = 10;
export const ORGANIZER_MEMBER = 20;

// Validate url
export const validateURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(str);
};

export const WebsiteCheckPattern = /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,}$/gi;

export const CountryCodePattern = /^\+(\d{1}-)?(\d{1,3})$/;

export const passwordCheckPattern = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;

export const phoneNumberCheckPattern = /^(?!.*-.*-.*-)(?=(?:\d{6,12}$)|(?:(?=.{9,11}$)[^-]*-[^-]*$)|(?:(?=.{10,12}$)[^-]*-[^-]*-[^-]*$) {2})[\d-]+$/;

export const urlCheckPattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const NONEMPTY = /^(?!\s*$).+/;
// Job Results Filters
export const STATUS_SUBMITTED = 2;
export const STATUS_SELECTED = 3;
export const STATUS_REJECTED = 4;
export const JOB_TYPE = {
  PART_TIME: 0,
  FULL_TIME: 1,
};

export const LOCATION_TYPE = {
  REMOTE: 1,
  ONSITE: 2,
  HYBRID: 3,
};

// Filters
export const FULL_TIME_SELECT = 2;
export const CONTRACT_AND_CONSULTING_SELECT = 2;
export const INTERNSHIP_SELECT = 3;
export const PERMANENT_EMPLOYMENT_SELECT = 4;
export const FREELANCING = 5;

// Interview status
export const INTERVIEW_STATUS_ACTIVE = 10;
export const INTERVIEW_STATUS_DRAFT = 50;

// Application Status
export const APPLICATION_STATUS_SUBMITTED = 50;
export const APPLICATION_STATUS_SELECTED = 10;
export const APPLICATION_STATUS_REJECTED = 20;
export const APPLICATION_STATUS_HOLD = 40;
export const APPLICATION_STATUS_REVIEW = 30;
export const REMOTE_SELECT = 2;
export const LOCATION_SELECT = 3;

export const APPLICATION_STATUS_TEXT = {
  SUBMITTED: 'Submit',
  SELECTED: 'Shortlisted',
  REJECTED: 'Rejected',
  HOLD: 'Hold',
  REVIEW: 'Review',
  SKIPPED: 'Skipped',
};

export const getApplicationStatusText = (applicationStatus, status) => {
  switch (applicationStatus) {
    case APPLICATION_STATUS_SUBMITTED:
      return 'Unreviewed';
    case APPLICATION_STATUS_SELECTED:
      return APPLICATION_STATUS_TEXT.SELECTED;
    case APPLICATION_STATUS_REJECTED:
      return status === STATUS_ACTIVE
        ? APPLICATION_STATUS_TEXT.SKIPPED
        : APPLICATION_STATUS_TEXT.REJECTED;
    case APPLICATION_STATUS_HOLD:
      return APPLICATION_STATUS_TEXT.HOLD;
    case APPLICATION_STATUS_REVIEW:
      return APPLICATION_STATUS_TEXT.REVIEW;
    default:
      return 'Unreviewed';
  }
};
export const checkIfApplicationForwarded = (status) => {
  return status === STATUS_ACTIVE
    ? { check: true, label: 'Forwarded' }
    : { check: false };
};

export const MASTER_ADMIN_CANDIDATE_JOB_APPLIED_COLUMN_MAP = {
  unreviewedJobApplications: 'unreviewed',
  skillMatchPercentage: 'skillMatch',
  eta: 'eta',
};

export const verification_status = {
  received: 'Received',
  reject: 'Reject',
  forward: 'Forward',
};
export const getDefaultValueForVerification = (status) => {
  if (status === APPLICATION_STATUS_REJECTED) {
    return verification_status.reject;
  }
  return verification_status.received;
};

export const getApplicationStatusColor = (status) => {
  switch (status) {
    case APPLICATION_STATUS_SELECTED:
      return COLORS.primaryColor;
    case APPLICATION_STATUS_REJECTED:
      return COLORS.CUSTOM_RED;
    case APPLICATION_STATUS_HOLD:
      return COLORS.NAPLES_YELLOW;
    case APPLICATION_STATUS_REVIEW:
      return COLORS.NAPLES_YELLOW;
    default:
      return COLORS.DODGER_BLUE;
  }
};

// For recruiter only
export const getApplicationTextColorByStatus = (status) => {
  switch (status) {
    case APPLICATION_STATUS_SELECTED:
      return COLORS.TRUE_GREEN;
    case APPLICATION_STATUS_REJECTED:
      return COLORS.CUSTOM_RED;
    case APPLICATION_STATUS_HOLD:
      return COLORS.SCIENCE_BLUE;
    case APPLICATION_STATUS_REVIEW:
      return COLORS.NAPLES_YELLOW;
    default:
      return COLORS.CHINESE_BLACK;
  }
};

// This function is to get the proper payload for api request to change application status
export const getApplicationStatusTextForApi = (status) => {
  switch (status) {
    case APPLICATION_STATUS_SUBMITTED:
      return 'Unreviewed';
    case APPLICATION_STATUS_SELECTED:
      return 'select';
    case APPLICATION_STATUS_REJECTED:
      return 'reject';
    case APPLICATION_STATUS_HOLD:
      return APPLICATION_STATUS_TEXT.HOLD;
    case APPLICATION_STATUS_REVIEW:
      return APPLICATION_STATUS_TEXT.REVIEW;
    default:
      return 'Unreviewed';
  }
};

export const JobApplicationSelectedText = 'Shortlisted';
export const JobApplicationSkipedText = 'Skipped';
export const JobApplicationUnderReviewText = 'Under Review';
export const JobApplicationSubmitedText = 'Submitted';
export const JobApplicationUnreviewedText = 'Unreviewed';
export const JobApplicationUnderVerificationText = 'Under Verification';
export const JobApplicationRejectedText = 'Not Eligible';

// ChatRoom
export const CHATROOM_TYPE_PRIVATE = 'personal';
export const CHATROOM_TYPE_GROUP = 'group';

export const USERTYPE_INDIVIDUAL = 'individual';
export const USERTYPE_COMPANY = 'company';

export const DELETE_STATUS_CODE = 20;

// Chatroom Pagination
export const INITIAL_PAGE = 1;

export const PERSONALCHAT_CHATROOM_LIMIT = 8;
export const PERSONALCHAT_MESSAGES_LIMIT = 8;

export const GROUPCHAT_CHATROOM_LIMIT = 8;
export const GROUPCHAT_MESSAGES_LIMIT = 8;

//
export const PasswordRuleText =
  'Your password must be minimum 6 characters long. It must contain an alphabet and a special character.';

// FindAndRemove types
export const lastModifiedType = 'lastModified';

export const InternetConnectionOfflineText =
  "You're offline right now. Check your connection.";
export const InternetConnectionOnlineText = "you're back online.";

export const OfflineOnlinePolling = {
  url: config.appURL,
  enabled: true,
  interval: 90000,
};
export const JobResultTabValue = 2;

export const StrokeWidth = 10;
export const OrganizerExploreEventsPage = 1;
export const OrganizerExploreEventsLimit = 5;

export const BOOKING_METHOD = {
  recorem: 1,
  thirdParty: 2,
};

export const BOOKING_METHOD_TEXT = {
  recorem: 'Recorem',
  thirdParty: 'Third Party',
};

// 5 minutes in ms
export const RateLimitErrorResetTime = 60000;

export const APIRequestLimit = {
  duration: 10000,
  request: 3,
};

export const MASTER_ADMIN_SKILLS_FILTER = {
  ALL: 'all',
  NEW: 'new',
  CANDIDATE: 'candidate',
  JOB: 'job',
};

export const AllEventsValue = {
  id: -1,
  name: 'All events',
};

export const BOOK_EVENT_SUCCESS_MESSAGE = 'Event booked';

export const JOIN_EVENT_SUCCESS_MESSAGE =
  'Event joined. You can now apply for jobs and chat with other attendees.';

export const RECRUITER_JOIN_FAIL_MESSAGE =
  'Please contact the Organizer to mark you present so that job seekers can start applying to the currently active jobs.';

export const CANDIDATE_JOIN_FAIL_MESSAGE =
  'Please contact the Organizer to mark you present so that you can start applying for jobs.';

export const RECRUITER_JOIN_EVENT_SUCCESS_MESSAGE =
  'Event joined. Now jobseekers can start applying to your currently active jobs.';

export const CRISP_CLIENT_URL = 'https://client.crisp.chat/l.js';

export const ACTIVE_CRISP_COUNT = 1;

export const CROP_COVER_OPTIONS = {
  aspect: 3 / 1,
  shape: 'rect',
  modalTitle: 'Crop cover image',
};

export const EVENT_CROP_COVER_OPTIONS = {
  aspect: 16 / 9,
  shape: 'rect',
  modalTitle: 'Crop cover image',
};

export const EVENT_SPONSORS_PARTNERS_OPTIONS = {
  aspect: 16 / 9,
  shape: 'rect',
  modalTitle: 'Crop Logo',
};

export const CROP_POFILE_PIC_OPTIONS = {
  aspect: 1 / 1,
  shape: 'round',
  modalTitle: 'Crop Image',
};

// Skill Origin constants
export const SKILL_ORIGIN = {
  USER: 'user',
  ADMIN: 'admin',
  DEFAULT: 'default',
};

export const CONFIRM_MESSAGES = {
  removeInvitation: 'Want to remove the crew invititation?',
  leaveCrew: 'Want to leave the crew?',
  removeMemberInvite: 'Want to remove the member invite?',
  removeMember: 'Want to remove member from crew?',
};

export const ADMIN_EDIT_EVENT_ACCESS_EXTENDED_TIME = 3 * 24 * 60 * 60;

export const SUPPORT_EMAIL = 'support@recorem.com';
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;

export const SuperAdminApis = {
  baseUrl: '/v1/super-admin/master-admin/',
};

// Maximum characters to display in table for a text
export const MAX_CHAR_TO_DISPLAY = 23;

// TOP SKILL COUNT
export const DEFAULT_TOP_COUNT = 10;

export const ALLOWED_MIN_MINUTE = 0;
export const ALLOWED_MAX_MINUTE = 30;

export const CandidateProgressDefaultWidth = 50;
export const CandidateProgressDefaultWidthSMALL = 45;
export const CandidateProgressDefaultType = 'circle';

export const TAG_COLOR_BY_STATUS = {
  [STATUS_ACTIVE]: 'green',
  [STATUS_DRAFT]: 'blue',
  [STATUS_DELETE]: 'red',
  [STATUS_DISABLED]: 'volcano',
};

export const TAG_TEXT_BY_STATUS = {
  [STATUS_ACTIVE]: STATUS_ACTIVE_TEXT,
  [STATUS_DRAFT]: STATUS_DRAFT_TEXT,
  [STATUS_DELETE]: STATUS_DISABLED_TEXT,
  [STATUS_DISABLED]: STATUS_DISABLED_TEXT,
};

export const DEFAULT_COLOR = 'blue';

export const PIP_POSITION = {
  X: 10,
  Y: 10,
  WIDTH_LAPTOP: 225,
  WIDTH_DESKTOP: 360,
  WIDTH_MOBILE: 190,
};

export const DASHBOARD_ROUTES = {
  [ORGANIZER]: OrganizerRoutes.organizer,
  [RECRUITER]: RecruiterRoutes.recruiter,
  [CANDIDATE]: CandidateRoutes.candidate,
  [ADMIN]: AdminRoutes.dashboard,
  [SUPER_ADMIN]: SuperAdminRoutes.dashboard,
};
export const ZERO_RESULTS = 'ZERO_RESULTS';

export const HelpDocLimit = ['1', '2', '5', '10'];
export const ONLINE_EVENT_CODE = 2;
export const ONLINE_TEXT = 'Online';
export const HYBRID_TEXT = 'Hybrid';
export const LIVE_TEXT = 'Live';
export const UPCOMING_TEXT = 'Upcoming';
export const ONGOING_TEXT = 'Ongoing';
export const PAST_TEXT = 'Past';
export const LIVE_EVENT_TEXT = 'Live Event';

export const SIGNUP_PAGE_FORMS = {
  INITIAL: 1,
  SIGNUP: 2,
  PASSWORD_LOGIN: 3,
  LOGIN_OTP: 4,
};

export const DEFAULT_NOTIFICATION_DURATION = 4.5;

export const integrationTypes = {
  SLACK: 'slack',
  DISCORD: 'discord',
  TELEGRAM: 'telegram',
};

export const integrationTypesDetails = {
  [integrationTypes.SLACK]: {
    name: 'Slack',
    id: 'slack',
    content:
      'Connecting slack would enable you to share your jobs to your community when created',
    oauth: true,
    url: config.slackAuthUrl,
  },
  [integrationTypes.DISCORD]: {
    name: 'Discord',
    id: 'discord',
    content:
      'Connecting discord would enable you to share your jobs to your community when created via channels',

    url: config.discordAuthUrl,
    oauth: true,
  },
  [integrationTypes.TELEGRAM]: {
    name: 'Telegram',
    id: 'telegram',
    oauth: false,
    content:
      'Connecting Telegram will add a recorem-bot to your community group which will notify about the new jobs, which you can share across your community',
    note: 'Please have telegram app installed on your desktop or mobile.',
    url: config.telegramBotUrl,
  },
};

export const PAYMENT = {
  PAID: 1,
  PENDING: 2,
  REFUND: 3,
  ERROR: 4,
};
export const TWITTER_REDIRECT_URL = 'https://api.twitter.com/oauth/authorize';

export const FULL_TIME_TEXT = 'Full-time';
export const PART_TIME_TEXT = 'Part-time';

// Pages
export const RECRUITER_EVENTS_PAGE = 'recruiter-events';
export const JOB_DETAILS_PAGE = 'job-details';

export const PIP_WIDTH = {
  LAPTOP: 340,
  DESKTOP: 430,
  MOBILE: 340,
};

export const SKILLSTEPS = [
  '0 – 6 months',
  '0.5 – 2 years',
  '2 – 5 years',
  'More than 5 years',
];

export const SkillLevelToTimelineMap = {
  L1: SKILLSTEPS[0],
  L2: SKILLSTEPS[1],
  L3: SKILLSTEPS[2],
  L4: SKILLSTEPS[3],
};

export const BOOLEAN_QUERY = {
  1: 'BASE QUERY',
  2: 'MANDATORY SKILL WITHOUT INTITLE QUERY',
  3: 'MANDATORY SKILL QUERY', // just mandatory skill
  4: 'SUPER QUERY WITHOUT INTITLE AND RELATED SKILL',
  5: 'SUPER QUERY WITHOUT RELATED SKILL', // primary skill mapping for mandatory skill
  6: 'SUPER QUERY WITHOUT INTITLE',
  7: 'SUPER QUERY',
  8: 'LINKEDIN MANDATORY SKILL QUERY', // related skills and primary skill mapping for mandatory skill
  9: 'LINKEDIN SUPER QUERY WITHOUT RELATED SKILL',
  10: 'LINKEDIN SUPER QUERY',
  11: 'GITHUB MANDATORY SKILL QUERY', // related skills and primary skill mapping for mandatory skill
  12: 'GITHUB SUPER QUERY WITHOUT RELATED SKILL',
  13: 'GITHUB SUPER QUERY',
};

export const RECRUITER_BOOLEAN_QUERY = {
  2: 'FIND ELIGIBLE CANDIDATES',
  3: 'FIND ELIGIBLE CANDIDATE RESUME', // just mandatory skill
  6: 'FIND RELEVANT CANDIDATES',
  7: 'FIND RELEVANT RESUMES',
  8: 'FIND ELIGIBLE LINKEDIN PROFILES', // related skills and primary skill mapping for mandatory skill
  10: 'FIND RELEVANT LINKEDIN PROFILES',
  11: 'FIND ELIGIBLE GITHUB PROFILES', // related skills and primary skill mapping for mandatory skill
  13: 'FIND RELEVANT GITHUB PROFILES',
};

export const APPLICATION_EXTENSION_TOOLTIP =
  'The application evaluation deadline is approaching soon, click to request an extension';

export const REQUEST_SUCCESS_MESSAGE = `Successfully requested for ETA extension.`;

export const INDIVIDUAL_USER = 'individual';
export const COMPANY_USER = 'company';

export const HIGH_PRIORITY_JOB = 'high';
export const LOW_PRIORITY_JOB = 'low';

export const PAYMENT_STATUS = {
  PAID: 1,
  PENDING: 2,
  REFUND: 3,
  ERROR: 4,
  PROCESSING: 5,
  REQUIRES_ACTION: 6,
};

export const PAYMENT_INTENT_KEYS = {
  CLIENT_SECRET: 1,
  REDIRECT_URL: 2,
};

export const EventStatus = {
  LIVE: 'live',
  ONGOING: 'ongoing',
  PAST: 'past',
  UPCOMING: 'upcoming',
};

export const socialMediaIconList = [
  {
    name: FACEBOOK_TEXT,
    value: `/static/images/facebook.png`,
    backgroundColor: `#3C5A9A`,
    filter: 'brightness(0) invert(1)',
  },
  {
    name: TWITTER_TEXT,
    value: `/static/images/twitter.png`,
    backgroundColor: `#55ACEE`,
    filter: 'brightness(0) invert(1)',
  },
  {
    name: LINKEDIN_TEXT,
    value: `/static/images/linkedin.png`,
    backgroundColor: `rgb(10, 102, 194)`,
    filter: 'brightness(0) invert(1)',
    extraSmall: true,
  },
  {
    name: INSTAGRAM_TEXT,
    value: `/static/images/instagram.png`,
    backgroundColor: `#D95D7B`,
    filter: 'brightness(0) invert(1)',
  },
];

export const ON_SELECT_FROM_ROW = 'onSelectFromRow';
