import React, { useLayoutEffect, useEffect, useState } from 'react';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Pagination } from 'antd';
import styled from 'styled-components';
import organizerHelpDoc from '../../assets/docs/organizer-help-doc.pdf';
import recruiterHelpDoc from '../../assets/docs/recruiter-help-doc.pdf';
import candidatetHelpDoc from '../../assets/docs/candidate-help-doc.pdf';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  getRoleId,
} from '../../utils/userInfo';
import { addClickBlocker } from '../../utils/document';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { StyledLargeHeading } from '../shared-components/Texts';
import { DEFAULT_PAGE, HelpDocLimit } from '../../constants';
import DocPages from './DocPages';

const HelpDoc = () => {
  const [pdfFile, setPdfFile] = React.useState();
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const roleId = getRoleId();
  const [limit, setLimit] = useState(DEFAULT_PAGE);

  useEffect(() => {
    switch (true) {
      case checkIfUserIsOrganizer(roleId):
        setPdfFile(organizerHelpDoc);
        break;
      case checkIfUserIsCandidate(roleId):
        setPdfFile(candidatetHelpDoc);
        break;
      default:
        setPdfFile(recruiterHelpDoc);
    }
  }, [roleId]);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [pageNumber]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onChangePage = (index) => {
    setPageNumber(index);
  };

  const onShowSizeChange = (currentPageNumber, pageSize) => {
    setLimit(pageSize);
  };

  const getTotal = (pages) => {
    return pages;
  };

  useEffect(() => {
    return addClickBlocker('helper-doc');
  }, []);

  return (
    <StyledLargeHeading id="helper-doc">
      <StyledContainerDocument
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <DocPages pageNumber={pageNumber} limit={limit} numPages={numPages} />
      </StyledContainerDocument>
      <StyledHelpDocPagination
        defaultCurrent={DEFAULT_PAGE}
        onChange={onChangePage}
        onShowSizeChange={onShowSizeChange}
        total={getTotal(numPages)}
        current={pageNumber}
        pageSizeOptions={HelpDocLimit}
        defaultPageSize={DEFAULT_PAGE}
        showSizeChanger
      />
    </StyledLargeHeading>
  );
};

// "react-pdf__Page" is a class of 'react-pdf/dist/esm/entry.webpack'
// It need to be kept here.
const StyledContainerDocument = styled(Document)`
  user-select: none;
  margin-bottom: 30px;
  ${DISPLAY_FLEX('column', 'center', 'center')}

  .react-pdf__Page {
    padding-top: 30px;

    canvas {
      @media (max-width: ${BREAKPOINTS.MOBILE}) {
        width: 100vw !important;
        height: 100vh !important;
      }
    }
  }
`;

const StyledHelpDocPagination = styled(Pagination)`
  ${DISPLAY_FLEX('row', 'center')}
`;

export default HelpDoc;
