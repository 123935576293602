import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import toLower from 'lodash/toLower';
import { getFormatedTimeZone } from './formatDateTime';
import { STATUS } from '../constants';
import { getRoleId, getUserId } from './userInfo';
import { getThemeByRoleId } from './common';
import { BREAKPOINTS } from '../styles/constants/breakpoints';

const { RESOLVED, IDLE } = STATUS;

export const useTimeZone = (event) => {
  const [timeZone, setTimeZone] = React.useState('');

  const { status, timeZones } = useSelector((state) => state.commons);

  React.useEffect(() => {
    if (event && event.meta) {
      const { timezone } = event.meta;
      if (status === RESOLVED || status === IDLE) {
        getFormatedTimeZone(timeZones, timezone).then((formatedTimezone) => {
          setTimeZone(formatedTimezone);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, status]);

  return timeZone;
};

export const useTagManager = () => {
  const userId = getUserId();
  const roleId = getRoleId();
  return (action) => {
    const datalayerObj = {
      loggedInUserId: userId,
      loggedInUserRole: toLower(getThemeByRoleId(roleId)),
      userAction: action,
      appPath: window.location.href,
      event: 'recorem_event',
    };
    TagManager.dataLayer({
      dataLayer: datalayerObj,
    });
  };
};

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export const useGetSize = (
  desktop,
  laptop,
  tablet = 2,
  tabletSmall = 2,
  mobile = 1,
  smallMobile = 1,
  defaultLaptop = 3,
) => {
  const isLaptop = useMediaQuery(
    `(min-width: ${BREAKPOINTS.LAPTOP_MIN}) and (max-width: ${BREAKPOINTS.LAPTOP_MAX})`,
  );
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.MOBILE})`);
  const isSmallMobile = useMediaQuery(
    `(max-width: ${BREAKPOINTS.MOBILE_SPECIAL_CASE_1})`,
  );

  const isTablet = useMediaQuery(
    `(min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: ${BREAKPOINTS.TABLET_MAX})`,
  );
  const isSmallTablet = useMediaQuery(
    `(min-width: ${BREAKPOINTS.TABLET_MIN}) and (max-width: 950px)`,
  );

  switch (true) {
    case isSmallMobile: {
      return smallMobile || desktop;
    }
    case isMobile: {
      return mobile || desktop;
    }
    case isLaptop: {
      return laptop || desktop;
    }
    case isTablet: {
      return tablet || laptop;
    }
    case isSmallTablet: {
      return tabletSmall || tablet;
    }

    default: {
      return defaultLaptop && laptop ? laptop : desktop;
    }
  }
};
