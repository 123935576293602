import React from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import {
  FACEBOOK_TEXT,
  INSTAGRAM_TEXT,
  LINKEDIN_TEXT,
  TWITTER_TEXT,
} from '../../constants';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
} from '../svgicons';
import { DISPLAY_FLEX } from '../../styles/constants/display';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';

const UserSocialLinks = ({ links, string = false }) => {
  const socialMediaLists = [
    {
      name: FACEBOOK_TEXT,
      value: FacebookIcon,
    },
    {
      name: TWITTER_TEXT,
      value: TwitterIcon,
    },
    {
      name: LINKEDIN_TEXT,
      value: LinkedinIcon,
    },
    {
      name: INSTAGRAM_TEXT,
      value: InstagramIcon,
    },
  ];

  const findSocialMediaIcon = (name) => {
    const result = socialMediaLists.find((el) => name.includes(el.name));
    return result ? result.value : null;
  };

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <StyledProfileLinksContainer>
      {links.map((link, idx) => {
        const linkText = string ? link : link.link;
        const Icon = findSocialMediaIcon(linkText);
        return (
          <li key={idx.toString()}>
            <Tooltip title={linkText}>
              <StyledButton onClick={() => handleClick(linkText)}>
                {Icon ? <Icon /> : <GlobalOutlined />}
              </StyledButton>
            </Tooltip>
          </li>
        );
      })}
    </StyledProfileLinksContainer>
  );
};

export default UserSocialLinks;

const StyledProfileLinksContainer = styled.ul`
  ${DISPLAY_FLEX('row')}
  width: 100%;
  line-height: 0;
  list-style: none;
  column-gap: 12px;
  margin-bottom: 0;

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    margin-left: 8px;
  }
`;

const StyledButton = styled(Button)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: none;
  color: #bfbfbf;
  padding: 0;

  svg {
    filter: opacity(0.7);
    height: inherit;
    width: inherit;
    border-radius: 50%;

    path {
      fill: rgb(72 72 72);
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #f5f5f5;

    svg {
      color: black;
      filter: opacity(1);
    }
  }
`;
