import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STATUS } from '../../constants';
import {
  fetchWalletInformation,
  getIncomingBalance,
  withdrawFromWallet as withdraw,
  revertFailedTransaction,
} from '../../service/virtualWallet';

const getWalletInformation = createAsyncThunk('wallet', async () => {
  return await fetchWalletInformation();
});

const getIncomingBalanceStore = createAsyncThunk(
  'wallet/incoming-balance',
  async () => {
    return await getIncomingBalance();
  },
);

const withdrawFromWallet = createAsyncThunk(
  'wallet/withdraw',
  async (requestBody) => {
    const txnHash = await withdraw(requestBody);
    return txnHash;
  },
);

export const revertWalletBalance = createAsyncThunk(
  'wallet/revert',
  async (requestBody, { dispatch }) => {
    await revertFailedTransaction(requestBody);
    return dispatch(getWalletInformation());
  },
);

const walletInitialState = {
  isLoading: false,
  status: STATUS.IDLE,
  wallet: null,
  incomingBalance: null,
  error: null,
  incomingBalanceFetching: STATUS.IDLE,
  incomingBalanceError: null,
  withdrawTransactionHash: null,
  isWithdrawComplete: false,
  isWithdrawing: false,
  withdrawError: null,
};

const wallet = createSlice({
  name: 'virtual wallet',
  initialState: walletInitialState,
  extraReducers: (builder) => {
    builder
      .addCase(getWalletInformation.pending, (state) => {
        state.isLoading = true;
        state.status = STATUS.PENDING;
      })
      .addCase(getWalletInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = STATUS.RESOLVED;
        state.wallet = action.payload;
      })
      .addCase(getWalletInformation.rejected, (state, action) => {
        state.isLoading = false;
        state.status = STATUS.REJECTED;
        state.error = action.error.message;
      })
      .addCase(getIncomingBalanceStore.pending, (state) => {
        state.incomingBalanceFetching = STATUS.PENDING;
      })
      .addCase(getIncomingBalanceStore.fulfilled, (state, action) => {
        state.incomingBalanceFetching = STATUS.RESOLVED;
        state.incomingBalance = action.payload;
      })
      .addCase(getIncomingBalanceStore.rejected, (state, action) => {
        state.incomingBalanceFetching = STATUS.REJECTED;
        state.incomingBalanceError = action.error.message;
      })
      .addCase(withdrawFromWallet.pending, (state) => {
        state.isWithdrawing = true;
      })
      .addCase(withdrawFromWallet.fulfilled, (state, action) => {
        state.isWithdrawing = false;
        state.isWithdrawComplete = true;
        state.withdrawTransactionHash = action.payload;
      })
      .addCase(withdrawFromWallet.rejected, (state, action) => {
        state.isWithdrawing = false;
        state.withdrawError = action.error.message;
      });
  },
});

export { getWalletInformation, getIncomingBalanceStore, withdrawFromWallet };
export default wallet.reducer;
