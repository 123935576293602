import React, { useCallback, useState } from 'react';
import message from 'antd/es/message';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { removeApplyJobData } from '../store/features/commons';
import { applyToJob } from '../store/features/jobOpportunities';
import { useTagManager } from '../utils/customHooks';
import {
  checkJobWorkAuthorization,
  updateWorkAuthorization,
} from '../service/candidate';
import { ConfirmWorkAuthorization } from '../containers/Candidate/common';
import { userProfileStore } from '../utils/profile';
import { changeRefetchStatus } from '../store/features/jobDetails';

const successText = (
  <div>
    <p>Job Applied!</p>
    <p>New Job Application Submitted!</p>
  </div>
);

export const useApplyJobInfo = ({ eventId, refetchFn = null, eventData }) => {
  const [isApplyJobInfoOpen, setIsApplyJobInfoOpen] = useState(false);
  const [applyJobData, setApplyJobData] = useState({});
  const { applyJobData: marketingApplyJobData } = useSelector(
    (st) => st.commons,
  );
  const updateUserActionInGTM = useTagManager();
  const [jobApplications, setJobApplications] = useState({});
  const { profileData } = userProfileStore();
  const dispatch = useDispatch();

  const removetheApplyJobData = async () => {
    if (marketingApplyJobData && !isEmpty(marketingApplyJobData)) {
      const { eventId: eId } = marketingApplyJobData;
      if (eventData.id === +eId) {
        await dispatch(removeApplyJobData());
      }
    }
  };

  const handleApplyJob = useCallback(
    async (jobId) => {
      const applyJob = dispatch(applyToJob({ id: jobId, eventId }));
      applyJob.then((data) => {
        updateUserActionInGTM('job_application_created');
        if (data?.meta?.requestStatus === 'fulfilled') {
          message.info(successText);
        }
      });
      setJobApplications((prev) => {
        const newApplications = { ...prev };
        newApplications[jobId] = true;
        return newApplications;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId, updateUserActionInGTM],
  );

  const handleSaveApplyJob = async () => {
    if (!isEmpty(applyJobData)) {
      await handleApplyJob(get(applyJobData, 'job.id')).then(() => {
        removetheApplyJobData();
        message.info(
          'You have successfully applied for the job, In order to increase your chances of getting shortlisted, please complete your profile',
          8,
        );
        dispatch(changeRefetchStatus({ flag: true }));
        if (typeof refetchFn === 'function') refetchFn();
      });
    }
    setIsApplyJobInfoOpen(false);
  };
  const handleJobReloadWithProfile = async () => {
    setIsApplyJobInfoOpen(false);
    removetheApplyJobData();
    // change refetchStatus to true and refetch once again so that in both backend and frontend the data is not cached again
    dispatch(changeRefetchStatus({ flag: true }));
    if (typeof refetchFn === 'function') refetchFn();
  };

  const handleYes = useCallback(
    (jobCountryId, jobId) => {
      updateWorkAuthorization({
        workAuthorizations: [
          { operation: 'add', countryId: jobCountryId, isAuthorized: true },
        ],
      }).then(() => {
        handleApplyJob(jobId);
      });
    },
    [handleApplyJob],
  );

  const handleNo = useCallback((jobCountryId) => {
    updateWorkAuthorization({
      workAuthorizations: [
        { operation: 'add', countryId: jobCountryId, isAuthorized: false },
      ],
    }).then(() => {
      if (typeof refetchFn === 'function') refetchFn();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onJobApplyClick = useCallback(
    async (job, type) => {
      const jobId = job.id;
      if (
        !(
          get(profileData, 'resume', null) &&
          get(profileData, 'userSkills', []).length
        )
      ) {
        setIsApplyJobInfoOpen(true);
        setApplyJobData({ job, type });
      } else {
        const response = await checkJobWorkAuthorization(jobId);
        const isEligible = get(response, 'isEligible', true);
        const jobCountry = get(response, 'jobCountry', '');
        const jobCountryId = get(response, 'jobCountryId', '');
        if (isEligible || (!jobCountry && !jobCountryId)) {
          handleApplyJob(jobId);
        } else {
          ConfirmWorkAuthorization(
            jobCountry,
            () => handleYes(jobCountryId, jobId),
            () => handleNo(jobCountryId, jobCountry, type),
          );
        }
      }
    },
    [handleApplyJob, handleYes, handleNo, profileData],
  );

  const handleModalClose = () => {
    setIsApplyJobInfoOpen(false);
    removetheApplyJobData();
    dispatch(changeRefetchStatus({ flag: true }));
    if (typeof refetchFn === 'function') refetchFn();
  };

  const handleClose = () => {
    setIsApplyJobInfoOpen(false);
    removetheApplyJobData();
  };

  return {
    isApplyJobInfoOpen,
    handleModalClose,
    handleSaveApplyJob,
    handleJobReloadWithProfile,
    applyJobData,
    setApplyJobData,
    handleApplyJob,
    removetheApplyJobData,
    onJobApplyClick,
    handleClose,
    profileData,
    jobApplications,
  };
};
