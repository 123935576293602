import React from 'react';
import { UserInformation } from '../utils/userInfo';
import Notification from '../components/Notification/Notification';
import PageNotFound from './PageNotFound';
import PrivatePageNotFound from './PrivatePageNotFound';

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  const { isAuthenticated } = UserInformation();

  React.useEffect(() => {
    Notification('error', 'error', error.message);
  }, [error.message]);

  return isAuthenticated ? (
    <PrivatePageNotFound
      resetErrorBoundary={resetErrorBoundary}
      status="500"
      title="500"
      subTitle={error.message}
    />
  ) : (
    <PageNotFound
      resetErrorBoundary={resetErrorBoundary}
      status="500"
      subTitle={error.message}
    />
  );
};

export default ErrorBoundaryFallback;
