import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Form, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import first from 'lodash/first';
import BookEventModal from '../../BookEvent/BookEventModal';
import { FormGroup } from '../../../shared-components/Form/Form';
import {
  bookEventData,
  bookEventWithInviteCodeData,
} from '../../../../store/features/events';
import getNow from '../../../../utils/getNow';
import Notification from '../../../Notification/Notification';
import {
  checkIfUserIsCandidate,
  checkIfUserIsOrganizer,
  checkIfUserIsRecruiter,
  getRoleId,
  getUserClassWithRoleId,
} from '../../../../utils/userInfo';
import { PrimaryButton } from '../../../shared-components/Button';
import { STATUS_DRAFT } from '../../../../constants';
import { useTagManager } from '../../../../utils/customHooks';
import RegisterButton from './RegisterButton';

export default ({
  event,
  isPublicEvent,
  openBookEventModal,
  inviteCode,
  setOpenBookEventModal,
  profileData,
  openPaymentModal,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [bookEvent, setBookEvent] = useState(!!openPaymentModal);
  const updateUserActionInGTM = useTagManager();
  const companyData = get(profileData, 'company', null);
  const dispatch = useDispatch();
  const [requestSent, setRequestSent] = useState(false);

  const thirdPartyData = get(event, 'thirdPartyData', []);

  const handleBookEventModalOpen = () => {
    setBookEvent(true);
  };

  const roleId = getRoleId();

  const handleBookEventModalClose = () => {
    setBookEvent(false);
    setOpenBookEventModal(false);
  };

  const handleBookEvent = useCallback(() => {
    if (
      checkIfUserIsRecruiter(roleId) &&
      get(profileData, 'company.status', STATUS_DRAFT) === STATUS_DRAFT
    ) {
      return Notification(
        'error',
        'Company Verification',
        'Unverified Recruiters cannot Register for events',
      );
    }
    if (isPublicEvent) {
      if (!roleId) {
        history.push(`/login?eventId=${event.id}`);
      } else {
        const redirect = `events/view/${event.id}`;
        if (checkIfUserIsOrganizer(roleId))
          history.push(`/organizer/${redirect}`);
        else if (checkIfUserIsRecruiter(roleId))
          history.push(`/recruiter/${redirect}`);
        else if (checkIfUserIsCandidate(roleId))
          history.push(`/candidate/${redirect}`);
        else message.error('...');
      }
      return;
    }
    if (
      ((event.userTicketPrice && checkIfUserIsCandidate(roleId)) ||
        (event.recruiterTicketPrice && checkIfUserIsRecruiter(roleId))) &&
      thirdPartyData.length === 0
    ) {
      handleBookEventModalOpen();
    } else {
      updateUserActionInGTM('event_booked');
      const freeEventBody = {
        eventId: event.id,
        paymentDate: getNow(),
      };
      if (!inviteCode) {
        dispatch(bookEventData(freeEventBody, event.id, true));

        return;
      }
      if (
        !event.isEventBooked &&
        openBookEventModal &&
        inviteCode &&
        requestSent === false
      ) {
        setRequestSent(true);
        dispatch(
          bookEventWithInviteCodeData(
            { ...freeEventBody, inviteCode },
            event.id,
          ),
        );
      }
    }
    // eslint-disable-next-line
  }, [
    event,
    dispatch,
    inviteCode,
    isPublicEvent,
    openBookEventModal,
    requestSent,
    history,
    roleId,
  ]);

  useEffect(() => {
    if (openBookEventModal === true) {
      handleBookEvent();
      setOpenBookEventModal(false);
    }
  }, [openBookEventModal, handleBookEvent, setOpenBookEventModal]);

  const idBase = 'book_event_button';

  return (
    <>
      {thirdPartyData.length === 0 ? (
        <RegisterButton
          event={event}
          companyData={companyData}
          handleBookEvent={handleBookEvent}
          idBase={idBase}
          profileData={profileData}
        />
      ) : (
        <a
          href={first(thirdPartyData).url}
          id="third-party-booking-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonWrapper>
            <PrimaryButton
              theme={getUserClassWithRoleId(roleId)}
              id={`${idBase}_third-party`}
            >
              {`Register @ ${get(first(thirdPartyData), 'name', '')}`}
            </PrimaryButton>
          </ButtonWrapper>
        </a>
      )}

      <FormGroup form={form} name="Billing-address-modal" expand>
        {bookEvent ? (
          <BookEventModal
            bookEvent={bookEvent}
            handleBookEventModalOpen={handleBookEventModalOpen}
            handleBookEventModalClose={handleBookEventModalClose}
            inviteCode={inviteCode}
            setOpenBookEventModal={setOpenBookEventModal}
            form={form}
          />
        ) : null}
      </FormGroup>
    </>
  );
};

const ButtonWrapper = styled.div`
  button {
    width: fit-content;
    padding: 7px 10px;
  }
`;
