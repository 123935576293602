import React from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { KebabMenuProps } from '../../interfaces/components/KebabMenu';
import { DISPLAY_FLEX, MARGIN } from '../../styles/constants/display';
import { COLORS } from '../../styles/constants/colors';

export const RightAlign: React.FC = ({ children }) => {
  return <RightAlignContainer>{children}</RightAlignContainer>;
};

export const KebabMenu: React.FC<KebabMenuProps> = ({ menu, id }) => {
  return (
    <StyledWrapper id={id}>
      <RightAlign>
        <Dropdown overlay={menu} trigger={['click']}>
          <span>
            <KebabMenuDot />
            <KebabMenuDot />
            <KebabMenuDot />
          </span>
        </Dropdown>
      </RightAlign>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.span`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')}

  margin: 0 0 0 auto;
  &:hover {
    cursor: pointer;
  }
`;

const RightAlignContainer = styled.span`
  ${DISPLAY_FLEX('row', 'flex-end', 'center')}

  margin: 0 0 0 auto;
`;

const KebabMenuDot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${COLORS.ARGENT_2};
  ${DISPLAY_FLEX('row', 'space-around')}
  ${MARGIN(0, 0, 2, 0)}

  border-radius: 5px;
`;
