/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Modal } from 'antd';
import { checkIfMailsUnsubscribed, unsubscribeMails } from '../service/auth';
import { getUrlVars } from '../utils/common';
import config from '../config';
import styled from 'styled-components';
import { StyledWrapper } from './login-signup/common';
import { getUserColorWithRoleId } from '../styles/utils';
import { ADMIN } from '../constants';
import ModalImage from '../assets/svg/unsub_modal_image.svg';
import RecoremLogo from '../assets/svg/recorem_color_logo.svg';
import { FONT_WEIGHTS } from '../styles/constants/typography';
import { BREAKPOINTS } from '../styles/constants/breakpoints';
import { DISPLAY_FLEX, MARGIN, PADDING } from '../styles/constants/display';
import { PrimaryButton } from '../components/shared-components/Button';
import get from 'lodash/get';
import Unsubscribed from '../assets/svg/unsubscribed.svg';
import { COLORS } from '../styles/constants/colors';

const Unsubscribe = (props) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isAlreadyUnsubscribed, setIsAlreadyUnsubscribed] = useState(false);

  const { token } = getUrlVars(props.location.search);
  if (!token) props.history.push('/login');

  useEffect(() => {
    checkIfMailsUnsubscribed(token).then((response) => {
      setIsAlreadyUnsubscribed(get(response, 'isUnsubscribed', false));
    });
  }, [token]);

  const handleModalCancel = () => {
    window.open(config.appURL, '_self');
    window.close();
  };

  const handleUnsubscribe = () => {
    if (token) {
      setIsClicked(true);
      unsubscribeMails(token)
        .then(() => {
          setIsConfirmed(true);
          setIsClicked(false);
        })
        .catch(() => {
          setIsClicked(false);
        });
    }
  };

  return (
    <div>
      <StyledModal
        visible={true}
        onCancel={handleModalCancel}
        footer={null}
        centered
        width="80%"
      >
        <StyledWrapper id="social_sigup_modal" isSocialModal>
          <StyledLeftConatiner xs={24} md={15}>
            <Col>
              <StyledModalImage src={ModalImage} alt="Recorem modal image" />
            </Col>
          </StyledLeftConatiner>
          <StyledRightConatiner xs={24} md={9}>
            {!isAlreadyUnsubscribed ? (
              !isConfirmed ? (
                <StyledDiv>
                  <StyledLogoHeader src={RecoremLogo} alt="Recorem Logo" />
                  <StyledTitle>We are sorry to see you go!</StyledTitle>
                  <StyledSubTitle>
                    Click the <StyledText>unsubscribe</StyledText> button below
                    to confirm
                  </StyledSubTitle>

                  <StyleCenterAlignedCon>
                    <PrimaryButton
                      color={getUserColorWithRoleId(ADMIN, true)}
                      onClick={handleUnsubscribe}
                    >
                      {!isClicked ? 'Unsubscribe' : <Spin size="medium" />}
                    </PrimaryButton>
                  </StyleCenterAlignedCon>
                </StyledDiv>
              ) : (
                <StyledSubTitle>
                  <StyledUnsubscribedImage src={Unsubscribed} alt="unsub" />
                  You have unsubscribed mails from Recorem.
                </StyledSubTitle>
              )
            ) : (
              <StyledSubTitle>
                <StyledUnsubscribedImage src={Unsubscribed} alt="unsub" />
                <div>
                  Your email address has already been moved to the 'Do-not-mail'
                  list. You will no longer receive emails.
                </div>
              </StyledSubTitle>
            )}
          </StyledRightConatiner>
        </StyledWrapper>
      </StyledModal>
    </div>
  );
};

export default Unsubscribe;

const StyledModal = styled(Modal)`
  ${PADDING(0, 0, 0, 0)}
  overflow: hidden;
  max-width: 90rem;
  border-radius: 1rem;
  ${MARGIN(null, null, 0, null)}

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    overflow: unset;
  }

  @media (min-width: 1660px) and (max-width: 1900px) {
    max-width: 110rem;
  }

  .ant-modal-body {
    padding: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: fit-content !important;
    height: 70vh;
  }

  .ant-modal-content {
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: 100vh;
    }
  }
`;

const StyledLeftConatiner = styled(Col)`
  height: 100%;
  background-color: ${COLORS.WHITE};

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    display: none;
  }
`;

const StyledModalImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 70vh;
`;

const StyledLogoHeader = styled.img`
  height: 100px;
  aspect-ratio: 16 / 9;
  ${MARGIN(-140, null, 30, null)}

  @media (max-width: ${BREAKPOINTS.LAPTOP_SPECIAL_CASE_2}) {
    ${MARGIN(-100, null, null, null)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(0, null, null, null)}
  }
`;

const StyledTitle = styled.div`
  ${MARGIN(20, 0, 0, 0)}
  color: #000000CC;
  font-size: 18px;
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 16px;
  }
`;

const StyledText = styled.span`
  color: ${COLORS.PURPLE};
  display: contents;
`;

const StyledDiv = styled.div`
  text-align: center;
`;

const StyledSubTitle = styled.div`
  ${DISPLAY_FLEX('column', 'center', 'center')}
  ${MARGIN(15, 0)}
  text-align:center;
`;

const StyleCenterAlignedCon = styled.div`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  ${MARGIN(30, 0, 0, 0)}

  button {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.PURPLE};
    height: 40px;
    width: 160px !important;
    border-radius: 5px;

    &:hover:enabled,
    &:focus,
    &:active {
      background-color: ${COLORS.PURPLE};
      color: ${COLORS.WHITE};
      border: 1px solid transparent;
    }
  }
`;

const StyledRightConatiner = styled(Col)`
  ${DISPLAY_FLEX('row', 'center', 'center')}
  ${MARGIN(null, null, null, -70)}

  @media (min-width: 1660px) and (max-width: 1900px) {
    ${MARGIN(null, null, null, -120)}
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    ${MARGIN(null, null, null, 0)}
  }
`;

const StyledUnsubscribedImage = styled.img`
  width: 160px;
  height: 160px;
`;
