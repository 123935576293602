import React, { useState, useEffect } from 'react';
import { getDomainsData } from '../../utils/common';
import { Dropdown } from '../shared-components/Form/Form';

const IndustryTypes = ({
  mode,
  handleChange,
  disabled,
  id,
  required = true,
  showArrow = false,
}) => {
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    getDomainsData().then((res) => setDomains(res));
  }, []);

  return (
    <Dropdown
      required={required}
      name="industryType"
      placeholder="Industry Type"
      options={domains}
      showSearch
      disabled={disabled}
      mode={mode}
      handleChange={handleChange}
      id={id}
      showArrow={showArrow}
    />
  );
};

export default IndustryTypes;
