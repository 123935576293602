import React from 'react';
import styled from 'styled-components';
import { FONT_WEIGHTS } from '../../../../styles/constants/typography';

const JobTab = ({ data }) => {
  const dataWithSpanRemoved = data.replace(/<\/?span[^>]*>/g, '');
  const styledData = dataWithSpanRemoved.replace(
    /<p>(.*?)<\/p>/g,
    '<p style="font-weight:700;">$1</p>',
  );
  return (
    <StyledJobDescription>
      <p
        dangerouslySetInnerHTML={{
          __html: styledData,
        }}
      />
    </StyledJobDescription>
  );
};

const StyledJobDescription = styled.div`
  p {
    font-size: 15px;
    font-weight: ${FONT_WEIGHTS.MEDIUM};
    opacity: 0.8;
  }

  margin-bottom: 30px;
`;

export default JobTab;
